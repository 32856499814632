import React from 'react';
import { Input } from './StandardInput';

export const ProcessFlowGroupCell = ({ tableManager, value, data, column, colIndex, rowIndex }) => {
    if (typeof(tableManager) === 'undefined') {
      return (null)
    } else {
      return (
          <div className='rgt-cell-inner' style={{display: 'flex', alignItems: 'center', overflow: 'hidden'}}>
              <ProcessFlowGroupCellClass dataItem={data} field={column.field} value={typeof(data[column.field]) !== 'undefined' ? data[column.field]: value}/>
          </div>
      )
    }
}

class ProcessFlowGroupCellClass extends React.Component {

    handleChange(e) {
      this.props.dataItem[this.props.field] = e.target.value;
      let targetTable = this.props.dataItem['targetTable'];

      if (typeof(this.props.dataItem.operationDetail) !== 'undefined' && typeof(this.props.dataItem.operationDetail.performedOperation) !== 'undefined' && typeof(this.props.dataItem.operationDetail.parent_targetTable) !== 'undefined' && (this.props.dataItem.operationDetail.performedOperation === 'before_pivot' || this.props.dataItem.operationDetail.performedOperation === 'before_single_pivot')) {
          targetTable = this.props.dataItem.operationDetail.parent_targetTable;
      }

      let group = global.targetTableProcessFlowGroup.filter(group => group.targetTable === targetTable);

      if (group.length > 0 && group[0].title !== this.props.dataItem[this.props.field]) {
        global.targetTableProcessFlowGroup = global.targetTableProcessFlowGroup.filter(targetGroup => !(targetGroup.targetTable === targetTable && targetGroup.title === group[0].title));
      }

      this.props.onChange({
        dataItem: this.props.dataItem,
        field: this.props.field,
        syntheticEvent: e.syntheticEvent,
        value: e.target.value
      });
    }
    render() {
        let groupTitle = this.props.dataItem[this.props.field];
        let readOnlyTitle = true;

        if (typeof(global.targetTableProcessFlowGroup) === 'undefined' || this.props.dataItem['rowId'] === 0) {
          global.targetTableProcessFlowGroup = [];
        }

        let targetTable = this.props.dataItem['targetTable'];

        if (typeof(this.props.dataItem.operationDetail) !== 'undefined' && typeof(this.props.dataItem.operationDetail.performedOperation) !== 'undefined' && typeof(this.props.dataItem.operationDetail.parent_targetTable) !== 'undefined' && (this.props.dataItem.operationDetail.performedOperation === 'before_pivot' || this.props.dataItem.operationDetail.performedOperation === 'before_single_pivot')) {
            targetTable = this.props.dataItem.operationDetail.parent_targetTable;
        }

        let group = global.targetTableProcessFlowGroup.filter(group => group.targetTable === targetTable);

        if (group.length === 0){
          global.targetTableProcessFlowGroup.push({ title: groupTitle, targetTable: targetTable, jobId: this.props.dataItem['jobId']});
          readOnlyTitle = false;
        } else if (group.length > 0 ){
          groupTitle = group[0].title;

          if (group[0].jobId === this.props.dataItem['jobId']) {
            readOnlyTitle = false;
          }
        }

        if (typeof(this.props.dataItem['operation']) !== 'undefined' && this.props.dataItem['operation'] === 'Apply Process Flow' && typeof(this.props.dataItem['operationDetail'].isSameStudy) !== 'undefined' && this.props.dataItem['operationDetail'].isSameStudy === true) {
          groupTitle = this.props.dataItem[this.props.field];
        }
        this.props.dataItem[this.props.field] = groupTitle;
        readOnlyTitle = true;
        return (
            <>
              <Input className="width-full" onChange={this.handleChange.bind(this)} value={groupTitle} length={40} disabled={readOnlyTitle}/>
            </>
        );
    }
}
