import React    from "react";
import axios from 'axios';
import { connect } from 'react-redux';
import { createSelector } from 'reselect';
import $ from 'jquery';
import moment from 'moment';
import template from "./BulkActionTask.jsx";
import restClient from './../../../../restClient';
import { CREATE, GET } from './../../../../restClient/types';
import {setMessage} from './../../../../actions/actionNotification';
import MessageTypeConst from "./../../../MessageTypeConst";
import TaskConstantType from './../TaskConstantType';

class BulkActionTask extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      schemaName: props.schemaName,
      taskId: props.taskId,
      commentDescription: '',
      status: 0,
      statusList: [],
      userList: [],
      selectedStatus: {},
      selectedUser: {},
    }

    TaskConstantType.STATUSLIST.forEach(status => {
      this.state.statusList.push({statusId: status[0], statusName: status[1]})
    })

    this.handleSaveTaskClick = this.handleSaveTaskClick.bind(this);
  }

  createBulkActionTask = (headers, params) => restClient(CREATE, 'task/bulk/update', params, headers);

  fetchUserList = (headers, params) => restClient(GET, 'users/list', params, headers);

  componentDidMount = () => {
    let that = this;
    $(".loader").show();
    axios.all([this.fetchUserList({'Tenant-ID': "master"}, {})])
        .then(axios.spread((responseUser, responseGroup) => {
          if (typeof(responseUser.data.message) === 'undefined') {
            if (typeof(responseUser.data) !== 'undefined') {
              let userList = [];
              responseUser.data.forEach(user => {
                userList.push({
                  userId: user.userId,
                  userName: user.firstName + ' ' + user.lastName
                })
              })
              that.setState({userList: userList})
            }
          } else {
              that.props.setMessage(responseUser.data.message, responseUser.data.messageType);
          }

          $(".loader").hide();
        })).catch(error => {
          $(".loader").hide();
          that.props.setMessage("Error occurred while fetching user list", MessageTypeConst.ERROR_MESSAGE);
      });
  }
  handleStatusChange = (event) => {
    this.setState({selectedStatus: event.target.value})
  }

  handleCommentDescriptionChange = (event) => {
    this.setState({commentDescription: event.target.value})
  }

  handleUserChange = (event) => {
      this.setState({selectedUser: event.target.value})
  }

  handleSaveTaskClick = (event) => {
    let params = {
      comment: this.state.commentDescription,
      taskId: this.state.taskId
    }

    if (typeof(this.state.selectedStatus.statusId) !== 'undefined') {
      params = Object.assign(params, {status: this.state.selectedStatus.statusId})
    }

    if (typeof(this.state.selectedUser.userId) !== 'undefined') {
      params = Object.assign(params, {userId: this.state.selectedUser.userId, userName: this.state.selectedUser.userName})
    }

    $(".loader").show();
    axios.all([this.createBulkActionTask({'Tenant-ID': this.state.schemaName}, {data: params})])
          .then(axios.spread((response) => {
            if (typeof(response.data) !== 'undefined') {
              this.props.setMessage("Bulk action completed successfully", MessageTypeConst.SUCCESS_MESSAGE)
              $(".loader").hide()
              this.props.closeEvent(event, true)
            } else {
                $(".loader").hide();
            }
          })).catch(error => {
              $(".loader").hide();
              this.props.setMessage("Failed to perform bulk action for selected task.",MessageTypeConst.ERROR_MESSAGE)
          })


  }

  render() {
    return template.call(this);
  }
}

const mapStateToProps = createSelector(
  state => state.processFlow.tenantId,
  (tenantId) => ({
    tenantId
  })
);

const mapActionsToProps = {
  setMessage: setMessage,
}

export default connect(mapStateToProps, mapActionsToProps)(BulkActionTask);
