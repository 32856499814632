import React from 'react';
import { DropDownList } from './StandardDropDownList';

export const SearchCriteriaFindCell = ({ tableManager, value, data, column, colIndex, rowIndex }) => {
    if (typeof(tableManager) === 'undefined') {
      return (null)
    } else {
      return (
          <div className='rgt-cell-inner' style={{display: 'flex', alignItems: 'center', overflow: 'hidden'}}>
              <SearchCriteriaFindCellClass dataItem={data} field={column.field} value={typeof(data[column.field]) !== 'undefined' ? data[column.field]: value} column={column} tableManager={tableManager}/>
          </div>
      )
    }
}

class SearchCriteriaFindCellClass extends React.Component {
    data = ["Start Of Heading", "Start Of Text", "End Of Text", "End Of Transmission", "Enquiry", "Acknowledge", "Bell", "Backspace", "Horizontal tab", "New Line", "Vertical Tab", "New Page", "carriage Return", "Shift Out", "Shift In", "Data Link Escap", "Device Cntrol 1", "Device Cntrol 2", "Device Cntrol 3", "Device Cntrol 4", "Negative Acknowledge", "Syncronous Idel", "End of Trans. Block", "Cancel", "End Of Medium", "Substitute", "Escap", "File Separator", "Group Separator", "Record Separator", "Until Seprator"];

    handleChange(e) {
      this.props.dataItem[this.props.field] = this.data.indexOf(e.target.value) +1;
      this.props.tableManager.rowsApi.setRows(this.props.tableManager.rowsApi.rows)
    }

    render() {
        let index = parseInt(this.props.dataItem[this.props.field], 10);
        let defaultValue = null;
        if(!isNaN(index)){
          defaultValue = this.data[index -1];

        }
        return (
            <>
              <DropDownList className="width-full" onChange={this.handleChange.bind(this)} data={this.data} value={defaultValue} />
            </>
        );
    }
}
