
class ShortNameConst {
    constructor(props){

        // Short Name

        this.APPEND_OP = "App/Set";
    this.COMPARE_OP = "Compare";
    this.CRITERIAOPS_OP = "CritOps";
    this.CONTINUOUSDATE_OP = "Cont Date";
    this.DATAOPS_OP = "DataOps";
    this.DECOLLAPSE_ONETABLE_OP = "DColl One";
    this.DECOLLAPSE_TWOTABLE_OP = "DColl Two";
    this.JOIN_OP = "Join";
    this.SORTOPS_OP = "SortOps";
    this.IFELSEOPS_OP = "IfElseOps";
    this.LINK_OP = "Link";
    this.PIVOT_RTC_OP = "Pivot RtoC";
    this.PIVOT_CTR_OP = "Pivot CtoR";
    this.REFERENCEPOINT_ONETABLE_OP = "RefP One";
    this.REFERENCEPOINT_TWOTABLE_OP = "RefP Two";
    this.REFERENCEPOINT_COMPOSITE_OP = "RefP Comp";
    this.REFERENCEPOINT_REPLACE_OP = "RefP Replace";
    this.REFERENCEPOINT_COMBINE_OP = "RefP Comb";
    this.REPORTINGOPS_OP = "RepoOps";
    this.PROCREPORT_OP = "ProcReport";
    this.R_Report_OP = "R Report";
    this.SPLIT_OP = "Split";
    this.STRINGOPS_ALIGN_OP = "SOps Align";
    this.STRINGOPS_INDUCESPACING_OP = "SOps Space";
    this.STRINGOPS_TRIMCOMPRESS_OP = "SOps Trim";
    this.STRINGOPS_CHANGECASE_OP = "SOps Case";
    this.STRINGOPS_SEARCHNREPLACE_OP = "SOps S&R";
    this.STRINGOPS_OCCURENCE_OP = "SOps Occ";
    this.STRINGOPS_SPLIT_OP = "SOps Split";
    this.TABLEOPS_RENAME_OP = "Rename/New";
    this.TABLEOPS_LENGTH_ADJ_OP = "LengthAdj";
    this.TYPECONVERSION_DATETIME_OP = "TypeC Date";
    this.TYPECONVERSION_CHARNUM_OP = "TypeC Num";
    this.VARMAPPING_OP = "VarMap";
    this.TIMELINE_OP = "Timeline";
    this.MAP_ANNOTATE_OP = "MapAnnotate";
    this.R_Report_OP = "R Report";
    this.AUTO_OP = "AUTO";
    }
}
export default (new ShortNameConst());
