import React from "react";
import { TabStrip, TabStripTab  } from './../common/Layout';
import { Title, FootNote, Ods, Graph } from '../common/ProcReport';
import DataOpsIdentifierConstants from "../../../DataOpsIdentifierConstants";

function getPreOptions (preOptionsList,  preOptioName){
  let value ='';
  if(preOptionsList === null || preOptionsList === ''){
    return value;
  }
  let selectedItem = preOptionsList.filter(item => item.property.toLowerCase() === preOptioName.toLowerCase());
  if(selectedItem.length > 0){
    value= selectedItem[0].value;
  }

  return value;
}

function getGlobalValue(controlIndex){
  let controlValue = '';
  global.processBlockControlValues.forEach((control, index) => {
    if (control.controlId === controlIndex) {
      controlValue = global.processBlockControlValues[index].controlValue;

    }
  });
  return controlValue;
}

function saveGlobalValue(controlIndex, value){
  let controlIdFound = false;
  global.processBlockControlValues.forEach((control, index) => {
    if (control.controlId === controlIndex) {
      controlIdFound = true;
      global.processBlockControlValues[index].controlValue = value;
    }
  });
  if(!controlIdFound){
      global.processBlockControlValues.push({controlId:controlIndex, controlType:controlIndex,controlValue:value});
  }
}

function getColumnValue(footnote, inputColumns, colName) {
  if (footnote === "" || footnote === null || typeof(footnote) === "undefined") {
    let selectedValue = inputColumns.filter(item => item.toLowerCase() === colName.toLowerCase());
    if (selectedValue.length > 0) {
      footnote = selectedValue[0];
    }
  }
  else {
    let selectedValue = inputColumns.filter(item => item.toLowerCase() === footnote.toLowerCase());
    if (selectedValue.length > 0) {
      footnote = selectedValue[0];
    }
    else {
      let selectedValue = inputColumns.filter(item => item.toLowerCase() === colName.toLowerCase());
      if (selectedValue.length > 0) {
        footnote = selectedValue[0];
      }else{
        footnote = "";
      }
    }
  }
  return footnote;
}

function SaveDefaultTreeViewParametersToGlobal(treeView, activeProcessFlow) {
  let ProjectName =  93;
  let StudyTagName =  94;
  let StudyName =  95;
  let GroupClassifierName =  96;
  let GroupName =  97;
  let ProcessFlowName =  98;

  treeView.children.forEach(project => {
    project.children.filter(study => study.study.schemaName === activeProcessFlow.schemaName).forEach(study => {
      saveGlobalValue(ProjectName, project.name);
      saveGlobalValue(StudyTagName, study.study.classifier);
      saveGlobalValue(StudyName, study.name);
      const processFlowGroup = study.study.processFlowGroup.find(processFlowGroup => processFlowGroup.processGroupId === activeProcessFlow.process.processGroupId);

      if (processFlowGroup !== null && typeof(processFlowGroup) !== 'processFlowGroup' && typeof(processFlowGroup.processGroupName) !== 'undefined') {
        const classifier = study.study.groupClassifier.find(classifier => classifier.classifierId === processFlowGroup.classifier);
        if (classifier !== null && typeof(classifier) !== 'undefined' && typeof(classifier.classifierName) !== 'undefined') {
          saveGlobalValue(GroupClassifierName, classifier.classifierName);
        }

        saveGlobalValue(GroupName, processFlowGroup.processGroupName);
      }

      saveGlobalValue(ProcessFlowName, activeProcessFlow.name);
    })
  })
}

export function saveDefaultParametersToGlobalForGraph(objectData, inputColumns, treeView, activeProcessFlow){
  let ProcReportComputeTableIndex  = 18;
  let DefineColumnStatementIndex = 17;
  let ProcReportDefineIndex  = 14;
  let ProcReportStyleTemplateIndex  = 20;
  let ProcReportPageOrientationIndex  = 21;
  let ProcReportPageSizeIndex  = 22;
  let ProcReportTopMarginIndex  = 23;
  let ProcReportBottomMarginIndex  = 90;
  let ProcReportLeftMarginIndex  = 25;
  let ProcReportRightMarginIndex  = 26;
  let ProcReportEscapeCharacterIndex  = 27;
  let ProcReportPdfOptionsIndex  = 28;
  let ProcReportRtfOptionsIndex  = 29;
  let ProcReportHtmlOptionsIndex  = 30;
  let ProcReportXlsOptionsIndex  = 31;
  let ProcReportBreakTableIndex  = 19;
  let ReportOptionIndex = 11;
  let BlankValueIndex = 89;
  let ProcReportTitlesIndex = 12;
  let ProcReportFootNotesIndex = 13;
  let ProcReportTitleOptionType =  36;
  let ProcReportFootNoteOptionType =  37;
  let titleObject = {
    title1:"",
    title2:"",
    title3:"",
    title4:"",
    title5:"",
    title6:"",
    title7:"",
    title8:"",
    title9:"",
    title10:""
  }
  let footNoteObject = {
    footNote1:"",
    footNote2:"",
    footNote3:"",
    footNote4:"",
    footNote5:"",
    footNote6:"",
    footNote7:"",
    footNote8:"",
    footNote9:"",
    footNote10:""
  }
  if(getGlobalValue(ProcReportTitleOptionType) === ""){
    for(let i=1 ; i<=10 ; i++){
      titleObject[`title${i}`] = getColumnValue(titleObject[`title${i}`], inputColumns, `title${i}`);
    }
    saveGlobalValue(ProcReportTitlesIndex, JSON.stringify(titleObject));
    saveGlobalValue(ProcReportTitleOptionType,"TEXT");
  }
  if(getGlobalValue(ProcReportFootNoteOptionType) === ""){
    for(let i=1 ; i<=10 ; i++){
      footNoteObject[`footNote${i}`] = getColumnValue(footNoteObject[`footNote${i}`], inputColumns, `footNote${i}`);
    }
    saveGlobalValue(ProcReportFootNotesIndex, JSON.stringify(footNoteObject));
    saveGlobalValue(ProcReportFootNoteOptionType,"TEXT");
  }
  if(getGlobalValue(BlankValueIndex) === "" && !global.updateBlock){
    saveGlobalValue(BlankValueIndex,"0");
  }

  //validation to ensure user to have some column specified in 'r_report_column'
  let originalvalue = getPreOptions(objectData,'r_report_column' );
  let value = originalvalue.match(/"([^"]+\W*)"/ig).join(" ").replace(/"/g,'');
  let dupicateCols = value.split(' ');
  let uniqueCols = [...new Set(dupicateCols)];
  value = uniqueCols.join(' ');
  let requiredColumns = value;
  let preColumn = value.split(' ');
  preColumn.forEach((col,index)=>{
    preColumn[index] = col.replace('(','').replace(')','');
  });
  let regexColumn = [];
  //only those columns that are present in tables need to be added
  let newInputColumns = [];
  inputColumns.forEach((item)=>{
    newInputColumns.push(item);
  });
  if(preColumn && preColumn.length >0){
    regexColumn = preColumn.filter(item => item.indexOf('<n>') !== -1);
    regexColumn.forEach((col,index) => {
      if(col.indexOf(',') !== -1){
        regexColumn[index] = col.substr(0,col.lastIndexOf(','));
      }
    });
    regexColumn.forEach((col,index) => {
      regexColumn[index] = col.replace("<n>","");
    });
  }
  let newCol = "";
  regexColumn.forEach((col,index) => {
    newCol = "";
    let pattern = "\\b" +col + "[0-9]+\\b";
    let  obj = new RegExp(pattern,'i');
    newInputColumns.forEach((column) => {
      if(obj.test(column)){
        if(/\bcol_var_sort[0-9]+\b/i.test(newCol)){
          newCol = newCol + "," + column
          newCol = newCol.trim()
        }else{
          newCol = newCol + " " + column
          newCol = newCol.trim()
        }
      }
    });
    originalvalue = originalvalue.replace(col+"<n>" ,  newCol);
  });
  let inputColumnFound = false;
  preColumn.forEach((col) => {
    if(newInputColumns.indexOf(col) === -1){
      originalvalue = originalvalue.replace(col, '').trim();
    }else{
      inputColumnFound = true;
    }
  });

  saveGlobalValue(DefineColumnStatementIndex, originalvalue);


  value = getPreOptions(objectData,'orientation' );
  saveGlobalValue(ProcReportPageOrientationIndex, value);
  value = getPreOptions(objectData,'r_report_options' );
  saveGlobalValue(ReportOptionIndex, value);
  value = getPreOptions(objectData,'papersize' );
  saveGlobalValue(ProcReportPageSizeIndex, value);
  value = getPreOptions(objectData,'topMargin' );
  saveGlobalValue(ProcReportTopMarginIndex, value);
  value = getPreOptions(objectData,'bottomMargin' );
  saveGlobalValue(ProcReportBottomMarginIndex, value);
  value = getPreOptions(objectData,'leftMargin' );
  saveGlobalValue(ProcReportLeftMarginIndex, value);
  value = getPreOptions(objectData,'rightMargin' );
  saveGlobalValue(ProcReportRightMarginIndex, value);
  value = getPreOptions(objectData,'escapeChar' );
  saveGlobalValue(ProcReportEscapeCharacterIndex, value);
  value = getPreOptions(objectData,'htmlOdsOpts' );
  saveGlobalValue(ProcReportHtmlOptionsIndex, JSON.stringify(value.split("\n").filter(a=>a)));
  value = getPreOptions(objectData,'rtfOdsOpts' );
  saveGlobalValue(ProcReportRtfOptionsIndex, JSON.stringify(value.split("\n").filter(a=>a)));
  value = getPreOptions(objectData,'pdfOdsOpts' );
  saveGlobalValue(ProcReportPdfOptionsIndex, JSON.stringify(value.split("\n").filter(a=>a)));
  value = getPreOptions(objectData,'csvOdsOpts' );
  saveGlobalValue(ProcReportXlsOptionsIndex, JSON.stringify(value.split("\n").filter(a=>a)));
  value = getPreOptions(objectData,'orientation' );
  saveGlobalValue(ProcReportPageOrientationIndex, value);
  value = getPreOptions(objectData,'r_template' );
  saveGlobalValue(ProcReportStyleTemplateIndex, JSON.stringify(value.split("\n").filter(a=>a)));
  SaveDefaultTreeViewParametersToGlobal(treeView, activeProcessFlow);
}

function getGBYValue(row){
  let gby= false;
  if(/\bby_var[0-9]+\b/i.test(row.column)){
    gby = true;
  }
  return gby;
}

function GraphLayoutTemplate() {
  let ReportOptionIndex = 11;
  let ReportOutputFormatIndex = 45;
  let BlankValueIndex = 89;
  let blankValue = getGlobalValue(BlankValueIndex);
  let reportOption = getGlobalValue(ReportOptionIndex);
  let chkPdfFormat = false;
  let chkXlsFormat = false;
  let chkRtfFormat = false;
  let chkHtmlFormat = false;
  if(this.state.processFlowAction === DataOpsIdentifierConstants.R_Report_OP){
    chkRtfFormat = true;
    chkHtmlFormat = true;
  }else{
    chkPdfFormat = true;
  }
  let ProcReportTitleOptionType =  36;
  let ProcReportFootNoteOptionType =  37;

  if(getGlobalValue(ProcReportTitleOptionType) === "" && global.updateBlock){
    saveGlobalValue(ProcReportTitleOptionType,"TEXT");
  }

  if(getGlobalValue(ProcReportFootNoteOptionType) === "" && global.updateBlock){
    saveGlobalValue(ProcReportFootNoteOptionType,"TEXT");
  }

  if(getGlobalValue(ReportOutputFormatIndex) !== ""){
    chkHtmlFormat = false;
    chkPdfFormat = false;
    chkRtfFormat = false;
    chkXlsFormat = false;
    let formats = JSON.parse(getGlobalValue(ReportOutputFormatIndex));
    if(formats.indexOf("HTML") > -1){
      chkHtmlFormat = true;
    }
    if(formats.indexOf("PDF") > -1){
      chkPdfFormat = true;
    }
    if(formats.indexOf("RTF") > -1){
      chkRtfFormat = true;
    }
    if(formats.indexOf("XLS") > -1){
      chkXlsFormat = true;
    }
  }else{
    let formats = [];
    if(chkHtmlFormat)
    {
      formats.push("HTML");
    }
    if(chkPdfFormat)
    {
      formats.push("PDF");
    }
    if(chkXlsFormat)
    {
      formats.push("XLS");
    }
    if(chkRtfFormat)
    {
      formats.push("RTF");
    }
    saveGlobalValue(ReportOutputFormatIndex, JSON.stringify(formats));
  }

  var handleBlankValueChange = (event) =>{
    blankValue = event.target.value;
    saveGlobalValue(BlankValueIndex,blankValue);
    this.setState({...this.state.tableList});
  }
  var handleReportOptionChange = (event) =>{
    reportOption = event.target.value;
    saveGlobalValue(ReportOptionIndex,reportOption);
    this.setState({...this.state.tableList});
  }
  var handleReportOutputFormatChange = (event) =>{
    switch(event.target.id){
      case "report-outout-format-pdf":
      chkPdfFormat = event.target.checked;
      break;
      case "report-outout-format-xls":
      chkXlsFormat = event.target.checked;
      break;
      case "report-outout-format-html":
      chkHtmlFormat = event.target.checked;
      break;
      case "report-outout-format-rtf":
      chkRtfFormat = event.target.checked;
      break;
      default:
      break;
    }
    let formats = [];
    if(chkHtmlFormat)
    {
      formats.push("HTML");
    }
    if(chkPdfFormat)
    {
      formats.push("PDF");
    }
    if(chkXlsFormat)
    {
      formats.push("XLS");
    }
    if(chkRtfFormat)
    {
      formats.push("RTF");
    }
    saveGlobalValue(ReportOutputFormatIndex, JSON.stringify(formats));
    this.setState({...this.state.tableList});
  }

  return (
    <div className="process-option">
      <div className="field_group">
        <div className="container-fluid p_15">
          <div className="row">
            <div className="filter-area am-form-inline m-0 col-12">
              <div className="am-form-field report_output_checkbox">
                <span><b>Report Output Format : </b></span>
                <input type="checkbox" id="report-outout-format-pdf" className="am-checkbox m-0" checked={chkPdfFormat} onChange={handleReportOutputFormatChange} />
                <label className="am-checkbox-label" htmlFor="report-outout-format-pdf">PDF</label>
                <input type="checkbox" id="report-outout-format-xls" className="am-checkbox" checked={chkXlsFormat} onChange={handleReportOutputFormatChange}/>
                <label className="am-checkbox-label" htmlFor="report-outout-format-xls">XLS</label>
                <input type="checkbox" id="report-outout-format-html" className="am-checkbox" checked={chkHtmlFormat} onChange={handleReportOutputFormatChange}/>
                <label className="am-checkbox-label" htmlFor="report-outout-format-html">HTML</label>
                <input type="checkbox" id="report-outout-format-rtf" className="am-checkbox" checked={chkRtfFormat} onChange={handleReportOutputFormatChange}/>
                <label className="am-checkbox-label" htmlFor="report-outout-format-rtf">RTF</label>
              </div>
            </div>
          </div>
        </div>
      </div>
      <TabStrip keepTabsMounted={true} selected={this.state.subtabSelected} onSelect={this.handleSelectSubTabPanel}>
        <TabStripTab title={"Header"}>
          {typeof(this.state.tableList[0]) !== 'undefined' &&
            <Title table={this.state.tableList[0]} processFlowAction={this.state.processFlowAction}/>
          }
        </TabStripTab>
        <TabStripTab title={"Footer"}>
          {typeof(this.state.tableList[0]) !== 'undefined' &&
            <FootNote table={this.state.tableList[0]} processFlowAction={this.state.processFlowAction}/>
          }
        </TabStripTab>
        <TabStripTab title={"Graph"}>
          {typeof(this.state.tableList[0]) !== 'undefined' &&
            <Graph table={this.state.tableList[0]} inputColumns={this.state.inputColumns} processFlowAction={this.state.processFlowAction}/>
          }
        </TabStripTab>
        <TabStripTab title={"Format"}>
          {typeof(this.state.preOptions) !== 'undefined' && typeof(this.state.preOptions.length) !== 'undefined' &&
            <Ods preOptions={this.state.preOptions} processFlowAction={this.state.processFlowAction} />
          }
        </TabStripTab>
      </TabStrip>
    </div>
  );

};

export default GraphLayoutTemplate;
