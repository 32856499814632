import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css
import './ConfirmBox.css';
import React from 'react';
import { confirmAlert } from 'react-confirm-alert';
import { Button } from './../Button/StandardButton';


class ConfirmBox extends React.Component {
  constructor(props) {
    super(props);
    ConfirmBox.open = this.open.bind(this);
    ConfirmBox.alert = this.alert.bind(this);
  }

  open = (message, title = 'Confirmation') => {
    return new Promise(async (resolve, reject) => {
    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <div className='react-confirm-alert-body'>
            <h1>{title}</h1>
            <div className="message">{message}</div>
            <div className="button-group">
              <Button
                primary={true}
                autoFocus={true}
                onClick={() => {
                  onClose();
                  resolve()
                }}>
                Ok
              </Button>
              <Button onClick={onClose}>Cancel</Button>
            </div>
          </div>
        );
      },
      closeOnClickOutside: false
    })
  })
  }

  alert = (message, title = 'Alert') => {
    return new Promise(async (resolve, reject) => {
    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <div className='react-confirm-alert-body'>
            <h1>{title}</h1>
            <div className="message">{message}</div>
            <div className="button-group">
              <Button primary={true} autoFocus={true} className="m-w-40" onClick={onClose}>Ok</Button>
            </div>
          </div>
        );
      },
      closeOnClickOutside: false
    })
  })
  }

  done = () => {

  }
}

export default (new ConfirmBox());
