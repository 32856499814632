import "./../TaskManagement.css";
import React from "react";
import { PropTypes } from "prop-types";
import Dialog from 'react-dialog';
import { Button } from './../../DataOperation/common/Button/StandardButton';
import { Input } from './../../DataOperation/common/InputBox/StandardInput';
import { DropDownList } from './../../DataOperation/common/DropDown/StandardDropDownList';
import RichTextEditor from "./../../RichTextEditor";
import moment from 'moment';

function template() {
  let nodeName = '';
  if (this.state.selectedNode.nodeId === 1 && typeof(this.state.selectedStudy.studyName) !== 'undefined') {
    nodeName = this.state.selectedStudy.studyName;
  } else if (this.state.selectedNode.nodeId === 2 && typeof(this.state.selectedProcessGroup.processGroupName) !== 'undefined') {
    nodeName = this.state.selectedProcessGroup.processGroupName;
  } else if (this.state.selectedNode.nodeId === 3 && typeof(this.state.selectedProcessFlow.processFlowName) !== 'undefined') {
    nodeName = this.state.selectedProcessFlow.processFlowName;
  } else if (this.state.selectedNode.nodeId === 4 && typeof(this.state.selectedBlock.blockName) !== 'undefined'){
    nodeName = this.state.selectedBlock.blockName;
  }
  return (
    <div className={`create-task-dialog ${this.state.mode}`}>
      <Dialog title={`${this.state.taskPrefix} Task${nodeName !== '' ? ' - '+nodeName : ''}`} width="calc(100% - 10px)" height="calc(100vh - 20px)" modal={true} onClose={this.props.closeEvent} buttons={[<Button primary={true} onClick={this.handleSaveTaskClick} disabled={this.state.title === '' || this.state.description === '' || (this.state.selectedNode.nodeId === 2 && this.state.selectedProcessGroup === '') || (this.state.selectedNode.nodeId === 3 && this.state.selectedProcessFlow === '') || (this.state.selectedNode.nodeId === 4 && this.state.selectedBlock === '')} key="btn-save">Save</Button>,<Button onClick={this.props.closeEvent} key="btn-cancel">Cancel</Button>]}>
        <div className="container-fluid m-t-5">
          <div className="row">
              <div className="col-2">
                <div className="am-form-field">
                  <span className="title">Node List:</span>
                  <DropDownList data={this.state.nodeList} textField={'nodeName'} dataItemKey={'nodeId'} onChange={this.handleSelectNodeChange} value={this.state.selectedNode}/>
                </div>
              </div>
              <div className="col-10">
                <div className="row">
                  <div className="col-3">
                    <div className="am-form-field">
                      <span className="title">Study List:</span>
                      <DropDownList data={this.state.studyList} textField={'studyName'} dataItemKey={'studyId'} onChange={(event) => this.handleSelectChange(event, 'study')} value={this.state.selectedStudy}/>
                    </div>
                  </div>
                  {this.state.selectedNode.nodeId > 1 &&
                    <div className="col-3">
                      <div className="am-form-field">
                        <span className="title">Process Group List:</span>
                        <DropDownList data={this.state.processGroupList} textField={'processGroupName'} dataItemKey={'processGroupId'} onChange={(event) => this.handleSelectChange(event, 'processGroup')} value={this.state.selectedProcessGroup}/>
                      </div>
                    </div>
                  }

                  {this.state.selectedNode.nodeId > 2 &&
                    <div className="col-3">
                      <div className="am-form-field">
                        <span className="title">Process Flow List:</span>
                        <DropDownList data={this.state.processFlowList} textField={'processFlowName'} dataItemKey={'processId'} onChange={(event) => this.handleSelectChange(event, 'processFlow')} value={this.state.selectedProcessFlow}/>
                      </div>
                    </div>
                  }

                  {this.state.selectedNode.nodeId > 3 &&
                    <div className="col-3">
                      <div className="am-form-field">
                        <span className="title">Block List:</span>
                        <DropDownList data={this.state.blockList} textField={'blockName'} dataItemKey={'blockId'} onChange={(event) => this.handleSelectChange(event, 'block')} value={this.state.selectedBlock}/>
                      </div>
                    </div>
                  }
                </div>
              </div>
          </div>
          <div className="row">
              <div className="col-12">
                <div className="am-form-field">
                  <span className="title">Title:</span>
                  <Input className='full-width' type="text" onChange={this.handleTaskTitleChange} autoFocus={true} value={this.state.title} />
                </div>
              </div>
          </div>
          <div className="row">
              <div className="col-12">
                <div className="am-form-field">
                  <span className="title">Description:</span>
                  <RichTextEditor content={this.state.description} onChange={this.handleDescriptionChange}/>
                </div>
              </div>
          </div>
          <div className="row">
            <div className="col-3">
              <div className="am-form-field">
                <span className="title">Status:</span>
                <DropDownList data={this.state.statusList} textField={'statusName'} dataItemKey={'statusId'} onChange={this.handleStatusChange} value={this.state.selectedStatus}/>
              </div>
            </div>
            <div className="col-3">
              <div className="am-form-field">
                <span className="title">Assign User:</span>
                <DropDownList data={this.state.userList} textField={'userName'} dataItemKey={'userId'} onChange={this.handleUserChange} value={this.state.selectedUser}/>
              </div>
            </div>
          </div>
          {this.state.mode === 'edit' &&
            <div className="task-detail">
              <div className="row">
                <div className="col-8">
                  <div className="am-form-field comment-content">
                    <span className="title">Comments:</span>
                    {this.state.commentsList.map((comment, key) => {
                      return (
                          <div key={`comment-${key}`} className="comment-wrapper">
                            <div className="description">{comment.commentDescription}</div>
                            <div className="footer">
                              <span className="author">{comment.userName}</span>
                              <span className="date">{comment.audit !== null && comment.audit.modifiedAt !== null ? moment.utc(comment.audit.modifiedAt).format('lll') : "--"}</span>
                            </div>
                          </div>
                      )
                    })}
                    {this.state.commentsList.length === 0 &&
                      <h6>No Comment Found</h6>
                    }
                  </div>
                </div>
                <div className="col-4">
                  <div className="am-form-field">
                    <span className="title">New Comment:</span>
                    <textarea className="text-box-display am-textbox textarea-box" defaultValue={this.state.commentDescription} onChange={this.handleCommentDescriptionChange} />
                  </div>
                </div>
              </div>
            </div>
          }
        </div>
      </Dialog>
    </div>
  )
}

Dialog.propTypes = {
    height: PropTypes.oneOfType([
        PropTypes.string
    ]),
    width: PropTypes.oneOfType([
        PropTypes.string
    ])
}
export default template;
