import React from 'react';

export const TagCheckBoxCell = ({ tableManager, value, data, column, colIndex, rowIndex, onChange }) => {
    if (typeof(tableManager) === 'undefined') {
      return (null)
    } else {
      return (
          <div className='rgt-cell-inner' style={{display: 'flex', alignItems: 'center', overflow: 'hidden'}}>
              <TagCheckBoxCellClass dataItem={data} field={column.field} value={typeof(data[column.field]) !== 'undefined' ? data[column.field]: value} tableManager={tableManager} onChange={onChange}/>
          </div>
      )
    }
}

class TagCheckBoxCellClass extends React.Component {

    handleChange(e) {
      this.props.dataItem[this.props.field] = e.target.checked;
      this.props.tableManager.rowsApi.setRows(this.props.tableManager.rowsApi.rows)
      this.props.onChange();
    }
    render() {
        let checked = false;
        if (this.props.dataItem[this.props.field]) {
          checked = true;
        }
        let colId = "rowId";
        return (
            <div className="text-center width-full">
              {(this.props.dataItem['isPreDefine'] === true || (this.props.dataItem['isPreDefine'] === false && this.props.dataItem['documentId'] > 0)) &&
                <span>-</span>
              }
              {this.props.dataItem['isPreDefine'] === false && this.props.dataItem['documentId'] === 0 &&
                [<input type="checkbox" className="am-checkbox" id={`checkbox-${this.props.dataItem[colId]}`} onChange={this.handleChange.bind(this)}
                checked={checked} disabled={this.props.dataItem['isPreDefine']} key={`checkbox-element-${this.props.dataItem[colId]}`}/>,
              <label className="am-checkbox-label" htmlFor={`checkbox-${this.props.dataItem[colId]}`} key={`checkbox-label-${this.props.dataItem[colId]}`}></label>]
              }
            </div>
        );
    }
}
