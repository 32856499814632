// @flow

import React from "react";
import { Button } from './../../DataOperation/common/Button/StandardButton';
import { DropDownList } from './../../DataOperation/common/DropDown/StandardDropDownList';
import type { T_Highlight } from "../../src/types";
type T_ManuscriptHighlight = T_Highlight;

type Props = {
  highlights: Array<T_ManuscriptHighlight>,
  scrollViewerTo: () => void
};
/*
<DropDownList onChange={(event) => {setSelectedPageType(event.target.value)}} data={[ "Current Page", "All Pages"]} value={selectedPageType}/>
<Button className="m-l-5" onClick={() => {jumpToNextPage()}} disabled={selectedPageType === 'All Pages'}>Next Page</Button>
*/
function FindAndAnnotate({ highlights, scrollViewerTo, selectedPageType, setSelectedPageType, jumpToNextPage, searchAndHighlightPage, highlightAndAnnotate, clearHighlight }: Props) {
  return (
    <div className="find-annotate col-9">
      <Button primary={true} className="m-l-5" onClick={() => {searchAndHighlightPage()}} >Find</Button>
      <Button primary={true} className="reset m-l-5" onClick={() => {highlightAndAnnotate()}} >Annotate</Button>
      <Button className="m-l-5" onClick={() => {clearHighlight()}} >Clear</Button>
    </div>
  );
}

export default FindAndAnnotate;
