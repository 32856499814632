class DataValidation {

  getGlobalValue(controlIndex){
    let controlValue = '';
    global.processBlockControlValues.forEach((control, index) => {
      if (control.controlId === controlIndex) {
        controlValue = global.processBlockControlValues[index].controlValue;

      }
    });
    return controlValue;
  }

  atleastOneVariableSelected = (dataOperationErrorList, processGrid) => {
    if (processGrid.filter(column => column.selected === true && column.column.indexOf('_app_') === -1 && column.column.indexOf('APP_') === -1).length === 0) {
      dataOperationErrorList.push('Please select at least one variable for processing.')
    }

    return dataOperationErrorList
  }

  atleastOneGBYVariableSelected = (dataOperationErrorList, processGrid) => {
    if (processGrid.filter(column => column.gby === true && column.column.indexOf('_app_') === -1 && column.column.indexOf('APP_') === -1).length === 0) {
      dataOperationErrorList.push('Please select at least one GBY variable for processing.')
    }

    return dataOperationErrorList
  }

  processingVariableSelected = (dataOperationErrorList, processGrid) => {
    let processingColumnlist = [];
    processGrid.filter(column => column.selected === false && column.column.indexOf('_app_') === -1 && column.column.indexOf('APP_') === -1 && column.newColumnName !== '').forEach(column => {
      processingColumnlist.push(column.column)
    })
    if (processingColumnlist.length > 0) {
      dataOperationErrorList.push('Processing Variable("'+processingColumnlist.join(', ')+'") must be selected as DISPLAY.')
    }

    return dataOperationErrorList
  }

  isValidStringopsAlignOp = () => {
    let dataOperationErrorList = [];
    let processingColumnlist = [];
    const processGrid = JSON.parse(this.getGlobalValue(3));

    dataOperationErrorList = this.atleastOneVariableSelected(dataOperationErrorList, processGrid);

    dataOperationErrorList = this.processingVariableSelected(dataOperationErrorList, processGrid);

    processGrid.filter(column => column.column.indexOf('_app_') === -1 && column.column.indexOf('APP_') === -1 && column.newColumnName !== '' && column.alignment === '').forEach(column => {
      processingColumnlist.push(column.column)
    })

    if (processingColumnlist.length > 0) {
      dataOperationErrorList.push('Alignment Type is missing in processing variable("'+processingColumnlist.join(', ')+'").')
    }

    return dataOperationErrorList;
  }

  isValidStringopsAlignOp = () => {
    let dataOperationErrorList = [];
    let processingColumnlist = [];
    const processGrid = JSON.parse(this.getGlobalValue(3));

    dataOperationErrorList = this.atleastOneVariableSelected(dataOperationErrorList, processGrid);

    dataOperationErrorList = this.processingVariableSelected(dataOperationErrorList, processGrid);

    processGrid.filter(column => column.column.indexOf('_app_') === -1 && column.column.indexOf('APP_') === -1 && column.newColumnName !== '' && column.alignment === '').forEach(column => {
      processingColumnlist.push(column.column)
    })

    if (processingColumnlist.length > 0) {
      dataOperationErrorList.push('Alignment Type is missing in processing variable("'+processingColumnlist.join(', ')+'").')
    }

    return dataOperationErrorList;
  }

  isValidStringopsTrimcompressOp = () => {
    let dataOperationErrorList = [];
    let processingColumnlist = [];
    const processGrid = JSON.parse(this.getGlobalValue(3));

    dataOperationErrorList = this.atleastOneVariableSelected(dataOperationErrorList, processGrid);

    dataOperationErrorList = this.processingVariableSelected(dataOperationErrorList, processGrid);

    processGrid.filter(column => column.column.indexOf('_app_') === -1 && column.column.indexOf('APP_') === -1 && column.newColumnName !== '' && column.leftTrim === false && column.rightTrim === false && column.compress === false).forEach(column => {
      processingColumnlist.push(column.column)
    })

    if (processingColumnlist.length > 0) {
      dataOperationErrorList.push('Trim Type is missing in processing variable("'+processingColumnlist.join(', ')+'").')
    }

    return dataOperationErrorList;
  }

  isValidStringopsInducespacingOp = () => {
    let dataOperationErrorList = [];
    let processingColumnlist = [];
    const processGrid = JSON.parse(this.getGlobalValue(3));

    dataOperationErrorList = this.atleastOneVariableSelected(dataOperationErrorList, processGrid);

    dataOperationErrorList = this.processingVariableSelected(dataOperationErrorList, processGrid);

    processGrid.filter(column => column.column.indexOf('_app_') === -1 && column.column.indexOf('APP_') === -1 && column.newColumnName !== '' && column.lengthNew === '').forEach(column => {
      processingColumnlist.push(column.column)
    })

    if (processingColumnlist.length > 0) {
      dataOperationErrorList.push('Length is missing in processing variable("'+processingColumnlist.join(', ')+'").')
    }
    processingColumnlist.length = 0;

    processGrid.filter(column => column.column.indexOf('_app_') === -1 && column.column.indexOf('APP_') === -1 && column.lengthNew !== '' && column.spacing === '').forEach(column => {
      processingColumnlist.push(column.column)
    })

    if (processingColumnlist.length > 0) {
      dataOperationErrorList.push('Space Position is missing in processing variable("'+processingColumnlist.join(', ')+'").')
    }
    processingColumnlist.length = 0;

    return dataOperationErrorList;
  }

  isValidStringopsOccurenceOp = () => {
    let dataOperationErrorList = [];
    let processingColumnlist = [];
    const processGrid = JSON.parse(this.getGlobalValue(3));

    dataOperationErrorList = this.atleastOneVariableSelected(dataOperationErrorList, processGrid);

    dataOperationErrorList = this.processingVariableSelected(dataOperationErrorList, processGrid);

    processGrid.filter(column => column.column.indexOf('_app_') === -1 && column.column.indexOf('APP_') === -1 && column.newColumnName !== '' && column.keyString === '').forEach(column => {
      processingColumnlist.push(column.column)
    })

    if (processingColumnlist.length > 0) {
      dataOperationErrorList.push('Key String is missing in processing variable("'+processingColumnlist.join(', ')+'").')
    }

    return dataOperationErrorList;
  }

  isValidStringopsSearchnreplaceOp = () => {
    let dataOperationErrorList = [];
    let processingColumnlist = [];
    const processGrid = JSON.parse(this.getGlobalValue(3));

    dataOperationErrorList = this.atleastOneVariableSelected(dataOperationErrorList, processGrid);

    dataOperationErrorList = this.processingVariableSelected(dataOperationErrorList, processGrid);

    processGrid.filter(column => column.column.indexOf('_app_') === -1 && column.column.indexOf('APP_') === -1 && column.newColumnName !== '' && column.seachPolicy === '').forEach(column => {
      processingColumnlist.push(column.column)
    })

    if (processingColumnlist.length > 0) {
      dataOperationErrorList.push('Search Policy is missing in processing variable("'+processingColumnlist.join(', ')+'").')
    }
    processingColumnlist.length = 0;

    processGrid.filter(column => column.column.indexOf('_app_') === -1 && column.column.indexOf('APP_') === -1 && column.newColumnName !== '' && column.condition === '').forEach(column => {
      processingColumnlist.push(column.column)
    })

    if (processingColumnlist.length > 0) {
      dataOperationErrorList.push('Condition is missing in processing variable("'+processingColumnlist.join(', ')+'").')
    }
    processingColumnlist.length = 0;

    processGrid.filter(column => column.column.indexOf('_app_') === -1 && column.column.indexOf('APP_') === -1 && column.condition === 'Special Criteria' && column.searchCriteria === '').forEach(column => {
      processingColumnlist.push(column.column)
    })

    if (processingColumnlist.length > 0) {
      dataOperationErrorList.push('Search Criteria is missing in processing variable("'+processingColumnlist.join(', ')+'").')
    }
    processingColumnlist.length = 0;

    return dataOperationErrorList;
  }

  isValidStringopsChangecaseOp = () => {
    let dataOperationErrorList = [];
    let processingColumnlist = [];
    const processGrid = JSON.parse(this.getGlobalValue(3));

    dataOperationErrorList = this.atleastOneVariableSelected(dataOperationErrorList, processGrid);

    dataOperationErrorList = this.processingVariableSelected(dataOperationErrorList, processGrid);

    processGrid.filter(column => column.column.indexOf('_app_') === -1 && column.column.indexOf('APP_') === -1 && column.newColumnName !== '' && column.charCase === '').forEach(column => {
      processingColumnlist.push(column.column)
    })

    if (processingColumnlist.length > 0) {
      dataOperationErrorList.push('Char Case is missing in processing variable("'+processingColumnlist.join(', ')+'").')
    }

    return dataOperationErrorList;
  }

  isValidStringopsSplitOp = () => {
    let dataOperationErrorList = [];
    let processingColumnlist = [];
    const processGrid = JSON.parse(this.getGlobalValue(3));

    dataOperationErrorList = this.atleastOneVariableSelected(dataOperationErrorList, processGrid);

    processGrid.filter(column => column.selected === false && column.column.indexOf('_app_') === -1 && column.column.indexOf('APP_') === -1 && column.splitOperation !== '').forEach(column => {
      processingColumnlist.push(column.column)
    })
    if (processingColumnlist.length > 0) {
      dataOperationErrorList.push('Processing Variable("'+processingColumnlist.join(', ')+'") must be selected as DISPLAY.')
    }

    return dataOperationErrorList;
  }

  isValidTypeconversionCharnumOp = () => {
    let dataOperationErrorList = [];
    let processingColumnlist = [];
    const processGrid = JSON.parse(this.getGlobalValue(3));

    dataOperationErrorList = this.atleastOneVariableSelected(dataOperationErrorList, processGrid);

    dataOperationErrorList = this.processingVariableSelected(dataOperationErrorList, processGrid);

    processGrid.filter(column => column.column.indexOf('_app_') === -1 && column.column.indexOf('APP_') === -1 && column.newColumnName !== '' && column.conversionType === '').forEach(column => {
      processingColumnlist.push(column.column)
    })

    if (processingColumnlist.length > 0) {
      dataOperationErrorList.push('Conversion Type is missing in processing variable("'+processingColumnlist.join(', ')+'").')
    }
    processingColumnlist.length = 0;

    processGrid.filter(column => column.column.indexOf('_app_') === -1 && column.column.indexOf('APP_') === -1 && column.newColumnName !== '' && column.decimalFormat === '').forEach(column => {
      processingColumnlist.push(column.column)
    })

    if (processingColumnlist.length > 0) {
      dataOperationErrorList.push('Decimal Format is missing in processing variable("'+processingColumnlist.join(', ')+'").')
    }
    processingColumnlist.length = 0;

    return dataOperationErrorList;
  }

  isValidTypeconversionDatetimeOp = () => {
    let dataOperationErrorList = [];
    let processingColumnlist = [];
    const processGrid = JSON.parse(this.getGlobalValue(3));

    dataOperationErrorList = this.atleastOneVariableSelected(dataOperationErrorList, processGrid);

    dataOperationErrorList = this.processingVariableSelected(dataOperationErrorList, processGrid);

    processGrid.filter(column => column.column.indexOf('_app_') === -1 && column.column.indexOf('APP_') === -1 && column.newColumnName !== '' && column.inputDateFormat === '').forEach(column => {
      processingColumnlist.push(column.column)
    })

    if (processingColumnlist.length > 0) {
      dataOperationErrorList.push('Input Date Format is missing in processing variable("'+processingColumnlist.join(', ')+'").')
    }
    processingColumnlist.length = 0;

    processGrid.filter(column => column.column.indexOf('_app_') === -1 && column.column.indexOf('APP_') === -1 && column.newColumnName !== '' && column.outputDateFormat === '' && column.outputType !== 'DATE').forEach(column => {
      processingColumnlist.push(column.column)
    })

    if (processingColumnlist.length > 0) {
      dataOperationErrorList.push('Output Date Format is missing in processing variable("'+processingColumnlist.join(', ')+'").')
    }
    processingColumnlist.length = 0;

    processGrid.filter(column => column.column.indexOf('_app_') === -1 && column.column.indexOf('APP_') === -1 && column.newColumnName !== '' && column.outputType === '').forEach(column => {
      processingColumnlist.push(column.column)
    })

    if (processingColumnlist.length > 0) {
      dataOperationErrorList.push('Output Type is missing in processing variable("'+processingColumnlist.join(', ')+'").')
    }
    processingColumnlist.length = 0;

    if (processGrid.filter(column => column.column.indexOf('_app_') === -1 && column.column.indexOf('APP_') === -1 && column.inputDateFormat !== '').length === 0) {
      dataOperationErrorList.push('Input Date Format is missing.')
    }

    if (processGrid.filter(column => column.column.indexOf('_app_') === -1 && column.column.indexOf('APP_') === -1 && column.outputDateFormat !== '').length === 0) {
      dataOperationErrorList.push('Output Date Format is missing.')
    }

    return dataOperationErrorList;
  }

  isValidPivotRtcOp = () => {
    let dataOperationErrorList = [];
    const idVariable = this.getGlobalValue(15);
    const idLabel = this.getGlobalValue(16);

    if (idVariable === '') {
      dataOperationErrorList.push('ID Variable is missing. Please select atleast one variable');
    }

    if (idLabel === '') {
      dataOperationErrorList.push('ID Lable is missing. Please select atleast one variable');
    }
    return dataOperationErrorList;
  }

  isValidPivotCtrOp = () => {
    let dataOperationErrorList = [];
    const processGrid = JSON.parse(this.getGlobalValue(3));

    dataOperationErrorList = this.atleastOneGBYVariableSelected(dataOperationErrorList, processGrid);

    return dataOperationErrorList;
  }

  isValidSplitOp = () => {
    let dataOperationErrorList = [];
    let whereCondition = this.getGlobalValue(11);
    const processGrid = JSON.parse(this.getGlobalValue(3));

    dataOperationErrorList = this.atleastOneVariableSelected(dataOperationErrorList, processGrid);

    if (typeof(whereCondition) !== 'undefined' && whereCondition !== null) {
      whereCondition = whereCondition.trim()
    }
    if (whereCondition === '') {
      dataOperationErrorList.push('Where condition is missing. Please check.');
    }

    return dataOperationErrorList;
  }

  isValidReferencepointOnetableOp = () => {
    let dataOperationErrorList = [];
    let name = this.getGlobalValue(58);
    let description = this.getGlobalValue(59);
    let startReferenceOption = this.getGlobalValue(64);
    let stopReferenceOption = this.getGlobalValue(69);
    let startDateVariable = this.getGlobalValue(4);
    let stopDateVariable = this.getGlobalValue(5);
    let startPrefix = this.getGlobalValue(66);
    let startNumberType = this.getGlobalValue(67);
    let startSuffix = this.getGlobalValue(68);
    let stopPrefix = this.getGlobalValue(71);
    let stopNumberType = this.getGlobalValue(72);
    let stopSuffix = this.getGlobalValue(73);
    let studyIdVariable = this.getGlobalValue(60);
    let subjectIdVariable = this.getGlobalValue(61);

    if (typeof(name) !== 'undefined' && name !== null) {
      name = name.trim();
    }
    if (typeof(description) !== 'undefined' && description !== null) {
      description = description.trim();
    }
    if (typeof(startDateVariable) !== 'undefined' && startDateVariable !== null) {
      startDateVariable = startDateVariable.trim();
    }
    if (typeof(stopDateVariable) !== 'undefined' && stopDateVariable !== null) {
      stopDateVariable = stopDateVariable.trim();
    }
    if (typeof(startPrefix) !== 'undefined' && startPrefix !== null) {
      startPrefix = startPrefix.trim();
    }
    if (typeof(startSuffix) !== 'undefined' && startSuffix !== null) {
      startSuffix = startSuffix.trim();
    }
    if (typeof(stopPrefix) !== 'undefined' && stopPrefix !== null) {
      startDateVariable = startDateVariable.trim();
    }
    if (typeof(stopSuffix) !== 'undefined' && stopSuffix !== null) {
      stopSuffix = stopSuffix.trim();
    }
    if (typeof(studyIdVariable) !== 'undefined' && studyIdVariable !== null) {
      studyIdVariable = studyIdVariable.trim();
    }
    if (typeof(subjectIdVariable) !== 'undefined' && subjectIdVariable !== null) {
      subjectIdVariable = subjectIdVariable.trim();
    }

    if (name === '' || name === null) {
      dataOperationErrorList.push('Reference Point Name is missing. Please check.');
    }

    if (description === '' || description === null) {
      dataOperationErrorList.push('Reference Point Description is missing. Please check.');
    }

    if (studyIdVariable === '' || studyIdVariable === null) {
      dataOperationErrorList.push('Study Id is missing. Please check.');
    }

    if (subjectIdVariable === '' || subjectIdVariable === null) {
      dataOperationErrorList.push('Subject Id is missing. Please check.');
    }

    if ((startReferenceOption === 'Expression' || stopReferenceOption === 'Expression') && ((startDateVariable === '' || startDateVariable === null) && (stopDateVariable === '' || stopDateVariable === null))) {
      dataOperationErrorList.push('Start Date Variable or Stop Date Variable is required if Reference option Expression selected.');
    }

    /*if ((startReferenceOption !== 'Startdate Infinite' && (startPrefix === '' || startPrefix === null || startNumberType !== 'Autogenerated Number' || startSuffix === '' || startSuffix === null)) || (stopReferenceOption !== 'Stopdate Infinite' && (stopPrefix === '' || stopPrefix === null || stopNumberType !== 'Autogenerated Number' || stopSuffix === '' || stopSuffix === null))) {
      dataOperationErrorList.push('Prefix, Auto-Number and Suffix are required if Reference option "Startdate Infinite" or "Stopdate Infinite" is not selected.');
    }*/

    return dataOperationErrorList;
  }

  isValidContinuousdateOp = () => {
    let dataOperationErrorList = [];
    const processGrid = JSON.parse(this.getGlobalValue(3));
    let startDateVariable = this.getGlobalValue(4);
    let stopDateVariable = this.getGlobalValue(5);
    let newVariableName = this.getGlobalValue(6);

    if (typeof(startDateVariable) !== 'undefined' && startDateVariable !== null) {
      startDateVariable = startDateVariable.trim();
    }
    if (typeof(stopDateVariable) !== 'undefined' && stopDateVariable !== null) {
      stopDateVariable = stopDateVariable.trim();
    }
    if (typeof(newVariableName) !== 'undefined' && newVariableName !== null) {
      newVariableName = newVariableName.trim();
    }

    dataOperationErrorList = this.atleastOneGBYVariableSelected(dataOperationErrorList, processGrid);

    if (startDateVariable === '' || startDateVariable === null) {
      dataOperationErrorList.push('Start date is missing. Please check.');
    }
    if (stopDateVariable === '' || stopDateVariable === null) {
      dataOperationErrorList.push('Stop date is missing. Please check.');
    }
    if (newVariableName === '' || newVariableName === null) {
      dataOperationErrorList.push('New Variable Name is missing. Please check.');
    }
    return dataOperationErrorList;
  }

  isValidDecollapseOnetableOp = () => {
    let dataOperationErrorList = [];
    const processGrid = JSON.parse(this.getGlobalValue(3));
    let startDateVariable = this.getGlobalValue(4);
    let stopDateVariable = this.getGlobalValue(5);

    if (typeof(startDateVariable) !== 'undefined' && startDateVariable !== null) {
      startDateVariable = startDateVariable.trim();
    }
    if (typeof(stopDateVariable) !== 'undefined' && stopDateVariable !== null) {
      stopDateVariable = stopDateVariable.trim();
    }

    dataOperationErrorList = this.atleastOneGBYVariableSelected(dataOperationErrorList, processGrid);

    if (startDateVariable === '' || startDateVariable === null) {
      dataOperationErrorList.push('Start date is missing. Please check.');
    }
    if (stopDateVariable === '' || stopDateVariable === null) {
      dataOperationErrorList.push('Stop date is missing. Please check.');
    }
    return dataOperationErrorList;
  }

  isValidDataopsOp = () => {
    let dataOperationErrorList = [];
    const processGrid = JSON.parse(this.getGlobalValue(3));
    let timeScaleVariable = this.getGlobalValue(20);
    let timeScaleDirection = this.getGlobalValue(21);
    let timeScaleRule = this.getGlobalValue(22);

    if (typeof(timeScaleVariable) !== 'undefined' && timeScaleVariable !== null) {
      timeScaleVariable = timeScaleVariable.trim();
    }
    if (typeof(timeScaleDirection) !== 'undefined' && timeScaleDirection !== null) {
      timeScaleDirection = timeScaleDirection.trim();
    }
    if (typeof(timeScaleRule) !== 'undefined' && timeScaleRule !== null) {
      timeScaleRule = timeScaleRule.trim();
    }

    dataOperationErrorList = this.atleastOneGBYVariableSelected(dataOperationErrorList, processGrid);

    if (timeScaleVariable === null) {
      dataOperationErrorList.push('Time Scale Variable is missing. Please check.');
    }
    if (timeScaleDirection === '' || timeScaleDirection === null) {
      dataOperationErrorList.push('Time Scale Direction is missing. Please check.');
    }
    if (timeScaleRule === '' || timeScaleRule === null) {
      dataOperationErrorList.push('Time Scale Rule is missing. Please check.');
    }
    return dataOperationErrorList;
  }

  isValidRankopsOp = () => {
    let dataOperationErrorList = [];
    const processGrid = JSON.parse(this.getGlobalValue(3));

    dataOperationErrorList = this.atleastOneVariableSelected(dataOperationErrorList, processGrid);

    dataOperationErrorList = this.atleastOneGBYVariableSelected(dataOperationErrorList, processGrid);

    return dataOperationErrorList;
  }

  isValidSortopsOp = () => {
    let dataOperationErrorList = [];
    const processGrid = JSON.parse(this.getGlobalValue(3));

    dataOperationErrorList = this.atleastOneVariableSelected(dataOperationErrorList, processGrid);

    dataOperationErrorList = this.atleastOneGBYVariableSelected(dataOperationErrorList, processGrid);

    return dataOperationErrorList;
  }

  isValidSequqenceopsOp = () => {
    let dataOperationErrorList = [];
    const processGrid = JSON.parse(this.getGlobalValue(3));

    dataOperationErrorList = this.atleastOneVariableSelected(dataOperationErrorList, processGrid);

    dataOperationErrorList = this.atleastOneGBYVariableSelected(dataOperationErrorList, processGrid);

    return dataOperationErrorList;
  }

  isValidTableopsRenameOp = () => {
    let dataOperationErrorList = [];
    const processGrid = JSON.parse(this.getGlobalValue(3));

    dataOperationErrorList = this.atleastOneVariableSelected(dataOperationErrorList, processGrid);

    return dataOperationErrorList;
  }
}

export default (new DataValidation());
