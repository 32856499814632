import './Breadcrumb.css'
import React from 'react';

function template() {
  return (
    <div className="breadcrumb-component">
      <div id="crumbs">
        <ul>
      		{typeof(this.props.itemList) !== 'undefined' && this.props.itemList.length > 0 &&
            this.props.itemList.map((item, index) => {
              return (
                <li key={`breadcrumb-${index}`}>
                  <span>
                    {typeof(item.iconClass) !== 'undefined' &&
                      <i className={item.iconClass} aria-hidden="true"></i>
                    }
                    {item.name}
                  </span>
                </li>
              )
            })
          }
      	</ul>
      </div>
    </div>
  )
}

export default template;
