import "./../TaskManagement.css";
import React from "react";
import { Button } from './../../DataOperation/common/Button/StandardButton';
import { Input } from './../../DataOperation/common/InputBox/StandardInput';
import { PropTypes } from "prop-types";
import Dialog from 'react-dialog';
import { DropDownList } from './../../DataOperation/common/DropDown/StandardDropDownList';
import CreateTask from './../CreateTask';
import RichGridTable from './../../RichGridTable';
import BulkActionTask from './../BulkActionTask';

function template() {
  const tableViewLabel = (this.state.commentFlag ? 'Blogger': 'Tabular') + " View";
  return (
      <div className="task-list">
        <Dialog title={`Task List`} width="calc(100% - 10px)" height="calc(100vh - 20px)" modal={true} onClose={this.props.closeEvent} buttons={[<Button onClick={this.props.closeEvent} key="btn-cancel" >Cancel</Button>]}>
          <div className="action-bar">
            <div className="row">
              <div className="col-5">
                <Input className="width-full" onChange={this.handleSearchTaskChange} value={this.state.searchTitle} placeholder="Search task" />
              </div>
              <div className="col-7 text-left">
                <Button primary={true} onClick={this.handleSearchTaskListClick}>Search</Button>
                <Button className="m-r-5" onClick={this.handleGetAllTaskListView}>All Task</Button>
                <Button secondary="true" className={'m-r-5'} onClick={this.handleChangeTaskListView}>{tableViewLabel}</Button>
                <Button primary={true} look="outline" className={'m-r-5'} onClick={this.handleBulkActionClick}>Bulk Action</Button>
                <Button look="outline" className="m-r-0" onClick={this.handleCreateTaskDialogClick}>Create</Button>

              </div>
            </div>
          </div>

          {this.state.tableList.rows.length > 0 &&
            <div className="task-list-wrapper">
              <RichGridTable {...this.state.tableList} key={this.state.tableList.id} />
            </div>
          }

          {this.state.tableList.rows.length === 0 && this.state.isDataLoaded === true &&
            <div className="no-task">No Task Found</div>
          }

          {this.state.isDataLoaded === false &&
            <div className="no-task">Loading....</div>
          }

          {this.state.createTaskDialogStatus === true &&
            <CreateTask node={this.state.triggerNode} closeEvent={this.handleCreateTaskDialogClick} />
          }

          {this.state.bulkActionDialogStatus === true &&
            <BulkActionTask schemaName={this.state.schemaName} taskId={this.state.taskIdList} closeEvent={this.handleBulkActionClick} />
          }
        </Dialog>
      </div>
  )
}

export default template;
