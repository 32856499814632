import React    from "react";
import { connect } from 'react-redux';
import { createSelector } from 'reselect';
import axios from 'axios';
import $ from 'jquery';
import cloneDeep from 'lodash/cloneDeep';
import Excel from 'exceljs';
import template from "./TreeView.jsx";
import DrawColumnMappingLine from './../Annotate/DrawColumnMappingLine';
import { manageMappedColumnList, addWhereCondition, removeWhereCondition, addHardcode, removeHardcode, addRenameColumn, removeRenameColumn, addMultipleColumnMappedList, setDefaultTargetColumnExpression, setTreeElement, removeApplyProcessFlow, updateTreeElementViaImmutable, manageTargetTableValueInExpression, manageWhereMatrix} from './../../../actions/actionAnnotate';
import { GET_ALL, GET_ALL_FROM_BODY_PARAM } from './../../../restClient/types';
import restClient from './../../../restClient';
import MappedColumnValue from "../Annotate/MappedColumnValue";
import { setMessage } from '../../../actions/actionNotification';
import MessageTypeConst from '../../MessageTypeConst';
import ConfirmBox from './../DataOperation/common/ConfirmBox';
var sideBarEventTimeOutId = null;


const getDimensions = (element) => {
  if (element !== '' && typeof(element) !== 'undefined') {
    const { width, height, x, y, top, bottom, left, right } = element.getBoundingClientRect();
    return { width, height, x, y, top, bottom, left, right };
  } else {
    return false;
  }
};

const wrapperOffsetElement = () => {
  if (document.getElementsByClassName('svg-line-mapper').length > 0 && document.getElementsByClassName('svg-line-mapper')[0].querySelectorAll('svg').length > 0) {
      return getDimensions(document.getElementsByClassName('svg-line-mapper')[0].querySelectorAll('svg')[0]);
  }
}

const drawLineElementLimit = (element) => {
  if ($(element).parents('.drawline_limit').length > 0) {
      return getDimensions($(element).parents('.drawline_limit')[0]);
  }
}


class TreeView extends React.Component {
  constructor(props) {
    super(props);
//treeView: cloneDeep(props.treeView),
    this.state = {
      id:props.id,
      treeView: props.treeView,
      visibleAddWhereCondition: false,
      visibleAddExpression: false,
      visibleAddHardCode: false,
      visibleAddRenameColumn: false,
      selectNode:{},
      tableWhereCondition:'',
      columnHardcode:'',
      renameColumn: {
        tableName: '',
        column: '',
        label: ''
      },
      hardcode: {
        type: 'Hardcode',
        intermediateColumn: '',
        columnHardcode: ''
      },
      visibleRemoveMappedColumn: false,
      targetMappedColumn: {
        targetId: null,
        tableList: []
      },
      expressionTitle:"Expression",
      visibleApplyProcessFlow:false,
      visibleSortApplyProcessFlow: false,
      visibleSortColumns: false,
      visiblePivotMatrixDialog: false,
      visibleDataViewer: false,
      visibleWhereMatrixDialog: false,
      visibleTargetValueDialog: false,
      valueTitle: '',
      targetTableName: '',
      searchText: {
        sourceValue: '',
        targetValue: ''
      },
      visibleTLFTableRenameDialog: false,
      visibleReportingEventDialog: false
    }

    this.onToggle = this.onToggle.bind(this);
    this.startDrawLine = this.startDrawLine.bind(this);
    this.drawLine = this.drawLine.bind(this);
    this.endDropLine = this.endDropLine.bind(this);
    this.endDrawLine = this.endDrawLine.bind(this);
    this.dragOverLine = this.dragOverLine.bind(this);
    this.modifyDrawLine = this.modifyDrawLine.bind(this);
    this.handleWhereConditionClick = this.handleWhereConditionClick.bind(this);
    this.handleExpressionClick = this.handleExpressionClick.bind(this);
    this.handleWhereConditionChange = this.handleWhereConditionChange.bind(this);
    this.handleAddWhereConditionClick = this.handleAddWhereConditionClick.bind(this);
    this.handleHardcodeClick = this.handleHardcodeClick.bind(this);
    this.triggerExpressClick = this.triggerExpressClick.bind(this);
    this.handleRenameColumnClick = this.handleRenameColumnClick.bind(this);
    this.handleAddRenameColumnClick = this.handleAddRenameColumnClick.bind(this);
    this.handleRenameColumnTextChange = this.handleRenameColumnTextChange.bind(this);
    this.handleHardcodeTypeChange = this.handleHardcodeTypeChange.bind(this);
    this.addMultipleTargetColumnExpression = this.addMultipleTargetColumnExpression.bind(this);
    this.sortColumnMappedList = this.sortColumnMappedList.bind(this);
    this.handleApplyProcessFlowClick = this.handleApplyProcessFlowClick.bind(this);
    this.handleShowTableMapping = this.handleShowTableMapping.bind(this);
    this.handlePivotMatrixClick = this.handlePivotMatrixClick.bind(this);
    this.handleWhereMatrixClick = this.handleWhereMatrixClick.bind(this);
    this.closePivotMatrixDialog = this.closePivotMatrixDialog.bind(this);
    this.handleDataViewerClick = this.handleDataViewerClick.bind(this);
    this.closeDataViewerDialog = this.closeDataViewerDialog.bind(this);
    this.getSingleGroupExpression = this.getSingleGroupExpression.bind(this);
    this.handleRemoveHardcodeClick = this.handleRemoveHardcodeClick.bind(this);
    this.handleNewSetValueClick = this.handleNewSetValueClick.bind(this);
    this.handleRemoveSetValueClick = this.handleRemoveSetValueClick.bind(this);
    this.findParentTable = this.findParentTable.bind(this);
    this.handleTargetValueClick = this.handleTargetValueClick.bind(this);
    this.handleAddTargetVariableValueClick = this.handleAddTargetVariableValueClick.bind(this);
    this.handleRemoveTargetVariableValueClick = this.handleRemoveTargetVariableValueClick.bind(this);
    this.handleSearchValueClick = this.handleSearchValueClick.bind(this);
    this.getExpressionString = this.getExpressionString.bind(this);
    this.getTargetVariableValueWithCarryForward = this.getTargetVariableValueWithCarryForward.bind(this);
    this.handlePasteTLFTableClick = this.handlePasteTLFTableClick.bind(this);
    this.handleImportAutoTlfEventFilesChange = this.handleImportAutoTlfEventFilesChange.bind(this);
    this.handleReportingEventClick = this.handleReportingEventClick.bind(this);
  }

  fecthPreference = (headers, params) => restClient(GET_ALL, 'distinct/values', params, headers);

  fetchTableMultiColumnValues = (headers, params) => restClient(GET_ALL_FROM_BODY_PARAM, 'multiple/mapvalues', params, headers);

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (JSON.stringify(nextProps) !== '' && typeof(nextProps.treeView)) {
      this.setState({treeView: nextProps.treeView})
    }
  }

  onToggle(node, toggled, delay){
    delay = delay || 250;

        if (!sideBarEventTimeOutId) {
            sideBarEventTimeOutId = setTimeout(() => {
              if(this.state.cursor){this.state.cursor.active = false;}
              node.active = true;
              if(node.children){ node.toggled = toggled; }

              if (typeof(node.fetchColumnValue) !== 'undefined' && node.fetchColumnValue === true && typeof(node.column) !== 'undefined') {
                let columnDetail = node.id.split('-');
                const isChildDragable = node.isChildDragable;

                if (columnDetail.length > 1) {
                  let tenantId = this.props.tenantId;
                  //tenantId = 'schema_1_50';
                  $(".loader").show();
                  if (node.type === 'metadata' && this.props.columnMappedList.filter(columnMapped => columnMapped.table.target === node.tableName && columnMapped.columnTitle.target === node.column.column_header).length > 0) {
                    this.getTargetVariableValueWithCarryForward(node, this.props.columnMappedList.filter(columnMapped => columnMapped.table.target === node.tableName && columnMapped.columnTitle.target === node.column.column_header)[0].node.target, node.tableName);
                  }  else {
                    axios.all([this.fecthPreference({'Tenant-ID': tenantId}, {'columnName':node.column.column_header,'tableName':node.tableName})])
                        .then(axios.spread((response) => {
                          if (typeof(response.data) !== 'undefined' && typeof(response.data.distinctValues) !== 'undefined' && response.data.distinctValues.length > 0) {
                            response.data.distinctValues.sort((a,b) => ('' + a).localeCompare(b) ).filter(columnValue =>  columnValue !== '').forEach((columnValue, index) => {
                                if (node.children.length < 10 || node.cssClassName === 'manage-target-table-variable') {
                                  let columnValueId = node.id.split('-');
                                  let rootType = columnValueId[columnValueId.length - 2];
                                  columnValueId = columnValueId.join('-') + '-'+ rootType + '-' + index;
                                  node.children.push(this.manageColumnValueInTreeView(columnValue, node.tableName, node.column.column_header, node.column, columnValueId, isChildDragable))
                                }
                            })
                          }

                          if (node.isVariableValueNode === true && typeof(node.children) !== 'undefined' && node.children.length > 0 && typeof(this.props.applyProcessFlowList) !== 'undefined' && this.props.applyProcessFlowList.length > 0) {
                            this.props.applyProcessFlowList.filter(flow => (flow.id === node.id || (typeof(flow.selectedTargetColumnList) !== 'undefined' && flow.selectedTargetColumnList.indexOf(node.column.column_header) > -1)) && flow.tableName === node.tableName && typeof(flow.selectedTargetColumnValueList) !== 'undefined').forEach(flow => {
                              node.children.map(columnValue => {
                                const selectedTargetColumnValue = flow.selectedTargetColumnValueList.filter(selectedTargetColumnValue => selectedTargetColumnValue.valueList.indexOf(columnValue.name) > -1)
                                if (selectedTargetColumnValue.length > 0) {
                                  node.children.filter(setValueNode => typeof(setValueNode.isSetValue) !== 'undefined' && setValueNode.isSetValue === true && setValueNode.name === selectedTargetColumnValue[0].setValueName && typeof(setValueNode.children) !== 'undefined' && setValueNode.children.filter(valueNode => valueNode.name === columnValue.name).length === 0).map(setValueNode => {
                                    setValueNode.children.push(columnValue)
                                    node.children = node.children.filter(valueNode => valueNode.name !== columnValue.name)
                                    return setValueNode
                                  })
                                }
                                return columnValue;
                              })
                            })
                          } else if (node.isVariableValueNode === true && typeof(node.selectedTargetColumnValueList) !== 'undefined' && node.selectedTargetColumnValueList.length > 0) {
                            node.children.map(columnValue => {
                              const selectedTargetColumnValue = node.selectedTargetColumnValueList.filter(selectedTargetColumnValue => selectedTargetColumnValue.valueList.indexOf(columnValue.name) > -1)
                              if (selectedTargetColumnValue.length > 0) {
                                node.children.filter(setValueNode => typeof(setValueNode.isSetValue) !== 'undefined' && setValueNode.isSetValue === true && setValueNode.name === selectedTargetColumnValue[0].setValueName && typeof(setValueNode.children) !== 'undefined' && setValueNode.children.filter(valueNode => valueNode.name === columnValue.name).length === 0).map(setValueNode => {
                                  setValueNode.children.push(columnValue)
                                  node.children = node.children.filter(valueNode => valueNode.name !== columnValue.name)
                                  return setValueNode
                                })
                              }
                              return columnValue;
                            })
                          }
                          node.fetchColumnValue = false;
                          this.setState({ cursor: node }, this.modifyDrawLine());
                          $(".loader").hide();
                        })
                      ).catch(error => {
                        this.props.setMessage("Error occurred while fetching values", MessageTypeConst.ERROR_MESSAGE);
                      });
                  }


                } else {
                    this.setState({ cursor: node }, this.modifyDrawLine());
                }

              } else {
                this.setState({ cursor: node }, this.modifyDrawLine());
              }
              let findAnnotateWord = typeof(node.id) !== 'undefined' ? node.id.split('_annotate') : [];
              let annotateTreeView = findAnnotateWord[findAnnotateWord.length - 1] === '' ? true : false;
              if (typeof(node.whereCondition) !== 'undefined' && annotateTreeView === false && node.id.indexOf('-source_design') === -1) {
                let { nodeList } = DrawColumnMappingLine.getState();

                let tableNodeList = nodeList.filter(mappedcolumn => mappedcolumn.table.source === node.tableName || mappedcolumn.table.target === node.tableName);
                if (node.toggled === false && tableNodeList.length > 0) {
                  if (node.cssClassName.indexOf(' highlight-table') === -1) {
                    node.cssClassName = node.cssClassName + ' highlight-table';
                  }
                } else {
                  node.cssClassName = node.cssClassName.replace(new RegExp(' highlight-table', 'gi'), '').trim();
                }
              }

              if (annotateTreeView === false) {
                this.setState({ cursor: node }, this.modifyDrawLine());
              } else {
                this.setState({ cursor: node });
              }

              sideBarEventTimeOutId = null
            }, delay);
        } else {
            sideBarEventTimeOutId = clearTimeout(sideBarEventTimeOutId);

            if (node.cssClassName === 'manage-variable-set-value' && typeof(node.isSelected) !== 'undefined') {
              node.isSelected = true;
              let treeView = this.state.treeView;

              treeView.children.filter(setValueNode => setValueNode.id !== node.id && setValueNode.isSetValue === true && setValueNode.isSelected === true).map(setValueNode => setValueNode.isSelected = false)
              this.setState({treeView: treeView})
              this.props.reloadAssignProcessFlow(node)
            }

            //select value mapping
            if(node.tag && node.tag === 'select-drop-down' && node.tableName  && node.tableName !== "" && node.type === 'metadata'){
              MappedColumnValue.populateSelectedTargetMapping(node);
            }

            if (node.type === 'source' && typeof(node.isBlink) !== 'undefined') {
                let treeElement = this.props.treeElement;
                let { nodeList } = DrawColumnMappingLine.getState();

                treeElement.sourceTableTree.children.map((table) => {
                  table.children.map((column) => {
                      column.isBlink = nodeList.filter((columnMap) => columnMap.node.source === node.id && column.id === node.id).length > 0 ? true : false;
                      return column
                    })

                  return table;
                });

                treeElement.metadataTableTree.children.map((table) => {
                  table.children.map((column) => {
                      column.isBlink = nodeList.filter((columnMap) => columnMap.node.target === column.id && columnMap.node.source === node.id).length > 0 ? true : false;
                      return column
                    })

                  return table;
                });

                this.props.updateTreeElementViaImmutable(treeElement);
            }

            if (typeof(node.id) !== 'undefined' && node.id.indexOf('tablelist-') > -1 && typeof(node.isSelected) !== 'undefined') {
              let treeView = this.state.treeView;
              treeView.children.map(tableNode => {
                tableNode.children.map(tableChild => {
                  tableChild.isSelected = false;
                  return tableChild
                })
                return tableNode;
              })
              node.isSelected = true
              this.setState({treeView: treeView}, () => {
                this.props.handleTLFTableSelectClick(node.id);
              })
            }
            this.setState({ cursor: node });
        }

  }

  modifyDrawLine = () => {
    if (typeof(this.state.id) === 'undefined' || this.state.id.indexOf('standard-treeview') === -1) {
      setTimeout(()=>{
        DrawColumnMappingLine.handleScrollCallback();
      },1000)
    }
  }

  manageColumnValueInTreeView = (columnValue, tableName, columnName, parentColumn, id=null, isChildDragable = false) => {
    if (id === null) {
      id = tableName+'-'+columnName+'-'+columnValue + '-' +Math.ceil(Math.random()*10000);
    }
    return {
      name: columnValue,
      title: columnValue,
      cssClassName: 'manage-table-column-value-name',
      iconClass: 'fa fa-list-alt',
      id: id,
      draggable: isChildDragable,
      tableName: tableName,
      columnName: columnName,
      parentColumn: parentColumn
    }
  }

  startDrawLine = (e) => {
    let target =  e.target;
    let element = getDimensions(target);
    let annotateTreeView = false;

    e.dataTransfer.setData('sourceId', target.id);
    if (typeof(target.id) !== 'undefined' && target.id !== '') {
        annotateTreeView = target.id.includes('_annotate');
        if(target.id.indexOf('-rightPersistent-') > 0 || target.id.indexOf('-targetValue-') > 0 || (annotateTreeView === false && (target.id.indexOf('-metadata-') > 0 || target.id.indexOf('-metadata') > 0)) || (annotateTreeView === true && target.id.indexOf('-metadata-') === -1)) {
            return false;
        }
    } else if (target.id === '') {
      return false;
    }

    if (annotateTreeView === false && target.id.indexOf('-source_design') === -1) {
      let line = { startX: 0, startY: 0};
      let offset = wrapperOffsetElement();
      let x = element.right - offset.left + 10;
      let y = element.y - offset.top + (element.height/2);
      let source = target.id;
      let sourceColumn = (typeof($(target).attr('column')) !== 'undefined' && $(target).attr('column') !== '') ? JSON.parse($(target).attr('column')) : {};
      let sourceTable = $(target).attr('table');
      let sourceColumnValue = $(target).attr('column-value');
      let sourceParentColumn = (typeof($(target).attr('parent-column')) !== 'undefined' && $(target).attr('parent-column') !== '') ? JSON.parse($(target).attr('parent-column')) : {};
      let sourceColumnTitle = $(target).attr('column-title');

      if (typeof(sourceColumnValue) !== 'undefined' && sourceColumnValue !== '') {
        sourceColumnValue = sourceColumnValue.split(':-:-:');
      } else {
        sourceColumnValue = [];
      }

      line.startX = x;
      line.startY = y;
      line.stopX = x;
      line.stopY = y;

      DrawColumnMappingLine.setLineState(line, {source: source, target: null}, {source: sourceColumn, target: null }, {source: sourceTable, target: null }, {source: sourceColumnValue, target: [] }, {source: sourceParentColumn, target: null }, {source: sourceColumnTitle, target: null });
    } else {
      let columnTitle;

      if(target.id.split('_annotate')[1] !== ''){
        columnTitle = e.target.getBoundingClientRect();
      }else{
        columnTitle = e.target.getElementsByClassName("column-title")[0].getBoundingClientRect();
      }
      e.dataTransfer.setData('element', target.id);
      e.dataTransfer.setData('height', target.clientHeight);
      e.dataTransfer.setData('width', columnTitle.width);
      e.dataTransfer.setData('title', target.getAttribute("column-title"));
    }
  }

  drawLine = (e) => {
    $('.react-contextmenu.react-contextmenu--visible[role="menu"]').each(function(){
      $(this).removeClass('react-contextmenu--visible').addClass('hidden');
    });
    let target = e.target;
    let annotateTreeView = false;

    if (typeof(target.id) !== 'undefined' && target.id !== '') {
        let findAnnotateWord = typeof(target.id) !== 'undefined' ? target.id.split('_annotate') : [];
        annotateTreeView = findAnnotateWord[findAnnotateWord.length - 1] === '' ? true : false;
        if(target.id.indexOf('-metadata') > 0 || target.id.indexOf('-rightPersistent-') > 0 || target.id.indexOf('-targetValue') > 0) {
            return false;
        }
    } else if (target.id === '') {
      return false;
    }

    if (annotateTreeView === false && target.id.indexOf('-source_design') === -1) {
      let line = { stopX: 0, stopY: 0};
      let offset = wrapperOffsetElement();
      let drawlineLimit = drawLineElementLimit(e.target);

      line.stopX = e.clientX - offset.left;
      line.stopY = e.clientY - offset.top;

      if (typeof(drawlineLimit) !== 'undefined' && typeof(drawlineLimit.height) !== 'undefined' && (drawlineLimit.height + drawlineLimit.top - offset.top)  < line.stopY) {
        line.stopY = drawlineLimit.height + drawlineLimit.top - offset.top;
      }

      if (line.stopY < (drawlineLimit.top - offset.top + 5)) {
        line.stopY = drawlineLimit.top - offset.top + 5;
      }

      DrawColumnMappingLine.drawLine(line);
    }
  }

  dragOverLine = (e) => {
    let target = e.target;
    let element = getDimensions(target);
    let line = { stopX: 0, stopY: 0};
    let offset = wrapperOffsetElement();

    if(target.id.indexOf('-metadata') > 0 || target.id.indexOf('-rightPersistent-') > 0 || target.id.indexOf('-targetValue') > 0 || target.id === '') {
        return false;
    }

    line.stopX = element.left - 30;
    line.stopY = element.y - offset.top + (element.height/2);

    DrawColumnMappingLine.drawLine(line);
  }

  endDropLine = (e) => {
    let target =  $(e.target).parent()[0];
    if (target.id === '') {
      target = $(target).parents('.data-div')[0];
    }
    let targetParentColumn = (typeof($(target).attr('parent-column')) !== 'undefined' && $(target).attr('parent-column') !== '') ? JSON.parse($(target).attr('parent-column')) : {};
    if(typeof(target) === "undefined" || typeof(target.id) === "undefined" || (target && target.id && target.id.indexOf('_annotate') > 0) || (target.id.indexOf('-targetValue') > 0 && typeof(targetParentColumn.column_header) === 'undefined') || !(target.id.indexOf('-metadata') > 0 || target.id.indexOf('-target') > 0)){
      return false;
    }

    let { nodeList, node, table } = DrawColumnMappingLine.getState();

    if (node.source === null && node.target === null ) {
      let treeView = this.state.treeView;

      if (treeView.isVariableValueNode === true) {
        const sourceId = e.dataTransfer.getData('sourceId');
        let sourceNode = cloneDeep(treeView.children.find(setValueNode => setValueNode.id === sourceId));

        if (typeof(sourceNode) === 'undefined') {
          treeView.children.forEach(setValueNode => {
            if (typeof(setValueNode.children) !== 'undefined' && setValueNode.children.filter(valueNode => valueNode.id === sourceId).length > 0) {
              sourceNode = cloneDeep(setValueNode.children.find(valueNode => valueNode.id === sourceId));
            }
          })
        }
        if (typeof(sourceNode) !== 'undefined' && typeof(sourceNode.id) !== 'undefined') {
          if (treeView.children.filter(setValueNode => setValueNode.id === sourceId).length > 0) {
            treeView.children = treeView.children.filter(setValueNode => setValueNode.id !== sourceId);
          } else {
            treeView.children.filter(setValueNode => typeof(setValueNode.children) !== 'undefined' && setValueNode.children.filter(valueNode => valueNode.id === sourceId).length > 0).map(setValueNode => {
              setValueNode.children = setValueNode.children.filter(valueNode => valueNode.id !== sourceId)
              return setValueNode
            })
          }

          if (treeView.id === target.id) {
            treeView.children.push(sourceNode)
          } else if (treeView.children.filter(setValueNode => setValueNode.id === target.id).length > 0) {
            treeView.children.filter(setValueNode => setValueNode.id === target.id).map(setValueNode => {
              if (typeof(setValueNode.children) !== 'undefined') {
                setValueNode.children.push(sourceNode);
              } else {
                treeView.children.push(sourceNode)
              }

              return setValueNode
            })
          } else {
            treeView.children.map(setValueNode => {
              if (typeof(setValueNode.children) !== 'undefined' && setValueNode.children.filter(valueNode => valueNode.id === target.id).length > 0) {
                setValueNode.children.push(sourceNode);
              }
              return setValueNode;
            })
          }

          this.setState({treeView: treeView});
        }

      }
    }
    if (node.source === null) {
      return false;
    }
    let mappedNode = nodeList.filter(mappedNode => mappedNode.node.target === target.id && mappedNode.node.source === node.source)
    if (mappedNode.length > 0) {
      return false;
    }

    let element = getDimensions(target);
    let line = { stopX: 0, stopY: 0};
    let offset = wrapperOffsetElement();
    let targetColumn = (typeof($(target).attr('column')) !== 'undefined' && $(target).attr('column') !== '') ? JSON.parse($(target).attr('column')) : {};
    let targetTable = $(target).attr('table');
    let targetColumnValue = $(target).attr('column-value');
    let targetColumnTitle = $(target).attr('column-title');
    let targetTableNodes = []
    if (typeof(targetColumnValue) !== 'undefined' && targetColumnValue !== '') {
      targetColumnValue = targetColumnValue.split(':-:-:');
    } else {
      targetColumnValue = [];
    }

    if (typeof(this.props.treeElement.metadataTableTree) !== 'undefined') {
      targetTableNodes = this.props.treeElement.metadataTableTree.children.filter(metadataTable => metadataTable.tableName === targetTable);
    }

    /*if (targetTableNodes.length === 0) {
      targetTableNodes = this.props.treeElement.rightPersistentTableTree.children.filter(rightPersistentTable => rightPersistentTable.tableName === targetTable);
    }*/

    if (targetTableNodes.length > 0) {
      let targetColumnNode = targetTableNodes[0].children.filter(columnNode => columnNode.id === target.id);

      if (targetColumnNode.length > 0 && (targetColumnNode[0].hardcode === true || targetColumnNode[0].expressionIntermediate === true)) {
        let columnType = 'Hardcode';

        if (targetColumnNode[0].expressionIntermediate === true) {
          columnType = 'Intermediate';
        }

        this.props.setMessage(columnType+" Column can't be map.",MessageTypeConst.WARNING_MESSAGE);

        return false;
      }
    }

    if (typeof(targetColumn.column_header) !== 'undefined' && typeof(targetTable) !== 'undefined' && typeof(table.source) !== 'undefined' && this.props.pivotMatrixList.filter(pivotMatrix => pivotMatrix.rows.length > 0 && pivotMatrix.sourceTable === table.source && pivotMatrix.tableName === targetTable && pivotMatrix.rows.filter(row => row.targetColumn === targetColumn.column_header).length > 0).length > 0) {
      this.props.setMessage("'"+targetColumn.column_header+"' variable is used in pivot metrics. Please use pivot metrics feature to map variables.",MessageTypeConst.WARNING_MESSAGE);
      return false;
    }


    line.stopX = element.left - 30;
    line.stopY = element.y - offset.top + (element.height/2);

    if (target !== '') {
        let retainSourceTableList = [];
        let targetCustomValueList = []
        nodeList.filter(mappedValue => mappedValue.targetId === target.id).forEach(mappedValue => {
          if (retainSourceTableList.indexOf(mappedValue.table.source) === -1 && this.props.expressionList.filter(expression => expression.id === target.id && expression.expressionDetail.filter(tableExpression => tableExpression.tableName === mappedValue.table.source && (tableExpression.tableOperation === 'Pivot' || (tableExpression.tableOperation === 'No Pivot' && table.source !== mappedValue.table.source))).length > 0).length > 0) {
            retainSourceTableList.push(mappedValue.table.source)
          }

          if (mappedValue.customValue === true && targetCustomValueList.indexOf(mappedValue.columnTitle.target) === -1) {
            targetCustomValueList.push(mappedValue.columnTitle.target)
          }
        })

        let customValue = false;
        if (typeof(this.state.treeView.children) !== 'undefined') {
          this.state.treeView.children.forEach(tableNode => {
            if (typeof(tableNode.children) !== 'undefined' && tableNode.children.length > 0) {
              tableNode.children.forEach(variableNode => {
                if (typeof(variableNode.children) !== 'undefined' && variableNode.children.length > 0) {
                  variableNode.children.filter(variableValueNode => variableValueNode.id ===  target.id && typeof(variableValueNode.customValue) !== 'undefined').forEach(variableValueNode => {
                    customValue = variableValueNode.customValue;
                  })
                }
              })
            }
          })
        }
        DrawColumnMappingLine.endDropLine(line, target.id, targetColumn, targetTable, targetColumnValue, targetParentColumn, this.props.columnMappingTargetId, targetColumnTitle, retainSourceTableList, customValue);

        setTimeout(()=>{
          let { nodeList } = DrawColumnMappingLine.getState();

          if (nodeList.length > 0 && typeof(nodeList[nodeList.length - 1].mappingType) !== 'undefined') {
            let treeView = this.state.treeView;
            let lastNode = nodeList[nodeList.length - 1];
            let isValueNode = false;

            if (typeof(lastNode.mappingType) !== 'undefined') {
              isValueNode = lastNode.mappingType.split('-')[0] === 'value' ? true : false;
            }

            treeView.children.map((table) => {
              if ((lastNode.mappingType === 'column-table' || lastNode.mappingType === 'value-table') && table.tableName === lastNode.table.target) {
                table.carryForward = true;
                table.removeMappedLine = true;
              }
              table.children.map((column) => {
                if (column.id === target.id) {
                  if (column.cssClassName.indexOf('contextmenu-hardcode-expression') > -1) {
                    column.cssClassName = column.cssClassName.replace('contextmenu-hardcode-expression', 'contextmenu-expression');
                  } else if (column.cssClassName.indexOf('contextmenu-hardcode') > -1) {
                    column.cssClassName = column.cssClassName.replace('contextmenu-hardcode', 'contextmenu-expression');
                  } else if (column.cssClassName.indexOf('contextmenu-intermediate-expression') > -1) {
                    column.cssClassName = column.cssClassName.replace('contextmenu-intermediate-expression', 'contextmenu-expression');
                  }

                  column.removeMappedLine = true;
                  column.highlight = false;
                  if (isValueNode === false) {
                    column.expression = true;
                    column.columnStatus = nodeList.filter(node => node.mappingType === 'column-column' && node.node.target === target.id && eval(node.column.source.column_data_length) > eval(column.column.column_data_length)).length > 0 ? 'yellow' : column.columnStatus
                    if (typeof(column.columnCTStatus) !== 'undefined' && column.columnCTStatus !== '') {
                      column.columnStatus = column.columnCTStatus === 'No' ? 'red' : 'yellow'
                    }
                  }
                }

                if (typeof(column.children) !== 'undefined' && column.children.length > 0) {
                  column.children.forEach((columnValue) => {
                    if (columnValue.id === target.id) {
                      columnValue.removeMappedLine = true;
                    }
                  })
                }

                return column;
              })

              return table;
            });
            let treeElement = this.props.treeElement;
            treeElement.sourceTableTree.children.map((table) => {
              if (table.tableName === lastNode.table.source) {
                table.children.map((column) => {
                  if (column.id === lastNode.node.source) {
                    column.highlight = false;
                  }
                  return column
                })
              }
              return table;
            });

            if (isValueNode === true) {
              treeElement.metadataTableTree.children.map((table) => {
                if (table.tableName === lastNode.table.target) {
                  table.children.map((column) => {
                    if (typeof(column.columnCTStatus) !== 'undefined' && column.id === lastNode.targetId ) {
                      if (column.columnCTStatus === 'Yes') {
                        column.columnStatus = '';
                      } else if (column.columnCTStatus === 'No') {
                        let { sourceValueTree } = MappedColumnValue.getMappedColumnValueState();
                        let totalValueCount = 0;
                        sourceValueTree.children.forEach(table => {
                          table.children.forEach((column, i) => {
                            totalValueCount += column.children.length;
                          });
                        })

                        if (totalValueCount === nodeList.filter(node => node.targetId === lastNode.targetId).length) {
                          column.columnStatus = '';
                        }
                      }

                      if (lastNode.customValue === true) {
                        MappedColumnValue.saveCustomUnmapValueList(column.tableName, column.column.column_header, lastNode.columnTitle.target, 'remove')
                      }
                    }
                    return column
                  })
                }
                return table;
              });
            }
            /*treeElement.leftPersistentTableTree.children.forEach((table) => {
              if (table.tableName === lastNode.table.source) {
                table.children.forEach((column) => {
                  if (column.id === lastNode.node.source) {
                    column.highlight = false;
                  }
                })
              }
            });*/

            targetCustomValueList.forEach(targetCustomValue => {
              if (nodeList.filter(node => node.customValue === true && node.targetId === target.id && node.mappingType === 'value-value' && node.columnTitle.target === targetCustomValue).length === 0) {
                  MappedColumnValue.saveCustomUnmapValueList(targetTable, targetColumn.column_header, targetCustomValue)
              }
            })


            this.props.updateTreeElementViaImmutable(treeElement);

            if (isValueNode === false) {
              this.addMultipleTargetColumnExpression([target.id], null, [], false, false);
            }

            this.setState({treeView: treeView});
          }
        },1)

    } else {
        DrawColumnMappingLine.setLineState({startX: 0, startY: 0, stopX: 0, stopY : 0}, {source: null, target: null}, {source: null, target: null}, {source: null, target: null}, {source: [], target: []}, {source: null, target: null}, {source: null, target: null});
    }

  }

  endDrawLine = (e) => {
    let { sourceTableTree, metadataTableTree } = this.props.treeElement;

    if (typeof(metadataTableTree) === 'undefined') {
      return true;
    }
    DrawColumnMappingLine.setLineState({startX: 0, startY: 0, stopX: 0, stopY : 0}, {source: null, target: null}, {source: null, target: null}, {source: null, target: null}, {source: [], target: []}, {source: null, target: null}, {source: null, target: null});

    let { nodeList, lineDraw } = DrawColumnMappingLine.getState();

    let sortNodeList = [];
    let uniqueTargetList = [];
    nodeList.forEach((node) => {
      let nodeTarget = uniqueTargetList.filter(uniqueTarget => uniqueTarget.target === node.node.target);

      if (nodeTarget.length > 0) {
        nodeTarget[0].totalMappedSource++;
      } else {
        uniqueTargetList.push({ target : node.node.target, totalMappedSource: 1 })
      }
    })

    uniqueTargetList.sort((a,b) => {
        if (a.totalMappedSource > b.totalMappedSource)
          return -1;
        if (a.totalMappedSource < b.totalMappedSource)
          return 1;
        return 0;
    })

    uniqueTargetList.forEach((uniqueTarget) => {
      sortNodeList.push(...nodeList.filter( node => node.node.target === uniqueTarget.target))
    })

    sortNodeList.map((sortNode) => {
      let sourceTable = sourceTableTree.children.filter(table => table.tableName === sortNode.table.source);
      let targetTable = metadataTableTree.children.filter(table => table.tableName === sortNode.table.target);

      /*if (sourceTable.length === 0) {
        sourceTable = leftPersistentTableTree.children.filter(table => table.tableName === sortNode.table.source);
      }

      if (targetTable.length === 0) {
        targetTable = rightPersistentTableTree.children.filter(table => table.tableName === sortNode.table.source);
      }*/

      return Object.assign(sortNode, {sortOrder : {source: sourceTable.length > 0 ? sourceTable[0].sortOrder : 0, target: targetTable.length > 0 ? targetTable[0].sortOrder : 0}})
    })

    sortNodeList.sort((a,b) => {
        if (a.sortOrder.target < b.sortOrder.target)
          return -1;
        if (a.sortOrder.target > b.sortOrder.target)
          return 1;
        return 0;
    })

    sortNodeList.sort((a,b) => {
        if (a.sortOrder.target === b.sortOrder.target) {
          if (this.props.renameColumnList.filter(renameColumn => renameColumn.id === a.node.source) > this.props.renameColumnList.filter(renameColumn => renameColumn.id === b.node.source))
            return -1
        }
        return 0;
    })

    if (lineDraw === true) {
      this.props.manageMappedColumnList(sortNodeList);
    }
  }

  handleWhereConditionClick = (e, data) => {
    if (typeof(data) !== 'undefined') {
      let whereCondition = this.props.whereConditionList.filter((whereCondition) => whereCondition.id === data.node.id );
      let tableWhereCondition = this.state.tableWhereCondition;
      if (whereCondition.length > 0) {
        tableWhereCondition = whereCondition[0].value;
      }
      this.setState({ visibleAddWhereCondition: !this.state.visibleAddWhereCondition, selectNode: data.node, tableWhereCondition: tableWhereCondition });
    } else {
      this.setState({ visibleAddWhereCondition: !this.state.visibleAddWhereCondition, selectNode:{}, tableWhereCondition: '' });
    }
  }

  handleWhereConditionChange = (e) => {
    this.setState({ tableWhereCondition: e.target.value });
  }

  handleAddWhereConditionClick = (e) => {
    let treeView = this.state.treeView;

    treeView.children.forEach((table) => {
      if (table.id === this.state.selectNode.id) {

        if (this.state.tableWhereCondition !== '') {
          table.whereCondition = true;

          this.props.addWhereCondition({id: this.state.selectNode.id , tableName:this.state.selectNode.tableName, value: this.state.tableWhereCondition });
        } else {
          table.whereCondition = false;
          this.props.removeWhereCondition(this.state.selectNode.id);
        }
      }
    });

    this.setState({ visibleAddWhereCondition: !this.state.visibleAddWhereCondition, selectNode: {}, tableWhereCondition: '', treeView: treeView });
  }

  handleHardcodeClick = (e, data, type) => {
    if (typeof(data) !== 'undefined') {
      let hardcode = this.props.hardcodeList.filter((hardcode) => hardcode.id === data.node.id );
      if (hardcode.length > 0) {
        hardcode = Object.assign({}, hardcode[0].hardcode);
      } else {
        hardcode = this.state.hardcode;
      }

      hardcode.type = type;

      this.setState({ visibleAddHardcode: !this.state.visibleAddHardcode, selectNode: data.node, hardcode: hardcode });
    } else {
      this.setState({ visibleAddHardcode: !this.state.visibleAddHardcode, selectNode:{}, hardcode: {type: 'Hardcode', intermediateColumn: '', columnHardcode: ''} });
    }
  }

  handleHardcodeChange = (e) => {
    let hardcode = this.state.hardcode;
    hardcode.columnHardcode = e.target.value !== null ? e.target.value : '';

    this.setState({ hardcode: hardcode });
  }

  handleAddHardcodeClick = (e) => {
    let treeView = this.state.treeView;
    let isvalid = true;
    treeView.children.forEach((table) => {
      table.children.forEach((column) => {
        if (column.id === this.state.selectNode.id) {

          if (this.state.hardcode.columnHardcode.trim() !== '') {
            if (this.state.hardcode.type === 'Hardcode') {
              column.hardcode = true;
            } else if (this.state.hardcode.type === 'Expression') {
              column.expressionIntermediate = true;
            }
            if(this.state.hardcode.type === 'Expression' && this.state.hardcode.intermediateColumn.trim() === "")
            {
              column.expressionIntermediate = false;
              isvalid = false;
              this.props.setMessage("Intermediate Column can't be blank.",MessageTypeConst.WARNING_MESSAGE);
              return;
            }else{
              if (this.state.hardcode.type === 'Hardcode' && column.columnCTStatus === 'No') {
                column.columnStatus = 'red';
              }
              this.props.addHardcode({
                ...this.state.selectNode,
                columnName: this.state.selectNode.name,
                hardcode: this.state.hardcode,
                column: this.state.selectNode.column.column_header
              });
            }
          } else {
            if (this.state.hardcode.type === 'Hardcode') {
              column.hardcode = false;
            } else if (this.state.hardcode.type === 'Expression') {
              column.expressionIntermediate = false;
            }
            if (this.state.hardcode.type === 'Hardcode' && column.columnCTStatus === 'No') {
              column.columnStatus = '';
            }
            this.props.removeHardcode(this.state.selectNode.id);
          }
          if(column.hardcode){
            column.cssClassName = column.type+'-contextmenu-hardcode';
          }else if(column.expressionIntermediate){
            column.cssClassName = column.type+'-contextmenu-intermediate-expression';
          }else{
            column.cssClassName = column.type+'-contextmenu-hardcode-expression';
          }
        }
      })

    });
    if(isvalid){
      this.setState({ visibleAddHardcode: !this.state.visibleAddHardcode, selectNode: {}, hardcode: {type: 'Hardcode', intermediateColumn: '', columnHardcode: ''}, treeView: treeView });
    }
  }

  handleExpressionClick = (e, data) => {
    let title = "Expression";
    if(typeof(data) !== "undefined"){
      let treeView = this.state.treeView;
      let tableName = data.node.tableName;
      let selectedTable =  treeView.children.filter(table => table.tableName === tableName);
      if(selectedTable.length > 0){
        tableName = selectedTable[0].name;
      }
      title = title + " - "+tableName+" [" + data.node.title + "]"
    }
    this.setState({ visibleAddExpression: !this.state.visibleAddExpression, expressionTitle : title, selectNode: (typeof(data) !== 'undefined'? data.node : []) });
  }

  triggerExpressClick = (status) => {
    let treeView = this.state.treeView;

    treeView.children.forEach((table) => {
      table.children.forEach((column) => {
        if (column.id === this.state.selectNode.id) {
          column.expression = status;
        }
      })
    });

    this.setState({ visibleAddExpression: !this.state.visibleAddExpression, selectNode: {}, treeView: treeView });
  }

  handleRenameColumnClick = (e, data) => {
    let renameColumn = this.state.renameColumn;
    let selectNode = typeof(data) !== 'undefined'? data.node : {};

    if (typeof(selectNode.title) !== 'undefined') {
      let renameColumnList = this.props.renameColumnList.filter(row => row.id === selectNode.id);

      if (renameColumnList.length > 0 && selectNode.renameColumn) {
        renameColumn = Object.assign({}, renameColumnList[0].renameColumn);
      } else {
        renameColumn.column = selectNode.title
        renameColumn.label = selectNode.label
        renameColumn.tableName = data.node.tableName;
      }
    }

    this.setState({ visibleAddRenameColumn: !this.state.visibleAddRenameColumn, renameColumn: renameColumn, selectNode: selectNode });
  }

  handleRenameColumnTextChange = (e, type) => {
    let renameColumn = this.state.renameColumn;

    if (type === 'column') {
      renameColumn.column = e.target.value;
    } else {
      renameColumn.label = e.target.value;
    }

    this.setState({ renameColumn: renameColumn });
  }

  handleAddRenameColumnClick = () => {
    let renameColumn = this.state.renameColumn;
    let treeView = this.state.treeView;

    treeView.children.forEach((table) => {
      table.children.forEach((column) => {
        if (column.id === this.state.selectNode.id) {
          let renameColumnList = this.props.renameColumnList;

          if (renameColumn.column.trim() !== '' && this.state.selectNode.title !== renameColumn.column) {
            column.renameColumn = true;
            this.props.addRenameColumn({id: this.state.selectNode.id, renameColumn: renameColumn})
            renameColumnList.push({id: this.state.selectNode.id, renameColumn: renameColumn});
          } else {
            column.renameColumn = false;
            this.props.removeRenameColumn(this.state.selectNode.id)

            renameColumnList = renameColumnList.filter(renameColumnDetail => renameColumnDetail.id !== this.state.selectNode.id)
          }

          let columnMappedList = this.props.columnMappedList.filter(columnMapped => columnMapped.node.source === this.state.selectNode.id);
          let targetIdList = [];

          columnMappedList.forEach((columnMapped) => {
            targetIdList.push(columnMapped.node.target);
          })

          this.addMultipleTargetColumnExpression(targetIdList, renameColumnList, [], true)
        }
      })
    });

    this.setState({ visibleAddRenameColumn: !this.state.visibleAddRenameColumn, selectNode: {}, renameColumn: {tableName: '', column: '', label: ''}, treeView: treeView });
  }

  handleHardcodeTypeChange = (event) => {
    let hardcode = this.state.hardcode;
    hardcode.type = event.value

    this.setState({hardcode: hardcode})
  }

  handleIntermediateColumnChange = (e) => {
    let hardcode = this.state.hardcode;
    hardcode.intermediateColumn = e.target.value !== null ? e.target.value : '';

    this.setState({ hardcode: hardcode });
  }

  handleRemoveMappedColumnClick = (e, id, isValueRemove = false) => {
    let targetId = typeof(id) !== 'undefined' ? id : null;
    let targetMappedColumn = this.state.targetMappedColumn;
    let tableList = [];

    if (targetId !== null) {
      let { nodeList } = DrawColumnMappingLine.getState();
      let sourceColumnList = nodeList.filter((columnMap) => columnMap.node.target === targetId);

      sourceColumnList.forEach((columnMapped) => {
        let sourceElement = '';
        let sourceColumnNode = columnMapped.node.source;
        if (columnMapped.mappingType !== '') {
          let columnType = columnMapped.mappingType.split('-');

          if (columnType[0] === 'column' && typeof(columnMapped.column.source.column_header) !== 'undefined') {
            sourceElement = columnMapped.table.source + '->' + columnMapped.column.source.column_header;
          } else if(columnType[0] === 'value' && typeof(columnMapped.parentColumn.source.column_header) !== 'undefined')  {
            sourceElement = columnMapped.table.source + '->' + columnMapped.parentColumn.source.column_header + '->' + columnMapped.columnTitle.source;
          } else if(columnType[0] === 'value') {
            sourceElement = columnMapped.table.source + '->' + columnMapped.parentColumn.source + '->' + columnMapped.columnTitle.source
          }
        }

        let table = tableList.filter(table => table.tableName === columnMapped.table.source);
        let isPivot = this.props.pivotMatrixList.filter(pivotMatrix => pivotMatrix.rows.length > 0 && pivotMatrix.sourceTable === columnMapped.table.source && pivotMatrix.tableName === columnMapped.table.target && pivotMatrix.rows.filter(row => row.targetColumn === columnMapped.columnTitle.target).length > 0).length > 0

        if (table.length > 0) {
          table[0].children.push({sourceElement: sourceElement, sourceColumnNode: sourceColumnNode, status: true, isPivot: isPivot});
        } else {
          tableList.push({tableName: columnMapped.table.source,children:[{sourceElement: sourceElement, sourceColumnNode: sourceColumnNode, status: true, isPivot: isPivot}]})
        }
      })
    }

    targetMappedColumn.targetId = targetId;
    targetMappedColumn.tableList = tableList;
    this.setState({ visibleRemoveMappedColumn: isValueRemove === false ? !this.state.visibleRemoveMappedColumn : false, targetMappedColumn: targetMappedColumn }, () => {
      if (isValueRemove === true) {
        this.handleRemoveMappedLineClick(e, isValueRemove);
      }

    });
  }

  handleRemoveApplyProcessFlowClick = (e, node) => {
    let that = this;
    ConfirmBox.open("Are you sure you want to delete?").then((data) => {
      if (typeof(node.isVariableValueNode) === 'undefined') {
          that.props.removeApplyProcessFlow({...node})
      }
      let treeView = that.state.treeView;
      treeView.children.forEach((table) => {
        table.children.forEach((column) => {
          if (column.id === node.id) {
            column.applyProcessFlow = false;
          }
        })

        if (treeView.id === 'variable-value' && table.id === node.id) {
          table.applyProcessFlow = false;
          table.toggled = node.toggled
        }
      });

      that.setState({ treeView: treeView })
    })
  }

  handleRemoveMappedLineClick = (e, isValueRemove = true) => {
    let { targetId, tableList } = this.state.targetMappedColumn;
    let columnTargetId = '';
    let columnTargetTableName = ''
    const isHighlightVariable = document.querySelectorAll('.js-highlight-button').length > 0 && document.querySelectorAll('.js-highlight-button')[0].textContent === 'Clear Unmap'
    if (targetId !== '' && typeof(targetId) !== 'undefined') {
      let { nodeList } = DrawColumnMappingLine.getState();
      let targetNodeList = nodeList.filter(columnMapped => columnMapped.node.target === targetId || columnMapped.targetId === targetId);
      if (targetId.indexOf('targetValue-') > 0) {
        columnTargetId = targetNodeList[0].targetId;
        columnTargetTableName = targetNodeList[0].table.target;
      } else {
        targetNodeList.filter(targetNode => targetNode.mappingType === 'column-table' && targetNode.node.target === targetId).forEach(targetNode => {
          columnTargetId = targetNode.targetId;
          columnTargetTableName = targetNode.table.target;
        })
      }

      if (targetNodeList.length > 0) {
        let treeView = this.state.treeView;
        let removeSourceColumnList = [];
        let totalSourceColumn = 0;
        tableList.forEach((table) => {
          table.children.forEach((column) =>{
            if (column.status === false) {
              removeSourceColumnList.push(column.sourceColumnNode);
            }
            totalSourceColumn++;
          })
        })

        nodeList.filter(columnMapped => columnMapped.customValue === true && columnMapped.node.target === targetId && removeSourceColumnList.indexOf(columnMapped.node.source) > -1).forEach(columnMapped => {
          MappedColumnValue.saveCustomUnmapValueList(columnMapped.table.target, columnMapped.parentColumn.target.column_header, columnMapped.columnTitle.target)
        })

        nodeList = nodeList.filter(columnMapped => !(columnMapped.node.target === targetId && removeSourceColumnList.indexOf(columnMapped.node.source) > -1))
        let retainSourceTableList = [];
        nodeList.filter(mappedValue => mappedValue.targetId === targetId).forEach(mappedValue => {
          if (retainSourceTableList.indexOf(mappedValue.table.source) === -1 && this.props.expressionList.filter(expression => expression.id === targetId && expression.expressionDetail.filter(tableExpression => tableExpression.tableName === mappedValue.table.source && (tableExpression.tableOperation === 'Pivot' || tableExpression.children.length === 1)).length > 0).length > 0) {
            retainSourceTableList.push(mappedValue.table.source)
          }
        })

        nodeList = nodeList.filter(mappedNode => !(mappedNode.targetId === targetId && (retainSourceTableList.indexOf(mappedNode.table.source) === -1 || removeSourceColumnList.indexOf(mappedNode.table.source+'-source-'+mappedNode.parentColumn.source+'-source') > -1)))
        treeView.children.forEach((table) => {

          if (table.id === targetId) {
              table.removeMappedLine = totalSourceColumn === removeSourceColumnList.length ? false : true;
              table.carryForward = totalSourceColumn === removeSourceColumnList.length ? false : true;
          }

          table.children.forEach((column) => {
            if (column.id === targetId) {
              column.removeMappedLine = totalSourceColumn === removeSourceColumnList.length ? false : true;
              column.hardcode = false;
              column.expression = totalSourceColumn === removeSourceColumnList.length ? false : true;
              column.highlight = false;
              column.renameColumn = false;
              column.pivotOperationColumn = false;

              if (column.removeMappedLine === false) {
                column.cssClassName = column.cssClassName.replace('expression', 'hardcode-expression');
              }

              if (typeof(column.columnStatus) !== 'undefined') {
                column.columnStatus = nodeList.filter(node => node.mappingType === 'column-column' && node.node.target === targetId && eval(node.column.source.column_data_length) > eval(column.column.column_data_length)).length > 0 ? 'yellow' : column.columnStatus.replace('yellow', '');

                if (typeof(column.columnCTStatus) !== 'undefined' && column.columnCTStatus !== '') {
                  column.columnStatus = column.columnCTStatus === 'No' ? 'red' : 'yellow'
                }
                if (nodeList.filter(node => node.mappingType === 'column-column' && node.node.target === targetId).length === 0) {
                  column.columnStatus = '';
                }
              }

              if (column.expression === false) {
                column.isBlink = false
              } else if (typeof(this.props.treeElement.sourceTableTree) !== 'undefined') {
                column.isBlink = nodeList.filter(node => node.mappingType === 'column-column' && node.node.target === column.id && this.props.treeElement.sourceTableTree.children.filter(sourceTable => sourceTable.tableName === node.table.source && sourceTable.children.filter(sourceColumn => sourceColumn.isBlink === true && sourceColumn.id === node.node.source).length > 0).length > 0).length > 0 ? true: false
              }

              if (isHighlightVariable === true && column.removeMappedLine === false && column.applyProcessFlow === false) {
                column.highlight = true;
              }
            }

            if(typeof(column.children) !== 'undefined' && column.children.length > 0) {
              column.children.forEach((columnValue) => {
                if (columnValue.id === targetId) {
                  columnValue.removeMappedLine = totalSourceColumn === removeSourceColumnList.length ? false : true;
                }
              })
            }
          })
        });

        let { sourceValueTree } = MappedColumnValue.getMappedColumnValueState();

        if (typeof(sourceValueTree.children) !== 'undefined') {
          sourceValueTree.children.map(table => {
            if (typeof(table.children) !== 'undefined') {
              table.children.map(column => {
                if (typeof(column.children) !== 'undefined') {
                  column.children.map(columnValue => {

                    if (removeSourceColumnList.indexOf(columnValue.id) > -1) {
                      columnValue.carryForward = true
                    }

                    return columnValue;
                  })
                }

                return column
              })
            }
            return table
          })
        }

        if (totalSourceColumn === removeSourceColumnList.length) {
          setTimeout(() => {
            MappedColumnValue.removeTargetColumn(targetId)
          }, 1)
        }

        let treeElement = this.props.treeElement;
        treeElement.sourceTableTree.children.map((table) => {
          if (nodeList.filter(node => node.table.source === table.tableName).length === 0) {
            table.cssClassName = table.cssClassName.replace(' highlight-table', '')
          }

          table.children.map(column => {
            if (column.columnStatus === 'yellow' && column.column.column_data_length < 200) {
              column.columnStatus = '';
            }

            if (removeSourceColumnList.indexOf(column.id) > -1 && nodeList.filter(node => node.mappingType === 'column-column' && node.node.source === column.id && treeElement.metadataTableTree.children.filter(metadataTable => metadataTable.tableName === node.table.target && metadataTable.children.filter(targetColumn => targetColumn.isBlink === true && targetColumn.id === node.node.target).length > 0).length > 0).length == 0) {
              column.isBlink = false
            }

            if (isHighlightVariable === true && nodeList.filter(columnMapped => columnMapped.table.source === table.tableName && columnMapped.columnTitle.source === column.title).length === 0) {
              column.highlight = true
            }
            return column
          })
          return table;
        });

        treeElement.metadataTableTree.children.filter(table => table.tableName === columnTargetTableName).map((table) => {
          if (nodeList.filter(node => node.table.target === table.tableName).length === 0) {
            table.cssClassName = table.cssClassName.replace(' highlight-table', '')
          }
          table.children.filter(column => column.id === columnTargetId && typeof(column.columnCTStatus) !== 'undefined' && column.columnCTStatus !== '').map(column => {
            if (column.columnCTStatus === 'Yes' && nodeList.filter(node => node.targetId === columnTargetId).length === 0) {
              column.columnStatus = 'yellow';
            } else if (column.columnCTStatus === 'No') {
              let { sourceValueTree } = MappedColumnValue.getMappedColumnValueState();
              let totalValueCount = 0;
              sourceValueTree.children.forEach(table => {
                table.children.forEach((column, i) => {
                  totalValueCount += column.children.length;
                });
              })

              if (totalValueCount !== nodeList.filter(node => node.targetId === columnTargetId).length) {
                column.columnStatus = 'red';
              }
            }
            return column
          })
          return table;
        });

        /*treeElement.leftPersistentTableTree.children.map((table) => {
          if (nodeList.filter(node => node.table.source === table.tableName).length == 0) {
            table.cssClassName = table.cssClassName.replace(' highlight-table', '')
          }
          return table;
        });*/

        this.setState({ treeView: treeView, visibleRemoveMappedColumn: isValueRemove === false ? !this.state.visibleRemoveMappedColumn : false, targetMappedColumn: { targetId: null, tableList: [] } });
        DrawColumnMappingLine.setNodeListState(nodeList);
        this.props.manageMappedColumnList(nodeList);
        this.props.setTreeElement(treeElement);

        if (isValueRemove === true) {
          this.modifyDrawLine();
        }

        if (this.props.whereMatrixList.length > 0) {
          let whereMatrixList = this.props.whereMatrixList;
          whereMatrixList = whereMatrixList.filter(whereMatrix => nodeList.filter(node => node.table.source === whereMatrix.sourceTable && node.table.target === whereMatrix.tableName).length > 0)
          this.props.manageWhereMatrix(whereMatrixList)
        }
        setTimeout(() => {
          this.addMultipleTargetColumnExpression([targetId], null, removeSourceColumnList, false, false)
        }, 1)
      }
    }
  }

  tableElement = (table, index) => {
    return (
      table.children.map((item, index) => this.rowElement(item, index, table))
    )
  }

  rowElement = (element, index, table) => {
    let sourceTable = null;
    let tableName = table.tableName;
    let sourceElement = element.sourceElement;

    if (typeof(this.props.treeElement.sourceTableTree) !== 'undefined') {
      sourceTable = this.props.treeElement.sourceTableTree.children.filter(sourceTable => sourceTable.tableName === table.tableName);

      /*if (sourceTable.length === 0) {
          sourceTable = this.props.treeElement.leftPersistentTableTree.children.filter(sourceTable => sourceTable.tableName === table.tableName);
      }*/

      if (sourceTable.length > 0) {
        sourceElement = sourceElement.replace(tableName, sourceTable[0].name);
        tableName = sourceTable[0].name;
      }
    }

    return (
      <tr key={`row-${element.sourceElement}-${index}`}>
        {index===0 &&
          <td rowSpan={table.children.length} className="valign-middle">
            {tableName}
          </td>
        }
        <td>
          <input type="checkbox" id={`${index}-${element.sourceColumnNode}`} className="am-checkbox"
          checked={element.status} onChange={(event) => this.handleRemoveColumnChange(event, table.tableName, element.sourceColumnNode)} disabled={element.isPivot}/>
          <label className="am-checkbox-label" htmlFor={`${index}-${element.sourceColumnNode}`}>{sourceElement} {element.isPivot && <sub className="sub-pivot">(Pivot)</sub>}</label>
        </td>
      </tr>
    )
  }

  handleRemoveColumnChange = (event, tableName, sourceColumnNode) => {
    let targetMappedColumn = this.state.targetMappedColumn;

    targetMappedColumn.tableList.forEach((table) => {
      if (table.tableName === tableName) {
        table.children.forEach((column) => {
          if (column.sourceColumnNode === sourceColumnNode) {
            column.status = event.target.checked;
          }
        })
      }
    })

    this.setState({ targetMappedColumn: targetMappedColumn })
  }

  addMultipleTargetColumnExpression = (targetColumnList, renameColumnList = null, removeSourceColumnList = [], resetExression = false, pivotStatus = true) => {
    let columnExpressionList = [];
    const inputPivotStatus = pivotStatus
    if (renameColumnList === null) {
      renameColumnList = this.props.renameColumnList;
    }

    targetColumnList.forEach((targetColumn) => {
      if (typeof(targetColumn) !== 'undefined') {
        let sourceColumnList = this.props.columnMappedList.filter((columnMap) => columnMap.node.target === targetColumn);
        let tableList = [];
        let nodeExpression = this.props.expressionList.filter(expression => expression.id === targetColumn);
        let reCalculateExpression = true;
        let isUserSaved = false;

        if (sourceColumnList.length === 0) {
          let { nodeList } = DrawColumnMappingLine.getState();
          sourceColumnList = nodeList.filter((columnMap) => columnMap.node.target === targetColumn);
        }

        sourceColumnList.forEach((columnMapped) => {
            let sourceElement = '';
            let tableExpressionType = 'Single';
            let tableOperation = 'No Pivot';
            let tableExpression = '';
            let elementExpression = '';
            let sourceColumnName = '';
            let group = 'Group 1';
            let groupExpressionDetail = [];
            let mappingGrid = [];
            let targetValues = [];
            let renameColumnHeader = null;
            let renameColumnDetail = []
            let tablePivotOutput = 'Q_VALUE';

            if (nodeExpression.length > 0 && typeof(nodeExpression[0].isUserSaved) !== 'undefined') {
              resetExression = false
              reCalculateExpression = false;
              isUserSaved = nodeExpression[0].isUserSaved;
            }

            if (columnMapped.mappingType !== '') {
              let columnType = columnMapped.mappingType.split('-');
              renameColumnDetail = renameColumnList.filter(renameColumnDetail => renameColumnDetail.id === columnMapped.node.source);

              if (renameColumnDetail.length > 0){
                renameColumnHeader = renameColumnDetail[0].renameColumn.column;
              }

              if (columnType[0] === 'column' && typeof(columnMapped.column.source.column_header) !== 'undefined') {
                sourceElement =   columnMapped.table.source + '->' + columnMapped.column.source.column_header;
                elementExpression = this.getExpressionString(columnMapped.column.source, columnMapped.column.target, renameColumnHeader);
                sourceColumnName = columnMapped.column.source.column_header;
              } else if(columnType[0] === 'value' && typeof(columnMapped.parentColumn.source.column_header) !== 'undefined')  {
                sourceElement =   columnMapped.table.source + '->' + columnMapped.parentColumn.source.column_header + '->' + columnMapped.columnTitle.source;
                elementExpression = this.getExpressionString(columnMapped.parentColumn.source, columnMapped.parentColumn.target, renameColumnHeader);
                sourceColumnName = columnMapped.parentColumn.source.column_header;
              }

              if (renameColumnDetail.length > 0){
                sourceElement += '('+renameColumnDetail[0].renameColumn.column+')';
              }

              if (tableExpressionType === 'Single') {
                tableExpression = elementExpression;
              }
            }

            let table = tableList.filter(table => table.tableName === columnMapped.table.source);

            if (nodeExpression.length > 0 && nodeExpression[0].expressionDetail.length > 0) {
              reCalculateExpression = false;

              if (nodeExpression[0].expressionDetail.filter(expressionDetail => expressionDetail.tableName === columnMapped.table.source).length === 0) {
                nodeExpression[0].expressionDetail.push({tableName: columnMapped.table.source, expressionType: tableExpressionType, tableOperation: tableOperation, expression: tableExpression, mappingGrid: mappingGrid, targetValues: targetValues, children:[{sourceElement: sourceElement,expression: elementExpression, sourceColumnName: sourceColumnName, group: group, renameSourceColumnName: renameColumnHeader}], groupExpressionDetail: groupExpressionDetail, columnPivotMatrix: {}, pivotOutput: tablePivotOutput, columnWhereMatrix: {}, error: false})
              }

              nodeExpression[0].expressionDetail.map((expressionDetail, index) => {
                if (expressionDetail.tableName === columnMapped.table.source) {
                  const beforeSingleTableExpression = this.getSingleGroupExpression(nodeExpression[0].id, expressionDetail.children)
                  if (renameColumnDetail.length > 0 && renameColumnHeader !== null){
                    expressionDetail.children = expressionDetail.children.filter(sourceNode => sourceNode.sourceColumnName !== sourceColumnName)
                  }

                  let expressionDetailChildren = expressionDetail.children.filter(sourceNode => sourceNode.sourceColumnName === sourceColumnName);
                  if (expressionDetailChildren.length === 0 ) {
                    expressionDetail.children.push({sourceElement: sourceElement,expression: elementExpression, sourceColumnName: sourceColumnName, group: group, renameSourceColumnName: renameColumnHeader, carrySourceColumn: false, whereCondition: ''});
                    expressionDetail.children.sort((a,b) => ('' + a.group+a.sourceColumnName).localeCompare(b.group+b.sourceColumnName));
                  }

                  if (removeSourceColumnList.length > 0) {
                    expressionDetail.children = expressionDetail.children.filter(sourceColumn => removeSourceColumnList.filter(removeSourceColumn => removeSourceColumn.indexOf(expressionDetail.tableName+'-source') > -1 && removeSourceColumn.indexOf(sourceColumn.sourceColumnName+'-source') > -1).length === 0)
                  }

                  if (expressionDetail.children.length === 1) {
                    nodeExpression[0].expressionDetail[index].expressionType = 'Single';
                  } else if(expressionDetail.tableOperation === 'Pivot' && expressionDetail.children.length > 0) {
                    nodeExpression[0].expressionDetail[index].expressionType = 'Multiple';
                  }

                  if (expressionDetail.tableOperation === 'Pivot' && expressionDetail.children.length > 1 && inputPivotStatus === true) {
                    pivotStatus = true;
                    isUserSaved = false
                  }

                  if (expressionDetail.expressionType !== 'Multiple' && expressionDetail.children.length > 1 && isUserSaved === false && pivotStatus === true) {
                    nodeExpression[0].expressionDetail[index].expressionType = 'Multiple';
                  }

                  if (!(nodeExpression[0].expressionDetail[index].expressionType === 'Multiple' && nodeExpression[0].expressionDetail[index].tableOperation === 'Pivot')) {
                    nodeExpression[0].expressionDetail[index].pivotOutput = 'Q_VALUE'
                  }

                  if ((isUserSaved === true || pivotStatus === false) && nodeExpression[0].expressionDetail[index].expressionType === 'Single') {
                    let singleTableExpression = this.getSingleGroupExpression(nodeExpression[0].id, expressionDetail.children)
                    if (beforeSingleTableExpression === nodeExpression[0].expressionDetail[index].expression) {
                      nodeExpression[0].expressionDetail[index].expression = singleTableExpression
                    }
                  }
                }
                return expressionDetail
              })
            }

            if (resetExression === true) {
              reCalculateExpression = resetExression
            }

            if (reCalculateExpression === true) {
              if (table.length > 0) {
                table[0].children.push({sourceElement: sourceElement,expression: elementExpression, sourceColumnName: sourceColumnName, group: group, renameSourceColumnName: renameColumnHeader, carrySourceColumn: false, whereCondition: ''});
                table[0].children.sort((a,b) => ('' + a.group).localeCompare(b.group));
                table[0].expressionType = 'Multiple';
                table[0].tableOperation = tableOperation;

                if (pivotStatus === false) {
                  table[0].expressionType = tableExpressionType;
                  table[0].tableOperation = tableOperation;
                  table[0].expression = this.getSingleGroupExpression(targetColumn, table[0].children)
                }
              } else {
                tableList.push({tableName: columnMapped.table.source, expressionType: tableExpressionType, tableOperation: tableOperation, expression: tableExpression, mappingGrid: mappingGrid, targetValues:targetValues, children:[{sourceElement: sourceElement,expression: elementExpression, sourceColumnName: sourceColumnName, group: group, renameSourceColumnName: renameColumnHeader}], groupExpressionDetail: groupExpressionDetail, columnPivotMatrix: {}, pivotOutput: tablePivotOutput, columnWhereMatrix: {}, error: false})
              }
            }
        })

        if (reCalculateExpression === false) {
          tableList.length = 0;
          tableList.push(...nodeExpression[0].expressionDetail)
        }

        tableList.map(table => {
          table.children = table.children.filter(children => sourceColumnList.filter(sourceColumn => sourceColumn.table.source === table.tableName && sourceColumn.columnTitle.source === children.sourceColumnName).length > 0)

          if (table.tableOperation === 'Pivot') {
            table.expressionType = table.children.length === 1 ? 'Single' : 'Multiple';
          } else {
            table.expressionType = 'Single';
          }

          if (table.children.length === 1) {
            table.expression = table.children[0].expression
          }
          return table
        })

        tableList = tableList.filter(table => table.children.length > 0)
        columnExpressionList.push({id: targetColumn, expressionDetail: tableList, isUserSaved: isUserSaved})
      }
    })

    this.props.setDefaultTargetColumnExpression(columnExpressionList, false);
  }

  getExpressionString = (source, target, renameColumnHeader = null) => {
    let columnHeader = source.column_header;
    let expression = '';

    if (renameColumnHeader != null) {
      columnHeader = renameColumnHeader
    }
    if ((source.column_data_type === 'DATE' || source.column_data_type === 'DATETIME') && target.column_data_type === 'CHAR') {
        expression = 'put('+columnHeader+',is8601'+(source.column_data_type === 'DATE' ? 'da' :'dt')+'.)';
      } else if(source.column_data_type === 'CHAR' && target.column_data_type === 'NUM') {
        expression = 'input('+columnHeader+', best.)';
      } else if(source.column_data_type === 'NUM' && target.column_data_type === 'CHAR') {
        expression = 'put('+columnHeader+', best.-L)'
      } else {
        expression = columnHeader;
      }

      if (typeof(this.props.lastExecuteEngineValue) !== 'undefined' && typeof(this.props.lastExecuteEngineValue.value) !== 'undefined' && this.props.lastExecuteEngineValue.value === 3) {
        expression = columnHeader;
      }
      return expression;
  }

  getSingleGroupExpression = (targetId, sourceVariableList, group = 'Group 1') => {
    let sourceColumnList = this.props.columnMappedList.filter((collumnMap) => collumnMap.node.target === targetId);
    let expression = '';

    if (sourceColumnList.length > 0) {
      let { column, parentColumn, mappingType } = sourceColumnList[0];
      let columnType = mappingType.split('-');
      let targetType = '';
      if (columnType[1] === 'column' && typeof(column.target.column_header) !== 'undefined') {
        targetType = column.target.column_data_type;
      } else if(columnType[1] === 'value' && typeof(parentColumn.target.column_header) !== 'undefined')  {
        targetType = parentColumn.target.column_data_type;
      }

      if (targetType !== '') {
        let expressionList = [];

        if (sourceVariableList.length > 0) {
          sourceVariableList.filter(sourceElement => sourceElement.group === group).forEach((sourceVariable) => {
            let expressString = sourceVariable.expression;

            if (targetType === 'CHAR' && sourceVariableList.filter(sourceElement => sourceElement.group === group).length > 1) {
              expressString = 'trim('+expressString+')';
            }

            expressionList.push(expressString);
          })

          if (targetType !== 'CHAR') {
            expression = expressionList.join('+');
          } else {
            expression = expressionList.join('||" "||');
          }

          if (typeof(this.props.lastExecuteEngineValue) !== 'undefined' && typeof(this.props.lastExecuteEngineValue.value) !== 'undefined' && this.props.lastExecuteEngineValue.value === 3) {
            expression = expressionList.length > 1 ? 'paste('+expressionList.join(',')+')' : expressionList.join('');
          }
        }
      }
    }

    return expression;
  }

  handleShowTableMapping = (e, data, type) => {
    let treeElement = this.props.treeElement
    let { nodeList } = DrawColumnMappingLine.getState();
    let expandTableList = [];

    nodeList.filter(node => node.targetId === null && ((type === 'target' && node.table.source === data.node.tableName) || (type === 'source' && node.table.target === data.node.tableName))).forEach(node => {
      let tableName = node.table.target
      if (type === 'source') {
        tableName = node.table.source
      }

      if (expandTableList.indexOf(tableName) === -1) {
        expandTableList.push(tableName);
      }

    })

    if (expandTableList.length > 0) {
      if (type === 'target') {
        treeElement = {
          ...treeElement,
          sourceTableTree: this.expandTreeElement(treeElement.sourceTableTree, [data.node.tableName], nodeList),
          //leftPersistentTableTree: this.expandTreeElement(treeElement.leftPersistentTableTree, [data.node.tableName], nodeList),
          metadataTableTree: this.expandTreeElement(treeElement.metadataTableTree, expandTableList, nodeList),
          //rightPersistentTableTree: this.expandTreeElement(treeElement.rightPersistentTableTree, expandTableList, nodeList)
        }
      } else {
        treeElement = {
          ...treeElement,
          sourceTableTree: this.expandTreeElement(treeElement.sourceTableTree, expandTableList, nodeList),
          //leftPersistentTableTree: this.expandTreeElement(treeElement.leftPersistentTableTree, expandTableList, nodeList),
          metadataTableTree: this.expandTreeElement(treeElement.metadataTableTree, [data.node.tableName], nodeList),
          //rightPersistentTableTree: this.expandTreeElement(treeElement.rightPersistentTableTree, [data.node.tableName], nodeList)
        }
      }
    }

    this.props.updateTreeElementViaImmutable(treeElement);
    this.modifyDrawLine();
  }

  expandTreeElement = (tableList, expandTableList, nodeList) => {
    tableList.children.map(table => {
      table.toggled = expandTableList.indexOf(table.tableName) > -1 ? true: false

      if (table.toggled === true) {
        table.active = table.toggled;
        table.cssClassName = table.cssClassName.replace(new RegExp(' highlight-table', 'gi'), '').trim();
      } else {
        table.active = false
        let tableNodeList = nodeList.filter(mappedcolumn => mappedcolumn.table.source === table.tableName || mappedcolumn.table.target === table.tableName);

        if (tableNodeList.length > 0) {
          table.cssClassName = table.cssClassName.indexOf(' highlight-table') === -1 ? table.cssClassName + ' highlight-table' : table.cssClassName;
        }
      }

      return table;
    })

    return tableList;
  }

  render() {
    return template.call(this);
  }

  moveNodeUp = (event, id, tableType) => {
    event.preventDefault();
    event.stopPropagation();
    let that = this;
    let currentNode = {};
    switch(tableType)
    {
      case "source":
      currentNode = this.props.treeElement.sourceTableTree;
      break;
      case "metadata":
      currentNode = this.props.treeElement.metadataTableTree;
      break;
      /*case "leftPersistent":
      currentNode = this.props.treeElement.leftPersistentTableTree;
      break;
      case "rightPersistent":
      currentNode = this.props.treeElement.rightPersistentTableTree;
      break;*/
      default:
      break;
    }
    if(typeof(currentNode) !== "undefined" && currentNode !== null &&
    typeof(currentNode.children) !== "undefined" && currentNode.children !== null){
      let selectedNodeIndex = currentNode.children.findIndex(item => item.id === id);
      if(selectedNodeIndex > 0){
        let previousNodeIndex = selectedNodeIndex - 1;
        let currentNodeOrder = currentNode.children[selectedNodeIndex].sortOrder;
        let previousNodeOrder = currentNode.children[previousNodeIndex].sortOrder;
        currentNode.children[selectedNodeIndex].sortOrder = previousNodeOrder;
        currentNode.children[previousNodeIndex].sortOrder = currentNodeOrder;
        let sourceNode = this.state.treeView.children.filter(a => a.id === currentNode.children[selectedNodeIndex].id);
        if(sourceNode.length > 0){
          currentNode.children[selectedNodeIndex].toggled = sourceNode[0].toggled;
        }
        let previousNode = this.state.treeView.children.filter(a => a.id === currentNode.children[previousNodeIndex].id);
        if(previousNode.length > 0){
          currentNode.children[previousNodeIndex].toggled = previousNode[0].toggled;
        }
        currentNode.children.sort((a, b) => a.sortOrder - b.sortOrder);

        currentNode.children.map(table => {
          table.children.map(column => {
            column.tableSortOrder = table.sortOrder
            return column
          })

          return table
        })

        let treeElement = that.props.treeElement;
        switch(tableType)
        {
          case "source":
          treeElement.sourceTableTree = currentNode;
          break;
          case "metadata":
          treeElement.metadataTableTree = currentNode;
          break;
          /*case "leftPersistent":
          treeElement.leftPersistentTableTree = currentNode;
          break;
          case "rightPersistent":
          treeElement.rightPersistentTableTree = currentNode;
          break;*/
          default:
          break;
        }
        this.props.setTreeElement(treeElement);
        this.setState({treeView:currentNode}, () => {
          that.modifyDrawLine();
          that.sortColumnMappedList();
        });
      }
    }
  }

  moveNodeDown = (event, id, tableType) => {
    event.preventDefault();
    event.stopPropagation();
    let that = this;
    let currentNode = {};
    switch(tableType)
    {
      case "source":
      currentNode = this.props.treeElement.sourceTableTree;
      break;
      case "metadata":
      currentNode = this.props.treeElement.metadataTableTree;
      break;
      /*case "leftPersistent":
      currentNode = this.props.treeElement.leftPersistentTableTree;
      break;
      case "rightPersistent":
      currentNode = this.props.treeElement.rightPersistentTableTree;
      break;*/
      default:
      break;
    }
    if(typeof(currentNode) !== "undefined" && currentNode !== null &&
      typeof(currentNode.children) !== "undefined" && currentNode.children !== null){
       let selectedNodeIndex = currentNode.children.findIndex(item => item.id === id);
        if(selectedNodeIndex >= 0 && selectedNodeIndex < (currentNode.children.length - 1)){
          let nextNodeIndex = selectedNodeIndex + 1;
          let currentNodeOrder = currentNode.children[selectedNodeIndex].sortOrder;
          let nextNodeOrder = currentNode.children[nextNodeIndex].sortOrder;
          currentNode.children[selectedNodeIndex].sortOrder = nextNodeOrder;
          currentNode.children[nextNodeIndex].sortOrder = currentNodeOrder;

          let sourceNode = this.state.treeView.children.filter(a => a.id === currentNode.children[selectedNodeIndex].id);
          if(sourceNode.length > 0){
            currentNode.children[selectedNodeIndex].toggled = sourceNode[0].toggled;
          }
          let nextNode = this.state.treeView.children.filter(a => a.id === currentNode.children[nextNodeIndex].id);
          if(nextNode.length > 0){
            currentNode.children[nextNodeIndex].toggled = nextNode[0].toggled;
          }
          currentNode.children.sort((a, b) => a.sortOrder - b.sortOrder);

          currentNode.children.map(table => {
            table.children.map(column => {
              column.tableSortOrder = table.sortOrder
              return column
            })

            return table
          })

          let treeElement = that.props.treeElement;
          switch(tableType)
          {
            case "source":
            treeElement.sourceTableTree = currentNode;
            break;
            case "metadata":
            treeElement.metadataTableTree = currentNode;
            break;
            /*case "leftPersistent":
            treeElement.leftPersistentTableTree = currentNode;
            break;
            case "rightPersistent":
            treeElement.rightPersistentTableTree = currentNode;
            break;*/
            default:
            break;
          }
          this.props.setTreeElement(treeElement);
          this.setState({treeView:currentNode}, () => {
            that.modifyDrawLine();
            that.sortColumnMappedList();
          });
        }
      }


  }

  sortColumnMappedList = () => {
    let columnMappedList = this.props.columnMappedList;
    let { sourceTableTree, metadataTableTree } = this.props.treeElement;

    columnMappedList.map((sortNode) => {
      let sourceTable = sourceTableTree.children.filter(table => table.tableName === sortNode.table.source);
      let targetTable = metadataTableTree.children.filter(table => table.tableName === sortNode.table.target);

      /*if (sourceTable.length === 0) {
        sourceTable = leftPersistentTableTree.children.filter(table => table.tableName === sortNode.table.source);
      }

      if (targetTable.length === 0) {
        targetTable = rightPersistentTableTree.children.filter(table => table.tableName === sortNode.table.source);
      }*/

      return Object.assign(sortNode, {sortOrder : {source: sourceTable.length > 0 ? sourceTable[0].sortOrder : 0, target: targetTable.length > 0 ? targetTable[0].sortOrder : 0}})
    })

    columnMappedList.sort((a,b) => {
        if (a.sortOrder.target < b.sortOrder.target)
          return -1;
        if (a.sortOrder.target > b.sortOrder.target)
          return 1;
        return 0;
    })
    this.props.manageMappedColumnList(columnMappedList);
  }

  handleApplyProcessFlowClick = (e, data) => {
    this.setState({visibleApplyProcessFlow:true, selectNode:data.node});
  }

  closeApplyProcessFlowDialog = () => {
    this.setState({visibleApplyProcessFlow:false});
  }

  handleSortApplyProcessFlowClick = (e, data) => {
    let flows = this.props.applyProcessFlowList.filter(node => node.tableName === data.node.title);
    if(flows.length > 0){
      this.setState({visibleSortApplyProcessFlow:true, selectNode:data.node});
    }else{
      this.props.setMessage("Applied process flow not found. Please apply process flow before sorting.",MessageTypeConst.WARNING_MESSAGE);
    }
  }

  closeSortApplyProcessFlowDialog = () => {
    this.setState({visibleSortApplyProcessFlow:false});
  }

  handleSortColumns = (e, data) => {
    this.setState({visibleSortColumns: true, selectNode: data.node});
  }

  closeSortColumnsDialog = () => {
    this.setState({visibleSortColumns: false, selectNode: {}});
  }

  handlePivotMatrixClick = (e, data) => {
    this.setState({visiblePivotMatrixDialog:true, selectNode:data.node});
  }

  handleExpressionDialogPivotMatrixClick = (sourceTable, targetTable) => {
    let selectNode = '';
    let { sourceTableTree } = this.props.treeElement;

    if (typeof(sourceTableTree) !== 'undefined') {
      const table = sourceTableTree.children.find(table => table.tableName === sourceTable);
      if (typeof(table) !== 'undefined') {
        this.setState({visiblePivotMatrixDialog:true, selectNode:table, targetTableName: targetTable});
      }
    }
  }

  handleWhereMatrixClick = (e, data) => {
    this.setState({visibleWhereMatrixDialog: true, selectNode:data.node});
  }

  closePivotMatrixDialog = (isSaveType = false) => {
    if (isSaveType === true) {
      this.setState({visiblePivotMatrixDialog: false, selectNode: {}, targetTableName: ''});
      this.modifyDrawLine();
    } else {
      ConfirmBox.open("Are you sure you want to exit ?").then((data) => {
        this.setState({visiblePivotMatrixDialog: false, selectNode: {}, targetTableName: ''});
        DrawColumnMappingLine.handleScrollCallback();
      })
    }
  }

  closeWhereMatrixDialog = (isSaveType = false) => {
    if (isSaveType === true) {
      this.setState({visibleWhereMatrixDialog: false, selectNode: {}});
    } else {
      ConfirmBox.open("Are you sure you want to exit ?").then((data) => {
        this.setState({visibleWhereMatrixDialog: false, selectNode: {}});
      })
    }
  }

  handleDataViewerClick = (e, data) => {
    global.processBlockControlValues = [{
      "controlId": -96,
      "controlType": -96,
      "controlValue": ''
    }];
    global.outputBlockTable = "";
    this.setState({visibleDataViewer:true, selectNode:data.node});
  }

  closeDataViewerDialog = () => {
    this.setState({visibleDataViewer: false, selectNode: {}});
  }

  handleRemoveHardcodeClick = (e, id, type) => {
    let that = this;
    ConfirmBox.open("Are you sure you want to delete?").then((data) => {
      that.props.removeHardcode(id);
      let treeView = that.state.treeView;
      let isvalid = true;
      let targetTableName = '';
      let isRDomain = false
      treeView.children.forEach((table) => {
        table.children.filter(column => column.id === id).map((column) => {
          targetTableName = table.tableName;
          if (type === 'hardcode' && column.columnCTStatus === 'No') {
            column.columnStatus = '';
          }

          if (type === 'hardcode') {
            column.hardcode = false;

            if (column.column.column_header === 'RDOMAIN') {
              isRDomain = true
            }
          } else {
            column.expressionIntermediate = false;
          }

          if(column.hardcode){
            column.cssClassName = column.type+'-contextmenu-hardcode';
          }else if(column.expressionIntermediate){
            column.cssClassName = column.type+'-contextmenu-intermediate-expression';
          }else{
            column.cssClassName = column.type+'-contextmenu-hardcode-expression';
          }
          return column;
        })
      });

      if (type === 'hardcode' && targetTableName !== '' && isRDomain === true) {
        let whereMatrixList = this.props.whereMatrixList;

        whereMatrixList = whereMatrixList.filter(whereMatrix => !(whereMatrix.tableName === targetTableName && whereMatrix.sourceTable.indexOf('md_') === 0))
        this.props.manageWhereMatrix(whereMatrixList)
      }

      that.setState({treeView: treeView})
    })
  }

  handleNewSetValueClick = (e, data) => {
    let treeView = this.state.treeView;
    const valueList = cloneDeep(treeView.children.filter(child => child.isSetValue !==  true));
    const setValueList = cloneDeep(treeView.children.filter(child => child.isSetValue ===  true));
    treeView.children.length = 0;
    treeView.children.push(...setValueList);
    treeView.children.push({
      name: 'Set '+ (treeView.children.filter(child => child.isSetValue ===  true).length + 1),
      cssClassName: 'manage-variable-set-value',
      iconClass: 'fa fa-columns',
      loading: false,
      toggled: false,
      children: [],
      id: treeView.id+'-set-value-'+(treeView.children.filter(child => child.isSetValue ===  true).length + 1),
      isSetValue: true,
      assignProcessflowId: 0,
      isSelected: treeView.children.length === 0 ? true : false,
      schemaName: '',
      applyProcessFlow: {}
    })

    treeView.applyProcessFlow = false;
    treeView.children.push(...valueList);
    this.setState({treeView: treeView})
  }

  handleRemoveSetValueClick = (e, data) => {
    let treeView = this.state.treeView;
    const valueList = cloneDeep(treeView.children.filter(setValueNode => setValueNode.id === data.node.id));

    treeView.children = treeView.children.filter(setValueNode => setValueNode.id !== data.node.id)

    if (valueList.length > 0 && valueList[0].children.length > 0) {
      treeView.children.push(...valueList[0].children)

      treeView.children.sort((a,b) => ('' + a.name).localeCompare(b.name))
      const valueNodeList = cloneDeep(treeView.children.filter(setValueNode => typeof(setValueNode.isSetValue) === 'undefined'));
      treeView.children = treeView.children.filter(setValueNode => typeof(setValueNode.isSetValue) !== 'undefined')

      if (treeView.children.length === 0) {
        treeView.applyProcessFlow = true;
      }
      treeView.children.push(...valueNodeList)
    }

    if (treeView.children.filter(setValueNode => typeof(setValueNode.isSetValue) !== 'undefined').length === 0) {
      treeView.applyProcessFlow = true;
    }
    this.setState({treeView: treeView})
  }

  findParentTable = (node) => {
    let validChildTable = {parentTable: ""};

    if (node.iconClass === 'fa fa-table' && typeof(this.props.treeElement) !== 'undefined' && typeof(this.props.treeElement.metadataTableTree) !== 'undefined' && node.type === 'metadata' && typeof(this.props.hardcodeList) !== '') {
      this.props.hardcodeList.filter(hardcode => typeof(hardcode.hardcode) !== 'undefined' && hardcode.hardcode.type === 'Hardcode' && hardcode.tableName === node.tableName && hardcode.column === 'RDOMAIN').forEach(hardcode => {
        validChildTable.parentTable = hardcode.hardcode.columnHardcode.replaceAll('"', '');
      })
    }

    return validChildTable;
  }

  handleTargetValueClick = (event, data, type = 'add') => {
    let selectNode = {}
    let valueTitle = '';
    if (typeof(data) !== 'undefined' && data !== null) {
      selectNode = data
    }

    if (type === 'update' && typeof(selectNode.node) !== 'undefined' && typeof(selectNode.node.title) !== 'undefined') {
      valueTitle = selectNode.node.title;
    }

    this.setState({selectNode: selectNode, visibleTargetValueDialog: !this.state.visibleTargetValueDialog, valueTitle: valueTitle})
  }

  handleTargetValueChange = (event) => {
    this.setState({valueTitle: event.target.value})
  }

  handleAddTargetVariableValueClick = () => {
    let {valueTitle, treeView} = this.state;
    let isTargetValue = false;
    let type = this.state.selectNode.node.cssClassName === 'manage-custom-variable-value' ? 'update' : 'add'
    valueTitle = valueTitle.replaceAll('"', '');
    treeView.children.map(table => {
      table.children.map(targetVariable => {
        if (targetVariable.children.filter(targetValue => targetValue.title.toLowerCase() === valueTitle.toLowerCase() && (type === 'add' || (type === 'update' && this.state.selectNode.node.title.toLowerCase() !== valueTitle.toLowerCase()))).length > 0) {
          isTargetValue = true;
        } else {
          let targetValueList = ["--Keep--",""];
          let lastValue = targetVariable.children[targetVariable.children.length - 1];
          let maxNumber = 0;
          let replaceValueList = [];

          if (type === 'add') {
            targetVariable.children.forEach(targetValue => {
              const newMaxNumber = parseInt(targetValue.id.split('-')[targetValue.id.split('-').length - 1])+1

              if (newMaxNumber > maxNumber) {
                maxNumber = newMaxNumber
              }
            })

            if (typeof(lastValue) === 'undefined') {
              lastValue = {
                carryForward: false,
                columnName: targetVariable.column.column_header,
                draggable: false,
                iconClass: 'fa fa-columns',
                tableName: targetVariable.tableName,
                parentColumn: targetVariable.column
              }
            }
            targetVariable.children.push({
              ...lastValue,
              name: valueTitle,
              label: valueTitle,
              title: valueTitle,
              customValue: true,
              id: targetVariable.id+'-targetValue-'+maxNumber,
              cssClassName: 'manage-custom-variable-value',
              removeMappedLine: false
            })
            MappedColumnValue.saveCustomUnmapValueList(targetVariable.tableName, targetVariable.column.column_header, valueTitle)
          } else if(this.state.selectNode.node.title.toLowerCase() !== valueTitle.toLowerCase()) {
            replaceValueList.push({oldValue: this.state.selectNode.node.title, newValue: valueTitle})
            targetVariable.children.filter(valueNode => valueNode.id === this.state.selectNode.node.id).map(valueNode => {
              MappedColumnValue.saveCustomUnmapValueList(targetVariable.tableName, targetVariable.column.column_header, valueTitle, 'replace', valueNode.name)
              valueNode.name = valueTitle;
              valueNode.label = valueTitle;
              valueNode.title = valueTitle;
              return valueNode
            })
          }

          targetVariable.children.sort((a, b) => (a.customValue < b.customValue) ? 1 : ((a.customValue > b.customValue) ? -1 : 0))
          targetVariable.children.forEach(targetValue => {
            targetValueList.push(targetValue.title)
          })
          if (targetValueList.length > 0) {
            this.props.manageTargetTableValueInExpression({tableName: targetVariable.tableName, columnName: targetVariable.column.column_header, targetValues: targetValueList, replaceValueList: replaceValueList})
          }
        }

        return targetVariable
      })
      return table
    })

    if (isTargetValue === false) {
      this.modifyDrawLine();
      this.handleTargetValueClick();
    } else {
      this.props.setMessage("Duplicate value is found. Please check the target value list!",MessageTypeConst.WARNING_MESSAGE);
    }

  }

  handleRemoveTargetVariableValueClick = (event, data) => {
    let that = this;
    ConfirmBox.open("Are you sure you want \""+data.node.name+"\" to delete?").then((element) => {
      let treeView = that.state.treeView;
      treeView.children.map(table => {
        table.children.map(targetVariable => {
          let targetValueList = ["--Keep--",""];
          targetVariable.children = targetVariable.children.filter(targetValue => targetValue.id !== data.node.id);
          targetVariable.children.forEach(targetValue => {
            targetValueList.push(targetValue.title)
          })
          if (targetValueList.length > 0) {
            that.props.manageTargetTableValueInExpression({tableName: targetVariable.tableName, columnName: targetVariable.column.column_header, targetValues: targetValueList})
          }
          return targetVariable
        })
        return table
      })

      that.setState({treeView: treeView}, () => {
        let { nodeList } = DrawColumnMappingLine.getState();
        let sourceColumnList = nodeList.filter((columnMap) => columnMap.node.target === data.node.id);

        if (sourceColumnList.length > 0) {
          that.handleRemoveMappedColumnClick(event, data.node.id, true);
        } else {
          that.modifyDrawLine();
        }
      })
    })
  }

  handleSearchValueClick = (event, nodeId) => {
    let {treeView, searchText} = this.state;
    searchText[nodeId] = event.target.value
    treeView.toggled = true;
    treeView.children.map(table => {
      table.toggled = true;
      table.children.map(column => {
        column.toggled = true;
        return column
      })
      return table
    })
    this.setState({treeView: treeView, searchText: searchText}, () => {
      this.modifyDrawLine();
    })
  }

  isValidSearchNode = (node) => {
    let searchStatus = '';
    let {treeView, searchText} = this.state;

    if (typeof(searchText[this.state.treeView.id]) !== 'undefined' && searchText[this.state.treeView.id] !== '') {
      try {
        const searchTextRegularExpression = new RegExp(searchText[this.state.treeView.id], 'i');

        if (!searchTextRegularExpression.test(node.name)) {
            searchStatus = ' hidden';
        }
      } catch (e) {
        if (node.name.toLowerCase().indexOf(searchText[this.state.treeView.id].toLowerCase) === -1) {
          searchStatus = ' hidden';
        }
      }
    }
    return searchStatus
  }

  getTargetVariableValueWithCarryForward = (node, targetId, selectedTargetTable) => {
    let targetValueList = [];
    let targetCarryForwardList = [];
    let sourceColumnList = this.props.columnMappedList.filter((columnMap) => columnMap.node.target === targetId);

    if (sourceColumnList.length > 0) {
      let tenantId = this.props.tenantId;
      //tenantId = 'schema_1_50';
      let targetColumn = sourceColumnList[0];

      let targetExpression = this.props.expressionList.filter(expression => expression.id === targetId);
      let uniqueSourceTableList = [];
      let sourceTableColumnValueWithExpressionList = [];

      if (targetExpression.length > 0) {
        targetExpression = targetExpression[0].expressionDetail;
      }

      sourceColumnList.forEach((columnMapped, index) => {
          let singleColumnValue = targetExpression.filter( sourceExpression => sourceExpression.tableName === columnMapped.table.source && sourceExpression.expressionType === 'Single' && sourceExpression.expression !== '' );
          if (uniqueSourceTableList.indexOf(columnMapped.table.source) === -1) {
            uniqueSourceTableList.push(columnMapped.table.source);
          }
      });

      uniqueSourceTableList.forEach((sourceTableName, tableIndex) => {
        let sourceColumns = sourceColumnList.filter((columnMapped) => columnMapped.table.source === sourceTableName );
        let sourceMappedColumn = targetExpression.filter( sourceExpression => sourceExpression.tableName === sourceTableName);

        if (sourceMappedColumn.length > 0) {
          sourceMappedColumn = sourceMappedColumn[0];
          if (sourceMappedColumn.groupExpressionDetail.length > 0) {
            sourceMappedColumn.groupExpressionDetail.forEach((groupExpression) => {
              let sourceMappedColumnList = sourceMappedColumn.children.filter(column => column.group === groupExpression.group);
              if (groupExpression.expressionType === 'Single') {
                let sourceColumnExpressionList = [];
                let groupExpressionExpression = groupExpression.expression
                if (sourceMappedColumnList.length > 0) {
                  sourceMappedColumnList.forEach((sourceColumn) => {
                    sourceColumnExpressionList.push(sourceColumn.sourceColumnName);
                    let renameSourceColumnDetail = this.props.renameColumnList.filter(renameColumn => renameColumn.id.indexOf('-'+sourceColumn.sourceColumnName+'-') > 0);

                    if (renameSourceColumnDetail.length > 0) {
                      groupExpressionExpression = groupExpressionExpression.replace(renameSourceColumnDetail[0].renameColumn.column, sourceColumn.sourceColumnName)
                    }
                  })
                }

                sourceTableColumnValueWithExpressionList.push({
                      sourceTableName: sourceTableName,
                      targetTableName:targetColumn.table.target,
                      sourceColumn: sourceColumnExpressionList.join(','),
                      targetColumn:targetColumn.column.target.column_header,
                      expression: groupExpressionExpression,
                      pivotOutput: sourceMappedColumn.pivotOutput,
                      sourceColumnLabel: ''
                    })
              } else {
                sourceMappedColumnList.forEach((groupColumn) => {
                  let groupColumnExpression = groupColumn.expression;
                  let renameSourceColumnDetail = this.props.renameColumnList.filter(renameColumn => renameColumn.id.indexOf('-'+groupColumn.sourceColumnName+'-') > 0);

                  if (renameSourceColumnDetail.length > 0) {
                    groupColumnExpression = groupColumnExpression.replace(renameSourceColumnDetail[0].renameColumn.column, groupColumn.sourceColumnName)
                  }
                  let sourceColumn = sourceColumns.filter(sourceColumn => sourceColumn.mappingType === 'column-column' && sourceColumn.columnTitle.source === groupColumn.sourceColumnName);
                  sourceTableColumnValueWithExpressionList.push({
                        sourceTableName: sourceTableName,
                        targetTableName:targetColumn.table.target,
                        sourceColumn: groupColumn.sourceColumnName,
                        targetColumn:targetColumn.column.target.column_header,
                        expression: groupColumnExpression,
                        pivotOutput: sourceMappedColumn.pivotOutput,
                        sourceColumnLabel: sourceColumn.length > 0 ? sourceColumn[0].column.source.column_label : ''
                      })
                })
              }
            })
          } else {
            let sourceMappedColumnList = sourceMappedColumn.children;

            if (sourceMappedColumn.expressionType === 'Single') {
              let sourceColumnExpressionList = [];
              let sourceMappedColumnExpression = sourceMappedColumn.expression
              if (sourceMappedColumnList.length > 0) {
                sourceMappedColumnList.forEach((sourceColumn) => {
                  sourceColumnExpressionList.push(sourceColumn.sourceColumnName);
                  let renameSourceColumnDetail = this.props.renameColumnList.filter(renameColumn => renameColumn.id.indexOf('-'+sourceColumn.sourceColumnName+'-') > 0);

                  if (renameSourceColumnDetail.length > 0) {
                    sourceMappedColumnExpression = sourceMappedColumnExpression.replace(renameSourceColumnDetail[0].renameColumn.column, sourceColumn.sourceColumnName)
                  }
                })
              }

              sourceTableColumnValueWithExpressionList.push({
                    sourceTableName: sourceTableName,
                    targetTableName:targetColumn.table.target,
                    sourceColumn: sourceColumnExpressionList.join(','),
                    targetColumn:targetColumn.column.target.column_header,
                    expression: sourceMappedColumnExpression,
                    pivotOutput: sourceMappedColumn.pivotOutput,
                    sourceColumnLabel: ''
              })
            } else {
              sourceMappedColumnList.forEach((groupColumn) => {
                let groupColumnExpression = groupColumn.expression;
                let renameSourceColumnDetail = this.props.renameColumnList.filter(renameColumn => renameColumn.id.indexOf('-'+groupColumn.sourceColumnName+'-') > 0);

                if (renameSourceColumnDetail.length > 0) {
                  groupColumnExpression = groupColumnExpression.replace(renameSourceColumnDetail[0].renameColumn.column, groupColumn.sourceColumnName)
                }
                let sourceColumn = sourceColumns.filter(sourceColumn => sourceColumn.mappingType === 'column-column' && sourceColumn.columnTitle.source === groupColumn.sourceColumnName);

                sourceTableColumnValueWithExpressionList.push({
                      sourceTableName: sourceTableName,
                      targetTableName:targetColumn.table.target,
                      sourceColumn: groupColumn.sourceColumnName,
                      targetColumn:targetColumn.column.target.column_header,
                      expression: groupColumnExpression,
                      pivotOutput: sourceMappedColumn.pivotOutput,
                      sourceColumnLabel: sourceColumn.length > 0 ? sourceColumn[0].column.source.column_label : ''
                })
              })
            }
          }
        }
      })

      if (sourceTableColumnValueWithExpressionList.length > 0) {
        $(".loader").show();
      }

      axios.all([this.fetchTableMultiColumnValues({'Tenant-ID': tenantId}, {data: sourceTableColumnValueWithExpressionList})])
          .then(axios.spread((response) => {
            if(response!= null && response.data !== null && typeof(response.data.messageType) !== "undefined"
            &&  response.data.messageType !== null && response.data.messageType === MessageTypeConst.WARNING_MESSAGE )
            {
                this.props.setMessage(response.data.message, response.data.messageType);
                $(".loader").hide();
                return;
            }
            if (typeof(response.data) !== 'undefined' && response.data.length > 0) {
              targetValueList.push(...response.data[0].target)
              response.data.map(mappedColumnValue => {
                mappedColumnValue.source.filter(sourceValue => sourceValue !== '' && this.props.columnMappedList.filter(columnMapped => columnMapped.targetId === targetId && columnMapped.table.source === mappedColumnValue.sourceTable && columnMapped.parentColumn.source === mappedColumnValue.sourceColumn && columnMapped.columnTitle.source === sourceValue).length === 0 && targetCarryForwardList.indexOf(sourceValue) === -1).forEach(sourceValue => {
                  targetCarryForwardList.push(sourceValue)
                })
              })

              targetValueList.sort((a,b) => ('' + a).localeCompare(b) ).filter(columnValue =>  columnValue !== '').forEach((columnValue, index) => {
                let columnValueId = node.id.split('-');
                let rootType = columnValueId[columnValueId.length - 2];
                columnValueId = columnValueId.join('-') + '-'+ rootType + '-' + index;
                node.children.push(this.manageColumnValueInTreeView(columnValue, node.tableName, node.column.column_header, node.column, columnValueId, false))
              })

              targetCarryForwardList.sort((a,b) => ('' + a).localeCompare(b) ).filter(columnValue =>  columnValue !== '').forEach((columnValue, index) => {
                let columnValueId = node.id.split('-');
                let rootType = columnValueId[columnValueId.length - 2];
                columnValueId = columnValueId.join('-') + '-'+ rootType + '-' + (targetValueList.length + index);
                node.children.push({...this.manageColumnValueInTreeView(columnValue, node.tableName, node.column.column_header, node.column, columnValueId, false), carryForward: true})
              })

              if (node.isVariableValueNode === true && typeof(node.children) !== 'undefined' && node.children.length > 0 && typeof(this.props.applyProcessFlowList) !== 'undefined' && this.props.applyProcessFlowList.length > 0) {
                this.props.applyProcessFlowList.filter(flow => (flow.id === node.id || (typeof(flow.selectedTargetColumnList) !== 'undefined' && flow.selectedTargetColumnList.indexOf(node.column.column_header) > -1)) && flow.tableName === node.tableName && typeof(flow.selectedTargetColumnValueList) !== 'undefined').forEach(flow => {
                  node.children.map(columnValue => {
                    const selectedTargetColumnValue = flow.selectedTargetColumnValueList.filter(selectedTargetColumnValue => selectedTargetColumnValue.valueList.indexOf(columnValue.name) > -1)
                    if (selectedTargetColumnValue.length > 0) {
                      node.children.filter(setValueNode => typeof(setValueNode.isSetValue) !== 'undefined' && setValueNode.isSetValue === true && setValueNode.name === selectedTargetColumnValue[0].setValueName && typeof(setValueNode.children) !== 'undefined' && setValueNode.children.filter(valueNode => valueNode.name === columnValue.name).length === 0).map(setValueNode => {
                        setValueNode.children.push(columnValue)
                        node.children = node.children.filter(valueNode => valueNode.name !== columnValue.name)
                        return setValueNode
                      })
                    }
                    return columnValue;
                  })
                })
              } else if (node.isVariableValueNode === true && typeof(node.selectedTargetColumnValueList) !== 'undefined' && node.selectedTargetColumnValueList.length > 0) {
                node.children.map(columnValue => {
                  const selectedTargetColumnValue = node.selectedTargetColumnValueList.filter(selectedTargetColumnValue => selectedTargetColumnValue.valueList.indexOf(columnValue.name) > -1)
                  if (selectedTargetColumnValue.length > 0) {
                    node.children.filter(setValueNode => typeof(setValueNode.isSetValue) !== 'undefined' && setValueNode.isSetValue === true && setValueNode.name === selectedTargetColumnValue[0].setValueName && typeof(setValueNode.children) !== 'undefined' && setValueNode.children.filter(valueNode => valueNode.name === columnValue.name).length === 0).map(setValueNode => {
                      setValueNode.children.push(columnValue)
                      node.children = node.children.filter(valueNode => valueNode.name !== columnValue.name)
                      return setValueNode
                    })
                  }
                  return columnValue;
                })
              }
              node.fetchColumnValue = false;
              this.setState({ cursor: node }, this.modifyDrawLine());
              $(".loader").hide();
            }
          })
        ).catch(error => {
            this.props.setMessage("Error occurred while fetching unique column values.", MessageTypeConst.ERROR_MESSAGE);
            $(".loader").hide();
        });
      }
  }

  handleAddTLFTableClick = (e, data, type, tableName = '') => {
    let treeView = this.state.treeView;
    if (typeof(data.node) !== 'undefined') {
      treeView.children.filter(tableNode => tableNode.id === data.node.id).map(tableNode => {
        const name = tableName === '' ? type+' '+(tableNode.children.length +1) : tableName
        tableNode.children.push({
          name: name,
          id: 'tablelist-tables-'+type+'-'+(tableNode.children.length +1),
          cssClassName: 'manage-table',
          iconClass: 'fa fa-columns',
          isSelected: false,
          isCopy: false,
          isWarning: (type === 'Figure' ? false: true)
        })
        tableNode.toggled = true;

        if (tableName === '') {
          this.props.createTableNameInLayoutSheet(name)
        }
        return tableNode;
      })
    }

    this.setState({treeView: treeView})
  }

  handleRenameTLFTableClick = (e, data) => {
    this.setState({visibleTLFTableRenameDialog: !this.state.visibleTLFTableRenameDialog, selectNode: typeof(data) !== 'undefined' ? data.node: {}, valueTitle: typeof(data) !== 'undefined' ? data.node.name: ''});
  }

  handleSaveTLFTableTitleClick = (e) => {
    let {selectNode, valueTitle, treeView} = this.state;
    let isduplicateName = false;
    treeView.children.map(tableNode => {
      tableNode.children.filter(tableChild => tableChild.id === selectNode.id).map(tableChild => {
        if (tableNode.children.filter(tableTitleChild => tableTitleChild.id !== selectNode.id && tableTitleChild.name === valueTitle).length === 0) {
          this.props.updateTableNameInLayoutSheet(valueTitle, tableChild.name)
          tableChild.name = valueTitle;
          if (tableChild.isSelected === true) {
            this.props.getDisplayTLFTableName(tableChild.name)
          }
        } else {
          isduplicateName = true;
        }


        return tableChild
      })
      return tableNode;
    })

    if (isduplicateName == false) {
      this.setState({treeView: treeView}, () => {
        this.handleRenameTLFTableClick(e)
      })
    } else {
      this.props.setMessage('The duplicate name("'+valueTitle+'") not allowed. Please use a unique name.', MessageTypeConst.WARNING_MESSAGE);
    }

  }

  handleDeleteTLFTableClick = (e, data) => {
    let treeView = this.state.treeView;
    let that = this;
    ConfirmBox.open("Are you sure you want to delete?").then(function() {
      if (typeof(data.node) !== 'undefined') {
        treeView.children.map(tableNode => {
          tableNode.children = tableNode.children.filter(tableChild => tableChild.id !== data.node.id)
          that.props.deleteTLFTable(data.node.id)
          return tableNode;
        })
      }

      that.setState({treeView: treeView})
    })
  }

  handleCopyTLFTableClick = (e, data) => {
    this.setState({selectNode: data.node})
  }

  handlePasteTLFTableClick = (e, data) => {
    let treeView = this.state.treeView;
    let targetId = ''
    if (typeof(this.state.selectNode.id) !== 'undefined') {
      treeView.children.filter(tableNode => tableNode.id === data.node.id).map(tableNode => {
        targetId = 'tablelist-tables-'+tableNode.name+'-'+(tableNode.children.length + 1);
        let name = cloneDeep(this.state.selectNode.name);

        if (tableNode.children.filter(tableNameNode => tableNameNode.name === name).length > 0) {
          let maxNumber = 0;
          tableNode.children.filter(tableNameNode => tableNameNode.name.indexOf(this.state.selectNode.name+'_') === 0).forEach(tableNameNode => {
            if (maxNumber < parseInt(tableNameNode.name.replace(this.state.selectNode.name+'_', ''))) {
              maxNumber = parseInt(tableNameNode.name.replace(this.state.selectNode.name+'_', ''))
            }
          })

          name = this.state.selectNode.name + '_'+(maxNumber+1);
        }
        tableNode.children.push({...this.state.selectNode, id: targetId, isCopy: true, isSelected: false, name: name})
        return tableNode;
      })
    }
    this.props.copyTLFTable(this.state.selectNode.id, targetId)
    this.setState({treeView: treeView, selectNode: {}})
  }

  handleImportCDISCMetadataClick = (event, data) => {
    this.refs.import_auto_tlf_event_files.click();
  }

  handleImportAutoTlfEventFilesChange = (e) => {
    if (e.target.files.length > 0) {
      const file = e.target.files[0];
      const wb = new Excel.Workbook();
      const reader = new FileReader();
      $(".loader").show();

      reader.readAsArrayBuffer(file);
      reader.onload = () => {
        const buffer = reader.result;
        wb.xlsx.load(buffer).then((workbook) => {
          let sheets = [];
          workbook.eachSheet((sheet, id) => {
            let rows = [];
            let columns = [];
            const sheetId = sheet.name.toLowerCase().replaceAll(' ', '-');
            sheet.eachRow((row, rowIndex) => {
              let sheetRow = []
              for(let i = 1; i <= row.cellCount; i++) {
                if (rowIndex === 1) {
                  columns.push(row.values[i])
                  sheetRow.push({value: typeof(row.values[i]) !== 'undefined' && typeof(row.values[i]) !== 'object' ? row.values[i]: '', className: "orange-column"})
                } else {
                  let columnValue = typeof(row.values[i]) !== 'undefined' && typeof(row.values[i]) !== 'object' ? row.values[i]: '';
                  if (typeof(row.values[i]) === 'object' && typeof(row.values[i].result) !== 'undefined') {
                    columnValue = row.values[i].result
                  }
                  sheetRow.push({value: columnValue})
                }
              }

              if (rowIndex === 1 && (sheetId === 'datasubsets' || sheetId === 'analysissets') && sheetRow.filter(rowColumn => rowColumn.value === 'condition').length === 0) {
                sheetRow.push({value: 'condition', className: "orange-column"})
              }
              rows.push(sheetRow)
            })

            if (columns.indexOf('condition') === -1 && (sheetId === 'datasubsets' || sheetId === 'analysissets')) {
              columns.push('condition')
            }

            sheets.push({id: sheetId, name: sheet.name, rows: rows, columns: columns})
          });


          sheets.filter(sheet => sheet.id === 'outputs').forEach(sheet => {
            let nameIndex = 2;
            let tableNameList = [];
            sheet.rows.forEach((row, rowIndex) => {
              if (rowIndex > 0 && typeof(row[nameIndex]) !== 'undefined' && typeof(row[nameIndex].value) !== 'undefined' && row[nameIndex].value !== '') {
                tableNameList.push(row[nameIndex].value)
              }
            })

            tableNameList = [...new Set(tableNameList)]
            this.state.treeView.children.filter(node => node.id === 'table-tlf-tables').forEach(node => {
              tableNameList.forEach(tableName => {
                this.handleAddTLFTableClick(e, {node: node}, 'Tables', tableName);
              })
            })
          })

          this.state.treeView.handleImportCDISCMetadataClick(sheets);
        });
      };
    }
  }

  handleReportingEventClick = () => {
    this.setState({visibleReportingEventDialog: !this.state.visibleReportingEventDialog})
  }

  handleReportingEventChange = (data) => {
    this.props.saveReportingEventData(data)
    this.handleReportingEventClick();
  }
}

const mapStateToProps = createSelector(
  state => state.processFlow.tenantId,
  state => state.annotate.columnMappingTargetId,
  state => state.annotate.whereConditionList,
  state => state.annotate.hardcodeList,
  state => state.annotate.renameColumnList,
  state => state.annotate.columnMappedList,
  state => state.annotate.expressionList,
  state => state.annotate.treeElement,
  state => state.annotate.applyProcessFlowList,
  state => state.annotate.highlightList,
  state => state.annotate.pivotMatrixList,
  state => state.annotate.virtualTargetTableList,
  state => state.annotate.whereMatrixList,
  state => state.annotate.lastExecuteEngineValue,
  state => state.annotate.additionalWhereConditionList,
  (tenantId, columnMappingTargetId, whereConditionList, hardcodeList, renameColumnList, columnMappedList, expressionList, treeElement, applyProcessFlowList, highlightList, pivotMatrixList, virtualTargetTableList, whereMatrixList, lastExecuteEngineValue, additionalWhereConditionList) => ({
    tenantId,
    columnMappingTargetId,
    whereConditionList,
    hardcodeList,
    renameColumnList,
    columnMappedList,
    expressionList,
    treeElement,
    applyProcessFlowList,
    highlightList,
    pivotMatrixList,
    virtualTargetTableList,
    whereMatrixList,
    lastExecuteEngineValue,
    additionalWhereConditionList
  })
);

const mapActionsToProps = {
  manageMappedColumnList: manageMappedColumnList,
  addWhereCondition: addWhereCondition,
  removeWhereCondition: removeWhereCondition,
  addHardcode: addHardcode,
  removeHardcode: removeHardcode,
  addRenameColumn: addRenameColumn,
  removeRenameColumn: removeRenameColumn,
  addMultipleColumnMappedList: addMultipleColumnMappedList,
  setDefaultTargetColumnExpression: setDefaultTargetColumnExpression,
  setTreeElement: setTreeElement,
  setMessage: setMessage,
  removeApplyProcessFlow: removeApplyProcessFlow,
  updateTreeElementViaImmutable: updateTreeElementViaImmutable,
  manageTargetTableValueInExpression: manageTargetTableValueInExpression,
  manageWhereMatrix: manageWhereMatrix
}
export default connect(mapStateToProps, mapActionsToProps)(TreeView);
