import axios from 'axios';
import restClient from '../restClient';
import { GET_LIST, POST_PARAM_BODY } from '../restClient/types';
import { USER_PREFERENCE_FETCH_SELECTED_STUDY, USER_PREFERENCE_SAVE_SELECTED_STUDY, SET_ERROR_MESSAGE, USER_PREFERENCE_SET_TREE_PREPARED, USER_PREFERENCE_ADD_USER_AND_GROUP, USER_PREFERENCE_MANAGE_USER_LIST, USER_PREFERENCE_MANAGE_GROUP_LIST, USER_PREFERENCE_MANAGE_USER_GROUP_PERMISSION} from './actionsTypes'
import MessageTypeConst from '../views/MessageTypeConst';

const fetchSelectedStudyList = (headers) => restClient(GET_LIST, 'getUserPreference', {}, headers);
const saveSelectedStudyList = (headers, userPreference) => restClient(POST_PARAM_BODY, 'saveUserPreference', {data: userPreference}, headers);
const fetchAllPermissions = (headers) => restClient(GET_LIST, 'permissions/list', {}, headers);
const fetchUserPermissions = (headers) => restClient(GET_LIST, 'permissions/user', {}, headers);

export const fetchSelectedStudy = () => dispatch => {
    axios.all([fetchSelectedStudyList({'Tenant-ID': 'master'}), fetchAllPermissions({'Tenant-ID': 'master'}), fetchUserPermissions({'Tenant-ID': 'master'})])
    .then(axios.spread((response, allPermission, userPermission) => {
      if(response.data !== null && typeof(response.data.studyPreference) !== "undefined"
      && response.data.studyPreference !== null && typeof(response.data.studyPreference.studyList) !== "undefined"
      && response.data.studyPreference.studyList !== null ){
        dispatch({
            type: USER_PREFERENCE_FETCH_SELECTED_STUDY,
            payload: {isStudyListFetched:true, studyList:response.data.studyPreference.studyList, allPermission: allPermission.data !== null ? allPermission.data : [], userPermission: userPermission.data !== null && typeof(userPermission.data) !== "undefined" ? userPermission.data: []}
          });
      }else{
        dispatch({
            type: USER_PREFERENCE_FETCH_SELECTED_STUDY,
            payload: {isStudyListFetched:true, studyList:[], allPermission: []}
          });
      }
    }
    )
    ).catch(error=>{
    dispatch({
    type: SET_ERROR_MESSAGE,
    payload: "Error occurred while fetching selected study."
    });
    });
}

export const saveSelectedStudy = selectedStudies => dispatch => {

    let studyList = [];
    if(selectedStudies !== null && selectedStudies.length > 0){
        selectedStudies.forEach((project) =>{
            if(project.study !== null && typeof(project.study) !== "undefined"){
              let selectedstudy = project.study.filter(item => item.selected === true);
              if(selectedstudy.length > 0){
                selectedstudy.forEach((studySelected)=>{
                    studyList.push({studyId:studySelected.studyId,projectId:studySelected.projectId,schemaName:studySelected.schemaName});
                })
              }
            }
          });
    }
    let userPreference = {
        studyPreference :{
            studyList:studyList
        }
    }
    axios.all([saveSelectedStudyList({'Tenant-ID': 'master'}, userPreference)])
    .then(axios.spread((response) => {
      if(response.data !== null && typeof(response.data.messageType) !== "undefined"
      && response.data.messageType !== null
      && response.data.messageType === MessageTypeConst.SUCCESS_MESSAGE ){

        dispatch({
        type: USER_PREFERENCE_SAVE_SELECTED_STUDY,
        payload: {studyList:studyList}
        });
      }else{
        dispatch({
            type: SET_ERROR_MESSAGE,
            payload: response.data.message
          });
      }
    }
    )
    ).catch(error=>{
    dispatch({
    type: SET_ERROR_MESSAGE,
    payload: "Error occurred while saving selected study."
    });
    });
}

export const setTreePrepared = (isTreePrepared) => dispatch => {
    dispatch({
        type: USER_PREFERENCE_SET_TREE_PREPARED,
        payload: isTreePrepared
      });
}

export const addUserAndGroupList = addUserAndGroupList => dispatch => {
  dispatch({
    type: USER_PREFERENCE_ADD_USER_AND_GROUP,
    payload: addUserAndGroupList
  });
}
export const manageUserList = userList => dispatch => {
  dispatch({
    type: USER_PREFERENCE_MANAGE_USER_LIST,
    payload: userList
  });
}
export const manageGroupList = groupList => dispatch => {
  dispatch({
    type: USER_PREFERENCE_MANAGE_GROUP_LIST,
    payload: groupList
  });
}
export const manageUserGroupPermission = userGroupPermission => dispatch => {
  dispatch({
    type: USER_PREFERENCE_MANAGE_USER_GROUP_PERMISSION,
    payload: userGroupPermission
  });
}
