import React    from "react";
import template from "./Copying.jsx";
import { connect } from 'react-redux';
import { createSelector } from 'reselect';
import { CREATE } from '../../../restClient/types';
import restClient from '../../../restClient';
import axios from 'axios';
import $ from 'jquery';
import MessageTypeConst from "../../MessageTypeConst";
import { setMessage } from '../../../actions/actionNotification';
import CopyType from "../../CopyType.js";
import { NewColumnNameCell } from '../DataOperation/common/InputBox/NewColumnNameCell';
import { setTreeViewData } from '../../../actions/actionTreeView';
import { addImportTable, updateProcessFlowBlockBySchemaName, updateProcessFlowSyncFlagByTabIndex, updateProcessflowBlockRenderStatus } from '../../../actions/actionProcessFlow';
import { addStudy } from '../../../actions/actionStudy';
import { updateProjectList } from '../../../actions/actionProject';
import moment from 'moment';
import { manageClassifierInTreeView, manageProceeFlowGroupInTreeView, manageProcessInTreeView, manageStudyInTreeView, manageStudyElementsInTreeView } from '../NavigationBarMenu/NavigationBarMenu.js';

class Copying extends React.Component {

    constructor(props){
        super(props);
        this.state ={
            title:this.getTitle(this.props.copySource),
            inputLabel:this.getInputLabel(this.props.copySource),
            newTargetName:this.getName(this.props.copySource),
            warningMessage:"",
            isAcrossStudy:this.isAcrossStudy(this.props.copySource, this.props.copyTarget),
            copyInputTable:true,
            closeEvent:props.closeEvent,
            copySource:props.copySource,
            copyTarget:props.copyTarget,
            newClassifier: typeof(props.copyTarget.classifier) ? props.copyTarget.classifier : "",
            newDescription: typeof(props.copySource.description) ? props.copySource.description : "",
            groupRows: this.populateGroupRows(this.props.copySource, this.props.copyTarget),
            groupColumnProperty: [
              { field: "selected", show: false},
              { field: "sourceGroupId",show: false},
              { field: "sourceGroupName", title:"Source Group"},
              { field: "targetGroupName", title: "Group Name", cell :NewColumnNameCell},
            ]
        }
        this.className = this.state.isAcrossStudy ? "data-properties" : "copy-properties";
        this.inputClassname = "pro-label title f-w-600";
        this.closeDialog = this.closeDialog.bind(this);
        this.handleTargetNameChange = this.handleTargetNameChange.bind(this);
        this.handleClassifierChange = this.handleClassifierChange.bind(this);
        this.handleDescriptionChange = this.handleDescriptionChange.bind(this);
        this.handleCopyInputTableChange = this.handleCopyInputTableChange.bind(this);
        this.handleCopyClick = this.handleCopyClick.bind(this);
        this.validateFlowCopy = this.validateFlowCopy.bind(this);
        this.validateGroupCopy = this.validateGroupCopy.bind(this);
        this.validateStudytoStudyCopy = this.validateStudytoStudyCopy.bind(this);
        this.validateStudytoProjectCopy = this.validateStudytoProjectCopy.bind(this);
        this.validateGroupName = this.validateGroupName.bind(this);
        this.isValidTargetName  = this.isValidTargetName.bind(this);
        this.populateGroupRows  = this.populateGroupRows.bind(this);
    }

    populateGroupRows(copySource, copyTarget){
        let groupRows = [{selected:"",sourceGroupId:0,sourceGroupName:"",targetGroupName:""}];
        let treeView = this.props.treeView.data;
        if(typeof(copyTarget) !== "undefined" && copyTarget !== null && typeof(treeView) !== "undefined" && treeView !== null &&
        typeof(copySource) !== "undefined" && copySource !== null && copySource.copyType === CopyType.COPY_STUDY_STUDY){
            let selectedProject = treeView.children.filter(proj => proj.projectId === copySource.projectId);
            if(selectedProject.length > 0){
                let selectedStudy = selectedProject[0].children.filter(study => study.study.studyId === copySource.studyId);
                if(selectedStudy.length > 0){
                    let rootprocessFlowNode = selectedStudy[0].children.filter(node => node.name === "ProcessFlows");
                    if(rootprocessFlowNode.length > 0){
                        groupRows.length = 0;
                        rootprocessFlowNode[0].children.forEach((classifier) => {
                          classifier.children.forEach((group) => {
                              groupRows.push({selected:"",sourceGroupId:group.processGroupId ,sourceGroupName:group.name,targetGroupName:group.name});
                          });
                        })

                    }
                }
            }
        }
        return groupRows;
    }

    isAcrossStudy(copySource, copyTarget){
        let isAcrossStudy = false;
        if(typeof(copyTarget) !== "undefined" && copyTarget !== null && copyTarget.copyType !== CopyType.COPY_STUDY_PROJECT &&
        typeof(copySource) !== "undefined" && copySource !== null && copySource.studyId !== copyTarget.studyId){
            isAcrossStudy = true;
        }
        return isAcrossStudy;
    }

    getTitle(copySource){
        let title = "Copy";
        if(typeof(copySource) !== "undefined" && copySource !== null){
            switch(copySource.copyType){
                case CopyType.COPY_FLOW:
                title = "Copy Flow";
                break;
                case CopyType.COPY_GROUP:
                title = "Copy Group";
                break;
                case CopyType.COPY_STUDY_STUDY:
                title = "Copy Study";
                break;
                default :
                break;
            }
        }
        return title;
    }

    getInputLabel(copySource){
        let inputLabel = "Name";
        if(typeof(copySource) !== "undefined" && copySource !== null){
            switch(copySource.copyType){
                case CopyType.COPY_FLOW:
                inputLabel = "Flow Name";
                break;
                case CopyType.COPY_GROUP:
                inputLabel = "Group Name";
                break;
                case CopyType.COPY_STUDY_STUDY:
                inputLabel = "Study Name";
                break;
                default :
                break;
            }
        }
        return inputLabel;
    }

    getName(copySource){
        let targetName = "";
        if(typeof(copySource) !== "undefined" && copySource !== null){
            targetName = copySource.sourceName;
        }
        return targetName;
    }

    handleCopyInputTableChange(event){
        let copyInputTable = true;
        switch(event.target.id){
            case "optImportTrue":
            copyInputTable = true;
            break;
            case "optImportFalse":
            copyInputTable = false;
            break;
            default:
            break;
        }
        this.setState({copyInputTable:copyInputTable});
    }

    handleTargetNameChange(event){
        let name = event.target.value === null ? "" : event.target.value;
        this.setState({newTargetName:name});
    }

    handleClassifierChange(event){
        let name = event.target.value === null ? "" : event.target.value.toUpperCase();
        this.setState({newClassifier:name});
    }

    handleDescriptionChange(event){
        this.setState({newDescription: event.target.value});
    }

    closeDialog(){
        this.state.closeEvent();
    }

    render() {
        return template.call(this);
    }

    handleCopyClick(){
        let copySource = this.state.copySource;
        let copyTarget = this.state.copyTarget;
        let isValid = false;
        if(typeof(copySource) !== "undefined" && copySource !== null){
            switch(copySource.copyType){
                case CopyType.COPY_FLOW:
                isValid = this.validateFlowCopy();
                break;
                case CopyType.COPY_GROUP:
                isValid = this.validateGroupCopy();
                break;
                case CopyType.COPY_STUDY_STUDY:
                if(typeof(copyTarget) !== "undefined" && copyTarget !== null && copyTarget.copyType === CopyType.COPY_STUDY_STUDY){
                    isValid = this.validateStudytoStudyCopy();
                }
                if(typeof(copyTarget) !== "undefined" && copyTarget !== null && copyTarget.copyType === CopyType.COPY_STUDY_PROJECT){
                    isValid = this.validateStudytoProjectCopy();
                }
                break;
                default:
                break;
            }
        }
        if(!isValid){
            return;
        }

        switch(copyTarget.copyType){
            case CopyType.COPY_FLOW:
            this.executeFlowCopy();
            break;
            case CopyType.COPY_GROUP:
            this.executeGroupCopy();
            break;
            case CopyType.COPY_STUDY_STUDY:
            this.executeStudyToStudyCopy();
            break;
            case CopyType.COPY_STUDY_PROJECT:
            this.executeStudyToProjectCopy();
            break;
            default:
            break;
        }
    }

    executeStudyToProjectCopy = () => {
        $(".loader").show();
        let that = this;
        let request = {
            sourceProjectId : this.state.copySource.projectId,
            sourceStudyId : this.state.copySource.studyId,
            sourceGroupId : this.state.copySource.groupId,
            sourceFlowId : this.state.copySource.processId,
            targetProjectId : this.state.copyTarget.projectId,
            targetStudyId : this.state.copyTarget.studyId,
            targetGroupId : this.state.copyTarget.groupId,
            targetFlowId : this.state.copyTarget.processId,
            copyType :CopyType.COPY_STUDY_PROJECT,
            targetNewName:this.state.newTargetName,
            groupNames:[],
            sourceSchemaName:this.state.copySource.sourceTenantId,
            targetSchemaName:this.state.copyTarget.targetTenantId,
            processSyncFlag: 0,
            copyTables:this.state.copyInputTable,
            studyClassifier:this.state.newClassifier,
            targetStudyDesc: this.state.newDescription
        }
        axios.all([restClient(CREATE, 'copy', {data : request}, {'Tenant-ID': 'master'})])
        .then(axios.spread(function (response)  {
            if (typeof(response.data) !== 'undefined' && response.data.message != null && response.data.messageType !==  MessageTypeConst.SUCCESS_MESSAGE) {
                that.props.setMessage(response.data.message, response.data.messageType);
            }
            else if (typeof(response.data) !== 'undefined' && response.data !== null && typeof(response.data) !== 'undefined'
            && response.data.study !== null && typeof(response.data.study) !== 'undefined') {
                let documents = [];
                if(response.data.documents !== null && typeof(response.data.documents) !== 'undefined'){
                    response.data.documents.forEach((document) => {
                        documents.push({
                          name: document.filename,
                          cssClassName: 'manage-study-edit-documents',
                          schemaName: response.data.study.schemaName,
                          studyId:response.data.study.studyId,
                          projectId:request.targetProjectId,
                          fileId: document.fileId,
                          path: document.filepath,
                          tableType: 2
                        })
                      })
                }
                let sourceTables = [];
                if(response.data.sourceTables !== null && typeof(response.data.sourceTables) !== 'undefined'){
                    response.data.sourceTables.forEach((table) => {
                        sourceTables.push({
                          name: table.srcTableName,
                          cssClassName: 'manage-study-edit-source-table',
                          schemaName: response.data.study.schemaName,
                          studyId:response.data.study.studyId,
                          projectId:request.targetProjectId,
                          tag:'open-data-viewer',
                          tableName:table.tableName,
                          tableType: 0
                        })
                      })
                }
                let metaTables = [];
                if(response.data.metaTables !== null && typeof(response.data.metaTables) !== 'undefined'){
                    response.data.metaTables.forEach((table) => {
                        metaTables.push({
                          name: table.srcTableName,
                          cssClassName: 'manage-study-edit-meta-table',
                          schemaName: response.data.study.schemaName,
                          studyId:response.data.study.studyId,
                          projectId:request.targetProjectId,
                          tag:'open-data-viewer',
                          tableName:table.tableName,
                          tableType: 1
                        })
                      })
                }

                let groupClassifier = [];

                if (typeof(response.data.groupClassifier) !== 'undefined' && response.data.groupClassifier !== null) {
                  response.data.groupClassifier.forEach((classifier) => {
                    let processFlowGroup = [];
                    if(response.data.processFlowGroup !== null && typeof(response.data.processFlowGroup) !== 'undefined'){
                        response.data.processFlowGroup.forEach((group) => {
                            var processFlow = [];
                            if (classifier.classifierId === group.classifier) {
                              if (typeof(group.processFlow) !== 'undefined' && group.processFlow !== null) {
                                group.processFlow.forEach((process) => {
                                  processFlow.push(manageProcessInTreeView(process, request.targetProjectId, response.data.study.schemaName, response.data.study.studyName));
                                })
                              }
                              processFlowGroup.push(manageProceeFlowGroupInTreeView(group, processFlow, response.data.study));
                            }
                          })
                    }

                    groupClassifier.push(manageClassifierInTreeView(classifier, processFlowGroup, response.data.study))
                  })
                }

                let studyNode = manageStudyInTreeView(response.data.study, manageStudyElementsInTreeView(response.data.study, sourceTables, metaTables, groupClassifier, documents), true);
                if (studyNode.study.sourceTable === null || typeof (studyNode.study.sourceTable) === "undefined") {
                    studyNode.study.sourceTable = response.data.sourceTables !== null && typeof(response.data.sourceTables) !== 'undefined' ? response.data.sourceTables : [];
                }
                if (studyNode.study.metaTable === null || typeof (studyNode.study.metaTable) === "undefined") {
                    studyNode.study.metaTable = response.data.metaTables !== null && typeof(response.data.metaTables) !== 'undefined' ? response.data.metaTables : [];
                }
                if (studyNode.study.studyDocuments === null || typeof (studyNode.study.studyDocuments) === "undefined") {
                    studyNode.study.studyDocuments = response.data.documents !== null && typeof(response.data.documents) !== 'undefined' ? response.data.documents : [];
                }
                if (studyNode.study.groupClassifier === null || typeof (studyNode.study.groupClassifier) === "undefined") {
                    studyNode.study.groupClassifier = response.data.groupClassifier !== null && typeof(response.data.groupClassifier) !== 'undefined' ? response.data.groupClassifier : [];
                }
                if (studyNode.study.processFlowGroup === null || typeof (studyNode.study.processFlowGroup) === "undefined") {
                    studyNode.study.processFlowGroup = response.data.processFlowGroup !== null && typeof(response.data.processFlowGroup) !== 'undefined' ? response.data.processFlowGroup : [];
                }

                let treeView = that.props.treeView.data;
                treeView.children.forEach((proj, projIndex) => {
                    if (proj.projectId === request.targetProjectId) {
                        treeView.children[projIndex].children.push(studyNode);
                    }
                });

                let newStudy = Object.assign({}, response.data.study, { selected: true,  timestamp:response.data.study.audit !== null ? moment.utc(response.data.study.audit.createdAt).format('lll') : ""  });
                that.props.addStudy(newStudy);

                that.props.projectList.map((project) => {
                if (project.projectId === newStudy.projectId) {

                    if (typeof(project.study) === 'undefined' || project.study === null) {
                        project.study = [];
                    }
                    let projectStudyList = project.study.slice();
                    projectStudyList.splice(projectStudyList.length, 0, newStudy);

                    project.study.splice(0);
                    project.study.splice(0, 0, ...projectStudyList);

                    if (typeof(project.studyList) === 'undefined' || project.studyList === null) {
                        project.studyList = [];
                    }

                    project.studyList.splice(0);
                    project.studyList.splice(0, 0, ...projectStudyList);
                }
                return project;
                });
                that.props.updateProjectList(that.props.projectList);
                that.props.setTreeViewData(treeView, true);
                if(response.data.message !== "" && response.data.message !== null){
                    that.props.setMessage(response.data.message, response.data.messageType);
                }
            }
            that.closeDialog();
            $(".loader").hide();
        })).catch(error => {
            $(".loader").hide();
            that.props.setMessage("Error occurred while copying study.", MessageTypeConst.ERROR_MESSAGE);
        });


    }

    getTargetGroupNameArray = () =>{
        let groups= [];
        this.state.groupRows.forEach((row)=>{
            groups.push({sourceGroupId:row.sourceGroupId,targetGroupName:row.targetGroupName});
        });
        return groups;
    }

    executeStudyToStudyCopy = () => {
        $(".loader").show();
        let that = this;
        let request = {
            sourceProjectId : this.state.copySource.projectId,
            sourceStudyId : this.state.copySource.studyId,
            sourceGroupId : this.state.copySource.groupId,
            sourceFlowId : this.state.copySource.processId,
            targetProjectId : this.state.copyTarget.projectId,
            targetStudyId : this.state.copyTarget.studyId,
            targetGroupId : this.state.copyTarget.groupId,
            targetFlowId : this.state.copyTarget.processId,
            copyType :CopyType.COPY_STUDY_STUDY,
            targetNewName:this.state.newTargetName,
            groupNames:this.getTargetGroupNameArray(),
            sourceSchemaName:this.state.copySource.sourceTenantId,
            targetSchemaName:this.state.copyTarget.targetTenantId,
            processSyncFlag: 0,
            copyTables:this.state.copyInputTable,
        }
        axios.all([restClient(CREATE, 'copy', {data : request}, {'Tenant-ID': 'master'})])
        .then(axios.spread(function (response)  {
            if (typeof(response.data) !== 'undefined' && response.data.message != null && response.data.messageType !==  MessageTypeConst.SUCCESS_MESSAGE) {
                that.props.setMessage(response.data.message, response.data.messageType);
            }
            else if (typeof(response.data) !== 'undefined' && response.data !== null && typeof(response.data) !== 'undefined'
            && response.data.processFlowGroup !== null && typeof(response.data.processFlowGroup) !== 'undefined' && response.data.processFlowGroup.length > 0) {
                that.updateTreeViewBasedOnResponse(request, response, true);
                if(response.data.message !== "" && response.data.message !== null){
                    that.props.setMessage(response.data.message, response.data.messageType);
                }
            }
            that.closeDialog();
            $(".loader").hide();
        })).catch(error => {
            $(".loader").hide();
            that.props.setMessage("Error occurred while copying study.", MessageTypeConst.ERROR_MESSAGE);
        });


    }

    executeGroupCopy = () => {
        $(".loader").show();
        let that = this;
        let request = {
            sourceProjectId : this.state.copySource.projectId,
            sourceStudyId : this.state.copySource.studyId,
            sourceGroupId : this.state.copySource.groupId,
            sourceFlowId : this.state.copySource.processId,
            targetProjectId : this.state.copyTarget.projectId,
            targetStudyId : this.state.copyTarget.studyId,
            targetGroupId : this.state.copyTarget.groupId,
            targetFlowId : this.state.copyTarget.processId,
            copyType :CopyType.COPY_GROUP,
            targetNewName: this.state.newTargetName,
            groupNames:[],
            sourceSchemaName:this.state.copySource.sourceTenantId,
            targetSchemaName:this.state.copyTarget.targetTenantId,
            processSyncFlag: 0,
            copyTables:this.state.copyInputTable,
        }
        axios.all([restClient(CREATE, 'copy', {data : request}, {'Tenant-ID': this.state.copyTarget.targetTenantId})])
        .then(axios.spread(function (response)  {
            if (typeof(response.data) !== 'undefined' && response.data.message != null && response.data.messageType !==  MessageTypeConst.SUCCESS_MESSAGE) {
                that.props.setMessage(response.data.message, response.data.messageType);
            }
            else if (typeof(response.data) !== 'undefined' && response.data !== null && typeof(response.data) !== 'undefined'
            && response.data.processFlowGroup !== null && typeof(response.data.processFlowGroup) !== 'undefined' && response.data.processFlowGroup.length > 0) {
                that.updateTreeViewBasedOnResponse(request, response, true);
                if(response.data.message !== "" && response.data.message !== null){
                    that.props.setMessage(response.data.message, response.data.messageType);
                }
            }
            that.closeDialog();
            $(".loader").hide();
        })).catch(error => {
            $(".loader").hide();
            that.props.setMessage("Error occurred while copying group.", MessageTypeConst.ERROR_MESSAGE);
        });


    }

    executeFlowCopy = () => {
        $(".loader").show();
        let that = this;
        let request = {
            sourceProjectId : this.state.copySource.projectId,
            sourceStudyId : this.state.copySource.studyId,
            sourceGroupId : this.state.copySource.groupId,
            sourceFlowId : this.state.copySource.processId,
            targetProjectId : this.state.copyTarget.projectId,
            targetStudyId : this.state.copyTarget.studyId,
            targetGroupId : this.state.copyTarget.groupId,
            targetFlowId : this.state.copyTarget.processId,
            copyType :CopyType.COPY_FLOW,
            targetNewName: this.state.newTargetName,
            groupNames:[],
            sourceSchemaName:this.state.copySource.sourceTenantId,
            targetSchemaName:this.state.copyTarget.targetTenantId,
            processSyncFlag: this.getProcessSyncFlag(this.state.copySource.projectId, this.state.copySource.studyId, this.state.copySource.groupId, this.state.copySource.processId),
            copyTables:this.state.copyInputTable,
        }
        axios.all([restClient(CREATE, 'copy', {data : request}, {'Tenant-ID': this.state.copyTarget.targetTenantId})])
        .then(axios.spread(function (response)  {
            if (typeof(response.data) !== 'undefined' && response.data.message != null && response.data.messageType !==  MessageTypeConst.SUCCESS_MESSAGE) {
                that.props.setMessage(response.data.message, response.data.messageType);
            }
            else if (typeof(response.data) !== 'undefined' && response.data !== null && typeof(response.data) !== 'undefined'
            && response.data.processFlowGroup !== null && typeof(response.data.processFlowGroup) !== 'undefined' && response.data.processFlowGroup.length > 0) {
                that.updateTreeViewBasedOnResponse(request, response, false);
                if(response.data.message !== "" && response.data.message !== null){
                    that.props.setMessage(response.data.message, response.data.messageType);
                }
            }
            that.closeDialog();
            $(".loader").hide();
        })).catch(error => {
            $(".loader").hide();
            that.props.setMessage("Error occurred while copying flow.", MessageTypeConst.ERROR_MESSAGE);
        });

    }

    getProcessSyncFlag = (projectId, studyId, groupId, flowId) =>{
        let processSyncFlag= 0;
        var that = this;
        let treeView = that.props.treeView.data;
        let selectedProject = treeView.children.filter(proj => proj.projectId === projectId);
        if(selectedProject.length > 0){
            let selectedStudy = selectedProject[0].children.filter(study => study.study.studyId === studyId);
            if(selectedStudy.length > 0){
                let rootprocessFlowNode = selectedStudy[0].children.filter(node => node.name === "ProcessFlows");
                if(rootprocessFlowNode.length > 0){
                  rootprocessFlowNode[0].children.forEach((classfier) => {
                    let selectedGroup = classfier.children.filter(grp => grp.processGroupId === groupId);
                    if(selectedGroup.length > 0){
                        let selectedFlow = selectedGroup[0].children.filter(flow => flow.process.processId === flowId);
                        if(selectedFlow.length > 0){
                            processSyncFlag = selectedFlow[0].process.processSyncFlag

                        }
                    }
                  })

                }
            }
        }
        return processSyncFlag;

    }

    updateTreeViewBasedOnResponse = (request, response, addGroup) => {
        let that = this;
        let treeView = that.props.treeView.data;
        let processFlowStatus = [];
        if(response.data.virtualBlockUpdates !== null && typeof((response.data.virtualBlockUpdates) !== "undefined"))
        {
            if(response.data.virtualBlockUpdates.processBlock !== null
            && typeof(response.data.virtualBlockUpdates.processBlock) !== "undefined"
            && response.data.virtualBlockUpdates.processBlock.length > 0){
            that.props.updateProcessFlowBlockBySchemaName(that.state.copyTarget.targetTenantId, response.data.virtualBlockUpdates.processBlock);
            }
            if(response.data.virtualBlockUpdates.processFlow !== null
            && typeof(response.data.virtualBlockUpdates.processFlow) !== "undefined"
            && response.data.virtualBlockUpdates.processFlow.length > 0){
            response.data.virtualBlockUpdates.processFlow.forEach((flow) => {
                processFlowStatus.push({processId:flow.processId,processSyncFlag:flow.processSyncFlag, studyId: flow.studyId})
            });
            that.props.processFlowList.forEach((processFlow, index) => {
                response.data.virtualBlockUpdates.processFlow.forEach((flow) => {
                if (processFlow.process.studyId === flow.studyId && processFlow.process.processId === flow.processId) {
                    that.props.updateProcessFlowSyncFlagByTabIndex(index, flow.processSyncFlag);
                    that.props.updateProcessflowBlockRenderStatus(index, true);
                }});
                });
            }
        }
        treeView.children.forEach((proj, projIndex) => {
            if (proj.projectId === request.targetProjectId) {
                treeView.children[projIndex].children.forEach((study, studyIndex) => {
                    if (study.study.studyId === request.targetStudyId) {
                        if (study.study.sourceTable === null || typeof (study.study.sourceTable) === "undefined") {
                            study.study.sourceTable = [];
                        }
                        if (study.study.metaTable === null || typeof (study.study.metaTable) === "undefined") {
                            study.study.metaTable = [];
                        }
                        if (study.study.studyDocuments === null || typeof (study.study.studyDocuments) === "undefined") {
                            study.study.studyDocuments = [];
                        }
                        treeView.children[projIndex].children[studyIndex].children.forEach((node, nodeIndex) => {
                            if (node.name === "ProcessFlows") {
                                if(addGroup){
                                    response.data.processFlowGroup.forEach((newGroup) => {
                                        var processFlows = [];
                                        if (typeof (newGroup.processFlow) !== "undefined"
                                            && newGroup.processFlow !== null
                                            && newGroup.processFlow.length > 0) {
                                            newGroup.processFlow.forEach((updatedFlow) => {
                                                processFlows.push(manageProcessInTreeView(Object.assign({}, updatedFlow, { selected: false }), request.targetProjectId, request.targetSchemaName, study.study.studyName));
                                            });
                                        }
                                        treeView.children.forEach((project, projectIndex) => {
                                          if (projIndex === projectIndex) {
                                            project.children.forEach((study, index) => {
                                              if (studyIndex === index) {
                                                study.children.forEach((studyElement) => {
                                                  if (studyElement.name === 'ProcessFlows') {
                                                    studyElement.toggled = true;
                                                    studyElement.children.forEach((classifier) => {
                                                      if (classifier.classifierId === newGroup.classifier) {
                                                        classifier.toggled = true;
                                                        classifier.children.push(manageProceeFlowGroupInTreeView(newGroup, processFlows, study.study))
                                                        project.toggled = true;
                                                        study.toggled = true;
                                                      }
                                                      if(processFlowStatus.length > 0){
                                                        classifier.children.forEach((group) => {
                                                            group.children.map((flow)=>{
                                                                that.updateFlowIconInTree(processFlowStatus, flow, project, study);
                                                            });
                                                        })
                                                        }
                                                    })
                                                  }
                                                })
                                              }
                                            })
                                          }
                                        })
                                    });
                                }else{
                                    response.data.processFlowGroup.forEach((updatedGroup) => {
                                        treeView.children.forEach((project) => {
                                          if (project.projectId === request.targetProjectId) {
                                            project.children.forEach((study, index) => {
                                              if (studyIndex === index ) {
                                                study.children.forEach((studyElement) => {
                                                  if (studyElement.name === 'ProcessFlows') {
                                                    studyElement.children.forEach((classifier) => {
                                                      classifier.children.forEach((group) => {
                                                        if (group.processGroupId === updatedGroup.processGroupId && typeof(updatedGroup.processFlow) !== "undefined" && updatedGroup.processFlow !== null && updatedGroup.processFlow.length > 0) {
                                                          updatedGroup.processFlow.forEach(updatedFlow => {
                                                            let selectedFlow = group.children.filter(flow => flow.process.processId === updatedFlow.processId);
                                                            if(selectedFlow === null || selectedFlow.length <= 0 ){
                                                              group.toggled = true;
                                                              group.children.push(manageProcessInTreeView(Object.assign({}, updatedFlow, { selected: false }), request.targetProjectId, request.targetSchemaName, study.study.studyName));
                                                              project.toggled = true;
                                                              study.toggled = true;
                                                              classifier.toggled = true;
                                                            }
                                                          })
                                                        }
                                                        if(processFlowStatus.length > 0){
                                                        group.children.map((flow)=>{
                                                            that.updateFlowIconInTree(processFlowStatus, flow, project, study);
                                                        });
                                                        }
                                                      });
                                                    })
                                                  }
                                                })
                                              }
                                            })
                                          }
                                        })
                                    });
                                }
                            }
                            else if (node.name === "Source Table" &&
                                response.data.sourceTables !== null && typeof (response.data.sourceTables) !== 'undefined'
                                && response.data.sourceTables.length > 0) {
                                response.data.sourceTables.forEach((srcTable) => {
                                    let newImportTable = Object.assign({
                                    name: srcTable.srcTableName, projectId: request.targetProjectId,
                                        studyId: study.study.studyId, cssClassName: 'manage-study-edit-source-table', schemaName: study.study.schemaName,
                                        tableType: 0, tag: 'open-data-viewer', tableName: srcTable.tableName
                                    });
                                    treeView.children[projIndex].children[studyIndex].children[nodeIndex].children.push(newImportTable);
                                    let newTable = Object.assign({
                                    srcTableName: srcTable.srcTableName,
                                        tableId: srcTable.tableId, tableName: srcTable.tableName, tableVersion: srcTable.tableVersion,
                                        versionLimit: srcTable.versionLimit, importLog: '', tableType: 0, timestamp: srcTable.timestamp
                                    });
                                    let sourceTable = study.study.sourceTable.filter(table => table.srcTableName !== srcTable.srcTableName);
                                    sourceTable.splice(sourceTable.length, 0, newTable);
                                    study.study.sourceTable.splice(0);
                                    study.study.sourceTable.splice(0, 0, ...sourceTable);
                                    that.props.addImportTable(newImportTable);
                                });
                            }
                            else if (node.name === "Meta Table" &&
                                response.data.metaTables !== null && typeof (response.data.metaTables) !== 'undefined'
                                && response.data.metaTables.length > 0) {
                                response.data.metaTables.forEach((metaDataTable) => {
                                    let newImportTable = Object.assign({
                                    name: metaDataTable.srcTableName, projectId: request.targetProjectId,
                                        studyId: study.study.studyId, cssClassName: 'manage-study-edit-meta-table', schemaName: study.study.schemaName, tableType: 1,
                                        tag: 'open-data-viewer', tableName: metaDataTable.tableName
                                    });
                                    treeView.children[projIndex].children[studyIndex].children[nodeIndex].children.push(newImportTable);
                                    let metaTable = study.study.metaTable.filter(table => table.srcTableName !== metaDataTable.srcTableName);
                                    let newTable = Object.assign({
                                    srcTableName: metaDataTable.srcTableName,
                                        tableId: metaDataTable.tableId, tableName: metaDataTable.tableName, tableVersion: metaDataTable.tableVersion,
                                        versionLimit: metaDataTable.versionLimit, importLog: '', tableType: 1, timestamp: metaDataTable.timestamp
                                    });
                                    metaTable.splice(metaTable.length, 0, newTable);
                                    study.study.metaTable.splice(0);
                                    study.study.metaTable.splice(0, 0, ...metaTable);
                                    that.props.addImportTable(newImportTable);
                                });
                            }
                            else if (node.name === "Documents" &&
                                response.data.documents !== null && typeof (response.data.documents) !== 'undefined'
                                && response.data.documents.length > 0) {
                                response.data.documents.forEach((document) => {
                                    let newDoc = Object.assign({
                                    name: document.filename, projectId: request.targetProjectId,
                                        studyId: study.study.studyId, cssClassName: 'manage-study-edit-documents',
                                        fileId: document.fileId, path: document.filepath,
                                        schemaName: study.study.schemaName, tableType: 2
                                    });
                                    if (newDoc !== null) {
                                        treeView.children[projIndex].children[studyIndex].children[nodeIndex].children.push(newDoc);
                                        let tableList = treeView.children[projIndex].children[studyIndex].children[nodeIndex].children.slice();
                                        let importTableIndex = tableList.length;
                                        treeView.children[projIndex].children[studyIndex].children[nodeIndex].children.forEach((table, index) => {
                                            if (table.name === document.filename) {
                                                importTableIndex = index;
                                            }
                                        });
                                        tableList = tableList.filter(table => table.name !== document.filename);
                                        tableList.splice(importTableIndex, 0, newDoc);
                                        treeView.children[projIndex].children[studyIndex].children[nodeIndex].toggled = true;
                                        treeView.children[projIndex].children[studyIndex].children[nodeIndex].children.splice(0);
                                        treeView.children[projIndex].children[studyIndex].children[nodeIndex].children.splice(0, 0, ...tableList);
                                        if (newDoc.tableType === 2) {
                                            let documents = study.study.studyDocuments.filter(table => table.name !== document.filename);
                                            ;
                                            documents.splice(documents.length, 0, document);
                                            study.study.studyDocuments.splice(0);
                                            study.study.studyDocuments.splice(0, 0, ...documents);
                                        }
                                    }
                                });
                            }
                        });
                    }
                });
            }
        });

        that.props.setTreeViewData(treeView, true);
    }

    updateFlowIconInTree(processFlowStatus, flow, project, study) {
        processFlowStatus.forEach((updatedFlow) => {
            if (updatedFlow.processId === flow.process.processId) {
                project.toggled = true;
                study.toggled = true;
                flow.process.processSyncFlag = updatedFlow.processSyncFlag;
                if (updatedFlow.processSyncFlag === 1) {
                    flow.iconClass = "fa fa-microchip";
                }
                else if (updatedFlow.processSyncFlag === 2) {
                    flow.iconClass = "fa fa-exclamation-triangle yellow";
                }
                else if (updatedFlow.processSyncFlag === 3) {
                    flow.iconClass = "fa fa-exclamation-circle red";
                }
            }
        });
    }

    validateFlowCopy(){
        if(!this.isValidTargetName()){
            return false;
        }
        var that = this;
        let copyTarget = this.state.copyTarget;
        if(typeof(copyTarget) === "undefined" || copyTarget === null){
            return false;
        }
        let treeView = that.props.treeView.data;
        let selectedProject = treeView.children.filter(proj => proj.projectId === copyTarget.projectId);
        let status = true;
        if(selectedProject.length > 0){
            let selectedStudy = selectedProject[0].children.filter(study => study.study.studyId === copyTarget.studyId);
            if(selectedStudy.length > 0){
                let rootprocessFlowNode = selectedStudy[0].children.filter(node => node.name === "ProcessFlows");
                if(rootprocessFlowNode.length > 0){
                    rootprocessFlowNode[0].children.forEach((classifier) => {
                      let selectedGroup = classifier.children.filter(grp => grp.processGroupId === copyTarget.groupId);
                      if(selectedGroup.length > 0){
                          let flowwithTargetName = selectedGroup[0].children.filter(flow => flow.name.toLowerCase() === that.state.newTargetName.toLowerCase());
                          if(flowwithTargetName.length > 0){
                              that.props.setMessage(`Process Flow '${that.state.newTargetName}' already exists in selected target group. Please specify new process flow name.`, MessageTypeConst.WARNING_MESSAGE)
                              status = false;
                              return status;
                          }
                      }
                    })

                }
            }
        }
        return status;

    }

    validateGroupCopy(){
        if(!this.isValidTargetName()){
            return false;
        }
        var that = this;
        let copyTarget = this.state.copyTarget;
        if(typeof(copyTarget) === "undefined" || copyTarget === null){
            return false;
        }
        let treeView = that.props.treeView.data;
        let selectedProject = treeView.children.filter(proj => proj.projectId === copyTarget.projectId);
        let status = true;
        if(selectedProject.length > 0){
            let selectedStudy = selectedProject[0].children.filter(study => study.study.studyId === copyTarget.studyId);
            if(selectedStudy.length > 0){
                let rootprocessFlowNode = selectedStudy[0].children.filter(node => node.name === "ProcessFlows");
                if(rootprocessFlowNode.length > 0){
                  rootprocessFlowNode[0].children.forEach((classifier) => {
                    let selectedGroup = classifier.children.filter(grp => grp.name.toLowerCase() === (that.state.newTargetName.toLowerCase()));
                    if(selectedGroup.length > 0){
                        that.props.setMessage(`Process Group '${that.state.newTargetName}' already exists in selected target study. Please specify new process group name.`, MessageTypeConst.WARNING_MESSAGE)
                        status = false
                        return status;
                    }
                  })
                }
            }
        }
        return status;
    }

    validateStudytoStudyCopy(){
        if(!this.validateGroupName()){
            return false;
        }
        return true;
    }

    validateGroupName(){
        let that = this;
        let copyTarget = this.state.copyTarget;
        if(typeof(copyTarget) === "undefined" || copyTarget === null){
            return false;
        }
        let treeView = that.props.treeView.data;
        let selectedProject = treeView.children.filter(proj => proj.projectId === copyTarget.projectId);
        let status = true;
        if(selectedProject.length > 0){
            let selectedStudy = selectedProject[0].children.filter(study => study.study.studyId === copyTarget.studyId);
            if(selectedStudy.length > 0){
                let rootprocessFlowNode = selectedStudy[0].children.filter(node => node.name === "ProcessFlows");
                if(rootprocessFlowNode.length > 0){
                    let grpExists = [];
                    that.state.groupRows.forEach((row) => {
                        rootprocessFlowNode[0].children.forEach((classifier) => {
                          let selectedGroup = classifier.children.filter(grp => grp.name.toLowerCase() === row.targetGroupName.toLowerCase());
                          if(selectedGroup.length > 0){
                              grpExists.push(...selectedGroup);
                          }
                        })
                    })
                    if(grpExists.length > 0){
                        let message  = "Process Group ";
                        grpExists.forEach((grp1)=>{
                            message = message + "'" + grp1.name + "', ";
                        });
                        message = message.trim().substr(0, message.trim().length - 1);
                        message = message + " already exists in selected target study. Please specify new process group name.";
                        that.props.setMessage(message, MessageTypeConst.WARNING_MESSAGE);
                        status = false
                        return status;
                    }
                }
            }
        }
        return status;
    }

    validateStudytoProjectCopy(){
        if(!this.isValidTargetName()){
            return false;
        }
        var that = this;
        let copyTarget = this.state.copyTarget;
        if(typeof(copyTarget) === "undefined" || copyTarget === null){
            return false;
        }
        if (that.state.newClassifier.trim() === '') {
          that.props.setMessage(`Classifier Name can't be empty.`, MessageTypeConst.WARNING_MESSAGE);
          return false;
        }
        let selectedProject = that.props.projectList.filter(project => project.projectId === copyTarget.projectId);
        if(selectedProject.length > 0 && selectedProject[0].studyList !== null && typeof(selectedProject[0].studyList) !== "undefined"){
          let selectedStudy = selectedProject[0].studyList.filter(study => study.studyName.toLowerCase() === that.state.newTargetName.toLowerCase() && study.classifier.toLowerCase() === that.state.newClassifier.toLowerCase());
          if(selectedStudy.length > 0){
            that.props.setMessage(`Study '${that.state.newTargetName}' already exists. Study name must be unique within project.`, MessageTypeConst.WARNING_MESSAGE);
            return false;
          }

          if(!/^[A-Z0-9-_ ]+$/i.test(that.state.newTargetName)){
            that.props.setMessage("Study Name can't be allow special characters.", MessageTypeConst.WARNING_MESSAGE);
            return false;
          }
        }
        return true;
    }

    isValidTargetName(){
        let targetName = this.state.newTargetName;
        if(targetName.trim() === ""){
            this.props.setMessage("Please enter name.", MessageTypeConst.WARNING_MESSAGE);
            return false;
        }
        return true;
    }
}

const mapStateToProps = createSelector(
    state => state.treeView,
    state => state.project.projectList,
    state => state.processFlow.processFlowList,
    (treeView, projectList, processFlowList) => ({
        treeView  ,
        projectList,
        processFlowList  })
  );

  const mapActionsToProps = {
    setMessage: setMessage,
    setTreeViewData: setTreeViewData,
    addImportTable: addImportTable,
    addStudy: addStudy,
    updateProjectList: updateProjectList,
    updateProcessFlowBlockBySchemaName: updateProcessFlowBlockBySchemaName,
    updateProcessFlowSyncFlagByTabIndex: updateProcessFlowSyncFlagByTabIndex,
    updateProcessflowBlockRenderStatus: updateProcessflowBlockRenderStatus
  }

  export default connect(mapStateToProps, mapActionsToProps)(Copying);
