class BlockStatus {
    constructor(){
       this.VALID = 1;
       this.WARNING = 2;
        this.INVALID = 3;
        this.VIRTUAL = 4;

    }
}

export default (new BlockStatus());
