import React from 'react';
import { PanelBar, PanelBarItem } from './../Layout';
import { StandardDropDown } from '../DropDown/StandardDropDown';
import TextEditor from '../../../TextEditor';
import { Input } from './../InputBox/StandardInput';
import DataOpsIdentifierConstants from '../../../../DataOpsIdentifierConstants';

export class Ods extends React.Component {
  ProcReportPageOrientationIndex  = 21;
  ProcReportPageSizeIndex  = 22;
  ProcReportTopMarginIndex  = 23;
  ProcReportBottomMarginIndex  = 90;
  ProcReportLeftMarginIndex  = 25;
  ProcReportRightMarginIndex  = 26;
  ProcReportEscapeCharacterIndex  = 27;
  ProcReportPdfOptionsIndex  = 28;
  ProcReportRtfOptionsIndex  = 29;
  ProcReportHtmlOptionsIndex  = 30;
  ProcReportXlsOptionsIndex  = 31;

  constructor(props){
    super(props);
    this.getDefaultValueForOptions = this.getDefaultValueForOptions.bind(this);
    this.state = {
      preOptions: props.preOptions,
      processFlowAction : props.processFlowAction,
      pageOrientation : this.getGlobalValue(this.ProcReportPageOrientationIndex) !== "" ? this.getGlobalValue(this.ProcReportPageOrientationIndex): this.getPreOptions(props.preOptions,"_orientation"),
      pageSize : this.getGlobalValue(this.ProcReportPageSizeIndex) !== "" ? this.getGlobalValue(this.ProcReportPageSizeIndex) : this.getPreOptions(props.preOptions,"_papersize"),
      topMargin : this.getGlobalValue(this.ProcReportTopMarginIndex) !== "" ? this.getGlobalValue(this.ProcReportTopMarginIndex) : this.getPreOptions(props.preOptions,"_topMargin"),
      //previously bottom margin was 24 , however now its 90
      bottomMargin : this.getGlobalValue(this.ProcReportBottomMarginIndex) !== "" ? this.getGlobalValue(this.ProcReportBottomMarginIndex) : this.getGlobalValue(24) !== "" ? this.getGlobalValue(24) : this.getPreOptions(props.preOptions,"_bottomMargin"),
      leftMargin : this.getGlobalValue(this.ProcReportLeftMarginIndex) !== "" ? this.getGlobalValue(this.ProcReportLeftMarginIndex) : this.getPreOptions(props.preOptions,"_leftMargin"),
      rightMargin : this.getGlobalValue(this.ProcReportRightMarginIndex) !== "" ? this.getGlobalValue(this.ProcReportRightMarginIndex) : this.getPreOptions(props.preOptions,"_rightMargin"),
      escapeCharacter : this.getGlobalValue(this.ProcReportEscapeCharacterIndex) !== "" ? this.getGlobalValue(this.ProcReportEscapeCharacterIndex) : this.getPreOptions(props.preOptions,"_escapeChar"),
      htmlOptions : this.getDefaultValueForOptions(this.ProcReportHtmlOptionsIndex, "_htmlOdsOpts", props.preOptions),
      rtfOptions : this.getDefaultValueForOptions(this.ProcReportRtfOptionsIndex, "_rtfOdsOpts", props.preOptions),
      pdfOptions : this.getDefaultValueForOptions(this.ProcReportPdfOptionsIndex, "_pdfOdsOpts", props.preOptions),
      xlsOptions : this.getDefaultValueForOptions(this.ProcReportXlsOptionsIndex, "_csvOdsOpts", props.preOptions),
    };
    this.handleSizeChange = this.handleSizeChange.bind(this);
    this.handleOrientationChange = this.handleOrientationChange.bind(this);
    this.handleTopMarginChange = this.handleTopMarginChange.bind(this);
    this.handleBottomMarginChange = this.handleBottomMarginChange.bind(this);
    this.handleLeftMarginChange = this.handleLeftMarginChange.bind(this);
    this.handleRightMarginChange = this.handleRightMarginChange.bind(this);
    this.handleEscapeCharacterChange = this.handleEscapeCharacterChange.bind(this);
    this.handlehtmlOptionsChange = this.handlehtmlOptionsChange.bind(this);
    this.handlePdfOptionsChange = this.handlePdfOptionsChange.bind(this);
    this.handleXlsOptionsChange = this.handleXlsOptionsChange.bind(this);
    this.handleRtfOptionsChange = this.handleRtfOptionsChange.bind(this);
  }
  getDefaultValueForOptions(index, propertyName, preOptions){
    let value ='';
    if(this.getGlobalValue(index) !== ""){
      if(!Array.isArray(this.getGlobalValue(index))){
          value = JSON.parse(this.getGlobalValue(index)).join('\n');
      }else{
          value = this.getGlobalValue(index).join('\n')
      }

    }else {
      value = this.getPreOptions(preOptions,"_htmlOdsOpts");
    }
    return value;
  }

  handlePdfOptionsChange = (code) =>{
    this.setState( {pdfOptions :code});
    this.saveGlobalValue(this.ProcReportPdfOptionsIndex,  JSON.stringify(code.split("\n").filter(a=>a)));

  }

  handleXlsOptionsChange = (code) =>{
    this.setState( {xlsOptions :code});
    this.saveGlobalValue(this.ProcReportXlsOptionsIndex,  JSON.stringify(code.split("\n").filter(a=>a)));

  }

  handleRtfOptionsChange = (code) =>{
    this.setState( {rtfOptions :code});
    this.saveGlobalValue(this.ProcReportRtfOptionsIndex,  JSON.stringify(code.split("\n").filter(a=>a)));

  }

  getGlobalValue(controlIndex){
    let controlValue = '';
    global.processBlockControlValues.forEach((control, index) => {
      if (control.controlId === controlIndex) {
        controlValue = global.processBlockControlValues[index].controlValue;

      }
    });
    return controlValue;
  }

  saveGlobalValue(controlIndex, value){
    global.processBlockControlValues.forEach((control, index) => {
      if (control.controlId === controlIndex) {
        global.processBlockControlValues[index].controlValue = value;
      }
    });
  }
  getPreOptions(preOptionsList,  preOptioName){
    let value ='';
    let selectedItem = preOptionsList.filter(item => item.property === preOptioName);
    if(selectedItem.length > 0){
      value= selectedItem[0].value;
    }
    switch(preOptioName){
      case '_orientation':
      this.saveGlobalValue(this.ProcReportPageOrientationIndex, value);
      break;
      case '_papersize':
      this.saveGlobalValue(this.ProcReportPageSizeIndex, value);
      break;
      case '_topMargin':
      this.saveGlobalValue(this.ProcReportTopMarginIndex, value);
      break;
      case '_bottomMargin':
      this.saveGlobalValue(this.ProcReportBottomMarginIndex, value);
      break;
      case '_leftMargin':
      this.saveGlobalValue(this.ProcReportLeftMarginIndex, value);
      break;
      case '_rightMargin':
      this.saveGlobalValue(this.ProcReportRightMarginIndex, value);
      break;
      case '_escapeChar':
      this.saveGlobalValue(this.ProcReportEscapeCharacterIndex, value);
      break;
      case '_htmlOdsOpts':
      this.saveGlobalValue(this.ProcReportHtmlOptionsIndex, JSON.stringify(value.split("\n").filter(a=>a)));
      break;
      case '_rtfOdsOpts':
      this.saveGlobalValue(this.ProcReportRtfOptionsIndex, JSON.stringify(value.split("\n").filter(a=>a)));
      break;
      case '_pdfOdsOpts':
      this.saveGlobalValue(this.ProcReportPdfOptionsIndex, JSON.stringify(value.split("\n").filter(a=>a)));
      break;
      case '_csvOdsOpts':
      this.saveGlobalValue(this.ProcReportXlsOptionsIndex, JSON.stringify(value.split("\n").filter(a=>a)));
      break;
      default :
      break;
    }
    return value;
  }

  handleEscapeCharacterChange(event){
    this.setState({escapeCharacter:event.target.value});
    this.saveGlobalValue(this.ProcReportEscapeCharacterIndex,event.target.value);
  }

  handleLeftMarginChange(event){
    this.setState({leftMargin:event.target.value});
    this.saveGlobalValue(this.ProcReportLeftMarginIndex, event.target.value);
  }

  handleRightMarginChange(event){
    this.setState({rightMargin:event.target.value});
    this.saveGlobalValue(this.ProcReportRightMarginIndex, event.target.value);
  }

  handleBottomMarginChange(event){
    this.setState({bottomMargin:event.target.value});
    this.saveGlobalValue(this.ProcReportBottomMarginIndex, event.target.value);
  }

  handleTopMarginChange(event){
    this.setState({topMargin:event.target.value});
    this.saveGlobalValue(this.ProcReportTopMarginIndex, event.target.value);
  }

  handleOrientationChange(event) {
    this.setState({pageOrientation:event.value});
    this.saveGlobalValue(this.ProcReportPageOrientationIndex, event.value);
  }

  handleSizeChange(event){
    this.setState({pageSize:event.target.value});
    this.saveGlobalValue(this.ProcReportPageSizeIndex, event.target.value);
  }

  handlehtmlOptionsChange = (code) =>{
    this.setState( {htmlOptions :code});
    this.saveGlobalValue(this.ProcReportHtmlOptionsIndex, JSON.stringify(code.split("\n").filter(a=>a)));

  }

  render() {
      return (
        <div className="proc-report-ods">
          <PanelBar openId={["page-options"]}>
              <PanelBarItem id={"page-options"} title="Page Options">
                  <div className="page-options">
                      <div className="container-fluid p_15">
                          <div className="filter-area am-form-inline col-12 p-0">
                            <label className="am-form-field">
                              <span className="col-4"><b>Orientation:</b></span>
                              <StandardDropDown data={["Landscape", "Portrait"]} value={this.state.pageOrientation} onChange={this.handleOrientationChange} />
                            </label>
                            <label className="am-form-field">
                              <span className="col-4"><b>Size:</b></span>
                              <Input value={this.state.pageSize} onChange={this.handleSizeChange}/>
                            </label>
                            <label className="am-form-field">
                              <span className="col-4"><b>Top Margin:</b></span>
                              <Input value={this.state.topMargin} onChange={this.handleTopMarginChange}/>
                            </label>
                            <label className="am-form-field">
                              <span className="col-4"><b>Bottom Margin:</b></span>
                              <Input value={this.state.bottomMargin} onChange={this.handleBottomMarginChange}/>
                            </label>
                            <label className="am-form-field">
                              <span className="col-4"><b>left Margin:</b></span>
                              <Input value={this.state.leftMargin} onChange ={this.handleLeftMarginChange}/>
                            </label>
                            <label className="am-form-field">
                              <span className="col-4"><b>Right Margin:</b></span>
                              <Input value={this.state.rightMargin} onChange={this.handleRightMarginChange}/>
                            </label>
                          </div>
                      </div>
                  </div>
              </PanelBarItem>
              <PanelBarItem id={"format-options"} title={this.state.processFlowAction === DataOpsIdentifierConstants.R_Report_OP ? "Format Options" : "ODS Format Options"}>
                  <div className="ods-format-options">
                      <div className="container-fluid p_15">
                          <div className="filter-area am-form-inline col-12 p-0">
                            <label className="am-form-field">
                              <span className="col-4"><b>{this.state.processFlowAction === DataOpsIdentifierConstants.R_Report_OP ? "PDF Options:" : "PDF ODS Options:"}</b></span>
                              <div className="col-8">
                                <TextEditor options={{ lineNumbers: false }} code={this.state.pdfOptions} onChange={this.handlePdfOptionsChange}/>
                              </div>
                            </label>
                            <label className="am-form-field">
                              <span className="col-4"><b>{this.state.processFlowAction === DataOpsIdentifierConstants.R_Report_OP ? "RTF Options:" : "RTF ODS Options:"}</b></span>
                              <div className="col-8">
                                <TextEditor options={{ lineNumbers: false }} code={this.state.rtfOptions} onChange={this.handleRtfOptionsChange}/>
                              </div>
                            </label>
                            <label className="am-form-field">
                              <span className="col-4"><b>{this.state.processFlowAction === DataOpsIdentifierConstants.R_Report_OP ? "HTML Options:" : "HTML ODS Options:"}</b></span>
                              <div className="col-8">
                                <TextEditor options={{ lineNumbers: false }} code={this.state.htmlOptions} onChange={this.handlehtmlOptionsChange} />
                              </div>
                            </label>
                            <label className="am-form-field">
                              <span className="col-4"><b>{this.state.processFlowAction === DataOpsIdentifierConstants.R_Report_OP ? "XLS Options:" : "XLS ODS Options:"}</b></span>
                              <div className="col-8">
                                <TextEditor options={{ lineNumbers: false }} code={this.state.xlsOptions} onChange={this.handleXlsOptionsChange}/>
                              </div>
                            </label>
                          </div>
                      </div>
                  </div>
              </PanelBarItem>
              <PanelBarItem id={"general"} title="General">
                  <div className="general">
                      <div className="container-fluid p_15">
                          <div className="filter-area am-form-inline col-12 p-0">
                            <label className="am-form-field">
                              <span className="col-4"><b>Escape Character:</b></span>
                              <Input value={this.state.escapeCharacter} onChange={this.handleEscapeCharacterChange}/>
                            </label>
                          </div>
                      </div>
                  </div>
              </PanelBarItem>
          </PanelBar>
        </div>
      )
  }
}
