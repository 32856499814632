import React from "react";
import Dialog from 'react-dialog';
import axios from 'axios';
import { POST_PARAM_BODY } from '../../../../restClient/types';
import restClient from '../../../../restClient'
import $ from 'jquery';
import {Controlled as CodeMirror} from 'react-codemirror2';
import { connect } from 'react-redux';
import { PropTypes } from 'prop-types';
import { createSelector } from 'reselect';
import { setMessage } from '../../../../actions/actionNotification';
import MessageTypeConst from '../../../MessageTypeConst';
import { highLightText } from '../../TextEditor/TextEditor.jsx';

class LogDialog extends React.Component{
    constructor(props) {
        super(props);
        this.state ={
          log:'',
          errorCount: 0,
          warningCount: 0,
          tenantId:props.tenantId,
          processFlowTableId: props.processFlowTableId,
          processFlowList:props.processFlowList,
          activeProcessFlow:props.activeProcessFlow

        }
        this.instance = null;
        this.processBlock = this.getProcessBlock();
        this.title = "Log";
        if(this.processBlock !== null){
         if(this.processBlock.blockName !== "" && this.processBlock.blockName !== null){
            this.title = `${this.processBlock.blockName} (${this.processBlock.blockTable}) - Log`;
         }else{
            this.title = `${this.processBlock.blockTable} - Log`;
         }
        }
      }

    fetchlog = (headers, params) => restClient(POST_PARAM_BODY, 'dataviewer/log', params, headers);

    componentDidMount(){
        let that = this;
        var params = this.processBlock;
        let Tenant_ID = this.state.tenantId;
        $(".loader").show();
        axios.all([this.fetchlog({'Tenant-ID': Tenant_ID}, { data: params })])
               .then(axios.spread((logResponse) => {
                 if (typeof(logResponse.data) !== 'undefined') {
                   if (typeof(logResponse.data.log) !== 'undefined' && logResponse.data.log !== null) {
                        var str = logResponse.data.log.toString().toLowerCase();
                        var errorCount = str.match(/\berror(\s)*([a-z0-9-])*:/ig) !== null ? str.match(/\berror(\s)*([a-z0-9-])*:/ig).length : 0;
                        var warningCount = str.match(/\bwarning:/ig) !== null ? str.match(/\bwarning:/ig).length : 0;
                        this.setState({log:logResponse.data.log,errorCount:errorCount,warningCount:warningCount}, () => {
                            highLightText(str, that.instance.editor);
                        });
                   }
                }
                $(".loader").hide();
               }
            )
        ).catch(error => {
            $(".loader").hide();
            this.props.setMessage("Error occurred while fetching log.",MessageTypeConst.ERROR_MESSAGE);
          });
    }

    componentWillUnmount(){
        global.blockLog = "";
    }

    getProcessBlock() {
        var processBlock = [];
        if(this.state.processFlowList !== null && this.state.processFlowList.length >0 &&
            this.state.processFlowList[this.state.activeProcessFlow] !== null && typeof(this.state.processFlowList[this.state.activeProcessFlow]) !== "undefined"){
            let processFlow = this.state.processFlowList[this.state.activeProcessFlow].process;
            if(typeof(processFlow) !== 'undefined' && processFlow !== null){
                processBlock = processFlow.processBlock;
            }

        }
        //var processBlock = JSON.parse(localStorage.getItem("processBlock"));
        var processFlowTableId = parseInt(this.state.processFlowTableId, 10);
        var actionBlock = {};
        processBlock.forEach((block, index) => {
          if (processFlowTableId === block.blockId) {
            actionBlock = block
            return block;
          }
        })
        return actionBlock;
    }

    toggleDialog = () => {
        window.location.hash='#process-flow-list';
    }

    render(){
        return (
            <div className="data-operation code-dialog log-dialog">
            <div>
            <Dialog title={this.title} width="85%" height="calc(100vh - 100px)" modal={true} onClose={this.toggleDialog}>
                <div className="align-left">
                    <label className="warning">Warning: <span>{this.state.warningCount}</span></label>
                    <label className="error">Error: <span>{this.state.errorCount}</span></label>
                </div>
                <div className="log_Editor">
                    <CodeMirror ref = {ref => this.instance = ref} value={this.state.log} options = { {'lineNumbers': true, readOnly: true, mode:"javascript", styleSelectedText:true} }/>
                </div>
            </Dialog>
            </div>
            </div>
          );
    }
}

const mapStateToProps = createSelector(
    state => state.processFlow.processFlowList,
    state => state.processFlow.tenantId,
    state => state.processFlow.processFlowTable,
    state => state.processFlow.processFlowTableId,
    state => state.processFlow.activeProcessFlow,
    (processFlowList, tenantId, processFlowTable, processFlowTableId, activeProcessFlow) => ({
      processFlowList,
      tenantId,
      processFlowTable,
      processFlowTableId,
      activeProcessFlow
    })
  );

  const mapActionsToProps = {
    setMessage:setMessage
  }

  LogDialog.propTypes = {
    processFlowList: PropTypes.array,
    tenantId: PropTypes.string,
    processFlowTable: PropTypes.string,
    activeProcessFlow: PropTypes.number
  }
  Dialog.propTypes = {
      height: PropTypes.oneOfType([
          PropTypes.string
      ]),
      width: PropTypes.oneOfType([
          PropTypes.string
      ])
  }
  export default connect(mapStateToProps, mapActionsToProps)(LogDialog);
