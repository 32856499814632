import React from 'react';
import { DropDownList } from './StandardDropDownList';

export const InputTableDropDownCell = ({ tableManager, value, data, column, colIndex, rowIndex }) => {
    if (typeof(tableManager) === 'undefined') {
      return (null)
    } else {
      return (
          <div className='rgt-cell-inner' style={{alignItems: 'center', overflow: 'hidden'}}>
              <InputTableDropDownCellClass dataItem={data} field={column.field} value={typeof(data[column.field]) !== 'undefined' ? data[column.field]: value} column={column} tableManager={tableManager}/>
          </div>
      )
    }
}

class InputTableDropDownCellClass extends React.Component {
  handleChange(e) {
    let value  = e.target.value
    if (value !== '' && typeof(this.props.dataItem['inputTableNameList']) !== 'undefined' && this.props.dataItem['inputTableNameList'].length > 0) {
      let displayValue = this.props.dataItem['inputTableNameList'].filter(inputTable => inputTable.displayTableName === value && inputTable.type === (this.props.field === 'leftInputTable' ? 'source' : 'target'));

      if (displayValue.length > 0) {
        value = displayValue[0].tableName;
      }
    }

    if (typeof(this.props.dataItem['selectTables']) !== 'undefined') {
      let selectTables = this.props.dataItem['selectTables'];

      selectTables = selectTables.split(',');

      if (selectTables.indexOf(value) === -1) {
        selectTables.push(value);
        this.props.dataItem['selectTables'] = selectTables.filter(selectTable => selectTable !== this.props.dataItem[this.props.field]).join(',');
      }
    }

    if (typeof(this.props.dataItem.highlightFieldList) !== 'undefined' && typeof(global.executionPlanUserChangeList) !== 'undefined' && typeof(this.props.dataItem.inputTableNameList) !== 'undefined' && typeof(this.props.dataItem.systemSuggestedInputTables) !== 'undefined') {
      if (["leftInputTable", "rightInputTable"].indexOf(this.props.field) >= 0) {
        let inputTable = this.props.dataItem.systemSuggestedInputTables[0];

        if (this.props.field === "rightInputTable") {
          inputTable = this.props.dataItem.systemSuggestedInputTables[1];
        }

        if (this.props.dataItem.inputTableNameList.filter(inputTableName => inputTableName.tableName === inputTable ).length > 0) {
          global.executionPlanUserChangeList = global.executionPlanUserChangeList.filter(executionPlanUserChange => !(executionPlanUserChange.targetTable === this.props.dataItem.targetTable && executionPlanUserChange.sourceTable === this.props.dataItem.operationDetail.originalSourceTableName && executionPlanUserChange.inputTable === inputTable && executionPlanUserChange.field === this.props.field));
          if (value !== '' && inputTable !== value) {
            let bottomPosition = null;
            let originDisplayTargetTable = null;
            if (value.toLowerCase().indexOf('job ') === 0 && value.toLowerCase().indexOf('(output)') > 0 && typeof(this.props.dataItem.outputTableListWithBottomPosition) !== 'undefined' ) {
              let outputTableWithBottomPosition = this.props.dataItem.outputTableListWithBottomPosition.filter(outputTableList => outputTableList.outputTable === value);

              if (outputTableWithBottomPosition.length > 0) {
                bottomPosition = outputTableWithBottomPosition[0].bottomPosition
                originDisplayTargetTable = outputTableWithBottomPosition[0].originDisplayTargetTable
              }
            }
            global.executionPlanUserChangeList.push({
              targetTable: this.props.dataItem.targetTable,
              sourceTable: this.props.dataItem.operationDetail.originalSourceTableName,
              inputTable: inputTable,
              field: this.props.field,
              value: value,
              bottomPosition: bottomPosition,
              originDisplayTargetTable: originDisplayTargetTable
            })
          }
        }
      }
    }

    this.props.dataItem[this.props.field] = value;
    this.props.tableManager.rowsApi.setRows(this.props.tableManager.rowsApi.rows)

  }
  render() {
    let data = [];
    let value = this.props.dataItem[this.props.field];

    if (this.props.dataItem[this.props.field] !== '' && typeof(this.props.dataItem[this.props.field+'List']) !== 'undefined') {
      data = this.props.dataItem[this.props.field+'List'];
    }

    if (value !== '' && typeof(this.props.dataItem['inputTableNameList']) !== 'undefined' && this.props.dataItem['inputTableNameList'].length > 0) {
      let displayValue = this.props.dataItem['inputTableNameList'].filter(inputTable => inputTable.tableName === value);

      if (displayValue.length > 0) {
        value = displayValue[0].displayTableName;
      }
    }

    return (
        <div className={(typeof(this.props.dataItem.highlightFieldList) !== 'undefined' && this.props.dataItem.highlightFieldList.indexOf(this.props.field) > -1 && typeof(this.props.dataItem.systemSuggestedInputTables) !== 'undefined' && this.props.dataItem.systemSuggestedInputTables.indexOf(this.props.dataItem[this.props.field]) === -1) || (this.props.dataItem[this.props.field] !== '' && typeof(this.props.dataItem.systemSuggestedInputTables) !== 'undefined' && this.props.dataItem.systemSuggestedInputTables.indexOf(this.props.dataItem[this.props.field]) !== (this.props.field === 'leftInputTable' ? 0 : 1) && ["leftInputTable", "rightInputTable"].indexOf(this.props.field) >= 0 && !(this.props.dataItem[this.props.field].indexOf('pb_') === 0 && this.props.dataItem[this.props.field].indexOf('_v') > 3 && this.props.dataItem.systemSuggestedInputTables[this.props.field === 'leftInputTable' ? 0 : 1], this.props.dataItem[this.props.field], this.props.dataItem.systemSuggestedInputTables[this.props.field === 'leftInputTable' ? 0 : 1] === this.props.dataItem[this.props.field]) ) ? 'highlight-yellow' : ''}>
          {this.props.dataItem[this.props.field] !== '' &&
            <DropDownList className="width-full" onChange={this.handleChange.bind(this)} data={data} value={value}/>
          }
        </div>

      );
    }
}
