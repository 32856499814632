import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { DEFAULT_APP_VERSION } from './../../config';
import Dialog from 'react-dialog';

const propTypes = {
  children: PropTypes.node,
};

const defaultProps = {};

class DefaultFooter extends Component {

  constructor(props){
    super(props);
    this.state = {
      showDialog:false
    }
    this.closeDialog = this.closeDialog.bind(this);
    this.showDialog = this.showDialog.bind(this);
  }

  closeDialog(){
    this.setState({showDialog:false});
  }

  showDialog(){
    this.setState({showDialog:true});
  }

  render() {

    // eslint-disable-next-line
    const { children, ...attributes } = this.props;

    return (
      <React.Fragment>
        {/* <span className="ml-auto">Version
          <div className="version">{DEFAULT_APP_VERSION}</div>
          <button className="whatsNew" onClick={this.showDialog}> What's New ? </button>
        </span> */}
        {
          this.state.showDialog && <Dialog title={`Upcoming Features`} width="85%" height="calc(100vh - 100px)" modal={true} onClose={this.closeDialog} className="featureListDialog">
          <div className="featureListDialog">
          <ul>
            <li>Version Manager</li>
            <li>Execute All -> Group and Study Level</li>
          </ul>
          </div>
          </Dialog>

        }
      </React.Fragment>
    );
  }
}

DefaultFooter.propTypes = propTypes;
DefaultFooter.defaultProps = defaultProps;


Dialog.propTypes = {
    height: PropTypes.oneOfType([
        PropTypes.string
    ]),
    width: PropTypes.oneOfType([
        PropTypes.string
    ])
}
export default DefaultFooter;
