class AccessControlConstants {
    constructor(){
        this.CREATE_PROJECT = "Create Project";
        this.MANAGE_USERS_GROUPS = "Manage Users-Groups";
        this.PROJECT = 1;
        this.STUDY = 2;
        this.SOURCE_TABLE = 3;
        this.META_TABLE = 4;
        this.DOCUMENT = 5;
        this.PROCESS_FLOW_GROUP = 6;
        this.PROCESS_FLOW = 7;
        this.PROCESS_BLOCK = 8;
        this.SAVE_NULL = -1;
        this.GROUP = 1;
        this.USER = 2;
        this.WRITE = 1;
        this.VIEW = 2;
        this.DELEGATE = 3;
    }
}

export default (new AccessControlConstants());
