import React from "react";
import { Highlight, AreaHighlight } from "react-pdf-highlighter";
import Annotation from "./Annotation";
import Draggable from "./Draggable";

type Props = {};

const WrappedHighlight = (prop: Props) => {
  const {
    highlight,
    isScrolledTo,
    viewportToScaled,
    screenshot,
    updateHighlight,
    updateAnnotationPosition,
    removeHighlight
  } = prop;

  const isTextHighlight = !Boolean(
    highlight.content && highlight.content.image
  );

  const onDragEnd = (event) => {
    updateAnnotationPosition(highlight.id, event);
  }

  const component = isTextHighlight ? (
    <Highlight
      isScrolledTo={isScrolledTo}
      position={highlight.position}
      comment={highlight.comment}
    />
  ) : (
    <AreaHighlight
      highlight={highlight}
      onChange={(boundingRect) => {
        updateHighlight(
          highlight.id,
          { boundingRect: viewportToScaled(boundingRect) },
          { image: screenshot(boundingRect) }
        );
      }}
    />
  );

  return (
    <div
      className="Highlight--container"
      id={highlight.id}
      style={highlight.position.rects[0]}
    >
      {component}
      {highlight.data && typeof(highlight.data.title) !== 'undefined' && (
        <Draggable
          onDragEnd={onDragEnd}
        >
          <Annotation
            updateHighlight={(text) => updateHighlight(highlight.id, text)}
            removeHighlight={() => removeHighlight(highlight.id)}
            highlight={highlight}
          ></Annotation>
        </Draggable>
      )}
    </div>
  );
};

export default WrappedHighlight;
