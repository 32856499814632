import React from 'react';
import { SortOrder } from '../SortOrder'; 
import { GET_ALL } from '../../../../../restClient/types';
import restClient from '../../../../../restClient';
import axios from 'axios';
import $ from 'jquery';
import { connect } from 'react-redux';
import { PropTypes } from 'prop-types';
import { createSelector } from 'reselect';
import { setMessage } from '../../../../../actions/actionNotification';
import MessageTypeConst from "../../../../MessageTypeConst";

class Block extends React.Component {
  ProcReportBlockTabIndex  = 90;
  constructor(props) {
    super(props);
    let processFlowTable = global.updateBlock ? this.getGlobalValue(-97) :props.processFlowTable
    this.state = {
        tenantId:props.tenantId,
        processFlowTable:processFlowTable,
        table : {
            rows: []
          }
    }
  }

  componentDidMount(){
    $(".loader").show();
    let fecthBlockValues = (headers, params) => restClient(GET_ALL, 'report/list/blockValues', params, headers);
    var that = this;
    axios.all([fecthBlockValues({'Tenant-ID': this.state.tenantId},{'tableName':this.state.processFlowTable})])
      .then(axios.spread((response) => {
       if(typeof(response.data) !== 'undefined' && response.data !== "" && response.data !== null) {
          let table = that.state.table;
          table.rows.length = 0;
          let count = 0;
          response.data.forEach((element)=>{
            let item = {
                'gby':true,
                'column':element,
                'columnId':count+1
              } 
              count = count +1;
              table.rows.push(item);
          })
          that.setState({table:table});
          $(".loader").hide();
       }
      })).catch(error => {   
        $(".loader").hide();                 
        that.props.setMessage("Failed to fetch Block values.",MessageTypeConst.ERROR_MESSAGE);
      });    
  }
          
  getGlobalValue(controlIndex){
    let controlValue = '';
    global.processBlockControlValues.forEach((control, index) => {
      if (control.controlId === controlIndex) {
        controlValue = global.processBlockControlValues[index].controlValue;
  
      }
    });
    return controlValue;
  }
  
  saveGlobalValue(controlIndex, value){
    global.processBlockControlValues.forEach((control, index) => {
      if (control.controlId === controlIndex) {
        global.processBlockControlValues[index].controlValue = value;
      }
    });
  }

  render() {
      return (
        <div className="row blockSort">
            <div className="col-12">
                <SortOrder table={this.state.table} />
            </div>
        </div>
      )
  }
}

const mapStateToProps = createSelector(
    state => state.processFlow.tenantId,
    state => state.processFlow.processFlowTable,
    (tenantId, processFlowTable) => ({
      tenantId,
      processFlowTable,
    })
  );
  
  const mapActionsToProps = {
    setMessage: setMessage
  }
  
  Block.propTypes = {
    tenantId: PropTypes.string,
    processFlowTable: PropTypes.string,
  }
  
  export const BlockTab = connect(mapStateToProps, mapActionsToProps)(Block);