import React from 'react';
import { DropDownList } from './StandardDropDownList';

export const DecimalFormatCell = ({ tableManager, value, data, column, colIndex, rowIndex }) => {
    if (typeof(tableManager) === 'undefined') {
      return (null)
    } else {
      return (
          <div className='rgt-cell-inner' style={{display: 'flex', alignItems: 'center', overflow: 'hidden'}}>
              <DecimalFormatCellClass dataItem={data} field={column.field} value={typeof(data[column.field]) !== 'undefined' ? data[column.field]: value} column={column} tableManager={tableManager}/>
          </div>
      )
    }
}

class DecimalFormatCellClass extends React.Component {
    handleChange(e) {
      this.props.dataItem[this.props.field] = e.target.value;
      this.props.tableManager.rowsApi.setRows(this.props.tableManager.rowsApi.rows)
    }

    render() {
        var disabled = true;
        if (this.props.dataItem['type'] === 'CHAR' || this.props.dataItem['type'] === 'NUM') {
          disabled = false;
        }
        return (
            <>
              <DropDownList className="width-full" onChange={this.handleChange.bind(this)} disabled={disabled} data={["As is", "0(Truncate)", "0.0(Truncate)", "0.00(Truncate)", "0.000(Truncate)", "0.0000(Truncate)", "0(Round)", "0.0(Round)", "0.00(Round)", "0.000(Round)", "0.0000(Round)"]} value={this.props.dataItem[this.props.field]} />
            </>
        );
    }
}
