import React from 'react';

export const ColumnTitle = ({ tableManager, value, data, column, colIndex, rowIndex }) => {
    if (typeof(tableManager) === 'undefined') {
      return (null)
    } else {
      return (
          <div className='rgt-cell-inner' style={{display: 'flex', alignItems: 'center', overflow: 'hidden'}}>
              <ColumnTitleClass dataItem={data} field={column.field} value={typeof(data[column.field]) !== 'undefined' ? data[column.field]: value} column={column} tableManager={tableManager}/>
          </div>
      )
    }
}

class ColumnTitleClass extends React.Component {
  render() {
        return (
            <div className={typeof(this.props.dataItem.status) !== 'undefined' && this.props.dataItem.status === 0 ? 'remove-column' : 'new-column'}>
              {this.props.dataItem[this.props.field]}
            </div>
        );
    }
}
