import "./NavigationBarMenu.css";
import React from "react";
import { PropTypes } from "prop-types";
import {Treebeard, decorators} from 'react-treebeard';
import { ContextMenu, MenuItem, ContextMenuTrigger } from "react-contextmenu";
import Dialog from 'react-dialog';
import { Input } from './../DataOperation/common/InputBox/StandardInput';
import { Button } from './../DataOperation/common/Button/StandardButton';
import { DropDownList } from './../DataOperation/common/DropDown/StandardDropDownList';
import { TabStrip, TabStripTab  } from './../DataOperation/common/Layout';
import ExecuteAll from '../DataOperation/ExecuteAll/ExecuteAll';
import ExecuteAllTypeConst from '../DataOperation/ExecuteAll/ExecuteAllTypeConst';
import TextEditor from '../TextEditor';
import SourceMetaVersionManager from '../VersionManager/SourceMetaVersionManager';
import ProcessNodeVersionManager from '../VersionManager/ProcessNodeVersionManager';
import RootNodeVersionManager from '../VersionManager/RootNodeVersionManager';
import Export from '../Export/Export';
import Copying from '../Copying/Copying';
import copyIconImg from '../../ProcessFlow/img/copy_icon.png';
import StudyAssessment from '../Copying/StudyAssessment';
import GroupAssessment from '../Copying/GroupAssessment';
import ASSESSMENT_TYPE from '../Copying/AssessmentType';
import PermanentFlagEventType from '../../PermanentFlagEventType';
import FileManager from './../FileManager';
import StudyProperties from "../StudyProperrty/StudyProperties";
import ImportStudyDef from '../StudyDefinition/ImportStudyDef';
import AccessControl from './../UserManagement/AccessControl';
import AccessControlConstants from './../../AccessControlConstants';
import CreateTask from './../TaskManagement/CreateTask';
import TagManagement from './../TagManagement';
import MockTLFSpecs from './../MockTLFSpecs';
import ProcessFlowSorting from './ProcessFlowSorting';
import ExecuteAllNode from '../DataOperation/ExecuteAll/ExecuteAllNode';
import ProcessFlowGroupSorting from './ProcessFlowGroupSorting';
import StudySampleFilter from './../StudyProperrty/StudySampleFilter';
import SearchReplace from './SearchReplace';

function clickContextMenu(event){
  if(event.target !== null && typeof(event.target) !== "undefined"){
    event.target.classList.add("focusOnContextMenu");
  }
}

function hideContextMenu(event){
  let elements = document.getElementsByClassName("focusOnContextMenu");
  if(elements !== null && typeof(elements) !== "undefined"){
    for(let i =0 ;i<elements.length;i++){
      elements[i].classList.remove("focusOnContextMenu");
    }
  }
}


decorators.Loading = (props) => {
        return (
            <div className="loading" style={props.style}>
                loading...
            </div>
        );
}

decorators.Toggle = (props) => {
        return (
            <div style={props.style} className="triangle-wrapper">
                <div className="triangle">&nbsp;</div>
            </div>
        );
}

function collect(props) {
    return { node: props.node };
}

function template() {
  decorators.Header = ({style, node}) => {
      const iconClass = `${ typeof(node.iconClass) !== 'undefined' ? node.iconClass : ''}`;
      const iconStyle = {marginRight: '5px'};
      const cssClassName = `${ typeof(node.cssClassName) !== 'undefined' ? node.cssClassName : ''}`;
      const activeProcessClassName = `${ typeof(node.activeProcess) !== 'undefined' && node.activeProcess ? 'active-process-flow' : ''}`;
      return (
          <div style={style.base} className="item">
            <ContextMenuTrigger id={cssClassName} className={cssClassName} collect={collect} node={node}   >
              <div style={style.title} className={`${cssClassName} ${activeProcessClassName}${node.isCut === 1 ? ' node-cut' : ''}`} onContextMenu={clickContextMenu}>
              <i className={iconClass} style={iconStyle}/>
              {typeof(node.process) !== 'undefined' && typeof(node.process.isSampleFiltered) !== 'undefined' && node.process.isSampleFiltered === true ? <i className="fa fa-filter sample-filter-icon" aria-hidden="true"></i> : ""}
                  {node.name}
                  {typeof(node.iconClass) !== 'undefined' && ((node.iconClass === 'fa fa-briefcase' && typeof(node.projectId) !== 'undefined' && this.props.allProjectList.filter(project => project.projectId === node.projectId && typeof(project.enableActions) !== 'undefined' && project.enableActions.length === 1 && project.enableActions.indexOf(AccessControlConstants.VIEW) === 0).length > 0) || (node.iconClass === 'fa fa-folder-open' && typeof(node.study) !== 'undefined' && typeof(node.study.enableActions) !== 'undefined' && node.study.enableActions.length === 1 && node.study.enableActions.indexOf(AccessControlConstants.VIEW) === 0)) &&
                    <span className="badge badge-pill badge-primary read-only-icon">R</span>
                  }
                  {typeof(node.iconClass) !== 'undefined' && ((node.iconClass === 'fa fa-briefcase' && typeof(node.projectId) !== 'undefined' && this.props.allProjectList.filter(project => project.projectId === node.projectId && typeof(project.enableActions) !== 'undefined' && project.enableActions.length === 3 && project.enableActions.indexOf(AccessControlConstants.DELEGATE) > -1).length > 0) || (node.iconClass === 'fa fa-folder-open' && typeof(node.study) !== 'undefined' && typeof(node.study.enableActions) !== 'undefined' && node.study.enableActions.length === 3 && node.study.enableActions.indexOf(AccessControlConstants.DELEGATE) > -1)) &&
                    <span className="badge badge-pill badge-success delegate-only-icon">D</span>
                  }
                  {node.isCopy ? <img src={copyIconImg} className="copyIcon" alt=""/> : ""}
              </div>
            </ContextMenuTrigger>
          </div>
      );
  };

  /*
  <div className="hidden">
    <div className="input-group">
      <input className="form-control"
        onKeyUp={this.onFilterMouseUp.bind(this)}
        placeholder="Search the tree..."
        type="text"/>
    </div>
  </div>
  <div>
    <div className="input-group view-mode custom-switch">
      <Switch onLabel={"Tag"} offLabel={"Study"} checked={this.state.tagTreeViewStatus} onChange={this.handleTreeviewModeChangeEvent}/>
    </div>
  </div>
  */
  const isProjectCreatePermission = this.props.userPreference.userPermission.indexOf(AccessControlConstants.CREATE_PROJECT) > -1
  const isManageUsersGroupsPermission =  this.props.userPreference.userPermission.indexOf(AccessControlConstants.MANAGE_USERS_GROUPS) > -1
  return (
    <div className="navigation-bar-menu">

      <div className="am-treeview">
        <Treebeard data={this.state.displayTreeView} onToggle={this.onToggle} decorators={decorators} id={'navigation'} />

        {(isProjectCreatePermission || isManageUsersGroupsPermission) &&
          <ContextMenu id='manage-project' target={'.manage-project'} onHide={hideContextMenu} onShow={this.handleContextMenuAccessControl}>
            {isProjectCreatePermission &&
              <MenuItem onClick={this.toggleAddProjectDialog} >{'New Project'}</MenuItem>
            }
            {isManageUsersGroupsPermission &&
              <MenuItem onClick={this.handleAccessControl} >{'Access Control'}</MenuItem>
            }
          </ContextMenu>
        }
        <ContextMenu id='manage-study' target={'.manage-study'} onHide={hideContextMenu} onShow={this.handleContextMenuAccessControl}>
          <MenuItem onClick={this.toggleDialogAddStudy} className="write-ac" >{'New Study'}</MenuItem>
          <MenuItem divider className="write-ac" />
          <MenuItem onClick={this.handlePasteOnProject} className="write-ac" >{'Paste'}</MenuItem>
          <MenuItem divider className="write-ac" />
          <MenuItem onClick={this.handleRemoveProject} className="write-ac" >{'Delete'}</MenuItem>
          <MenuItem divider className="write-ac" />
          <MenuItem onClick={this.handleImportStudyDef} className="write-ac" >{'Import Clone Definition'}</MenuItem>
          <MenuItem divider className="write-ac" />
          <MenuItem onClick={this.handleAccessControl} className="delegate-ac" >{'Access Control'}</MenuItem>
		      <MenuItem divider className="delegate-ac" />
          <MenuItem onClick={this.toggleRenameProjectDialog} className="write-ac"  >{'Properties'}</MenuItem>
        </ContextMenu>
        <ContextMenu id='manage-study-by-tag' target={'.manage-study-by-tag'} onHide={hideContextMenu} onShow={this.handleContextMenuAccessControl}>
          <MenuItem onClick={this.toggleDialogAddStudy} className="write-ac" >{'New Study'}</MenuItem>
          <MenuItem divider className="write-ac" />
          <MenuItem onClick={this.handlePasteOnProject} className="write-ac" >{'Paste'}</MenuItem>
          <MenuItem divider className="write-ac" />
          <MenuItem onClick={this.handleImportStudyDef} className="write-ac" >{'Import Clone Definition'}</MenuItem>
        </ContextMenu>
        <ContextMenu id='manage-study-property' target={'.manage-study-property'} onHide={hideContextMenu} onShow={this.handleContextMenuAccessControl}>
          <MenuItem onClick={this.handleRefreshStudy} className="write-ac" >{'Refresh'}</MenuItem>
          <MenuItem divider className="write-ac" />
          <MenuItem onClick={this.handleSampleFilterStudy} className="write-ac" >{'Sample Filter'}</MenuItem>
          <MenuItem divider className="write-ac" />
          <MenuItem onClick={this.handleCopyStudy} className="write-ac" >{'Copy'}</MenuItem>
          <MenuItem onClick={this.handlePasteOnStudy} className="write-ac" >{'Paste'}</MenuItem>
          <MenuItem divider className="write-ac" />
          <MenuItem onClick={this.handleRemoveStudy} className="write-ac" >{'Delete'}</MenuItem>
          <MenuItem divider className="write-ac"  />
          <MenuItem onClick={this.handleSearchReplaceStudy} className="write-ac" >{'Search/Replace'}</MenuItem>
          <MenuItem divider className="write-ac"  />
          <MenuItem onClick={(event, data) => this.handleExecuteAllAction(event, data, 'Study')} className="write-ac" >{'Execute All (Batch)'}</MenuItem>
          <MenuItem divider className="write-ac" />
          <MenuItem onClick={this.handleSyncOnStudy} >{'Compare (Study Process Flow Group)'}</MenuItem>
          <MenuItem onClick={this.handleVersionDifference} >{'Compare (Study Data)'}</MenuItem>
          <MenuItem divider />
          <MenuItem onClick={(event, data) => this.handleFlagPermanent(event, data, PermanentFlagEventType.STUDY)} className="write-ac" >{'Flag Permanent'}</MenuItem>
          <MenuItem divider className="write-ac" />
          <MenuItem onClick={this.handleExportStudy} >{'Export Object'}</MenuItem>
          <MenuItem onClick={this.handleExportStudyDef} >{'Export Clone Definition'}</MenuItem>
          <MenuItem divider className="write-ac" />
          <MenuItem onClick={this.handleCreateMockTLFSpecs} className="write-ac">{'Create Mock TLF specs'}</MenuItem>
          <MenuItem divider className="write-ac" />
          <MenuItem onClick={this.handleCreateTask} className="write-ac">{'Create Task'}</MenuItem>
          <MenuItem divider className="write-ac" />
          <MenuItem onClick={this.handleAccessControl} className="delegate-ac" >{'Access Control'}</MenuItem>
          <MenuItem divider className="delegate-ac" />
          <MenuItem onClick={this.handleManageStudyProperty}>{'Properties'}</MenuItem>
        </ContextMenu>
        <ContextMenu id='manage-study-source-table' target={'.manage-study-source-table'} onHide={hideContextMenu} onShow={this.handleContextMenuAccessControl}>
          <MenuItem onClick={this.handleImportSourceTable} className="write-ac" >{'Import Source Table (Local)'}</MenuItem>
          <MenuItem onClick={this.handleImportSourceTableFromServer} className="write-ac" >{'Import Source Table (Server)'}</MenuItem>
          <MenuItem divider className="write-ac" />
          <MenuItem onClick={this.handleRootManageVersion} className="write-ac" >{'Manage Version'}</MenuItem>
          <MenuItem onClick={(event, data) => this.handleFlagPermanent(event, data, PermanentFlagEventType.SOURCE)} className="write-ac" >{'Flag Permanent'}</MenuItem>
        </ContextMenu>
        <ContextMenu id='manage-study-meta-table' target={'.manage-study-meta-table'} onHide={hideContextMenu} onShow={this.handleContextMenuAccessControl}>
          <MenuItem onClick={this.handleAddMetaTable} className="write-ac" >{'Create Meta Table'}</MenuItem>
          <MenuItem divider className="write-ac" />
          <MenuItem onClick={this.handleImportMetaTable} className="write-ac" >{'Import Meta Table (Local)'}</MenuItem>
          <MenuItem onClick={this.handleImportSourceTableFromServer} className="write-ac" >{'Import Meta Table (Server)'}</MenuItem>
          <MenuItem divider className="write-ac" />
          <MenuItem onClick={this.handleRootManageVersion} className="write-ac" >{'Manage Version'}</MenuItem>
          <MenuItem onClick={(event, data) => this.handleFlagPermanent(event, data, PermanentFlagEventType.METADATA)} className="write-ac" >{'Flag Permanent'}</MenuItem>
        </ContextMenu>
        <ContextMenu id='manage-study-edit-source-table' target={'.manage-study-edit-source-table'} onHide={hideContextMenu} onShow={this.handleContextMenuAccessControl}>
          <MenuItem onClick={this.handleRemoveTable} className="write-ac" >{'Delete'}</MenuItem>
          <MenuItem divider className="write-ac" />
          <MenuItem onClick={this.handleManageVersion} className="write-ac" >{'Manage Version'}</MenuItem>
        </ContextMenu>
        <ContextMenu id='manage-study-documents' target={'.manage-study-documents'} onHide={hideContextMenu} onShow={this.handleContextMenuAccessControl}>
        <MenuItem onClick={this.handleImportDocuments} className="write-ac" >{'Import Documents'}</MenuItem>
        </ContextMenu>

        <ContextMenu id='manage-study-edit-documents' target={'.manage-study-edit-documents'} onHide={hideContextMenu} onShow={this.handleContextMenuAccessControl}>
        <MenuItem onClick={this.handleDownloadWithTagClick} >{'Download With Tag'}</MenuItem>
        <MenuItem onClick={this.handleDeleteDocuments} className="write-ac" >{'Delete'}</MenuItem>
        </ContextMenu>

        <ContextMenu id='manage-study-edit-meta-table' target={'.manage-study-edit-meta-table'} onHide={hideContextMenu} onShow={this.handleContextMenuAccessControl}>
          <MenuItem onClick={this.handleEditMetaTable} className="write-ac" >{'Edit Meta Table'}</MenuItem>
          <MenuItem divider className="write-ac" />
          <MenuItem onClick={this.handleRemoveTable} className="write-ac" >{'Delete'}</MenuItem>
          <MenuItem divider className="write-ac" />
          <MenuItem onClick={this.handleManageVersion} className="write-ac" >{'Manage Version'}</MenuItem>
        </ContextMenu>
        <ContextMenu id='manage-study-classifier-group' target={'.manage-study-classifier-group'} onHide={hideContextMenu} onShow={this.handleContextMenuAccessControl}>
          <MenuItem onClick={(event, data) => this.handleExecuteAllAction(event, data, 'ProcessFlows')} className="write-ac" >{'Execute All (Batch)'}</MenuItem>
        </ContextMenu>
        <ContextMenu id='manage-study-process-flow-group' target={'.manage-study-process-flow-group'} onHide={hideContextMenu} onShow={this.handleContextMenuAccessControl}>
          <MenuItem onClick={this.toggleAddProcessGroupDialog} className="write-ac" >{'New Process Flow Group'}</MenuItem>
          <MenuItem divider className="write-ac" />
          <MenuItem onClick={(event, data) => this.handleExecuteAllAction(event, data, 'Classifier')} className="write-ac" >{'Execute All (Batch)'}</MenuItem>
            <MenuItem divider className="write-ac" />
            <MenuItem onClick={this.handleProcessFlowGroupSorting} className="write-ac" >{'Process Flow Group Sorting'}</MenuItem>
        </ContextMenu>
        <ContextMenu id='manage-study-process-flow' target={'.manage-study-process-flow'} onHide={hideContextMenu} onShow={this.handleContextMenuAccessControl}>
          <MenuItem onClick={this.toggleAddProcessFlowDialog} className="write-ac"  >{'New Process Flow'}</MenuItem>
          <MenuItem divider className="write-ac"  />
          <MenuItem onClick={(event, data) => this.handleGroupExecuteAll(event, data, false)} className="write-ac" >{'Execute All (Batch)'}</MenuItem>
          <MenuItem onClick={(event, data) => this.handleGroupExecuteAll(event, data, true)} className="write-ac" >{'Execute All (Interactive)'}</MenuItem>
          <MenuItem divider className="write-ac" />
          <MenuItem onClick={this.handleCopyProcessFlowGroup} className="write-ac" >{'Copy'}</MenuItem>
          <MenuItem onClick={this.handlePasteOnGroup} className="write-ac" >{'Paste'}</MenuItem>
          <MenuItem divider className="write-ac" />
          <MenuItem onClick={this.handleRemoveProcessFlowGroup} className="write-ac" >{'Delete'}</MenuItem>
          <MenuItem divider className="write-ac" />
          <MenuItem onClick={this.handleExportProcessGroup} >{'Export Object'}</MenuItem>
          <MenuItem divider />
          <MenuItem onClick={this.handleSyncOnGroup} >{'Group Compare'}</MenuItem>
          <MenuItem divider className="write-ac" />
          <MenuItem onClick={this.handleCreateTask} className="write-ac">{'Create Task'}</MenuItem>
          <MenuItem divider className="write-ac" />
          <MenuItem onClick={this.handleProcessFlowSorting} className="write-ac">{'Process Flow Sorting'}</MenuItem>
          <MenuItem divider />
          <MenuItem onClick={this.toggleRenameProcessGroupDialog}>{'Properties'}</MenuItem>
        </ContextMenu>
        <ContextMenu id='manage-study-process' target={'.manage-study-process'} onHide={hideContextMenu} onShow={this.handleContextMenuAccessControl}>
          <MenuItem onClick={(event, data) => this.handleExecuteAllWarning(event, data, false)} className="write-ac" >{'Execute All (Batch Warning)'}</MenuItem>
          <MenuItem onClick={(event, data) => this.handleExecuteAllWarning(event, data, true)} className="write-ac" >{'Execute All (Interactive Warning)'}</MenuItem>
          <MenuItem divider className="write-ac" />
          <MenuItem onClick={(event, data) => this.handleExecuteAll(event, data, false)} className="write-ac" >{'Execute All (Batch)'}</MenuItem>
          <MenuItem onClick={(event, data) => this.handleExecuteAll(event, data, true)} className="write-ac" >{'Execute All (Interactive)'}</MenuItem>
          <MenuItem divider className="write-ac" />
          <MenuItem onClick={this.handleCopyProcessFlow} className="write-ac" >{'Copy'}</MenuItem>
          <MenuItem onClick={this.handleCopyProcessFlowLink} className="write-ac" >{'Copy Link'}</MenuItem>
          <MenuItem divider className="write-ac" />
          <MenuItem onClick={this.handleCutProcessFlow} className="write-ac" >{'Cut'}</MenuItem>
          <MenuItem divider className="write-ac" />
          <MenuItem onClick={this.handleRemoveProcess} className="write-ac" >{'Delete'}</MenuItem>
          <MenuItem divider className="write-ac" />
          <MenuItem onClick={this.handleExportProcessFlow} >{'Export Object'}</MenuItem>
          <MenuItem divider className="write-ac" />
          <MenuItem onClick={this.handleProcessFlowManageVersion} className="write-ac" >{'Manage Version'}</MenuItem>
          <MenuItem onClick={(event, data) => this.handleFlagPermanent(event, data, PermanentFlagEventType.FLOW)} className="write-ac" >{'Flag Permanent'}</MenuItem>
		      <MenuItem divider className="write-ac" />
          <MenuItem onClick={this.handleCreateTask} className="write-ac" >{'Create Task'}</MenuItem>
          <MenuItem divider />
          <MenuItem onClick={this.toggleRenameProcessFlowDialog}>{'Properties'}</MenuItem>
        </ContextMenu>
      </div>

      <input type="file" name="importSource_table" id="import_source_table_files" ref="import_source_meta_table" multiple onChange={this.handleImportFilesTableFilesChange} accept=".csv,.xpt,.sas7bdat,.xls,.xlsx"/>
      <form action={this.state.url} method="POST" id="downloadForm">
                        <input type="hidden" name="fileName" id="fileName" value={this.state.fileName}/>
                        <input type="hidden" name="filePath" id="filePath" value={this.state.filePath}/>
                    </form>
      <input type="file" name="import_documents" id="import_documents_files" ref="import_documents" multiple onChange={this.handleImportDocumentsFilesChange}/>
      <div className={this.state.executeAllDialogStatus ? 'study-properties-main executeallproperties': (this.state.importDialogStatus || this.state.importDocumentDialogStatus) ? 'study-properties-main importLog' : 'study-properties-main'} >
      {this.state.visibleAddStudy && <Dialog className="study-properties" title={"New Study"} width="450px" height="auto" modal={true} onClose={this.toggleDialogAddStudy} buttons={[<Button primary={true} onClick={this.handleSaveStudy} disabled={this.state.addStudyButtonDisable} key="btn-ok">OK</Button>,<Button onClick={this.toggleDialogAddStudy} key="btn-cancel">Cancel</Button>]}>

                  <div className="am-form-line-left">
                      <label className="pro-label col-sm-3"><span >Study Name </span></label>
                      <Input className='text-box-display study-width-text' type="text" id="txtStudyName" ref="txtStudyName" name="txtStudyName"
                      onChange={this.handleStudyNameChange}  autoFocus={true} onKeyDown={this.enterKeyPressForAddStudy} value={this.state.study.studyName} />
                  </div>
                  <div className="am-form-line-left">
                      <label className="pro-label col-sm-3"><span >Identifier 1 </span></label>
                      <Input className="text-box-display study-width-text" name="txtIdentifier1" defaultValue={this.state.study.identifier1} disabled />
                  </div>
                  <div className="am-form-line-left">
                      <label className="pro-label col-sm-3"><span >Identifier 2</span></label>
                      <Input className="text-box-display study-width-text" name="txtIdentifier2" defaultValue={this.state.study.identifier2} disabled />
                  </div>
                  <div className="am-form-line-left">
                      <label className="pro-label col-sm-3"><span >Identifier 3 </span></label>
                      <Input type="text" className="text-box-display study-width-text" name="txtIdentifier3" id="txtIdentifier3" ref="txtIdentifier3" defaultValue={this.state.study.identifier3}
                      onChange={this.handleIdentifierThreeChange} onKeyDown={this.enterKeyPressForAddStudy}/>
                  </div>
                  <div className="am-form-line-left">
                      <label className="pro-label col-sm-3"><span >Classifier </span></label>
                      <Input type="text" className="text-box-display study-width-text" id="txtIdClassifier" value={this.state.study.classifier}
                      onChange={event => this.handleStudyNameChange(event, 'classifier')} onKeyDown={this.enterKeyPressForAddStudy}/>
                  </div>
                  <div className="am-form-line-left">
                      <label className="pro-label col-sm-3"><span >Description </span></label>
                      <textarea className="text-box-display am-textbox textarea-box study-width-text" id="txtIdDescription" value={this.state.study.description}
                      onChange={event => this.handleStudyNameChange(event, 'description')} onKeyDown={this.enterKeyPressForAddStudy}/>
                  </div>


      </Dialog>
      }

      {this.state.visiblePropertyStudy && <StudyProperties closeEvent={this.toggleDialogStudyProperty} studyProperty={this.state.studyProperty} handleRenameStudy={this.handleRenameStudy} node={this.state.triggerNode}>
      </StudyProperties>
      }

      { this.state.importDialogStatus && <Dialog title={"Import Files"} width="470px" height="auto" modal={true} onClose={this.toggleImportDialog} buttons={[<Button primary={true} className="k-button" onClick={this.toggleImportDialog} disabled={this.state.disabledOkButton} key="btn-ok">OK</Button>]}>
                          {[...this.state.importFiles, ...this.state.invalidFileList].map((file, index) => (
                            <div key={index} className="am-form-line-left clearfix bottom-line">
                                <div className="col-sm-8 float-left"><span title={`${file.name}`} >{`${index+1}. ${file.name}`} </span></div>
                                <div className="col-sm-4 float-right">
                                <div className={`${file.status} float-left`}></div>
                                <Button className="k-button" value={index} color="info" onClick={this.showLog}> Log</Button>
                                </div>
                            </div>
                            )
                          )}
                          </Dialog>
                        }
                        { this.state.importDocumentDialogStatus && <Dialog title={"Import Documents"} width="470px" height="auto" modal={true} onClose={this.toggleImportDocumentDialog} buttons={[<Button primary={true} className="k-button" onClick={this.toggleImportDocumentDialog} disabled={this.state.disabledOkButton} key="btn-ok">OK</Button>]}>
                          {this.state.importFiles.map((file, index) => (
                            <div key={index} className="am-form-line-left clearfix bottom-line">
                                <div className="col-sm-8 float-left"><span title={`${file.name}`}>{`${index+1}. ${file.name}`} </span></div>
                                <div className="col-sm-4 float-left">
                                  <div className={`${file.status} float-left`}></div>
                                </div>
                            </div>
                            )
                          )}
                          </Dialog>
                        }
        { this.state.executeAllDialogStatus &&
            <Dialog  title={this.state.executeAllInteractiveStatus === false ? (this.state.executeAllType === ExecuteAllTypeConst.ProcessFlowLevelExecuteAllWarning ? 'Execute All (Batch Warning)': 'Execute All (Batch)') : (this.state.executeAllType === ExecuteAllTypeConst.ProcessFlowLevelExecuteAllWarning ? 'Execute All (Interactive Warning)': 'Execute All (Interactive)')} width="calc(100% - 10px)" height="calc(100vh - 30px)" modal={true} onClose={this.closeExecuteAllDialog} buttons={[<Button primary={true} onClick={this.executeJobs}autoFocus={true} disabled={this.state.disabledExecuteButton} key="btn-execute"> Execute</Button>,<Button onClick={this.closeExecuteAllDialog} disabled={this.state.disabledCloseButton} key="btn-close"> Close</Button>]}>

              <div className="data-properties">
              <ExecuteAll executeAllType={this.state.executeAllType} process={this.state.executeAllDataNode.process}
              ref = {ref => this.executeAllRef = ref}  studyId={this.state.executeAllDataNode.studyId} processGroupId={this.state.executeAllDataNode.processGroupId}
              projectId={this.state.executeAllDataNode.projectId} schemaName={this.state.executeAllDataNode.schemaName} updateJobs={this.updateJobs} closeEvent={this.closeExecuteAllDialog}/>
              </div>
              </Dialog>
        }
        { this.state.showLogPopUp &&
          <Dialog className="data-properties" title={'Logs'} width="auto" height="auto" modal={true} onClose={this.toggleLogPopUp} buttons={[<Button primary={true} onClick={this.toggleLogPopUp} key="btn-ok"> OK</Button>]}>
            <div>
            <div className="log_Editor">
            <TextEditor  options={{ lineNumbers: true, readOnly: true}} code={this.state.logData} />
          </div>

            </div>
          </Dialog>
      }
      {this.state.addRenameTitlePopupVisible &&
        <div className={`${this.state.selected === 1 ? 'study-properties-dialog' : ''}`}>
          <Dialog className="study-properties" title={this.state.addRenameTitleEventRaiseBy === this.RENAME_STUDY_EVENT ? 'Rename Study' :
          this.state.addRenameTitleEventRaiseBy === this.ADD_PROJECT_EVENT ? 'New Project' :
          this.state.addRenameTitleEventRaiseBy === this.RENAME_PROJECT_EVENT ? 'Project Properties'  :
          this.state.addRenameTitleEventRaiseBy === this.ADD_PROCESS_GROUP_EVENT ? 'Add Process Flow Group' :
          this.state.addRenameTitleEventRaiseBy === this.RENAME_PROCESS_GROUP_EVENT ? 'Process Flow Group Properties' :
          this.state.addRenameTitleEventRaiseBy === this.ADD_PROCESS_FLOW_EVENT ? 'Add Process Flow' :
          this.state.addRenameTitleEventRaiseBy === this.RENAME_PROCESS_FLOW_EVENT ? 'Process Flow Properties' : ''} width="450px" height="auto" modal={true} onClose={this.toggleAddRenameTitleDialog} buttons={[<Button primary={true} onClick={this.handleAddRenameOKButton} disabled={this.state.addStudyButtonDisable || this.state.readOnlyAccess} key="btn-ok"> OK</Button>,<Button onClick={this.toggleAddRenameTitleDialog} key="btn-cancel"> Cancel</Button>]}>
          {(this.state.addRenameTitleEventRaiseBy === this.RENAME_PROCESS_GROUP_EVENT || this.state.addRenameTitleEventRaiseBy === this.RENAME_PROCESS_FLOW_EVENT) &&
            <TabStrip keepTabsMounted={true} selected={this.state.selected} onSelect={this.handleSelect}>
              <TabStripTab title="General">
                <div className="am-form-line-left">
                    <label className="pro-label col-sm-4"><span >
                    {this.state.addRenameTitleEventRaiseBy === this.RENAME_STUDY_EVENT ? 'Study' :
                    this.state.addRenameTitleEventRaiseBy === this.ADD_PROJECT_EVENT ? 'Project' :
                    this.state.addRenameTitleEventRaiseBy === this.RENAME_PROJECT_EVENT ? 'Project' :
                    this.state.addRenameTitleEventRaiseBy === this.ADD_PROCESS_GROUP_EVENT ? 'Process Flow Group' :
                    this.state.addRenameTitleEventRaiseBy === this.RENAME_PROCESS_GROUP_EVENT ? 'Process Flow Group' :
                    this.state.addRenameTitleEventRaiseBy === this.ADD_PROCESS_FLOW_EVENT ? 'Process Flow' :
                    this.state.addRenameTitleEventRaiseBy === this.RENAME_PROCESS_FLOW_EVENT ? 'Process Flow' : ''} </span></label>
                    <Input className={`text-box-display study-width-text type-${this.state.addRenameTitleEventRaiseBy}`} type="text" id="txtName" ref="txtName" name="txtName" onFocus={this.selectedInput}
                    onChange={this.handleAddRenameTitleChange}  autoFocus={true} onKeyDown={this.enterKeyPressForAddRenameTitle} value={this.state.title} />

                  {this.state.addRenameTitleEventRaiseBy === this.ADD_PROJECT_EVENT && false &&
                      <div>
                        <label className="pro-label col-sm-4"><span>
                          Classifier
                        </span></label>
                        <Input className='text-box-display' type="text" onChange={this.handleAddRenameClassifierChange} onKeyDown={this.enterKeyPressForAddRenameTitle} value={this.state.classifier} />
                      </div>

                    }
                    {this.state.addRenameTitleEventRaiseBy === this.RENAME_PROCESS_GROUP_EVENT &&
                      <div>
                        <label className="pro-label col-sm-4"><span>
                          Group Classifier
                        </span></label>
                      <span className="m-l-28">
                          <DropDownList data={this.state.groupClassifierList} textField="classifierName" dataItemKey="classifierId" value={this.state.selectedGroupClassifier} onChange={this.handleChangeGroupClassifierEvent} />
                        </span>
                      </div>
                    }
                </div>
              </TabStripTab>
              <TabStripTab title="Tag">
                <TagManagement schemaName={this.state.triggerNode.schemaName} node={this.state.triggerNode}/>
              </TabStripTab>
            </TabStrip>
          }
          {!(this.state.addRenameTitleEventRaiseBy === this.RENAME_PROCESS_GROUP_EVENT || this.state.addRenameTitleEventRaiseBy === this.RENAME_PROCESS_FLOW_EVENT) &&
            <div className="am-form-line-left">
                <label className={`pro-label col-sm-2 type-${this.state.addRenameTitleEventRaiseBy}`}><span >
                {this.state.addRenameTitleEventRaiseBy === this.RENAME_STUDY_EVENT ? 'Study' :
                this.state.addRenameTitleEventRaiseBy === this.ADD_PROJECT_EVENT ? 'Project' :
                this.state.addRenameTitleEventRaiseBy === this.RENAME_PROJECT_EVENT ? 'Project' :
                this.state.addRenameTitleEventRaiseBy === this.ADD_PROCESS_GROUP_EVENT ? 'Process Flow Group' :
                this.state.addRenameTitleEventRaiseBy === this.RENAME_PROCESS_GROUP_EVENT ? 'Process Flow Group' :
                this.state.addRenameTitleEventRaiseBy === this.ADD_PROCESS_FLOW_EVENT ? 'Process Flow' :
                this.state.addRenameTitleEventRaiseBy === this.RENAME_PROCESS_FLOW_EVENT ? 'Process Flow' : ''} </span></label>
              <Input className={`text-box-display study-width-text type-${this.state.addRenameTitleEventRaiseBy}`} type="text" id="txtName" ref="txtName" name="txtName" onFocus={this.selectedInput}
                onChange={this.handleAddRenameTitleChange}  autoFocus={true} onKeyDown={this.enterKeyPressForAddRenameTitle} value={this.state.title} />

              {this.state.addRenameTitleEventRaiseBy === this.ADD_PROJECT_EVENT && false &&
                  <div>
                    <label className="pro-label col-sm-4"><span>
                      Classifier
                    </span></label>
                    <Input className='text-box-display' type="text" onChange={this.handleAddRenameClassifierChange} onKeyDown={this.enterKeyPressForAddRenameTitle} value={this.state.classifier} />
                  </div>

                }
                {this.state.addRenameTitleEventRaiseBy === this.RENAME_PROCESS_GROUP_EVENT &&
                  <div>
                    <label className="pro-label col-sm-4"><span>
                      Group Classifier
                    </span></label>
                  <span className="m-l-28">
                      <DropDownList data={this.state.groupClassifierList} textField="classifierName" dataItemKey="classifierId" value={this.state.selectedGroupClassifier} onChange={this.handleChangeGroupClassifierEvent} />
                    </span>
                  </div>
                }
            </div>
          }

              </Dialog>
        </div>

    }
    {
      this.state.showVersion &&
      <SourceMetaVersionManager closeEvent={this.closeManageVersionDialog} srcTableName={this.state.triggerNode.name}
      tableType={this.state.triggerNode.tableType} schemaName={this.state.triggerNode.schemaName} projectId ={this.state.triggerNode.projectId}
      studyId={this.state.triggerNode.studyId}/>
    }

    {
      this.state.showRootVersion &&
      <RootNodeVersionManager closeEvent={this.closeRootManageVersionDialog}
      tableType={this.state.triggerNode.name === "Source Table"  ? 0 : 1}  schemaName={this.state.triggerNode.schemaName} studyId={this.state.triggerNode.studyId}/>
    }

    {
      this.state.showProcessVersion &&
      <ProcessNodeVersionManager closeEvent={this.closeProcessFlowManageVersionDialog} process={this.state.triggerNode.process}
      schemaName={this.state.triggerNode.schemaName} studyId={this.state.triggerNode.studyId}/>
    }
    {
      this.state.exportNode &&
      <Export closeEvent={this.toggleExportNode} params={this.state.triggerNode}/>
    }
    {
      this.state.showCopyDialog &&
      <Copying closeEvent={this.closeCopyDialog} copySource={this.state.copySource} copyTarget={this.state.copyTarget} />

    }
    {
      (this.state.showCopyAssessmentDialog && this.state.assessmentNode.copyAssesmentType === ASSESSMENT_TYPE.GROUP_ASSESSMENT) &&
      <GroupAssessment closeEvent={this.closeCopyAssessmentDialog} assessmentNode={this.state.assessmentNode} />

    }
    {
      (this.state.showCopyAssessmentDialog && this.state.assessmentNode.copyAssesmentType === ASSESSMENT_TYPE.STUDY_ASSESSMENT) &&
      <StudyAssessment closeEvent={this.closeCopyAssessmentDialog} assessmentNode={this.state.assessmentNode} />

    }
    {
      this.state.flagPermanentDialog &&
      <Dialog className="study-properties" title={"Flag Permanent"} width="auto" height="auto" modal={true} onClose={this.closeFlagPermanentDialog} buttons={[<Button primary={true} onClick={this.handlePermanentFlagOKButton} key="btn-ok"> OK</Button>,<Button onClick={this.closeFlagPermanentDialog} key="btn-cancel"> Cancel</Button>]}>

                    <div className="am-form-line-left">
                      <label className="pro-label col-sm-12"><span >{this.state.permanentMessage}</span></label>
                    </div>
                  <div className={`am-form-line-left row flag-type-${this.state.permanentFlagType}`}>
                      <label className="pro-label title"><span >Tag </span></label>
                      <Input className='text-box-display full-width' type="text" id="txtTag" ref="txtTag" name="txtTag" onFocus={this.selectedInput}
                      onChange={this.handlePermanentTagChange}  autoFocus={true} onKeyDown={this.enterKeyPressForPermanentTag} value={this.state.permanentTag} />
                  </div>

            </Dialog>
    }
    {this.state.visibleImportTableFromServerDialogStatus &&
      <div className="import-table-from-server-dialog">
        <Dialog title={"Import Table From Server"} width="87%" height="87%" modal={true} onClose={this.toggleDialogImportSourceTableFromServer} buttons={[<Button primary={true} onClick={this.handleImportSelectedFileFromServerClick} disabled={this.state.selectFileFromServer.length === 0 ? true: false} key="btn-import-table">Import Table</Button>,<Button onClick={this.toggleDialogImportSourceTableFromServer} key="btn-cancel">Cancel</Button>]}>
            <FileManager node={this.state.triggerNode} onSelectFile={this.handleSelectFileFromServer} />
        </Dialog>
      </div>

    }
    {
      this.state.visibleImportStudyDef &&
      <ImportStudyDef node={this.state.triggerNode.node} closeEvent={this.closeImportStudyDef} />
    }

    {
    this.state.createTaskDialogStatus === true &&
      <CreateTask node={this.state.triggerNode} closeEvent={this.handleCreateTask} />
    }

    {this.state.accessControlDialog &&
      <div className="access-control-dialog">
        <AccessControl node={this.state.triggerNode} onClose={this.handleAccessControl}/>
      </div>
    }
    {this.state.createMockTLFSpecsDialog === true &&
      <MockTLFSpecs node={this.state.triggerNode} closeEvent={this.handleCreateMockTLFSpecs} />
    }
    {this.state.processFlowSortingDialog === true &&
      <ProcessFlowSorting node={this.state.triggerNode} closeEvent={this.handleProcessFlowSorting} />
    }
    {this.state.processFlowGroupSortingDialog === true &&
      <ProcessFlowGroupSorting node={this.state.triggerNode} closeEvent={this.handleProcessFlowGroupSorting} />
    }
    {this.state.executeAllNode.dialogStatus === true &&
      <ExecuteAllNode ref = {ref => this.executeAllRef = ref} node={this.state.executeAllNode.node} nodeType={this.state.executeAllNode.nodeType} classifier={this.state.executeAllNode.classifier} toggleDialog={this.handleExecuteAllAction}/>
    }
    {this.state.sampleFilterDialog === true &&
      <StudySampleFilter node={this.state.triggerNode} updateProcessFlowAndProcessBlockStatus={this.updateProcessFlowAndProcessBlockStatus} closeEvent={this.handleSampleFilterStudy} />
    }
    {this.state.searchReplaceDialog === true &&
      <SearchReplace node={this.state.triggerNode} closeEvent={this.handleSearchReplaceStudy} />
    }

      </div>
    </div>
  );

};
Dialog.propTypes = {
    height: PropTypes.oneOfType([
        PropTypes.string
    ]),
    width: PropTypes.oneOfType([
        PropTypes.string
    ])
}

export default template;
