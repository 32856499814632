import { ANNOTATE_MANAGE_COLUMN_MAPPED_LIST, ANNOTATE_SET_COLUMN_MAPPING_TARGET_ID, ANNOTATE_ADD_WHERE_CONDITION, ANNOTATE_REMOVE_WHERE_CONDITION, ANNOTATE_ADD_HARDCODE, ANNOTATE_REMOVE_HARDCODE, ANNOTATE_GET_SOURCE_MAPPING_LIST_BY_TARGET_NODE, ANNOTATE_ADD_EXPRESSION, ANNOTATE_REMOVE_EXPRESSION, ANNOTATE_RESET_STATE, ANNOTATE_SET_TABLE_EXTENDEDLIST, ANNOTATE_MANAGE_MAPPINGGRID_EXPRESSION, ANNOTATE_ADD_MULTIPLE_COLUMN_MAPPED_LIST, ANNOTATE_RESET_BULK_COLUMN_MAPPED_LIST, ANNOTATE_SET_TABLE_TYPE_LIST, ANNOTATE_ADD_RENAME_COLUMN, ANNOTATE_REMOVE_RENAME_COLUMN, ANNOTATE_UPDATE_COLUMN_DATA, ANNOTATE_SET_HOLD_ANNOTATE_DATA, ANNOTATE_HOLD_ANNOTATE_DATA_TARGET_COLUMN_OPERATION_LIST_AND_JOB_LIST, ANNOTATE_SET_DEFAULT_TARGET_COLUMN_EXPRESSION, ANNOTATE_SET_TREE_ELEMENT, ANNOTATE_ADD_APPLY_PROCESS_FLOW, ANNOTATE_REMOVE_APPLY_PROCESS_FLOW, ANNOTATE_ADD_MULTIPLE_RENAME_COLUMN, ANNOTATE_ADD_MULTIPLE_HARDCODE_COLUMN, ANNOTATE_ADD_TABLE_SORT_COLUMN, ANNOTATE_UPDATE_TREE_ELEMENT_VIA_IMMULTABLE, ANNOTATE_REMOVE_MULTIPLE_EXPRESSION, ANNOTATE_ADD_PIVOT_MATRIX_LIST, ANNOTATE_MANAGE_HARDCODE_LIST, ANNOTATE_ADD_MULTIPLE_APPLY_PROCESS_FLOW, ANNOTATE_ADD_MULTIPLE_TABLE_SORT_COLUMN, ANNOTATE_MANAGE_TABLE_VERSION_CHANGE, ANNOTATE_MANAGE_MULTIPLE_TARGET_TABLE_LIST, ANNOTATE_RESET_TARGET_COLUMN_LIST, ANNOTATE_SOURCE_TABLE_LIST_TYPE, ANNOTATE_TARGET_TABLE_LIST_TYPE, ANNOTATE_SET_HIGHLIGHTLIST, ANNOTATE_REMOVE_BLOCK_CLASSIFIER_DETAILS, ANNOTATE_MANAGE_MAPPING_SPECS_DETAIL, ANNOTATE_MANAGE_WHERE_MATRIX, ANNOTATE_MANAGE_LAST_EXECUTE_ENGINE_VALUE, ANNOTATE_MANAGE_APPLY_PROCESS_FLOW, ANNOTATE_MANAGE_TARGET_TABLE_VALUE_IN_EXPRESSION, ANNOTATE_MANAGE_ADDITIONAL_WHERE_CONDITION, ANNOTATE_MANAGE_DOCUMENT_NAME, ANNOTATE_MANAGE_CUSTOM_UNMAP_VALUE_LIST, ANNOTATE_REFRESH_TLF_VIEWER } from '../actions/actionsTypes';

const INITIAL_DATA = {
  columnMappedList:[],
  columnMappingTargetId: null,
  whereConditionList: [],
  hardcodeList: [],
  expressionList: [],
  tableExtendedList:[],
  treeElement: {},
  bulkColumnMappedList: false,
  sourceTableType: 'source_tables',
  targetTableType: 'metadata_tables',
  renameColumnList: [],
  updateBlock: false,
  holdAnnotateData: {},
  targetColumnOperationList: [],
  jobList: {},
  applyProcessFlowList: [],
  tableList:{},
  lastMappedTargetColumnId: null,
  tableSortColumns: [],
  pivotMatrixList: [],
  virtualTargetTableList: [],
  selectedBlockClassifier: [],
  selectedTargetClassifier: 'Meta',
  highlightList: [],
  classifierList: [],
  executionPlanUserChangeList: [],
  mappingSpecsDetail: {
    status: false,
    createdAt: null,
    data: [],
    fetchStatus: false
  },
  whereMatrixList: [],
  lastExecuteEngineValue: {},
  additionalWhereConditionList: [],
  annotateDocumentName: '',
  customUnmapValueList: [],
  refreshTlfViewerStatus: false,
  refreshStudyNodeStatus: false,
  autoType: 'mapper'
};

export default function annotateReducer(state=INITIAL_DATA, action) {
  let tableExtendedList;
  let metadataTableTree;
  let sourceTableTree;
  let expressionList;
  let pivotMatrixList;
  let hardcodeList;
  let whereConditionList ;
  let columnMappedList;
  let renameColumnList;
  let applyProcessFlowList;
  let tableSortColumns;
  let virtualTargetTableList;
  let whereMatrixList;
  let customUnmapValueList;
  let additionalWhereConditionList;

  switch (action.type) {
    case ANNOTATE_MANAGE_COLUMN_MAPPED_LIST:
    expressionList = state.expressionList
    pivotMatrixList = state.pivotMatrixList;
    metadataTableTree = state.treeElement.metadataTableTree;
    sourceTableTree = state.treeElement.sourceTableTree;

    if (action.payload.filter((columnMap) => columnMap.mappingType === 'value-value').length > 0) {
      expressionList.map(expression => {
        expression.expressionDetail.map(tableExpression => {
          if (tableExpression.mappingGrid.length > 0) {
            tableExpression.mappingGrid.map(valueGrid => {
                let valueMapped = action.payload.filter((columnMap) => columnMap.targetId === expression.node.id && columnMap.table.target === expression.node.tableName && columnMap.table.source === tableExpression.tableName && columnMap.columnTitle.source === valueGrid.source)
                if (valueMapped.length > 0) {
                  valueGrid.target = valueMapped[0].columnTitle.target
                }
                return valueGrid
            })
          }

          return tableExpression
        })

        return expression
      })
    }

    if (action.payload.length === 0) {
      expressionList = []
      pivotMatrixList = [];
      if (typeof(sourceTableTree) !== 'undefined') {
        sourceTableTree.children.map((table) => {
          table.cssClassName = table.cssClassName.replace(new RegExp(' highlight-table', 'gi'), '').trim();

          return table;
        })
      }
      if (typeof(metadataTableTree) !== 'undefined') {
        metadataTableTree.children.map((table) => {
          table.cssClassName = table.cssClassName.replace(new RegExp(' highlight-table', 'gi'), '').trim();
          table.children.filter(column => column.expression === true).map((column) => {
              column.removeMappedLine = false;
              column.expression = false;
              column.cssClassName = 'metadata-contextmenu-hardcode-expression';
              column.columnStatus = '';

              return column
          })

          return table;
        })
      }
    }
    return {
      ...state,
      columnMappedList: action.payload,
      expressionList: expressionList,
      treeElement: {
        ...state.treeElement,
        metadataTableTree,
        sourceTableTree
      },
      pivotMatrixList: pivotMatrixList,
      applyProcessFlowList: updateApplyProcessFlowList(state.applyProcessFlowList, state.tableExtendedList, action.payload, state.hardcodeList)
    }

    case ANNOTATE_SET_COLUMN_MAPPING_TARGET_ID:

      return {
        ...state,
        columnMappingTargetId: action.payload
      }

    case ANNOTATE_ADD_WHERE_CONDITION:
      whereConditionList = state.whereConditionList.filter(whereCondition => whereCondition.id !== action.payload.id).slice();
      whereConditionList.splice(whereConditionList.length, 0, action.payload);

      return {
      ...state,
      whereConditionList: whereConditionList
      }

    case ANNOTATE_REMOVE_WHERE_CONDITION:

      return {
        ...state,
        whereConditionList: state.whereConditionList.filter(whereCondition => whereCondition.id !== action.payload)
      }

    case ANNOTATE_ADD_HARDCODE:
      hardcodeList = state.hardcodeList.filter(hardcode => hardcode.id !== action.payload.id).slice();
      hardcodeList.splice(hardcodeList.length, 0, action.payload);

      return {
        ...state,
        hardcodeList: hardcodeList,
        applyProcessFlowList: updateApplyProcessFlowList(state.applyProcessFlowList, state.tableExtendedList, state.columnMappedList, hardcodeList)
      }

    case ANNOTATE_REMOVE_HARDCODE:
      hardcodeList = state.hardcodeList.filter(hardcode => hardcode.id !== action.payload)
      return {
        ...state,
        hardcodeList: hardcodeList,
        applyProcessFlowList: updateApplyProcessFlowList(state.applyProcessFlowList, state.tableExtendedList, state.columnMappedList, hardcodeList)
      }

    case ANNOTATE_ADD_EXPRESSION:
      pivotMatrixList = state.pivotMatrixList
      expressionList = state.expressionList.filter(expression => expression.id !== action.payload.id).slice();
      expressionList.splice(expressionList.length, 0, action.payload);

      //pivotMatrixList = updatePivotMatrix(pivotMatrixList, expressionList.filter(expression => expression.id === action.payload.id))

      return {
        ...state,
        expressionList: expressionList,
        pivotMatrixList: pivotMatrixList
      }

    case ANNOTATE_REMOVE_EXPRESSION:

      return {
        ...state,
        expressionList: state.expressionList.filter(expression => expression.id !== action.payload)
      }
    case ANNOTATE_REMOVE_MULTIPLE_EXPRESSION:
      let multiplExpressionList = state.expressionList;
      let multiplhHardcodeList = state.hardcodeList;
      metadataTableTree = state.treeElement.metadataTableTree;
      action.payload.forEach(expressionId => {
        multiplExpressionList = multiplExpressionList.filter(expression => expression.id !== expressionId)
        multiplhHardcodeList = multiplhHardcodeList.filter(expression => expression.id !== expressionId)
      })

      if (typeof(metadataTableTree) !== 'undefined') {
        metadataTableTree.children.map((table) => {
          table.children.filter(column => action.payload.indexOf(column.id) > -1 ).map((column) => {
              column.removeMappedLine = false;
              column.expression = false;
              column.hardcode = false;
              column.expressionIntermediate = false;
              column.renameColumn = false;
              column.pivotOperationColumn = false;
              column.applyProcessFlow = false;
              column.cssClassName = 'metadata-contextmenu-hardcode-expression';
              column.columnStatus = '';

              return column
          })

          if (table.children.filter(column => column.expression === true).length === 0) {
            table.cssClassName = table.cssClassName.replace(new RegExp(' highlight-table', 'gi'), '').trim();
          }

          return table;
        })
      }

      return {
        ...state,
        expressionList: multiplExpressionList,
        hardcodeList: multiplhHardcodeList,
        treeElement: {
          ...state.treeElement,
          metadataTableTree
        },
        applyProcessFlowList: updateApplyProcessFlowList(state.applyProcessFlowList, state.tableExtendedList, state.columnMappedList, multiplhHardcodeList)
      }
    case ANNOTATE_SET_TABLE_EXTENDEDLIST:
      sourceTableTree = action.payload.treeElement.sourceTableTree;
      metadataTableTree = action.payload.treeElement.metadataTableTree;
      if (typeof(action.payload.treeElement) !== 'undefined' && typeof(sourceTableTree) !== 'undefined' && typeof(metadataTableTree) !== 'undefined') {
        sourceTableTree.children.map(treeTable => {
          treeTable.whereCondition = state.whereMatrixList.filter(whereMatrix => whereMatrix.sourceTable === treeTable.tableName && metadataTableTree.children.filter(table => table.tableName === whereMatrix.tableName).length > 0).length > 0;
          return treeTable;
        })

        metadataTableTree.children.map(treeTable => {
          treeTable.whereCondition = state.whereMatrixList.filter(whereMatrix => whereMatrix.tableName === treeTable.tableName && sourceTableTree.children.filter(table => table.tableName === whereMatrix.sourceTable).length > 0).length > 0;
          return treeTable;
        })
      }

      return {
        ...state,
        tableExtendedList: action.payload.tableExtendedList,
        treeElement: {
            ...action.payload.treeElement,
            sourceTableTree: sourceTableTree,
            metadataTableTree: metadataTableTree
        },
        classifierList: typeof(action.payload.classifierList) !== 'undefined' && action.payload.classifierList.length > 0 ? action.payload.classifierList: state.classifierList
      }
    case ANNOTATE_RESET_STATE:
      return {
        ...state,
        ...INITIAL_DATA,
        columnMappedList:[],
        columnMappingTargetId: null,
        whereConditionList: [],
        hardcodeList: [],
        expressionList: [],
        tableExtendedList:[],
        treeElement: {},
        bulkColumnMappedList: false,
        sourceTableType: 'source_tables',
        targetTableType: 'metadata_tables',
        renameColumnList: [],
        updateBlock: false,
        holdAnnotateData: {},
        targetColumnOperationList: [],
        jobList: {},
        applyProcessFlowList: [],
        tableList:{},
        lastMappedTargetColumnId: null,
        tableSortColumns: [],
        pivotMatrixList: [],
        whereMatrixList: [],
        virtualTargetTableList: [],
        mappingSpecsDetail: {
          status: false,
          createdAt: null,
          data: [],
          fetchStatus: false
        },
        customUnmapValueList: [],
        autoType: 'mapper'
      }
    case ANNOTATE_MANAGE_MAPPINGGRID_EXPRESSION:
      state.expressionList.forEach((expression, index) => {
        if (expression.id === action.payload.id) {
          expression.expressionDetail.map((tableExpression, tableIndex) => {
            if (tableExpression.tableName === action.payload.tableName) {
              tableExpression.mappingGrid = action.payload.mappingGrid;

              if(typeof(tableExpression.targetValues) === 'undefined') {
                tableExpression.targetValues = [];
              }

              if (tableExpression.mappingGrid.length > 0 && typeof(tableExpression.mappingGrid[0].targetValues) !== 'undefined' && tableExpression.mappingGrid[0].targetValues.length > 0) {
                tableExpression.mappingGrid[0].targetValues.filter(targetValue => tableExpression.targetValues.filter(currentTargetValue => currentTargetValue.toLowerCase() === targetValue.toLowerCase()).length === 0).forEach(targetValue => {
                    tableExpression.targetValues.push(targetValue)
                })
                tableExpression.targetValues = [... new Set(tableExpression.targetValues)];
                tableExpression.mappingGrid.map(mapping => mapping.targetValues.length = 0)
              }
            }
            return tableExpression;
          })
        }
      });

    return {
      ...state,
      expressionList: state.expressionList
    }
    case ANNOTATE_ADD_MULTIPLE_COLUMN_MAPPED_LIST:
    let { leftPersistentTableTree } = state.treeElement;
    sourceTableTree = state.treeElement.sourceTableTree
    metadataTableTree = state.treeElement.metadataTableTree
    pivotMatrixList = state.pivotMatrixList
    columnMappedList = state.columnMappedList;
    expressionList = state.expressionList;
    whereMatrixList = state.whereMatrixList;
    additionalWhereConditionList = state.additionalWhereConditionList;

    if (action.payload.unmapAll === true) {
      columnMappedList = [];
      pivotMatrixList = [];
      whereMatrixList = [];
      additionalWhereConditionList = [];
    } else {
      let targetColumnList = [];
      let uniqueSourceTableNameList = [];
      let targetValueColumnIdList = []
      action.payload.mappedColumnList.forEach(mappedColumn => {
        targetColumnList = targetColumnList.filter(targetColumn => targetColumn !== mappedColumn.node.target);
        targetColumnList.push(mappedColumn.node.target);

        if (mappedColumn.targetId !== null && targetValueColumnIdList.indexOf(mappedColumn.targetId) === -1) {
          targetValueColumnIdList.push(mappedColumn.targetId)
        }
      })

      if (action.payload.targetColumnReset === true) {
        targetColumnList.forEach(targetColumn => {
          columnMappedList = columnMappedList.filter(columnMapped => columnMapped.node.target !== targetColumn);
        })
      }

      if (action.payload.targetColumnValueReset === true) {
        targetValueColumnIdList.forEach(targetValueColumnId => {
          columnMappedList = columnMappedList.filter(columnMapped => columnMapped.targetId !== targetValueColumnId);
        })
      }

      if (action.payload.removeSourceTableValues === true && action.payload.sourceTableList.length > 0) {
        columnMappedList = columnMappedList.filter(columnMapped => !(columnMapped.mappingType === 'value-value' && columnMapped.targetId !== null && action.payload.sourceTableList.indexOf(columnMapped.table.source) > -1));
      }

      action.payload.mappedColumnList.forEach((mappedColumn) => {
        if (columnMappedList.filter(columnMapped => columnMapped.node.target === mappedColumn.node.target && columnMapped.node.source === mappedColumn.node.source ).length === 0) {
          if (mappedColumn.mappingType === 'value-value') {
            columnMappedList = columnMappedList.filter(columnMapped => !(columnMapped.mappingType === 'value-value' && columnMapped.table.source === mappedColumn.table.source && columnMapped.table.target === mappedColumn.table.target && columnMapped.parentColumn.target.column_header === mappedColumn.parentColumn.target.column_header && columnMapped.parentColumn.source === mappedColumn.parentColumn.source && columnMapped.columnTitle.target.toLowerCase() === mappedColumn.columnTitle.target.toLowerCase() && columnMapped.columnTitle.source.toLowerCase() === mappedColumn.columnTitle.source.toLowerCase()))
          } else if (mappedColumn.mappingType === 'value-column') {
            columnMappedList = columnMappedList.filter(columnMapped => !(columnMapped.mappingType === 'value-column' && columnMapped.table.source === mappedColumn.table.source && columnMapped.table.target === mappedColumn.table.target && columnMapped.column.target.column_header === mappedColumn.column.target.column_header && columnMapped.parentColumn.source === mappedColumn.parentColumn.source && columnMapped.columnTitle.target.toLowerCase() === mappedColumn.columnTitle.target.toLowerCase() && columnMapped.columnTitle.source.toLowerCase() === mappedColumn.columnTitle.source.toLowerCase()))
          }
          columnMappedList.push(mappedColumn)
        }
      });
    }

    if (typeof(metadataTableTree) !== '') {
      columnMappedList.forEach((columnMapped) => {
        metadataTableTree.children.forEach((table) => {
          if (table.tableName === columnMapped.table.target) {
            if (table.cssClassName.indexOf('highlight-table') === -1 && table.toggled === false) {
              table.cssClassName = table.cssClassName + ' highlight-table';
            }

            table.children.forEach((column) => {
              if (column.id === columnMapped.node.target) {
                if (column.cssClassName.indexOf('contextmenu-hardcode-expression') > -1) {
                  column.cssClassName = column.cssClassName.replace('contextmenu-hardcode-expression', 'contextmenu-expression');
                } else if (column.cssClassName.indexOf('contextmenu-hardcode') > -1) {
                  column.cssClassName = column.cssClassName.replace('contextmenu-hardcode', 'contextmenu-expression');
                } else if (column.cssClassName.indexOf('contextmenu-intermediate-expression') > -1) {
                  column.cssClassName = column.cssClassName.replace('contextmenu-intermediate-expression', 'contextmenu-expression');
                }
                column.removeMappedLine = true;
                column.expression = true;
              }
            })
          }
        })
      });

      if (action.payload.unmapAll === true) {
        metadataTableTree.children.forEach((table) => {
          table.cssClassName = table.cssClassName.replace(new RegExp(' highlight-table', 'gi'), '').trim();
          table.carryForward = false;
          table.removeMappedLine = false;
          table.children.forEach((column) => {
              column.removeMappedLine = false;
              column.expression = false;
              column.hardcode = false;
              column.expressionIntermediate = false;
              column.renameColumn = false;
              column.pivotOperationColumn = false;
              column.applyProcessFlow = false;
              column.cssClassName = 'metadata-contextmenu-hardcode-expression';
              column.columnStatus = '';
              column.isBlink = false;
          })
        })
      }
    }

    if (sourceTableTree !== '') {
      columnMappedList.forEach((columnMapped) => {
        sourceTableTree.children.forEach((table) => {
          if (table.tableName === columnMapped.table.source) {
            if (table.cssClassName.indexOf('highlight-table') === -1 && table.toggled === false) {
              table.cssClassName = table.cssClassName + ' highlight-table';
            }
          }
        })
      })

      if (action.payload.unmapAll === true) {
        sourceTableTree.children.forEach((table) => {
          table.cssClassName = table.cssClassName.replace(new RegExp(' highlight-table', 'gi'), '').trim();
          table.children.forEach((column) => {
              column.removeMappedLine = false;
              column.expression = false;
              column.hardcode = false;
              column.expressionIntermediate = false;
              //column.renameColumn = false;
              column.pivotOperationColumn = false;
              column.applyProcessFlow = false;
              column.cssClassName = 'source-contextmenu-rename-column';
              column.isBlink = false;
          })
        })
      }
    }

    if (action.payload.pivotMatrixList.length > 0) {
      action.payload.pivotMatrixList.forEach(autoPivotMatrix => {
        let pivotMatrix = pivotMatrixList.filter(pivotMatrix => pivotMatrix.sourceTable === autoPivotMatrix.sourceTable && pivotMatrix.tableName === autoPivotMatrix.tableName);

        if (pivotMatrix.length === 0) {
          pivotMatrixList.push(autoPivotMatrix)
        } else {
          pivotMatrix.map(matrix => {
            matrix.maximumGroupLength = matrix.maximumGroupLength < autoPivotMatrix.maximumGroupLength ? autoPivotMatrix.maximumGroupLength :  matrix.maximumGroupLength
            autoPivotMatrix.rows.forEach(autoRow => {
              let row = matrix.rows.filter(row => row.targetColumnTitle === autoRow.targetColumnTitle);
              if (row.length === 0) {
                matrix.rows.push(autoRow)
              } else {
                row.map(childRow => {
                  for(var autoKey in autoRow) {
                    if(autoKey.indexOf('group') > -1) {
                      let newGroupTitle = autoRow[autoKey]
                      let addGroup = true;
                      for(var key in childRow) {
                        if(key.indexOf('group') > -1 && childRow[key] === newGroupTitle) {
                          addGroup = false;
                        }
                      }

                      if (addGroup) {
                        matrix.maximumGroupLength++;
                        childRow["group"+matrix.maximumGroupLength] = newGroupTitle
                      }
                    }
                  }
                  return childRow;
                })
              }
            })

            matrix.rows.map(row => {
              for(var i = 1; i <= matrix.maximumGroupLength; i++) {
                const groupTitle = 'group'+i;
                if (typeof(row[groupTitle]) === 'undefined') {
                  row[groupTitle] = '""'
                  row.blankCell = row.blankCell.filter(group => group !== groupTitle);
                  row.blankCell.push(groupTitle)
                }
              }

              return row
            })
            return matrix
          })
        }
      })
    }

    if (action.payload.unmapAll === false && columnMappedList.filter((columnMap) => columnMap.mappingType === 'value-value').length > 0) {
      expressionList.map(expression => {
        expression.expressionDetail.map(tableExpression => {
          if (tableExpression.mappingGrid.length > 0) {
            let valueMappedCount = 0;
            tableExpression.mappingGrid.map(valueGrid => {
                let valueMapped = columnMappedList.filter((columnMap) => columnMap.targetId === expression.node.id && columnMap.table.target === expression.node.tableName && columnMap.table.source === tableExpression.tableName && columnMap.columnTitle.source === valueGrid.source)
                if (valueMapped.length > 0) {
                  valueGrid.target = valueMapped[0].columnTitle.target
                  valueMappedCount++;
                }
                return valueGrid
            })

            metadataTableTree.children.filter(table => table.tableName === expression.node.tableName).map((table) => {
              table.children.filter(column => column.id === expression.id && typeof(column.columnCTStatus) !== 'undefined' && column.columnCTStatus !== '').map((column) => {
                if (column.columnCTStatus === 'Yes') {
                  column.columnStatus = valueMappedCount === 0 ? 'yellow' : '';
                } else if(column.columnCTStatus === 'No') {
                  column.columnStatus = valueMappedCount < tableExpression.mappingGrid.length ? 'red' : '';
                }
                expression.node = column;
                return column;
              })
              return table
            })

          }

          if (typeof(tableExpression.mappingGrid) !== 'undefined') {
            columnMappedList = columnMappedList.filter((columnMap) => !(columnMap.targetId === expression.node.id && columnMap.table.target === expression.node.tableName && columnMap.table.source === tableExpression.tableName && tableExpression.mappingGrid.filter(valueGrid => valueGrid.source === columnMap.columnTitle.source).length === 0) )
          }

          return tableExpression
        })

        return expression
      })
    }

    action.payload.whereMatrixList.forEach(autoWhereMatrix => {
      let isNewWhereMatrix = true;
      whereMatrixList.filter(whereMatrix => whereMatrix.tableName === autoWhereMatrix.tableName && whereMatrix.sourceTable === autoWhereMatrix.sourceTable).map(whereMatrix => {
        whereMatrix.whereCondition = autoWhereMatrix.whereCondition;
        isNewWhereMatrix = false;
        return whereMatrix;
      })

      if (isNewWhereMatrix === true) {
        whereMatrixList.push(autoWhereMatrix)
      }
    })

    if (typeof(sourceTableTree) !== 'undefined' && typeof(metadataTableTree) !== 'undefined') {
      sourceTableTree.children.map(treeTable => {
        treeTable.whereCondition = whereMatrixList.filter(whereMatrix => whereMatrix.sourceTable === treeTable.tableName && metadataTableTree.children.filter(table => table.tableName === whereMatrix.tableName).length > 0).length > 0;
        return treeTable;
      })

      metadataTableTree.children.map(treeTable => {
        treeTable.whereCondition = whereMatrixList.filter(whereMatrix => whereMatrix.tableName === treeTable.tableName && sourceTableTree.children.filter(table => table.tableName === whereMatrix.sourceTable).length > 0).length > 0;
        return treeTable;
      })
    }

    return {
      ...state,
      columnMappedList: columnMappedList,
      treeElement: {
        ...state.treeElement,
        sourceTableTree,
        metadataTableTree,
        leftPersistentTableTree
      },
      bulkColumnMappedList: true,
      //renameColumnList: action.payload.unmapAll === false ? state.renameColumnList: [],
      hardcodeList: action.payload.unmapAll === false ? state.hardcodeList: [],
      //whereConditionList: action.payload.unmapAll === false ? state.whereConditionList: [],
      expressionList: action.payload.unmapAll === false ? state.expressionList: [],
      applyProcessFlowList: action.payload.unmapAll === false ? updateApplyProcessFlowList(state.applyProcessFlowList, state.tableExtendedList, columnMappedList, state.hardcodeList): [],
      pivotMatrixList: pivotMatrixList,
      whereMatrixList: whereMatrixList,
      lastMappedTargetColumnId: action.payload.unmapAll === false ? state.lastMappedTargetColumnId: null,
      additionalWhereConditionList: additionalWhereConditionList
    }
    case ANNOTATE_RESET_BULK_COLUMN_MAPPED_LIST:
    return {
      ...state,
      bulkColumnMappedList: action.payload
    }
    case ANNOTATE_SET_TABLE_TYPE_LIST:
    return {
      ...state,
      sourceTableType: action.payload.type === 'source' ? action.payload.tableType : state.sourceTableType,
      targetTableType: action.payload.type === 'target' ? action.payload.tableType : state.targetTableType
    }

    case ANNOTATE_ADD_RENAME_COLUMN:
      renameColumnList = state.renameColumnList.filter(renameColumn => renameColumn.id !== action.payload.id).slice();
      renameColumnList.splice(renameColumnList.length, 0, action.payload);

      return {
        ...state,
        renameColumnList: renameColumnList
      }

    case ANNOTATE_REMOVE_RENAME_COLUMN:

      return {
        ...state,
        renameColumnList: state.renameColumnList.filter(renameColumn => renameColumn.id !== action.payload)
      }

    case ANNOTATE_UPDATE_COLUMN_DATA:
      let selectedBlockClassifier = state.selectedBlockClassifier
      if (typeof(action.payload.selectedBlockClassifier) !== 'undefined' && typeof(action.payload.selectedBlockClassifier.classifierName) !== 'undefined' && !Array.isArray(action.payload.selectedBlockClassifier)) {
        selectedBlockClassifier.push(action.payload.selectedBlockClassifier.classifierName)
      } else if (typeof(action.payload.selectedBlockClassifier) !== 'undefined') {
        selectedBlockClassifier = action.payload.selectedBlockClassifier
      }
      return {
          ...state,
          ...action.payload,
          selectedBlockClassifier: selectedBlockClassifier
      }

    case ANNOTATE_SET_HOLD_ANNOTATE_DATA:
      return {
        ...state,
        holdAnnotateData: action.payload
      }

    case ANNOTATE_HOLD_ANNOTATE_DATA_TARGET_COLUMN_OPERATION_LIST_AND_JOB_LIST:
      return {
        ...state,
        targetColumnOperationList: action.payload.targetColumnOperationList,
        jobList: action.payload.jobList,
        holdAnnotateData: action.payload.holdAnnotateData
      }

    case ANNOTATE_SET_DEFAULT_TARGET_COLUMN_EXPRESSION:
      expressionList = state.expressionList
      pivotMatrixList = state.pivotMatrixList
      if (typeof(state.treeElement.metadataTableTree) !== 'undefined') {
        action.payload.columnExpressionList.forEach((columnExpression) => {
          state.treeElement.metadataTableTree.children.forEach((table) => {
            if (table.children.length > 0) {
              let nodeList = table.children.filter(nodeList => nodeList.id === columnExpression.id);
              if (nodeList.length > 0) {
                expressionList = expressionList.filter(expression => expression.id !== columnExpression.id).slice();
                expressionList.splice(expressionList.length, 0, Object.assign({}, columnExpression, {node: nodeList[0]}));
              }
            }
          })
        })
      }

      expressionList = expressionList.filter(expression => expression.expressionDetail.length > 0)

      if (action.payload.pivotMatrixUpdate === true) {
        //pivotMatrixList = updatePivotMatrix(pivotMatrixList, expressionList.filter(expression => expression.id === action.payload.id))
      }

      expressionList.map(expression => {
        expression.expressionDetail.filter(tableExpression => tableExpression.tableOperation === 'Pivot').map(tableExpression => {
          let pivotMatrix = pivotMatrixList.filter(pivotMatrix => pivotMatrix.tableName === expression.node.tableName && pivotMatrix.sourceTable === tableExpression.tableName && pivotMatrix.rows.length > 0);
          if (pivotMatrix.length > 0) {
            tableExpression.columnPivotMatrix = {
              ...pivotMatrix[0]
            }
          }

          return tableExpression
        })

        if (action.payload.targetNodeUpdate === true && expression.node.id !== '' && expression.node.tableName !== '' && typeof(state.treeElement.metadataTableTree) !== 'undefined') {
          state.treeElement.metadataTableTree.children.filter(table => table.tableName === expression.node.tableName).forEach(table => {
            let node = table.children.filter(column => column.id === expression.node.id);
            if (node.length > 0) {
              expression.node = node[0]
            }
          })
        }

        return expression;
      })

      expressionList.map(expression => {
        expression.expressionDetail.map(tableExpression => {
          if (typeof(tableExpression.columnWhereMatrix) !== 'undefined') {
            const whereMatrix = state.whereMatrixList.find(whereMatrix => whereMatrix.tableName === expression.node.tableName && (whereMatrix.sourceTable === tableExpression.tableName || whereMatrix.isVirtualSourceTable === true))

            if (typeof(whereMatrix) !== 'undefined') {
                tableExpression.columnWhereMatrix = whereMatrix;
            }
          }

          return tableExpression
        })

        return expression;
      })
      return {
          ...state,
          expressionList: expressionList,
          lastMappedTargetColumnId: action.payload.columnExpressionList.length > 0 ? action.payload.columnExpressionList[0].id : 0,
          pivotMatrixList: pivotMatrixList
      }
    case ANNOTATE_SET_TREE_ELEMENT:
      expressionList = state.expressionList

      if (typeof(state.treeElement.metadataTableTree) !== 'undefined') {
        expressionList.map(expression =>{
          state.treeElement.metadataTableTree.children.filter(table => table.tableName === expression.node.tableName).forEach(columnList => {
            let columnNode = columnList.children.filter(columnNode => columnNode.id === expression.node.id);

            if (columnNode.length > 0) {
                expression.node = columnNode[0]
            }
          })
          return expression
        })
      }

      return {
        ...state,
        treeElement: action.payload,
        expressionList: expressionList
      }

    case ANNOTATE_UPDATE_TREE_ELEMENT_VIA_IMMULTABLE:
      return {
        ...state,
        treeElement:{
            ...action.payload,
            sourceTableTree: action.payload.sourceTableTree,
            metadataTableTree: action.payload.metadataTableTree
        }
      }

    case ANNOTATE_ADD_APPLY_PROCESS_FLOW:
      let applyProcessFlowInsertIndex = state.applyProcessFlowList.length;
      state.applyProcessFlowList.forEach((applyProcessFlow, index) => {
        if (applyProcessFlow.id === action.payload.id) {
          applyProcessFlowInsertIndex = index;
        }
      })

      applyProcessFlowList = state.applyProcessFlowList.filter(applyProcessFlow => applyProcessFlow.id !== action.payload.id).slice();
      applyProcessFlowList.splice(applyProcessFlowInsertIndex, 0, action.payload);
      return {
        ...state,
        applyProcessFlowList: applyProcessFlowList
      }
    case ANNOTATE_REMOVE_APPLY_PROCESS_FLOW:
      applyProcessFlowList = state.applyProcessFlowList;
      if (applyProcessFlowList.filter(applyProcessFlow => applyProcessFlow.id === action.payload.id).length > 0) {
        applyProcessFlowList = applyProcessFlowList.filter(applyProcessFlow => applyProcessFlow.id !== action.payload.id)
      }
      return {
        ...state,
        applyProcessFlowList: applyProcessFlowList
      }
    case ANNOTATE_ADD_TABLE_SORT_COLUMN:
      tableSortColumns = state.tableSortColumns.filter(table => table.tableName !== action.payload.tableName).slice();
      tableSortColumns.splice(tableSortColumns.length, 0, action.payload);
      metadataTableTree = state.treeElement.metadataTableTree;
      hardcodeList = state.hardcodeList
      expressionList = state.expressionList;
      applyProcessFlowList = state.applyProcessFlowList;

      metadataTableTree.children.filter(table => table.tableName === action.payload.tableName).map(table => {
        table.children.map(column => {
          let gby = false;
          let oby = false;
          let obyGbyOrder = -1

          action.payload.rows.filter((sortColumn, index) => {
            if (sortColumn.column === column.title) {
              gby = sortColumn.gby;
              oby = sortColumn.oby;
              obyGbyOrder = index
            }
          })

          column.gby = gby;
          column.oby = oby;
          column.obyGbyOrder = obyGbyOrder;

          hardcodeList.filter(hardcode => hardcode.id === column.id).map(hardcode => {
            hardcode.gby = gby;
            hardcode.oby = oby;
            hardcode.obyGbyOrder = obyGbyOrder;
            return hardcode
          })

          expressionList.filter(expression => expression.node.id === column.id).map(expression => {
            expression.node.gby = gby
            expression.node.oby = oby
            expression.node.obyGbyOrder = obyGbyOrder
            return expression;
          })

          applyProcessFlowList.filter(applyProcessFlow => applyProcessFlow.id === column.id).map(applyProcessFlow => {
            applyProcessFlow.gby = gby
            applyProcessFlow.oby = oby
            applyProcessFlow.obyGbyOrder = obyGbyOrder
            return applyProcessFlow;
          })
        })
        return table;
      })
      return {
        ...state,
        tableSortColumns: tableSortColumns,
        treeElement: {
          ...state.treeElement,
          metadataTableTree: metadataTableTree,
        },
        hardcodeList: hardcodeList,
        expressionList: expressionList,
        applyProcessFlowList: applyProcessFlowList
    }
    case ANNOTATE_ADD_MULTIPLE_RENAME_COLUMN:
      renameColumnList = state.renameColumnList;
      sourceTableTree = state.treeElement.sourceTableTree;
      //leftPersistentTableTree = state.treeElement.leftPersistentTableTree;
      if (typeof(state.treeElement.sourceTableTree) !== 'undefined') {
        action.payload.forEach((renameColumn) => {
          sourceTableTree.children.forEach((table) => {
            if (table.children.length > 0) {
              let nodeList = table.children.filter(nodeList => nodeList.id === renameColumn.id);
              table.children.forEach((node) => {
                if (node.id === renameColumn.id) {
                  renameColumnList = renameColumnList.filter(column => column.id !== renameColumn.id).slice();
                  renameColumnList.splice(renameColumnList.length, 0, Object.assign({}, renameColumn));
                  node.renameColumn = true;
                }
              })
            }
          })
        })
      }

      /*if (typeof(state.treeElement.leftPersistentTableTree) !== 'undefined') {
        action.payload.forEach((renameColumn) => {
          leftPersistentTableTree.children.forEach((table) => {
            if (table.children.length > 0) {
              let nodeList = table.children.filter(nodeList => nodeList.id === renameColumn.id);
              table.children.forEach((node) => {
                if (node.id === renameColumn.id) {
                  renameColumnList = renameColumnList.filter(column => column.id !== renameColumn.id).slice();
                  renameColumnList.splice(renameColumnList.length, 0, Object.assign({}, renameColumn));
                  node.renameColumn = true;
                }
              })
            }
          })
        })
      }*/

      return {
        ...state,
        renameColumnList: renameColumnList,
        treeElement: {
          ...state.treeElement,
          sourceTableTree: sourceTableTree
        }
      }
    case ANNOTATE_ADD_MULTIPLE_HARDCODE_COLUMN:
      hardcodeList = state.hardcodeList
      metadataTableTree = state.treeElement.metadataTableTree;
      if (typeof(state.treeElement.metadataTableTree) !== 'undefined') {
        action.payload.forEach((hardcodeColumn) => {
          metadataTableTree.children.forEach((table) => {
            if (table.children.length > 0) {
              let nodeList = table.children.filter(nodeList => nodeList.id === hardcodeColumn.id);
              table.children.forEach((node) => {
                if (node.id === hardcodeColumn.id) {
                  hardcodeList = hardcodeList.filter(column => column.id !== hardcodeColumn.id).slice();
                  hardcodeList.splice(hardcodeList.length, 0, Object.assign({}, hardcodeColumn));

                  if (hardcodeColumn.hardcode.type === 'Expression') {
                    node.expressionIntermediate = true;
                    node.hardcode = false;

                    node.cssClassName = node.type+'-contextmenu-intermediate-expression';
                  } else {
                    node.expressionIntermediate = false;
                    node.hardcode = true;

                    node.cssClassName = node.type+'-contextmenu-hardcode';
                  }
                }
              })
            }
          })
        })
      }

      return {
        ...state,
        hardcodeList: hardcodeList,
        treeElement: {
          ...state.treeElement,
          metadataTableTree: metadataTableTree
        }
      }
    case ANNOTATE_ADD_PIVOT_MATRIX_LIST:
        columnMappedList = state.columnMappedList;
        pivotMatrixList = state.pivotMatrixList.filter(table => table.sourceTable !== action.payload[0].sourceTable).slice();
        pivotMatrixList.splice(pivotMatrixList.length, 0, ...action.payload);
        expressionList = state.expressionList;

        pivotMatrixList.map(pivotMatrix => {
          pivotMatrix.rows.map(row => {
            expressionList.filter(expression => expression.node.title === row.targetColumn && expression.node.tableName === pivotMatrix.tableName && expression.expressionDetail.filter(tableExpression => tableExpression.tableName === pivotMatrix.sourceTable && tableExpression.tableOperation !== 'Pivot').length > 0).forEach(expression => {
              expression.expressionDetail.filter(tableExpression => tableExpression.tableName === pivotMatrix.sourceTable && tableExpression.tableOperation !== 'Pivot').forEach(tableExpression => {
                tableExpression.children.forEach(sourceColumn => {
                  columnMappedList = columnMappedList.filter(columnMapped => !(columnMapped.table.target === pivotMatrix.tableName && columnMapped.table.source === pivotMatrix.sourceTable && columnMapped.mappingType === 'column-column' && columnMapped.column.source.column_header === sourceColumn.sourceColumnName && columnMapped.column.target.column_header === row.targetColumn))
                })
              })
            })
            return row;
          })
          return pivotMatrix
        })

        return {
        ...state,
          pivotMatrixList: pivotMatrixList,
          columnMappedList: columnMappedList,
          applyProcessFlowList: updateApplyProcessFlowList(state.applyProcessFlowList, state.tableExtendedList, columnMappedList, state.hardcodeList)
       }
    case ANNOTATE_ADD_PIVOT_MATRIX_LIST:
        return {
          ...state,
          pivotMatrixList: action.payload
       }
    case ANNOTATE_ADD_MULTIPLE_APPLY_PROCESS_FLOW:
        applyProcessFlowList = state.applyProcessFlowList
        action.payload.applyProcessFlowList.forEach(applyProcessFlow => {
          let applyProcessFlowInsertIndex = applyProcessFlowList.length;
          applyProcessFlowList.forEach((applyProcessFlow, index) => {
            if (applyProcessFlow.id === action.payload.id) {
              applyProcessFlowInsertIndex = index;
            }
          })

          applyProcessFlowList = applyProcessFlowList.filter(savedApplyProcessFlow => savedApplyProcessFlow.id !== applyProcessFlow.id).slice();
          applyProcessFlowList.splice(applyProcessFlowInsertIndex, 0, applyProcessFlow);
        })

        return {
          ...state,
          applyProcessFlowList: applyProcessFlowList
        }
    case ANNOTATE_MANAGE_APPLY_PROCESS_FLOW:
        return  {
          ...state,
          applyProcessFlowList: action.payload,
        }
    case ANNOTATE_ADD_MULTIPLE_TABLE_SORT_COLUMN:
        tableSortColumns = state.tableSortColumns
        action.payload.tableSortColumnList.forEach(tableSortColumn => {
          let table = tableSortColumns.filter(table => table.tableName === tableSortColumn.tableName);

          if (table.length === 0 ) {
              tableSortColumns.splice(tableSortColumns.length, 0, tableSortColumn);
          } else {
            table.map(rowsTable => {
              tableSortColumn.rows.forEach(newRow => {
                  let row = rowsTable.rows.filter(row => row.column === newRow.column)

                  if (row.length === 0) {
                    rowsTable.rows.push(newRow)
                  }
              })

              return rowsTable
            })
          }
        })

        return  {
          ...state,
          tableSortColumns: tableSortColumns
        }
    case ANNOTATE_MANAGE_MULTIPLE_TARGET_TABLE_LIST:
        tableExtendedList = state.tableExtendedList;

        tableExtendedList.sourceTableGroupList.length = 0;
        tableExtendedList.virtualTargetTableList.length = 0;
        action.payload.forEach(virtualTargetTable => {
          if (virtualTargetTable.records.length > 0) {
            tableExtendedList.metaTableTables = tableExtendedList.metaTableTables.filter(metaTableTable => metaTableTable.blockName !== virtualTargetTable.blockName)
            tableExtendedList.metaTableTables.push(virtualTargetTable)
            if (tableExtendedList.virtualTargetTableList.indexOf(virtualTargetTable.blockName) === -1) {
              tableExtendedList.virtualTargetTableList.push(virtualTargetTable.blockName)
            }
          }

          tableExtendedList.sourceTableGroupList = tableExtendedList.sourceTableGroupList.filter(sourceTableGroup => sourceTableGroup.targetGroupName !== virtualTargetTable.blockName);
          tableExtendedList.sourceTableGroupList.push({targetGroupName: virtualTargetTable.blockName, sourceTableNameList: virtualTargetTable.sourceTableNameList})
        })

        if (action.payload.length === 0) {
          tableExtendedList.virtualTargetTableList.length = 0;
          tableExtendedList.sourceTableGroupList.length = 0;
        }

        tableExtendedList.reload = true

        return  {
          ...state,
          tableExtendedList: tableExtendedList,
          virtualTargetTableList: action.payload
        }
    case ANNOTATE_MANAGE_TABLE_VERSION_CHANGE:
        return {
          ...state,
          ...action.payload,
          applyProcessFlowList: updateApplyProcessFlowList(
            typeof(action.payload.applyProcessFlowList) !== 'undefined' ? action.payload.applyProcessFlowList: state.applyProcessFlowList,
            typeof(action.payload.tableExtendedList) !== 'undefined' ? action.payload.tableExtendedList: state.tableExtendedList,
            typeof(action.payload.columnMappedList) !== 'undefined' ? action.payload.columnMappedList: state.columnMappedList,
            typeof(action.payload.hardcodeList) !== 'undefined' ? action.payload.hardcodeList: state.hardcodeList,
          )
        }
    case ANNOTATE_RESET_TARGET_COLUMN_LIST:
        columnMappedList = state.columnMappedList;
        expressionList = state.expressionList
        hardcodeList = state.hardcodeList
        applyProcessFlowList = state.applyProcessFlowList
        pivotMatrixList = state.pivotMatrixList
        metadataTableTree = state.treeElement.metadataTableTree;

        columnMappedList = columnMappedList.filter(columnMapped => action.payload.targetColumnList.filter(targetNode => (targetNode.targetId === columnMapped.node.target || columnMapped.targetId === targetNode.targetId) && (targetNode.sourceTableList.length === 0 || targetNode.sourceTableList.indexOf(columnMapped.table.source) > -1)).length === 0)

        expressionList.filter(expression => action.payload.targetColumnList.filter(targetNode => targetNode.targetId === expression.node.id).length > 0).map(expression => {
          const targetNode = action.payload.targetColumnList.find(targetNode => targetNode.targetId === expression.node.id)
          expression.expressionDetail = expression.expressionDetail.filter(tableExpression => targetNode.sourceTableList.indexOf(tableExpression.tableName) === -1)
          return expression;
        })

        expressionList = expressionList.filter(expression => !(action.payload.targetColumnList.filter(targetNode => targetNode.targetId === expression.node.id).length > 0 && expression.expressionDetail.length === 0))

        pivotMatrixList.map(pivotMatrix => {
          pivotMatrix.rows = pivotMatrix.rows.filter(row => action.payload.targetColumnList.filter(targetNode => targetNode.targetTable === pivotMatrix.tableName && targetNode.targetVariable === row.targetColumnTitle && (targetNode.sourceTableList.length === 0 || targetNode.sourceTableList.indexOf(pivotMatrix.sourceTable) > -1)).length === 0)

          return pivotMatrix
        })

        pivotMatrixList = pivotMatrixList.filter(pivotMatrix => pivotMatrix.rows.length > 0)

        if (typeof(metadataTableTree) !== 'undefined') {
            metadataTableTree.children.filter(table => action.payload.targetColumnList.filter(targetNode => targetNode.targetTable === table.tableName && targetNode.sourceTableList.length === 0).length > 0).map(table => {
              table.children.filter(column => action.payload.targetColumnList.filter(targetNode => targetNode.targetTable === table.tableName && targetNode.targetId === column.id).length > 0).map(column => {
                column.columnStatus = '';
                column.hardcode = false;
                column.expression = false;
                column.expressionIntermediate = false;
                column.applyProcessFlow = false;
                column.cssClassName = 'metadata-contextmenu-hardcode-expression';

                return column
              })
              return table
            })
        }
        return {
          ...state,
          columnMappedList: columnMappedList,
          expressionList: expressionList,
          hardcodeList: hardcodeList,
          pivotMatrixList: pivotMatrixList,
          applyProcessFlowList: applyProcessFlowList,
          treeElement: {
            ...state.treeElement,
            metadataTableTree
          },
          applyProcessFlowList: updateApplyProcessFlowList(applyProcessFlowList, state.tableExtendedList, columnMappedList, hardcodeList)
        }
    case ANNOTATE_SOURCE_TABLE_LIST_TYPE:
        tableExtendedList = state.tableExtendedList;
        virtualTargetTableList = state.virtualTargetTableList;
        if (action.payload.removeSourceGroupStatus === true) {
          tableExtendedList.sourceTableGroupList.length = 0;
          tableExtendedList.virtualTargetTableList.length = 0;
          virtualTargetTableList.length = 0;
        }

        return {
          ...state,
          selectedBlockClassifier: action.payload.sourceTableListType,
          tableExtendedList: tableExtendedList,
          virtualTargetTableList: virtualTargetTableList
        }
    case ANNOTATE_TARGET_TABLE_LIST_TYPE:
        return {
          ...state,
          selectedTargetClassifier: action.payload
        }
    case ANNOTATE_SET_HIGHLIGHTLIST:
        return {
          ...state,
          highlightList: action.payload
        }
    case ANNOTATE_REMOVE_BLOCK_CLASSIFIER_DETAILS:
        tableExtendedList = state.tableExtendedList
        sourceTableTree = state.treeElement.sourceTableTree
        metadataTableTree = state.treeElement.metadataTableTree
        columnMappedList = state.columnMappedList
        expressionList = state.expressionList
        pivotMatrixList = state.pivotMatrixList
        virtualTargetTableList = state.virtualTargetTableList;
        let sourceTableGroupList = tableExtendedList.sourceTableGroupList
        let tableExtendedVirtualTargetTableList = tableExtendedList.virtualTargetTableList
        let metaTableTables = tableExtendedList.metaTableTables

        let removeSourceTableList = []
        const classifier = state.classifierList.filter(classifier => classifier.classifierName === action.payload);

        tableExtendedList.sourceTables.filter(sourceTable => sourceTable.classifierId === (classifier[0].classifierId === 1  ? null: classifier[0].classifierId)).forEach(sourceTable => {
          removeSourceTableList.push(sourceTable.tableName.replace('_extended', ''))
        })

        sourceTableTree.children.filter(table => removeSourceTableList.indexOf(table.tableName) > -1).forEach(table => {
          sourceTableGroupList.filter(sourceTableGroup => sourceTableGroup.sourceTableNameList.indexOf(table.name) > -1).map(sourceTableGroup => {
            sourceTableGroup.sourceTableNameList = sourceTableGroup.sourceTableNameList.filter(sourceTableName => sourceTableName !== table.name)
            if (sourceTableGroup.sourceTableNameList.length === 0) {
              tableExtendedVirtualTargetTableList = tableExtendedVirtualTargetTableList.filter(virtualTargetTable => virtualTargetTable !== sourceTableGroup.targetGroupName)
              virtualTargetTableList = virtualTargetTableList.filter(virtualTargetTable => virtualTargetTable.groupName !== sourceTableGroup.targetGroupName)
              metaTableTables = metaTableTables.filter(metaTable => metaTable.groupName !== sourceTableGroup.targetGroupName)
              metadataTableTree.children = metadataTableTree.children.filter(table => table.blockName !== sourceTableGroup.targetGroupName)
            }
            return sourceTableGroup;
          })

          virtualTargetTableList.filter(virtualTargetTable => virtualTargetTable.sourceTableNameList.indexOf(table.name) > -1).map(virtualTargetTable => {
            virtualTargetTable.sourceTableNameList = virtualTargetTable.sourceTableNameList.filter(sourceTableName => sourceTableName !== table.name)
            return virtualTargetTable
          })
        })

        sourceTableGroupList = sourceTableGroupList.filter(sourceTableGroup => sourceTableGroup.sourceTableNameList.length > 0)
        virtualTargetTableList = virtualTargetTableList.filter(virtualTargetTable => virtualTargetTable.sourceTableNameList.length > 0)
        tableExtendedList = {
          ...tableExtendedList,
          sourceTables: tableExtendedList.sourceTables.filter(sourceTable => !(sourceTable.classifierId === (classifier[0].classifierId === 1  ? null: classifier[0].classifierId))),
          metaTableTables: metaTableTables,
          sourceTableGroupList: sourceTableGroupList,
          virtualTargetTableList: tableExtendedVirtualTargetTableList
        }

        sourceTableTree.children = sourceTableTree.children.filter(table => removeSourceTableList.indexOf(table.tableName) === -1)
        columnMappedList = columnMappedList.filter(columnMapped => removeSourceTableList.indexOf(columnMapped.table.source) === -1)

        expressionList.map(expression => {
          expression.expressionDetail = expression.expressionDetail.filter(tableExpression => removeSourceTableList.indexOf(tableExpression.tableName) === -1)

          if (expression.expressionDetail.length === 0 && typeof(expression.node) !== 'undefined') {
            metadataTableTree.children.filter(table => table.tableName === expression.node.tableName).map((table) => {
              table.children.filter(column => column.id === expression.id).map((column) => {
                  column.removeMappedLine = false;
                  column.expression = false;
                  column.hardcode = false;
                  column.expressionIntermediate = false;
                  column.renameColumn = false;
                  column.pivotOperationColumn = false;
                  column.applyProcessFlow = false;
                  column.cssClassName = 'metadata-contextmenu-hardcode-expression';
                  column.columnStatus = '';

                  return column
              })

              if (table.children.filter(column => column.expression === true).length === 0) {
                table.cssClassName = table.cssClassName.replace(new RegExp(' highlight-table', 'gi'), '').trim();
              }

              return table;
            })
          }
          return expression
        })
        expressionList = expressionList.filter(expression => expression.expressionDetail.length > 0);
        pivotMatrixList = pivotMatrixList.filter(pivotMatrix => removeSourceTableList.indexOf(pivotMatrix.sourceTable) === -1)
        return {
          ...state,
          tableExtendedList: tableExtendedList,
          treeElement: {
            ...state.treeElement,
            sourceTableTree,
            metadataTableTree
          },
          columnMappedList: columnMappedList,
          expressionList: expressionList,
          pivotMatrixList: pivotMatrixList,
          virtualTargetTableList: virtualTargetTableList,
          applyProcessFlowList: updateApplyProcessFlowList(state.applyProcessFlowList, state.tableExtendedList, columnMappedList, state.hardcodeList)
        }
    case ANNOTATE_MANAGE_MAPPING_SPECS_DETAIL:
      let mappingSpecsDetail = state.mappingSpecsDetail;

      if (mappingSpecsDetail === "{}") {
        mappingSpecsDetail = {};
      }

      if (typeof(action.payload.status) !== 'undefined') {
        mappingSpecsDetail.status = action.payload.status
      }

      if (typeof(action.payload.createdAt) !== 'undefined') {
        mappingSpecsDetail.createdAt = action.payload.createdAt
      }

      if (typeof(action.payload.data) !== 'undefined') {
        mappingSpecsDetail.data = action.payload.data;
      }

      if (typeof(action.payload.fetchStatus) !== 'undefined') {
        mappingSpecsDetail.fetchStatus = action.payload.fetchStatus;
      }

      return {
        ...state,
        mappingSpecsDetail: mappingSpecsDetail
      }
    case ANNOTATE_MANAGE_WHERE_MATRIX:
      expressionList = state.expressionList
      sourceTableTree = state.treeElement.sourceTableTree
      metadataTableTree = state.treeElement.metadataTableTree
      additionalWhereConditionList = state.additionalWhereConditionList

      expressionList.map(expression => {
        expression.expressionDetail.map(tableExpression => {
          if (typeof(tableExpression.columnWhereMatrix) !== 'undefined') {
            const whereMatrix = action.payload.find(whereMatrix => whereMatrix.tableName === expression.node.tableName && (whereMatrix.sourceTable === tableExpression.tableName || whereMatrix.isVirtualSourceTable === true))

            tableExpression.columnWhereMatrix = typeof(whereMatrix) !== 'undefined' ? whereMatrix: {}
          }

          return tableExpression
        })

        return expression;
      })

      if (typeof(sourceTableTree) !== 'undefined' && typeof(metadataTableTree) !== 'undefined') {
        sourceTableTree.children.map(treeTable => {
          treeTable.whereCondition = action.payload.filter(whereMatrix => whereMatrix.sourceTable === treeTable.tableName && metadataTableTree.children.filter(table => table.tableName === whereMatrix.tableName).length > 0).length > 0;
          return treeTable;
        })

        metadataTableTree.children.map(treeTable => {
          treeTable.whereCondition = action.payload.filter(whereMatrix => whereMatrix.tableName === treeTable.tableName && (sourceTableTree.children.filter(table => table.tableName === whereMatrix.sourceTable).length > 0 || whereMatrix.isVirtualSourceTable === true)).length > 0;
          return treeTable;
        })
      }

      additionalWhereConditionList = state.additionalWhereConditionList.filter(additionalWhereCondition => action.payload.filter(whereMatrix => whereMatrix.tableName === additionalWhereCondition.tableName).length > 0 )
      return {
        ...state,
        expressionList: expressionList,
        whereMatrixList: action.payload,
        treeElement: {
          ...state.treeElement,
          sourceTableTree,
          metadataTableTree
        },
        additionalWhereConditionList: additionalWhereConditionList
      }
    case ANNOTATE_MANAGE_LAST_EXECUTE_ENGINE_VALUE:
      return {
        ...state,
        lastExecuteEngineValue: action.payload
      }
    case ANNOTATE_MANAGE_TARGET_TABLE_VALUE_IN_EXPRESSION:
      expressionList = state.expressionList
      columnMappedList = state.columnMappedList
      expressionList.filter(expression => typeof(expression.node) !== 'undefined' && expression.node.tableName === action.payload.tableName && expression.node.title === action.payload.columnName).map(expression => {
        expression.expressionDetail.map(tableExpression => {
          tableExpression.targetValues.length = 0;
          tableExpression.targetValues.push(...action.payload.targetValues)

          tableExpression.mappingGrid.map(mapping => {
            if (typeof(action.payload.replaceValueList) !== 'undefined' && action.payload.replaceValueList.length > 0) {
              action.payload.replaceValueList.filter(replaceValue => replaceValue.oldValue === mapping.target).forEach(replaceValue => {
                mapping.target = replaceValue.newValue;
                columnMappedList.filter(columnMapped => columnMapped.targetId === expression.id && columnMapped.columnTitle.target === replaceValue.oldValue).map(columnMapped => {
                  columnMapped.columnTitle.target = mapping.target
                  return columnMapped
                })
              })
            }

            if (action.payload.targetValues.indexOf(mapping.target) === -1) {
              mapping.target = '--Keep--'
            }
            return mapping
          })
          return tableExpression
        })

        return expression;
      })
      return {
        ...state,
        expressionList: expressionList,
        columnMappedList: columnMappedList
      }
    case ANNOTATE_MANAGE_ADDITIONAL_WHERE_CONDITION:
      return {
        ...state,
        additionalWhereConditionList: action.payload,
      }
    case ANNOTATE_MANAGE_DOCUMENT_NAME:
      let annotateDocumentName = state.annotateDocumentName
      let highlightList = state.highlightList

      if (annotateDocumentName !== action.payload || action.payload === '') {
        highlightList.length = 0;
      }
      return {
        ...state,
        annotateDocumentName: action.payload,
        highlightList: highlightList
      }
    case ANNOTATE_MANAGE_CUSTOM_UNMAP_VALUE_LIST:
      return {
        ...state,
        customUnmapValueList: action.payload,
      }
    case ANNOTATE_REFRESH_TLF_VIEWER:
      return {
        ...state,
        refreshTlfViewerStatus: action.payload,
      }
    default:
      return state;
  }
}

const updateApplyProcessFlowList = (applyProcessFlowList, tableExtendedList, columnMappedList, hardcodeList) => {
  if (typeof(tableExtendedList.metaTableTables) !== 'undefined' && tableExtendedList.metaTableTables.length > 0) {
    applyProcessFlowList = applyProcessFlowList.filter(applyProcessFlow => tableExtendedList.metaTableTables.filter(table => table.tableName.replace('_extended', '') === applyProcessFlow.tableName && table.records.filter(column => column.column_header === applyProcessFlow.column).length > 0).length > 0);
    applyProcessFlowList.map(applyProcessFlow => {
      let columnMappingRules = []

      if (typeof(applyProcessFlow.selectedTargetColumnList) === 'undefined') {
        applyProcessFlow.selectedTargetColumnList = [];
      }
      applyProcessFlow.processFlowList.map(processFlow => {
        processFlow.columnMappingRules = processFlow.columnMappingRules.filter(columnMappingRule => (columnMappingRule.columnMappingType !== 1) || (columnMappingRule.mappedColumnName !== null && columnMappingRule.columnMappingType === 1 && (applyProcessFlowList.filter(subApplyProcessFlow => subApplyProcessFlow.column === columnMappingRule.mappedColumnName).length > 0 || columnMappedList.filter(columnMapped => ["column-column", "column-table"].indexOf(columnMapped.mappingType) >= 0 && columnMapped.table.target === applyProcessFlow.tableName && (columnMapped.column.target.column_header === columnMappingRule.mappedColumnName || (columnMapped.mappingType === 'column-table' && columnMapped.column.source.column_header === columnMappingRule.mappedColumnName))).length > 0 || hardcodeList.filter(hardcode => hardcode.tableName === applyProcessFlow.tableName && hardcode.column === columnMappingRule.mappedColumnName).length > 0)))
        processFlow.columnMappingRules.filter(columnMappingRule => columnMappingRule.mappedColumnName !== null && (columnMappingRule.columnMappingType === 2 || columnMappingRule.columnMappingType === 4) && !(applyProcessFlowList.filter(subApplyProcessFlow => subApplyProcessFlow.column === columnMappingRule.mappedColumnName).length > 0 || columnMappedList.filter(columnMapped => ["column-column", "column-table"].indexOf(columnMapped.mappingType) >= 0 && columnMapped.table.target === applyProcessFlow.tableName && (columnMapped.column.target.column_header === columnMappingRule.mappedColumnName || (columnMapped.mappingType === 'column-table' && columnMapped.column.source.column_header === columnMappingRule.mappedColumnName))).length > 0 || hardcodeList.filter(hardcode => hardcode.tableName === applyProcessFlow.tableName && hardcode.column === columnMappingRule.mappedColumnName).length > 0)  && applyProcessFlow.selectedTargetColumnList.indexOf(columnMappingRule.mappedColumnName) === -1).map(columnMappingRule => {
          columnMappingRule.columnMappingType = 3;
          columnMappingRule.mappedColumnId = null;
          columnMappingRule.mappedColumnName = null;
          columnMappingRule.mappedColumnType = null;
          columnMappingRule.mappedColumnLength = null;
          columnMappingRule.mappedColumnLabel = null;
          return columnMappingRule
        })

        columnMappedList.filter(columnMapped => ["column-column", "column-table"].indexOf(columnMapped.mappingType) >= 0 && columnMapped.table.target === applyProcessFlow.tableName && processFlow.columnMappingRules.filter(columnMappingRule => columnMappingRule.mappedColumnName === (columnMapped.mappingType === 'column-table' ? columnMapped.column.source.column_header : columnMapped.column.target.column_header)).length === 0).forEach(columnMapped => {
          const column = columnMapped.mappingType === 'column-table' ? columnMapped.column.source : columnMapped.column.target;

          if (typeof(column.column_header) !== 'undefined') {
            let isAddColumn = true;
            processFlow.columnMappingRules.filter(columnMappingRule => columnMappingRule.columnName === column.column_header).map(columnMappingRule => {
              isAddColumn = false;
              columnMappingRule.columnMappingType = 4;
              columnMappingRule.mappedColumnId = processFlow.columnMappingRules.filter(columnMappingRule => [1,4].indexOf(columnMappingRule.columnMappingType) >= 0).length;
              columnMappingRule.mappedColumnName = column.column_header;
              columnMappingRule.mappedColumnType = column.column_data_type;
              columnMappingRule.mappedColumnLength = column.column_data_length;
              columnMappingRule.mappedColumnLabel = column.column_label;
              return columnMappingRule;
            })

            if (isAddColumn === true) {
              processFlow.columnMappingRules.push({
                columnId: null,
                columnLabel: null,
                columnLength: null,
                columnMappingType: 1,
                columnName: null,
                columnType: null,
                mappedColumnId: processFlow.columnMappingRules.filter(columnMappingRule => [1,4].indexOf(columnMappingRule.columnMappingType) >= 0).length,
                mappedColumnName: column.column_header,
                mappedColumnType: column.column_data_type,
                mappedColumnLength: column.column_data_length,
                mappedColumnLabel: column.column_label
              })
            }
          }
        })

        hardcodeList.filter(hardcode => hardcode.tableName === applyProcessFlow.tableName && processFlow.columnMappingRules.filter(columnMappingRule => columnMappingRule.mappedColumnName === hardcode.column).length === 0).forEach(hardcode => {
          let isAddColumn = true;
          processFlow.columnMappingRules.filter(columnMappingRule => columnMappingRule.columnName === hardcode.column).map(columnMappingRule => {
            isAddColumn = false;
            columnMappingRule.columnMappingType = 4;
            columnMappingRule.mappedColumnId = processFlow.columnMappingRules.filter(columnMappingRule => [1,4].indexOf(columnMappingRule.columnMappingType) >= 0).length;
            columnMappingRule.mappedColumnName = hardcode.column;
            columnMappingRule.mappedColumnType = hardcode.data_type;
            columnMappingRule.mappedColumnLength = hardcode.newName[2];
            columnMappingRule.mappedColumnLabel = hardcode.label;
            return columnMappingRule;
          })

          if (isAddColumn === true) {
            processFlow.columnMappingRules.push({
              columnId: null,
              columnLabel: null,
              columnLength: null,
              columnMappingType: 1,
              columnName: null,
              columnType: null,
              mappedColumnId: processFlow.columnMappingRules.filter(columnMappingRule => [1,4].indexOf(columnMappingRule.columnMappingType) >= 0).length,
              mappedColumnName: hardcode.column,
              mappedColumnType: hardcode.data_type,
              mappedColumnLength: hardcode.newName[2],
              mappedColumnLabel: hardcode.label
            })
          }
        })
        columnMappingRules = processFlow.columnMappingRules;

        return processFlow;
      })

      applyProcessFlow.columnMapperNodeList = applyProcessFlow.columnMapperNodeList.filter(columnMapperNode => columnMappingRules.filter(columnMappingRule => columnMappingRule.columnId === columnMapperNode.node.source).length > 0 && columnMappingRules.filter(columnMappingRule => columnMappingRule.mappedColumnId === columnMapperNode.node.target).length > 0)
      return applyProcessFlow
    })
  }

  return applyProcessFlowList
}

const findGroupSourceColumn = (row, key) => {
  let pivotSourceColumn = '';
  if (typeof(row['selectedSourceColumnList']) !== 'undefined') {
    row['selectedSourceColumnList'].filter(selectedSourceColumn => selectedSourceColumn.groupTitle === key).forEach(selectedSourceColumn => {
      pivotSourceColumn = selectedSourceColumn.column;
    })
  }

  return pivotSourceColumn;
}

const removeVerticalBlankGroup = (pivotMatrix) => {
  const pivotMatrixMaximumGroupLength = pivotMatrix.maximumGroupLength;
  let verticalGroupList = [];
  for(var i = 1; i <=pivotMatrixMaximumGroupLength; i++) {
    let totalBlankGroupCount = 0;
    let groupTitle = "group"+i;
    pivotMatrix.rows.map(row => {
      if (typeof(row[groupTitle]) === 'undefined') {
        row[groupTitle] = '""';
        if (row.blankCell.indexOf(groupTitle) === -1) {
          row.blankCell.push(groupTitle)

          if (typeof(row['selectedSourceColumnList']) !== 'undefined') {
            row['selectedSourceColumnList'] = row['selectedSourceColumnList'].filter(selectedSourceColumn => selectedSourceColumn.groupTitle !== groupTitle)
          }
        }
      }

      let pivotSourceColumn = findGroupSourceColumn(row, groupTitle);
      const groupSourceColumn = pivotSourceColumn !== '' ? pivotSourceColumn : row[groupTitle];

      if (row.blankCell.indexOf(groupTitle) > -1 && groupSourceColumn === '""') {
        totalBlankGroupCount++
      }
      return row
    })

    if (pivotMatrix.rows.length === totalBlankGroupCount) {
      verticalGroupList.push(groupTitle)
    }
  }

  let maxAvailableGroupList = []
  verticalGroupList.forEach(verticalGroup => {
    pivotMatrix.rows.map(row => {
      row.blankCell = row.blankCell.filter(group => group !== verticalGroup);
      if (typeof(row['selectedSourceColumnList']) !== 'undefined') {
        row['selectedSourceColumnList'] = row['selectedSourceColumnList'].filter(selectedSourceColumn => selectedSourceColumn.groupTitle !== verticalGroup)
      }
      delete row[verticalGroup];

      return row
    })
    pivotMatrix.maximumGroupLength--;
  })

  pivotMatrix.rows.forEach(row => {
    for (var key in row) {
      if (key.indexOf('group') > -1 && maxAvailableGroupList.indexOf(key) === -1 && verticalGroupList.indexOf(key) === -1) {
        maxAvailableGroupList.push(key);
      }
    }
    return row
  })

  maxAvailableGroupList.sort((a, b) => parseInt(a.replace('group', '')) - parseInt(b.replace('group', '')));
  pivotMatrix.rows.map(row => {
    maxAvailableGroupList.forEach((maxAvailableGroup, groupIndex) => {
      const groupTitle = 'group'+(groupIndex+1);
      if (pivotMatrix.maximumGroupLength >= (groupIndex+1)){
        row[groupTitle] = row[maxAvailableGroup];
        if (row.blankCell.filter(group => group === maxAvailableGroup).length > 0) {
          row.blankCell = row.blankCell.filter(group => group !== maxAvailableGroup);
          row.blankCell.push(groupTitle);

          if (typeof(row['selectedSourceColumnList']) !== 'undefined') {
            row['selectedSourceColumnList'] = row['selectedSourceColumnList'].filter(selectedSourceColumn => selectedSourceColumn.groupTitle !== maxAvailableGroup)
            row['selectedSourceColumnList'] = row['selectedSourceColumnList'].filter(selectedSourceColumn => selectedSourceColumn.groupTitle !== groupTitle)
          }

        } else {
          if (typeof(row['selectedSourceColumnList']) !== 'undefined' && groupTitle !== maxAvailableGroup) {
            const selectedSourceColumn = row['selectedSourceColumnList'].filter(selectedSourceColumn => selectedSourceColumn.groupTitle === maxAvailableGroup);
            if (selectedSourceColumn.length > 0) {
              row['selectedSourceColumnList'] = row['selectedSourceColumnList'].filter(selectedSourceColumn => selectedSourceColumn.groupTitle !== maxAvailableGroup);
              row['selectedSourceColumnList'].push({groupTitle: groupTitle, column: selectedSourceColumn[0].column})
            }
          }
        }
      }
    })
    return row
  })

  for(var i = 1; i <= pivotMatrix.maximumGroupLength; i++) {
    pivotMatrix.rows.map(row => {
      for(var key in row) {
        if (key.indexOf("group") > -1 && parseInt(key.replace('group', '')) > pivotMatrix.maximumGroupLength) {
            delete row[key];
        }
      }
      return row
    })
  }
  return pivotMatrix
}


const updatePivotMatrix = (pivotMatrixList, expressionList) => {
  expressionList.forEach(expression => {
    expression.expressionDetail.forEach(tableExpression => {
      let pivotMatrix = pivotMatrixList.filter(pivotMatrix => pivotMatrix.tableName === expression.node.tableName && pivotMatrix.sourceTable === tableExpression.tableName)
      if (tableExpression.tableOperation === 'Pivot') {
        tableExpression.children.forEach(sourceColumn => {
          pivotMatrix = pivotMatrixList.filter(pivotMatrix => pivotMatrix.tableName === expression.node.tableName && pivotMatrix.sourceTable === tableExpression.tableName)
          if (pivotMatrix.length === 0) {
            pivotMatrixList.push({
              tableName: expression.node.tableName,
              sourceTable: tableExpression.tableName,
              rows: [{
                rowId: 0,
                targetColumn: expression.node.title,
                pivotOutput: tableExpression.pivotOutput,
                blankCell: [],
                columnList: [],
                targetColumnTitle: expression.node.title,
                group1: sourceColumn.sourceColumnName,
                selectedSourceColumnList: [{groupTitle: 'group1', column: sourceColumn.sourceColumnName}]
              }],
              maximumGroupLength: 1
            })
          } else {
            pivotMatrix.map(matrix => {
              let pivotMatrixRow = matrix.rows.filter(row => row.targetColumnTitle === expression.node.title)
              if (pivotMatrixRow.length === 0) {
                pivotMatrixRow = {
                  rowId: matrix.rows.length,
                  targetColumn: expression.node.title,
                  pivotOutput: tableExpression.pivotOutput,
                  blankCell: [],
                  columnList: [],
                  targetColumnTitle: expression.node.title,
                  group1: sourceColumn.sourceColumnName,
                  selectedSourceColumnList: [{groupTitle: 'group1', column: sourceColumn.sourceColumnName}]
                };

                if (matrix.maximumGroupLength > 1) {
                  for(var i = 2; i <= matrix.maximumGroupLength; i++) {
                    let groupTitle = 'group'+i;
                    pivotMatrixRow[groupTitle] = '""';
                    pivotMatrixRow.blankCell.push(groupTitle)
                  }
                }
                matrix.rows.push(pivotMatrixRow)
              } else {
                let addNewPivotOperation = true;
                let groupIndex = 1
                for(var i = 1; i <= matrix.maximumGroupLength; i++) {
                  let pivotSourceColumn = '';
                  if (typeof(pivotMatrixRow[0]['selectedSourceColumnList']) !== 'undefined') {
                    pivotMatrixRow[0]['selectedSourceColumnList'].filter(selectedSourceColumn => selectedSourceColumn.groupTitle === "group"+i).forEach(selectedSourceColumn => {
                      pivotSourceColumn = selectedSourceColumn.column;
                    })
                  }
                  const groupSourceColumn = pivotSourceColumn !== '' ? pivotSourceColumn : pivotMatrixRow[0]["group"+i];

                  if (groupSourceColumn === sourceColumn.sourceColumnName) {
                    addNewPivotOperation = false;
                  }

                  if (typeof(groupSourceColumn) !== 'undefined' && groupSourceColumn !== '""') {
                    groupIndex++;
                  }
                }

                if (addNewPivotOperation === true) {
                  if (groupIndex >= matrix.maximumGroupLength) {
                    matrix.maximumGroupLength = groupIndex
                  }

                  pivotMatrixRow.map(row => {
                    let newGroupTitle = "group"+groupIndex;
                    row[newGroupTitle] = sourceColumn.expression;
                    if (typeof(row['selectedSourceColumnList']) !== 'undefined') {
                      row['selectedSourceColumnList'] = row['selectedSourceColumnList'].filter(selectedSourceColumn => selectedSourceColumn.groupTitle !== newGroupTitle);
                      row['selectedSourceColumnList'].push({groupTitle: newGroupTitle, column: sourceColumn.sourceColumnName})
                    }
                    row.blankCell = row.blankCell.filter(blankCell => blankCell !== newGroupTitle)
                    row.pivotOutput = tableExpression.pivotOutput
                    return row;
                  })
                } else {
                  pivotMatrixRow.map(row => {
                    row.pivotOutput = tableExpression.pivotOutput
                    return row;
                  })
                }
              }

              return matrix
            })
          }
        })
      } else if(pivotMatrix.length > 0) {
        tableExpression.children.forEach(sourceColumn => {
          pivotMatrix.map(matrix => {
            matrix.rows.filter(row => row.targetColumnTitle === expression.node.title).map(row => {
              for (var key in row) {
                let pivotSourceColumn = '';
                if (typeof(row['selectedSourceColumnList']) !== 'undefined') {
                  row['selectedSourceColumnList'].filter(selectedSourceColumn => selectedSourceColumn.groupTitle === key).forEach(selectedSourceColumn => {
                    pivotSourceColumn = selectedSourceColumn.column;
                  })
                }
                const groupSourceColumn = pivotSourceColumn !== '' ? pivotSourceColumn : row[key];

                if (key.indexOf("group") > -1 && groupSourceColumn === sourceColumn.sourceColumnName) {
                  row[key] = '""';
                  if (row.blankCell.indexOf(key) === -1) {
                    row.blankCell.push(key)
                  }
                }
              }
            })
          })
        })
      }
    })
  })

  pivotMatrixList.map(pivotMatrix => {
    pivotMatrix = removeVerticalBlankGroup(pivotMatrix);

    let removeTargetColumnRows = [];
    pivotMatrix.rows.map(row => {
      let totalGroupCount = 0;
      for(var key in row) {
        let pivotSourceColumn = findGroupSourceColumn(row, key);
        const groupSourceColumn = pivotSourceColumn !== '' ? pivotSourceColumn : row[key];

        if (key.indexOf("group") > -1 && groupSourceColumn === '""') {
          totalGroupCount++;
        }
      }

      if (totalGroupCount === pivotMatrix.maximumGroupLength) {
        removeTargetColumnRows.push(row.targetColumnTitle)
      }
    })

    if (removeTargetColumnRows.length > 0) {
        pivotMatrix.rows = pivotMatrix.rows.filter(row => removeTargetColumnRows.indexOf(row.targetColumnTitle) === -1)
    }
    return pivotMatrix;
  })

  pivotMatrixList = pivotMatrixList.filter(autoPivotMatrix => autoPivotMatrix.rows.length > 0)

  return pivotMatrixList
}
