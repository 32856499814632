import "./TextEditor.css";
import React, { useRef, useEffect, useState } from "react";
import CodeMirror from "react-codemirror";
import { Button } from './../DataOperation/common/Button/StandardButton';
import useUndoableState from "@jeremyling/react-use-undoable-state";

function template() {
  return (
    <div className="text-editor">
      <CodeMirrorEditor {...this.props}  />
    </div>
  );
};

export function highLightText(str, codeMirrorInstance){
  if(str === null || typeof(str) === "undefined" || str === ""){
    return;
  }
  var strArray = str.toLowerCase().replace(/\r\n/g, "\r").replace(/\n/g, "\r").split(/\r/);
  var warningLines = strArray.reduce((arr, str1, i) => ((str1.match(/^warning:/ig) !== null) && arr.push(i), arr), []);
  warningLines.forEach(element => {
      codeMirrorInstance.markText({line: element, ch: 0},{line: element, ch: strArray[element].length}, {className: "warning-styled-background"});
    let isWarningContinue = true;
    if(strArray[element].trim().endsWith(".")){
      isWarningContinue = false;
    }
    let index = element;
    while(isWarningContinue){
      index = index+1;
      if(strArray[index] !== "" && strArray[index] !== null && typeof(strArray[index]) !== "undefined"){
        if(strArray[index].trim().endsWith(".")){
          codeMirrorInstance.markText({line: index, ch: 0},{line: index, ch: strArray[index].length}, {className: "warning-styled-background"});
          isWarningContinue = false;
          break;
        }else{
          codeMirrorInstance.markText({line: index, ch: 0},{line: index, ch: strArray[index].length}, {className: "warning-styled-background"});
        }

      }
    }

  });
  var errorLines = strArray.reduce((arr, str1, i) => ((str1.match(/\berror(\s)*([a-z0-9-])*:/ig) !== null) && arr.push(i), arr), []);
  errorLines.forEach(element => {
      codeMirrorInstance.markText({line: element, ch: 0},{line: element, ch: strArray[element].length}, {className: "error-styled-background"});
    let isErrorContinue = true;
    if(strArray[element].trim().endsWith(".")){
      isErrorContinue = false;
    }
    let index = element;
    while(isErrorContinue){
      index = index+1;
      if(strArray[index] !== "" && strArray[index] !== null && typeof(strArray[index]) !== "undefined"){
        if(strArray[index].trim().endsWith(".")){
          codeMirrorInstance.markText({line: index, ch: 0},{line: index, ch: strArray[index].length}, {className: "error-styled-background"});
          isErrorContinue = false;
          break;
        }else{
          codeMirrorInstance.markText({line: index, ch: 0},{line: index, ch: strArray[index].length}, {className: "error-styled-background"});
        }

      }
    }
  });
}

function CodeMirrorEditor(props) {
  const init = { text:  props.code};
  const {state: doc, setState: setDoc, resetState: resetDoc, index: docStateIndex, lastIndex: docStateLastIndex, goBack: undoDoc, goForward: redoDoc} = useUndoableState(init, 500);
  const canUndo = docStateIndex > 0;
  const canRedo = docStateIndex < docStateLastIndex;
  const instance = useRef(null);
  const [refreshKey, setRefreshKey] = useState(0);

  useEffect(() => {
    setDoc({ text: props.code})
    if (instance !== null && typeof(instance.current) !== 'undefined') {
      global.codeMirrorInstance = instance.current.codeMirror;
      highLightText(props.code, instance.current.codeMirror);
    }
  },[props.code])

  useEffect(() => {
    if (props.code !== doc.text && typeof(props.onChange) !== 'undefined') {
      props.onChange(doc.text);
    }
  },[refreshKey])

  const updateCode = (code) => {
    setDoc({ text: code})
    if(typeof(props.onChange) !== 'undefined'){
      props.onChange(code);
    }
  }

  const undoCode = () => {
    undoDoc()
    setRefreshKey(refreshKey+1)
  }

  const redoCode = () => {
    redoDoc();
    setRefreshKey(refreshKey+1)
  }

  return (
    <div>
      {typeof (props.options) !== 'undefined' && props.options.undoRedo === true &&
        <div className="m-b-5">
          <Button primary={true} onClick={undoCode} disabled={!canUndo}>
              <i className="fa fa-undo" aria-hidden="true"></i>
          </Button>
          <Button primary={true} onClick={redoCode} disabled={!canRedo} className="m-l-10" >
            <i className="fa fa-repeat" aria-hidden="true"></i>
          </Button>
        </div>
      }

      <CodeMirror ref={instance} value={doc.text} onChange={updateCode} options={typeof (props.options) === 'undefined' ? { lineNumbers: true, lineSeparator:"\n" } : props.options} key={refreshKey}/>
    </div>
  )
}

/*class CodeMirrorEditor extends React.Component {

  constructor(props) {
    super(props);

    this.instance = null;
    var options = props.options;
    var code = props.code;
    var onChange = props.onChange;
    if (typeof (options) === 'undefined') {
        options = { lineNumbers: true, lineSeparator:"\n" };
    }

    if (typeof (code) === 'undefined') {
        code = '';
    }

    this.state = {
      code: code,
      options: options,
      onChange:onChange
    };

    this.updateCode = this.updateCode.bind(this);
  }

  componentDidMount(){
    global.codeMirrorInstance = this.instance.codeMirror;
    highLightText(this.state.code, this.instance.codeMirror);
  }

  updateCode(code) {
    this.setState({code: code});
    if(typeof(this.state.onChange) !== 'undefined'){
      this.state.onChange(code);
    }
  }

  render() {
      return (
        <div>
          <CodeMirror ref = {ref => this.instance = ref} value={this.state.code} onChange={this.updateCode} options={this.state.options} markText={this.maarkText}/>
        </div>
      );
    }
}
*/

export default template;
