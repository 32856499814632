import React from 'react';
import { Button } from './StandardButton';
import { PropTypes } from "prop-types";
import Dialog from 'react-dialog';
import { StandardDropDown } from '../DropDown/StandardDropDown';
import { ProcessOptionGrid } from "../../common/gridOperation";
import { MappingRulesTargetValueCell } from "../../common";
import { GET_ALL } from '../../../../../restClient/types';
import restClient from '../../../../../restClient';
import axios from 'axios';
import $ from 'jquery';
import { DrawMapppingLine ,getSourceElement, getTargetElement, removeClassNameInElement} from '../../../GridMapper/GridMapper.jsx'
import { connect } from 'react-redux';
import { createSelector } from 'reselect';
import { setMessage } from '../../../../../actions/actionNotification';
import MessageTypeConst from "../../../../MessageTypeConst";
import ConfirmBox from './../../common/ConfirmBox';

class BaseMappingRules extends React.Component {
  constructor(props) {
    super(props);

    let mappingRules = [];
    if(this.getGlobalValue(53) !== ""){
      mappingRules = JSON.parse(this.getGlobalValue(53));

      if (mappingRules.length > 0) {
        mappingRules = mappingRules.filter(mappingRule => !(mappingRule.targetColumn == '' && mappingRule.sourceColumn == ''));
        this.saveGlobalValue(53,JSON.stringify(mappingRules));
        mappingRules = this.convertNewValueMappingStructure(mappingRules);
      }
    }
    this.state = {
        mappingRules: mappingRules,
        tenantID: props.tenantID,
        visible: false,
        tableList: props.tableList,
        nodeList:[],
        targetList:[],
        selectedTargetSource: '',
        expression: '',
        targetColumn: '',
        mapValue : false,
        disableMapValue : false,
        mapValueTable:[{
          id: 'mapping-rules-value-grid',
          rows: [{rowId:1,source:'',target:'',targetValues:[]}],
          resize: true,
          filterable: false,
          columnProperty: [
            { field: "rowId", title:"rowId", show:false },
            { field: "source", title:"Source", show:true },
            { field: "target",  cell: MappingRulesTargetValueCell,  title:"Target" }
          ]
        }],
    };

    this.populateMapValues = this.populateMapValues.bind(this);
    this.getRowData = this.getRowData.bind(this);
    this.toggleDialog = this.toggleDialog.bind(this);
    this.handleTargetChange = this.handleTargetChange.bind(this);
    this.getSourceColumnByTargetColumn = this.getSourceColumnByTargetColumn.bind(this);
    this.getExpressionString = this.getExpressionString.bind(this);
    this.handleExpressChange = this.handleExpressChange.bind(this);
    this.removeMappingRule = this.removeMappingRule.bind(this);
    this.handleDefaultExpressClick = this.handleDefaultExpressClick.bind(this);
    this.getGlobalValue = this.getGlobalValue.bind(this);
    this.saveGlobalValue = this.saveGlobalValue.bind(this);
    this.handleMapValueChange = this.handleMapValueChange.bind(this);
    this.saveMappingRules = this.saveMappingRules.bind(this);
    this.handleRefresh = this.handleRefresh.bind(this);
    MappingRules.AddDefaultMappingRuleForTargetColumn = this.AddDefaultMappingRuleForTargetColumn.bind(this);
  }
  fecthPreference = (headers, params) => restClient(GET_ALL, 'distinct/mapvalues', params, headers);

  AddDefaultMappingRuleForTargetColumn(targetColumn, tableList){
    let selectedTargetSource = this.getSourceColumnByTargetColumn(targetColumn, tableList);
    let expression = this.getExpressionString(targetColumn, tableList);
    let mappingRules = [];
    if(this.getGlobalValue(53) !== ""){
      mappingRules = JSON.parse(this.getGlobalValue(53));

      if (mappingRules.length > 0) {
        mappingRules = this.convertNewValueMappingStructure(mappingRules);
      }
    }
    let ruleFound = false;
    mappingRules.forEach((item)=>{
      if(item.targetColumn === targetColumn){
        ruleFound = true;
      }
    });
    if(!ruleFound){
      let existingMappingRule = {targetColumn:targetColumn,
      sourceColumn:selectedTargetSource, expression:expression,
      mapValue:false, mappingGrid:[], targetValues: []};

      if (!(existingMappingRule.sourceColumn === '' || existingMappingRule.targetColumn === '')) {
        mappingRules.push(existingMappingRule);
        this.saveGlobalValue(53,JSON.stringify(mappingRules));
      }
    }else{
      mappingRules = mappingRules.filter(item => item.targetColumn !== targetColumn);
      let existingMappingRule = {targetColumn:targetColumn,
      sourceColumn:selectedTargetSource, expression:expression,
      mapValue:false, mappingGrid:[], targetValues: []};

      if(!(existingMappingRule.sourceColumn === '' || existingMappingRule.targetColumn === '')) {
        mappingRules.push(existingMappingRule);
        this.saveGlobalValue(53,JSON.stringify(mappingRules));
      }
    }
  }

  getRowData(){
    return [];
  }

  populateMapValues(){
        let that = this;
        // $(".loader").show();
        if(global.updateBlock && !this.state.mapValue && this.getGlobalValue(53) !== ""){
          //restore values from global
          let mappingRules = JSON.parse(this.getGlobalValue(53));
          let newMappingRules  =  mappingRules.filter(item => item.targetColumn === this.state.targetColumn);
          if(newMappingRules.length > 0){
            // mapping rule found but Map Value is not selected
            if(!newMappingRules[0].mapValue){
              return;
            }
          }else{
            //mapping rule not found so return
            return;
          }
        }
        let tenantID = this.state.tenantID;
        let targetColumnName = this.state.targetColumn;
        let sourceColumnName = this.state.selectedTargetSource;
        let targetTableName = '';
        let sourceTableName = '';
        if(this.getGlobalValue(-97) !== ""){
          let tableNames = this.getGlobalValue(-97).split(',');
          if(tableNames.length > 1){
            targetTableName = tableNames[1];
            sourceTableName = tableNames[0];
          } else if(tableNames.length > 0){
            sourceTableName = tableNames[0];
            targetTableName = tableNames[0];
          }
        }

        $(".loader").show();
        axios.all([this.fecthPreference({'Tenant-ID': tenantID},
        {'sourceColumn':sourceColumnName,'targetColumn':targetColumnName,
        'expression':this.state.expression,'sourceTableName':sourceTableName,
        'targetTableName':targetTableName, engineId: typeof(this.props.selectedEngine) != 'undefined' && typeof(this.props.selectedEngine.value) !== 'undefined' ? this.props.selectedEngine.value : 0 })])
        .then(axios.spread((response) => {
          if(response!= null && response.data !== null && typeof(response.data.messageType) !== "undefined"
            &&  response.data.messageType !== null && response.data.messageType === MessageTypeConst.WARNING_MESSAGE )
            {
                this.props.setMessage(response.data.message, response.data.messageType);
                $(".loader").hide();
                return;
            }
         if(typeof(response.data) !== 'undefined') {
           let rowData = [];
           let count=1;
           let targets =["--Keep--",""]
           if(typeof(response.data.target) !== 'undefined' && response.data.target !== "" && response.data.target !== null){
            let targetData = response.data.target;
            if(!Array.isArray(targetData)){
              targetData = JSON.parse(targetData);
            }
            targetData.forEach((item) =>{
              targets.push(item);
            });
           }

           if(typeof(response.data.source) !== 'undefined' && response.data.source !== "" && response.data.source !== null){
            let sourceData = response.data.source;
            if(!Array.isArray(sourceData)){
              sourceData = JSON.parse(sourceData);
            }
            //let rules = this.getGlobalValue(53);
            if(this.getGlobalValue(53) !== ""){
              //restore values from global
              let mappingRules = JSON.parse(this.getGlobalValue(53));
              if (mappingRules.length > 0) {
                  mappingRules = this.convertNewValueMappingStructure(mappingRules);
              }
              let newMappingRules  =  mappingRules.filter(item => item.targetColumn === targetColumnName);
              if(newMappingRules.length > 0 && newMappingRules[0].mapValue && newMappingRules[0].mappingGrid !== ""){
                let rowDataRules = newMappingRules[0].mappingGrid;
                if(rowDataRules.length > 0){
                  sourceData.forEach((item) =>{
                    let selectedRow = rowDataRules.filter(row=>row.source === item);
                    if(selectedRow.length >0){
                      rowData.push({rowId:count,source:item, target:selectedRow[0].target, 'targetValues':targets});
                    }else{
                      rowData.push({rowId:count,source:item, target:'--Keep--', 'targetValues':targets});
                    }
                    count++;
                  });

                }else{
                  sourceData.forEach((item) =>{
                    rowData.push({rowId:count,source:item, target:'--Keep--', 'targetValues':targets});
                    count++;
                  });
                }
              }else{
                sourceData.forEach((item) =>{
                  rowData.push({rowId:count,source:item, target:'--Keep--', 'targetValues':targets});
                  count++;
                });
              }
            }else{
              sourceData.forEach((item) =>{
                rowData.push({rowId:count,source:item, target:'--Keep--', 'targetValues':targets});
                count++;
              });
            }
          }

           let mapValueTable = this.state.mapValueTable;

           mapValueTable[0].rows.length = 0;
           mapValueTable[0].rows.push(...rowData);

           this.setState({mapValueTable:mapValueTable});
          //  $(".loader").hide();
         }
         $(".loader").hide();
       })).catch(error => {
        $(".loader").hide();
        that.props.setMessage("Error occurred while fetching map values.",MessageTypeConst.ERROR_MESSAGE);
      });
  }

  handleRefresh = (event) =>{
    if(this.state.mapValue){
      this.populateMapValues();
    }
  }

  handleMapValueChange = (event) => {
    if(event.target.checked){
      this.setState({
        mapValue :event.target.checked
      }, ()=>{
        this.populateMapValues();
      });


    } else
    {
      ConfirmBox.open("Are you sure you would like to unmap previously selected values?")
      .then(() =>{
        let mapValueTable = this.state.mapValueTable;
        mapValueTable[0].rows.length = 0;
        mapValueTable[0].rows.push({rowId:1,source:'',target:'--Keep--', targetValues:[]});
        this.setState({mapValueTable:mapValueTable, mapValue :false});
      });

    }
  }

  getGlobalValue(controlIndex){
    let controlValue = '';
    global.processBlockControlValues.forEach((control, index) => {
      if (control.controlId === controlIndex) {
        controlValue = global.processBlockControlValues[index].controlValue;

      }
    });
    return controlValue;
  }

  saveGlobalValue(controlIndex, value){
    global.processBlockControlValues.forEach((control, index) => {
      if (control.controlId === controlIndex) {
        global.processBlockControlValues[index].controlValue = value;
      }
    });
  }

  toggleDialog() {
    var targetList = [];
    var selectedTargetSource = '';
    var expression = '';
    var visible = !this.state.visible;
    var targetColumn = '';
    //let sourceColumn = '';
    let mapValueTable = this.state.mapValueTable;
    let mappingRules = [];
    let storeMapValue = false;
    let disableMapValue = false;

    if (visible) {
      let rightMappedColumn = [];
      if(this.getGlobalValue(27) !== ""){
        rightMappedColumn = JSON.parse(this.getGlobalValue(27));
      }


      if (typeof(global.columnMapperNodeList) !== 'undefined') {
        global.columnMapperNodeList.forEach((node) => {
          this.state.tableList[1].rows.forEach((row) => {
            if (node.node.target === row.columnId) {
              targetList.push(row.column);
              return true;
            }
          })
        })

        targetList = targetList.filter(function(item, pos, self) {
            return self.indexOf(item) === pos;
        });
      }

      //push items that are not mapped however need to assign mapping rules
      if(this.getGlobalValue(35) !== ""){
        let cols = JSON.parse(this.getGlobalValue(35));
        cols.forEach((col) => {
          if(targetList.indexOf(col) === -1){
            targetList.push(col);
          }
        });
      }
      //push target column selected
      if (typeof(global.columnMapperTargetSelected) !== 'undefined' && global.columnMapperTargetSelected !== "") {

        if(targetList.indexOf(global.columnMapperTargetSelected) === -1){
          let selectedTableList = {};
          if(this.state.tableList.length > 1){
            selectedTableList = this.state.tableList[1];
          }else{
            selectedTableList = this.state.tableList[0];
          }
          let selectedCol = selectedTableList.rows.filter(row=>row.column === global.columnMapperTargetSelected);
          if(selectedCol.length > 0){
            targetList.push(global.columnMapperTargetSelected);
          }
        }
      }

      if (typeof(global.columnMapperTargetSelected) !== 'undefined') {
        if (targetList.indexOf(global.columnMapperTargetSelected) > -1) {
          targetColumn = global.columnMapperTargetSelected;
          if(rightMappedColumn.indexOf(targetColumn) === -1){
            //target column is not mapped then disable Map value check box
            disableMapValue= true;
          }
          selectedTargetSource = this.getSourceColumnByTargetColumn(targetColumn, this.state.tableList);
          expression = this.getExpressionString(targetColumn, this.state.tableList);
          mapValueTable[0].rows.length = 0;
          mapValueTable[0].rows.push({rowId:1,source:'',target:'--Keep--', targetValues:[]});
        }
      }
      if(this.getGlobalValue(53) === "" || selectedTargetSource === ""){
          mapValueTable[0].rows.length = 0;
          mapValueTable[0].rows.push({rowId:1,source:'',target:'--Keep--',targetValues:[]});
      }
      if(this.getGlobalValue(53) !== ""){
        //restore values from global
        mappingRules = JSON.parse(this.getGlobalValue(53));
        if (mappingRules.length > 0) {
          mappingRules = this.convertNewValueMappingStructure(mappingRules);
        }
        let newMappingRules  =  mappingRules.filter(item => item.targetColumn === targetColumn);
          //let mapValue = false;
          if(newMappingRules.length > 0){
            storeMapValue = newMappingRules[0].mapValue;

            if (expression !== 'Q_VALUE') {
              expression = newMappingRules[0].expression;
            }

            if(newMappingRules[0].mappingGrid !== ""){
              let rowData = newMappingRules[0].mappingGrid;
              if (typeof(newMappingRules[0].targetValues) !== 'undefined') {
                rowData.map(row => row.targetValues.push(...newMappingRules[0].targetValues))
              }

              if(rowData.length > 0){
                mapValueTable[0].rows.length = 0;
                mapValueTable[0].rows.push(...rowData);
              }
            }
          } else {
            if(rightMappedColumn.indexOf(targetColumn) === -1){
              //target column is not mapped then disable Map value check box
              disableMapValue= true;
            }
            mapValueTable[0].rows.length = 0;
              mapValueTable[0].rows.push({rowId:1,source:'',target:'--Keep--',targetValues:[]});
          }
      }


    }

    this.setState({
        visible: visible,
        nodeList: global.columnMapperNodeList,
        targetList: targetList,
        selectedTargetSource: selectedTargetSource,
        expression: expression,
        targetColumn: targetColumn,
        mapValueTable : mapValueTable,
        mappingRules:mappingRules,
        mapValue:storeMapValue,
        disableMapValue: disableMapValue
    },()=>{
      if(visible === true && global.updateBlock){
        this.populateMapValues();
      }
    });
  }

  saveMappingRules(){
    if(this.state.expression === "" || this.state.expression === null){
      ConfirmBox.open("On making expression blank, target and source column mapping will be removed. Are you sure to proceed?")
      .then(() =>{
        this.removeMappingRule(this.state.targetColumn,this.state.selectedTargetSource,this.state.tableList);
        var visible = !this.state.visible;
        this.setState({ visible : visible});
      });
    }else{
      var visible = !this.state.visible;
      this.setState({ visible : visible});
      //let MappingRules = this.state.mappingRules;
      let newMappingRules  =  this.state.mappingRules.filter(item => item.targetColumn !== this.state.targetColumn);
      let rowData = [];
      if(this.state.mapValue){
        rowData = this.state.mapValueTable[0].rows;
      }
      let existingMappingRule = {targetColumn:this.state.targetColumn,
      sourceColumn:this.state.selectedTargetSource, expression:this.state.expression,
      mapValue:this.state.mapValue, mappingGrid:rowData, targetValues: []};
      newMappingRules.push(existingMappingRule);
      newMappingRules.map(newMappingRule => {
        if (newMappingRule.mappingGrid.length > 0) {
          newMappingRule.targetValues.push(...newMappingRule.mappingGrid[0].targetValues);
          newMappingRule.mappingGrid.map(mapping => mapping.targetValues.length = 0)
        }
        return newMappingRule;
      })

      newMappingRules = newMappingRules.filter(mappingRule => !(mappingRule.targetColumn == '' && mappingRule.sourceColumn == ''));
      this.saveGlobalValue(53,JSON.stringify(newMappingRules));
      this.setState({ mappingRules : newMappingRules});
      let rightMappedColumn = [];
      let notMappedColumn = [];
      if(this.getGlobalValue(27) !== ""){
        rightMappedColumn = JSON.parse(this.getGlobalValue(27));
      }
      if(rightMappedColumn.indexOf(this.state.targetColumn) === -1){
        if(this.getGlobalValue(35) !== ""){
          notMappedColumn = JSON.parse(this.getGlobalValue(35));
        }
        notMappedColumn.push(this.state.targetColumn);
        this.saveGlobalValue(35, JSON.stringify(notMappedColumn));
      }
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if(JSON.stringify(nextProps) !== '' && typeof(nextProps.selectedEngine) !== 'undefined' && typeof(nextProps.tableList) !== 'undefined') {
      let mappingRules = this.getGlobalValue(53);

      if (mappingRules !== '') {
        mappingRules = JSON.parse(mappingRules);
        mappingRules.map(mappingRule => {
          const previousExpression = this.getExpressionString(mappingRule.targetColumn, nextProps.tableList, nextProps.selectedEngine.value === 2);
          if (mappingRule.expression === previousExpression) {
            mappingRule.expression = this.getExpressionString(mappingRule.targetColumn, nextProps.tableList, true, nextProps.selectedEngine);
          }
          return mappingRule;
        })
        mappingRules = mappingRules.filter(mappingRule => !(mappingRule.targetColumn == '' && mappingRule.sourceColumn == ''));
        this.saveGlobalValue(53, JSON.stringify(mappingRules));
      }
      this.setState({tableList: nextProps.tableList});
    }
  }

  handleTargetChange(event) {
    let mappingRules = [];
    if(this.getGlobalValue(53) !== ""){
      mappingRules = JSON.parse(this.getGlobalValue(53));
      if (mappingRules.length > 0) {
        mappingRules = this.convertNewValueMappingStructure(mappingRules);
      }
      this.setState({mappingRules:mappingRules});
    }
    let rightMappedColumn = [];
    if(this.getGlobalValue(27) !== ""){
      rightMappedColumn = JSON.parse(this.getGlobalValue(27));
    }
    let disableMapValue = false;
    if(rightMappedColumn.indexOf(event.value) === -1){
      //target column is not mapped then disable Map value check box
      disableMapValue = true;
    }
    this.setState({disableMapValue:disableMapValue});

    let sourceColumn = this.getSourceColumnByTargetColumn(event.value, this.state.tableList);
    let expression = this.getExpressionString(event.value, this.state.tableList);
    let mapValueTable = this.state.mapValueTable;
    let newMappingRules  =  mappingRules.filter(item => item.targetColumn === event.value)
    let mapValue = false;
    if(newMappingRules.length > 0){
      mapValue = newMappingRules[0].mapValue;
      if(sourceColumn === newMappingRules[0].sourceColumn){
        expression = newMappingRules[0].expression;
      }
      if(newMappingRules[0].mappingGrid !== "" && newMappingRules[0].mappingGrid.length > 0){
        let rowData = newMappingRules[0].mappingGrid;
        rowData.map(row => row.targetValues.push(...newMappingRules[0].targetValues))
        mapValueTable[0].rows.length = 0;
        mapValueTable[0].rows.push(...rowData);
      }
      else {
        mapValueTable[0].rows.length = 0;
        mapValueTable[0].rows.push({rowId:1,source:'',target:'--Keep--',targetValues:[]});

      }
    }else {
      mapValueTable[0].rows.length = 0;
      mapValueTable[0].rows.push({rowId:1,source:'',target:'--Keep--',targetValues:[]});

    }

    this.setState({
        selectedTargetSource: sourceColumn,
        expression: expression,
        targetColumn: event.value,
        mapValue :mapValue,
        mapValueTable: mapValueTable
      },()=>{
        if(global.updateBlock){
          this.populateMapValues();
        }
      });
  }

  getSourceColumnByTargetColumn(targetColumn, tableList) {
    var sourceColumnId = [];
    var sourceColumn = [];

    tableList[1].rows.forEach((row) => {
      if (row.column === targetColumn) {
        global.columnMapperNodeList.forEach((node) => {
          if(node.node.target === row.columnId) {
            sourceColumnId.push(node.node.source);
            return true;
          }
        });
      }
    });

    if (sourceColumnId.length) {
      tableList[0].rows.forEach((row) => {
        if (sourceColumnId.indexOf(row.columnId) > -1) {
          sourceColumn.push(row.column);
        }
      })
    }

    return sourceColumn.join(',');
  }

  getExpressionString(targetColumn, tableList, isDefaultType = true, selectedEngine = '') {
    var sourceColumnId = [];
    var targetType = '';
    var nodeType = [];
    var expression = '';
    var expressionList = [];

    if (selectedEngine === '') {
      selectedEngine = this.props.selectedEngine;
    }

    tableList[1].rows.forEach((row) => {
      if (row.column === targetColumn) {
        global.columnMapperNodeList.forEach((node) => {
          if(node.node.target === row.columnId) {
            sourceColumnId.push(node.node.source);
            targetType = row.type;
            return true;
          }
        });
      }
    });

    if (sourceColumnId.length && targetType !== '') {
     tableList[0].rows.forEach((row) => {
        if (sourceColumnId.indexOf(row.columnId) > -1) {
          nodeType.push({sourceType: row.type, targetType: targetType, sourceColumn: row.column});
        }
      })
    }

    if (nodeType.length === 1) {
      if ((nodeType[0].sourceType === 'DATE' || nodeType[0].sourceType === 'DATETIME') && nodeType[0].targetType === 'CHAR') {
        expression = 'put('+nodeType[0].sourceColumn+',is8601'+(nodeType[0].sourceType === 'DATE' ? 'da' :'dt')+'.)';
      } else if(nodeType[0].sourceType === 'CHAR' && nodeType[0].targetType === 'NUM') {
        expression = 'input('+nodeType[0].sourceColumn+', best.)';
      } else if(nodeType[0].sourceType === 'NUM' && nodeType[0].targetType === 'CHAR') {
        expression = 'put('+nodeType[0].sourceColumn+', best.-L)'
      } else {
        expression = nodeType[0].sourceColumn;
      }

      if (isDefaultType === true && typeof(selectedEngine) !== 'undefined' && typeof(selectedEngine.value) !== 'undefined' && selectedEngine.value === 3) {
        expression = nodeType[0].sourceColumn;
      }
    } else {
      nodeType.forEach((node) =>{
        var expressString = '';
        if ((node.sourceType === 'DATE' || nodeType[0].sourceType === 'DATETIME') && node.targetType === 'CHAR') {
          expressString = 'put('+node.sourceColumn+',is8601'+(node.sourceType === 'DATE' ? 'da' :'dt')+'.)';
        } else if(node.sourceType === 'CHAR' && node.targetType === 'NUM') {
          expressString = 'input('+node.sourceColumn+', best.)';
        } else if(node.sourceType === 'NUM' && node.targetType === 'CHAR') {
          expressString = 'put('+node.sourceColumn+', best.-L)';
        } else {
          expressString = node.sourceColumn;
        }

        if (targetType === 'CHAR') {
          expressString = 'trim('+expressString+')';
        }

        if (isDefaultType === true && typeof(selectedEngine) !== 'undefined' && typeof(selectedEngine.value) !== 'undefined' && selectedEngine.value === 3) {
          expressString = node.sourceColumn;
        }
        expressionList.push(expressString);
      });

      if (targetType !== 'CHAR') {
        expression = expressionList.join('+');
      } else {
        expression = expressionList.join('||" "||');
      }

      if (isDefaultType === true && typeof(selectedEngine) !== 'undefined' && typeof(selectedEngine.value) !== 'undefined' && selectedEngine.value === 3) {
        expression = 'paste('+expressionList.join(',')+')';
      }
    }

    return expression;
  }

  removeMappingRule(targetColumn, sourceColumn, tableList){
    //removing mapping rule if expression is set to blank
    if(this.getGlobalValue(53) !== ""){
      let mappingRules = JSON.parse(this.getGlobalValue(53));
      if (mappingRules.length > 0) {
        mappingRules = this.convertNewValueMappingStructure(mappingRules);
      }
      let newMappingRules  =  mappingRules.filter(item => item.targetColumn !== this.state.targetColumn);
      this.saveGlobalValue(53, JSON.stringify(newMappingRules));
    }
    if(this.state.selectedTargetSource !== ""){
      let sourceColumnArray = sourceColumn.split(',').filter(item=>item);
      let targetColumnId = 0;
      let sourceColIds= [];
      tableList[1].rows.forEach((row) => {
        if(row.column === targetColumn){
          targetColumnId = row.columnId;
        }
      });
      sourceColumnArray.forEach((sourceCol) => {
        tableList[0].rows.forEach((row) => {
          if(row.column === sourceCol){
            sourceColIds.push(row.columnId);
          }
        });
      });
      sourceColIds.forEach((colid) =>{
        global.columnMapperNodeList = global.columnMapperNodeList.filter(nodeItem => nodeItem.node.source !== colid && nodeItem.node.target !== targetColumnId);
      });
      DrawMapppingLine.setLineState({ nodeList: global.columnMapperNodeList });
      DrawMapppingLine.populateColumn();
      sourceColIds.forEach((colid) =>{
        removeClassNameInElement(getSourceElement(colid), 'k-state-selected');
        removeClassNameInElement(getTargetElement(targetColumnId), 'k-state-selected');

      });
    }

  }

  handleExpressChange(event) {
    this.setState({
      expression: event.target.value
    });
  }

  handleDefaultExpressClick(event) {
    this.setState({
      expression: this.getExpressionString(this.state.targetColumn, this.state.tableList)
    });
  }

  convertNewValueMappingStructure = (mappingRules) => {
    mappingRules.map(mappingRuls => {
      if (typeof(mappingRuls.targetValues) === 'undefined') {
        mappingRuls.targetValues = [];

        if (mappingRuls.mappingGrid.length > 0) {
          mappingRuls.targetValues.push(...mappingRuls.mappingGrid[0].targetValues)
          mappingRuls.mappingGrid.map(mapping => mapping.targetValues.length = 0)
        }
      }

      return mappingRuls
    });

    return mappingRules;
  }

  render() {
      return (
        <div className="mapping-rule-pop">
          <Button primary={true} onClick={this.toggleDialog}>Mapping Rules</Button>
            {this.state.visible && <Dialog title={"Mapping Rules"} width="calc(100% - 10px)" height="calc(100vh - 20px)" modal={true} onClose={this.toggleDialog}>
                <div className="mini-popup">
                  <fieldset>
                    <div className="field_group">
                      <legend>Mapping Definition</legend>
                      <div className="filter-area am-form-inline col-12 m-0 clearfix">
                        <div className="col-6 float-left">
                          <label className="am-form-field">
                            <span className="col-3"><b>Target cols:</b></span>
                            <div className="col-6">
                              <StandardDropDown data={this.state.targetList} onChange={this.handleTargetChange} value={this.state.targetColumn}/>
                            </div>
                          </label>
                          <label className="am-form-field">
                            <span className="col-4"><b>Mapped to source:</b></span>
                            <div className="col-8">
                              {`${this.state.selectedTargetSource}`}
                            </div>
                          </label>
                        </div>
                      </div>
                    </div>
                  </fieldset>
                  <fieldset>
                    <div className="field_group">
                      <legend>Expression</legend>
                      <div className="filter-area am-form-inline col-12 align-left m-0 clearfix Expression_textarea">
                        <label className="am-form-field full-width">
                          {/* <span className=""><b>Expression:</b></span> */}
                            <textarea className="col-12" value={`${this.state.expression}`} onChange={this.handleExpressChange}/>
                        </label>
                        <Button primary={true}  onClick={this.handleDefaultExpressClick}>Default Expression</Button>
                      </div>
                    </div>
                  </fieldset>
                  <fieldset>
                    <div className="field_group">
                      <legend>Value Level Mapping</legend>
                      <div className="filter-area am-form-inline col-12 clearfix">

                          <label className="am-form-field float-left">
                            <input type="checkbox" id="map-values" className="am-checkbox" checked={this.state.mapValue}
                            onChange = {this.handleMapValueChange} disabled={this.state.disableMapValue || this.state.targetColumn === ""}/>
                            <label className="am-checkbox-label" htmlFor="map-values">Map Values</label>
                          </label>
                          <Button primary={true} className="float-right" onClick={this.handleRefresh} disabled={this.state.disableMapValue || this.state.targetColumn === ""}>Refresh</Button>

                      </div>
                      <div className="filter-area am-form-inline col-12 clearfix">
                        <div className="grid-content Height_Level_Mapping">
                          <ProcessOptionGrid tableList={this.state.mapValueTable}  />
                        </div>
                      </div>
                    </div>
                  </fieldset>
                  <div className="align-center">
                    <Button primary={true} onClick={this.saveMappingRules}>Save</Button>
                    <Button onClick={this.toggleDialog} className="small-margin">Cancel</Button>
                  </div>
                </div>
                </Dialog>}
        </div>
      );
    }
}
const mapStateToProps = createSelector(
  state => state.processFlow.tenantId,
  state => state.dataOperation.selectedEngine,
  (tenantId, selectedEngine) => ({
    tenantId,
    selectedEngine
  })
);

const mapActionsToProps = {
  setMessage: setMessage
}

BaseMappingRules.propTypes = {
  tenantId: PropTypes.string
}

Dialog.propTypes = {
    height: PropTypes.oneOfType([
        PropTypes.string
    ]),
    width: PropTypes.oneOfType([
        PropTypes.string
    ])
}
export const MappingRules = connect(mapStateToProps, mapActionsToProps)(BaseMappingRules);
