import React from 'react';

export const ColumnStatusCell = ({ tableManager, value, data, column, colIndex, rowIndex }) => {
    if (typeof(tableManager) === 'undefined') {
      return (null)
    } else {
      return (
          <div className='rgt-cell-inner' style={{display: 'flex', alignItems: 'center', overflow: 'hidden'}}>
              <ColumnStatusCellClass dataItem={data} field={column.field} value={typeof(data[column.field]) !== 'undefined' ? data[column.field]: value} column={column} tableManager={tableManager}/>
          </div>
      )
    }
}

class ColumnStatusCellClass extends React.Component {
  render() {

        return (
            <>
              {this.props.dataItem[this.props.field] === 3 &&
                <div>--</div>
              }
              {this.props.dataItem[this.props.field] !== 0 && this.props.dataItem[this.props.field] !== 3 &&
                <input type="checkbox" className="am-checkbox" id={`checkbox-status-status-${this.props.dataItem[this.props.field]}`} disabled={true} checked={true} />
              }
              {this.props.dataItem[this.props.field] !== 3 &&
                <label className={`am-checkbox-label status-${this.props.dataItem[this.props.field]}`} htmlFor={`checkbox-status-status-${this.props.dataItem[this.props.field]}`}></label>
              }
            </>
        );
    }
}
