import React from "react";
import DataOpsIdentifierConstants from '../../DataOpsIdentifierConstants';

export default class RuleTab extends React.Component {

    constructor(props){
        super(props);
        this.state = {
            processFlowAction : this.props.processFlowAction,
            disableDisplay:false,
            disableGby:false,
            disableOby:false,
            checkDisplay:false,
            checkGby:false,
            checkOby:false
        }
    }

    getGlobalValue(controlIndex){
        let controlValue = '';
        global.processBlockControlValues.forEach((control, index) => {
          if (control.controlId === controlIndex) {
            controlValue = global.processBlockControlValues[index].controlValue;

          }
        });
        return controlValue;
      }

      saveGlobalValue(controlIndex, value){
        let controlIdFound = false;
        global.processBlockControlValues.forEach((control, index) => {
          if (control.controlId === controlIndex) {
            controlIdFound = true;
            global.processBlockControlValues[index].controlValue = value;
          }
        });
        if(!controlIdFound){
            global.processBlockControlValues.push({controlId:controlIndex, controlType:controlIndex,controlValue:value});
        }
      }

    componentDidMount(){
        let checkDisplay = false;
        let checkGby = false;
        let checkOby = false;
        let disableDisplay = false;
        let disableGby = false;
        let disableOby = false;
        let ruleString = this.getGlobalValue(-93);
        let newVarRule = [];
        if(ruleString !== ""){
            newVarRule = JSON.parse(ruleString);
        }
        switch (this.state.processFlowAction) {
            case DataOpsIdentifierConstants.STRINGOPS_ALIGN_OP:
            case DataOpsIdentifierConstants.STRINGOPS_INDUCESPACING_OP:
            case DataOpsIdentifierConstants.STRINGOPS_TRIMCOMPRESS_OP:
            case DataOpsIdentifierConstants.STRINGOPS_CHANGECASE_OP:
            case DataOpsIdentifierConstants.STRINGOPS_SEARCHNREPLACE_OP:
            case DataOpsIdentifierConstants.STRINGOPS_OCCURENCE_OP:
            case DataOpsIdentifierConstants.STRINGOPS_SPLIT_OP:
            case DataOpsIdentifierConstants.TABLEOPS_RENAME_OP:
            case DataOpsIdentifierConstants.TABLEOPS_LENGTH_ADJ_OP:
            case DataOpsIdentifierConstants.TYPECONVERSION_DATETIME_OP:
            case DataOpsIdentifierConstants.TYPECONVERSION_CHARNUM_OP:
            case DataOpsIdentifierConstants.VARMAPPING_OP:
            case DataOpsIdentifierConstants.SPLIT_OP:
            case DataOpsIdentifierConstants.APPEND_OP:
            case DataOpsIdentifierConstants.APPEND_QUICK_OP:
            case DataOpsIdentifierConstants.JOIN_OP:
            case DataOpsIdentifierConstants.JOIN_QUICK_OP:
            case DataOpsIdentifierConstants.DECOLLAPSE_TWOTABLE_OP:
            case DataOpsIdentifierConstants.LINK_OP:
            disableGby = true;
            disableOby = true;
            break;
            case DataOpsIdentifierConstants.CONTINUOUSDATE_OP:
            case DataOpsIdentifierConstants.DATAOPS_OP:
            case DataOpsIdentifierConstants.DECOLLAPSE_ONETABLE_OP:
            disableOby = true;
            break;
            case DataOpsIdentifierConstants.SORTOPS_OP:
            case DataOpsIdentifierConstants.RANKOPS_OP:
            case DataOpsIdentifierConstants.SEQUQENCEOPS_OP:
            break;
            case DataOpsIdentifierConstants.PIVOT_RTC_OP:
            case DataOpsIdentifierConstants.PIVOT_CTR_OP:
            disableDisplay = true;
            disableOby = true;
            break;
            case DataOpsIdentifierConstants.CRITERIAOPS_OP:
            case DataOpsIdentifierConstants.REFERENCEPOINT_ONETABLE_OP:
            case DataOpsIdentifierConstants.REFERENCEPOINT_TWOTABLE_OP:
            case DataOpsIdentifierConstants.REFERENCEPOINT_COMPOSITE_OP:
            case DataOpsIdentifierConstants.REFERENCEPOINT_REPLACE_OP:
            case DataOpsIdentifierConstants.REFERENCEPOINT_COMBINE_OP:
            case DataOpsIdentifierConstants.COMPARE_OP:
            case DataOpsIdentifierConstants.CODEEDITOR_FILE_OP:
            case DataOpsIdentifierConstants.CODEEDITOR_TABLE_OP:
            case DataOpsIdentifierConstants.REPORTINGOPS_CUSTOM_OP:
            case DataOpsIdentifierConstants.REPORTINGOPS_DESCRIPTIVE_OP:
            case DataOpsIdentifierConstants.REPORTINGOPS_CATEGORICAL_OP:
            case DataOpsIdentifierConstants.REPORTINGOPS_CATEGORICAL_TOTAL_OP:
            case DataOpsIdentifierConstants.REPORTINGOPS_CATEGORICAL_TOTAL_UNIQUE_OP:
            case DataOpsIdentifierConstants.REPORTINGOPS_CATEGORICAL_HIERARCHICAL_OP:
            case DataOpsIdentifierConstants.REPORTINGOPS_CATEGORICAL_HIERARCHICAL_UNIQUE_OP:
            case DataOpsIdentifierConstants.PROCREPORT_ANALYSIS_CHAR_OP:
            case DataOpsIdentifierConstants.PROCREPORT_ANALYSIS_NUM_OP:
            case DataOpsIdentifierConstants.R_Report_OP:
            disableDisplay = true;
            disableGby = true;
            disableOby = true;
            break;
            default:
            break;
          }
          if(newVarRule.findIndex(rule => rule === "display") !== -1){
              checkDisplay = true;
          }
          if(newVarRule.findIndex(rule => rule === "gby") !== -1){
              checkGby = true;
          }
          if(newVarRule.findIndex(rule => rule === "oby") !== -1){
              checkOby = true;
          }
          this.setState({
            disableDisplay:disableDisplay,
            disableGby:disableGby,
            disableOby:disableOby,
            checkDisplay:checkDisplay,
            checkGby:checkGby,
            checkOby:checkOby
        });
    }

    handleOnChange = (e) =>{
        let ruleString = this.getGlobalValue(-93);
        let newVarRule = [];
        if(ruleString !== ""){
            newVarRule = JSON.parse(ruleString);
        }
        let checkDisplay = this.state.checkDisplay;
        let checkGby = this.state.checkGby;
        let checkOby = this.state.checkOby;
        if(e.target.id === "chkDisplay"){
            checkDisplay = e.target.checked;
            if(e.target.checked && newVarRule.findIndex(rule => rule === "display") === -1){
                newVarRule.push("display");
            }
            if(!e.target.checked && newVarRule.findIndex(rule => rule === "display") !== -1){
                newVarRule = newVarRule.filter(rule => rule !== "display")
            }

        }else if(e.target.id === "chkGby"){
            checkGby = e.target.checked;
            if(e.target.checked && newVarRule.findIndex(rule => rule === "gby") === -1){
                newVarRule.push("gby");
                if(!this.state.disableOby){
                    checkOby = true;
                    newVarRule.push("oby");
                }
            }
            if(!e.target.checked && newVarRule.findIndex(rule => rule === "gby") !== -1){
                newVarRule = newVarRule.filter(rule => rule !== "gby");
            }
        }else if(e.target.id === "chkOby"){
            checkOby = e.target.checked;
            if(e.target.checked && newVarRule.findIndex(rule => rule === "oby") === -1){
                newVarRule.push("oby");
            }
            if(!e.target.checked && newVarRule.findIndex(rule => rule === "oby") !== -1){
                newVarRule = newVarRule.filter(rule => rule !== "oby");
                if(!this.state.disableGby){
                    checkGby = false;
                    newVarRule = newVarRule.filter(rule => rule !== "gby");
                }
            }
        }
        this.saveGlobalValue(-93, JSON.stringify(newVarRule));
        this.setState({
          checkDisplay:checkDisplay,
          checkGby:checkGby,
          checkOby:checkOby
      });

    }

    render() {
        return(
            <div className="code_file">
            <div className="row">
            <div className="col-sm-12">
            <fieldset>
                <div className="field_group m-t-10 m-b-10">
                <legend>New Variables Rule </legend>
                    <br/>
                    <div className="am-form-field m-0">
                        <input type="checkbox" id="chkDisplay" className="am-checkbox" disabled={this.state.disableDisplay} checked={this.state.checkDisplay} onChange={this.handleOnChange}/>
                        <label className="am-checkbox-label" htmlFor="chkDisplay">Display</label>
                    </div>
                    <br/>
                    <div className="am-form-field m-0">
                        <input type="checkbox" id="chkGby" className="am-checkbox" disabled={this.state.disableGby} checked={this.state.checkGby} onChange={this.handleOnChange}/>
                        <label className="am-checkbox-label" htmlFor="chkGby">Gby</label>
                    </div>
                    <br/>
                    <div className="am-form-field m-0">
                        <input type="checkbox" id="chkOby" className="am-checkbox" disabled={this.state.disableOby} checked={this.state.checkOby} onChange={this.handleOnChange}/>
                        <label className="am-checkbox-label" htmlFor="chkOby">Oby</label>
                    </div>
                </div>
            </fieldset>
            </div>
            </div>
            </div>
        );
    }
}
