import React, { Component } from 'react';
import axios from 'axios';
import { stringify } from 'qs';
import { Route, Switch } from 'react-router-dom';
import { Container } from 'reactstrap';
import { connect } from 'react-redux';
import { createSelector } from 'reselect';
import IdleTimer from 'react-idle-timer';
import jwt_decode from 'jwt-decode';
import $ from 'jquery';
import { CognitoUserPool, CognitoUser, CognitoRefreshToken } from 'amazon-cognito-identity-js';

import { DEFAULT_LOGIN_URL, SYSTEM_IDLE_TIMEOUT, DEFAULT_CLIENT_ID, DEFAULT_AWS_REGION, DEFAULT_REDIRECT_URI, DEFAULT_CLIENT_DOMAIN } from './../../config';

import {
  AppAside,
  AppFooter,
  AppHeader,
  AppSidebar,
} from '@coreui/react';
// sidebar nav config
import { NavigationBarMenu, Notification } from '../../views/Resource';
// routes config
import routes from '../../routes';
import DefaultAside from './DefaultAside';
import DefaultFooter from './DefaultFooter';
import DefaultHeader from './DefaultHeader';
import './custSidebar.css';
import Splitter from 'm-react-splitters';
import 'm-react-splitters/lib/splitters.css';

import { setToken } from '../../actions/actionLogin';
import { fetchSelectedStudy, setTreePrepared } from '../../actions/actionUserPreference';
import { fetchProject } from '../../actions/actionProject';
import { buildTreeViewStatus } from '../../actions/actionTreeView';
import { addStudy } from '../../actions/actionStudy';

class DefaultLayout extends Component {
    constructor(props) {
        super(props);

        let loginFeature = true;

        if (window.location.host === "localhost:3000") {
          loginFeature = true;
        }
        if (localStorage.getItem("login_feature") !== null) {
          loginFeature = eval(localStorage.getItem("login_feature"));

          if (localStorage.getItem("id_token") !== null) {
            const token_detail = jwt_decode(localStorage.getItem("id_token"));
            props.setToken(Object.assign({token_detail: token_detail, id_token: localStorage.getItem("id_token")}));
          }
        }

        this.state = {
          loginFeature: loginFeature,
          userLoggedIn:false
        }

        this.idleTimer = null
        this.onAction = this._onAction.bind(this)
        this.onActive = this._onActive.bind(this)
        this.onIdle = this._onIdle.bind(this)
    }

    getCognitoUser = (tokenDetail) => {
      const poolData = {
        UserPoolId : this.props.cognito_user_pool_id,
        ClientId : DEFAULT_CLIENT_ID
      };
      const userPool = new CognitoUserPool(poolData);
      const userData = {
        Username : tokenDetail.email,
        Pool : userPool
      };
      return new CognitoUser(userData);
    }

    getTokens = (session) => {
      return {
        access_token: session.getAccessToken().getJwtToken(),
        id_token: session.getIdToken().getJwtToken(),
        refresh_token: session.getRefreshToken().getToken()
      };
    };

    redirectTologinPage = () => {
      if (this.state.loginFeature === true) {
        this.setState({userLoggedIn:false});
        localStorage.clear();
        window.location.href = DEFAULT_LOGIN_URL;
      }
    }

    _onAction(e) {
      if (SYSTEM_IDLE_TIMEOUT <= this.idleTimer.getElapsedTime()) {
        this.idleTimer.reset();

        if (this.props.refresh_token !== null && this.props.cognito_user_pool_id !== null && this.props.cognito_user_pool_id !== '') {
          let that = this;
          const RefreshToken = new CognitoRefreshToken({RefreshToken: this.props.refresh_token});

          let cognitoUser = this.getCognitoUser(this.props.token_detail);

          cognitoUser.refreshSession(RefreshToken, (err, session) => {
            if (err) {
              that.redirectTologinPage();
            } else {
              let token = that.getTokens(session);
              let token_detail = jwt_decode(token.id_token);
              that.props.setToken(Object.assign({token_detail: token_detail, ...token}));
              that.setState({userLoggedIn:true});
            }

          });
        } else {
          this.redirectTologinPage();
        }
      }

   }

   _onActive(e) {
   }

   _onIdle(e) {
     this.redirectTologinPage();
   }

   UNSAFE_componentWillMount() {
     if (this.state.loginFeature === true && window.location.search === '') {
       const urlParams = new URLSearchParams(this.props.location.search)
       const code = urlParams.get('code');

       if (code !== null && code !== '') {
          $(".loader").show();
           let url = DEFAULT_CLIENT_DOMAIN+'.auth.'+DEFAULT_AWS_REGION+'.amazoncognito.com/oauth2/token';
           const config = {
               headers: {
                   'Content-Type': 'application/x-www-form-urlencoded'
               }
           }

           var requestBody = {
             grant_type: 'authorization_code',
             client_id: DEFAULT_CLIENT_ID,
             redirect_uri: DEFAULT_REDIRECT_URI,
             code: code
           }

           axios.post(url, stringify(requestBody), config)
             .then((response) => {
               if (response !== '' && typeof(response.data) !== 'undefined' && typeof(response.data.id_token) !== 'undefined') {
                 let token_detail = jwt_decode(response.data.id_token);

                 this.props.setToken(Object.assign({token_detail: token_detail, ...response.data}));
                 this.props.fetchSelectedStudy();
                 this.setState({userLoggedIn:true});
                 $(".loader").hide();
               } else {
                 this.redirectTologinPage();
               }
             })
             .catch((err) => {
               this.redirectTologinPage();
            })
        } else {
         this.redirectTologinPage();
       }
     }
     if(this.state.loginFeature === false){
      this.props.fetchSelectedStudy();
     }
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    $(".loader").hide();
    if(JSON.stringify(nextProps) !== '' && typeof(nextProps.userPreference) !== "undefined" && nextProps.userPreference !== null
    && typeof(nextProps.project) !== "undefined" && nextProps.project !== null) {
      if(nextProps.userPreference.isStudyListFetched && nextProps.project.apiConnect){
        this.props.fetchProject(nextProps.userPreference.studyList);
      }
      if(!nextProps.project.apiConnect && !nextProps.userPreference.isTreePrepared){
        nextProps.project.projectList.forEach((item) => {
          if(typeof(item.study) === 'undefined') {
            if(item.studyList === null || typeof(item.studyList) === 'undefined'){
              item.studyList = [];
            }
            item.study = item.studyList;
            }
            item.study.forEach(study => {
              this.props.addStudy(study);
            });
        })
        this.props.buildTreeViewStatus(true);
        this.props.setTreePrepared(true);
      }

    }
  }

  render() {
    if (this.state.loginFeature === true && !this.state.userLoggedIn) {
      return (
        <div></div>
      )
  }
    return (
      <div className="app">
        <AppHeader fixed>
          <DefaultHeader />
        </AppHeader>
        <div id="mainSplitter" className="app-body">
        <Splitter
          position="vertical"
            primaryPaneMaxWidth="80%"
            primaryPaneMinWidth="200px"
            primaryPaneWidth="200px"
          >
          <AppSidebar fixed display="lg" >
            <NavigationBarMenu {...this.props} />
          </AppSidebar>
            <main className="main">
              <Container fluid>
                <Switch>
                  { routes.map((route, idx) => {
                      return route.component ? (<Route key={idx} path={route.path} exact={route.exact} name={route.name} render={props => (
                          <route.component {...props} />
                        )} />)
                        : (null);
                    },
                  )}
                </Switch>
                <Notification />
              </Container>
            </main>
        </Splitter>
        {
          /*
          <AppAside fixed hidden>
            <DefaultAside />
          </AppAside>
          */
        }

        </div>
        <div className="loader" style={{display:'none'}}>
            <div className="loader_overlay">
            </div>
        </div>
        {<AppFooter>
          <DefaultFooter />
        </AppFooter>}
        {this.state.loginFeature === true &&
          <IdleTimer
            ref={ref => { this.idleTimer = ref }}
            element={document}
            onActive={this.onActive}
            onIdle={this.onIdle}
            onAction={this.onAction}
            timeout={SYSTEM_IDLE_TIMEOUT} />
        }

      </div>
    );
  }
}


const mapStateToProps = createSelector(
  state => state.login.token,
  state => state.login.token_detail,
  state => state.login.refresh_token,
  state => state.login.cognito_user_pool_id,
  state => state.userPreference,
  state => state.project,
  (token, token_detail, refresh_token, cognito_user_pool_id, userPreference, project) => ({
    token,
    token_detail,
    refresh_token,
    cognito_user_pool_id,
    userPreference,
    project
  })
);

const mapActionsToProps = {
  setToken: setToken,
  fetchSelectedStudy: fetchSelectedStudy,
  fetchProject: fetchProject,
  buildTreeViewStatus: buildTreeViewStatus,
  addStudy: addStudy,
  setTreePrepared: setTreePrepared
}

export default connect(mapStateToProps, mapActionsToProps)(DefaultLayout);
