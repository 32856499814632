import React from "react";
import { TabStrip, TabStripTab  } from './../common/Layout';
import { Button } from './../common/Button/StandardButton';
import { ProcessOptionGrid } from "../common/gridOperation";
import { StandardDropDown, NewColumnSpanCell, PivotOutputCell, MultiSelectDropDownList, SourceColumnCell, NewColumnNameCell } from '../common';
import DataOpsIdentifierConstants from '../../../DataOpsIdentifierConstants';
import RichGridTable from './../../RichGridTable';

function PivotMatrixLayoutTemplate() {
  return (
    <div className="process-option">
      <TabStrip keepTabsMounted={true} selected={this.state.tabSelected} onSelect={this.handleSelectTabPanel}>
        <TabStripTab title="GBY">
          <div className="field_group">
            {/* <div>
              <label className="am-form-field">
                <span>Find:</span>
                <input className="am-textbox m_l_5" placeholder="Search data" />
              </label>
            </div> */}
            <div className="height_fixed_step1 full-width-table">
              <ProcessOptionGrid tableList={this.state.tableList} />
            </div>
          </div>
        </TabStripTab>
        <TabStripTab title="Metrics">
          <PivotMatrixProcess processFlowAction={this.state.processFlowAction} inputColumns={this.state.inputColumns} />
        </TabStripTab>
      </TabStrip>

    </div>
  );
};

class PivotMatrixProcess extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      inputColumns: props.inputColumns,
      processFlowAction: props.processFlowAction,
      tableList:{
        id: 'data-operation-pivot-matrix-grid',
        rows: [],
        resize: true,
        filterable: false,
        selectedField: "selected",
        dragAndDrop: false,
        columnProperty: [
          { field: "rowId", show: false },
          { field: "columnId", show: false },
          { field: "sourceColumnList", show: false },
          { field: "blankCell", show: false },
          { field: "targetColumn", show: false },
          { field: "targetColumnTitle", title: 'Target Column', cell: NewColumnNameCell, width:"300px"},
          { field: "pivotOutput", cell: PivotOutputCell, width:"100px"}
        ]
      },
      selectedTargetColumnList: [],
      pivotOperationList: ["Pivot Operation 1"],
      selectedPivotOperationList: ["Pivot Operation 1"],
      gridKey: ["Pivot Operation 1"].join('-')
    };
    this.getGlobalValue = this.getGlobalValue.bind(this);
    this.handlePivotOperationChange = this.handlePivotOperationChange.bind(this);
  }

  componentDidMount() {
    const totalPivotOperation = this.getGlobalValue(92);
    let pivotRows = this.getGlobalValue(91);

    if (pivotRows !== '') {
      let {tableList, selectedTargetColumnList, pivotOperationList, selectedPivotOperationList, gridKey} = this.state;

      pivotRows = JSON.parse(pivotRows);
      selectedTargetColumnList.length = 0;
      pivotRows.forEach(pivotRow => {
        selectedTargetColumnList.push(pivotRow.targetColumn)
      })

      selectedTargetColumnList = [...new Set(selectedTargetColumnList)];

      pivotOperationList.length = 0;
      selectedPivotOperationList.length = 0;

      for (var i = 1; i <= eval(totalPivotOperation); i++) {
        const newPivotOperation = 'Pivot Operation '+(pivotOperationList.length+1);
        pivotOperationList.push(newPivotOperation);
        selectedPivotOperationList.push(newPivotOperation)
      }

      pivotOperationList = [...new Set(pivotOperationList)];
      selectedPivotOperationList = [...new Set(selectedPivotOperationList)];

      tableList.rows.length = 0;
      tableList.rows.push(...pivotRows);

      this.setState({tableList: tableList, selectedTargetColumnList: selectedTargetColumnList, pivotOperationList: pivotOperationList, selectedPivotOperationList: selectedPivotOperationList}, () => {
        if (selectedTargetColumnList.length > 0) {
          this.handleTargetTableColumnChange(null, selectedTargetColumnList)
        }
      })
    }

  }

  handleTargetTableColumnChange = (event, selectedColumnList = []) => {
    let { tableList, selectedPivotOperationList, pivotOperationList } = this.state;
    let rows = [];
    let columnList = ["", "[Enter Text]"];
    let selectedTargetColumnList = selectedColumnList.length > 0 ? selectedColumnList : event.value

    columnList.push(...this.state.inputColumns)

    let groupTitleList = [];
    selectedTargetColumnList.forEach(targetColumn => {
      let row = {rowId: rows.length, columnId: rows.length, selected: false, targetColumn: targetColumn, targetColumnTitle: '', pivotOutput: 'Q_VALUE', blankCell: [], columnList: columnList, selectedSourceColumnList: []}
      let changeRow = tableList.rows.filter(changeRow => changeRow.targetColumn === targetColumn);

      if (changeRow.length > 0) {
        changeRow = changeRow[0];
        row.blankCell = changeRow.blankCell
        row.pivotOutput = changeRow.pivotOutput
        row.targetColumnTitle = changeRow.targetColumnTitle

        if (typeof(changeRow.selectedSourceColumnList) !== 'undefined') {
          row.selectedSourceColumnList = changeRow.selectedSourceColumnList
        }
      }
      selectedPivotOperationList.forEach((title, index) => {
        const name = 'group'+(parseInt(title.replace('Pivot Operation ', '')));
        if (groupTitleList.filter(groupTitle => groupTitle.name === name).length === 0) {
          groupTitleList.push({name: name, title: title})
        }

        row = Object.assign(row, {[name]: typeof(changeRow[name]) !== 'undefined' ? changeRow[name] : ''})
      })

      row.blankCell = row.blankCell.filter(blankGroup => groupTitleList.filter(groupTitle => groupTitle.name === blankGroup).length > 0)

      if (typeof(row.selectedSourceColumnList) !== 'undefined') {
        groupTitleList.forEach(groupTitle => {
          if (row.blankCell.indexOf(groupTitle.name) === -1 && typeof(row[groupTitle.name]) !== 'undefined' && row.selectedSourceColumnList.filter(selectedSourceColumn => selectedSourceColumn.groupTitle === groupTitle.name).length === 0) {
            row.selectedSourceColumnList.push({groupTitle: groupTitle.name, column: row[groupTitle.name]})
          }
        })
      }

      row.selectedSourceColumnList = row.selectedSourceColumnList.filter(selectedSourceColumn => groupTitleList.filter(groupTitle => groupTitle.name === selectedSourceColumn.groupTitle).length > 0)

      selectedPivotOperationList.forEach((title, index) => {
        const oldGroupIndex = parseInt(title.replace('Pivot Operation ', ''));
        const oldGroupName = 'group'+(oldGroupIndex);
        const newGroupName = 'group'+ (index+1);

        if (oldGroupName !== newGroupName && oldGroupIndex > (index+1) && typeof(row[newGroupName]) === 'undefined') {
          const oldGroupValue = row[oldGroupName];
          delete row[oldGroupName];
          row = Object.assign(row, {[newGroupName]:oldGroupValue});

          if (row.blankCell.indexOf(oldGroupName) > -1 && row.blankCell.indexOf(newGroupName) === -1) {
            row.blankCell = row.blankCell.filter(blankGroup => blankGroup !== oldGroupName);
            row.blankCell.push(newGroupName)
          }

          if (typeof(row['selectedSourceColumnList']) !== 'undefined') {
            row['selectedSourceColumnList'].filter(selectedSourceColumn => selectedSourceColumn.groupTitle === oldGroupName).map(selectedSourceColumn => {
              selectedSourceColumn.groupTitle = newGroupName
              return selectedSourceColumn
            })
          }
        }
      })

      rows.push(row)
    })

    if (rows.length > 0) {
      for (var key in rows[0]) {
        if (rows[0].hasOwnProperty(key) && key.indexOf("group") > -1) {
          tableList.columnProperty = tableList.columnProperty.filter(columnProperty => columnProperty.field !== key)
          let title = "Pivot Operation "+key.replace('group', '');
          if (groupTitleList.filter(groupTitle => groupTitle.name === key).length > 0) {
            title = groupTitleList.filter(groupTitle => groupTitle.name === key)[0].title;
          }

          tableList.columnProperty.push({ field: key, cell: SourceColumnCell, title: title, width:"200px"})
        }
      }
    }

    tableList.rows.length = 0;
    tableList.rows.push(...rows)

    selectedPivotOperationList.forEach((selectedPivotOperation, index) => {
      selectedPivotOperationList[index] = 'Pivot Operation '+(index+1)
    })

    if (pivotOperationList.length < selectedPivotOperationList.length) {
      pivotOperationList = selectedPivotOperationList;
    }

    this.setState({selectedTargetColumnList: selectedTargetColumnList, tableList: tableList, pivotOperationList: pivotOperationList, gridKey: selectedPivotOperationList.join('-')+tableList.rows.length, selectedPivotOperationList: selectedPivotOperationList}, () => {
      this.saveGlobalValue(92, selectedPivotOperationList.length);
    })
  }

  handleAddPivotOperationClick = (event) => {
    let {pivotOperationList, selectedPivotOperationList} = this.state;
    const newPivotOperation = 'Pivot Operation '+(pivotOperationList.length+1);
    pivotOperationList.push(newPivotOperation);
    selectedPivotOperationList.push(newPivotOperation)

    pivotOperationList = [...new Set(pivotOperationList)];
    selectedPivotOperationList = [...new Set(selectedPivotOperationList)];
    this.saveGlobalValue(92, selectedPivotOperationList.length);
    this.setState({pivotOperationList: pivotOperationList, selectedPivotOperationList: selectedPivotOperationList}, () => {
      if (this.state.selectedTargetColumnList.length > 0) {
        this.handleTargetTableColumnChange(event, this.state.selectedTargetColumnList)
      }
    })
  }

  getGlobalValue(controlIndex){
    let controlValue = '';
    global.processBlockControlValues.forEach((control, index) => {
      if (control.controlId === controlIndex) {
        controlValue = global.processBlockControlValues[index].controlValue;

      }
    });
    return controlValue;
  }

  saveGlobalValue(controlIndex, value){
    global.processBlockControlValues.forEach((control, index) => {
      if (control.controlId === controlIndex) {
        global.processBlockControlValues[index].controlValue = value;
      }
    });
  }

  handlePivotOperationChange = (event) => {
    let selectedPivotOperationList = event.value
    selectedPivotOperationList.sort((a, b) => parseInt(a.replace('Pivot Operation ', '')) - parseInt(b.replace('Pivot Operation ', '')));;
    this.saveGlobalValue(92, selectedPivotOperationList.length);
    this.setState({selectedPivotOperationList: selectedPivotOperationList}, () => {
      if (this.state.selectedTargetColumnList.length > 0) {
        this.handleTargetTableColumnChange(event, this.state.selectedTargetColumnList)
      }
    })
  }

  handleAddTargetRowClick = (event) => {
    let selectedTargetColumnList = this.state.selectedTargetColumnList;
    let nextCount = 1;

    if (selectedTargetColumnList.length > 0) {
      let lastTargetColumn = selectedTargetColumnList[selectedTargetColumnList.length - 1];
      nextCount = eval(lastTargetColumn.replace('Q_COLUMN_', '')) + 1
    }
    const newTargetColumn = 'Q_COLUMN_'+nextCount;
    selectedTargetColumnList.push(newTargetColumn)
    selectedTargetColumnList = [...new Set(selectedTargetColumnList)];

    this.setState({selectedTargetColumnList: selectedTargetColumnList}, () => {
      if (this.state.selectedTargetColumnList.length > 0) {
        this.handleTargetTableColumnChange(event, this.state.selectedTargetColumnList)
      }
    })
  }

  handleRemoveTargetRowClick = (event) => {
    let {selectedTargetColumnList, tableList, selectedPivotOperationList} = this.state;

    tableList.rows.forEach(row => {
      if (row.selected === true) {
        selectedTargetColumnList = selectedTargetColumnList.filter(selectedTargetColumn => selectedTargetColumn !== row.targetColumn)
      }
    })

    let rows = tableList.rows.filter(row => row.selected === false);
    tableList.rows.length = 0;
    tableList.rows.push(...rows)

    this.setState({selectedTargetColumnList: selectedTargetColumnList, tableList: tableList, gridKey: selectedPivotOperationList.join('-')+tableList.rows.length})
  }

  render() {
      return (
        <div className="data-ops-process pivot-order-dialog">
          <div className="row m-t-10">
            <div className="col-6">
              <div className="am-form-field dropdown-list float-left m-r-10">
                <Button primary={true} onClick={this.handleAddTargetRowClick} > Add Row</Button>
              </div>
              <div className="am-form-field dropdown-list float-left m-r-10">
                <Button primary={true} onClick={this.handleRemoveTargetRowClick} > Remove Row</Button>
              </div>
              <div className="am-form-field dropdown-list float-left pivot-list">
                <span><b>Pivot Operation List: </b></span>
                <MultiSelectDropDownList data={[...new Set(this.state.pivotOperationList)]} onChange={this.handlePivotOperationChange} value={[...new Set(this.state.selectedPivotOperationList)]}/>
              </div>
              <div className="am-form-field dropdown-list">
                <Button primary={true} onClick={this.handleAddPivotOperationClick} > Add</Button>
              </div>
            </div>
            <div className="col-12 matrix-list">
              <div className="height_fixed_step1 full-width-table">
                {this.state.tableList.rows.length > 0 &&
                  <RichGridTable {...this.state.tableList} key={this.state.gridKey} />
                }
              </div>
            </div>
          </div>
        </div>
      );
    }
}
export default PivotMatrixLayoutTemplate;
