import React from "react";
import "../../Resource/DataProperties/DataProperties.css";
import { PropTypes } from "prop-types";
import Dialog from 'react-dialog';
import { Button } from './../DataOperation/common/Button/StandardButton';
import RichGridTable from '../RichGridTable';

function template() {
    return (
        <div className="data-properties process-manage-version " >
        <Dialog className="data-properties" title={'Version Manager'} width="85%" height="auto" modal={true} onClose={this.closeDialog} buttons={[<Button primary={true} onClick={this.updateVersionDetails} autoFocus={true} disabled={this.state.disabledButton} key="btn-save"> Save</Button>,<Button onClick={this.closeDialog} key="btn-cancel"> Cancel</Button>]}>
          <div className="m-b-10 richgrid-height-165">
            <RichGridTable {...this.state.tableList[0]} />
          </div>
        </Dialog>
        </div>
    )
}


Dialog.propTypes = {
    height: PropTypes.oneOfType([
        PropTypes.string
    ]),
    width: PropTypes.oneOfType([
        PropTypes.string
    ])
}
export default template;
