import React from 'react';

export const DragAndDropCell = ({ tableManager, value, data, column, colIndex, rowIndex }) => {
    if (typeof(tableManager) === 'undefined') {
      return (null)
    } else {
      return (
          <div className='rgt-cell-inner' style={{display: 'flex', alignItems: 'center', overflow: 'hidden'}}>
              <DragAndDropCellClass dataItem={data} field={column.field} value={value} tableManager={tableManager}/>
          </div>
      )
    }
}

export class DragAndDropCellClass extends React.Component {

    handleChange(e) {
      this.props.dataItem['selected'] = e.target.checked
      this.props.dataItem[this.props.field] = e.target.checked;
      this.props.tableManager.rowsApi.setRows(this.props.tableManager.rowsApi.rows)

    }
    dragOverEvent(e) {
      e.preventDefault();
    }

    draggingEvent(e) {
    }
    dropedEvent(e) {
      e.preventDefault();
    }
    dragStartEvent(e) {
    }
    dragEndEvent(e) {
    }
    render() {
        return (
          <>
              <span
                  className={`k-icon k-i-reorder`}
                  draggable="true"
                  style={{ cursor: "move" }}
                  onDragStart={(e) => {
                      this.dragStartEvent(e);
                      e.dataTransfer.setData("dragging", "");
                  }}
              >
              </span>
          </>
        );
    }
}
