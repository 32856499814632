import React from "react";
import TextEditor from '../../TextEditor';
import DataOpsIdentifierConstants from '../../../DataOpsIdentifierConstants';
function getGlobalValue(controlIndex){
  let controlValue = '';
  global.processBlockControlValues.forEach((control, index) => {
    if (control.controlId === controlIndex) {
      controlValue = global.processBlockControlValues[index].controlValue;

    }
  });
  return controlValue;
}

function saveGlobalValue(controlIndex, value){
  global.processBlockControlValues.forEach((control, index) => {
    if (control.controlId === controlIndex) {
      global.processBlockControlValues[index].controlValue = value;
    }
  });
}
function CodeEditorLayoutTemplate() {
  let CodeEditorTemplate = 16;
  let defaultFileValue = "/****************************************************************************************************************************\n"+
  "Input Table : &_inlib.&_g_strInputTable                     Output File : file=\"&_g_executeFolderPath.&_g_strOutputTable..pdf\";\n"+
  "e.g. ods pdf file=\"&_g_executeFolderPath.&_g_strOutputTable..pdf\";\n"+
  "     proc report data=&_inlib.&_g_strInputTable nowd headline headskip;\n"+
  "          column usubjid;\n"+
  "          define usubjid / display;\n"+
  "    run;\n"+
  "    ods pdf close;\n"+
  "*****************************************************************************************************************************/";
  let defaultTableValue = "/****************************************************************************************************************************\n"+
  "Input Table : &_inlib.&_g_strInputTable                                       Output Table : &_outlib.&_g_strOutputTable\n"+
  "e.g. data &_outlib.&_g_strOutputTable;\n" +
  "     set &_inlib.&_g_strInputTable;\n"+
  "   run;\n"+
  "****************************************************************************************************************************/";

  const defaultREngineTableValue = "####################################################################################################\n"+
  "# Create DataFrame from Database\n"+
  "####################################################################################################\n\n"+
  "#tic(\"[1] [LOG]  \")\n"+
  "#in_dt <- dbReadTableDF(\"inlib\", \"g_strInputTable\")\n"+
  "#setDT(in_dt)\n"+
  "#toc()\n"+
  "#dataframe_log(df=in_dt)\n\n"+
  "####################################################################################################\n"+
  "# Data Processing\n"+
  "####################################################################################################\n\n"+
  "####################################################################################################\n"+
  "# Write DataFrame to Database\n"+
  "####################################################################################################\n"+
  "#tic()\n"+
  "#eval(parse(text=paste0(g_strOutputTable, ' <- in_dt')))\n"+
  "#eval(parse(text=paste0('dbWriteTableDF(outlib, \"', g_strOutputTable,'\", ', g_strOutputTable, ')')))\n"+
  "#toc()\n\n"+
  "#eval(parse(text=paste0('dataframe_log(df=', g_strOutputTable,')')));";

  let actioName = getGlobalValue(-1);
  if(getGlobalValue(CodeEditorTemplate) === ""){
    switch (actioName) {
      case DataOpsIdentifierConstants.CODEEDITOR_TABLE_OP:
      saveGlobalValue(CodeEditorTemplate,defaultTableValue);
      break;
      case DataOpsIdentifierConstants.CODEEDITOR_FILE_OP:
      saveGlobalValue(CodeEditorTemplate , defaultFileValue);
      break;
      default:
        break;
    }
  }
  var TemplateIndex  = 16;
  var defaultCode = getGlobalValue(CodeEditorTemplate);
  var handleOnChange = (code) =>{
    defaultCode = code;
    saveGlobalValue(TemplateIndex, code);

  }

  var handleCodeByEngineChange = (engineId) => {
    let engineDefaultCode = defaultTableValue;
    if (engineId === 3) {
      engineDefaultCode = defaultREngineTableValue
    }

    if (typeof(global.codeMirrorInstance) !== 'undefined') {
        global.codeMirrorInstance.getDoc().setValue(engineDefaultCode);
    }
    handleOnChange(engineDefaultCode)
  }

  CodeEditorLayoutTemplate.handleCodeByEngineChange = handleCodeByEngineChange

  return (
    <div className="code_file process-option">
      <TextEditor options={{ lineNumbers: true, undoRedo: true}} code={defaultCode} onChange= {handleOnChange}/>
    </div>
  );
};

export default CodeEditorLayoutTemplate;
