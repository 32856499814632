import React from "react";
import { connect } from 'react-redux';
import { Button } from './../DataOperation/common/Button/StandardButton';
import { Input } from './../DataOperation/common/InputBox/StandardInput';
import { PropTypes } from "prop-types";
import Dialog from 'react-dialog';
import { TabStrip, TabStripTab } from './../DataOperation/common/Layout';
import { DropDownList } from './../DataOperation/common/DropDown/StandardDropDownList';
import cloneDeep from 'lodash/cloneDeep';
import { createSelector } from 'reselect';
import MessageTypeConst from '../../MessageTypeConst';
import { manageWhereMatrix, manageAdditionalWhereCondition } from './../../../actions/actionAnnotate';
import { setMessage } from './../../../actions/actionNotification';
import { NewColumnNameCell } from './../DataOperation/common';
import RichGridTable from './../RichGridTable';

class WhereMatrix extends React.Component {
    constructor(props) {
        super(props);

        if (typeof(global.gridRef) !== 'undefined') {
          delete global.gridRef;
        }

        this.state = {
          node: props.node,
          closeEvent:props.onClose,
          tableList:{
            id: 'annotate-where-matrix-grid',
            rows: [],
            resize: true,
            filterable: false,
            selectedField: "selected",
            dragAndDrop: false,
            columnProperty: [
              { field: "rowId", show: false },
              { field: "tableName", show: false },
              { field: "displayTableName", width:"300px", title: (props.node.type === 'source' ? "Target" : "Source") +" Table Name"},
              { field: "whereCondition", cell: NewColumnNameCell},
              { field: "isVirtualSourceTable", show: false }
            ]
          },
          additionalWhereCondition: '',
          selected: 0
        }

        this.getDisplayTableNameMapping = this.getDisplayTableNameMapping.bind(this);
        this.handleSaveClick = this.handleSaveClick.bind(this);
        this.handleAdditionalWhereConditionChange = this.handleAdditionalWhereConditionChange.bind(this);
    }

    componentDidMount() {
      const whereMatrixList = this.props.whereMatrixList;
      const additionalWhereConditionList = typeof(this.props.additionalWhereConditionList) !== 'undefined' ? this.props.additionalWhereConditionList : [];
      let tableList = this.state.tableList;
      let getDisplayTableNameMapping = this.getDisplayTableNameMapping();
      let additionalWhereCondition = '';
      const blockName = typeof(this.state.node.blockName) !== 'undefined' ? this.state.node.blockName.replace('_meta', '').toLowerCase() : '';
      getDisplayTableNameMapping.filter(table => table.type !== this.state.node.type).forEach(table => {
        const whereMatrix = whereMatrixList.find(whereMatrix => (this.state.node.type === "source" && whereMatrix.sourceTable === this.state.node.tableName && whereMatrix.tableName === table.tableName) || (this.state.node.type !== "source" && whereMatrix.sourceTable === table.tableName && whereMatrix.tableName === this.state.node.tableName))
        tableList.rows.push({
          rowId: tableList.rows.length + 1,
          tableName: table.tableName,
          displayTableName: table.displayTableName,
          whereCondition: typeof(whereMatrix) !== 'undefined' ? whereMatrix.whereCondition : '',
          isVirtualSourceTable: false
        })
      })

      if (blockName !== '' && additionalWhereConditionList.length > 0) {
        additionalWhereConditionList.filter(tableWhereCondition => tableWhereCondition.blockName === blockName && tableWhereCondition.type === 'metadata' && tableWhereCondition.tableName === this.state.node.tableName).forEach(tableWhereCondition => {
          additionalWhereCondition = tableWhereCondition.whereCondition
        })
      }

      this.props.hardcodeList.filter(hardcode => typeof(hardcode.hardcode) !== 'undefined' && hardcode.hardcode.type === 'Hardcode' && hardcode.tableName === this.state.node.tableName && hardcode.column === 'RDOMAIN').forEach(hardcode => {
        getDisplayTableNameMapping.filter(table => table.type === 'metadata' && table.blockName === hardcode.hardcode.columnHardcode.replaceAll('"', '').toLowerCase()+'_meta').forEach(table => {
          const whereMatrix = whereMatrixList.find(whereMatrix => whereMatrix.sourceTable === table.tableName && whereMatrix.tableName === this.state.node.tableName)
          tableList.rows.length = 0;
          tableList.rows.push({
            rowId: tableList.rows.length + 1,
            tableName: table.tableName,
            displayTableName: 'IN:('+hardcode.hardcode.columnHardcode.replaceAll('"', '')+')',
            whereCondition: typeof(whereMatrix) !== 'undefined' ? whereMatrix.whereCondition : '',
            isVirtualSourceTable: true
          })
        })
      })
      this.setState({tableList: tableList, additionalWhereCondition: additionalWhereCondition})
    }

    getDisplayTableNameMapping = () => {
      let {sourceTables, metaTableTables} = this.props.tableExtendedList;
      const columnMappedList = this.props.columnMappedList;
      let displayTableNameMapping = [];
      let tableName = null;
      let displayTableName = null;
      let tableVersion = null;

      if (typeof(sourceTables) !== 'undefined') {
        sourceTables.forEach((sourceTable) => {
          tableName = sourceTable.tableName.replace('_extended', '');
          tableVersion = tableName.split('_');
          tableVersion = tableVersion[tableVersion.length-1];
          displayTableName = sourceTable.blockName + ' ('+tableVersion+')';

          if (columnMappedList.filter(columnMapped => columnMapped.table.source === tableName && columnMapped.table.target === this.state.node.tableName).length > 0) {
              displayTableNameMapping.push({tableName: tableName, displayTableName: displayTableName, type: "source", blockName: sourceTable.blockName});
          }
        })
      }

      if (typeof(metaTableTables) !== 'undefined') {
        metaTableTables.forEach((metadataTable) => {
          tableName = metadataTable.tableName.replace('_extended', '');
          tableVersion = tableName.split('_');
          tableVersion = tableVersion[tableVersion.length-1];
          displayTableName = this.getBlockName(metadataTable.blockName) + ' ('+tableVersion+')';

          displayTableNameMapping.push({tableName: tableName, displayTableName: displayTableName, type: "metadata", blockName: metadataTable.blockName});
        })
      }

      return displayTableNameMapping;
    }

    getBlockName = (blockName) => {
      if (blockName !== null && blockName !== '') {
        let blockNameSplit = blockName.split('_meta');

        if (blockNameSplit[blockNameSplit.length - 1] === '') {
          blockNameSplit.length = blockNameSplit.length - 1;
        }

        blockName = blockNameSplit.join('_meta')
      }

      return blockName;
    }

    handleSaveClick = (event) => {
      let treeElement = this.props.treeElement;
      let whereMatrixList = this.props.whereMatrixList.filter(whereMatrix => whereMatrix.tableName !== this.state.node.tableName);

      this.state.tableList.rows.forEach(row => {
        let isNewWhereMatrix = true;
        whereMatrixList.filter(whereMatrix => (this.state.node.type === "source" && whereMatrix.sourceTable === this.state.node.tableName && whereMatrix.tableName === row.tableName) || (this.state.node.type !== "source" && whereMatrix.sourceTable === row.tableName && whereMatrix.tableName === this.state.node.tableName)).map(whereMatrix => {
          whereMatrix.whereCondition = row.whereCondition;
          isNewWhereMatrix = false;
        })

        if (isNewWhereMatrix === true && row.whereCondition !== '') {
          whereMatrixList.push({
            sourceTable: this.state.node.type === "source" ? this.state.node.tableName : row.tableName,
            tableName: this.state.node.type !== "source" ? this.state.node.tableName : row.tableName,
            whereCondition: row.whereCondition,
            isVirtualSourceTable: typeof(row.isVirtualSourceTable) !== 'undefined' ? row.isVirtualSourceTable : false
          })
        }
      })

      if (this.state.node.type === 'metadata') {
        let additionalWhereConditionList = typeof(this.props.additionalWhereConditionList) !== 'undefined' ? this.props.additionalWhereConditionList : [];
        const blockName = typeof(this.state.node.blockName) !== 'undefined' ? this.state.node.blockName.replace('_meta', '').toLowerCase() : '';
        additionalWhereConditionList = additionalWhereConditionList.filter(additionalWhereCondition => !(additionalWhereCondition.blockName === blockName && additionalWhereCondition.type === 'metadata' && additionalWhereCondition.tableName === this.state.node.tableName));

        if (this.state.additionalWhereCondition !== '') {
            additionalWhereConditionList.push({type: "metadata", blockName: blockName, tableName: this.state.node.tableName, whereCondition: this.state.additionalWhereCondition})
        }
        this.props.manageAdditionalWhereCondition(additionalWhereConditionList)
      }

      this.props.manageWhereMatrix(whereMatrixList.filter(whereMatrix => whereMatrix.whereCondition !== ''))
      this.state.closeEvent(true)
    }

    handleAdditionalWhereConditionChange = (event) => {
      this.setState({additionalWhereCondition: event.target.value})
    }

    handleSelect = (e) => {
      this.setState({selected: e.selected});
    }

    render(){
        return(
          <div className={`where-order-dialog${this.state.node.type === 'metadata' ? ' additional-wherecondtion' : ''}`}>
            <Dialog className="expression" title={`Where Metrics - ${this.state.node.name}`} width="65%" height="calc(100vh - 176px)" onClose={(event) => this.state.closeEvent(this.state.tableList.rows.length === 0)} buttons={this.state.tableList.rows.length > 0 ? [<Button primary={true} onClick={this.handleSaveClick} key="btn-save"> Save</Button>, <Button onClick={(event) => this.state.closeEvent(this.state.tableList.rows.length === 0)} key="btn-cancel"> Cancel</Button>] : []}>
              {this.state.tableList.rows.length > 0 &&
                <div className="regex-columnMaaping-detail">
                  <div className="container-fluid">
                    <TabStrip keepTabsMounted={true} selected={this.state.selected} onSelect={this.handleSelect}>
                      <TabStripTab title={'Pre'}>
                        <div className="height_fixed_step1 full-width-table m-t-10 m-b-10">
                          {this.state.tableList.rows.length > 0 &&
                            <RichGridTable {...this.state.tableList} key={this.state.tableList.id} />
                          }
                        </div>
                      </TabStripTab>
                      <TabStripTab title={'Post'}>
                        <div className="row m-t-10">
                          <div className="col-12">
                            <fieldset>
                              <div className="field_group">
                                <legend>{`${this.state.node.name} - Output Where Condition`}</legend>
                                <div className="row">
                                  <div className="col-12">
                                    <div className="am-form-field">
                                      <Input className="width-full" onChange={this.handleAdditionalWhereConditionChange} value={this.state.additionalWhereCondition} />
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </fieldset>
                          </div>
                        </div>
                      </TabStripTab>
                    </TabStrip>
                  </div>
                </div>
              }
              {this.state.tableList.rows.length === 0 &&
                <h4 className="align-center">No mapped source table operation found</h4>
              }
            </Dialog>
          </div>
        )
    }
}

const mapStateToProps = createSelector(
  state => state.annotate.tableExtendedList,
  state => state.annotate.whereMatrixList,
  state => state.annotate.additionalWhereConditionList,
  state => state.annotate.columnMappedList,
  state => state.annotate.hardcodeList,
  (tableExtendedList, whereMatrixList, additionalWhereConditionList, columnMappedList, hardcodeList) => ({
      tableExtendedList,
      whereMatrixList,
      additionalWhereConditionList,
      columnMappedList,
      hardcodeList
    })
);
const mapActionsToProps = {
  setMessage: setMessage,
  manageWhereMatrix: manageWhereMatrix,
  manageAdditionalWhereCondition: manageAdditionalWhereCondition
}

Dialog.propTypes = {
    height: PropTypes.oneOfType([
        PropTypes.string
    ]),
    width: PropTypes.oneOfType([
        PropTypes.string
    ])
}
export default connect(mapStateToProps, mapActionsToProps)(WhereMatrix);
