import React from 'react';
import { connect } from 'react-redux';
import { Button } from './../Button/StandardButton';
import { createSelector } from 'reselect';
import { setMessage } from '../../../../../actions/actionNotification';
import MessageTypeConst from "../../../../MessageTypeConst";

export const MultipleTableName = ({ tableManager, value, data, column, colIndex, rowIndex }) => {
    if (typeof(tableManager) === 'undefined') {
      return (null)
    } else {
      return (
          <div className='rgt-cell-inner' style={{display: 'flex', alignItems: 'center', overflow: 'hidden'}}>
              <MultipleTableNameClass dataItem={data} field={column.field} value={typeof(data[column.field]) !== 'undefined' ? data[column.field]: value} column={column} tableManager={tableManager}/>
          </div>
      )
    }
}

class MultipleTableNameClass extends React.Component {

    render() {
        let displaySourceTable = this.props.dataItem['displaySourceTable'];

        if (typeof(displaySourceTable) !== 'undefined' & displaySourceTable !== '') {
          displaySourceTable = displaySourceTable.split(', ');
        } else {
          displaySourceTable = [];
        }

        return (
            <>
              { displaySourceTable.length > 0 &&
                displaySourceTable.sort((a,b) => ('' + a).localeCompare(b)).map((table, index) => {
                  return (
                    <div key={`key-table-${index}-${this.props.dataItem["columnId"]}`}>
                      <span className={`table-name remove-mapped-line badge badge-pill badge-secondary`}>
                        {table}
                      </span>
                    </div>
                  )
                })
              }

              { displaySourceTable.length === 0 &&
                <div className="align-center">--</div>
              }
            </>
        );
    }
}
