import React    from "react";
import template from "./RichTextEditor.jsx";

const config = {
  readonly: false,
  buttons: ['source', 'undo', 'redo', 'bold', 'italic', 'hr', 'table', 'ul', 'ol', 'indent', 'outdent', 'left', 'fontsize','paragraph','brush'],
  height: 100,
  allowResizeX: false,
  allowResizeY: false
}
class RichTextEditor extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      config: typeof(props.config) !== 'undefined' ? props.config: config,
      content: typeof(props.content) !== 'undefined' ? props.content: ''
    }

    if (typeof(props.height) !== 'undefined') {
      this.state.config.height = props.height
    }
  }

  render() {
    return template.call(this);
  }
}

export default RichTextEditor;
