import React, { memo, Fragment } from 'react';
import { Handle, Position } from 'reactflow';
import notesBackGround from '././../../ProcessFlow/img/notes.png';

export default memo(({ data }) => {
  return (
    <>
      <div className="block-wrapper note-wrapper" style={{backgroundImage:`url(${notesBackGround})`}}>
        <div className="note-text" dangerouslySetInnerHTML={{__html: data.label}}/>
      </div>
      <Handle
        type="source"
        position={Position.Top}
        style={{ top: "0px" }}
        id="a"
      />
      <Handle
        type="source"
        position={Position.Right}
        style={{ right: "0px" }}
        id="b"
      />
      <Handle
        type="source"
        position={Position.Bottom}
        style={{ bottom: "0px" }}
        id="c"
      />
      <Handle
        type="source"
        position={Position.Left}
        style={{ left: "0px" }}
        id="d"
      />

    </>
  );
});
