import React from 'react';
import RichGridTable from '../RichGridTable';
import { POST_PARAM_BODY } from '../../../restClient/types';
import restClient from '../../../restClient';
import { connect } from 'react-redux';
import { PropTypes } from 'prop-types';
import { createSelector } from 'reselect';
import { setMessage } from '../../../actions/actionNotification';
import { BlockPropertiesFetchDetails } from '../DataOperation/common/Button/BlockPropertiesFetchDetails';

class PropertiesGrid extends React.Component {
    dataOpsProperties = (headers, params) => restClient(POST_PARAM_BODY, 'processblock/relatedblocks', params, headers);
    constructor(props) {
      super(props);
      this.state = {
        tenantId:props.tenantId,
        blockName:props.blockName,
        processFlowTableId: props.processFlowTableId,
        processFlowList:props.processFlowList,
        activeProcessFlow:props.activeProcessFlow,
        tableList:[{
          id: 'properties-grid',
          rows: props.data,
          resize: true,
          filterable: false,
          rowClick:true,
          isSingleRowSelectionEnable: true,
          selectedField: "selected",
          showSearch: false,
          showColumnVisibilityManager: false,
          columnProperty: [
            { field: "selected", sortable:false, title:"",className:"text-center", show:false, orderIndex:0 },
            { field: "studyName", sortable:false,width:"130px",className:"text-center", orderIndex:3 },
            { field: "relation", width:"70px",sortable:false,className:"text-center", orderIndex:1 },
            { field: "blockCreationType", width:"165px",sortable:false,className:"text-center", orderIndex:2 },
            { field: "studyId", sortable:false,className:"text-center", show:false, orderIndex:7 },
            { field: "processFlowId", sortable:false,className:"text-center", show:false, orderIndex:8 },
            { field: "processBlockId", sortable:false,className:"text-center", show:false, orderIndex:9 },
            { field: "flowName", sortable:false,width:"150px",className:"text-center", orderIndex:5 },
            { field: "groupName", sortable:false,width:"130px",className:"text-center", orderIndex:4 },
            { field: "blockName", sortable:false,width:"160px",className:"text-center", orderIndex:6 },
            { field: "details", sortable:false, width:"auto",className:"text-center", orderIndex:10, cell:BlockPropertiesFetchDetails },
            { field: "schemaName", sortable:false,className:"text-center", show:false, orderIndex:11 },
          ]
        }]
      }
    }

    UNSAFE_componentWillReceiveProps(nextProps){
      if(JSON.stringify(nextProps) !== '') {
        let tableList = this.state.tableList;
        tableList[0].rows.length=0;
        let rows = nextProps.data;
        if(rows.length === 0){
          rows.push({selected:false,studyName:'',relation:'',blockCreationType:'',studyId:'',processFlowId:'',processBlockId:'',flowName:'',groupName:'',blockName:'',details:'', schemaName:''});
        }
        tableList[0].rows.push(...rows);
        this.setState({tableList:tableList});
      }
    }

    render() {
        return (
          <div className="referring-block">
            <div className="filter-area am-form-inline col-12">
                <RichGridTable {...this.state.tableList[0]} />
            </div>
          </div>
        )
      }
}
const mapStateToProps = createSelector(
  state => state.processFlow.processFlowList,
  state => state.processFlow.tenantId,
  state => state.processFlow.processFlowTable,
  state => state.processFlow.processFlowTableId,
  state => state.processFlow.activeProcessFlow,
  (processFlowList, tenantId, processFlowTable, processFlowTableId, activeProcessFlow) => ({
    processFlowList,
    tenantId,
    processFlowTable,
    processFlowTableId,
    activeProcessFlow
  })
);

const mapActionsToProps = {
  setMessage: setMessage
}

PropertiesGrid.propTypes = {
  processFlowList: PropTypes.array,
  tenantId: PropTypes.string,
  processFlowTable: PropTypes.string,
  activeProcessFlow: PropTypes.number
}

export default connect(mapStateToProps, mapActionsToProps)(PropertiesGrid);
