import "./MockTLFSpecs.css";
import React from "react";
import { PropTypes } from "prop-types";
import { Button } from './../DataOperation/common/Button/StandardButton';
import { Input } from './../DataOperation/common/InputBox/StandardInput';
import Dialog from 'react-dialog';
import Spreadsheet from "react-spreadsheet";
import { TabStrip, TabStripTab  } from './../DataOperation/common/Layout';
import { DropDownList } from './../DataOperation/common/DropDown/StandardDropDownList';
import SignaturePad from 'react-signature-pad-wrapper'
import TreeView from './../TreeView';

function template() {
  return (
    <div className={`create-mock-tlf-specs-dialog`}>
      <Dialog title={`Create Mock TLF specs - ${this.state.node.name}`} width="calc(100% - 10px)" height="calc(100vh - 20px)" modal={true} onClose={this.props.closeEvent}>
        <div className="row">
          <div className="col-3">
            <div className="row">
              <div className="col-12">
              <fieldset>
                <div className="field_group">
                  <legend>Mock TLF List</legend>
                  <div className={'target-tablelist tree-view full-height'}>
                    <div className={'target treeview-content align-left'}>
                      <TreeView treeView={this.state.mockTLFTreeView} id={`${this.state.mockTLFTreeView.id}`} />
                    </div>
                  </div>
                </div>
              </fieldset>
              </div>
            </div>
          </div>
          <div className="col-9">
            <div className="row">
              <div className="col-12">
              <fieldset>
                <div className="field_group design-wrapper">
                  <legend>Mock Design</legend>
                  <TabStrip keepTabsMounted={true} selected={this.state.selected} onSelect={this.handleSelect}>
                    <TabStripTab title={'Sketch'}>
                      <div className="full-height">
                        <SignaturePad ref={(ref) => { this.signaturePadRef = ref }} redrawOnResize={true}/>
                      </div>
                    </TabStripTab>
                    <TabStripTab title={'Metadata'}>
                      <div className="full-height">
                        <Spreadsheet data={this.state.displaySheetData} className="am-sheet" onChange={this.handleSheetDataChange} />
                      </div>
                    </TabStripTab>
                  </TabStrip>
                  <div className="align-right m-t-10">
                    <Button onClick={this.handleClearClick}> Clear</Button>
                    <Button primary={true} className="m-l-10"> Save</Button>
                    <Button className="m-l-10" onClick={this.props.closeEvent}> Cancel</Button>
                  </div>
                </div>
              </fieldset>
              </div>
            </div>
          </div>
        </div>
      </Dialog>
    </div>
  )
}


Dialog.propTypes = {
    height: PropTypes.oneOfType([
        PropTypes.string
    ]),
    width: PropTypes.oneOfType([
        PropTypes.string
    ])
}
export default template;
