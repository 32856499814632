import React from 'react';
import { ComboBox } from './../DropDown/StandardComboBox';
import { Button } from './../Button/StandardButton';
import { Input } from './../InputBox/StandardInput';
import DataOpsIdentifierConstants from "../../../../DataOpsIdentifierConstants";

export class FootNote extends React.Component {
  ProcReportFootNotesIndex = 13;
  ProcReportFootNoteOptionType =  37;
  ProcReportDefineIndex  = 14;
  constructor(props){
    super(props);
    this.state = {
      rows:[],
      inputColumns: [],
      optionType: "TEXT",
      isTextOption: true,
      footNoteObject : {
        footNote1:"",
        footNote2:"",
        footNote3:"",
        footNote4:"",
        footNote5:"",
        footNote6:"",
        footNote7:"",
        footNote8:"",
        footNote9:"",
        footNote10:""
      },
      processFlowAction:props.processFlowAction
    }
    if(this.getGlobalValue(this.ProcReportDefineIndex) !== ""){
      this.state.rows = JSON.parse(this.getGlobalValue(this.ProcReportDefineIndex));
    }else{
      this.state.rows = [].concat(this.state.rows);
    }
    this.state.inputColumns = this.getInputColumnList(this.state.rows);
    if(typeof(this.getGlobalValue(this.ProcReportFootNoteOptionType)) !== 'undefined' && this.getGlobalValue(this.ProcReportFootNoteOptionType) !== ""){
        this.state.optionType = this.getGlobalValue(this.ProcReportFootNoteOptionType);
    }else{
      this.state.optionType = this.isFootNoteColumnPresent(this.state.inputColumns) ? "DROPDOWN" : "TEXT"
      this.saveGlobalValue(this.ProcReportFootNoteOptionType, this.state.optionType );
    }
    if(this.state.optionType === "DROPDOWN"){
      this.state.isTextOption = false;
    }
    if(typeof(this.getGlobalValue(this.ProcReportFootNotesIndex)) !== 'undefined' && this.getGlobalValue(this.ProcReportFootNotesIndex) !== ""){
      this.state.footNoteObject = JSON.parse(this.getGlobalValue(this.ProcReportFootNotesIndex));
    }else{
      if(this.state.optionType === "DROPDOWN"){
        this.state.footNoteObject = this.populateFootNote(this.state.inputColumns, this.state.footNoteObject);
      }
    }
    this.handleOnChange = this.handleOnChange.bind(this);
    this.handleOptionTypeSelectionChange = this.handleOptionTypeSelectionChange.bind(this);
    this.populateFootNote = this.populateFootNote.bind(this);
    this.handleResetToDefault = this.handleResetToDefault.bind(this);
  }

  getInputColumnList = (data) => {
    var columns = [];
    data.forEach((row) => {
      columns = [...columns, row.column];
    });

    return columns.sort();
  }
  handleOnChange = (event)=>{
    let obj = this.state.footNoteObject;
    let id = event.target.id;
    if(id === null || typeof(id) === "undefined"){
      id = event.target.name;
    }
    obj[id] = event.target.value === null ? "" : event.target.value;
    this.setState({footNoteObject:obj});
    this.saveGlobalValue(this.ProcReportFootNotesIndex, JSON.stringify(obj));
  }
  getGlobalValue(controlIndex){
    let controlValue = '';
    global.processBlockControlValues.forEach((control, index) => {
      if (control.controlId === controlIndex) {
        controlValue = global.processBlockControlValues[index].controlValue;

      }
    });
    return controlValue;
  }

  saveGlobalValue(controlIndex, value){
    global.processBlockControlValues.forEach((control, index) => {
      if (control.controlId === controlIndex) {
        global.processBlockControlValues[index].controlValue = value;
      }
    });
  }

  handleOptionTypeSelectionChange = (event) => {
    let isTextOption = event.target.checked;
    let optionType = "TEXT";
    let footNoteObject = this.state.footNoteObject;
    if(event.target.id === "optFootNoteDropDown"){
      isTextOption = !isTextOption;
      optionType= "DROPDOWN";
      footNoteObject = this.populateFootNote(this.state.inputColumns, footNoteObject);
    }
    this.saveGlobalValue(this.ProcReportFootNoteOptionType,optionType);
    this.setState({isTextOption:isTextOption, optionType: optionType, footNoteObject: footNoteObject});

  }

  populateFootNote = (inputColumns, footNoteObject)=>{
    for(let i=1 ; i<=10 ; i++){
      footNoteObject[`footNote${i}`] = this.getColumnValue(footNoteObject[`footNote${i}`], inputColumns, `footNote${i}`);
    }
    this.saveGlobalValue(this.ProcReportFootNotesIndex, JSON.stringify(footNoteObject));
    return footNoteObject;
  }

  isFootNoteColumnPresent = (inputColumns) => {
    let footNoteColPresent = false;
    for(let i=1 ; i<=10 ; i++){
      let col = this.getColumnValue("", inputColumns, `footNote${i}`);
      if(col !== ""){
        footNoteColPresent = true;
        return footNoteColPresent;
      }
    }
    return footNoteColPresent;

  }

  getColumnValue(footnote, inputColumns, colName) {
    if (footnote === "" || footnote === null || typeof(footnote) === "undefined") {
      let selectedValue = inputColumns.filter(item => item.toLowerCase() === colName.toLowerCase());
      if (selectedValue.length > 0) {
        footnote = selectedValue[0];
      }
    }
    else {
      let selectedValue = inputColumns.filter(item => item.toLowerCase() === footnote.toLowerCase());
      if (selectedValue.length > 0) {
        footnote = selectedValue[0];
      }
      else {
        let selectedValue = inputColumns.filter(item => item.toLowerCase() === colName.toLowerCase());
        if (selectedValue.length > 0) {
          footnote = selectedValue[0];
        }else{
          footnote = "";
        }
      }
    }
    return footnote;
  }

  handleResetToDefault = (event) => {
    let footNoteObject = this.state.footNoteObject;
    for(let i=1 ; i<=10 ; i++){
      footNoteObject[`footNote${i}`] = "";
    }
    if(!this.state.isTextOption){
      footNoteObject = this.populateFootNote(this.state.inputColumns, footNoteObject)
    }
    this.setState({footNoteObject: footNoteObject});
  }

  renderTextBox = (key, index) => {
    return (
      <label key={`${key}-${index+1}`} className="am-form-field">
      <span className="col-1"><b>{`${this.state.processFlowAction === DataOpsIdentifierConstants.R_Report_OP ? "Footer" : "Foot Note"} ${index+1}:`}</b></span>
      <Input type="text" id={key} className="width-full m-0"  value={this.state.footNoteObject[key]}
      onChange={this.handleOnChange}/>
       </label>
    );
  }

  renderDropDown = (key, index) => {
    return (
      <label key={`${key}-${index+1}`} className="am-form-field">
        <span className="col-1"><b>{`${this.state.processFlowAction === DataOpsIdentifierConstants.R_Report_OP ? "Footer" : "Foot Note"} ${index+1}:`}</b></span>
        <ComboBox data={this.state.inputColumns} allowCustom={false} name={key} value={this.state.footNoteObject[key]} className="width-full m-0"  onChange={this.handleOnChange}/>
      </label>
    );
  }

  render() {
      return (
        <div className="proc-report-foot-notes">
          <div className="container-fluid p_15">
          <div className="row">
              <div className="filter-area am-form-inline col-12">
                <label className="am-form-field m_t_5">
                  <span className="col-1"><b>{this.state.processFlowAction === DataOpsIdentifierConstants.R_Report_OP ? "Footer Type:" : "Foot Note Type:"} </b></span>
                   <input type="radio" name="optFootNoteType" value="Text" checked={this.state.isTextOption}
                    onChange={this.handleOptionTypeSelectionChange} id="optFootNoteText" className="am-radio" />
                    <label className="am-radio-label" htmlFor="optFootNoteText">Text</label> &nbsp;&nbsp;
                    <input type="radio" name="optFootNoteType" value="Destination" checked={!this.state.isTextOption}
                    onChange={this.handleOptionTypeSelectionChange} id="optFootNoteDropDown" className="am-radio"/>
                    <label className="am-radio-label" htmlFor="optFootNoteDropDown">DropDown</label>
                    <Button onClick={this.handleResetToDefault}  > Reset to Default</Button>
                </label>
            { this.state.isTextOption && <div>
              {
                Object.keys(this.state.footNoteObject).map(this.renderTextBox)
              } </div>
            }
            { !this.state.isTextOption && <div>
              {
                Object.keys(this.state.footNoteObject).map(this.renderDropDown)
              } </div>
            }
            </div>
          </div>
          </div>
        </div>
      )
  }
}
