import React    from "react";
import axios from 'axios';
import { connect } from 'react-redux';
import { createSelector } from 'reselect';
import $ from 'jquery';
import moment from 'moment';
import template from "./TagManagement.jsx";
import restClient from '../../../restClient';
import { POST_PARAM_BODY } from '../../../restClient/types';
import {setMessage} from '../../../actions/actionNotification';
import MessageTypeConst from "../../MessageTypeConst";
import TagContantType from "./TagContantType";
import { TagCheckBoxCell, TagPropertyCell, TagValueWithDocumentCell } from './../DataOperation/common';

class TagManagement extends React.Component {
  constructor(props) {
    super(props);

    global.removedTagList = [];
    let rows = [];
    this.props.treeView.children.forEach(project => {
      project.children.filter(study => study.study.schemaName === props.schemaName).forEach(study => {
        if (typeof(study.study.studyDocuments) !== 'undefined') {
          study.study.studyDocuments.forEach(studyDocument => {
            rows.push({
              rowId: rows.length,
              selected: true,
              property: studyDocument.originalFilename,
              type: 'Document',
              value: '',
              isPreDefine: false,
              fileName: studyDocument.filename,
              node: props.node,
              schemaName: props.schemaName,
              tagId: 0,
              documentId: studyDocument.fileId,
              nodeTagList: [],
              tagPropertyId: 0,
              sortOrder: rows.length + 1
            })
          })
        }

      })
    })
    this.state = {
      schemaName: props.schemaName,
      node: props.node,
      isDataLoaded: false,
      tableList:{
        id: 'tag-management-grid',
        rows: rows,
        resize: true,
        columnProperty: [
          { field: "selected", className:"text-center", title: ' ', width: '25px', sortable:false, cell: TagCheckBoxCell},
          { field: "rowId", show: false },
          { field: 'isPreDefine', show: false },
          { field: 'type', width: '100px' },
          { field: 'property', cell: TagPropertyCell },
          { field: 'value', cell: TagValueWithDocumentCell },
          { field: 'fileName', show: false },
          { field: 'node', show: false },
          { field: 'schemaName', show: false },
          { field: 'tagId', show: false},
          { field: 'documentId', show: false },
          { field: 'nodeTagList', show: false },
          { field: 'tagPropertyId', show: false },
          { field: 'sortOrder', show: false }
        ]
      }
    }

    this.handleAddRowClick = this.handleAddRowClick.bind(this);
    this.handleRemoveRowClick = this.handleRemoveRowClick.bind(this);
  }

  fetchTagList = (headers, params) => restClient(POST_PARAM_BODY, 'tag/filter', params, headers);

  componentDidMount() {
    let params = {
      objectId: 0,
      objectType: 0
    }

    if (this.state.node.cssClassName === 'manage-study-property') {
      params.objectId = this.state.node.study.studyId;
      params.objectType = TagContantType.STUDY
    } else if (this.state.node.cssClassName === 'manage-study-process-flow') {
      params.objectId = this.state.node.processGroupId
      params.objectType = TagContantType.PROCESS_FLOW_GROUP
    } else if (this.state.node.cssClassName === 'manage-study-process') {
      params.objectId = this.state.node.process.processId
      params.objectType = TagContantType.PROCESS_FLOW
    } else if (typeof(this.state.node.blockId) !== 'undefined') {
      params.objectId = this.state.node.blockId
      params.objectType = TagContantType.PROCESS_BLOCK
    }

    if (params.objectId > 0) {
      let that = this;
      $(".loader").show();
      axios.all([this.fetchTagList({'Tenant-ID': this.state.schemaName}, {data: params})])
          .then(axios.spread((response) => {
              if (typeof(response.data) !== 'undefined') {
                let tableList = this.state.tableList

                response.data.forEach(item => {
                  if(tableList.rows.filter(row => row.property === item.tagProperty && TagContantType.list.filter(tagType => tagType[0] === item.tagType && tagType[1] === row.type).length > 0).length > 0) {
                    tableList.rows.filter(row => row.property === item.tagProperty && TagContantType.list.filter(tagType => tagType[0] === item.tagType && tagType[1] === row.type).length > 0).map(row => {
                      row.tagId = item.tagId
                      row.value = item.tagValue
                      return row
                    })
                  } else {
                    tableList.rows.push({
                      rowId: tableList.rows.length,
                      selected: false,
                      property: item.tagProperty,
                      type: 'Text',
                      value: item.tagValue !== null && item.tagValue !== '' ? item.tagValue : '',
                      isPreDefine: item.preDefine,
                      fileName: '',
                      node: that.state.node,
                      schemaName: that.state.schemaName,
                      tagId: item.tagId,
                      documentId: 0,
                      nodeTagList: [],
                      tagPropertyId: item.tagPropertyId,
                      sortOrder: tableList.rows.length + 1
                    })
                  }
                })
                $(".loader").hide();
                that.setState({isDataLoaded : true, tableList: tableList})
              }
            })).catch(error => {
                $(".loader").hide();
                this.props.setMessage("Failed to fetch tag list.",MessageTypeConst.ERROR_MESSAGE)
            })
    } else {
      this.setState({isDataLoaded : true})
    }

  }

  handleAddRowClick(event) {
    var  tableList = this.state.tableList;
    var newRowId = 0;
    if (typeof(tableList.rows[0]) !== 'undefined' && tableList.rows[0]["rowId"] === '') {
        tableList.rows.pop();
    }

    if (tableList.rows.length > 0) {
      let maxRowId = Math.max.apply(Math, tableList.rows.map(function(o) { return o.rowId; }))
      newRowId = maxRowId + 1;
    }

    var addRow = {"rowId": newRowId, "selected": false, "property": '', "type": 'Text', "value": '', "isPreDefine": false, "nodeTagList": [], "documentId": 0, "tagPropertyId": 0, "sortOrder": newRowId + 1};

    tableList.rows.push(addRow);

    this.setState({ tableList: tableList });
  }

  handleRemoveRowClick(event) {
    let tableList = this.state.tableList;
    const { rows } = this.state.tableList;
    if(typeof(rows) !== 'undefined') {
      tableList = this.removeTableRowsRecursiveElements(tableList)
    }

    tableList.rows.map((row, index) => {
      row.sortOrder = index + 1;
      return row;
    })
    this.setState({ tableList: tableList });
  }

  removeTableRowsRecursiveElements(tableList) {
    var index;
    for (index = 0; index < tableList.rows.length; index++) {
      if (tableList.rows[index].selected === true && tableList.rows[index].isPreDefine === false && tableList.rows[index].documentId === 0) {
        if (typeof(tableList.rows[index]) !== 'undefined' && typeof(tableList.rows[index].tagId) !== 'undefined' && tableList.rows[index].tagId > 0 && global.removedTagList.filter(removedTag => removedTag.tagId === tableList.rows[index].tagId).length === 0) {
          tableList.rows[index].value = '';
          global.removedTagList.push(tableList.rows[index])
        }
        tableList.rows.splice(index, 1);
      }
    }

    for (index = 0; index < tableList.rows.length; index++) {
      if (tableList.rows[index].selected === true && tableList.rows[index].isPreDefine === false && tableList.rows[index].documentId === 0) {
        tableList = this.removeTableRowsRecursiveElements(tableList);
      }
    }

    return tableList;
  }

  render() {
    return template.call(this);
  }
}

const mapStateToProps = createSelector(
  state => state.processFlow.tenantId,
  state => state.treeView.data,
  (tenantId, treeView) => ({
    tenantId,
    treeView
  })
);

const mapActionsToProps = {
  setMessage: setMessage,
}

export default connect(mapStateToProps, mapActionsToProps)(TagManagement);
