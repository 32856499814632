import React from 'react';
import RichGridTable from './../RichGridTable';
import $ from 'jquery';
import moment from 'moment';

export const DetailComponent = ({ tableManager, value, data, column, colIndex, rowIndex }) => {
    if (typeof(tableManager) === 'undefined') {
      return (null)
    } else {
      return (
          <div className='rgt-cell-inner' style={{display: 'flex', alignItems: 'center', overflow: 'hidden'}}>
              <DetailComponentClass dataItem={data} field={column.field} value={value} tableManager={tableManager}/>
          </div>
      )
    }
}

class DetailComponentClass extends React.Component {

    constructor(props) {
      super(props);

      this.state = {
        data: typeof(this.props.dataItem.study) !== 'undefined' ? this.props.dataItem.study : []
      };

      this.state.data.map(row => row.displayStudyTitle = row.studyName + (row.classifier !=='' ? ' ('+row.classifier+')' : '' ))
      this.state.data.map(row => row.columnId = row.studyId)

      this.headerSelectionChange = this.headerSelectionChange.bind(this);
      this.selectionChange = this.selectionChange.bind(this);
      this.state.data.map(row => row.selectionChange = this.selectionChange)
    }

    selectionChange(row) {
      if (document.getElementById('project-study-handle-'+row.projectId) !== null) {
        if (this.state.data.filter(study => study.selected === true).length > 0 && this.state.data.filter(study => study.selected === true).length !== this.state.data.length) {
          document.getElementById('project-study-handle-'+row.projectId).parentNode.parentNode.nextSibling.querySelector('.am-checkbox').indeterminate = true;
        }

        if (this.state.data.filter(study => study.selected === true).length === 0) {
          document.getElementById('project-study-handle-'+row.projectId).parentNode.parentNode.nextSibling.querySelector('.am-checkbox').indeterminate = false;
          document.getElementById('project-study-handle-'+row.projectId).parentNode.parentNode.nextSibling.querySelector('.am-checkbox').checked = false;
        }

        if (this.state.data.filter(study => study.selected === true).length > 0 && this.state.data.filter(study => study.selected === true).length === this.state.data.length) {
          document.getElementById('project-study-handle-'+row.projectId).parentNode.parentNode.nextSibling.querySelector('.am-checkbox').indeterminate = false;
          document.getElementById('project-study-handle-'+row.projectId).parentNode.parentNode.nextSibling.querySelector('.am-checkbox').checked = true;
        }
      }
    }

    headerSelectionChange(event) {
      this.props.dataItem[this.props.field] = !this.props.dataItem[this.props.field];
      this.props.tableManager.rowsApi.setRows(this.props.tableManager.rowsApi.rows)
      if (this.props.dataItem[this.props.field]) {
        setTimeout(() => {
          event.target.parentNode.querySelector('.study-grid').classList.remove('hidden');
          event.target.parentNode.parentElement.nextElementSibling.nextElementSibling.nextElementSibling.insertAdjacentElement('afterend', event.target.parentNode.querySelector('.study-grid'))
        }, 1)
      } else {
        if (event.target.parentNode.parentElement.nextElementSibling.nextElementSibling.nextElementSibling.nextElementSibling.classList.contains('study-grid')) {
          event.target.parentNode.parentElement.nextElementSibling.nextElementSibling.nextElementSibling.nextElementSibling.classList.add('hidden');
          event.target.parentNode.querySelector('.target-study-grid').append(event.target.parentNode.parentElement.nextElementSibling.nextElementSibling.nextElementSibling.nextElementSibling)
        }
      }
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
      if(nextProps !== '' && typeof(nextProps) !== 'undefined' && typeof(nextProps.dataItem) !== 'undefined') {
        if (typeof(this.props.dataItem.study) !== 'undefined') {
          this.props.dataItem.study.map((item) => {
            if (!item.hasOwnProperty('selected')) {
              item.selected = this.props.dataItem.selected;
            }
            if (!item.hasOwnProperty('timestamp')) {
              item.timestamp = item.audit !== null ? moment.utc(item.audit.createdAt).format('lll') : "";
            }
            return item
          });
          this.setState({data: this.props.dataItem.study});
        }
      }
    }

    componentDidMount() {
      if (this.props.dataItem[this.props.field] === true) {
        if (document.getElementById('project-study-handle-'+this.props.dataItem.projectId) !== null) {
          document.getElementById('project-study-handle-'+this.props.dataItem.projectId).parentNode.parentNode.nextSibling.querySelector('.am-checkbox').indeterminate = this.state.data.filter(study => study.selected === true).length != this.state.data.length
          document.getElementById('project-study-handle-'+this.props.dataItem.projectId).parentNode.parentElement.nextElementSibling.nextElementSibling.nextElementSibling.insertAdjacentElement('afterend', document.getElementById('project-study-handle-'+this.props.dataItem.projectId).parentNode.querySelector('.study-grid'))
        }
      }
    }

    render() {
      if (typeof(this.props.dataItem[this.props.field]) !== 'undefined' && (this.props.dataItem[this.props.field] === true || this.state.data.filter(study => study.selected === true).length > 0)) {
        return (
              <>
              <div className={`am-icon am-${this.props.dataItem[this.props.field] === true ? 'minus': 'plus'} project-selection`} onClick={this.headerSelectionChange} id={`project-study-handle-${this.props.dataItem.projectId}`}></div>
              <div className="target-study-grid">
                <div className={`study-grid grid-column-3${this.props.dataItem[this.props.field] === false ? ' hidden': ''}`}>
                  <RichGridTable {...{id: `select-project-${this.props.dataItem['projectId']}-grid`, rows: this.state.data, columnProperty: [
                    { field: "selected", width: "25px", title:"", show: true, className:`text-center p_l_2`, orderIndex: 1},
                    { field: "displayStudyTitle", width: "428px", title:"Study Name", show: true, className:`p_l_2`, orderIndex: 2},
                    { field: "classifier", title:"", show: false},
                    { field: "dataSourceId", title:"", show: false},
                    { field: "description", title:"", show: false},
                    { field: "identifier1", title:"", show: false},
                    { field: "identifier2", title:"", show: false},
                    { field: "identifier3", title:"", show: false},
                    { field: "isCopy", title:"", show: false},
                    { field: "projectId", width: "25px", title:"", show: true, orderIndex: 0},
                    { field: "schemaName", title:"", show: false},
                    { field: "studyId", width: "24px", title:"", show: true, orderIndex: 0},
                    { field: "studyName", title:"", show: false},
                    { field: "timestamp", title:"", show: false},
                    { field: "id", title:"", show: false},
                    { field: "selectionChange", title:"", show: false},
                  ] }}/>
                </div>
              </div>
              </>
            );
        }
        return (
            <div className={`am-icon am-${this.props.dataItem[this.props.field] === true ? 'minus': 'plus'} project-selection`} onClick={this.headerSelectionChange}></div>
        );
    }
  }
