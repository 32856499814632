import React from 'react';
import { DropDownList } from './StandardDropDownList';
import { MultiSelectDropDownList } from './StandardMultiSelectDropDownList';

export const RefDocumentIdCell = ({ tableManager, value, data, column, colIndex, rowIndex }) => {
    if (typeof(tableManager) === 'undefined') {
      return (null)
    } else {
      return (
          <div className='rgt-cell-inner' style={{display: 'flex', alignItems: 'center', overflow: 'hidden'}}>
              <RefDocumentIdCellClass dataItem={data} field={column.field} value={typeof(data[column.field]) !== 'undefined' ? data[column.field]: value} column={column} tableManager={tableManager}/>
          </div>
      )
    }
}
class RefDocumentIdCellClass extends React.Component {

    handleChange(e) {
      this.props.dataItem[this.props.field] = this.props.column.multiple === true ? e.value.join(',') : e.target.value;
      this.props.tableManager.rowsApi.setRows(this.props.tableManager.rowsApi.rows)
    }
    render() {
        const valueList = this.props.dataItem[this.props.field] === "" ? [] : this.props.dataItem[this.props.field].split(',');
        return (
            <>
              {typeof(this.props.column.multiple) !== 'undefined' &&  this.props.column.multiple === true ? (
                <MultiSelectDropDownList className={`width-full$`} onChange={this.handleChange.bind(this)} data={this.props.column.fetchData(this.props.field)} value={valueList} />
              ) : (
                <DropDownList className="width-full" onChange={this.handleChange.bind(this)} data={this.props.column.fetchData(this.props.field)} value={this.props.dataItem[this.props.field]} />
              )}
            </>
        );
    }
}
