import { BUILD_TREEVIEW_STATUS, SET_TREEVIEW_DATA, REDISPLAY_TREEVIEW_STATUS, PROJECT_UPDATE_STUDY_ACTION, TREEVIEW_REFRESH_STUDY_NODE  } from '../actions/actionsTypes';

const INITIAL_DATA = {
  build: false,
  data: {},
  reDisplay: false,
  refreshStudyNodeStatus: false
};

export default function treeViewReducer(state=INITIAL_DATA, action) {
  switch (action.type) {
    case BUILD_TREEVIEW_STATUS:
      return {
          ...state,
          build: action.payload
      }
    case SET_TREEVIEW_DATA:
      return {
        ...state,
        data: action.payload.treeView,
        reDisplay: action.payload.reDisplay
      }
    case REDISPLAY_TREEVIEW_STATUS:
      return {
          ...state,
          reDisplay: action.payload
      }
    case PROJECT_UPDATE_STUDY_ACTION:
      if (typeof(state.data.children) !== 'undefined' && state.data.children.length > 0) {
        state.data.children.map(project => {
          project.children.filter(study => study.study.studyId === action.payload.studyId).map(study => {
            if (typeof(study.study.enableActions) !== 'undefined') {
              study.study.enableActions.length = 0
              study.study.enableActions.push(...action.payload.enableActions)
            }
            return study
          })
          return project
        })
      }

      return {
          ...state
      }
    case TREEVIEW_REFRESH_STUDY_NODE:
      return {
        ...state,
        refreshStudyNodeStatus: action.payload,
      }
    default:
      return state;
  }
}
