import './RichTextEditor.css'
import React from 'react';
import JoditEditor from "jodit-react";

function template() {
  return (
    <JoditEditor value={this.state.content} config={this.state.config} tabIndex={1} onBlur={this.props.onChange} onChange={this.props.onChange}/>
        );
}

export default template;
