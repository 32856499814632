import React from "react";
import { TabStrip, TabStripTab  } from './../common/Layout';
import { ProcessOptionGrid } from "../common/gridOperation";
import { SortOrder } from "../common";

function RankOpsLayoutTemplate() {
  return (
    <div className="process-option">
      <TabStrip selected={this.state.tabSelected} onSelect={this.handleSelectTabPanel}>
        <TabStripTab title="Process">
          <div className="field_group">
            <div className="height_fixed_rank">
              <ProcessOptionGrid tableList={this.state.tableList} />
            </div>
          </div>
        </TabStripTab>
        <TabStripTab title="GBY & OBY Order">
          {this.state.tableList.length > 0 ? (
            <SortOrder table={this.state.tableList[0]} />
          ) : (<></>)}
        </TabStripTab>
      </TabStrip>
    </div>
  );
};

export default RankOpsLayoutTemplate;
