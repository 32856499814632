import "./TagManagement.css";
import React from "react";
import { Button } from './../DataOperation/common/Button/StandardButton';
import { Input } from './../DataOperation/common/InputBox/StandardInput';
import RichGridTable from './../RichGridTable';

function template() {
  return (
    <div className="tag-management">
      <div className="filter-area am-form-inline col-12">
        <div className="clearfix">
          <Button primary={true} onClick={this.handleAddRowClick}>Add</Button>
          <Button primary={true} className="m-left" onClick={this.handleRemoveRowClick}>Delete</Button>
        </div>
      </div>
      {this.state.isDataLoaded === true &&
        <RichGridTable {...this.state.tableList} />
      }
      {this.state.isDataLoaded !== true &&
        <h5 className="align-center">Loading...</h5>
      }
    </div>
  )
}

export default template;
