import "./GridMapper.css";
import React from "react";
import cloneDeep from 'lodash/cloneDeep';
import ReactDOM from 'react-dom';
import { Button } from './../DataOperation/common/Button/StandardButton';
import {PathLine} from 'react-svg-pathline';
import MessageTypeConst from '../../MessageTypeConst';
import { ObyProcessCell, CompareOpsGbyCheckBoxCell, BasicCheckBoxCell, NewColumnSpanCell } from "../DataOperation/common";
import { MappingRules } from '../DataOperation/common/Button/MappingRules.js';
import DataOpsIdentifierConstants from '../../DataOpsIdentifierConstants';
import RichGridTable from './../RichGridTable';
//var gridFindMapper = null;
var idSelectorPostFix = '';

const getDimensions = (element) => {
  if (element !== '' && typeof(element) !== 'undefined') {
    const { width, height, x, y, top, bottom, left, right } = element.getBoundingClientRect();
    return { width, height, x, y, top, bottom, left, right };
  } else {
    return false;
  }
};

export const getSourceElement = (sourceId) => {
  let postionGrid = global.directionMode === 'left' ? 0: 1;
  let gridNodeType = global.directionMode === 'left' ? 'left' :'right';

  if (typeof(global.tabGridIndex) !== 'undefined') {
    //postionGrid = postionGrid + (global.tabGridIndex * 2)
  }

  return document.getElementsByClassName('grid-mapper-wrapper')[postionGrid].querySelectorAll('.grid-'+gridNodeType+'-node-'+sourceId)[0];
};

export const getTargetElement = (targetId) => {
  let postionGrid = global.directionMode === 'left' ? 1: 0;
  let gridNodeType = global.directionMode === 'left' ? 'right' :'left';

  if (typeof(global.tabGridIndex) !== 'undefined') {
    //postionGrid = postionGrid + (global.tabGridIndex * 2)
  }

  return document.getElementsByClassName('grid-mapper-wrapper')[postionGrid].querySelectorAll('.grid-'+gridNodeType+'-node-'+targetId)[0];
};

const limitOffsetElement = () => {
  return getDimensions(document.getElementsByClassName('k-grid-header')[0]);
}

const wrapperOffsetElement = () => {
  if (document.getElementsByClassName('ui-dialog-content').length > 0) {
      return getDimensions(document.getElementsByClassName('ui-dialog-content')[document.getElementsByClassName('ui-dialog-content').length - 1]);
  }
}

function getTableName (processFlowList, tableName) {
  if (processFlowList.length > 0) {
    let blockFound = false;
    processFlowList.forEach(processFlow => {
      if (typeof(processFlow.process) !== 'undefined' && typeof(processFlow.process.processBlock) !== 'undefined' && processFlow.process.processBlock.length > 0 && blockFound === false) {
        let block = processFlow.process.processBlock.filter(block => block.blockTable === tableName);
        if (block.length > 0 && tableName.search(block[0].blockName) === -1) {
          tableName = block[0].blockName +" ("+tableName+")";
          blockFound = true;
        }
      }
    })
  }
  return tableName
}

function template() {
  var tableIdOne = '';
  var tableIdTwo = '';
  var mappingKey = '';
  let processFlowList = typeof(this.props.processFlowList) !== 'undefined' ? this.props.processFlowList : []
  if (typeof(this.props.tableList[0]) !== 'undefined') {
    tableIdOne = getTableName(processFlowList, this.props.tableList[0].id);

    if (tableIdOne === this.props.tableList[0].id && typeof(this.props.tableList[0].displayTableName) !== 'undefined') {
      tableIdOne = this.props.tableList[0].displayTableName;
    }
  }

  if (typeof(this.props.tableList[1]) !== 'undefined') {
    tableIdTwo = getTableName(processFlowList, this.props.tableList[1].id);

    if (tableIdTwo === this.props.tableList[1].id && typeof(this.props.tableList[1].displayTableName) !== 'undefined') {
      tableIdTwo = this.props.tableList[1].displayTableName;
    }
    if (tableIdOne === this.props.tableList[0].id && tableIdTwo !== this.props.tableList[1].id) {
      //tableIdOne = tableIdTwo.split(' (')[0] + " ("+this.props.tableList[0].id+")";
    }
  }

  if (typeof(this.props.constructiveOperation) !== 'undefined' && this.props.constructiveOperation) {
    this.state.gridFindMapper = 'grid_not_gby_Mapper';
  }

  //idSelectorPostFix = (typeof(global.tabGridIndex) !== 'undefined') ? '-'+global.tabGridIndex : '';


  if (this.props.tableList.length > 0) {
    if (typeof(this.props.tableList[0].id) !== 'undefined') {
        mappingKey = this.props.tableList[0].id
    }

    if (this.props.tableList.length > 1 && typeof(this.props.tableList[1].id) !== 'undefined') {
        mappingKey = mappingKey + '_' + this.props.tableList[1].id
    }
  }
  return (
    <div className="grid-mapper">
      <div className="container">
        <div className="row">
          <fieldset className="width-full">
            <div className="field_group_main row-clickable">
              {/* <legend>Column Mapper</legend> */}
              <div className={`${this.state.gridFindMapper}-grid`} >
                  <div className="row">
                    <div className="col-5 left-grid join_master" >
                      <fieldset>
                        <div className="field_group">
                          <legend>{`${tableIdOne}`}</legend>
                            {/* <div className="am-form-inline col-8 clearfix">
                              <div>
                                <label className="am-form-field">
                                  <span>Find:</span>
                                  <input className="am-textbox" placeholder="Search data" />
                                </label>
                              </div>
                            </div> */}
                            <div className="height_fixed grid-mapper-wrapper">
                              <GridPropagate table={this.props.tableList[0]} position="left" processFlowAction={this.state.processFlowAction}
                              setMessage = {this.props.setMessage}
                              constructiveOperation={ typeof(this.props.constructiveOperation) !== 'undefined' && this.props.constructiveOperation ? this.props.constructiveOperation : false } />
                            </div>
                          </div>
                        </fieldset>
                    </div>
                    <div className="col-1 join_master">&nbsp;</div>
                    <div className="col-5 right-grid join_master">
                      <fieldset>
                        <div className="field_group">
                            <legend>{`${tableIdTwo}`}</legend>
                          {/* <div className="am-form-inline col-8 clearfix">
                            <label className="am-form-field">
                              <span>Find:</span>
                              <input className="am-textbox" placeholder="Search data" />
                            </label>
                          </div> */}
                          <div className="height_fixed grid-mapper-wrapper">
                            <GridPropagate table={this.props.tableList[1]} position="right" processFlowAction={this.state.processFlowAction}
                            setMessage = {this.props.setMessage}
                            constructiveOperation={ typeof(this.props.constructiveOperation) !== 'undefined' && this.props.constructiveOperation ? this.props.constructiveOperation : false } />
                          </div>
                        </div>
                      </fieldset>
                    </div>
                  </div>
                </div>
                <DrawMapppingLine tableList={this.props.tableList} resize={this.state.resize}
                raiseExecute={this.state.raiseExecute}
                processFlowAction={this.state.processFlowAction} key={mappingKey}
                constructiveOperation={ typeof(this.props.constructiveOperation) !== 'undefined' && this.props.constructiveOperation ? this.props.constructiveOperation : false }
                columnMapperNodeList={ typeof(this.state.columnMapperNodeList) !== 'undefined' ? this.state.columnMapperNodeList: [] }
                />
            </div>
          </fieldset>
        </div>
      </div>
    </div>
  );
};


const DragCell = ({ tableManager, value, data, column, colIndex, rowIndex }) => {
    if (typeof(tableManager) === 'undefined') {
      return (null)
    } else {
      return (
          <DragCellClass dataItem={data} field={column.field} value={value} tableManager={tableManager} className={column.className}/>
      )
    }
}

class DragCellClass extends React.Component {
    handleChange(e) {

    }
    render() {
        if ((typeof(this.props.dataItem['status']) === 'undefined') || (typeof(this.props.dataItem['status']) !== 'undefined' && this.props.dataItem['status'] === 0)) {
          return (
            <div className="rgt-cell-inne text-center" onDragOver={(e) => { DragCell.dragOverEvent(e, this.props.dataItem); }} onDrag={(e) => { DragCell.draggingEvent(e,this.props.dataItem); }} onDrop={(e) => { DragCell.dropedEvent(e,this.props.dataItem); }} type={`${this.props.className}`} style={{display: 'flex', alignItems: 'center', overflow: 'hidden'}}>
                  {typeof(this.props.dataItem['status']) !== 'undefined' && this.props.dataItem['status'] === 0 && this.props.className === 'left' &&
                      <div className="float-left m-l-14">
                        <input type="checkbox" className="am-checkbox" id={`checkbox-status-status-${this.props.dataItem['status']}-${this.props.dataItem['columnId']}-${this.props.className}-${this.props.tableManager.id}`} onChange={this.handleChange.bind(this)}
                        checked={false} disabled={''}/>
                        <label className={`am-checkbox-label status-${this.props.dataItem['status']}`} htmlFor={`checkbox-status-status-${this.props.dataItem['status']}-${this.props.dataItem['columnId']}-${this.props.className}-${this.props.tableManager.id}`}></label>
                      </div>
                  }
                  <span
                      className={`grid-column-handle grid-${this.props.className}-node-${this.props.dataItem.columnId}`}
                      draggable="true"
                      style={{ cursor: "move" }}
                      onDragStart={(e) => {
                          DragCell.dragStartEvent(e, this.props.dataItem);
                          e.dataTransfer.setData("dragging", "");
                          e.dataTransfer.setData("SourceDataItemType", this.props.dataItem.type);

                          setNodeDirectionMode(e);
                      }}

                      onDragEnd={(e) => {
                          DragCell.dragEndEvent(e, this.props.dataItem);
                          e.dataTransfer.setData("dragend", "");
                          setNodeDirectionMode(e);
                      }}
                      type={`${this.props.className}`}
                  >
                    <i className="fa fa-bars" aria-hidden="true"></i>
                  </span>
              </div>
          );
        } else if (typeof(this.props.dataItem['status']) !== 'undefined' && this.props.dataItem['status'] === 1) {
          let checked = false;
          if (this.props.dataItem['status']) {
            checked = true;
          }
          return (
            <div className="rgt-cell-inne text-center" style={{display: 'flex', alignItems: 'center', overflow: 'hidden'}}>
              <div className={this.props.className === 'left' ? 'float-left m-l-14' : 'm-l-5'}>
                <input type="checkbox" className="am-checkbox" id={`checkbox-status-status-${this.props.dataItem['status']}-${this.props.dataItem['columnId']}-${this.props.className}-${this.props.tableManager.id}`} onChange={this.handleChange.bind(this)}
                checked={checked} disabled={this.props.dataItem['status'] === 1 ? 'disabled' : '' }/>
                <label className={`am-checkbox-label status-${this.props.dataItem['status']}`} htmlFor={`checkbox-status-status-${this.props.dataItem['status']}-${this.props.dataItem['columnId']}-${this.props.className}-${this.props.tableManager.id}`}></label>
              </div>
              <span className={`k-icon k-i-reorder grid-${this.props.className}-node-${this.props.dataItem.columnId} hidden`} type={`${this.props.className}`} />
            </div>
          );
        }

    }
}

class GridPropagate extends React.Component {
    constructor(props) {
        super(props);
        let gridData = [];
        if(typeof(props.table)  !== 'undefined'){
          if(props.constructiveOperation){
            gridData = props.table.rows.map((row, rowIndex) => {
              row.columnId = rowIndex;
              return row
            });
          }else{
            gridData = props.table.rows.sort((a,b)=> {return a.column.localeCompare(b.column)});
          }
        }

        this.state = {
            table : props.table,
            processFlowAction:props.processFlowAction,
            gridData: gridData,
            position: props.position,
            gridHeight:300,
            selectedField : 'selected',
            wrapperClassName: '',
            sort:[{
                field: "column",
                dir: "asc"
            }],
            sortable: false,
            allowUnsort: false,
            multiple: false,
            constructiveOperation:props.constructiveOperation
        };
        this.setMessage = this.props.setMessage;
        //if (typeof(this.props.constructiveOperation) !== 'undefined' && this.props.constructiveOperation) {
        global.gridElement = [];
        global.directionMode = 'left';

        DragCell.dragStartEvent = this.dragStartEvent.bind(this);
        DragCell.dragOverEvent = this.dragOverEvent.bind(this);
        DragCell.draggingEvent = this.draggingEvent.bind(this);
        DragCell.dropedEvent = this.dropedEvent.bind(this);
        DragCell.dragEndEvent = this.dragEndEvent.bind(this);

        this.rowRender = this.rowRender.bind(this);
        this.findBlinkNodes = this.findBlinkNodes.bind(this);
        this.selectionChange = this.selectionChange.bind(this);
        this.headerSelectionChange = this.headerSelectionChange.bind(this);
        this.setGridData = this.setGridData.bind(this);
        this.itemChange = this.itemChange.bind(this);
        this.widgetRef = this.widgetRef.bind(this);
        this.sortChange = this.sortChange.bind(this);
    }
    // initialProcessOptionGrid = initialProcessOptionGrid.sort((a,b)=> {return a.column.localeCompare(b.column)});

    sortChange(event){
      const sort = event.sort;
      if (sort.length >= this.state.sort.length) {
          sort.unshift(sort.pop());
      }

      this.setState({
        gridData: this.getSortedGridData(sort),
          sort: sort
      });

      setTimeout(()=>{
          DrawMapppingLine.reDrawMappedLines()
      },1)


    }
    getSortedGridData(sort) {
      const data = this.state.gridData.slice();
      return orderBy(data.sort((a,b)=> {return a.column.localeCompare(b.column)}), sort);
    }
    setGridData() {
      if (typeof(global.processBlockControlValues) !== 'undefined' && this.state.position === 'left') {
        global.processBlockControlValues.forEach((control, index) => {
          if (control.controlId === 3) {
            global.processBlockControlValues[index].controlValue = JSON.stringify(this.state.gridData);
            return true;
          }
        });
      }
      if (typeof(global.processBlockControlValues) !== 'undefined' && this.state.position === 'right') {
        global.processBlockControlValues.forEach((control, index) => {
          if (control.controlId === 24) {
            global.processBlockControlValues[index].controlValue = JSON.stringify(this.state.gridData);
            return true;
          }
        });
      }

      setTimeout(()=>{
        if (typeof(global.processBlockControlValues) !== 'undefined') {
          let sourceMappedVariableList = [];
          let targetMappedVariableList = [];
          let sourceGrid = [];
          let targetGrid = [];
          let isDataCompareOp = false;
          global.processBlockControlValues.forEach((control, index) => {
            if (control.controlId === -1 && control.controlValue !== '' && control.controlValue === DataOpsIdentifierConstants.COMPARE_OP) {
              isDataCompareOp = true;
            }
            if (control.controlId === 3 && control.controlValue !== '') {
              sourceGrid = JSON.parse(control.controlValue);
            }
            if (control.controlId === 24 && control.controlValue !== '') {
              targetGrid = JSON.parse(control.controlValue);
            }
            if (control.controlId === 26 && control.controlValue !== '') {
              sourceMappedVariableList = JSON.parse(control.controlValue);
            }
            if (control.controlId === 27 && control.controlValue !== '') {
              targetMappedVariableList = JSON.parse(control.controlValue);
            }
          });

          if  (isDataCompareOp ===  true) {
            sourceMappedVariableList.forEach((sourceMappedVariable, index) => {
              if (typeof(targetMappedVariableList[index]) !== 'undefined') {
                const targetMappedVariable = targetMappedVariableList[index];
                const sourceVariableGBY = sourceGrid.filter(sourceColumn => sourceColumn.column === sourceMappedVariable && sourceColumn.gby === true);
                const targetVariableGBY = targetGrid.filter(targetColumn => targetColumn.column === targetMappedVariable && targetColumn.gby === true);

                if (sourceVariableGBY.length === 0) {
                  sourceGrid.filter(sourceColumn => sourceColumn.column === sourceMappedVariable).forEach(sourceColumn => {
                    if  (document.getElementById('checkbox-gby-'+sourceColumn.columnId+'-left') !== null) {
                      document.getElementById('checkbox-gby-'+sourceColumn.columnId+'-left').click()
                    }
                  })
                }

                if (targetVariableGBY.length === 0) {
                  targetGrid.filter(targetColumn => targetColumn.column === targetMappedVariable).forEach(targetColumn => {
                    if  (document.getElementById('checkbox-gby-'+targetColumn.columnId+'-right') !== null) {
                      document.getElementById('checkbox-gby-'+targetColumn.columnId+'-right').click()
                    }
                  })
                }

                /*if ((sourceVariableGBY.length > 0 || targetVariableGBY.length > 0) && (sourceVariableGBY.length === 0 || targetVariableGBY.length === 0)) {
                  if(sourceVariableGBY.length == 0 || (this.state.position === 'right' && sourceVariableGBY.length > 0)){
                    sourceGrid.filter(sourceColumn => sourceColumn.column === sourceMappedVariable).forEach(sourceColumn => {
                      if  (document.getElementById('checkbox-gby-'+sourceColumn.columnId+'-left') !== null) {
                        document.getElementById('checkbox-gby-'+sourceColumn.columnId+'-left').click()
                      }
                    })
                  }

                  if(targetVariableGBY.length == 0 || (this.state.position === 'left' && targetVariableGBY.length > 0)){
                    targetGrid.filter(targetColumn => targetColumn.column === targetMappedVariable).forEach(targetColumn => {
                      if  (document.getElementById('checkbox-gby-'+targetColumn.columnId+'-right') !== null) {
                        document.getElementById('checkbox-gby-'+targetColumn.columnId+'-right').click()
                      }
                    })
                  }
                }*/
              }
            })
          }
        }
      }, 1)

    }

    selectionChange(event) {
      event.dataItem[this.state.selectedField] = !event.dataItem[this.state.selectedField];
      this.forceUpdate();
      this.setGridData();
    }

    headerSelectionChange(event) {
      const checked = event.syntheticEvent.target.checked;

      this.state.gridData.forEach((row, index) => {
        if (typeof(global.columnMapperNodeList) !== 'undefined') {
          var columnMapped = false;
          global.columnMapperNodeList.forEach((mappedNode) => {
            if (this.state.position === 'left' && mappedNode.node.source === row.columnId) {
                columnMapped = true;
                return true;
            } else if(this.state.position === 'right' && mappedNode.node.target === row.columnId) {
              columnMapped = true;
              return true;
            }
          })

          if (columnMapped) {
            this.state.gridData[index][this.state.selectedField] = true;
          } else {
            this.state.gridData[index][this.state.selectedField] = checked;
          }
        } else {
            this.state.gridData[index][this.state.selectedField] = checked
        }

      });
      //need to set select=true for column 'APP_KEY' for all operation
      if(typeof(global.ColumnAlwaysNeedsToBeDisplay) !== 'undefined' && global.ColumnAlwaysNeedsToBeDisplay.length > 0)
      global.ColumnAlwaysNeedsToBeDisplay.forEach((columnName) => {
        this.state.gridData.forEach((item,  index) => {
          if(typeof(item['column']) !== 'undefined' && item['column'] === columnName && typeof(item['selected']) !== 'undefined' ){
            this.state.gridData[index]['selected'] = true;
          }
        });
      });
      this.forceUpdate();
      this.setGridData();
    }

    dragStartEvent(e, dataItem) {
      setNodeDirectionMode(e);
      startDrawLine({ startX: e.clientX, startY: e.clientY}, dataItem)
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
      if(typeof(nextProps.table) !== 'undefined' && JSON.stringify(nextProps) !== '') {
        let gridData = [];
        if(typeof(nextProps.table)  !== 'undefined'){
          if(nextProps.constructiveOperation){
            gridData = nextProps.table.rows.filter((row) => row.column.indexOf('_app_') === -1  && row.column.indexOf('APP_') === -1);;
          }else{
            gridData = nextProps.table.rows.sort((a,b)=> {return a.column.localeCompare(b.column)});
          }
        }
        this.setState({ gridData: gridData , table: nextProps.table});
      }
    }

    dragEndEvent(e, dataItem) {
      e.preventDefault();
      setNodeDirectionMode(e);
      endDrawLine();
    }
    dragOverEvent(e, dataItem) {
      setNodeDirectionMode(e);
      e.preventDefault();
      overDrawLine({ stopX: e.clientX, stopY: e.clientY})
    }

    draggingEvent(e, dataItem) {
      setNodeDirectionMode(e);
      e.preventDefault();
      drawLine({ stopX: e.clientX, stopY: e.clientY})
    }

    dropedEvent(e, dataItem) {
      if (e.dataTransfer.getData('SourceDataItemType') === 'CHAR' && (dataItem.type === 'DATE' || dataItem.type === 'DATETIME')) {
        this.setMessage('Type CHAR to DATE is not valid entity', MessageTypeConst.WARNING_MESSAGE);
        return false;
      }
      if(this.state.constructiveOperation){
        let columnExists = false;
        global.columnMapperNodeList.forEach((node, nodeIndex) => {
          if ((node.node.target === dataItem.columnId)) {
            columnExists = true;
          }
        });
        if(columnExists){
          this.setMessage("Multiple mapping to same target not allowed.", MessageTypeConst.WARNING_MESSAGE);
          return false;
        }

        const state = DrawMapppingLine.getState();
        //(this.state.processFlowAction === 'annotate' || this.state.processFlowAction === 'applyProcessFlow') &&
        if (global.columnMapperNodeList.filter(node => node.node.source === state.node.source).length > 0) {
          this.setMessage("Source variable is already mapped in target.", MessageTypeConst.WARNING_MESSAGE);
          return false;
        }
      }
/*
      var processFlowAction = this.state.processFlowAction;
      if (processFlowAction === DataOpsIdentifierConstants.APPEND_OP) {
        if (((e.dataTransfer.getData('SourceDataItemType') === 'DATE' || e.dataTransfer.getData('SourceDataItemType') === 'DATETIME')
        && dataItem.type === 'NUM') ||
        (e.dataTransfer.getData('SourceDataItemType') === 'NUM' && (dataItem.type === 'DATE' || dataItem.type === 'DATETIME'))) {
          kendo.alert('Type '+e.dataTransfer.getData('SourceDataItemType')+' to '+dataItem.type+' is not valid entity');
          return false;
        }
      } else {
        if (e.dataTransfer.getData('SourceDataItemType') === 'DATE' || e.dataTransfer.getData('SourceDataItemType') === 'DATETIME') {
          if(processFlowAction === DataOpsIdentifierConstants.VARMAPPING_OP){
            if(!(dataItem.type === 'DATE' || dataItem.type === 'DATETIME' || dataItem.type === 'NUM')){
              kendo.alert('Type '+e.dataTransfer.getData('SourceDataItemType')+' to '+dataItem.type+' is not valid entity');
              return false;
            }
          } else {
            if (!(dataItem.type === 'DATE' || dataItem.type === 'DATETIME')) {
            kendo.alert('Type '+e.dataTransfer.getData('SourceDataItemType')+' to '+dataItem.type+' is not valid entity');
            return false;
            }
          }
        } else {
          if (e.dataTransfer.getData('SourceDataItemType') !== dataItem.type) {
            kendo.alert('Type '+e.dataTransfer.getData('SourceDataItemType')+' to '+dataItem.type+' is not valid entity');
            return false;
          }
        }
      }
*/

      e.preventDefault();
      setNodeDirectionMode(e);
      dropDrawLine(dataItem);


    }

    componentDidMount() {
      this.element = ReactDOM.findDOMNode(this);
      global.gridElement = [...global.gridElement, this.element];
    }

    findBlinkNodes(dataItem) {

      if (this.state.position === 'right') {
        global.columnMapperTargetSelected = dataItem.dataItem.column;
      } else {
        global.columnMapperTargetSelected = '';
      }

      blinkingMappedRows(this.state.position, dataItem.dataItem.columnId)
    }

    rowRender(trElement, dataItem) {
        const trProps = {
            ...trElement.props,
            // onClick: () => {
            //   this.findBlinkNodes(dataItem);
            // }
        };
        return React.cloneElement(trElement, { ...trProps }, trElement.props.children);
    }

    itemChange(event) {
      event.dataItem[event.field] = event.value;
      this.forceUpdate();
      this.setGridData();
    }

    widgetRef(element) {
      if (typeof(global.gridMapperRef) === 'undefined') {
          global.gridMapperRef = [];
      }

      if (element !== null) {
          global.gridMapperRef.push(element);
      }

    }




    render() {
      //handling visibility of 'selected' column as it need to be hidden for 'Decollasepe(TwoTable) action'
      let showSelectedColumn =true;
      let showGbyColumn =true;
      let showObyColumn =true;
      let showTableTitle = typeof(this.state.gridData[0]) !== 'undefined' && typeof(this.state.gridData[0].tableTitle) !== 'undefined' && this.state.gridData[0].tableTitle !== '' ? true : false;
      const showUsageColumn =  this.state.position === 'left' && typeof(this.state.gridData[0]) !== 'undefined' && typeof(this.state.gridData[0].usage) !== 'undefined' ? true: false;

      if(typeof(this.state.table) !== 'undefined' && typeof(this.state.table) !== 'undefined'
      && this.state.table.columnProperty.length > 0){
        this.state.table.columnProperty.forEach((column, index) => {
          if(column.field === 'selected') {
            showSelectedColumn = this.state.table.columnProperty[index].show;
          } if(column.field === 'gby') {
            showGbyColumn = this.state.table.columnProperty[index].show ;
          } if(column.field === 'oby') {
            showObyColumn = this.state.table.columnProperty[index].show ;
          }
        });
      }

      var key = 0;

      if (this.state.position === 'right') {
        key = 1;
      }
      var gby = (typeof(this.state.gridData[0]) !== 'undefined' && typeof(this.state.gridData[0].gby) !== 'undefined') ? '-gby' : '';
      return (
            <div className={`${this.state.position}${gby}-grid`}>
              {this.state.gridData.length > 0  ? (
                <RichGridTable {...{id: `${this.state.position}-grid${this.state.constructiveOperation === true ? '-input-structure': ''}`, rows: this.state.gridData, columnProperty: [
                  { field: "selected", className:this.state.position, show: showSelectedColumn},
                  { field: "tableName", show: false},
                  { field: "status", show: false},
                  { field: "gby", title: "GBY", filterable: false, width:"32px", cell: CompareOpsGbyCheckBoxCell, className: this.state.position, show: showGbyColumn, orderIndex:1},
                  { field: "oby", title: "OBY", filterable: false, width:"61px", cell: ObyProcessCell, className: this.state.position, show: showObyColumn, orderIndex:1},
                  { field: "tableTitle", title: "Table Title", filterable: false, width:"80px", className: 'table-title', show: showTableTitle, orderIndex:1},
                  { field: "column", title: "Variable", filterable: false, width:(145+(gby === '' ? 5:0))+"px", cell: NewColumnSpanCell, orderIndex:1},
                  { field: "label", orderIndex:2, sortable:false, width:`${showTableTitle ? (132+(gby === '' ? 8:0))+"px" : (212+(gby === '' ? 8:0))+"px"}`,filterable: false},
                  { field: "type", filterable: false, width:"45px", sortable:false, className: "text-center", orderIndex:3},
                  { field: "length", filterable: false, width:"55px", sortable:false, className: "text-center", orderIndex:4},
                  { field: "usage", filterable: false, width:"65px", sortable:false, className: "text-center", show: showUsageColumn, orderIndex:4},
                  { field: "columnId", title: '', filterable: false, width:`${typeof(this.state.gridData[0]) !== 'undefined' && typeof(this.state.gridData[0].status) !== "undefined" && this.state.position === 'left' ? "65px" : "25px"}`, sortable:false, className: this.state.position, cell: DragCell, show: true, orderIndex: `${this.state.position === 'left'? 5 : 0}`},
                ]}} key={`${key}-${this.state.constructiveOperation === true ? 'input-structure': 'normal'}`}/>
              ) : (<></>)}
            </div>
        );
    }
}

function setNodeDirectionMode(e){
  if (typeof(e.target.getAttribute('type')) !== 'undefined' && e.target.getAttribute('type') !== '') {
    global.directionMode = e.target.getAttribute('type');
  }
}

function calculateSVGGrid(){
  if (typeof(global.gridElement[0]) !== 'undefined' && typeof(global.gridElement[1]) !== 'undefined') {
    let leftGrid = getDimensions(global.gridElement[0]);
    let rightGrid = getDimensions(global.gridElement[1]);
    let offset = wrapperOffsetElement();

    if (offset !== ''  && typeof(leftGrid) !== 'undefined' && typeof(leftGrid) !== null && typeof(rightGrid) !== 'undefined' && typeof(rightGrid) !== null && typeof(rightGrid.right) !== 'undefined' && typeof(offset.left) !== 'undefined' && typeof(rightGrid.left) !== 'undefined' ) {
        return { left: (leftGrid.right - offset.left), top: (leftGrid.top - offset.top), width: (rightGrid.left - leftGrid.right), height: leftGrid.height }
    }
  }
}

function populateSelectionCheckBox(selectionType = true) {
  if (typeof(global.columnMapperNodeList) !== 'undefined' && global.columnMapperNodeList !== null) {
      var leftGrid = cloneDeep(global.gridMapperRef[0]);
      var rightGrid = cloneDeep(global.gridMapperRef[1]);

      global.columnMapperNodeList.forEach((node, nodeIndex) => {
        leftGrid.props.data.forEach((column, columnIndex) => {
          if ((node.node.source === column.columnId)) {
            leftGrid.props.data[columnIndex].selected = selectionType;
          }
        });

        rightGrid.props.data.forEach((column, columnIndex) => {
          if ((node.node.target === column.columnId)) {
            rightGrid.props.data[columnIndex].selected = selectionType;
          }
        });
      });

      if (typeof(global.processBlockControlValues) !== 'undefined') {
        global.processBlockControlValues.forEach((control, index) => {
          if (control.controlId === 3) {
            global.processBlockControlValues[index].controlValue = JSON.stringify(leftGrid.props.data);
          } if (control.controlId === 24) {
            global.processBlockControlValues[index].controlValue = JSON.stringify(rightGrid.props.data);
          }
        });
      }
    }
}

function startDrawLine( line, dataItem ) {
  let state = DrawMapppingLine.getState();
  state.node.source = dataItem.columnId;
  state.nodePosition.start = global.directionMode;
  let offset = wrapperOffsetElement();
  let sourceElement = getDimensions(getSourceElement(state.node.source));
  let x = (global.directionMode === 'left') ? 5 : (state.style.width -5)
  let y = (sourceElement.height/2) + sourceElement.y - (state.style.top + offset.top);
  state.line.startX = x;
  state.line.startY = y;
  state.line.stopX = x;
  state.line.stopY = y;
}

function endDrawLine() {
  let state = DrawMapppingLine.getState();
  if (state.node.source === 0 || state.node.target === 0) {
      DrawMapppingLine.setLineState({ line: {startX:0,startY: 0,stopX: 0,stopY: 0}, node: {source: 0, target: 0}, strokeColor: 'white' });
  }
}

function drawLine( line ) {
  let offset = wrapperOffsetElement();
  let state = DrawMapppingLine.getState();

  state.line.stopX = line.stopX - (state.style.left + offset.left);
  state.line.stopY = line.stopY - (state.style.top + offset.top);

  if (state.line.stopX > (state.style.width - 5)) {
      state.line.stopX = state.style.width - 5;
  }

  if (state.line.stopX < 5) {
      state.line.stopX = 5;
  }

  if (state.line.stopY > (state.style.height - 5)) {
    state.line.stopY = state.style.height - 5;
  }

  DrawMapppingLine.setLineState({ line: state.line , strokeColor: 'black' });
}

function overDrawLine( line ) {
  let offset = wrapperOffsetElement();
  let state = DrawMapppingLine.getState();
  line.stopX = line.stopX -  (state.style.left + offset.left);
  line.stopY = line.stopY -  (state.style.top + offset.top);
  line.startX = state.line.startX;
  line.startY = state.line.startY;

  if (line.stopX > (state.style.width - 5)) {
    line.stopX = state.style.width - 5;
  }

  if (line.stopX < 5) {
    line.stopX = 5;
  }

  if (line.stopY > (state.style.height - 5)) {
    line.stopY = state.style.height - 5;
  }

  DrawMapppingLine.setLineState({ line: line, strokeColor: 'black' });
}

function dropDrawLine( dataItem ) {
  let offset = wrapperOffsetElement();
  let state = DrawMapppingLine.getState();
  state.node.target = dataItem.columnId;
  state.nodePosition.stop = global.directionMode;
  global.directionMode = global.directionMode === 'left' ? 'right' : 'left';
  let targetElement = getDimensions(getTargetElement(state.node.target));
  state.line.stopY = (targetElement.height/2) + targetElement.y - (state.style.top + offset.top);

  if (state.line.stopY > (state.style.height - 5)) {
    state.line.stopY = state.style.height - 5;
  }

  if(state.nodePosition.start !== state.nodePosition.stop) {
    var targetNodeElement = getTargetElement(state.node.target);
    var sourceNodeElement = getSourceElement(state.node.source);
    addClassNameInElement(sourceNodeElement, 'k-state-selected',state.processFlowAction);
    addClassNameInElement(targetNodeElement, 'k-state-selected',state.processFlowAction);

    if (state.lastClickNode.id === state.node.source) {
      addClassNameInElement(getTargetElement(state.node.target), 'row-black',state.processFlowAction);
    }
    if (state.nodePosition.start === 'right') {
      state.node.source = state.node.source + state.node.target;
      state.node.target = state.node.source - state.node.target;
      state.node.source = state.node.source - state.node.target;

      state.line.startX = state.line.startX + state.line.stopX;
      state.line.stopX = state.line.startX - state.line.stopX;
      state.line.startX = state.line.startX - state.line.stopX

      state.line.startY = state.line.startY + state.line.stopY;
      state.line.stopY = state.line.startY - state.line.stopY;
      state.line.startY = state.line.startY - state.line.stopY;
    }

    global.columnMapperNodeList = [...state.nodeList, { node: state.node, line: state.line, strokeColor: 'green'}];
    DrawMapppingLine.setLineState({ nodeList: global.columnMapperNodeList });
    DrawMapppingLine.populateColumn();
    let  action = state.processFlowAction;
    if(action === DataOpsIdentifierConstants.VARMAPPING_OP){
      MappingRules.AddDefaultMappingRuleForTargetColumn(dataItem.column, state.tableList);
    }
    //populateSelectionCheckBox();
  }

  DrawMapppingLine.setLineState({ line: {startX:0,startY: 0,stopX: 0,stopY: 0}, node: {source: 0, target: 0}, strokeColor: 'white' });
}

function selectBothGroupByForMapped(sourceElement,targetElement){
  if(sourceElement.parentNode.parentNode.classList.contains('k-state-selected')){
    if(sourceElement.getAttribute("type") === 'left'){
      if(sourceElement.parentNode.parentNode.children[1] && sourceElement.parentNode.parentNode.children[1].children[0] &&
        targetElement.parentNode.parentNode.children[2] && targetElement.parentNode.parentNode.children[2].children[0]
      )
      {
        let sourceState = sourceElement.parentNode.parentNode.children[1].children[0].checked;
        let targetState = targetElement.parentNode.parentNode.children[2].children[0].checked;
        if(sourceElement.parentNode.parentNode.children[1].children.length>0 &&
          targetElement.parentNode.parentNode.children[2].children.length>0 &&
          sourceState !== targetState)
          {
              targetElement.parentNode.parentNode.children[2].children[0].click();
          }
      }

    }
    if(sourceElement.getAttribute("type") === 'right'){
      if(sourceElement.parentNode.parentNode.children[2] && sourceElement.parentNode.parentNode.children[2].children[0] &&
        targetElement.parentNode.parentNode.children[1] && targetElement.parentNode.parentNode.children[1].children[0]
      )
      {
        let sourceState = sourceElement.parentNode.parentNode.children[2].children[0].checked;
        let targetState = targetElement.parentNode.parentNode.children[1].children[0].checked;
        //for group by check box
        if(sourceElement.parentNode.parentNode.children[2].children.length>0 &&
          targetElement.parentNode.parentNode.children[1].children.length>0 &&
          sourceState !== targetState)
          {
            targetElement.parentNode.parentNode.children[1].children[0].click();
          }
      }
    }
  }
}

function addClassNameInElement(element, className, processFlowAction, update = false) {
  if (className !== 'row-black') {
      element.parentNode.parentNode.classList.add(className);
  } else {
    if (element.parentNode.parentNode.classList.contains('k-state-selected')) {
      element.parentNode.parentNode.classList.add(className);
    }
  }

  if(className === 'k-state-selected' && !update)
  {
    let actionName = processFlowAction;
    if(element.getAttribute("type") === 'left' && actionName !== DataOpsIdentifierConstants.VARMAPPING_OP){
      if(element.parentNode.parentNode.parentNode.querySelectorAll('.rgt-cell-checkbox[data-row-id="'+element.parentNode.parentNode.dataset["rowId"]+'"] .am-checkbox').length > 0
      && !element.parentNode.parentNode.parentNode.querySelector('.rgt-cell-checkbox[data-row-id="'+element.parentNode.parentNode.dataset["rowId"]+'"] .am-checkbox').checked){
        element.parentNode.parentNode.parentNode.querySelector('.rgt-cell-checkbox[data-row-id="'+element.parentNode.parentNode.dataset["rowId"]+'"] .am-checkbox').click();
        element.parentNode.parentNode.parentNode.querySelector('.rgt-cell-checkbox[data-row-id="'+element.parentNode.parentNode.dataset["rowId"]+'"] .am-checkbox').disabled = true;
      }

      //for group by check box
      if(element.parentNode.parentNode.parentNode.querySelectorAll('.rgt-cell[data-row-id="'+element.parentNode.parentNode.dataset["rowId"]+'"][data-column-id="2"] .am-checkbox').length>0){
        element.parentNode.parentNode.parentNode.querySelector('.rgt-cell[data-row-id="'+element.parentNode.parentNode.dataset["rowId"]+'"][data-column-id="2"] .am-checkbox').disabled = false;

        if (element.parentNode.parentNode.parentNode.querySelectorAll('.rgt-cell[data-row-id="'+element.parentNode.parentNode.dataset["rowId"]+'"][data-column-id="3"] .am-dropdown .Dropdown-placeholder').length > 0 && false) {
          element.parentNode.parentNode.parentNode.querySelector('.rgt-cell[data-row-id="'+element.parentNode.parentNode.dataset["rowId"]+'"][data-column-id="3"] .am-dropdown .Dropdown-placeholder').innerText = "A"
        }
      }
    }
    if(element.getAttribute("type") === 'right'){
      if(element.parentNode.parentNode.parentNode.querySelectorAll('.rgt-cell-checkbox[data-row-id="'+element.parentNode.parentNode.dataset["rowId"]+'"] .am-checkbox').length > 0
      && !element.parentNode.parentNode.parentNode.querySelector('.rgt-cell-checkbox[data-row-id="'+element.parentNode.parentNode.dataset["rowId"]+'"] .am-checkbox').checked){
        element.parentNode.parentNode.parentNode.querySelector('.rgt-cell-checkbox[data-row-id="'+element.parentNode.parentNode.dataset["rowId"]+'"] .am-checkbox').click();
        element.parentNode.parentNode.parentNode.querySelector('.rgt-cell-checkbox[data-row-id="'+element.parentNode.parentNode.dataset["rowId"]+'"] .am-checkbox').disabled = true;
      }
      //for group by check box
      if(element.parentNode.parentNode.parentNode.querySelectorAll('.rgt-cell[data-row-id="'+element.parentNode.parentNode.dataset["rowId"]+'"][data-column-id="3"] .am-checkbox').length>0){
        element.parentNode.parentNode.parentNode.querySelector('.rgt-cell[data-row-id="'+element.parentNode.parentNode.dataset["rowId"]+'"][data-column-id="3"] .am-checkbox').disabled = false;

        if (element.parentNode.parentNode.parentNode.querySelectorAll('.rgt-cell[data-row-id="'+element.parentNode.parentNode.dataset["rowId"]+'"][data-column-id="4"] .am-dropdown .Dropdown-placeholder').length > 0 && false) {
          element.parentNode.parentNode.parentNode.querySelector('.rgt-cell[data-row-id="'+element.parentNode.parentNode.dataset["rowId"]+'"][data-column-id="4"] .am-dropdown .Dropdown-placeholder').innerText = "A"
        }
      }
    }
  }
  if(className === 'k-state-selected' && update){
    // if column is mapped then during update disable "selected" check box
    let actionName = processFlowAction;
    if(element.getAttribute("type") === 'left' && actionName !== DataOpsIdentifierConstants.VARMAPPING_OP){
      if(element.parentNode.parentNode.parentNode.querySelectorAll('.rgt-cell-checkbox[data-row-id="'+element.parentNode.parentNode.dataset["rowId"]+'"]').length > 0
      && !element.parentNode.parentNode.parentNode.querySelector('.rgt-cell-checkbox[data-row-id="'+element.parentNode.parentNode.dataset["rowId"]+'"]').children[0].children[0].checked){
        element.parentNode.parentNode.parentNode.querySelector('.rgt-cell-checkbox[data-row-id="'+element.parentNode.parentNode.dataset["rowId"]+'"]').children[0].children[0].click();
        element.parentNode.parentNode.parentNode.querySelector('.rgt-cell-checkbox[data-row-id="'+element.parentNode.parentNode.dataset["rowId"]+'"]').children[0].children[0].disabled = true;
      }
      if(element.parentNode.parentNode.parentNode.querySelectorAll('.rgt-cell[data-row-id="'+element.parentNode.parentNode.dataset["rowId"]+'"][data-column-id="3"] .am-checkbox').length>0){
        element.parentNode.parentNode.parentNode.querySelector('.rgt-cell[data-row-id="'+element.parentNode.parentNode.dataset["rowId"]+'"][data-column-id="3"] .am-checkbox').disabled = false;
      }
    }
    if(element.getAttribute("type") === 'right'){
      if(element.parentNode.parentNode.parentNode.querySelectorAll('.rgt-cell-checkbox[data-row-id="'+element.parentNode.parentNode.dataset["rowId"]+'"] .am-checkbox').length > 0
      && !element.parentNode.parentNode.parentNode.querySelector('.rgt-cell-checkbox[data-row-id="'+element.parentNode.parentNode.dataset["rowId"]+'"] .am-checkbox').checked){
        element.parentNode.parentNode.parentNode.querySelector('.rgt-cell-checkbox[data-row-id="'+element.parentNode.parentNode.dataset["rowId"]+'"] .am-checkbox').click();
        element.parentNode.parentNode.parentNode.querySelector('.rgt-cell-checkbox[data-row-id="'+element.parentNode.parentNode.dataset["rowId"]+'"] .am-checkbox').disabled = true;
      }

      if(element.parentNode.parentNode.parentNode.querySelectorAll('.rgt-cell[data-row-id="'+element.parentNode.parentNode.dataset["rowId"]+'"][data-column-id="3"] .am-checkbox').length>0){
        element.parentNode.parentNode.parentNode.querySelector('.rgt-cell[data-row-id="'+element.parentNode.parentNode.dataset["rowId"]+'"][data-column-id="3"] .am-checkbox').disabled = false;
      }
    }
  }
}

export function removeClassNameInElement(element, className) {
  element.parentNode.parentNode.classList.remove(className);

  if(className === 'k-state-selected')
  {
    if(element.getAttribute("type") === 'left'){
      if(element.parentNode.parentNode.parentNode.querySelectorAll('.rgt-cell-checkbox[data-row-id="'+element.parentNode.parentNode.dataset["rowId"]+'"] .am-checkbox').length > 0
      && element.parentNode.parentNode.parentNode.querySelector('.rgt-cell-checkbox[data-row-id="'+element.parentNode.parentNode.dataset["rowId"]+'"] .am-checkbox').checked){
        element.parentNode.parentNode.parentNode.querySelector('.rgt-cell-checkbox[data-row-id="'+element.parentNode.parentNode.dataset["rowId"]+'"] .am-checkbox').disabled = false;
        let leftColumnName = '';

        if (element.parentNode.parentNode.parentNode.querySelectorAll('.rgt-cell[data-row-id="'+element.parentNode.parentNode.dataset["rowId"]+'"][data-column-id="4"] .rgt-cell-inner').length > 0) {
          leftColumnName = element.parentNode.parentNode.parentNode.querySelector('.rgt-cell[data-row-id="'+element.parentNode.parentNode.dataset["rowId"]+'"][data-column-id="4"] .rgt-cell-inner').innerText.trim();
        }

        //if column name is set to be always display, no need to un-check it
        if(typeof(global.ColumnAlwaysNeedsToBeDisplay) !== 'undefined' && global.ColumnAlwaysNeedsToBeDisplay.length > 0
          && typeof(leftColumnName) !== "undefined" && leftColumnName !== null && leftColumnName !== ""){
          if(global.ColumnAlwaysNeedsToBeDisplay.indexOf(leftColumnName) === -1){
            element.parentNode.parentNode.parentNode.querySelector('.rgt-cell-checkbox[data-row-id="'+element.parentNode.parentNode.dataset["rowId"]+'"] .am-checkbox').click();
          }
        }
      }
      //un checked 'grp' check box
      if(element.parentNode.parentNode.parentNode.querySelectorAll('.rgt-cell[data-row-id="'+element.parentNode.parentNode.dataset["rowId"]+'"][data-column-id="2"] .am-checkbox').length>0 && element.parentNode.parentNode.parentNode.querySelector('.rgt-cell[data-row-id="'+element.parentNode.parentNode.dataset["rowId"]+'"][data-column-id="2"] .am-checkbox').checked){
        element.parentNode.parentNode.parentNode.querySelector('.rgt-cell[data-row-id="'+element.parentNode.parentNode.dataset["rowId"]+'"][data-column-id="2"] .am-checkbox').disabled = false;
        element.parentNode.parentNode.parentNode.querySelector('.rgt-cell[data-row-id="'+element.parentNode.parentNode.dataset["rowId"]+'"][data-column-id="2"] .am-checkbox').click();
        element.parentNode.parentNode.parentNode.querySelector('.rgt-cell[data-row-id="'+element.parentNode.parentNode.dataset["rowId"]+'"][data-column-id="2"] .am-checkbox').disabled = true;
      }

      //reset order by
      if(element.parentNode.parentNode.parentNode.querySelectorAll('.rgt-cell[data-row-id="'+element.parentNode.parentNode.dataset["rowId"]+'"][data-column-id="3"] .am-dropdown .Dropdown-placeholder').length > 0){
        element.parentNode.parentNode.parentNode.querySelector('.rgt-cell[data-row-id="'+element.parentNode.parentNode.dataset["rowId"]+'"][data-column-id="3"] .am-dropdown .Dropdown-placeholder').innerText = '';
      }
    }
    if(element.getAttribute("type") === 'right'){
      if(element.parentNode.parentNode.parentNode.querySelectorAll('.rgt-cell-checkbox[data-row-id="'+element.parentNode.parentNode.dataset["rowId"]+'"] .am-checkbox').length > 0
      && element.parentNode.parentNode.parentNode.querySelector('.rgt-cell-checkbox[data-row-id="'+element.parentNode.parentNode.dataset["rowId"]+'"] .am-checkbox').checked){
        element.parentNode.parentNode.parentNode.querySelector('.rgt-cell-checkbox[data-row-id="'+element.parentNode.parentNode.dataset["rowId"]+'"] .am-checkbox').disabled = false;
        let rightColumnName = '';

        if (element.parentNode.parentNode.parentNode.querySelectorAll('.rgt-cell[data-row-id="'+element.parentNode.parentNode.dataset["rowId"]+'"][data-column-id="5"] .rgt-cell-inner').length > 0) {
          rightColumnName = element.parentNode.parentNode.parentNode.querySelector('.rgt-cell[data-row-id="'+element.parentNode.parentNode.dataset["rowId"]+'"][data-column-id="5"] .rgt-cell-inner').innerText.trim();
        }
        //if column name is set to be always display, no need to un-check it
        if(typeof(global.ColumnAlwaysNeedsToBeDisplay) !== 'undefined' && global.ColumnAlwaysNeedsToBeDisplay.length > 0
          && typeof(rightColumnName) !== "undefined" && rightColumnName !== null && rightColumnName !== ""){
          if(global.ColumnAlwaysNeedsToBeDisplay.indexOf(rightColumnName) === -1){
            element.parentNode.parentNode.parentNode.querySelector('.rgt-cell-checkbox[data-row-id="'+element.parentNode.parentNode.dataset["rowId"]+'"] .am-checkbox').click();
          }
        }
      }
      //un checked 'grp' check box
      if(element.parentNode.parentNode.parentNode.querySelectorAll('.rgt-cell[data-row-id="'+element.parentNode.parentNode.dataset["rowId"]+'"][data-column-id="3"] .am-checkbox').length>0 && element.parentNode.parentNode.parentNode.querySelector('.rgt-cell[data-row-id="'+element.parentNode.parentNode.dataset["rowId"]+'"][data-column-id="3"] .am-checkbox').checked){
        element.parentNode.parentNode.parentNode.querySelector('.rgt-cell[data-row-id="'+element.parentNode.parentNode.dataset["rowId"]+'"][data-column-id="3"] .am-checkbox').disabled = false;
        element.parentNode.parentNode.parentNode.querySelector('.rgt-cell[data-row-id="'+element.parentNode.parentNode.dataset["rowId"]+'"][data-column-id="3"] .am-checkbox').click();
        element.parentNode.parentNode.parentNode.querySelector('.rgt-cell[data-row-id="'+element.parentNode.parentNode.dataset["rowId"]+'"][data-column-id="3"] .am-checkbox').disabled = true;
      }
      //reset order by
      if(element.parentNode.parentNode.parentNode.querySelectorAll('.rgt-cell[data-row-id="'+element.parentNode.parentNode.dataset["rowId"]+'"][data-column-id="4"] .am-dropdown .Dropdown-placeholder').length > 0){
        element.parentNode.parentNode.parentNode.querySelector('.rgt-cell[data-row-id="'+element.parentNode.parentNode.dataset["rowId"]+'"][data-column-id="4"] .am-dropdown .Dropdown-placeholder').innerText = '';
      }
    }
  }

  //populateSelectionCheckBox(false);
}

function toggleClassNameInElement(element, className) {
  element.parentNode.parentNode.classList.toggle(className);
}

function resetGridHighlightsRows(){
  for (var i = 0; i < document.querySelectorAll('.process-option .am-rich-grid-table .rgt-container').length; i++) {
    document.querySelectorAll('.process-option .am-rich-grid-table .rgt-container')[i].querySelectorAll('.row-black').forEach((row, i) => {
      row.classList.remove('row-black')
    });
  }
}

function resetGridRows(){
  for (var i = 0; i < document.querySelectorAll('.process-option .am-rich-grid-table .rgt-container').length; i++) {
    document.querySelectorAll('.process-option .am-rich-grid-table .rgt-container')[i].querySelectorAll('.rgt-cell-checkbox').forEach((row, k) => {
      if(row.parentNode.querySelectorAll('.rgt-cell-checkbox[data-row-id="'+row.dataset["rowId"]+'"] .am-checkbox').length > 0
      && row.parentNode.querySelector('.rgt-cell-checkbox[data-row-id="'+row.dataset["rowId"]+'"] .am-checkbox').checked){
        row.parentNode.querySelector('.rgt-cell-checkbox[data-row-id="'+row.dataset["rowId"]+'"] .am-checkbox').disabled = false;
        let leftColumnName = '';

        if (row.parentNode.querySelectorAll('.rgt-cell-column.rgt-cell[data-row-id="'+row.dataset["rowId"]+'"] .rgt-cell-inner').length > 0) {
          leftColumnName = row.parentNode.querySelector('.rgt-cell-column.rgt-cell[data-row-id="'+row.dataset["rowId"]+'"] .rgt-cell-inner').innerText.trim();
        }

        //if column name is set to be always display, no need to un-check it
        if(typeof(global.ColumnAlwaysNeedsToBeDisplay) !== 'undefined' && global.ColumnAlwaysNeedsToBeDisplay.length > 0
          && typeof(leftColumnName) !== "undefined" && leftColumnName !== null && leftColumnName !== ""){
          if(global.ColumnAlwaysNeedsToBeDisplay.indexOf(leftColumnName) === -1){
            row.parentNode.querySelector('.rgt-cell-checkbox[data-row-id="'+row.dataset["rowId"]+'"] .am-checkbox').click();
          }
        }
      }
      //un checked 'grp' check box
      if(row.parentNode.querySelectorAll('.rgt-cell-gby.rgt-cell[data-row-id="'+row.dataset["rowId"]+'"] .am-checkbox').length>0 && row.parentNode.querySelector('.rgt-cell-gby.rgt-cell[data-row-id="'+row.dataset["rowId"]+'"] .am-checkbox').checked){
        row.parentNode.querySelector('.rgt-cell-gby.rgt-cell[data-row-id="'+row.dataset["rowId"]+'"] .am-checkbox').disabled = false;
        row.parentNode.querySelector('.rgt-cell-gby.rgt-cell[data-row-id="'+row.dataset["rowId"]+'"] .am-checkbox').click();
        row.parentNode.querySelector('.rgt-cell-gby.rgt-cell[data-row-id="'+row.dataset["rowId"]+'"] .am-checkbox').disabled = true;
      }

      //reset order by
      if(row.parentNode.querySelectorAll('.rgt-cell-oby.rgt-cell[data-row-id="'+row.dataset["rowId"]+'"] .am-dropdown .Dropdown-placeholder').length > 0){
        row.parentNode.querySelector('.rgt-cell-oby.rgt-cell[data-row-id="'+row.dataset["rowId"]+'"] .am-dropdown .Dropdown-placeholder').innerText = '';
      }


      if(row.classList.contains('k-state-selected')){
        row.classList.remove('k-state-selected');
      }
    });
  }
  for (var i = 0; i < document.querySelectorAll('.process-option .am-rich-grid-table .rgt-container').length; i++) {
    document.querySelectorAll('.process-option .am-rich-grid-table .rgt-container')[i].querySelectorAll('.k-master-row').forEach((row, k) => {
      row.classList.remove('row-black');
    });
  }
}
function blinkingMappedRows(position, element) {
  let state = DrawMapppingLine.getState();
  if (state.nodeList.length > 0) {
    global.directionMode = 'left';
    let sourceElement = '';
    var triggerElementList = [];
    state.nodeList.forEach((node, i) => {
      if (position === 'left' && node.node.source === element) {
        triggerElementList.push(getTargetElement(node.node.target));
      } else if(position === 'right' && node.node.target === element) {
        triggerElementList.push(getSourceElement(node.node.source));
      }
    });

    if (triggerElementList !== '') {
      sourceElement = getSourceElement(element);
      if (position === 'right') {
          sourceElement = getTargetElement(element);
      }
    }

    if (sourceElement !== '' && triggerElementList !== '') {
      var eventMode = (state.lastClickNode.id === element && state.lastClickNode.position === position) ? 'toggle' : 'add';
      state.lastClickNode.id = element;
      state.lastClickNode.position = position

      if (eventMode === 'add') {
          resetGridHighlightsRows();
          addClassNameInElement(sourceElement, 'row-black', state.processFlowAction);

          triggerElementList.forEach((triggerElement, i) => {
            addClassNameInElement(triggerElement, 'blink', state.processFlowAction);
            selectBothGroupByForMapped(sourceElement,triggerElement);
            setTimeout(()=>{
              removeClassNameInElement(triggerElement, 'blink')
              addClassNameInElement(triggerElement, 'row-black', state.processFlowAction);
            }, 250);
          });
      } else {
        toggleClassNameInElement(sourceElement, 'row-black');

        triggerElementList.forEach((triggerElement, i) => {
          addClassNameInElement(triggerElement, 'blink', state.processFlowAction);
          selectBothGroupByForMapped(sourceElement,triggerElement);
          setTimeout(()=>{
            removeClassNameInElement(triggerElement, 'blink')
            removeClassNameInElement(triggerElement, 'row-black');
          }, 250);
        });

        DrawMapppingLine.setLineState({ lastClickNode: {id: 0, position: 'left'}})
      }
    }
  }
}

export class DrawMapppingLine extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
          style: {
            left: 0,
            top: 0,
            height: 0,
            width: 0
          },
          line: {
            startX:0,
            startY: 0,
            stopX: 0,
            stopY: 0
          },
          strokeColor:'white',
          node: {
            source: 0,
            target: 0
          },
          nodeList: [],
          nodePosition: {
            start: 'left',
            stop: 'right'
          },
          lastClickNode:{
            id:0,
            position: 'left'
          },
          tableList: props.tableList,
          resize: props.resize,
          executeMethodCalled : false,
          processFlowAction:props.processFlowAction,
          constructiveOperation: props.constructiveOperation
        };

        startDrawLine = startDrawLine.bind(this);
        overDrawLine = overDrawLine.bind(this);
        dropDrawLine = dropDrawLine.bind(this);
        drawLine = drawLine.bind(this);
        endDrawLine = endDrawLine.bind(this);
        blinkingMappedRows = blinkingMappedRows.bind(this);
        this.handleScrollCallback = this.handleScrollCallback.bind(this);
        this.modifyDrawLine = this.modifyDrawLine.bind(this);
        this.resizeWindowEvent = this.resizeWindowEvent.bind(this);
        this.removeMappedNode = this.removeMappedNode.bind(this);
        this.autoMapEvent = this.autoMapEvent.bind(this);
        this.quickMapItems = this.quickMapItems.bind(this);
        this.autoUnMapEvent = this.autoUnMapEvent.bind(this);
        this.checkNodeIsExist = this.checkNodeIsExist.bind(this);
        DrawMapppingLine.populateColumn = this.populateColumn.bind(this);
        this.saveGlobalValue = this.saveGlobalValue.bind(this);
        this.RestoreMappedLines = this.RestoreMappedLines.bind(this);
        this.getGlobalValue = this.getGlobalValue.bind(this);
        DrawMapppingLine.setLineState = this.setLineState.bind(this);
        DrawMapppingLine.reDrawMappedLines = this.reDrawMappedLines.bind(this);
        DrawMapppingLine.getState = this.getState.bind(this);
    }

    getGlobalValue(controlIndex){
      let controlValue = '';
      global.processBlockControlValues.forEach((control, index) => {
        if (control.controlId === controlIndex) {
          controlValue = global.processBlockControlValues[index].controlValue;

        }
      });
      return controlValue;
    }

    setLineState(line) {
      this.setState(line);
    }

    getState() {
      return this.state;
    }

    getStateNodeList() {
      return this.state.nodeList;
    }

    //to populate global variable
    populateColumn(){
      if (this.props.constructiveOperation === true) {
        return;
      }
      const leftMapColumnControlIndex = 26;
      const rightMapColumnControlIndex = 27;
      const leftMapLengthControlIndex = 28;
      const rightMapLengthControlIndex = 29;
      const leftMapTypeControlIndex = 30;
      const rightMapTypeControlIndex = 31;
      const variableMappingGrid = 53;
      //storing left table
      let leftTableData = [];
      let righTableData = [];
      let leftColumn = [];
      let rightColumn = [];
      let leftLength = [];
      let rightLength = [];
      let leftType= [];
      let rightType = [];
      global.processBlockControlValues.forEach((control, index) => {
        if (control.controlId === 3 && global.processBlockControlValues[index].controlValue) {
          leftTableData = JSON.parse(global.processBlockControlValues[index].controlValue);
          return true;
        }
      });
      //storing right table
      global.processBlockControlValues.forEach((control, index) => {
        if (control.controlId === 24 && global.processBlockControlValues[index].controlValue) {
          righTableData = JSON.parse(global.processBlockControlValues[index].controlValue);
          return true;
        }
      });
      global.columnMapperNodeList.forEach((colunmMapping,mappingIndex) => {
          if(colunmMapping.node){
            //left side column id
            const leftId = colunmMapping.node.source;
            let selectedLeftCol = leftTableData.filter((item)=>{
              return item.columnId === leftId;
            })
            if(selectedLeftCol.length > 0)
            {
              leftColumn.push(selectedLeftCol[0].column);
              leftType.push(selectedLeftCol[0].type);
              leftLength.push(selectedLeftCol[0].length);
            }

            //right side column id
            const rightId = colunmMapping.node.target;
            let selectedRightCol = righTableData.filter((item1)=>{
              return item1.columnId === rightId;
            });
            if(selectedRightCol.length > 0)
            {
              rightColumn.push(selectedRightCol[0].column);
              rightType.push(selectedRightCol[0].type);
              rightLength.push(selectedRightCol[0].length);
            }
          }

      });
      this.saveGlobalValue(leftMapColumnControlIndex,JSON.stringify(leftColumn));
      this.saveGlobalValue(leftMapTypeControlIndex,JSON.stringify(leftType));
      this.saveGlobalValue(leftMapLengthControlIndex,JSON.stringify(leftLength));
      this.saveGlobalValue(rightMapColumnControlIndex,JSON.stringify(rightColumn));
      this.saveGlobalValue(rightMapTypeControlIndex,JSON.stringify(rightType));
      this.saveGlobalValue(rightMapLengthControlIndex,JSON.stringify(rightLength));
      let mappingRules = [];
      let actionName = this.state.processFlowAction;
      if(actionName === DataOpsIdentifierConstants.VARMAPPING_OP){
        if(this.getGlobalValue(variableMappingGrid) !== "" && this.getGlobalValue(variableMappingGrid) !== null){
          mappingRules = JSON.parse(this.getGlobalValue(variableMappingGrid));
          let newMappingRules = mappingRules.filter(rule => rightColumn.indexOf(rule.targetColumn) !== -1);
          this.saveGlobalValue(variableMappingGrid, JSON.stringify(newMappingRules));
        }
      }

    }



    componentDidMount() {
      this.RestoreMappedLines();
      window.addEventListener("resize", this.resizeWindowEvent);
      //window.addEventListener("keyup", this.removeMappedNode.bind(this));
      window.addEventListener("keydown", this.removeMappedNode);

      setTimeout(()=>{
        this.setState({style : calculateSVGGrid()}, () => {
          if (this.props.columnMapperNodeList.length > 0) {
            this.setState({ nodeList: this.props.columnMapperNodeList }, () => {
              this.handleScrollCallback();
            })
          }
        });
      }, 10);

      setTimeout(()=>{
        if (document.querySelector('.data-operation .left-grid.join_master .am-rich-grid-table') !== null) {
          document.querySelector('.data-operation .left-grid.join_master .am-rich-grid-table').addEventListener('scroll', this.handleScrollCallback)
        }

        if (document.querySelector('.data-operation .right-grid.join_master .am-rich-grid-table .rgt-container') !== null) {
          document.querySelector('.data-operation .right-grid.join_master .am-rich-grid-table .rgt-container').addEventListener('scroll', this.handleScrollCallback)
        }

        this.reDrawMappedLines();

        this.state.nodeList.forEach(node => {
          if  (document.querySelector('.checkbox-selected-columnId-'+node.node.source+'-left') !== null && document.querySelector('.checkbox-selected-columnId-'+node.node.source+'-left').checked === true) {
            document.querySelector('.checkbox-selected-columnId-'+node.node.source+'-left').disabled = true;
          }
          if  (document.querySelector('.checkbox-selected-columnId-'+node.node.target+'-right') !== null) {
            document.querySelector('.checkbox-selected-columnId-'+node.node.target+'-right').disabled = true;
          }

          if  (document.getElementById('checkbox-gby-'+node.node.target+'-right') !== null) {
            document.getElementById('checkbox-gby-'+node.node.target+'-right').disabled = true;
          }
        })
      }, 600);

    }

    saveGlobalValue(controlIndex, value){
      global.processBlockControlValues.forEach((control, index) => {
        if (control.controlId === controlIndex) {
          global.processBlockControlValues[index].controlValue = value;
        }
      });
    }

    componentWillUnmount() {
      if (document.querySelector('.data-operation .left-grid.join_master .am-rich-grid-table') !== null) {
        document.querySelector('.data-operation .left-grid.join_master .am-rich-grid-table').removeEventListener('scroll', this.handleScrollCallback)
      }

      if (document.querySelector('.data-operation .right-grid.join_master .am-rich-grid-table .rgt-container') !== null) {
        document.querySelector('.data-operation .right-grid.join_master .am-rich-grid-table .rgt-container').removeEventListener('scroll', this.handleScrollCallback)
      }

      window.removeEventListener("resize", this.resizeWindowEvent);
      //window.removeEventListener("keyup", this.removeMappedNode.bind(this));
      window.removeEventListener("keydown", this.removeMappedNode);

      //global.columnMapperNodeList = [];
      //DrawMapppingLine.setLineState({ nodeList: [] });
    }

    resizeWindowEvent(){
      this.setState({style : calculateSVGGrid()});
      this.handleScrollCallback();
    }

    reDrawMappedLines() {
      this.handleScrollCallback();
    }

    modifyDrawLine(sourceElement1, targetElement1, nodeLine, update = false){
      addClassNameInElement(sourceElement1, 'k-state-selected', this.state.processFlowAction, update);
      addClassNameInElement(targetElement1, 'k-state-selected', this.state.processFlowAction,  update);
      let sourceElement = getDimensions(sourceElement1);
      let targetElement = getDimensions(targetElement1);
      let offset = wrapperOffsetElement();

      if (update) {
        this.state.style = calculateSVGGrid();
      }

      global.directionMode = 'left';
      nodeLine.startY = sourceElement.top + (sourceElement.height/2) -  (this.state.style.top + offset.top);
      nodeLine.stopY = targetElement.top + (targetElement.height/2) -  (this.state.style.top + offset.top);

      let limitOffset = limitOffsetElement();

      //nodeLine.stopX = targetElement.left - limitOffset.left - this.state.style.left;
      nodeLine.stopX = this.state.style.width - 5
      if (nodeLine.startY < limitOffset.height) {
        nodeLine.startY = limitOffset.height;
      }
      if (nodeLine.startY > (this.state.style.height - 5)) {
        nodeLine.startY = this.state.style.height - 5;
      }

      if (nodeLine.stopY < limitOffset.height) {
        nodeLine.stopY = limitOffset.height;
      }

      if (nodeLine.stopY > (this.state.style.height - 5)) {
        nodeLine.stopY = this.state.style.height - 5;
      }

      return nodeLine;
    }
    handleScrollCallback() {
      global.directionMode = 'left';
      this.state.nodeList.forEach((node, i) => {
        var sourceElement = getSourceElement(node.node.source);
        var targetElement = getTargetElement(node.node.target);

        if (typeof(sourceElement) !== 'undefined' && typeof(targetElement) !== 'undefined') {
            this.state.nodeList[i].line = this.modifyDrawLine(sourceElement, targetElement, node.line, true);
        }
      });

      this.setState({ nodeList : this.state.nodeList});
    }

    removeMappedNode(event) {
      if (event.keyCode === 8 || event.keyCode === 46) {
          if (document.querySelectorAll('.link.selected').length > 0) {
            global.directionMode = 'left';
            var nodeList = this.state.nodeList;
            let deletedNode = [];
            document.querySelectorAll('.link.selected').forEach((path, i) => {
              deletedNode.push(path.id.replace('mapped-node-', ''));
              path.classList.remove('selected');
            })

            let targetCol = "";
            this.state.nodeList.forEach((node, i) => {
              if(deletedNode.indexOf(node.node.source + "-" + node.node.target) > -1) {
                nodeList = nodeList.filter(item => deletedNode.indexOf(item.node.source + "-" + item.node.target) === -1);
                targetCol = node.node.target;
                let sourceNodeOtherMapping = nodeList.filter(sourceNode => sourceNode.node.source === node.node.source);
                if(sourceNodeOtherMapping.length === 0){
                  removeClassNameInElement(getSourceElement(node.node.source), 'k-state-selected');
                  removeClassNameInElement(getSourceElement(node.node.source), 'row-black');
                }
                let targetNodeOtherMapping = nodeList.filter(sourceNode => sourceNode.node.target === node.node.target);
                if(targetNodeOtherMapping.length === 0){
                  removeClassNameInElement(getTargetElement(node.node.target), 'k-state-selected');
                  removeClassNameInElement(getTargetElement(node.node.target), 'row-black');
                }
              }
            });

            this.setState({ nodeList : nodeList});
            global.columnMapperNodeList = [...nodeList];
            DrawMapppingLine.populateColumn();
            let  action = this.state.processFlowAction;
            if(action === DataOpsIdentifierConstants.VARMAPPING_OP){
              let targetRow = this.state.tableList[1].rows.filter(a=>a.columnId === targetCol);
              if(targetRow.length >0){
                MappingRules.AddDefaultMappingRuleForTargetColumn(targetRow[0].column, this.state.tableList);
              }
            }
          }
        }

    }

    displayMappedNodeList = (mappedNode, nodeIndex) => {
      return (
        <PathLine
            key={nodeIndex}
            id={`mapped-node-${mappedNode.node.source}-${mappedNode.node.target}`}
            className='link'
            markerStart={`url(#start-arrow-mapped${idSelectorPostFix})`}
            markerEnd={`url(#end-arrow-mapped${idSelectorPostFix})`}
            points={[{x: mappedNode.line.startX, y: mappedNode.line.startY}, {x: mappedNode.line.stopX, y: mappedNode.line.stopY}]}
            stroke={`${mappedNode.strokeColor}`}
            strokeWidth="3"
            fill="none"
            r={0}
            onClick={(e) => { e.target.classList.toggle('selected') }}
            />
      )
    };

    UNSAFE_componentWillReceiveProps(nextProps) {
      if(JSON.stringify(nextProps) !== '') {
        setTimeout(()=>{
          this.setState({style : calculateSVGGrid()});
          this.handleScrollCallback();
          //Need to raise AutoMap event on receiving props for Quick action
          let  action = this.state.processFlowAction;
          if(!this.state.executeMethodCalled && typeof(global.columnMapperNodeList) === 'undefined' && (action === DataOpsIdentifierConstants.APPEND_QUICK_OP)){
            this.autoMapEvent();
          }
          if(!this.state.executeMethodCalled && typeof(global.columnMapperNodeList) === 'undefined' && (action === DataOpsIdentifierConstants.JOIN_QUICK_OP)){
            this.quickMapItems();
          }

        }, 1);

        this.setState({tableList: nextProps.tableList, style : calculateSVGGrid()});
      }
    }
    RestoreMappedLines() {
      if (typeof(this.state.tableList[0]) !== 'undefined') {
        var nodeList = [];
        global.directionMode = 'left';
        let leftMappedColumn = [];
        let rightMappedColumn = [];

        if (this.state.constructiveOperation === true) {
          if (typeof(global.constructiveOperationGridMapping) !== 'undefined' && this.state.tableList.length > 1) {
              let constructiveOperationGridMapping = global.constructiveOperationGridMapping.filter( gridMapping => gridMapping.gridUniqueKey === (this.state.tableList[0].id +"-"+ this.state.tableList[1].id) );

              if (constructiveOperationGridMapping.length > 0) {
                  leftMappedColumn = constructiveOperationGridMapping[0].leftMappedColumn;
                  rightMappedColumn = constructiveOperationGridMapping[0].rightMappedColumn;
              }
          }
        } else {
          if(this.getGlobalValue(26) !== ""){
            leftMappedColumn = JSON.parse(this.getGlobalValue(26));
          }

          if(this.getGlobalValue(27) !== ""){
            rightMappedColumn = JSON.parse(this.getGlobalValue(27));
          }
        }

        let count = 0;
        while(count < leftMappedColumn.length){
          let leftColumnName = leftMappedColumn[count];
          let rightColumnName = rightMappedColumn[count];
          let sourceRow = this.state.tableList[0].rows.filter(item => item.column === leftColumnName);
          let targetRow = [];
          if(this.state.tableList.length > 1){
            targetRow = this.state.tableList[1].rows.filter(item => item.column === rightColumnName);
          }else{
            targetRow = this.state.tableList[0].rows.filter(item => item.column === rightColumnName);
          }
          if(sourceRow.length > 0 && targetRow.length > 0){
            if(this.checkNodeIsExist(sourceRow[0].columnId)) {
              var line = { startX:5, startY: 0, stopX: 0, stopY: 0 };
              var lineNode = { node: { source: sourceRow[0].columnId, target: targetRow[0].columnId }, line: line, strokeColor: 'green'}
              var sourceElement = getSourceElement(sourceRow[0].columnId);
              var targetElement = getTargetElement(targetRow[0].columnId);
              if (typeof(sourceElement) !== 'undefined' && typeof(targetElement) !== 'undefined') {
                  lineNode.line = this.modifyDrawLine(sourceElement, targetElement, line, true);
              }
              nodeList.push(lineNode);
          }
          }
          count++;
        }
        global.columnMapperNodeList = [...this.state.nodeList, ...nodeList];
        this.state.nodeList.length = 0;
        this.state.nodeList.push(...global.columnMapperNodeList );
        this.setState({ nodeList : this.state.nodeList });
      }
    }

    quickMapItems(){
      //For Quick join, auto map only selected columns
      let columns = localStorage.getItem('quickcolumns');
      if(columns !== null && columns !== ''){
        let columnsArray = JSON.parse(columns);if (typeof(this.state.tableList[0]) !== 'undefined') {
          var nodeList = [];
          global.directionMode = 'left';
          columnsArray.forEach((col)=>{
            this.state.tableList[0].rows.forEach((sourceRow, sourceIndex) => {
              this.state.tableList[1].rows.forEach((targetRow, targetIndex) => {
                if(sourceRow.column.toLowerCase() === col.toLowerCase() &&
                sourceRow.column.toLowerCase() === targetRow.column.toLowerCase() &&
                !sourceRow.column.toLowerCase().startsWith('_app') &&
                 this.checkNodeIsExist(sourceRow.columnId)) {
                  var line = { startX:5, startY: 0, stopX: 0, stopY: 0 };
                  var lineNode = { node: { source: sourceRow.columnId, target: targetRow.columnId }, line: line, strokeColor: 'green'}
                  var sourceElement = getSourceElement(sourceRow.columnId);
                  var targetElement = getTargetElement(targetRow.columnId);

                  if (typeof(sourceElement) !== 'undefined' && typeof(targetElement) !== 'undefined') {
                      lineNode.line = this.modifyDrawLine(sourceElement, targetElement, line);
                  }
                  nodeList.push(lineNode);
                  return true;
                }
              })
            })});

          global.columnMapperNodeList = [...this.state.nodeList, ...nodeList];
          this.setState({ nodeList : global.columnMapperNodeList });
          DrawMapppingLine.populateColumn();
          let  action = this.state.processFlowAction;
          if(!this.state.executeMethodCalled && (action === DataOpsIdentifierConstants.JOIN_QUICK_OP)){
            this.props.raiseExecute();
            this.setState({executeMethodCalled : true});
          }
        }
      }
    }

    autoMapEvent() {
      if (typeof(this.state.tableList[0]) !== 'undefined') {
        var nodeList = [];
        global.directionMode = 'left';
        this.state.tableList[0].rows.forEach((sourceRow, sourceIndex) => {
          this.state.tableList[1].rows.forEach((targetRow, targetIndex) => {
            if(sourceRow.column.toLowerCase() === targetRow.column.toLowerCase() &&
              !sourceRow.column.toLowerCase().startsWith('_app') &&
              !sourceRow.column.startsWith('APP_') &&
              this.checkNodeIsExist(sourceRow.columnId)) {
              var line = { startX:5, startY: 0, stopX: 0, stopY: 0 };
              var lineNode = { node: { source: sourceRow.columnId, target: targetRow.columnId }, line: line, strokeColor: 'green'}
              var sourceElement = getSourceElement(sourceRow.columnId);
              var targetElement = getTargetElement(targetRow.columnId);
              if (typeof(sourceElement) !== 'undefined' && typeof(targetElement) !== 'undefined') {
                  lineNode.line = this.modifyDrawLine(sourceElement, targetElement, line);
              }
              nodeList.push(lineNode);
              global.columnMapperNodeList = [...this.state.nodeList, ...nodeList];
              let  action = this.state.processFlowAction;
              if(action === DataOpsIdentifierConstants.VARMAPPING_OP){
                MappingRules.AddDefaultMappingRuleForTargetColumn(targetRow.column, this.state.tableList);
              }
              return true;
            }
          })
        })

        global.columnMapperNodeList = [...this.state.nodeList, ...nodeList];
        this.setState({ nodeList : global.columnMapperNodeList });
        DrawMapppingLine.populateColumn();
        let  action = this.state.processFlowAction;
        if(!this.state.executeMethodCalled && (action === DataOpsIdentifierConstants.APPEND_QUICK_OP)){
          this.props.raiseExecute();
          this.setState({executeMethodCalled : true});
        }
        //populateSelectionCheckBox(true);
      }
    }

    autoUnMapEvent() {
      global.columnMapperNodeList = [];
      resetGridRows();
      this.setState({ nodeList : []});
      const leftMapColumnControlIndex = 26;
      const rightMapColumnControlIndex = 27;
      const leftMapLengthControlIndex = 28;
      const rightMapLengthControlIndex = 29;
      const leftMapTypeControlIndex = 30;
      const rightMapTypeControlIndex = 31;
      const variableMappingGird =53;
      this.saveGlobalValue(leftMapColumnControlIndex,"");
      this.saveGlobalValue(leftMapTypeControlIndex,"");
      this.saveGlobalValue(leftMapLengthControlIndex,"");
      this.saveGlobalValue(rightMapColumnControlIndex,"");
      this.saveGlobalValue(rightMapTypeControlIndex,"");
      this.saveGlobalValue(rightMapLengthControlIndex,"");
      this.saveGlobalValue(variableMappingGird,"");
      //populateSelectionCheckBox(false);
    }

    checkNodeIsExist(source) {
      var status = true;
      this.state.nodeList.forEach((node, index) => {
        if (node.node.source === source) {
          status = false;
          return true;
        }
      });

      return status;
    }

    render() {
        return (
          <div key={idSelectorPostFix}>
            <svg style={this.state.style}>
            <defs>
              <marker id={`end-arrow${idSelectorPostFix}`} viewBox='0 -5 10 10' refX='6' orient='auto' markerWidth='3' markerHeight='3'>
                <path d='M0,-5L10,0L0,5' fill={`${this.state.strokeColor}`} />
              </marker>
              <marker id={`start-arrow${idSelectorPostFix}`} viewBox='0 -5 10 10' refX='6' orient='auto' markerWidth='3' markerHeight='3'>
                <path d='M10,-5L0,0L10,5' fill={`${this.state.strokeColor}`} />
              </marker>
              <marker id={`end-arrow-mapped${idSelectorPostFix}`} viewBox='0 -5 10 10' refX='6' orient='auto' markerWidth='3' markerHeight='3'>
                <path d='M0,-5L10,0L0,5' fill='green' />
              </marker>
              <marker id={`start-arrow-mapped${idSelectorPostFix}`} viewBox='0 -5 10 10' refX='6' orient='auto' markerWidth='3' markerHeight='3'>
                <path d='M10,-5L0,0L10,5' fill='green' />
              </marker>
            </defs>
              <PathLine
                  className='link'
                  markerStart={`url(#start-arrow${idSelectorPostFix})`}
                  markerEnd={`url(#end-arrow${idSelectorPostFix})`}
                  points={[{x: this.state.line.startX, y: this.state.line.startY}, {x: this.state.line.stopX, y: this.state.line.stopY}]}
                  stroke={`${this.state.strokeColor}`}
                  strokeWidth="3"
                  fill="none"
                  r={0}
                  />
              { this.state.nodeList.map(this.displayMappedNodeList)}
            </svg>
            <div className="align-center m_t_5">
              {!this.props.constructiveOperation && <Button className="k-button k-primary" primary={true} onClick={this.autoMapEvent}>Auto Map</Button>}
              <Button className="m-left k-primary" primary={true} onClick={this.autoUnMapEvent}>Unmap All</Button>
            </div>
          </div>
        );
    }
}
export default template;
