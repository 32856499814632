import React from 'react';

export const GbyandObyCheckBoxCell = ({ tableManager, value, data, column, colIndex, rowIndex }) => {
    if (typeof(tableManager) === 'undefined') {
      return (null)
    } else {
      return (
          <div className='rgt-cell-inner' style={{display: 'flex', alignItems: 'center', overflow: 'hidden'}}>
              <GbyandObyCheckBoxCellClass dataItem={data} field={column.field} value={typeof(data[column.field]) !== 'undefined' ? data[column.field]: value} tableManager={tableManager}/>
          </div>
      )
    }
}

class GbyandObyCheckBoxCellClass extends React.Component {

    handleChange(e) {
        this.props.dataItem[this.props.field] = e.target.checked;

        if (this.props.field === 'gby') {
            this.props.dataItem["oby"] =  this.props.dataItem[this.props.field] === true ? true : this.props.dataItem["oby"];
        }

        this.props.tableManager.rowsApi.setRows(this.props.tableManager.rowsApi.rows)
    }
    render() {
        let checked = false;
        let disabled = false;
        if (this.props.dataItem[this.props.field]) {
          checked = true;
        }

        if (this.props.field === "oby" && this.props.dataItem["gby"] === true) {
            disabled = true;
        }

        let colId = "columnId";
        return (
            <div className="text-center width-full">
                { this.props.dataItem[this.props.field] !== '' &&
                    <div>
                        <input type="checkbox" className="am-checkbox" id={`checkbox-${this.props.field}-${this.props.dataItem[colId]}`} onChange={this.handleChange.bind(this)} checked={checked} disabled={disabled}/>
                        <label className="am-checkbox-label" htmlFor={`checkbox-${this.props.field}-${this.props.dataItem[colId]}`}></label>
                    </div>
                }
            </div>
        );
    }
}
