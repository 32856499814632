import React from "react";
import { TabStrip, TabStripTab  } from './../common/Layout';
import { Button } from './../common/Button/StandardButton';
import { Input } from './../common/InputBox/StandardInput';
import { ProcessOptionGrid } from "../common/gridOperation";
import { StandardDropDown, StandardAutoComplete, DataOpsNewColumnNameCell, DataOpsCopyFromFlagCell, DataOpsProcessResultTypeCell, DataOpsProcessVariableCell, DataOpsProcessOptionCell, DataOpsProcessSetOrderCell, DataOpsProcessDecimalOptionCell, DataOpsProcessVariableLikeCell } from "../common";

function DataOpsLayoutTemplate() {
  let dupkeychecked = false;
  global.processBlockControlValues.forEach((control, index) => {
    if (control.controlId === 7) {
      dupkeychecked = global.processBlockControlValues[index].controlValue !== "" ? JSON.parse(global.processBlockControlValues[index].controlValue): false;
    }
  });
  var handleDupKeyCheckBoxChange = (event) => {
    global.processBlockControlValues.forEach((control, index) => {
      if (control.controlId === 7) {
        global.processBlockControlValues[index].controlValue = event.target.checked;
        dupkeychecked = event.target.checked;
        this.setState({...this.state.tableList});
        return true;

      }
    });
  }
  return (
    <div className="process-option data-ops">
      <TabStrip selected={this.state.tabSelected} onSelect={this.handleSelectTabPanel}>
        <TabStripTab title="Display and GBY">
          <div className="field_group">
            <div className="filter-area am-form-inline col-3 m-0">
              <div className="am-form-field">
                <input
                  type="checkbox"
                  id="data-ops-no-dupkey"
                  className="am-checkbox"
                  checked={dupkeychecked}
                  onChange={handleDupKeyCheckBoxChange}
                />
                <label className="am-checkbox-label" htmlFor={'data-ops-no-dupkey'}>No Dupkey (GBY)</label>
              </div>
            </div>
            {/* <div>
              <label className="am-form-field">
                <span>Find:</span>
                <input className="am-textbox m_l_5" placeholder="Search data" onChange={this.handleSeachDataChangeEvent} />
              </label>
            </div> */}
            <div className="height_fixed_tabs">
              <ProcessOptionGrid tableList={this.state.tableList} />
            </div>
          </div>
        </TabStripTab>
        <TabStripTab title="Process">
          {typeof(this.state.tableList[0]) !== 'undefined' &&
            <DataOpsProcess table={this.state.tableList[0]} />
          }
        </TabStripTab>
      </TabStrip>
    </div>
  );
};


class DataOpsProcess extends React.Component {
  constructor(props) {
    super(props);
    var scale = [""];
    var variablesList = [];
    let ruleType = '';
    let scaleVar = this.getGlobalValue(20);
    if (typeof(props.table.rows) !== "undefined") {
      props.table.rows.forEach((row) => {
        scale = [...scale, row.column];
        variablesList = [...variablesList, row.column+'-'+row.type+' ('+row.length+')']
        if(scaleVar !== ''){
          if(scaleVar.toLowerCase() === row.column.toLowerCase()){
            if(row.type === 'DATE' || row.type === 'DATETIME') {
              if(typeof(row.columnFormat) !== "undefined" && row.columnFormat !== null && row.columnFormat.toLowerCase() === 'datetime'){
                ruleType = "DATETIME";
              }else{
                ruleType = "DATE";
              }
            }else{
              ruleType = row.type;
            }
          }
        }
      });
    }
    let rowData = [{"rowId": "", "columnId":"", "selected": false, "id": "---", "variable": "", "resultType": "", "variableLike": "", "processOption": "", "decimalOption": "", "preference": "", "newVariable":"", "multi":"", "qc": "", "copyFromFlag":"", "processOptionVar":"" }];
    let updateData = this.getGlobalValue(23);
    if(updateData && updateData !== ""){
      let data = JSON.parse(updateData);
      if(data.length > 0){
        rowData = JSON.parse(updateData);
      }
    }

    this.state = {
      sourceCondition:this.getGlobalValue(18),
      targetCondition:this.getGlobalValue(19),
      scaleVariable:this.getGlobalValue(20),
      scaleDirection:this.getGlobalValue(21),
      scaleRule:this.getGlobalValue(22),
      direction: ["Forward(>)", "Backward(<)", "Forward(>=)", "Backward(<=)", "All"],
      rule:[],
      //rule: ["First", "Last", "First(Immediate)", "Last(Immediate)", "All"],
      //dateRule: ["First Date", "Last Date", "First Date(Immediate)", "Last Date(Immediate)", "All"],
      //dateTimeRule: ["First DateTime", "Last DateTime", "First DateTime(Immediate)", "Last DateTime(Immediate)", "First Date(Immediate)", "Last Date(Immediate)", "All"],
      scale: scale,
      ruleType: ruleType,
      condition: {
        directionAllForCharRule:["First", "Last", "All"],
        directionAllForNumRule:["First", "Last", "All"],
        directionAllForDateRule:["First Date", "Last Date", "All"],
        directionAllForDateTimeRule: ["First DateTime", "Last DateTime", "All"],
        directionForwardOrBackwardForNumRule:["First", "Last", "First(Immediate)", "Last(Immediate)", "All"],
        directionForwardOrBackwardForCharRule:["First", "Last", "All"],
        directionForwardOrBackwardForDateRule:["First Date", "Last Date", "First Date(Immediate)", "Last Date(Immediate)", "All"],
        directionForwardOrBackwardForDateTimeRule: ["First DateTime", "Last DateTime",  "First DateTime(Immediate)","Last DateTime(Immediate)", "All"],
      },
      reset:{
        charRule:["First", "Last", "All"],
        numRule:["First", "Last", "All"],
        dateRule: ["First Date", "Last Date", "All"],
        dateTimeRule: ["First DateTime", "Last DateTime", "All"],
      },
      tableList:[{
        id: 'data-ops-process-grid',
        rows: rowData,
        resize: true,
        filterable: false,
        selectedField: "selected",
        columnProperty: [
          { field: "rowId", show: false },
          { field: "columnId", show: false },
          { field: "id", width: "80px" },
          { field: "preference", width: "120px", cell: DataOpsProcessSetOrderCell },
          { field: "variable", width: "250px", cell: DataOpsProcessVariableCell },
          { field: "resultType", width: "100px", cell: DataOpsProcessResultTypeCell },
          { field: "variableLike", width: "100px", cell: DataOpsProcessVariableLikeCell, show:false },
          { field: "processOption", width: "115px", cell: DataOpsProcessOptionCell },
          { field: "decimalOption", width: "120px", cell: DataOpsProcessDecimalOptionCell },
          { field: "newVariable", width: "120px", cell: DataOpsNewColumnNameCell },
          { field: "multi", width: "120px", cell: DataOpsNewColumnNameCell },
          { field: "qc", width: "120px", cell: DataOpsNewColumnNameCell },
          { field: "copyFromFlag", width: "120px", cell: DataOpsCopyFromFlagCell },
          { field: "processOptionVar", width: "150px", cell: DataOpsNewColumnNameCell }
        ]
      }],
    };

    this.handleTimeScaleDirectionChange = this.handleTimeScaleDirectionChange.bind(this);
    this.handleAddRowClick = this.handleAddRowClick.bind(this);
    this.handleRemoveRowClick = this.handleRemoveRowClick.bind(this);
    this.handleTimeScaleVariableChange = this.handleTimeScaleVariableChange.bind(this);
    this.getGlobalValue = this.getGlobalValue.bind(this);
    this.saveGlobalValue = this.saveGlobalValue.bind(this);
    this.handleSourceConditionChange = this.handleSourceConditionChange.bind(this);
    this.handleTargetConditionChange = this.handleTargetConditionChange.bind(this);
    this.handleTimeScaleRuleChange = this.handleTimeScaleRuleChange.bind(this);
    this.populateTimeScaleRuleDropDownList = this.populateTimeScaleRuleDropDownList.bind(this);
  }

  componentDidMount(){
    this.populateTimeScaleRuleDropDownList();
    this.handleTimeScaleVariableChange({value : this.state.scaleVariable})
  }

  getGlobalValue(controlIndex){
    let controlValue = '';
    global.processBlockControlValues.forEach((control, index) => {
      if (control.controlId === controlIndex) {
        controlValue = global.processBlockControlValues[index].controlValue;

      }
    });
    return controlValue;
  }

  saveGlobalValue(controlIndex, value){
    global.processBlockControlValues.forEach((control, index) => {
      if (control.controlId === controlIndex) {
        global.processBlockControlValues[index].controlValue = value;
      }
    });
  }

  handleTimeScaleDirectionChange(event) {
    if(event.value === null){
      this.saveGlobalValue(21,"");
      this.setState({ rule: [], scaleDirection:"", scaleRule: "" });
    }else{
      this.saveGlobalValue(21,event.value);
      this.setState({ scaleDirection:event.value }, ()=>{
        this.populateTimeScaleRuleDropDownList();
      });
    }
  }

  handleSourceConditionChange(event) {
    this.saveGlobalValue(18,event.target.value);
    this.setState({ sourceCondition:event.target.value });
  }

  handleTargetConditionChange(event) {
    this.saveGlobalValue(19,event.target.value);
    this.setState({ targetCondition:event.target.value });
  }

  handleTimeScaleRuleChange(event) {
    this.saveGlobalValue(22,event.value);
    this.setState({ scaleRule:event.value });
  }

  populateTimeScaleRuleDropDownList(){
    let rule = [];
    switch(this.state.ruleType){
      case "CHAR":
      rule = this.state.scaleDirection === "All" ? this.state.condition.directionAllForCharRule :
      (this.state.scaleDirection === "Forward(>)" ||  this.state.scaleDirection === "Backward(<)") ? this.state.condition.directionForwardOrBackwardForCharRule :
      (this.state.scaleDirection !== "" && this.state.scaleDirection !== null) ? this.state.reset.charRule : [];
      break;
      case "DATE":
      rule = this.state.scaleDirection === "All" ? this.state.condition.directionAllForDateRule :
      (this.state.scaleDirection === "Forward(>)" ||  this.state.scaleDirection === "Backward(<)") ? this.state.condition.directionForwardOrBackwardForDateRule :
      (this.state.scaleDirection !== "" && this.state.scaleDirection !== null) ? this.state.reset.dateRule : [];
      break;
      case "DATETIME":
      rule = this.state.scaleDirection === "All" ? this.state.condition.directionAllForDateTimeRule :
      (this.state.scaleDirection === "Forward(>)" ||  this.state.scaleDirection === "Backward(<)") ? this.state.condition.directionForwardOrBackwardForDateTimeRule :
      (this.state.scaleDirection !== "" && this.state.scaleDirection !== null) ? this.state.reset.dateTimeRule : [];
      break;
      case "NUM":
      rule = this.state.scaleDirection === "All" ? this.state.condition.directionAllForNumRule :
      (this.state.scaleDirection === "Forward(>)" ||  this.state.scaleDirection === "Backward(<)") ? this.state.condition.directionForwardOrBackwardForNumRule :
      (this.state.scaleDirection !== "" && this.state.scaleDirection !== null) ? this.state.reset.numRule : [];
      break;
      default:
      break;
    }
    let scaleRule = this.state.scaleRule;
    if(rule.indexOf(this.state.scaleRule) === -1){
      scaleRule = "";
    }

    if (this.state.scaleVariable === '' && this.state.scaleDirection === 'All') {
      rule.push('All');
      scaleRule = 'All';
      this.handleTimeScaleRuleChange({value: 'All'})
    }
    this.setState({ rule: rule, scaleRule:scaleRule });
  }

  handleTimeScaleVariableChange(event) {
    let ruleType = '';

    if (typeof(event.value) == 'undefined' || event.value === null) {
      event.value = '';
    }
    this.props.table.rows.forEach((row) => {
      if (row.column.toLowerCase() === event.value.toLowerCase()) {
        if(row.type === 'DATE' || row.type === 'DATETIME') {
          if(typeof(row.columnFormat) !== "undefined" && row.columnFormat !== null && row.columnFormat.toLowerCase() === 'datetime'){
            ruleType = "DATETIME";
          }else{
            ruleType = "DATE";
          }
        }else{
          ruleType = row.type;
        }
      }
    });
    this.saveGlobalValue(20,event.value);
    this.setState({ ruleType: ruleType, scaleVariable:event.value  },()=>{
      if (event.value === '') {
        this.handleTimeScaleDirectionChange({value: "All"})
      } else {
        this.populateTimeScaleRuleDropDownList();
      }
    });

  }

  handleAddRowClick(event) {
    var tableList = this.state.tableList;
    var newRowId = 0;
    if (typeof(tableList[0].rows[0]) !== 'undefined' && tableList[0].rows[0]["rowId"] === '') {
        tableList[0].rows.pop();
    }

    if (tableList[0].rows.length > 0) {
      newRowId = tableList[0].rows[tableList[0].rows.length - 1].rowId + 1
    }


    var addRow = { "rowId": newRowId,"columnId":newRowId ,"selected":false, "id": "DOP"+newRowId, "variable": "", "resultType": "", "variableLike": "", "processOption": "", "decimalOption": "", "preference": "", "newVariable":"", "multi":"", "qc": "", "copyFromFlag":"", "processOptionVar":"" };

    tableList[0].rows.push(addRow);

    this.setState({ tableList: tableList });
  }

  handleRemoveRowClick(event) {
    let tableList = this.state.tableList;
    const { rows } = this.state.tableList[0];
    if(typeof(rows) !== 'undefined') {
      tableList = this.removeTableRowsRecursiveElements(tableList)
    }

    this.setState({ tableList: tableList });
    this.saveGlobalValue(23,JSON.stringify(this.state.tableList[0].rows));
  }

  removeTableRowsRecursiveElements(tableList) {
    var index;
    for (index = 0; index < tableList[0].rows.length; index++) {
      if (tableList[0].rows[index].selected === true) {
        tableList[0].rows.splice(index, 1);
      }
    }

    for (index = 0; index < tableList[0].rows.length; index++) {
      if (tableList[0].rows[index].selected === true) {
        tableList = this.removeTableRowsRecursiveElements(tableList);
      }
    }

    return tableList;
  }

  render() {
      return (
        <div className="data-ops-process">
          <div className="field_group">
            <div className="filter-area am-form-inline col-12 m-0">
              <label className="am-form-field">
                <span>Source Condition:</span>
                <Input value={this.state.sourceCondition} onChange={this.handleSourceConditionChange} />
              </label>
              <label className="am-form-field">
                <span>Target Condition:</span>
                <Input value={this.state.targetCondition} onChange={this.handleTargetConditionChange}/>
              </label>
              <div className="row clearfix">
                <label className="am-form-field col-4 m_b_0">
                  <span>Time Scale Variable:</span>
                  <StandardAutoComplete data={this.state.scale} onChange={this.handleTimeScaleVariableChange} value={this.state.scaleVariable}/>
                </label>
                <label className="am-form-field col-4 m_b_0">
                  <span>Time Scale Direction:</span>
                  <StandardDropDown data={this.state.direction} onChange={this.handleTimeScaleDirectionChange} value={this.state.scaleDirection}/>
                </label>
                <label className="am-form-field col-4 m_b_0">
                  <span><b>Time Scale Rule:</b></span>
                  <StandardDropDown data={this.state.rule} onChange={this.handleTimeScaleRuleChange} value={this.state.scaleRule}/>
                </label>
              </div>
            </div>
          </div>
          <div className="field_group">
            <div className="clearfix am-form-field">
              <Button primary={true} onClick={this.handleAddRowClick}>Add</Button>
              <Button primary={true} className="m-left" onClick={this.handleRemoveRowClick}>Delete</Button>
              <Button primary={true} className="m-left">Add All</Button>
            </div>
            <div className="grid-content">
              <div className="height_fixed_process">
                <ProcessOptionGrid tableList={this.state.tableList}  />
              </div>
            </div>
          </div>
        </div>
      );
  }
}
export default DataOpsLayoutTemplate;
