import React from 'react';
import { BasicGbyCheckBoxCell } from '../CheckBox/BasicGbyCheckBoxCell';
import { NewColumnNameCell } from '../InputBox/NewColumnNameCell';
import { Input } from './../InputBox/StandardInput';

import RichGridTable from '../../../RichGridTable';

export class Define extends React.Component {
  ProcReportDefineIndex  = 14;
  DefineColumnStatementIndex = 17;
  constructor(props) {
    super(props);
    this.state = {
      preOptions: props.preOptions,
      columnStatement : this.getGlobalValue(this.DefineColumnStatementIndex) !== "" ? this.getGlobalValue(this.DefineColumnStatementIndex) : this.getPreOptions(props.preOptions,"_proc_report_column"),
      table: props.table,
      rows : [],
      columnProperty : [
        { field: "selected", show: false },
        { field: "gby",  title: "GBY", filterable: false, sortable:false, width:"35px", cell: BasicGbyCheckBoxCell },
        { field: "defineOptions", sortable:false, cell: NewColumnNameCell },
        { field: "oby", show:false, title: "BY"},
        { field: "newSASLabel", show:false}
      ]
    }
    if(this.getGlobalValue(this.ProcReportDefineIndex) !== ""){
      this.state.rows = JSON.parse(this.getGlobalValue(this.ProcReportDefineIndex));
    }else{
      this.state.rows = [].concat(this.props.table.rows);
      this.state.rows = this.appendGridColumns(this.state.rows, {"gby" : false}, 'selected');
      this.state.rows = this.appendGridColumns(this.state.rows, {"defineOptions" : ''});

    }
    this.handleColumnStatementChange = this.handleColumnStatementChange.bind(this);
  }

  getGlobalValue(controlIndex){
    let controlValue = '';
    global.processBlockControlValues.forEach((control, index) => {
      if (control.controlId === controlIndex) {
        controlValue = global.processBlockControlValues[index].controlValue;

      }
    });
    return controlValue;
  }

  saveGlobalValue(controlIndex, value){
    global.processBlockControlValues.forEach((control, index) => {
      if (control.controlId === controlIndex) {
        global.processBlockControlValues[index].controlValue = value;
      }
    });
  }

  getPreOptions(preOptionsList,  preOptioName){
    let value ='';
    let selectedItem = preOptionsList.filter(item => item.property === preOptioName);
    if(selectedItem.length > 0){
      value= selectedItem[0].value;
    }
    this.saveGlobalValue(this.DefineColumnStatementIndex, value);
    return value;

  }

  appendGridColumns(data, newFields, position='') {
    data.forEach((row, index) => {
      if (position !== '') {
        var beforePosition = {};
        var afterPosition = {};
        for (var key in row) {
          if(key === position) {
            beforePosition = {...beforePosition, ...{ [key]:row[key]}}
          } else {
            afterPosition = {...afterPosition, ...{ [key]:row[key]}}
          }
        }
        data[index] = { ...beforePosition, ...newFields, ...afterPosition};
      } else {
        data[index] = { ...row, ...newFields};
      }
    });

    return data;
  }
  handleColumnStatementChange = (event) =>{
    this.setState({columnStatement: event.target.value});
    this.saveGlobalValue(this.DefineColumnStatementIndex, event.target.value);

  }
  render() {
      return (
        <div className="proc-report-define">
          <div className="container-fluid p_15">
            <div className="row">
              <div className="filter-area am-form-inline col-12 p-0">
                <label className="am-form-field">
                  <span className="m-r-15-i"><b>Column Statement</b></span>
                  <Input value={this.state.columnStatement} onChange={this.handleColumnStatementChange}/>
                </label>
              </div>
            </div>
          </div>

          <RichGridTable id={"define-grid-table"} rows={this.state.rows} columnProperty={this.state.columnProperty} />
        </div>
      )
  }
}
