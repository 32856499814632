import React from "react";
import { connect } from 'react-redux';
import { PropTypes } from 'prop-types';
import { createSelector } from 'reselect';
import { FileExplorer } from './common/FileExplorer';
import axios from 'axios';
import { CREATE } from '../../../restClient/types';
import restClient from '../../../restClient';
import $ from 'jquery';
import { setMessage } from '../../../actions/actionNotification';
import MessageTypeConst from '../../MessageTypeConst';

class FileViewerWrapper extends React.Component {
    dataOpsUpdateBlock = (headers, params) => restClient(CREATE, 'dataops/updatelist', params, headers);
    constructor(props)
    {
        super(props);
        this.state ={
            executeFolderPath: '',
            fileNames: [],
            tenantId:props.tenantId,
            processFlowTableId: props.processFlowTableId,
            processFlowList:props.processFlowList,
            activeProcessFlow:props.activeProcessFlow
        }
    }
    componentDidMount(){
        var params = this.getProcessBlock();
        let Tenant_ID = this.state.tenantId;
        $(".loader").show();
        axios.all([this.dataOpsUpdateBlock({'Tenant-ID': Tenant_ID}, { data: params })])
               .then(axios.spread((dataOpsBlock) => {
                 if (typeof(dataOpsBlock.data) !== 'undefined') {
                   if (typeof(dataOpsBlock.data.codeLog) !== 'undefined' && dataOpsBlock.data.codeLog !== null) {
                        this.setState({ executeFolderPath : dataOpsBlock.data.codeLog.executeFolderPath,
                            fileNames : dataOpsBlock.data.codeLog.fileNames });
                   }
                }

                if (typeof(global.selectedBlock) !== 'undefined') {
                  delete global.selectedBlock;
                }
                $(".loader").hide();
               }
            )
        ).catch(error => {
            $(".loader").hide();
            this.props.setMessage("Error occurred while fetching files.",MessageTypeConst.ERROR_MESSAGE);
          });

    }
    getProcessBlock() {
        var processBlock = [];
        if(this.state.processFlowList !== null && this.state.processFlowList.length >0 &&
            this.state.processFlowList[this.state.activeProcessFlow] !== null && typeof(this.state.processFlowList[this.state.activeProcessFlow]) !== "undefined"){
            let processFlow = this.state.processFlowList[this.state.activeProcessFlow].process;
            if(typeof(processFlow) !== 'undefined' && processFlow !== null){
                processBlock = processFlow.processBlock;
            }

        }
       // processBlock = JSON.parse(localStorage.getItem("processBlock"));
        var processFlowTableId = parseInt(this.state.processFlowTableId, 10);
        var actionBlock = {};
        processBlock.forEach((block, index) => {
          if (processFlowTableId === block.blockId) {
            actionBlock = block
            return block;
          }
        })

        if (typeof(actionBlock.blockId) === 'undefined' && typeof(global.selectedBlock) !== 'undefined') {
          actionBlock = global.selectedBlock;
        }
        return actionBlock;
      }

    render(){
        return(
            <div>
                <FileExplorer folderPath={this.state.executeFolderPath} fileNames={this.state.fileNames} tenantId={this.state.tenantId}/>
            </div>
        );
    }
}

const mapStateToProps = createSelector(
    state => state.processFlow.processFlowList,
    state => state.processFlow.tenantId,
    state => state.processFlow.processFlowTable,
    state => state.processFlow.processFlowTableId,
    state => state.processFlow.activeProcessFlow,
    (processFlowList, tenantId, processFlowTable, processFlowTableId, activeProcessFlow) => ({
      processFlowList,
      tenantId,
      processFlowTable,
      processFlowTableId,
      activeProcessFlow
    })
  );

  const mapActionsToProps = {
    setMessage:setMessage
  }

  FileViewerWrapper.propTypes = {
    processFlowList: PropTypes.array,
    tenantId: PropTypes.string,
    processFlowTable: PropTypes.string,
    activeProcessFlow: PropTypes.number
  }

  export default connect(mapStateToProps, mapActionsToProps)(FileViewerWrapper);
