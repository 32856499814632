import React from 'react';
import char_icon from '../../../../../assets/img/icons/char_icon.png';
import num_icon from '../../../../../assets/img/icons/num_icon.png';
import date_icon from '../../../../../assets/img/icons/date_icon.png';

export const NewColumnSpanCell = ({ tableManager, value, data, column, colIndex, rowIndex }) => {
    if (typeof(tableManager) === 'undefined') {
      return (null)
    } else {
      let typeCheck = data['type'] !== null ? data['type'].toLowerCase() : '';
      let aplayClass = "";
      if(typeCheck === "char"){
          aplayClass = <img src={char_icon} alt="C" className="p_b_2 p-r-5" />
      }else if(typeCheck === "num"){
          aplayClass = <img src={num_icon} alt="N" className="p_b_2 p-r-5" />
      }else if(typeCheck === "date" || typeCheck === "datetime"){
          aplayClass = <img src={date_icon} alt="D" className="p_b_2 p-r-5" />
      }
      return (
          <div className='rgt-cell-inner' style={{display: 'flex', alignItems: 'center', overflow: 'hidden'}}>
              {aplayClass} <span className="rgt-text-truncate">{value}</span>
          </div>
      )
    }
}
