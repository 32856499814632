import React from 'react';
import { DropDownList } from './StandardDropDownList';
import cartesian from '../../../../../../src/assets/img/join/CartesianProduct.png';
import inner from '../../../../../../src/assets/img/join/innerjoin.jpg';
import fullJoin1 from '../../../../../../src/assets/img/join/fulljoin1.jpg';
import fullJoin2 from '../../../../../../src/assets/img/join/fulljoin2.jpg';
import leftJoin1 from'../../../../../../src/assets/img/join/LeftModifiedJoin.jpg';
import leftJoin2 from '../../../../../../src/assets/img/join/leftjoin2.jpg';
import rightJoin1 from '../../../../../../src/assets/img/join/RightModifiedJoin.jpg';
import rightJoin2 from '../../../../../../src/assets/img/join/rightjoin2.jpg';

export class JoinTypeDropDown extends React.Component {
  itemRender = (li, itemProps) => {
        let itemChildren = <span >
        {itemProps.dataItem === "CARTESIAN Join (A x B)" &&
        <img src={cartesian} alt="" style={{height:"70px",width:"70px"}}/>  }
        {itemProps.dataItem === "Left Join (If A)" &&
        <img src={leftJoin1} alt="" style={{height:"70px",width:"70px"}}/>  }
        {itemProps.dataItem === "Left Join (If A + NOT B)" &&
        <img src={leftJoin2} alt="" style={{height:"70px",width:"70px"}}/>  }
        {itemProps.dataItem === "Right Join (If B)" &&
        <img src={rightJoin1} alt="" style={{height:"70px",width:"70px"}}/>  }
        {itemProps.dataItem === "Right Join (If B + NOT A)" &&
        <img src={rightJoin2} alt="" style={{height:"70px",width:"70px"}}/>  }
        {itemProps.dataItem === "Full Outer Join (IF A and B EXCEPT INTERSECT)" &&
        <img src={fullJoin1} alt="" style={{height:"70px",width:"70px"}}/>  }
        {itemProps.dataItem === "Full Outer Join (IF A or B)" &&
        <img src={fullJoin2} alt="" style={{height:"70px",width:"70px"}}/>  }
        {itemProps.dataItem === "Inner Join (IF A and B)" &&
        <img src={inner} alt="" style={{height:"70px",width:"70px"}}/>  }
        &nbsp;&nbsp;{li.props.children}
        </span>

        return React.cloneElement(li, li.props, itemChildren);
  }
  handleChange(e) {
    this.props.onChange({
      data: this.props.data,
      syntheticEvent: e.syntheticEvent,
      value: e.target.value
    });
  }
  render() {
      return (
        <DropDownList className="m-0 join-type"
         onChange={this.handleChange.bind(this)} data={this.props.data}
         value={this.props.value}/>
      );
    }
}
