import React from 'react';
import { NewColumnNameCell } from '../InputBox/NewColumnNameCell';
import RichGridTable from '../../../RichGridTable';
import DataOpsIdentifierConstants from '../../../../DataOpsIdentifierConstants';

export class Compute extends React.Component {
  ProcReportComputeTableIndex  = 18;
  constructor(props) {
    super(props);
    this.state = {
      table: props.table,
      processFlowAction : props.processFlowAction,
      rows : [],
      columnProperty : [
        { field: "selected", show: false },
        { field: "computeStatement", sortable:false, cell: NewColumnNameCell, title:(props.processFlowAction === DataOpsIdentifierConstants.R_Report_OP ? "Custom Statement" : "Compute Statement")},
        { field: "gby", show:false},
        { field: "oby", show:false},
        { field: "newSASLabel", show:false}
      ]
    }
    if(this.getGlobalValue(this.ProcReportComputeTableIndex) !== ""){
      this.state.rows = JSON.parse(this.getGlobalValue(this.ProcReportComputeTableIndex));
    }else{
      this.state.rows = [].concat(this.props.table.rows);
      this.state.rows = this.appendGridColumns(this.state.rows, {"computeStatement" : ''});
    }
  }

  getGlobalValue(controlIndex){
    let controlValue = '';
    global.processBlockControlValues.forEach((control, index) => {
      if (control.controlId === controlIndex) {
        controlValue = global.processBlockControlValues[index].controlValue;
  
      }
    });
    return controlValue;
  }
  
  saveGlobalValue(controlIndex, value){
    global.processBlockControlValues.forEach((control, index) => {
      if (control.controlId === controlIndex) {
        global.processBlockControlValues[index].controlValue = value;
      }
    });
  }

  appendGridColumns(data, newFields, position='') {
    data.forEach((row, index) => {
      if (position !== '') {
        var beforePosition = {};
        var afterPosition = {};
        for (var key in row) {
          if(key === position) {
            beforePosition = {...beforePosition, ...{ [key]:row[key]}}
          } else {
            afterPosition = {...afterPosition, ...{ [key]:row[key]}}
          }
        }
        data[index] = { ...beforePosition, ...newFields, ...afterPosition};
      } else {
        data[index] = { ...row, ...newFields};
      }
    });

    return data;
  }
  render() {
      return (
        <div className="proc-report-compute">
          <div className="height_fixed_break">
            <RichGridTable id={"compute-grid-table"} rows={this.state.rows} columnProperty={this.state.columnProperty} />
          </div>
        </div>
      )
  }
}
