import React from 'react';
import { Button } from './StandardButton';
import { GET } from '../../../../../restClient/types';
import restClient from '../../../../../restClient';
import axios from 'axios';
import $ from 'jquery';
import { connect } from 'react-redux';
import { createSelector } from 'reselect';
import MessageTypeConst from "../../../../MessageTypeConst";
import { setMessage } from '../../../../../actions/actionNotification';

export const BlockPropertiesFetchDetails = ({ tableManager, value, data, column, colIndex, rowIndex }) => {
    if (typeof(tableManager) === 'undefined') {
      return (null)
    } else {
      return (
          <div className='text-center width-full rgt-cell-inner' style={{alignItems: 'center', overflow: 'hidden'}}>
              <BlockPropertiesFetchDetailsClass dataItem={data} field={column.field} value={typeof(data[column.field]) !== 'undefined' ? data[column.field]: value} tableManager={tableManager}/>
          </div>
      )
    }
}
class BaseBlockPropertiesFetchDetailsClass extends React.Component {

    constructor(props) {
        super(props);
        this.fetchDetails = this.fetchDetails.bind(this);
    }

    handleChange(e) {
        this.props.dataItem[this.props.field] = e.target.value;
        this.props.tableManager.rowsApi.setRows(this.props.tableManager.rowsApi.rows)
    }

    fetchDetails(e){
        e.preventDefault();
        e.stopPropagation();
        let that = this;
        if(this.props.dataItem['schemaName'] === null || typeof(this.props.dataItem['schemaName']) === "undefined"){
            that.props.setMessage(`${that.props.dataItem['relation']} block study does not exist.`, MessageTypeConst.WARNING_MESSAGE);
            return;
        }
        $(".loader").show();
        axios.all([
            restClient(GET, 'processblock/relatedblockdetails?processId='+this.props.dataItem['processFlowId']+'&blockId='+this.props.dataItem['processBlockId'], {}, {'Tenant-ID': this.props.dataItem['schemaName']})
          ])
          .then(axios.spread((response) => {
              if (typeof(response.data) !== 'undefined' && response.data != null) {
                  if(response.data.messageType !== null && typeof(response.data.messageType) !== "undefined" && response.data.messageType !==  MessageTypeConst.SUCCESS_MESSAGE){
                    that.props.setMessage(response.data.message, response.data.messageType);
                  }else{
                    this.props.tableManager.rowEditApi.setEditRowId(this.props.dataItem.id)
                    this.props.dataItem['flowName'] = response.data.processFlowName;
                    this.props.dataItem['groupName'] = response.data.processFlowGroupName;
                    this.props.dataItem['blockName'] = response.data.blockName;
                    this.props.dataItem[that.props.field] = '';
                    this.props.tableManager.rowsApi.setRows(this.props.tableManager.rowsApi.rows)
                    this.props.tableManager.rowEditApi.setEditRowId(null)

                    setTimeout(()=>{
                      if (document.querySelectorAll('.am-rich-grid-table[refs="properties-grid"] .rgt-container').length > 0) {
                        document.querySelectorAll('.am-rich-grid-table[refs="properties-grid"] .rgt-container .rgt-cell').forEach(rowColumn => {
                          if (rowColumn.classList.contains('last') === true) {
                            rowColumn.classList.remove('last')
                          }
                        })
                        document.querySelectorAll('.am-rich-grid-table[refs="properties-grid"] .rgt-container .rgt-cell[data-row-index="'+this.props.tableManager.rowsApi.rows.length+'"]').forEach(lastRowColumn => {
                          if (lastRowColumn.classList.contains('last') === false) {
                            lastRowColumn.classList.add('last')
                          }
                        })
                      }
                    },10)

                  }
                };
              $(".loader").hide();
            })
          ).catch(error=>{
            $(".loader").hide();
            that.props.setMessage("Error occurred while fetching block details.", MessageTypeConst.ERROR_MESSAGE);
          });
    }

    render() {
        let disabled = false;
        if((typeof(this.props.dataItem['flowName']) !== "undefined" && this.props.dataItem['flowName'] !== null && this.props.dataItem['flowName'] !== "") ||
        (typeof(this.props.dataItem['studyName']) !== "undefined" && this.props.dataItem['studyName'] !== null && this.props.dataItem['studyName'] === "")){
            disabled = true;
        }
        return (
            <>
              <Button onClick={this.fetchDetails} disabled={disabled}> Fetch Details</Button>
            </>
        );
    }
}

const mapStateToProps = createSelector(
    state => state.processFlow.tenantId,
    (tenantId) => ({
        tenantId
    })
  );

  const mapActionsToProps = {
    setMessage: setMessage
  }

const BlockPropertiesFetchDetailsClass = connect(mapStateToProps, mapActionsToProps)(BaseBlockPropertiesFetchDetailsClass);
