import React    from "react";
import { connect } from 'react-redux';
import { createSelector } from 'reselect';
import cloneDeep from 'lodash/cloneDeep';
import axios from 'axios';
import $ from 'jquery';
import template from "./AccessControl.jsx";
import MessageTypeConst from "./../../../MessageTypeConst";
import {setMessage} from './../../../../actions/actionNotification';
import restClient from './../../../../restClient';
import { GET, CREATE } from './../../../../restClient/types';
import { addUserAndGroupList, manageUserList, manageUserGroupPermission } from './../../../../actions/actionUserPreference';
import AccessControlConstants from './../../../AccessControlConstants';

class AccessControl extends React.Component {
  constructor(props) {
    super(props);

    let objectId = 0;
    let objectType = 0;
    if (typeof(props.node.projectId) !== 'undefined' && typeof(props.node.cssClassName) !== 'undefined' && props.node.cssClassName === 'manage-study') {
      objectId = props.node.projectId;
      objectType = AccessControlConstants.PROJECT;
    } else if (typeof(props.node.cssClassName) !== 'undefined' && props.node.cssClassName === 'manage-study-property' && typeof(props.node.study) !== 'undefined') {
      objectId = props.node.study.studyId;
      objectType = AccessControlConstants.STUDY;
    }

    this.state = {
      selected: 0,
      node: props.node,
      onClose: props.onClose,
      groupAccessList: [],
      userAccessList: [],
      selectedItem:{
        type: '',
        id: 0,
        title: ''
      },
      isRootPermission: typeof(props.node.cssClassName) !== 'undefined' && props.node.cssClassName === 'manage-project' ? true : false,
      objectId: objectId,
      objectType: objectType
    }

    this.handleApplyClick = this.handleApplyClick.bind(this);
  }

  fetchUserList = (headers, params) => restClient(GET, 'users/list', params, headers);

  fetchGroupList = (headers, params) => restClient(GET, 'groups/list', params, headers);

  manageUserAndGroupPermissionList = (headers, params) => restClient(CREATE, 'permissions/add', params, headers);

  manageUserAndGroupAccessControlList = (headers, params) => restClient(CREATE, 'accessControl/add', params, headers);

  fetchUserAndGroupPermissionList = (headers, params) => restClient(GET, 'permissions/usersGroups', params, headers);

  fetchUserAndGroupAccessControl = (headers, params) => restClient(CREATE, 'accessControl/list', params, headers);

  handleSelect = (e) => {
    this.setState({selected: e.selected, selectedItem: {
      type: '',
      id: 0,
      title: ''
    }})
  }

  handleItemSelection = (event, type, id, title) => {
    let selectedItem = this.state.selectedItem;
    if (id > 0) {

      if (selectedItem.type === type && selectedItem.id === id) {
        selectedItem.type = '';
        selectedItem.id = 0;
        selectedItem.title = '';
      } else {
        selectedItem.type = type;
        selectedItem.id = id;
        selectedItem.title = title;
      }

      this.setState({selectedItem: selectedItem})
    }
  }

  handlePermissionSelection = (event, type) => {
    let {selectedItem, groupAccessList, userAccessList} = this.state;
    if (selectedItem.type === 'group') {
      if (groupAccessList.filter(groupAccess => groupAccess.groupId === selectedItem.id).length === 0) {
        let groupAccess = {groupId: selectedItem.id, readOnly: 0, writeOnly: 0, delegateOnly: 0, isChanged: 1}
        if (this.state.isRootPermission) {
          groupAccess = {groupId: selectedItem.id, createProject: 0, manageUserGroup: 0, isChanged: 1}
        }
        groupAccessList.push(groupAccess)
      }

      groupAccessList.filter(groupAccess => groupAccess.groupId === selectedItem.id).map(groupAccess => {
        groupAccess[type] = event.target.checked === true ? 1: 0
        groupAccess.isChanged = 1;

        if (groupAccess[type] === 1) {
          if (!this.state.isRootPermission) {
            if (type === 'writeOnly' || type === 'delegateOnly') {
              groupAccess.readOnly = 1;

              if (type === 'delegateOnly') {
                  groupAccess.writeOnly = 1;
              }
            }
          }
        } else {
          if (type === 'readOnly') {
            groupAccess[type] = groupAccess.writeOnly == 1 || groupAccess.delegateOnly == 1 ? 1: groupAccess[type]
          } else if (type === 'writeOnly') {
            groupAccess[type] = groupAccess.delegateOnly == 1 ? 1: groupAccess[type]
          }
        }

        return groupAccess
      });

      /*if (event.target.checked === false && false) {
        if (this.state.isRootPermission) {
          groupAccessList = groupAccessList.filter(groupAccess => !(groupAccess.groupId === selectedItem.id && groupAccess.createProject === 0 && groupAccess.manageUserGroup === 0))
        } else {
          groupAccessList = groupAccessList.filter(groupAccess => !(groupAccess.groupId === selectedItem.id && groupAccess.readOnly === 0 && groupAccess.writeOnly === 0 && groupAccess.delegateOnly === 0))
        }

      }*/
    } else if (selectedItem.type === 'user') {
      if (userAccessList.filter(userAccess => userAccess.userId === selectedItem.id).length === 0) {
        let userAccess = {userId: selectedItem.id, readOnly: 0, writeOnly: 0, delegateOnly: 0, isChanged: 1}
        if (this.state.isRootPermission) {
          userAccess = {userId: selectedItem.id, createProject: 0, manageUserGroup: 0, isChanged: 1}
        }
        userAccessList.push(userAccess)
      }

      userAccessList.filter(userAccess => userAccess.userId === selectedItem.id).map(userAccess => {
        userAccess[type] = event.target.checked === true ? 1: 0
        userAccess.isChanged = 1;

        if (userAccess[type] === 1) {
          if (!this.state.isRootPermission) {
            if (type === 'writeOnly' || type === 'delegateOnly') {
              userAccess.readOnly = 1;

              if (type === 'delegateOnly') {
                  userAccess.writeOnly = 1;
              }
            }
          }
        } else {
          if (type === 'readOnly') {
            userAccess[type] = userAccess.writeOnly == 1 || userAccess.delegateOnly == 1 ? 1: userAccess[type]
          } else if (type === 'writeOnly') {
            userAccess[type] = userAccess.delegateOnly == 1 ? 1: userAccess[type]
          }
        }

        return userAccess
      })

      /*if (event.target.checked === false && false) {
        if (this.state.isRootPermission) {
          userAccessList = userAccessList.filter(userAccess => !(userAccess.userId === selectedItem.id && userAccess.createProject === 0 && userAccess.manageUserGroup === 0))
        } else {
          userAccessList = userAccessList.filter(userAccess => !(userAccess.userId === selectedItem.id && userAccess.readOnly === 0 && userAccess.writeOnly === 0 && userAccess.delegateOnly === 0))
        }
      }*/
    }

    this.setState({groupAccessList: groupAccessList, userAccessList: userAccessList})
  }

  getPermissionValue = (type) => {
    let {selectedItem, groupAccessList, userAccessList} = this.state;
    let status = false;
    if (selectedItem.id > 0) {
      if (selectedItem.type === 'group') {
          let groupAccess = groupAccessList.filter(groupAccess => groupAccess.groupId === selectedItem.id)

          if (groupAccess.length > 0 && typeof(groupAccess[0][type]) !== 'undefined') {
            status = groupAccess[0][type] === 1 ? true: false
          }
      } else if (selectedItem.type === 'user') {
        let userAccess = userAccessList.filter(userAccess => userAccess.userId === selectedItem.id)

        if (userAccess.length > 0 && typeof(userAccess[0][type]) !== 'undefined') {
          status = userAccess[0][type] === 1 ? true: false
        }
      }
    }

    return status;
  }

  componentDidMount() {
    let params = {
      objectId: this.state.objectId,
      objectType: this.state.objectType
    };
    if (this.props.userList.length === 0 && this.props.groupList.length === 0) {
      $(".loader").show();
      axios.all([this.fetchUserList({'Tenant-ID': "master"}, {}), this.fetchGroupList({'Tenant-ID': "master"}, {}), (this.state.isRootPermission === true ? this.fetchUserAndGroupPermissionList({'Tenant-ID': "master"}, {}) : this.fetchUserAndGroupAccessControl({'Tenant-ID': "master"}, {data: params}))])
          .then(axios.spread((responseUser, responseGroup, responseUserAndGroupPermission) => {
            if (typeof(responseUser.data.message) === 'undefined') {
              if (typeof(responseUser.data) !== 'undefined' && typeof(responseGroup.data) !== 'undefined') {
                this.props.addUserAndGroupList({userList: responseUser.data, groupList: responseGroup.data})
              }
            } else {
                this.props.setMessage(responseUser.data.message, responseUser.data.messageType);
            }

            if (typeof(responseUserAndGroupPermission.data) !== 'undefined') {
              if (responseUserAndGroupPermission.data.messageType === MessageTypeConst.SUCCESS_MESSAGE) {
                let userAccessList = [];
                let groupAccessList = [];
                let userAndGroupList = [];
                if (this.state.isRootPermission === true) {
                  userAndGroupList = this.getUserAndGroupPermissionList(responseUserAndGroupPermission.data.groups, responseUserAndGroupPermission.data.users);
                } else {
                  userAndGroupList = this.getUserAndGroupAccessControlList(responseUserAndGroupPermission.data.accessors);
                }

                userAccessList = userAndGroupList.userAccessList
                groupAccessList = userAndGroupList.groupAccessList
                this.setState({userAccessList: userAccessList, groupAccessList: groupAccessList})
              } else {
                  this.props.setMessage(responseUserAndGroupPermission.data.message, MessageTypeConst.ERROR_MESSAGE);
              }
            }
            $(".loader").hide();
          })
        ).catch(error => {
          $(".loader").hide();
          this.props.setMessage("Error occurred while fetching user/group list", MessageTypeConst.ERROR_MESSAGE);
      });
    } else {
      $(".loader").show();
      axios.all([(this.state.isRootPermission === true ? this.fetchUserAndGroupPermissionList({'Tenant-ID': "master"}, {}) : this.fetchUserAndGroupAccessControl({'Tenant-ID': "master"}, {data: params}))])
          .then(axios.spread((responseUserAndGroupPermission) => {
            if (typeof(responseUserAndGroupPermission.data) !== 'undefined') {
              if (responseUserAndGroupPermission.data.messageType === MessageTypeConst.SUCCESS_MESSAGE) {
                let userAccessList = [];
                let groupAccessList = [];
                let userAndGroupList = [];
                if (this.state.isRootPermission === true) {
                  userAndGroupList = this.getUserAndGroupPermissionList(responseUserAndGroupPermission.data.groups, responseUserAndGroupPermission.data.users);
                } else {
                  userAndGroupList = this.getUserAndGroupAccessControlList(responseUserAndGroupPermission.data.accessors);
                }

                userAccessList = userAndGroupList.userAccessList
                groupAccessList = userAndGroupList.groupAccessList
                this.setState({userAccessList: userAccessList, groupAccessList: groupAccessList})
              } else {
                this.props.setMessage(responseUserAndGroupPermission.data.message, MessageTypeConst.ERROR_MESSAGE);
              }
            }
            $(".loader").hide();
          })
        ).catch(error => {
          $(".loader").hide();
          this.props.setMessage("Error occurred while fetching user/group "+(this.state.isRootPermission === true ? "permission" : "access control"), MessageTypeConst.ERROR_MESSAGE);
      });
    }
  }

  getUserAndGroupPermissionList = (groups, users) => {
    let userAccessList = [];
    let groupAccessList = [];

    users.forEach(user => {
      if (user.permissions.length > 0) {
        userAccessList.push({
          userId: user.user.userId,
          createProject: user.permissions.filter(permission => permission.name === AccessControlConstants.CREATE_PROJECT).length > 0 ? 1: 0,
          manageUserGroup: user.permissions.filter(permission => permission.name === AccessControlConstants.MANAGE_USERS_GROUPS).length > 0 ? 1: 0,
          isChanged: 0
        })
      }
    })

    groups.forEach(group => {
      if (group.permissions.length > 0) {
        groupAccessList.push({
          groupId: group.group.groupId,
          createProject: group.permissions.filter(permission => permission.name === AccessControlConstants.CREATE_PROJECT).length > 0 ? 1: 0,
          manageUserGroup: group.permissions.filter(permission => permission.name === AccessControlConstants.MANAGE_USERS_GROUPS).length > 0 ? 1: 0,
          isChanged: 0
        })
      }
    })

    return {
      userAccessList: userAccessList,
      groupAccessList: groupAccessList
    }
  }

  getUserAndGroupAccessControlList = (accessors) => {
    let userAccessList = [];
    let groupAccessList = [];
    accessors.forEach(accessor => {
      if (accessor.accessorType === AccessControlConstants.GROUP) {
        groupAccessList.push({
          groupId: accessor.accessorId,
          readOnly: accessor.enableActions.indexOf(AccessControlConstants.VIEW) > -1 ? 1: 0,
          writeOnly: accessor.enableActions.indexOf(AccessControlConstants.WRITE) > -1 ? 1: 0,
          delegateOnly: accessor.enableActions.indexOf(AccessControlConstants.DELEGATE) > -1 ? 1: 0,
          isChanged: 0,
          inheritFromParent: accessor.inheritFromParent
        })
      } else {
        userAccessList.push({
          userId: accessor.accessorId,
          readOnly: accessor.enableActions.indexOf(AccessControlConstants.VIEW) > -1 ? 1: 0,
          writeOnly: accessor.enableActions.indexOf(AccessControlConstants.WRITE) > -1 ? 1: 0,
          delegateOnly: accessor.enableActions.indexOf(AccessControlConstants.DELEGATE) > -1 ? 1: 0,
          isChanged: 0,
          inheritFromParent: accessor.inheritFromParent
        })
      }
    })
    return {
      userAccessList: userAccessList,
      groupAccessList: groupAccessList
    }
  }

  handleApplyClick = (event) => {
    if (this.state.isRootPermission === true) {
      let params = [];
      [...this.state.groupAccessList, ...this.state.userAccessList].forEach(access => {
        let permissionsList = [];

        for (var key in access) {
          let permission = '';
          if (access[key] === 1 && (key === 'createProject' || key === 'manageUserGroup')) {
            permission = this.props.allPermission.find(permission => permission.name === (key === 'createProject' ? AccessControlConstants.CREATE_PROJECT : AccessControlConstants.MANAGE_USERS_GROUPS));
            if (typeof(permission) !== 'undefined') {
              permissionsList.push(permission.permissionId);
            }
          }
        }
        let param = {permissionsList: permissionsList};

        if (typeof(access.groupId) !== 'undefined') {
          param = Object.assign(param, {groupId: access.groupId})
        } else {
          param = Object.assign(param, {userId: access.userId})
        }
        params.push(param)
      })

      if (params.length > 0) {
        $(".loader").show();
        axios.all([this.manageUserAndGroupPermissionList({'Tenant-ID': "master"}, {data: params})])
              .then(axios.spread((response) => {
                if (typeof(response.data) !== 'undefined') {
                  this.props.manageUserGroupPermission({userAccessList: this.state.userAccessList, groupAccessList: this.state.groupAccessList});
                  this.props.setMessage(response.data.message, response.data.messageType === 1 ? MessageTypeConst.SUCCESS_MESSAGE: MessageTypeConst.ERROR_MESSAGE);
                  this.state.onClose(event);
                }
                $(".loader").hide();
              })
            ).catch(error => {
              $(".loader").hide();
              this.props.setMessage("Error occurred while assign permission to user/group list", MessageTypeConst.ERROR_MESSAGE);
          });
      }
    } else {
      let params = {
        accessors: [],
        objectId: this.state.objectId,
        objectType: this.state.objectType
      };
      [...this.state.groupAccessList, ...this.state.userAccessList].filter(access => access.isChanged === 1).forEach(access => {
        let enableActions = [];
        let disableActions = [];
        if (access.readOnly === 1) {
          enableActions.push(AccessControlConstants.VIEW)
        } else {
          disableActions.push(AccessControlConstants.VIEW)
        }

        if (access.writeOnly === 1) {
          enableActions.push(AccessControlConstants.WRITE)
        } else {
          disableActions.push(AccessControlConstants.WRITE)
        }

        if (access.delegateOnly === 1) {
          enableActions.push(AccessControlConstants.DELEGATE)
        } else {
          disableActions.push(AccessControlConstants.DELEGATE)
        }

        params.accessors.push({
            accessorId: typeof(access.userId) !== 'undefined' ? access.userId: access.groupId,
            accessorType: typeof(access.userId) !== 'undefined' ? AccessControlConstants.USER: AccessControlConstants.GROUP,
            enableActions: enableActions,
            disableActions: disableActions,
            inheritFromParent: access.inheritFromParent
        })
      })

      if (params.accessors.length > 0) {
        $(".loader").show();
        axios.all([this.manageUserAndGroupAccessControlList({'Tenant-ID': "master"}, {data: params})])
              .then(axios.spread((response) => {
                if (typeof(response.data) !== 'undefined') {
                  this.props.setMessage(response.data.message, response.data.messageType === 1 ? MessageTypeConst.SUCCESS_MESSAGE: MessageTypeConst.ERROR_MESSAGE);
                  this.state.onClose(event);
                }
                $(".loader").hide();
              })
            ).catch(error => {
              $(".loader").hide();
              this.props.setMessage("Error occurred while assign access control to user/group list", MessageTypeConst.ERROR_MESSAGE);
          });
      } else {
        this.props.setMessage('No change found in access control', MessageTypeConst.WARNING_MESSAGE);
        this.state.onClose(event);
      }
    }
  }

  render() {
    return template.call(this);
  }
}

const mapStateToProps = createSelector(
  state => state.processFlow.tenantId,
  state => state.userPreference.userList,
  state => state.userPreference.groupList,
  state => state.userPreference.allPermission,
  state => state.userPreference.userGroupPermission,
  state => state.login.token_detail,
  state => state.userPreference.userPermission,
  (tenantId, userList, groupList, allPermission, userGroupPermission, token_detail, userPermission) => ({
    tenantId,
    userList,
    groupList,
    allPermission,
    userGroupPermission,
    token_detail,
    userPermission
  })
);

const mapActionsToProps = {
  setMessage: setMessage,
  addUserAndGroupList: addUserAndGroupList,
  manageUserGroupPermission: manageUserGroupPermission
}

export default connect(mapStateToProps, mapActionsToProps)(AccessControl);
