import React    from "react";
import { connect } from 'react-redux';
import { createSelector } from 'reselect';
import { Input } from './../DataOperation/common/InputBox/StandardInput';
import { setMessage } from './../../../actions/actionNotification';
import VersionLimitType from '../../VersionLimitType';

class VersionStudyProperties extends React.Component {

    constructor(props){
        super(props);
        this.state = {
            versionSettings : this.props.versionSettings
        }
    }

    getVersionSettings = () =>{
        return this.state.versionSettings;
    }

    handleChangeEvent = (event, type) => {
        let versionSettings = this.state.versionSettings;
        let value = parseFloat(event.target.value);
        switch(type){
            case VersionLimitType.SOURCE_TABLE_VERSION_LIMIT:
            versionSettings.sourceDataLimit = value;
            break;
            case VersionLimitType.META_TABLE_VERSION_LIMIT:
            versionSettings.metaDataLimit = value;
            break;
            case VersionLimitType.BLOCK_VERSION_LIMIT:
            versionSettings.blockDataLimit = value;
            break;
            case VersionLimitType.BLOCK_DEF_VERSION_LIMIT:
            versionSettings.blockDefLimit = value;
            break;
            default:
            break;
        }
        this.setState({versionSettings : versionSettings});
    }

    render (){
        return (
            <div className="version-study-content">
                    <div className="am-form-line-left row">
                      <label className="pro-label col-sm-6"><span >Source Table Version Limit </span></label>
                      <Input type="number" min="1" className='text-box-display col-sm-4' name="txtSourceDataLimit" id="txtSourceDataLimit"
                      value={this.state.versionSettings.sourceDataLimit} onChange = {(event) => { this.handleChangeEvent(event, VersionLimitType.SOURCE_TABLE_VERSION_LIMIT)}}/>
                    </div>
                    <div className="am-form-line-left row">
                        <label className="pro-label col-sm-6"><span >Meta Table Version Limit </span></label>
                        <Input type="number" min="1" className="text-box-display col-sm-4" name="txtMetaDataLimit" id="txtMetaDataLimit"
                        value={this.state.versionSettings.metaDataLimit} onChange = {(event) => { this.handleChangeEvent(event, VersionLimitType.META_TABLE_VERSION_LIMIT)}}/>
                    </div>
                    <div className="am-form-line-left row">
                        <label className="pro-label col-sm-6"><span >Block Data Version Limit</span></label>
                        <Input type="number" min="1" className="text-box-display col-sm-4" name="txtBlockDataVersionLimit" id="txtBlockDataVersionLimit"
                        value={this.state.versionSettings.blockDataLimit} onChange = {(event) => { this.handleChangeEvent(event, VersionLimitType.BLOCK_VERSION_LIMIT)}}/>
                    </div>
                    <div className="am-form-line-left row">
                        <label className="pro-label col-sm-6"><span >Block Definition Version Limit </span></label>
                        <Input type="number" min="1" className="text-box-display col-sm-4" name="txtBlockDefVersionLimit" id="txtBlockDefVersionLimit"
                        value={this.state.versionSettings.blockDefLimit} onChange = {(event) => { this.handleChangeEvent(event, VersionLimitType.BLOCK_DEF_VERSION_LIMIT)}}/>
                    </div>
            </div>
        );
    }
}

const mapStateToProps = createSelector(
    state => state.processFlow.tenantId,
    (tenantId) => ({
      tenantId,
    })
  );

const mapActionsToProps = {
setMessage: setMessage
}

export default connect(mapStateToProps, mapActionsToProps, null, { withRef: true })(VersionStudyProperties);
