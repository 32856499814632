import React from 'react';
import TextEditor from '../../../TextEditor';
import DataOpsIdentifierConstants from "../../../../DataOpsIdentifierConstants";

export class StyleTemplate extends React.Component {
  ProcReportStyleTemplateIndex  = 20;
  
  constructor(props) {
    super(props);
    this.getDefaultValueForTemplate = this.getDefaultValueForTemplate.bind(this);
    this.handleOnChange = this.handleOnChange.bind(this);
    this.state = {
      preOptions: props.preOptions,
      dataOps: props.dataOps,
      template: this.getDefaultValueForTemplate(props.preOptions)
    }
    this.handleOnChange = this.handleOnChange.bind(this);
  }
  getDefaultValueForTemplate(preOptions) {
    let value = '';
    if (this.getGlobalValue(this.ProcReportStyleTemplateIndex) !== "") {
      if (!Array.isArray(this.getGlobalValue(this.ProcReportStyleTemplateIndex))) {
          value = JSON.parse(this.getGlobalValue(this.ProcReportStyleTemplateIndex)).join('\n');
      } else {
          value = this.getGlobalValue(this.ProcReportStyleTemplateIndex).join('\n')
      }
    } else {
      if (this.state.dataOps !== DataOpsIdentifierConstants.R_Report_OP) {
        value = this.getPreOptions(preOptions,"_proc_template");
      } else {
        value = this.getPreOptions(preOptions,"_r_template");
      }
    }
    return value;
  }
  getPreOptions(preOptionsList,  preOptioName){
    let value ='';
    let selectedItem = preOptionsList.filter(item => item.property === preOptioName);
    if(selectedItem.length > 0){
      value= selectedItem[0].value;
    }
    //to form array based on new line character and removing empty entry
    this.saveGlobalValue(this.ProcReportStyleTemplateIndex, JSON.stringify(value.split("\n").filter(a=>a)));
    return value;
  }
  handleOnChange = (code) =>{
    this.setState({template :code});
    //to form array based on new line character and removing empty entry
    this.saveGlobalValue(this.ProcReportStyleTemplateIndex, JSON.stringify(code.split("\n").filter(a=>a)));
  }

  getGlobalValue(controlIndex){
    let controlValue = '';
    global.processBlockControlValues.forEach((control, index) => {
      if (control.controlId === controlIndex) {
        controlValue = global.processBlockControlValues[index].controlValue;
  
      }
    });
    return controlValue;
  }
  
  saveGlobalValue(controlIndex, value){
    global.processBlockControlValues.forEach((control, index) => {
      if (control.controlId === controlIndex) {
        global.processBlockControlValues[index].controlValue = value;
      }
    });
  }
  render() {
      return (
        <div className="proc-report-style-template row">
          <div className="height_fixed_style_template">
            <div className="container-fluid p_15">
                <div className="row">
                  {/* <label className="am-form-field"> */}
                    <span className="col-12"><b>Template Options:</b></span>
                    <div className="col-12">
                      <TextEditor options={{ lineNumbers: false}}  code={this.state.template} onChange= {this.handleOnChange}/>
                    </div>
                  {/* </label> */}
              </div>
            </div>
          </div>
        </div>
      )
  }
}
