import { REFRESH_DATA_STATUS, CONSTRUCTIVE_OPERATION_STATUS, UPDATE_REFERENCE_POINS, ENABLE_EXECUTE_BUTTON, SET_PROCESS_ENGINELIST, SET_PROCESS_ENGINE } from '../actions/actionsTypes';

const INITIAL_DATA = {
  refreshDataStatus: false,
  constructiveOperationStatus: false,
  referencePoints:[],
  referencePointsName:[],
  disableExecuteButton:true,
  engineList:[],
  selectedEngine: {}
};

export default function dataOperationReducer(state=INITIAL_DATA, action) {
  switch (action.type) {
    case REFRESH_DATA_STATUS:
      return {
        ...state,
        refreshDataStatus: action.payload
    }
    case CONSTRUCTIVE_OPERATION_STATUS:
      return {
        ...state,
        constructiveOperationStatus: action.payload
    }
    case UPDATE_REFERENCE_POINS:
    return {
      ...state,
      referencePoints : action.payload.referencePoints,
      referencePointsName: action.payload.referencePointsName
    }
    case ENABLE_EXECUTE_BUTTON:
    return {
      ...state,
      disableExecuteButton:!action.payload
    }
    case SET_PROCESS_ENGINELIST:
    return {
      ...state,
      engineList: action.payload,
      selectedEngine: action.payload.find(engine => engine.isDefault === true)
    }
    case SET_PROCESS_ENGINE:
      let engineList = state.engineList

      if (typeof(action.payload.value) === 'undefined') {
        engineList = []
      }
      return {
        ...state,
        selectedEngine: action.payload,
        engineList: engineList
      }
    default:
      return state;
  }
}
