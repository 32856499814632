import React from 'react';
import { DropDownList } from './StandardDropDownList';

export const SubRoleCell = ({ tableManager, value, data, column, colIndex, rowIndex }) => {
    if (typeof(tableManager) === 'undefined') {
      return (null)
    } else {
      return (
          <div className='rgt-cell-inner' style={{display: 'flex', alignItems: 'center', overflow: 'hidden'}}>
              <SubRoleCellClass dataItem={data} field={column.field} value={typeof(data[column.field]) !== 'undefined' ? data[column.field]: value} column={column} tableManager={tableManager}/>
          </div>
      )
    }
}

class SubRoleCellClass extends React.Component {

    handleChange(e) {
        this.props.dataItem[this.props.field] = e.target.value;
        this.props.tableManager.rowsApi.setRows(this.props.tableManager.rowsApi.rows)
      }
      render() {
          let disabled= true;
          let data = [];
          let role = this.props.dataItem['column_role'];
          if(role === 'Qualifier'){
            data=global.qualifiesSubRoles;
            disabled = false;
          }else if(role === 'Timing'){
            data=global.timingSubRoles;
            disabled = false;
          }
          return (
              <>
                <DropDownList className="width-full" onChange={this.handleChange.bind(this)} data={data}
                value={this.props.dataItem[this.props.field]} disabled={disabled}/>
              </>
          );
      }
}
