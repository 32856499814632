import React from 'react';
import { Input } from './StandardInput';

export const ProcessFlowCell = ({ tableManager, value, data, column, colIndex, rowIndex }) => {
    if (typeof(tableManager) === 'undefined') {
      return (null)
    } else {
      return (
          <div className='rgt-cell-inner' style={{display: 'flex', alignItems: 'center', overflow: 'hidden'}}>
              <ProcessFlowCellClass dataItem={data} field={column.field} value={typeof(data[column.field]) !== 'undefined' ? data[column.field]: value} column={column} tableManager={tableManager}/>
          </div>
      )
    }
}


class ProcessFlowCellClass extends React.Component {

    handleChange(e) {
      this.props.dataItem[this.props.field] = e.target.value;
      let targetTable = this.props.dataItem['targetTable'];

      if (typeof(this.props.dataItem.operationDetail) !== 'undefined' && typeof(this.props.dataItem.operationDetail.performedOperation) !== 'undefined' && typeof(this.props.dataItem.operationDetail.parent_targetTable) !== 'undefined' && (this.props.dataItem.operationDetail.performedOperation === 'before_pivot' || this.props.dataItem.operationDetail.performedOperation === 'before_single_pivot')) {
          targetTable = this.props.dataItem.operationDetail.parent_targetTable;
      }

      let group = global.targetTableProcessFlow.filter(group => group.targetTable === targetTable);

      if (group.length > 0 && group[0].title !== this.props.dataItem[this.props.field]) {
        global.targetTableProcessFlow = global.targetTableProcessFlow.filter(targetGroup => !(targetGroup.targetTable === targetTable && targetGroup.title === group[0].title));
      }

      this.props.tableManager.rowsApi.setRows(this.props.tableManager.rowsApi.rows)
    }
    render() {
        let processFlowTitle = this.props.dataItem[this.props.field];
        let readOnlyTitle = true;

        let targetTable = this.props.dataItem['targetTable'];

        if (typeof(this.props.dataItem.operationDetail) !== 'undefined' && typeof(this.props.dataItem.operationDetail.performedOperation) !== 'undefined' && typeof(this.props.dataItem.operationDetail.parent_targetTable) !== 'undefined' && (this.props.dataItem.operationDetail.performedOperation === 'before_pivot' || this.props.dataItem.operationDetail.performedOperation === 'before_single_pivot')) {
            targetTable = this.props.dataItem.operationDetail.parent_targetTable;
        }

//typeof(this.props.dataItem['operationDetail']) !== 'undefined' && typeof(this.props.dataItem['operationDetail'].carryForwardColumnList) !== 'undefined' &&
        if (typeof(this.props.dataItem['operation']) !== 'undefined' && this.props.dataItem['operation'] === 'Apply Process Flow') {
          readOnlyTitle = false;
        } else if(!(this.props.dataItem['operation'] === 'TableOps(LengthAdj)')) {
          if (typeof(global.targetTableProcessFlow) === 'undefined' || this.props.dataItem['rowId'] === 0) {
            global.targetTableProcessFlow = [];
          }

          let group = global.targetTableProcessFlow.filter(group => group.targetTable === targetTable);

          if (group.length === 0){
            global.targetTableProcessFlow.push({ title: processFlowTitle, targetTable: targetTable, jobId: this.props.dataItem['jobId']});
            readOnlyTitle = false;
          } else if (group.length > 0 ){
            processFlowTitle = group[0].title;

            if (group[0].jobId === this.props.dataItem['jobId']) {
              readOnlyTitle = false;
            }
          }

          this.props.dataItem[this.props.field] = processFlowTitle;
        }

        return (
            <>
              <Input className="width-full" onChange={this.handleChange.bind(this)} value={processFlowTitle} length={40} disabled={readOnlyTitle}/>
            </>
        );
    }
}
