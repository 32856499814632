export const GET = 'GET';
export const GET_ALL = 'GET_ALL';
export const GET_LIST = 'GET_LIST';
export const GET_ONE = 'GET_ONE';
export const GET_MANY = 'GET_MANY';
export const GET_MANY_REFERENCE = 'GET_MANY_REFERENCE';
export const CREATE = 'CREATE';
export const UPDATE = 'UPDATE';
export const DELETE = 'DELETE';
export const POST_PARAM_BODY = 'POST_PARAM_BODY';
export const POST_FILE_REQUEST = 'POST_FILE_REQUEST';
export const GET_ALL_WITH_APPEND = 'GET_ALL_WITH_APPEND';
export const GET_ALL_FROM_BODY_PARAM = 'GET_ALL_FROM_BODY_PARAM';
