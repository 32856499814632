import React from 'react';
import { Input } from './StandardInput';

export const MultipleSourceColumnNameCell = ({ tableManager, value, data, column, colIndex, rowIndex }) => {
    if (typeof(tableManager) === 'undefined') {
      return (null)
    } else {
      return (
          <div className='rgt-cell-inner' style={{display: 'flex', alignItems: 'center', overflow: 'hidden'}}>
              <MultipleSourceColumnNameCellClass dataItem={data} field={column.field} value={typeof(data[column.field]) !== 'undefined' ? data[column.field]: value} column={column} tableManager={tableManager}/>
          </div>
      )
    }
}

class MultipleSourceColumnNameCellClass extends React.Component {

    handleChange(e) {
      this.props.dataItem[this.props.field] = e.target.value;
      this.props.tableManager.rowsApi.setRows(this.props.tableManager.rowsApi.rows)
    }

    render() {
      let columnList = [];

      if (typeof(this.props.dataItem[this.props.field]) !== 'undefined' && this.props.dataItem[this.props.field] !== null) {
        const sourceColumnList = JSON.parse(this.props.dataItem[this.props.field]);
        sourceColumnList.forEach(sourceColumn => {
          columnList.push({oldTitle: sourceColumn.replaceColumn.oldColumn !== '' ? (sourceColumn.replaceColumn.oldColumn + '-'+ sourceColumn.replaceColumn.oldColumnDetail.type+' ('+sourceColumn.replaceColumn.oldColumnDetail.length+')') : '', title: sourceColumn.replaceColumn.newColumn + '-'+ sourceColumn.replaceColumn.newColumnDetail.type+' ('+sourceColumn.replaceColumn.newColumnDetail.length+')'})
        })
      }
        return (
            <>
              <ul className="variable-list">
              {columnList.map((column, key) => {
                  return (
                    <li key={key} className="variable-title"><span>{column.title}</span>{column.oldTitle !== '' ? (<span className="old-title">{column.oldTitle}</span>) : (null)}</li>
                  )
              })}
              </ul>
            </>
        );
    }
}
