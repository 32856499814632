import React from 'react';
import RichGridTable from '../../../RichGridTable';
import { Button } from './../Button/StandardButton';
import { NewColumnNameCell } from '../InputBox/NewColumnNameCell';

export class AnalysisGrid extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      tableList:[{
        id: 'reporting-ops-analysis-grid',
        rows: [{"rowId": 0,"columnId": 0, "selected": false, "order":0, "label" : "", "pictureFormat" : "", "expression" : ""}],
        resize: true,
        filterable: false,
        disableDefaultSortTable: false,
        selectedField: "selected",
        columnProperty: [
          { field: "rowId", show: false },
          { field: "columnId", show: false },
          { field: "order", show: false },
          { field: "selected", width: '23px', orderIndex: 1 },
          { field: "label", title: "Label", sortable:false, width: "230px", cell: NewColumnNameCell, orderIndex: 2 },
          { field: "pictureFormat", title: "Pattern", sortable:false, width: "230px", cell: NewColumnNameCell, orderIndex: 3 },
          { field: "expression", title: "Statistics", sortable:false, width: "230px", cell: NewColumnNameCell, orderIndex: 4 }
        ],
      }]
    }
    if(this.getGlobalValue(87) !== ""){
      let rowData = JSON.parse(this.getGlobalValue(87));
      if(rowData.length > 0){
        this.state.tableList[0].rows = rowData;
      }
    }
	  this.handleAddRowClick = this.handleAddRowClick.bind(this);
    this.handleRemoveRowClick = this.handleRemoveRowClick.bind(this);
    this.handleItemUpEvent = this.handleItemUpEvent.bind(this);
    this.handleItemDownEvent = this.handleItemDownEvent.bind(this);
  }
  getGlobalValue(controlIndex){
    let controlValue = '';
    global.processBlockControlValues.forEach((control, index) => {
      if (control.controlId === controlIndex) {
        controlValue = global.processBlockControlValues[index].controlValue;

      }
    });
    return controlValue;
  }
  handleAddRowClick(event) {
    var  tableList = this.state.tableList;
    var newRowId = 0;
    var orderId= 0;
    if (typeof(tableList[0].rows[0]) !== 'undefined' && tableList[0].rows[0]["rowId"] === '') {
        tableList[0].rows.pop();
    }

    if (tableList[0].rows.length > 0) {
      let maxRowId = Math.max.apply(Math, tableList[0].rows.map(function(o) { return o.rowId; }))
      newRowId = maxRowId + 1;
      let maxOrderId = Math.max.apply(Math, tableList[0].rows.map(function(o) { return o.order; }))
      orderId = maxOrderId + 1;
    }


    var addRow = { "rowId": newRowId, "columnId" : newRowId,"selected":false, "order": orderId, "label" : "", "pictureFormat" : "", "expression" : ""};

    tableList[0].rows.push(addRow);

    this.setState({ tableList: tableList });
    this.saveGlobalValue(87, JSON.stringify(tableList[0].rows));
  }

  saveGlobalValue(controlIndex, value){
    global.processBlockControlValues.forEach((control, index) => {
      if (control.controlId === controlIndex) {
        global.processBlockControlValues[index].controlValue = value;
      }
    });
  }

  handleRemoveRowClick(event) {
    let tableList = this.state.tableList;
    const { rows } = this.state.tableList[0];
    if(typeof(rows) !== 'undefined') {
      tableList = this.removeTableRowsRecursiveElements(tableList)
    }
    tableList[0].rows.forEach((row,index)=>{
      rows[index].order=index;
    })
    this.setState({ tableList: tableList });
    this.saveGlobalValue(87, JSON.stringify(this.state.tableList[0].rows));
  }

  removeTableRowsRecursiveElements(tableList) {
    var index;
    for (index = 0; index < tableList[0].rows.length; index++) {
      if (tableList[0].rows[index].selected === true) {
        tableList[0].rows.splice(index, 1);
      }
    }

    for (index = 0; index < tableList[0].rows.length; index++) {
      if (tableList[0].rows[index].selected === true) {
        tableList = this.removeTableRowsRecursiveElements(tableList);
      }
    }

    return tableList;
  }

  handleItemUpEvent(event) {
    let tableList = this.state.tableList;
    let { rows } = tableList[0];
    rows.forEach((row, index) => {
        if (row.selected === true) {

            row.order = row.order > 1 ? row.order - 1 : 0;
            rows[index] = row;

            if (typeof(rows[index-1]) !== 'undefined') {
                rows[index-1].order = rows[index-1].order + 1;
            }
        }
    });

    rows = rows.sort((a, b) => a.order - b.order);
    var tmpRows = [].concat(rows)
    tableList[0].rows.splice(0, rows.length);
    tmpRows.forEach((row, index) => {
      tableList[0].rows.push(row);
    });
    this.saveGlobalValue(87, JSON.stringify(tableList[0].rows));
    this.setState({ tableList: tableList })
  }
  handleItemDownEvent(event) {
    let tableList = this.state.tableList;
    let { rows } = tableList[0];
    rows.forEach((row, index) => {
        if (row.selected === true) {

            row.order = row.order >= rows.length ? rows.length : row.order + 1;
            rows[index] = row;

            if (typeof(rows[index+1]) !== 'undefined') {
                rows[index+1].order = rows[index+1].order - 1;
            }
        }
    });

    rows = rows.sort((a, b) => a.order - b.order);
    var tmpRows = [].concat(rows)
    tableList[0].rows.splice(0, rows.length);
    tmpRows.forEach((row, index) => {
      tableList[0].rows.push(row);
    });
    this.saveGlobalValue(87, JSON.stringify(tableList[0].rows));
    this.setState({ tableList: tableList })
  }

  getStateDetail = () => {
    return this.state.tableList;
  }

  render() {
      return (
        <div className="analysis-grid">
          <div className="filter-area am-form-inline col-12">
            <div className="clearfix">
              <Button primary={true} onClick={this.handleAddRowClick}>Add</Button>
              <Button primary={true} className="m-left" onClick={this.handleRemoveRowClick}>Delete</Button>
              <Button primary={true} className="m-left" onClick={this.handleItemUpEvent}>Up</Button>
              <Button primary={true} className="m-left" onClick={this.handleItemDownEvent}>Down</Button>
            </div>
          </div>
          <RichGridTable {...this.state.tableList[0]} />
        </div>
      )
    }
}
