import React from 'react';

export const FlowNameCell = ({ tableManager, value, data, column, colIndex, rowIndex }) => {
    if (typeof(tableManager) === 'undefined') {
      return (null)
    } else {
      return (
          <div className='rgt-cell-inner' style={{display: 'flex', alignItems: 'center', overflow: 'hidden'}}>
              <FlowNameCellClass dataItem={data} field={column.field} value={value} tableManager={tableManager}/>
          </div>
      )
    }
}

class FlowNameCellClass extends React.Component {

    handleChange(e) {
        this.props.dataItem[this.props.field] = e.target.value;
        this.props.tableManager.rowsApi.setRows(this.props.tableManager.rowsApi.rows)
    }

    render() {
        let name = this.props.dataItem.processFlowName;
        if(this.props.field === "sourceFlowName"){
            name = this.props.dataItem.sourceFlowName;
        }
        return (
            <div className="text-center">
              {name}
           </div>
            );
    }
}
