import React from "react";
import { DropDownList } from './../common/DropDown/StandardDropDownList';
import { TabStrip, TabStripTab  } from './../common/Layout';
import { ProcessOptionGrid } from "../common/gridOperation";
import DataOpsIdentifierConstants from '../../../DataOpsIdentifierConstants';
import { RowGrid, ColumnGrid, AnalysisGrid, GroupByGrid } from '../common';
import { Input } from './../common/InputBox/StandardInput';
import { ComboBox } from './../common/DropDown/StandardComboBox';

function getTableName (processFlowList, tableName) {
  if (processFlowList.length > 0) {
    let blockFound = false;
    processFlowList.forEach(processFlow => {
      if (typeof(processFlow.process) !== 'undefined' && typeof(processFlow.process.processBlock) !== 'undefined' && processFlow.process.processBlock.length > 0 && blockFound === false) {
        let block = processFlow.process.processBlock.filter(block => block.blockTable === tableName);

        if (block.length > 0 && tableName.search(block[0].blockName) === -1) {
          tableName = block[0].blockName +" ("+tableName+")";
          blockFound = true;
        }
      }
    })
  }
  return tableName
}

function getGlobalValue(controlIndex){
  let controlValue = '';
  global.processBlockControlValues.forEach((control, index) => {
    if (control.controlId === controlIndex) {
      controlValue = global.processBlockControlValues[index].controlValue;

    }
  });
  return controlValue;
}

function saveGlobalValue(controlIndex, value){
  global.processBlockControlValues.forEach((control, index) => {
    if (control.controlId === controlIndex) {
      global.processBlockControlValues[index].controlValue = value;
    }
  });
}
function ReportingOpsLayoutTemplate() {
  if(global.updateBlock === false){
    if (typeof(this.state.tableList[1]) !== 'undefined') {
      saveGlobalValue(24,JSON.stringify(this.state.tableList[1].rows));
    }
  }
  let actionName = getGlobalValue(-1);
  //let ReportingOpsTableIndex = 3;
  let IdVariableIndex = 26;
  let BigNVariableIndex = 27;
  let ReportingTableIndex = 32;
  let BigNTableIndex = 33;
  let AnalysisVariableIndex = 54;
  let AnalysisVariableSortIndex = 55;
  let AnalysisTitleIndex = 81;
  let BlockIdentifierIndex = 89;
  let TotalIndex = 82;
  let SortTotalIndex = 83;
  //let RowTableIndex = 85;
  //let ColumnTableIndex = 86;
  //let AnalysisTableIndex = 87;
  let chkBlankLineIndex = 84;
  let leftColumnList = [];
  let leftNumColumnList = [];
  let rightColumnList = [];
  let selectedTables = getGlobalValue(-97).split(',');
  let numColumnList = [];
  let treatmentVariableIndex = 93;
  let denominatorPreWhereIndex = 94;

  let isTreatmentVariableControlFound = false;
  let isDenominatorPreWhereControlFound = false;
  global.processBlockControlValues.forEach((control, index) => {
    if (control.controlId === treatmentVariableIndex) {
      isTreatmentVariableControlFound = true;
    }
    if (control.controlId === denominatorPreWhereIndex) {
      isDenominatorPreWhereControlFound = true;
    }
  });

  if (!isTreatmentVariableControlFound) {
    global.processBlockControlValues.push({
      "controlId": treatmentVariableIndex,
      "controlType": treatmentVariableIndex,
      "controlValue": ''
    })
  }

  if (!isDenominatorPreWhereControlFound) {
    global.processBlockControlValues.push({
      "controlId": denominatorPreWhereIndex,
      "controlType": denominatorPreWhereIndex,
      "controlValue": ''
    })
  }

  saveGlobalValue(ReportingTableIndex,selectedTables[0]);
  if(selectedTables.length > 1){
    saveGlobalValue(BigNTableIndex,selectedTables[1]);
  } else if(selectedTables.length > 0){
    saveGlobalValue(BigNTableIndex,selectedTables[0]);
  }
  /*if(getGlobalValue(ReportingTableIndex) == ""){
    if(selectedTables.length > 0){
      saveGlobalValue(ReportingTableIndex,selectedTables[0]);
    }
  }if(getGlobalValue(BigNTableIndex) == ""){
    if(selectedTables.length > 1){
      saveGlobalValue(BigNTableIndex,selectedTables[1]);
    } else if(selectedTables.length > 0){
      saveGlobalValue(BigNTableIndex,selectedTables[0]);
    }
  }*/
  let reportingTableName = getGlobalValue(ReportingTableIndex);
  let bigNTableName = getGlobalValue(BigNTableIndex);
  let bigNVariableName = getGlobalValue(BigNVariableIndex);
  let idVariable = getGlobalValue(IdVariableIndex);
  let analysisTitle =  getGlobalValue(AnalysisTitleIndex);
  let blockIdentifier = getGlobalValue(BlockIdentifierIndex);
  let total = getGlobalValue(TotalIndex);
  let sortTotal = getGlobalValue(SortTotalIndex);
  let analysisVariable = getGlobalValue(AnalysisVariableIndex);
  let analysisSortVariable = getGlobalValue(AnalysisVariableSortIndex);
  let chkBlankLine = false;
  if(getGlobalValue(chkBlankLineIndex) === ""){
    saveGlobalValue(chkBlankLineIndex, chkBlankLine);
  }
  chkBlankLine = typeof(getGlobalValue(chkBlankLineIndex)) === "boolean" ? getGlobalValue(chkBlankLineIndex) : getGlobalValue(chkBlankLineIndex).toLowerCase() === "true";
  //populating left table column
  if (typeof(this.state.tableList[0]) !== 'undefined') {
    if (this.state.tableList[0].rows.length > 0) {
      this.state.tableList[0].rows.forEach((row) => {
        leftColumnList = [...leftColumnList, row.column];
        if(actionName === DataOpsIdentifierConstants.REPORTINGOPS_DESCRIPTIVE_OP){
          if(row.type === 'NUM'){
            leftNumColumnList = [...leftNumColumnList, row.column];
          }
        }else{
          leftNumColumnList = [...leftNumColumnList, row.column];
        }

        if (row.type === 'NUM') {
          numColumnList = [...numColumnList, row.column];
        }
      });
    }
  }

  //populating right table column
  if(bigNTableName === ""){
    if (typeof(this.state.tableList[1]) !== 'undefined') {
      if (this.state.tableList[1].rows.length > 0) {
        this.state.tableList[1].rows.forEach((row) => {
          rightColumnList = [...rightColumnList, row.column];
        });
      }
    }else{
      rightColumnList = [...rightColumnList];
    }
  } else {
    let rightSelectedTable = this.state.tableList.filter(item => item.id === bigNTableName);
    if(rightSelectedTable.length > 0){
      rightSelectedTable[0].rows.forEach((row) => {
        rightColumnList = [...rightColumnList, row.column];
      });
    }
  }

  var handleAnalysisSortVariableChange = (event)=>{
    analysisSortVariable = event.target.value;
    saveGlobalValue(AnalysisVariableSortIndex, analysisSortVariable);
    this.setState({...this.state.tableList});

  }

  var handleAnalysisVariableChange = (event, index = -1)=>{
    analysisVariable = event.target.value;

    if (index > -1) {
      let analysisVariableList = getGlobalValue(AnalysisVariableIndex, analysisVariable);
      analysisVariableList = analysisVariableList.split(' ');

      if (analysisVariableList.length === 1) {
        analysisVariableList = [analysisVariableList[0], '', ''];
      }

      if (typeof(analysisVariableList[index]) !== 'undefined') {
        analysisVariableList[index] = event.target.value;
        analysisVariable = analysisVariableList.join(' ')
      }
    }
    saveGlobalValue(AnalysisVariableIndex, analysisVariable);
    this.setState({...this.state.tableList});

  }

  var handleSortTotalChange = (event) => {
    sortTotal = event.target.value;
    saveGlobalValue(SortTotalIndex,sortTotal);
    this.setState({...this.state.tableList});
  }

  var handleTotalChange = (event) => {
    total = event.target.value;
    saveGlobalValue(TotalIndex,total);
    this.setState({...this.state.tableList});
  }

  var handleDenominatorPreWhereChange = (event) => {
    saveGlobalValue(denominatorPreWhereIndex, event.target.value);
    this.setState({...this.state.tableList});
  }

  var handleAnalysisTitleChange = (event) => {
    analysisTitle = event.target.value;
    saveGlobalValue(AnalysisTitleIndex,analysisTitle);
    this.setState({...this.state.tableList});
  }

  var handleBlockIdentifierChange = (event) => {
    blockIdentifier = event.target.value;
    saveGlobalValue(BlockIdentifierIndex,blockIdentifier);
    this.setState({...this.state.tableList});
  }

  var handleBugNVariableChange = (event) =>{
    bigNVariableName = event.target.value;
    let tableList = this.state.tableList;
    saveGlobalValue(BigNVariableIndex,bigNVariableName);

    if (reportingTableName !== bigNTableName && typeof(leftColumnList) !== 'undefined' && leftColumnList.indexOf(bigNVariableName) > -1) {
      tableList.map((table, index) => {
        if (index === 0) {
          table.rows.filter(row => row.column === bigNVariableName).map(row => {
            row.columnCheckbox = true;

            let columnData = [];
            let columnHase = 0;
            if (this.getGlobalValue(86) !== '') {
              columnData = JSON.parse(this.getGlobalValue(86));

              columnData.forEach(column => {
                columnHase = parseInt(column.column_hase) > columnHase ? parseInt(column.column_hase): columnHase
              })
            }

            columnData.push({...row,...{"column_hase": (columnHase+1), "column_var_sort": "", "column_title": "", "column_num": false, "column_total": "", "column_unique":"", "sort_variables":[]}});
            this.saveGlobalValue(86, JSON.stringify(columnData));
            return row
          })
        }
        return table
      })
      handleTreatmentVariableChange(event);
    }
    this.setState({...tableList});
  }

  // var handleBigNTableChange = (event) =>{
  //   bigNTableName = event.target.value;
  //   bigNVariableName = null;
  //   rightColumnList.length = 0;
  //   saveGlobalValue(BigNTableIndex, bigNTableName);
  //   saveGlobalValue(BigNVariableIndex, bigNVariableName);
  //   this.setState({...this.state.tableList});
  // }

  // var handleReportingTableChange = (event) => {
  //   reportingTableName = event.target.value ;
  //   saveGlobalValue(ReportingTableIndex, reportingTableName);
  //   this.setState({...this.state.tableList});
  // }

  var handleIdVariableChange = (event)=>{
    idVariable = event.target.value;
    saveGlobalValue(IdVariableIndex, idVariable);
    this.setState({...this.state.tableList});

  }

  var handleTreatmentVariableChange = (event)=>{
    saveGlobalValue(treatmentVariableIndex, event.target.value);
    this.setState({...this.state.tableList});
  }

  var handleGenerateBlankLineCheckBoxChange = (event) =>{
    saveGlobalValue( chkBlankLineIndex ,event.target.checked);
    chkBlankLine = event.target.checked;
    this.setState({...this.state.tableList});
  }

  return (
    <div className="process-option">
      <div className="field_group_main">
        <fieldset>
          <div className="field_group">
            <legend>Analysis</legend>
            <div className="container-fluid p_15">
              <div className="row">
                  <div className="filter-area am-form-inline col-5 m-0">
                    <label className="am-form-field m-left m-0">
                      <span className="col-2">Block ID:</span>
                      <Input value={blockIdentifier} onChange={handleBlockIdentifierChange} />
                    </label>
                  </div>
                  <div className="filter-area am-form-inline col-4 m-0">
                    <label className="am-form-field m-left">
                      <span className="col-1">Analysis Title:</span>
                      <Input value={analysisTitle} onChange={handleAnalysisTitleChange} />
                    </label>
                  </div>
                  <div className="filter-area am-form-inline col-3 m-0">
                  <div className="am-form-field m-left">
                      <input type="checkbox" id="blsblankLine" className="am-checkbox"   onChange={handleGenerateBlankLineCheckBoxChange}
                       checked={chkBlankLine} />
                      <label className="am-checkbox-label" htmlFor="blsblankLine">Insert BLANK line (at the top)</label>
                    </div>
                </div>
              </div>
            </div>
          </div>
        </fieldset>
        <div className="row">
          <div className="col-6">
            <fieldset>
              <div className="field_group Reporting_Info">
                <legend>Data</legend>
                <div className="container-fluid p_15">
                  <div className="row">
                      <div className="filter-area am-form-inline m-0 col-6">
                        <label className="am-form-field m-t-5">
                          <span>Table:</span>
                          <span>{getTableName(this.props.processFlowList, reportingTableName)}</span>
                        </label>
                      </div>
                      <div className="filter-area am-form-inline m-0 col-6">
                        <label className="am-form-field m-left m-0">
                          <span>ID Variable:</span>
                          <ComboBox className="m-0 width-no-flex-200" data={leftColumnList}  value={idVariable} onChange={handleIdVariableChange}/>
                        </label>
                      </div>
                  </div>
                  {reportingTableName !== bigNTableName &&
                    <div className="row">

                      <div className="filter-area am-form-inline m-0 col-12">
                        <label className="am-form-field">
                          <span>Treatment Variable:</span>
                          <ComboBox className="m-0 width-no-flex-200" data={leftColumnList} value={getGlobalValue(treatmentVariableIndex)} onChange={handleTreatmentVariableChange}/>
                        </label>
                      </div>
                    </div>
                  }
                  {(actionName === DataOpsIdentifierConstants.REPORTINGOPS_CATEGORICAL_TOTAL_OP || actionName !== DataOpsIdentifierConstants.REPORTINGOPS_DESCRIPTIVE_OP) &&
                    <div className="row">
                      <div className="filter-area am-form-inline m-0 col-11">
                        <label className="am-form-field m-t-5">
                          <span><b>Denominator Pre-Where:</b></span>
                          <Input value={getGlobalValue(denominatorPreWhereIndex)} onChange={handleDenominatorPreWhereChange} />
                        </label>
                      </div>
                     </div>
                  }
                </div>
              </div>
            </fieldset>
          </div>
          <div className="col-6">
            <fieldset>
              <div className="field_group Reporting_Info">
                <legend>AnalysisSet</legend>
                <div className="container-fluid p_15">
                  <div className="row">
                    <div className="filter-area am-form-inline m-0 col-5">
                      <label className="am-form-field m-t-5">
                        <span>Table:</span>
                        <span>{getTableName(this.props.processFlowList, bigNTableName)}</span>
                      </label>
                    </div>
                    <div className="filter-area am-form-inline m-0 col-7">
                      <label className="am-form-field m-left m-0">
                        <span>Treatment Variable:</span>
                        <ComboBox className="m-0 width-no-flex-200" data={rightColumnList} value={bigNVariableName} onChange={handleBugNVariableChange}/>
                      </label>
                    </div>
                  </div>
                </div>
              </div>
            </fieldset>
          </div>
        </div>
              { //need to hide Analysis Variable, Analysis Sort Variable, Analyssi Total and Analysis Sort for
                //'ReportingOps(CategoricalHierarchicalUniquePerId)' and
                // 'ReportingOps(CategoricalHierarchical)' and 'ReportingOps(Custom)'action
              (actionName !== DataOpsIdentifierConstants.REPORTINGOPS_CUSTOM_OP) &&
              <fieldset>
                <div className="field_group">
                  <legend>Analysis Variable</legend>
                  <div className="container-fluid p_15">
                    {actionName !== DataOpsIdentifierConstants.REPORTINGOPS_CATEGORICAL_TOTAL_OP &&
                      <div className="row">
                        {actionName !== DataOpsIdentifierConstants.REPORTINGOPS_CATEGORICAL_HIERARCHICAL_OP &&
                          <div className="filter-area am-form-inline m-0">
                            <label className="am-form-field">
                              <span><b>Analysis Variable:</b></span>
                              <ComboBox className="width-no-flex-200 m-0" data={leftNumColumnList} value={analysisVariable} onChange={handleAnalysisVariableChange}/>
                            </label>
                          </div>
                        }
                        {actionName === DataOpsIdentifierConstants.REPORTINGOPS_CATEGORICAL_HIERARCHICAL_OP &&
                          <>
                          <div className="filter-area am-form-inline m-0 col-4">
                            <label className="am-form-field">
                              <span><b>Analysis Variable (1):</b></span>
                              <ComboBox className="width-no-flex-200 m-0 mh" data={leftNumColumnList} value={analysisVariable.split(' ').length > 1 ? analysisVariable.split(' ')[0] : analysisVariable} onChange={(event) => handleAnalysisVariableChange(event, 0)}/>
                            </label>
                          </div>
                          <div className="filter-area am-form-inline m-0 col-4">
                            <label className="am-form-field">
                              <span><b>Analysis Variable (2):</b></span>
                              <ComboBox className="width-no-flex-200 m-0 mh" data={leftNumColumnList} value={analysisVariable.split(' ').length > 1 ? analysisVariable.split(' ')[1] : analysisVariable} onChange={(event) => handleAnalysisVariableChange(event, 1)}/>
                            </label>
                          </div>
                          <div className="filter-area am-form-inline m-0 col-4">
                            <label className="am-form-field">
                              <span><b>Analysis Variable (3):</b></span>
                              <ComboBox className="width-no-flex-200 m-0 mh" data={leftNumColumnList} value={analysisVariable.split(' ').length > 1 ? analysisVariable.split(' ')[2] : analysisVariable} onChange={(event) => handleAnalysisVariableChange(event, 2)}/>
                            </label>
                          </div>
                          </>
                        }
                        { //need to hide Analysis Sort Variable, Analyssi Total and Analysis Sort for
                          //'ReportingOps(Descriptive)' and 'ReportingOps(Custom)' action
                        (actionName !== DataOpsIdentifierConstants.REPORTINGOPS_DESCRIPTIVE_OP && actionName !== DataOpsIdentifierConstants.REPORTINGOPS_CUSTOM_OP && actionName !== DataOpsIdentifierConstants.REPORTINGOPS_CATEGORICAL_HIERARCHICAL_OP) &&
                        <div className="filter-area am-form-inline m-0">
                          <label className="am-form-field m-left">
                            <span><b>Analysis (N) Variable:</b></span>
                            <ComboBox className="width-no-flex-200 m-r-15" data={numColumnList} value={analysisSortVariable} onChange={handleAnalysisSortVariableChange}/>
                          </label>
                        </div>
                        }
                        { //need to hide Analysis Sort Variable, Analyssi Total and Analysis Sort for
                           //'ReportingOps(Descriptive)' and 'ReportingOps(Custom)' action
                           (actionName !== DataOpsIdentifierConstants.REPORTINGOPS_DESCRIPTIVE_OP && actionName !== DataOpsIdentifierConstants.REPORTINGOPS_CUSTOM_OP && actionName !== DataOpsIdentifierConstants.REPORTINGOPS_CATEGORICAL_HIERARCHICAL_OP) &&
                           <>
                            <div className="filter-area am-form-inline m-0">
                               <label className="am-form-field">
                                 <span className="m-l-5"><b>Analysis Total (Label) :</b></span>
                                 <Input value={total} onChange={handleTotalChange} />
                               </label>
                            </div>
                            <div className="filter-area am-form-inline m-0">
                              <label className="am-form-field m-left">
                                <span><b>Analysis Total (N):</b></span>
                                <Input value={sortTotal} onChange={handleSortTotalChange}/>
                              </label>
                            </div>
                          </>
                        }
                      </div>
                    }
                    {actionName === DataOpsIdentifierConstants.REPORTINGOPS_CATEGORICAL_TOTAL_OP &&
                      <div className="row">
                        <div className="filter-area am-form-inline m-0">
                           <label className="am-form-field">
                             <span className="m-l-10"><b>Analysis Total (Label) :</b></span>
                             <Input className="w-800" value={total} onChange={handleTotalChange} />
                           </label>
                        </div>
                      </div>
                    }
                  </div>
                </div>
              </fieldset>
          }
          </div>
          <div className="height_fixed_report">
            <ReportingGrid tableList={this.state.tableList} actionName={getGlobalValue(-1)} />
          </div>
        </div>
  );
};

class ReportingGrid extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
            selected: 0,
            visible: true,
            tableList: props.tableList,
            leftTableList : props.tableList.length > 0 ? [props.tableList[0]] : [],
            actionName:props.actionName
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if(JSON.stringify(nextProps) !== '') {
      this.setState({tableList: nextProps.tableList});
      this.setState({leftTableList: nextProps.tableList.length > 0 ? [nextProps.tableList[0]] : []});
      this.setState({actionName: nextProps.actionName});
    }
  }

  handleSelect = (e) => {
        this.setState({selected: e.selected})
  }

  render() {
      return this.state.tableList.length ? (
        <TabStrip selected={this.state.selected} onSelect={this.handleSelect} >
          <TabStripTab title="Reporting Ops">
            <div className="Reporting_Ops">
              <ProcessOptionGrid tableList={this.state.leftTableList} />
            </div>
          </TabStripTab>
          <TabStripTab title="BY">
            <div className="Reporting_Ops">
              <GroupByGrid table={this.state.tableList[0]} />
            </div>
          </TabStripTab>
          <TabStripTab title="Row">
            <div className="Reporting_Ops">
              <RowGrid table={this.state.tableList[0]} />
            </div>
          </TabStripTab>
          <TabStripTab title="Column">
            <div className="Reporting_Ops">
              <ColumnGrid table={this.state.tableList[0]} />
            </div>
          </TabStripTab>
          <TabStripTab title="Result Operations">
            <div className="Analysis-grid-height">
              <AnalysisGrid />
            </div>
          </TabStripTab>
        </TabStrip>
      ) : (<></>)
  }
}
export default ReportingOpsLayoutTemplate;
