import React from 'react';
import { DropDownList } from './StandardDropDownList';

export const StandardTableGridDropDownCell = ({ tableManager, value, data, column, colIndex, rowIndex }) => {
    if (typeof(tableManager) === 'undefined') {
      return (null)
    } else {
      return (
          <div className='rgt-cell-inner' style={{display: 'flex', alignItems: 'center', overflow: 'hidden'}}>
              <StandardTableGridDropDownCellClass dataItem={data} field={column.field} value={typeof(data[column.field]) !== 'undefined' ? data[column.field]: value} column={column} tableManager={tableManager}/>
          </div>
      )
    }
}

class StandardTableGridDropDownCellClass extends React.Component {
    handleChange(e) {
      this.props.dataItem[this.props.field] = e.target.value;
      this.props.tableManager.rowsApi.setRows(this.props.tableManager.rowsApi.rows)
    }

    render() {
        const data = typeof(this.props.dataItem[this.props.field+"List"]) ? this.props.dataItem[this.props.field+"List"]: [];
        const value = this.props.dataItem[this.props.field];
        return (
            <>
              <DropDownList className={`width-full${value !== '' && data.indexOf(value) === -1 ? ' error': ''}`} onChange={this.handleChange.bind(this)} data={data} value={value} />
            </>
        );
    }
}
