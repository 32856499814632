import React from 'react';
import GroupAssessment from '../Copying/GroupAssessment';
import { connect } from 'react-redux';
import { createSelector } from 'reselect';
import { Button } from './../DataOperation/common/Button/StandardButton';
import ASSESSMENT_TYPE from "./AssessmentType.js";
import CopyAssessmentStatus from './CopyAssessmentStatus';

export const FlowSyncDetailsButton = ({ tableManager, value, data, column, colIndex, rowIndex }) => {
    if (typeof(tableManager) === 'undefined') {
      return (null)
    } else {
      return (
          <div className='rgt-cell-inner' style={{display: 'flex', alignItems: 'center', overflow: 'hidden'}}>
              <FlowSyncDetailsButtonClass dataItem={data} field={column.field} value={value} tableManager={tableManager}/>
          </div>
      )
    }
}

class BaseFlowSyncDetailsButton extends React.Component {

    constructor(props) {
        let assessmentNode = {
          copyAssesmentType:0,
          studyId:0,
          projectId:0,
          groupId:0,
          schemaName:'',
          name:''
        }
        super(props);
        this.state = {
            showDetailsDialog:false,
            assessmentNode:assessmentNode
        }
        this.handleOnClick = this.handleOnClick.bind(this);
    }

    handleChange(e) {
        this.props.dataItem[this.props.field] = e.target.value;
        this.props.tableManager.rowsApi.setRows(this.props.tableManager.rowsApi.rows)
    }

    handleOnClick = (event) => {
        let assessmentNode = {
          copyAssesmentType: ASSESSMENT_TYPE.GROUP_ASSESSMENT,
          projectId:0,
          studyId: 0,
          groupId: 0,
          schemaName: '',
          name: ''
        }
        if(this.props.dataItem.processGroupId !== 0 && this.props.dataItem.processGroupId !== null && typeof(this.props.dataItem.processGroupId) !== "undefined"){
            let groupNode = this.getGroupNode(this.props.dataItem.processStudyId, this.props.dataItem.processGroupId);
            assessmentNode = {
                copyAssesmentType: ASSESSMENT_TYPE.GROUP_ASSESSMENT,
                projectId:groupNode.projectId,
                studyId: this.props.dataItem.processStudyId,
                groupId: this.props.dataItem.processGroupId,
                schemaName: groupNode.schemaName,
                name: this.props.dataItem.processGroupName
              }
        }else{
            let groupNode = this.getGroupNode(this.props.dataItem.sourceStudyId, this.props.dataItem.sourceGroupId);
            assessmentNode = {
                copyAssesmentType: ASSESSMENT_TYPE.GROUP_ASSESSMENT,
                projectId:groupNode.projectId,
                studyId: this.props.dataItem.sourceStudyId,
                groupId: this.props.dataItem.sourceGroupId,
                schemaName: groupNode.schemaName,
                name: this.props.dataItem.sourceGroupName
              }
        }
        this.setState({showDetailsDialog:true, assessmentNode:assessmentNode});
    }

    closeDialog = (event) => {
        let assessmentNode = {
          copyAssesmentType:0,
          studyId:0,
          projectId:0,
          groupId:0,
          schemaName:'',
          name:''
        }
        this.setState({showDetailsDialog:false,assessmentNode:assessmentNode});
    }

    getGroupNode = (studyId, groupId) => {
        let groupNode={projectId:0,schemaName:''};
        let treeView = this.props.treeView.data;
        treeView.children.forEach((project)=>{
            project.children.forEach((study)=>{
                if(study.study.studyId === studyId){
                    study.children.forEach((studyElement) => {
                        if (studyElement.name === 'ProcessFlows') {
                            studyElement.children.forEach((classifier) => {
                              let selectedGroup = classifier.children.filter(grp => grp.processGroupId === groupId);
                              if(selectedGroup.length > 0){
                                  groupNode = selectedGroup[0];
                                  return selectedGroup[0];
                              }
                            })
                          }
                    });
                }
            })
        });
        return groupNode;
    }

    render() {
        let disabled = true;
        if(this.props.dataItem.status === CopyAssessmentStatus.GROUP_UPDATED || this.props.dataItem.status === CopyAssessmentStatus.GROUP_SAME_AS_SOURCE){
            disabled = false;
        }
        let name = this.props.dataItem.processFlowName;
        if(name === null || name === "" || typeof(name) === "undefined"){
            name = this.props.dataItem.sourceFlowName;
        }
        return (
            <div className="text-center">
              <Button primary={true} onClick={this.handleOnClick} disabled={disabled}> Flow Sync Details</Button>
              <div>
              {
                  (this.state.showDetailsDialog && this.state.assessmentNode.groupId !== 0) && <div className="data-properties">
                  <GroupAssessment closeEvent={this.props.dataItem.closeEvent} assessmentNode={this.state.assessmentNode} />
                  </div>
              }
              </div>
           </div>
            );
    }
}

const mapStateToProps = createSelector(
    state => state.treeView,
    state => state.project.projectList,
    (treeView, projectList) => ({
        treeView  ,
        projectList  })
  );

  const mapActionsToProps = {
  }

const FlowSyncDetailsButtonClass = connect(mapStateToProps, mapActionsToProps)(BaseFlowSyncDetailsButton);
