import React from 'react';
import { Button } from './../Button/StandardButton';
import { Input } from './StandardInput';

export const BlankCell = ({ tableManager, value, data, column, colIndex, rowIndex }) => {
    if (typeof(tableManager) === 'undefined') {
      return (null)
    } else {
      return (
          <div className='rgt-cell-inner' style={{display: 'flex', alignItems: 'center', overflow: 'hidden'}}>
              <BlankCellClass dataItem={data} field={column.field} value={typeof(data[column.field]) !== 'undefined' ? data[column.field]: value} tableManager={tableManager}/>
          </div>
      )
    }
}

class BlankCellClass extends React.Component {

    constructor(props) {
      super(props)

      this.moveNodeElement = this.moveNodeElement.bind(this);
    }

    handleChange(e) {
      this.props.dataItem[this.props.field] = e.target.value;
      this.props.tableManager.rowsApi.setRows(this.props.tableManager.rowsApi.rows)
    }

    moveNodeElement(e, direction) {
      let currentGroupIndex = eval(this.props.field.replace('group', ''));
      let value = '';
      let newField = direction === 'left' ? "group"+(currentGroupIndex-1) : "group"+(currentGroupIndex+1)
      value = this.props.dataItem[newField];
      this.props.dataItem[newField] = this.props.dataItem[this.props.field];

      if (this.props.dataItem.blankCell.indexOf(this.props.field) > -1 && this.props.dataItem.blankCell.indexOf(newField) === -1) {
        let blankCell = this.props.dataItem.blankCell.filter(field => field !== this.props.field);
        blankCell.push(newField)
        this.props.dataItem.blankCell = blankCell
      } else if (this.props.dataItem.blankCell.indexOf(this.props.field) === -1 && this.props.dataItem.blankCell.indexOf(newField) > -1) {
        let blankCell = this.props.dataItem.blankCell.filter(field => field !== newField);
        blankCell.push(this.props.field)
        this.props.dataItem.blankCell = blankCell
      }
      this.props.onChange({
        dataItem: this.props.dataItem,
        field: this.props.field,
        syntheticEvent: e.syntheticEvent,
        value: value
      });
    }
    render() {
        let maxGroupNumber = 0;
        for (var key in this.props.dataItem) {
          if (this.props.dataItem.hasOwnProperty(key) && key !== 'rowId' && key !== 'blankCell') {
            if (key.indexOf('group') > -1) {
              key = eval(key.replace('group', ''));

              if (maxGroupNumber < key) {
                maxGroupNumber = key
              }
            }
          }
        }

        let bothMoveButton = this.props.field !== 'group1' && this.props.field !== 'group'+maxGroupNumber ? true : false;

        return (
            <>
              <div className={`title-wrapper float-left${bothMoveButton && this.props.field !== 'targetColumn' ? " bottom-move-button" : ""}`}>

              {this.props.dataItem['blankCell'].indexOf(this.props.field) > -1 &&
                <Input className="width-full" onChange={this.handleChange.bind(this)} value={this.props.dataItem[this.props.field]} length={40} />
              }

              {this.props.dataItem['blankCell'].indexOf(this.props.field) === -1 &&
                <div>{this.props.dataItem[this.props.field]}</div>
              }
            </div>
            {this.props.field !== 'targetColumn' &&
              <div className="action-wrapper float-left">
                {this.props.field !== 'group1' &&
                  <div className="moveButton"><Button primary={true} iconClass="fa fa-long-arrow-left" onClick={(event) => this.moveNodeElement(event, 'left')}></Button></div>
                }

                {this.props.field !== 'group'+maxGroupNumber &&
                  <div className="moveButton"><Button primary={true} iconClass="fa fa-long-arrow-right" onClick={(event) => this.moveNodeElement(event, 'right')}></Button></div>
                }
              </div>
            }
            </>
        );
    }
}
