import React from 'react';
import { Alert } from 'reactstrap';

function template() {
    if(this.state.visible && this.state.color !== 'danger'){
        setTimeout(() => {
            this.onDismiss();
          }, this.state.visibilityDuration);
    }
    return (
        <Alert color={this.state.color}  isOpen={this.state.visible} toggle={(event) => this.onDismiss(event, true)} className="myAlertInfo-topS">
          {this.state.alertMessage}
        </Alert>
    )

}
export default template;
