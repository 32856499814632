class TagContantType {
    constructor(props){
        this.STUDY = 1;
        this.PROCESS_FLOW_GROUP = 2;
        this.PROCESS_FLOW = 3;
        this.PROCESS_BLOCK = 4;
        this.TEXT = 1;
        this.DOCUMENT = 2;
        this.DROPDOWN = 3;
        this.URL = 4;
        this.list = [[this.STUDY, "Study"], [this.PROCESS_FLOW_GROUP, "ProcessFlowGroup"], [this.PROCESS_FLOW, "ProcessFlow"], [this.PROCESS_BLOCK, "ProcessBlock"], [this.TEXT, "Text"], [this.DOCUMENT, "Document"], [this.DROPDOWN, "Dropdown"], [this.URL, "URL"]];
    }
}

export default (new TagContantType());
