import "./DataOperation.css";
import React from "react";
import { PropTypes } from "prop-types";
import { connect } from 'react-redux';
import { createSelector } from 'reselect';
import { TabStrip, TabStripTab  } from './common/Layout';
import Dialog from 'react-dialog';
import Spec from './spec';
import PreWhere from './preWhere';
import ProcessOption from './processOption';
import Code from './code';
import Log from './log';
import List from './list';
import DataExplorer from './dataExplorer';
import ButtonPanel from './buttonPanel';
import axios from 'axios';
import restClient from '../../../restClient';
import { GET_ALL} from '../../../restClient/types';
import $ from 'jquery';
import { FileExplorer } from '../DataOperation/common/FileExplorer';
import { setRefreshDataStatus } from '../../../actions/actionDataOperation';
import { setMessage } from '../../../actions/actionNotification';
import MessageTypeConst from '../../MessageTypeConst';
import DataOpsTitleConstants from '../../DataOpsTitleConstants';
import DataOpsIdentifierConstants from '../../DataOpsIdentifierConstants';
import PostInitialization from './postInitialization';
import RuleTab from './RuleTab';
import DataExploratory from './dataExploratory';
import { Graph } from './common/ProcReport';
import { setProcessEngine } from './../../../actions/actionDataOperation';
import ConfirmBox from './common/ConfirmBox';

class OperationPanelWrapper extends React.Component {
  dataOpsOnCancel = (headers,params) => restClient(GET_ALL, 'dataops/cancel', params, headers)
  constructor(props) {
    super(props);

    this.raiseExecute = this.raiseExecute.bind(this);
    let selectedTabIndex = 2;
    if(props.processFlowAction === DataOpsIdentifierConstants.SPLIT_OP){
      selectedTabIndex = 1;
    }
    this.state = {
            executeRef : React.createRef(),
            selected: selectedTabIndex,
            visible: true,
            action_title: props.action_title,
            tenant_ID: props.tenant_ID,
            processFlowAction: props.processFlowAction,
            processFlowTable: props.processFlowTable,
            processFlowTableId: props.processFlowTableId,
            studyList: props.studyList,
            postKey:0,
            codeKey: 0,
            logKey:0,
            listKey: 0,
            dataExplorerKey:0,
            fileExplorerKey : 0,
            ruleTabKey:0,
            dataExploratoryKey: 0,
            graphTabKey: 0,
            raiseExecute : this.raiseExecute,
            activeProcessFlow:props.activeProcessFlow,
            processFlowList:props.processFlowList
    }
     this.toggleDialog = this.toggleDialog.bind(this);
     this.tabChangeAndRefreshTabContent = this.tabChangeAndRefreshTabContent.bind(this);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (typeof(nextProps.refreshDataStatus) !== 'undefined' && nextProps.refreshDataStatus === true) {
      this.tabChangeAndRefreshTabContent(this.state.selected);
      this.props.setRefreshDataStatus(false);
    }
  }

  raiseExecute() {
    //raising execute button click event
    this.state.executeRef.current.handleClick();
  }

  handleSelect = (e) => {
    this.tabChangeAndRefreshTabContent(e.selected);
  }

  tabChangeAndRefreshTabContent = (selected) => {
    let { codeKey, logKey, listKey, dataExplorerKey, fileExplorerKey, postKey, ruleTabKey, dataExploratoryKey } = this.state;
    let activeTabIndex = selected;

    if (this.state.processFlowAction === DataOpsIdentifierConstants.SPLIT_OP ||
      this.state.processFlowAction === DataOpsIdentifierConstants.R_Report_OP ||
      this.state.processFlowAction === DataOpsIdentifierConstants.PROCREPORT_ANALYSIS_CHAR_OP ||
      this.state.processFlowAction === DataOpsIdentifierConstants.PROCREPORT_ANALYSIS_NUM_OP) {
      activeTabIndex++;
    }
    if (this.state.processFlowAction === DataOpsIdentifierConstants.CODEEDITOR_FILE_OP){
      activeTabIndex = activeTabIndex + 2;
    }

    if (this.state.processFlowAction === DataOpsIdentifierConstants.CODEEDITOR_TABLE_OP) {
      activeTabIndex = activeTabIndex + 1;
    }

    switch (activeTabIndex) {
      case 3:
        postKey ++;
        break;
      case 4:
        codeKey++;
        break;
      case 5:
        logKey++;
        break;
      case 6:
        listKey++;
        break;
      case 7:
        dataExplorerKey++;
        break;
      case 8:
        dataExploratoryKey++;
        break;
      case 9:
        fileExplorerKey++;
        break;
      case 10:
       ruleTabKey++;
        break;
      default:
        break;
    }

    this.setState({selected: selected, codeKey: codeKey, logKey: logKey,listKey:listKey, dataExplorerKey: dataExplorerKey, dataExploratoryKey: dataExploratoryKey, fileExplorerKey:fileExplorerKey, postKey: postKey, ruleTabKey: ruleTabKey})
  }

  toggleDialog = () => {
    var tenantID = this.state.tenant_ID;
    ConfirmBox.open("Are you sure you want to exit ?").then((data) => {
      global.outputBlockTable = '';
      global.blockNewBlockId = 0;
      this.props.props.setALLProcessFlowRenderStatus(true);
      this.props.setProcessEngine({});
      $(".loader").show();
      if(global.processBlockExecuteBlockId > 0){
        axios.all([this.dataOpsOnCancel({'Tenant-ID': tenantID},{'blockId':global.processBlockExecuteBlockId,'blockTable':global.outputBlockTable,'updateBlock':global.updateBlock})])
            .then(axios.spread((response) => {
                if(typeof(response.data) !== 'undefined') {
                  $(".loader").hide();
                  window.location.hash='#process-flow-list';
                }
              })).catch(error=>{
                $(".loader").hide();
                this.props.setMessage("Error occurred while cancelling data operation.",MessageTypeConst.ERROR_MESSAGE);
              });
      } else {
        $(".loader").hide();
        window.location.hash='#process-flow-list';
      }
    })
   }

  render() {
      return (
        <Dialog title={this.state.action_title} width="calc(100% - 10px)" height="calc(100vh - 20px)" modal={true} onClose={this.toggleDialog} buttons={[<ButtonPanel {...this.state} {...this.props} tenantID={this.state.tenant_ID} key="btn-button-panel"/>]}>
        { this.state.processFlowAction === DataOpsIdentifierConstants.SPLIT_OP &&
            <TabStrip keepTabsMounted={true} selected={this.state.selected} onSelect={this.handleSelect}>
                <TabStripTab title={DataOpsTitleConstants.SPEC_TAB_TITLE}>
                  <Spec/>
                </TabStripTab>
                <TabStripTab title={DataOpsTitleConstants.PROCESS_OPTION_TAB_TITLE}>
                  <ProcessOption tenantID={this.state.tenant_ID} executeRef={this.state.executeRef} raiseExecute={this.state.raiseExecute}
                  processFlowAction={this.state.processFlowAction} processFlowTable={this.state.processFlowTable}
                  processFlowTableId={this.state.processFlowTableId} studyList={this.state.studyList} quickcondition={this.state.quickcondition}
                  activeProcessFlow={this.state.activeProcessFlow} processFlowList={this.state.processFlowList}/>
                </TabStripTab>
                <TabStripTab title={DataOpsTitleConstants.POST_INITIALIZATION_TAB_TITLE}>
                  <PostInitialization key={this.state.postKey} />
                </TabStripTab>
                <TabStripTab title={DataOpsTitleConstants.CODE_TAB_TITLE}>
                  <Code key={this.state.codeKey} />
                </TabStripTab>
                <TabStripTab title={DataOpsTitleConstants.LOG_TAB_TITLE}>
                  <Log key={this.state.logKey} />
                </TabStripTab>
                <TabStripTab title={DataOpsTitleConstants.LIST_TAB_TITLE}>
                  <List key={this.state.listKey} />
                </TabStripTab>
                <TabStripTab title={DataOpsTitleConstants.DATA_EXPLORER_TAB_TITLE}>
                  <DataExplorer tenantID={this.state.tenant_ID} key={this.state.dataExplorerKey} selectInputTable={false} autoLoaded={false} />
                </TabStripTab>
                <TabStripTab title={DataOpsTitleConstants.EXPLORATORY_TAB_TITLE}>
                  <DataExploratory key={this.state.dataExploratoryKey} processFlowAction={this.state.processFlowAction}/>
                </TabStripTab>
                <TabStripTab title={DataOpsTitleConstants.RULE_TAB_TITLE}>
                  <RuleTab key={this.state.ruleTabKey} processFlowAction={this.state.processFlowAction}/>
                </TabStripTab>
                <TabStripTab title={DataOpsTitleConstants.GRAPH_TAB_TITLE}>
                  <Graph key={this.state.graphTabKey} processFlowAction={this.state.processFlowAction}/>
                </TabStripTab>
            </TabStrip>
          }
        { this.state.processFlowAction === DataOpsIdentifierConstants.CODEEDITOR_TABLE_OP &&
            <TabStrip keepTabsMounted={true} selected={this.state.selected} onSelect={this.handleSelect}>
                <TabStripTab title={DataOpsTitleConstants.SPEC_TAB_TITLE}>
                  <Spec/>
                </TabStripTab>
                <TabStripTab title={DataOpsTitleConstants.PRE_WHERE_TAB_TITLE}>
                  <PreWhere tenantID={this.state.tenant_ID} />
                </TabStripTab>
                <TabStripTab title={DataOpsTitleConstants.PROCESS_OPTION_TAB_TITLE}>
                  <ProcessOption tenantID={this.state.tenant_ID} executeRef={this.state.executeRef} raiseExecute={this.state.raiseExecute}
                  processFlowAction={this.state.processFlowAction} processFlowTable={this.state.processFlowTable}
                  processFlowTableId={this.state.processFlowTableId} studyList={this.state.studyList} quickcondition={this.state.quickcondition}
                  activeProcessFlow={this.state.activeProcessFlow} processFlowList={this.state.processFlowList}/>
                </TabStripTab>
                <TabStripTab title={DataOpsTitleConstants.POST_INITIALIZATION_TAB_TITLE}>
                  <PostInitialization key={this.state.postKey} />
                </TabStripTab>
                <TabStripTab title={DataOpsTitleConstants.LOG_TAB_TITLE}>
                  <Log key={this.state.logKey} />
                </TabStripTab>
                <TabStripTab title={DataOpsTitleConstants.LIST_TAB_TITLE}>
                  <List key={this.state.listKey} />
                </TabStripTab>
                <TabStripTab title={DataOpsTitleConstants.DATA_EXPLORER_TAB_TITLE}>
                  <DataExplorer tenantID={this.state.tenant_ID} key={this.state.dataExplorerKey} selectInputTable={false} autoLoaded={false}/>
                </TabStripTab>
                <TabStripTab title={DataOpsTitleConstants.EXPLORATORY_TAB_TITLE}>
                  <DataExploratory key={this.state.dataExploratoryKey} processFlowAction={this.state.processFlowAction}/>
                </TabStripTab>
                <TabStripTab title={DataOpsTitleConstants.RULE_TAB_TITLE}>
                  <RuleTab key={this.state.ruleTabKey} processFlowAction={this.state.processFlowAction}/>
                </TabStripTab>
                <TabStripTab title={DataOpsTitleConstants.GRAPH_TAB_TITLE}>
                  <Graph key={this.state.graphTabKey} processFlowAction={this.state.processFlowAction}/>
                </TabStripTab>
            </TabStrip>
          }
        { this.state.processFlowAction === DataOpsIdentifierConstants.CODEEDITOR_FILE_OP &&
            <TabStrip keepTabsMounted={true} selected={this.state.selected} onSelect={this.handleSelect}>
                <TabStripTab title={DataOpsTitleConstants.SPEC_TAB_TITLE}>
                  <Spec/>
                </TabStripTab>
                <TabStripTab title={DataOpsTitleConstants.PRE_WHERE_TAB_TITLE}>
                  <PreWhere tenantID={this.state.tenant_ID} />
                </TabStripTab>
                <TabStripTab title={DataOpsTitleConstants.PROCESS_OPTION_TAB_TITLE}>
                  <ProcessOption tenantID={this.state.tenant_ID} executeRef={this.state.executeRef}
                  raiseExecute={this.state.raiseExecute}  processFlowAction={this.state.processFlowAction} processFlowTable={this.state.processFlowTable}
                  processFlowTableId={this.state.processFlowTableId} studyList={this.state.studyList} quickcondition={this.state.quickcondition}
                  activeProcessFlow={this.state.activeProcessFlow} processFlowList={this.state.processFlowList}/>
                </TabStripTab>
                <TabStripTab title={DataOpsTitleConstants.LOG_TAB_TITLE}>
                  <Log key={this.state.logKey} />
                </TabStripTab>
                <TabStripTab title={DataOpsTitleConstants.LIST_TAB_TITLE}>
                  <List key={this.state.listKey} />
                </TabStripTab>
                <TabStripTab title={DataOpsTitleConstants.DATA_EXPLORER_TAB_TITLE}>
                  <DataExplorer tenantID={this.state.tenant_ID} key={this.state.dataExplorerKey} selectInputTable={false} autoLoaded={false}/>
                </TabStripTab>
                <TabStripTab title={DataOpsTitleConstants.EXPLORATORY_TAB_TITLE}>
                  <DataExploratory key={this.state.dataExploratoryKey} processFlowAction={this.state.processFlowAction}/>
                </TabStripTab>
                <TabStripTab title={DataOpsTitleConstants.FILE_EXPLORER_TAB_TITLE}>
                  <FileExplorer folderPath={global.executeFolderPath} fileNames={global.fileNames} key={this.state.fileExplorerKey} tenantId={this.state.tenant_ID}/>
                </TabStripTab>
                <TabStripTab title={DataOpsTitleConstants.RULE_TAB_TITLE}>
                  <RuleTab key={this.state.ruleTabKey} processFlowAction={this.state.processFlowAction}/>
                </TabStripTab>
                <TabStripTab title={DataOpsTitleConstants.GRAPH_TAB_TITLE}>
                  <Graph key={this.state.graphTabKey} processFlowAction={this.state.processFlowAction}/>
                </TabStripTab>
            </TabStrip>
          }
          { (this.state.processFlowAction === DataOpsIdentifierConstants.PROCREPORT_ANALYSIS_CHAR_OP
          || this.state.processFlowAction === DataOpsIdentifierConstants.R_Report_OP
        || this.state.processFlowAction === DataOpsIdentifierConstants.PROCREPORT_ANALYSIS_NUM_OP) &&
            <TabStrip keepTabsMounted={true} selected={this.state.selected} onSelect={this.handleSelect}>
                <TabStripTab title={DataOpsTitleConstants.SPEC_TAB_TITLE}>
                  <Spec/>
                </TabStripTab>
                <TabStripTab title={DataOpsTitleConstants.PRE_WHERE_TAB_TITLE}>
                  <PreWhere tenantID={this.state.tenant_ID} />
                </TabStripTab>
                <TabStripTab title={DataOpsTitleConstants.PROCESS_OPTION_TAB_TITLE}>
                 <ProcessOption tenantID={this.state.tenant_ID} executeRef={this.state.executeRef} raiseExecute={this.state.raiseExecute}
                  processFlowAction={this.state.processFlowAction} processFlowTable={this.state.processFlowTable}
                  processFlowTableId={this.state.processFlowTableId} studyList={this.state.studyList} quickcondition={this.state.quickcondition}
                  activeProcessFlow={this.state.activeProcessFlow} processFlowList={this.state.processFlowList}/>
                </TabStripTab>
                <TabStripTab title={DataOpsTitleConstants.CODE_TAB_TITLE}>
                  <Code key={this.state.codeKey} />
                </TabStripTab>
                <TabStripTab title={DataOpsTitleConstants.LOG_TAB_TITLE}>
                  <Log key={this.state.logKey} />
                </TabStripTab>
                <TabStripTab title={DataOpsTitleConstants.LIST_TAB_TITLE}>
                  <List key={this.state.listKey} />
                </TabStripTab>
                <TabStripTab title={DataOpsTitleConstants.DATA_EXPLORER_TAB_TITLE}>
                  <DataExplorer tenantID={this.state.tenant_ID} key={this.state.dataExplorerKey} selectInputTable={false} autoLoaded={false}/>
                </TabStripTab>
                <TabStripTab title={DataOpsTitleConstants.EXPLORATORY_TAB_TITLE}>
                  <DataExploratory key={this.state.dataExploratoryKey} processFlowAction={this.state.processFlowAction}/>
                </TabStripTab>
                <TabStripTab title={DataOpsTitleConstants.FILE_EXPLORER_TAB_TITLE}>
                  <FileExplorer folderPath={global.executeFolderPath} fileNames={global.fileNames} key={this.state.fileExplorerKey} tenantId={this.state.tenant_ID}/>
                </TabStripTab>
                <TabStripTab title={DataOpsTitleConstants.RULE_TAB_TITLE}>
                  <RuleTab key={this.state.ruleTabKey} processFlowAction={this.state.processFlowAction}/>
                </TabStripTab>
                <TabStripTab title={DataOpsTitleConstants.GRAPH_TAB_TITLE}>
                  <Graph key={this.state.graphTabKey} processFlowAction={this.state.processFlowAction}/>
                </TabStripTab>
            </TabStrip>
          }
          { this.state.processFlowAction === DataOpsIdentifierConstants.GRAPH_OP &&
              <TabStrip keepTabsMounted={true} selected={this.state.selected} onSelect={this.handleSelect}>
                  <TabStripTab title={DataOpsTitleConstants.SPEC_TAB_TITLE}>
                    <Spec/>
                  </TabStripTab>
                  <TabStripTab title={DataOpsTitleConstants.PRE_WHERE_TAB_TITLE}>
                    <PreWhere tenantID={this.state.tenant_ID} />
                  </TabStripTab>
                  <TabStripTab title={DataOpsTitleConstants.PROCESS_OPTION_TAB_TITLE}>
                    <ProcessOption tenantID={this.state.tenant_ID} executeRef={this.state.executeRef}
                    raiseExecute={this.state.raiseExecute}  processFlowAction={this.state.processFlowAction} processFlowTable={this.state.processFlowTable}
                    processFlowTableId={this.state.processFlowTableId} studyList={this.state.studyList} quickcondition={this.state.quickcondition}
                    activeProcessFlow={this.state.activeProcessFlow} processFlowList={this.state.processFlowList}/>
                  </TabStripTab>
                  <TabStripTab title={DataOpsTitleConstants.LOG_TAB_TITLE}>
                    <Log key={this.state.logKey} />
                  </TabStripTab>
                  <TabStripTab title={DataOpsTitleConstants.LIST_TAB_TITLE}>
                    <List key={this.state.listKey} />
                  </TabStripTab>
                  <TabStripTab title={DataOpsTitleConstants.DATA_EXPLORER_TAB_TITLE}>
                    <DataExplorer tenantID={this.state.tenant_ID} key={this.state.dataExplorerKey} selectInputTable={false} autoLoaded={false}/>
                  </TabStripTab>
                  <TabStripTab title={DataOpsTitleConstants.EXPLORATORY_TAB_TITLE}>
                    <DataExploratory key={this.state.dataExploratoryKey} processFlowAction={this.state.processFlowAction}/>
                  </TabStripTab>
                  <TabStripTab title={DataOpsTitleConstants.FILE_EXPLORER_TAB_TITLE}>
                    <FileExplorer folderPath={global.executeFolderPath} fileNames={global.fileNames} key={this.state.fileExplorerKey} tenantId={this.state.tenant_ID}/>
                  </TabStripTab>
                  <TabStripTab title={DataOpsTitleConstants.RULE_TAB_TITLE}>
                    <RuleTab key={this.state.ruleTabKey} processFlowAction={this.state.processFlowAction}/>
                  </TabStripTab>
              </TabStrip>
            }
          {(this.state.processFlowAction !== DataOpsIdentifierConstants.PROCREPORT_ANALYSIS_CHAR_OP &&
          this.state.processFlowAction !== DataOpsIdentifierConstants.R_Report_OP &&
          this.state.processFlowAction !== DataOpsIdentifierConstants.PROCREPORT_ANALYSIS_NUM_OP ) &&
          this.state.processFlowAction !== DataOpsIdentifierConstants.CODEEDITOR_FILE_OP &&
          this.state.processFlowAction !== DataOpsIdentifierConstants.CODEEDITOR_TABLE_OP &&
          this.state.processFlowAction !== DataOpsIdentifierConstants.SPLIT_OP &&
          this.state.processFlowAction !== DataOpsIdentifierConstants.GRAPH_OP &&
            <TabStrip keepTabsMounted={true} selected={this.state.selected} onSelect={this.handleSelect}>
                <TabStripTab title={DataOpsTitleConstants.SPEC_TAB_TITLE}>
                  <Spec/>
                </TabStripTab>
                <TabStripTab title={DataOpsTitleConstants.PRE_WHERE_TAB_TITLE} >
                  <PreWhere tenantID={this.state.tenant_ID} />
                </TabStripTab>
                <TabStripTab title={DataOpsTitleConstants.PROCESS_OPTION_TAB_TITLE}>
                  <ProcessOption tenantID={this.state.tenant_ID} executeRef={this.state.executeRef} raiseExecute={this.state.raiseExecute}
                  processFlowAction={this.state.processFlowAction} processFlowTable={this.state.processFlowTable}
                  processFlowTableId={this.state.processFlowTableId} studyList={this.state.studyList} quickcondition={this.state.quickcondition}
                  activeProcessFlow={this.state.activeProcessFlow} processFlowList={this.state.processFlowList}/>
                </TabStripTab>
                <TabStripTab title={DataOpsTitleConstants.POST_INITIALIZATION_TAB_TITLE}>
                  <PostInitialization key={this.state.postKey} />
                </TabStripTab>
                <TabStripTab title={DataOpsTitleConstants.CODE_TAB_TITLE}>
                  <Code key={this.state.codeKey} />
                </TabStripTab>
                <TabStripTab title={DataOpsTitleConstants.LOG_TAB_TITLE}>
                  <Log key={this.state.logKey} />
                </TabStripTab>
                <TabStripTab title={DataOpsTitleConstants.LIST_TAB_TITLE}>
                  <List key={this.state.listKey} />
                </TabStripTab>
                <TabStripTab title={DataOpsTitleConstants.DATA_EXPLORER_TAB_TITLE}>
                  <DataExplorer tenantID={this.state.tenant_ID} key={this.state.dataExplorerKey} selectInputTable={false} autoLoaded={false}/>
                </TabStripTab>
                <TabStripTab title={DataOpsTitleConstants.EXPLORATORY_TAB_TITLE}>
                  <DataExploratory tenantID={this.state.tenant_ID} key={this.state.dataExploratoryKey} processFlowAction={this.state.processFlowAction}/>
                </TabStripTab>
                <TabStripTab title={DataOpsTitleConstants.RULE_TAB_TITLE}>
                  <RuleTab key={this.state.ruleTabKey} processFlowAction={this.state.processFlowAction}/>
                </TabStripTab>
                <TabStripTab title={DataOpsTitleConstants.GRAPH_TAB_TITLE}>
                  <Graph key={this.state.graphTabKey} processFlowAction={this.state.processFlowAction}/>
                </TabStripTab>
            </TabStrip>
          }
        </Dialog>
      );
  }
}


const mapStateToProps = createSelector(
  state => state.dataOperation.refreshDataStatus,
  (refreshDataStatus) => ({
    refreshDataStatus
  })
);

const mapActionsToProps = {
  setRefreshDataStatus: setRefreshDataStatus,
  setMessage:setMessage,
  setProcessEngine: setProcessEngine
}

Dialog.propTypes = {
    height: PropTypes.oneOfType([
        PropTypes.string
    ]),
    width: PropTypes.oneOfType([
        PropTypes.string
    ])
}
export default connect(mapStateToProps, mapActionsToProps)(OperationPanelWrapper)
