import React    from "react";
import { connect } from 'react-redux';
import { createSelector } from 'reselect';
import { POST_PARAM_BODY, GET } from '../../../restClient/types';
import restClient from '../../../restClient';
import axios from 'axios';
import $ from 'jquery';
import MessageTypeConst from "../../MessageTypeConst";
import { setMessage } from '../../../actions/actionNotification';
import { PropTypes } from "prop-types";
import Dialog from 'react-dialog';
import { Button } from './../DataOperation/common/Button/StandardButton';
import RichGridTable from "./../RichGridTable";
import { JumpToBlockButton } from '../Copying/JumpToBlockButton';
import { CopyObjectStatusCell } from './CopyObjectStatusCell';
import { BlockCreationTypeCell } from "./BlockCreationTypeCell";
import CopyAssessmentStatus from "./CopyAssessmentStatus.js";
import { addProcessFlow, setActiveProcessFlow, refreshProcessFlow, addImportTable} from '../../../actions/actionProcessFlow';
import { createShape, createNotes } from '../../ProcessFlow/ProcessFlows.js';
import COPY_TYPE from "../../CopyType.js";
import { setTreeViewData } from '../../../actions/actionTreeView';
import ConfirmBox from './../DataOperation/common/ConfirmBox';
import ProcessFlow from './../ProcessFlow';

class BlocksAssessment extends React.Component {
    constructor(props){
        super(props);
        this.processFlow = this.getProcessFlow();
        this.state ={
            copyInputTable:true,
            copySourceBlockVersion:true,
            showReCopyDialog:false,
            sourceBlockAndLinkData:{processBlock:[],processBlockLink:[], processFlowNotes:[]},
            targetBlockAndLinkData:{processBlock:[],processBlockLink:[], processFlowNotes:[]},
			showCompareDialog:false,
            closeEvent:props.closeEvent,
            targetProcessId:props.targetProcessId,
            groupRows:[{targetProcessId:0,targetStudyId:0,targetGroupId:0, studyId:0, groupId:0, flowId:0, status:0, blockId:0, targetBlockId:0, blockName:'',sourceBlockName:'',blockCreationType:'',closeEvent:this.props.closeEvent}],
            groupColumnProperty:[
                { field: "studyId",show: false},
                { field: "groupId",show: false},
                { field: "flowId",show: false},
                { field: "targetStudyId",show:false},
                { field: "targetProcessId",show:false},
                { field: "targetGroupId",show: false},
                { field: "closeEvent",show: false},
                { field: "blockName", title:"Target Block Name", width:"230px", orderIndex:1, className:"text-center"},
                { field: "sourceBlockName", title:"Source Block Name", width:"230px", orderIndex:2, className:"text-center"},
                { field: "blockCreationType", title: "Block Creation Type", width:"230px", orderIndex:3, className:"text-center", cell:BlockCreationTypeCell},
                { field: "status",title:"Status (in comparison with source)",cell:CopyObjectStatusCell,  width:"250px", orderIndex :4, className:"text-center"},
                { field: "blockId",title:"Navigate",cell:JumpToBlockButton, orderIndex:5, width:"180px", className:"text-center"},
                { field: "targetBlockId",title:"Navigate",cell:JumpToBlockButton, orderIndex:6, className:"text-center"},
            ],
            sourceFlowId:0,
            sourceStudyId:0,
            sourceFlowName:'',
            sourceGroupId:0,
            title: this.getTitle(this.processFlow),
            flowName : this.getFlowName(this.processFlow),
            flowStatusMsg : ""
        }
    }

    componentDidMount(){
        let that = this;
        let params ={
            processId:this.state.targetProcessId
        }
        $(".loader").show();
        axios.all([restClient(POST_PARAM_BODY, 'copy/processBlockAssessment', {params : params}, {'Tenant-ID': this.props.tenantId})])
        .then(axios.spread(function (response)  {
            if(response.data !== null && typeof(response.data) !== "undefined" && response.data.messageType !== MessageTypeConst.SUCCESS_MESSAGE){
                that.props.setMessage(response.data.message, response.data.messageType);
            }
            if(response.data !== null && typeof(response.data) !== "undefined" && response.data.blockAssessmentDetails !== null && typeof(response.data.blockAssessmentDetails) !== "undefined"
            && Array.isArray(response.data.blockAssessmentDetails)){
                let groupRows = that.state.groupRows;
                let sourceFlowId = response.data.sourceFlowId;
                let sourceStudyId = response.data.sourceStudyId;
                let sourceGroupId = response.data.sourceGroupId;
                if(response.data.blockAssessmentDetails.length >0){
                    groupRows.length = 0;
                }
                response.data.blockAssessmentDetails.forEach((block, index)=>{
                    if(block.status === CopyAssessmentStatus.BLOCK_NEWLY_ADDED){
                        let row ={
                            studyId:response.data.sourceStudyId,
                            groupId:response.data.sourceGroupId,
                            flowId:response.data.sourceFlowId,
                            targetProcessId:that.state.targetProcessId,
                            targetStudyId:that.processFlow.studyId,
                            targetGroupId:that.processFlow.processsGroupid,
                            blockName:block.blockName,
                            sourceBlockName:block.sourceBlockName,
                            blockCreationType:block.blockCreationType,
                            status:block.status,
                            blockId:block.sourceBlockId,
                            targetBlockId:block.targetBlockId,
                            closeEvent:that.props.closeEvent
                        }
                        groupRows.push(row);

                    } else if(block.status === CopyAssessmentStatus.BLOCK_DELETED){
                        let row ={
                            studyId:response.data.sourceStudyId,
                            groupId:response.data.sourceGroupId,
                            flowId:response.data.sourceFlowId,
                            targetProcessId:that.state.targetProcessId,
                            targetStudyId:that.processFlow.studyId,
                            targetGroupId:that.processFlow.processsGroupid,
                            blockName:block.blockName,
                            sourceBlockName:block.sourceBlockName,
                            blockCreationType:block.blockCreationType,
                            status:block.status,
                            blockId:block.sourceBlockId,
                            targetBlockId:block.targetBlockId,
                            closeEvent:that.props.closeEvent
                        }
                        groupRows.push(row);

                    }else if(block.status === CopyAssessmentStatus.SOURCE_BLOCK_UPDATED){
                        let row ={
                            studyId:response.data.sourceStudyId,
                            groupId:response.data.sourceGroupId,
                            flowId:response.data.sourceFlowId,
                            targetProcessId:that.state.targetProcessId,
                            targetStudyId:that.processFlow.studyId,
                            targetGroupId:that.processFlow.processsGroupid,
                            blockName:block.blockName,
                            sourceBlockName:block.sourceBlockName,
                            blockCreationType:block.blockCreationType,
                            status:block.status,
                            blockId:block.sourceBlockId,
                            targetBlockId:block.targetBlockId,
                            closeEvent:that.props.closeEvent
                        }
                        groupRows.push(row);

                    }else if(block.status === CopyAssessmentStatus.BLOCK_NEW_AT_TARGET){
                        let row ={
                            studyId:response.data.sourceStudyId,
                            groupId:response.data.sourceGroupId,
                            flowId:response.data.sourceFlowId,
                            targetProcessId:that.state.targetProcessId,
                            targetStudyId:that.processFlow.studyId,
                            targetGroupId:that.processFlow.processsGroupid,
                            blockName:block.blockName,
                            sourceBlockName:block.sourceBlockName,
                            blockCreationType:block.blockCreationType,
                            status:block.status,
                            blockId:block.sourceBlockId,
                            targetBlockId:block.targetBlockId,
                            closeEvent:that.props.closeEvent
                        }
                        groupRows.push(row);

                    }else if(block.status === CopyAssessmentStatus.BLOCK_SAME_AS_SOURCE){
                        let row ={
                            studyId:response.data.sourceStudyId,
                            groupId:response.data.sourceGroupId,
                            flowId:response.data.sourceFlowId,
                            targetProcessId:that.state.targetProcessId,
                            targetStudyId:that.processFlow.studyId,
                            targetGroupId:that.processFlow.processsGroupid,
                            blockName:block.blockName,
                            sourceBlockName:block.sourceBlockName,
                            blockCreationType:block.blockCreationType,
                            status:block.status,
                            blockId:block.sourceBlockId,
                            targetBlockId:block.targetBlockId,
                            closeEvent:that.props.closeEvent
                        }
                        groupRows.push(row);

                    }
                });
                groupRows = groupRows.sort((a,b) => (a.status > b.status) ? 1 : (a.status === b.status) ? ((a.status < b.status) ? 1 : -1) : -1 );
                let syncData = groupRows.filter(row => row.status === CopyAssessmentStatus.BLOCK_SAME_AS_SOURCE);

                let flowStatusMsg = "Flow '" + that.state.flowName +"' is not in sync with source.";
                if(groupRows.length == syncData.length){
                    flowStatusMsg = "Flow '" + that.state.flowName +"' is in sync with source.";
                }

                that.setState({groupRows:groupRows, sourceFlowId:sourceFlowId,sourceStudyId:sourceStudyId , sourceGroupId: sourceGroupId, flowStatusMsg: flowStatusMsg});
            }
            $(".loader").hide();
        })).catch(error => {
            $(".loader").hide();
            that.props.setMessage("Error occurred while impact assessment.", MessageTypeConst.ERROR_MESSAGE);
        });


    }


    handleOnCompareClick = (event) => {
        let that = this;
        if(that.processFlow=== null || typeof(that.processFlow) === "undefined"){
            return;
        }
        this.setState({showCompareDialog:true},()=>{
            that.getBlockAndLinkFromProcessFlow(that.processFlow.studyId, that.processFlow.processId, "targetDiagram");
            that.getBlockAndLinkFromProcessFlow(that.state.sourceStudyId, that.state.sourceFlowId, "sourceDiagram");
        });
    }

    getProcessFlow = () => {
        var processFlow = null;
        if(this.props.processFlowList !== null && this.props.processFlowList.length >0 &&
            this.props.processFlowList[this.props.activeProcessFlow] !== null && typeof(this.props.processFlowList[this.props.activeProcessFlow]) !== "undefined"){
            return this.props.processFlowList[this.props.activeProcessFlow].process;
        }
        return processFlow;
    }

    getTitle = (processFlow) => {
        let title = "";
        if(processFlow === null || typeof(processFlow) === "undefined"){
            return;
        }
        title = `${processFlow.processName} Assessment`;
        return title;
    }

    getFlowName = (processFlow) => {
        let title = "";
        if(processFlow === null || typeof(processFlow) === "undefined"){
            return;
        }
        title = `${processFlow.processName}`;
        return title;
    }

    closeDialog = (event) => {
        this.state.closeEvent();
    }

    closeCompareDialog = (event) => {
        this.setState({showCompareDialog:false});
    }

    getBlockAndLinkFromProcessFlow = (studyId, processId, diagramId) => {
        var data = {processBlock:[],processBlockLink:[],processFlowNotes:[]};
        let treeView = this.props.treeView;
        var that = this;
        let schemaName = '';
        let sourceFlowName = '';
        treeView.data.children.forEach((project)=>{
                project.children.forEach((study)=>{
                    if(study.study.studyId === studyId){
                        schemaName = study.study.schemaName;
                        study.children.forEach((studyElement) => {
                            if (studyElement.name === 'ProcessFlows') {
                              studyElement.children.forEach((classifier)=>{
                                classifier.children.forEach((group)=>{
                                    group.children.forEach((flow)=>{
                                    if(flow.process.processId === processId){
                                        if(flow.process.processBlock === null || typeof(flow.process.processBlock) === "undefined" ||
                                        flow.process.processBlockLink === null || typeof(flow.process.processBlockLink) === "undefined"){
                                        $(".loader").show();
                                        axios.all([
                                            restClient(GET, 'processblock/blockAndLinkList?studyId='+studyId+'&processId='+processId, {}, {'Tenant-ID':schemaName})
                                            ])
                                            .then(axios.spread((response) => {
                                                if (typeof(response.data) !== 'undefined') {
                                                    if (typeof(response.data) !== 'undefined' && typeof(response.data.messageType) !== 'undefined' && response.data.messageType !== null && response.data.messageType ===  MessageTypeConst.WARNING_MESSAGE) {
                                                        that.props.setMessage(response.data.message, response.data.messageType);
                                                        $(".loader").hide();
                                                        return;
                                                    }
                                                flow.process.processBlock = []
                                                if (response.data.processBlock !== null && response.data.processBlock !== '' && response.data.processBlock.length > 0) {

                                                    flow.process.processBlock.push(...response.data.processBlock);
                                                    data.processBlock = flow.process.processBlock;
                                                }

                                                flow.process.processBlockLink = []
                                                if (response.data.processBlockLink !== null && response.data.processBlockLink !== '' && response.data.processBlockLink.length > 0) {
                                                    flow.process.processBlockLink.push(...response.data.processBlockLink);
                                                    data.processBlockLink = flow.process.processBlockLink;
                                                }
                                                }

                                                flow.process.processFlowNotes = []
                                                if (response.data.processFlowNotes !== null && response.data.procprocessFlowNotesessBlock !== '' && response.data.processFlowNotes.length > 0) {

                                                    flow.process.processFlowNotes.push(...response.data.processFlowNotes);
                                                    data.processFlowNotes = flow.process.processFlowNotes;
                                                }
                                                if(diagramId === "targetDiagram"){
                                                    that.setState({targetBlockAndLinkData:data});

                                                }else if(diagramId === "sourceDiagram"){
                                                    //make api call to populate blocks and links
                                                    sourceFlowName = flow.process.processName;
                                                    that.setState({sourceBlockAndLinkData:data, sourceFlowName: sourceFlowName});

                                                }

                                                $(".loader").hide();
                                            }))
                                            .catch(error => {
                                                $(".loader").hide();
                                                that.props.setMessage("Failed to fetch blocks and links!",MessageTypeConst.ERROR_MESSAGE);
                                            });
                                        }
                                        else{
                                            data.processBlock = flow.process.processBlock;
                                            data.processBlockLink = flow.process.processBlockLink;
                                            data.processFlowNotes = flow.process.processFlowNotes;
                                            if(diagramId === "targetDiagram"){
                                                that.setState({targetBlockAndLinkData:data});

                                            }else if(diagramId === "sourceDiagram"){
                                                //make api call to populate blocks and links
                                                sourceFlowName = flow.process.processName;
                                                that.setState({sourceBlockAndLinkData:data, sourceFlowName: sourceFlowName});

                                            }
                                        }
                                    }
                                  });
                                });
                              })

                    }
                })
                    }
                });
        });
        return data;
    }

    handleOnSourceFlowClick = (event) => {
        let that = this;
        let process = that.fetchProcessFlow(that.state.sourceStudyId,that.state.sourceFlowId );
        if(process !== null){
          let flowAlreadyOpen = false;
          that.props.processFlowList.forEach((flow, index) => {
            if(flow.process.processId === process.process.processId && flow.process.studyId === process.process.studyId && flow.process.processGroupId === process.process.processGroupId){
              flowAlreadyOpen = true;
              that.props.setActiveProcessFlow(index);
            }
          });
          if(!flowAlreadyOpen){
            that.props.addProcessFlow(process);
          }
          window.location.hash='#process-flow-list';
          that.closeDialog();
        }else{
          ConfirmBox.alert("Study for selected jump to block not included. Please select appropriate Study using ‘Study Select’ link.");
        }
    }

    handleOnCopyFlowClick = (event) => {
        let that = this;
        let process = that.fetchProcessFlow(that.processFlow.studyId,that.processFlow.processId );
        if(process !== null){
          let flowAlreadyOpen = false;
          that.props.processFlowList.forEach((flow, index) => {
            if(flow.process.processId === process.process.processId && flow.process.studyId === process.process.studyId && flow.process.processGroupId === process.process.processGroupId){
              flowAlreadyOpen = true;
              that.props.setActiveProcessFlow(index);
            }
          });
          if(!flowAlreadyOpen){
            that.props.addProcessFlow(process);
          }
          window.location.hash='#process-flow-list';
          that.closeDialog();
        }else{
          ConfirmBox.alert("Study for selected jump to block not included. Please select appropriate Study using ‘Study Select’ link.");
        }
    }

    fetchProcessFlow = (studyId, processId) =>{
        var that = this;
        let treeView = that.props.treeView.data;
        let selectedFlow = null;
        treeView.children.forEach((project, index) => {
          project.children.forEach((study) => {
            if (study.study.studyId === studyId) {
              study.children.forEach((studyElement) => {
                if (studyElement.name === 'ProcessFlows') {
                  studyElement.children.forEach((classifier) => {
                    classifier.children.forEach((processGroup) => {
                      processGroup.children.forEach((flow)=>{
                        if(processId === flow.process.processId){
                          selectedFlow = flow;
                          return selectedFlow;
                        }
                      })
                    });
                  })

                }
              })
            }
         });
        })
        return selectedFlow;
    }

    getSchemaName = (studyId) =>{
        var that = this;
        let treeView = that.props.treeView.data;
        let schemaName = '';
        treeView.children.forEach((project, index) => {
          project.children.forEach((study) => {
            if (study.study.studyId === studyId) {
                schemaName = study.study.schemaName;
                return schemaName;
            }
         });
        })
        return schemaName;
    }

    getProjectId = (studyId) =>{
        var that = this;
        let treeView = that.props.treeView.data;
        let projectId = 0;
        treeView.children.forEach((project, index) => {
          project.children.forEach((study) => {
            if (study.study.studyId === studyId) {
                projectId = project.projectId;
                return projectId;
            }
         });
        })
        return projectId;
    }

    syncFlowWithSource = () =>{
        this.setState({showReCopyDialog:true});
    }

    closeReCopyDialog = () =>{
        this.setState({showReCopyDialog:false});
    }

    executeFlowCopy = () => {
        $(".loader").show();
        let that = this;
        let request = {
            sourceProjectId : this.getProjectId(this.state.sourceStudyId),
            sourceStudyId : this.state.sourceStudyId,
            sourceGroupId : this.state.sourceGroupId,
            sourceFlowId : this.state.sourceFlowId,
            targetProjectId : this.getProjectId(this.processFlow.studyId),
            targetStudyId : this.processFlow.studyId,
            targetGroupId : this.processFlow.processGroupId,
            targetFlowId : this.processFlow.processId,
            copyType :COPY_TYPE.COPY_FLOW,
            targetNewName:'',
            groupNames:[],
            sourceSchemaName:this.getSchemaName(this.state.sourceStudyId),
            targetSchemaName:this.props.tenantId,
            processSyncFlag: 0,
            copyTables:this.state.copyInputTable,
            copySourceBlockVersion:this.state.copySourceBlockVersion
        }
        axios.all([restClient(POST_PARAM_BODY, 'recopy/processblocks', {data : request}, {'Tenant-ID': this.props.tenantId})])
        .then(axios.spread(function (response)  {
            if (typeof(response.data) !== 'undefined' && response.data.message != null && response.data.messageType !==  MessageTypeConst.SUCCESS_MESSAGE) {
                that.props.setMessage(response.data.message, response.data.messageType);
            }
            else if (typeof(response.data) !== 'undefined' && response.data !== null && typeof(response.data) !== 'undefined') {
                that.props.processFlowList.forEach((processFlow, index)=>{
                    if(that.processFlow.processId === processFlow.process.processId){
                        that.updateTreeViewBasedOnResponse(request, response, false);
                        response.data.processFlowGroup.forEach((updatedGroup) => {
                            if(that.processFlow.processGroupId === updatedGroup.processGroupId){
                                if(typeof(updatedGroup.processFlow) !== "undefined"
                                    && updatedGroup.processFlow !== null
                                    && updatedGroup.processFlow.length > 0){
                                        let selectedFlow = updatedGroup.processFlow.filter(flow=>flow.processId === that.processFlow.processId);
                                        if(selectedFlow.length > 0){
                                            processFlow.process.isSync = selectedFlow[0].isSync;
                                        }
                                    }
                            }
                        });
                        that.props.refreshProcessFlow(processFlow);
                    }
                })
                that.props.setMessage(response.data.message, response.data.messageType);
            }
            that.closeDialog();
            $(".loader").hide();
        })).catch(error => {
            $(".loader").hide();
            that.props.setMessage("Error occurred while copying flow.", MessageTypeConst.ERROR_MESSAGE);
        });

    }

    updateTreeViewBasedOnResponse = (request, response) => {
        let that = this;
        let treeView = that.props.treeView.data;
        treeView.children.forEach((proj, projIndex) => {
            if (proj.projectId === request.targetProjectId) {
                treeView.children[projIndex].children.forEach((study, studyIndex) => {
                    if (study.study.studyId === request.targetStudyId) {
                        if (study.study.sourceTable === null || typeof (study.study.sourceTable) === "undefined") {
                            study.study.sourceTable = [];
                        }
                        if (study.study.metaTable === null || typeof (study.study.metaTable) === "undefined") {
                            study.study.metaTable = [];
                        }
                        if (study.study.studyDocuments === null || typeof (study.study.studyDocuments) === "undefined") {
                            study.study.studyDocuments = [];
                        }
                        treeView.children[projIndex].children[studyIndex].children.forEach((node, nodeIndex) => {
                            if (node.name === "Source Table" &&
                                response.data.sourceTables !== null && typeof (response.data.sourceTables) !== 'undefined'
                                && response.data.sourceTables.length > 0) {
                                response.data.sourceTables.forEach((srcTable) => {
                                    let newImportTable = Object.assign({
                                    name: srcTable.srcTableName, projectId: request.targetProjectId,
                                        studyId: study.study.studyId, cssClassName: 'manage-study-edit-source-table', schemaName: study.study.schemaName,
                                        tableType: 0, tag: 'open-data-viewer', tableName: srcTable.tableName
                                    });
                                    treeView.children[projIndex].children[studyIndex].children[nodeIndex].children.push(newImportTable);
                                    let newTable = Object.assign({
                                    srcTableName: srcTable.srcTableName,
                                        tableId: srcTable.tableId, tableName: srcTable.tableName, tableVersion: srcTable.tableVersion,
                                        versionLimit: srcTable.versionLimit, importLog: '', tableType: 0, timestamp: srcTable.timestamp
                                    });
                                    let sourceTable = study.study.sourceTable.filter(table => table.srcTableName !== srcTable.srcTableName);
                                    sourceTable.splice(sourceTable.length, 0, newTable);
                                    study.study.sourceTable.splice(0);
                                    study.study.sourceTable.splice(0, 0, ...sourceTable);
                                    that.props.addImportTable(newImportTable);
                                });
                            }
                            else if (node.name === "Meta Table" &&
                                response.data.metaTables !== null && typeof (response.data.metaTables) !== 'undefined'
                                && response.data.metaTables.length > 0) {
                                response.data.metaTables.forEach((metaDataTable) => {
                                    let newImportTable = Object.assign({
                                    name: metaDataTable.srcTableName, projectId: request.targetProjectId,
                                        studyId: study.study.studyId, cssClassName: 'manage-study-edit-meta-table', schemaName: study.study.schemaName, tableType: 1,
                                        tag: 'open-data-viewer', tableName: metaDataTable.tableName
                                    });
                                    treeView.children[projIndex].children[studyIndex].children[nodeIndex].children.push(newImportTable);
                                    let metaTable = study.study.metaTable.filter(table => table.srcTableName !== metaDataTable.srcTableName);
                                    let newTable = Object.assign({
                                    srcTableName: metaDataTable.srcTableName,
                                        tableId: metaDataTable.tableId, tableName: metaDataTable.tableName, tableVersion: metaDataTable.tableVersion,
                                        versionLimit: metaDataTable.versionLimit, importLog: '', tableType: 1, timestamp: metaDataTable.timestamp
                                    });
                                    metaTable.splice(metaTable.length, 0, newTable);
                                    study.study.metaTable.splice(0);
                                    study.study.metaTable.splice(0, 0, ...metaTable);
                                    that.props.addImportTable(newImportTable);
                                });
                            }
                            else if (node.name === "Documents" &&
                                response.data.documents !== null && typeof (response.data.documents) !== 'undefined'
                                && response.data.documents.length > 0) {
                                response.data.documents.forEach((document) => {
                                    let newDoc = Object.assign({
                                    name: document.filename, projectId: request.targetProjectId,
                                        studyId: study.study.studyId, cssClassName: 'manage-study-edit-documents',
                                        fileId: document.fileId, path: document.filepath,
                                        schemaName: study.study.schemaName, tableType: 2
                                    });
                                    if (newDoc !== null) {
                                        treeView.children[projIndex].children[studyIndex].children[nodeIndex].children.push(newDoc);
                                        let tableList = treeView.children[projIndex].children[studyIndex].children[nodeIndex].children.slice();
                                        let importTableIndex = tableList.length;
                                        treeView.children[projIndex].children[studyIndex].children[nodeIndex].children.forEach((table, index) => {
                                            if (table.name === document.filename) {
                                                importTableIndex = index;
                                            }
                                        });
                                        tableList = tableList.filter(table => table.name !== document.filename);
                                        tableList.splice(importTableIndex, 0, newDoc);
                                        treeView.children[projIndex].children[studyIndex].children[nodeIndex].toggled = true;
                                        treeView.children[projIndex].children[studyIndex].children[nodeIndex].children.splice(0);
                                        treeView.children[projIndex].children[studyIndex].children[nodeIndex].children.splice(0, 0, ...tableList);
                                        if (newDoc.tableType === 2) {
                                            let documents = study.study.studyDocuments.filter(table => table.name !== document.filename);
                                            ;
                                            documents.splice(documents.length, 0, document);
                                            study.study.studyDocuments.splice(0);
                                            study.study.studyDocuments.splice(0, 0, ...documents);
                                        }
                                    }
                                });
                            }
                            else if(node.name === "ProcessFlows"){
                                response.data.processFlowGroup.forEach((updatedGroup) => {
                                  treeView.children[projIndex].children[studyIndex].children[nodeIndex].children.forEach((classfier) => {
                                    classfier.children.forEach((group, groupIndex) => {
                                        if(group.processGroupId === updatedGroup.processGroupId){
                                            if(typeof(updatedGroup.processFlow) !== "undefined"
                                                && updatedGroup.processFlow !== null
                                                && updatedGroup.processFlow.length > 0){
                                                    updatedGroup.processFlow.forEach(updatedFlow => {
                                                        group.children.map((flow)=>{
                                                            if(updatedFlow.processId === flow.process.processId){
                                                              flow.process.processSyncFlag = updatedFlow.processSyncFlag;

                                                              if(updatedFlow.processSyncFlag === 2){
                                                                flow.iconClass = "fa fa-exclamation-triangle yellow";
                                                              }
                                                              else if(updatedFlow.processSyncFlag === 3){
                                                                flow.iconClass = "fa fa-exclamation-circle red";
                                                              }
                                                              else if(updatedFlow.processSyncFlag === 1){
                                                                flow.iconClass = "fa fa-microchip";
                                                              }
                                                            }
                                                            return flow;
                                                          })
                                                    });
                                                }
                                        }
                                    })
                                  })

                                });

                            }
                        });
                    }
                });
            }
        });
        that.props.setTreeViewData(treeView);
    }

    handleCopyInputTableChange = (event) => {
        let copyInputTable = true;
        switch(event.target.id){
            case "optImportTrue":
            copyInputTable = true;
            break;
            case "optImportFalse":
            copyInputTable = false;
            break;
            default:
            break;
        }
        this.setState({copyInputTable:copyInputTable});
    }

    handleCopySourceVersionChange = (event) => {
        let copySourceBlockVersion = true;
        switch(event.target.id){
            case "optUpdateVersionTrue":
            copySourceBlockVersion = true;
            break;
            case "optUpdateVersionFalse":
            copySourceBlockVersion = false;
            break;
            default:
            break;
        }
        this.setState({copySourceBlockVersion:copySourceBlockVersion});
    }

    render(){
        return(
            <div className="data-properties flow">
                    <Dialog className={this.className} title={this.state.title} width="calc(100% - 10px)" height="calc(100vh - 20px)" modal={true} onClose={this.closeDialog} buttons={[<Button primary={true} onClick={this.syncFlowWithSource} key="btn-sync"> Sync</Button>,<Button onClick={this.closeDialog} key="btn-cancel"> Cancel</Button>]}>
                        <div className="data-properties" >
                            <div className="text-center richgrid-height-165">
                                {this.state.flowStatusMsg}
                            </div>
                            <div className="text-center richgrid-height-165">
                              <Button primary={true} className="m-b-10" onClick={this.handleOnCompareClick} >Compare Source and Target Flow</Button>
                              <RichGridTable id={"flow-assessment-grid-table"} rows={this.state.groupRows} selectedField="selected" columnProperty={this.state.groupColumnProperty} />
                            </div>
                        </div>
                    </Dialog>

            {
                this.state.showCompareDialog && <div className="compare-flows"><div className="data-properties">
                <Dialog className={this.className} title={"Flows"} width="calc(100% - 10px)" height="calc(100vh - 20px)" modal={true} onClose={this.closeCompareDialog}>
                    <div className="data-properties" >
                        <div className="row">
                            <div className="col-md-6">
                              <fieldset>
                                <div className="field_group">
                                  <legend>{`Process Flow Diagram of Source '${this.state.sourceFlowName}'`}&nbsp;&nbsp;<Button primary={true} onClick={this.handleOnSourceFlowClick}> Open Flow</Button></legend>
                                  <div className="process_flow">
                                    <div className="exView">
                                      <ProcessFlow id={`process-flow-source`} schemaName={''} {...this.state.sourceBlockAndLinkData} readOnly={true} isFlowCheckedOut={false} isFlowCheckedOutByLoggedInUser={false} key={`process-flow-source`} />
                                    </div>
                                  </div>
                                  </div>
                                </fieldset>
                            </div>
                            <div className="col-md-6">
                              <fieldset>
                                <div className="field_group">
                                  <legend>{`Process Flow Diagram of Target '${this.processFlow.processName}'`}&nbsp;&nbsp;<Button primary={true} onClick={this.handleOnCopyFlowClick}> Open Flow</Button></legend>
                                  <div className="process_flow">
                                    <div className="exView">
                                      <ProcessFlow id={`process-flow-target`} schemaName={''} {...this.state.targetBlockAndLinkData} readOnly={true} isFlowCheckedOut={false} isFlowCheckedOutByLoggedInUser={false} key={`process-flow-target`} />
                                    </div>
                                  </div>
                                </div>
                                </fieldset>
                            </div>
                        </div>
                    </div>
                </Dialog>
                </div>
                </div>
            }
            {
                this.state.showReCopyDialog && <div className="data-properties">
                <Dialog className={this.className} title={"Recopy"} width="750px" height="auto" modal={true} onClose={this.closeReCopyDialog} buttons={[<Button primary={true} onClick={this.executeFlowCopy} key="btn-recopy"> Re-Copy</Button>,<Button onClick={this.closeReCopyDialog} key="btn-cancel"> Cancel</Button>]}>
                     <div className="field_group">
                        <div className="data-properties" >
                        <fieldset>
                        <div className="field_group m-t-10 m-b-10">
                            <legend>While copying</legend>
                            <fieldset>
                            <div className="field_group m-t-10 m-b-10">
                            <div>
                                <label><input type="radio" name="optImport" value="Source" onChange={this.handleCopyInputTableChange}
                                checked={this.state.copyInputTable} id="optImportTrue"/> Copy input table/document if not exists</label>
                            </div>
                            <div>
                                <label><input type="radio" name="optImport" value="Source" onChange={this.handleCopyInputTableChange}
                                checked={!this.state.copyInputTable} id="optImportFalse"/> Skip copying input table/document if not exists (child blocks will be in Invalid state)</label>
                            </div>
                            </div>
                            </fieldset>
                            <fieldset>
                            <div className="field_group m-t-10 m-b-10">
                            <div>
                                <label><input type="radio" name="optUpdateVersion" value="Version" onChange={this.handleCopySourceVersionChange}
                                checked={this.state.copySourceBlockVersion} id="optUpdateVersionTrue"/> Copy updated source block</label>
                            </div>
                            <div>
                                <label><input type="radio" name="optUpdateVersion" value="Version" onChange={this.handleCopySourceVersionChange}
                                checked={!this.state.copySourceBlockVersion} id="optUpdateVersionFalse"/> Skip copying updated source block</label>
                            </div>
                            </div>
                            </fieldset>
                        </div>
                        </fieldset>
                        <b>Note: </b>
                        <ul className="notes-list">
                          <li>While copying LINK blocks, will check compare source block name from which block is created. If block name not found, then LINK block will be skipped and all its child blocks will be in Invalid state.</li>
                          <li>AUTO and Timeline blocks will get skipped.</li>
                        </ul>
                        </div>
                        </div>
                      </Dialog>
                </div>
            }
            </div>);
    }


}

const mapStateToProps = createSelector(
    state => state.processFlow.processFlowList,
    state => state.treeView,
    state => state.processFlow.tenantId,
    state => state.processFlow.activeProcessFlow,
    (processFlowList, treeView, tenantId, activeProcessFlow) => ({
      processFlowList,
      treeView,
      tenantId,
      activeProcessFlow
    })
  );

const mapActionsToProps = {
    setMessage: setMessage,
    addProcessFlow: addProcessFlow,
    setActiveProcessFlow: setActiveProcessFlow,
    refreshProcessFlow: refreshProcessFlow,
    setTreeViewData: setTreeViewData,
    addImportTable: addImportTable,
}

Dialog.propTypes = {
    height: PropTypes.oneOfType([
        PropTypes.string
    ]),
    width: PropTypes.oneOfType([
        PropTypes.string
    ])
}
export default connect(mapStateToProps, mapActionsToProps)(BlocksAssessment);
