import React from 'react';
import { DropDownList } from './StandardDropDownList';

export class StandardDropDown extends React.Component {
  handleChange(e) {
    this.props.onChange({
      data: this.props.data,
      syntheticEvent: e.syntheticEvent,
      value: e.target.value
    });
  }
  render() {
      let className = "m-0";

      if (typeof(this.props.className) !== 'undefined' && this.props.className !== '') {
        className = className + " " + this.props.className;
      }
      return (
        <DropDownList className={className} onChange={this.handleChange.bind(this)} data={this.props.data} value={this.props.value} disabled={typeof(this.props.disabled) !== 'undefined' ? this.props.disabled : false}/>
      );
    }
}
