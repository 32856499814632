import React    from "react";
import { PropTypes } from "prop-types";
import { connect } from 'react-redux';
import { createSelector } from 'reselect';
import Dialog from 'react-dialog';
import { Button } from './../DataOperation/common/Button/StandardButton';
import { setTreeViewData } from './../../../actions/actionTreeView';
import axios from 'axios';
import $ from 'jquery';
import MessageTypeConst from '../../MessageTypeConst';
import restClient from '../../../restClient';
import { POST_PARAM_BODY } from '../../../restClient/types';
import { setMessage } from './../../../actions/actionNotification';

class ProcessFlowSorting extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
          node: props.node,
          closeEvent:props.closeEvent,
          itemList: [],
          selectedItemList: []
        }
    }

    componentDidMount(){
        let itemList = [];
        let processFlowList = this.state.node.children;
        let index = 0;
        if(typeof(processFlowList) !== "undefined" && processFlowList !== null && processFlowList.length>0){
            processFlowList.forEach((processFlow, nodeIndex)=>{
                let sortOrder = itemList.length;

                if (typeof(processFlow.process.sortOrder) !== 'undefined') {
                  sortOrder = processFlow.process.sortOrder
                }
                let item = {id: processFlow.process.processId.toString(), sortOrder: sortOrder,name: processFlow.name, processFlowId: processFlow.process.processId, processGroupId: processFlow.process.processGroupId};
                itemList.push(item);
                index = index + 1;
            })
        }
        itemList = itemList.sort((a,b)=> a.sortOrder - b.sortOrder);
        itemList.map((item, index) => item.sortOrder = index)
        this.setState({itemList:itemList});
    }

    handleSelectItem = (event) => {
        let itemSelected = event.target.id.replace('item-', '');
        let selectedItemList = this.state.selectedItemList;
        selectedItemList.push(itemSelected)

        event.target.classList.toggle('selected')

        if (!event.target.classList.contains('selected')) {
          selectedItemList = selectedItemList.filter(selectedItem => selectedItem != itemSelected)
        }
        this.setState({selectedItemList: selectedItemList });
      }

    displayItem = (item, index) => {
        var selectedClassName = '';

        if (this.state.selectedItemList.indexOf(item.id) > -1 ) {
          selectedClassName = 'selected';
        }

        return (
            <li key={index} onClick={this.handleSelectItem} id={`item-${item.id}`} className={selectedClassName}>{`${item.name}`}</li>
        )
    };

    handleItemUpEvent = (event) => {
        let itemList = this.state.itemList;
        this.state.selectedItemList.forEach(selectedItem => {
          itemList.forEach((item, index) => {
            if (item.id === selectedItem) {
                if (typeof(itemList[index-1]) !== 'undefined' && typeof(itemList[index-1].id) !== 'undefined' && this.state.selectedItemList.indexOf(itemList[index-1].id) === -1) {
                  item.sortOrder = item.sortOrder - 1;
                  itemList[index] = item;
                  itemList[index-1].sortOrder = itemList[index-1].sortOrder + 1;
                }
              }
          });
          itemList.sort((item1,item2)=>item1.sortOrder-item2.sortOrder)
          itemList.map((item, index) => item.sortOrder = index)
        })

        this.setState({ itemList : itemList});
    }
    handleItemDownEvent = (event) => {
        let itemList = this.state.itemList;
        this.state.selectedItemList.forEach(selectedItem => {
          itemList.forEach((item, index) => {
            if (item.id === selectedItem) {
                if (typeof(itemList[index+1]) !== 'undefined' && typeof(itemList[index+1].id) !== 'undefined' && this.state.selectedItemList.indexOf(itemList[index+1].id) === -1) {
                    item.sortOrder = item.sortOrder + 1;
                    itemList[index] = item;
                    itemList[index+1].sortOrder = itemList[index+1].sortOrder - 1;
                }
              }
          });
          itemList.sort((item1,item2)=>item1.sortOrder-item2.sortOrder)
          itemList.map((item, index) => item.sortOrder = index)
        })

        this.setState({ itemList : itemList});
    }

    getItems() {
        return this.state.itemList;
    }

    handleSaveClick =(event) =>{
        let that = this;
        $(".loader").show();

        axios.all([restClient(POST_PARAM_BODY, 'processflowgroups/sort', {data: this.state.itemList}, {'Tenant-ID': this.state.node.schemaName})])
              .then(axios.spread((response) => {
                if (typeof(response.data) !== 'undefined' && response.data.message != null && response.data.messageType ===  MessageTypeConst.SUCCESS_MESSAGE) {
                  let treeView =  this.props.treeView.data;
                  treeView.children.filter(project => project.projectId === this.state.node.projectId).map(project => {
                    project.children.filter(study => study.study.studyId === this.state.node.studyId).map(study => {
                      study.study.processFlowGroup.filter(group => group.processGroupId === this.state.node.processGroupId).map(group => {
                        group.processFlow.map(process => {
                          that.state.itemList.filter(item => item.processFlowId === process.processId).forEach(item => {
                            process.sortOrder = item.sortOrder;
                          })
                          return process;
                        })
                        return group;
                      })

                      study.children.map((studyElement) => {
                        if (studyElement.name === 'ProcessFlows') {
                          studyElement.children.map((classifier) => {
                            classifier.children.map((processGroup) => {
                              if (processGroup.processGroupId === this.state.node.processGroupId) {
                                processGroup.children.map(process => {
                                  that.state.itemList.filter(item => item.processFlowId === process.process.processId).forEach(item => {
                                    process.process.sortOrder = item.sortOrder;
                                  })
                                  return process;
                                })
                                processGroup.children = processGroup.children.sort((a,b) => a.process.sortOrder - b.process.sortOrder)
                              }
                              return processGroup;
                            })
                            return classifier;
                          })
                        }
                        return studyElement;
                      })
                      return study;
                    })
                    return project;
                  })

                  that.props.setTreeViewData(treeView);
                }

                if (typeof(response.data) !== 'undefined' && typeof(response.data.message) !== 'undefined') {
                  that.props.setMessage(response.data.message, response.data.messageType);
                }
                $(".loader").hide();
                that.state.closeEvent();
            })).catch(error => {
                that.props.setMessage("Process flow sorting failed!", MessageTypeConst.ERROR_MESSAGE);
                $(".loader").hide();
                return;
            });
    }

    render(){
        let itemList = this.state.itemList.sort((a, b) => a.sortOrder - b.sortOrder);
        return(
            <div className="annotate-contextmenu-sortApplyProcessFlow-dialog">
              <Dialog className="expression" title={`Process Flow Sorting - ${this.state.node.name}`} width="800px" height="auto" modal={true} onClose={this.state.closeEvent} buttons={[<Button primary={true} onClick={this.handleSaveClick} key="btn-save"> Save</Button>,<Button onClick={this.state.closeEvent} key="btn-cancel"> Cancel</Button>]}>
                <div className="sort-items">
                    <div className="list-wrapper m-t-10">
                      <ul className="list">
                        { itemList.map(this.displayItem) }
                      </ul>
                    </div>
                    <div className="button-wrapper m-b-10">
                      <Button primary={true} className="m-left m-l-0" onClick={this.handleItemUpEvent}> Up</Button>
                      <Button primary={true} className="m-left" onClick={this.handleItemDownEvent}> Down</Button>
                    </div>
                </div>
            </Dialog>
          </div>
        );
    }
}

const mapStateToProps = createSelector(
    state => state.treeView,
    (treeView) => ({
        treeView
    })
  );
  const mapActionsToProps = {
    setTreeViewData: setTreeViewData,
    setMessage: setMessage
  }


  Dialog.propTypes = {
      height: PropTypes.oneOfType([
          PropTypes.string
      ]),
      width: PropTypes.oneOfType([
          PropTypes.string
      ])
  }
  export default connect(mapStateToProps, mapActionsToProps)(ProcessFlowSorting);
