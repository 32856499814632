import { fetchJson } from './util/fetch';

export function setHeader(url, options = {}) {
		var initialHeader = {Accept: 'application/json'};

		if (localStorage.getItem("id_token") !== null) {
			global.login_token = {
				id_token: localStorage.getItem("id_token")
			}
			localStorage.clear()
		}
		if (typeof(global.login_token) !== 'undefined') {
			initialHeader = Object.assign({Authorization: 'Bearer '+ global.login_token.id_token}, initialHeader);
		}


		//onsole.log(global.login_token);
		var header = Object.assign(initialHeader, options);
		if (typeof(header.body) !== 'undefined') {
			delete header.body;
		}
		options.headers = new Headers(header);
		options.headers.set('Access-Control-Allow-Header', '*');

    return fetchJson(url, options);
}
