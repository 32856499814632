import React from "react";
import { CombineGrid } from '../../common';
import { Input } from './../../common/InputBox/StandardInput';

function getGlobalValue(controlIndex){
  let controlValue = '';
  global.processBlockControlValues.forEach((control, index) => {
    if (control.controlId === controlIndex) {
      controlValue = global.processBlockControlValues[index].controlValue;

    }
  });
  return controlValue;
}

function saveGlobalValue(controlIndex, value){
  global.processBlockControlValues.forEach((control, index) => {
    if (control.controlId === controlIndex) {
      global.processBlockControlValues[index].controlValue = value;
    }
  });
}

function CombineTableTemplate() {
  const RefNameIndex = 58;
  const RefDescriptionIndex = 59;
  let refName = getGlobalValue(RefNameIndex);
  let refDescription = getGlobalValue(RefDescriptionIndex);
  var handleRefNameChange = (event)=>{
    refName = event.target.value;
    saveGlobalValue(RefNameIndex,event.target.value);
    this.setState({...this.state.tableList});
  }
  var handleRefDescriptionChange = (event)=>{
    refDescription = event.target.value;
    saveGlobalValue(RefDescriptionIndex,event.target.value);
    this.setState({...this.state.tableList});
  }
  return (
    <div className="process-option reference">
      <div className="container-fluid">
        <div className="row">
          <div className="col-12">
            <div className="field_group">
              <div className="filter-area am-form-inline m-0">
                <div className="row">
                  <div className="col-md-6">
                    <label className="am-form-field">
                      <span>Reference Point Name:</span>
                      <Input onChange={handleRefNameChange} value={refName}/>
                    </label>
                  </div>
                  <div className="col-md-6">
                    <label className="am-form-field">
                      <span>Reference Point Description:</span>
                      <Input onChange={handleRefDescriptionChange} value={refDescription}/>
                    </label>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-12">
            <div className="field_group">
              <CombineGrid tenantID={this.state.tenantID}/>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CombineTableTemplate;
