import { SET_SUCCESS_MESSAGE, SET_WARNING_MESSAGE, SET_ERROR_MESSAGE, SET_INFORMATION_MESSAGE, SET_MESSAGE, SET_MESSAGE_ALONG_WITH_VISIBILITY_DURATION, RESET_MESSAGE } from '../actions/actionsTypes';
import MessageTypeConst from '../views/MessageTypeConst';
const INITIAL_DATA = {
  message: '',
  messageType: 0,
  visibilityDuration:5000
};

export default function notificationReducer(state=INITIAL_DATA, action) {
    switch (action.type) {
      case SET_SUCCESS_MESSAGE:
        return Object.assign({}, state, {message: action.payload,messageType: MessageTypeConst.SUCCESS_MESSAGE})
      case SET_WARNING_MESSAGE:        
        return Object.assign({}, state, {message: action.payload,messageType: MessageTypeConst.WARNING_MESSAGE})
      case SET_ERROR_MESSAGE:
        return Object.assign({}, state, {message: action.payload,messageType: MessageTypeConst.ERROR_MESSAGE})
      case SET_INFORMATION_MESSAGE:
        return Object.assign({}, state, {message: action.payload,messageType: MessageTypeConst.INFORMATION_MESSAGE})
      case SET_MESSAGE:
        return Object.assign({}, state, {message: action.payload.message,messageType: action.payload.messageType})
      case SET_MESSAGE_ALONG_WITH_VISIBILITY_DURATION:
        return Object.assign({}, state, {message: action.payload.message,messageType: action.payload.messageType, visibilityDuration: action.payload.visibilityDuration})
      case RESET_MESSAGE:
        return Object.assign({}, state, INITIAL_DATA)
        default:
        return state;
    }
  }