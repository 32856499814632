import React from "react";
import "../../Resource/DataProperties/DataProperties.css";
import { PropTypes } from "prop-types";
import Dialog from 'react-dialog';
import { Button } from './../DataOperation/common/Button/StandardButton';
import { Input } from './../DataOperation/common/InputBox/StandardInput';
import RichGridTable from '../RichGridTable';

function template() {
    return (
        <div className="data-properties manage-version" >
          <Dialog className="data-properties" title={'Version Manager'} width="98%" height="auto" modal={true} onClose={this.closeDialog} buttons={[<Button primary={true} onClick={this.updateVersionDetails} autoFocus={true} key="btn-save"> Save</Button>,<Button onClick={this.closeDialog} key="btn-cancel"> Cancel</Button>]}>
            <div className="am-form-line-left row">
              <label className="pro-label"><span className="f-w-600">Version Limit: </span></label>
              <Input type="number" value={this.state.dataVersionLimit} onChange={this.handleDataVersionChange} min="1"/>
            </div>
            <div className="m-b-10 richgrid-height-190">
              <RichGridTable {...this.state.tableList[0]} />
            </div>
          </Dialog>
        </div>
    )
}


Dialog.propTypes = {
    height: PropTypes.oneOfType([
        PropTypes.string
    ]),
    width: PropTypes.oneOfType([
        PropTypes.string
    ])
}
export default template;
