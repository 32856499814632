import React from 'react';
import BlockCreationType from '../../BlockCreationType';


export const BlockCreationTypeCell = ({ tableManager, value, data, column, colIndex, rowIndex }) => {
    if (typeof(tableManager) === 'undefined') {
      return (null)
    } else {
      return (
          <div className='text-center rgt-cell-inner' style={{alignItems: 'center', overflow: 'hidden'}}>
              <BlockCreationTypeCellClass dataItem={data} field={column.field} value={value}/>
          </div>
      )
    }
}
class BlockCreationTypeCellClass extends React.Component {

    getTitle = (dataItem) =>{
        let title = "";
        if(dataItem !== null && typeof(dataItem) !== "undefined"){
            switch(dataItem.blockCreationType){
                case BlockCreationType.CREATE_UPDATE_BLOCK:
                    title="Create/Update Block";
                    break;
                case BlockCreationType.IMPORT_SOURCE_TABLE:
                    title="Import Source table";
                    break;
                case BlockCreationType.IMPORT_META_TABLE:
                    title="Import Meta table";
                    break;
                case BlockCreationType.DATA_COPY:
                    title="Data Copy";
                    break;
                case BlockCreationType.DATA_LINK:
                    title="Data Link";
                    break;
                case BlockCreationType.DEF_COPY:
                    title="Copy Definition";
                break;
                case BlockCreationType.IMPORT_SOURCE_DOCUMENT:
                    title="Import Source Document";
                break;
                case BlockCreationType.TIMELINE:
                    title="Timeline";
                break;
                case BlockCreationType.MAP_ANNOTATE:
                    title="AUTO";
                    break;
                default:
                    break;
            }
        }
        return title;
    }

    handleChange(e) {
        this.props.dataItem[this.props.field] = this.getTitle(this.props.dataItem);
        this.props.tableManager.rowsApi.setRows(this.props.tableManager.rowsApi.rows)
    }

    render() {
        let title = this.getTitle(this.props.dataItem);
        return (
            <div className="text-center">
              <span>{title}</span>
            </div>
        );
    }
}
