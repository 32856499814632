import React from "react";
import { DropDownList } from './../../common/DropDown/StandardDropDownList';
import { ComboBox } from './../../common/DropDown/StandardComboBox';
import { Input } from './../../common/InputBox/StandardInput';

function getGlobalValue(controlIndex){
  let controlValue = '';
  global.processBlockControlValues.forEach((control, index) => {
    if (control.controlId === controlIndex) {
      controlValue = global.processBlockControlValues[index].controlValue;

    }
  });
  return controlValue;
}

function saveGlobalValue(controlIndex, value){
  global.processBlockControlValues.forEach((control, index) => {
    if (control.controlId === controlIndex) {
      global.processBlockControlValues[index].controlValue = value;
    }
  });
}

function OneTableTemplate() {
  const RefNameIndex = 58;
  const RefDescriptionIndex = 59;
  const StudyIdIndex = 60;
  const SubjectIdIndex = 61;
  const GroupByIndex = 62;
  const FlagForRefNameIndex = 63;
  const StartOptionIndex = 64;
  const StartExpressionIndex = 65;
  const StartPrefixIndex = 66;
  const StartRuleIndex = 67;
  const StartSuffixIndex = 68;
  const StopOptionIndex = 69;
  const StopExpressionIndex = 70;
  const StopPrefixIndex = 71;
  const StopRuleIndex = 72;
  const StopSuffixIndex = 73;
  const StartDateIndex = 4;
  const StopDateIndex = 5;
  const LeftColumnArrayIndex = 53;
  let startDate = getGlobalValue(StartDateIndex);
  let stopdate = getGlobalValue(StopDateIndex);
  let refName = getGlobalValue(RefNameIndex);
  let refDescription = getGlobalValue(RefDescriptionIndex);
  let studyId = getGlobalValue(StudyIdIndex);
  let subjectId =getGlobalValue(SubjectIdIndex);
  let groupBy = getGlobalValue(GroupByIndex);
  let chkRefName = getGlobalValue(FlagForRefNameIndex) !== "" ? getGlobalValue(FlagForRefNameIndex) : true;
  let chkStartExpression = false;
  let chkStartDateInfinite = false;
  let chkStartDate = false;
  let startExpression = getGlobalValue(StartExpressionIndex);
  let startPrefix = getGlobalValue(StartPrefixIndex);
  let startRule = getGlobalValue(StartRuleIndex) !== "" ? getGlobalValue(StartRuleIndex) :"Autogenerated Number";
  let startSuffix = getGlobalValue(StartSuffixIndex);
  let chkStopSingleValue = false;
  let chkStopExpression = false;
  let chkStopDateInfinite= false;
  let chkStopDate= false;
  let stopExpression = getGlobalValue(StopExpressionIndex);
  let stopPrefix = getGlobalValue(StopPrefixIndex);
  let stopRule = getGlobalValue(StopRuleIndex) !== "" ? getGlobalValue(StopRuleIndex) :"Autogenerated Number";;
  let stopSuffix = getGlobalValue(StopSuffixIndex);
  let disabledStopOptions = false;
  let dateColumns = [];
  let disableStartExpression = false;
  let disableStartDateOtherOption = false;
  let disableStopExpression = false;
  let disableStopDateOtherOption = false;
  let leftcolumns = [];
  saveGlobalValue(FlagForRefNameIndex, chkRefName);
  saveGlobalValue(StartRuleIndex, startRule);
  saveGlobalValue(StopRuleIndex, stopRule);
  if(this.state.tableList){
    //Continues Date operation is applicable to single block only
    if(this.state.tableList[0]){
      let dateObjects = this.state.tableList[0].rows.filter(row=>row.type === 'DATE' || row.type === 'DATETIME');
      dateColumns = dateObjects.map(a=>a.column);
      //saving data
      saveGlobalValue(3, JSON.stringify(this.state.tableList[0].rows));
    }
  }
  //populating left table columns
  if(this.state.tableList){
    //Continues Date operation is applicable to single block only
    if(this.state.tableList[0]){
      this.state.tableList[0].rows.forEach((row)=>{
        leftcolumns = [...leftcolumns, row.column];
      });
      saveGlobalValue(LeftColumnArrayIndex, JSON.stringify(leftcolumns));
    }
  }

  var ChangeStopDateOptionBasedOnStartDate = () =>{
    if(chkStartDate){
      chkStopExpression = true;
      saveGlobalValue(StopOptionIndex,"Expression");
      setStopOption();
      disabledStopOptions = true;
    } else {
      disabledStopOptions = false;
    }
  }
  var setStartOption = () =>{
    let option = getGlobalValue(StartOptionIndex);
    disableStartExpression = true;
    disableStartDateOtherOption = false;
    switch(option){
      case "Expression":
      disableStartExpression = false;
      chkStartExpression = true;
      chkStartDateInfinite = false;
      chkStartDate = false;
      break;
      case "Startdate Infinite":
      disableStartDateOtherOption = true;
      chkStartExpression = false;
      chkStartDateInfinite = true;
      chkStartDate = false;
      break;
      case "Start Date - First Date after precious Date":
      chkStartExpression = false;
      chkStartDateInfinite = false;
      chkStartDate = true;
      break;
      default:
      disableStartExpression = false;
      chkStartExpression = true;
      chkStartDateInfinite = false;
      chkStartDate = false;
      saveGlobalValue(StartOptionIndex, "Expression");
      break;
    }
    ChangeStopDateOptionBasedOnStartDate();
  }
  var setStopOption = () =>{
    let option = getGlobalValue(StopOptionIndex);
    disableStopExpression = true;
    disableStopDateOtherOption = false;
    switch(option){
      case "Expression":
      disableStopExpression = false;
      chkStopDate = false;
      chkStopDateInfinite = false;
      chkStopExpression = true;
      chkStopSingleValue = false;
      break;
      case "Stopdate Infinite":
      disableStopDateOtherOption = true;
      chkStopDate = false;
      chkStopDateInfinite = true;
      chkStopExpression = false;
      chkStopSingleValue = false;
      break;
      case "Single Value for Stopdate":
      chkStopDate = false;
      chkStopDateInfinite = false;
      chkStopExpression = false;
      chkStopSingleValue = true;
      break;
      case "Last date before next Ref start":
      chkStopDate = true;
      chkStopDateInfinite = false;
      chkStopExpression = false;
      chkStopSingleValue = false;
      break;
      default:
      disableStopExpression = false;
      chkStopDate = false;
      chkStopDateInfinite = false;
      chkStopExpression = true;
      chkStopSingleValue = false;
      saveGlobalValue(StopOptionIndex, "Expression");
      break;
    }
  }
  setStopOption();
  setStartOption();
  var clearStartDateValue = () =>{
    saveGlobalValue(StartPrefixIndex, "");
    saveGlobalValue(StartSuffixIndex,"");
    saveGlobalValue(StartExpressionIndex,"");
    saveGlobalValue(StartDateIndex,"");
    saveGlobalValue(StartRuleIndex,"Autogenerated Number");
  }
  var clearStopDateValue = () =>{
    saveGlobalValue(StopPrefixIndex, "");
    saveGlobalValue(StopSuffixIndex,"");
    saveGlobalValue(StopExpressionIndex,"");
    saveGlobalValue(StopDateIndex,"");
    saveGlobalValue(StopRuleIndex,"Autogenerated Number");
  }

  var handleRefNameChange = (event)=>{
    refName = event.target.value;
    saveGlobalValue(RefNameIndex,event.target.value);
    this.setState({...this.state.tableList});
  }
  var handleRefDescriptionChange = (event)=>{
    refDescription = event.target.value;
    saveGlobalValue(RefDescriptionIndex,event.target.value);
    this.setState({...this.state.tableList});
  }
  var handleSubjectIdChange = (event)=>{
    subjectId = event.target.value === null ? "" : event.target.value;
    saveGlobalValue(SubjectIdIndex,subjectId);
    this.setState({...this.state.tableList});
  }
  var handleStudyIdChange = (event)=>{
    studyId = event.target.value === null ? "" : event.target.value;
    saveGlobalValue(StudyIdIndex,studyId);
    this.setState({...this.state.tableList});
  }
  var handleGroupByChange = (event)=>{
    groupBy = event.target.value === null ? "" : event.target.value;
    saveGlobalValue(GroupByIndex,groupBy);
    this.setState({...this.state.tableList});
  }
  var handleRefNameCheckBoxChange = (event)=>{
    chkRefName = event.target.checked;
    saveGlobalValue(FlagForRefNameIndex,chkRefName);
    this.setState({...this.state.tableList});

  }

  var handleStartExpressionChange = (event)=>{
    startExpression = event.target.value;
    saveGlobalValue(StartExpressionIndex,event.target.value);
    this.setState({...this.state.tableList});
  }
  var handleStartPrefixChange = (event)=>{
    startPrefix = event.target.value;
    saveGlobalValue(StartPrefixIndex,event.target.value);
    this.setState({...this.state.tableList});
  }
  var handleStartSuffixChange = (event) =>{
    startSuffix = event.target.value;
    saveGlobalValue(StartSuffixIndex,event.target.value);
    this.setState({...this.state.tableList});
  }
  var handleStartRuleChange = (event) => {
    startRule = event.target.value;
    saveGlobalValue(StartRuleIndex,event.target.value);
    this.setState({...this.state.tableList});
  }
  var handleStartDateChange = (event) =>{
    startDate = typeof(event.target.value) === 'undefined' || event.target.value === null ? "" : event.target.value;
    saveGlobalValue(StartDateIndex,startDate);
    this.setState({...this.state.tableList});
  }
  var handleStopExpressionChange = (event) => {
    stopExpression = event.target.value;
    saveGlobalValue(StopExpressionIndex,event.target.value);
    this.setState({...this.state.tableList});
  }
  var handleStopPrefixChange = (event) =>{
    stopPrefix = event.target.value;
    saveGlobalValue(StopPrefixIndex,event.target.value);
    this.setState({...this.state.tableList});
  }
  var handleStopSuffixChange = (event) =>{
    stopSuffix = event.target.value;
    saveGlobalValue(StopSuffixIndex,event.target.value);
    this.setState({...this.state.tableList});
  }
  var handleStopRuleChange = (event) =>{
    stopRule = event.target.value;
    saveGlobalValue(StopRuleIndex,event.target.value);
    this.setState({...this.state.tableList});
  }
  var handleStopDateChange = (event) =>{
    stopdate = typeof(event.target.value) === 'undefined' || event.target.value === null ? "" : event.target.value;
    saveGlobalValue(StopDateIndex,stopdate);
    this.setState({...this.state.tableList});
  }
  var handleStartOptionChange = (event)=>{
    switch(event.target.id){
      case "reference-start-expression":
      saveGlobalValue(StartOptionIndex,"Expression");
      break;
      case "reference-start-date-infinite":
      saveGlobalValue(StartOptionIndex,"Startdate Infinite");
      break;
      case "reference-start-date":
      saveGlobalValue(StartOptionIndex,"Start Date - First Date after precious Date");
      break;
      default:
      break;
    }
    clearStartDateValue();
    setStartOption();
    this.setState({...this.state.tableList});
  }
  var handleStopOptionChange = (event) =>{
    switch(event.target.id){
      case "reference-stop-date-single":
      saveGlobalValue(StopOptionIndex,"Single Value for Stopdate");
      break;
      case "reference-stop-date-infinite":
      saveGlobalValue(StopOptionIndex,"Stopdate Infinite");
      break;
      case "reference-stop-date-expression":
      saveGlobalValue(StopOptionIndex,"Expression");
      break;
      case "reference-stop-date":
      saveGlobalValue(StopOptionIndex,"Last date before next Ref start");
      break;
      default:
      break;
    }
    clearStopDateValue();
    setStopOption();
    this.setState({...this.state.tableList});
  }

  return (
    <div className="process-option reference">
      <fieldset>
        <div className="field_group">
          <legend>Table Options</legend>
          <div className="">
            <div className="">
              <div className="filter-area am-form-inline col-8 m-0">
                <label className="am-form-field">
                  <span className="col-md-4">Reference Point Name:</span>
                  <Input onChange={handleRefNameChange} value={refName}/>
                </label>
                <label className="am-form-field">
                  <span className="col-md-4">Reference Point Description:</span>
                  <Input onChange={handleRefDescriptionChange} value={refDescription} />
                </label>
                <label className="am-form-field">
                  <span className="col-md-4">Study Id:</span>
                  <ComboBox className="width-full m-0" data={this.state.inputColumns} onChange={handleStudyIdChange} value={studyId}/>
                </label>
                <label className="am-form-field">
                  <span className="col-md-4">Subject Id:</span>
                  <ComboBox className="width-full m-0" data={this.state.inputColumns} onChange={handleSubjectIdChange} value={subjectId}/>
                </label>
                <label className="am-form-field">
                  <span className="col-md-4">Group By Variable:</span>
                  <ComboBox className="width-full m-0" data={this.state.inputColumns} onChange={handleGroupByChange} value={groupBy} />
                </label>
                <label className="am-form-field">
                  <input type="checkbox" id="reference-point-checkbox" className="am-checkbox" checked={chkRefName}  onChange={handleRefNameCheckBoxChange}/>
                  <label className="am-checkbox-label" htmlFor="reference-point-checkbox">Check to name reference point labels according to name entered above</label>
                </label>
                <label className="am-form-field">
                  <span className="col-md-6 note">*By default group var values will be used</span>
                </label>
              </div>
            </div>
          </div>
        </div>
      </fieldset>
      <fieldset>
        <div className="field_group">
          <legend>Start Reference Options</legend>
          <div className="">
            <div className="row">
              <div className="col-md-6">
                <div className="am-form-inline">
                  <label className="am-form-field">
                    <span className="col-5 align_self">Value:</span>
                    <div>
                      <div>
                        <input type="radio" name="reference-start-value" id="reference-start-expression" className="am-radio"
                        checked={chkStartExpression} onChange={handleStartOptionChange}/>
                        <label className="am-radio-label" htmlFor="reference-start-expression">Expression</label>
                      </div>
                      <div>
                        <input type="radio" name="reference-start-value" id="reference-start-date-infinite" className="am-radio"
                        checked={chkStartDateInfinite}  onChange={handleStartOptionChange}/>
                        <label className="am-radio-label" htmlFor="reference-start-date-infinite">Startdate Infinite</label>
                      </div>
                      <div>
                        <input type="radio" name="reference-start-value" className="am-radio" id="reference-start-date"
                        checked={chkStartDate}  onChange={handleStartOptionChange}/>
                        <label className="am-radio-label" htmlFor="reference-start-date">Start Date - First Date after previous Date</label>
                        </div>
                      </div>
                  </label>
                </div>
              </div>
              <div className="col-md-6">
              <div className="am-form-inline">
                <label className="am-form-field">
                  <span>Reference Start Expression:</span>
                  <Input value={startExpression} onChange={handleStartExpressionChange} disabled = {disableStartExpression}/>
                </label>
                </div>
              </div>
            </div>
              <div className="filter-area am-form-inline col-md-8 m-0">
                <label className="am-form-field">
                  <span className="col-4"><b>Start Date Variable:</b></span>
                  <ComboBox className="width-full" data={dateColumns} value={startDate} onChange={handleStartDateChange} disabled={disableStartDateOtherOption} />
                </label>
              </div>
              <div className="filter-area am-form-inline col-md-8 m-0">
              <label className="am-form-field">
                  <span className="col-md-4">Label</span>
                    <div>
                      <Input value={startPrefix} onChange={handleStartPrefixChange} disabled={disableStartDateOtherOption}/>
                    </div>
                    <div className="col-md-3">
                      <DropDownList className="width-full" data={["Autogenerated Number", "Group Variable"]} onChange={handleStartRuleChange}
                      value={startRule} />
                    </div>
                    <div className="col-md-2">
                      <Input value={startSuffix} onChange={handleStartSuffixChange} disabled={disableStartDateOtherOption} />
                    </div>
                </label>
              </div>
          </div>
        </div>
      </fieldset>
      <fieldset>
        <div className="field_group m_b_0">
          <legend>Stop Reference Options</legend>
          <div className="">
            <div className="row">
              <div className="col-md-6">
                <div className="am-form-inline">
                  <label className="am-form-field">
                    <span className="col-5 align_self"><b>Value:</b></span>
                    <div>
                      <div>
                        <input type="radio" name="reference-stop-value" id="reference-stop-date-single" className="am-radio"
                        checked={chkStopSingleValue}  onChange={handleStopOptionChange} disabled={disabledStopOptions}/>
                        <label className="am-radio-label" htmlFor="reference-stop-date-single">Single Value for Stopdate</label>
                      </div>
                      <div>
                        <input type="radio" name="reference-stop-value" className="am-radio" id="reference-stop-date-infinite"
                        checked={chkStopDateInfinite} onChange={handleStopOptionChange} disabled={disabledStopOptions}/>
                        <label className="am-radio-label" htmlFor="reference-stop-date-infinite">Stopdate Infinite</label>
                      </div>
                      <div>
                        <input type="radio" name="reference-stop-value" id="reference-stop-date-expression" className="am-radio"
                        checked={chkStopExpression} onChange={handleStopOptionChange}/>
                        <label className="am-radio-label" htmlFor="reference-stop-date-expression">Expression</label>
                      </div>
                      <div>
                        <input type="radio" name="reference-stop-value" id="reference-stop-date" className="am-radio"
                        checked={chkStopDate} onChange={handleStopOptionChange} disabled={disabledStopOptions}/>
                        <label className="am-radio-label" htmlFor="reference-stop-date">Last date before next Ref start</label>
                      </div>
                    </div>
                  </label>
                </div>
              </div>
              <div className="col-md-6">
                <div className="am-form-inline">
                  <label className="am-form-field ref_Expression">
                    <span>Reference Stop Expression:</span>
                    <Input value={stopExpression} onChange={handleStopExpressionChange} disabled={disableStopExpression}/>
                  </label>
                </div>
              </div>
            </div>
              <div className="filter-area am-form-inline col-md-8 m-0">
                  <label className="am-form-field">
                    <span className="col-md-4"><b>Stop Date Variable:</b></span>
                    <ComboBox className="width-full" data={dateColumns}
                    onChange={handleStopDateChange} value={stopdate} disabled={disableStopDateOtherOption}/>
                  </label>
                </div>
                <div className="filter-area am-form-inline col-md-8 m-0">
                  <label className="am-form-field">
                    <span className="col-md-4">Label</span>
                      <div>
                      <Input onChange={handleStopPrefixChange} value={stopPrefix} disabled={disableStopDateOtherOption}/>
                      </div>
                      <div className="col-md-3">
                        <DropDownList className="width-full" data={["Autogenerated Number", "Group Variable"]}
                        onChange={handleStopRuleChange} value={stopRule} disabled={disableStopDateOtherOption}/>
                      </div>
                      <div className="col-md-2">
                        <Input onChange={handleStopSuffixChange} value={stopSuffix} disabled={disableStopDateOtherOption}/>
                      </div>
                  </label>
                </div>
          </div>
        </div>
      </fieldset>
    </div>
  );
};

export default OneTableTemplate;
