import React from 'react';
import { Input } from './StandardInput';

export const ExecuteAllPreWhereInput = ({ tableManager, value, data, column, colIndex, rowIndex }) => {
    if (typeof(tableManager) === 'undefined') {
      return (null)
    } else {
      return (
          <div className='rgt-cell-inner' style={{display: 'flex', alignItems: 'center', overflow: 'hidden'}}>
              <ExecuteAllPreWhereInputClass dataItem={data} field={column.field} value={typeof(data[column.field]) !== 'undefined' ? data[column.field]: value} column={column} tableManager={tableManager}/>
          </div>
      )
    }
}

class ExecuteAllPreWhereInputClass extends React.Component {

    handleChange(e) {
      this.props.dataItem[this.props.field] = e.target.value;
      this.props.tableManager.rowsApi.setRows(this.props.tableManager.rowsApi.rows)
    }
    render() {
        let disabled = false;
        if((this.props.field === "rightWhereCondition" && this.props.dataItem.inputTable.split(",").length <= 1) || (['rightWhereCondition', 'leftWhereCondition'].indexOf(this.props.field) >= 0 && this.props.dataItem.operation === 'Split Table')){
          disabled = true;
        }
        return (
            <>
              <Input className="width-full" onChange={this.handleChange.bind(this)} value={this.props.dataItem[this.props.field]} length={40} disabled={disabled}/>
            </>
        );
    }
}
