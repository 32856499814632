// @flow

import React from "react";
import { Button } from './../../DataOperation/common/Button/StandardButton';
import type { T_Highlight } from "../../src/types";
type T_ManuscriptHighlight = T_Highlight;

type Props = {
  highlights: Array<T_ManuscriptHighlight>,
  resetHighlights: () => void,
  scrollViewerTo: () => void,
  selectChangeHighlight: () => void
};
/*
<div className="description">
  <p>
    <small>
      To edit the annotation press ⌥ Option key (Alt), then click on the annotation.
    </small>
  </p>
</div>

*/

function Sidebar({ highlights, isAllPages, isFindMode, setIsAllPages, resetHighlights, scrollViewerTo, selectChangeHighlight, searchAndHighlightPage, highlightAndAnnotate, clearHighlight, isSelectedAll, type, closeDialog }: Props) {
  const selectedButtonText = isSelectedAll ? "Select All" : "Unselect All";
  let displyPageNumber = [];
  return (
    <div className="sidebar col-3 float-left align-left" style={{ height: '80vh'}}>
      {highlights.length > 0 &&
        <div className="control-panel">
          {type === 'auto' &&
            <div>
              <Button className={`${isFindMode ? ' find-mode': ''}`} primary={true} onClick={() => {searchAndHighlightPage()}}>Find Next</Button>
              <Button primary={true} className="reset m-l-5" onClick={() => {highlightAndAnnotate()}} >Annotate</Button>
              <Button className="m-l-5" onClick={() => {clearHighlight()}} >Clear</Button>
            </div>
          }
          {type === 'tag' &&
            <Button className={`${isFindMode ? ' find-mode': ''}`} primary={true} onClick={(event) => {closeDialog(event, true)}}>Save</Button>
          }

          <hr className="line" />
          <Button primary={true} onClick={() => {selectChangeHighlight('')}} >{selectedButtonText}</Button>
          <Button className="m-l-5" onClick={() => {resetHighlights(false)}}>Delete</Button>
          <Button primary={true} className="reset m-l-5" onClick={() => {resetHighlights()}}>Reset</Button>
        </div>
      }
      {type === 'tag' && highlights.length === 0 &&
        <div className="control-panel">
          <Button className={`${isFindMode ? ' find-mode': ''}`} primary={true} onClick={(event) => {closeDialog(event, true)}}>Save</Button>
        </div>
      }
      <ul className={`sidebar__highlights`}>
        {highlights.filter(highlight => highlight.parentId === null).map((highlight, index) => (
          <li
            key={index}
            className={`sidebar__highlight ${isFindMode && highlight.selected ? ' find-mode': ''}`}
          >
            <div>
              <strong><input type="checkbox" id={`checkbox-highlight-${index}`} onChange={()=>{selectChangeHighlight(highlight.id)}} checked={highlight.selected} /> {`${index+1}`}) Highlight:</strong>
              {highlight.content.text ? (
                <blockquote>
                  {`${highlight.content.text.trim()}`}
                </blockquote>
              ) : <div>---</div>}
              <strong>Annotate Variable:</strong>
              {typeof(highlight.data) !== 'undefined' && highlight.data.title ? (
                <blockquote>
                  {`${highlight.data.title.trim()}`}
                </blockquote>
              ) : <div>---</div>}
              {highlight.content.image ? (
                <div
                  className="highlight__image"
                  style={{ marginTop: "0.5rem" }}
                >
                  <img src={highlight.content.image} alt={"Screenshot"} />
                </div>
              ) : null}
            </div>
            <div className="highlight__location">
              Page <span className="page-title" onClick={() => {
                scrollViewerTo(highlight);
              }}>{highlight.position.pageNumber}</span>
            {highlights.filter(childHighlight => childHighlight.parentId === highlight.id && childHighlight.position.pageNumber !== highlight.position.pageNumber).map((childHighlight, childIndex) => {
              if (displyPageNumber.indexOf(highlight.id+'-'+ childHighlight.position.pageNumber) === -1) {
                displyPageNumber.push(highlight.id+'-'+ childHighlight.position.pageNumber)
                return (
                  <span className="page-title" key={`page-number-${highlight.parentId}-${childIndex}`} onClick={() => {
                    scrollViewerTo(childHighlight);
                  }}>{childHighlight.position.pageNumber}</span>
                )
              } else {
                return (<span className="hidden" key={`page-number-${highlight.parentId}-${childIndex}`} />)}

            })}
            </div>
          </li>
        ))}
      </ul>

    </div>
  );
}

export default Sidebar;
