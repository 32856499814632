class DataOpsTitleConstants {
    constructor(props){
        this.SPEC_TAB_TITLE = "Note";
        this.PROCESS_OPTION_TAB_TITLE = "Process Options";
        this.CODE_TAB_TITLE = "Code";
        this.LOG_TAB_TITLE = "Log";
        this.LIST_TAB_TITLE = "List";
        this.DATA_EXPLORER_TAB_TITLE = "Data Explorer";
        this.FILE_EXPLORER_TAB_TITLE = "File Explorer";
        this.PRE_WHERE_TAB_TITLE = "Pre-Where";
        this.STRING_OPS_ALIGN_ACTION_TITLE = "String Ops (Align)";
        this.STRING_OPS_INSERT_SPACE_ACTION_TITLE = "String Ops (Insert Space)";
        this.STRING_OPS_TRIM_ACTION_TITLE = "String Ops (Trim)";
        this.STRING_OPS_CASE_ACTION_TITLE = "String Ops (Case)";
        this.STRING_OPS_SEARCH_ACTION_TITLE = "String Ops (Search)";
        this.STRING_OPS_OCCURRENCE_ACTION_TITLE = "String Ops (Occurence)";
        this.STRING_OPS_SPLIT_ACTION_TITLE = "String Ops (Split)";
        this.TABLE_RENAME_ACTION_TITLE = "Table Ops (Rename/New)";
        this.TABLE_LENGTH_ADJ_ACTION_TITLE = "Table Ops (Length Adjustment)";
        this.TYPE_CONVERSION_DATETIME_ACTION_TITLE = "TypeConversion (DateTime)";
        this.TYPE_CONVERSION_CHARNUM_ACTION_TITLE = "TypeConversion (CharNum)";
        this.DECOLLAPSE_ONE_ACTION_TITLE = "Decollapse (One)";
        this.DECOLLAPSE_TWO_ACTION_TITLE = "Decollapse (Two)";
        this.RANK_OPS_ACTION_TITLE = "RankOps";
        this.SEQUENCE_OPS_ACTION_TITLE = "SequenceOps";
        this.REF_POINT_ONE_ACTION_TITLE = "Reference Point (One)";
        this.REF_POINT_TWO_ACTION_TITLE = "Reference Point (Two)";
        this.PIVOT_ROW_TO_COL_ACTION_TITLE = "Pivot (Row To Column)";
        this.PIVOT_COL_TO_ROW_ACTION_TITLE = "Pivot (Column To Row)";
        this.PIVOT_MARIX_ACTION_TITLE = "Pivot Metrics";
        this.CODE_EDITOR_FILE_ACTION_TITLE = "Code Editor (Report)";
        this.CODE_EDITOR_TABLE_ACTION_TITLE = "Code Editor (Table)";
        this.REPO_CUSTOM_ACTION_TITLE = "ReportingOps (Custom)";
        this.REPO_DES_ACTION_TITLE = "ReportingOps (Descriptive)";
        this.REPO_CAT_ACTION_TITLE = "ReportingOps (Categorical)";
        this.REPO_CAT_TOTAL_ACTION_TITLE = "ReportingOps (Categorical Total)";
        this.REPO_CAT_UNIQUE_ACTION_TITLE = "ReportingOps (Categorical Total Unique Per Id)";
        this.REPO_CAT_HIERARCHICAL_ACTION_TITLE = "ReportingOps (Categorical Hierarchical)";
        this.REPO_CAT_HIERARCHICAL_UNIQUE_ACTION_TITLE = "ReportingOps (Categorical Hierarchical Unique Per Id)";
        this.POST_INITIALIZATION_TAB_TITLE = "Post Initialize";
        this.RULE_TAB_TITLE= "Variable Rule";
        this.EXPLORATORY_TAB_TITLE = "Exploratory";
        this.GRAPH_TAB_TITLE = "Graph";
    }
}

export default (new DataOpsTitleConstants());
