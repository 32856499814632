import React    from "react";
import { PropTypes } from "prop-types";
import Dialog from 'react-dialog';
import axios from 'axios';
import $ from 'jquery';
import { connect } from 'react-redux';
import { createSelector } from 'reselect';
import restClient from '../../../restClient';
import { GET, POST_PARAM_BODY, CREATE } from '../../../restClient/types';
import { setMessage } from './../../../actions/actionNotification';
import MessageTypeConst from '../../MessageTypeConst';
import GenericStudyProperties from './GenericStudyProperties';
import { Button } from './../DataOperation/common/Button/StandardButton';
import { TabStrip, TabStripTab  } from './../DataOperation/common/Layout';
import VersionStudyProperties from './VersionStudyProperties';
import { setStudyDescription } from './../../../actions/actionStudy';
import AccessControlConstants from './../../AccessControlConstants';
import TagManagement from './../TagManagement';
import TagContantType from "./../TagManagement/TagContantType";
import ConfirmBox from './../DataOperation/common/ConfirmBox';

class StudyProperties extends React.Component {

    constructor(props){
        super(props);
        this.versionPropertiesRef = null;
        this.state = {
            studyProperty : this.props.studyProperty,
            selected : 0,
            versionSettings : {
                sourceDataLimit : 0,
                metaDataLimit : 0,
                blockDataLimit : 0,
                blockDefLimit : 0,
                description: this.props.studyProperty.description,
                studyId: this.props.studyProperty.studyId
            },
            handleRenameStudy: props.handleRenameStudy
        }

        this.removeTagRichGrid();
    }

    fetchStudyProperty = (params, headers, studyName, projectName) => restClient(GET, 'studyProperties?studyName='+studyName+'&projectName='+projectName, params, headers);

    saveStudyProperty = (params, headers) => restClient(CREATE,'update/studyProperties',params,headers);

    saveTagProperty = (params, headers) => restClient(CREATE,'tag/saveUpdate', params,headers);

    closeDialog = () =>{
      this.removeTagRichGrid();
      this.props.closeEvent();
    }

    handleSelect = (e) => {
        this.setState({selected: e.selected});
    }

    handleDescriptionChange = (event) => {
      let versionSettings = this.state.versionSettings;
      versionSettings.description = event.target.value;
      this.setState({versionSettings: versionSettings})
    }

    handleStudyNameChange = (event) => {
      let studyProperty = this.state.studyProperty;
      studyProperty.studyName = event.target.value;
      this.setState({studyProperty: studyProperty})
    }

    componentDidMount(){
        let studyProperty = this.state.studyProperty;
        let versionSettings = this.state.versionSettings;
         $(".loader").show();
        axios.all([this.fetchStudyProperty({}, {'Tenant-ID': this.props.tenantId}, this.state.studyProperty.studyName, this.state.studyProperty.projectName)])
            .then(axios.spread((response) => {
                if (typeof(response.data) !== 'undefined') {
                studyProperty.importDirectory = response.data.importDirectory;
                versionSettings.sourceDataLimit = response.data.sourceDataLimit;
                versionSettings.metaDataLimit = response.data.metaDataLimit;
                versionSettings.blockDataLimit = response.data.blockDataLimit;
                versionSettings.blockDefLimit = response.data.blockDefLimit;
                this.setState({ studyProperty: studyProperty, versionSettings : versionSettings});
                }

                $(".loader").hide();
            })
        ).catch(error => {
        $(".loader").hide();
        this.props.setMessage("Error occurred while fetching study properties.",MessageTypeConst.ERROR_MESSAGE);
        });
    }

    handleSaveButtonClick = (event) => {
        let versions = this.versionPropertiesRef
        let message = "";
        let isValid = true;
        if(versions !== null && typeof(versions) !== "undefined"){
            versions = versions.getWrappedInstance().getVersionSettings();

            if(versions.sourceDataLimit === null || versions.sourceDataLimit === "" || versions.sourceDataLimit <= 0 || !Number.isInteger(versions.sourceDataLimit)){
                message = message + "Source table, ";
                isValid = false;
            }
            if(versions.metaDataLimit === null || versions.metaDataLimit === "" || versions.metaDataLimit <= 0 || !Number.isInteger(versions.metaDataLimit)){
                message = message + "Meta table, ";
                isValid = false;
            }
            if(versions.blockDataLimit === null || versions.blockDataLimit === "" || versions.blockDataLimit <= 0 || !Number.isInteger(versions.blockDataLimit)){
                message = message + "Block data, ";
                isValid = false;
            }
            if(versions.blockDefLimit === null || versions.blockDefLimit === "" || versions.blockDefLimit <= 0 || !Number.isInteger(versions.blockDefLimit)){
                message = message + "Block definition, ";
                isValid = false;
            }
        } else {
          versions = this.state.versionSettings
        }

        if(!isValid){
            message = message.substr(0, message.length - 2);
            message = message + "  version limit must be integer greater than 0.";
            ConfirmBox.alert(message);
            return;
        }else if(parseInt(versions.blockDataLimit, 10) > parseInt(versions.blockDefLimit, 10)){
            ConfirmBox.alert("Data version limit can't be greater than Definition version limit.");
            return;
        }else{
            this.saveProperty();
        }
    }

    saveProperty = () => {
        let tagList = this.getTagList(this.state.versionSettings.studyId, TagContantType.STUDY)

        if (tagList.length > 0 && this.validateTagList(tagList) === false) {
          this.props.setMessage("Custom tag property title should be unique.",MessageTypeConst.WARNING_MESSAGE);
          return false;
        }

        $(".loader").show();
        axios.all(tagList.length === 0 ?
          [this.saveStudyProperty({data:this.state.versionSettings}, {'Tenant-ID': this.props.tenantId})]:
          [this.saveStudyProperty({data:this.state.versionSettings}, {'Tenant-ID': this.props.tenantId}), this.saveTagProperty({data: tagList}, {'Tenant-ID': this.props.tenantId})]
        )
            .then(axios.spread((response, responseTag) => {
                if (typeof(response.data) !== 'undefined') {
                    this.props.setStudyDescription({studyId: this.state.versionSettings.studyId, description: this.state.versionSettings.description})
                    this.state.handleRenameStudy(response.data.message, response.data.messageType)
                } else {
                  $(".loader").hide();
                }
            })
        ).catch(error => {
        $(".loader").hide();
          this.closeDialog();
          this.props.setMessage("Error occurred while saving study properties.",MessageTypeConst.ERROR_MESSAGE);
        });
    }

    getTagList = (objectId, objectType) => {
      let tagList = []
      if (typeof(global.gridRef) !== 'undefined' && global.gridRef.length > 0) {
        global.gridRef.filter(item => item.props.refs === 'tag-management-grid').forEach(item => {
          let tagType = '';
          item.props.data.filter(tag => ((tag.value === '' && tag.tagId > 0) || tag.value !== '') && tag.property !== '').forEach(tag => {
            TagContantType.list.filter(element => element[1] === tag.type).forEach(element => {
              tagType = element[0]
            })

            let studyAnnotateList = [];
            let additionalTagList = typeof(tag.nodeTagList) !== 'undefined' ? tag.nodeTagList : [];

            if (tagType === 2 && tag.value !== '') {
              if (typeof(tag.value) === 'string') {
                const tagValue = JSON.parse(tag.value)
                if (typeof(tagValue.highlightList) !== 'undefined') {
                    tag.value = tagValue.highlightList
                }
              }
              tag.value.forEach(tagAnnotate => {
                if (tagAnnotate.data.id === 'navigation_annotate') {
                  if (studyAnnotateList.filter(nodeHighlight => nodeHighlight.content.text === tagAnnotate.content.text).length === 0) {
                    studyAnnotateList.push(tagAnnotate)
                  }
                } else if(tagAnnotate.data.id.indexOf('_group_') > 0 && tagAnnotate.data.id.indexOf('_flow_') > 0 && tagAnnotate.data.id.indexOf('_block_') > 0) {
                  const additionalObjectId = eval(tagAnnotate.data.id.split('_block_')[1].replace('_annotate', ''));

                  let additionalTag = additionalTagList.filter(additionalTag => additionalTag.objectId === additionalObjectId && additionalTag.objectType === TagContantType.PROCESS_BLOCK)
                  if (additionalTag.length > 0) {
                    additionalTag.map(tagObject => {
                      let objectTagValue = JSON.parse(tagObject.tagValue);

                      if (typeof(objectTagValue.highlightList) !== 'undefined') {
                        if (objectTagValue.highlightList.filter(nodeHighlight => nodeHighlight.content.text === tagAnnotate.content.text).length === 0) {
                          objectTagValue.highlightList.push(tagAnnotate);
                        }
                        tagObject.tagValue = JSON.stringify(objectTagValue);
                      }

                      return tagObject;
                    })
                  } else {
                    additionalTagList.push({
                      objectId: additionalObjectId,
                      objectType: TagContantType.PROCESS_BLOCK,
                      tagProperty: tag.property,
                      tagType: tagType,
                      tagValue: JSON.stringify({highlightList: [tagAnnotate]}),
                      documentId: typeof(tag.documentId) !== 'undefined' ? tag.documentId : 0,
                      preDefine: tag.isPreDefine,
                      tagPropertyId: tag.tagPropertyId,
                      sortOrder: tag.sortOrder
                    })
                  }
                } else if (tagAnnotate.data.id.indexOf('_group_') > 0 && tagAnnotate.data.id.indexOf('_flow_') > 0 && tagAnnotate.data.id.indexOf('_block_') === -1) {
                  const additionalObjectId = eval(tagAnnotate.data.id.split('_flow_')[1].replace('_annotate', ''));
                  let additionalTag = additionalTagList.filter(additionalTag => additionalTag.objectId === additionalObjectId && additionalTag.objectType === TagContantType.PROCESS_FLOW)
                  if (additionalTag.length > 0) {
                    additionalTag.map(tagObject => {
                      let objectTagValue = JSON.parse(tagObject.tagValue);

                      if (typeof(objectTagValue.highlightList) !== 'undefined') {
                        if (objectTagValue.highlightList.filter(nodeHighlight => nodeHighlight.content.text === tagAnnotate.content.text).length === 0) {
                          objectTagValue.highlightList.push(tagAnnotate);
                        }
                        tagObject.tagValue = JSON.stringify(objectTagValue);
                      }

                      return tagObject;
                    })
                  } else {
                    additionalTagList.push({
                      objectId: additionalObjectId,
                      objectType: TagContantType.PROCESS_FLOW,
                      tagProperty: tag.property,
                      tagType: tagType,
                      tagValue: JSON.stringify({highlightList: [tagAnnotate]}),
                      documentId: typeof(tag.documentId) !== 'undefined' ? tag.documentId : 0,
                      preDefine: tag.isPreDefine,
                      tagPropertyId: tag.tagPropertyId,
                      sortOrder: tag.sortOrder
                    })
                  }
                } else if (tagAnnotate.data.id.indexOf('_group_') > 0 && tagAnnotate.data.id.indexOf('_flow_') === -1 && tagAnnotate.data.id.indexOf('_block_') === -1) {
                  const additionalObjectId = eval(tagAnnotate.data.id.split('_group_')[1].replace('_annotate', ''));
                  let additionalTag = additionalTagList.filter(additionalTag => additionalTag.objectId === additionalObjectId && additionalTag.objectType === TagContantType.PROCESS_FLOW_GROUP)
                  if (additionalTag.length > 0) {
                    additionalTag.map(tagObject => {
                      let objectTagValue = JSON.parse(tagObject.tagValue);

                      if (typeof(objectTagValue.highlightList) !== 'undefined') {
                        if (objectTagValue.highlightList.filter(nodeHighlight => nodeHighlight.content.text === tagAnnotate.content.text).length === 0) {
                          objectTagValue.highlightList.push(tagAnnotate);
                        }

                        tagObject.tagValue = JSON.stringify(objectTagValue);
                      }

                      return tagObject;
                    })
                  } else {
                    additionalTagList.push({
                      objectId: additionalObjectId,
                      objectType: TagContantType.PROCESS_FLOW_GROUP,
                      tagProperty: tag.property,
                      tagType: tagType,
                      tagValue: JSON.stringify({highlightList: [tagAnnotate]}),
                      documentId: typeof(tag.documentId) !== 'undefined' ? tag.documentId : 0,
                      preDefine: tag.isPreDefine,
                      tagPropertyId: tag.tagPropertyId,
                      sortOrder: tag.sortOrder
                    })
                  }
                }
              })
            }

            let savedTag = {
              objectId: objectId,
              objectType: objectType,
              tagProperty: tag.property,
              tagType: tagType,
              tagValue: tagType === 2 && tag.value !== '' ? JSON.stringify({highlightList: studyAnnotateList}) : tag.value,
              documentId: typeof(tag.documentId) !== 'undefined' ? tag.documentId : 0,
              preDefine: tag.isPreDefine,
              tagPropertyId: tag.tagPropertyId,
              sortOrder: tag.sortOrder
            }

            if (tag.tagId > 0) {
              savedTag = Object.assign(savedTag, {tagId: tag.tagId})
            }
            tagList.push(savedTag)

            if (additionalTagList.length > 0) {
              additionalTagList.forEach(additionalTag => {
                if (tagList.filter(tag => tag.objectId === additionalTag.objectId && tag.objectType === additionalTag.objectType).length === 0) {
                    tagList.push(additionalTag);
                }
              })
            }
          })
        })
      }

      if (typeof(global.removedTagList) !== 'undefined' && global.removedTagList.length > 0) {
        global.removedTagList.forEach(removedTag => {
          if (tagList.filter(tag => tag.tagId === removedTag.tagId).length === 0) {
            tagList.push(removedTag)
          }
        })
      }
      return tagList;
    }

    validateTagList = (tagList) => {
      let customTagProperyList = [];
      let isValid = true;
      tagList.filter(tag => tag.preDefine === false && tag.documentId === 0).forEach(tag => {
        if (customTagProperyList.indexOf(tag.tagProperty.trim()) === -1) {
          customTagProperyList.push(tag.tagProperty.trim());
        } else {
          isValid = false;
        }
      })
      return isValid;
    }

    removeTagRichGrid = () => {
      if (typeof(global.gridRef) !== 'undefined' && global.gridRef.length > 0) {
        global.gridRef = global.gridRef.filter(item => item.props.refs !== 'tag-management-grid');
      }
    }

    render () {
        const study = this.props.studyList.find(study => study.studyId === this.state.versionSettings.studyId);
        return (
            <div className="study-properties-dialog dyanmic-width">
            <Dialog title={"Study Properties"} width="auto" height="auto" modal={true} onClose={this.closeDialog} buttons={[<Button primary={true} disabled={!(typeof(study) !== 'undefined' && typeof(study.enableActions) !== 'undefined' && study.enableActions.indexOf(AccessControlConstants.WRITE) > -1)} autoFocus={typeof(study) !== 'undefined' && typeof(study.enableActions) !== 'undefined' && study.enableActions.indexOf(AccessControlConstants.WRITE) > -1} onClick={this.handleSaveButtonClick} key="btn-save"> Save</Button>,<Button onClick={this.closeDialog} key="btn-cancel"> Cancel</Button>]}>
                <TabStrip keepTabsMounted={true} selected={this.state.selected} onSelect={this.handleSelect}>
                    <TabStripTab title="General">
                        <GenericStudyProperties studyProperty={this.state.studyProperty} handleDescriptionChange={this.handleDescriptionChange} handleStudyNameChange={this.handleStudyNameChange} />
                    </TabStripTab>
                    <TabStripTab title="Versioning">
                        <VersionStudyProperties versionSettings={this.state.versionSettings} ref = {ref => this.versionPropertiesRef = ref}/>
                    </TabStripTab>
                    <TabStripTab title="Tag">
                        <TagManagement schemaName={this.props.tenantId} node={this.props.node}/>
                    </TabStripTab>
                </TabStrip>
            </Dialog>
            </div>
        );
    }


}

const mapStateToProps = createSelector(
    state => state.processFlow.tenantId,
    state => state.study.studyList.filter( study => study.selected === true),
    (tenantId, studyList) => ({
      tenantId,
      studyList
    })
  );

  const mapActionsToProps = {
    setMessage: setMessage,
    setStudyDescription: setStudyDescription
  }

  Dialog.propTypes = {
      height: PropTypes.oneOfType([
          PropTypes.string
      ]),
      width: PropTypes.oneOfType([
          PropTypes.string
      ])
  }
  export default connect(mapStateToProps, mapActionsToProps, null, { withRef: true })(StudyProperties);
