class StaticNodes {
    constructor(){
        this.ROOT_NODE = -1;
            this.PROJECT_NODE = 0;
                this.STUDY_NODE = 1;
                    this.TABLE_PARENT_NODE = 4;
                this.TABLE_NODE = 3;
                this.METATABLE_PARENT_NODE = 25;

    }
}
class ContextMenuOptions {
    constructor(props){
        // context menu list
        this.rootMenu = "rootMenu";
        this.projectMenu = "projectMenu";
        this.studyMenu = "studyMenu";
        this.processFlowMenu = "processFlowMenu";;
        this.tableMenu = "tableMenu";
        this.tableParentMenu = "tableParentMenu";
        this.metaTableParentMenu = "metaTableParentMenu";
        this.pflowParentMenu = "pflowParentMenu";
        this.pflowGroupMenu = "pflowGroupMenu";

        // menu list options

        this.newProjItem = "New Project";
        this.newStudyItem = "New Study";
        this.renameItem = "Rename";
        this.moveItem = "Move";
        this.newFlowItem = "New Process Flow";
        this.newFlowGroupItem = "New Process Flow Group";
        this.importDataItem = "Import Data";
        this.importMetaTbItem = "Import MetaTable";
        this.createTbItem = "Create MetaTable";
        this.extendedItem = "Extended Attributes";
        this.copyTable = "Copy Table";
        this.pasteTable = "Paste Table";
        this.propertiesItem = "Properties";
        this.createTaskItem = "Create Task";

        this.addDocumentsItem = "Add Documents";
        this.viewDocumentsItem = "View Documents";
        this.annotationItem = "Annotate";

        this.updateItem = "Update";
        this.deleteItem = "Delete";
        this.exportItem = "Export Wizard";
        this.exportStudyItem = "Export Study";
        this.importStudyItem = "Import Study";
        this.prcessGroupDeleteItem = "Delete";
        this.executeAll = "Execute All";

        this.settingsItem = "Settings";
        this.exitItem = "Exit";
        this.helpItem = "Help";
        // appMenuBar.getMenus().get(0).getItems().addAll(settingsItem, exitItem);
        // appMenuBar.getMenus().get(1).getItems().add(helpItem);

        // rootMenu.setAutoHide(true);
        // projectMenu.setAutoHide(true);
        // studyMenu.setAutoHide(true);
        // processFlowMenu.setAutoHide(true);
        // tableMenu.setAutoHide(true);
        // pflowParentMenu.setAutoHide(true);
        // tableParentMenu.setAutoHide(true);
        // pflowGroupMenu.setAutoHide(true);

        // ---------------------------------------------------
        this.viewMenu = "View";
        this.timelineChartNGItem = "Timeline Profile";
        this.mapAndAnnotateItem = "AUTO";
        this.autoMappingItem = "Mapper";
        this.autoTableItem = "TLF";
        this.dataOpMenu = "Data Operations";
        this.addLinkItem = "Add Link";
        this.deleteLinkItem = "Delete Link";
        this.updateItemBlock = "Update Block";
        this.blockOpMenu = "Block Options";


        this.continueDateItem = "Continues Date";
        this.dataOpsItem = "Data Ops";
        this.decollapseItem1 = "Decollapse (One Table)";
        this.pivotItem = "Pivot";
        this.stringOpsMenu = "String Ops";
        this.tableOpsMenu = "Table Ops";
        this.sortOpsMenuItem = "Sort Ops";
        this.rankOpsMenuItem = "Rank Ops";
        this.seqOpsMenuItem = "Sequence Ops";
        this.typeConvMenu = "TypeConversion";
        this.criteriaOpsItem = "Criteria Ops";
        this.refPtMenu = "Reference Points";
        this.varMappingItem = "Variable Mapping";
        this.quickAppendMenu = "Append/Set";
        this.quickJoinMenu = "Join/Merge";
        this.stgOpSplitItem = "Split";
        this.compareItem = "Compare";
        this.decollapseItem2 = "Decollapse (Two Table)";
        this.linkItem = "Link";
        this.codeEditorItem = "Code Editor";
        this.reportOpsMenu = "Reporting Ops";
        this.procReportMenu = "Proc Report";
        this.ManageVersion = "Manage Version";

        this.procReportAnalysisCharItem = "Proc Report (Analysis Char)";
        this.procReportAnalysisNumItem = "Proc Report (Analysis Num)";
        this.codeEditorTable = "Table";
        this.codeEditorFile = "Report";

        this.addNotesItem = "Add Notes";
        this.deleteNotesItem = "Delete Notes";
        this.editNotesItem = "Edit Notes";
        this.integrityCheckItem = "Check Integrity";
        this.autoArrangeBlockItem = "Auto Arrange";
        this.dataWizardItem = "Data Wizard";
        this.zoomInItem = "Zoom In";
        this.zoomOutItem = "Zoom Out";
        this.pasteBlockItem = "Paste";
        this.importTable = "Select Source Table";
        this.importMetaTable = "Select Meta Table";
        this.importDocuments = "Select Document";

        this.viewDataItem = "Data";
        this.viewReportItem = "Report/Document";
        this.viewCodeItem = "Code";
        this.viewLogItem = "Log";
        this.viewExtItem = "Extended Attributes";

        this.copyBlockItem = "Copy";
        this.deleteBlockItem = "Delete";
        this.pasteBlockItem = "Paste";
        this.groupBlockItem = "Group";
        this.ungroupBlockItem = "Ungroup";

        this.stgOpAlignItem =  "Align";
        this.stgOpSpacingItem =  "Insert Spacing";
        this.stgOpTrimItem =  "Trim/Compress";
        this.stgOpCaseItem =  "Change case";
        this.stgOpSearchItem =  "Search and Replace";
        this.stgOpOccItem =  "Occurence";
        this.stgOpSplitItem =  "Split";

        this.tbOpRenameItem = "Rename/New";
        this.tbOpLengthAdjItem = "Length Adjustment";

        this.dtmConvTConItem ="Date Time Conversion";
        this.cnConvTConItem = "Char/Num Conversion";

        this.refPointItem1 = "Reference Point (One Table)";
        this.refPointItem2 = "Reference Point (Two Table)";
        this.refPtCompItem = "Reference Point (Composite)";
        this.retPtReplaceItem = "Reference Point (Replace)";
        this.retPtCombineItem = "Reference Point (Combine)";

        this.splitDetailItem = "Detail";
        this.splitQuickItem = "Quick";

        this.appendDetailItem = "Detail";
        this.appendQuickItem = "Quick";


        this.joinDetailItem = "Detail";
        this.JoinQuickItem = "Quick";
        this.rowToColumn = "Row To Column";
        this.columnToRow = "Column To Row";
        this.pivotMatrix = "Pivot Metrics";

        this.descriptiveReporting = "Descriptive";
        this.categoricalReporting = "Categorical";
        this.categoricalTotalReporting = "Categorical Total";
        this.categoricalTotalUniqueIdReporting = "Categorical Total Unique Per Id";
        this.categoricalHierarchicalReporting = "Categorical Hierarchical";
        this.categoricalHierarchicalUniqueIdReporting = "Categorical Hierarchical Unique Per Id";
        this.customReportingOps = "Custom";
        this.ExecuteAllItem = "Execute All (Batch)";
        this.ExecuteAllItemInteractive = "Execute All (Interactive)";
        this.searchAndReplace = "Search/Replace";
        this.ExportItem ="Export Object";
        this.R_ReportItem = "R Report";
        this.GraphItem = "Graph";
        this.Interactive = "Interactive";
        this.RShinny = "RShinny";
        this.DASH = "DASH";
    }
}

export default (new StaticNodes(), new ContextMenuOptions());
