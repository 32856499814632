import React from "react";
import GridMapper from '../../GridMapper';
import { ComboBox } from './../common/DropDown/StandardComboBox';
import { DropDownList } from './../common/DropDown/StandardDropDownList';
import { MultiSelectDropDownList } from './../common';

//Scenario 1 - User Selects Start Date of Left Table and Start Date of Right Table
//Scenario 2 - User Selects Start Date of Left and Start/Stop Date of Right Table
//Scenario 3 - User Selects Start/Stop Date of Left and Start/Stop Date of Right Table

var Option1Data = new Map();
Option1Data.set("0 - Left Event starts and ends BEFORE Right Event",0);
Option1Data.set("1 - Left Event starts BEFORE Right Event",1);
Option1Data.set("2 - Left Event starts AFTER Right Event ends",2);
Option1Data.set("3 - Left Event starts AFTER Right Event ends",3);
Option1Data.set("4 - Left Event Starts IN BETWEEN Right Event but Left Event Ends after Right Event",4);
Option1Data.set("5 - Left Event Starts BEFORE Right Event but Left Event Ends IN BETWEEN Right Event",5);
Option1Data.set("6 - Left Event OVERLAPS Right Event",6);
Option1Data.set("7 - Right Event OVERLAPS Left Event",7);
Option1Data.set("8 - Right Event Starts Immediately after Left Event",8);
Option1Data.set("9 - Left Event Starts Immediately after Right Event",9);

function getGlobalValue(controlIndex){
  let controlValue = '';
  global.processBlockControlValues.forEach((control, index) => {
    if (control.controlId === controlIndex) {
      controlValue = global.processBlockControlValues[index].controlValue;

    }
  });
  return controlValue;
}

function saveGlobalValue(controlIndex, value){
  global.processBlockControlValues.forEach((control, index) => {
    if (control.controlId === controlIndex) {
      global.processBlockControlValues[index].controlValue = value;
    }
  });
}

function LinkLayoutTemplate() {
  var leftDateColumns = [];
  var rightDateColumns = [];
  var leftStopDateColumns = [];
  var rightStopDateColumns = [];
  var option1Array= [] ;
  if (typeof(this.state.tableList[0]) !== 'undefined') {
      if (this.state.tableList[0].rows.length > 0) {
        this.state.tableList[0].rows.forEach((row) => {
          if(row.type === 'DATE' || row.type === 'DATETIME' || row.type === 'NUM') {
            leftDateColumns = [...leftDateColumns, row.column];
            leftStopDateColumns = [...leftStopDateColumns, row.column];
          }
        });
      }
  }

  if (typeof(this.state.tableList[1]) !== 'undefined') {
      if (this.state.tableList[1].rows.length > 0) {
        this.state.tableList[1].rows.forEach((row) => {
          if(row.type === 'DATE' || row.type === 'DATETIME' || row.type === 'NUM') {
            rightDateColumns = [...rightDateColumns, row.column];
            rightStopDateColumns = [...rightStopDateColumns, row.column];
          }
        });
      }
  }

  let leftStartDateValue = getGlobalValue(4);
  let leftStopDateValue = getGlobalValue(5);
  let rightStartDateValue = getGlobalValue(43);
  let rightStopDateValue = getGlobalValue(44);
  this.updateGlobalColumnAlwaysDisplay(leftStartDateValue);
  this.updateGlobalColumnAlwaysDisplay(leftStopDateValue);
  this.updateGlobalColumnAlwaysDisplay(rightStartDateValue);
  this.updateGlobalColumnAlwaysDisplay( rightStopDateValue);
  this.updateTableRowAccoringToDisplayColumn(0, global.ColumnAlwaysNeedsToBeDisplay, []);
  this.updateTableRowAccoringToDisplayColumn(1, global.ColumnAlwaysNeedsToBeDisplay, []);
  let stopCols = leftStopDateColumns.filter(item => item !== leftStartDateValue);
  leftStopDateColumns = stopCols;
  let stopCols1 = rightStopDateColumns.filter(item => item !== rightStartDateValue);
  rightStopDateColumns = stopCols1;
  var retrieveOption1 = () =>{
    let optionValue = [];
    let optionId = getGlobalValue(50);
    if (optionId === '') {
      optionId = []
    } else {
      optionId = optionId.split(' ').map(a => parseInt(a))
    }
    Option1Data.forEach((value, key, map)=>{
      if(optionId.indexOf(value) > -1){
        optionValue.push(key);
      }
    });
    return optionValue;
  }
  let option2 = getGlobalValue(51) === "" ? saveGlobalValue(51,1) : getGlobalValue(51);

  switch(parseInt(option2,10)){
    case 1:
    option2 = "One To Many";
    break;
    case 2:
    option2 = "One To One(Duplicate)";
    break;
    case 3:
    option2 = "One To One(No Duplicate)";
    break;
    default:
    break;
  }

  if( getGlobalValue(52) === ""){
    saveGlobalValue(52, "Keep All Obs");
  }
  let option1 = [];
  let outputTableFormat = getGlobalValue(52);
  let onlyLink =false;
  let disabledLeftStopColumn = getGlobalValue(44) !== "" ? false : true;
  var populateOption1 = () =>{
    option1Array = [];
    if(rightStartDateValue !== "" && leftStartDateValue !== "" && leftStopDateValue !== "" && rightStopDateValue !== ""){
      let correctEntry = [0,1,2,3,4,5,6,7,8,9];
      Option1Data.forEach((value, key, map)=>{
        if(correctEntry.indexOf(value) !== -1){
          option1Array.push(key);
        }
      });

    } else if(rightStartDateValue !== "" && leftStartDateValue !== "" && rightStopDateValue !== ""){
      let correctEntry = [0,2,7,8,9];
      Option1Data.forEach((value, key, map)=>{
        if(correctEntry.indexOf(value) !== -1){
          option1Array.push(key);
        }
      });

    }else if(rightStartDateValue !== "" && leftStartDateValue !== ""){
      let correctEntry = [1,3,7,8,9];
      Option1Data.forEach((value, key, map)=>{
        if(correctEntry.indexOf(value) !== -1){
          option1Array.push(key);
        }
      });

    } else if (leftStartDateValue !== "" && (rightStartDateValue !== "" || rightStopDateValue !== "")) {
      let correctEntry = [1,3];
      Option1Data.forEach((value, key, map)=>{
        if(correctEntry.indexOf(value) !== -1){
          option1Array.push(key);
        }
      });
    }

    option1 = [];
  }
  switch(outputTableFormat){
    case "Keep Only Linked Obs":
    onlyLink = true;
    break;
    case "Keep All Obs":
    onlyLink = false;
    break;
    default:
    break;
  }
  populateOption1();
  option1 = retrieveOption1();





  var handleOption1ColumnChange = (event) => {
    if(event.value.length > 0 ){
      let saveOptionIds = []
      event.value.forEach(selectedValue => {
        saveOptionIds.push(Option1Data.get(selectedValue));
      })
      saveGlobalValue(50,saveOptionIds.join(' '));
      option1 = retrieveOption1();
    } else {
      option1 = [];
      saveGlobalValue(50, option1.join(' '));
    }
    this.setState({...this.state.tableList});
  }
  var handleOption2ColumnChange = (event) => {
    option2 = event.target.value;
    switch(event.target.value){
      case "One To Many":
      saveGlobalValue(51,1);
      break;
      case "One To One(Duplicate)":
      saveGlobalValue(51,2);
      break;
      case "One To One(No Duplicate)":
      saveGlobalValue(51,3);
      break;
      default:
      break;
    }
    this.setState({...this.state.tableList});
  }

  var handleOutputTableFormatChange = (event) =>{
    switch(event.target.id){
      case "keep_only_linked_obs":
        if(event.target.checked){
          saveGlobalValue(52,"Keep Only Linked Obs");
          onlyLink = true;
        }
      break;
      case "keep_all_obs":
      if(event.target.checked){
        saveGlobalValue(52,"Keep All Obs");
        onlyLink = false;
      }
      break;
      default:
      break;
    }
    this.setState({...this.state.tableList});
  }

  var handleLeftStartColumnChange = (event) => {
    this.updateGlobalColumnAlwaysDisplay(event.target.value, leftStartDateValue);
    this.updateTableRowAccoringToDisplayColumn(0, global.ColumnAlwaysNeedsToBeDisplay, [leftStartDateValue]);
    leftStartDateValue = event.target.value === null ? '' : event.target.value;
    saveGlobalValue(4,leftStartDateValue);
    if(leftStopDateValue === event.target.value){
      leftStopDateValue = null;
      this.updateGlobalColumnAlwaysDisplay(event.target.value, leftStopDateValue);
      this.updateTableRowAccoringToDisplayColumn(0, global.ColumnAlwaysNeedsToBeDisplay, [leftStopDateValue]);
      saveGlobalValue(5,leftStopDateValue);
    }
    populateOption1();
    this.setState({tableList: this.state.tableList, gridKey: this.state.gridKey+1});
  }

  var handleLeftStopColumnChange = (event) => {
    this.updateGlobalColumnAlwaysDisplay(event.target.value, leftStopDateValue);
    this.updateTableRowAccoringToDisplayColumn(0, global.ColumnAlwaysNeedsToBeDisplay, [leftStopDateValue]);
    leftStopDateValue = event.target.value === null ? '' : event.target.value;
    saveGlobalValue(5,leftStopDateValue);
    //clear option1
    option1 = [];
    saveGlobalValue(50, option1.join(' '));
    populateOption1();
    this.setState({tableList: this.state.tableList, gridKey: this.state.gridKey+1});
  }

  var handleRightStartColumnChange = (event) => {
    this.updateGlobalColumnAlwaysDisplay(event.target.value, rightStartDateValue);
    this.updateTableRowAccoringToDisplayColumn(1, global.ColumnAlwaysNeedsToBeDisplay, [rightStartDateValue]);
    rightStartDateValue = event.target.value === null ? '' : event.target.value;
    saveGlobalValue(43,rightStartDateValue);
    if(rightStopDateValue === event.target.value){
      rightStopDateValue = null;
      this.updateGlobalColumnAlwaysDisplay(event.target.value, rightStopDateValue);
      this.updateTableRowAccoringToDisplayColumn(1, global.ColumnAlwaysNeedsToBeDisplay, [rightStopDateValue]);
      saveGlobalValue(44,rightStopDateValue);
    }
    //clear option1
    option1 = [];
    saveGlobalValue(50, option1.join(' '));
    populateOption1();
    this.setState({tableList: this.state.tableList, gridKey: this.state.gridKey+1});
  }

  var handleRightStopColumnChange = (event) => {
    this.updateGlobalColumnAlwaysDisplay(event.target.value, rightStopDateValue);
    this.updateTableRowAccoringToDisplayColumn(1, global.ColumnAlwaysNeedsToBeDisplay, [rightStopDateValue]);
    rightStopDateValue = event.target.value === null ? '' : event.target.value;
    saveGlobalValue(44,rightStopDateValue);
    //clear option1
    option1 = [];
    saveGlobalValue(50, option1.join(' '));
    disabledLeftStopColumn = false;
    if(event.target.value === ""){
      //clear left stop column
      saveGlobalValue(5,"");
      disabledLeftStopColumn = true;
    }
    populateOption1();
    this.setState({tableList: this.state.tableList, gridKey: this.state.gridKey+1});
  }

  return (
    <div className="process-option standard-two link-ops">
      <div className="field_group_main">
        <div className="container-fluid p_15">
          <div className="row">
            <div className="filter-area am-form-inline col-6 m-0">
              <fieldset>
                <div className="field_group small-width">
                  <legend>Left Start/Stop (Date/Num)</legend>
                  <div className="row">
                    <div className="col-6 p-r-0">
                      <div className="am-form-field">
                        <span>Start (Date/Num):</span>
                        <ComboBox data={leftDateColumns} onChange={handleLeftStartColumnChange} value={leftStartDateValue}/>
                      </div>
                    </div>
                    <div className="col-6 p-r-0 p-l-5">
                      <div className="am-form-field">
                        <span>Stop (Date/Num):</span>
                        <ComboBox data={leftStopDateColumns} onChange={handleLeftStopColumnChange} value={leftStopDateValue} disabled={disabledLeftStopColumn}/>
                      </div>
                    </div>
                  </div>
                </div>
              </fieldset>
            </div>
            <div className="filter-area am-form-inline col-6 m-0 p-0">
            <fieldset>
                <div className="field_group small-width">
                  <legend>Right Start/Stop (Date/Num)</legend>
                  <div className="row">
                    <div className="col-6 p-r-0">
                      <div className="am-form-field">
                        <span>Start (Date/Num):</span>
                        <ComboBox data={rightDateColumns} onChange={handleRightStartColumnChange} value={rightStartDateValue}/>
                      </div>
                    </div>
                    <div className="col-6 p-r-0 p-l-5">
                      <div className="am-form-field">
                        <span>Stop (Date/Num):</span>
                        <ComboBox data={rightStopDateColumns} onChange={handleRightStopColumnChange} value={rightStopDateValue}/>
                      </div>
                    </div>
                  </div>
                </div>
              </fieldset>
            </div>
          </div>
          <div className="row">
            <div className="filter-area am-form-inline col-8 m-0">
              <fieldset>
                <div className="field_group">
                  <legend>Link Options</legend>
                    <div className="row">
                      <div className="col-7">
                        <div className="am-form-field">
                          <span className="w-105">Set Option 1:</span>
                          <MultiSelectDropDownList className="width-full" data={option1Array} onChange={handleOption1ColumnChange} value={option1}/>
                        </div>
                      </div>
                      <div className="col-5">
                        <div className="am-form-field">
                          <span>Set Option 2:</span>
                          <DropDownList data={["One To Many", "One To One(Duplicate)", "One To One(No Duplicate)"]}
                          onChange={handleOption2ColumnChange} value={option2}/>
                        </div>
                      </div>
                    </div>
                </div>
              </fieldset>
            </div>
            <div className="filter-area am-form-inline col-4 m-0 p-0">
                <fieldset>
                  <div className="field_group">
                    <legend>Output</legend>
                    <div className="am-form-field">
                      <span><b>Output Link Table:</b></span>
                      <input type="radio" name="output_link" value="keep_only_linked_obs" id="keep_only_linked_obs" className="am-radio"
                      onChange={handleOutputTableFormatChange} checked={onlyLink}/>
                    <label className="am-radio-label m-r-10" htmlFor="keep_only_linked_obs">Keep Only Linked Obs</label>
                      <input type="radio" name="output_link" value="keep_all_obs" id="keep_all_obs" className="am-radio"
                      onChange={handleOutputTableFormatChange} checked={!onlyLink} />
                      <label className="am-radio-label m-r-10" htmlFor="keep_all_obs">Keep All Obs</label>
                    </div>
                  </div>
                </fieldset>
            </div>
          </div>
        </div>
      </div>
      <div className="height_fixed_link">
        <GridMapper {...this.state}/>
      </div>
    </div>
  );
};

export default LinkLayoutTemplate;
