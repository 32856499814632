import React from "react";
import OperationPanelWrapper from "./OperationPanelWrapper";

function template() {
  return (
    <div className="data-operation main">
        <OperationPanelWrapper { ...this}/>
    </div>
  );
};

export default template;
