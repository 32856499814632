import "./TreeView.css";
import React from "react";
import { PropTypes } from "prop-types";
import Dialog from 'react-dialog';
import {Treebeard, decorators} from 'react-treebeard';
import { ContextMenu, MenuItem, ContextMenuTrigger } from "react-contextmenu";
import { Input } from './../DataOperation/common/InputBox/StandardInput';
import { Button } from './../DataOperation/common/Button/StandardButton';
import char_icon from './../../../assets/img/icons/char_icon.png';
import num_icon from './../../../assets/img/icons/num_icon.png';
import date_icon from './../../../assets/img/icons/date_icon.png';
import ExpressionDialog from './../Annotate/ExpressionDialog';
import ApplyProcessFlow from './../Annotate/ApplyProcessFlow';
import ApplyProcessFlowSorting from './../Annotate/ApplyProcessFlowSorting';
import PivotMatrix from './../Annotate/PivotMatrix';
import SortColumns from './../Annotate/SortColumns';
import DataExplorer from './../DataOperation/dataExplorer';
import WhereMatrix from './../Annotate/WhereMatrix';
import ReportingEvent from './../Annotate/ReportingEvent';
import copyIconImg from './../../ProcessFlow/img/copy_icon.png';
import invalidImg from './../../ProcessFlow/img/invalid.png';

decorators.Loading = (props) => {
        return (
            <div className="loading" style={props.style}>
                loading...
            </div>
        );
}

decorators.Toggle = (props) => {
        return (
            <div style={props.style} className="triangle-wrapper">
                <div className="triangle">&nbsp;</div>
            </div>
        );
}

function collect(props) {
    return { node: props.node };
}

function template() {
  let that = this;
  decorators.Header = ({style, node}) => {
      const iconClass = `${ typeof(node.iconClass) !== 'undefined' ? node.iconClass : ''}`;
      const iconStyle = {marginRight: '5px'};
      const cssClassName = `${ typeof(node.cssClassName) !== 'undefined' ? (node.cssClassName.indexOf('highlight-table') > 0 ? (node.cssClassName.indexOf('-contextmenu-where-condition') > 0 ? this.state.treeView.id+ '-contextmenu-where-condition' : node.cssClassName.replace(new RegExp(' highlight-table', 'gi'), '').trim()) + ' highlight-table' : (node.cssClassName.indexOf('-contextmenu-where-condition') > 0 ? this.state.treeView.id+ '-contextmenu-where-condition' : node.cssClassName)) : ''}`;
      const activeProcessClassName = `${ typeof(node.activeProcess) !== 'undefined' && node.activeProcess ? 'active-process-flow' : ''}`;
      const highlight = typeof(that.props.highlightList) !== 'undefined' ? that.props.highlightList.filter(highlight => typeof(highlight.data) !== 'undefined' && highlight.data.id === node.id): [];
      node.draggable = !(typeof(node.carryForward) !== 'undefined' && node.carryForward === true) && node.id.indexOf('-sourceValue-') > 0 && typeof(node.parentColumn) !== 'undefined' && node.parentColumn !== '' ? false : true;
      if ((node.id.indexOf('-sourceValue-') > 0 && (typeof(node.carryForward) === 'undefined' || node.carryForward === false)) || node.id === 'targetValue' || node.id === 'sourceValue' || (node.iconClass === 'fa fa-table' && node.tableType === 'source') || node.id.split('-source-').length > 2) {
        node.draggable = false;
      }
      const isValueNode = (node.id.indexOf('-sourceValue-') > 0 || node.id.indexOf('-targetValue-') > 0 || node.cssClassName.indexOf('manage-table-column-value-name') > -1) && typeof(node.parentColumn) !== 'undefined' && node.parentColumn !== ''
      if (that.state.treeView.id === 'navigation_annotate') {
        node.draggable = node.id === 'navigation_annotate' || node.id.indexOf('_group_') > 0 || node.id.indexOf('_flow_');
      }
      let typeIcon = "";
      let columnValue = [];
      let column = {};
      let parentColumn = {};
      if (typeof(node.data_type) !== 'undefined') {
        let typeCheck = node.data_type.toLowerCase();
        if(typeCheck === "char"){
            typeIcon = <img src={char_icon} alt="C" className="p_b_2" />
        }else if(typeCheck === "num"){
            typeIcon = <img src={num_icon} alt="N" className="p_b_2" />
        }else if(typeCheck === "date" || typeCheck === "datetime"){
            typeIcon = <img src={date_icon} alt="D" className="p_b_2" />
        }
      }

      if (typeof(node.fetchColumnValue) !== 'undefined' && typeof(node.children) !== 'undefined' && node.children.length) {
        node.children.forEach((childNode) => {
        columnValue.push(childNode.name);
        })
        columnValue = columnValue.join(":-:-:");
      }

      if (typeof(node.column) !== 'undefined') {
        column = node.column;
      }

      if (typeof(node.parentColumn) !== 'undefined') {
        parentColumn = node.parentColumn;
      }

      const isPivotMatrix = node.iconClass === 'fa fa-table' && that.props.pivotMatrixList.filter(pivotMatrix => ((node.type === 'source' && pivotMatrix.sourceTable === node.tableName) || (node.type === 'metadata' && pivotMatrix.tableName === node.tableName)) && pivotMatrix.rows.length > 0).length ? true : false;
      const findParentTable = that.findParentTable(node);
      const searchNodeStatus = typeof(node.searchNode) !== 'undefined' && node.searchNode === true ? this.isValidSearchNode(node) : '';
      const expressionErrorVariableList = that.props.expressionList.filter(expression => typeof(expression.id) !== 'undefined' && expression.id !== '' && typeof(expression.node) !== 'undefined' && typeof(expression.node.name) !== 'undefined' && expression.id.indexOf(node.tableName+'-metadata-') === 0 && node.iconClass === 'fa fa-table' && node.blockName !== '' && node.tableType === 'metadata' && expression.expressionDetail.filter(tableExpression => tableExpression.error === true).length > 0).map(expression => expression.node.name).join(',')
      return (
          <div style={style.base} className={`item ${cssClassName}${node.highlight ? ' highlight-background' : ''}${node.isBlink === true ? ' blink-background' : ''}${searchNodeStatus}`}
            onDragOver={(e) => {
              e.preventDefault();
              if (node.draggable) {
                that.dragOverLine(e);
              }
            }}
            onDrag={(e) => {
              if (node.draggable) {
                that.drawLine(e);
              }
            }}
            onDrop={(e) => {
              e.preventDefault();
              if (node.draggable) {
                that.endDropLine(e);
              }
            }}
            >
              <div style={style.title} className={`${cssClassName} ${activeProcessClassName} data-div${searchNodeStatus}`} title={typeof(node.label) !== 'undefined'  ? node.label: ''} draggable={typeof(node.draggable) !== 'undefined'  ? node.draggable: false} column={JSON.stringify(column)} table={node.tableName} column-value={columnValue} parent-column={JSON.stringify(parentColumn)} column-title={node.title}
                onDragStart={(e) => {
                  if (node.draggable) {
                    that.startDrawLine(e);
                  }

                  }}
                onDragEnd={(e) => {
                  if (node.draggable && node.id.indexOf('-source_design') === -1) {
                    that.endDrawLine(e);
                  }
                  }}
                  id={node.id}
                >
                <ContextMenuTrigger id={cssClassName.replace(' highlight-table', '')} className={cssClassName} collect={collect} node={node}>
                    <i className={iconClass} style={iconStyle}/>
                    {typeIcon}
                    {typeof(node.newName) !== 'undefined' &&
                      <span className={`column-name ${node.isRequired === true ? 'font-bold' : ''}${typeof(node.columnStatus) !== 'undefined' && node.columnStatus !== '' ? ' status-'+node.columnStatus : ''}${node.isSelected === true ? ' selected-node': ''}`}>
                          <span className={`column-title`}>{node.newName[0]}</span>
                          {typeof(node.newName[1]) !== 'undefined' && node.newName[1] !== '' &&
                            <span className="column-label"> (<div>{node.newName[1]}</div>)</span>
                          }
                          {typeof(node.newName[2]) !== 'undefined' &&
                            <span className="column-length"> ({node.newName[2]})</span>
                          }

                      </span>
                    }
                    {typeof(node.newName) === 'undefined' &&
                      <span className={`column-name ${node.isRequired === true ? 'font-bold' : ''}${typeof(node.columnStatus) !== 'undefined' && node.columnStatus !== '' ? ' status-'+node.columnStatus : ''}${node.isSelected === true ? ' selected-node': ''}`}>
                        {node.name === ' ' ? '[BLANK]' : (isValueNode ? <div className={`value-node${!(typeof(node.carryForward) !== 'undefined' && node.carryForward === true) ? ' no-carry-forward' : ''}`} title={node.name}>{node.name}</div>: node.name)} {(typeof(findParentTable.parentTable) !== 'undefined' && findParentTable.parentTable !== '') && <span className="parent-blockname">IN:({findParentTable.parentTable})</span>}
                      </span>
                    }
                    {(typeof(node.customValue) !== 'undefined' && node.customValue === true) &&
                      <span className="custom-column-value">[<i className="fa fa-plus"></i>]</span>
                    }
                    {((typeof(node.tableType) !== 'undefined' && node.tableType === 'metadata' && node.children.filter(column => column.columnCTStatus !== '' && column.columnStatus !== '').length > 0) || (node.cssClassName === 'manage-variable-set-value' && (node.assignProcessflowId === 0 || node.children.length === 0)) || node.isWarning && typeof(node.id) !== '' && node.id.indexOf('tablelist-tables-') > -1) &&
                      <i className={'fa fa-exclamation-triangle orange'} style={iconStyle}></i>
                    }
                    {(typeof(node.renameColumn) !== 'undefined' && node.renameColumn === true) &&
                      <span className="rename-column">[R]</span>
                    }
                    {(typeof(node.carryForward) !== 'undefined' && node.carryForward === true) &&
                      <span className="carry-forward">[CF]</span>
                    }
                    {isPivotMatrix === true &&
                      <span className="pivot-operation-column">[P]</span>
                    }
                    {((typeof(node.whereCondition) !== 'undefined' && node.whereCondition === true) || (typeof(that.props.additionalWhereConditionList) !== 'undefined' && typeof(node.tableName) !== 'undefined' && typeof(node.whereCondition) !== 'undefined' && that.props.additionalWhereConditionList.filter(additionalWhereCondition => additionalWhereCondition.tableName === node.tableName).length > 0)) &&
                      <span className="where-condition">[W]</span>
                    }
                    {(typeof(node.hardcode) !== 'undefined' && node.hardcode === true) &&
                      <span className="hardcode">[H]</span>
                    }
                    {(typeof(node.expressionIntermediate) !== 'undefined' && node.expressionIntermediate === true) &&
                      <span className="expressionIntermediate">[E(I)]</span>
                    }
                    {(typeof(node.expression) !== 'undefined' && node.expression === true) &&
                      <span className="expression">[E]</span>
                    }
                    {(typeof(node.applyProcessFlow) !== 'undefined' && node.applyProcessFlow === true) &&
                      <span className="applyProcessFlow">[PF]</span>
                    }
                    {(typeof(node.applyProcessFlow) !== 'undefined' && node.applyProcessFlow === true) && false &&
                      <span className="remove-mapped-line"><Button primary={true} icon="fa fa-times" onClick={(event) => this.handleRemoveApplyProcessFlowClick(event, node)}></Button></span>
                    }
                    {(typeof(node.pivotOperationColumn) !== 'undefined' && node.pivotOperationColumn === true) &&
                      <span className="pivot-operation-column">[P]</span>
                    }
                    {(typeof(node.gby) !== 'undefined' && node.gby === true) &&
                      <span className="gby-column">[G]</span>
                    }
                    {(typeof(node.oby) !== 'undefined' && node.oby === true) &&
                      <span className="oby-column">[O]</span>
                    }
                    {(typeof(node.columnCTStatus) !== 'undefined' && node.columnCTStatus !== '') &&
                      <span className="ct-column">[CT-{node.columnCTStatus}]</span>
                    }
                    {(typeof(node.removeMappedLine) !== 'undefined' && node.removeMappedLine === true && (node.type === 'metadata' || node.type === 'rightPersistent' || node.id.indexOf('-targetValue') > 0)) &&
                      <span className="remove-mapped-line"><Button primary={true} icon="fa fa-times" onClick={(event) => this.handleRemoveMappedColumnClick(event, node.id)}></Button></span>
                    }
                    {(typeof(node.moveUp) !== 'undefined' && node.moveUp === true) &&
                      <span className="moveButton"><Button primary={true} iconClass="fa fa-long-arrow-up" onClick={(event) => this.moveNodeUp(event, node.id, node.tableType)}></Button></span>
                    }
                    {(typeof(node.moveDown) !== 'undefined' && node.moveDown === true) &&
                    <span className="moveButton"><Button primary={true} iconClass="fa fa-long-arrow-down" onClick={(event) => this.moveNodeDown(event, node.id, node.tableType)}></Button></span>
                    }
                    {highlight.length > 0 &&
                      <span className="annotate-variable-button">A</span>
                    }
                    {(typeof(node.hardcode) !== 'undefined' && node.hardcode === true) &&
                      <span className="remove-mapped-line"><Button primary={true} icon="fa fa-times" onClick={(event) => this.handleRemoveHardcodeClick(event, node.id, 'hardcode')}></Button></span>
                    }
                    {(typeof(node.expressionIntermediate) !== 'undefined' && node.expressionIntermediate === true) &&
                      <span className="remove-mapped-line"><Button primary={true} icon="fa fa-times" onClick={(event) => this.handleRemoveHardcodeClick(event, node.id, 'expressionIntermediate')}></Button></span>
                    }
                    {node.isCopy ? <img src={copyIconImg} className="copyIcon" alt=""/> : ""}
                    {expressionErrorVariableList !== '' &&
                      <img src={invalidImg} className="m-l-5" alt="Expression invalid" title={`Invalid Variable List: ${expressionErrorVariableList}`}/>
                    }
                  </ContextMenuTrigger>
              </div>
          </div>
      );
  };

  return (
    <div className="tree-view">
      <div className="navigation-bar-menu">
        <div className="am-treeview">
          {['sourceValue', 'targetValue'].indexOf(this.state.treeView.id) > -1 &&
            <div className="searchNode m-l-5 m-t-5"><Input className="width-full" onChange={(event) => this.handleSearchValueClick(event, this.state.treeView.id)} value={this.state.searchText[this.state.treeView.id]} placeholder="Search Value"/></div>
          }
          {typeof(this.state.treeView.children) !== 'undefined' && (this.state.treeView.children.length > 0 || (typeof(this.state.treeView.isVariableValueNode) !== 'undefined' && this.state.treeView.isVariableValueNode === true)) &&
            <Treebeard data={this.state.treeView} onToggle={this.onToggle} decorators={decorators} />
          }

          {this.state.treeView.id.indexOf('source') === 0 && this.state.treeView.children.length > 0 &&
            <div>
              <ContextMenu id={`${this.state.treeView.id}-contextmenu-where-condition`} target={`.${this.state.treeView.id}-contextmenu-where-condition`} >
                <MenuItem onClick={this.handleDataViewerClick} >{'Data Viewer'}</MenuItem>
                <MenuItem divider />
                <MenuItem onClick={(event, data) => this.handleShowTableMapping(event, data, 'target')} >{"Show Target Mapping"}</MenuItem>
                <MenuItem divider />
                <MenuItem onClick={this.handlePivotMatrixClick} >{"Pivot Metrics"}</MenuItem>
              </ContextMenu>

              <ContextMenu id={`table-design-contextmenu`} target={`.table-design-contextmenu`} >
                <MenuItem onClick={this.props.handleAddCategoricalTotalClick} >{'Add Block (Categorical Total)'}</MenuItem>
            </ContextMenu>

              {/*
              <ContextMenu id={`${this.state.treeView.id}-contextmenu-rename-column`} target={`.${this.state.treeView.id}-contextmenu-rename-column`} >
                <MenuItem onClick={this.handleRenameColumnClick} >{'Rename Column'}</MenuItem>
              </ContextMenu>
              */}

              <div className="annotate-contextmenu-where-condition-dialog">
                {this.state.visibleAddWhereCondition && <Dialog className="where-condition" title={"Where Condition: Source"} width="auto" height="auto" onClose={this.handleWhereConditionClick} buttons={[<Button primary={true} onClick={this.handleAddWhereConditionClick} key="btn-ok">OK</Button>,<Button onClick={this.handleWhereConditionClick} key="btn-cancel">Cancel</Button>]}>
                  <div className="am-form-line-left">
                      <label className="pro-label col-sm-4"><span>Table Name </span></label>
                      <Input className="text-box-display" name="table_name" defaultValue={this.state.selectNode.name} disabled />
                  </div>
                  <div className="am-form-line-left">
                      <label className="pro-label col-sm-4"><span>Where Condition</span></label>
                      <Input className="text-box-display" name="where_condition" onChange={this.handleWhereConditionChange} value={this.state.tableWhereCondition} autoFocus={true} />
                  </div>
                </Dialog>
                }
              </div>
              <div className="annotate-contextmenu-rename-column-dialog">
                {this.state.visibleAddRenameColumn && <Dialog className="rename-column" title={"Rename Column"} width="auto" height="auto" onClose={this.handleRenameColumnClick} buttons={[<Button primary={true} onClick={this.handleAddRenameColumnClick} key="btn-ok">OK</Button>,<Button onClick={this.handleRenameColumnClick} key="btn-cancel">Cancel</Button>]}>
                  <div className="am-form-line-left">
                      <label className="pro-label col-sm-4"><span>Table Name </span></label>
                      <Input className="text-box-display" name="rename-column-table" defaultValue={this.state.selectNode.tableName} disabled />
                  </div>
                  <div className="am-form-line-left">
                      <label className="pro-label col-sm-4"><span>Column</span></label>
                      <Input className="text-box-display" name="rename-column-label" onChange={(event) => this.handleRenameColumnTextChange(event, 'column')} value={this.state.renameColumn.column} autoFocus={true} />
                  </div>
                  <div className="am-form-line-left">
                      <label className="pro-label col-sm-4"><span>Label</span></label>
                      <Input className="text-box-display" name="rename-column-label" onChange={(event) => this.handleRenameColumnTextChange(event, 'label')} value={this.state.renameColumn.label} />
                  </div>
                </Dialog>
                }
              </div>
            </div>
          }

          {this.state.treeView.id.indexOf('source') !== 0 && this.state.treeView.id !== 'leftPersistent' &&
            <div>
              <ContextMenu id={`${this.state.treeView.id}-contextmenu-sort-apply-process-flow`} target={`${this.state.treeView.id}-contextmenu-sort-apply-process-flow`} >
                {this.props.virtualTargetTableList.filter(virtualTargetTable => this.state.treeView.children.filter(table => table.blockName === virtualTargetTable.blockName).length > 0 ).length === 0 &&
                  <>
                    <MenuItem onClick={this.handleDataViewerClick} >{'Data Viewer'}</MenuItem>
                    <MenuItem divider />
                  </>
                }
                <MenuItem onClick={this.handleSortApplyProcessFlowClick} >{"Sort - Applied Process Flow"}</MenuItem>
                <MenuItem divider />
                <MenuItem onClick={this.handleSortColumns} >{"Sort - Variable"}</MenuItem>
                <MenuItem divider />
                <MenuItem onClick={(event, data) => this.handleShowTableMapping(event, data, 'source')} >{"Show Source Mapping"}</MenuItem>
                <MenuItem divider />
                <MenuItem onClick={this.handleWhereMatrixClick} >{"Where Metrics"}</MenuItem>
              </ContextMenu>

              <ContextMenu id={`${this.state.treeView.id}-contextmenu-expression`} target={`${this.state.treeView.id}-contextmenu-expression`} >
                <MenuItem onClick={this.handleExpressionClick}>{'Expression'}</MenuItem>
                <MenuItem onClick={this.handleApplyProcessFlowClick}>{'Apply Process Flow'}</MenuItem>
              </ContextMenu>

              <ContextMenu id={`${this.state.treeView.id}-contextmenu-hardcode`} target={`${this.state.treeView.id}-contextmenu-hardcode`}>
                <MenuItem onClick={(event, data) => this.handleHardcodeClick(event, data, 'Hardcode')}>{'Hardcode'}</MenuItem>
                <MenuItem onClick={this.handleApplyProcessFlowClick}>{'Apply Process Flow'}</MenuItem>
              </ContextMenu>

              <ContextMenu id={`${this.state.treeView.id}-contextmenu-hardcode-expression`} target={`${this.state.treeView.id}-contextmenu-hardcode-expression`}>
                <MenuItem onClick={(event, data) => this.handleHardcodeClick(event, data, 'Hardcode')}>{'Hardcode'}</MenuItem>
                <MenuItem onClick={(event, data) => this.handleHardcodeClick(event, data, 'Expression')}>{'Expression (Intermediate)'}</MenuItem>
                <MenuItem onClick={this.handleApplyProcessFlowClick}>{'Apply Process Flow'}</MenuItem>
              </ContextMenu>

              <ContextMenu id={`${this.state.treeView.id}-contextmenu-intermediate-expression`} target={`${this.state.treeView.id}-contextmenu-intermediate-expression`}>
                <MenuItem onClick={(event, data) => this.handleHardcodeClick(event, data, 'Expression')}>{'Expression (Intermediate)'}</MenuItem>
                <MenuItem onClick={this.handleApplyProcessFlowClick}>{'Apply Process Flow'}</MenuItem>
              </ContextMenu>

              <div className="annotate-contextmenu-applyProcessFlow-dialog">
              {this.state.visibleApplyProcessFlow &&
                <ApplyProcessFlow onClose={this.closeApplyProcessFlowDialog} node={this.state.selectNode}/>
              }
              </div>

              <div className="annotate-contextmenu-sortApplyProcessFlow-dialog">
              {this.state.visibleSortApplyProcessFlow &&
                <ApplyProcessFlowSorting onClose={this.closeSortApplyProcessFlowDialog} node={this.state.selectNode}/>
              }
              </div>

              <div className="annotate-contextmenu-sortColumns-dialog">
              {this.state.visibleSortColumns &&
                <SortColumns onClose={this.closeSortColumnsDialog} node={this.state.selectNode}/>
              }
              </div>

              <div className="annotate-contextmenu-expression-dialog">
                {this.state.visibleAddExpression && <Dialog className="expression" title={this.state.expressionTitle} width="90%" height="auto" onClose={this.handleExpressionClick}>
                  <ExpressionDialog node={this.state.selectNode} triggerExpressClick={this.triggerExpressClick} handleExpressionClick={this.handleExpressionClick} handlePivotMatrixClick={this.handleExpressionDialogPivotMatrixClick}/>
                  </Dialog>
                }
              </div>

              <div className={`annotate-contextmenu-hardcode-dialog ${this.state.hardcode.type}`}>
                {this.state.visibleAddHardcode && <Dialog className="hardcode" title={this.state.hardcode.type === 'Hardcode' ? 'Hardcode' : 'Expression (Intermediate)'} width="550px" height="auto" onClose={this.handleHardcodeClick} buttons={[<Button primary={true} onClick={this.handleAddHardcodeClick} key="btn-ok">OK</Button>,<Button onClick={this.handleHardcodeClick} key="btn-cancel">Cancel</Button>]}>
                  <div className="am-form-line-left">
                      <label className="pro-label col-sm-4"><span>Table Name </span></label>
                      <Input className="text-box-display" name="table_name" defaultValue={typeof(this.state.selectNode.displayTableName) !== 'undefined' ?  this.state.selectNode.displayTableName : this.state.selectNode.tableName} disabled />
                  </div>
                  <div className="am-form-line-left">
                      <label className="pro-label col-sm-4"><span>Column Name </span></label>
                      <Input className="text-box-display" name="table_name" defaultValue={this.state.selectNode.name} disabled />
                  </div>
                  {this.state.hardcode.type === 'Expression' &&
                    <div className="am-form-line-left">
                        <label className="pro-label col-sm-4"><span>Intermediate Column</span></label>
                        <Input className="text-box-display" name="intermediate_column" onChange={this.handleIntermediateColumnChange} value={this.state.hardcode.intermediateColumn} />
                    </div>
                  }

                  <div className="am-form-line-left">
                      <label className="pro-label col-sm-4"><span>{this.state.hardcode.type}</span></label>
                      <Input className="text-box-display" name="hardcode_condition" onChange={this.handleHardcodeChange} value={this.state.hardcode.columnHardcode} />
                  </div>
                  </Dialog>
                }
              </div>

              <div className="annotate-remove-mapped-column-dialog">
                {this.state.visibleRemoveMappedColumn && <Dialog title={`Select Mapped ${this.state.targetMappedColumn.targetId !== null && this.state.targetMappedColumn.targetId.indexOf('-targetValue-targetValue') > 0 ? 'Values':'Variables'}`} width="500px" height="auto" onClose={this.handleRemoveMappedColumnClick} buttons={[<Button primary={true} onClick={this.handleRemoveMappedLineClick} key="btn-ok">OK</Button>,<Button onClick={this.handleRemoveMappedColumnClick} key="btn-cancel">Cancel</Button>]}>
                  <div className="expression-detail">
                    <table className="table">
                      <thead>
                        <tr>
                          <th>Source Table</th>
                          <th>Source Variables</th>
                        </tr>
                      </thead>
                      <tbody>
                        {this.state.targetMappedColumn.tableList.map(this.tableElement)}
                      </tbody>
                    </table>
                  </div>
                  </Dialog>
                }
              </div>

              <ContextMenu id={`${this.state.treeView.id}-manage-column-name`} target={`${this.state.treeView.id}-manage-column-name`}>
                <MenuItem onClick={(event, data) => this.handleTargetValueClick(event, data)}>{'Add Value'}</MenuItem>
              </ContextMenu>

              <div className="annotate-contextmenu-target-column-value-dialog">
                {this.state.visibleTargetValueDialog && <Dialog className="target-column" title={"New Target Variable Value"} width="520px" height="140px" onClose={this.handleTargetValueClick} buttons={[<Button primary={true} onClick={this.handleAddTargetVariableValueClick} disabled={this.state.valueTitle === ''} key="btn-ok">OK</Button>,<Button onClick={this.handleTargetValueClick} key="btn-cancel">Cancel</Button>]}>
                  <div className="am-form-line-left m-t-5">
                      <label className="pro-label col-sm-2"><span>Variable Value </span></label>
                      <Input className="text-box-display target-variable-value" name="targeet-column-value" value={this.state.valueTitle} onChange={this.handleTargetValueChange} autoFocus={true}/>
                  </div>
                </Dialog>
                }
              </div>
            </div>
          }

          {this.state.visibleDataViewer &&
            <div className="data-operation data_viewer_popup">
              <div className="DaveViewer_height" >
                <Dialog title={"Data Viewer"} width="calc(100% - 10px)" height="calc(100vh - 20px)" onClose={this.closeDataViewerDialog}>
                  <DataExplorer tenantID={this.props.tenantId} key={0} selectInputTable={true} inputTableList={[this.state.selectNode.tableName]} autoLoaded={true} closeEvent={this.closeDataViewerDialog}/>
                </Dialog>
              </div>
            </div>
          }

          { this.state.visibleWhereMatrixDialog &&
            <WhereMatrix onClose={this.closeWhereMatrixDialog} node={this.state.selectNode} key={this.state.selectNode.tableName}/>
          }

          {typeof(this.state.treeView.isVariableValueNode) !== 'undefined' && this.state.treeView.isVariableValueNode === true &&
            <div>
              <ContextMenu id={`manage-target-table-variable`} target={`.manage-target-table-variable`} >
                <MenuItem onClick={this.handleNewSetValueClick} >{'New Set Value'}</MenuItem>
              </ContextMenu>
              <ContextMenu id={`manage-variable-set-value`} target={`.manage-variable-set-value`} >
                <MenuItem onClick={this.handleRemoveSetValueClick} >{'Delete'}</MenuItem>
              </ContextMenu>
            </div>
          }

          {typeof(this.state.treeView.id) !== 'undefined' && this.state.treeView.id == 'targetValue' &&
            <div>
              <ContextMenu id={`manage-custom-variable-value`} target={`.manage-custom-variable-value`} >
                <MenuItem onClick={(event, data) => this.handleTargetValueClick(event, data, 'update')} >{'Update'}</MenuItem>
                <MenuItem onClick={this.handleRemoveTargetVariableValueClick} >{'Delete'}</MenuItem>
              </ContextMenu>
            </div>
          }

          {this.state.visiblePivotMatrixDialog &&
            <PivotMatrix onClose={this.closePivotMatrixDialog} node={this.state.selectNode} key={this.state.selectNode.tableName} targetTableName={this.state.targetTableName}/>
          }

          {typeof(this.state.treeView.id) !== 'undefined' && this.state.treeView.id == 'standard-treeview-tablelist' &&
            <>
              <ContextMenu id={`manage-source-tables`} target={`.manage-source-tables`}>
                <MenuItem onClick={this.handleImportCDISCMetadataClick}>{'Import CDISC ARS Metadata'}</MenuItem>
                <MenuItem divider/>
                <MenuItem onClick={this.handleReportingEventClick}>{'Properties'}</MenuItem>
              </ContextMenu>
              <ContextMenu id={`manage-tables`} target={`.manage-tables`}>
                <MenuItem onClick={(e, data) => this.handleAddTLFTableClick(e, data, 'Tables')}>{'Create'}</MenuItem>
                {typeof(this.state.selectNode.id) !== 'undefined' ? (<><MenuItem divider /><MenuItem onClick={this.handlePasteTLFTableClick}>{'Paste'}</MenuItem></>) : (null) }
              </ContextMenu>
              <ContextMenu id={`manage-listing`} target={`.manage-listing`}>
                <MenuItem onClick={(e, data) => this.handleAddTLFTableClick(e, data, 'Listing')}>{'Create'}</MenuItem>
                {typeof(this.state.selectNode.id) !== 'undefined' ? (<><MenuItem divider /><MenuItem onClick={this.handlePasteTLFTableClick}>{'Paste'}</MenuItem></>) : (null) }
              </ContextMenu>
              <ContextMenu id={`manage-figure`} target={`.manage-figure`}>
                <MenuItem onClick={(e, data) => this.handleAddTLFTableClick(e, data, 'Figure')}>{'Create'}</MenuItem>
                {typeof(this.state.selectNode.id) !== 'undefined' ? (<><MenuItem divider /><MenuItem onClick={this.handlePasteTLFTableClick}>{'Paste'}</MenuItem></>) : (null) }
              </ContextMenu>
              <ContextMenu id={`manage-table`} target={`.manage-table`}>
                <MenuItem onClick={this.handleRenameTLFTableClick}>{'Rename'}</MenuItem>
                <MenuItem onClick={this.handleDeleteTLFTableClick}>{'Delete'}</MenuItem>
                <MenuItem divider/>
                <MenuItem onClick={this.handleCopyTLFTableClick}>{'Copy'}</MenuItem>
              </ContextMenu>

              <div className="annotate-contextmenu-target-column-value-dialog">
                {this.state.visibleTLFTableRenameDialog && <Dialog className="target-column" title={"Rename - Table Name"} width="520px" height="140px" onClose={this.handleRenameTLFTableClick} buttons={[<Button primary={true} onClick={this.handleSaveTLFTableTitleClick} disabled={this.state.valueTitle === ''} key="btn-ok">OK</Button>, <Button onClick={this.handleRenameTLFTableClick} key="btn-cancel">Cancel</Button>]}>
                  <div className="am-form-line-left m-t-5">
                      <label className="pro-label col-sm-2"><span>Title </span></label>
                      <Input className="text-box-display target-variable-value" name="targeet-column-value" value={this.state.valueTitle} onChange={this.handleTargetValueChange} autoFocus={true}/>
                  </div>
                </Dialog>
                }
              </div>
            </>
          }
          {this.state.visibleReportingEventDialog &&
            <ReportingEvent closeEvent={this.handleReportingEventClick} handleReportingEventChange={this.handleReportingEventChange} getReportingEventData={this.props.getReportingEventData}/>
          }
          <input type="file" name="importAutoTlfEvent_table" id="import_auto_tlf_event_files" ref="import_auto_tlf_event_files" onChange={this.handleImportAutoTlfEventFilesChange} accept=".xlsx"/>
        </div>
      </div>
    </div>
  );
};

Dialog.propTypes = {
    height: PropTypes.oneOfType([
        PropTypes.string
    ]),
    width: PropTypes.oneOfType([
        PropTypes.string
    ])
}
export default template;
