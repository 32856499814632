import React from 'react';
import { Button } from './StandardButton';
import { PropTypes } from "prop-types";
import Dialog from 'react-dialog';
import { connect } from 'react-redux';
import { createSelector } from 'reselect';
import { setMessage } from '../../../../../actions/actionNotification';
import DataExplorer from '../../dataExplorer';
import "../../DataOperation.css";

export const DataButton = ({ tableManager, value, data, column, colIndex, rowIndex }) => {
  if (typeof(tableManager) === 'undefined') {
    return (null)
  } else {
    return (
        <div className='rgt-cell-inner' style={{display: 'flex', alignItems: 'center', overflow: 'hidden'}}>
            <DataButtonClass dataItem={data} field={column} value={typeof(data[column.field]) !== 'undefined' ? data[column.field]: value} tenantId={typeof(column.tenantId) !== 'undefined' ? column.tenantId: ''}/>
        </div>
    )
  }
}
class BaseDataButton extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
          visible: false
        };
        this.closeDialog = this.closeDialog.bind(this);
        this.openDialog = this.openDialog.bind(this);
      }
      getGlobalValue(controlIndex){
        let controlValue = '';
        global.processBlockControlValues.forEach((control, index) => {
          if (control.controlId === controlIndex) {
            controlValue = global.processBlockControlValues[index].controlValue;

          }
        });
        return controlValue;
      }

      saveGlobalValue(controlIndex, value){
        global.processBlockControlValues.forEach((control, index) => {
          if (control.controlId === controlIndex) {
            global.processBlockControlValues[index].controlValue = value;
          }
        });
      }
      openDialog(){
        let blockTable = this.props.dataItem['blockTable'];
        if (typeof(blockTable) == 'undefined' && typeof(this.props.dataItem['outputTable']) !== 'undefined') {
          blockTable = this.props.dataItem['outputTable'];
          if (global.processBlockControlValues.filter(processBlockControlValue => processBlockControlValue.controlId === -97).length === 0) {
            global.processBlockControlValues = [{
              "controlId": -97,
              "controlType": -97,
              "controlValue": blockTable
            }];

            global.processBlockControlValues.push({
              "controlId": -96,
              "controlType": -96,
              "controlValue": ''
            });
          }

        }
        this.saveGlobalValue(-97,blockTable);
        global.outputBlockTable = "";
        this.setState({
            visible: true
          });
      }
      closeDialog() {
        this.setState({
            visible: false
          });
      }

      render() {
          let disabled = true;
          let title = 'Data';
          if(typeof(this.props.dataItem['blockTable']) !== "undefined" && this.props.dataItem['blockTable'] !== null && this.props.dataItem['blockTable'] !== "" ){
              disabled = false;
          }
          if(typeof(this.props.dataItem['outputTable']) !== "undefined" && this.props.dataItem['outputTable'] !== null && this.props.dataItem['outputTable'] !== "" ){
              disabled = false;
          }
          if(typeof(this.props.dataItem['tableExists']) !== "undefined" && this.props.dataItem['tableExists'] !== null && this.props.dataItem['tableExists'] !== ""){
            disabled = !this.props.dataItem['tableExists'] ;
          }

          if(typeof(this.props.dataItem['data']) !== "undefined" && this.props.dataItem['data'] !== null && this.props.dataItem['data'] !== "" ){
              disabled = false;
          }

          if(typeof(this.props.dataItem['studyManager']) !== 'undefined' && this.props.dataItem['studyManager'] === true) {
            title = this.props.dataItem['name']
          }

          if(typeof(this.props.dataItem['outputTable']) !== 'undefined' && typeof(this.props.dataItem['operation']) !== 'undefined' && typeof(this.props.dataItem['leftInputTable']) !== 'undefined' && this.props.dataItem['outputTable'].indexOf('(Output)') > 0) {
            disabled = true
          }
          return (
              <div className={title === 'Data' ? "text-center width-full" : "link-button"}>
                {!this.state.visible &&
                  <Button onClick={this.openDialog} disabled={disabled}>{title}</Button>
                }
                {this.state.visible &&
                    <div className="data-operation data_viewer_popup">
                    <div className="DaveViewer_height" >
                      <Dialog title={"Data Viewer"} width="calc(100% - 10px)" height="calc(100vh - 20px)" onClose={this.closeDialog}>
                        <DataExplorer tenantID={this.props.tenantId} key={0} selectInputTable={true} autoLoaded={true} closeEvent={this.closeDialog}/>
                      </Dialog>
                    </div>
                  </div>}
              </div>
          );
      }
}

const mapStateToProps = createSelector(
    state => state.processFlow.tenantId,
    (tenantId) => ({
        tenantId
    })
  );

  const mapActionsToProps = {
    setMessage: setMessage
  }

  BaseDataButton.propTypes = {
    tenantId: PropTypes.string
  }


  Dialog.propTypes = {
      height: PropTypes.oneOfType([
          PropTypes.string
      ]),
      width: PropTypes.oneOfType([
          PropTypes.string
      ])
  }
const DataButtonClass = connect(mapStateToProps, mapActionsToProps)(BaseDataButton);
