import React from "react";
import { connect } from 'react-redux';
import { createSelector } from 'reselect';
import { PropTypes } from 'prop-types';
import axios from 'axios';
import cloneDeep from 'lodash/cloneDeep';
import { Button } from './../common/Button/StandardButton';
import { Input } from './../common/InputBox/StandardInput';
import { DropDownList } from './../common/DropDown/StandardDropDownList';
import ConfirmBox from './../common/ConfirmBox';
import Dialog from 'react-dialog';
import {camelCase} from 'lodash';
import { addProcessFlowBlockByTabIndex, updateProcessFlowBlockByTabIndex, addProcessFlowBlockLinkByTabIndex, updateProcessflowBlockRenderStatus, setActiveProcessFlow, setALLProcessFlowRenderStatus, updateProcessFlowSyncFlagByTabIndex } from '../../../../actions/actionProcessFlow';
import { setRefreshDataStatus, setProcessEngineList, setProcessEngine } from '../../../../actions/actionDataOperation';
import {setMessage} from '../../../../actions/actionNotification'
import { setTreeViewData } from '../../../../actions/actionTreeView';
import restClient from '../../../../restClient';
import { CREATE, GET_ALL, GET} from '../../../../restClient/types';
import '../../../Resource/SideBarMenu/sidebar.css';
import $ from 'jquery';
import MessageTypeConst from "../../../MessageTypeConst";
import BlockSaveType from "../../../BlockSaveType";
import DataOpsIdentifierConstants from '../../../DataOpsIdentifierConstants';
import CheckOutCheckinFalg from '../../../CheckOutCheckInFlag';
import CodeEditorLayoutTemplate from './../processOption/CodeEditorLayout.jsx';
import DataValidation from './DataValidation';
import BlockStatus from './../../../ProcessFlow/BlockStatus';

class ButtonPanel extends React.Component {
  dataList= ["Persistent", "Temp"];
  constructor(props) {
    super(props);

    let isFlowCheckedOut = false;
    let isFlowCheckedOutByLoggedInUser = false;
    let flow = this.props.processFlowList[this.props.activeProcessFlow];
    let userName = this.props.token_detail['email'];
    if(typeof(flow) !== "undefined" && flow !== null && flow.process !== null && flow.process.checkOutFlag === CheckOutCheckinFalg.CHECK_OUT_FLAG){
      isFlowCheckedOut = true;
    }
    if(typeof(flow) !== "undefined" && flow !== null && flow.process !== null && userName !== null){
      if(flow.process.userName === userName){
        isFlowCheckedOutByLoggedInUser = true;
      }
    }
    let disabledButton = window.location.host !== "localhost:3000" && !(isFlowCheckedOutByLoggedInUser && isFlowCheckedOut);
    let studyIndentifier1 = "";
    let studyIndentifier2 = "";
    let studyIndentifier3 = "";
    let selectedStudy = this.props.studyList.filter(study => study.schemaName === this.props.tenantId);
    if(selectedStudy.length > 0){
      studyIndentifier1 = typeof(selectedStudy[0].identifier1) !== "undefined" && selectedStudy[0].identifier1 !== null ? selectedStudy[0].identifier1 : "";
      studyIndentifier2 = typeof(selectedStudy[0].identifier2) !== "undefined" && selectedStudy[0].identifier2 !== null ? selectedStudy[0].identifier2 : "";
      studyIndentifier3 = typeof(selectedStudy[0].identifier3) !== "undefined" && selectedStudy[0].identifier3 !== null ? selectedStudy[0].identifier3 : "";

    }

    this.state = {
      disableSaveButton: true,
      disabledButton:disabledButton,
      engineValue: {},
      engineList: [],
      tenantID: props.tenantID,
      saveDialog: false,
      blockName: '',
      blockTag: '',
      permanentFlag:false,
      blockSaveType: this.getDefaultBlockSaveType(),
      enableExecuteButton: true,
      disableCancelButton: false,
      controlValueJsonString : "",
      studyIndentifier1:studyIndentifier1,
      studyIndentifier2:studyIndentifier2,
      studyIndentifier3:studyIndentifier3,
      blockClassifierList:[{value:0,text:''}],
      selectedBlockClassifier:{value:0,text:''},
      executeSelectedEngineId:0,
      isExecute: 0,
      dataOperationErrorList: [],
      isSampleFiltered: false
    }
    this.updateProcessEngine = this.updateProcessEngine.bind(this);
    this.toggleSaveDialog = this.toggleSaveDialog.bind(this);
    this.handleblockNameChange = this.handleblockNameChange.bind(this);
    this.handleblockTagChange = this.handleblockTagChange.bind(this);
    this.handleProcessEngineChange = this.handleProcessEngineChange.bind(this);
    this.ChangeblockSaveType= this.ChangeblockSaveType.bind(this);
    this.onSave = this.onSave.bind(this);
    this.executeBlock = this.executeBlock.bind(this);
    this.getDefaultBlockSaveType = this.getDefaultBlockSaveType.bind(this);
    this.updateFlowIconInTreeView = this.updateFlowIconInTreeView.bind(this);
    this.ChangeBlockClassifier = this.ChangeBlockClassifier.bind(this);
    this.handlePermanentFlagChange = this.handlePermanentFlagChange.bind(this);
  }

  componentDidMount(){
    let fecthClassifier = (headers, params) => restClient(GET_ALL, 'processblock/classifier', params, headers);
    let fecthProcessEngine = (headers, params) => restClient(GET_ALL, 'master/config/processingengines/getall', params, headers);
    let savedProcessEngine = this.getGlobalValue(-98);
    $(".loader").show();
    let blockClassifierList = [{value:0,text:''}];
    let selectedBlockClassifier = this.state.selectedBlockClassifier;
    let isSampleFiltered = this.state.isSampleFiltered;
    if (global.updateBlock === true && typeof(this.props.processFlowList[this.props.activeProcessFlow]) !== 'undefined' && typeof(this.props.processFlowList[this.props.activeProcessFlow].process) !== 'undefined' && typeof(this.props.processFlowList[this.props.activeProcessFlow].process.processBlockLink) !== 'undefined') {
      let processBlock = this.getProcessBlock()
      isSampleFiltered = processBlock.isSampleFiltered;
      if (typeof(processBlock.inputTableCount) !== 'undefined' && processBlock.inputTableCount !== this.props.processFlowList[this.props.activeProcessFlow].process.processBlockLink.filter(processBlockLink => processBlockLink.blockId2 === processBlock.blockId).length) {
          this.setState({enableExecuteButton: false})
      }
    }
    axios.all([fecthClassifier({'Tenant-ID': this.state.tenantID},{})])
    .then(axios.spread((response) => {
     if(typeof(response.data) !== 'undefined' && response.data !== null && response.data.length > 0) {
        response.data.filter(classifier => ["Source", "Meta", "Document"].indexOf(classifier.classifierName) === -1).forEach((classifier)=>{
          if ([DataOpsIdentifierConstants.PROCREPORT_ANALYSIS_CHAR_OP, DataOpsIdentifierConstants.R_Report_OP, DataOpsIdentifierConstants.CODEEDITOR_FILE_OP].indexOf(this.getGlobalValue(-1)) > -1) {
            if (classifier.classifierName === "Report") {
                blockClassifierList.push({value:classifier.classifierId,text:classifier.classifierName});
                selectedBlockClassifier = {value:classifier.classifierId,text:classifier.classifierName};
            }
          } else if (classifier.classifierName !== "Report") {
            blockClassifierList.push({value:classifier.classifierId,text:classifier.classifierName});
          }
        });
        this.setState({blockClassifierList, selectedBlockClassifier: selectedBlockClassifier, isSampleFiltered: isSampleFiltered });
       $(".loader").hide();
      }
    })).catch(error => {
        $(".loader").hide();
        this.props.setMessage("Error occurred while fetching block classifier.", MessageTypeConst.ERROR_MESSAGE);
    });

    if (this.props.engineList.length === 0) {
      $(".loader").show();
      axios.all([fecthProcessEngine({'Tenant-ID': 'master'}, {})])
      .then(axios.spread((response) => {
       if(typeof(response.data) !== 'undefined' && response.data !== null && response.data.length > 0) {
          let engineList = [];
          let engineValue = {};
          response.data.forEach((engine)=>{
            engineList.push({text: engine.engineName + ' - ' + engine.engineVersion, value: engine.engineType, isDefault: engine.isDefault});

            if (engine.isDefault === true) {
              engineValue = {text: engine.engineName + ' - ' + engine.engineVersion, value: engine.engineType, isDefault: engine.isDefault}
            }
          });

          if (savedProcessEngine !== '') {
              let defaultEngine = engineList.filter(defaultEngine => defaultEngine.value === eval(savedProcessEngine));

              if (defaultEngine.length > 0) {
                engineValue = defaultEngine[0];
              }
          }
          this.props.setProcessEngineList(engineList);
          if(this.props.processFlowAction === DataOpsIdentifierConstants.R_Report_OP){
            engineList = engineList.filter(eng => eng.value === 3);//R Engine Type is 3
            if(engineValue.value !== 3){
              let defaultEngine = engineList.filter(defaultEngine => defaultEngine.isDefault === true);

              if (defaultEngine.length > 0) {
                engineValue = defaultEngine[0]
              }else if(engineList.length > 0){
                engineValue = engineList[0]
              }

            }
          }
          if(this.props.processFlowAction === DataOpsIdentifierConstants.PROCREPORT_ANALYSIS_CHAR_OP
            || this.props.processFlowAction === DataOpsIdentifierConstants.PROCREPORT_ANALYSIS_NUM_OP){
            engineList = engineList.filter(eng => eng.value === 2);//SAS Engine Type is 2
            if(engineValue.value !== 3){
              let defaultEngine = engineList.filter(defaultEngine => defaultEngine.isDefault === true);

              if (defaultEngine.length > 0) {
                engineValue = defaultEngine[0]
              }else if(engineList.length > 0){
                engineValue = engineList[0]
              }

            }
          }
          this.setState({engineList: engineList, engineValue: engineValue}, () => {
            this.props.setProcessEngine(engineValue)
          })
         $(".loader").hide();
        }
      })).catch(error => {
          $(".loader").hide();
          this.props.setMessage("Error occurred while fetching process engine detail.", MessageTypeConst.ERROR_MESSAGE);
      });
    } else {
      let engineValue = {};
      let engineList = this.props.engineList;
      let defaultEngine = this.props.engineList.filter(defaultEngine => defaultEngine.isDefault === true);

      if (defaultEngine.length > 0) {
        engineValue = defaultEngine[0]
      }

      if (savedProcessEngine !== '') {
        defaultEngine = this.props.engineList.filter(defaultEngine => defaultEngine.value === eval(savedProcessEngine));
      }

      if (defaultEngine.length > 0) {
        engineValue = defaultEngine[0]
      }
      if(this.props.processFlowAction === DataOpsIdentifierConstants.R_Report_OP){
        engineList = engineList.filter(eng => eng.value === 3);//R Engine Type is 3
        if(engineValue.value !== 3){
          let defaultEngine = engineList.filter(defaultEngine => defaultEngine.isDefault === true);

          if (defaultEngine.length > 0) {
            engineValue = defaultEngine[0]
          }else if(engineList.length > 0){
            engineValue = engineList[0]
          }

        }
      }
      if(this.props.processFlowAction === DataOpsIdentifierConstants.PROCREPORT_ANALYSIS_CHAR_OP
        || this.props.processFlowAction === DataOpsIdentifierConstants.PROCREPORT_ANALYSIS_NUM_OP){
        engineList = engineList.filter(eng => eng.value === 2);//SAS Engine Type is 2
        if(engineValue.value !== 3){
          let defaultEngine = engineList.filter(defaultEngine => defaultEngine.isDefault === true);

          if (defaultEngine.length > 0) {
            engineValue = defaultEngine[0]
          }else if(engineList.length > 0){
            engineValue = engineList[0]
          }

        }
      }
      this.setState({engineList: engineList, engineValue: engineValue})
    }
}

  updateFlowIconInTreeView(processFlowStatus){
    let treeView = this.props.treeView;
    treeView.children.forEach((project, index) => {
      project.children.forEach((study) => {
        processFlowStatus.forEach((updatedFlow)=>{
          if (study.study.studyId === updatedFlow.studyId) {
            study.children.forEach((studyElement) => {
              if (studyElement.name === 'ProcessFlows') {
                studyElement.children.forEach((classifier) => {
                  classifier.children.forEach((processGroup) => {
                    processGroup.children.map((flow)=>{
                      if(updatedFlow.processId === flow.process.processId){
                        flow.process.processSyncFlag = updatedFlow.processSyncFlag;

                        if(updatedFlow.processSyncFlag === 2){
                          flow.iconClass = "fa fa-exclamation-triangle yellow";
                        }
                        else if(updatedFlow.processSyncFlag === 3){
                          flow.iconClass = "fa fa-exclamation-circle red";
                        }
                        else if(updatedFlow.processSyncFlag === 1){
                          flow.iconClass = "fa fa-microchip";
                        }

                        if (typeof(updatedFlow.isSampleFiltered) !== 'undefined') {
                            flow.process.isSampleFiltered = updatedFlow.isSampleFiltered
                        }
                      }
                      return flow;
                    })
                  });
                })

              }
            })
          }
        })


     });
    })
    this.props.setTreeViewData(treeView);
  }

  getDefaultBlockSaveType(){
    let actionName = this.getGlobalValue(-1);
    let blockType = 2;
    switch(actionName){
      case DataOpsIdentifierConstants.CODEEDITOR_TABLE_OP:
      case DataOpsIdentifierConstants.CODEEDITOR_FILE_OP:
      case DataOpsIdentifierConstants.REPORTINGOPS_CUSTOM_OP:
      case DataOpsIdentifierConstants.REPORTINGOPS_DESCRIPTIVE_OP:
      case DataOpsIdentifierConstants.REPORTINGOPS_CATEGORICAL_OP:
      case DataOpsIdentifierConstants.REPORTINGOPS_CATEGORICAL_TOTAL_OP:
      case DataOpsIdentifierConstants.REPORTINGOPS_CATEGORICAL_TOTAL_UNIQUE_OP:
      case DataOpsIdentifierConstants.REPORTINGOPS_CATEGORICAL_HIERARCHICAL_OP:
      case DataOpsIdentifierConstants.REPORTINGOPS_CATEGORICAL_HIERARCHICAL_UNIQUE_OP:
      case DataOpsIdentifierConstants.REFERENCEPOINT_ONETABLE_OP:
      case DataOpsIdentifierConstants.REFERENCEPOINT_TWOTABLE_OP:
      case DataOpsIdentifierConstants.REFERENCEPOINT_COMPOSITE_OP:
      case DataOpsIdentifierConstants.REFERENCEPOINT_REPLACE_OP:
      case DataOpsIdentifierConstants.REFERENCEPOINT_COMBINE_OP:
      case DataOpsIdentifierConstants.PROCREPORT_ANALYSIS_CHAR_OP:
      case DataOpsIdentifierConstants.PROCREPORT_ANALYSIS_NUM_OP:
      case DataOpsIdentifierConstants.R_Report_OP:
      blockType = 1;
      break;
      default :
      blockType = 2;
      break;
    }
    return blockType;
  }

  dataViewerOnClose = (headers,params) => restClient(GET_ALL, 'dataviewer/close', params, headers);

  dataOpsOnCancel = (headers,params) => restClient(GET_ALL, 'dataops/cancel', params, headers)

  createProcessWizard = (headers, params) => restClient(CREATE, 'processwizard/add', params, headers);

  dataOpsExecute = (headers, params) => restClient(CREATE, 'dataops/execute?executeBlockId='+global.processBlockExecuteBlockId+'&updateBlock='+global.updateBlock, params, headers);

  dataOpsSave = (headers, params) => restClient(CREATE, 'dataops/save?blockId='+(typeof(global.blockNewBlockId) !== 'undefined' && global.blockNewBlockId > 0 ? global.blockNewBlockId: 0)+'&updateBlock='+global.updateBlock+'&blockName='+(this.state.blockName.trim() !== '' || global.updateBlock === false ? this.state.blockName:(params.data.processBlock.blockId === global.blockNewBlockId && typeof(params.data.processBlock.blockTable) !== 'undefined' && params.data.processBlock.blockTable.split('_').length > 1 ? params.data.processBlock.blockTable.split('_').slice(0,-1).join('_'): this.state.blockName
) )+'&blockTag='+this.state.blockTag+'&blockSaveType='+this.state.blockSaveType+'&processBlockClassifier='+this.state.selectedBlockClassifier.value+'&permanentFlag='+this.state.permanentFlag+'&isExecute='+this.state.isExecute, params, headers);

  fecthTableSampleFilterList = (headers, params) => restClient(CREATE, 'sampleFilter/fetch', params, headers);

  getGlobalValue(controlIndex){
    let controlValue = '';
    global.processBlockControlValues.forEach((control, index) => {
      if (control.controlId === controlIndex) {
        controlValue = global.processBlockControlValues[index].controlValue;

      }
    });
    return controlValue;
  }

  saveGlobalValue(controlIndex, value){
    global.processBlockControlValues.forEach((control, index) => {
      if (control.controlId === controlIndex) {
        global.processBlockControlValues[index].controlValue = value;
      }
    });
  }

  isValidPostInitializeParameter = () => {
    let isValid = true;
    if(this.getGlobalValue(-94) !== null && typeof(this.getGlobalValue(-94)) !== "undefined" && this.getGlobalValue(-94) !== ""){
      let data = JSON.parse(this.getGlobalValue(-94));
      let emptyRecords = data.filter(item => item.colName.trim() === "" && (item.colLength !== "" || item.colValue.trim() !== ""));
      if(emptyRecords.length > 0 && data.length !== 1){
        isValid = false;
        this.props.setMessage("Column Name can't be empty in post initialization.", MessageTypeConst.WARNING_MESSAGE);
        return isValid;
      }

      if (emptyRecords.length === data.length && data.length === 1) {
        data = []
      }
      let records =  data.filter(item => item.colName !== "").map(obj => Object.assign({},{colName:obj.colName, colType:obj.colType,colLength:obj.colLength,colValue:obj.colValue}));

      let uniqueRecords  = [...new Set(records.map(obj => obj.colName.trim().toLowerCase()))] ;
      if(uniqueRecords.length !== records.length){
        isValid = false;
        this.props.setMessage("Column Name must be unique in post initialization.", MessageTypeConst.WARNING_MESSAGE);
        return isValid;

      }
      this.saveGlobalValue(-94, JSON.stringify(records));
    }else{
      this.saveGlobalValue(-94, JSON.stringify([]));
    }
    return isValid;
  }

  appendBlockNameInPreWhereCondition = () => {
    let preWhereList = this.getGlobalValue(-99);

    if (preWhereList !== '') {
      preWhereList = JSON.parse(preWhereList)

      if (typeof(preWhereList) === 'object') {
        preWhereList.map(preWhere => {
          if (typeof(preWhere.blockName) === 'undefined') {
            preWhere.blockName = this.getBlockName(preWhere.tableName);
          }
          return preWhere;
        })
      }

      this.saveGlobalValue(-99, JSON.stringify(preWhereList));
    }
  }

  isValidDataOperation = () => {
    let isValid = true;
    const dataOperationType = this.getGlobalValue(-1);
    for (var key in DataOpsIdentifierConstants) {
      if (DataOpsIdentifierConstants[key] === dataOperationType) {
        try {
          const dataOperationErrorList = DataValidation['isValid'+this.capitalizeFirstLetter(camelCase(key))]();
          isValid = dataOperationErrorList.length === 0
          this.setState({dataOperationErrorList: dataOperationErrorList})
        } catch(e) {
          isValid = true
        }
      }
    }
    return isValid;
  }

  capitalizeFirstLetter = (string) => {
    return string.charAt(0).toUpperCase() + string.slice(1);
  }

  removeRowId = (processBlockControlValues) => {
    processBlockControlValues.filter(processBlockControlValue => [3, 14, 18, 19, 24, 32, 33, 53, 85, 86, 87, 88].indexOf(processBlockControlValue.controlId) > -1 && processBlockControlValue.controlValue !== '' && processBlockControlValue.controlValue !== null && this.isJSON(processBlockControlValue.controlValue) === true).map(processBlockControlValue => {
      processBlockControlValue.controlValue = JSON.parse(processBlockControlValue.controlValue);
      processBlockControlValue.controlValue.map(row => {
        if (typeof(row.id) !== 'undefined') {
          delete row.id
        }

        if (processBlockControlValue.controlId === 53 && typeof(row.mappingGrid) !== 'undefined' && row.mappingGrid.length > 0) {
          row.mappingGrid = row.mappingGrid.map(mapping => {
            if (typeof(mapping.id) !== 'undefined') {
              delete mapping.id;
            }
            return mapping
          })
        }
        return row;
      })
      processBlockControlValue.controlValue = JSON.stringify(processBlockControlValue.controlValue);
    })
    return processBlockControlValues
  }

  isJSON = (str) => {
      try {
          return JSON.parse(str) && !!str;
      } catch (e) {
          return false;
      }
  }

  onExecute = (e) => {
    if(!this.isValidPostInitializeParameter()){
      return;
    }

    if(!this.isValidDataOperation()){
      return;
    }

    this.appendBlockNameInPreWhereCondition();
    var params = { processBlock: {}, blockValue: { controlValues: [], ipTableDefinitions:[] }, identifier1:'', identifier2:'', identifier3:'' };
    var tenantID = this.state.tenantID;
    let isSampleFiltered = this.state.isSampleFiltered;
    this.updateProcessEngine();
     params.processBlock = this.getProcessBlock();
     global.processBlockControlValues = this.removeRowId(global.processBlockControlValues);
     params.blockValue.controlValues = global.processBlockControlValues;
     params.blockValue.ipTableDefinitions = global.inputTableStructure;
     params.identifier1 = this.state.studyIndentifier1;
     params.identifier2 = this.state.studyIndentifier2;
     params.identifier3 = this.state.studyIndentifier3;

    var that = this;
    this.setState({
      enableExecuteButton: true,
      disableSaveButton: true,
      disableCancelButton: false,
      controlValueJsonString:  JSON.stringify(global.processBlockControlValues)
    })
     if (Object.keys(params.processBlock).length === 0 || params.blockValue.controlValues.length === 0) {
      this.setState({
        enableExecuteButton: false,
        disableCancelButton: false
      });
      $(".loader").hide();
      that.props.setMessage("Error: Please check control value or process block", MessageTypeConst.ERROR_MESSAGE);
      return;
     }
     //Validating "User must have selected atleast one column as ROW" for action 'ReportingOps(CategoricalHierarchicalUniquePerId)'
     if(this.getGlobalValue(-1) === DataOpsIdentifierConstants.REPORTINGOPS_CATEGORICAL_HIERARCHICAL_UNIQUE_OP){
      let rowData = JSON.parse(this.getGlobalValue(3));
      let rowSelected = rowData.filter(item => item.rowCheckox === true);
      if(rowSelected.length <= 0){
        this.setState({
          enableExecuteButton: false,
          disableCancelButton: false
        });
        $(".loader").hide();
        that.props.setMessage("At least one column must be selected as ROW.", MessageTypeConst.WARNING_MESSAGE);
        return;
      }
     }
     //Validating "User must not have select any column as ROW" for action 'ReportingOps(CategoricalTotal)' and 'ReportingOps(CategoricalTotalUniquePerId)'
     if(this.getGlobalValue(-1) === DataOpsIdentifierConstants.REPORTINGOPS_CATEGORICAL_TOTAL_UNIQUE_OP){
      let rowData = JSON.parse(this.getGlobalValue(3));
      let rowSelected = rowData.filter(item => item.rowCheckox === true);
      if(rowSelected.length > 0){
        this.setState({
          enableExecuteButton: false,
          disableCancelButton: false
        });
        $(".loader").hide();
        that.props.setMessage("ROW selection not allowed.", MessageTypeConst.WARNING_MESSAGE);
        return;
      }
     }

     let inputTablePreWhereConditionList = this.getGlobalValue(-99);
     isSampleFiltered = false;
     let inputTableSampleFilterList = []
     if (inputTablePreWhereConditionList !== '' && inputTablePreWhereConditionList !== null) {
       inputTablePreWhereConditionList = JSON.parse(inputTablePreWhereConditionList)
       inputTablePreWhereConditionList.forEach(inputTablePreWhereCondition => {
         if (typeof(inputTablePreWhereCondition.sampleFilter) !== 'undefined' && inputTablePreWhereCondition.sampleFilter !== '') {
           isSampleFiltered = true;

           if  (typeof(global.sampleFilterList) === 'undefined' || (typeof(global.sampleFilterList) !== 'undefined' && global.sampleFilterList.filter(sampleFilter => sampleFilter.tableName === inputTablePreWhereCondition.tableName).length === 0)) {
             inputTableSampleFilterList.push(inputTablePreWhereCondition.tableName)
           }
         }
       })
     }

     if (typeof(params.processBlock) !== 'undefined' && typeof(params.processBlock.blockSyncFlag) !== 'undefined' && params.processBlock.blockSyncFlag === BlockStatus.VALID) {
        inputTableSampleFilterList.length = 0
     }

     if (isSampleFiltered === true && inputTableSampleFilterList.length > 0) {
       $(".loader").show();
       axios.all([this.fecthTableSampleFilterList({'Tenant-ID': this.state.tenantID}, {data: {tableNames: inputTableSampleFilterList}})])
            .then(axios.spread((response) => {
              if (typeof(response.data) !== 'undefined' && typeof(response.data.sampleFilterDatas) !== 'undefined' ) {
                inputTablePreWhereConditionList.map(inputTablePreWhereCondition => {
                  response.data.sampleFilterDatas.filter(sampleFilterData => sampleFilterData.tableName === inputTablePreWhereCondition.tableName && typeof(sampleFilterData.sampleFilter) !== 'undefined' && sampleFilterData.sampleFilter !== '' && sampleFilterData.sampleFilter !== null).forEach(sampleFilterData => {
                    const engineSampleFilter = JSON.parse(sampleFilterData.sampleFilter)
                    engineSampleFilter.filter(engineFilter => engineFilter.engineCode === this.state.engineValue.value).forEach(engineFilter => {
                      inputTablePreWhereCondition.sampleFilter = engineFilter.whereCondition
                    })
                  })

                  return inputTablePreWhereCondition;
                })
                this.saveGlobalValue(-99,JSON.stringify(inputTablePreWhereConditionList));
                params.blockValue.controlValues = global.processBlockControlValues;
              }

              this.executeBlock(params, isSampleFiltered)
            })).catch(error => {
             $(".loader").hide();
             this.setState({
               enableExecuteButton: true,
               disableSaveButton: true,
               disableCancelButton: false,
               isExecute: 0
             });
             that.props.setMessage("Error occurred while fetching sample filter details.", MessageTypeConst.ERROR_MESSAGE);
           });
     } else {
       this.executeBlock(params, isSampleFiltered)
     }
  }

  executeBlock = (params, isSampleFiltered) => {
    let that = this;
    $(".loader").show();
    axios.all([this.dataOpsExecute({'Tenant-ID': this.state.tenantID}, {data: params})])
        .then(axios.spread((response) => {
            if(typeof(response.data) !== 'undefined' && (response.data.messageType === MessageTypeConst.SUCCESS_MESSAGE || response.data.messageType === MessageTypeConst.ERROR_MESSAGE)) {
             //let  action = localStorage.getItem("process-flow-action");
             let  action = this.props.processFlowAction;

              global.codeNlog = response.data;
              global.blockCode = response.data.code;
              global.blockLog = response.data.log;
              global.blockList = response.data.operationList;
              global.blockNewBlockId = response.data.blockId;
              global.processBlockExecuteBlockId = response.data.blockId;
              global.outputBlockTable = response.data.blockTable;
              global.executeFolderPath = response.data.executeFolderPath;
              global.fileNames = response.data.fileNames;
              global.restOutPutTableInDataViewer = true;

              this.props.setRefreshDataStatus(true);

              if (response.data.blockId > 0) {
                 this.setState({
                   disableSaveButton : false,
                   enableExecuteButton: true,
                   disableCancelButton: false,
                   executeSelectedEngineId: this.state.engineValue.value,
                   isExecute: 1,
                   isSampleFiltered: isSampleFiltered
                 });
                 $(".loader").hide();
                 that.props.setMessage(response.data.message, response.data.messageType);
                 //executing save action as soon as execute is complete for Quick action
                 if(action === DataOpsIdentifierConstants.APPEND_QUICK_OP ||
                     action === DataOpsIdentifierConstants.JOIN_QUICK_OP){
                   this.toggleSaveDialog();
                 }
              }
            }else if(typeof(response.data) !== 'undefined' && response.data.message !== ""){
               global.codeNlog = response.data;
               global.blockCode = response.data.code;
               global.blockLog = response.data.log;
               global.blockList = response.data.operationList;
               global.executeFolderPath = response.data.executeFolderPath;
               global.fileNames = response.data.fileNames;
               global.blockNewBlockId = response.data.blockId;
               global.processBlockExecuteBlockId = response.data.blockId;
               global.outputBlockTable = response.data.blockTable;
               global.restOutPutTableInDataViewer = true;
               this.props.setRefreshDataStatus(true);
               this.setState({
                 disableSaveButton: true,
                 enableExecuteButton: true,
                 disableCancelButton: false,
                 isExecute: 1
               });
               $(".loader").hide();
               that.props.setMessage(response.data.message, MessageTypeConst.ERROR_MESSAGE);
            }
          })).catch(error => {
           $(".loader").hide();
           this.setState({
             enableExecuteButton: true,
             disableSaveButton: true,
             disableCancelButton: false,
             isExecute: 0
           });
           that.props.setMessage("Failed to perform data operation.", MessageTypeConst.ERROR_MESSAGE);
         });
  }

  getProcessBlock() {
    /*var processBlock = JSON.parse(localStorage.getItem("processBlock"));
    var processFlowTableId = eval(localStorage.getItem("process-flow-table-id"));*/

    var processFlowTableId = parseInt(this.props.processFlowTableId, 10);
    var tenantID = this.state.tenantID;
    var actionBlock = {};

    this.props.processFlowList.forEach((processFlow) => {
      if (processFlow.schemaName === tenantID) {
        processFlow.process.processBlock.forEach((block) => {
          if (processFlowTableId === block.blockId) {
            actionBlock = block
            return block;
          }
        })
      }
    })
    /*processBlock.forEach((block, index) => {
      if (processFlowTableId === block.blockId) {
        actionBlock = block
        return block;
      }
    })*/

    return actionBlock;
  }

  onSaveValidation = (e) => {
    let that = this;
    // while saving persistent block.
    //Persistent block name can contain only character, underscore and numbers and it should begin with character only.
    if(that.state.blockSaveType === 1 && that.state.blockName.trim() !== ""){
      let pattern = new RegExp("^([a-z])+[0-9a-z_]*$","ig");
      let blockName = that.state.blockName;
      if(!pattern.test(blockName)){
        that.props.setMessage("Persistent block name should begin with character and it should contain only character, underscore and numbers.", MessageTypeConst.WARNING_MESSAGE);
        return;
      }
    }
    that.setState({disableSaveButton:true});
    that.onSave(e);
  }

  onSave = (e) => {
    $(".loader").show();
    var params = { processBlock: {}, blockValue: { controlValues: [], ipTableDefinitions:[] }, identifier1:'', identifier2:'', identifier3:'' };
    var tenantID = this.state.tenantID;
    this.updateProcessEngine();
    params.processBlock = cloneDeep(this.getProcessBlock());
    params.blockValue.controlValues = global.processBlockControlValues;
    params.blockValue.ipTableDefinitions = global.inputTableStructure;
    params.codeNLog = global.codeNlog;
    if(params.codeNLog !== null && typeof(params.codeNLog) !== "undefined" && typeof(params.codeNLog["spec"]) !== "undefined"){
      params.codeNLog["spec"] = global.blockSpec;
    }
    params.identifier1 = this.state.studyIndentifier1;
    params.identifier2 = this.state.studyIndentifier2;
    params.identifier3 = this.state.studyIndentifier3;
    var that = this;

    if (Object.keys(params.processBlock).length === 0 || params.blockValue.controlValues.length === 0) {
        $(".loader").hide();
        that.props.setMessage("Error: Please check control value or process block", MessageTypeConst.ERROR_MESSAGE);
        return;
    }

    params.processBlock.isSampleFiltered = this.state.isSampleFiltered;
    axios.all([this.dataOpsSave({'Tenant-ID': tenantID}, {data: params})])
         .then(axios.spread((response) => {
             if(typeof(response.data) !== 'undefined' && response.data !== null){
             let processFlowStatus = [];
             if(response.data.messageType !== MessageTypeConst.SUCCESS_MESSAGE){
                that.props.setMessage(response.data.message, response.data.messageType);
                that.setState({disableSaveButton:false});
             }else if(response.data.processBlock !== null &&
              response.data.processBlock.length > 0){
                var modifyBlock = response.data.processBlock[0];
                 if (typeof(global.updateBlock) !== 'undefined' && global.updateBlock === true) {
                   if(typeof(response.data.processFlow) !== "undefined" &&
                   response.data.processFlow !== null && response.data.processFlow.length >0 ){
                    response.data.processFlow.forEach((flow) => {
                      processFlowStatus.push({processId:flow.processId,processSyncFlag:flow.processSyncFlag, studyId: flow.studyId, isSampleFiltered: flow.isSampleFiltered})
                    });
                    that.props.processFlowList.forEach((processFlow, index) => {
                      response.data.processFlow.forEach((flow) => {
                        if (processFlow.process.studyId === flow.studyId && processFlow.process.processId === flow.processId) {
                          that.props.updateProcessFlowSyncFlagByTabIndex(index, flow.processSyncFlag);
                          that.props.updateProcessflowBlockRenderStatus(index, true);
                        }});

                      });
                   }

                   that.props.processFlowList.forEach((processFlow, index) => {
                    response.data.processFlow.forEach((flow) => {
                      if (processFlow.process.studyId === flow.studyId && processFlow.process.processId === flow.processId) {
                        processFlow.process.processBlock.forEach((block, blockIndex) => {
                          response.data.processBlock.forEach((updatedBlock, responseblockIndex) => {
                            if(updatedBlock.processId === flow.processId && updatedBlock.blockId === block.blockId){
                              that.props.updateProcessFlowBlockByTabIndex(index, blockIndex, updatedBlock);
                            }
                          })
                        });

                      }});
                      that.props.updateProcessflowBlockRenderStatus(index, true);
                      that.updateFlowIconInTreeView(processFlowStatus);
                    })

                    that.props.setMessage("'" +modifyBlock.blockName + "' PROCESS BLOCK have been updated successfully!", MessageTypeConst.SUCCESS_MESSAGE);
                 } else {

                  that.props.processFlowList.forEach((processFlow, index) => {
                     if (processFlow.schemaName === tenantID && processFlow.process.processId === modifyBlock.processId) {
                      that.props.addProcessFlowBlockByTabIndex(index, modifyBlock);
                       response.data.processBlockLink.forEach((processBlockLink) => {
                        that.props.addProcessFlowBlockLinkByTabIndex(index, processBlockLink);
                       })

                       if (typeof(response.data.processFlow) !== 'undefined') {
                         response.data.processFlow.forEach((flow) => {
                           if (processFlow.process.studyId === flow.studyId && processFlow.process.processId === flow.processId) {
                             that.props.updateProcessFlowSyncFlagByTabIndex(index, flow.processSyncFlag);
                           }
                         });
                       }

                       that.props.updateProcessflowBlockRenderStatus(index, true);
                       that.props.setActiveProcessFlow(index);
                       return true;
                     }
                   })

                   if (typeof(response.data.processFlow) !== 'undefined') {
                     response.data.processFlow.forEach((flow) => {
                       processFlowStatus.push({processId:flow.processId,processSyncFlag:flow.processSyncFlag, studyId: flow.studyId, isSampleFiltered: flow.isSampleFiltered})
                     });
                   }

                   if (processFlowStatus.length > 0) {
                     that.updateFlowIconInTreeView(processFlowStatus);
                   }

                   that.props.setMessage("'" +modifyBlock.blockName + "' PROCESS BLOCK have been created successfully!", MessageTypeConst.SUCCESS_MESSAGE);
                 }
                 global.outputBlockTable = '';
                 global.blockNewBlockId = 0;
                if (that.props.props.location.pathname !== '/process-flow-list') {
                    that.props.props.history.push("/process-flow-list");
                      return true;
                }
             }
            }
            $(".loader").hide();
           })).catch(error => {
            $(".loader").hide();
            that.setState({saveDialog:false});
            that.props.setMessage("Failed to save output block.", MessageTypeConst.ERROR_MESSAGE);
          });
  }

  onClose = (e) => {
    var that = this;
    ConfirmBox.open("Are you sure you want to exit ?").then((data) => {
      this.props.setALLProcessFlowRenderStatus(true);
      this.props.setProcessEngine({});
      var tenantID = this.state.tenantID;
      var inputTable = this.getGlobalValue(-97) !== null  ? this.getGlobalValue(-97).split(',') : [];
      if (typeof(global.outputBlockTable) !== 'undefined' && global.outputBlockTable !== '' && global.outputBlockTable !== null) {
        inputTable.push(global.outputBlockTable);
      }
      inputTable.forEach((table,index) => {
        axios.all([this.dataViewerOnClose({'Tenant-ID': tenantID},{'blockTable':table})])
        .then(axios.spread((response) => {
          if(typeof(response.data) !== 'undefined') {

                    }
        }));

      });
      if(global.processBlockExecuteBlockId > 0){
        $(".loader").show();
        axios.all([this.dataOpsOnCancel({'Tenant-ID': tenantID},{'blockId':global.processBlockExecuteBlockId,'blockTable':global.outputBlockTable,'updateBlock':global.updateBlock})])
            .then(axios.spread((response) => {
                if(typeof(response.data) !== 'undefined') {
                  $(".loader").hide();
                  window.location.hash='#process-flow-list';
                }
              })).catch(error => {
                $(".loader").hide();
                that.props.setMessage("Error while calling cancel api.", MessageTypeConst.ERROR_MESSAGE);
              });
      } else {
        window.location.hash='#process-flow-list';
      }
      global.outputBlockTable = '';
      global.blockNewBlockId = 0;
    })
  }

  updateProcessEngine() {
    global.processBlockControlValues.forEach((control, index) => {
      if (control.controlId === -98) {
        global.processBlockControlValues[index].controlValue = this.state.engineValue.value;
        return true;
      }
    });
  }

  toggleSaveDialog() {
    if(global.updateBlock === true){
      let processBlock = this.getProcessBlock();
      if(typeof(processBlock) !== 'undefined' && processBlock !== null && processBlock !== ""){
        let blockName = processBlock.blockName;
        let blocksaveType= processBlock.blockSaveType;
        let blockClassifierList = this.state.blockClassifierList.filter(blockClassifier => blockClassifier.value === processBlock.processBlockClassifier)
        let selectedBlockClassifier = this.state.selectedBlockClassifier
        if (blockClassifierList.length > 0) {
          selectedBlockClassifier = blockClassifierList[0]
        }
        this.setState( { blockName: blockName, blockSaveType: blocksaveType, selectedBlockClassifier: selectedBlockClassifier, permanentFlag: false, blockTag: ''});
      }
    }
    this.setState( { saveDialog: !this.state.saveDialog, permanentFlag: false, blockTag: '' });
  }

  handleblockNameChange(event) {
    const value = event.target.value !== null ? event.target.value.trim() : "";
    this.setState({ blockName: value.replace(/[^\w\s]/gi, '') })
  }

  handleblockTagChange(event) {
    this.setState({ blockTag: event.target.value })
  }

  handlePermanentFlagChange(event) {
    this.setState({ permanentFlag: event.target.checked })
  }

handleProcessEngineChange(event){
  const actionName = this.getGlobalValue(-1);
  let that = this;
  const selectedEngine = event.target.value;
  if (DataOpsIdentifierConstants.CODEEDITOR_TABLE_OP === actionName) {
    ConfirmBox.open("Default Code will change according to \""+selectedEngine.text+"\" engine. Are you sure you want to change engine ?").then((data) => {
        CodeEditorLayoutTemplate.handleCodeByEngineChange(selectedEngine.value);
        that.setState({engineValue : selectedEngine});
        that.props.setProcessEngine(selectedEngine)
    })

  } else {
    let disableSaveButton = this.state.disableSaveButton;

    if (this.state.executeSelectedEngineId > 0) {
      disableSaveButton = this.state.executeSelectedEngineId !== event.target.value.value

      if (disableSaveButton === true) {
        this.props.setMessage("SAVE button disabled (processing engine changed after execution)", MessageTypeConst.WARNING_MESSAGE);
      }
    }
    this.setState({engineValue : event.target.value, disableSaveButton: disableSaveButton});
    this.props.setProcessEngine(event.target.value)
  }
}
  ChangeblockSaveType(e){
    var blockSaveType= '';
    let selectedBlockClassifier = this.state.selectedBlockClassifier;
    let blockClassifierList = this.state.blockClassifierList;
    let permanentFlag = this.state.permanentFlag;
    if(e.target.value === 'Persistent'){
        blockSaveType = 1
        if ([DataOpsIdentifierConstants.PROCREPORT_ANALYSIS_CHAR_OP, DataOpsIdentifierConstants.R_Report_OP, DataOpsIdentifierConstants.CODEEDITOR_FILE_OP].indexOf(this.getGlobalValue(-1)) > -1) {
          blockClassifierList.filter(blockClassifier => blockClassifier.text === 'Report').forEach(blockClassifier => {
            selectedBlockClassifier = {value:blockClassifier.value, text:blockClassifier.text};
          })
        }
    }
    else {
        blockSaveType = 2;
        selectedBlockClassifier = {text:"",value:0};
        permanentFlag = false;
    }
    this.setState({
        blockSaveType: blockSaveType,
        selectedBlockClassifier: selectedBlockClassifier,
        permanentFlag: permanentFlag
    })
  }

  ChangeBlockClassifier(e){
    let selectedBlockClassifier = e.target.value;

    this.setState({
        selectedBlockClassifier:selectedBlockClassifier
    })
}

  toggleDataOperationDialog = () => {
    this.setState({dataOperationErrorList: []})
  }

  getBlockName = (tableName) => {
    let blockName = '';
    if (typeof(this.props.processFlowList) !== 'undefined') {
      this.props.processFlowList.forEach(process => {
        if (blockName === '') {
          process.process.processBlock.filter(block => block.blockTable === tableName).forEach(block => {
            blockName = block.blockName;
          })
        }
      })
    }
    return blockName;
  }
  render() {
    return (
        <div className="button-panel">
          <label className="am-form-field">
            <span className="col-8">Processing Engine:</span>
            <DropDownList data={this.state.engineList} className="top-3" textField={'text'} dataItemKey={'value'} value={this.state.engineValue}  onChange={this.handleProcessEngineChange}/>
          </label>
          <Button primary={true} className="m-left" onClick={this.onExecute} disabled={(!this.state.enableExecuteButton || this.state.disabledButton || this.props.disableExecuteButton || this.state.dataOperationErrorList.length > 0)} ref={this.props.executeRef} autoFocus={true}>Execute</Button>
          <Button primary={true} onClick={this.toggleSaveDialog} disabled={this.state.disabledButton}> Save</Button>
          <Button onClick={this.onClose} disabled={this.state.disableCancelButton} > Cancel</Button>

          { this.state.saveDialog &&
              <div className="custom_save_pop bordered_popup no-border">
              <Dialog width="auto" height="auto" className="save-dialog" title={'Save Options'} modal={true} onClose={this.toggleSaveDialog} buttons={[<Button primary={true} onClick={this.onSaveValidation} autoFocus={true} disabled={this.state.disabledButton} key="btn-save"> Save</Button>, <Button onClick={this.toggleSaveDialog} key="btn-cancel"> Cancel</Button>]}>
                  <div className="am-form-inline p-0">
                      <div className="am-form-field">
                            <label className="pro-label col-sm-4 text-left p-0"><span >Block Name: </span></label>
                            <Input className='width-full' type="text" value={this.state.blockName} onChange={this.handleblockNameChange} maxLength={150} />
                      </div>
                      <div className="am-form-field">
                            <label className="pro-label col-sm-4 text-left p-0"><span >Block Tag: </span></label>
                            <Input className='width-full' type="text" defaultValue={this.state.blockTag} onChange={this.handleblockTagChange} />
                      </div>
                      <div className="am-form-field m-t-r-4">
                            <label className="pro-label col-sm-4 text-left p-0 m-t-7"><span>Mark Permanent: </span></label>
                            <input type="checkbox" id={"blockPermanentFlag"} className="am-checkbox" checked={this.state.permanentFlag}
                            onChange={this.handlePermanentFlagChange} disabled={this.state.blockSaveType !== 1} />
                            <label className="am-checkbox-label" htmlFor={"blockPermanentFlag"}><span > </span></label>
                      </div>
                      <div className="am-form-field">
                            <label className="pro-label col-sm-4 text-left p-0"><span >Block Save Type:</span></label>
                            <DropDownList
                              onChange={this.ChangeblockSaveType}
                              data={this.dataList} defaultValue={
                                this.state.blockSaveType === 1 ? (
                                  'Persistent'
                                ) : (
                                  'Temp'
                                )}/>
                      </div>
                      <div className="am-form-field">
                        <label className="pro-label col-sm-4 text-left p-0"><span >Block Classifier: </span></label>
                        <DropDownList onChange={this.ChangeBlockClassifier}
                          data={this.state.blockClassifierList} textField={'text'} dataItemKey={'value'}  value={this.state.selectedBlockClassifier}
                          defaultValue={this.state.selectedBlockClassifier} disabled={this.state.blockSaveType === 2} />
                      </div>
                    </div>
              </Dialog>
              </div>
          }
          {this.state.dataOperationErrorList.length > 0 &&
            <div className="validation_error custom_save_pop bordered_popup no-border">
              <Dialog width="50%" height="auto" title={'Data Operation Error'} onClose={this.toggleDataOperationDialog} buttons={[<Button onClick={this.toggleDataOperationDialog} key="btn-close"> Close</Button>]}>
                <div className="row">
                  <div className="col-1">
                    <i className="fa fa-times-circle error" aria-hidden="true"></i>
                  </div>
                  <div className="col-11">
                    <ul className="error-list">
                      {this.state.dataOperationErrorList.map((dataOperationError, index) => <li key={index}>{dataOperationError}</li>)}
                    </ul>
                  </div>
                </div>
              </Dialog>
            </div>
          }
        </div>
      );
    }
}

const mapStateToProps = createSelector(
  state => state.processFlow.processFlowList,
  state => state.processFlow.tenantId,
  state => state.processFlow.processFlowAction,
  state => state.processFlow.processFlowTable,
  state => state.processFlow.processFlowTableId,
  state => state.processFlow.quickcondition,
  state => state.processFlow.submenucolumns,
  state => state.treeView.data,
  state => state.processFlow.activeProcessFlow,
  state => state.login.token_detail,
  state => state.study.studyList,
  state => state.dataOperation.disableExecuteButton,
  state => state.dataOperation.engineList,
  (processFlowList, tenantId, processFlowAction, processFlowTable, processFlowTableId, quickcondition, submenucolumns, treeView, activeProcessFlow, token_detail, studyList, disableExecuteButton, engineList) => ({
    processFlowList,
    tenantId,
    processFlowAction,
    processFlowTable,
    processFlowTableId,
    quickcondition,
    submenucolumns,
    treeView,
    activeProcessFlow,
    token_detail,
    studyList,
    disableExecuteButton,
    engineList
  })
);

const mapActionsToProps = {
  addProcessFlowBlockByTabIndex: addProcessFlowBlockByTabIndex,
  updateProcessFlowBlockByTabIndex: updateProcessFlowBlockByTabIndex,
  addProcessFlowBlockLinkByTabIndex: addProcessFlowBlockLinkByTabIndex,
  updateProcessflowBlockRenderStatus: updateProcessflowBlockRenderStatus,
  setActiveProcessFlow: setActiveProcessFlow,
  setALLProcessFlowRenderStatus: setALLProcessFlowRenderStatus,
  updateProcessFlowSyncFlagByTabIndex: updateProcessFlowSyncFlagByTabIndex,
  setRefreshDataStatus: setRefreshDataStatus,
  setMessage: setMessage,
  setTreeViewData:setTreeViewData,
  setProcessEngineList: setProcessEngineList,
  setProcessEngine: setProcessEngine
}

ButtonPanel.propTypes = {
  processFlowList: PropTypes.array,
  tenantId: PropTypes.string,
  processFlowAction: PropTypes.string,
  processFlowTable: PropTypes.string,
  quickcondition: PropTypes.string,
  submenucolumns: PropTypes.string
}
Dialog.propTypes = {
    height: PropTypes.oneOfType([
        PropTypes.string
    ]),
    width: PropTypes.oneOfType([
        PropTypes.string
    ])
}
export default connect(mapStateToProps, mapActionsToProps)(ButtonPanel);
