import React    from "react";
import { Input } from './../DataOperation/common/InputBox/StandardInput';

export default class GenericStudyProperties extends React.Component {

    constructor(props){
        super(props);
        this.state = {
            studyProperty : this.props.studyProperty,
            handleDescriptionChange: props.handleDescriptionChange,
            handleStudyNameChange: props.handleStudyNameChange
        }
    }

    render (){
        return (
            <div className="study-property-content">
                    <div className="am-form-line-left row">
                      <label className="pro-label col-sm-2"><span >Study Name </span></label>
                      <Input className='text-box-display col-sm-9' name="txtStudyName" defaultValue={this.state.studyProperty.studyName} onChange={this.state.handleStudyNameChange}/>
                    </div>
                    <div className="am-form-line-left row">
                        <label className="pro-label col-sm-2"><span >Identifier 1 </span></label>
                        <Input className="text-box-display col-sm-9" name="txtIdentifier1" value={this.state.studyProperty.identifier1} disabled />
                    </div>
                    <div className="am-form-line-left row">
                        <label className="pro-label col-sm-2"><span >Identifier 2</span></label>
                        <Input className="text-box-display col-sm-9" name="txtIdentifier2" value={this.state.studyProperty.identifier2} disabled />
                    </div>
                    <div className="am-form-line-left row">
                        <label className="pro-label col-sm-2"><span >Identifier 3 </span></label>
                        <Input className="text-box-display col-sm-9" name="txtIdentifier3" value={this.state.studyProperty.identifier3} disabled />
                    </div>
                    <div className="am-form-line-left row">
                        <label className="pro-label col-sm-2"><span >Classifier </span></label>
                        <Input className="text-box-display col-sm-9" value={this.state.studyProperty.classifier} disabled />
                    </div>
                    <div className="am-form-line-left row">
                        <label className="pro-label col-sm-2"><span >Description </span></label>
                        <textarea className="text-box-display col-sm-9 am-textbox textarea-box" defaultValue={this.state.studyProperty.description} onChange={this.state.handleDescriptionChange} />
                    </div>
                    <div className="am-form-line-left row">
                        <label className="pro-label col-sm-2"><span >Import Directory: </span></label>
                        <Input className="text-box-display col-sm-9" name="txtImportDir" value={this.state.studyProperty.importDirectory} disabled />
                    </div>
            </div>
        );
    }
}
