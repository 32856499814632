
const BLOCK_CREATION_TYPE = Object.freeze({
    IMPORT_SOURCE_TABLE:1,
    IMPORT_META_TABLE:2,
    CREATE_UPDATE_BLOCK:3,
    DATA_COPY:4,
    DATA_LINK:5,
    DEF_COPY:6,
    IMPORT_SOURCE_DOCUMENT:7,
    SAVE_NULL:-1,
    TIMELINE:8,
    MAP_ANNOTATE:9,

});

export default BLOCK_CREATION_TYPE;