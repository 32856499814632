import React from "react";
import axios from 'axios';
import { ExploratoryOperation } from "../common/gridOperation";
import restClient from '../../../../restClient';
import { GET_ONE } from '../../../../restClient/types';
import $ from 'jquery';
import { connect } from 'react-redux';
import MessageTypeConst from "../../../MessageTypeConst";
import { setMessage } from '../../../../actions/actionNotification';
import DataOpsIdentifierConstants from "../../../DataOpsIdentifierConstants";
import { createSelector } from 'reselect';


function getTableName (processFlowList, tableName, treeView, tenantId) {
  let blockFound = false;
  if (processFlowList.length > 0) {
    processFlowList.forEach(processFlow => {
      if (typeof(processFlow.process) !== 'undefined' && typeof(processFlow.process.processBlock) !== 'undefined' && processFlow.process.processBlock.length > 0 && blockFound === false) {
        let block = processFlow.process.processBlock.filter(block => block.blockTable === tableName);

        if (block.length > 0 && tableName.search(block[0].blockName) === -1) {
          tableName = block[0].blockName +" ("+tableName+")";
          blockFound = true;
        }
      }
    })
  }

  if (blockFound === false && typeof(treeView.children) !== 'undefined') {
    treeView.children.forEach(project => {
      project.children.filter(study => study.study.schemaName === tenantId).forEach(study => {
        let table = study.study.metaTable.filter(table => table.tableName === tableName);

        if (table.length === 0) {
          table = study.study.sourceTable.filter(table => table.tableName === tableName);
        }

        if (table.length > 0) {
          tableName = table[0].srcTableName +" ("+tableName+")";
          blockFound = true;
        }
      })
    })
  }
  return tableName
}

class DataExploratory extends React.Component {

  getColumnFields = (columnList, tableName) => {
    var columns = [];
    let studyIndentifier1 = "";
    let studyIndentifier2 = "";
    let studyIndentifier3 = "";
    let rowColumns = columnList;
    let selectedStudy = this.props.studyList.filter(study => study.schemaName === this.props.tenantId);
    if(selectedStudy.length > 0){
      studyIndentifier1 = typeof(selectedStudy[0].identifier1) !== "undefined" && selectedStudy[0].identifier1 !== null ? selectedStudy[0].identifier1 : "";
      studyIndentifier2 = typeof(selectedStudy[0].identifier2) !== "undefined" && selectedStudy[0].identifier2 !== null ? selectedStudy[0].identifier2 : "";
      studyIndentifier3 = typeof(selectedStudy[0].identifier3) !== "undefined" && selectedStudy[0].identifier3 !== null ? selectedStudy[0].identifier3 : "";
    }
    if(rowColumns.indexOf(studyIndentifier1) !== -1){
      columns.push(studyIndentifier1);
    }
    if(rowColumns.indexOf(studyIndentifier2) !== -1){
      columns.push(studyIndentifier2);
    }
    if(rowColumns.indexOf(studyIndentifier3) !== -1){
      columns.push(studyIndentifier3);
    }
    let nonkeyColums = rowColumns.filter(name => name.indexOf('_app_') === -1 && name.indexOf('APP_') === -1 &&
    name !== studyIndentifier1 && name !== studyIndentifier2 && name !== studyIndentifier3);
    if(this.getGlobalValue(-1) !== DataOpsIdentifierConstants.COMPARE_OP &&
    typeof(global.outputBlockTable) !== "undefined" && global.outputBlockTable !== null && global.outputBlockTable !== "" && global.outputBlockTable !== tableName){
      nonkeyColums = nonkeyColums.sort();
    }
    for (let i =0; i<nonkeyColums.length;i++) {
      columns.push(nonkeyColums[i]);
    }
    let appkeyColums = rowColumns.filter(name => name.indexOf('APP_KEY') !== -1);
    for (let i =0; i<appkeyColums.length;i++) {
      columns.push(appkeyColums[i]);
    }
    let appPkeyColums = rowColumns.filter(name => name.indexOf('APP_PKEY') !== -1);
    for (let i =0; i<appPkeyColums.length;i++) {
      columns.push(appPkeyColums[i]);
    }
    let appRkeyColums = rowColumns.filter(name => name.indexOf('APP_RKEY') !== -1);
    for (let i =0; i<appRkeyColums.length;i++) {
      columns.push(appRkeyColums[i]);
    }
    let appLkeyColums = rowColumns.filter(name => name.indexOf('APP_LKEY') !== -1);
    for (let i =0; i<appLkeyColums.length;i++) {
      columns.push(appLkeyColums[i]);
    }
    let appWarnColums = rowColumns.filter(name => name.indexOf('APP_WARN') !== -1);
    for (let i =0; i<appWarnColums.length;i++) {
      columns.push(appWarnColums[i]);
    }
    let keyColums = rowColumns.filter(name => (name.indexOf('_app_') !== -1 || name.indexOf('APP_') !== -1) && (name.indexOf('APP_KEY') === -1
    && name.indexOf('APP_PKEY') === -1 && name.indexOf('APP_RKEY') === -1 && name.indexOf('APP_LKEY') === -1 && name.indexOf('APP_WARN') === -1));
    for (let i =0; i<keyColums.length;i++) {
      columns.push(keyColums[i]);
    }

    return columns.join(' ');
  };

  getColumnProperty(row, tableName) {
  let columnProperty = [];
  if(typeof(row) === 'undefined' || row === null){
    return columnProperty;
  }
  let studyIndentifier1 = "";
  let studyIndentifier2 = "";
  let studyIndentifier3 = "";
  let selectedStudy = this.props.studyList.filter(study => study.schemaName === this.props.tenantId);
  if(selectedStudy.length > 0){
    studyIndentifier1 = typeof(selectedStudy[0].identifier1) !== "undefined" && selectedStudy[0].identifier1 !== null ? selectedStudy[0].identifier1 : "";
    studyIndentifier2 = typeof(selectedStudy[0].identifier2) !== "undefined" && selectedStudy[0].identifier2 !== null ? selectedStudy[0].identifier2 : "";
    studyIndentifier3 = typeof(selectedStudy[0].identifier3) !== "undefined" && selectedStudy[0].identifier3 !== null ? selectedStudy[0].identifier3 : "";

  }
  let columns = Object.keys(row);
  let orderIndex = 3 ;
  let nonkeyColums = columns.filter(name => name.indexOf('_app_') === -1 && name.indexOf('APP_') === -1);
  let keyColums = columns.filter(name => name.indexOf('_app_') !== -1 || name.indexOf('APP_') !== -1);
  let app_key_Index = 1;
  let app_pkey_Index = 2;
  let app_rkey_Index = 3;
  let app_lkey_Index = 4;
  let app_warn_Index = 5;
  if(this.getGlobalValue(-1) !== DataOpsIdentifierConstants.COMPARE_OP &&
    typeof(global.outputBlockTable) !== "undefined" && global.outputBlockTable !== null && global.outputBlockTable !== "" && global.outputBlockTable !== tableName){
      nonkeyColums = nonkeyColums.sort();
  }
  keyColums = keyColums.sort();
  for (var i =0; i<nonkeyColums.length;i++) {
    let column = nonkeyColums[i];
    let item = {field:column, show:true};
    if(column.toLowerCase() === studyIndentifier1.toLowerCase()){
      item.orderIndex = 0;
    }
    else if(column.toLowerCase() === studyIndentifier2.toLowerCase()){
      item.orderIndex = 1;
    }
    else if(column.toLowerCase() === studyIndentifier3.toLowerCase()){
      item.orderIndex = 2;
    }
    else if(column.indexOf('_app_') === -1 && column.indexOf('APP_') === -1){
      item.orderIndex = orderIndex;
      orderIndex = orderIndex + 1;
    }
    columnProperty.push(item);
  }
  for (var i =0; i<keyColums.length;i++) {
    let column = keyColums[i];
    let item = {field:column, show:true};
    if(column.indexOf('_app_') !== -1 || column.indexOf('APP_') !== -1){
      switch (column.toUpperCase()) {
        case 'APP_KEY':
          item.orderIndex = orderIndex + app_key_Index;
          break;
        case 'APP_PKEY':
          item.orderIndex = orderIndex + app_pkey_Index;
          break;
        case 'APP_RKEY':
          item.orderIndex = orderIndex + app_rkey_Index;
          break;
        case 'APP_LKEY':
          item.orderIndex = orderIndex + app_lkey_Index;
          break;
        case 'APP_WARN':
          item.orderIndex = orderIndex + app_warn_Index;
          break;
        default:
          orderIndex = orderIndex + 6;
          item.orderIndex = orderIndex;
          break;
      }
      columnProperty.push(item);
    }
  }
  return columnProperty;
}
  getGlobalValue(controlIndex){
    let controlValue = '';
    global.processBlockControlValues.forEach((control, index) => {
      if (control.controlId === controlIndex) {
        controlValue = global.processBlockControlValues[index].controlValue;

      }
    });
    return controlValue;
  }

  saveGlobalValue(controlIndex, value){
    global.processBlockControlValues.forEach((control, index) => {
      if (control.controlId === controlIndex) {
        global.processBlockControlValues[index].controlValue = value;
      }
    });
  }
  constructor(props) {
    super(props);
    this.state={
      inputTable: [],
      tableList: [
        //  {id: "table1",name:"table 1",rows:table_one, status:true}
        ],
        tenantID: props.tenantID,
        selectInputTable : props.selectInputTable,
        autoLoaded: props.autoLoaded,
        closeEvent:props.closeEvent,
        inputTableList: typeof(props.inputTableList) !== 'undefined' ? props.inputTableList: []
    }
    this.handleTableStateChangeEvent = this.handleTableStateChangeEvent.bind(this);
    this.TableStatusBar = this.TableStatusBar.bind(this);
  }

  fetchDataViewer = (headers, params) => restClient(GET_ONE, 'exploratory', params, headers);

  componentDidMount(){
    var inputTable = this.getGlobalValue(-97).split(',');
    if (typeof(global.outputBlockTable) !== 'undefined' && global.outputBlockTable !== '' && global.outputBlockTable !== null) {
      //if output is file no need to add output table
      let actionName = this.getGlobalValue(-1);
      if(actionName !== DataOpsIdentifierConstants.R_Report_OP && actionName !== DataOpsIdentifierConstants.PROCREPORT_ANALYSIS_CHAR_OP && actionName !== DataOpsIdentifierConstants.PROCREPORT_ANALYSIS_NUM_OP && actionName !== DataOpsIdentifierConstants.CODEEDITOR_FILE_OP){
        inputTable.push(global.outputBlockTable);
      }
    }

    if (this.state.inputTableList.length > 0) {
      inputTable = this.state.inputTableList;
    }

    if(this.state.autoLoaded){
      this.recursiveSelectedInputTables(0, [], inputTable, this.state.selectInputTable);
    }

    this.setState({ inputTable: inputTable });
  }

  recursiveSelectedInputTables(index, tableList, inputTable, tableStaus) {
    let that = this;
    if (typeof(inputTable[index]) !== 'undefined') {
      if (index === 0) {
        $(".loader").show();
      }
      var table = inputTable[index];
      axios.all([this.fetchDataViewer({'Tenant-ID': this.state.tenantID}, {id: table, pagination:{ page: 1, perPage: 50 }})])
          .then(axios.spread((dataViewer) => {
              if(typeof(dataViewer.data) !== 'undefined') {
                if(dataViewer.data.messageType === MessageTypeConst.WARNING_MESSAGE || dataViewer.data.messageType === MessageTypeConst.ERROR_MESSAGE){
                  this.props.setMessage(dataViewer.data.message, dataViewer.data.messageType);
                  if(typeof(this.state.closeEvent) !== "undefined" && this.state.closeEvent !== null){
                    this.state.closeEvent();
                  }
                  $(".loader").hide();
                }else{
                  tableList = [...tableList, {id: table, name: table, fullTableName: getTableName(that.props.processFlowList, table, that.props.treeView, that.props.tenantId), rows: dataViewer.data.records,
                    columns:{columnField : this.getColumnFields(dataViewer.data.columnNames, table), originColumnField: this.getColumnFields(dataViewer.data.columnNames, table)},
                    columnProperty : [], status:tableStaus, resize: true, pageable: true, total: dataViewer.data.count, take:50, skip:0, pagination: { action: 'dataviewer',  tenant_id: this.state.tenantID},
                    htmlContent: dataViewer.data.htmlContent
                   }
                  ];
                  index++;

                  if (typeof(inputTable[index]) !== 'undefined') {
                    tableList = this.recursiveSelectedInputTables(index, tableList, inputTable, tableStaus)
                  } else {
                    this.setState({ tableList : tableList });
                  }
                  $(".loader").hide();
                  return tableList;
                }
              }
            })
      ).catch(error => {
        $(".loader").hide();
        if(typeof(this.state.closeEvent) !== "undefined" && this.state.closeEvent !== null){
          this.state.closeEvent();
        }
        this.props.setMessage("Error occurred while fetching data.", MessageTypeConst.ERROR_MESSAGE);
      });
    }
  }

  handleTableStateChangeEvent(event) {
    $(".loader").show();
    let that = this;
    let tableGrid = this.state.tableList.filter( table => table.name === event.target.value );
    let status = event.target.checked;

    if (tableGrid.length > 0) {
      let tableList = this.state.tableList.filter( table => table.name !== event.target.value );
      tableGrid[0].status = event.target.checked;
      tableList = [...tableList, tableGrid[0]];

      this.setState({ tableList : tableList });
      $(".loader").hide();
      //this.handleToggleTableList(event);
    } else {
      let table = event.target.value;

      if (typeof(global.outputBlockTable) !== 'undefined' && global.outputBlockTable !== '' && global.outputBlockTable !== null) {
        if (global.outputBlockTable === table && typeof(global.restOutPutTableInDataViewer) !== 'undefined') {
          global.restOutPutTableInDataViewer = false;
        }
      }

      axios.all([this.fetchDataViewer({'Tenant-ID': this.state.tenantID}, {id: table, pagination:{ page: 1, perPage: 50 }})])
          .then(axios.spread((dataViewer) => {
              if(typeof(dataViewer.data) !== 'undefined') {
                if(dataViewer.data.messageType === MessageTypeConst.WARNING_MESSAGE || dataViewer.data.messageType === MessageTypeConst.ERROR_MESSAGE){
                  if(typeof(this.state.closeEvent) !== "undefined" && this.state.closeEvent !== null){
                    this.state.closeEvent();
                  }
                  this.props.setMessage(dataViewer.data.message, dataViewer.data.messageType);
                }else{
                  let tableList = [...this.state.tableList, {id: table, name: table, fullTableName: getTableName(that.props.processFlowList, table, that.props.treeView, that.props.tenantId), rows: [],
                    columns:{columnField : this.getColumnFields(dataViewer.data.columnNames, table), originColumnField: this.getColumnFields(dataViewer.data.columnNames, table)},
                    columnProperty : [], status: status, resize: true, pageable: true, total: dataViewer.data.count, take:50, skip:0, pagination: { action: 'dataviewer',  tenant_id: this.state.tenantID},
                    htmlContent: dataViewer.data.htmlContent
                   }
                  ];
                  this.setState({ tableList : tableList });
                }
                $(".loader").hide();
              }
            })
      ).catch(error => {
        $(".loader").hide();
        if(typeof(this.state.closeEvent) !== "undefined" && this.state.closeEvent !== null){
          this.state.closeEvent();
        }
        this.props.setMessage("Error occurred while fetching data.", MessageTypeConst.ERROR_MESSAGE);
      });
    }
  }

  handleToggleTableList(event) {

    $(".loader").hide();
  }

  TableStatusBar = (table, tableIndex) => {
      let tableType = global.outputBlockTable !== table ? 'Input' : 'Output';
      let status = false;
      if(this.state.tableList.length > 0){
        let gridTable = this.state.tableList.filter( gridTable => gridTable.id === table );
        if (gridTable.length > 0) {
          status = gridTable[0].status
        }
      }

      return (
        <span key={tableIndex} className={`m-right tableName badge badge-pill badge-${tableType === 'Input'? "warning" : "success"}`}>
              <input
                type="checkbox"
                id={`table-status-data-exploratory-checkbox-${table}`}
                className="am-checkbox"
                onChange={this.handleTableStateChangeEvent}
                value={table}
                checked={status}
              />
            <label className="am-checkbox-label" htmlFor={`table-status-data-exploratory-checkbox-${table}`}>{getTableName(this.props.processFlowList, table, this.props.treeView, this.props.tenantId)}</label>
      </span>
      )
  };

  render() {
      let inputTable = this.state.inputTable;
      if (typeof(global.outputBlockTable) !== 'undefined' && global.outputBlockTable !== '' && global.outputBlockTable !== null) {
        //if output is file no need to add output table
        let actionName = this.getGlobalValue(-1);
        if(actionName !== DataOpsIdentifierConstants.R_Report_OP && actionName !== DataOpsIdentifierConstants.PROCREPORT_ANALYSIS_CHAR_OP && actionName !== DataOpsIdentifierConstants.PROCREPORT_ANALYSIS_NUM_OP && actionName !== DataOpsIdentifierConstants.CODEEDITOR_FILE_OP){
          if (inputTable.indexOf(global.outputBlockTable) === -1) {
              inputTable.push(global.outputBlockTable);
          }

        }
      }

      return (
        <div className="dataviewer-grid">
          <div className="field_group text-center">
            <div className="am-form-field gird-status-bar">
              { inputTable.map(this.TableStatusBar)}
            </div>
          </div>

            <ExploratoryOperation tableList={this.state.tableList} type={'data'} tenantID={this.state.tenantID}/>
        </div>
      );
    }
}

const mapStateToProps = createSelector(
  state => state.study.studyList,
  state => state.processFlow.tenantId,
  state => state.processFlow.processFlowList,
  state => state.treeView.data,
  (studyList, tenantId, processFlowList, treeView) => ({
    studyList,
    tenantId,
    processFlowList,
    treeView
  })
);

const mapActionsToProps = {
  setMessage:setMessage
}
export default connect(mapStateToProps, mapActionsToProps)(DataExploratory);
