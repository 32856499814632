import axios from 'axios';
import moment from 'moment';
import restClient from '../restClient';
import { GET_LIST } from '../restClient/types';
import { FETCH_PROJECT, ADD_PROJECT, REMOVE_PROJECT, API_CONNECT_PROJECT, SET_VISIBILITY_FILTER, SET_ERROR_MESSAGE, SET_WARNING_MESSAGE, UPDATE_PROJECT_LIST } from './actionsTypes';

const fetchProjectList = (headers) => restClient(GET_LIST, 'project/list', {pagination: { page: 1, perPage: -1 }, sort: { field: 'id', order: 'ASC' }}, headers);

export const fetchProject = (userPreferenceStudyList) => dispatch => {
  axios.all([fetchProjectList({'Tenant-ID': 'master'})])
         .then(axios.spread((response) => {
           if(response.data !== null && response.data.length === 0){
            dispatch({
              type: SET_WARNING_MESSAGE,
              payload: "Project not found. Please create new project."
            });
           }
           let projectList = response.data.map( project => Object.assign({}, project, { selected: false, expanded:false, timestamp: project.audit !== null ? moment.utc(project.audit.createdAt).format('lll') : "" }));

           projectList.map(project => {
             if(typeof(project.studyList) !== 'undefined') {
               project.studyList.map(study => {
                 study.selected = project.selected
                 if(typeof(userPreferenceStudyList) !== 'undefined' && userPreferenceStudyList !== null && userPreferenceStudyList.length >0){
                   let selectedStudy = userPreferenceStudyList.filter(selStudy => selStudy.studyId === study.studyId);
                   if(selectedStudy.length > 0){
                    study.selected = true;                }

                 }
                 study.timestamp = study.audit !== null ? moment.utc(study.audit.createdAt).format('lll') : ""

                 return study;
               })
             }
             let selectedStudy = project.studyList.filter(pstudy => pstudy.selected);
             if(selectedStudy.length > 0){
              project.selected = true;
              project.expanded = true;   
             }
             project.study = project.studyList;
             return project;
           })

           dispatch({
             type: FETCH_PROJECT,
             payload: projectList
           });
         }
      )
  ).catch(error=>{
    dispatch({
      type: SET_ERROR_MESSAGE,
      payload: "Error occurred while fetching project."
    });
  });
};

export const addProject = project => ({
    type: ADD_PROJECT,
    payload: project
})

export const removeProject = projectId => ({
    type: REMOVE_PROJECT,
    payload: projectId
})

export const apiConnectProject = status => ({
    type: API_CONNECT_PROJECT,
    payload: status
})

export const setVisibilityFilter = filter => ({
  type: SET_VISIBILITY_FILTER,
  filter
})

export const updateProjectList = projectList => ({
  type: UPDATE_PROJECT_LIST,
  payload: projectList
})
