import React from 'react';
import { connect } from 'react-redux';
import { Button } from './../Button/StandardButton';
import { createSelector } from 'reselect';
import { setMessage } from '../../../../../actions/actionNotification';
import MessageTypeConst from "../../../../MessageTypeConst";

export const MultipleColumnName = ({ tableManager, value, data, column, colIndex, rowIndex }) => {
    if (typeof(tableManager) === 'undefined') {
      return (null)
    } else {
      return (
          <div className='rgt-cell-inner' style={{alignItems: 'center'}}>
              <MultipleColumnNameClass dataItem={data} field={column.field} value={typeof(data[column.field]) !== 'undefined' ? data[column.field]: value} column={column} tableManager={tableManager}/>
          </div>
      )
    }
}

class MultipleColumnNameClass extends React.Component {

    handleChange(e) {
      let targetId = typeof(this.props.dataItem['expressionDetail'].id) !== 'undefined' ? this.props.dataItem['expressionDetail'].id : '';
      let columnIndex = e.target.parentElement.getAttribute('data-column-index');
      if (columnIndex === null) {
        columnIndex = e.target.getAttribute('data-column-index');
      }

      let {sourceVariableStatusList, sourceTable, sourceVariable, sourceVariableList, originalSourceColumnList, displaySourceTable, columnMappedList, expressionDetail } = this.props.dataItem;
      let columnDetail = sourceVariableStatusList[columnIndex];
      displaySourceTable = [];
      let sourceTableList = [];
      if (typeof(columnDetail) !== 'undefined') {
        sourceVariableStatusList.splice(columnIndex, 1);
        columnMappedList = columnMappedList.filter(columnMapped => !(columnMapped.table.target === this.props.dataItem.targetTable && columnMapped.table.source === columnDetail.tableName && columnMapped.columnTitle.target === this.props.dataItem.targetVariable && columnMapped.columnTitle.source === columnDetail.column))


        sourceVariableStatusList.forEach(sourceVariableStatus => {
          if(sourceTableList.indexOf(sourceVariableStatus.tableName) === -1) {
            sourceTableList.push(sourceVariableStatus.tableName)
          }

          if (displaySourceTable.indexOf(sourceVariableStatus.displayTableName) === -1) {
            displaySourceTable.push(sourceVariableStatus.displayTableName)
          }
        })

        this.props.dataItem['sourceTableList'] = sourceTableList;
        this.props.dataItem['sourceTable'] = sourceTableList.join(', ');
        this.props.dataItem['displaySourceTable'] = displaySourceTable.join(', ');
        this.props.dataItem['sourceVariableStatusList'] = sourceVariableStatusList;
        this.props.dataItem['columnMappedList'] = columnMappedList;
        this.props.dataItem['expressionConflict'] = false
        this.props.dataItem['multipleExpression'] = false
        if (columnMappedList.length > 0) {
          let renameColumnList = [];

          if (typeof(expressionDetail.expressionDetail) !== 'undefined' && expressionDetail.expressionDetail.length > 0) {
            columnMappedList.forEach(columnMapped => {
              let renameColumn = expressionDetail.expressionDetail.filter(expression => expression.tableName === columnMapped.table.source && typeof(expression.children) !== 'undefined' && expression.children.filter(child => child.sourceColumnName === columnMapped.columnTitle.source && typeof(child.renameSourceColumnName) !== 'undefined' && child.renameSourceColumnName !== null));
              if(renameColumn.length > 0 && renameColumnList.filter(renameColumn => renameColumn.id === columnMapped.node.source).length === 0) {
                renameColumnList.push({
                  id: columnMapped.node.source,
                  renameColumn: {
                    column: renameColumn[0].children.filter(child => child.sourceColumnName === columnMapped.columnTitle.source)[0].renameSourceColumnName
                  }
                })
              }
            })
          }

          if (targetId !== '') {
            let newExpressionDetail = this.getTargetColumnExpression(targetId, columnMappedList, [this.props.dataItem['expressionDetail']])
            if (newExpressionDetail.length > 0 ) {
              let expressionDetail = this.props.dataItem['expressionDetail'];

              if (typeof(expressionDetail.id) === 'undefined') {
                let combineExpressionList = this.props.dataItem['combineExpressionList'];

                expressionDetail = {
                  id: combineExpressionList[0].id,
                  node: combineExpressionList[0].node,
                  expressionDetail: newExpressionDetail,
                  frequency: 1
                }
              } else {
                expressionDetail.expressionDetail = newExpressionDetail
              }

              this.props.dataItem['expressionDetail'] = expressionDetail;
              this.props.dataItem['combineExpressionList'] = [];
            }
          }

        }
      }

      this.props.dataItem['expressionButton'] = columnMappedList.length === 0 ? false : true
      this.props.dataItem[this.props.field] = sourceVariableStatusList;
      this.props.tableManager.rowsApi.setRows(this.props.tableManager.rowsApi.rows)

    }

    getSingleGroupExpression = (targetId, sourceVariableList, sourceColumnList, group = 'Group 1') => {
      let expression = '';

      if (sourceColumnList.length > 0) {
        let { column, parentColumn, mappingType } = sourceColumnList[0];
        let columnType = mappingType.split('-');
        let targetType = '';
        if (columnType[1] === 'column' && typeof(column.target.column_header) !== 'undefined') {
          targetType = column.target.column_data_type;
        } else if(columnType[1] === 'value' && typeof(parentColumn.target.column_header) !== 'undefined')  {
          targetType = parentColumn.target.column_data_type;
        }

        if (targetType !== '') {
          let expressionList = [];

          if (sourceVariableList.length > 0) {
            sourceVariableList.filter(sourceElement => sourceElement.group === group).forEach((sourceVariable) => {
              let expressString = sourceVariable.expression;

              if (targetType === 'CHAR' && sourceVariableList.filter(sourceElement => sourceElement.group === group).length > 1) {
                expressString = 'trim('+expressString+')';
              }

              expressionList.push(expressString);
            })

            if (targetType !== 'CHAR') {
              expression = expressionList.join('+');
            } else {
              expression = expressionList.join('||" "||');
            }
          }
        }
      }

      return expression;
    }

    getTargetColumnExpression(targetColumn, sourceColumnList, nodeExpression, renameColumnList = [], removeSourceColumnList = [], pivotStatus = true) {
      let tableList = [];
      let isUserSaved = false;

      sourceColumnList.forEach((columnMapped) => {
            let sourceElement = '';
            let tableExpressionType = 'Single';
            let tableOperation = 'No Pivot';
            let tableExpression = '';
            let elementExpression = '';
            let sourceColumnName = '';
            let group = 'Group 1';
            let groupExpressionDetail = [];
            let mappingGrid = [];
            let targetValues = [];
            let renameColumnHeader = null;
            let renameColumnDetail = []
            let tablePivotOutput = 'Q_VALUE';

            if (nodeExpression.length > 0 && typeof(nodeExpression[0].isUserSaved) !== 'undefined') {
              isUserSaved = nodeExpression[0].isUserSaved;
            }

            if (columnMapped.mappingType !== '') {
              let columnType = columnMapped.mappingType.split('-');
              renameColumnDetail = renameColumnList.filter(renameColumnDetail => renameColumnDetail.id === columnMapped.node.source);

              if (renameColumnDetail.length > 0){
                renameColumnHeader = renameColumnDetail[0].renameColumn.column;
              }

              if (columnType[0] === 'column' && typeof(columnMapped.column.source.column_header) !== 'undefined') {
                sourceElement =   columnMapped.table.source + '->' + columnMapped.column.source.column_header;
                elementExpression = this.getExpressionString(columnMapped.column.source, columnMapped.column.target, renameColumnHeader);
                sourceColumnName = columnMapped.column.source.column_header;
              } else if(columnType[0] === 'value' && typeof(columnMapped.parentColumn.source.column_header) !== 'undefined')  {
                sourceElement =   columnMapped.table.source + '->' + columnMapped.parentColumn.source.column_header + '->' + columnMapped.columnTitle.source;
                elementExpression = this.getExpressionString(columnMapped.parentColumn.source, columnMapped.parentColumn.target, renameColumnHeader);
                sourceColumnName = columnMapped.parentColumn.source.column_header;
              }

              if (renameColumnDetail.length > 0){
                sourceElement += '('+renameColumnDetail[0].renameColumn.column+')';
              }

              if (tableExpressionType === 'Single') {
                tableExpression = elementExpression;
              }
            }

            let table = tableList.filter(table => table.tableName === columnMapped.table.source);

            if (nodeExpression.length > 0 && nodeExpression[0].expressionDetail.length > 0) {
              if (nodeExpression[0].expressionDetail.filter(expressionDetail => expressionDetail.tableName === columnMapped.table.source).length === 0) {
                nodeExpression[0].expressionDetail.push({tableName: columnMapped.table.source, expressionType: tableExpressionType, tableOperation: tableOperation, expression: tableExpression, mappingGrid: mappingGrid, targetValues: targetValues, children:[{sourceElement: sourceElement,expression: elementExpression, sourceColumnName: sourceColumnName, group: group, renameSourceColumnName: renameColumnHeader}], groupExpressionDetail: groupExpressionDetail, columnPivotMatrix: {}, pivotOutput: tablePivotOutput, columnWhereMatrix: {}, error: false})
              }

              nodeExpression[0].expressionDetail.map((expressionDetail, index) => {
                if (expressionDetail.tableName === columnMapped.table.source) {
                  const beforeSingleTableExpression = this.getSingleGroupExpression(nodeExpression[0].id, expressionDetail.children, sourceColumnList)
                  if (renameColumnDetail.length > 0 && renameColumnHeader !== null){
                    expressionDetail.children = expressionDetail.children.filter(sourceNode => sourceNode.sourceColumnName !== sourceColumnName)
                  }

                  let expressionDetailChildren = expressionDetail.children.filter(sourceNode => sourceNode.sourceColumnName === sourceColumnName);
                  if (expressionDetailChildren.length === 0 ) {
                    expressionDetail.children.push({sourceElement: sourceElement,expression: elementExpression, sourceColumnName: sourceColumnName, group: group, renameSourceColumnName: renameColumnHeader, carrySourceColumn: false, whereCondition: ''});
                    expressionDetail.children.sort((a,b) => ('' + a.group+a.sourceColumnName).localeCompare(b.group+b.sourceColumnName));
                  }

                  if (removeSourceColumnList.length > 0) {
                    expressionDetail.children = expressionDetail.children.filter(sourceColumn => removeSourceColumnList.filter(removeSourceColumn => removeSourceColumn.indexOf(expressionDetail.tableName+'-source') > -1 && removeSourceColumn.indexOf(sourceColumn.sourceColumnName+'-source') > -1).length === 0)
                  }

                  if (expressionDetail.children.length === 1) {
                    nodeExpression[0].expressionDetail[index].expressionType = 'Single';
                  }

                  if (expressionDetail.tableOperation === 'Pivot' && expressionDetail.children.length > 1) {
                    pivotStatus = true;
                    isUserSaved = false
                  }

                  if (expressionDetail.expressionType !== 'Multiple' && expressionDetail.children.length > 1 && isUserSaved === false && pivotStatus === true) {
                    nodeExpression[0].expressionDetail[index].expressionType = 'Multiple';
                    nodeExpression[0].expressionDetail[index].tableOperation = tableOperation;
                  }

                  if (!(nodeExpression[0].expressionDetail[index].expressionType === 'Multiple' && nodeExpression[0].expressionDetail[index].tableOperation === 'Pivot')) {
                    nodeExpression[0].expressionDetail[index].pivotOutput = 'Q_VALUE'
                  }

                  if ((isUserSaved === true || pivotStatus === false) && nodeExpression[0].expressionDetail[index].expressionType === 'Single') {
                    let singleTableExpression = this.getSingleGroupExpression(nodeExpression[0].id, expressionDetail.children, sourceColumnList)

                    if (beforeSingleTableExpression === nodeExpression[0].expressionDetail[index].expression) {
                      nodeExpression[0].expressionDetail[index].expression = singleTableExpression
                    }
                  }
                }

                return expressionDetail
              })
            }
        })

        tableList.length = 0;
        tableList.push(...nodeExpression[0].expressionDetail)

        tableList.map(table => {
          table.children = table.children.filter(children => sourceColumnList.filter(sourceColumn => sourceColumn.table.source === table.tableName && sourceColumn.columnTitle.source === children.sourceColumnName).length > 0)

          if (table.children.length === 1) {
            table.expressionType = 'Single'
          }
          return table
        })

      tableList = tableList.filter(table => table.children.length > 0)

      return tableList;
    }

    getExpressionString = (source, target, renameColumnHeader = null) => {
      let columnHeader = source.column_header;
      let expression = '';

      if (renameColumnHeader != null) {
        columnHeader = renameColumnHeader
      }

      if ((source.column_data_type === 'DATE' || source.column_data_type === 'DATETIME') && target.column_data_type === 'CHAR') {
          expression = 'put('+columnHeader+',is8601'+(source.column_data_type == 'DATE' ? 'da' :'dt')+'.)';
        } else if(source.column_data_type === 'CHAR' && target.column_data_type === 'NUM') {
          expression = 'input('+columnHeader+', best.)';
        } else if(source.column_data_type === 'NUM' && target.column_data_type === 'CHAR') {
          expression = 'put('+columnHeader+', best.-L)'
        } else {
          expression = columnHeader;
        }

        return expression;
    }

    render() {
        let sourceVariableStatusList = typeof(this.props.dataItem['sourceVariableStatusList']) !== 'undefined' ? this.props.dataItem['sourceVariableStatusList'].sort((a,b) => ('' + a.displayTableName).localeCompare(b.displayTableName)) : [];
        let latestTableName = sourceVariableStatusList.length > 0 ? sourceVariableStatusList[0].tableName : '';
        return (
            <>
              { sourceVariableStatusList.length > 0 &&
                sourceVariableStatusList.map((sourceVariableStatus, index) => {
                  let badgeType = 'info';
                  let columnBreakLine = false;
                  if (latestTableName !== sourceVariableStatus.tableName) {
                    latestTableName = sourceVariableStatus.tableName
                    columnBreakLine = true;
                  }
                  switch (sourceVariableStatus.status) {
                    case 0:
                      badgeType = 'danger';
                      break;
                    case 1:
                      badgeType = 'success';
                      break;
                    case 2:
                      badgeType = 'warning';
                      break;
                    case 3:
                      badgeType = 'primary';
                      break;
                  }

                  return (
                    <div key={`key-${index}-${this.props.dataItem['columnId']}-${sourceVariableStatus.column}-${sourceVariableStatus.tableName}-${sourceVariableStatus.status}`} className={columnBreakLine === true ? 'column-break-line-drow' : ''}>
                      <span className={`remove-mapped-line badge badge-pill badge-${badgeType}`}>
                        {sourceVariableStatus.displayTableName} -> {sourceVariableStatus.column}
                        <Button primary={true} icon="x" onClick={this.handleChange.bind(this)} data-column-index={index}></Button>
                      </span>
                      {typeof(sourceVariableStatus.frequency) !== 'undefined' &&
                        <span className="frequency-column">[{sourceVariableStatus.frequency}]</span>
                      }
                      {typeof(sourceVariableStatus.rename) !== 'undefined' && sourceVariableStatus.rename === 1 &&
                        <span className="rename-column">[R]</span>
                      }
                      {typeof(sourceVariableStatus.carryForward) !== 'undefined' && sourceVariableStatus.carryForward === true &&
                        <span className="carry-forward">[CF]</span>
                      }
                    </div>
                  )
                })
              }

              { sourceVariableStatusList.length == 0 &&
                <div className="align-center">--</div>
              }
            </>
        );
    }
}
