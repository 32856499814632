import { ANNOTATE_MANAGE_COLUMN_MAPPED_LIST, ANNOTATE_SET_COLUMN_MAPPING_TARGET_ID, ANNOTATE_ADD_WHERE_CONDITION, ANNOTATE_REMOVE_WHERE_CONDITION, ANNOTATE_ADD_HARDCODE, ANNOTATE_REMOVE_HARDCODE, ANNOTATE_ADD_EXPRESSION, ANNOTATE_REMOVE_EXPRESSION, ANNOTATE_RESET_STATE, ANNOTATE_SET_TABLE_EXTENDEDLIST, ANNOTATE_MANAGE_MAPPINGGRID_EXPRESSION, ANNOTATE_ADD_MULTIPLE_COLUMN_MAPPED_LIST, ANNOTATE_RESET_BULK_COLUMN_MAPPED_LIST, ANNOTATE_SET_TABLE_TYPE_LIST, ANNOTATE_ADD_RENAME_COLUMN, ANNOTATE_REMOVE_RENAME_COLUMN, ANNOTATE_UPDATE_COLUMN_DATA, ANNOTATE_SET_HOLD_ANNOTATE_DATA, ANNOTATE_HOLD_ANNOTATE_DATA_TARGET_COLUMN_OPERATION_LIST_AND_JOB_LIST, ANNOTATE_SET_DEFAULT_TARGET_COLUMN_EXPRESSION, ANNOTATE_SET_TREE_ELEMENT, ANNOTATE_ADD_APPLY_PROCESS_FLOW, ANNOTATE_REMOVE_APPLY_PROCESS_FLOW, ANNOTATE_ADD_MULTIPLE_RENAME_COLUMN, ANNOTATE_ADD_MULTIPLE_HARDCODE_COLUMN, ANNOTATE_ADD_TABLE_SORT_COLUMN, ANNOTATE_UPDATE_TREE_ELEMENT_VIA_IMMULTABLE, ANNOTATE_REMOVE_MULTIPLE_EXPRESSION, ANNOTATE_ADD_PIVOT_MATRIX_LIST, ANNOTATE_MANAGE_HARDCODE_LIST, ANNOTATE_ADD_MULTIPLE_APPLY_PROCESS_FLOW, ANNOTATE_ADD_MULTIPLE_TABLE_SORT_COLUMN, ANNOTATE_MANAGE_TABLE_VERSION_CHANGE, ANNOTATE_MANAGE_MULTIPLE_TARGET_TABLE_LIST, ANNOTATE_RESET_TARGET_COLUMN_LIST, ANNOTATE_SOURCE_TABLE_LIST_TYPE, ANNOTATE_TARGET_TABLE_LIST_TYPE, ANNOTATE_SET_HIGHLIGHTLIST, ANNOTATE_REMOVE_BLOCK_CLASSIFIER_DETAILS, ANNOTATE_MANAGE_MAPPING_SPECS_DETAIL, ANNOTATE_MANAGE_WHERE_MATRIX, ANNOTATE_MANAGE_LAST_EXECUTE_ENGINE_VALUE, ANNOTATE_MANAGE_APPLY_PROCESS_FLOW, ANNOTATE_MANAGE_TARGET_TABLE_VALUE_IN_EXPRESSION, ANNOTATE_MANAGE_ADDITIONAL_WHERE_CONDITION, ANNOTATE_MANAGE_DOCUMENT_NAME, ANNOTATE_MANAGE_CUSTOM_UNMAP_VALUE_LIST, ANNOTATE_REFRESH_TLF_VIEWER } from './actionsTypes'

export const manageMappedColumnList = mappedColumnList => dispatch => {
  dispatch({
    type: ANNOTATE_MANAGE_COLUMN_MAPPED_LIST,
    payload: mappedColumnList
  });
}

export const setColumnMappingTargetId = targetId => dispatch => {
  dispatch({
    type: ANNOTATE_SET_COLUMN_MAPPING_TARGET_ID,
    payload: targetId
  });
}

export const addWhereCondition = whereCondition => dispatch => {
  dispatch({
    type: ANNOTATE_ADD_WHERE_CONDITION,
    payload: whereCondition
  });
}

export const removeWhereCondition = whereCondition => dispatch => {
  dispatch({
    type: ANNOTATE_REMOVE_WHERE_CONDITION,
    payload: whereCondition
  });
}

export const addHardcode = hardcode => dispatch => {
  dispatch({
    type: ANNOTATE_ADD_HARDCODE,
    payload: hardcode
  });
}

export const removeHardcode = hardcode => dispatch => {
  dispatch({
    type: ANNOTATE_REMOVE_HARDCODE,
    payload: hardcode
  });
}

export const addExpression = expression => dispatch => {
  dispatch({
    type: ANNOTATE_ADD_EXPRESSION,
    payload: expression
  });
}

export const removeExpression = expression => dispatch => {
  dispatch({
    type: ANNOTATE_REMOVE_EXPRESSION,
    payload: expression
  });
}

export const removeMultipleExpression = expressionIds => dispatch => {
  dispatch({
    type: ANNOTATE_REMOVE_MULTIPLE_EXPRESSION,
    payload: expressionIds
  });
}

export const setTableExtendedList = (tableExtendedList, treeElement, classifierList = []) => dispatch => {
  dispatch({
    type: ANNOTATE_SET_TABLE_EXTENDEDLIST,
    payload: {tableExtendedList: tableExtendedList, treeElement: treeElement, classifierList: classifierList}
  });
}


export const resetState = reset => dispatch => {
  dispatch({
    type: ANNOTATE_RESET_STATE,
    payload: reset
  });
}

export const manageMappingGridExpression = (id, tableName, mappingGrid) => dispatch => {
  dispatch({
    type: ANNOTATE_MANAGE_MAPPINGGRID_EXPRESSION,
    payload: {id: id, tableName: tableName, mappingGrid: mappingGrid}
  });
}

export const addMultipleColumnMappedList = (mappedColumnList, unmapAll = false, targetColumnReset = true, pivotMatrixList = [], targetColumnValueReset = false, removeSourceTableValues = false, sourceTableList = [], whereMatrixList = []) => dispatch => {
  dispatch({
    type: ANNOTATE_ADD_MULTIPLE_COLUMN_MAPPED_LIST,
    payload: { mappedColumnList: mappedColumnList, unmapAll: unmapAll, targetColumnReset: targetColumnReset, pivotMatrixList: pivotMatrixList, targetColumnValueReset: targetColumnValueReset, removeSourceTableValues: removeSourceTableValues, sourceTableList: sourceTableList, whereMatrixList: whereMatrixList }
  });
}

export const resetBulkColumnMappedList = reset => dispatch => {
  dispatch({
    type: ANNOTATE_RESET_BULK_COLUMN_MAPPED_LIST,
    payload: reset
  });
}

export const setTableTypeList = (tableType, type) => dispatch => {
  dispatch({
    type: ANNOTATE_SET_TABLE_TYPE_LIST,
    payload: {tableType: tableType, type: type}
  });
}

export const addRenameColumn = renameColumn => dispatch => {
  dispatch({
    type: ANNOTATE_ADD_RENAME_COLUMN,
    payload: renameColumn
  });
}

export const removeRenameColumn = renameColumn => dispatch => {
  dispatch({
    type: ANNOTATE_REMOVE_RENAME_COLUMN,
    payload: renameColumn
  });
}

export const updateColumnData = columnData => dispatch => {
  dispatch({
    type: ANNOTATE_UPDATE_COLUMN_DATA,
    payload: columnData
  });
}

export const setHoldAnnotateData = annotateData => dispatch => {
  dispatch({
    type: ANNOTATE_SET_HOLD_ANNOTATE_DATA,
    payload: annotateData
  });
}

export const setHoldAnnotateDataAndTargetColumnOperationListAndJobList = (holdAnnotateData, targetColumnOperationList, jobList) => dispatch => {
  dispatch({
    type: ANNOTATE_HOLD_ANNOTATE_DATA_TARGET_COLUMN_OPERATION_LIST_AND_JOB_LIST,
    payload: {holdAnnotateData: holdAnnotateData, targetColumnOperationList: targetColumnOperationList, jobList: jobList}
  });
}

export const setDefaultTargetColumnExpression = (columnExpressionList, pivotMatrixUpdate = false, targetNodeUpdate = true) => dispatch => {
  dispatch({
    type: ANNOTATE_SET_DEFAULT_TARGET_COLUMN_EXPRESSION,
    payload: {columnExpressionList: columnExpressionList, pivotMatrixUpdate: pivotMatrixUpdate, targetNodeUpdate: targetNodeUpdate}
  });
}

export const setTreeElement = treeElement => dispatch => {
  dispatch({
    type: ANNOTATE_SET_TREE_ELEMENT,
    payload:treeElement
  });
}

export const addApplyProcessFlow = applyProcessFlow => dispatch => {
  dispatch({
    type: ANNOTATE_ADD_APPLY_PROCESS_FLOW,
    payload: applyProcessFlow
  });
}

export const addMultipleApplyProcessFlow = applyProcessFlowList => dispatch => {
  dispatch({
    type: ANNOTATE_ADD_MULTIPLE_APPLY_PROCESS_FLOW,
    payload: { applyProcessFlowList: applyProcessFlowList }
  });
}

export const manageApplyProcessFlow = applyProcessFlowList => dispatch => {
  dispatch({
    type: ANNOTATE_MANAGE_APPLY_PROCESS_FLOW,
    payload: applyProcessFlowList
  });
}

export const removeApplyProcessFlow = applyProcessFlow => dispatch => {
  dispatch({
    type: ANNOTATE_REMOVE_APPLY_PROCESS_FLOW,
    payload: applyProcessFlow
  });
}

export const addMultipleRenameColumn = renameColumnList => dispatch => {
  dispatch({
    type: ANNOTATE_ADD_MULTIPLE_RENAME_COLUMN,
    payload: renameColumnList
  });
}

export const addMultipleHardcodeColumn = hardcodeColumnList => dispatch => {
  dispatch({
    type: ANNOTATE_ADD_MULTIPLE_HARDCODE_COLUMN,
    payload: hardcodeColumnList
  });
}

export const addTableSortColumn = tableSortColumns => dispatch => {
  dispatch({
    type: ANNOTATE_ADD_TABLE_SORT_COLUMN,
    payload: tableSortColumns
  });
}

export const addMultipleTableSortColumn = tableSortColumnList => dispatch => {
  dispatch({
    type: ANNOTATE_ADD_MULTIPLE_TABLE_SORT_COLUMN,
    payload: {tableSortColumnList: tableSortColumnList}
  });
}

export const updateTreeElementViaImmutable = treeElement => dispatch => {
  dispatch({
    type: ANNOTATE_UPDATE_TREE_ELEMENT_VIA_IMMULTABLE,
    payload:treeElement
  });
}
export const addPivotMatrixList = pivotMatrixList => dispatch => {
  dispatch({
    type: ANNOTATE_ADD_PIVOT_MATRIX_LIST,
    payload:pivotMatrixList
  });
}

export const manageHardcodeList = hardcodeList => dispatch => {
  dispatch({
    type: ANNOTATE_MANAGE_HARDCODE_LIST,
    payload: hardcodeList
  });
}

export const manageTableVersionChange = tableVersionAnnotate => dispatch => {
  dispatch({
    type: ANNOTATE_MANAGE_TABLE_VERSION_CHANGE,
    payload: tableVersionAnnotate
  });
}

export const manageMultipleTargetTableList = virtualTargetTableList => dispatch => {
  dispatch({
    type: ANNOTATE_MANAGE_MULTIPLE_TARGET_TABLE_LIST,
    payload: virtualTargetTableList
  });
}

export const restTargetColumnList = (targetColumnList) => dispatch => {
  dispatch({
    type: ANNOTATE_RESET_TARGET_COLUMN_LIST,
    payload: {targetColumnList: targetColumnList}
  });
}

export const setSourceTableListType = (sourceTableListType, removeSourceGroupStatus = true) => dispatch => {
  dispatch({
    type: ANNOTATE_SOURCE_TABLE_LIST_TYPE,
    payload: {sourceTableListType: sourceTableListType, removeSourceGroupStatus: removeSourceGroupStatus}
  });
}

export const setTargetTableListType = targetTableListType => dispatch => {
  dispatch({
    type: ANNOTATE_TARGET_TABLE_LIST_TYPE,
    payload: targetTableListType
  });
}

export const setHighlightList = highlightList => dispatch => {
  dispatch({
    type: ANNOTATE_SET_HIGHLIGHTLIST,
    payload: highlightList
  });
}
export const removedBlockClassifierDetails = classifierName => dispatch => {
  dispatch({
    type: ANNOTATE_REMOVE_BLOCK_CLASSIFIER_DETAILS,
    payload: classifierName
  });
}
export const manageMappingSpecsDetail = mappingSpecsDetail => dispatch => {
  dispatch({
    type: ANNOTATE_MANAGE_MAPPING_SPECS_DETAIL,
    payload: mappingSpecsDetail
  });
}
export const manageWhereMatrix = whereMatrixList => dispatch => {
  dispatch({
    type: ANNOTATE_MANAGE_WHERE_MATRIX,
    payload: whereMatrixList
  });
}

export const manageLastExecuteEngineValue = lastExecuteEngineValue => dispatch => {
  dispatch({
    type: ANNOTATE_MANAGE_LAST_EXECUTE_ENGINE_VALUE,
    payload: lastExecuteEngineValue
  });
}

export const manageTargetTableValueInExpression = TargetTableVariable => dispatch => {
  dispatch({
    type: ANNOTATE_MANAGE_TARGET_TABLE_VALUE_IN_EXPRESSION,
    payload: TargetTableVariable
  });
}

export const manageAdditionalWhereCondition = additionalWhereConditionList => dispatch => {
  dispatch({
    type: ANNOTATE_MANAGE_ADDITIONAL_WHERE_CONDITION,
    payload: additionalWhereConditionList
  });
}

export const manageDocumentName = documentName => dispatch => {
  dispatch({
    type: ANNOTATE_MANAGE_DOCUMENT_NAME,
    payload: documentName
  });
}

export const manageCustomUnmapValueList = customUnmapValueList => dispatch => {
  dispatch({
    type: ANNOTATE_MANAGE_CUSTOM_UNMAP_VALUE_LIST,
    payload: customUnmapValueList
  });
}

export const refreshTlfViewer = refreshTlfViewerStatus => dispatch => {
  dispatch({
    type: ANNOTATE_REFRESH_TLF_VIEWER,
    payload: refreshTlfViewerStatus
  });
}
