import React from 'react';
import BlockCreationType from './../../BlockCreationType';
import { Input } from './../DataOperation/common/InputBox/StandardInput';
import $ from 'jquery';
import BlockStatus from './BlockStatus';

export default class SingleBlockPasteDialog extends React.Component{
    constructor(props){
        super(props);
        let disabledCopyLinkData = false;
        if(props.selectsItems[0] != null && typeof(props.selectsItems[0]) !== "undefined"){
            if(props.currentProcessId === props.selectsItems[0].processId &&
                props.currentStudyId === props.selectsItems[0].studyId){
                disabledCopyLinkData = true;
            }
        }
        if(props.selectsItems[0] != null && typeof(props.selectsItems[0]) !== "undefined"){
            if(props.selectsItems[0].blockCreationType === BlockCreationType.DEF_COPY
                && props.selectsItems[0].blockSyncFlag === BlockStatus.INVALID){
                disabledCopyLinkData = true;
            }
            if(props.selectsItems[0].tableExists === 0){
                disabledCopyLinkData = true;
            }
        }
        this.state = {
            currentProcessId:props.currentProcessId,
            selectsItems : props.selectsItems,
            blockCreationType : props.selectsItems[0] != null && typeof(props.selectsItems[0]) !== "undefined" ?
            props.selectsItems[0].blockCreationType : '',
            blockStudyId : props.selectsItems[0] != null && typeof(props.selectsItems[0]) !== "undefined" ?
            props.selectsItems[0].studyId : '',
            blockType : props.selectsItems[0] != null && typeof(props.selectsItems[0]) !== "undefined" ?
            props.selectsItems[0].blockType : '',
            currentStudyId: props.currentStudyId,
            disabledLinkandCopyData:disabledCopyLinkData,
            disableBlockNameStatus: false
        }

        if (((this.state.blockCreationType === BlockCreationType.IMPORT_META_TABLE ||
           this.state.blockCreationType === BlockCreationType.IMPORT_SOURCE_TABLE ||
           this.state.blockCreationType === BlockCreationType.DATA_COPY ||
           this.state.blockCreationType === BlockCreationType.DATA_LINK ) && this.state.blockStudyId === this.state.currentStudyId) || (this.state.blockStudyId === this.state.currentStudyId && !this.state.disabledLinkandCopyData)) {
          this.state.disableBlockNameStatus = true
        }

        if (this.state.blockType === 2) {
          this.state.disableBlockNameStatus = false
        }
    }

    handleSaveTypeChange = (event) => {
      if (event.target.value === '5') {
        $("#txtblockname").val(this.state.selectsItems[0].blockName);
      }

      this.setState({disableBlockNameStatus: event.target.value === '5'})
    }

    render(){
        return(
            <div className="m-t-5 single-block-paste">
            <div className="am-form-line-left">
            <label className="pro-label p-0"><span >Block Name </span></label>
            <Input className="text-box-display blockname full-width" id="txtblockname" defaultValue={this.state.selectsItems[0].blockName} disabled={this.state.disableBlockNameStatus} maxLength={150} />
        </div>
        {this.state.blockType === 2 ?
            (<div className="am-form-line-left">
            <label><input type="radio" name="optradio" value="6" defaultChecked={true} onClick={this.handleSaveTypeChange} />Copy Definition</label>
            </div>)
        :
       (
         (this.state.blockCreationType === BlockCreationType.IMPORT_META_TABLE ||
            this.state.blockCreationType === BlockCreationType.IMPORT_SOURCE_TABLE ||
            this.state.blockCreationType === BlockCreationType.DATA_COPY ||
            this.state.blockCreationType === BlockCreationType.DATA_LINK )
         ? (
            <div className="m-t-5">
                <div className="am-form-line-left">
                    <label><input type="radio" name="optradio" value="4" defaultChecked={!this.state.disabledLinkandCopyData} disabled={this.state.disabledLinkandCopyData} onClick={this.handleSaveTypeChange}/>Copy Data</label>
                </div>
                {
                    (this.state.blockStudyId === this.state.currentStudyId) &&
                    <div className="am-form-line-left">
                    <label><input type="radio" name="optradio" value="5" defaultChecked={!this.state.disabledLinkandCopyData} disabled={this.state.disabledLinkandCopyData} onClick={this.handleSaveTypeChange}/>Link Data</label>
                    </div>
                }
            </div>
          ) : (
            <div className="m-t-5">
                {
                    (!this.state.disabledLinkandCopyData) &&
                    <div className="am-form-line-left">
                        <label><input type="radio" name="optradio" value="4" defaultChecked={!this.state.disabledLinkandCopyData} onClick={this.handleSaveTypeChange} />Copy Data</label>
                    </div>
                }
                {
                    (this.state.blockStudyId === this.state.currentStudyId && !this.state.disabledLinkandCopyData) &&
                    <div className="am-form-line-left">
                    <label><input type="radio" name="optradio" value="5" defaultChecked={!this.state.disabledLinkandCopyData} onClick={this.handleSaveTypeChange} />Link Data</label>
                    </div>
                }
                <div className="am-form-line-left">
                    <label><input type="radio" name="optradio" value="6" defaultChecked={this.state.disabledLinkandCopyData} onClick={this.handleSaveTypeChange} />Copy Definition</label>
                </div>
            </div>
          ))}

            </div>
        )
    }
}
