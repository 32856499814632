import React from "react";
import TextEditor from '../../TextEditor';

class List extends React.Component {
  render() {
      return (
        <div className="list_file">
          <TextEditor options={{ lineNumbers: true, readOnly: true}} code={global.blockList} />
        </div>
      );
    }
}

export default List;
