import React from 'react';
import { MultiSelectDropDownList } from './StandardMultiSelectDropDownList';

export const StandardTableGridMultileDropDownCell = ({ tableManager, value, data, column, colIndex, rowIndex }) => {
    if (typeof(tableManager) === 'undefined') {
      return (null)
    } else {
      return (
          <div className='rgt-cell-inner' style={{display: 'flex', alignItems: 'center', overflow: 'hidden'}}>
              <StandardTableGridMultileDropDownCellClass dataItem={data} field={column.field} value={typeof(data[column.field]) !== 'undefined' ? data[column.field]: value} column={column} tableManager={tableManager}/>
          </div>
      )
    }
}


class StandardTableGridMultileDropDownCellClass extends React.Component {
    handleChange(e) {
      this.props.dataItem[this.props.field] = e.value.join(',');
      this.props.tableManager.rowsApi.setRows(this.props.tableManager.rowsApi.rows)
    }

    render() {
        const data = typeof(this.props.dataItem[this.props.field+"List"]) ? this.props.dataItem[this.props.field+"List"]: []
        const valueList = this.props.dataItem[this.props.field] === "" ? [] : this.props.dataItem[this.props.field].split(',');
        let isValueMissed = '';

        if (valueList.length > 0) {
          valueList.forEach(value => {
            if (data.indexOf(value) === -1 && isValueMissed === '') {
              isValueMissed = ' error';
            }
          })
        }

        return (
            <>
              <MultiSelectDropDownList className={`width-full${isValueMissed}`} onChange={this.handleChange.bind(this)} data={data} value={valueList} />
            </>
        );
    }
}
