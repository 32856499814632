import React    from "react";
import axios from 'axios';
import { connect } from 'react-redux';
import { createSelector } from 'reselect';
import $ from 'jquery';
import cloneDeep from 'lodash/cloneDeep';
import template from "./StudySampleFilter.jsx";
import restClient from './../../../restClient';
import { GET, CREATE, GET_ALL } from './../../../restClient/types';
import {setMessage} from './../../../actions/actionNotification';
import MessageTypeConst from "./../../MessageTypeConst";
import { NewColumnNameCellForValue } from './../DataOperation/common';
import moment from 'moment';

class StudySampleFilter extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      node: props.node,
      tenantId: props.node.study.schemaName,
      classifierList: [],
      selectedBlockClassifier: [],
      tableList:{
        id: 'sample-filter-study-grid',
        rows: [],
        resize: true,
        columnProperty: [
          { field: "classifierName", width: '150px' },
          { field: "blockName", width: '250px' },
          { field: "tableName", show: false },
        ]
      },
      engineList: [],
      sampleFilterDatas: []
    }

    this.handleSaveClick = this.handleSaveClick.bind(this);
  }

  fecthClassifier = (headers, params) => restClient(GET, 'processblock/classifier', params, headers);

  fecthClassifierTableList = (headers, params) => restClient(CREATE, 'sampleFilter/fetch', params, headers);

  fecthProcessEngine = (headers, params) => restClient(GET_ALL, 'master/config/processingengines/getall', params, headers);

  saveSampleFilterList = (headers, params) => restClient(CREATE, 'sampleFilter/save', params, headers);

  componentDidMount(){
    $(".loader").show();
    if (this.state.classifierList.length === 0) {
      axios.all([this.fecthClassifier({'Tenant-ID': this.state.tenantId}, {}), this.fecthProcessEngine({'Tenant-ID': 'master'}, {})])
          .then(axios.spread((blockClassifierList, processEngineList) => {
            if (typeof(blockClassifierList.data) !== 'undefined') {
              let selectedBlockClassifier = [];
              let engineList = [];
              blockClassifierList.data.filter(blockClassifier => blockClassifier.classifierId === 1).forEach(blockClassifier => {
                selectedBlockClassifier.push(blockClassifier.classifierName)
              })

              if(typeof(processEngineList.data) !== 'undefined' && processEngineList.data !== null && processEngineList.data.length > 0) {
                processEngineList.data.forEach((engine)=>{
                  engineList.push({text: engine.engineName + ' - ' + engine.engineVersion, value: engine.engineType, isDefault: engine.isDefault});
                });
              }
              this.setState({classifierList: blockClassifierList.data, selectedBlockClassifier: selectedBlockClassifier, engineList: engineList}, () => {
                this.fetchClassifierTableList()
              })
            } else {
              $(".loader").hide();
            }
          })).catch(error => {
              $(".loader").hide();
              this.props.setMessage("Error occurred while fetching classifier", MessageTypeConst.ERROR_MESSAGE);
          });
    }
  }

  fetchClassifierTableList = () => {
    let classifierIdList = [];
    this.state.selectedBlockClassifier.forEach(selectedClassifier => {
      this.state.classifierList.filter(classifier => classifier.classifierName === selectedClassifier).forEach(classifier => {
          classifierIdList.push(classifier.classifierId)
      })
    })
    if (classifierIdList.length > 0) {
      $(".loader").show();
      axios.all([this.fecthClassifierTableList({'Tenant-ID': this.state.tenantId}, {data: {classifiers: classifierIdList}})])
          .then(axios.spread((blockClassifierTableList) => {
            if (typeof(blockClassifierTableList.data) !== 'undefined' && typeof(blockClassifierTableList.data.sampleFilterDatas) !== 'undefined') {
              let tableList = this.state.tableList;
              tableList.rows.length = 0;
              blockClassifierTableList.data.sampleFilterDatas.forEach(sampleFilterData => {
                let classifierName = '';
                let row = {};

                this.state.classifierList.filter(classifier => classifier.classifierId === sampleFilterData.classifier).forEach(classifier => {
                    classifierName = classifier.classifierName
                })
                row =  Object.assign(row, {classifierName: classifierName, blockName: sampleFilterData.blockName, tableName: sampleFilterData.tableName});

                this.state.engineList.forEach(engine => {
                  let fieldTitle = "engine_"+engine.value;
                  let fieldValue = "";
                  if (sampleFilterData.sampleFilter !== null && sampleFilterData.sampleFilter !== '') {
                    const sampleFilterList = JSON.parse(sampleFilterData.sampleFilter);
                    sampleFilterList.filter(sampleFilter => sampleFilter.engineCode === engine.value).forEach(sampleFilter => {
                      fieldValue = sampleFilter.whereCondition
                    })
                  }
                  row = Object.assign(row, {[fieldTitle]: fieldValue})
                  tableList.columnProperty = tableList.columnProperty.filter(columnField => columnField.field !== fieldTitle);
                  tableList.columnProperty.push({field: fieldTitle, title: engine.text, cell: NewColumnNameCellForValue})
                })
                tableList.rows.push(row)
              })
              this.setState({tableList: tableList, sampleFilterDatas: blockClassifierTableList.data.sampleFilterDatas})
            }
            $(".loader").hide();
          })).catch(error => {
              $(".loader").hide();
              this.props.setMessage("Error occurred while fetching classifier table list", MessageTypeConst.ERROR_MESSAGE);
          });
    }
  }

  handleBlockClassifierSelectChange = (event) => {
    const selectedBlockClassifier = event.value
    this.setState({selectedBlockClassifier: selectedBlockClassifier} , () => {
      this.fetchClassifierTableList()
    })
  }

  handleSaveClick = (event) => {
    let {tableList, engineList, sampleFilterDatas} = this.state;
    let saveTableNameList = [];
    tableList.rows.forEach(row => {
      let sampleFilter = [];
      engineList.forEach(engine => {
        if (typeof(row['engine_'+engine.value]) !== 'undefined' && row['engine_'+engine.value] !== '' && row['engine_'+engine.value] !== null) {
          sampleFilter.push({engineCode: engine.value, whereCondition: row['engine_'+engine.value].trim()})
        }
      })

      sampleFilterDatas.filter(sampleFilterData => sampleFilterData.tableName === row.tableName).map(sampleFilterData => {
        if (sampleFilterData.sampleFilter !== null && sampleFilterData.sampleFilter !== '' && sampleFilter.length === 0) {
          sampleFilterData.sampleFilter = null;
          saveTableNameList.push(sampleFilterData.tableName)
        }
        if (sampleFilter.length > 0) {
          if (sampleFilterData.sampleFilter !== JSON.stringify(sampleFilter)) {
            sampleFilterData.sampleFilter = JSON.stringify(sampleFilter)
            saveTableNameList.push(sampleFilterData.tableName)
          }
        }
        return sampleFilterData
      })
    })

    sampleFilterDatas = sampleFilterDatas.filter(sampleFilterData => saveTableNameList.indexOf(sampleFilterData.tableName) > -1)
    if (sampleFilterDatas.length > 0) {
      $(".loader").show();
      axios.all([this.saveSampleFilterList({'Tenant-ID': this.state.tenantId}, {data: sampleFilterDatas})])
          .then(axios.spread((response) => {
            if (typeof(response.data) !== 'undefined') {
                this.props.setMessage(response.data.message, response.data.messageType);
                if (response.data.messageType === MessageTypeConst.SUCCESS_MESSAGE) {
                  if (typeof(response.data.blocks) !== 'undefined') {
                    this.props.updateProcessFlowAndProcessBlockStatus(this.state.tenantId, response.data.blocks)
                  }
                  this.props.closeEvent()
                }
            }
            $(".loader").hide();
          })).catch(error => {
              $(".loader").hide();
              this.props.setMessage("Error occurred while saving sample filter details", MessageTypeConst.ERROR_MESSAGE);
          });
    } else {
      this.props.closeEvent()
    }
  }

  render() {
    return template.call(this);
  }
}
const mapStateToProps = createSelector(
  state => state.treeView.data,
  (treeView) => ({
    treeView,
  })
);

const mapActionsToProps = {
  setMessage: setMessage
}

export default connect(mapStateToProps, mapActionsToProps)(StudySampleFilter);
