import React from "react";
import cloneDeep from 'lodash/cloneDeep';
import { Button } from './../Button/StandardButton';
import { Input } from './../InputBox/StandardInput';
import RichGridTable from './../../../RichGridTable';
import { PanelBar, PanelBarItem } from './../Layout';
import restClient from '../../../../../restClient';
import { GET_ALL } from '../../../../../restClient/types';
import $ from 'jquery';
import axios from 'axios';
import MessageTypeConst from "../../../../MessageTypeConst";
import { connect } from 'react-redux';
import { PropTypes } from 'prop-types';
import { createSelector } from 'reselect';
import { setMessage } from '../../../../../actions/actionNotification';
import DataOpsIdentifierConstants from "../../../../DataOpsIdentifierConstants";

let outputTableColumnList = null;
let outputTableIsDistinct = false;
class DataGridOperation extends React.Component {
  fetchDataViewer = (headers, params) => restClient(GET_ALL, 'dataviewer/filter', params, headers);
  constructor(props) {
    super(props);

    this.state={
      tableList: props.tableList,
      wrapperClassName: '',
      gridType:props.type,
      expandedclassName: [],
      tenantID: props.tenantID,
      postKey:0
    }

    this.handleTableStateChangeEvent = this.handleTableStateChangeEvent.bind(this);
    this.handleWhereCondtionChange = this.handleWhereCondtionChange.bind(this);
    this.getPreWhereCondition = this.getPreWhereCondition.bind(this);
    this.getSampleFilter = this.getSampleFilter.bind(this);
    this.addUpdateValueinGlobalVariable = this.addUpdateValueinGlobalVariable.bind(this);
    this.handlePanelSelect = this.handlePanelSelect.bind(this);
    this.handleApplyButton = this.handleApplyButton.bind(this);
    this.fetchData = this.fetchData.bind(this);
    this.handleDistinctChange = this.handleDistinctChange.bind(this);
    this.getBlockName = this.getBlockName.bind(this);
    global.dataExplorerTablePanelBarItemStatus = [];
  }

  componentDidMount(){
    let wrapperClassName = '';
    let tableList = this.state.tableList;

    if (typeof(tableList) !== 'undefined') {
      let uniqueObject = this.getUniqueTables(tableList);
      if (uniqueObject.length === 2) {
        wrapperClassName = 'two-grid';
      } else if(uniqueObject.length > 2) {
        wrapperClassName = 'two-grid more-than-two-grid';
      }

      tableList.map(table => {
        if (typeof(table.originVariableList) === 'undefined' && typeof(table.columns) !== 'undefined' && typeof(table.columns.columnField) !== 'undefined' && table.columns.columnField !== null && table.columns.columnField !== '') {
          table.originVariableList = cloneDeep(table.columns.columnField.split(',').join(' '));
          table.displayVariableList = cloneDeep(table.columns.columnField.split(',').join(' '));
        }
        return table;
      })
    }

    this.setState({tableList: tableList, wrapperClassName: wrapperClassName});
  }
  UNSAFE_componentWillReceiveProps(nextProps) {
    if(JSON.stringify(nextProps) !== '') {
      var wrapperClassName = '';
      let uniqueObject = this.getUniqueTables(nextProps.tableList);
      if (uniqueObject.length === 2) {
        wrapperClassName = 'two-grid';
      } else if(uniqueObject.length > 2) {
        wrapperClassName = 'two-grid more-than-two-grid';
      }
      let tableList = nextProps.tableList;

      tableList.map(table => {
        if (typeof(table.originVariableList) === 'undefined' && typeof(table.columns) !== 'undefined' && typeof(table.columns.columnField) !== 'undefined' && table.columns.columnField !== null && table.columns.columnField !== '') {
          table.originVariableList = cloneDeep(table.columns.columnField.split(',').join(' '));
          table.displayVariableList = cloneDeep(table.columns.columnField.split(',').join(' '));
        }
        return table;
      })
      this.setState({tableList: tableList, wrapperClassName: wrapperClassName});
    }
  }
  getPreWhereCondition(table){
    let condition = '';
    if(typeof(table) !== 'undefined'){
      let preWhereData = '';
      if(this.state.gridType === 'preWhere'){
        preWhereData = this.getGlobalValue(-99);
      } else if(this.state.gridType === 'data'){
        preWhereData = this.getGlobalValue(-96);
      }
      if(typeof(preWhereData) !== 'undefined' && preWhereData !== ''  && preWhereData !== null){
          let preWhere = JSON.parse(preWhereData);
          let selectedObj = preWhere.filter((obj => obj.tableName === table.name));
          if(selectedObj.length > 0){
              condition = selectedObj[0].preWhereCondition;
          }
      }
    }
    return condition;
  }
  getSampleFilter = (table) => {
    let sampleFilter = '';
    if(typeof(table) !== 'undefined'){
      let preWhereData = '';
      if(this.state.gridType === 'preWhere'){
        preWhereData = this.getGlobalValue(-99);
      }
      if(typeof(preWhereData) !== 'undefined' && preWhereData !== ''  && preWhereData !== null){
          let preWhere = JSON.parse(preWhereData);
          preWhere.filter(obj => obj.tableName === table.name && typeof(obj.sampleFilter) !== 'undefined').forEach(obj => {
            sampleFilter = obj.sampleFilter;
          })
      }
    }
    return sampleFilter;
  }
  getGlobalValue(controlIndex){
    let controlValue = '';
    global.processBlockControlValues.forEach((control, index) => {
      if (control.controlId === controlIndex) {
        controlValue = global.processBlockControlValues[index].controlValue;
      }
    });
    return controlValue;
  }
  saveGlobalValue(controlIndex, value){
    global.processBlockControlValues.forEach((control, index) => {
      if (control.controlId === controlIndex) {
        global.processBlockControlValues[index].controlValue = value;
      }
    });
  }

  handleTableStateChangeEvent(event, tableName) {
    let tableList = this.state.tableList;
    tableList.filter(table => table.name === tableName && typeof(table.originVariableList) !== 'undefined').map(table => {
      table.displayVariableList = cloneDeep(table.originVariableList);
      return table;
    })
    this.setState({tableList: tableList}, () => {
      this.handleApplyButton(event);
    });
  }

  handleApplyButton(event){
    let isReset = false;
    this.fetchData(event,isReset);
  }

  handleDistinctChange = (event, tableIndex) => {
    let tableList = this.state.tableList;

    if (typeof(tableList[tableIndex]) !== 'undefined') {
      tableList[tableIndex] = Object.assign({}, tableList[tableIndex], {distinctChecked: event.target.checked})
    }

    this.setState({tableList: tableList});
  }

  fetchData(event, isReset = false)
  {
    let tableName = event.target.value;
    let tableIndex = 0;
    this.state.tableList.forEach((table, index) =>{
        if(table.name === tableName){
          tableIndex = index;
        }
    })
    let selectedTable = this.state.tableList.filter((table)=> table.name === tableName);
    if(selectedTable.length === 0){
      return;
    }
    let tenantID = this.state.tenantID;
    let whereCondition = '';
    let columnList = '';
    let isDistinct = '';
    let firstRow = selectedTable[0].rows[0];
    const sampleFilter = this.getSampleFilter({name: tableName})
    if (typeof(document.getElementById(`${tableName}-${this.state.gridType}`)) !== 'undefined' && document.getElementById(`${tableName}-${this.state.gridType}`) !== null) {
      whereCondition = document.getElementById(`${tableName}-${this.state.gridType}`).value;
    } else {
      whereCondition = this.getPreWhereCondition({name: tableName});
    }

    if (sampleFilter !== '' && sampleFilter !== null) {
      whereCondition = whereCondition + (whereCondition !== '' ? 'AND ': '') + sampleFilter
    }
    if (typeof(document.getElementsByClassName('variables_input_'+tableName+'_'+this.state.gridType)) !== 'undefined' && document.getElementsByClassName('variables_input_'+tableName+'_'+this.state.gridType).length > 0) {
      columnList = document.getElementsByClassName('variables_input_'+tableName+'_'+this.state.gridType)[0].value;
    } else {
      columnList = outputTableColumnList;
    }

    if (typeof(document.getElementById(tableName+'-nodupkey-'+ this.state.gridType)) !== 'undefined' && document.getElementById(tableName+'-nodupkey-'+ this.state.gridType) !== null) {
      isDistinct = document.getElementById(tableName+'-nodupkey-'+ this.state.gridType).checked;
    } else {
      isDistinct = outputTableIsDistinct;
    }

    if (columnList === null) {
      columnList = this.getSpaceSeperatedColumn(firstRow, tableName);
    }
    var that = this;
    if (!isReset && (columnList === '' || columnList === null)) {
      that.props.setMessage("Please enter variable", MessageTypeConst.WARNING_MESSAGE);
    } else {
      $(".loader").show();

      const isOutputTable = global.outputBlockTable === tableName && global.processBlockExecuteBlockId > 0
      axios.all([this.fetchDataViewer({'Tenant-ID': tenantID}, {whereCondition: whereCondition,isOutputTable: isOutputTable,
        columnList:columnList,isReset:isReset,isDistinct:isDistinct,tableName:tableName,
         pagination:{ page: 1, perPage: 50 }, engineId: typeof(that.props.selectedEngine) !== 'undefined' && typeof(that.props.selectedEngine.value) !== 'undefined' ? that.props.selectedEngine.value : 0})])
          .then(axios.spread((dataViewer) => {
              $(".loader").hide();
              if(typeof(dataViewer.data) !== 'undefined') {
                if(dataViewer.data.messageType !== MessageTypeConst.SUCCESS_MESSAGE){
                  that.props.setMessage(dataViewer.data.message, dataViewer.data.messageType);
                } else {
                  let tableList=  this.state.tableList;
                  let postKey = this.state.postKey + 1
                  let displayVariableList = []
                  if (dataViewer.data.records.length === 0) {
                    if (dataViewer.data.records.length === 0) {
                      for (var key in firstRow) {
                        if (firstRow.hasOwnProperty(key)) {
                          firstRow[key] = '';
                        }
                      }
                    }

                    dataViewer.data.records = [firstRow];
                  }

                  tableList[tableIndex].rows.length = 0;
                  tableList[tableIndex].rows.push(...dataViewer.data.records);
                  tableList[tableIndex].pagination.action = 'dataviewer/filter';
                  tableList[tableIndex].pagination.method = "GET_ALL";
                  tableList[tableIndex].pagination.params = {whereCondition: whereCondition,isOutputTable: isOutputTable,
                    columnList:columnList,isReset:false,isDistinct:isDistinct,tableName:tableName, engineId: typeof(that.props.selectedEngine) !== 'undefined' && typeof(that.props.selectedEngine.value) !== 'undefined' ? that.props.selectedEngine.value : 0}
                  tableList[tableIndex].total = dataViewer.data.count;
                  tableList[tableIndex].columnProperty = this.getColumnProperty(dataViewer.data.records[0], tableName);
                  tableList[tableIndex].columns = {};
                  tableList[tableIndex].columns.columnField = this.getColumnFields(dataViewer.data.records[0], tableName);
                  tableList[tableIndex].dataState = {take: 50, skip: 0};
                  tableList[tableIndex].distinctChecked = isDistinct;
                  this.setState({tableList:tableList, postKey: postKey});
                  that.props.setMessage("Data refreshed successfully according to condition.", MessageTypeConst.SUCCESS_MESSAGE);
                }


              }})).catch(error => {
                  $(".loader").hide();
                  that.props.setMessage("Failed to fetch the data please check the variables and condition.", MessageTypeConst.ERROR_MESSAGE);
              });
    }
  }

  handleWhereCondtionChange(event) {
    if(this.state.gridType === 'preWhere'){
      let tableName = event.target.id.substr(0,event.target.id.lastIndexOf('-'));
      this.addUpdateValueinGlobalVariable(-99, tableName,event.target.value);
    } else if(this.state.gridType === 'data'){
      let tableName = event.target.id.substr(0,event.target.id.lastIndexOf('-'));
      this.addUpdateValueinGlobalVariable(-96, tableName,event.target.value);
    }
      this.setState({tableList: this.state.tableList});
  }

  addUpdateValueinGlobalVariable(GlobalId,  tableName, value, sampleFilter){
    let preWhereData = this.getGlobalValue(GlobalId);
    if(preWhereData !== '' && preWhereData !== null){
      let preWhere = JSON.parse(preWhereData);
      let objectFound = false;
      let objIndex = preWhere.findIndex((obj => obj.tableName === tableName));
      if(objIndex > -1){
        preWhere[objIndex].preWhereCondition = value;
        objectFound = true;

        if (typeof(sampleFilter) !== 'undefined') {
          preWhere[objIndex].sampleFilter = sampleFilter;
        }

        if (typeof(preWhere[objIndex].blockName) === 'undefined' || preWhere[objIndex].blockName === '') {
          preWhere[objIndex]['blockName'] = this.getBlockName(tableName)
        }
      }
      //add new condition
      if(!objectFound){
        let condition = {tableName:tableName,preWhereCondition:value,blockName: this.getBlockName(tableName), sampleFilter: typeof(sampleFilter) !== 'undefined' ? sampleFilter : ""};
        preWhere.push(condition);
      }
      if(GlobalId !== -96){
        preWhere = this.arrangePreWhereAccordingToInputTableSequence(preWhere);
      }
      this.saveGlobalValue(GlobalId,JSON.stringify(preWhere));
    } else{
      //no control value present, so directy add condition
      let preWhere = [];
      let condition = {tableName:tableName,preWhereCondition:value,blockName: this.getBlockName(tableName), sampleFilter: typeof(sampleFilter) !== 'undefined' ? sampleFilter : ""};
      preWhere.push(condition);
      if(GlobalId !== -96){
        preWhere = this.arrangePreWhereAccordingToInputTableSequence(preWhere);
      }
      this.saveGlobalValue(GlobalId,JSON.stringify(preWhere));
    }
  }

  arrangePreWhereAccordingToInputTableSequence = (preWhere) => {
    let inputTables = this.getGlobalValue(-97) !== null  ? this.getGlobalValue(-97).split(',') : [];
    let newPreWhere = [];
    inputTables.forEach((tableName) => {
      let objectFound = false;
      let objIndex = preWhere.findIndex((obj => obj.tableName === tableName));
      if(objIndex > -1){
        newPreWhere.push(preWhere[objIndex]);
        objectFound = true;
      }
      //add new condition
      if(!objectFound){
        let condition = {tableName:tableName,preWhereCondition:""};
        newPreWhere.push(condition);
      }
    });
    return newPreWhere;
  }

  getColumnProperty(row, tableName) {
    let columnProperty = [];
    if(typeof(row) === 'undefined' || row === null){
      return columnProperty;
    }
    let studyIndentifier1 = "";
    let studyIndentifier2 = "";
    let studyIndentifier3 = "";
    let selectedStudy = this.props.studyList.filter(study => study.schemaName === this.props.tenantId);
    if(selectedStudy.length > 0){
      studyIndentifier1 = typeof(selectedStudy[0].identifier1) !== "undefined" && selectedStudy[0].identifier1 !== null ? selectedStudy[0].identifier1 : "";
      studyIndentifier2 = typeof(selectedStudy[0].identifier2) !== "undefined" && selectedStudy[0].identifier2 !== null ? selectedStudy[0].identifier2 : "";
      studyIndentifier3 = typeof(selectedStudy[0].identifier3) !== "undefined" && selectedStudy[0].identifier3 !== null ? selectedStudy[0].identifier3 : "";

    }
    let columns = Object.keys(row);
    let orderIndex = 3 ;
    let nonkeyColums = columns.filter(name => name.indexOf('_app_') === -1 && name.indexOf('APP_') === -1);
    let keyColums = columns.filter(name => name.indexOf('_app_') !== -1 || name.indexOf('APP_') !== -1);
    let app_key_Index = 1;
    let app_pkey_Index = 2;
    let app_rkey_Index = 3;
    let app_lkey_Index = 4;
    let app_warn_Index = 5;
    if(this.getGlobalValue(-1) !== DataOpsIdentifierConstants.COMPARE_OP &&
    typeof(global.outputBlockTable) !== "undefined" && global.outputBlockTable !== null && global.outputBlockTable !== "" && global.outputBlockTable !== tableName){
      nonkeyColums = nonkeyColums.sort();
    }
    keyColums = keyColums.sort();
    for (var i =0; i<nonkeyColums.length;i++) {
      let column = nonkeyColums[i];
      let item = {field:column, show:true};
      if(column.toLowerCase() === studyIndentifier1.toLowerCase()){
        item.orderIndex = 0;
      }
      else if(column.toLowerCase() === studyIndentifier2.toLowerCase()){
        item.orderIndex = 1;
      }
      else if(column.toLowerCase() === studyIndentifier3.toLowerCase()){
        item.orderIndex = 2;
      }
      else if(column.indexOf('_app_') === -1 && column.indexOf('APP_') === -1){
        item.orderIndex = orderIndex;
        orderIndex = orderIndex + 1;
      }
      columnProperty.push(item);
    }
    for (var i =0; i<keyColums.length;i++) {
      let column = keyColums[i];
      let item = {field:column, show:true};
      if(column.indexOf('_app_') !== -1 || column.indexOf('APP_') !== -1){
        switch (column.toUpperCase()) {
          case 'APP_KEY':
            item.orderIndex = orderIndex + app_key_Index;
            break;
          case 'APP_PKEY':
            item.orderIndex = orderIndex + app_pkey_Index;
            break;
          case 'APP_RKEY':
            item.orderIndex = orderIndex + app_rkey_Index;
            break;
          case 'APP_LKEY':
            item.orderIndex = orderIndex + app_lkey_Index;
            break;
          case 'APP_WARN':
            item.orderIndex = orderIndex + app_warn_Index;
            break;
          default:
            orderIndex = orderIndex + 6;
            item.orderIndex = orderIndex;
            break;
        }
        columnProperty.push(item);
      }
    }
    return columnProperty;
  }

  getSpaceSeperatedColumn = (row, tableName) => {
    return this.getColumnFields(row, tableName).split(",").join(" ")
  }

  getColumnFields = (row, tableName) => {
    var columns = [];
    let studyIndentifier1 = "";
    let studyIndentifier2 = "";
    let studyIndentifier3 = "";
    let rowColumns = Object.keys(typeof(row) !== 'undefined' ? row: {});
    let selectedStudy = this.props.studyList.filter(study => study.schemaName === this.props.tenantId);
    if(selectedStudy.length > 0){
      studyIndentifier1 = typeof(selectedStudy[0].identifier1) !== "undefined" && selectedStudy[0].identifier1 !== null ? selectedStudy[0].identifier1 : "";
      studyIndentifier2 = typeof(selectedStudy[0].identifier2) !== "undefined" && selectedStudy[0].identifier2 !== null ? selectedStudy[0].identifier2 : "";
      studyIndentifier3 = typeof(selectedStudy[0].identifier3) !== "undefined" && selectedStudy[0].identifier3 !== null ? selectedStudy[0].identifier3 : "";
    }
    if(rowColumns.indexOf(studyIndentifier1) !== -1){
      columns.push(studyIndentifier1);
    }
    if(rowColumns.indexOf(studyIndentifier2) !== -1){
      columns.push(studyIndentifier2);
    }
    if(rowColumns.indexOf(studyIndentifier3) !== -1){
      columns.push(studyIndentifier3);
    }
    let nonkeyColums = rowColumns.filter(name => name.indexOf('_app_') === -1 && name.indexOf('APP_') === -1 &&
    name !== studyIndentifier1 && name !== studyIndentifier2 && name !== studyIndentifier3);
    if(this.getGlobalValue(-1) !== DataOpsIdentifierConstants.COMPARE_OP &&
    typeof(global.outputBlockTable) !== "undefined" && global.outputBlockTable !== null && global.outputBlockTable !== "" && global.outputBlockTable !== tableName){
      nonkeyColums = nonkeyColums.sort();
    }
    for (let i =0; i<nonkeyColums.length;i++) {
      columns.push(nonkeyColums[i]);
    }
    let appkeyColums = rowColumns.filter(name => name.indexOf('APP_KEY') !== -1);
    for (let i =0; i<appkeyColums.length;i++) {
      columns.push(appkeyColums[i]);
    }
    let appPkeyColums = rowColumns.filter(name => name.indexOf('APP_PKEY') !== -1);
    for (let i =0; i<appPkeyColums.length;i++) {
      columns.push(appPkeyColums[i]);
    }
    let appRkeyColums = rowColumns.filter(name => name.indexOf('APP_RKEY') !== -1);
    for (let i =0; i<appRkeyColums.length;i++) {
      columns.push(appRkeyColums[i]);
    }
    let appLkeyColums = rowColumns.filter(name => name.indexOf('APP_LKEY') !== -1);
    for (let i =0; i<appLkeyColums.length;i++) {
      columns.push(appLkeyColums[i]);
    }
    let appWarnColums = rowColumns.filter(name => name.indexOf('APP_WARN') !== -1);
    for (let i =0; i<appWarnColums.length;i++) {
      columns.push(appWarnColums[i]);
    }
    let keyColums = rowColumns.filter(name => (name.indexOf('_app_') !== -1 || name.indexOf('APP_') !== -1) && (name.indexOf('APP_KEY') === -1
    && name.indexOf('APP_PKEY') === -1 && name.indexOf('APP_RKEY') === -1 && name.indexOf('APP_LKEY') === -1 && name.indexOf('APP_WARN') === -1));
    for (let i =0; i<keyColums.length;i++) {
      columns.push(keyColums[i]);
    }

    return columns.join(',');
  };

  handlePanelSelect = (event, tableIndex, tableName) => {
    let expandedclassName = this.state.expandedclassName;
    expandedclassName[tableIndex] = !event.target.props.expanded ? 'increase-grid-height' :'';

    global.dataExplorerTablePanelBarItemStatus = global.dataExplorerTablePanelBarItemStatus.filter( table => table.name !== tableName );

    global.dataExplorerTablePanelBarItemStatus.push({ name: tableName, status: event.target.props.expanded });

    if (typeof(global.outputBlockTable) !== 'undefined' && global.outputBlockTable !== '' && global.outputBlockTable !== null) {
      if (global.outputBlockTable === tableName) {
          if (typeof(document.getElementsByClassName('variables_input_'+tableName+'_'+this.state.gridType)) !== 'undefined') {
              outputTableColumnList = document.getElementsByClassName('variables_input_'+tableName+'_'+this.state.gridType)[0].value;
          }

          if (typeof(document.getElementById(tableName+'-nodupkey-'+ this.state.gridType)) !== 'undefined') {
              outputTableIsDistinct = document.getElementById(tableName+'-nodupkey-'+ this.state.gridType).checked;
          }
      }
    }


    this.setState({ expandedclassName: expandedclassName });
  }

  applyButtonRef = (element, tableName) => {
    if (typeof(global.outputBlockTable) !== 'undefined' && global.outputBlockTable !== '' && global.outputBlockTable !== null && element !== null) {
      if (global.outputBlockTable === tableName && typeof(global.restOutPutTableInDataViewer) !== 'undefined' && global.restOutPutTableInDataViewer === true) {
            element.click();
            global.restOutPutTableInDataViewer = false;
      }
    }
  }

  handleTableVariableChange = (event, tableName) => {
    let tableList = this.state.tableList
    tableList.filter(table => table.name === tableName).map(table => {
      table.displayVariableList = event.target.value;
      return table;
    })
    this.setState({tableList: tableList})
  }

  handleSampleFilterChange = (event, tableName, tableEngineSampleFilter) => {
    let {postKey, tableList} = this.state;
    postKey = this.state.postKey + 1

    if (document.getElementById(tableName+'-sampleFilter-preWhere') !== null && document.getElementById(tableName+'-preWhere').value !== null) {
      let currentWhereCondition = document.getElementById(tableName+'-preWhere').value
      if (currentWhereCondition !== '' && currentWhereCondition !== null) {
        currentWhereCondition = currentWhereCondition.trim()
      }
      if (!event.target.checked) {
        tableEngineSampleFilter = ''
      }

      document.getElementById(tableName+'-sampleFilter-preWhere').value = tableEngineSampleFilter
      this.addUpdateValueinGlobalVariable(-99, tableName, currentWhereCondition, tableEngineSampleFilter);
    }

    this.setState({postKey: postKey})
  }

  TableDataBar = (table, tableIndex) => {
    let hidden = table.status ? '' : 'hidden';
    let whereCondition = this.getPreWhereCondition(table);
    let sampleFilter = this.getSampleFilter(table);
    let expandedclassName = this.state.expandedclassName;
    let distinctChecked = false;
    let expanded = this.state.gridType === 'preWhere' ? true : false;

    if (expanded === false) {
      expandedclassName.push(["increase-grid-height"])
    }

    if (this.state.gridType === 'data') {
      if (typeof(global.dataExplorerTablePanelBarItemStatus) !== 'undefined') {
        let tablePanelBarItem = global.dataExplorerTablePanelBarItemStatus.filter( item => item.name === table.name );
        if (tablePanelBarItem.length > 0) {
          expanded = tablePanelBarItem[0].status;
        }
      }
    }

    if(typeof(table.distinctChecked) !== 'undefined') {
      distinctChecked = table.distinctChecked;
    }

    table = Object.assign(table, {isDynamicGrid: true, isPaginated: true, showSearch: false, showColumnVisibilityManager: false});
    const tableEngineSampleFilter = typeof(table.sampleFilter) !== 'undefined' && table.sampleFilter.length > 0 && typeof(this.props.selectedEngine) !== 'undefined' && typeof(this.props.selectedEngine.value) !== 'undefined' && table.sampleFilter.filter(tableSampleFilter => tableSampleFilter.engineCode === this.props.selectedEngine.value).length > 0 ? table.sampleFilter.filter(tableSampleFilter => tableSampleFilter.engineCode === this.props.selectedEngine.value)[0].whereCondition : '';

    if (typeof(table.displaySampleFilter) !== 'undefined' && table.displaySampleFilter === true && tableEngineSampleFilter !== '' && sampleFilter !== '' && sampleFilter !== tableEngineSampleFilter) {
      sampleFilter = tableEngineSampleFilter;

      this.addUpdateValueinGlobalVariable(-99, table.name, whereCondition, sampleFilter);
    }
    const isBlankTable = typeof(table.rows) !== 'undefined' && typeof(table.columnProperty) !== 'undefined' && table.rows.length === 0 && table.columnProperty.length === 0
    return (
      <div className={`card ${hidden}`} key={`${table.name}_${this.state.postKey}`}>
        <div className="card-block">
          <div className="">
          <fieldset>
              <PanelBar onSelect={event => this.handlePanelSelect(event, tableIndex, table.name)} openId={[expanded === true ? tableIndex+'-table': '']}>
                <PanelBarItem key={tableIndex} id={tableIndex+'-table'} expanded={expanded} title={typeof(table.fullTableName) !== 'undefined' ? table.fullTableName : table.name}>
                  <div className="field_group">
                    <div className="am-form-inline">
                      <div className="am-form-field">
                        <span>Variables:</span>
                        <Input className={`variables_input_${table.name}_${this.state.gridType}`} name={`variables[${table.name}]`} value={typeof(table.displayVariableList) !== 'undefined' ? table.displayVariableList : this.getSpaceSeperatedColumn(table.rows[0], table.name)} onChange={(event) => this.handleTableVariableChange(event, table.name)} placeholder="Variables" />
                        <Button primary={true} className="m-left" value={table.name} onClick={(event) => this.handleTableStateChangeEvent(event, table.name)} disabled={isBlankTable}>Reset Variable</Button>
                      </div>
                      <div className="am-form-field">
                        <span>Where condition:</span>
                        <Input placeholder="Where condition" onChange={this.handleWhereCondtionChange} id={`${table.name}-${this.state.gridType}`} value={whereCondition}/>
                        <div className="am-form-field m_b_0 m-left">
                          <input type="checkbox" id={`${table.name}-samplefilterkey-${this.state.gridType}`} className="am-checkbox" checked={sampleFilter !== ''} onChange={event => this.handleSampleFilterChange(event, table.name, tableEngineSampleFilter)} disabled={tableEngineSampleFilter === ''} />
                          <label className={`am-checkbox-label m-r-5${typeof(table.displaySampleFilter) === 'undefined' ? ' hidden': ''}`} htmlFor={`${table.name}-samplefilterkey-${this.state.gridType}`} disabled={tableEngineSampleFilter === ''}>Sample Filter </label>
                          <input type="checkbox" id={`${table.name}-nodupkey-${this.state.gridType}`} className="am-checkbox" checked={distinctChecked} onChange={event => this.handleDistinctChange(event, tableIndex)} disabled={isBlankTable} />
                          <label className="am-checkbox-label" htmlFor={`${table.name}-nodupkey-${this.state.gridType}`} disabled={isBlankTable} >Distinct </label>
                          <Button type="button" primary={true} className="m-left" value={table.name} onClick={this.handleApplyButton} disabled={isBlankTable}>Apply</Button>
                        </div>
                      </div>
                      <div className={`am-form-field${tableEngineSampleFilter === '' || sampleFilter === '' ? ' hidden' : ''}`}>
                        <span>Sample Filter:</span>
                        <Input placeholder="sample filter" id={`${table.name}-sampleFilter-${this.state.gridType}`} value={sampleFilter} readOnly={true} disabled={true}/>
                      </div>
                    </div>
                  </div>
                </PanelBarItem>
              </PanelBar>

              {/* <div className="am-form-field">
              </div> */}
              {!isBlankTable &&
                <div className={`height_fixed_pre ${expandedclassName[tableIndex]}`}>
                  <RichGridTable  {...table} />
                </div>
              }
              {isBlankTable &&
                <div className={`height_fixed_pre ${expandedclassName[tableIndex]}`}>
                  <h3 className="no-table-found">Table {typeof(table.fullTableName) !== 'undefined' ? table.fullTableName : table.name} does not exist.</h3>
                </div>
              }

              <button type="button" className="k-button k-primary m-left hidden" value={table.name} onClick={this.handleApplyButton} ref={element => this.applyButtonRef(element, table.name)}>Apply</button>
          </fieldset>
          </div>
        </div>
      </div>

    )
  };

  getUniqueTables(tableList){
    let tableAdded = [...new Set(tableList.map(item => item.name))];
    let uniqueObject = [];
    tableAdded.forEach((item) =>{
      let selectedTable = tableList.filter((table) => table.name === item && table.status === true);
      if(selectedTable.length > 0){
        uniqueObject.push(selectedTable[0]);
      }
    })
    return uniqueObject;
  }

  getBlockName = (tableName) => {
    let blockName = '';
    if (typeof(this.props.processFlowList) !== 'undefined') {
      this.props.processFlowList.forEach(process => {
        if (blockName === '') {
          process.process.processBlock.filter(block => block.blockTable === tableName).forEach(block => {
            blockName = block.blockName;
          })
        }
      })
    }
    return blockName;
  }

  render() {
      let uniqueObject = this.getUniqueTables(this.state.tableList);
      return (
        <div className="grid-operation clearfix">
          <div className={`dataViewer ${this.state.wrapperClassName}`}>
            <div className="side_by_side_grid">
            {
              uniqueObject.map(this.TableDataBar)
            }
            </div>
          </div>
        </div>
      );
    }
}
const mapStateToProps = createSelector(
  state => state.processFlow.tenantId,
  state => state.study.studyList,
  state => state.dataOperation.selectedEngine,
  state => state.processFlow.processFlowList,
  (tenantId,studyList, selectedEngine, processFlowList) => ({
    tenantId,
    studyList,
    selectedEngine,
    processFlowList
  })
);

const mapActionsToProps = {
  setMessage: setMessage
}

DataGridOperation.propTypes = {
  tenantId: PropTypes.string
}

export const GridOperation = connect(mapStateToProps, mapActionsToProps)(DataGridOperation);
