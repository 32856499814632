import React from "react";
import { PropTypes } from "prop-types";
import Dialog from 'react-dialog';
import { Input } from './../DataOperation/common/InputBox/StandardInput';
import { Button } from './../DataOperation/common/Button/StandardButton';
import CopyType from "../../CopyType";
import RichGridTable from "./../RichGridTable";

function template() {
    return(
    <div className={this.className}>
            <Dialog className={this.className} title={this.state.title} width="450px" height="auto" modal={true} onClose={this.closeDialog} buttons={[<Button primary={true} onClick={this.handleCopyClick} key="btn-paste"> Paste</Button>,<Button onClick={this.closeDialog} key="btn-cancel"> Cancel</Button>]}>
              {(this.state.copyTarget.copyType !== CopyType.COPY_STUDY_STUDY )&& <div className="am-form-line-left">
                    <label className={this.inputClassname}><span >{this.state.inputLabel} </span></label>
                    <Input className={`form-textbox study-width-text${(this.state.isAcrossStudy? ' width-full': '')}`} id="newName" value={this.state.newTargetName} onChange={this.handleTargetNameChange} autoFocus={true}/>
                </div>
              }
                {(this.state.copyTarget.copyType === CopyType.COPY_STUDY_PROJECT )&& <div className="am-form-line-left">
                    <label className={this.inputClassname}><span > Classifier </span></label>
                    <Input className="form-textbox study-width-text" id="newClassifier" value={this.state.newClassifier} onChange={this.handleClassifierChange} autoFocus={true}/>
                </div>
                }
                {(this.state.copyTarget.copyType === CopyType.COPY_STUDY_PROJECT )&& <div className="am-form-line-left">
                    <label className={this.inputClassname}><span > Description </span></label>
                    <textarea className="form-textbox am-textbox textarea-box study-width-text" id="newDescription" value={this.state.newDescription} onChange={this.handleDescriptionChange}/>
                </div>
                }
                {
                    this.state.isAcrossStudy &&
                    <div>
                    <fieldset>
                    <div className="field_group m-t-10 m-b-10">
                        <legend>While copying</legend>
                        <div>
                            <label><input type="radio" name="optImport" value="Source" onChange={this.handleCopyInputTableChange}
                            checked={this.state.copyInputTable} id="optImportTrue"/> Copy input table/document if not exists</label>
                        </div>
                        <div>
                            <label><input type="radio" name="optImport" value="Source" onChange={this.handleCopyInputTableChange}
                            checked={!this.state.copyInputTable} id="optImportFalse"/> Skip copying input table/document if not exists (child blocks will be in Invalid state)</label>
                        </div>
                    </div>
                    </fieldset>

                    <p className="m-l-5">
                        <b>Note: </b> While copying LINK blocks, will check compare source block name from which block is created. If block name not found, then LINK block will be skipped and all its child blocks will be in Invalid state.
                    </p>
                    </div>
                }
                {(this.state.copySource.copyType === CopyType.COPY_STUDY_STUDY && this.state.copyTarget.copyType === CopyType.COPY_STUDY_STUDY)&&
                <div className={this.className} >
                <fieldset>
                <div className="field_group m-t-10 m-b-10">
                    <legend>Copying Group As</legend>
                <div>
                 <RichGridTable id={"group-compare-grid-table"} rows={this.state.groupRows} columnProperty={this.state.groupColumnProperty} />
                 </div></div></fieldset></div>
                }

                <div className="m-t-10 m-b-10">
                  <p className="m-l-5"><b>Note: </b> AUTO and TIMELINE process block will not be copied.</p>
                </div>
            </Dialog>
    </div>);
}


Dialog.propTypes = {
    height: PropTypes.oneOfType([
        PropTypes.string
    ]),
    width: PropTypes.oneOfType([
        PropTypes.string
    ])
}
export default template;
