import React from "react";
import cloneDeep from 'lodash/cloneDeep';
import { ProcessOptionGrid } from "../common/gridOperation";
import { StandardDropDown, NewColumnSpanCell } from '../common';
import DataOpsIdentifierConstants from '../../../DataOpsIdentifierConstants';
import { Input } from './../common/InputBox/StandardInput';
import { Button } from './../common/Button/StandardButton';

function PivotLayoutTemplate() {
  return (
    <div className="process-option">
      <PivotProcess processFlowAction={this.state.processFlowAction} tableList={this.state.tableList} inputColumns={this.state.inputColumns} />
    </div>
  );
};

class PivotProcess extends React.Component {
  constructor(props) {
    super(props);
    let rowData = [];
    let updateData = this.getGlobalValue(14);
    let gridData = props.tableList;
    if(updateData){
      rowData = JSON.parse(updateData);

    }
    if(updateData && gridData.length > 0){
      let orginalRows = [];
      gridData[0].rows.forEach((item,index)=>{
        let rowItem = rowData.filter((rowItem,index1)=>{
          return rowItem.column === item.column;
        });
        if(rowItem.length <= 0 ){
          orginalRows.push(item);
        }
      });
      gridData[0].rows = orginalRows;
    }
    this.state = {
      tableList: gridData,
      inputColumns: props.inputColumns,
      processFlowAction: props.processFlowAction,
      idVariable:this.getGlobalValue(15),
      idLabel:this.getGlobalValue(16),
      prefix:this.getGlobalValue(17),
      resultColumn:this.getGlobalValue(12),
      dataColumn:this.getGlobalValue(13),
      dropTable:[{
        id: 'pivot-ops-drop-grid',
        rows: rowData,
        resize: true,
        filterable: false,
        selectedField: "selected",
        dragAndDrop: true,
        columnProperty: [
            { field: "selected", show: false},
            { field: "gby", show: false},
            { field: "column", title: "Variable", width: "150px", cell: NewColumnSpanCell},
            { field: "label", sortable:false, width:"260px"},
            { field: "type", sortable:false, width:"45px"},
            { field: "length", sortable:false, width:"auto"},
            { field: "id", show: false}
        ]
      }],
      blankDropTable:[{
        id: 'blank-grid',
        rows: [{"selected": false,"gby":false, "column": "", "label": "", "type": "", "length": "" }],
        resize: true,
        filterable: false,
        selectedField: "selected",
        dragAndDrop: true,
        columnProperty: [
            { field: "selected", show: false},
            { field: "gby", show: false},
            { field: "column", title: "Variable", width: "150px", cell: NewColumnSpanCell},
            { field: "label", sortable:false, width:"260px"},
            { field: "type", sortable:false, width:"45px"},
            { field: "length", sortable:false, width:"auto"},
            { field: "id", show: false}
        ]
      }]
    };
    this.getGlobalValue = this.getGlobalValue.bind(this);
    this.handleInputColumnIDVariableChange = this.handleInputColumnIDVariableChange.bind(this);
    this.handleInputColumnIDLabelChange = this.handleInputColumnIDLabelChange.bind(this);
    this.handlePrefixChange  = this.handlePrefixChange.bind(this);
    this.handleResultColumnChange = this.handleResultColumnChange.bind(this);
    this.handleDataColumnChange = this.handleDataColumnChange.bind(this);
  }

  handleMoveDirection = (direction) => {
    let {tableList, dropTable} = this.state;

    if (direction === 'right') {
      const rows = cloneDeep(tableList[0].rows.filter(row => row.selected === true && row.gby === false && (typeof(global.ColumnAlwaysNeedsToBeDisplay) === 'undefined' || (typeof(global.ColumnAlwaysNeedsToBeDisplay) !== 'undefined' && global.ColumnAlwaysNeedsToBeDisplay.indexOf(row.column) === -1))));
      rows.forEach(row => {
          row.selected = false;
          if (typeof(row.id) !== 'undefined') {
            delete row.id;
          }
          dropTable[0].rows.push(cloneDeep(row))
      })

      tableList[0].rows = tableList[0].rows.filter(row => !(row.selected === true && row.gby === false && (typeof(global.ColumnAlwaysNeedsToBeDisplay) === 'undefined' || (typeof(global.ColumnAlwaysNeedsToBeDisplay) !== 'undefined' && global.ColumnAlwaysNeedsToBeDisplay.indexOf(row.column) === -1))))
    } else {
      const rows = cloneDeep(dropTable[0].rows.filter(row => row.selected === true && (typeof(global.ColumnAlwaysNeedsToBeDisplay) === 'undefined' || (typeof(global.ColumnAlwaysNeedsToBeDisplay) !== 'undefined' && global.ColumnAlwaysNeedsToBeDisplay.indexOf(row.column) === -1))));
      rows.forEach(row => {
          row.selected = false;
          if (typeof(row.id) !== 'undefined') {
            delete row.id;
          }
          tableList[0].rows.push(cloneDeep(row))
      })

      dropTable[0].rows = dropTable[0].rows.filter(row => !(row.selected === true && (typeof(global.ColumnAlwaysNeedsToBeDisplay) === 'undefined' || (typeof(global.ColumnAlwaysNeedsToBeDisplay) !== 'undefined' && global.ColumnAlwaysNeedsToBeDisplay.indexOf(row.column) === -1))))
    }

    tableList[0].rows.sort((a,b) => ('' + a.column).localeCompare(b.column))
    dropTable[0].rows.sort((a,b) => ('' + a.column).localeCompare(b.column))

    global.processBlockControlValues.forEach((control, index) => {
      if (control.controlId === 3) {
        global.processBlockControlValues[index].controlValue = JSON.stringify(tableList[0].rows);
      }
    });
    global.processBlockControlValues.forEach((control, index) => {
      if (control.controlId === 14) {
        global.processBlockControlValues[index].controlValue = JSON.stringify(dropTable[0].rows);
      }
    });

    tableList.map(table => {
      table.rows.filter(row => row.selected === true && (typeof(global.ColumnAlwaysNeedsToBeDisplay) === 'undefined' || (typeof(global.ColumnAlwaysNeedsToBeDisplay) !== 'undefined' && global.ColumnAlwaysNeedsToBeDisplay.indexOf(row.column) === -1))).map(row => {
        row.selected = false
        return row
      })
      return table
    })
    dropTable.map(table => {
      table.rows.filter(row => row.selected === true && (typeof(global.ColumnAlwaysNeedsToBeDisplay) === 'undefined' || (typeof(global.ColumnAlwaysNeedsToBeDisplay) !== 'undefined' && global.ColumnAlwaysNeedsToBeDisplay.indexOf(row.column) === -1))).map(row => {
        row.selected = false
        return row
      })
      return table
    })
    this.setState({tableList: tableList, dropTable: dropTable}, () => {
      if (document.getElementsByClassName('pivot-grid row-clickable').length > 0) {
        document.querySelectorAll('.pivot-grid.row-clickable .rgt-cell.row-black').forEach(item => {
          if (item.classList.contains('row-black') === true) {
            item.classList.remove('row-black')
          }
        })
      }
    })
  }

  getGlobalValue(controlIndex){
    let controlValue = '';
    global.processBlockControlValues.forEach((control, index) => {
      if (control.controlId === controlIndex) {
        controlValue = global.processBlockControlValues[index].controlValue;

      }
    });
    return controlValue;
  }

  handleInputColumnIDVariableChange(e) {
    global.processBlockControlValues.forEach((control, index) => {
      if (control.controlId === 15) {
        global.processBlockControlValues[index].controlValue = e.value;
        this.setState({"idVariable":e.value});
        return true;
      }
    });

  }
  handleInputColumnIDLabelChange(e) {
    global.processBlockControlValues.forEach((control, index) => {
      if (control.controlId === 16) {
        global.processBlockControlValues[index].controlValue = e.value;
        this.setState({"idLabel":e.value});
        return true;
      }
    });
  }

  handlePrefixChange(e){
    global.processBlockControlValues.forEach((control, index) => {
      if (control.controlId === 17) {
        global.processBlockControlValues[index].controlValue = e.target.value;
        this.setState({"prefix":e.target.value});
        return true;
      }
    });
  }

  handleResultColumnChange(e){
    global.processBlockControlValues.forEach((control, index) => {
      if (control.controlId === 12) {
        global.processBlockControlValues[index].controlValue = e.target.value;
        this.setState({"resultColumn":e.target.value});
        return true;
      }
    });
  }

  handleDataColumnChange(e){
    global.processBlockControlValues.forEach((control, index) => {
      if (control.controlId === 13) {
        global.processBlockControlValues[index].controlValue = e.target.value;
        this.setState({"dataColumn":e.target.value});
        return true;
      }
    });
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if(JSON.stringify(nextProps) !== '') {
      this.setState({tableList: nextProps.tableList, processFlowAction: nextProps.processFlowAction});
    }
  }

  render() {
      return (
        <div className="row pivot-grid row-clickable">
          <div className="col-6">
            <div className="field_group">
              {/* <div>
                <label className="am-form-field">
                  <span>Find:</span>
                  <input className="am-textbox m_l_5" placeholder="Search data" />
                </label>
              </div> */}
              <h4 className="title">Step 1: Select GBY variable</h4>
              <div className="height_fixed_step1 full-width-table">
                <ProcessOptionGrid tableList={this.state.tableList} />
              </div>
            </div>
          </div>

          <div className="col-6">
            {//data column and result column  not needed for Col to Row- Informed by mail on 19-Feb-2019
              this.state.processFlowAction === DataOpsIdentifierConstants.PIVOT_RTC_OP && <div className="field_group">
              <h4 className="title">Step 2: Define transpose variable name</h4>
              { this.state.processFlowAction === DataOpsIdentifierConstants.PIVOT_RTC_OP &&
                <div className="clearfix">
                <div className="row">
                  <div className="col-md-6">
                    <div className="am-form-inline">
                      <div className="am-form-field">
                        <span>ID Variable:</span>
                        <StandardDropDown data={this.state.inputColumns} onChange={this.handleInputColumnIDVariableChange} value={this.state.idVariable}/>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className=" am-form-inline">
                      <div className="am-form-field">
                        <span>ID label:</span>
                        <StandardDropDown data={this.state.inputColumns} onChange={this.handleInputColumnIDLabelChange} value={this.state.idLabel}/>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-12">
                    <div className="am-form-inline">
                      <div className="am-form-field m-0">
                        <span >Enter Prefix(Optional):</span>
                        <Input value={this.state.prefix} onChange={this.handlePrefixChange}/>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              }

              { this.state.processFlowAction === DataOpsIdentifierConstants.PIVOT_CTR_OP &&
                <div className="clearfix">
                  <div className="am-form-inline">
                    <div className="am-form-field">
                      <span>Result Column:</span>
                      <Input onChange={this.handleResultColumnChange} value={this.state.resultColumn}/>
                    </div>
                  </div>
                  <div className="am-form-inline">
                    <div className="am-form-field m-0">
                      <span>Data Column:</span>
                      <Input onChange={this.handleDataColumnChange} value={this.state.dataColumn}/>
                    </div>
                  </div>
                </div>
              }
            </div>
            }
            <div className="field_group">
              <h4 className="title">{
                this.state.processFlowAction === DataOpsIdentifierConstants.PIVOT_RTC_OP ?
                "Step 3: Select variable to be transposed(click to move buttons)":
                "Step 2: Select variable to be transposed(click to move buttons)"
                }</h4>
              <div className={this.state.processFlowAction === DataOpsIdentifierConstants.PIVOT_RTC_OP ? "height_fixed_step3 full-width-table" :"height_fixed_step1 full-width-table"}>
                {this.state.dropTable.length > 0 && this.state.dropTable[0].rows.length > 0 ? (<ProcessOptionGrid tableList={this.state.dropTable} key={this.state.dropTable[0].id} />) : (<ProcessOptionGrid tableList={this.state.blankDropTable} key={this.state.blankDropTable[0].id} />)}
              </div>
            </div>
          </div>
          <div className="move-action-button">
            <Button primary={true} onClick={(event) => this.handleMoveDirection('right')}><i className="fa fa-arrow-right"></i></Button>
            <Button primary={true} onClick={(event) => this.handleMoveDirection('left')} className="m-t-10"><i className="fa fa-arrow-left"></i></Button>
          </div>
        </div>
      );
    }
}
export default PivotLayoutTemplate;
