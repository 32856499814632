import React    from "react";
import { connect } from 'react-redux';
import { createSelector } from 'reselect';
import template from "./GridMapper.jsx";
import { setMessage } from '../../../actions/actionNotification';
import { enableExecuteButton } from '../../../actions/actionDataOperation';


class GridMapper extends React.Component {
  constructor(props) {
      super(props);
      this.props.enableExecuteButton(false);
      this.state = {
        tableList: props.tableList,
        gridFindMapper: this.getGridCssClass(),
        resize: false,
        raiseExecute:props.raiseExecute,
        processFlowAction: props.processFlowAction,
        quickcondition:props.quickcondition,
        columnMapperNodeList: typeof(props.applyProcessFlow) !== 'undefined' && typeof(props.applyProcessFlow.columnMapperNodeList) !== 'undefined' ? props.applyProcessFlow.columnMapperNodeList : []
      }
      global.columnMapperNodeList = [];
  }

  getGridCssClass(){
    let defaultClass  = "grid_not_gby_Mapper";
    if(this.props.tableList.length > 0){
      if(this.props.tableList[0].gridClassName === ""){
        defaultClass = 'grid_gby_Mapper';
      } else if(this.props.tableList[0].gridClassName === "String_Ops"){
        defaultClass = 'grid_not_gby_Mapper';
      }
    }
    return defaultClass;
  }

  getInitialState() {
   return { /* initial state */ };
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if(JSON.stringify(nextProps) !== '') {
      if(typeof(nextProps.tableList) !== 'undefined' && JSON.stringify(nextProps) !== '') {
        var gridFindMapper = '';
        var resize = this.state.resize;

        nextProps.tableList.forEach((table) => {
          if(table.gridClassName === ""){
            gridFindMapper = 'grid_gby_Mapper';
            resize = true;
          } else if(table.gridClassName === "String_Ops"){
            gridFindMapper = 'grid_not_gby_Mapper';
            resize = true;
          }
        });
      }
      this.props.enableExecuteButton(true);

      if (typeof(this.props.constructiveOperation) !== 'undefined' && this.props.constructiveOperation && gridFindMapper === '') {
        gridFindMapper = 'grid_not_gby_Mapper'
      }

      this.setState({ tableList: nextProps.tableList, gridFindMapper: gridFindMapper, resize: resize });
    }
  }

  render() {

    if (this.props.constructiveOperation === false) {
      //storing left table
      global.processBlockControlValues.forEach((control, index) => {
        if (control.controlId === 3 && this.props.tableList && this.props.tableList.length > 0) {
          global.processBlockControlValues[index].controlValue = JSON.stringify(this.state.tableList[0].rows);
        }
      });
      //storing right table
      global.processBlockControlValues.forEach((control, index) => {
        if (control.controlId === 24 && this.props.tableList && this.props.tableList.length > 1) {
          global.processBlockControlValues[index].controlValue = JSON.stringify(this.state.tableList[1].rows);
        }
      });
    }

    return template.call(this);
  }
}

const mapStateToProps = createSelector(
  state => state.dataOperation.constructiveOperationStatus,
  (constructiveOperationStatus) => ({
    constructiveOperationStatus
  })
);

const mapActionsToProps = {
  setMessage: setMessage,
  enableExecuteButton: enableExecuteButton
}
export default connect(mapStateToProps, mapActionsToProps)(GridMapper);
