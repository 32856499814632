import axios from 'axios';
import { SET_TOKEN } from './actionsTypes';

export const setToken = token => dispatch => {
    dispatch({
        type: SET_TOKEN,
        payload: token
    });

    axios.defaults.headers.common['Authorization'] = token.id_token;
}
