import React from 'react';
import Loadable from 'react-loadable'

import DefaultLayout from './containers/DefaultLayout';

function Loading(props) {
  if (props.error) {
    return <div>There is some internal error occured. Error: {props.error}. Please retry operation.</div>;
  } else {
    return <div>Loading...</div>;
  }
}

const CoreUIIcons = Loadable({
  loader: () => import('./views/Icons/CoreUIIcons'),
  loading: Loading,
});

const Flags = Loadable({
  loader: () => import('./views/Icons/Flags'),
  loading: Loading,
});

const FontAwesome = Loadable({
  loader: () => import('./views/Icons/FontAwesome'),
  loading: Loading,
});

const SimpleLineIcons = Loadable({
  loader: () => import('./views/Icons/SimpleLineIcons'),
  loading: Loading,
});

const ProcessFlowView = Loadable({
  loader: () => import('./views/ProcessFlow/ProcessFlows'),
  loading: Loading,
});

const ProcessFlowList = Loadable({
  loader: () => import('./views/Resource/ProcessFlowList'),
  loading: Loading,
});

const Study = Loadable({
  loader: () => import('./views/Resource/Study'),
  loading: Loading,
});

const DataOperation = Loadable({
  loader: () => import('./views/Resource/DataOperation'),
  loading: Loading,
});

const DataProperties = Loadable({
  loader: () => import('./views/Resource/DataProperties'),
  loading: Loading,
});

const CodeDialog = Loadable({
  loader: () => import('./views/Resource/DataOperation/code/CodeDialog'),
  loading: Loading,
});

const LogDialog = Loadable({
  loader: () => import('./views/Resource/DataOperation/log/LogDialog'),
  loading: Loading,
});

const UpdateBlock = Loadable({
  loader: () => import('./views/Resource/UpdateBlock'),
  loading: Loading,
});

const TimelineChart = Loadable({
  loader: () => import('./views/Resource/TimelineChart'),
  loading: Loading,
});

const MetadataEditor = Loadable({
  loader: () => import('./views/Resource/MetadataEditor'),
  loading: Loading,
});

const ExtendedAttributes = Loadable({
  loader: () => import('./views/Resource/DataOperation/ExtendedAttributes/ExtendedAttributes'),
  loading: Loading,
});

const AddLink = Loadable({
  loader: () => import('./views/Resource/DataOperation/AddLink/AddLink'),
  loading: Loading,
});

const ExecuteAllBlockLevelDialog = Loadable({
  loader: () => import('./views/Resource/DataOperation/ExecuteAll/ExecuteAllBlockLevelDialog'),
  loading: Loading,
});

const Annotate = Loadable({
  loader: () => import('./views/Resource/Annotate'),
  loading: Loading,
});

const ManageVersion = Loadable({
  loader: () => import('./views/Resource/VersionManager'),
  loading: Loading,
});

const LogManager = Loadable({
  loader: () => import('./views/Resource/LogManager'),
  loading: Loading,
});

const StudyManager = Loadable({
  loader: () => import('./views/Resource/StudyManager'),
  loading: Loading,
});

const UserManagement = Loadable({
  loader: () => import('./views/Resource/UserManagement'),
  loading: Loading,
});

const VersionDifference = Loadable({
  loader: () => import('./views/Resource/VersionDifference'),
  loading: Loading,
});

const AutomationCenter = Loadable({
  loader: () => import('./views/Resource/AutomationCenter'),
  loading: Loading,
});

const JobQueue = Loadable({
  loader: () => import('./views/Resource/JobQueue'),
  loading: Loading,
});
// https://github.com/ReactTraining/react-router/tree/master/packages/react-router-config
const routes = [
  { path: '/', exact: true, name: 'Home', component: DefaultLayout },
  { path: '/icons', exact: true, name: 'Icons', component: CoreUIIcons },
  { path: '/icons/coreui-icons', name: 'CoreUI Icons', component: CoreUIIcons },
  { path: '/icons/flags', name: 'Flags', component: Flags },
  { path: '/icons/font-awesome', name: 'Font Awesome', component: FontAwesome },
  { path: '/icons/simple-line-icons', name: 'Simple Line Icons', component: SimpleLineIcons },
  { path: '/process-flow-view', name: 'Process Flow View', component: ProcessFlowView },
  { path: '/process-flow-list', name: 'Process Flow List', component: ProcessFlowList },
  { path: '/study', name: 'Study', component: Study },
  { path: '/data-operation',  exact: true, name: 'Data Operation', component: DataOperation },
  { path: '/data-properties',  exact: true, name: 'Data Properties', component: DataProperties },
  { path: '/update-block',  exact: true, name: 'Update Block', component: UpdateBlock },
  { path: '/timeline-profile',  exact: true, name: 'Timeline Chart', component: TimelineChart },
  { path: '/metadata-editor',  exact: true, name: 'SpreadSheet Grid', component: MetadataEditor },
  { path: '/code',  exact: true, name: 'Code', component: CodeDialog },
  { path: '/log',  exact: true, name: 'Log', component: LogDialog },
  { path: '/extendedattributes',  exact: true, name: 'Extended Attributes', component: ExtendedAttributes },
  { path: '/addLink',  exact: true, name: 'Add Link', component: AddLink },
  { path: '/executeallblock', name : 'Execute All', component: ExecuteAllBlockLevelDialog },
  { path: '/executeallinteractiveblock', name : 'Execute All', component: ExecuteAllBlockLevelDialog },
  { path: '/annotate', name : 'Annotate', component: Annotate },
  { path: '/manageVersion', name : 'Manage Version', component: ManageVersion },
  { path: '/log-manager',  exact: true, name: 'Log Manager', component: LogManager },
  { path: '/study-manager',  exact: true, name: 'Study Manager', component: StudyManager },
  { path: '/user-management',  exact: true, name: 'User Management', component: UserManagement },
  { path: '/version-difference',  exact: true, name: 'Version Difference', component: VersionDifference },
  { path: '/automation-center',  exact: true, name: 'Automation Center', component: AutomationCenter },
  { path: '/job-queue',  exact: true, name: 'Job Queue', component: JobQueue },
];

export default routes;
