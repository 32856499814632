import React from 'react';
import { DropDownList } from './StandardDropDownList';
import { connect } from 'react-redux';
import { PropTypes } from 'prop-types';
import { createSelector } from 'reselect';
import { setMessage } from '../../../../../actions/actionNotification';
import MessageTypeConst from "../../../../MessageTypeConst";

export const ObyProcessCell = ({ tableManager, value, data, column, colIndex, rowIndex }) => {
    if (typeof(tableManager) === 'undefined') {
      return (null)
    } else {
      return (
          <div className='rgt-cell-inner' style={{display: 'flex', alignItems: 'center', overflow: 'hidden'}}>
              <ObyProcessCellClass dataItem={data} field={column.field} value={typeof(data[column.field]) !== 'undefined' ? data[column.field]: value} column={column} tableManager={tableManager} className={column.className}/>
          </div>
      )
    }
}

class ObyProcessCustomCell extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      refreshKey: 0
    }
  }
  OrderListSorting(orderList){
    let newGrpList = [];
    let newOrderList=[];
    global.processBlockControlValues.forEach((control, index) => {
      if (control.controlId === 3 && global.processBlockControlValues[index].controlValue !== null && global.processBlockControlValues[index].controlValue !== "" ) {
        let rowData = JSON.parse(global.processBlockControlValues[index].controlValue);
        rowData.forEach((row) => {
          let isGrpBy = false;
          let selectedOrder = orderList.filter((order) => {
            if(order.column === row.column){
              isGrpBy = row['gby'];
              return order;
            }
          });
          if(selectedOrder.length > 0){
            if(isGrpBy){
              newGrpList.push(selectedOrder[0]);
            }else {
              newOrderList.push(selectedOrder[0]);
            }
          }
        });
      }
    });
    if(newOrderList.length > 0 || newGrpList.length > 0){
      return [...newGrpList,...newOrderList ];
    }else{
      return orderList;
    }
  }
    handleChange(e) {
      let columnRestricted = false;
      if(this.props.dataItem['gby'] === true && e.target.value === ""){
        columnRestricted = true;
        this.props.setMessage("OrderBy required for column marked as GroupBy.", MessageTypeConst.WARNING_MESSAGE);
        let refreshKey = this.state.refreshKey + 1;
        this.setState({refreshKey: refreshKey })
        return;
      }
      if(!columnRestricted){

        this.props.dataItem[this.props.field] = e.target.value;
        this.props.tableManager.rowsApi.setRows(this.props.tableManager.rowsApi.rows)

      global.processBlockControlValues.forEach((control, index) => {
        if (control.controlId === 10 ) {
          if(global.processBlockControlValues[index].controlValue !== ""){
            let orderList = JSON.parse(global.processBlockControlValues[index].controlValue);
            let itemFound = false;
            let itemIndex =0;
            orderList.forEach((item1,index1)=>{
              if(item1.column === this.props.dataItem['column']){
                itemFound = true;
                itemIndex = index1;
              }
            });
            if(e.target.value)
            {
              if(!itemFound)
              {
                //if check box checked add item into list
                let orderitem ={"column":this.props.dataItem['column'],"oby":e.target.value};
                orderList.push(orderitem);
              }else{
                if(this.props.dataItem['column'] !== e.target.value)
                {
                  var newArray = orderList.map((item,index3)=>{
                    if(index3 === itemIndex){
                      item= {"column":this.props.dataItem['column'],"oby":e.target.value};
                    }
                    return item;
                  });
                  orderList = newArray;
                }
              }
            } else{
              //if check box unchecked remove item from list
              if(itemFound)
              {
                var filtered = orderList.filter((value, index2, arr) => {
                  return index2 !== itemIndex;
                });
                orderList = filtered;
              }
            }
            orderList = this.OrderListSorting(orderList);
            global.processBlockControlValues[index].controlValue = JSON.stringify(orderList);
          }
          else{
            let orderList = [];
            if(e.target.value)
            {
              let orderitem = {"column":this.props.dataItem['column'],"oby":e.target.value};
              orderList.push(orderitem);
              orderList = this.OrderListSorting(orderList);
              global.processBlockControlValues[index].controlValue = JSON.stringify(orderList);
            }
          }
        }
        });
      }
    }

    render() {
      //Needed to differentiate columns in grid mapper
      var position = typeof(this.props.className) !== 'undefined' ? this.props.className : '';
        return (
            <>
              <DropDownList className="width-full" onChange={this.handleChange.bind(this)} data={["", "A", "D"]}
              id={`ddl-${this.props.field.replace(' ', '_')}-${this.props.dataItem['columnId']}-${position}`}
              value={this.props.dataItem[this.props.field]} key={this.state.refreshKey} />
            </>
        );
    }
}

const mapStateToProps = createSelector(
  state => state.processFlow.tenantId,
  (tenantId) => ({
    tenantId,
  })
);

const mapActionsToProps = {
  setMessage: setMessage
}

ObyProcessCustomCell.propTypes = {
  tenantId: PropTypes.string
}

export const ObyProcessCellClass = connect(mapStateToProps, mapActionsToProps)(ObyProcessCustomCell);
