import React from 'react';
import axios from 'axios';
import { Button } from './StandardButton';
import { connect } from 'react-redux';
import { PropTypes } from 'prop-types';
import { createSelector } from 'reselect';
import $ from 'jquery';
import moment from 'moment';
import restClient from './../../../../../restClient';
import { GET } from './../../../../../restClient/types';
import { setMessage } from '../../../../../actions/actionNotification';
import MessageTypeConst from "./../../../../MessageTypeConst";
import CreateTask from './../../../TaskManagement/CreateTask';

export const TaskCommentButton = ({ tableManager, value, data, column, colIndex, rowIndex }) => {
    if (typeof(tableManager) === 'undefined') {
      return (null)
    } else {
      return (
          <div className='rgt-cell-inner' style={{display: 'flex', alignItems: 'center', overflow: 'hidden'}}>
              <TaskCommentButtonClass dataItem={data} field={column.field} value={typeof(data[column.field]) !== 'undefined' ? data[column.field]: value} column={column} tableManager={tableManager}/>
          </div>
      )
    }
}

class BaseTaskCommentButton extends React.Component {

    constructor(props) {
        super(props);
    }

    render() {
          return (
              <>
                <div className="task-detail list">
                  {typeof(this.props.dataItem.taskDetail.commentsList) !== 'undefined' && this.props.dataItem.taskDetail.commentsList.filter(comment => comment.commentId === this.props.dataItem[this.props.field]).map((comment, key) => {
                    return (
                        <div key={`comment-${key}`} className="comment-wrapper">
                          <div className="description">{comment.commentDescription}</div>
                          <div className="footer">
                            <span className="author">{comment.userName}</span>
                            <span className="date">{comment.audit !== null && comment.audit.modifiedAt !== null ? moment.utc(comment.audit.modifiedAt).format('lll') : "--"}</span>
                          </div>
                        </div>
                    )
                  })}
                  {typeof(this.props.dataItem.taskDetail.commentsList) !== 'undefined' && this.props.dataItem.taskDetail.commentsList.filter(comment => comment.commentId === this.props.dataItem[this.props.field]).length === 0 &&
                    <span>-</span>
                  }
                </div>
              </>
          );
      }
}

const mapStateToProps = createSelector(
    state => state.processFlow.tenantId,
    state => state.treeView.data,
    (tenantId, treeView) => ({
        tenantId,
        treeView
    })
  );

  const mapActionsToProps = {
    setMessage: setMessage,
  }

  const TaskCommentButtonClass = connect(mapStateToProps, mapActionsToProps)(BaseTaskCommentButton);
