import React from 'react';
import { Button } from './StandardButton';
import { PropTypes } from "prop-types";
import Dialog from 'react-dialog';
import { SortOrder } from '../SortOrder';
import { GET_ALL } from '../../../../../restClient/types';
import restClient from '../../../../../restClient';
import axios from 'axios';
import $ from 'jquery';
import { connect } from 'react-redux';
import MessageTypeConst from "../../../../MessageTypeConst";
import { setMessage } from '../../../../../actions/actionNotification';
import { createSelector } from 'reselect';

export const DataOpsProcessSetOrderCell = ({ tableManager, value, data, column, colIndex, rowIndex }) => {
    if (typeof(tableManager) === 'undefined') {
      return (null)
    } else {
      return (
          <div className='rgt-cell-inner' style={{display: 'flex', alignItems: 'center', overflow: 'hidden'}}>
              <DataOpsProcessSetOrderCellClass dataItem={data} field={column.field} value={typeof(data[column.field]) !== 'undefined' ? data[column.field]: value} column={column} tableManager={tableManager}/>
          </div>
      )
    }
}

class BaseDataOpsProcessSetOrderCell extends React.Component {

    constructor(props) {
      super(props);
      this.state = {
          tenantId : props.tenantId,
          visible: false,
          table : {
            rows: []
          }
      };
      this.toggleDialog = this.toggleDialog.bind(this);
      this.handleSaveGridData = this.handleSaveGridData.bind(this);
      this.populateTableName = this.populateTableName.bind(this);

    }
    fecthPreference = (headers, params) => restClient(GET_ALL, 'distinct/values', params, headers);

    getGlobalValue(controlIndex){
      let controlValue = '';
      global.processBlockControlValues.forEach((control, index) => {
        if (control.controlId === controlIndex) {
          controlValue = global.processBlockControlValues[index].controlValue;

        }
      });
      return controlValue;
    }

    populateTableName(data){
      let count =0;
      let rowData=[];
      var table = this.state.table;
      if(typeof(data) !== 'undefined' && typeof(data.distinctValues) !== 'undefined')
      {
        data.distinctValues.forEach(element => {
          let item = {
            'gby':true,
            'column':element,
            'columnId':count+1
          }
          rowData.push(item);
          count++;
        });
      }
      table.rows = rowData;
       this.setState({
        visible: !this.state.visible,
        table: table
      });
    }

    toggleDialog() {
      if(this.state.visible === true){
        this.setState({
          visible: !this.state.visible
        });
        return;
      }
      var fieldValue = this.props.dataItem[this.props.field];
      var table = this.state.table;
      let count =0;
      let rowData = [];
      if (fieldValue !== '') {
        let rows = JSON.parse(fieldValue);
        rows.forEach((element)=>{
          let item = {
          'gby':true,
          'column':element,
          'columnId':count+1
        }
        rowData.push(item);
        count++;

        });
        table.rows = rowData;
        this.setState({
         visible: !this.state.visible,
         table: table
       });
      } else{
        let variable = this.props.dataItem['variable'];
        let columnName = variable.substr(0,variable.lastIndexOf('-') );
        let tableName = this.getGlobalValue(-97);
        $(".loader").show();
        axios.all([this.fecthPreference({'Tenant-ID': this.state.tenantId},{'columnName':columnName,'tableName':tableName})])
        .then(axios.spread((response) => {
          if(response!= null && response.data !== null && typeof(response.data.messageType) !== "undefined"
            &&  response.data.messageType !== null && response.data.messageType === MessageTypeConst.WARNING_MESSAGE )
            {
                this.props.setMessage(response.data.message, response.data.messageType);
                $(".loader").hide();
                return;
            }
         if(typeof(response.data) !== 'undefined') {
           this.populateTableName(response.data);
           $(".loader").hide();
         }
       })).catch(error => {
        $(".loader").hide();
        this.props.setMessage("Error occurred while fetching data.", MessageTypeConst.ERROR_MESSAGE);
      });
      }


    }

    handleChange(e) {
      this.props.dataItem[this.props.field] = e.target.value;
      this.props.tableManager.rowsApi.setRows(this.props.tableManager.rowsApi.rows)
    }

    handleSaveGridData(e) {
      let items= [];
      SortOrder.getItems().forEach((item)=>{
        items.push(item.column);

      });
      this.props.onChange({
        dataItem: this.props.dataItem,
        field: this.props.field,
        syntheticEvent: e.syntheticEvent,
        value: JSON.stringify(items)

      });

      this.toggleDialog();
    }

    render() {
        var disabled = true;
        if (this.props.dataItem['resultType'] === 'Categorical') {
          disabled = false;
        }
        return (
            <div className="Set_PreferenceList bordered_popup">
              <Button primary={true} disabled={disabled} onClick={this.toggleDialog}>...</Button>
                {this.state.visible && <Dialog title={"Set Preference List"} width="650px" height="calc(100vh - 120px)" modal={true} onClose={this.toggleDialog} buttons={[<Button primary={true} className="k-button" onClick={this.handleSaveGridData} key="btn-ok">Ok</Button>,<Button className="k-button" onClick={this.toggleDialog} key="btn-cancel">Cancel</Button>]}>
                  <div className="row">
                    <div className="col-12">
                      <SortOrder table={this.state.table} />
                    </div>
                  </div>
                </Dialog>}
            </div>
        );
    }
}

const mapStateToProps = createSelector(
  state => state.processFlow.tenantId,
  ( tenantId) => ({
    tenantId
  })
);

const mapActionsToProps = {
  setMessage: setMessage
}

const DataOpsProcessSetOrderCellClass = connect(mapStateToProps, mapActionsToProps)(BaseDataOpsProcessSetOrderCell);
