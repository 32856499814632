import React from "react";
import TextEditor from '../../TextEditor';

class Log extends React.Component {
  constructor(props) {
    super(props);
    this.state={
      errorCount: 0,
      warningCount: 0
    }
  }
  componentDidMount() {
      var blockLog = global.blockLog;
      if(blockLog !== ""  && blockLog !== undefined && blockLog !== "undefined"){
        try{
          var str = blockLog.toString().toLowerCase();
          var errorCount = str.match(/\berror(\s)*([a-z0-9-])*:/ig) !== null ? str.match(/\berror(\s)*([a-z0-9-])*:/ig).length : 0;
          var warningCount = str.match(/warning:/g) !== null ? str.match(/warning:/g).length : 0;
          if((errorCount !== 0 && errorCount !== null && errorCount !== undefined) || (warningCount !==0 && warningCount !== null && warningCount !== undefined)){
            this.setState({
              errorCount: errorCount,
              warningCount: warningCount
            })
          }
        }
        catch(e){}
      }
  }
  render() {
      return (
        <div>
          <div className="align-left">
            <label className="warning">Warning: <span>{this.state.warningCount}</span></label>
            <label className="error">Error: <span>{this.state.errorCount}</span></label>
          </div>
          <div className="log_Editor">
            <TextEditor  options={{ lineNumbers: true, readOnly: true, mode:"javascript", styleSelectedText:true}} code={global.blockLog} />
          </div>
        </div>
      );
    }
}

export default Log;
