import React    from "react";
import axios from 'axios';
import cloneDeep from 'lodash/cloneDeep';
import { connect } from 'react-redux';
import { createSelector } from 'reselect';
import $ from 'jquery';
import moment from 'moment';
import template from "./TaskList.jsx";
import restClient from './../../../../restClient';
import { POST_PARAM_BODY } from './../../../../restClient/types';
import {setMessage} from './../../../../actions/actionNotification';
import MessageTypeConst from "./../../../MessageTypeConst";
import { BasicCheckBoxCell, TaskTitleButton, TaskCommentButton} from "./../../DataOperation/common";
import TaskConstantType from './../TaskConstantType';

class TaskList extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      schemaName: props.schemaName,
      closeEvent: props.closeEvent,
      filters: typeof(props.filters) ? props.filters: {status: "", userName: ""},
      tableList:{
        id: 'task-management-grid',
        rows: [],
        resize: true,
        columnProperty: [
          {field: "rowId", show: false},
          {field: "taskId", show: false},
          {field: "taskDetail", show: false},
          {field: "getTaskList", show: false},
          {field: "selected", title: ' ', width: '40px', cell:BasicCheckBoxCell},
          {field: "node", width: '200px'},
          {field: "assignedUser", width: '150px'},
          {field: "status", width: '100px'},
          {field: "modifiedDate", width: '170px'},
        ]
      },
      searchTitle: '',
      createTaskDialogStatus: false,
      study: '',
      isDataLoaded: false,
      commentFlag: false,
      triggerNode: '',
      bulkActionDialogStatus: false,
      taskIdList: [],
      originalRows: []
    }

    this.getNodeTitle = this.getNodeTitle.bind(this);
    this.getTaskList = this.getTaskList.bind(this);
    this.handleChangeTaskListView = this.handleChangeTaskListView.bind(this);
    this.handleSearchTaskListClick = this.handleSearchTaskListClick.bind(this);
  }

  fetchTaskList = (headers, params) => restClient(POST_PARAM_BODY, 'task/list', params, headers);

  handleSearchTaskChange = (event) => {
    this.setState({searchTitle: event.target.value})
  }

  toggleDialog(){
   this.props.history.goBack();
  }

  handleCreateTaskDialogClick = (event, reloadTaskList = false) => {
    this.setState({createTaskDialogStatus: !this.state.createTaskDialogStatus}, () => {
      if (this.state.createTaskDialogStatus === false && reloadTaskList === true) {
          this.getTaskList();
      }
    })
  }

  handleBulkActionClick = (event, reloadTaskList = false) => {
    let taskIdList = [];

    if (this.state.bulkActionDialogStatus === false) {
      this.state.tableList.rows.filter(row => row.selected === true).forEach(row => {
        taskIdList.push(row.taskId)
      })

      if (taskIdList.length === 0) {
        this.props.setMessage("Please select at least one task from the list.",MessageTypeConst.WARNING_MESSAGE)
        return false;
      }
    }

    this.setState({bulkActionDialogStatus: !this.state.bulkActionDialogStatus, taskIdList: taskIdList}, () => {
      if (this.state.bulkActionDialogStatus === false && reloadTaskList === true) {
          this.getTaskList();
      }
    })

  }

  componentDidMount() {
    this.getTaskList();
  }

  handleChangeTaskListView = () => {
    this.setState({commentFlag: !this.state.commentFlag}, () => {
      this.getTaskList();
    })
  }

  handleGetAllTaskListView = () => {
    this.setState({filters: {status: "", userName: ""}}, () => {
      this.getTaskList();
    })
  }

  getTaskList = () => {
    $(".loader").show();
    axios.all([this.fetchTaskList({'Tenant-ID': this.state.schemaName}, {data: {...this.state.filters, commentFlag: this.state.commentFlag}})])
        .then(axios.spread((response) => {
          if (typeof(response.data) !== 'undefined') {
            let tableList = this.state.tableList;
            let triggerNode = '';
            tableList.rows.length = 0;
            let taskMaxCommmentCount = 0;
            response.data.forEach(task => {
              let row = {
                rowId: tableList.rows.length + 1,
                columnId: task.taskId,
                selected: false,
                taskId: task.taskId,
                node: this.getNodeTitle(task),
                title: task.title,
                status: this.getStatusName(task.status),
                taskDetail: task,
                getTaskList: this.getTaskList
              }

              if (this.state.commentFlag === false) {
                row = Object.assign({}, row, {assignedUser: task.userName, modifiedDate: task.audit !== null && task.audit.modifiedAt !== null ? moment.utc(task.audit.modifiedAt).format('lll') : "--"});
              } else {
                if (typeof(task.commentsList) !== 'undefined') {
                  task.commentsList.forEach((comment, index) => {
                    const commentLabel = 'comment'+(index+1);
                    row = Object.assign({}, row, {[commentLabel]: comment.commentId});
                  })

                  if (taskMaxCommmentCount < task.commentsList.length) {
                    taskMaxCommmentCount = task.commentsList.length
                  }
                }
              }
              tableList.rows.push(row)
            })

            tableList.rows.forEach((row, index) => {
              for(var i = 1; i<=taskMaxCommmentCount; i++) {
                const commentLabel = 'comment'+(i);
                if(typeof(row[commentLabel]) === 'undefined') {
                  row = Object.assign({}, row, {[commentLabel]: '-', });
                }
              }

              tableList.rows[index] = row
            })

            tableList.columnProperty = tableList.columnProperty.filter(column => column.field !== 'title')

            if (this.state.commentFlag === true && taskMaxCommmentCount > 0) {
              tableList.columnProperty.push({field: 'title', width: '500px', cell: TaskTitleButton})
            } else {
              tableList.columnProperty.push({field: 'title', cell: TaskTitleButton})
            }

            for(var i = 1; i<=taskMaxCommmentCount; i++) {
              const commentLabel = 'comment'+(i);
              if (tableList.columnProperty.filter(column => column.field === commentLabel).length === 0) {
                tableList.columnProperty.push({field: commentLabel, width: '300px', cell: TaskCommentButton})
              }
            }

            this.props.treeView.children.filter(project => {
              project.children.filter(study => study.study.schemaName === this.state.schemaName).forEach(study => {
                triggerNode = study
              })
            })
            tableList.id = 'task-management-grid-'+ Math.ceil(Math.random()*100000);
            this.setState({tableList: tableList, isDataLoaded: true, triggerNode: triggerNode, originalRows:[]}, () => {
              if (this.state.searchTitle.trim() !== '') {
                this.handleSearchTaskListClick();
              }
            })
          }
          $(".loader").hide();
        })).catch(error => {
            $(".loader").hide();
            this.props.setMessage("Failed to fetch task list.",MessageTypeConst.ERROR_MESSAGE)
        })
  }

  getStatusName = (statusId) => {
    let statusNamme = '';

    TaskConstantType.STATUSLIST.filter(status => status[0] === statusId).forEach(status => {
      statusNamme = status[1];
    })
    return statusNamme
  }

  getNodeTitle = (task) => {
    let nodeTitleList = [];
    this.props.treeView.children.filter(project => {
      project.children.filter(study => study.study.schemaName === this.state.schemaName).forEach(study => {
        if (task.studyId > 0) {
          nodeTitleList.push(study.name)
        }
        study.children.filter(studyElement => studyElement.name === 'ProcessFlows').forEach(studyElement => {
          studyElement.children.forEach(classifier => {
            classifier.children.forEach(processGroup => {
              if (task.processGroupId > 0 && processGroup.processGroupId === task.processGroupId) {
                nodeTitleList.push(study.name)
                nodeTitleList.push(processGroup.name)
              }
              processGroup.children.forEach(processFlow => {
                if ((task.processId > 0 && processFlow.process.processId === task.processId) || (task.blockProcessId > 0 && task.blockName !== '' && processFlow.process.processId === task.blockProcessId)) {
                  nodeTitleList.push(study.name)
                  nodeTitleList.push(processGroup.name)
                  nodeTitleList.push(processFlow.name)
                }
              })
            })
          })
        })
      })
    })

    if (typeof(task.blockName) !== 'undefined' && task.blockName !== null && task.blockName !== '') {
      nodeTitleList.push(task.blockName)
    }

    nodeTitleList = [... new Set(nodeTitleList)];

    return nodeTitleList.join(' -> ')
  }

  handleSearchTaskListClick = () => {
    let {tableList, originalRows, searchTitle} = this.state;
    const currentSearchTitle = searchTitle.toLocaleLowerCase().trim();
    if (originalRows.length === 0) {
      originalRows = cloneDeep(tableList.rows);
    }

    tableList.rows.length = 0;

    if (currentSearchTitle === '') {
      tableList.rows.push(...originalRows)
    } else {
      originalRows.forEach(originalRow => {
        let isValidRow = originalRow.title.toLocaleLowerCase().includes(currentSearchTitle);
        if (isValidRow === false && typeof(originalRow.taskDetail) !== 'undefined' && typeof(originalRow.taskDetail.description) !== 'undefined') {
          isValidRow = originalRow.taskDetail.description.toLocaleLowerCase().includes(currentSearchTitle);
        }

        if (isValidRow === false && typeof(originalRow.taskDetail) !== 'undefined' && typeof(originalRow.taskDetail.commentsList) !== 'undefined' && originalRow.taskDetail.commentsList.length > 0) {
          originalRow.taskDetail.commentsList.forEach(comment => {
            if (comment.commentDescription.toLocaleLowerCase().includes(currentSearchTitle) === true) {
              isValidRow = true;
              return true
            }
          })
        }

        if (isValidRow === true) {
          tableList.rows.push(originalRow)
        }
      })
    }

    this.setState({tableList: tableList, originalRows: originalRows})
  }

  render() {
    return template.call(this);
  }
}

const mapStateToProps = createSelector(
  state => state.processFlow.tenantId,
  state => state.treeView.data,
  (tenantId, treeView) => ({
    tenantId,
    treeView
  })
);

const mapActionsToProps = {
  setMessage: setMessage,
}

export default connect(mapStateToProps, mapActionsToProps)(TaskList);
