import React from 'react';
import { connect } from 'react-redux';
import { PropTypes } from 'prop-types';
import { createSelector } from 'reselect';
import { setMessage } from '../../../../../actions/actionNotification';
import MessageTypeConst from "../../../../MessageTypeConst";
import { Input } from './StandardInput';

export const NewColumnNameForCharCell = ({ tableManager, value, data, column, colIndex, rowIndex }) => {
    if (typeof(tableManager) === 'undefined') {
      return (null)
    } else {
      return (
          <div className='rgt-cell-inner' style={{display: 'flex', alignItems: 'center', overflow: 'hidden'}}>
              <NewColumnNameForCharCellClass dataItem={data} field={column.field} value={typeof(data[column.field]) !== 'undefined' ? data[column.field]: value} tableManager={tableManager}/>
          </div>
      )
    }
}

class NewColumnNameForCharCustomCellClass extends React.Component {
    handleonBlur(e){
      if(this.props.field === 'lengthNew' && e.target.value !== null && e.target.value !== "" && this.props.dataItem['length'] >= e.target.value){
        this.props.setMessage("New length must be greater than existing length.", MessageTypeConst.WARNING_MESSAGE);
        e.target.value = "";
        this.handleChange(e) ;
        return;
      } else if (this.props.field === 'lengthNew' && e.target.value !== null && e.target.value !== "" && typeof(this.props.dataItem['spacing']) !== 'undefined' && this.props.dataItem['spacing'] === '') {
        this.props.dataItem['spacing'] = 'Start'
      }
      this.handleChange(e) ;
    }
    handleChange(e) {
      if  (e.target.value === '' && typeof(e.target.value) !== 'undefined' && e.target.value !== null) {
        if (typeof(this.props.dataItem['seachPolicy']) !== 'undefined' && typeof(this.props.dataItem['condition']) !== 'undefined' && typeof(this.props.dataItem['searchCriteria']) !== 'undefined') {
          this.props.dataItem['seachPolicy'] = '';
          this.props.dataItem['condition'] = '';
          this.props.dataItem['searchCriteria'] = '';
        }
      }

      this.props.dataItem[this.props.field] = e.target.value;
      this.props.tableManager.rowsApi.setRows(this.props.tableManager.rowsApi.rows)
    }
    render() {
        var disabled = false;
        if (this.props.dataItem['type'] !== 'CHAR') {
          disabled = true;
        }
        var InputType="";
        if(this.props.field === 'lengthNew'){
          InputType = "number"
        }
        var placeholder = "";
        if (this.props.dataItem['splitOperation'] === 'At Absolute Range') {
          placeholder = "e.g. 1-1,1-2,3-5";
        } else if(this.props.dataItem['splitOperation'] === 'At Character Occurence'){
          placeholder = "e.g. A";
        }
        return (
            <>
              <Input type={InputType} className="width-full" onBlur={this.handleonBlur.bind(this)} onChange={this.handleChange.bind(this)}
              defaultValue={this.props.dataItem[this.props.field]} length={40}
              disabled={disabled} placeholder={placeholder}
              />
            </>
        );
    }
}

const mapStateToProps = createSelector(
  state => state.processFlow.tenantId,
  (tenantId) => ({
    tenantId,
  })
);

const mapActionsToProps = {
  setMessage: setMessage
}

NewColumnNameForCharCustomCellClass.propTypes = {
  tenantId: PropTypes.string
}

const NewColumnNameForCharCellClass = connect(mapStateToProps, mapActionsToProps)(NewColumnNameForCharCustomCellClass);
