// @flow
import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { createSelector } from "reselect";
import $ from 'jquery';
import cloneDeep from 'lodash/cloneDeep';
import Spinner from "./Spinner";
import { PdfLoader, PdfHighlighter } from "react-pdf-highlighter";
import Highlight from "./Highlight";
import searchPdf from "./searchPdf";
import type { T_NewHighlight } from "react-pdf-highlighter/types";
import Sidebar from "./Sidebar";
import FindAndAnnotate from "./FindAndAnnotate";
import "./style/App.css";
import {any} from "lodash/fp";
import { setHighlightList } from './../../../../actions/actionAnnotate';
import { setMessage } from './../../../../actions/actionNotification';
import MessageTypeConst from './../../../MessageTypeConst';
import ConfirmBox from './../../DataOperation/common/ConfirmBox';
type Props = {};

let scrollViewerTo = (highlight: any) => {};

const DEFAULT_URL = "";

const getNextId = () => String(Math.random()).slice(2);

const PdfViewAndHighlights = (props: Props) => {
  const [highlights, setHighlights] = useState(typeof(props.highlightList) !== 'undefined' ? props.highlightList : []);
  const [selectedHighlightId, setselectedHighlightId] = useState("");
  const [isAllPages, setIsAllPages] = useState(false);
  const [isFindMode, setIsFindMode] = useState(false);
  const [isSelectedAll, setIsSelectedAll] = useState(true);

  const resetHighlights = (type = true) => {
    ConfirmBox.open("Are you sure you want to "+(type === true ? "rest" : "delete")+"?").then((data) => {
      setHighlights(type === true ? [] : highlights.filter(highlight => highlight.selected !== true))
      setIsSelectedAll(true)
      setIsFindMode(false)
      clearHighlight();
    })
  };

  const selectChangeHighlight = (id) => {
    const updatedHighlights = highlights.map(highlight => {
      if (id === '') {
        setIsSelectedAll(!isSelectedAll)
        return {
          ...highlight,
          selected: isSelectedAll
        }
      } else if (highlight.id === id) {
        removeHighlightMarks(id);
        highlights.filter(childHighlight => childHighlight.parentId === id).forEach(childHighlight => {
          removeHighlightMarks(childHighlight.id);
        })
        return {
          ...highlight,
          selected: !highlight.selected
        }
      } else if (highlight.parentId === id) {
        return {
          ...highlight,
          selected: !highlight.selected
        }
      } else {
          return highlight;
      }
    });
    setHighlights([...updatedHighlights]);
  };

  const removeHighlightMarks = (parentId) => {
    const highlightedMarkList = document.querySelectorAll('mark[parent-id="'+parentId+'"]');
    for(let i = 0;i < highlightedMarkList.length; i++)
    {
      if(highlightedMarkList[i].getAttribute('parent-id') === parentId && highlightedMarkList[i].getAttribute('is-last-match') == '1') {
        var parent = highlightedMarkList[i].parentNode;
        parent.replaceChild(highlightedMarkList[i].firstChild, highlightedMarkList[i]);
        parent.normalize();
      }
    }
  }

  // using effect, when the selected highlight changes.
  // this will execute when user clicks on any of the highlight in sidebar
  // This will scroll the page to the highlight user has clicked
  useEffect(() => {
    const highlight = highlights.find(highlight => highlight.id === selectedHighlightId);
    if(highlight){
      scrollViewerTo(highlight);
    }
  }, [selectedHighlightId]);

  useEffect(() => {
    /*if(highlights.length){
      const lastHighlight = highlights[highlights.length - 1];
      if (lastHighlight.content.text && lastHighlight.data) {
        const pageNumber = lastHighlight.position.pageNumber;
        if (document.querySelector(`div[data-page-number='${pageNumber}']`) !== null) {
            searchAndHighlightPage(pageNumber, lastHighlight);
        }
      }
    }*/
    props.setHighlightList(highlights)
  }, [highlights]);

  //  Call this function recursively until user cancel the event
  //    or the PDF is completed
  const searchAndHighlightPage = (pageNumber = 0) => {
    $(".loader").show();
    // Logic to smoothly scrolling to the next page
    pageNumber = pageNumber === 0 ? getCurrentPageNumber() : pageNumber;
    //const textToSearch = highlight.content.text;
    // We will search the current page for all the possible highlight values
    var myPage = new searchPdf(`div[data-page-number='${pageNumber}']`); // id of the element to parse
    let validHighlights = highlights.filter(highlight => highlight.parentId === null && highlight.selected === true && typeof(highlight.data.title) !== 'undefined'); //
    //  Apply the search term to highlight
    //  and move to next page.
    //  We need to implement the functionality to highlight the next highlight
    //  this should be done in sequence for user to confirm those highlights
    let isFoundWordScroll = false;
    if (global.lastPageNumber !== pageNumber) {
      global.pageMarkList.length = 0;
    }

    validHighlights.forEach((highlight, index) => {
      const childHighlights = highlights.filter(childHighlight => childHighlight.parentId === highlight.id);
      let validHighlight = childHighlights.length > 0 ? childHighlights[childHighlights.length - 1] : highlight

      if (global.lastPageNumber === 0 && index === 0) {
        myPage = new searchPdf(`div[data-page-number='${validHighlight.position.pageNumber}']`);
      }
      let isFoundWord = myPage.apply(validHighlight)
      if (isFoundWord === true && isFoundWordScroll === false) {
        var lastMatchedMark = document.querySelector(`mark[parent-id='${validHighlight.id}']`);

        if (lastMatchedMark !== null) {
          lastMatchedMark.scrollIntoView({behavior: "smooth", block: "end", inline: "nearest"})
          isFoundWordScroll = true;
        }
      }

      global.lastPageNumber = pageNumber;
    })

    if (validHighlights.length > 0) {
      if (document.querySelector('mark') === null) {
        global.pageMarkList.length = 0;
        const nextPageNumber = pageNumber + 1;
        const nextPage = document.querySelector(`div[data-page-number='${nextPageNumber}']`);
        if (nextPage !== null) {
            nextPage.scrollIntoView({behavior: "smooth", block: "start", inline: "nearest"})

            if (nextPage.querySelector('.textLayer') === null) {
              if (nextPageNumber > 1) {
                document.querySelector(`div[data-page-number='${nextPageNumber-1}']`).scrollIntoView({behavior: "auto", block: "end", inline: "nearest"})
              }
              checkNextPageTextLayerFound(nextPageNumber)

            } else {
              searchAndHighlightPage(nextPageNumber);
            }
        } else {
          $(".loader").hide();
          props.setMessage("Can't find the text", MessageTypeConst.ERROR_MESSAGE)
        }
      } else {
        $(".loader").hide();
      }
    } else {
      $(".loader").hide();
    }


    // Once all the highlights are done and confirmed for the page
    //  implement the logic to go to next page as a callback of the apply function above
    //  call the searchAndHighlightPage function for next page with the same highlight
    //
  };

  const checkNextPageTextLayerFound = (pageNumber) => {
    let nextPage = document.querySelector(`div[data-page-number='${pageNumber}']`);
    if (nextPage.querySelector(`.textLayer`) === null) {
      setTimeout(()=> {
          nextPage.scrollIntoView({behavior: "auto", block: "end", inline: "nearest"})
          return checkNextPageTextLayerFound(pageNumber);
      }, 500)
    } else {
      searchAndHighlightPage(pageNumber);
      return true
    }
  }

  const addHighlight = (highlight: T_NewHighlight) => {
    const updatedHighlights = [...highlights];
    if(updatedHighlights[updatedHighlights.length - 1] && !updatedHighlights[updatedHighlights.length - 1].data.title){
      updatedHighlights.splice(-1, 1);
    }

    if(typeof(highlight.content) !== 'undefined' && highlight.content.text !== '') {
        highlight.content.text = highlight.content.text.trim();
    }

    if (typeof(props.treeView) !== 'undefined' && typeof(highlight.data.title) === 'undefined') {
      let columnDetail = '';

      if (props.treeView.columnStatus === 'blue') {
          columnDetail = {title: 'STUDY : '+ props.treeView.name, id: props.treeView.id}
      }

      if (!columnDetail) {
        props.treeView.children.filter(studyElement => studyElement.name === 'ProcessFlows').forEach(studyElement => {
          studyElement.children.filter(classifier => {
            classifier.children.forEach(processGroup => {
              if (processGroup.columnStatus === 'blue') {
                columnDetail = {title: 'PROCESS FLOW GROUP : '+ processGroup.name, id: processGroup.id}
              } else {
                processGroup.children.forEach(processFlow => {
                  if (processFlow.columnStatus === 'blue') {
                    columnDetail = {title: 'PROCESS FLOW : ' + processFlow.name, id: processFlow.id}
                  } else if (typeof(processFlow.children) !== 'undefined') {
                    processFlow.children.forEach(processBlock => {
                      if(processBlock.columnStatus === 'blue') {
                        columnDetail = {title: 'PROCESS BLOCK : ' +processBlock.name, id: processBlock.id}
                      }
                    })
                  }
                })
              }
            })
          })
        })
      }
      highlight = {
        ...highlight,
        data: {
          ...columnDetail,
          position: {
            height: "auto",
            left: highlight.position.boundingRect.x2 - 5,
            parentPageX: 'auto',
            parentPageY: 'auto',
            top: highlight.position.boundingRect.y1 - 25,
            width: "auto"
          }
        }
      }
    }

    if (updatedHighlights.length > 0 && updatedHighlights.filter(updatedHighlight => updatedHighlight.content.text.toLowerCase() === highlight.content.text.trim().toLowerCase() && updatedHighlight.parentId === null).length > 0) {
      props.setMessage("Invalid highlight. You have already highlighted same text. Please check your highlights", MessageTypeConst.ERROR_MESSAGE)
    } else {
      setHighlights([...updatedHighlights, {...highlight, id: getNextId()}]);
    }
  }

  const updateHighlight = (highlightId, text) => {
    setHighlights(highlights.map(highlight => {
      if(highlight.id === highlightId) {
        highlight.data.title = text;
      }
      return highlight;
    }))
  }

  const updateAnnotationPosition = (highlightId, event) => {
    setHighlights(highlights.map(highlight => {
      if(highlight.id === highlightId) {
        highlight.data.position = {
          ...highlight.data.position,
          left: highlight.data.position.left + event.translateX,
          top:  highlight.data.position.top + event.translateY
        };
      }
      return highlight;
    }))
  }

  const removeHighlight = highlightId => {
    setHighlights(highlights.filter(highlight => highlight.id !== highlightId ))
  }

  const jumpToNextPage = (position: T_Position) => {
    let pageNumber = getCurrentPageNumber();
    if (pageNumber > 0) {
      const nextPage = document.querySelector(`div[data-page-number='${pageNumber+1}']`)
      nextPage.scrollIntoView({
        behavior: "smooth"
      });
    }
  }

  const getCurrentPageNumber = () => {
    let pageNumber = 0;
    if (document.querySelector(`div.page`) !== null) {
      $('div.page[data-loaded="true"]').each(function(index, page) {
        if (checkVisible(page) === true && pageNumber < parseInt($(page).attr('data-page-number'))) {
          pageNumber = parseInt($(page).attr('data-page-number'))
        }
      })
    }

    return pageNumber;
  }

  const checkVisible = ( element, elementValue) => {
    elementValue = elementValue || "visible";
    var vpH = $(window).height(), // Viewport Height
        st = $(window).scrollTop(), // Scroll Top
        y = $(element).offset().top,
        elementHeight = $(element).height();

    if (elementValue == "visible") return ((y < (vpH + st)) && (y > (st - elementHeight)));
    if (elementValue == "above") return ((y < (vpH + st)));
  }

  const clearHighlight = () => {
    let pageNumber = getCurrentPageNumber();
    var myPage = new searchPdf(`div[data-page-number='${pageNumber}']`); // id of the element to parse
    myPage.remove()
    global.pageMarkList.length = 0;
    global.lastPageNumber = 0;
    setIsFindMode(false);
  }

  const highlightAndAnnotate = () => {
    let pageNumber = getCurrentPageNumber();
    let page = document.querySelector(`div[data-page-number='${pageNumber}']`);
    let pageBoundingClientRect = page.getBoundingClientRect();
    let updatedHighlights = [...highlights];
    $(`div[data-page-number='${pageNumber}'] mark`).each(function(index, mark) {
      let parentId = mark.getAttribute('parent-id');
      const boundingClientRect = mark.getBoundingClientRect();
      const markX = boundingClientRect.x - pageBoundingClientRect.x
      const markY = boundingClientRect.y - pageBoundingClientRect.y
      let highlight = highlights.find(highlight => highlight.id === parentId);

      if (typeof(highlight.parentId) !== 'undefined' && highlight.parentId !== null) {
        highlight = highlights.find(parentHighlight => parentHighlight.id === highlight.parentId);
        parentId = highlight.id
      }

      if (typeof(highlight.id) !== 'undefined') {
        highlight = cloneDeep(highlight);
        highlight.id = getNextId();
        highlight.parentId = parentId;

        let rects = highlight.position.rects
        rects.map(rect => {
          rect.x1 = markX;
          rect.x2 = markX + boundingClientRect.width;
          rect.y1 = markY;
          rect.y2 = markY + boundingClientRect.height;
          rect.pageNumber = pageNumber
          return rect
        })

        highlight.position = {
          ...highlight.position,
          boundingRect: {
            ...highlight.position.boundingRect,
            x1: markX,
            x2: markX + boundingClientRect.width,
            y1: markY,
            y2: markY + boundingClientRect.height,
            pageNumber: pageNumber
          },
          pageNumber: pageNumber,
          rects: rects
        }

        if (typeof(highlight.data.title) !== 'undefined') {
          highlight.data = {
            ...highlight.data,
            position: {
              ...highlight.data.position,
              left: markX + boundingClientRect.width + 10,
              top: markY,
              parentPageX: pageBoundingClientRect.x,
              parentPageY: pageBoundingClientRect.y
            }
          }
        }

      }
      updatedHighlights.push(highlight);
    })
    setHighlights(updatedHighlights);
    clearHighlight()
  }

  return (
    <div className="annotate-pdf-wrapper" style={{ display: "flex", height: "80vh" }}>
      <Sidebar
          highlights={highlights}
          isAllPages={isAllPages}
          isFindMode={isFindMode}
          setIsAllPages={setIsAllPages}
          resetHighlights={resetHighlights}
          scrollViewerTo={(highlight) => setselectedHighlightId(highlight.id)}
          selectChangeHighlight={selectChangeHighlight}
          searchAndHighlightPage={() => {
            if (isFindMode === false) {
                setIsFindMode(true);
            }
            searchAndHighlightPage()
          }}
          highlightAndAnnotate={() => {
            if(isFindMode === true) {
                setIsFindMode(false);
            }
            highlightAndAnnotate()
          }}
          clearHighlight={() => clearHighlight()}
          isSelectedAll={isSelectedAll}
          type={typeof(props.type) !== 'undefined' ? props.type: 'auto'}
          closeDialog={typeof(props.closeDialog) !== 'undefined' ? props.closeDialog: ''}
        />
        <div
          style={{
            height: "80vh",
            width: "75vw",
            position: "relative"
          }}
          onDragOver={(e) => {
            e.preventDefault();
          }}
          onDrag={(e) => {
          }}
          onDrop={(e) => {
            e.preventDefault();
            const { metadataTableTree, rightPersistentTableTree } = props.treeElement;
            let droppedElementId = e.dataTransfer.getData('element');
            let droppedElementParentId = droppedElementId;
            let droppedElementHeight = e.dataTransfer.getData('height');
            let droppedElementWidth = e.dataTransfer.getData('width');
            let droppedElementTitle = e.dataTransfer.getData('title');
            let columnDetail;
            let columnTitle;

            let isUniqueColumn = false;
            if(droppedElementId.split('_annotate')[1] !== ''){
              isUniqueColumn = true;
              droppedElementParentId = `${droppedElementId.split('_annotate')[0]}_annotate`;
            }

            if(droppedElementParentId){
              if (typeof(metadataTableTree) !== 'undefined') {
                metadataTableTree.children.forEach(table => {
                  if(!columnDetail){
                    columnDetail = cloneDeep(table.children.find(col => `${col.id}_annotate` === droppedElementParentId));
                  }
                });
              }

              if (typeof(props.treeView) !== 'undefined') {
                if (props.treeView.id === droppedElementParentId) {
                    columnDetail = {title: 'STUDY : '+ props.treeView.name, id: props.treeView.id}
                }

                if (!columnDetail) {
                  props.treeView.children.filter(studyElement => studyElement.name === 'ProcessFlows').forEach(studyElement => {
                    studyElement.children.filter(classifier => {
                      classifier.children.forEach(processGroup => {
                        if (processGroup.id === droppedElementParentId) {
                          columnDetail = {title: 'PROCESS FLOW GROUP : '+ processGroup.name, id: processGroup.id}
                        } else {
                          processGroup.children.forEach(processFlow => {
                            if (processFlow.id === droppedElementParentId) {
                              columnDetail = {title: 'PROCESS FLOW : ' + processFlow.name, id: processFlow.id}
                            }

                            if (!columnDetail && typeof(processFlow.children) !== 'undefined') {
                              processFlow.children.filter(procesBlock => procesBlock.id === droppedElementParentId).forEach(procesBlock => {
                                columnDetail = {title: 'PROCESS BLOCK : ' + procesBlock.name, id: procesBlock.id}
                              })
                            }
                          })
                        }
                      })
                    })
                  })
                }
              }
              if(!columnDetail && typeof(rightPersistentTableTree) !== 'undefined' && typeof(rightPersistentTableTree.children) !== 'undefined') {
                rightPersistentTableTree.children.forEach(table => {
                  if(!columnDetail){
                    columnDetail = table.children.find(col => `${col.id}_annotate` === droppedElementParentId);
                  }
                });
              }

              if(isUniqueColumn && typeof(columnDetail) !== 'undefined' && typeof(columnDetail.displayTableName) !== 'undefined'){
                columnTitle = `${columnDetail.displayTableName.split(' (')[0]} When ${columnDetail.column.column_header} = "${droppedElementTitle}"`;
                columnDetail.uniqueColumnValue = droppedElementTitle;
              }

              if (highlights.filter(highlight => typeof(highlight.content) !== 'undefined' && highlight.content.text === '').length > 0) {
                props.setMessage("You can't annotate more than one variable without highlight text. Please do highlight text", MessageTypeConst.ERROR_MESSAGE)
                return true;
              }

              if (highlights.filter(highlight => columnDetail && typeof(highlight.data.title) !== 'undefined' && highlight.data.id === columnDetail.id && highlight.data.uniqueColumnValue === columnDetail.uniqueColumnValue).length > 0 && typeof(metadataTableTree) !== 'undefined') {
                props.setMessage("You can't use same variable for more than one highlight text. please use another variable for annotate", MessageTypeConst.ERROR_MESSAGE)
                return true;
              }

              if(columnDetail){
                const currentPage = e.target.closest(".page");
                const boundingClientRect = currentPage.getBoundingClientRect();

                let data = {
                  ...columnDetail,
                  title: columnTitle || columnDetail.title,
                  position: {
                    left: e.clientX - boundingClientRect.x - droppedElementWidth/2,
                    top: e.clientY - boundingClientRect.y - droppedElementHeight/2,
                    height: droppedElementHeight,
                    width: droppedElementWidth,
                    parentPageX: boundingClientRect.x,
                    parentPageY: boundingClientRect.y
                  }
                }
                // Need to implement the logic to attach the dropped element's
                // data details to the specific highlight if the drop is on the wrapper component
                if(highlights.length > 0 && typeof(highlights[highlights.length - 1].data.title) === 'undefined'){
                  // Code to attach annotation to highlight
                  const currentHighlightId = highlights[highlights.length - 1].id;
                  const updatedHighlights = highlights.map(highlight => {
                    if (currentHighlightId === highlight.id) {
                      return {
                        ...highlight,
                        data: data
                      }
                    }else {
                      return highlight;
                    }
                  });
                  setHighlights([...updatedHighlights]);
                } else {
                  // code to add new highlight to the array as the dropped element is not on highlighted area
                  const blankHighlight = {
                    content: {
                      text: ""
                    },
                    position: {
                      boundingRect: {
                        x1: e.clientX - boundingClientRect.x - droppedElementWidth/2,
                        y1: e.clientY - boundingClientRect.y - droppedElementHeight/2,
                        x2: e.clientX - boundingClientRect.x - droppedElementWidth/2,
                        y2: e.clientY - boundingClientRect.y - droppedElementHeight/2,
                        width: boundingClientRect.width,
                        height: boundingClientRect.height
                      },
                      rects: [{
                        x1: e.clientX - boundingClientRect.x - droppedElementWidth/2,
                        y1: e.clientY - boundingClientRect.y - droppedElementHeight/2,
                        x2: e.clientX - boundingClientRect.x - droppedElementWidth/2,
                        y2: e.clientY - boundingClientRect.y - droppedElementHeight/2,
                        width: boundingClientRect.width,
                        height: boundingClientRect.height
                      }],
                      pageNumber: parseInt(currentPage.getAttribute("data-page-number"), 10) // Calculate page number
                    },
                    comment: {
                      text: ""
                    },
                    data: data,
                    id: getNextId(),
                    parentId: null,
                    selected: true
                  }
                  setHighlights([...highlights, { ...blankHighlight }]);
                }
              }
            }
          }}
        >
          <PdfLoader id="PdfLoader" url={props.fileURL} beforeLoad={<Spinner />}>
            {pdfDocument => (
              <PdfHighlighter
                pdfDocument={pdfDocument}
                scrollRef={scrollTo => {
                  scrollViewerTo = scrollTo;
                }}
                onScrollChange={() => setselectedHighlightId("") }
                onSelectionFinished={(
                  position,
                  content,
                  hideTipAndSelection,
                  transformSelection
                ) => {
                  if (position.rects.length <= (typeof(props.type) === 'undefined' ? 10: 100)) {
                    let comment = { text: '' };
                    let parentId = null;
                    let selected = true;
                    if(highlights.length > 0 && typeof(highlights[highlights.length - 1].content) !== 'undefined' && highlights[highlights.length - 1].content.text === ''){
                      // Code to attach annotation to highlight
                      const currentHighlightId = highlights[highlights.length - 1].id;
                      const updatedHighlights = highlights.map(highlight => {
                        if (currentHighlightId === highlight.id) {
                          return {
                            ...highlight,
                            content: content,
                            position: position,
                          }
                        }else {
                          return highlight;
                        }
                      });
                      setHighlights([...updatedHighlights]);
                    } else {
                      let data = {}
                      addHighlight({ content, position, comment, parentId, selected, data });
                    }

                  }
                }}
                highlightTransform={(
                  highlight,
                  index,
                  setTip,
                  hideTip,
                  viewportToScaled,
                  screenshot,
                  isScrolledTo
                ) => {
                  return (
                    <Highlight
                      key={index}
                      highlight={highlight}
                      isScrolledTo={isScrolledTo}
                      viewportToScaled={viewportToScaled}
                      screenshot ={screenshot}
                      updateHighlight = {(highlightId, text) => updateHighlight(highlightId, text)}
                      updateAnnotationPosition = {(highlightId, position) => updateAnnotationPosition(highlightId, position)}
                      removeHighlight = {highlightId => removeHighlight(highlightId)}
                    >

                    </Highlight>
                  )
                }}
                highlights={highlights}
              />
            )}
          </PdfLoader>
        </div>
      </div>
  )
}

const mapStateToProps = createSelector(
  state => state.processFlow.tenantId,
  state => state.annotate.treeElement,
  state => state.processFlow.processFlowTable,
  state => state.annotate.highlightList,
  (tenantId, treeElement, processFlowTable, highlightList) => ({
    tenantId,
    treeElement,
    processFlowTable,
    highlightList
  })
);

const mapActionsToProps = {
  setHighlightList: setHighlightList,
  setMessage: setMessage
}
export default connect(mapStateToProps, mapActionsToProps)(PdfViewAndHighlights);
