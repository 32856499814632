import React from 'react';
import { Accordion } from 'reactstrap';
import classnames from 'classnames';

export class PanelBar extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      tooglePanelList: typeof(props.openId) !== 'undefined' ? props.openId: []
    }
  }
  togglePanel = (tooglePanelId) => {
    let tooglePanelList = this.state.tooglePanelList;
    let expanded = true;
    if (tooglePanelList.indexOf(tooglePanelId) === -1) {
      tooglePanelList.push(tooglePanelId);
    } else if (tooglePanelList.indexOf(tooglePanelId) > -1) {
      tooglePanelList = tooglePanelList.filter(tooglePanel => tooglePanel !== tooglePanelId);
      expanded = false;
    }
    this.setState({tooglePanelList: tooglePanelList}, () => {
      if (typeof(this.props.onSelect) !== 'undefined') {
        this.props.onSelect({target: {props: {...(typeof(this.props.children[0]) !== 'undefined' ? this.props.children.find(prop => prop.props.id === tooglePanelId) : this.props.children).props, expanded: expanded}}})
      }
    })
  }

  render() {
      return (
        React.createElement(Accordion, {className: "am-panelbar", open:this.state.tooglePanelList, toggle:this.togglePanel}, this.props.children)
      );
    }
}
