import React from "react";
import GridMapper from '../../GridMapper';

function AppendLayoutTemplate() {
  return (
    <div className={`process-option${typeof(this.state.processFlowAction) !== 'undefined' ? ' standard' : ''}`}>
      <GridMapper {...this.state}/>
    </div>
  );
};

export default AppendLayoutTemplate;
