import React    from "react";
import { connect } from 'react-redux';
import { createSelector } from 'reselect';
import { POST_PARAM_BODY } from '../../../restClient/types';
import restClient from '../../../restClient';
import axios from 'axios';
import $ from 'jquery';
import MessageTypeConst from "../../MessageTypeConst";
import { setMessage } from '../../../actions/actionNotification';
import { setTreeViewData } from '../../../actions/actionTreeView';
import { addImportTable } from '../../../actions/actionProcessFlow';
import { addStudy } from '../../../actions/actionStudy';
import { updateProjectList } from '../../../actions/actionProject';
import { PropTypes } from "prop-types";
import Dialog from 'react-dialog';
import { Button } from './../DataOperation/common/Button/StandardButton';
import RichGridTable from "./../RichGridTable";
import ASSESSMENT_TYPE from "./AssessmentType.js";
import { CompareFlowButton } from './CompareFlowButton';
import { JumpToFlowButton } from './JumpToFlowButton';
import { CopyObjectStatusCell } from './CopyObjectStatusCell';
import { BasicCheckBoxCell } from "../DataOperation/common/index.js";
import { DropDownList } from './../DataOperation/common/DropDown/StandardDropDownList';
import { FlowNameCell } from './FlowNameCell';
import CopyAssessmentStatus from "./CopyAssessmentStatus";
import COPY_TYPE from "../../CopyType.js";
import { NewColumnNameCell } from './../DataOperation/common/InputBox/NewColumnNameCell';
import {manageProceeFlowGroupInTreeView, manageProcessInTreeView } from '../NavigationBarMenu/NavigationBarMenu.js';
import ConfirmBox from './../DataOperation/common/ConfirmBox';

class GroupAssessment extends React.Component {
    constructor(props){
        super(props);
        this.state ={
            copyInputTable:true,
            showReCopyDialog:false,
            closeEvent:props.closeEvent,
            sourceStudy:[{sourceStudyId:0, sourceStudyName:'',sourceSchemaName:''}],
            selectedSourceStudy:{sourceStudyId:0, sourceStudyName:'',sourceSchemaName:''},
            assessmentNode:props.assessmentNode,
            reCopyFlowRows: [{selected:"",sourceFlowId:0 ,sourceFlowName:'',targetFlowName:''}],
            reCopyFlowColumnProperty: [
              { field: "selected", show: false},
              { field: "sourceFlowId",show: false},
              { field: "sourceFlowName", title:"Source Process Flow"},
              { field: "targetFlowName", title: "Process Flow Name", cell :NewColumnNameCell},
            ],
            groupRows:[{selected:false,columnId:0, processGroupId:0, sourceStudyId:0, sourceGroupId:0,
                sourceFlowId:0,processGroupName:'',processFlowName:'', processFlowId:0, processStudyId:0,status:0,sourceGroupName:'',sourceFlowName:'',closeEvent:props.closeEvent}],
            groupColumnProperty:[
                { field: "selected", title:"", width:"25px", orderIndex:0, cell: BasicCheckBoxCell, sortable:false,},
                { field:"columnId", show :false},
                { field: "processGroupId",show: false},
                { field: "sourceStudyId",show: false},
                { field: "sourceGroupId",show: false},
                { field: "sourceFlowId",show: false},
                { field: "processGroupName", title:"Process Group", width:"250px", orderIndex:1,show: false},
                { field: "processFlowName", title: "Target Process Flow", width:"200px", orderIndex:2, cell: FlowNameCell},
                { field: "status",title:"Status (in comparison with source)",cell:CopyObjectStatusCell,  width:"250px", orderIndex:4},
                { field: "processFlowId",title:"Compare Flows",cell:CompareFlowButton, orderIndex:5, width:"140px"},
                { field: "processStudyId",title:"Jump to Flow",cell:JumpToFlowButton, orderIndex:6},
                { field: "sourceGroupName",show: false},
                { field: "sourceFlowName", title: "Source Process Flow", width:"200px", orderIndex:3, cell: FlowNameCell},
                { field: "closeEvent",show: false},
            ],
            title: this.getTitle(props.assessmentNode),
            studyComparisonData:[{
            sourceStudyId:0,
            copyAssessmentResponse:[{selected:false,columnId:0, processGroupId:0, sourceStudyId:0, sourceGroupId:0,
                sourceFlowId:0,processGroupName:'',processFlowName:'', processFlowId:0, processStudyId:0,status:0,sourceGroupName:'',sourceFlowName:''}]
            }]
        }
    }

    componentDidMount(){
        let request = {
            copyAssessmentType : this.state.assessmentNode.copyAssesmentType,
            processFlowGroups:this.getListOfGroupsForassessment(),
            sourceSchemaName:'',
            sourceStudyId:0
        };
        this.fetchAssessmentResult(request);

    }

    getListOfGroupsForassessment = () => {
        let groups = [];
        switch(this.state.assessmentNode.copyAssesmentType){
            case ASSESSMENT_TYPE.STUDY_ASSESSMENT:
            break;
            case ASSESSMENT_TYPE.GROUP_ASSESSMENT:
            return this.getGroupDetails();
            default:
            break;
        }
        return groups;
    }

    getTitle = (assessmentNode) => {
        let title = "";
        if(assessmentNode === null || typeof(assessmentNode) === "undefined"){
            return;
        }
        switch(assessmentNode.copyAssesmentType){
            case ASSESSMENT_TYPE.GROUP_ASSESSMENT:
                title = `${assessmentNode.name} Comparison`;
                break;
            case ASSESSMENT_TYPE.STUDY_ASSESSMENT:
                title = `${assessmentNode.name} Comparison`;
                break;
            default:
                break;

        }
        return title;
    }

    getGroupDetails = () => {
        let treeView = this.props.treeView.data;
        let assessmentNode = this.state.assessmentNode;
        let group = {
            processGroupId:0,
            studyId:0,
            processGroupName:'',
            processFlow:[]
        };
        if(assessmentNode === null || typeof(assessmentNode) === "undefined"){
            return [group];
        }
        group.processGroupId = assessmentNode.groupId;
        group.studyId = assessmentNode.studyId;
        group.processGroupName = assessmentNode.name;
        let selectedProject = treeView.children.filter(proj => proj.projectId === assessmentNode.projectId);
        if(selectedProject.length > 0){
            let selectedStudy = selectedProject[0].children.filter(study => study.study.studyId === assessmentNode.studyId);
            if(selectedStudy.length > 0){
                let rootprocessFlowNode = selectedStudy[0].children.filter(node => node.name === "ProcessFlows");
                if(rootprocessFlowNode.length > 0){
                    rootprocessFlowNode[0].children.forEach((groupClassifierNode)=>{
                    let selectedGroup = groupClassifierNode.children.filter(grp => grp.processGroupId === assessmentNode.groupId);
                    if(selectedGroup.length > 0){
                        selectedGroup[0].children.forEach((flow)=>{
                            let process = {
                                processId:flow.process.processId,
                                studyId:flow.process.studyId,
                                processName:flow.process.processName,
                                processGroupId:assessmentNode.groupId
                            }
                            group.processFlow.push(process);
                        })
                    }
                    });
                }
            }
        }
        return [group];
    }

    closeDialog = (event) => {
        this.state.closeEvent();
    }

    handleSourceStudyChange = (event) => {
        let that = this;
        let studyComparisonData = that.state.studyComparisonData;
        let selectedStudyData = studyComparisonData.filter(item=>item.sourceStudyId === event.target.value.sourceStudyId);
        if(selectedStudyData.length > 0){
            let groupRows = that.state.groupRows;
            groupRows.length = 0;
            groupRows.push(...selectedStudyData[0].copyAssessmentResponse);
            this.setState({selectedSourceStudy:event.target.value, groupRows:groupRows});
        }else{
            this.setState({selectedSourceStudy:event.target.value});
            let request = {
                copyAssessmentType : this.state.assessmentNode.copyAssesmentType,
                processFlowGroups:this.getListOfGroupsForassessment(),
                sourceSchemaName:event.target.value.sourceSchemaName,
                sourceStudyId:event.target.value.sourceStudyId
            };
            this.fetchAssessmentResult(request);
        }
    }

    fetchAssessmentResult = (request) => {
        let that = this;
        $(".loader").show();
        axios.all([restClient(POST_PARAM_BODY, 'copy/assessment', { data: request }, { 'Tenant-ID': this.state.assessmentNode.schemaName })])
            .then(axios.spread(function (response) {
                if (response.data !== null && typeof (response.data) !== "undefined" &&
                    response.data.copyAssessmentResponse !== null && typeof (response.data.copyAssessmentResponse) !== "undefined" && Array.isArray(response.data.copyAssessmentResponse)) {
                    let groupRows = that.state.groupRows;
                    let sourceStudy = that.state.sourceStudy;
                    let selectedSourceStudy = that.state.selectedSourceStudy;
                    if (response.data.sourceStudy !== null && typeof (response.data.sourceStudy) !== "undefined" && Array.isArray(response.data.sourceStudy)) {
                        if (response.data.copyAssessmentResponse.length > 0) {
                            sourceStudy.length = 0;
                            response.data.sourceStudy.forEach((study) => {
                                let srcStudy = {
                                    sourceStudyId: study.studyId, sourceStudyName: study.studyName, sourceSchemaName: study.schemaName
                                };
                                sourceStudy.push(srcStudy);
                            });
                        }
                    }
                    if (response.data.selectedStudyId !== null && typeof (response.data.selectedStudyId) !== "undefined" && response.data.selectedStudyId !== 0) {
                        if (sourceStudy.length > 0) {
                            let selectedStudy = sourceStudy.filter(item => item.sourceStudyId === response.data.selectedStudyId);
                            if (selectedStudy.length > 0) {
                                selectedSourceStudy = selectedStudy[0];
                            }
                        }
                    }
                    if (response.data.copyAssessmentResponse.length > 0) {
                        groupRows.length = 0;
                        response.data.copyAssessmentResponse.forEach((flow, index) => {
                            let row = {
                                selected: true,
                                columnId: index,
                                processGroupId: that.state.assessmentNode.groupId,
                                sourceStudyId: flow.sourceStudyId,
                                sourceGroupId: flow.sourceGroupId,
                                sourceFlowId: flow.sourceFlowId,
                                processGroupName: flow.processGroupName,
                                processFlowName: flow.processFlowName,
                                status: flow.status,
                                processFlowId: flow.processFlowId,
                                processStudyId: that.state.assessmentNode.studyId,
                                sourceGroupName: flow.sourceGroupName,
                                sourceFlowName: flow.sourceFlowName,
                                closeEvent:that.props.closeEvent
                            };
                            groupRows.push(row);
                        });
                    }
                    let studyComparisonData = that.state.studyComparisonData;
                    let copyAssessmentResponse = [];
                    copyAssessmentResponse.push(...groupRows);
                    studyComparisonData.push({
                        sourceStudyId:response.data.selectedStudyId,
                        copyAssessmentResponse:copyAssessmentResponse
                    });
                    that.setState({ groupRows: groupRows, sourceStudy: sourceStudy, selectedSourceStudy: selectedSourceStudy,studyComparisonData:studyComparisonData });
                }
                if(response.data.message !== "" && response.data.message !== null){
                    that.props.setMessage(response.data.message, response.data.messageType);
                }
                if(parseInt(response.data.messageType, 10) === parseInt(MessageTypeConst.WARNING_MESSAGE,10)){
                    that.closeDialog();
                }
                $(".loader").hide();
            })).catch(error => {
                $(".loader").hide();
                that.props.setMessage("Error occurred while impact assessment.", MessageTypeConst.ERROR_MESSAGE);
            });
    }

    updateTreeViewBasedOnResponse = (targetProjectId, targetStudyId, targetSchemaName, response, addGroup) => {
        let that = this;
        let treeView = that.props.treeView.data;
        treeView.children.forEach((proj, projIndex) => {
            if (proj.projectId === targetProjectId) {
                treeView.children[projIndex].children.forEach((study, studyIndex) => {
                    if (study.study.studyId === targetStudyId) {
                        if (study.study.sourceTable === null || typeof (study.study.sourceTable) === "undefined") {
                            study.study.sourceTable = [];
                        }
                        if (study.study.metaTable === null || typeof (study.study.metaTable) === "undefined") {
                            study.study.metaTable = [];
                        }
                        if (study.study.studyDocuments === null || typeof (study.study.studyDocuments) === "undefined") {
                            study.study.studyDocuments = [];
                        }
                        treeView.children[projIndex].children[studyIndex].children.forEach((node, nodeIndex) => {
                            if (node.name === "ProcessFlows") {

                                node.children.forEach((classifierNode) => {

                                if(addGroup){
                                    response.data.processFlowGroup.forEach((newGroup) => {
                                        if (classifierNode.classifierId === newGroup.classifier) {
                                        var processFlows = [];
                                        if (typeof (newGroup.processFlow) !== "undefined"
                                            && newGroup.processFlow !== null
                                            && newGroup.processFlow.length > 0) {
                                            newGroup.processFlow.forEach((updatedFlow) => {
                                                processFlows.push(manageProcessInTreeView(Object.assign({}, updatedFlow, { selected: false }), targetProjectId, targetSchemaName, study.study.studyName));
                                            });
                                        }
                                        classifierNode.children.push(manageProceeFlowGroupInTreeView(newGroup, processFlows, study.study));

                                        }
                                    });
                                }else{
                                    response.data.processFlowGroup.forEach((updatedGroup) => {
                                        classifierNode.children.forEach((group, groupIndex) => {
                                            if(group.processGroupId === updatedGroup.processGroupId){
                                                if(typeof(updatedGroup.processFlow) !== "undefined"
                                                    && updatedGroup.processFlow !== null
                                                    && updatedGroup.processFlow.length > 0){
                                                        updatedGroup.processFlow.forEach(updatedFlow => {
                                                            let selectedFlow = classifierNode.children[groupIndex].children.filter(flow => flow.process.processId === updatedFlow.processId);
                                                            if(selectedFlow === null || selectedFlow.length <= 0 ){
                                                                classifierNode.children[groupIndex].children.push(manageProcessInTreeView(Object.assign({}, updatedFlow, { selected: false }), targetProjectId, targetSchemaName, study.study.studyName));
                                                            }
                                                        });
                                                    }
                                            }
                                        })
                                    });
                                }
                                });
                            }
                            else if (node.name === "Source Table" &&
                                response.data.sourceTables !== null && typeof (response.data.sourceTables) !== 'undefined'
                                && response.data.sourceTables.length > 0) {
                                response.data.sourceTables.forEach((srcTable) => {
                                    let newImportTable = Object.assign({
                                    name: srcTable.srcTableName, projectId: targetProjectId,
                                        studyId: study.study.studyId, cssClassName: 'manage-study-edit-source-table', schemaName: study.study.schemaName,
                                        tableType: 0, tag: 'open-data-viewer', tableName: srcTable.tableName
                                    });
                                    treeView.children[projIndex].children[studyIndex].children[nodeIndex].children.push(newImportTable);
                                    let newTable = Object.assign({
                                    srcTableName: srcTable.srcTableName,
                                        tableId: srcTable.tableId, tableName: srcTable.tableName, tableVersion: srcTable.tableVersion,
                                        versionLimit: srcTable.versionLimit, importLog: '', tableType: 0, timestamp: srcTable.timestamp
                                    });
                                    let sourceTable = study.study.sourceTable.filter(table => table.srcTableName !== srcTable.srcTableName);
                                    sourceTable.splice(sourceTable.length, 0, newTable);
                                    study.study.sourceTable.splice(0);
                                    study.study.sourceTable.splice(0, 0, ...sourceTable);
                                    that.props.addImportTable(newImportTable);
                                });
                            }
                            else if (node.name === "Meta Table" &&
                                response.data.metaTables !== null && typeof (response.data.metaTables) !== 'undefined'
                                && response.data.metaTables.length > 0) {
                                response.data.metaTables.forEach((metaDataTable) => {
                                    let newImportTable = Object.assign({
                                    name: metaDataTable.srcTableName, projectId: targetProjectId,
                                        studyId: study.study.studyId, cssClassName: 'manage-study-edit-meta-table', schemaName: study.study.schemaName, tableType: 1,
                                        tag: 'open-data-viewer', tableName: metaDataTable.tableName
                                    });
                                    treeView.children[projIndex].children[studyIndex].children[nodeIndex].children.push(newImportTable);
                                    let metaTable = study.study.metaTable.filter(table => table.srcTableName !== metaDataTable.srcTableName);
                                    let newTable = Object.assign({
                                    srcTableName: metaDataTable.srcTableName,
                                        tableId: metaDataTable.tableId, tableName: metaDataTable.tableName, tableVersion: metaDataTable.tableVersion,
                                        versionLimit: metaDataTable.versionLimit, importLog: '', tableType: 1, timestamp: metaDataTable.timestamp
                                    });
                                    metaTable.splice(metaTable.length, 0, newTable);
                                    study.study.metaTable.splice(0);
                                    study.study.metaTable.splice(0, 0, ...metaTable);
                                    that.props.addImportTable(newImportTable);
                                });
                            }
                            else if (node.name === "Documents" &&
                                response.data.documents !== null && typeof (response.data.documents) !== 'undefined'
                                && response.data.documents.length > 0) {
                                response.data.documents.forEach((document) => {
                                    let newDoc = Object.assign({
                                    name: document.filename, projectId: targetProjectId,
                                        studyId: study.study.studyId, cssClassName: 'manage-study-edit-documents',
                                        fileId: document.fileId, path: document.filepath,
                                        schemaName: study.study.schemaName, tableType: 2
                                    });
                                    if (newDoc !== null) {
                                        treeView.children[projIndex].children[studyIndex].children[nodeIndex].children.push(newDoc);
                                        let tableList = treeView.children[projIndex].children[studyIndex].children[nodeIndex].children.slice();
                                        let importTableIndex = tableList.length;
                                        treeView.children[projIndex].children[studyIndex].children[nodeIndex].children.forEach((table, index) => {
                                            if (table.name === document.filename) {
                                                importTableIndex = index;
                                            }
                                        });
                                        tableList = tableList.filter(table => table.name !== document.filename);
                                        tableList.splice(importTableIndex, 0, newDoc);
                                        treeView.children[projIndex].children[studyIndex].children[nodeIndex].toggled = true;
                                        treeView.children[projIndex].children[studyIndex].children[nodeIndex].children.splice(0);
                                        treeView.children[projIndex].children[studyIndex].children[nodeIndex].children.splice(0, 0, ...tableList);
                                        if (newDoc.tableType === 2) {
                                            let documents = study.study.studyDocuments.filter(table => table.name !== document.filename);
                                            ;
                                            documents.splice(documents.length, 0, document);
                                            study.study.studyDocuments.splice(0);
                                            study.study.studyDocuments.splice(0, 0, ...documents);
                                        }
                                    }
                                });
                            }
                        });
                    }
                });
            }
        });
        that.props.setTreeViewData(treeView);
    }

    closeReCopyDialog = () =>{
        this.setState({showReCopyDialog:false});
    }

    openReCopyDialog = () =>{
        let newSourceFlows =  this.state.groupRows.filter(row=>row.selected && row.status === CopyAssessmentStatus.FLOW_NEWLY_ADDED);
        if(newSourceFlows.length > 0){
            let reCopyFlowRows = this.state.reCopyFlowRows;
            reCopyFlowRows.length = 0;
            newSourceFlows.forEach((item)=>{
                reCopyFlowRows.push({selected:true,sourceFlowId:item.sourceFlowId ,sourceFlowName:item.sourceFlowName,targetFlowName:item.sourceFlowName});
            });
            this.setState({showReCopyDialog:true, reCopyFlowRows: reCopyFlowRows});
        }else{
            ConfirmBox.alert("No new source process flow found or selected to copy.")
        }
    }

    handleCopyInputTableChange = (event) => {
        let copyInputTable = true;
        switch(event.target.id){
            case "optImportTrue":
            copyInputTable = true;
            break;
            case "optImportFalse":
            copyInputTable = false;
            break;
            default:
            break;
        }
        this.setState({copyInputTable:copyInputTable});
    }

    validateFlowCopy = () => {
        var that = this;
        let isFlowValid  = true;
        let assessmentNode = this.state.assessmentNode;
        if(typeof(assessmentNode) === "undefined" || assessmentNode === null){
            return false;
        }
        let treeView = that.props.treeView.data;
        let selectedProject = treeView.children.filter(proj => proj.projectId === assessmentNode.projectId);
        if(selectedProject.length > 0){
            let selectedStudy = selectedProject[0].children.filter(study => study.study.studyId === assessmentNode.studyId);
            if(selectedStudy.length > 0){
                let rootprocessFlowNode = selectedStudy[0].children.filter(node => node.name === "ProcessFlows");
                if(rootprocessFlowNode.length > 0){
                    let selectedGroup = null;
                    rootprocessFlowNode[0].children.forEach((classifier) => {
                        selectedGroup =  classifier.children.filter( grp =>
                            grp.processGroupId === assessmentNode.groupId);
                            if(selectedGroup !== null && selectedGroup.length > 0){
                                let flowName = '';
                                that.state.reCopyFlowRows.forEach((row)=>{
                                    let flowwithTargetName = selectedGroup[0].children.filter(flow => flow.name.toLowerCase() === row.targetFlowName.toLowerCase());
                                    if(flowwithTargetName.length > 0){
                                        flowName = flowName + `'` + row.targetFlowName +`', `;
                                    }
                                });
                                if(flowName !== ''){
                                    flowName = flowName.trim().substr(0, flowName.trim().length -1 );
                                    that.props.setMessage(`Process Flow(s) ${flowName} already exists in selected target group. Please specify new process flow name.`, MessageTypeConst.WARNING_MESSAGE)
                                    isFlowValid = false;
                                    return false;

                                }
                            }
                    })
                }
            }
        }
        return isFlowValid;

    }

    executeFlowCopy = () => {
        if(!this.validateFlowCopy()){
            return;
        }
        $(".loader").show();
        let that = this;
        let requestObj = [];
        this.state.reCopyFlowRows.forEach((row)=>{
            let selectedRow = that.state.groupRows.filter(grpRow=> grpRow.selected && grpRow.status === CopyAssessmentStatus.FLOW_NEWLY_ADDED &&
                grpRow.sourceFlowId === row.sourceFlowId);
            if(selectedRow.length > 0){
                let request = {
                    sourceProjectId : 0,
                    sourceStudyId : selectedRow[0].sourceStudyId,
                    sourceGroupId : selectedRow[0].sourceGroupId,
                    sourceFlowId : selectedRow[0].sourceFlowId,
                    targetProjectId : that.state.assessmentNode.projectId,
                    targetStudyId : that.state.assessmentNode.studyId,
                    targetGroupId : that.state.assessmentNode.groupId,
                    targetFlowId : 0,
                    copyType :COPY_TYPE.COPY_FLOW,
                    targetNewName:row.targetFlowName,
                    groupNames:[],
                    sourceSchemaName:that.state.selectedSourceStudy.sourceSchemaName,
                    targetSchemaName:that.state.assessmentNode.schemaName,
                    processSyncFlag: 0,
                    copyTables:that.state.copyInputTable,
                }
                requestObj.push(request);
            }

        });
        axios.all([restClient(POST_PARAM_BODY, 'recopy', {data : requestObj}, {'Tenant-ID': this.state.assessmentNode.schemaName})])
        .then(axios.spread(function (response)  {
            if (typeof(response.data) !== 'undefined' && response.data.message != null && response.data.messageType !==  MessageTypeConst.SUCCESS_MESSAGE) {
                that.props.setMessage(response.data.message, response.data.messageType);
            }
            else if (typeof(response.data) !== 'undefined' && response.data !== null && typeof(response.data) !== 'undefined'
            && response.data.processFlowGroup !== null && typeof(response.data.processFlowGroup) !== 'undefined' && response.data.processFlowGroup.length > 0) {
                that.updateTreeViewBasedOnResponse(that.state.assessmentNode.projectId, that.state.assessmentNode.studyId, that.state.assessmentNode.schemaName, response, false);
            }
            that.closeDialog();
            $(".loader").hide();
        })).catch(error => {
            $(".loader").hide();
            that.props.setMessage("Error occurred while copying flow.", MessageTypeConst.ERROR_MESSAGE);
        });

    }

    render(){
        return(
            <div className="data-properties group">
              <Dialog className={this.className} title={this.state.title} width="89%" height="auto" modal={true} onClose={this.closeDialog} buttons={[<Button primary={true} onClick={this.openReCopyDialog} key="btn-newly-added"> Copy Newly Added Flow(s)</Button>,<Button onClick={this.closeDialog} key="btn-cancel"> Cancel</Button>]}>
                  <div className="data-properties" >
                    <div className="m-t-10 m-b-10 richgrid-height-200">
                      <label className="am-form-field row">
                        <span className="m-l-t-15-5">Source Study:</span>
                        <DropDownList className="width-500" data={this.state.sourceStudy}  textField={'sourceStudyName'} dataItemKey={'sourceSchemaName'} value={this.state.selectedSourceStudy}  onChange={this.handleSourceStudyChange}/>
                      </label>
                      <RichGridTable id={"copy-assessment-grid-table"} rows={this.state.groupRows} selectedField="selected" columnProperty={this.state.groupColumnProperty} filterable={false}/>
                    </div>
                  </div>
              </Dialog>
              {
                    this.state.showReCopyDialog &&
                        <div>
                        <Dialog className={this.className} title={"Copy New Process Flows"} width="calc(100% - 10px)" height="calc(100vh - 20px)" onClose={this.closeReCopyDialog} buttons={[<Button primary={true} onClick={this.executeFlowCopy} key="btn-copy"> Copy</Button>,<Button onClick={this.closeReCopyDialog} key="btn-cancel"> Cancel</Button>]}>
                                <div className="left-align" >
                                <fieldset>
                                <div className="field_group m-t-10 m-b-10">
                                    <legend>While copying</legend>
                                    <div>
                                        <label><input type="radio" name="optImport" value="Source" onChange={this.handleCopyInputTableChange}
                                        checked={this.state.copyInputTable} id="optImportTrue"/> Copy input table/document if not exists</label>
                                    </div>
                                    <div>
                                        <label><input type="radio" name="optImport" value="Source" onChange={this.handleCopyInputTableChange}
                                        checked={!this.state.copyInputTable} id="optImportFalse"/> Skip copying input table/document if not exists (child blocks will be in Invalid state)</label>
                                    </div>
                                </div>
                                </fieldset>

                                <p>
                                    <b>Note: </b> While copying LINK blocks, will check compare source block name from which block is created. If block name not found, then LINK block will be skipped and all its child blocks will be in Invalid state.
                                </p>

                                <fieldset>
                                <div className="field_group m-t-10 m-b-10 richgrid-height-300">
                                    <legend>Copying Flow As</legend>
                                    <div>
                                <RichGridTable id={"flow-compare-grid-table"} rows={this.state.reCopyFlowRows} columnProperty={this.state.reCopyFlowColumnProperty} />
                                </div></div></fieldset>


                                </div>
                        </Dialog>

                        </div>
                    }
            </div>);
    }


}

const mapStateToProps = createSelector(
    state => state.treeView,
    state => state.project.projectList,
    (treeView, projectList) => ({
        treeView  ,
        projectList  })
  );

  const mapActionsToProps = {
    setMessage: setMessage,
    setTreeViewData: setTreeViewData,
    addImportTable: addImportTable,
    addStudy: addStudy,
    updateProjectList: updateProjectList
  }

  Dialog.propTypes = {
      height: PropTypes.oneOfType([
          PropTypes.string
      ]),
      width: PropTypes.oneOfType([
          PropTypes.string
      ])
  }
  export default connect(mapStateToProps, mapActionsToProps)(GroupAssessment);
