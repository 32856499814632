import React from 'react';
import { AccordionItem, AccordionHeader, AccordionBody } from 'reactstrap';
import classnames from 'classnames';

export class PanelBarItem extends React.Component {
  render() {
        return (
          React.createElement(AccordionItem, {className: "am-panelbar-item"},
            React.createElement(AccordionHeader, {targetId: this.props.id},
              (typeof(this.props.iconClass) !== 'undefined' ? (
                React.createElement('span', {className: this.props.iconClass + ' m-r-5', role: "presentation"}, null)
              ) : null),
              this.props.title),
            React.createElement(AccordionBody, {accordionId: this.props.id}, this.props.children)
          )
        );
    }
}
