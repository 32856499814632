import React from 'react';
import { ComboBox } from './StandardComboBox';

export const AutoCompleteRowHashCell = ({ tableManager, value, data, column, colIndex, rowIndex }) => {
    if (typeof(tableManager) === 'undefined') {
      return (null)
    } else {
      return (
          <div className='rgt-cell-inner' style={{alignItems: 'center'}}>
              <AutoCompleteRowHashCellClass dataItem={data} field={column.field} value={typeof(data[column.field]) !== 'undefined' ? data[column.field]: value} column={column} tableManager={tableManager}/>
          </div>
      )
    }
}

class AutoCompleteRowHashCellClass extends React.Component {
  handleChange(e) {
    this.props.dataItem[this.props.field] = e.target.value;
    this.props.tableManager.rowsApi.setRows(this.props.tableManager.rowsApi.rows)
  }
  render() {
      var data = [];
      for (var i=1; i<=100; i++) {
        data.push(`${i}`);;
      }

      return (
        <>
          <ComboBox className="width-full" onChange={this.handleChange.bind(this)} data={data} value={this.props.dataItem[this.props.field]}/>
        </>
      );
    }
}
