class BlockType {
    constructor(){
        this.TABLE = 1;
        this.FILE = 2;
        this.GROUP = 3;
        this.TIMELINE = 4;
        this.MAP_ANNOTATE = 5;

    }
}

export default (new BlockType());
