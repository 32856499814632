import React from 'react';

export const ReportingOpsCheckboxCell = ({ tableManager, value, data, column, colIndex, rowIndex }) => {
    if (typeof(tableManager) === 'undefined') {
      return (null)
    } else {
      return (
          <div className='rgt-cell-inner' style={{display: 'flex', alignItems: 'center', overflow: 'hidden'}}>
              <ReportingOpsCheckboxCellClass dataItem={data} field={column.field} value={typeof(data[column.field]) !== 'undefined' ? data[column.field]: value} tableManager={tableManager}/>
          </div>
      )
    }
}

class ReportingOpsCheckboxCellClass extends React.Component {
    getGlobalValue(controlIndex){
        let controlValue = '';
        global.processBlockControlValues.forEach((control, index) => {
          if (control.controlId === controlIndex) {
            controlValue = global.processBlockControlValues[index].controlValue;

          }
        });
        return controlValue;
      }

      saveGlobalValue(controlIndex, value){
        global.processBlockControlValues.forEach((control, index) => {
          if (control.controlId === controlIndex) {
            global.processBlockControlValues[index].controlValue = value;
          }
        });
      }
    handleChange(e) {
        this.props.dataItem[this.props.field] = e.target.checked;
        this.props.tableManager.rowsApi.setRows(this.props.tableManager.rowsApi.rows)

        if(!e.target.checked){
            if(this.getGlobalValue(86) !== ""){
                let columnData = JSON.parse(this.getGlobalValue(86));
                //removing column data from Column Grid on column uncheck event
                let newcolumnData = columnData.filter(item => item.column !== this.props.dataItem['column']);
                this.saveGlobalValue(86, JSON.stringify(newcolumnData));
            }
        }  else{
            let column = this.props.dataItem;
            //adding column data in Column Grid on column checked
            if(this.getGlobalValue(86) !== ""){
                let columnData = JSON.parse(this.getGlobalValue(86));
                let columnCount = [];
                let length = columnData.length;
                if(typeof(length) === "undefined" || isNaN(length)){
                    length = 0;
                }
                let count = length + 1;
                columnData.forEach((item,index) => {
                    columnCount.push(columnData[index].column_hase);
                });
                count = Math.max(...columnCount) + 1;
                if(!isFinite(count) || isNaN(count)){
                    count = 1;
                }
                if (count === Number.POSITIVE_INFINITY || count === Number.NEGATIVE_INFINITY)
                {
                    count = 1;
                }
                columnData.push({...column,...{"column_hase": `${count}` , "column_var_sort": "", "column_title": "", "column_num": false, "column_total": "", "column_unique":"", "sort_variables":[]}});
                this.saveGlobalValue(86, JSON.stringify(columnData));
            } else{
                let columnDataNew = [];
                columnDataNew.push({...column,...{"column_hase": `${1}`, "column_var_sort": "", "column_title": "", "column_num": false, "column_total": "", "column_unique":"", "sort_variables":[]}});
                this.saveGlobalValue(86, JSON.stringify(columnDataNew));
            }

        }
    }
    render() {
        let checked = false;
        if (this.props.dataItem[this.props.field]) {
          checked = true;
        }
        return (
            <div className="text-center width-full">
               <input type="checkbox" className="am-checkbox" id={`checkbox-${this.props.field.replace(' ', '_')}-${this.props.dataItem['columnId']}`} onChange={this.handleChange.bind(this)}
               checked={checked}/>
               <label className="am-checkbox-label" htmlFor={`checkbox-${this.props.field.replace(' ', '_')}-${this.props.dataItem['columnId']}`}></label>
            </div>
        );
    }
}
