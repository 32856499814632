import React    from "react";
import cloneDeep from 'lodash/cloneDeep';
import { connect } from 'react-redux';
import { createSelector } from 'reselect';
import { PropTypes } from "prop-types";
import Dialog from 'react-dialog';
import { Button } from './../DataOperation/common/Button/StandardButton';
import { ProcessOptionGrid } from "./../DataOperation/common/gridOperation";
import { NewColumnSpanCell, GbyandObyCheckBoxCell } from './../DataOperation/common';
import { addTableSortColumn } from './../../../actions/actionAnnotate';

class SortColumns extends React.Component {
  constructor(props) {
      super(props);

      let rows = [];
      let keyColumnRows = [{"selected": false, "gby": "", "oby": "", "column": "", "label": "", "type": "", "length": "" }];

      let tableSortColumn = this.props.tableSortColumns.filter(tableSortColumn => tableSortColumn.tableName === props.node.tableName);

      if (tableSortColumn.length > 0) {
        keyColumnRows = tableSortColumn[0].rows
      }

      if (typeof(global.gridRef) !== 'undefined') {
        delete global.gridRef;
      }

      props.node.children.forEach((row, index) => {
        if (typeof(row.column.column_key) !== 'undefined' && row.column.column_key === 'Y') {
          if (tableSortColumn.length === 0) {
            keyColumnRows.push({
              columnId: index,
              selected: false,
              gby: false,
              oby: true,
              column: row.column.column_header,
              label: row.column.column_label,
              type: row.column.column_data_type,
              length: row.column.column_data_length
            })
          }
        } else if (keyColumnRows.filter(keyColumnRow => keyColumnRow.column === row.column.column_header).length === 0) {
          rows.push({
            columnId: index,
            selected: false,
            column: row.column.column_header,
            label: row.column.column_label,
            type: row.column.column_data_type,
            length: row.column.column_data_length
          })
        }
      })

      this.state = {
        node: props.node,
        closeEvent:props.onClose,
        tableList: [{
          id: props.node.tableName,
          resize: true,
          filterable: false,
          selectedField: "selected",
          dragAndDrop: true,
          rows: rows,
          columnProperty: [
              { field: "selected", show: false},
              { field: "column", title: "Variable", width: "150px", cell: NewColumnSpanCell},
              { field: "label", sortable:false, width:"auto"},
              { field: "type", sortable:false, width:"45px"},
              { field: "length", sortable:false, width:"70px"},
          ]
        }],
        dropTable:[{
          id: props.node.tableName + '-column-drop-grid',
          rows: keyColumnRows,
          resize: true,
          filterable: false,
          selectedField: "selected",
          dragAndDrop: true,
          columnProperty: [
              { field: "selected", show: false},
              { field: "gby", width:"35px", cell: GbyandObyCheckBoxCell, orderIndex: 1 },
              { field: "oby", width:"35px", cell: GbyandObyCheckBoxCell, orderIndex: 2 },
              { field: "column", title: "Variable", width: "150px", orderIndex: 3 },
              { field: "label", sortable:false, width:"auto", orderIndex: 4 },
              { field: "type", sortable:false, width:"45px", orderIndex: 5 },
              { field: "length", sortable:false, width:"70px", orderIndex: 6 },
          ]
        }]
      }

      global.processBlockControlValues = []
      this.handleSaveClick = this.handleSaveClick.bind(this);
  }

  handleSaveClick = () => {
    let sortKeyColumns = global.gridRef[1].props.data
    if (sortKeyColumns.length === 1) {
      sortKeyColumns = this.state.dropTable[0].rows;
    }

    this.props.addTableSortColumn({tableName: this.state.node.tableName, rows: sortKeyColumns.filter(row => row.column !== '')});
    this.state.closeEvent()
  }

  handleMoveDirection = (direction) => {
    let {tableList, dropTable} = this.state;

    if (direction === 'right') {
      const rows = cloneDeep(tableList[0].rows.filter(row => row.selected === true && (typeof(global.ColumnAlwaysNeedsToBeDisplay) === 'undefined' || (typeof(global.ColumnAlwaysNeedsToBeDisplay) !== 'undefined' && global.ColumnAlwaysNeedsToBeDisplay.indexOf(row.column) === -1))));
      rows.forEach(row => {
          row.selected = false;
          if (typeof(row.id) !== 'undefined') {
            delete row.id;
          }
          dropTable[0].rows.push(cloneDeep(row))
      })

      tableList[0].rows = tableList[0].rows.filter(row => !(row.selected === true && (typeof(global.ColumnAlwaysNeedsToBeDisplay) === 'undefined' || (typeof(global.ColumnAlwaysNeedsToBeDisplay) !== 'undefined' && global.ColumnAlwaysNeedsToBeDisplay.indexOf(row.column) === -1))))
    } else {
      const rows = cloneDeep(dropTable[0].rows.filter(row => row.selected === true && (typeof(global.ColumnAlwaysNeedsToBeDisplay) === 'undefined' || (typeof(global.ColumnAlwaysNeedsToBeDisplay) !== 'undefined' && global.ColumnAlwaysNeedsToBeDisplay.indexOf(row.column) === -1))));
      rows.forEach(row => {
          row.selected = false;
          if (typeof(row.id) !== 'undefined') {
            delete row.id;
          }
          tableList[0].rows.push(cloneDeep(row))
      })

      dropTable[0].rows = dropTable[0].rows.filter(row => !(row.selected === true && (typeof(global.ColumnAlwaysNeedsToBeDisplay) === 'undefined' || (typeof(global.ColumnAlwaysNeedsToBeDisplay) !== 'undefined' && global.ColumnAlwaysNeedsToBeDisplay.indexOf(row.column) === -1))))
    }

    tableList[0].rows.sort((a,b) => ('' + a.column).localeCompare(b.column))
    dropTable[0].rows.sort((a,b) => ('' + a.column).localeCompare(b.column))

    tableList.map(table => {
      table.rows.filter(row => row.selected === true && (typeof(global.ColumnAlwaysNeedsToBeDisplay) === 'undefined' || (typeof(global.ColumnAlwaysNeedsToBeDisplay) !== 'undefined' && global.ColumnAlwaysNeedsToBeDisplay.indexOf(row.column) === -1))).map(row => {
        row.selected = false
        return row
      })

      table.rows.filter(row => typeof(row.gby) !== 'undefined' || typeof(row.oby) !== 'undefined').map(row => {
        delete row.gby;
        delete row.oby;
        return row
      })
      return table
    })
    dropTable.map(table => {
      table.rows.filter(row => row.selected === true && (typeof(global.ColumnAlwaysNeedsToBeDisplay) === 'undefined' || (typeof(global.ColumnAlwaysNeedsToBeDisplay) !== 'undefined' && global.ColumnAlwaysNeedsToBeDisplay.indexOf(row.column) === -1))).map(row => {
        row.selected = false
        return row
      })

      table.rows.filter(row => typeof(row.gby) === 'undefined' || typeof(row.oby) === 'undefined').map(row => {
        row.gby = false;
        row.oby = true;
        return row
      })
      return table
    })

    this.setState({tableList: tableList, dropTable: dropTable}, () => {
      if (document.getElementsByClassName('pivot-grid row-clickable').length > 0) {
        document.querySelectorAll('.pivot-grid.row-clickable .rgt-cell.row-black').forEach(item => {
          if (item.classList.contains('row-black') === true) {
            item.classList.remove('row-black')
          }
        })
      }
    })
  }

  render(){
      return(
        <div className="annotate-contextmenu-sortColumns-dialog">
          <Dialog className="expression" title={`Sort - Variable - ${this.state.node.name}`} width="calc(100% - 10px)" height="calc(100vh - 20px)" modal={true} onClose={this.state.closeEvent} buttons={[<Button primary={true} onClick={this.handleSaveClick} key="btn-save"> Save</Button>,<Button onClick={this.state.closeEvent} key="btn-cancel"> Cancel</Button>]}>
            <div className="row pivot-grid row-clickable">
              <div className="col-6">
                <div className="field_group">
                  {/* <div>
                    <label className="am-form-field">
                      <span>Find:</span>
                      <input className="am-textbox m_l_5" placeholder="Search data" />
                    </label>
                  </div> */}
                  <h4 className="title">Table Variable List</h4>
                  <div className="height_fixed_step1 full-width-table">
                    <ProcessOptionGrid tableList={this.state.tableList} />
                  </div>
                </div>
              </div>
              <div className="col-6">
                <div className="field_group">
                  {/* <div>
                    <label className="am-form-field">
                      <span>Find:</span>
                      <input className="am-textbox m_l_5" placeholder="Search data" />
                    </label>
                  </div> */}
                  <h4 className="title">Sort Key Variable List</h4>
                  <div className="height_fixed_step1 full-width-table">
                    <ProcessOptionGrid tableList={this.state.dropTable} />
                  </div>
                </div>
              </div>
              <div className="move-action-button">
                <Button primary={true} onClick={(event) => this.handleMoveDirection('right')}><i className="fa fa-arrow-right"></i></Button>
                <Button primary={true} onClick={(event) => this.handleMoveDirection('left')} className="m-t-10"><i className="fa fa-arrow-left"></i></Button>
              </div>
            </div>
          </Dialog>
        </div>
      );
  }
}

const mapStateToProps = createSelector(
    state => state.annotate.tableSortColumns,
    (tableSortColumns) => ({
        tableSortColumns
    })
);
const mapActionsToProps = {
  addTableSortColumn: addTableSortColumn
}

Dialog.propTypes = {
    height: PropTypes.oneOfType([
        PropTypes.string
    ]),
    width: PropTypes.oneOfType([
        PropTypes.string
    ])
}
export default connect(mapStateToProps, mapActionsToProps)(SortColumns);
