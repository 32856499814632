import React from 'react';
import { Button as RSButton } from 'reactstrap';
import cloneDeep from 'lodash/cloneDeep';

export class Button extends React.Component {
  render() {
    let props = cloneDeep(this.props);

    props = {
      ...props,
      className: 'am-button'+ (typeof(props.className) !== 'undefined' && props.className !== '' ? ' '+ props.className: '')
    }
    if (typeof(props.iconClass) !== 'undefined') {
      delete props.iconClass
    }

    if (typeof(props.icon) !== 'undefined') {
      delete props.icon
    }

    if (typeof(props.primary) !== 'undefined') {

      if (props.primary === true) {
        props.className += ' am-primary'
      }
      delete props.primary
    }
    return (
      React.createElement(RSButton, {...props}, (typeof(this.props.iconClass) !== 'undefined' && this.props.iconClass !== '') ? (
        React.createElement('span', {role: "presentation", className: this.props.iconClass})
      )
      :(null),
      (typeof(this.props.icon) !== 'undefined' && this.props.icon !== '') ? (
        React.createElement('span', {role: "presentation", className: 'am-icon '+ this.props.icon})
      )
      :(null),
        (typeof(this.props.children) !== 'undefined' && this.props.children !== '') ? (this.props.children) :(null)
      )
    )
  }
}
