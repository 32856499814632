import "./FileManager.css";
import React from "react";
import KeyboardEventHandler from 'react-keyboard-event-handler';
import FileBrowser, {Icons} from 'react-keyed-file-browser';

function template() {
  return (
    <div className="file-manager">
      {this.state.isGetFiles === false &&
        <div className="loading">Loading....</div>
      }
      {this.state.isGetFiles === true && this.state.files.length === 0 &&
        <h2 class="no-files-from-server align-center"> No Files In Server</h2>
      }
      {this.state.isGetFiles === true && this.state.files.length > 0 &&
        <KeyboardEventHandler handleKeys={['all']} onKeyEvent={(key, e) => this.handleKeyEvent(key, e)} >
          <FileBrowser
              files={this.state.files}
              icons={Icons.FontAwesome(4)}
              onSelectFile={this.handleSelectFile}
              onSelectFolder={this.handleSelectFolder}
              fileRenderer={this.handleFileRenderer}
            />
        </KeyboardEventHandler>
      }
    </div>
  )
}

export default template;
