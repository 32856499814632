import React from 'react';
import { DropDownList } from './StandardDropDownList';

export const SourceInputTableDropDownCell = ({ tableManager, value, data, column, colIndex, rowIndex }) => {
    if (typeof(tableManager) === 'undefined') {
      return (null)
    } else {
      return (
          <div className='rgt-cell-inner' style={{display: 'flex', alignItems: 'center', overflow: 'hidden'}}>
              <SourceInputTableDropDownCellClass dataItem={data} field={column.field} value={typeof(data[column.field]) !== 'undefined' ? data[column.field]: value} column={column} tableManager={tableManager}/>
          </div>
      )
    }
}

class SourceInputTableDropDownCellClass extends React.Component {
  handleChange(e) {
    this.props.dataItem[this.props.field] = e.target.value;
    this.props.tableManager.rowsApi.setRows(this.props.tableManager.rowsApi.rows)
  }

  render() {
      return (
        <>
            <DropDownList className="width-full" onChange={this.handleChange.bind(this)} data={this.props.dataItem['sourceTableList']} value={this.props.dataItem[this.props.field]}/>
        </>
      );
    }
}
