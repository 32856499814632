export const SHOW_ALL = 'SHOW_ALL';
export const SET_VISIBILITY_FILTER = 'SET_VISIBILITY_FILTER'

export const ADD_STUDY = 'study:addStudy';
export const REMOVE_STUDY = 'study:removeStudy';
export const REMOVE_STUDY_BY_PROJECT_ID = 'study:removeStudyByProjectId';
export const FETCH_STUDY = 'study:fetchStudy';
export const SHOW_SELECTED_STUDY = 'study:selectedStudy';
export const ADD_MULTIPLE_STUDY = 'study:addMultipleStudy';
export const SET_STUDY_MANAGER = 'study:setStudyManager';
export const SET_STUDY_DESCRIPTION = 'study:setStudyDescription';

export const ADD_PROJECT = 'project:addProject';
export const REMOVE_PROJECT = 'project:removeProject';
export const FETCH_PROJECT = 'project:fetchProject';
export const API_CONNECT_PROJECT = 'project:apiConnectProject';
export const UPDATE_PROJECT_LIST = 'project:updateProjectList';

export const BUILD_TREEVIEW_STATUS = 'treeView:build';
export const SET_TREEVIEW_DATA = 'treeView:setData';
export const REDISPLAY_TREEVIEW_STATUS = 'treeView:reDisplayTree';
export const TREEVIEW_REFRESH_STUDY_NODE = 'treeView:refreshStudyNode';

export const ADD_PROCESSFLOW = 'processFlow:addProcessFlow';
export const GET_PROCESSFLOW = 'processFlow:getProcessFlow';
export const REMOVE_PROCESSFLOW = 'processFlow:removeProcessFlow';
export const SET_TENANT_ID = 'processFlow:setTenantId';
export const REMOVE_PROCESSFLOW_BLOCK_BY_TABINDEX = 'processFlow:removeProcessflowBlockByTabIndex';
export const ADD_PROCESSFLOW_BLOCK_BY_TABINDEX = 'processFlow:addProcessflowBlockByTabIndex';
export const UPDATE_PROCESSFLOW_BLOCK_BY_TABINDEX = 'processFlow:updateProcessFlowBlockByTabIndex';
export const UPDATE_PROCESSFLOW_BLOCK_RENDER_STATUS_BY_TABINDEX = 'processFlow:updateProcessFlowBlockRenderStatusByTabIndex';
export const SET_PROCESSFLOW_ACTION = 'processFlow:processFlowAction';
export const SET_PROCESSFLOW_TABLE = 'processFlow:processFlowTable';
export const SET_PROCESSFLOW_TABLE_ID = 'processFlow:processFlowTableId';
export const SET_QUICK_CONDITION = 'processFlow:quickcondition';
export const SET_SUB_MENU_COLUMNS = 'processFlow:submenucolumns';
export const REMOVE_PROCESSFLOW_BLOCKLINK_BY_TABINDEX = 'processFlow:removeProcessflowBlockLinkByTabIndex';
export const ADD_PROCESSFLOW_BLOCKLINK_BY_TABINDEX = 'processFlow:addProcessflowBlockLinkByTabIndex';
export const ADD_IMPORT_TABLE = 'processFlow:addImportTable';
export const ADD_MULTIPLE_PROCESSFLOW_BLOCKLINK_BY_TABINDEX = 'processFlow:addMultipleProcessflowBlockLinkByTabIndex';
export const ADD_PROCESSFLOW_TITLE = 'processFlow:addProcessFlowTitle';
export const SET_ACTIVE_PROCESSFLOW = 'processFlow:setActiveProcessFlow';
export const SET_ALL_PROCESSFLOW_RENDER_STATUS = 'processFlow:setAllProcessFlowRenderStatus';
export const SELECTITEMS_FOR_COPY_PASTE = 'processFlow:selectItemsForCopyPaste';
export const UPDATE_PROCESSFLOW_BLOCK_BY_SCHEMA_NAME = 'processFlow:updateProcessFlowBlockBySchemaName';
export const UPDATE_PROCESSFLOW_SYNC_FLAG_BY_TABINDEX = 'processFlow:updateProcessFlowSyncFlagByTabIndex';
export const REMOVE_IMPORT_TABLE = 'processFlow:removeImportTable';
export const REFRESH_PROCESS_FLOW='processFlow:refreshProcessFlow';
export const TOGGLE_REFRESH_TOKEN = 'processFlow:toggleRefreshToken';
export const REMOVE_PROCESSFLOW_BY_PROJECT_ID = 'processFlow:removeProcessFlowByProjectId';
export const REMOVE_PROCESSFLOW_BY_STUDY_ID = 'processFlow:removeProcessFlowByStudyId';
export const REMOVE_PROCESSFLOW_BY_PROCESS_GROUP_ID = 'processFlow:removeProcessFlowByProcessGroupId';
export const UPDATE_PROCESSFLOW_CHECKED_OUT_BY_TABINDEX = 'processFlow:updateProcessFlowCheckedOutStatusByTabIndex';
export const SET_TOKEN = 'login:setToken';
export const SET_STUDY_ID = 'processFlow:setStudyId';
export const SET_PROJECT_ID = 'processFlow:setProjectId';
export const SET_JUMP_TO_BLOCK_ID_PROCESS_ID = 'processFlow:setJumpToBlockIdProcessId';
export const REMOVE_PROCESSFLOW_NOTE_BY_TABINDEX = 'processFlow:removeProcessFlowNoteByTabIndex';
export const ADD_PROCESSFLOW_NOTE_BY_TABINDEX = 'processFlow:addProcessFlowNoteByTabIndex';
export const UPDATE_PROCESSFLOW_NOTE_BY_TABINDEX = 'processFlow:updateProcessFlowNoteByTabIndex';
export const UPDATE_PROCESSFLOW_NOTE_BY_SCHEMA_NAME = 'processFlow:updateProcessFlowNoteBySchemaName';
export const REMOVE_ALL_PROCESSFLOW = 'processFlow:removeALLProcessFlow';

export const REFRESH_DATA_STATUS = 'dataOperation:setRefreshDataStatus';
export const CONSTRUCTIVE_OPERATION_STATUS = 'dataOperation:setConstructiveOperationStatus';
export const UPDATE_REFERENCE_POINS = 'dataOperation:setReferencePoints';
export const ENABLE_EXECUTE_BUTTON = 'dataOperation:enableExecuteButton';
export const SET_PROCESS_ENGINELIST = 'dataOperation:setProcessEngineList';
export const SET_PROCESS_ENGINE = 'dataOperation:setProcessEngine';

export const SET_SUCCESS_MESSAGE = 'notification:setSuccessMessage';
export const SET_WARNING_MESSAGE = 'notification:setWarningMessage';
export const SET_ERROR_MESSAGE = 'notification:setErrorMessage';
export const SET_INFORMATION_MESSAGE = 'notification:setInformationMessage';
export const SET_MESSAGE = 'notification:setMessage';
export const SET_MESSAGE_ALONG_WITH_VISIBILITY_DURATION = 'notification:setMessageAlongWithVisibilityDuration';
export const RESET_MESSAGE = 'notification:resetMessage';

export const ANNOTATE_MANAGE_COLUMN_MAPPED_LIST = 'annotate:manageColumnMappedList';
export const ANNOTATE_SET_COLUMN_MAPPING_TARGET_ID = 'annotate:setColumnMappingTargetId';
export const ANNOTATE_ADD_WHERE_CONDITION = 'annotate:addWhereCondition';
export const ANNOTATE_REMOVE_WHERE_CONDITION = 'annotate:removeWhereCondition';
export const ANNOTATE_ADD_HARDCODE = 'annotate:addHardcode';
export const ANNOTATE_REMOVE_HARDCODE = 'annotate:removeHardcode';
export const ANNOTATE_ADD_EXPRESSION = 'annotate:addExpression';
export const ANNOTATE_REMOVE_EXPRESSION = 'annotate:removeExpression';
export const ANNOTATE_REMOVE_MULTIPLE_EXPRESSION = 'annotate:removeMultipleExpression';
export const ANNOTATE_RESET_STATE = 'annotate:restState';
export const ANNOTATE_SET_TABLE_EXTENDEDLIST = 'annotate:setTableExtendedList';
export const ANNOTATE_MANAGE_MAPPINGGRID_EXPRESSION = 'annotate:manageMappingGridExpression';
export const ANNOTATE_ADD_MULTIPLE_COLUMN_MAPPED_LIST = 'annotate:addMultipleColumnMappedList';
export const ANNOTATE_RESET_BULK_COLUMN_MAPPED_LIST = 'annotate:resetBulkColumnMappedList';
export const ANNOTATE_SET_TABLE_TYPE_LIST = 'annotate:setTableTypeList';
export const ANNOTATE_ADD_RENAME_COLUMN = 'annotate:addRenameColumn';
export const ANNOTATE_REMOVE_RENAME_COLUMN = 'annotate:removeRenameColumn';
export const ANNOTATE_UPDATE_COLUMN_DATA = 'annotate:updateColumnData';
export const ANNOTATE_SET_HOLD_ANNOTATE_DATA = 'annotate:setHoldAnnotateData';
export const ANNOTATE_HOLD_ANNOTATE_DATA_TARGET_COLUMN_OPERATION_LIST_AND_JOB_LIST = 'annotate:setHoldAnnotateDataAndTargetColumnOperationListAndJobList';
export const ANNOTATE_SET_DEFAULT_TARGET_COLUMN_EXPRESSION = 'annotate:setDefaultTargetColumnExpression';
export const ANNOTATE_SET_TREE_ELEMENT = 'annotate:setTreeElement';
export const ANNOTATE_ADD_APPLY_PROCESS_FLOW = 'annotate:addApplyProcessFlow';
export const ANNOTATE_REMOVE_APPLY_PROCESS_FLOW = 'annotate:removeApplyProcessFlow';
export const ANNOTATE_ADD_MULTIPLE_RENAME_COLUMN = 'annotate:addMultipleRenameColumn';
export const ANNOTATE_ADD_MULTIPLE_HARDCODE_COLUMN = 'annotate:addMultipleHardcodeColumn';
export const ANNOTATE_ADD_TABLE_SORT_COLUMN = 'annotate:addTableSortColumn';

export const USER_PREFERENCE_FETCH_SELECTED_STUDY = 'userPreference:fetchSelectedStudy';
export const USER_PREFERENCE_SAVE_SELECTED_STUDY = 'userPreference:saveSelectedStudy';
export const USER_PREFERENCE_SET_TREE_PREPARED = 'userPreference:setTreePrepared';
export const ANNOTATE_UPDATE_TREE_ELEMENT_VIA_IMMULTABLE = 'annotate:updateTreeElementViaImmutable';
export const ANNOTATE_ADD_PIVOT_MATRIX_LIST = 'annotate:addPivotMatrixList';
export const ANNOTATE_MANAGE_HARDCODE_LIST = 'annotate:manageHardcodeList';
export const ANNOTATE_ADD_MULTIPLE_APPLY_PROCESS_FLOW = 'annotate:addMultipleApplyProcessFlow';
export const ANNOTATE_ADD_MULTIPLE_TABLE_SORT_COLUMN = 'annotate:addMultipleTableSortColumn';
export const ANNOTATE_MANAGE_TABLE_VERSION_CHANGE = 'annotate:manageTableVersionChange'
export const ANNOTATE_MANAGE_MULTIPLE_TARGET_TABLE_LIST = 'annotate:manageMultipleTargetTableList'
export const ANNOTATE_RESET_TARGET_COLUMN_LIST = 'annotate:restTargetColumnList';
export const ANNOTATE_SOURCE_TABLE_LIST_TYPE = 'annotate:setSourceTableListType';
export const ANNOTATE_TARGET_TABLE_LIST_TYPE = 'annotate:setTargetTableListType';
export const ANNOTATE_SET_HIGHLIGHTLIST = 'annotate:setHighlightList';
export const USER_PREFERENCE_ADD_USER_AND_GROUP = 'userPreference:addUserAndGroupList'
export const USER_PREFERENCE_MANAGE_USER_LIST = 'userPreference:manageUserList'
export const USER_PREFERENCE_MANAGE_GROUP_LIST = 'userPreference:manageGroupList'
export const USER_PREFERENCE_MANAGE_USER_GROUP_PERMISSION = 'userPreference:manageUserGroupPermission';
export const ANNOTATE_REMOVE_BLOCK_CLASSIFIER_DETAILS = 'annotate:removedBlockClassifierDetails';
export const ANNOTATE_MANAGE_MAPPING_SPECS_DETAIL = 'annotate:manageMappingSpecsDetail';
export const ANNOTATE_MANAGE_WHERE_MATRIX = 'annotate:manageWhereMatrix';
export const ANNOTATE_MANAGE_LAST_EXECUTE_ENGINE_VALUE = 'annotate:manageLastExecuteEngineValue';
export const ANNOTATE_MANAGE_APPLY_PROCESS_FLOW = 'annotate:manageApplyProcessFlow';
export const ANNOTATE_MANAGE_TARGET_TABLE_VALUE_IN_EXPRESSION = 'annotate:manageTargetTableValueInExpression';
export const ANNOTATE_MANAGE_ADDITIONAL_WHERE_CONDITION = 'annotate:manageAdditionalWhereCondition';
export const PROJECT_UPDATE_STUDY_ACTION = 'project:updateStudyAction';
export const ANNOTATE_MANAGE_DOCUMENT_NAME = 'annotate:manageDocumentName';
export const ANNOTATE_MANAGE_CUSTOM_UNMAP_VALUE_LIST = 'annotate:manageCustomUnmapValueList';
export const ANNOTATE_REFRESH_TLF_VIEWER = 'annotate:refreshTlfViewer';
