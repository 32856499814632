import React    from "react";
import template from "./Breadcrumb.jsx";

class Breadcrumb extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    return template.call(this);
  }
}

export default Breadcrumb;
