import React from "react";
import { ComboBox } from './../common/DropDown/StandardComboBox';
import GridMapper from '../../GridMapper';

function DecollapseTwoLayoutTemplate() {
  var leftDateColumns = [];
  var rightDateColumns = [];

  if (typeof(this.state.tableList[0]) !== 'undefined') {
      if (this.state.tableList[0].rows.length > 0) {
        this.state.tableList[0].rows.forEach((row) => {
          if(row.type === 'DATE' || row.type === 'DATETIME' || row.type === 'NUM') {
            leftDateColumns = [...leftDateColumns, row.column];
          }
        });
      }
  }

  if (typeof(this.state.tableList[1]) !== 'undefined') {
      if (this.state.tableList[1].rows.length > 0) {
        this.state.tableList[1].rows.forEach((row) => {
          if(row.type === 'DATE' || row.type === 'DATETIME' || row.type === 'NUM') {
            rightDateColumns = [...rightDateColumns, row.column];
          }
        });
      }
      //disabling selected column of right table
      if (this.state.tableList[1].columnProperty.length > 0) {
        this.state.tableList[1].columnProperty.forEach((column, index) => {
          if(column.field === 'selected') {
            this.state.tableList[1].columnProperty[index].show = false;
          }
        });
      }
  }

  let leftStartDateValue = this.getGlobalValue(4);
  let leftStopDateValue = this.getGlobalValue(5);
  let rightStartDateValue = this.getGlobalValue(43);
  let rightStopDateValue = this.getGlobalValue(44);
  this.updateGlobalColumnAlwaysDisplay(leftStartDateValue);
  this.updateGlobalColumnAlwaysDisplay(leftStopDateValue);
  this.updateGlobalColumnAlwaysDisplay(rightStartDateValue);
  this.updateGlobalColumnAlwaysDisplay( rightStopDateValue);
  this.updateTableRowAccoringToDisplayColumn(0, global.ColumnAlwaysNeedsToBeDisplay, []);
  this.updateTableRowAccoringToDisplayColumn(1, global.ColumnAlwaysNeedsToBeDisplay, []);

  var handleLeftStartColumnChange = (event) => {
    this.updateGlobalColumnAlwaysDisplay(event.target.value, leftStartDateValue);
    this.updateTableRowAccoringToDisplayColumn(0, global.ColumnAlwaysNeedsToBeDisplay, [leftStartDateValue]);
    this.saveGlobalValue(4,event.target.value);
    this.setState({tableList:this.state.tableList, gridKey: this.state.gridKey+1});
  }

  var handleLeftStopColumnChange = (event) => {
    this.updateGlobalColumnAlwaysDisplay(event.target.value, leftStopDateValue);
    this.updateTableRowAccoringToDisplayColumn(0, global.ColumnAlwaysNeedsToBeDisplay, [leftStopDateValue]);
    this.saveGlobalValue(5,event.target.value);
    this.setState({tableList:this.state.tableList, gridKey: this.state.gridKey+1});
  }

  var handleRightStartColumnChange = (event) => {
    this.updateGlobalColumnAlwaysDisplay(event.target.value, rightStartDateValue);
    this.updateTableRowAccoringToDisplayColumn(1, global.ColumnAlwaysNeedsToBeDisplay, [rightStartDateValue]);
    this.saveGlobalValue(43,event.target.value);
    this.setState({tableList:this.state.tableList, gridKey: this.state.gridKey+1});
  }

  var handleRightStopColumnChange = (event) => {
    this.updateGlobalColumnAlwaysDisplay(event.target.value, rightStopDateValue);
    this.updateTableRowAccoringToDisplayColumn(1, global.ColumnAlwaysNeedsToBeDisplay, [rightStopDateValue]);
    this.saveGlobalValue(44,event.target.value);
    this.setState({tableList:this.state.tableList, gridKey: this.state.gridKey+1});
  }

  return (
    <div className="process-option standard">
      <div className="field_group_main">
        <div className="container-fluid p_15">
          <div className="row">
            <div className="filter-area am-form-inline col-6 m-0">
              <fieldset>
                <div className="field_group small-width">
                  <legend>Left Start/Stop (Date/Num)</legend>
                  <div className="row">
                    <div className="col-6 p-r-0">
                      <div className="am-form-field">
                        <span>Start (Date/Num):</span>
                        <ComboBox className="m-0" data={leftDateColumns} onChange={handleLeftStartColumnChange} value={leftStartDateValue}/>
                      </div>
                    </div>
                    <div className="col-6 p-r-0 p-l-5">
                      <div className="am-form-field">
                        <span>Stop (Date/Num):</span>
                        <ComboBox className="m-0" data={leftDateColumns} onChange={handleLeftStopColumnChange} value={leftStopDateValue} />
                      </div>
                    </div>
                  </div>
                </div>
              </fieldset>
            </div>
            <div className="filter-area am-form-inline col-6 m-0 p-0">
              <fieldset>
                <div className="field_group small-width">
                  <legend>Right Start/Stop (Date/Num)</legend>
                  <div className="row">
                    <div className="col-6 p-r-0">
                      <div className="am-form-field">
                        <span>Start (Date/Num):</span>
                        <ComboBox className="m-0" data={rightDateColumns} onChange={handleRightStartColumnChange} value={rightStartDateValue}/>
                      </div>
                    </div>
                    <div className="col-6 p-r-0 p-l-5">
                      <div className="am-form-field">
                        <span>Stop (Date/Num):</span>
                        <ComboBox className="m-0" data={rightDateColumns} onChange={handleRightStopColumnChange} value={rightStopDateValue}/>
                      </div>
                    </div>
                  </div>
                </div>
              </fieldset>
            </div>
          </div>
        </div>
      </div>
      <div className="height_fixed_decollapse">
        <GridMapper {...this.state}/>
      </div>
    </div>
  );
};

export default DecollapseTwoLayoutTemplate;
