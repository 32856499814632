import React from 'react';
import axios from 'axios';
import { Button } from './StandardButton';
import { connect } from 'react-redux';
import { PropTypes } from 'prop-types';
import { createSelector } from 'reselect';
import $ from 'jquery';
import restClient from './../../../../../restClient';
import { GET } from './../../../../../restClient/types';
import { setMessage } from '../../../../../actions/actionNotification';
import MessageTypeConst from "./../../../../MessageTypeConst";
import CreateTask from './../../../TaskManagement/CreateTask';

export const TaskTitleButton = ({ tableManager, value, data, column, colIndex, rowIndex }) => {
    if (typeof(tableManager) === 'undefined') {
      return (null)
    } else {
      return (
          <div className='rgt-cell-inner' style={{display: 'flex', alignItems: 'center', overflow: 'hidden'}}>
              <TaskTitleButtonClass dataItem={data} field={column.field} value={typeof(data[column.field]) !== 'undefined' ? data[column.field]: value} column={column} tableManager={tableManager}/>
          </div>
      )
    }
}

class BaseTaskTitleButton extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
          visible: false,
          commentDescription: '',
          commentsList: props.dataItem.taskDetail.commentsList,
          triggerNode: ''
        };
        this.closeDialog = this.closeDialog.bind(this);
        this.openDialog = this.openDialog.bind(this);
      }

    fetchTaskDetail = (headers, params) => restClient(GET, 'task/'+params.taskId, params, headers);

    openDialog(event, table){
      let triggerNode = '';

      if (typeof(this.props.dataItem.taskDetail) !== 'undefined') {
        this.props.treeView.children.forEach(project => {
          project.children.filter(study => study.study.schemaName === this.props.tenantId).forEach(study => {
            if (this.props.dataItem.taskDetail.studyId > 0 && study.study.studyId === this.props.dataItem.taskDetail.studyId) {
              triggerNode = study
            }

            if (triggerNode === '') {
              study.children.filter(studyElement => studyElement.name === 'ProcessFlows').forEach(studyElement => {
                studyElement.children.forEach(classifier => {
                  classifier.children.forEach(processGroup => {
                    if (this.props.dataItem.taskDetail.processGroupId > 0 && processGroup.processGroupId === this.props.dataItem.taskDetail.processGroupId) {
                      triggerNode = processGroup
                    }

                    if (triggerNode === '' && this.props.dataItem.taskDetail.processId > 0) {
                      processGroup.children.filter(processFlow => processFlow.process.processId === this.props.dataItem.taskDetail.processId).forEach(processFlow => {
                        triggerNode = processFlow
                      })
                    }
                  })
                })
              })
            }
          })
        })

        if (this.props.dataItem.taskDetail.blockId > 0) {
          triggerNode = {blockId: this.props.dataItem.taskDetail.blockId, blockName: this.props.dataItem.taskDetail.blockName, blockProcessId: this.props.dataItem.taskDetail.blockProcessId, schemaName: this.props.tenantId}
        }
      }

      if (this.props.dataItem.taskDetail.commentsList.length === 0) {
        $(".loader").show();
        axios.all([this.fetchTaskDetail({'Tenant-ID': this.props.tenantId}, {taskId: this.props.dataItem.taskDetail.taskId})])
            .then(axios.spread((response) => {
              if (typeof(response.data) !== 'undefined') {
                if (response.data.commentsList.length > 0) {
                  this.props.dataItem.taskDetail.commentsList.push(...response.data.commentsList)
                }
                this.setState({visible: true,commentDescription: '', triggerNode: triggerNode});
              } else {
                this.props.setMessage("Failed to task comments.",MessageTypeConst.ERROR_MESSAGE)
              }
              $(".loader").hide();
            })).catch(error => {
                $(".loader").hide();
                this.props.setMessage("Failed to task comments.",MessageTypeConst.ERROR_MESSAGE)
            })
      } else {
        this.setState({visible: true,commentDescription: '', triggerNode: triggerNode});
      }
    }
    closeDialog(event, reloadTaskList = false) {
      this.setState({visible: false,commentDescription: ''}, () => {
        if (reloadTaskList === true) {
            this.props.dataItem.getTaskList()
        }
      });
    }

    render() {
          return (
              <>
                {!this.state.visible &&
                  <Button onClick={this.openDialog} className={'task-title'} >{this.props.dataItem[this.props.field]}</Button>
                }
                {this.state.visible &&
                  <CreateTask node={this.state.triggerNode} closeEvent={this.closeDialog} mode={'edit'} data={this.props.dataItem.taskDetail} />
                }
              </>
          );
      }
}

const mapStateToProps = createSelector(
    state => state.processFlow.tenantId,
    state => state.treeView.data,
    (tenantId, treeView) => ({
        tenantId,
        treeView
    })
  );

  const mapActionsToProps = {
    setMessage: setMessage,
  }

  const TaskTitleButtonClass = connect(mapStateToProps, mapActionsToProps)(BaseTaskTitleButton);
