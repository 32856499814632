import { SET_SUCCESS_MESSAGE, SET_WARNING_MESSAGE, SET_ERROR_MESSAGE, SET_INFORMATION_MESSAGE, SET_MESSAGE, SET_MESSAGE_ALONG_WITH_VISIBILITY_DURATION, RESET_MESSAGE } from './actionsTypes';

export const setSuccessMessage = message => ({
    type: SET_SUCCESS_MESSAGE,
    payload: message
})

export const setWarningMessage = message => ({
    type: SET_WARNING_MESSAGE,
    payload: message
})

export const setErrorMessage = message => ({
    type: SET_ERROR_MESSAGE,
    payload: message
})

export const setInformationMessage = message => ({
    type: SET_INFORMATION_MESSAGE,
    payload: message
})

export const setMessage = (message, messageType) => ({
    type: SET_MESSAGE,
    payload: {message:message,messageType:messageType}
})

export const setMessageAlongWithVisibilityDuration = (message, messageType, visibilityDuration) => ({
    type: SET_MESSAGE_ALONG_WITH_VISIBILITY_DURATION,
    payload: {message:message,messageType:messageType, visibilityDuration:visibilityDuration}
})

export const resetMessage = () => ({
    type:RESET_MESSAGE
})