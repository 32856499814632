import './ProcessFlow.css'
import 'reactflow/dist/style.css';
import React    from "react";
import axios from 'axios';
import $ from 'jquery';
import cloneDeep from 'lodash/cloneDeep';
import { createSelector } from 'reselect';
import { useState, useCallback, useEffect } from 'react';
import { connect } from 'react-redux';
import Dialog from 'react-dialog';
import { Button } from './../DataOperation/common/Button/StandardButton';
import ReactFlow, { Controls, Background, useNodesState, useEdgesState, MarkerType, ConnectionMode, useKeyPress } from 'reactflow';
import { ContextMenu, MenuItem, ContextMenuTrigger, SubMenu } from "react-contextmenu";
import restClient from '../../../restClient';
import { CREATE, DELETE, UPDATE, GET_ALL, GET, POST_PARAM_BODY } from '../../../restClient/types';
import MessageTypeConst from "../../MessageTypeConst";
import { removeProcessFlow, setTenantId, removeProcessFlowBlockByTabIndex, addProcessFlowBlockByTabIndex,
    addProcessFlowBlockLinkByTabIndex, setProcessFlowAction, setProcessFlowTable, setProcessFlowTableId,
    setQuickCondition, setSubMenuColumns, updateProcessFlowBlockByTabIndex, updateProcessflowBlockRenderStatus,
    setActiveProcessFlow, setALLProcessFlowRenderStatus, selectItemsForCopyPaste, removeProcessFlowBlockLinkByTabIndex, refreshProcessFlow,
    updateProcessFlowSyncFlagByTabIndex, updateProcessFlowBlockBySchemaName, updateProcessFlowCheckedOutStatusByTabIndex, setJumpToBlockIdProcessId,
    removeProcessFlowNoteByTabIndex, addProcessFlowNoteByTabIndex, updateProcessFlowNoteByTabIndex, updateProcessFlowNoteBySchemaName } from './../../../actions/actionProcessFlow';
import { setTreeViewData } from './../../../actions/actionTreeView';
import { setMessage } from '../../../actions/actionNotification';
import ProcessBlock from "./ProcessBlock.js";
import ProcessNote from "./ProcessNote.js";
import SimpleFloatingEdge from './SimpleFloatingEdge';
import Global_Context from './../../SideTreeView/ShowTree/Global_Static_Node';
import DataOpsIdentifierConstants from './../../DataOpsIdentifierConstants';
import ShortNamesConst from './../../ShortNameConst';
import BlockType from './../../BlockType';
import BlockSaveType from './../../BlockSaveType';
import BlockStatus from './../../ProcessFlow/BlockStatus';
import BlockCreationType from './../../BlockCreationType';
import AccessControlConstants from './../../AccessControlConstants';
import ConfirmBox from './../DataOperation/common/ConfirmBox';
import ExportTypeConst from './../../ExportTypeConst';
import Export from './../Export/Export';
import TaskList from './../TaskManagement/TaskList';
import CreateTask from './../TaskManagement/CreateTask';
import SingleBlockPasteDialog from './SingleBlockPasteDialog';
import MultipleBlockPasteDialog from './MultipleBlockPasteDialog';
import RichTextEditor from "./../RichTextEditor";
import SearchReplace from './../NavigationBarMenu/SearchReplace';

const nodeTypes = {
  processBlock: ProcessBlock,
  processNote: ProcessNote
};
const edgeTypes = {
  floating: SimpleFloatingEdge,
};

function onSelectionDragStop(event, nodes) {
  console.log('multiple nodes drag stop', nodes)
}


function getProcessBlockList(processBlockList) {
  let nodeList = [];

  processBlockList.forEach(processBlock => {
    nodeList.push(createBlock(processBlock))
  })

  return nodeList;
}

function createBlock(processBlock) {
  let blockCoord = typeof(processBlock.blockCoord) !== 'undefined' && processBlock.blockCoord !== null ? processBlock.blockCoord.split(',') : [50,50]
  return {
    id: processBlock.blockId.toString(),
    data: { label: processBlock.blockName !== "" && processBlock.blockName !== null ? processBlock.blockName: processBlock.blockTable, ...processBlock},
    position: { x: parseInt(blockCoord[0], 10), y: parseInt(blockCoord[1], 10) },
    type: 'processBlock'
  }
}

function getProcessNoteList(processNoteList) {
  let noteList = [];

  processNoteList.forEach(processNote => {
    noteList.push(createNote(processNote))
  })

  return noteList;
}

function createNote(processNote) {
  let noteCoord = typeof(processNote.noteCoord) !== 'undefined' && processNote.noteCoord !== null ? processNote.noteCoord.split(',') : [50,50]
  return {
    id: processNote.noteId.toString()+'-note',
    data: { label: processNote.data !== "" && processNote.data !== null ? processNote.data: '', ...processNote},
    position: { x: parseInt(noteCoord[0], 10), y: parseInt(noteCoord[1], 10) },
    type: 'processNote'
  }
}
function getProcessBlockLinkList(processBlockLinkList) {
  let edgeList = [];

  processBlockLinkList.forEach(processBlockLink => {
    edgeList.push(createBlockLink(processBlockLink));
  })
  return edgeList;
}

function createBlockLink(processBlockLink) {
  return {
    id: processBlockLink.linkId.toString(),
    source: processBlockLink.blockId1.toString(),
    target: processBlockLink.blockId2.toString(),
    sourceHandle: 'c',
    targetHandle: 'a',
    type: 'floating',
    markerEnd: { type: MarkerType.ArrowClosed, color: '#282828' },
    dataop: processBlockLink.dataop,
    data:processBlockLink
  }
}
function ProcessFlow(props) {
  const [nodes, setNodes, onNodesChange] = useNodesState([...getProcessBlockList(props.processBlock), ...getProcessNoteList(props.processFlowNotes)]);
  const [edges, setEdges, onEdgesChange] = useEdgesState(getProcessBlockLinkList(props.processBlockLink));
  const [exportNode, setExportNode] = useState(false);
  const [triggerNode, setTriggerNode] = useState('');
  const [pasteModel, setPasteModel] = useState(false);
  const [createTaskDialogStatus, setCreateTaskDialogStatus] = useState(false);
  const [noteModel, setNoteModel] = useState({showAddNotesDialog: false, notesContent: "", newNotes:true, noteId: 0});
  const deletePressed = useKeyPress('Delete');
  const ctrlAndCPressed = useKeyPress('Control+c');
  const [orderNodes, setOrderNodes] = useState([]);
  const [searchReplaceDialog, setSearchReplaceDialog] = useState(false);
  let contextDataOperationTrigger = null;
  let contextProcessFlowTrigger = null;
  let sourceTableList = [];
  let metaTableList = [];
  let documentsList = [];
  let multiplePasteRef = null;
  props.treeView.children.forEach((project) => {
      project.children.forEach((study) => {
        if (study.study.studyId === props.studyId) {
          if (typeof(study.study.sourceTable) !== 'undefined') {
            study.study.sourceTable.map((sourceTable) => {
              sourceTableList.push(sourceTable)
            })
          }
          if (typeof(study.study.metaTable) !== 'undefined') {
            study.study.metaTable.forEach((metaTable) => {
              metaTableList.push(metaTable);
            })
          }
          if (typeof(study.study.studyDocuments) !== 'undefined') {
              study.study.studyDocuments.forEach((doc) => {
                  documentsList.push(doc);
              })
          }
        }
      })
  })
  useEffect(() => {
    setNodes([...getProcessBlockList(props.processBlock), ...getProcessNoteList(props.processFlowNotes)])
    setEdges(getProcessBlockLinkList(props.processBlockLink))
  }, [props.processBlock,props.processFlowNotes,props.processBlockLink])

  useEffect(() => {
    if (deletePressed) {
        deleteNodes()
    }

  }, [deletePressed]);

  useEffect(() => {
    if (ctrlAndCPressed && typeof(props.processFlowList[props.activeProcessFlow]) !== 'undefined' && nodes.filter(node => node.selected === true && node.data.processId === props.processFlowList[props.activeProcessFlow].process.processId && node.type === 'processBlock').length > 0) {
      handeCopyItems(nodes.filter(node => node.selected === true && node.data.processId === props.processFlowList[props.activeProcessFlow].process.processId && node.type === 'processBlock'))
    }
  }, [ctrlAndCPressed]);

  useEffect(() => {
    nodes.filter(node => node.type === 'processBlock' && node.data.processId === props.jumpToProcessId && node.data.blockId === props.jumpToBlockId).map(node => {
      node.selected = true

      if (document.getElementById(props.id).closest('.exView') !== null) {
        document.getElementById(props.id).closest('.exView').scrollLeft = node.position.x - 100;
        document.getElementById(props.id).closest('.exView').scrollTop = node.position.y - 100;
      }
      return node
    })
    setNodes(nodes)

  }, [props.jumpToBlockId, props.jumpToProcessId])

  const onConnect = useCallback(
    (params) =>
      setEdges((eds) =>
        addEdge({ ...params, type: 'floating', markerEnd: { type: MarkerType.Arrow } }, eds)
      ),
    []
  );

  const onNodeClick = (event, node) => {
    event.preventDefault();
    global.dataViewerTemplate = false;
    global.fileViewerTemplate = false;
    //BlockType = 1 for table,
    //BlockType = 2 for File
    if(node.data.blockType === 1){
        global.dataViewerTemplate = true;
    }else if(node.data.blockType === 2){
        global.fileViewerTemplate = true;
    }

    setOrderNodes([...orderNodes.filter(orderNode => orderNode !== node.id), node.id])
  }

  const onNodeDoubleClick = (event, node) => {
    event.preventDefault();
    if (typeof(node.data) !== 'undefined' && node.type === 'processBlock') {

        if(node.data.blockSyncFlag === BlockStatus.VIRTUAL){
            ConfirmBox.alert("Virtual block does not have any data.");
            return;
        }
        global.selectedProcessBlocks = node.data.blockTable;
        global.updateBlock = false;
        global.dataViewerTemplate = false;
        global.fileViewerTemplate = false;
        //BlockType = 1 for table,
        //BlockType = 2 for File
        if(node.data.blockType === 1 && (node.data.blockCreationType === BlockCreationType.IMPORT_SOURCE_TABLE
            || node.data.blockCreationType === BlockCreationType.IMPORT_META_TABLE
            || node.data.blockCreationType === BlockCreationType.DATA_LINK
            || node.data.blockCreationType === BlockCreationType.DATA_COPY)){
            if(node.data.tableExists === 0){
                ConfirmBox.alert("Table does not exist for '"+ node.data.blockName+"' block. Generate table using Execute All at Process Block level.");
                return;
            }else{
                global.dataViewerTemplate = true;
            }
        }else if(node.data.blockType === 2 && node.data.blockCreationType === BlockCreationType.IMPORT_SOURCE_DOCUMENT){
            global.fileViewerTemplate = true;
        }else if(node.data.blockCreationType === BlockCreationType.DEF_COPY || node.data.blockCreationType === BlockCreationType.CREATE_UPDATE_BLOCK
            || node.data.blockCreationType === BlockCreationType.MAP_ANNOTATE || node.data.blockCreationType === BlockCreationType.TIMELINE){
                global.updateBlock = true;
                props.setTenantId(node.data.schemaName);
                props.setProcessFlowTable(node.data.blockTable);
                props.setProcessFlowTableId(node.data.blockId.toString());
                window.location.hash = '#update-block';
                return;
        }

        props.setTenantId(node.data.schemaName);
        props.setProcessFlowTable(node.data.blockTable);
        props.setProcessFlowTableId(node.data.blockId.toString());

        window.location.hash = '#data-operation';
    } else if (typeof(node.data) !== 'undefined' && node.type === 'processNote') {
      setNoteModel({showAddNotesDialog: true, notesContent: node.data.data, newNotes:false, noteId: node.data.noteId})
    }
  }

  const editNoteNode = () => {
    const selectedNotes = nodes.filter(node => node.selected === true && node.type === 'processNote');

    if (selectedNotes.length > 0) {
      setNoteModel({showAddNotesDialog: true, notesContent: selectedNotes[0].data.data, newNotes:false, noteId: selectedNotes[0].data.noteId})
    }
  }

  const onSelectionContextMenu = (event, nodes) => {
    event.preventDefault();
    if (contextDataOperationTrigger) {
      contextDataOperationTrigger.handleContextClick(event);
    }
  }

  const onNodeContextMenu = (event, node) => {
    event.preventDefault();
    if (contextDataOperationTrigger) {
      contextDataOperationTrigger.handleContextClick(event);
    }
  }

  const onPaneContextMenu = (event) => {
    if (typeof(event.nativeEvent) !== 'undefined') {
      global.blockXY = event.nativeEvent.offsetX+','+event.nativeEvent.offsetY;
    } else {
      global.blockXY = (event.clientX-220)+','+(event.clientY-164);
    }
    if (contextProcessFlowTrigger) {
      contextProcessFlowTrigger.handleContextClick(event);
    }
  }

  const handleAddBlockClick = (event, data) => {
    ConfirmBox.open("Are you sure you want to create new BLOCK ?").then(function () {
      let seleced_value = data.item;
      $(".loader").show();
        axios.all([restClient(CREATE, 'processblock/add?tableName='+seleced_value+'&studyName='+props.schemaName+'&studyId='+props.studyId+'&processId='+props.processId+'&blockXY='+global.blockXY+'&importType='+data.importType, {}, {'Tenant-ID': props.schemaName})])
            .then(axios.spread((processblockresponse) => {
                    if (typeof(processblockresponse.data) !== 'undefined' && typeof(processblockresponse.data.messageType) !== 'undefined' && processblockresponse.data.messageType !== null && processblockresponse.data.messageType ===  MessageTypeConst.WARNING_MESSAGE) {
                        props.setMessage(processblockresponse.data.message, processblockresponse.data.messageType);
                        return;
                    }
                    if (typeof(processblockresponse.data) !== 'undefined') {
                      props.addProcessFlowBlockByTabIndex(props.activeProcessFlow, processblockresponse.data);
                      setNodes([...nodes, ...[createBlock(processblockresponse.data)]])
                      props.setMessage("New '"+ seleced_value +"' PROCESS BLOCK have been created Successfully!", MessageTypeConst.SUCCESS_MESSAGE)
                    }
                    else{
                      props.setMessage("New '"+ seleced_value +"' PROCESS BLOCK creation Failed!", MessageTypeConst.ERROR_MESSAGE);
                    }

                    global.blockXY = '';
                    $(".loader").hide();
                })
            ).catch(error => {
                global.blockXY = '';
                $(".loader").hide();
                props.setMessage("New '"+ seleced_value +"' PROCESS BLOCK creation Failed!", MessageTypeConst.ERROR_MESSAGE);
              });
      })
  }

  const handleClick = (event, data) => {
    console.log('click', data)
  }

  const handleAddBlockOperationClick = (event, data) => {
    const selectedNodes = orderNodes.length === nodes.filter(node => node.selected === true && node.type === 'processBlock').length ? orderNodes.filter(orderNode => nodes.filter(node => node.selected === true && node.type === 'processBlock' && node.id === orderNode).length > 0).map(orderNode => nodes.find(node => node.selected === true && node.type === 'processBlock' && node.id === orderNode)) : nodes.filter(node => node.selected === true && node.type === 'processBlock');
    let selectnote = [];
    let selectnodeid = [];

    selectedNodes.forEach(selectedNode => {
      selectnote.push(selectedNode.data.blockTable);
      selectnodeid.push(selectedNode.data.blockId);
    })

    props.setProcessFlowAction(data.item);
    props.setQuickCondition(JSON.stringify(data.quickColumns.split('_')));
    props.setProcessFlowTable(selectnote.join(',').toString());
    props.setProcessFlowTableId(selectnodeid.join(',').toString());
    props.setTenantId(props.schemaName);

    global.dataViewerTemplate = false;
    global.fileViewerTemplate = false;
    global.updateBlock = false;

    if (typeof(data.exportNode) !== 'undefined') {
      setExportNode(data.exportNode);
    }
    if (data.value ==='view_data' && selectedNodes.length > 0 && selectedNodes[0].data.blockType === 1 && (selectedNodes[0].data.blockCreationType === BlockCreationType.IMPORT_SOURCE_TABLE
        || selectedNodes[0].data.blockCreationType === BlockCreationType.IMPORT_META_TABLE
        || selectedNodes[0].data.blockCreationType === BlockCreationType.DATA_LINK
        || selectedNodes[0].data.blockCreationType === BlockCreationType.CREATE_UPDATE_BLOCK
        || selectedNodes[0].data.blockCreationType === BlockCreationType.DATA_COPY)) {
          if(selectedNodes[0].data.tableExists === 0){
              ConfirmBox.alert("Table does not exist for '"+ selectedNodes[0].data.blockName+"' block. Generate table using Execute All at Process Block level.");
              return;
          }else{
              global.dataViewerTemplate = true;
          }
    } else if(data.value === 'view_report/document' && selectedNodes.length > 0 &&
    (selectedNodes[0].data.blockType === 2 || selectedNodes[0].data.blockType === 5) && (selectedNodes[0].data.blockCreationType === BlockCreationType.IMPORT_SOURCE_DOCUMENT
        || selectedNodes[0].data.blockCreationType === BlockCreationType.CREATE_UPDATE_BLOCK || selectedNodes[0].data.blockCreationType === BlockCreationType.MAP_ANNOTATE )){
        global.fileViewerTemplate = true;
    }
    if (data.value === 'create_task') {
      if (selectedNodes.length > 0) {
        handleCreateTaskDialogClick(null, {blockId: selectedNodes[0].data.blockId, blockName: selectedNodes[0].data.blockName, blockProcessId: selectedNodes[0].data.processId, schemaName: props.schemaName})
      }
    }

    if (data.value === 'block_options_copy' && selectedNodes.length > 0) {
      handeCopyItems(selectedNodes);
    }
    if (data.value === 'block_options_delete' && selectedNodes.length > 0) {
      const nodeStatus = edges.filter((link)=> link.target === selectedNodes[0].data.blockId.toString()).length > 0 ? 'Last Node' : 'Not Last Node';

      if(nodeStatus === '' || nodeStatus.toLowerCase() === "last node" || true){
        deleteNodes();
      }
      else{
          var message = selectBlockName + " cannot be deleted, Please delete child blocks of exisintg block!";
          props.setMessage(message , MessageTypeConst.WARNING_MESSAGE);
      }
    }
    if (data.value === 'Paste') {
      handlePasteOpen(true);
    }
    if (data.value !== 'view_data' && data.value !== 'view_report/document' ) {
        global.dataViewerTemplate = false;
        global.fileViewerTemplate = false;
    }

    if (data.value === 'search_replace') {
      setSearchReplaceDialog(true)
    }
    setOrderNodes([])
    if (typeof(data.hash) !== 'undefined' && data.hash !== '') {
      if (data.item === 'mapper' || data.item === 'tlf') {
        props.setProcessFlowTableId(0);
      }
      window.location.hash='#'+data.hash;
    } else if (typeof(data.hash) === 'undefined') {
      window.location.hash='#data-operation';
    }
  }

  const handeCopyItems = (selectedNodes) => {
    let items = [];
    selectedNodes.forEach(selectedNode => {
      items.push(selectedNode.data)
    })

    props.selectItemsForCopyPaste(items);
    props.setMessage("PROCESS BLOCK(s) have been copied Successfully!", MessageTypeConst.SUCCESS_MESSAGE);
  }

  const handlePasteOpen = (pasteDialogStatus = false) => {
      if(typeof(props.selectsItems) !== "undefined" && props.selectsItems.length === 1){
          if(props.selectsItems[0].blockCreationType === BlockCreationType.MAP_ANNOTATE){
                  ConfirmBox.alert("Map-Annotate block can't be copied.");
              return;
          }
          if(props.selectsItems[0].blockCreationType === BlockCreationType.TIMELINE){
              ConfirmBox.alert("Timeline block can't be copied.");
          return;
          }
          if((props.processId === props.selectsItems[0].processId &&
              props.studyId === props.selectsItems[0].studyId )&&
          ( props.selectsItems[0].blockCreationType === BlockCreationType.IMPORT_META_TABLE ||
              props.selectsItems[0].blockCreationType === BlockCreationType.IMPORT_SOURCE_TABLE ||
              props.selectsItems[0].blockCreationType === BlockCreationType.DATA_COPY ||
              props.selectsItems[0].blockCreationType === BlockCreationType.DATA_LINK)){
                  ConfirmBox.alert("Data block can't be copied in same process flow.");
              return;
          }
          if((props.selectsItems[0].blockCreationType === BlockCreationType.IMPORT_META_TABLE ||
              props.selectsItems[0].blockCreationType === BlockCreationType.IMPORT_SOURCE_TABLE ||
              props.selectsItems[0].blockCreationType === BlockCreationType.DATA_COPY ||
              props.selectsItems[0].blockCreationType === BlockCreationType.DATA_LINK ) && props.selectsItems[0].tableExists === 0){
                  ConfirmBox.alert("Table does not exist for copied block.");
                  return;
              }
          if(props.selectsItems[0].blockCreationType === BlockCreationType.IMPORT_SOURCE_DOCUMENT){
              ConfirmBox.alert("Document block can not be copied.");
              return;
          }
      }
      setPasteModel(props.selectsItems.length === 0 ? false : pasteDialogStatus);
  }

  const deleteNodes = () => {
    const selectedNodes = nodes.filter(node => node.selected === true && node.type === 'processBlock');
    const selectedNotes = nodes.filter(node => node.selected === true && node.type === 'processNote');
    const selectedEdges = edges.filter(edge => edge.selected === true);

    if (selectedNodes.length > 0) {
      let blockIds = [];
      let blockName = [];
      selectedNodes.forEach(selectedNode => {
        blockIds.push(selectedNode.data.blockId);
        blockName.push(`'${selectedNode.data.blockName}'`);
      })

      ConfirmBox.open(`Are you sure you want to delete blocks ${blockName.join(', ')} ?`).then(function () {
        $(".loader").show();
        let params = {
            studyId:selectedNodes[0].data.studyId,
            processId:selectedNodes[0].data.processId
        }
        axios.all([restClient(POST_PARAM_BODY, `processblock/deleteBlocks`, {data: blockIds,params:params}, {'Tenant-ID': props.schemaName})])
        .then(axios.spread((response) => {
          $(".loader").hide();
          let processFlowStatus = [];
          if(response.data.messageType === MessageTypeConst.ERROR_MESSAGE){
            props.setMessage(response.data.message, response.data.messageType);
          } else{
            if(response.data.processBlock !== null && typeof(response.data.processBlock) !== "undefined"){
              let validBlockIds = []
                for(let k=0; k < blockIds.length; k++){
                    let blockExists = response.data.processBlock.filter(block=>block.blockId ===  blockIds[k]);
                    if(blockExists.length <=  0){
                        let blockId = blockIds[k];
                        props.removeProcessFlowBlockByTabIndex(props.activeProcessFlow, parseInt(blockId, 10));
                        validBlockIds.push(blockId)
                    }
                }
                setNodes(nodes.filter(node => node.type === 'processBlock' && validBlockIds.indexOf(node.data.blockId) === -1));
                if(response.data.processBlock.length > 0){
                    props.updateProcessFlowBlockBySchemaName( props.schemaName, response.data.processBlock);
                }
            }
            if(response.data.processFlow !== null && typeof(response.data.processFlow) !== "undefined"
            && response.data.processFlow.length > 0){
                response.data.processFlow.forEach((flow) => {
                    processFlowStatus.push({processId:flow.processId,processSyncFlag:flow.processSyncFlag, studyId: flow.studyId, isSampleFiltered: flow.isSampleFiltered})
                });
                props.processFlowList.forEach((processFlow, index) => {
                    response.data.processFlow.forEach((flow) => {
                    if (processFlow.process.studyId === flow.studyId && processFlow.process.processId === flow.processId) {
                        props.updateProcessFlowSyncFlagByTabIndex(index, flow.processSyncFlag);
                    }});
                    });
                }
            updateFlowIconInTreeView(processFlowStatus);
            if(response.data.processBlockLink !== null && typeof(response.data.processBlockLink) !== "undefined"
            && response.data.processBlockLink.length > 0){
            props.processFlowList.forEach((processFlow, index) => {
                response.data.processBlockLink.forEach((link) => {
                if (processFlow.process.studyId === link.studyId && processFlow.process.processId === link.processId) {
                    props.removeProcessFlowBlockLinkByTabIndex(index, link.linkId);
                }});
                });
            }

            props.setMessage(response.data.message, response.data.messageType);
          }
        })).catch(error => {
            $(".loader").hide();
            props.setMessage("Failed to delete process block(s).", MessageTypeConst.ERROR_MESSAGE);
          });
      })
    } else if (selectedNotes.length > 0) {
      ConfirmBox.open("Are you sure you want to delete notes ?").then(function () {
          $(".loader").show();

          axios.all([restClient(DELETE, 'note/delete', {id: parseInt(selectedNotes[0].data.noteId,10)}, {'Tenant-ID': props.schemaName})])
          .then(axios.spread((response) => {
            $(".loader").hide();
            if(response.data.messageType !== MessageTypeConst.SUCCESS_MESSAGE){
              props.setMessage(response.data.message, response.data.messageType);
            } else{
              props.removeProcessFlowNoteByTabIndex(props.activeProcessFlow, parseInt(selectedNotes[0].data.noteId, 10));
              setNodes(nodes.filter(node => !(node.type === 'processNote' && node.data.noteId === selectedNotes[0].data.noteId)))
              props.setMessage(response.data.message, MessageTypeConst.SUCCESS_MESSAGE);
            }
          })).catch(error => {
              $(".loader").hide();
              props.setMessage("Process Notes deletion Failed!", MessageTypeConst.ERROR_MESSAGE);
            });
      });
    } else if (selectedEdges.length > 0) {
      let message = 'Are you sure you want to delete blocks links ?'

      if (selectedEdges.filter(selectedEdge => selectedEdge.dataop === DataOpsIdentifierConstants.MAP_ANNOTATE_OP).length > 0) {
        message = 'Are you sure you want to delete blocks links and mapper annotate highlights?'
      }
      ConfirmBox.open(message).then(function () {
          $(".loader").show();
          let blocklinks = [];
          selectedEdges.forEach(selectedEdge => {
              blocklinks.push(selectedEdge.data)
          })

          axios.all([restClient(POST_PARAM_BODY, 'link/deletelinks', {data: blocklinks}, {'Tenant-ID': props.schemaName})])
          .then(axios.spread((response) => {
            $(".loader").hide();
            let processFlowStatus = [];
            if(response.data.messageType !== MessageTypeConst.SUCCESS_MESSAGE){
              props.setMessage(response.data.message, response.data.messageType);
            } else{
                if(response.data.processBlock !== null && typeof(response.data.processBlock) !== "undefined" &&
                    response.data.processBlock.length > 0){
                    props.updateProcessFlowBlockBySchemaName(props.schemaName, response.data.processBlock);

                    let replaceBlocks = [];
                    response.data.processBlock.forEach((block) => {
                        replaceBlocks.push(createBlock(block));
                    });
                    setNodes([...nodes.filter(node => node.type === 'processBlock' && response.data.processBlock.filter(updateBlock => updateBlock.blockId !== node.data.blockId).length), ...replaceBlocks])
                }
                if(response.data.processFlow !== null && typeof(response.data.processFlow) !== "undefined"
                && response.data.processFlow.length > 0){
                    response.data.processFlow.forEach((flow) => {
                        processFlowStatus.push({processId:flow.processId,processSyncFlag:flow.processSyncFlag, studyId: flow.studyId})
                    });

                    props.processFlowList.forEach((processFlow, index) => {
                        response.data.processFlow.forEach((flow) => {
                        if (processFlow.process.studyId === flow.studyId && processFlow.process.processId === flow.processId) {
                            props.updateProcessFlowSyncFlagByTabIndex(index, flow.processSyncFlag);
                        }});
                        });
                }
                updateFlowIconInTreeView(processFlowStatus);
                for(let k=0;k<blocklinks.length;k++){
                  props.removeProcessFlowBlockLinkByTabIndex(props.activeProcessFlow,blocklinks[k].linkId);
                }

                setEdges(edges.filter(edge => !(edge.selected === true)))
                props.setMessage("Process block link(s) have been deleted successfully!",MessageTypeConst.SUCCESS_MESSAGE);
            }
          })).catch(error => {
              $(".loader").hide();
              props.setMessage("Failed to delete process block link(s).", MessageTypeConst.ERROR_MESSAGE);
            });
      });
    }
  }

  const updateFlowIconInTreeView = (processFlowStatus) => {
      let treeView = props.treeView;
      treeView.children.forEach((project, index) => {
        project.children.forEach((study) => {
          processFlowStatus.forEach((updatedFlow)=>{
            if (study.study.studyId === updatedFlow.studyId) {
              study.children.forEach((studyElement) => {
                if (studyElement.name === 'ProcessFlows') {
                  studyElement.children.forEach((classifier) => {
                    classifier.children.forEach((processGroup) => {
                      processGroup.children.map((flow)=>{
                        if(updatedFlow.processId === flow.process.processId){
                          flow.process.processSyncFlag = updatedFlow.processSyncFlag;
                          if(updatedFlow.processSyncFlag === 2){
                            flow.iconClass = "fa fa-exclamation-triangle yellow";
                          }
                          else if(updatedFlow.processSyncFlag === 3){
                            flow.iconClass = "fa fa-exclamation-circle red";
                          }
                          else if(updatedFlow.processSyncFlag === 1){
                            flow.iconClass = "fa fa-microchip";
                          }
                          if (typeof(updatedFlow.isSampleFiltered) !== 'undefined') {
                              flow.process.isSampleFiltered = updatedFlow.isSampleFiltered
                          }
                        }
                        return flow;
                      })
                    });
                  })

                }
              })
            }
          })
        });
      })
      props.setTreeViewData(treeView);
  }

  const datOperationMenuItemList = () => {
    let items = [
                { text: Global_Context.dataOpMenu, cssClass: "hr-view invalidBlockDisable virtualBlockDisable notForCheckedOutUser notForNotes notHavingData selectionPF3 notForStudyDocumentBlock",
                items: [
                    { text: Global_Context.continueDateItem, cssClass: "selectionPF2 selectionPF3 selectable", attr:{ actionname: DataOpsIdentifierConstants.CONTINUOUSDATE_OP} },
                    { text: Global_Context.dataOpsItem, cssClass: "selectionPF2 selectionPF3 selectable", attr:{ actionname: DataOpsIdentifierConstants.DATAOPS_OP} },
                    { text: Global_Context.decollapseItem1, cssClass: "selectionPF2 selectionPF3 selectable", attr:{ actionname: DataOpsIdentifierConstants.DECOLLAPSE_ONETABLE_OP} },
                    { text: Global_Context.pivotItem, cssClass: "selectionPF2 selectionPF3",
                    items: [
                        { text : Global_Context.rowToColumn, cssClass: "selectable", attr:{ actionname: DataOpsIdentifierConstants.PIVOT_RTC_OP} },
                        { text : Global_Context.columnToRow, cssClass: "selectable", attr:{ actionname: DataOpsIdentifierConstants.PIVOT_CTR_OP} },
                        { text : Global_Context.pivotMatrix, cssClass: "selectable", attr:{ actionname: DataOpsIdentifierConstants.PIVOT_MATRIX} },
                    ]
                    },
                    { text: Global_Context.stringOpsMenu, cssClass: "selectionPF2 selectionPF3",
                        items: [
                            { text: Global_Context.stgOpAlignItem, cssClass: "selectable", attr:{ actionname: DataOpsIdentifierConstants.STRINGOPS_ALIGN_OP} },
                            { text: Global_Context.stgOpSpacingItem, cssClass: "selectable", attr:{actionname:DataOpsIdentifierConstants.STRINGOPS_INDUCESPACING_OP} },
                            { text: Global_Context.stgOpTrimItem, cssClass: "selectable", attr:{actionname: DataOpsIdentifierConstants.STRINGOPS_TRIMCOMPRESS_OP} },
                            { text: Global_Context.stgOpCaseItem, cssClass: "selectable", attr:{actionname: DataOpsIdentifierConstants.STRINGOPS_CHANGECASE_OP} },
                            { text: Global_Context.stgOpSearchItem, cssClass: "selectable", attr:{actionname: DataOpsIdentifierConstants.STRINGOPS_SEARCHNREPLACE_OP} },
                            { text: Global_Context.stgOpOccItem, cssClass: "selectable", attr:{actionname: DataOpsIdentifierConstants.STRINGOPS_OCCURENCE_OP} },
                            { text: Global_Context.stgOpSplitItem, cssClass: "selectable", attr:{actionname: DataOpsIdentifierConstants.STRINGOPS_SPLIT_OP} },
                        ]
                    },
                    { text: Global_Context.tableOpsMenu, cssClass: "selectionPF2 selectionPF3",
                        items: [
                            { text: Global_Context.tbOpRenameItem, cssClass: "selectable", attr:{actionname: DataOpsIdentifierConstants.TABLEOPS_RENAME_OP} },
                            { text: Global_Context.tbOpLengthAdjItem, cssClass: "selectable", attr:{actionname: DataOpsIdentifierConstants.TABLEOPS_LENGTH_ADJ_OP} }
                        ]
                    },
                    { text: Global_Context.sortOpsMenuItem, cssClass: "selectionPF2 selectionPF3 selectable", attr:{actionname: DataOpsIdentifierConstants.SORTOPS_OP} },
                    { text: Global_Context.rankOpsMenuItem, cssClass: "selectionPF2 selectionPF3 selectable", attr:{actionname: DataOpsIdentifierConstants.RANKOPS_OP} },
                    { text: Global_Context.seqOpsMenuItem, cssClass: "selectionPF2 selectionPF3 selectable", attr:{actionname: DataOpsIdentifierConstants.SEQUQENCEOPS_OP} },
                    { text: Global_Context.typeConvMenu, cssClass: "selectionPF2 selectionPF3",
                        items: [
                            { text: Global_Context.dtmConvTConItem, cssClass: "selectable", attr:{actionname: DataOpsIdentifierConstants.TYPECONVERSION_DATETIME_OP} },
                            { text: Global_Context.cnConvTConItem, cssClass: "selectable", attr:{actionname: DataOpsIdentifierConstants.TYPECONVERSION_CHARNUM_OP} },
                        ]
                    },
                    { text: Global_Context.criteriaOpsItem, cssClass:"selectionPF1 selectionPF3 selectable", attr:{actionname: DataOpsIdentifierConstants.CRITERIAOPS_OP} },
                    { text: Global_Context.refPtMenu,
                        items: [
                            { text: Global_Context.refPointItem1, cssClass: "selectionPF2 selectionPF3 selectable", attr:{actionname: DataOpsIdentifierConstants.REFERENCEPOINT_ONETABLE_OP} },
                            { text: Global_Context.refPointItem2, cssClass: "selectionPF1 selectionPF3 selectable", attr:{actionname: DataOpsIdentifierConstants.REFERENCEPOINT_TWOTABLE_OP} },
                            { text: Global_Context.refPtCompItem, cssClass: "selectable selectionPF3", attr:{actionname: DataOpsIdentifierConstants.REFERENCEPOINT_COMPOSITE_OP} },
                            { text: Global_Context.retPtReplaceItem, cssClass: "selectable selectionPF3", attr:{actionname: DataOpsIdentifierConstants.REFERENCEPOINT_REPLACE_OP} },
                            { text: Global_Context.retPtCombineItem, cssClass: "selectable selectionPF3", attr:{actionname: DataOpsIdentifierConstants.REFERENCEPOINT_COMBINE_OP} },
                        ]
                    },
                    { text: Global_Context.varMappingItem, cssClass: "hr-view selectionPF3 selectable", attr:{actionname: DataOpsIdentifierConstants.VARMAPPING_OP} },
                    { text: Global_Context.quickAppendMenu, cssClass: "selectionPF1 selectionPF3 selectable",attr:{ actionname:DataOpsIdentifierConstants.APPEND_OP} },
                    { text: Global_Context.quickJoinMenu, cssClass:"selectionPF1 selectionPF3 selectable", attr:{id:'joinMenu', actionname:DataOpsIdentifierConstants.JOIN_OP}},
                    { text: Global_Context.stgOpSplitItem, cssClass: "hr-view selectionPF2 selectionPF3 selectable",attr:{ actionname:DataOpsIdentifierConstants.SPLIT_OP}},
                    { text: Global_Context.compareItem, cssClass: "selectionPF1 selectionPF3 selectable", attr:{ actionname:DataOpsIdentifierConstants.COMPARE_OP} },
                    { text: Global_Context.decollapseItem2, cssClass: "selectionPF1 selectionPF3 selectable", attr:{ actionname:DataOpsIdentifierConstants.DECOLLAPSE_TWOTABLE_OP} },
                    { text: Global_Context.linkItem, cssClass: "hr-view selectionPF1 selectionPF3 selectable", attr:{ actionname:DataOpsIdentifierConstants.LINK_OP} },
                    { text: Global_Context.codeEditorItem, cssClass: "hr-view selectionPF2 selectionPF3",
                        items: [
                            { text: Global_Context.codeEditorTable, cssClass: "selectable", attr:{ actionname:DataOpsIdentifierConstants.CODEEDITOR_TABLE_OP} },
                            { text: Global_Context.codeEditorFile, cssClass: "selectable", attr:{ actionname:DataOpsIdentifierConstants.CODEEDITOR_FILE_OP} },
                        ]
                    },
                    { text: Global_Context.reportOpsMenu, cssClass: "hr-view selectionPF3",
                        items: [
                            { text: Global_Context.descriptiveReporting, cssClass: "selectable", attr:{ actionname:DataOpsIdentifierConstants.REPORTINGOPS_DESCRIPTIVE_OP} },
                            { text: Global_Context.categoricalReporting, cssClass: "selectable", attr:{ actionname:DataOpsIdentifierConstants.REPORTINGOPS_CATEGORICAL_OP} },
                            { text: Global_Context.categoricalTotalReporting, cssClass: "selectable", attr:{ actionname:DataOpsIdentifierConstants.REPORTINGOPS_CATEGORICAL_TOTAL_OP} },
                            { text: Global_Context.categoricalHierarchicalReporting, cssClass: "selectable", attr:{ actionname:DataOpsIdentifierConstants.REPORTINGOPS_CATEGORICAL_HIERARCHICAL_OP} },
                            { text: Global_Context.customReportingOps, cssClass: "selectable", attr:{ actionname:DataOpsIdentifierConstants.REPORTINGOPS_CUSTOM_OP} },
                        ] },
                        { text: Global_Context.procReportMenu, cssClass: "selectionPF2 selectionPF3 selectable", attr:{ actionname:DataOpsIdentifierConstants.PROCREPORT_ANALYSIS_CHAR_OP}},
                        { text: Global_Context.R_ReportItem, cssClass: "hr-view selectionPF2 selectionPF3 selectable", attr:{ actionname:DataOpsIdentifierConstants.R_Report_OP}},
                        { text: Global_Context.GraphItem, cssClass: "hr-view selectionPF2 selectionPF3 selectable", attr:{ actionname:DataOpsIdentifierConstants.GRAPH_OP}},
                        {text: Global_Context.Interactive, cssClass: "selectionPF3",
                          items:[
                            { text: Global_Context.RShinny, cssClass: "selectionPF2 selectionPF3 selectable", attr: '' },
                            { text: Global_Context.DASH, cssClass: "selectionPF2 selectionPF3 selectable", attr: '' }
                          ]
                        }

                ]
            },
            {
                text:Global_Context.ExecuteAllItemInteractive,cssClass:"hr-view selectionPF2 selectionPF3 selectable notForNotes virtualBlockDisable notInRoot notForCheckedOutUser notForStudyDocumentBlock notForMapAnnotateBlock notForTimelineBlock invalidInputLinks notForOnlyInvalidBlock isInvalidBlock", hash: "executeallinteractiveblock"
            },
            {
                text:Global_Context.ExportItem,cssClass:"hr-view selectionPF2 selectionPF3 selectable notForNotes virtualBlockDisable notForMapAnnotateBlock notForTimelineBlock", exportNode: true, hash: ''
            },
            {
                text:Global_Context.ManageVersion,cssClass:"hr-view selectionPF2 selectionPF3 virtualBlockDisable notForNotes selectable notForLinkDataBlock notForCopyDataBlock notForStudyDocumentBlock", hash: "manageVersion"
            },
            {
                text:Global_Context.searchAndReplace,cssClass:"hr-view selectionPF2 selectionPF3 virtualBlockDisable notForNotes selectable notForLinkDataBlock notForCopyDataBlock notForStudyDocumentBlock", value:"search_replace", hash: ""
            },
            { text: Global_Context.viewMenu,cssClass: "selectionPF2 selectionPF3 notForNotes virtualBlockDisable notForMapAnnotateBlock notForTimelineBlock notForDocumentMapAnnotateBlock",
                items: [
                    { text: Global_Context.viewDataItem, cssClass: "selectable selectionPF2 selectionPF3 notHavingData notForFileTypeBlock", value:"view_data"  },
                    { text: Global_Context.viewReportItem, cssClass: "selectable selectionPF2 selectionPF3 notHavingData notForDataTypeBlock notForDocumentMapAnnotateBlock", value:"view_report/document" },
                    { text: Global_Context.viewExtItem , cssClass: "selectionPF2 selectionPF3 selectable notHavingData notForFileTypeBlock", hash: "extendedattributes"},
                    { text: Global_Context.viewCodeItem, cssClass: "selectionPF2 selectionPF3 notInRoot selectable notForMapAnnotateBlock", hash: "code" },
                    { text: Global_Context.viewLogItem, cssClass: "selectionPF2 selectionPF3 notInRoot selectable notForMapAnnotateBlock", hash: "log" },
                ]
            },
            { text: Global_Context.timelineChartNGItem, cssClass: "hr-view selectable invalidBlockDisable notForNotes virtualBlockDisable notForCheckedOutUser notHavingData notForStudyDocumentBlock", hash:"timeline-profile" },
            { text: Global_Context.mapAndAnnotateItem, cssClass: "hr-view selectionPF2 selectionPF3 notHavingData notForDataTypeBlock notHavingPDFFileDocument",
              items: [
                { text: Global_Context.autoMappingItem, cssClass: "hr-view selectable selectionPF2 selectionPF3 notHavingData notForDataTypeBlock notHavingPDFFileDocument notHavingDOCXFileDocument", hash:"annotate?type=mapper" },
                { text: Global_Context.autoTableItem , cssClass: "hr-view selectable selectionPF2 selectionPF3 notHavingData notForDataTypeBlock notHavingPDFFileDocument havingDOCXFileDocument", hash:"annotate?type=tlf"},
              ]
            },
            { text: Global_Context.addLinkItem, cssClass: "selectionPF1 selectionPF3 selectable virtualBlockDisable notForNotes notForCheckedOutUser notForStudyDocumentBlock notForMapAnnotateBlock notForTimelineBlock documentAutoPairBlock inValidLink notForCopyDataBlock", hash: "addLink" },
            { text: Global_Context.deleteLinkItem, cssClass: "selectionPF1 selectionPF2 virtualBlockDisable selectionPF3 notForNotes selectable notForCheckedOutUser notForStudyDocumentBlock" },
            { text: Global_Context.updateItemBlock, cssClass: "hr-view notInRoot virtualBlockDisable notForLinkDataBlock notForNotes notForCopyDataBlock disableIfParentNotPersistent selectable selectionPF2 selectionPF3 notHavingDataForUpdate notForStudyDocumentBlock invalidInputLinks isInvalidBlock", hash: "update-block" },
            { text: Global_Context.blockOpMenu, cssClass:"hr-view notForNotes",
                items: [
                    { text: Global_Context.copyBlockItem, cssClass: "selectable notForStudyDocumentBlock virtualBlockDisable", value: 'block_options_copy', hash: '' },
                    { text: Global_Context.deleteBlockItem , cssClass: "selectable selectionPF2 selectionPF3 notForCheckedOutUser", value: "block_options_delete", hash: ''}
                ]
            },
            { text: Global_Context.exportItem, cssClass: "selectionPF1 selectionPF2 selectionPF3 selectable virtualBlockDisable notForNotes notHavingData notForStudyDocumentBlock" },
            { text: Global_Context.createTaskItem, cssClass: "hr-view selectionPF2 selectionPF3 selectable notForNotes", value: 'create_task', hash:"" },
            { text: Global_Context.propertiesItem, cssClass: "selectionPF2 selectionPF3 selectable notForNotes", hash:"data-properties" },
            { text: Global_Context.editNotesItem, cssClass: "selectionPF2 selectionPF3 selectable notForBlocks notForCheckedOutUser"},
            { text: Global_Context.deleteNotesItem, cssClass: "selectionPF2 selectionPF3 selectable notForBlocks notForCheckedOutUser"},
        ];

        if (getWriteAcStatus(props.schemaName) === false) {
            items = items.filter(item => item.text !== Global_Context.blockOpMenu);
        }

        const selectedNodes = nodes.filter(node => node.selected === true && node.type === 'processBlock');
        let actionClassList = [];
        let isDocumentAutoBlock = false;
        let linkAnnotateBlock = false;
        if (selectedNodes.length === 1) {
          let actionBlock = selectedNodes[0].data;
          actionClassList = ['selectionPF1', 'notForBlocks'];
          let links = edges.filter((link)=> link.target === actionBlock.blockId.toString());
          let allParentBlockPersistent = true;
          let allParentBlockValid = true;
          if (links.length > 0 && actionBlock.blockCreationType === BlockCreationType.MAP_ANNOTATE) {
            isDocumentAutoBlock = true;
          }

          links.forEach((link)=>{
              let pBlock = nodes.filter(node=> node.type === 'processBlock' && node.data.blockId.toString() === link.source);
              if(pBlock.length > 0 && pBlock[0].data.blockSyncFlag === BlockStatus.INVALID){
                  allParentBlockPersistent = false;
                  allParentBlockValid = false;
              }
          })

          if(!allParentBlockPersistent && !allParentBlockValid && (actionBlock.blockSyncFlag === BlockStatus.INVALID || actionBlock.blockSyncFlag === BlockStatus.WARNING)){
              actionClassList.push('disableIfParentNotPersistent');
          }
          if(parseInt(actionBlock.blockLevel, 10) === 0 && actionBlock.blockType !== BlockType.MAP_ANNOTATE){
              actionClassList.push('notInRoot');
          }
          if(parseInt(actionBlock.tableExists, 10) === 0 && actionBlock.blockType !== 2){
              actionClassList.push('notHavingData');
          }
          if(typeof(edges) !== "undefined" && edges !== null){
              let forCodeProcReportMApAnnoate = true;
              let blockLinks = edges.filter((link)=> link.target === actionBlock.blockId.toString());
              blockLinks.forEach((plink)=>{
                  if(//plink.dataop !== DataOpsIdentifierConstants.CODEEDITOR_FILE_OP &&
                      //plink.dataop !== DataOpsIdentifierConstants.PROCREPORT_ANALYSIS_CHAR_OP &&
                      //plink.dataop !== DataOpsIdentifierConstants.PROCREPORT_ANALYSIS_NUM_OP &&
                      //plink.dataop !== DataOpsIdentifierConstants.R_Report_OP &&
                      plink.dataop !== DataOpsIdentifierConstants.MAP_ANNOTATE_OP &&
                      plink.dataop !== DataOpsIdentifierConstants.TIMELINE_OP){
                          forCodeProcReportMApAnnoate = false;
                  }
              });
              if(actionBlock.inputTableCount !== blockLinks.length){
                  actionClassList.push('invalidInputLinks');
              }
          }else{
              actionClassList.push('notHavingDataForUpdate');
          }
          if(actionBlock.blockSyncFlag === BlockStatus.INVALID || actionBlock.blockSyncFlag === BlockStatus.WARNING){
              actionClassList.push('invalidBlockDisable');
          }
          if(actionBlock.blockSyncFlag === BlockStatus.INVALID){
              actionClassList.push('notForOnlyInvalidBlock');
          }
          if(actionBlock.blockSyncFlag === BlockStatus.WARNING){
              actionClassList.push('warningBlockDisable');
          }
          if(actionBlock.blockSyncFlag === BlockStatus.VIRTUAL){
              actionClassList.push('virtualBlockDisable');
          }
          if(actionBlock.blockCreationType === BlockCreationType.DATA_LINK){
              actionClassList.push('notForLinkDataBlock');
          }
          if(actionBlock.blockCreationType === BlockCreationType.DATA_COPY){
              actionClassList.push('notForCopyDataBlock');
          }
          if(actionBlock.blockCreationType === BlockCreationType.IMPORT_SOURCE_DOCUMENT){
              actionClassList.push('notForStudyDocumentBlock');
          }
          if(actionBlock.blockCreationType === BlockCreationType.DATA_COPY){
              actionClassList.push('notForCopyDataBlock');
          }
          if(actionBlock.blockCreationType === BlockCreationType.MAP_ANNOTATE){
              actionClassList.push('notForMapAnnotateBlock');
          }
          if(actionBlock.blockCreationType === BlockCreationType.TIMELINE){
              actionClassList.push('notForTimelineBlock');
          }
          if(actionBlock.blockType === 2 || actionBlock.blockType === 5){
              actionClassList.push('notForFileTypeBlock');
          }
          if(actionBlock.blockType === 1){
              actionClassList.push('notForDataTypeBlock');
          }
          if(typeof(global.login_token) !== 'undefined' && !(props.isFlowCheckedOut  && props.isFlowCheckedOutByLoggedInUser)){
              actionClassList.push('notForCheckedOutUser');
          }
          if (isDocumentAutoBlock) {
            actionClassList = actionClassList.filter(actionClass => actionClass !== 'notForDocumentMapAnnotateBlock');
          }

          if (typeof(actionBlock.blockTable) !== 'undefined' && actionBlock.blockTable.indexOf('.pdf') === -1 && actionBlock.blockTable.indexOf('.docx') === -1 && actionBlock.blockCreationType === BlockCreationType.IMPORT_SOURCE_DOCUMENT) {
            actionClassList.push('notHavingPDFFileDocument');
          }

          if (typeof(actionBlock.blockTable) !== 'undefined' && actionBlock.blockTable.indexOf('.docx') === -1 && actionBlock.blockCreationType === BlockCreationType.IMPORT_SOURCE_DOCUMENT) {
            actionClassList.push('havingDOCXFileDocument');
          }

          if (typeof(actionBlock.blockTable) !== 'undefined' && actionBlock.blockTable.indexOf('.docx') > -1 && actionBlock.blockCreationType === BlockCreationType.IMPORT_SOURCE_DOCUMENT) {
            actionClassList.push('notHavingDOCXFileDocument');
          }

          if (actionBlock.blockSyncFlag === BlockStatus.INVALID) {
            actionClassList = actionClassList.filter(actionClass => actionClass !== 'isInvalidBlock');
          }
        } else if(selectedNodes.length === 2){
          actionClassList.push('selectionPF2');
          if(typeof(global.login_token) !== 'undefined' && !(props.isFlowCheckedOut  && props.isFlowCheckedOutByLoggedInUser)){
              $(".notForCheckedOutUser").addClass("enable-false");
          }
          let allBlocksHaveData = true;
          let blockNames = [];
          let isDcumentBlock = false;
          let isAutoBlock = false;
          let isFoundInValid = false;
          selectedNodes.forEach((selectedNode, index) => {
            const pblock = selectedNode.data;
            if(pblock.tableExists === 0){
                blockNames.push(pblock.blockName);
                allBlocksHaveData = false;
                if(pblock.blockCreationType === BlockCreationType.MAP_ANNOTATE){
                    actionClassList.push('notForMapAnnotateBlock')
                    isAutoBlock = true;
                }
                if(pblock.blockCreationType === BlockCreationType.TIMELINE){
                    actionClassList.push('notForTimelineBlock')
                }
                if(pblock.blockCreationType === BlockCreationType.IMPORT_SOURCE_DOCUMENT){
                  isDcumentBlock = true;
                }
            }
            if(pblock.blockCreationType === BlockCreationType.DEF_COPY && pblock.blockSyncFlag === BlockStatus.WARNING){
                actionClassList.push('notForCopyDefBlockWithWarning')
            }
            if(pblock.blockSyncFlag === BlockStatus.INVALID || pblock.blockSyncFlag === BlockStatus.WARNING){
                actionClassList.push('invalidBlockDisable')
            }
            if(pblock.blockSyncFlag === BlockStatus.INVALID){
                actionClassList.push('notForOnlyInvalidBlock')
                isFoundInValid = true;
            }
            if(pblock.blockCreationType === BlockCreationType.DATA_COPY){
                actionClassList.push('notForCopyDataBlock');
            }
          })

          if(!allBlocksHaveData){
              actionClassList.push('notHavingData')
          }

          if (isDcumentBlock === true && isAutoBlock === true) {
            actionClassList = actionClassList.filter(actionClass => actionClass !== 'documentAutoPairBlock');
          }

          if (selectedNodes.length === 2 && edges.filter(blockLink => (blockLink.source == selectedNodes[0].data.blockId.toString() && blockLink.target == selectedNodes[1].data.blockId.toString()) || (blockLink.source == selectedNodes[1].data.blockId.toString() && blockLink.target == selectedNodes[0].data.blockId.toString())).length > 0) {
            actionClassList.push('inValidLink')
          } else {
            actionClassList = actionClassList.filter(actionClass => actionClass !== 'inValidLink');
          }

          if (isAutoBlock === true && isDcumentBlock === true) {
            linkAnnotateBlock = true
          }
        } else if (selectedNodes.length > 2) {
          isDocumentAutoBlock = false
          actionClassList.push('selectionPF3')
          if(typeof(global.login_token) !== 'undefined' && !(props.isFlowCheckedOut  && props.isFlowCheckedOutByLoggedInUser)){
              $(".notForCheckedOutUser").addClass("enable-false");
          }
          let allBlocksHaveData = true;
          let blockNames = [];
          selectedNodes.forEach((selectedNode, index) => {
            const pblock = selectedNode.data;
            if(pblock.tableExists === 0){
                blockNames.push(pblock.blockName);
                allBlocksHaveData = false;
            }
            if(pblock.blockCreationType === BlockCreationType.DEF_COPY && pblock.blockSyncFlag === BlockStatus.WARNING){
                actionClassList.push('notForCopyDefBlockWithWarning')
            }
            if(pblock.blockSyncFlag === BlockStatus.INVALID || pblock.blockSyncFlag === BlockStatus.WARNING){
                actionClassList.push('invalidBlockDisable')
            }
            if(pblock.blockSyncFlag === BlockStatus.INVALID){
                actionClassList.push('notForOnlyInvalidBlock')
            }
            if(pblock.blockCreationType === BlockCreationType.DATA_COPY){
                actionClassList.push('notForCopyDataBlock');
            }
          })
          if(!allBlocksHaveData){
              actionClassList.push('notHavingData')
          }
        }

        actionClassList.forEach(actionClass => {
          items = items.filter(item => typeof(item.cssClass) === 'undefined' || (item.text === 'View' && isDocumentAutoBlock === true) || (item.text === 'Add Link' && linkAnnotateBlock === true) || (typeof(item.cssClass) !== 'undefined' && item.cssClass.split(' ').indexOf(actionClass) === -1)).map(item => {
            if (typeof(item.items) !== 'undefined') {
              item.items = item.items.filter(subItem => typeof(subItem.cssClass) === 'undefined' || (subItem.text === 'Report/Document' && isDocumentAutoBlock === true) || (typeof(subItem.cssClass) !== 'undefined' && subItem.cssClass.split(' ').indexOf(actionClass) === -1))
              item.items.filter(subItem => typeof(subItem.items) !== 'undefined').map(subItem => {
                subItem.items = subItem.items.filter(lastItem => typeof(lastItem.cssClass) === 'undefined' || (typeof(lastItem.cssClass) !== 'undefined' && lastItem.cssClass.split(' ').indexOf(actionClass) === -1))
                return subItem
              })
            }
            return item
          });
        })

        if (selectedNodes.length === 0) {
          items = [];
        }

        return items
  }

  const getWriteAcStatus = (schemaName) => {
    let isWriteAC = true;
    props.treeView.children.filter(project => {
      project.children.filter(study => study.study.schemaName === schemaName).forEach(study => {
        isWriteAC = study.study.enableActions.indexOf(AccessControlConstants.WRITE) > -1
      })
    })

    return isWriteAC;
  }

  const DynamicMenuItem = (item, itemIndex, parentTitle = '') => {
    if (typeof(item.items) === 'undefined') {
      return (<MenuItem key={`menu-${itemIndex}`} className={item.cssClass} onClick={handleAddBlockOperationClick} data={{ item: typeof(item.attr) !== 'undefined' && typeof(item.attr.actionname) !== 'undefined' ? item.attr.actionname : '', quickColumns: (typeof(parentTitle) !== 'undefined' && typeof(parentTitle) === 'string' && parentTitle !== '') ? parentTitle.replace(' ', '_').toUpperCase()+'_'+item.text.toUpperCase() : item.text.toUpperCase(), hash: item.hash, exportNode: item.exportNode, value: item.value}}>{item.text}</MenuItem>)
    } else {
      return (
        <React.Fragment key={`submenu-${itemIndex}`}>
          <MenuItem divider />
          <SubMenu className={item.cssClass} title={item.text} >
          {item.items.map((subMenuItem, subMenuItemIndex) => DynamicMenuItem(subMenuItem, subMenuItemIndex, typeof(parentTitle) === 'string' && parentTitle !== '' ? (parentTitle+'_'+item.text) : item.text))}
          </SubMenu>
          <MenuItem divider />
        </React.Fragment>
      )
    }
  }

  const onNodeDragStart = (event, node, nodes) => {
    console.log('node drag start', node, nodes)
  }
  const onNodeDragStop = (event, node, nodes) => {
    if (typeof(node) !== 'undefined') {
      let nextPosition = parseInt(node.position.x)+','+parseInt(node.position.y);
      if (typeof(node.data.blockCoord) !== 'undefined' && node.data.blockCoord !== nextPosition) {
        node.data.blockCoord = nextPosition;
        axios.all([restClient(UPDATE, 'processblock', {data: node.data, id: node.data.blockId}, {'Tenant-ID': node.data.schemaName})])
        .then(axios.spread((response) => {
            if (typeof(response.data) !== 'undefined' && typeof(response.data.processBlock) !== 'undefined' &&
            response.data.processBlock !== null){
              props.processFlowList.forEach((processFlow, index) => {
                  if (processFlow.schemaName === node.data.schemaName) {
                  processFlow.process.processBlock.forEach((block, blockIndex) => {
                      response.data.processBlock.forEach((updatedBlock)=>{
                          if (updatedBlock.blockId === block.blockId) {
                          props.updateProcessFlowBlockByTabIndex(index, blockIndex, updatedBlock);
                          return true;
                          }
                        })
                      })
                  }
              })
            }
        })).catch(error => {
            props.setMessage("Failed to update block co-ordinate.",MessageTypeConst.ERROR_MESSAGE);
        });
      } else if (typeof(node.data.noteCoord) !== 'undefined' && node.data.noteCoord !== nextPosition) {
        node.data.noteCoord = nextPosition;
        axios.all([restClient(CREATE, 'note/add', {data: node.data}, {'Tenant-ID': props.schemaName})])
        .then(axios.spread((response) => {
            if (typeof(response.data) !== 'undefined' && response.data !== null){
                if (typeof(response.data.messageType) !== 'undefined' && response.data.messageType !== null && response.data.messageType === MessageTypeConst.WARNING_MESSAGE){
                    props.setMessage(response.data.message, response.data.messageType);
                    return true;
                }
                props.processFlowList.forEach((processFlow, index) => {
                    if (processFlow.schemaName === props.schemaName) {
                      processFlow.process.processFlowNotes.forEach((note, noteIndex) => {
                          if (response.data.noteId === note.noteId) {
                              props.updateProcessFlowNoteByTabIndex(index, noteIndex, response.data);
                          }
                      })
                    }
                })
            }
        })).catch(error => {
            props.setMessage("Failed to update notes co-ordinate.",MessageTypeConst.ERROR_MESSAGE);
        });
      }
    }
  }

  const closeExportEvent = () => {
    setExportNode(false);
  }

  const handleCreateTaskDialogClick = (event, triggerNode = '') => {
    setTriggerNode(triggerNode);
    setCreateTaskDialogStatus(!createTaskDialogStatus);
  }

  const handlePasteOk = (event) => {
      event.preventDefault();
      let blockNametemp=$("#txtblockname").val();
      if(typeof(props.selectsItems) !== "undefined" && props.selectsItems !== null && props.selectsItems !== "" && props.selectsItems.length > 0)
      {
          let finalParse = props.selectsItems[0];
          if(finalParse.blockSaveType === 1 && blockNametemp.trim() !== ""){
              let pattern = new RegExp("^([a-z])+[0-9a-z_]*$","ig");
              if(!pattern.test(blockNametemp.trim())){
                props.setMessage("Persistent block name should begin with character and it should contain only character, underscore and numbers.", MessageTypeConst.WARNING_MESSAGE);
                return;
              }
            }
      }
      event.target.disabled = true;
      let buttonTarget = event.target;

      $(".loader").show();
      var optionType=$('input[name=optradio]:checked').val();
      let X = 100;
      let Y = 100;
      if (typeof(global.blockXY) !== 'undefined') {
        X = global.blockXY.split(',')[0];
        Y = global.blockXY.split(',')[1];
      }

      if(props.selectsItems !== undefined && props.selectsItems !== null && props.selectsItems !== "")
      {
          var finalParse = props.selectsItems
          var params = [];
          for (var i = 0; i < finalParse.length; i++) {
              params.push({
                  "blockCreationType":optionType,
                  "newBlockName": blockNametemp.trim(),
                  "blockSaveType":optionType,
                  "xCordinate":X,
                  "yCordinate":Y,
                  "processBlock":finalParse[i] ,
                  "destStudyName": props.schemaName,
                  "destStudyId": props.studyId,
                  "destProcessFlowId": props.processId
              });

              axios.all([restClient(CREATE, 'processflow/paste', {data : params}, {'Tenant-ID': props.schemaName})])
              .then(axios.spread((response) => {
                  if(typeof(response.data.processBlock) !== "undefined" && response.data.processBlock !== null &&
                  response.data.processBlock.length > 0)
                  {
                      let processFlowStatus = [];
                      props.addProcessFlowBlockByTabIndex(props.activeProcessFlow, response.data.processBlock[0]);
                      props.updateProcessflowBlockRenderStatus(props.activeProcessFlow, true);
                      if(response.data.processFlow !== null && typeof(response.data.processFlow) !== "undefined"
                                          && response.data.processFlow.length > 0){
                          response.data.processFlow.forEach((flow) => {
                              processFlowStatus.push({processId:flow.processId,processSyncFlag:flow.processSyncFlag, studyId: flow.studyId});
                              });
                          props.processFlowList.forEach((processFlow, index) => {
                              response.data.processFlow.forEach((flow) => {
                              if (processFlow.process.studyId === flow.studyId && processFlow.process.processId === flow.processId) {
                                  props.updateProcessFlowSyncFlagByTabIndex(index, flow.processSyncFlag);
                                  props.updateProcessflowBlockRenderStatus(index, true);
                              }});
                          });
                      }
                      updateFlowIconInTreeView(processFlowStatus);
                      setNodes([...nodes, ...[createBlock(response.data.processBlock[0])]])

                      props.setMessage("PROCESS BLOCK(s) have been pasted Successfully!", MessageTypeConst.SUCCESS_MESSAGE);
                      props.selectItemsForCopyPaste([]);
                      handlePasteOpen();
                  } else if (typeof(response.data.message) !== 'undefined' && typeof(response.data.messageType) !== 'undefined' && response.data.messageType === MessageTypeConst.WARNING_MESSAGE) {
                    props.setMessage(response.data.message, MessageTypeConst.WARNING_MESSAGE);
                  } else{
                      tprops.setMessage("PROCESS BLOCK paste operation Failed!", MessageTypeConst.ERROR_MESSAGE);
                  }

                  buttonTarget.disabled = false;
                  $(".loader").hide();
              })).catch(error => {
                  $(".loader").hide();
                  buttonTarget.disabled = false;
                  handlePasteOpen();
                  props.setMessage("PROCESS BLOCK paste operation Failed!", MessageTypeConst.ERROR_MESSAGE);
                });
          }
      }
      else{
          handlePasteOpen();
          $(".loader").hide();
      }
  }

  const handlePasteOkMultiple = () => {
    event.preventDefault();
    let options = multiplePasteRef.getWrappedInstance().getPasteData();
    if(options !== null && options.length === 0){
        return;
    }
    event.target.disabled = true;
    let buttonTarget = event.target;

    let X = 100;
    let Y = 100;
    if (typeof(global.blockXY) !== 'undefined') {
      X = global.blockXY.split(',')[0];
      Y = global.blockXY.split(',')[1];
    }
    if(options !== null && options.length > 0){
        $(".loader").show();
        var params = [];
        for (var i = 0; i < options.length; i++) {
            params.push({
                "blockCreationType":options[i].copyAs.value,
                "newBlockName": options[i].destinationBlock,
                "blockSaveType":options[i].copyAs.value,
                "xCordinate":X,
                "yCordinate":Y,
                "processBlock":options[i].item ,
                "destStudyName": props.schemaName,
                "destStudyId": props.studyId,
                "destProcessFlowId": props.processId
            });
        }

        axios.all([restClient(CREATE, 'processflow/paste', {data : params}, {'Tenant-ID': props.schemaName})])
        .then(axios.spread((response) => {
            $(".loader").show();
            if(response.data !== null && typeof(response.data) !== "undefined" && typeof(response.data.processBlock) !== 'undefined' && response.data.processBlock.length > 0){
                let processFlowStatus = [];
                if(typeof(response.data.processBlock) !== "undefined" && response.data.processBlock !== null &&
                    response.data.processBlock.length !== 0)
                {
                    let newBlocks = [];
                    response.data.processBlock.forEach((block) => {
                        props.addProcessFlowBlockByTabIndex(props.activeProcessFlow, block);
                        newBlocks.push(createBlock(block));
                    });

                    setNodes([...nodes, ...newBlocks])
                }

                if(typeof(response.data.processBlockLink) !== "undefined" && response.data.processBlockLink !== null &&
                response.data.processBlockLink.length !== 0){
                    let newEdges = [];
                    response.data.processBlockLink.forEach((link) => {
                        props.addProcessFlowBlockLinkByTabIndex(props.activeProcessFlow, link);
                        newEdges.push(createBlockLink(link));
                    });
                    setEdges([...edges, ...newEdges])
                }
                if(response.data.processFlow !== null && typeof(response.data.processFlow) !== "undefined"
                                        && response.data.processFlow.length > 0){
                response.data.processFlow.forEach((flow) => {
                    if(processFlowStatus.length > 0){
                        let existingFlow = processFlowStatus.filter(item => item.processId === flow.processId && item.processSyncFlag === flow.processSyncFlag);
                        if(existingFlow.length <= 0){
                            processFlowStatus.push({processId:flow.processId,processSyncFlag:flow.processSyncFlag, studyId: flow.studyId});
                        }
                    }else{
                        processFlowStatus.push({processId:flow.processId,processSyncFlag:flow.processSyncFlag, studyId: flow.studyId});
                    }
                    });
                    props.processFlowList.forEach((processFlow, index) => {
                        response.data.processFlow.forEach((flow) => {
                        if (processFlow.process.studyId === flow.studyId && processFlow.process.processId === flow.processId) {
                            props.updateProcessFlowSyncFlagByTabIndex(index, flow.processSyncFlag);
                        }});
                        });
                }
                updateFlowIconInTreeView(processFlowStatus);

                props.selectItemsForCopyPaste([]);
                props.setMessage("PROCESS BLOCK(s) have been pasted Successfully!", MessageTypeConst.SUCCESS_MESSAGE);
                handlePasteOpen();
            } else if (typeof(response.data.message) !== 'undefined' && typeof(response.data.messageType) !== 'undefined' && response.data.messageType === MessageTypeConst.WARNING_MESSAGE) {
              props.setMessage(response.data.message, MessageTypeConst.WARNING_MESSAGE);
            }
            else{
                props.setMessage("PROCESS BLOCK paste operation Failed!", MessageTypeConst.ERROR_MESSAGE);
            }

            buttonTarget.disabled = false;
            $(".loader").hide();
        })).catch(error => {
            buttonTarget.disabled = false;
            $(".loader").hide();
            props.setMessage("PROCESS BLOCK paste operation Failed!", MessageTypeConst.ERROR_MESSAGE);
          });
      }
  }

  const openAddNotesDialog = () => {
      setNoteModel({showAddNotesDialog:true, notesContent:"",newNotes:true, noteId: 0});
  }

  const closeAddNotesDialog = () => {
      setNoteModel({showAddNotesDialog:false, notesContent:"", newNotes:true, noteId: 0});
  }

  const handleAddNotesChange = (event) => {
    setNoteModel({...noteModel, notesContent: event})
  }

  const handleAddNotesOKButton = () => {
    if(noteModel.notesContent === null || noteModel.notesContent.trim() === ""){
        props.setMessage("Notes can't be blank.", MessageTypeConst.WARNING_MESSAGE);
        return;
    }

    let notes = {
        noteId :0,
        studyId: props.studyId,
        processId: props.processId,
        data: noteModel.notesContent,
        noteCoord: global.blockXY
    }
    if(!noteModel.newNotes){
        props.processFlowNotes.filter(processFlowNote => processFlowNote.noteId === noteModel.noteId).forEach(processFlowNote => {
          notes = processFlowNote;
        })

        notes.data = noteModel.notesContent;
        notes.noteId = noteModel.noteId;

        nodes.filter(node => node.type === 'processNote' && node.data.noteId === notes.noteId).forEach(node => {
          notes.noteCoord = node.data.noteCoord
        })
    }

    $(".loader").show();
    axios.all([restClient(CREATE, 'note/add', {data:notes}, {'Tenant-ID': props.schemaName})])
    .then(axios.spread((response) => {
            if (typeof(response.data) !== 'undefined') {
                if (typeof(response.data.messageType) !== 'undefined' && response.data.messageType !== null && response.data.messageType === MessageTypeConst.WARNING_MESSAGE){
                    props.setMessage(response.data.message, response.data.messageType);
                    return true;
                }
                if(!noteModel.newNotes){
                    props.processFlowList.forEach((processFlow, index) => {
                        if (processFlow.schemaName === props.schemaName) {
                          processFlow.process.processFlowNotes.forEach((note, noteIndex) => {
                              if (response.data.noteId === note.noteId) {
                                  props.updateProcessFlowNoteByTabIndex(index, noteIndex, response.data);
                                  setNodes([...nodes.filter(node => !(node.type === 'processNote' && node.data.noteId === response.data.noteId)), ...[createNote(response.data)]])
                                  return true;
                                }
                          })
                        }
                    })
                    props.setMessage("Notes have been updated Successfully!", MessageTypeConst.SUCCESS_MESSAGE);
                }else{
                    props.addProcessFlowNoteByTabIndex(props.activeProcessFlow, response.data);
                    setNodes([...nodes, ...[createNote(response.data)]])
                    props.setMessage("Notes have been created Successfully!", MessageTypeConst.SUCCESS_MESSAGE);
                }
            }
            else{
                props.setMessage("Failed to add Notes!", MessageTypeConst.ERROR_MESSAGE);
            }
            $(".loader").hide();
            closeAddNotesDialog();
        })
    ).catch(error => {
        $(".loader").hide();
        closeAddNotesDialog();
        props.setMessage("Failed to add Notes", MessageTypeConst.ERROR_MESSAGE);
    });
  }

  const onEdgeClick = () => {

  }

  const onPaneClick = () => {
    setOrderNodes([])
  }

  const handleSearchReplaceStudy = () => {
    setSearchReplaceDialog(false)
  }

  const getSourceBlock = () => {
    let treeView = props.treeView;
    let processFlowName = '';
    let processGroupName = '';
    treeView.children.forEach((project, index) => {
      project.children.forEach((study) => {
        if (study.study.studyId === nodes.filter(node => node.selected === true && node.type === 'processBlock')[0].data.studyId) {
          study.children.forEach((studyElement) => {
            if (studyElement.name === 'ProcessFlows') {
              studyElement.children.forEach((classifier) => {
                classifier.children.forEach((processGroup) => {
                  processGroup.children.forEach((flow)=>{
                    if(flow.process.processId === nodes.filter(node => node.selected === true && node.type === 'processBlock')[0].data.processId){
                      processFlowName = flow.name;
                      processGroupName = processGroup.name
                    }
                  })
                });
              })
            }
          })
        }
      });
    })

    return {selected: true, rowId: 0, blockId: nodes.filter(node => node.selected === true && node.type === 'processBlock')[0].data.blockId, blockTable: nodes.filter(node => node.selected === true && node.type === 'processBlock')[0].data.blockTable, blockName: nodes.filter(node => node.selected === true && node.type === 'processBlock')[0].data.blockName + " (v"+nodes.filter(node => node.selected === true && node.type === 'processBlock')[0].data.blockVersion+")", flowName: processFlowName, groupName: processGroupName, processBlock: nodes.filter(node => node.selected === true && node.type === 'processBlock')[0].data}
  }
  return (
    <>
      <div className="process-flow-wrapper">
        {exportNode === true && <Export closeEvent={closeExportEvent} params={{exportType:ExportTypeConst.EXPORT_PROCESS_BLOCK}}/>}
        {createTaskDialogStatus === true && <CreateTask node={triggerNode} closeEvent={handleCreateTaskDialogClick} />}
        {pasteModel === true && props.selectsItems.length === 1 && <div className="study-properties-main">
          <Dialog className="study-properties" title={"Paste Option"} width="450px" height="auto" modal={true} onClose={handlePasteOpen} buttons={[<Button primary={true} className="k-button" onClick={handlePasteOk} key="btn-ok">OK</Button>,<Button className="k-button" onClick={handlePasteOpen} key="btn-cancel">Cancel</Button>]}>
            <SingleBlockPasteDialog selectsItems={props.selectsItems} currentStudyId={props.studyId} currentProcessId={props.processId} />
          </Dialog>
        </div>}
        {pasteModel === true && props.selectsItems.length > 1 && <div className="study-properties-main multiple-copy-paste">
          <Dialog className="study-properties" title={"Paste Option"} width="670px" height="auto" modal={true} onClose={handlePasteOpen} buttons={[<Button primary={true} className="k-button" onClick={handlePasteOkMultiple} key="btn-ok">OK</Button>,<Button className="k-button" onClick={handlePasteOpen} key="btn-cancel">Cancel</Button>]}>
            <MultipleBlockPasteDialog selectedItems={props.selectsItems} currentStudyId={props.studyId} currentProcessId={props.processId} ref={ref => multiplePasteRef = ref} />
          </Dialog>
        </div>}
        {noteModel.showAddNotesDialog === true &&
            <div className="study-properties-main note">
            <Dialog className="study-properties" title="Notes" width="calc(100% - 10px)" height="calc(100vh - 20px)" modal={true} onClose={closeAddNotesDialog} buttons={[<Button primary={true} onClick={handleAddNotesOKButton} disabled={typeof(global.login_token) !== 'undefined'  && !(props.isFlowCheckedOutByLoggedInUser && props.isFlowCheckedOut)} key="btn-save"> Save</Button>,<Button onClick={closeAddNotesDialog} key="btn-cancel"> Cancel</Button>]}>
              <div className="container-fluid m-t-5">
                <div className="row">
                  <div className="am-form-field">
                    <span className="title"><b>Notes Details:</b></span>
                      <RichTextEditor content={noteModel.notesContent} onChange={handleAddNotesChange}/>
                  </div>
                </div>
              </div>
            </Dialog>
            </div>
        }
        {searchReplaceDialog === true && nodes.filter(node => node.selected === true && node.type === 'processBlock').length > 0 &&
          <SearchReplace node={{name: nodes.filter(node => node.selected === true && node.type === 'processBlock')[0].data.label, study: {schemaName: nodes.filter(node => node.selected === true && node.type === 'processBlock')[0].data.schemaName}}} closeEvent={handleSearchReplaceStudy} searchSourceBlockList={[getSourceBlock()]} sourceBlockStatus={false}/>
        }
        {props.readOnly === true ? (
          <ReactFlow
            nodes={nodes}
            nodeTypes={nodeTypes}
            onNodesChange={onNodesChange}
            edges={edges}
            onEdgesChange={onEdgesChange}
            edgeTypes={edgeTypes}
            onConnect={onConnect}
            connectionMode={ConnectionMode.Loose}
            selectionOnDrag={false}
            panOnDrag={false}
            id={props.id}
            >
            <Background id={props.id+'-background'} />
          </ReactFlow>
        ) : (
          <ReactFlow
            nodes={nodes}
            nodeTypes={nodeTypes}
            onNodesChange={onNodesChange}
            edges={edges}
            onEdgesChange={onEdgesChange}
            edgeTypes={edgeTypes}
            onConnect={onConnect}
            connectionMode={ConnectionMode.Loose}
            onNodeClick={onNodeClick}
            onNodeDoubleClick={onNodeDoubleClick}
            onNodeContextMenu={onNodeContextMenu}
            onEdgeClick={onEdgeClick}
            onSelectionContextMenu={onSelectionContextMenu}
            onPaneContextMenu={onPaneContextMenu}
            selectionOnDrag={true}
            panOnDrag={false}
            deleteKeyCode={[]}
            multiSelectionKeyCode={['Meta', 'Control']}
            onNodeDragStart={onNodeDragStart}
            onNodeDragStop={onNodeDragStop}
            onSelectionDragStop={onSelectionDragStop}
            onPaneClick={onPaneClick}
            id={props.id}
            >
              <Background id={props.id+'-background'} />
              <ContextMenuTrigger id={`data-operation-identifier-${props.studyId}-${props.processId}`} ref={c => contextDataOperationTrigger = c}>
                <div className="well"></div>
              </ContextMenuTrigger>

              {nodes.filter(node => node.selected === true && node.type === 'processBlock').length > 0 ? (
                <ContextMenu id={`data-operation-identifier-${props.studyId}-${props.processId}`}>
                  {datOperationMenuItemList().map(DynamicMenuItem)}
                </ContextMenu>
              ) : (null)}

              {nodes.filter(node => node.selected === true && node.type === 'processNote').length > 0 ? (
                <ContextMenu id={`data-operation-identifier-${props.studyId}-${props.processId}`}>
                  <MenuItem onClick={editNoteNode}>Edit</MenuItem>
                  <MenuItem divider />
                  <MenuItem onClick={deleteNodes}>Delete</MenuItem>
                </ContextMenu>
              ) : (null)}

              <ContextMenuTrigger id={`process-flow-identifier-${props.studyId}-${props.processId}`} ref={c => contextProcessFlowTrigger = c}>
                <div className="well"></div>
              </ContextMenuTrigger>

              {!(typeof(global.login_token) !== 'undefined' && !(props.isFlowCheckedOut  && props.isFlowCheckedOutByLoggedInUser)) ? (
                <ContextMenu id={`process-flow-identifier-${props.studyId}-${props.processId}`}>
                  <SubMenu title='AUTO'>
                    <MenuItem onClick={handleAddBlockOperationClick} data={{ item: 'mapper', quickColumns: "", hash:"annotate?type=mapper" }}>Mapper</MenuItem>
                    <MenuItem onClick={handleAddBlockOperationClick} data={{ item: 'tlf', quickColumns: "", hash:"annotate?type=tlf" }}>TLF</MenuItem>
                  </SubMenu>
                  <MenuItem onClick={openAddNotesDialog} data={{ item: Global_Context.addNotesItem, quickColumns: "", hash:"" }}>{Global_Context.addNotesItem}</MenuItem>
                  <MenuItem onClick={handleAddBlockOperationClick} data={{ item: Global_Context.dataWizardItem, quickColumns: "", hash:"" }}>{Global_Context.dataWizardItem}</MenuItem>
                  <MenuItem onClick={handleAddBlockOperationClick} data={{ item: Global_Context.pasteBlockItem, quickColumns: "", value: Global_Context.pasteBlockItem, hash:"" }}>{Global_Context.pasteBlockItem}</MenuItem>
                  <SubMenu title='Select Source Table' className={`${sourceTableList.length > 7 ? 'dynamic-menu': ''}`}>
                    {sourceTableList.map(sourceTable => {
                      return (
                        <MenuItem key={sourceTable.tableName} onClick={handleAddBlockClick} data={{item: sourceTable.srcTableName, importType: 0}}>{sourceTable.srcTableName}</MenuItem>
                      )
                    })
                    }
                  </SubMenu>
                  <SubMenu title='Select Meta Table' className={`${metaTableList.length > 7 ? 'dynamic-menu': ''}`}>
                    {metaTableList.map(metaTable => {
                      return (
                        <MenuItem key={metaTable.tableName} onClick={handleAddBlockClick} data={{item: metaTable.srcTableName, importType: 1}}>{metaTable.srcTableName}</MenuItem>
                      )
                    })
                    }
                  </SubMenu>
                  <SubMenu title='Select Document' className={`${documentsList.length > 7 ? 'dynamic-menu': ''}`}>
                    {documentsList.map(document => {
                      return (
                        <MenuItem key={document.fileId} onClick={handleAddBlockClick} data={{item: document.filename, importType: 2}}>{document.filename}</MenuItem>
                      )
                    })
                    }
                  </SubMenu>
                </ContextMenu>
              ) : (null)}
            </ReactFlow>
        )}


      </div>
    </>

  );
}

const mapStateToProps = createSelector(
  state => state.processFlow.processFlowList,
  state => state.treeView.data,
  state => state.processFlow.tenantId,
  state => state.processFlow.processFlowAction,
  state => state.processFlow.processFlowTable,
  state => state.processFlow.processFlowTableId,
  state => state.processFlow.quickcondition,
  state => state.processFlow.submenucolumns,
  state => state.processFlow.importTable,
  state => state.processFlow.processFlowTitle,
  state => state.processFlow.activeProcessFlow,
  state => state.processFlow.selectsItems,
  state => state.processFlow.refreshToken,
  state => state.login.token_detail,
  state => state.processFlow.jumpToBlockId,
  state => state.processFlow.jumpToProcessId,
  (processFlowList, treeView, tenantId, processFlowAction, processFlowTable, processFlowTableId, quickcondition, submenucolumns, importTable, processFlowTitle, activeProcessFlow, selectsItems, refreshToken, token_detail, jumpToBlockId, jumpToProcessId) => ({
    processFlowList,
    treeView,
    tenantId,
    processFlowAction,
    processFlowTable,
    processFlowTableId,
    quickcondition,
    submenucolumns,
    importTable,
    processFlowTitle,
    activeProcessFlow,
    selectsItems,
    refreshToken,
    token_detail,
    jumpToBlockId,
    jumpToProcessId
  })
);

const mapActionsToProps = {
  setTreeViewData: setTreeViewData,
  setTenantId: setTenantId,
  removeProcessFlowBlockByTabIndex: removeProcessFlowBlockByTabIndex,
  addProcessFlowBlockByTabIndex: addProcessFlowBlockByTabIndex,
  addProcessFlowBlockLinkByTabIndex:addProcessFlowBlockLinkByTabIndex,
  setProcessFlowAction: setProcessFlowAction,
  setProcessFlowTable: setProcessFlowTable,
  setProcessFlowTableId: setProcessFlowTableId,
  setQuickCondition: setQuickCondition,
  setSubMenuColumns: setSubMenuColumns,
  updateProcessFlowBlockByTabIndex: updateProcessFlowBlockByTabIndex,
  updateProcessflowBlockRenderStatus: updateProcessflowBlockRenderStatus,
  setActiveProcessFlow: setActiveProcessFlow,
  setALLProcessFlowRenderStatus: setALLProcessFlowRenderStatus,
  selectItemsForCopyPaste: selectItemsForCopyPaste,
  removeProcessFlowBlockLinkByTabIndex: removeProcessFlowBlockLinkByTabIndex,
  refreshProcessFlow: refreshProcessFlow,
  updateProcessFlowBlockBySchemaName : updateProcessFlowBlockBySchemaName,
  updateProcessFlowSyncFlagByTabIndex: updateProcessFlowSyncFlagByTabIndex,
  updateProcessFlowCheckedOutStatusByTabIndex: updateProcessFlowCheckedOutStatusByTabIndex,
  setMessage: setMessage,
  setJumpToBlockIdProcessId: setJumpToBlockIdProcessId,
  removeProcessFlowNoteByTabIndex: removeProcessFlowNoteByTabIndex,
  addProcessFlowNoteByTabIndex: addProcessFlowNoteByTabIndex,
  updateProcessFlowNoteByTabIndex: updateProcessFlowNoteByTabIndex,
  updateProcessFlowNoteBySchemaName: updateProcessFlowNoteBySchemaName,
}

export default connect(mapStateToProps, mapActionsToProps)(ProcessFlow);
