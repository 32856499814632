import React, { useState, useRef, useEffect } from "react";
import { Button } from './../../DataOperation/common/Button/StandardButton';
import { Input } from './../../DataOperation/common/InputBox/StandardInput';
import { prop } from "lodash/fp";

type Props = {

};

const Annotation = (props: Prop) => {
  // Manage the state whether to show the label or input field based on events
  const [isEditing, setIsEditing] = useState(false);

  const inputRef = useRef();

  // using effect, when isEditing changes
  useEffect(() => {
    if(inputRef && inputRef.current && isEditing){
      inputRef.current.focus();
    }
  }, [isEditing])

  // Event handler while pressing any key while editing
  const handleKeyDown = event => {
    // handle when key is pressed
    const {key} = event;
    const keys = ["Escape", "Tab", "Enter"];
    if(keys.indexOf(key) > -1){
      setIsEditing(false);
    }
  };

  // Event handler while pressing any key while editing
  const handleClick = event => {
    if(event.altKey){
      setIsEditing(true)
    }
  };

  const style = {
    left: props.highlight.data.position.left - (props.highlight.position.boundingRect.left + props.highlight.position.boundingRect.width),
    top: props.highlight.data.position.top - props.highlight.position.boundingRect.top
  }

  return (
    <section className="annotation__item" style={style}>
      {isEditing ? (
        <div
          onBlur={() => setIsEditing(false)}
          onKeyDown={e => handleKeyDown(e)}
        >
          <Input
            ref={inputRef}
            type="text"
            name="annotation"
            value={props.highlight.data.title}
            onChange={e => props.updateHighlight(e.target.value)}
          />
        </div>
      ) : (
        <div
          onClick={e => handleClick(e)}
        >
          <span>
            {props.highlight.data.title}
          </span>
        </div>
      )}
      {!isEditing && <Button className="annotation--remove" iconClass="fa fa-close" onClick={() => props.removeHighlight()}></Button>}
    </section>
  )
};

export default Annotation;
