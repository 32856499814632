import React from 'react';

export default class Draggable extends React.Component {
  state = {
    isDragging: false,

    originalX: 0,
    originalY: 0,

    translateX: 0,
    translateY: 0,

    lastTranslateX: 0,
    lastTranslateY: 0
  };

  componentWillUnmount() {
    window.removeEventListener('mousemove', this.handleMouseMove);
    window.removeEventListener('mouseup', this.handleMouseUp);
  }

  handleMouseDown = (e) => {
    const { clientX, clientY } = e;
    window.addEventListener('mousemove', this.handleMouseMove);
    window.addEventListener('mouseup', this.handleMouseUp);
    if (this.props.onDragStart) {
      this.props.onDragStart();
    }

    this.setState({
      originalX: clientX,
      originalY: clientY,
      isDragging: true
    });
  };

  handleMouseMove = ({ clientX, clientY }) => {
    const { isDragging } = this.state;
    const { onDrag } = this.props;

    if (!isDragging) {
      return;
    }

    this.setState(prevState => ({
      translateX: clientX - prevState.originalX + prevState.lastTranslateX,
      translateY: clientY - prevState.originalY + prevState.lastTranslateY
    }), () => {
      if (onDrag) {
        onDrag({
          translateX: this.state.translateX,
          translateY: this.state.translateY
        });
      }
    });
  };

  handleMouseUp = (e) => {
    window.removeEventListener('mousemove', this.handleMouseMove);
    window.removeEventListener('mouseup', this.handleMouseUp);
    const translateX =  this.state.translateX;
    const translateY =  this.state.translateY;

    this.setState(
      {
        originalX: 0,
        originalY: 0,
        lastTranslateX: 0,
        lastTranslateY: 0,
        translateX: 0,
        translateY: 0,

        isDragging: false
      },
      () => {
        if (this.props.onDragEnd) {
          this.props.onDragEnd({
            translateX,
            translateY,
            e
          });
        }
      }
    );
  };

  render() {
    const { children } = this.props;
    const { translateX, translateY, isDragging } = this.state;

    let style = {
      transform: `translate(${translateX}px, ${translateY}px)`,
      cursor: 'grab'
    }

    if(isDragging){
      style = {
        ...style,
        opacity: 0.8,
        cursor: 'grabbing'
      }
    }

    return (
      <div
        onMouseDown={this.handleMouseDown}
        style={style}
      >
        {children}
      </div>
    );
  }
}
