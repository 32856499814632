import "./../../../Annotate/Annotate.css";
import React from 'react';
import { Button } from './../Button/StandardButton';
import { Input } from './../InputBox/StandardInput';
import Dialog from 'react-dialog';
import { connect } from 'react-redux';
import { PropTypes } from 'prop-types';
import { createSelector } from 'reselect';
import cloneDeep from 'lodash/cloneDeep';
import $ from 'jquery';
import axios from 'axios';
import restClient from './../../../../../restClient';
import { CREATE, POST_PARAM_BODY } from './../../../../../restClient/types';
import { setMessage } from '../../../../../actions/actionNotification';
import PdfViewAndHighlights from './../../../Annotate/PdfViewAndHighlights';
import {Treebeard, decorators} from 'react-treebeard';
import TreeView from './../../../TreeView';
import { setHighlightList } from './../../../../../actions/actionAnnotate';
import MessageTypeConst from './../../../../MessageTypeConst';

export const TagValueWithDocumentCell = ({ tableManager, value, data, column, colIndex, rowIndex }) => {
    if (typeof(tableManager) === 'undefined') {
      return (null)
    } else {
      return (
          <div className='rgt-cell-inner' style={{display: 'flex', alignItems: 'center', overflow: 'hidden'}}>
              <TagValueWithDocumentCellClass dataItem={data} field={column.field} value={typeof(data[column.field]) !== 'undefined' ? data[column.field]: value} column={column} tableManager={tableManager}/>
          </div>
      )
    }
}

class BaseTagValueWithDocumentCell extends React.Component {

    constructor(props) {
      super(props);
      this.state = {
        visible: false,
        documentList: [{documentId: 1, documentName: 'Test 1'}],
        selectedDocument: {documentId: 1, documentName: 'Test 1'},
        fileURL: '',
        studyTreeView: '',
        nodeTagList: [],
        style: {
          width: '100%',
          height: '100%'
        },
      };
      this.closeDialog = this.closeDialog.bind(this);
      this.openDialog = this.openDialog.bind(this);
      this.handleSelectChange = this.handleSelectChange.bind(this);
      this.onToggle = this.onToggle.bind(this);

      global.pageMarkList = [];
      global.lastPageNumber = 0;
    }

    fetchDocumentURL = (headers, params) => restClient(CREATE, 'getPreSignedUrl', params, headers);

    fetchTagList = (headers, params) => restClient(POST_PARAM_BODY, 'tag/filter', params, headers);

    handleChange(e) {
      this.props.dataItem[this.props.field] = e.target.value;
      this.props.tableManager.rowsApi.setRows(this.props.tableManager.rowsApi.rows)
    }

    handleSelectChange(event) {
      this.setState({selectedDocument: event.target.value})
    }

    openDialog(){
      let that = this;
      let params = {
      	studySchemaName : this.props.dataItem.schemaName,
      	fileName: this.props.dataItem['fileName'],
      	contentType: "application/pdf"
      }

      let tagParams = {
        objectId: 0,
        objectType: 0,
        documentId: this.props.dataItem['documentId']
      }

      if (this.props.dataItem[this.props.field] !== '') {
        if (this.props.dataItem[this.props.field] !== '' && this.props.dataItem[this.props.field] !== null) {
          let highlightList = []
          if (typeof(this.props.dataItem[this.props.field]) === 'string') {
            const tagValue = JSON.parse(this.props.dataItem[this.props.field])

            if (typeof(tagValue.highlightList) !== 'undefined') {
                highlightList = tagValue.highlightList;
            }
          } else {
            highlightList = this.props.dataItem[this.props.field]
          }

          if (typeof(this.props.dataItem['highlightList']) !== 'undefined') {
            highlightList = this.props.dataItem['highlightList']
          }

          this.props.setHighlightList(highlightList)
        }
      }

      $(".loader").show();
      axios.all([this.fetchDocumentURL({'Tenant-ID': this.props.dataItem.schemaName}, { data: params }), this.fetchTagList({'Tenant-ID': this.props.dataItem.schemaName}, {data: tagParams})])
             .then(axios.spread((document, tagResponse) => {
                let nodeTagList = [];
                if (typeof(tagResponse.data) !== 'undefined') {
                  let highlightList = [];

                  tagResponse.data.map(tag => {
                    if (tag.documentId > 0 && tag.tagValue !== '') {
                      const tagHighlightList = JSON.parse(tag.tagValue)

                      if (typeof(tagHighlightList.highlightList) !== 'undefined') {
                        highlightList.push(...tagHighlightList.highlightList)

                        tag.tagValue = JSON.stringify({highlightList: []})
                      }
                    }

                    return tag
                  })

                  if (highlightList.length > 0 && typeof(that.props.dataItem['isFetchData']) === 'undefined') {
                    this.props.setHighlightList(highlightList)
                    that.props.dataItem['highlightList'] = cloneDeep(highlightList);
                  } else if (typeof(that.props.dataItem['highlightList']) !== 'undefined' && that.props.dataItem['highlightList'].length > 0){
                    this.props.setHighlightList(that.props.dataItem['highlightList'])
                  }

                  nodeTagList.length = 0;
                  if (tagResponse.data.length > 0) {
                    nodeTagList.push(...tagResponse.data)
                  }

                } else {
                  that.props.setMessage("Failed to fetch document annotate tag details.",MessageTypeConst.ERROR_MESSAGE);
                }

                if (typeof(document.data) !== 'undefined' && typeof(document.data.file) !== 'undefined' && document.data.file !== '') {
                  that.setState({fileURL: document.data.file, visible: true, studyTreeView: this.getStudyTreeView(), nodeTagList: nodeTagList})
                  that.props.dataItem['isFetchData'] = true;
                } else{
                  that.props.setMessage("Failed to fetch document file.",MessageTypeConst.ERROR_MESSAGE);
                }

                $(".loader").hide();
            })).catch(error => {
                 $(".loader").hide();
                 that.props.setMessage("Failed to fetch document file.",MessageTypeConst.ERROR_MESSAGE);
             })
    }

    closeDialog(e, savedType = false) {
      this.setState({visible: false, studyTreeView: ''});
      this.props.setHighlightList([])

      if (savedType === true) {
        this.props.dataItem[this.props.field] = this.props.highlightList;
        this.props.dataItem['nodeTagList'] = this.state.nodeTagList;
        this.props.dataItem['highlightList'] = this.props.highlightList;
        this.props.dataItem[this.props.field] = this.props.highlightList;
        this.props.tableManager.rowsApi.setRows(this.props.tableManager.rowsApi.rows)
      }
    }

    onToggle(node, toggled){
        const {cursor} = this.state;
        if (cursor) {
            this.setState(() => ({cursor, active: false}));
        }
        node.active = true;
        if (node.children) {
            node.toggled = toggled;
        }
        this.setState(() => ({cursor: node}));
    }

    getStudyTreeView(){
      let studyTreeView = '';
      this.props.treeView.children.forEach(project => {
        project.children.filter(study => study.study.schemaName === this.props.dataItem.schemaName).forEach(study => {
          studyTreeView = {
            ...cloneDeep(study),
            id: 'navigation_annotate'
          }
        })
      })

      if (studyTreeView !== '') {
        studyTreeView.newName = [studyTreeView.name]
        if (typeof(this.props.dataItem.node.study) !== 'undefined' && this.props.dataItem.node.study.schemaName === studyTreeView.study.schemaName) {
          studyTreeView.columnStatus = 'blue'
        }

        studyTreeView.children.forEach((studyElement, index) => {
          const elementId = studyElement.name.replace(' ', '').toLocaleLowerCase();
          studyTreeView.children[index].id = elementId+'_annotate';
          studyElement.children.forEach((elementNode, nIndex) => {
            if (typeof(elementNode.fileId) !== 'undefined') {
              studyTreeView.children[index].children[nIndex].id = elementId+'_node_'+elementNode.fileId+'_annotate';
            } else if (typeof(elementNode.tableName) !== 'undefined') {
              studyTreeView.children[index].children[nIndex].id = elementId+'_node_'+elementNode.tableName+'_annotate';
            } else if (typeof(elementNode.classifierId) !== 'undefined') {
              const classifierElementId = elementId+'_node_'+elementNode.classifierId;
              studyTreeView.children[index].children[nIndex].id = classifierElementId+'_annotate';

              elementNode.children.forEach((groupNode, groupIndex) => {
                const groupElementId = classifierElementId+'_'+groupNode.schemaName+'_group_'+groupNode.processGroupId;
                studyTreeView.children[index].children[nIndex].children[groupIndex].id = groupElementId+'_annotate';
                studyTreeView.children[index].children[nIndex].children[groupIndex].newName = [studyTreeView.children[index].children[nIndex].children[groupIndex].name]

                if (typeof(this.props.dataItem.node.processGroupId) !== 'undefined' && this.props.dataItem.node.processGroupId ===  groupNode.processGroupId) {
                  studyTreeView.children[index].children[nIndex].children[groupIndex].columnStatus = 'blue'
                }
                groupNode.children.forEach((processFlow, flowIndex) => {
                  const processFlowElementId = groupElementId+'_flow_'+processFlow.process.processId;
                  studyTreeView.children[index].children[nIndex].children[groupIndex].children[flowIndex].id = processFlowElementId+'_annotate'
                  studyTreeView.children[index].children[nIndex].children[groupIndex].children[flowIndex].newName = [studyTreeView.children[index].children[nIndex].children[groupIndex].children[flowIndex].name]

                  if (typeof(this.props.dataItem.node.process) !== 'undefined' && this.props.dataItem.node.process.processId === processFlow.process.processId) {
                    studyTreeView.children[index].children[nIndex].children[groupIndex].children[flowIndex].columnStatus = 'blue'
                  }

                  if (processFlow.process.processId === this.props.dataItem.node.processId) {
                    studyTreeView.children[index].children[nIndex].children[groupIndex].children[flowIndex].toggled = true;
                    studyTreeView.children[index].children[nIndex].children[groupIndex].children[flowIndex].children = [{
                      id: processFlowElementId+'_block_'+this.props.dataItem.node.blockId+'_annotate',
                      name: this.props.dataItem.node.blockName + '('+this.props.dataItem.node.blockTable+')',
                      newName: [this.props.dataItem.node.blockName + '('+this.props.dataItem.node.blockTable+')'],
                      cssClassName: 'manage-study-process-block',
                      iconClass: 'fa fa-tag',
                      columnStatus: 'blue',
                      block: this.props.dataItem.node,
                      schemaName: this.props.dataItem.node.schemaName
                    }]
                  }
                })
              })
            }

          })
        })
      }
      return studyTreeView;
    }

    render() {
      return (
            <div className="text-center width-full">
              {this.props.dataItem['type'] === 'Text' &&
                <Input className="width-full" onChange={this.handleChange.bind(this)} value={this.props.dataItem[this.props.field]} />
              }
              {this.props.dataItem['type'] === 'Document' &&
                <Button onClick={this.openDialog}>Tag Document</Button>
              }
              {this.state.visible &&
                <div className="tag-document-dialog annotate" >
                  <Dialog title={"Tag Document"} width="calc(100% - 10px)" height="calc(100vh - 20px)" modal={true} onClose={this.closeDialog}>
                    <div className="container-fluid">
                      <div className='svg-line-mapper hidden'>
                        <svg style={this.state.style}>
                        </svg>
                      </div>
                      {this.state.fileURL !== '' &&
                        <div className="row pdf-annotate">
                          <div className="col-9">
                            <PdfViewAndHighlights fileURL={this.state.fileURL} type="tag" treeView={this.state.studyTreeView} closeDialog={this.closeDialog} />
                          </div>
                          <div className={'col-3 target-tablelist tree-view'}>
                            <div className={'target treeview-content align-left'}>
                              {this.state.studyTreeView !== '' &&
                                <TreeView treeView={this.state.studyTreeView} id={'standard-treeview'} />
                              }
                              {this.state.studyTreeView === '' &&
                                <div>Please wait....</div>
                              }
                            </div>
                          </div>
                        </div>
                      }
                    </div>
                  </Dialog>
                </div>
              }
            </div>
        );
    }
}

const mapStateToProps = createSelector(
    state => state.processFlow.tenantId,
    state => state.treeView.data,
    state => state.annotate.highlightList,
    (tenantId, treeView, highlightList) => ({
        tenantId,
        treeView,
        highlightList
    })
  );

  const mapActionsToProps = {
    setMessage: setMessage,
    setHighlightList: setHighlightList
  }

  BaseTagValueWithDocumentCell.propTypes = {
    tenantId: PropTypes.string
  }

  Dialog.propTypes = {
      height: PropTypes.oneOfType([
          PropTypes.string
      ]),
      width: PropTypes.oneOfType([
          PropTypes.string
      ])
  }
const TagValueWithDocumentCellClass = connect(mapStateToProps, mapActionsToProps)(BaseTagValueWithDocumentCell);
