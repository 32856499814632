import { SET_TOKEN } from '../actions/actionsTypes';

const INITIAL_DATA = {
  token: null,
  token_type: null,
  expires_in: null,
  access_token: null,
  refresh_token: null,
  token_detail: {},
  cognito_user_pool_id: null
};

export default function loginReducer(state=INITIAL_DATA, action) {
  switch (action.type) {
    case SET_TOKEN:
      global.login_token = action.payload;
      let cognito_user_pool_id = state.cognito_user_pool_id;

      if (typeof(action.payload.token_detail) !== 'undefined' && typeof(action.payload.token_detail.iss) !== 'undefined') {
        cognito_user_pool_id = action.payload.token_detail.iss.split('/').pop();
      }

      return {
        ...state,
        token: action.payload.id_token,
        token_type: typeof(action.payload.token_type) !== 'undefined' ? action.payload.token_type : state.token_type,
        expires_in: typeof(action.payload.expires_in) !== 'undefined' ? action.payload.expires_in : state.expires_in,
        access_token: action.payload.access_token,
        refresh_token: action.payload.refresh_token,
        token_detail: action.payload.token_detail,
        cognito_user_pool_id: cognito_user_pool_id
    }
    default:
      return state;
  }
}
