import React    from "react";
import axios from 'axios';
import { connect } from 'react-redux';
import { createSelector } from 'reselect';
import moment from 'moment';
import $ from 'jquery';
import ClassNames from 'classnames'
import template from "./FileManager.jsx";
import restClient from '../../../restClient';
import { GET } from '../../../restClient/types';
import { extensionMapping } from './constants';

function fileSize(size) {
  if (size > 1024) {
    const kbSize = size / 1024
    if (kbSize > 1024) {
      const mbSize = kbSize / 1024
      return `${floatPrecision(mbSize, 2)} MB`
    }
    return `${Math.round(kbSize)} kB`
  }
  return `${size} B`
}

function floatPrecision(floatValue, precision) {
  floatValue = parseFloat(floatValue)
  if (isNaN(floatValue)) { return parseFloat('0').toFixed(precision) } else {
    const power = Math.pow(10, precision)
    floatValue = (Math.round(floatValue * power) / power).toFixed(precision)
    return floatValue.toString()
  }
}

class FileManager extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      files: [],
      node: props.node,
      onSelectFile: props.onSelectFile,
      selectedFiles:[],
      currentSelectFile: null,
      isGetFiles: false
    }

    this.handleSelectFile = this.handleSelectFile.bind(this);
    this.handleSelectFolder = this.handleSelectFolder.bind(this);
    this.itemSelectionByEvent = this.itemSelectionByEvent.bind(this);
  }

  fetchFileList = (headers, params, studyName) => restClient(GET, 'getServerFiles/list?studyName='+studyName, params, headers);

  componentDidMount() {

    let tenantId = this.state.node.schemaName;
    let studyName = this.state.node.schemaName;

    if (typeof(global.selectedStudy) !== 'undefined') {
      studyName = global.selectedStudy.studyName
    }

    $(".loader").show();
    axios.all([this.fetchFileList({'Tenant-ID': tenantId}, {}, studyName)])
          .then(axios.spread((response) => {

            if (typeof(response.data) !== 'undefined') {
              let files = response.data;
              files.forEach((file) => {
                let date = file.modified.split(' ');
                file.modified = moment(date[0], "MM/DD/YYYY");
                file.isSelected = false;
              })
              this.setState({files: files, isGetFiles: true})
            }

            $(".loader").hide();
          })
        );
  }

  handleSelectFile = (event) => {
    let selectedFiles = this.state.selectedFiles;
    selectedFiles.length = 0;
    selectedFiles.push(event.key);

    this.setState({selectedFiles: selectedFiles, currentSelectFile: event.key});

    this.state.onSelectFile(selectedFiles);
  }

  handleSelectFolder = (event) => {
    this.state.onSelectFile('');
  }

  getName(file) {
    let name = file.newKey || file.fileKey
    const slashIndex = name.lastIndexOf('/')
    if (slashIndex !== -1) {
      name = name.substr(slashIndex + 1)
    }
    return name
  }

  getExtension(fileKey) {
    const blobs = fileKey.split('.')
    return blobs[blobs.length - 1].toLowerCase().trim()
  }

  getFileType(fileKey) {
    return extensionMapping[this.getExtension(fileKey)] || 'File'
  }

  handleFileRenderer = (file) => {
    const { browserProps,depth, size, modified } = file

    const fileKey = file.newKey || file.fileKey;

    const icon = browserProps.icons[this.getFileType(fileKey)] || browserProps.icons.File

    let name = (
        <a
          href="#"
          onClick={this.handleFileClick}
          id={fileKey}
        >
          {icon}
          {this.getName(file)}
        </a>
    )

    let selected = file.isSelected;

    if (this.state.selectedFiles.indexOf(fileKey) > -1) {
      selected = true;
    }

    return (
      <tr
        className={ClassNames('file key'+ fileKey, {
          selected: selected,
        })}
        onClick={event => browserProps.select(fileKey, 'file')}
      >
        <td className="name">
          <div style={{ paddingLeft: (depth * 16) + 'px' }}>
            {name}
          </div>
        </td>
        <td className="size">{fileSize(size)}</td>
        <td className="modified">
          {typeof modified === 'undefined' ? '-' : moment(modified, 'x').fromNow()}
        </td>
      </tr>
    )
  }

  handleKeyEvent = (key, event) => {
    if (key === 'shift' && event.code === 'ArrowUp') {
      this.itemSelectionByEvent();
    } else if(key === 'shift' && event.code === 'ArrowDown') {
      this.itemSelectionByEvent('down');
    }
  }

  itemSelectionByEvent = (type = 'up') => {
    let files = this.state.files;
    let selectFileIndex = null;

    files.forEach((file, index) => {
      if (file.key === this.state.currentSelectFile) {
        selectFileIndex = index;
      }
    })

    if (selectFileIndex !== null) {
      selectFileIndex = selectFileIndex + (type === 'up' ? -1: 1)
    }

    if (typeof(files[selectFileIndex]) !== 'undefined') {
      let selectedFiles = this.state.selectedFiles;

      if (selectedFiles.indexOf(files[selectFileIndex].key) === -1) {
        selectedFiles.push(files[selectFileIndex].key);
      }

      this.setState({selectedFiles: selectedFiles, currentSelectFile: files[selectFileIndex].key, files: files});
    }
  }

  render() {
    return template.call(this);
  }
}

const mapStateToProps = createSelector(
  state => state.processFlow.tenantId,
  (tenantId) => ({
    tenantId
  })
);

const mapActionsToProps = {

}

export default connect(mapStateToProps, mapActionsToProps)(FileManager);
