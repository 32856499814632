import React    from "react";
import { PropTypes } from "prop-types";
import cloneDeep from 'lodash/cloneDeep';
import { connect } from 'react-redux';
import { createSelector } from 'reselect';
import Dialog from 'react-dialog';
import { Input } from './../DataOperation/common/InputBox/StandardInput';
import { Button } from './../DataOperation/common/Button/StandardButton';
import { DropDownList } from './../DataOperation/common/DropDown/StandardDropDownList';
import { addApplyProcessFlow, removeApplyProcessFlow, setTreeElement } from './../../../actions/actionAnnotate';
import { setConstructiveOperationStatus } from './../../../actions/actionDataOperation';
import { setTreeViewData } from './../../../actions/actionTreeView';
import { MultiSelectDropDownList } from './../DataOperation/common';
import axios from 'axios';
import $ from 'jquery';
import MessageTypeConst from '../../MessageTypeConst';
import restClient from '../../../restClient';
import { CREATE, GET, GET_ONE } from '../../../restClient/types';
import ProcessFlow from './../ProcessFlow';
import { createShape, createNotes } from '../../ProcessFlow/ProcessFlows.js';
import { setMessage } from './../../../actions/actionNotification';
import ConstructiveOperation from './../UpdateBlock/ConstructiveOperation.js';
import TreeView from './../TreeView';
import {manageProcessInTreeView, manageProceeFlowGroupInTreeView, manageClassifierInTreeView, manageStudyElementsInTreeView} from './../NavigationBarMenu/NavigationBarMenu'
import ConfirmBox from './../DataOperation/common/ConfirmBox';

class ApplyProcessFlow extends React.Component {
    constructor(props) {
      super(props);

      const autoApplyProcessFlowStatus = typeof(props.autoApplyProcessFlowStatus) !== "undefined" ? props.autoApplyProcessFlowStatus: false
      this.state = {
        node: props.node,
        childrenNode: {},
        closeEvent:props.onClose,
        projectList:[{projectId:0,projectName:''}],
        studyList:[{studyId:0,studyName:'',schemaName:''}],
        selectedProject:{projectId:0,projectName:''},
        selectedStudy:{studyId:0,studyName:'',schemaName:''},
        selectedGroup:{groupId:0,groupName:''},
        selectedFlow:{processFlowId:0,processFlowName:''},
        groupList:[{groupId:0,groupName:''}],
        flowList:[{processFlowId:0,processFlowName:''}],
        blockAndLinkData:{processBlock:[],processBlockLink:[],processFlowNotes:[]},
        applyProcessFlow:{},
        goToInputStructureOperation: false,
        constructiveOperationData: {},
        saveButtonStatus: false,
        autoApplyProcessFlowStatus: autoApplyProcessFlowStatus,
        processFlowList: typeof(props.processFlowList) !== "undefined" ? props.processFlowList: [],
        columnMapperNodeList: typeof(props.columnMapperNodeList) !== "undefined" ? props.columnMapperNodeList: [],
        handleApplyProcessSaveClick: typeof(props.handleApplyProcessSaveClick) !== 'undefined' ? props.handleApplyProcessSaveClick: '',
        targetColumnList: [],
        selectedTargetColumnList: [],
        removedTargetColumnList:[],
        variableValueTreeView: {
          name: props.node.name,
          column: autoApplyProcessFlowStatus === true ? {column_header: props.node.column} : props.node.column,
          cssClassName: 'manage-target-table-variable',
          iconClass: 'fa fa-columns',
          loading: false,
          toggled: false,
          children: [],
          id: props.node.id,
          tableName: props.node.tableName,
          fetchColumnValue: true,
          applyProcessFlow: true,
          isVariableValueNode: true,
          key:0,
          selectedTargetColumnValueList: autoApplyProcessFlowStatus === true && typeof(props.expressionDetail.selectedTargetColumnValueList) !== 'undefined' ? props.expressionDetail.selectedTargetColumnValueList : []
        },
        inputTableWhereConditiion: '',
        processFlowRefreshKey: 0
      }

      this.handleSaveApplyProcessFlow = this.handleSaveApplyProcessFlow.bind(this);
      this.props.projectList.forEach((project) => {
          if(project.selected || this.state.processFlowList.filter(processFlow => processFlow.projectId === project.projectId).length > 0){
            this.state.projectList.push({projectId:project.projectId,projectName:project.projectName});

            if (this.state.processFlowList.filter(processFlow => processFlow.projectId === project.projectId).length > 0) {
              this.state.selectedProject = {projectId:project.projectId,projectName:project.projectName};
              let study = project.studyList.filter(study =>study.studyId === this.state.processFlowList[0].studyId);

              if (study.length > 0) {
                this.state.selectedStudy = {studyId: this.state.processFlowList[0].studyId, studyName: study[0].studyName, schemaName: this.state.processFlowList[0].schemaName};
                this.state.studyList.push(this.state.selectedStudy);
              }
            }
          }
      })

      if (typeof(this.state.node.tableName) !== 'undefined' && typeof(this.props.treeElement) !== 'undefined' && typeof(this.props.treeElement.metadataTableTree) !== 'undefined') {
        this.props.treeElement.metadataTableTree.children.filter(table => table.tableName === this.state.node.tableName).forEach(table => {
          table.children.filter(column => column.title !== this.state.node.title && column.applyProcessFlow === false).forEach(column => {
            this.state.targetColumnList.push(column.title)
          })
        })
      }

      if (this.state.autoApplyProcessFlowStatus === true && this.state.processFlowList.length > 0) {
        this.state.selectedGroup = { groupId: this.state.processFlowList[0].groupId, groupName: this.state.processFlowList[0].groupName };
        this.state.selectedFlow = { processFlowId: this.state.processFlowList[0].processId, processFlowName: this.state.processFlowList[0].processName };

        this.state.groupList.push(this.state.selectedGroup);
        this.state.flowList.push(this.state.selectedFlow);
        this.state.applyProcessFlow = {...props.expressionDetail, ...this.state.node, processFlowList: this.state.processFlowList, columnMapperNodeList: this.state.columnMapperNodeList, selectedTargetColumnList: this.state.selectedTargetColumnList};
      }

      this.getProcessFlowDetail = this.getProcessFlowDetail.bind(this);
      this.populateProcessFlow = this.populateProcessFlow.bind(this)
      this.clearAssignApplyProcessFlow = this.clearAssignApplyProcessFlow.bind(this);
      this.appendStudyDetailInTreeView = this.appendStudyDetailInTreeView.bind(this);

    }

    fetchDataViewer = (headers, params) => restClient(GET_ONE, 'dataviewer', params, headers);

    fetchFlowProjectList = (params, headers) => restClient(CREATE, 'treeview', params, headers);

    handleProjectSelectChange = (event) => {
        let studyList = [];
        if (typeof(event.target.value) !== "undefined" && event.target.value !== null && event.target.value.projectId !== 0) {
          let project = this.props.projectList.filter(project => project.projectId === event.target.value.projectId);

          if (project.length > 0) {
              let selectedStudy = this.props.studyList.filter(study => study.projectId === project[0].projectId && study.selected);
              selectedStudy.forEach((study) => {
                  studyList.push({studyId:study.studyId,studyName:study.studyName,schemaName:study.schemaName});
            })
          }
        } else{
            studyList = [{studyId:0,studyName:'',schemaName:''}];
        }
        this.setState({selectedProject: event.target.value, selectedStudy: {studyId:0,studyName:'',schemaName:''},selectedGroup: {groupId:0,groupName:''}, selectedFlow:{processFlowId:0,processFlowName:''}, studyList: studyList})
    }

    handleStudySelectChange = (event) => {
        let groupList = [];
        if (typeof(event.target.value) !== "undefined" && event.target.value !== null && event.target.value.studyId !== 0) {
            this.populateGroups(this.state.selectedProject.projectId, event.target.value.studyId ).then(data => {
              groupList.push(...data);
              this.setState({groupList: groupList})
            });
        } else{
            groupList = [{groupId:0,groupName:''}];
        }
        this.setState({selectedStudy: event.target.value, selectedGroup: {groupId:0,groupName:''}, selectedFlow:{processFlowId:0,processFlowName:''}, groupList: groupList})
    }

    handleGroupSelectChange = (event) => {
        let flowList = [];
        if (typeof(event.target.value) !== "undefined" && event.target.value !== null && event.target.value.studyId !== 0) {
            flowList = this.populateFlows(this.state.selectedProject.projectId, this.state.selectedStudy.studyId, event.target.value.groupId );
        } else{
            flowList = [{processFlowId:0,processFlowName:''}];
        }
        this.setState({selectedGroup: event.target.value, selectedFlow:{processFlowId:0,processFlowName:''}, flowList: flowList})
    }

    handleFlowSelectChange = (event) => {
      if (this.state.selectedTargetColumnList.length === 0 && this.state.variableValueTreeView.children.filter(setValueNode => setValueNode.isSetValue === true && setValueNode.assignProcessflowId === event.target.value.processFlowId && setValueNode.schemaName === this.state.selectedStudy.schemaName).length > 0) {
        this.state.variableValueTreeView.children.filter(setValueNode => setValueNode.isSetValue === true && setValueNode.assignProcessflowId === event.target.value.processFlowId && setValueNode.schemaName === this.state.selectedStudy.schemaName).forEach(setValueNode => {
          let processFlowRefreshKey = this.state.processFlowRefreshKey;
          processFlowRefreshKey++;
          this.props.setMessage(`"${event.target.value.processFlowName}" processs flow already apply in "${setValueNode.name}"`, MessageTypeConst.WARNING_MESSAGE);
          this.setState({selectedFlow: {processFlowId:0,processFlowName:''}, saveButtonStatus: false, processFlowRefreshKey: processFlowRefreshKey });
        })
      } else {
        this.setState({selectedFlow: event.target.value, saveButtonStatus: false });
        this.getBlockAndLinkFromProcessFlow(this.state.selectedProject.projectId,this.state.selectedStudy.studyId,this.state.selectedStudy.schemaName,event.target.value.processFlowId);
      }
    }

    populateGroups = async (projectId, studyId) => {
        let groups = [{groupId:0,groupName:''}];
        let treeView = this.props.treeView.data;
        let selectedProject = treeView.children.filter(proj => proj.projectId === projectId);
        if(selectedProject.length > 0){
            let selectedStudy = selectedProject[0].children.filter(study => study.study.studyId === studyId);
            if(selectedStudy.length > 0){
                let rootprocessFlowNode = selectedStudy[0].children.filter(node => node.name === "ProcessFlows");
                if(rootprocessFlowNode.length > 0){
                    groups.length = 0;
                    rootprocessFlowNode[0].children.forEach((classifier) => {
                      classifier.children.forEach((group) => {
                          groups.push({groupId:group.processGroupId ,groupName:group.name});
                      });
                    })
                } else {
                  groups = await this.appendStudyDetailInTreeView(selectedStudy[0].study)
                }
            }
        }
        return groups;
    }

    appendStudyDetailInTreeView = async (study) => {
      let that = this;
      let groupList = [{groupId:0,groupName:''}];
      if (typeof(study) !== 'undefined')  {
        $(".loader").show();
        await axios.all([this.fetchFlowProjectList({data: study},{'Tenant-ID': study.schemaName})])
             .then(axios.spread((response) => {
               if (typeof(response.data) !== 'undefined') {
                 let treeView = this.props.treeView.data;
                 const study = response.data
                 treeView.children.filter(project => project.projectId === response.data.projectId).map(project => {
                   project.children.filter(blankStudy => blankStudy.study.schemaName === response.data.schemaName).map(blankStudy => {
                     let sourceTable = [];
                     let groupClassifier = []
                     let metaTable = [];
                     let documents = [];

                     if (typeof(study.studyDocuments) !== 'undefined' && study.studyDocuments !== null) {
                       study.studyDocuments.forEach((document) => {
                         documents.push({
                           name: document.filename,
                           cssClassName: 'manage-study-edit-documents',
                           schemaName: study.schemaName,
                           studyId:study.studyId,
                           projectId:project.projectId,
                           fileId: document.fileId,
                           path: document.filepath,
                           tableType: 2
                         })
                       })
                     }

                     if (typeof(study.sourceTable) !== 'undefined' && study.sourceTable !== null) {
                       study.sourceTable.forEach((table) => {
                         sourceTable.push({
                           name: table.srcTableName,
                           cssClassName: 'manage-study-edit-source-table',
                           schemaName: study.schemaName,
                           studyId:study.studyId,
                           projectId:project.projectId,
                           tag:'open-data-viewer',
                           tableName:table.tableName,
                           tableType: 0
                         })
                       })
                     }

                     if (typeof(study.metaTable) !== 'undefined' && study.metaTable !== null) {
                       study.metaTable.forEach((table) => {
                         metaTable.push({
                           name: table.srcTableName,
                           cssClassName: 'manage-study-edit-meta-table',
                           schemaName: study.schemaName,
                           studyId:study.studyId,
                           projectId:project.projectId,
                           tag:'open-data-viewer',
                           tableName:table.tableName,
                           tableType: 1
                         })
                       })
                     }

                     if (typeof(study.groupClassifier) !== 'undefined' && study.groupClassifier !== null) {
                       study.groupClassifier.forEach((classifier) => {
                         let processFlowGroup = [];
                         if (typeof(study.processFlowGroup) !== 'undefined' && study.processFlowGroup !== null) {
                           study.processFlowGroup.filter(group => group.classifier === classifier.classifierId).forEach((group) => {
                             var processFlow = [];
                             groupList.push({groupId:group.processGroupId ,groupName:group.processGroupName});
                             if (typeof(group.processFlow) !== 'undefined' && group.processFlow !== null) {
                               group.processFlow.forEach((process) => {
                                 processFlow.push(manageProcessInTreeView(process, project.projectId, study.schemaName, study.studyName));
                               })
                             }
                             processFlowGroup.push(manageProceeFlowGroupInTreeView(group, processFlow, study));
                           })
                         }

                         groupClassifier.push(manageClassifierInTreeView(classifier, processFlowGroup, study))
                       })
                     }

                     blankStudy.children.push(...manageStudyElementsInTreeView(study, sourceTable, metaTable, groupClassifier, documents));
                     blankStudy.loading = false
                     blankStudy.study = study
                     return blankStudy
                   })
                   return project
                 })

                 that.props.setTreeViewData(treeView);
                 that.setState({selectedGroup: {groupId:0,groupName:''}, selectedFlow:{processFlowId:0,processFlowName:''}, groupList: groupList})
              }
              $(".loader").hide();
            })).catch(error => {
               $(".loader").hide();
                this.props.setMessage("Error occurred while fetching study data", MessageTypeConst.ERROR_MESSAGE);
             })
        }

        return groupList
    }

    populateFlows = (projectId, studyId, processGroupId) => {
        let flows = [{processFlowId:0,processFlowName:''}];
        let treeView = this.props.treeView.data;
        let selectedProject = treeView.children.filter(proj => proj.projectId === projectId);
        if(selectedProject.length > 0){
            let selectedStudy = selectedProject[0].children.filter(study => study.study.studyId === studyId);
            if(selectedStudy.length > 0){
                let rootprocessFlowNode = selectedStudy[0].children.filter(node => node.name === "ProcessFlows");
                if(rootprocessFlowNode.length > 0){
                  rootprocessFlowNode[0].children.forEach((classifier) => {
                    let selectedGroup = classifier.children.filter(grp => grp.processGroupId === processGroupId);
                    if(selectedGroup.length > 0){
                        flows.length = 0;
                        selectedGroup[0].children.forEach((flow) => {
                            flows.push({processFlowId:flow.process.processId ,processFlowName:flow.name});
                        });
                    }
                  })

                }
            }
        }
        return flows;
    }

    getBlockAndLinkFromProcessFlow = (projectId, studyId, schemaName, processId) => {
        var data = {processBlock:[],processBlockLink:[]};
        let treeView = this.props.treeView;
        var that = this;
        treeView.data.children.forEach((project)=>{
            if(project.projectId ===projectId){
                project.children.forEach((study)=>{
                    if(study.study.studyId === studyId && study.study.schemaName === schemaName){
                        study.children.forEach((studyElement) => {
                            if (studyElement.name === 'ProcessFlows') {
                              studyElement.children.forEach((classifier)=>{
                                classifier.children.forEach((group)=>{
                                      group.children.forEach((flow)=>{
                                      if(flow.process.processId === processId){
                                          if(flow.process.processBlock === null || typeof(flow.process.processBlock) === "undefined" ||
                                          flow.process.processBlockLink === null || typeof(flow.process.processBlockLink) === "undefined"){
                                          //make api call to populate blocks and links
                                            that.getProcessFlowDetail(studyId, processId, schemaName, flow)
                                          }
                                          else{
                                              data.processBlock = flow.process.processBlock;
                                              data.processBlockLink = flow.process.processBlockLink;
                                              data.processFlowNotes = flow.process.processFlowNotes;
                                              let processFlowRefreshKey = that.state.processFlowRefreshKey;
                                              processFlowRefreshKey++;
                                              that.setState({blockAndLinkData:data, processFlowRefreshKey: processFlowRefreshKey});
                                          }
                                      }
                                  });
                              });
                              })

                    }
                })
                    }
                });
            }
        });
        return data;
    }

    getProcessFlowDetail = (studyId, processId, schemaName, flow) => {
      let that = this;
      var data = {processBlock:[],processBlockLink:[], processFlowNotes: []};
      $(".loader").show();
      axios.all([
          restClient(GET, 'processblock/blockAndLinkList?studyId='+studyId+'&processId='+processId, {}, {'Tenant-ID':schemaName})
          ])
          .then(axios.spread((response) => {
              if (typeof(response.data) !== 'undefined') {
              flow.process.processBlock = []
              if (response.data.processBlock !== null && response.data.processBlock !== '' && response.data.processBlock.length > 0) {

                  flow.process.processBlock.push(...response.data.processBlock);
                  data.processBlock.push(...response.data.processBlock);
              }

              flow.process.processBlockLink = []
              if (response.data.processBlockLink !== null && response.data.processBlockLink !== '' && response.data.processBlockLink.length > 0) {
                  flow.process.processBlockLink.push(...response.data.processBlockLink);
                  data.processBlockLink = flow.process.processBlockLink;
              }
              }

              flow.process.processFlowNotes = []
              if (response.data.processFlowNotes !== null && response.data.procprocessFlowNotesessBlock !== '' && response.data.processFlowNotes.length > 0) {

                  flow.process.processFlowNotes.push(...response.data.processFlowNotes);
                  data.processFlowNotes = flow.process.processFlowNotes;
              }
              let processFlowRefreshKey = that.state.processFlowRefreshKey;
              processFlowRefreshKey++;
              that.setState({blockAndLinkData:data, processFlowRefreshKey: processFlowRefreshKey});
              $(".loader").hide();
          }))
          .catch(error => {
              $(".loader").hide();
              that.props.setMessage("Failed to fetch blocks and links!",MessageTypeConst.ERROR_MESSAGE);
          });
    }

    componentDidMount(){
      if (typeof(this.state.node.id) !== 'undefined' && this.props.applyProcessFlowList.filter(flow => flow.id === this.state.node.id || (typeof(flow.selectedTargetColumnList) !== 'undefined' && flow.selectedTargetColumnList.indexOf(this.state.node.title) > -1)).length > 0 && this.state.selectedStudy.studyId === 0) {
        let {selectedGroup, selectedFlow, groupList, flowList, projectList, studyList, selectedProject, selectedStudy, node, variableValueTreeView} = this.state;
        let selectedProcessFlow = cloneDeep(this.props.applyProcessFlowList.filter(flow => flow.id === this.state.node.id || (typeof(flow.selectedTargetColumnList) !== 'undefined' && flow.selectedTargetColumnList.indexOf(node.title) > -1 && flow.tableName === node.tableName)))
        let isChildernNodeApplyProcessFlow = false;
        let nodeColumn = node;
        if (this.props.applyProcessFlowList.filter(flow => flow.id === this.state.node.id).length === 0 && selectedProcessFlow.length > 0) {
          this.props.treeElement.metadataTableTree.children.filter(table => table.tableName === node.tableName).forEach(table => {
            table.children.filter(column => column.id === selectedProcessFlow[0].id).forEach(column => {
              nodeColumn = column
              isChildernNodeApplyProcessFlow = true;
            })
          })
        }
        const childrenNode = isChildernNodeApplyProcessFlow ? cloneDeep(node): {};
        node = nodeColumn

        let processFlow = selectedProcessFlow[0].processFlowList[0]
        let targetColumnList = [];
        let selectedTargetColumnList = [];
        selectedGroup = { groupId: processFlow.groupId, groupName: processFlow.groupName }
        selectedFlow = { processFlowId: processFlow.processId, processFlowName: processFlow.processName }
        groupList.push(selectedGroup);
        flowList.push(selectedFlow);

        this.props.projectList.filter(project => project.projectId === processFlow.projectId).forEach((project) => {
            if (projectList.filter(selectedProject => selectedProject.projectId === processFlow.projectId).length === 0) {
              projectList.push({projectId:project.projectId,projectName:project.projectName});
            }

            if (studyList.filter(selectedStudy => selectedStudy.studyId === processFlow.studyId).length === 0) {
              let study = project.studyList.filter(study =>study.studyId === processFlow.studyId);

              if (study.length > 0) {
                studyList.push({studyId: processFlow.studyId, studyName: study[0].studyName, schemaName: processFlow.schemaName})
              }
            }
        })

        let project = projectList.filter(selectedProject => selectedProject.projectId === processFlow.projectId)
        let study = studyList.filter(selectedStudy => selectedStudy.studyId === processFlow.studyId)

        if (project.length > 0) {
          selectedProject = project[0]
        }

        if (study.length > 0) {
          selectedStudy = study[0]
        }

        if (typeof(selectedProcessFlow[0].selectedTargetColumnList) !== 'undefined') {
          selectedTargetColumnList = selectedProcessFlow[0].selectedTargetColumnList
        }

        if (typeof(node.tableName) !== 'undefined' && typeof(this.props.treeElement) !== 'undefined' && typeof(this.props.treeElement.metadataTableTree) !== 'undefined') {
          this.props.treeElement.metadataTableTree.children.filter(table => table.tableName === node.tableName).forEach(table => {
            table.children.filter(column => selectedTargetColumnList.indexOf(column.title) > -1 || column.applyProcessFlow === false).forEach(column => {
              targetColumnList.push(column.title)
            })
          })
        }

        if (isChildernNodeApplyProcessFlow === true) {
          variableValueTreeView = {
            ...variableValueTreeView,
            column: node.column,
            id: node.id,
            name: node.name
          }
        }
        this.setState({selectedGroup: selectedGroup, selectedFlow: selectedFlow, groupList: groupList, flowList: flowList, projectList: projectList, studyList: studyList, selectedProject: selectedProject, selectedStudy: selectedStudy, selectedTargetColumnList: selectedTargetColumnList, targetColumnList: targetColumnList, node: node, childrenNode: childrenNode, variableValueTreeView: variableValueTreeView}, () => {
            this.populateProcessFlow()
        })
      } else {
        this.populateProcessFlow()
      }
    }

    populateProcessFlow = async () => {
      let applyProcessFlowList = this.props.applyProcessFlowList;
      let selectedProcessFlow = applyProcessFlowList.filter(flow => flow.id === this.state.node.id);

      if(selectedProcessFlow.length > 0){
          let processFlow = selectedProcessFlow[0].processFlowList[0];
          if(typeof(processFlow) === "undefined" || processFlow === null){
              return;
          }
          let selectedProject = this.state.projectList.filter(proj => proj.projectId === processFlow.projectId);
          let processFlowMissing = true;
          let processFlowMissingMessage = "Unable to find applied process flow. Please check your Project/Study";
          if(selectedProject.length > 0){
            let studyList = [];
            let projStudyList = this.props.studyList.filter(study => study.projectId === selectedProject[0].projectId && study.selected);

            projStudyList.forEach((study) => {
                studyList.push({studyId:study.studyId,studyName:study.studyName,schemaName:study.schemaName});
            });
            let selectedStudy = projStudyList.filter(study => study.studyId === processFlow.studyId);

            if(selectedStudy.length > 0){
                let groupList = await this.populateGroups(selectedProject[0].projectId, selectedStudy[0].studyId );
                let selectedGroup =  groupList.filter(group => group.groupId === processFlow.groupId);
                if(selectedGroup.length > 0){
                    let flowList = this.populateFlows(selectedProject[0].projectId, selectedStudy[0].studyId, selectedGroup[0].groupId );
                    let selectedFlow = flowList.filter(flow => flow.processFlowId === processFlow.processId);
                    if(selectedFlow.length > 0){
                        processFlowMissing = false;
                        processFlowMissingMessage = '';
                        this.getBlockAndLinkFromProcessFlow(selectedProject[0].projectId,selectedStudy[0].studyId,selectedStudy[0].schemaName,selectedFlow[0].processFlowId);
                        let applyProcessFlow = {
                            id: this.state.node.id,
                            tableName: this.state.node.tableName,
                            columnName: this.state.node.name,
                            column: this.state.node.title,
                            type: this.state.node.type,
                            processFlowList:[processFlow],
                            columnMapperNodeList: selectedProcessFlow[0].columnMapperNodeList,
                            selectedTargetColumnList: typeof(selectedProcessFlow[0].selectedTargetColumnList) !== 'undefined' ? selectedProcessFlow[0].selectedTargetColumnList: [],
                            selectedTargetColumnValueList: typeof(selectedProcessFlow[0].selectedTargetColumnList) !== 'undefined' ? selectedProcessFlow[0].selectedTargetColumnList: [],
                            removedColumnList: typeof(selectedProcessFlow[0].removedColumnList) !== 'undefined' ? selectedProcessFlow[0].removedColumnList: []
                        }
                        this.setState({
                            studyList:studyList,
                            selectedProject:selectedProject[0],
                            selectedStudy:selectedStudy[0],
                            selectedGroup:selectedGroup[0],
                            selectedFlow:selectedFlow[0],
                            groupList:groupList,
                            flowList:flowList,
                            applyProcessFlow: selectedProcessFlow[0],
                            inputTableWhereConditiion: typeof(selectedProcessFlow[0].inputTableWhereConditiion) !== 'undefined' ? selectedProcessFlow[0].inputTableWhereConditiion : ''
                        }, () => {
                          this.updateVariableValueTreeView();
                        });
                    }
                } else {
                  processFlowMissingMessage = "Unable to find process flow group. Please check your Project/Study"
                }
            } else {
              processFlowMissingMessage = "Please select \""+this.state.selectedStudy.studyName+"\" study from \"Study select\" section.";
            }
          }

          if (processFlowMissing === true && processFlowMissingMessage !== '') {
            this.setState({applyProcessFlow: selectedProcessFlow[0]})
            this.props.setMessage(processFlowMissingMessage, MessageTypeConst.ERROR_MESSAGE);
          }
      } else if (this.state.autoApplyProcessFlowStatus === true) {
          let selectedProcessFlow = {...this.state.node, processFlowList: this.state.processFlowList, columnMapperNodeList: this.state.columnMapperNodeList, selectedTargetColumnList: this.state.selectedTargetColumnList}
          let flow = {
            process: {
              processBlock: [],
              processBlockLink: [],
              processFlowNotes: []
            }
          }
          this.getProcessFlowDetail(this.state.selectedStudy.studyId, this.state.selectedFlow.processFlowId, this.state.selectedStudy.schemaName, flow)
          this.updateVariableValueTreeView();
      }
    }

    handleApplyClick = (event) => {
        if(this.state.selectedFlow.processFlowId === 0 ){
            this.props.setMessage("Please set process flow before saving.", MessageTypeConst.WARNING_MESSAGE);
            return;
        }
        let node = this.state.node;
        let targetTableStructure = {
          tableName: null,
          tableColumns: []
        }
        let applyProcessFlow = {
            id: this.state.node.id,
            tableName: this.state.node.tableName,
            columnName: this.state.node.name,
            column: this.state.node.title,
            type: this.state.node.type,
            displayTableName: node.displayTableName,
            processFlowList:[{
                id:`${this.state.node.id}-${this.state.selectedStudy.studyId}-${this.state.selectedFlow.processFlowId}`,
                projectId : this.state.selectedProject.projectId,
                studyId: this.state.selectedStudy.studyId,
                schemaName: this.state.selectedStudy.schemaName,
                groupId: this.state.selectedGroup.groupId,
                processId: this.state.selectedFlow.processFlowId,
                processName: this.state.selectedFlow.processFlowName,
                groupName: this.state.selectedGroup.groupName,
                sortOrder:0,
                sortOrderList:[],
                sourceBlockId: null,
                sourceBlockTable: null,
                sourceSchemaName: null,
                linkBlockList: [],
                columnMappingRules: []
            }],
            columnMapperNodeList: []
        }

        if (typeof(this.state.applyProcessFlow.id) !== 'undefined' &&
            this.state.applyProcessFlow.id === applyProcessFlow.id &&
            this.state.applyProcessFlow.tableName === applyProcessFlow.tableName &&
            this.state.applyProcessFlow.column === applyProcessFlow.column &&
            this.state.applyProcessFlow.processFlowList[0].processId === applyProcessFlow.processFlowList[0].processId
          ) {
          applyProcessFlow = cloneDeep(this.state.applyProcessFlow);
          applyProcessFlow.processFlowList.map(processFlow => {
            processFlow.id = `${this.state.node.id}-${this.state.selectedStudy.studyId}-${this.state.selectedFlow.processFlowId}`;
            processFlow.projectId = this.state.selectedProject.projectId;
            processFlow.studyId = this.state.selectedStudy.studyId;
            processFlow.schemaName = this.state.selectedStudy.schemaName;
            processFlow.groupId = this.state.selectedGroup.groupId;
            processFlow.processId = this.state.selectedFlow.processFlowId;
            processFlow.processName = this.state.selectedFlow.processFlowName;
            processFlow.groupName = this.state.selectedGroup.groupName;
            return processFlow
          })
        }

        if (this.state.selectedTargetColumnList.length === 0) {
          this.state.variableValueTreeView.children.filter(setValueNode => setValueNode.isSetValue === true && setValueNode.isSelected === true && typeof(setValueNode.applyProcessFlow) !== 'undefined' && typeof(setValueNode.applyProcessFlow.processFlowList) !== 'undefined' && setValueNode.applyProcessFlow.processFlowList.length > 0 && setValueNode.applyProcessFlow.processFlowList[0].projectId === this.state.selectedProject.projectId && setValueNode.applyProcessFlow.processFlowList[0].schemaName === this.state.selectedStudy.schemaName && setValueNode.applyProcessFlow.processFlowList[0].processId === this.state.selectedFlow.processFlowId).forEach(setValueNode => {
            applyProcessFlow = cloneDeep(setValueNode.applyProcessFlow);
          })
        }
        applyProcessFlow = Object.assign(applyProcessFlow, {selectedTargetColumnList: this.state.selectedTargetColumnList})

        if (typeof(this.state.node.tableName) !== 'undefined') {
          let tableName = this.state.node.tableName.split('_');

          tableName.length = tableName.length - 1;
          tableName = tableName.join('_');

          tableName = tableName.split('_meta');
          tableName.length = tableName.length - 1;
          tableName = tableName.join('_meta');

          //let rootBlockTable = this.state.blockAndLinkData.processBlock.filter(rootBlockTable => rootBlockTable.blockCreationType === 5 && rootBlockTable.blockTable.toLowerCase().indexOf(tableName.toLowerCase()+'_') === 0);
          let rootBlockTableList = this.state.blockAndLinkData.processBlock.filter(rootBlockTable => rootBlockTable.blockLevel === 0);

          if (rootBlockTableList.length > 0) {
            rootBlockTableList.forEach((rootBlockTable) => {
              //rootBlockTable.blockName.toLowerCase() === 'temp'
              if (rootBlockTable.functionInputBlock === 1) {
                applyProcessFlow.processFlowList[0].sourceBlockId = rootBlockTable.blockId;
                applyProcessFlow.processFlowList[0].sourceBlockTable = rootBlockTable.blockTable;
                applyProcessFlow.processFlowList[0].sourceSchemaName = rootBlockTable.schemaName;
                applyProcessFlow.processFlowList[0].sourceBlockName = rootBlockTable.blockName;
              } else {
                applyProcessFlow.processFlowList[0].linkBlockList.push(rootBlockTable)
              }
            })
          }
        }

        if (applyProcessFlow.processFlowList[0].sourceBlockTable === null) {
          this.props.setMessage("Function input block is missing in selected process flow.", MessageTypeConst.ERROR_MESSAGE);

          return true;
        }

        if (this.state.blockAndLinkData.processBlock.filter(processBlock => processBlock.functionOutputBlock === 1).length === 0) {
          this.props.setMessage("Function output block is missing in selected process flow.", MessageTypeConst.ERROR_MESSAGE);

          return true;
        }

        let mappedTargetTableColumnList = [node.title];

        if (node.tableName !== 'undefined') {
          let { metadataTableTree, rightPersistentTableTree } = this.props.treeElement;
          let targetTable = metadataTableTree.children.filter(children => children.tableName === node.tableName);

          if (targetTable.length === 0) {
            targetTable = rightPersistentTableTree.children.filter(children => children.tableName === node.tableName);
          }

          if (targetTable.length > 0) {
            targetTableStructure.tableName = node.tableName;
            targetTable[0].children.forEach(columnDetail => {
              if (columnDetail.applyProcessFlow === true && mappedTargetTableColumnList.indexOf(columnDetail.column.column_header) === -1 && this.props.applyProcessFlowList.filter(subApplyProcessFlow => subApplyProcessFlow.id === columnDetail.id) === 1) {
                  mappedTargetTableColumnList.push(columnDetail.column.column_header)
              }
              targetTableStructure.tableColumns.push(columnDetail.column)
            })
          }
        }

        if (applyProcessFlow.processFlowList[0].sourceBlockTable !== null && applyProcessFlow.processFlowList[0].sourceSchemaName !== null && targetTableStructure.tableName !== null) {
          $(".loader").show();
          axios.all([this.fetchDataViewer({'Tenant-ID': applyProcessFlow.processFlowList[0].sourceSchemaName}, {id: applyProcessFlow.processFlowList[0].sourceBlockTable + '_extended'})])
                .then(axios.spread((dataViewer) => {
                    if(typeof(dataViewer.data) !== 'undefined' && typeof(dataViewer.data.records) !== 'undefined') {
                      let mappedTargetTableCarryForwardColumnList = this.props.columnMappedList.filter(columnMapped => columnMapped.table.target === targetTableStructure.tableName && columnMapped.mappingType === 'column-table');
                      this.props.columnMappedList.filter(columnMapped => columnMapped.table.target === targetTableStructure.tableName && (columnMapped.mappingType === 'column-column' || columnMapped.mappingType === 'column-table') ).forEach(columnMapped => {
                        if (mappedTargetTableColumnList.indexOf(columnMapped.columnTitle.target) === -1 && columnMapped.mappingType !== 'column-table') {
                          mappedTargetTableColumnList.push(columnMapped.columnTitle.target)
                        } else if (mappedTargetTableColumnList.indexOf(columnMapped.columnTitle.source) === -1 && columnMapped.mappingType === 'column-table') {
                          mappedTargetTableColumnList.push(columnMapped.columnTitle.source)
                        }
                      })

                      this.props.hardcodeList.filter(hardcode => hardcode.tableName === targetTableStructure.tableName).forEach(hardcode => {
                        if (mappedTargetTableColumnList.indexOf(hardcode.column) === -1) {
                          mappedTargetTableColumnList.push(hardcode.column)
                        }
                      })

                      this.state.selectedTargetColumnList.forEach(selectedTargetColumn => {
                        if (mappedTargetTableColumnList.indexOf(selectedTargetColumn) === -1) {
                          mappedTargetTableColumnList.push(selectedTargetColumn)
                        }
                      })

                      if (typeof(applyProcessFlow.processFlowList[0]) !== 'undefined' && typeof(applyProcessFlow.processFlowList[0].columnMappingRules) !== 'undefined') {
                        if (typeof(applyProcessFlow.columnMapperNodeList) !== 'undefined' && applyProcessFlow.columnMapperNodeList.length > 0 && applyProcessFlow.processFlowList.length > 0) {
                          applyProcessFlow.processFlowList[0].columnMappingRules.filter(columnMappingRule => columnMappingRule.columnMappingType === 2 && mappedTargetTableColumnList.indexOf(columnMappingRule.mappedColumnName) === -1).forEach(columnMappingRule => {
                            applyProcessFlow.columnMapperNodeList = applyProcessFlow.columnMapperNodeList.filter(columnMapperNode => !(columnMapperNode.node.source === columnMappingRule.columnId && columnMapperNode.node.target === columnMappingRule.mappedColumnId))
                          })
                        }
                        applyProcessFlow.processFlowList[0].columnMappingRules = applyProcessFlow.processFlowList[0].columnMappingRules.filter(columnMappingRule => !(columnMappingRule.columnMappingType === 2 && mappedTargetTableColumnList.indexOf(columnMappingRule.mappedColumnName) === -1))
                      }

                      if (this.state.autoApplyProcessFlowStatus === true && typeof(global.gridData) !== 'undefined' && typeof(this.state.applyProcessFlow.tableName) !== 'undefined') {
                        global.gridData.filter(row => row.selected === true && row.targetTable === this.state.applyProcessFlow.tableName && mappedTargetTableColumnList.indexOf(row.targetVariable) === -1).forEach(row => {
                          if (row.targetVariable !== '-') {
                            mappedTargetTableColumnList.push(row.targetVariable)
                          } else if (row.columnMappedList.length > 0 && row.expressionButton === false){
                            row.columnMappedList.filter(rowColumnMapped => rowColumnMapped.mappingType === 'column-table' && rowColumnMapped.table.target === this.state.applyProcessFlow.tableName && mappedTargetTableCarryForwardColumnList.filter(mappedTargetTableCarryForwardColumn => mappedTargetTableCarryForwardColumn.node.target === rowColumnMapped.node.target && mappedTargetTableCarryForwardColumn.node.source === rowColumnMapped.node.source).length === 0).forEach(rowColumnMapped => {
                              mappedTargetTableCarryForwardColumnList.push(rowColumnMapped);
                              mappedTargetTableColumnList.push(rowColumnMapped.columnTitle.source);
                            })
                          }
                        })

                        if (typeof(applyProcessFlow.columnMapperNodeList) !== 'undefined' && applyProcessFlow.columnMapperNodeList.length > 0 && applyProcessFlow.processFlowList.length > 0) {
                          applyProcessFlow.columnMapperNodeList = applyProcessFlow.columnMapperNodeList.filter(columnMapperNode => applyProcessFlow.processFlowList[0].columnMappingRules.filter(columnMappingRule => columnMappingRule.mappedColumnId === columnMapperNode.node.target && mappedTargetTableColumnList.indexOf(columnMappingRule.mappedColumnName) > -1).length > 0)
                        }
                      }

                      let constructiveOperationData = {
                        inputTableStructure: [],
                        sourceInputTableList: [],
                        processFlowAction: null,
                        block: {},
                        tenantId: null,
                        processFlowAction: 'applyProcessFlow',
                        applyProcessFlow: applyProcessFlow,
                        mappedTargetTableColumnList: mappedTargetTableColumnList,
                        mappedTargetTableCarryForwardColumnList: mappedTargetTableCarryForwardColumnList
                      };

                      constructiveOperationData.inputTableStructure.push({
                        selected: true,
                        tableName: applyProcessFlow.processFlowList[0].sourceBlockTable,
                        tableColumns: this.renameRecordBasedOnIpdefination(dataViewer.data.records)
                      })

                      constructiveOperationData.sourceInputTableList.push(targetTableStructure.tableName)
                      constructiveOperationData.tenantId = applyProcessFlow.processFlowList[0].sourceSchemaName;
                      global.constructiveOperationGridMapping = [];
                      global.processBlockControlValues = [];
                      this.props.setConstructiveOperationStatus(true);
                      this.setState({ goToInputStructureOperation: true, constructiveOperationData: constructiveOperationData})

                      $(".loader").hide();
                    }
          })).catch(error => {
            $(".loader").hide();
          });

        } else {
          this.handleSaveApplyProcessFlow(applyProcessFlow);
        }
    }

    renameRecordBasedOnIpdefination = (records) => {
      let rows = [];

      records.forEach(record => {
        rows.push({
          colName: record.column_header,
          colType: record.column_data_type,
          colGNType: record.column_gndata_type,
          colLength: record.column_data_length,
          colLabel: record.column_label
        })
      })

      return rows
    }

    handleSaveApplyProcessFlow = (applyProcessFlow) => {
      let variableValueTreeView = this.state.variableValueTreeView;

      if (this.state.selectedTargetColumnList.length > 0) {
        variableValueTreeView.children.filter(setValueNode => setValueNode.isSetValue === true).forEach(setValueNode => {
          variableValueTreeView.children.push(...setValueNode.children)
        })
        variableValueTreeView.children = variableValueTreeView.children.filter(setValueNode => setValueNode.isSetValue !== true);
        variableValueTreeView.children.sort((a,b) => ('' + a.name).localeCompare(b.name))
        variableValueTreeView.applyProcessFlow = true;
        variableValueTreeView.key++;

        if (typeof(applyProcessFlow.processFlowList) !== 'undefined' && applyProcessFlow.processFlowList.length > 0 && typeof(applyProcessFlow.processFlowList[0].sortOrderList) !== 'undefined') {
          applyProcessFlow.processFlowList[0].sortOrderList.length = 0
        }
      } else {
        variableValueTreeView.children.filter(setValueNode => setValueNode.isSelected === true).map(setValueNode => {
          setValueNode.assignProcessflowId = applyProcessFlow.processFlowList[0].processId;
          setValueNode.schemaName = applyProcessFlow.processFlowList[0].schemaName;
          setValueNode.applyProcessFlow = cloneDeep(applyProcessFlow);
          variableValueTreeView.key++;
          return setValueNode
        })
      }
      this.setState({applyProcessFlow: applyProcessFlow, saveButtonStatus: true, goToInputStructureOperation: false, variableValueTreeView });
    }

    handleRemoveClick = (event) => {
        if(typeof(this.state.applyProcessFlow) === "undefined" || this.state.applyProcessFlow === null ||
          typeof(this.state.applyProcessFlow.id) === "undefined" || typeof(this.state.applyProcessFlow.id) === null ||
          typeof(this.state.applyProcessFlow.id) === ""){
            this.props.setMessage("Saved process flow not found. Nothing to remove.", MessageTypeConst.WARNING_MESSAGE);
            this.updateTreeView(this.state.node, false, [], []);
            this.state.closeEvent();
            return;
        }

        this.props.removeApplyProcessFlow(this.state.applyProcessFlow);
        this.updateTreeView(this.state.node, false, this.state.applyProcessFlow.selectedTargetColumnList, []);

        this.state.closeEvent();
    }

    updateTreeView = (node, status, selectedTargetColumnList, removedTargetColumnList = []) => {
        let currentNode = null;
        if (this.state.node.type === "metadata") {
            currentNode = this.props.treeElement.metadataTableTree;
        }
        if (this.state.node.type === "rightPersistent") {
            currentNode = this.props.treeElement.rightPersistentTableTree;
        }
        if (typeof (currentNode) !== "undefined" && currentNode !== null) {
            let selectedTable = currentNode.children.filter(table => table.title === node.tableName);
            if (selectedTable.length > 0) {
                let selectedCol = selectedTable[0].children.filter(col => col.title === node.title);
                if (selectedCol.length > 0) {
                    let colIndex = selectedTable[0].children.findIndex(col => col.title === node.title);
                    let tableIndex = currentNode.children.findIndex(table => table.title === node.tableName);
                    currentNode.children[tableIndex].children[colIndex].applyProcessFlow = status;

                    if (typeof(selectedTargetColumnList) !== 'undefined') {
                      selectedTargetColumnList.filter(selectedTargetColumn => selectedTargetColumn !== node.title).forEach(selectedTargetColumn => {
                        let selectedCol = selectedTable[0].children.filter(col => col.title === selectedTargetColumn);
                        if (selectedCol.length > 0) {
                          let colIndex = selectedTable[0].children.findIndex(col => col.title === selectedTargetColumn);
                          currentNode.children[tableIndex].children[colIndex].applyProcessFlow = status;
                        }
                      })
                    }

                    if (removedTargetColumnList.length > 0) {
                      removedTargetColumnList.forEach(removedTargetColumn => {
                        let selectedCol = selectedTable[0].children.filter(col => col.title === removedTargetColumn);
                        if (selectedCol.length > 0) {
                          let colIndex = selectedTable[0].children.findIndex(col => col.title === removedTargetColumn);
                          currentNode.children[tableIndex].children[colIndex].applyProcessFlow = false;
                        }
                      })
                    }
                }
              }
        }
        let treeElement = this.props.treeElement;
        if (this.state.node.type === "metadata") {
            treeElement.metadataTableTree = currentNode;
        }
        if (this.state.node.type === "rightPersistent") {
            treeElement.rightPersistentTableTree = currentNode;
        }
        this.props.setTreeElement(treeElement);
    }

    toggleDialog = () => {
      ConfirmBox.open("Are you sure you want to exit ?").then((data) => {
          this.setState({ goToInputStructureOperation: false })
      })

    }

    handleSaveClick = (event) => {
      if (this.state.autoApplyProcessFlowStatus === true) {
        this.state.handleApplyProcessSaveClick(event, this.state.applyProcessFlow)
      } else {
        this.updateTreeView(this.state.node, true, this.state.applyProcessFlow.selectedTargetColumnList, typeof(this.state.childrenNode) !== 'undefined' ? this.state.removedTargetColumnList : []);
        this.props.addApplyProcessFlow({
          ...this.state.node,
          ...this.state.applyProcessFlow,
          selectedTargetColumnValueList: this.getValueLevelApplyProcessFlowList(),
          removedColumnList: this.getRemovedColumnList(),
          inputTableWhereConditiion: this.state.inputTableWhereConditiion
        });
      }
      this.setState({ saveButtonStatus: false });
      this.state.closeEvent();
    }

    handleTargetColumnSelectChange = (event) => {
      let valueList = event.value.filter(selectedValue => selectedValue !== this.state.node.title);
      let {selectedTargetColumnList} = this.state.applyProcessFlow

      this.setState({selectedTargetColumnList: valueList, saveButtonStatus: typeof(selectedTargetColumnList) !== 'undefined' && valueList.filter(value => selectedTargetColumnList.indexOf(value) === -1).length === 0 && valueList.length === selectedTargetColumnList.length ? true: false, removedTargetColumnList: typeof(selectedTargetColumnList) !== 'undefined' ? selectedTargetColumnList.filter(value => valueList.indexOf(value) === -1) : []})
    }

    updateVariableValueTreeView = () => {
      let {variableValueTreeView, applyProcessFlow} = this.state;
      if (typeof(applyProcessFlow.removedColumnList) !== 'undefined' && applyProcessFlow.removedColumnList.indexOf(variableValueTreeView.column.column_header) > -1) {
        variableValueTreeView.applyProcessFlow = false;
      }

      if (typeof(applyProcessFlow.selectedTargetColumnValueList) !== 'undefined' && applyProcessFlow.selectedTargetColumnValueList.length > 0) {
        applyProcessFlow.selectedTargetColumnValueList.forEach(targetColumnValueList => {
          variableValueTreeView.children.push({
            name: targetColumnValueList.setValueName,
            cssClassName: 'manage-variable-set-value',
            iconClass: 'fa fa-columns',
            loading: false,
            toggled: false,
            children: [],
            id: variableValueTreeView.id+'-set-value-'+(targetColumnValueList.setValueName.replace('Set', '').trim()),
            isSetValue: true,
            assignProcessflowId: typeof(targetColumnValueList.applyProcessFlow.id) !== 'undefined' ? targetColumnValueList.applyProcessFlow.processFlowList[0].processId : 0,
            isSelected: typeof(targetColumnValueList.applyProcessFlow.id) !== 'undefined' && targetColumnValueList.applyProcessFlow.id === applyProcessFlow.id && targetColumnValueList.applyProcessFlow.processFlowList[0].schemaName === applyProcessFlow.processFlowList[0].schemaName && targetColumnValueList.applyProcessFlow.processFlowList[0].processId === applyProcessFlow.processFlowList[0].processId ? true : false,
            schemaName: typeof(targetColumnValueList.applyProcessFlow.id) !== 'undefined' ? targetColumnValueList.applyProcessFlow.processFlowList[0].schemaName : '',
            applyProcessFlow: targetColumnValueList.applyProcessFlow
          })
        })
      }
      this.setState({variableValueTreeView: variableValueTreeView}, () => {
        if (variableValueTreeView.children.length > 0) {
            document.querySelector('.manage-target-table-variable').click()
        }
      })
    }

    getValueLevelApplyProcessFlowList = () => {
      let targetColumnValueList = []
      this.state.variableValueTreeView.children.filter(setValueNode => {
        if (typeof(setValueNode.isSetValue) !== 'undefined' && setValueNode.isSetValue === true) {
          let valueList = []
          setValueNode.children.forEach(valueNode => {
            valueList.push(valueNode.name)
          })

          targetColumnValueList.push({setValueName: setValueNode.name, valueList: valueList, applyProcessFlow: setValueNode.applyProcessFlow})
        }
      })
      return targetColumnValueList;
    }

    getRemovedColumnList = () => {
      let removedColumnList = [];
      if (this.state.variableValueTreeView.applyProcessFlow === false) {
        removedColumnList.push(this.state.variableValueTreeView.column.column_header)
      }
      return removedColumnList
    }

    reloadAssignProcessFlow = (setValueNode) => {
      let {applyProcessFlow, selectedProject, selectedStudy, selectedGroup, selectedFlow, columnMapperNodeList} = this.state;
      let that = this;
      if (setValueNode.schemaName === '' && setValueNode.assignProcessflowId === 0) {
        this.clearAssignApplyProcessFlow();
      } else {
        let isValidAssignProcessFlow = false;
        let projectList = [];
        let studyList = [];
        let groupList = [];
        let flowList = [];
        if (typeof(setValueNode.applyProcessFlow.id) !== 'undefined' && setValueNode.applyProcessFlow.processFlowList.length > 0) {
          setValueNode.applyProcessFlow.processFlowList.filter(processFlow => processFlow.schemaName === setValueNode.schemaName && processFlow.processId === setValueNode.assignProcessflowId).forEach(processFlow => {
            this.props.projectList.forEach((project) => {
                if(project.selected){
                  projectList.push({projectId:project.projectId,projectName:project.projectName});

                  if (processFlow.projectId === project.projectId) {
                    selectedProject = {projectId:project.projectId,projectName:project.projectName};

                    studyList = [];
                    let projStudyList = this.props.studyList.filter(study => study.projectId === project.projectId && study.selected);

                    projStudyList.forEach((study) => {
                      studyList.push({studyId:study.studyId,studyName:study.studyName,schemaName:study.schemaName});

                      if (processFlow.studyId === study.studyId) {
                        selectedStudy = {studyId: study.studyId,studyName: study.studyName,schemaName: study.schemaName};
                      }
                    });

                    if(selectedStudy.studyId > 0){
                      this.populateGroups(selectedProject.projectId, selectedStudy.studyId ).then(data => {
                        groupList.push(...data);
                        groupList.filter(group => group.groupId === processFlow.groupId).forEach(group => {
                          selectedGroup = group;
                          if(selectedGroup.groupId > 0){
                            flowList = that.populateFlows(selectedProject.projectId, selectedStudy.studyId, selectedGroup.groupId );

                            flowList.filter(flow => flow.processFlowId === processFlow.processId).forEach(flow => {
                              selectedFlow = flow;
                              if (selectedFlow.processFlowId > 0){
                                isValidAssignProcessFlow = true;

                                if (typeof(setValueNode.applyProcessFlow) !== 'undefined' && typeof(setValueNode.applyProcessFlow.columnMapperNodeList) !== 'undefined') {
                                  columnMapperNodeList = setValueNode.applyProcessFlow.columnMapperNodeList
                                }
                                that.getBlockAndLinkFromProcessFlow(selectedProject.projectId, selectedStudy.studyId, selectedStudy.schemaName, selectedFlow.processFlowId);
                                that.setState({selectedProject: selectedProject, selectedStudy: selectedStudy, selectedGroup: selectedGroup, selectedFlow: selectedFlow, projectList: projectList, studyList: studyList, groupList: groupList, flowList: flowList, columnMapperNodeList: columnMapperNodeList})
                              }
                            })
                          }
                        })
                      })
                    }
                  }
                }
            })
          })
        }

        if (typeof(setValueNode.applyProcessFlow) !== 'undefined' && typeof(setValueNode.applyProcessFlow.columnMapperNodeList) !== 'undefined') {
          columnMapperNodeList = setValueNode.applyProcessFlow.columnMapperNodeList
        }

        if (isValidAssignProcessFlow === true) {
            this.setState({selectedProject: selectedProject, selectedStudy: selectedStudy, selectedGroup: selectedGroup, selectedFlow: selectedFlow, projectList: projectList, studyList: studyList, groupList: groupList, flowList: flowList, columnMapperNodeList: columnMapperNodeList})
        } else {
          this.clearAssignApplyProcessFlow();
        }
      }
    }

    clearAssignApplyProcessFlow = () => {
      let {applyProcessFlow, selectedProject, selectedStudy, selectedGroup, selectedFlow} = this.state;

      selectedProject = {projectId:0,projectName:''};
      selectedStudy = {studyId:0,studyName:'',schemaName:''};
      selectedGroup = {groupId:0,groupName:''};
      selectedFlow = {processFlowId:0,processFlowName:''};

      this.setState({selectedProject: selectedProject, selectedStudy: selectedStudy, selectedGroup: selectedGroup, selectedFlow: selectedFlow, studyList: [], groupList: [], flowList: []})
    }

    handleInputTableWhereConditiionChange = (event) => {
      this.setState({inputTableWhereConditiion: event.target.value})
    }

    render(){
        let nodeTitle = typeof(this.state.node.displayTableName) !== 'undefined' ? this.state.node.displayTableName + " ["+this.state.node.title+"]": this.state.node.title;

        if (typeof(this.state.childrenNode.title) !== 'undefined') {
          nodeTitle = typeof(this.state.node.displayTableName) !== 'undefined' ? this.state.node.displayTableName + " ["+this.state.childrenNode.title+"]": this.state.childrenNode.title;
        }
        return(
          <div>
            <div className="annotate-contextmenu-applyProcessFlow-dialog">
            <Dialog className="expression" title={`Apply Process Flow - ${nodeTitle}`} width="calc(100% - 10px)" height="calc(100% - 20px)" modal={true} onClose={this.state.closeEvent} buttons={[<div className="col-9" key="where-condition"><fieldset><div className="field_group"><legend>Input Table Where Condition</legend><div className="am-form-field multiple-target-column"><Input className="width-full" name="input_where_condition" value={this.state.inputTableWhereConditiion} onChange={this.handleInputTableWhereConditiionChange} disabled={this.state.selectedFlow.processFlowId === 0 || typeof(this.state.selectedFlow.processFlowId) === 'undefined'}/></div></div></fieldset></div>,<div className="col-3 p-t-15" key="btn-action"><Button primary={true} onClick={this.handleApplyClick}> Apply</Button><Button primary={true} onClick={this.handleSaveClick} disabled={!this.state.saveButtonStatus || (this.state.selectedTargetColumnList.length === 0 && this.state.variableValueTreeView.children.filter(setValueNode => setValueNode.assignProcessflowId === 0 || (typeof(setValueNode.children) !== 'undefined' && setValueNode.children.length === 0)).length > 0 )}> Save</Button>{typeof(this.state.childrenNode.title) === 'undefined' && <Button primary={true} onClick={this.handleRemoveClick} disabled={this.state.autoApplyProcessFlowStatus}> Remove</Button>}<Button onClick={this.state.closeEvent} > Cancel</Button></div>]}>
            <div className="field_group_main">
              <div className="container-fluid">
                <div className="row">
                  <div className="col-3">
                    <div className="row">
                        <div className="col-12">
                        <fieldset>
                           <div className="field_group">
                             <legend>Select Variable</legend>
                              <div className="am-form-field multiple-target-column">
                                  <span>Target Variable: </span>
                                  <MultiSelectDropDownList data={[this.state.node.title, ...this.state.targetColumnList]} onChange={this.handleTargetColumnSelectChange} value={[this.state.node.title, ...this.state.selectedTargetColumnList]}/>
                              </div>
                           </div>
                        </fieldset>
                      </div>
                    </div>
                    <div className="row">
                        <div className="col-12">
                        <fieldset>
                           <div className={`field_group variable-value-wrapper${this.state.selectedTargetColumnList.length > 0 ? ' disabled-click-event' : ''}`}>
                           <legend>Select Set Value</legend>
                           <div className={'target-tablelist tree-view'}>
                             <div className={'target treeview-content align-left'}>
                                 <TreeView treeView={this.state.variableValueTreeView} id={`${this.state.variableValueTreeView.id}-treeview-${this.state.variableValueTreeView.children.length}-${this.state.variableValueTreeView.key}`} reloadAssignProcessFlow={this.reloadAssignProcessFlow}/>
                               </div>
                             </div>
                           </div>
                        </fieldset>
                      </div>
                    </div>
                  </div>
                  <div className="col-9">
                    <div className="row m-l-n-30">
                        <div className="col-12">
                        <fieldset>
                           <div className="field_group">
                           <legend>Select Process Flow</legend>
                           <div className="row">
                             <div className="col-3">
                                  <div className="am-form-field">
                                      <span>Project: </span>
                                      <DropDownList data={this.state.projectList} textField={'projectName'} dataItemKey={'projectId'} onChange={(event) => this.handleProjectSelectChange(event)} value={this.state.selectedProject}/>
                                  </div>
                              </div>
                              <div className="col-3">
                                  <div className="am-form-field">
                                      <span>Study: </span>
                                      <DropDownList data={this.state.studyList} textField={'studyName'} dataItemKey={'studyId'} onChange={(event) => this.handleStudySelectChange(event)} value={this.state.selectedStudy}/>
                                  </div>
                              </div>
                              <div className="col-3">
                                  <div className="am-form-field">
                                      <span>Process Flow Group: </span>
                                      <DropDownList data={this.state.groupList} textField={'groupName'} dataItemKey={'groupId'} onChange={(event) => this.handleGroupSelectChange(event)} value={this.state.selectedGroup}/>
                                  </div>
                              </div>
                              <div className="col-3">
                                  <div className="am-form-field">
                                      <span>Process Flow: </span>
                                      <DropDownList data={this.state.flowList} textField={'processFlowName'} dataItemKey={'processFlowId'} onChange={(event) => this.handleFlowSelectChange(event)} value={this.state.selectedFlow}/>
                                  </div>
                              </div>
                            </div>
                          </div>
                        </fieldset>
                        </div>
                    </div>
                    <div className="row m-l-n-30">
                        <div className="col-12">
                        <fieldset>
                           <div className="field_group bg-white">
                             <legend>Process Flow Diagram</legend>
                             {this.state.selectedFlow.processFlowId > 0 &&
                               <div className="process_flow">
                                 <div className="exView">
                                   <ProcessFlow id={`process-flow-${this.state.selectedFlow.processFlowId}-0-${this.state.selectedStudy.schemaName}`} schemaName={this.state.selectedStudy.schemaName} {...this.state.blockAndLinkData} readOnly={true} isFlowCheckedOut={false} isFlowCheckedOutByLoggedInUser={false} key={`process-flow-${this.state.selectedFlow.processFlowId}-0-${this.state.selectedStudy.schemaName}-${this.state.processFlowRefreshKey}`} />
                                 </div>
                                </div>
                             }
                             {this.state.selectedFlow.processFlowId == 0 &&
                               <div className="no-process-flow"></div>
                             }
                            </div>
                        </fieldset>
                        </div>
                        </div>
                  </div>
                </div>


               </div>
            </div>
            </Dialog>
            </div>

            { this.state.goToInputStructureOperation &&
            <div className={ this.state.goToInputStructureOperation ? 'data-operation apply-process-flow-constructive' : ''}>
              <Dialog show={true} title={this.state.goToInputStructureOperation ? 'Input Structure Alignment' : ''} width="calc(100% - 10px)" height="calc(100% - 20px)" modal={true} onClose={this.toggleDialog}>
                <ConstructiveOperation {...this.state.constructiveOperationData} action={this.handleSaveApplyProcessFlow} />
              </Dialog>
            </div>
            }
          </div>

        );
    }
}

const mapStateToProps = createSelector(
    state => state.annotate.applyProcessFlowList,
    state => state.project.projectList,
    state => state.treeView,
    state => state.annotate.treeElement,
    state => state.study.studyList,
    state => state.annotate.columnMappedList,
    state => state.annotate.hardcodeList,
    (applyProcessFlowList, projectList, treeView, treeElement, studyList, columnMappedList, hardcodeList) => ({
        applyProcessFlowList,
        projectList,
        treeView,
        treeElement,
        studyList,
        columnMappedList,
        hardcodeList
    })
  );
  const mapActionsToProps = {
    addApplyProcessFlow: addApplyProcessFlow,
    removeApplyProcessFlow: removeApplyProcessFlow,
    setTreeElement: setTreeElement,
    setMessage: setMessage,
    setConstructiveOperationStatus: setConstructiveOperationStatus,
    setTreeViewData: setTreeViewData
  }

  Dialog.propTypes = {
      height: PropTypes.oneOfType([
          PropTypes.string
      ]),
      width: PropTypes.oneOfType([
          PropTypes.string
      ])
  }
  export default connect(mapStateToProps, mapActionsToProps)(ApplyProcessFlow);
