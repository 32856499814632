import React from 'react';

export const ExecuteAllStatusCell = ({ tableManager, value, data, column, colIndex, rowIndex }) => {
    if (typeof(tableManager) === 'undefined') {
      return (null)
    } else {
      return (
          <div className='rgt-cell-inner' style={{alignItems: 'center', overflow: 'hidden'}}>
              <ExecuteAllStatusCellClass dataItem={data} field={column.field} value={value} tableManager={tableManager}/>
          </div>
      )
    }
}

class ExecuteAllStatusCellClass extends React.Component {

    constructor(props) {
      super(props);
      this.state = {
        logData:'',
        visible: false
      };

    }

    render() {
        return (
            <div className="text-center">
            <div className={this.props.dataItem[this.props.field]} title={this.props.dataItem[this.props.field+'title']}>
            </div>
            </div>
        );
    }
}
