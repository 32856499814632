function Search(selector) {
  // private variables
  var targetNode = document.querySelector(selector);
  var hiliteTag = "MARK";
  var skipTags = new RegExp("^(?:" + hiliteTag + "|SCRIPT|FORM)$");
  var colors = ["#ffe28f"];
  var wordColor = [];
  var colorIdx = 0;
  var matchRegExp = "";
  var openLeft = false;
  var openRight = false;
  var parentId = null;
  var matchAll = false;
  var currentHighlightWordPosition = null;
  var marked = null;
  // characters to strip from start and end of the input string
  var endRegExp = new RegExp("^[^\\w]+|[^\\w]+$", "g");

  // characters used to break up the input string into words
  var breakRegExp = new RegExp("[^\\w'-]+", "g");

  this.setParentId = function (id) {
    parentId = id;
    return parentId;
  };

  this.setMatchAll = function (matchAllStatus) {
    matchAll = matchAllStatus;
    return matchAll;
  };

  this.setCurrentHighlightWordPosition = function (highlight) {
    currentHighlightWordPosition = highlight.position;
    marked = document.querySelector(`mark[parent-id='${highlight.id}']`);
    return currentHighlightWordPosition;
  };

  this.setEndRegExp = function (regex) {
    endRegExp = regex;
    return endRegExp;
  };

  this.setBreakRegExp = function (regex) {
    breakRegExp = regex;
    return breakRegExp;
  };

  this.setMatchType = function (type) {
    switch (type) {
      case "left":
        this.openLeft = false;
        this.openRight = true;
        break;

      case "right":
        this.openLeft = true;
        this.openRight = false;
        break;

      case "open":
        this.openLeft = this.openRight = true;
        break;

      default:
        this.openLeft = this.openRight = false;
    }
  };

  this.setRegex = function (input) {
    input = input.replace(endRegExp, "");
    //input = input.replace(breakRegExp, "|");
    input = input.replace(/^\||\|$/g, "");
    if (input) {
      var re = "(" + input + ")";
      if (!this.openLeft) {
        re = "\\b" + re;
      }
      if (!this.openRight) {
        re = re + "\\b";
      }
      matchRegExp = new RegExp(re, "i");
      return matchRegExp;
    }
    return false;
  };

  this.getRegex = function () {
    var retval = matchRegExp.toString();
    retval = retval.replace(/(^\/(\\b)?|\(|\)|(\\b)?\/i$)/g, "");
    retval = retval.replace(/\|/g, " ");
    return retval;
  };

  // recursively apply word highlighting
  this.highlightNextWord = function (node) {
    if (node === undefined || !node) return;
    if (!matchRegExp) return;
    if (skipTags.test(node.nodeName)) return;

    if (node.hasChildNodes()) {
      for (var i = 0; i < node.childNodes.length; i++)
        this.highlightNextWord(node.childNodes[i]);
    }
    if (node.nodeType == 3) {
      // NODE_TEXT
      var highlightedMarkList = document.getElementsByTagName(hiliteTag);
      let nv, regs;
      if ((nv = node.nodeValue) && (regs = matchRegExp.exec(nv))) {
        if (!wordColor[regs[0].toLowerCase()]) {
          wordColor[regs[0].toLowerCase()] = colors[colorIdx++ % colors.length];
        }
        var match = document.createElement(hiliteTag);
        match.appendChild(document.createTextNode(regs[0]));
        match.style.backgroundColor = "#5fd258";
        match.style.color = "#00000000";

        var parentIdAttribute = document.createAttribute("parent-id");
        parentIdAttribute.value = parentId;
        match.setAttributeNode(parentIdAttribute);

        var idAttribute = document.createAttribute("id");
        idAttribute.value = "mark-"+parentId+"-"+highlightedMarkList.length;
        match.setAttributeNode(idAttribute);

        const parentNodeBoundingClientRect = node.parentNode.getBoundingClientRect()
        const dataPageNumber = parseInt(targetNode.getAttribute('data-page-number'))
        const pageBoundingClientRect = targetNode.getBoundingClientRect();
        let validMark = false;
        let isTextHighlight = false;

        //dataPageNumber === currentHighlightWordPosition.pageNumber &&
        if (typeof(parentNodeBoundingClientRect.x) !== 'undefined' && typeof(pageBoundingClientRect.x) !== 'undefined') {
            let markX = parentNodeBoundingClientRect.x - pageBoundingClientRect.x - 1;
            const markY = parentNodeBoundingClientRect.y - pageBoundingClientRect.y - 1;
            let markXed = currentHighlightWordPosition.boundingRect.x2
            let markYed = currentHighlightWordPosition.boundingRect.y1 + 1
            if (marked !== null) {
              const markedPosition = marked.getBoundingClientRect();
              markXed = markedPosition.x - pageBoundingClientRect.x - 1;
              markYed = markedPosition.y - pageBoundingClientRect.y - 1;
              if (markYed === markY) {
                markX = markX + node.parentNode.innerText.length + node.parentNode.innerText.indexOf(node.nodeValue) + pageBoundingClientRect.y;
              }
            }

            var parentMarkXAttribute = document.createAttribute("markX");
            parentMarkXAttribute.value = markX;
            match.setAttributeNode(parentMarkXAttribute);

            var parentMarkYAttribute = document.createAttribute("markY");
            parentMarkYAttribute.value = markY;
            match.setAttributeNode(parentMarkYAttribute);

            if ((markYed === markY && markXed < markX) || markYed < markY) {
                validMark = true;
            }

            global.pageMarkList.filter(pageMark => pageMark.indexOf(parentId) === 0).forEach(pageMark => {
              const pageMarkSplit = pageMark.split('_x_')[1].split('_y_');
              const pageMarkXed = pageMarkSplit[0];
              const pageMarkYed = pageMarkSplit[1];

              if (pageMarkYed == markY && pageMarkXed == markX) {
                isTextHighlight = true;
              }
            });

        }

        if (validMark === false && marked === null && dataPageNumber !== currentHighlightWordPosition.pageNumber) {
          validMark = true
        }

        if (validMark === true && isTextHighlight === false) {
          var after = node.splitText(regs.index);
          after.nodeValue = after.nodeValue.substring(regs[0].length);
          node.parentNode.insertBefore(match, after);
        }
      }
    }
  };

  // remove highlighting
  this.remove = function () {
    var arr = document.getElementsByTagName(hiliteTag);
    let el;
    while (arr.length && (el = arr[0])) {
      var parent = el.parentNode;
      parent.replaceChild(el.firstChild, el);
      parent.normalize();
    }
  };

  this.setNearNextWord = function () {
    var highlightedMarkList = document.querySelectorAll('mark[parent-id="'+parentId+'"]');
    const pageBoundingClientRect = targetNode.getBoundingClientRect();
    if (highlightedMarkList.length > 0) {
      let nearMarkY = 0;
      let nearMarkX = 0;
      for(let i = 0;i < highlightedMarkList.length; i++)
      {
        if(highlightedMarkList[i].getAttribute('parent-id') === parentId && highlightedMarkList[i].getAttribute('is-last-match') != '1') {
          const markedBoundingClientRect = highlightedMarkList[i].getBoundingClientRect();
          const markXed = markedBoundingClientRect.x - pageBoundingClientRect.x - 1;
          const markYed = markedBoundingClientRect.y - pageBoundingClientRect.y - 1;
          if (nearMarkY === 0 || (nearMarkY > markYed)) {
            nearMarkY = markYed
            nearMarkX = markXed
          } else if(nearMarkY === markYed && nearMarkX > markXed) {
            nearMarkX = markXed
          }
        }
      }

      if (nearMarkY > 0 && highlightedMarkList.length > 1) {
        for(let i = 0;i < highlightedMarkList.length; i++)
        {
          if(highlightedMarkList[i].getAttribute('parent-id') === parentId && highlightedMarkList[i].getAttribute('is-last-match') != '1') {
            const markedBoundingClientRect = highlightedMarkList[i].getBoundingClientRect();
            const markXed = markedBoundingClientRect.x - pageBoundingClientRect.x - 1;
            const markYed = markedBoundingClientRect.y - pageBoundingClientRect.y - 1;
            if (nearMarkY < markYed || (nearMarkY == markYed && nearMarkX < markXed)) {
              var parent = highlightedMarkList[i].parentNode;
              parent.replaceChild(highlightedMarkList[i].firstChild, highlightedMarkList[i]);
              parent.normalize();
            }
          }
        }
      }

      if (highlightedMarkList.length > 0) {
        highlightedMarkList = document.querySelectorAll('mark[parent-id="'+parentId+'"]');
        for(let i = 0;i < highlightedMarkList.length; i++)
        {
          if(highlightedMarkList[i].getAttribute('parent-id') === parentId && highlightedMarkList[i].getAttribute('is-last-match') == '1') {
            var parent = highlightedMarkList[i].parentNode;
            parent.replaceChild(highlightedMarkList[i].firstChild, highlightedMarkList[i]);
            parent.normalize();
          }
        }
      }
    }
  }
  // start highlighting at target node
  this.apply = function (highlight, matchAll = false) {
    let input = highlight.content.text;
    let id = highlight.id
    let isFoundWord = false;
    if (input === undefined || !(input = input.replace(/(^\s+|\s+$)/g, ""))) {
      return;
    }
    if (this.setRegex(input)) {
      this.setMatchAll(matchAll);
      this.setParentId(id);
      this.setCurrentHighlightWordPosition(highlight);
      this.highlightNextWord(targetNode);
      this.setNearNextWord();
      var lastMatchedMark = document.querySelector(`mark[parent-id='${highlight.id}']`);

      if (lastMatchedMark !== null) {
        var isLastMatchAttribute = document.createAttribute("is-last-match");
        isLastMatchAttribute.value = 1;
        lastMatchedMark.setAttributeNode(isLastMatchAttribute);
        isFoundWord = true;

        const pageMarkPosition = parentId+"_x_"+lastMatchedMark.getAttribute('markX')+"_y_"+lastMatchedMark.getAttribute('markY');
        if (global.pageMarkList.indexOf(pageMarkPosition) === -1) {
          global.pageMarkList.push(pageMarkPosition)
        }
      }
    }
    return isFoundWord;
  };
}


export default Search;
