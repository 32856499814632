import React from "react";
import { ComboBox } from './../../common/DropDown/StandardComboBox';
import { CompositeGrid } from '../../common';
import { Input } from './../../common/InputBox/StandardInput';

function getGlobalValue(controlIndex){
  let controlValue = '';
  global.processBlockControlValues.forEach((control, index) => {
    if (control.controlId === controlIndex) {
      controlValue = global.processBlockControlValues[index].controlValue;

    }
  });
  return controlValue;
}

function saveGlobalValue(controlIndex, value){
  global.processBlockControlValues.forEach((control, index) => {
    if (control.controlId === controlIndex) {
      global.processBlockControlValues[index].controlValue = value;
    }
  });
}

function ReplaceTableTemplate() {
  const RefElementNameIndex = 56;
  const RefElementIdIndex = 57;
  const RefNameIndex = 58;
  const RefDescriptionIndex = 59;
  const StudyIdIndex = 60;
  const SubjectIdIndex = 61;
  let tenantID = this.state.tenantID;
  let studyData = this.state.studyList;
  //if(typeof(localStorage.getItem("study-list")) != 'undefined'){
   // studyData = JSON.parse(localStorage.getItem("study-list"));
  //}
  let defaultStudyId = "";
  let defaultsubjectId = "";
  let ids = [];
  let selectedStudy = studyData.filter(item => item.schemaName === tenantID);
  if(selectedStudy.length > 0){
    defaultStudyId = selectedStudy[0].identifier1;
    defaultsubjectId = selectedStudy[0].identifier2;
    ids.push(defaultStudyId);
    ids.push(defaultsubjectId);
  }
  if(getGlobalValue(StudyIdIndex) === ""){
    saveGlobalValue(StudyIdIndex, defaultStudyId);
  }
  if(getGlobalValue(SubjectIdIndex) === ""){
    saveGlobalValue(SubjectIdIndex, defaultsubjectId);
  }
  let refNamesCollection = [];
  let refElement = getGlobalValue(RefElementNameIndex);
  let refIdsCollection = [];
  if(refElement !== ""){
    this.state.referencePoints.forEach((item)=>{
      if(item.RF_NM === refElement)
      refIdsCollection.push(item.RFE_ID);
      });
      refIdsCollection.sort();
      refIdsCollection.push("Min");
      refIdsCollection.push("Max");
  }
  //populate reference point name
  refNamesCollection.push(...this.state.referencePointsName);
  // if(localStorage.ReferencePointsName){
  //   refNamesCollection = JSON.parse(localStorage.getItem("ReferencePointsName"));

  // }
  //populate reference point elemetn id
  // data.forEach((item)=>{
  //   if(item.RF_NM == refElement)
  //   refIdsCollection.push(item.RFE_ID);
  // });
  //refIdsCollection.push("Min");
  //refIdsCollection.push("Max");
  let refElementId = getGlobalValue(RefElementIdIndex);
  let refName = getGlobalValue(RefNameIndex);
  let refDescription = getGlobalValue(RefDescriptionIndex);
  let studyId = getGlobalValue(StudyIdIndex);
  let subjectId = getGlobalValue(SubjectIdIndex);

  var handleRefNameChange = (event)=>{
    refName = event.target.value;
    saveGlobalValue(RefNameIndex,event.target.value);
    this.setState({...this.state.tableList});
  }
  var handleRefDescriptionChange = (event)=>{
    refDescription = event.target.value;
    saveGlobalValue(RefDescriptionIndex,event.target.value);
    this.setState({...this.state.tableList});
  }
  var handleSubjectIdChange = (event)=>{
    subjectId = event.target.value;
    saveGlobalValue(SubjectIdIndex,event.target.value);
    this.setState({...this.state.tableList});
  }
  var handleStudyIdChange = (event)=>{
    studyId = event.target.value;
    saveGlobalValue(StudyIdIndex,event.target.value);
    this.setState({...this.state.tableList});
  }
  var handleRefElementChange = (event)=>{
    if(event.target.value == null)
    {
      refElement = "";
      refElementId = "";
    }else{
      refElement = event.target.value;
      refElementId = "";
    }
    saveGlobalValue(RefElementNameIndex,refElement);
    saveGlobalValue(RefElementIdIndex,refElementId);
     this.setState({...this.state.tableList});
  }
  var handleRefEleemntIdChange = (event)=>{
    if(event.target.value == null)
    {
      refElementId = "";
    }else{
      refElementId = event.target.value;
    }
    saveGlobalValue(RefElementIdIndex,refElementId);
    this.setState({...this.state.tableList});
  }
  return (
    <div className="process-option reference" id={this.state.tabSelected}>
      <div className="container-fluid">
        <div className="row">
          <div className="col-12">
            <fieldset>
              <div className="field_group reference_replace">
                <legend>Table Level Options</legend>
                <div className="">
                  <div className="">
                    <div className="filter-area am-form-inline col-md-12 m-0">
                        <div className="row">
                          <div className="col-md-6">
                            <label className="am-form-field">
                              <span className="col-3">Reference Point Name:</span>
                              <Input onChange={handleRefNameChange} value={refName}/>
                            </label>
                          </div>
                          <div className="col-md-6">
                            <label className="am-form-field">
                              <span className="col-3">Reference Point Description:</span>
                              <Input onChange={handleRefDescriptionChange} value={refDescription}/>
                            </label>
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-md-6">
                            <label className="am-form-field">
                              <span className="col-3">Study Id:</span>
                              <ComboBox className="m-0" data={ids} onChange={handleStudyIdChange} value={studyId}/>
                            </label>
                          </div>
                          <div className="col-md-6">
                            <label className="am-form-field">
                              <span className="col-3">Subject Id:</span>
                              <ComboBox className="m-0" data={ids} onChange={handleSubjectIdChange} value={subjectId}/>
                            </label>
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-md-6">
                            <label className="am-form-field">
                              <span className="col-3">Reference Point Name:</span>
                              <ComboBox className="m-0" data={refNamesCollection} onChange={handleRefElementChange}
                              value={refElement} />
                            </label>
                          </div>
                          <div className="col-md-6">
                            <label className="am-form-field">
                                <span className="col-3">Element Id:</span>
                                <ComboBox className="m-0" data={refIdsCollection} onChange={handleRefEleemntIdChange} value={refElementId}/>
                            </label>
                          </div>
                        </div>
                    </div>
                  </div>
                </div>
            </div>
            </fieldset>
          </div>
          <div className="col-md-6">
            <fieldset>
              <div className="field_group_main">
                <legend>Start Options</legend>
                <div className="height_fixed_Point_Replace">
                  <CompositeGrid replaceType={true} optionType = 'startOption' />
                </div>
              </div>
            </fieldset>
          </div>
          <div className="col-md-6">
            <fieldset>
              <div className="field_group_main">
                <legend>Stop Options</legend>
                <div className="height_fixed_Point_Replace">
                  <CompositeGrid replaceType={true} optionType = 'stopOption' />
                </div>
              </div>
            </fieldset>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ReplaceTableTemplate;
