import React from "react";
import TextEditor from '../../TextEditor';

class Code extends React.Component {
  render() {
      return (
        <div className="code_file">
          <TextEditor options={{ lineNumbers: true, readOnly: true}} code={global.blockCode} />
        </div>
      );
    }
}

export default Code;
