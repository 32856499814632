import React from 'react';
import { TabContent, TabPane, Nav, NavItem, NavLink } from 'reactstrap';
import { TransitionGroup, CSSTransition } from 'react-transition-group';
import classnames from 'classnames';

const contentId = 'content';
export class TabStrip extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      contentId: this.uuid()
    }
  }
  uuid = () => {
    return ([1e7]+-1e3+-4e3+-8e3+-1e11).replace(/[018]/g, c =>
      (c ^ crypto.getRandomValues(new Uint8Array(1))[0] & 15 >> c / 4).toString(16)
    );
  }
  childFactory = (child, selected) => {
      return React.cloneElement(child, Object.assign({}, child.props, { in: child.props.id === String(this.state.contentId + selected)}));
  }
  render() {
        return (
          <>
          {typeof(this.props.children) !== 'undefined' && this.props.children.length > 0 &&
            <>
              {(typeof(this.props.tabPosition) === 'undefined' || this.props.tabPosition === 'top') &&
                <Nav tabs>
                  {
                    this.props.children.map((tab, index) => {
                      return (
                        React.createElement(NavItem, {key: index, className:classnames({ active: this.props.selected === index }, typeof(tab.props.className) !== 'undefined' ? tab.props.className: '')},
                        React.createElement(NavLink,{ onClick: () => this.props.onSelect({selected: index})},tab.props.title))
                      )
                    })
                  }
                </Nav>
              }
              <TabContent activeTab={typeof(this.props.selected) !=='undefined' ? this.props.selected.toString(): '0'}>
                {
                  React.Children.map(this.props.children, (tab, index) => {
                    let selected = typeof(this.props.selected) !=='undefined' ? this.props.selected.toString(): '0'
                    let visible = index.toString() === selected;
                    let contentProps = {
                        'role': 'tabpanel',
                        'aria-expanded': true,
                        'style': {
                            'display': visible ? undefined : 'none'
                        }
                    };
                    let animationStyle = {
                        position: 'initial',
                        display: visible ? undefined : 'none'
                    }

                    return this.props.keepTabsMounted ? (
                      React.createElement(TransitionGroup, {key: index, style: animationStyle, childFactory: (child) => this.childFactory(child, selected), component: 'div'},
                        React.createElement(CSSTransition, {in: visible, appear: true, enter: true, exit: this.props.keepTabsMounted, key: index, id: String(this.state.contentId + index), timeout: {enter: 500, exit: 500 }, mountOnEnter: true, unmountOnExit: typeof(this.props.unmountOnExit) !== 'undefined' ? this.props.unmountOnExit : false },
                          React.createElement(TabPane, {tabId: index.toString()},
                            React.createElement('div', {className: "tab-child"},
                              React.createElement('div', {role: "tabpanel"},
                                tab.props.children
                              )
                            )
                          )
                        )
                      )
                    ) : (
                      React.createElement(TabPane, {tabId: index.toString()},
                        React.createElement('div', {className: "tab-child"},
                          React.createElement('div', {role: "tabpanel"},
                            tab.props.children
                          )
                        )
                      )
                    )
                  })
                }
              </TabContent>
              {typeof(this.props.tabPosition) !== 'undefined' && this.props.tabPosition === 'bottom' &&
                <Nav className={this.props.tabPosition} tabs>
                  {
                    this.props.children.map((tab, index) => {
                      return (
                        React.createElement(NavItem, {key: index, className:classnames({ active: this.props.selected === index }, typeof(tab.props.className) !== 'undefined' ? tab.props.className: '')},
                        React.createElement(NavLink,{ onClick: () => this.props.onSelect({selected: index})},tab.props.title))
                      )
                    })
                  }
                </Nav>
              }
            </>
            }
          </>
        );
    }
}
