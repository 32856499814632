import React from "react";
import { DropDownList } from './../../common/DropDown/StandardDropDownList';
import { CompositeGrid } from '../../common';
import { Input } from './../../common/InputBox/StandardInput';

function getGlobalValue(controlIndex){
  let controlValue = '';
  global.processBlockControlValues.forEach((control, index) => {
    if (control.controlId === controlIndex) {
      controlValue = global.processBlockControlValues[index].controlValue;

    }
  });
  return controlValue;
}

function saveGlobalValue(controlIndex, value){
  global.processBlockControlValues.forEach((control, index) => {
    if (control.controlId === controlIndex) {
      global.processBlockControlValues[index].controlValue = value;
    }
  });
}

function CompositeTableTemplate() {
  const RefNameIndex = 58;
  const RefDescriptionIndex = 59;
  const StudyIdIndex = 60;
  const SubjectIdIndex = 61;
  let tenantID = this.state.tenantID;
  let studyData = this.state.studyList;
  //if(typeof(localStorage.getItem("study-list")) != 'undefined'){
   // studyData = JSON.parse(localStorage.getItem("study-list"));
  //}
  let defaultStudyId = "";
  let defaultsubjectId = "";
  let ids = [];
  let selectedStudy = studyData.filter(item => item.schemaName === tenantID);
  if(selectedStudy.length > 0){
    defaultStudyId = selectedStudy[0].identifier1;
    defaultsubjectId = selectedStudy[0].identifier2;
    ids.push(defaultStudyId);
    ids.push(defaultsubjectId);
  }
  if(getGlobalValue(StudyIdIndex) === ""){
    saveGlobalValue(StudyIdIndex, defaultStudyId);
  }
  if(getGlobalValue(SubjectIdIndex) === ""){
    saveGlobalValue(SubjectIdIndex, defaultsubjectId);
  }
  let refName = getGlobalValue(RefNameIndex);
  let refDescription = getGlobalValue(RefDescriptionIndex);
  let studyId = getGlobalValue(StudyIdIndex);
  let subjectId = getGlobalValue(SubjectIdIndex);

  var handleRefNameChange = (event)=>{
    refName = event.target.value;
    saveGlobalValue(RefNameIndex,event.target.value);
    this.setState({...this.state.tableList});
  }
  var handleRefDescriptionChange = (event)=>{
    refDescription = event.target.value;
    saveGlobalValue(RefDescriptionIndex,event.target.value);
    this.setState({...this.state.tableList});
  }
  var handleSubjectIdChange = (event)=>{
    subjectId = event.target.value;
    saveGlobalValue(SubjectIdIndex,event.target.value);
    this.setState({...this.state.tableList});
  }
  var handleStudyIdChange = (event)=>{
    studyId = event.target.value;
    saveGlobalValue(StudyIdIndex,event.target.value);
    this.setState({...this.state.tableList});
  }
  return (
    <div className="process-option reference">
      <div className="container-fluid">
        <div className="row">
          <div className="col-12">
            <fieldset>
              <div className="field_group">
                <legend>Table Level Options</legend>
                <div className="">
                  <div className="">
                    <div className="filter-area am-form-inline col-12 m-0">
                      <div className="row">
                        <div className="col-md-6">
                          <label className="am-form-field">
                            <span>Reference Point Name:</span>
                            <Input onChange={handleRefNameChange} value={refName} />
                          </label>
                          <label className="am-form-field">
                            <span>Reference Point Description:</span>
                            <Input onChange={handleRefDescriptionChange} value={refDescription}/>
                          </label>
                        </div>
                        <div className="col-md-6">
                          <label className="am-form-field">
                            <span>Study Id:</span>
                            <DropDownList className="m-0" data={ids} onChange={handleStudyIdChange} value={studyId}/>
                          </label>
                          <label className="am-form-field">
                            <span>Subject Id:</span>
                            <DropDownList className="m-0" data={ids} onChange={handleSubjectIdChange} value={subjectId}/>
                          </label>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </fieldset>
          </div>
          <div className="col-6">
            <fieldset>
              <div className="field_group_main">
                <legend>Start Options</legend>
                <div className="height_fixed_ReferencePoint">
                  <CompositeGrid optionType = 'startOption'/>
                </div>
              </div>
            </fieldset>
          </div>
          <div className="col-6">
            <fieldset>
              <div className="field_group_main">
                <legend>Stop Options</legend>
                <div className="height_fixed_ReferencePoint">
                  <CompositeGrid optionType = 'stopOption'/>
                </div>
              </div>
            </fieldset>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CompositeTableTemplate;
