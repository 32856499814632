import React from "react";
import { TabStrip, TabStripTab  } from './../common/Layout';
import { BlockTab } from '../common/ProcReport/BlockTab';
import ProcReportLayoutTemplate from './ProcReportLayout.jsx';



function ProcReportLayoutNewTemplate() {
    return (<div>
        <div className="process-option">
        <TabStrip keepTabsMounted={true} selected={this.state.tabSelected} onSelect={this.handleSelectTabPanel}>
            <TabStripTab title="Block Values">
            <BlockTab />
            </TabStripTab>
            <TabStripTab title="REPORT Definition">
            <div> {
                 ProcReportLayoutTemplate.call(this)
            }
            </div>
            </TabStripTab>
        </TabStrip>
        </div>
        </div>);
}

export default ProcReportLayoutNewTemplate;
