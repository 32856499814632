import React from "react";
import { connect } from 'react-redux';
import { setMessage } from '../../../../actions/actionNotification';
import { createSelector } from 'reselect';
import RichGridTable from '../../RichGridTable';
import { Button } from './../common/Button/StandardButton';
import { NewColumnNameCell } from '../common/InputBox/NewColumnNameCell';
import { NewColumnNameRequired }  from '../common/InputBox/NewColumnNameRequired';
import { NumericColumnCell } from '../common/InputBox/NumericColumnCell';
import { NewColumnNameCellForValue } from '../common/InputBox/NewColumnNameCellForValue';
import { NumericColumnCellLength } from '../common/InputBox/NumericColumnCellLength';
import { ColumnTypeCell } from '../common/DropDown/ColumnTypeCell';

class PostIntialization extends React.Component {
    PostIntializationIndex = -94;
    constructor(props) {
        super(props);
        this.state = {
          tableList:[{
            id: 'post-initialization-grid',
            rows: [{"rowId": 0,"columnNewId": 0, "selected": false, "colName":"", "colType" : "CHAR", "colLength" : "10", "colValue" : ""}],
            resize: true,
            filterable: false,
            selectedField: "selected",
            columnProperty: [
              { field: "selected", orderIndex: 1, className:"text-center", sortable:false,show: true},
              { field: "rowId", show: false },
              { field: "columnNewId", show: false },
              { field: "colName", sortable:false, width: "400px", cell: NewColumnNameRequired, orderIndex: 2, title:"Variable Name"},
              { field: "colType", sortable:false, width: "150px", cell: ColumnTypeCell, orderIndex: 3, title:"Variable Type"},
              { field: "colLength", sortable:false, width: "150px", cell: NumericColumnCellLength, orderIndex: 4, title:"Variable Length"},
              { field: "colValue", sortable:false, cell: NewColumnNameCellForValue, orderIndex: 5, title:"Variable Value"}
            ],
          }]
        }
        if(this.getGlobalValue(this.PostIntializationIndex) !== ""){
          let rowData = JSON.parse(this.getGlobalValue(this.PostIntializationIndex));
          if(rowData.length > 0){
            this.state.tableList[0].rows.length = 0;
            let that = this;
            rowData.forEach((row, index) => {
              that.state.tableList[0].rows.push(Object.assign({},{rowId:index,columnNewId:index,selected:false}, row));
            });
          }
        } else{
            this.saveGlobalValue(-94, JSON.stringify(this.state.tableList[0].rows));
        }
        this.handleAddRowClick = this.handleAddRowClick.bind(this);
        this.handleRemoveRowClick = this.handleRemoveRowClick.bind(this);
      }
      getGlobalValue(controlIndex){
        let controlValue = '';
        global.processBlockControlValues.forEach((control, index) => {
          if (control.controlId === controlIndex) {
            controlValue = global.processBlockControlValues[index].controlValue;

          }
        });
        return controlValue;
      }

      saveGlobalValue(controlIndex, value){
        let controlIdFound = false;
        global.processBlockControlValues.forEach((control, index) => {
          if (control.controlId === controlIndex) {
            controlIdFound = true;
            global.processBlockControlValues[index].controlValue = value;
          }
        });
        if(!controlIdFound){
            global.processBlockControlValues.push({controlId:controlIndex, controlType:controlIndex,controlValue:value});
        }
      }

      handleAddRowClick(event) {
        var  tableList = this.state.tableList;
        var newRowId = 0;
        if (typeof(tableList[0].rows[0]) !== 'undefined' && tableList[0].rows[0]["rowId"] === '') {
            tableList[0].rows.pop();
        }

        if (tableList[0].rows.length > 0) {
          let maxRowId = Math.max.apply(Math, tableList[0].rows.map(function(o) { return o.rowId; }))
          newRowId = maxRowId + 1;
        }

        var addRow = {"rowId": newRowId,"columnNewId": newRowId, "selected": false, "colName":"", "colType" : "CHAR", "colLength" : "10", "colValue" : ""};

        tableList[0].rows.push(addRow);

        this.setState({ tableList: tableList });
        this.saveGlobalValue(this.PostIntializationIndex, JSON.stringify(tableList[0].rows));
      }

      handleRemoveRowClick(event) {
        let tableList = this.state.tableList;
        const { rows } = this.state.tableList[0];
        if(typeof(rows) !== 'undefined') {
          tableList = this.removeTableRowsRecursiveElements(tableList)
        }
        this.setState({ tableList: tableList });
        this.saveGlobalValue(this.PostIntializationIndex, JSON.stringify(this.state.tableList[0].rows));
      }

      removeTableRowsRecursiveElements(tableList) {
        var index;
        for (index = 0; index < tableList[0].rows.length; index++) {
          if (tableList[0].rows[index].selected === true) {
            tableList[0].rows.splice(index, 1);
          }
        }

        for (index = 0; index < tableList[0].rows.length; index++) {
          if (tableList[0].rows[index].selected === true) {
            tableList = this.removeTableRowsRecursiveElements(tableList);
          }
        }

        return tableList;
      }

      render() {
          return (
            <div className="height-fixed-post-initilization">
              <div className="filter-area am-form-inline col-12">
                <div className="clearfix m-b-10">
                  <Button primary={true} onClick={this.handleAddRowClick}>Add</Button>
                  <Button primary={true} className="m-left" onClick={this.handleRemoveRowClick}>Delete</Button>
                </div>
              </div>
              <RichGridTable {...this.state.tableList[0]} />
            </div>
          )
        }
}

const mapStateToProps = createSelector(
    state => state.processFlow.tenantId,
    state => state.study.studyList,
    (tenantId,studyList) => ({
      tenantId,
      studyList
    })
  );

  const mapActionsToProps = {
    setMessage:setMessage
  }
  export default connect(mapStateToProps, mapActionsToProps)(PostIntialization);
