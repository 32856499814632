import React from 'react';
import { connect } from 'react-redux';
import { createSelector } from 'reselect';
import { PropTypes } from 'prop-types';
import template from "./Notification.jsx";
import MessageTypeConst from '../../MessageTypeConst';
import { resetMessage } from '../../../actions/actionNotification';

//https://reactstrap.github.io/components/alerts/
class Notification extends React.Component {
    constructor(props){
        super(props);
        let color = 'light';
        switch(props.messageType){
            case MessageTypeConst.SUCCESS_MESSAGE:
                color='success';
                break;
            case MessageTypeConst.WARNING_MESSAGE:
                color='warning';
                break;
            case MessageTypeConst.ERROR_MESSAGE:
                color='danger';
                break;
            case MessageTypeConst.INFORMATION_MESSAGE:
                color='info';
                break;
            default:
                break;

        }
        this.state = {
            visible: false,
            color:color,
            alertMessage:props.message,
            visibilityDuration:props.visibilityDuration
        }
        this.onDismiss = this.onDismiss.bind(this);
    }

    UNSAFE_componentWillReceiveProps(nextProps){
        if(nextProps.messageType === 0 || nextProps.message === ""){
            return;
        }
        let color = 'light';
        switch(nextProps.messageType){
            case MessageTypeConst.SUCCESS_MESSAGE:
                color='success';
                break;
            case MessageTypeConst.WARNING_MESSAGE:
                color='warning';
                break;
            case MessageTypeConst.ERROR_MESSAGE:
                color='danger';
                break;
            case MessageTypeConst.INFORMATION_MESSAGE:
                color='info';
                break;
            default:
                break;

        }
        this.setState({
            color:color,
            alertMessage:nextProps.message,
            visibilityDuration:nextProps.visibilityDuration,
            visible: true});
    }

    onDismiss(event, reset = false) {
        if (this.state.color !== 'danger' || reset) {
          this.setState({ visible: false });
          this.props.resetMessage();
        }
    }

    render() {
        return template.call(this);
    }
}

const mapStateToProps = createSelector(
    state => state.notification.message,
    state => state.notification.messageType,
    state => state.notification.visibilityDuration,
    (message, messageType, visibilityDuration) => ({
        message,
        messageType,
        visibilityDuration
    })
  );

  const mapActionsToProps = {
    resetMessage: resetMessage
  }

  Notification.propTypes = {
    message: PropTypes.string,
    messageType: PropTypes.number,
    visibilityDuration: PropTypes.number,
  }

  export default connect(mapStateToProps, mapActionsToProps)(Notification);
