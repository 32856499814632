import React from 'react';
import { Button } from './StandardButton';
import { Input } from './../InputBox/StandardInput';
import { PropTypes } from "prop-types";
import Dialog from 'react-dialog';
import { Range } from 'rc-slider';

Dialog.propTypes = {
    height: PropTypes.oneOfType([
        PropTypes.string
    ]),
    width: PropTypes.oneOfType([
        PropTypes.string
    ])
}

export const RangeScaleCell = ({ tableManager, value, data, column, colIndex, rowIndex }) => {
    if (typeof(tableManager) === 'undefined') {
      return (null)
    } else {
      return (
          <div className='rgt-cell-inner' style={{display: 'flex', alignItems: 'center', overflow: 'hidden'}}>
              <RangeScaleCellClass dataItem={data} field={column.field} value={typeof(data[column.field]) !== 'undefined' ? data[column.field]: value} column={column} tableManager={tableManager}/>
          </div>
      )
    }
}

class RangeScaleCellClass extends React.Component {
    constructor(props) {
      super(props);

      this.state = {
          visible: false,
          saleRange:[],
          rangeScale:'',
          minValue:0,
          maxValue:30
      };

      this.toggleDialog = this.toggleDialog.bind(this);
      this.handleNextClick = this.handleNextClick.bind(this);
    }

    toggleDialog() {
        this.setState({
            visible: !this.state.visible,
        });
    }
    handleChange(e) {
      this.props.dataItem[this.props.field] = e.target.value;
      this.props.tableManager.rowsApi.setRows(this.props.tableManager.rowsApi.rows)
    }

    handleSaveGridData(e) {
      this.props.dataItem[this.props.field] = JSON.stringify(this.state.tableList[0].rows);
      this.props.tableManager.rowsApi.setRows(this.props.tableManager.rowsApi.rows)
      this.toggleDialog();
    }

    handleNextClick(e) {

    }
    render() {
        var disabled = false;
        if (this.props.dataItem['type'] !== 'CHAR') {
          disabled = true;
        }
        return (
            <>
              <Button primary={true} disabled={disabled} onClick={this.toggleDialog}>Set Range Scale</Button>
                {this.state.visible && <Dialog title={"Range Scale"} width="auto" height="auto" onClose={this.toggleDialog} buttons={[<button className="k-button" onClick={this.toggleDialog} key="btn-cancel">Cancel</button>,<button className="k-button" onClick={this.handleSaveGridData} key="btn-ok">Ok</button>]}>
                        <div className="row clearfix">
                          <div className="col-4">Range Count : </div>
                          <div className="col-4"><Input className="am-textbox" defaultValue={this.state.rangeScale} /></div>
                          <div className="col-4"><Button primary={true} className="width-full" onClick={this.handleNextClick}>Next</Button></div>
                        </div>

                        <div className="row">
                            <div className="col-2"><Input className="am-textbox"/></div>
                            <div className="col-8">
                              <Range
                                  min={this.state.minValue}
                                  max={this.state.maxValue} />
                            </div>
                            <div className="col-2"><Input className="am-textbox" /></div>
                        </div>
                      </Dialog>}
            </>
        );
    }
}
