import React, {useState} from "react";
import { PropTypes } from "prop-types";
import { connect } from 'react-redux';
import { createSelector } from 'reselect';
import axios from 'axios';
import $ from 'jquery';
import Dialog from 'react-dialog';
import {Row, Col} from 'reactstrap';
import { Button } from './../DataOperation/common/Button/StandardButton';
import { DropDownList } from './../DataOperation/common/DropDown/StandardDropDownList';
import { Input } from './../DataOperation/common/InputBox/StandardInput';
import RichGridTable from './../RichGridTable';
import restClient from '../../../restClient';
import { CREATE, DELETE, UPDATE, GET_ALL, GET, POST_PARAM_BODY } from '../../../restClient/types';
import MessageTypeConst from "../../MessageTypeConst";
import { setMessage } from '../../../actions/actionNotification';
import { setTreeViewData } from './../../../actions/actionTreeView';
import { updateProcessFlowBlockBySchemaName, updateProcessFlowSyncFlagByTabIndex, updateProcessflowBlockRenderStatus } from './../../../actions/actionProcessFlow';
import { RelatedBlocks } from './../DataOperation/common';

const searchTypeList = [
  {name: "Text", value: 5},
  {name: "Study", value: 4},
  {name: "Process Group", value: 3},
  {name: "Process Flow", value: 2},
  {name: "Block", value: 1}
]
const replaceTypeList = [
  {name: "Text", value: 6},
  {name: "Study", value: 5},
  {name: "Process Group", value: 4},
  {name: "Process Flow", value: 3},
  {name: "Block", value: 2},
  {name: "Link Block", value: 1}
]
const blankTable = {
  id: 'blank-table-grid',
  rows: [],
  resize: true,
  filterable: false,
  selectedField: "selected",
  columnProperty: [
    { field: "selected", className:"text-center", show: false, sortable:false},
    { field: "rowId", show: false, sortable:false },
    { field: "blockId", show: false, sortable:false },
    { field: "blockTable", show: false, sortable:false },
    { field: "processBlock", show:false},
    { field: "blockName", cell: RelatedBlocks}
  ]
}
function SearchReplace(props) {
  const [sourceType, setSourceType] = useState({name: "Block", value: 1});
  const [sourceText, setSourceText] = useState('');
  const [sourceBlockList, setSourceBlockList] = useState(typeof(props.searchSourceBlockList) !== 'undefined' ? props.searchSourceBlockList: []);
  const [targetType, setTargetType] = useState({name: "Block", value: 1});
  const [targetText, setTargetText] = useState('');
  const [targetBlockList, setTargetBlockList] = useState([]);
  const [replaceType, setReplaceType] = useState({name: "Link Block", value: 1});
  const [refreshSourceKey, setRefreshSourceKey] = useState(0);
  const [refreshTargetKey, setRefreshTargetKey] = useState(0);
  const [sourceBlockStatus, setSouceBlockStatus] = useState(typeof(props.sourceBlockStatus) !== 'undefined' ? sourceBlockStatus : true)
  const handleSearchClick = (type = 'source') => {
    $(".loader").show();
    let param = {searchText:sourceText, searchType:sourceType.value}
    if (type == 'target') {
      param = {searchText:targetText, searchType:targetType.value}
    }
    axios.all([restClient(CREATE, 'search', {data: param}, {'Tenant-ID': props.node.study.schemaName})])
      .then(axios.spread((response) => {
          let blockList = [];
          response.data.blocks.forEach(block => {
            blockList.push({selected: false, rowId: blockList.length, blockId: block.blockId, blockTable: block.blockTable, blockName: block.blockName + " (v"+block.blockVersion+")", flowName: block.flowName, groupName: block.groupName, processBlock: block})
          })
          if (type == 'target') {
            setTargetBlockList(blockList)
          } else {
            setSourceBlockList(blockList)
          }

          if (type == 'target') {
            setRefreshTargetKey(refreshTargetKey+1)
          } else {
            setRefreshSourceKey(refreshSourceKey+1)
          }
          props.setMessage(response.data.message, response.data.messageType);
          $(".loader").hide();
      })).catch(error => {
            $(".loader").hide();
            props.setMessage("Error occurred while fetching data!", MessageTypeConst.ERROR_MESSAGE);
      });
  }

  const handleReplaceClick = () => {
    if (sourceBlockList.filter(sourceBlock => sourceBlock.selected === true).length > 0 && targetBlockList.filter(targetBlock => targetBlock.selected === true).length > 0) {
      $(".loader").show();
      let param = {replaceType: replaceType.value, replaceParams:[{
        sourceBlockId: sourceBlockList.filter(sourceBlock => sourceBlock.selected === true)[0].blockId,
        targetBlockId: targetBlockList.filter(targetBlock => targetBlock.selected === true)[0].blockId,
      }]};

      axios.all([restClient(CREATE, 'replace', {data: param}, {'Tenant-ID': props.node.study.schemaName})])
        .then(axios.spread((response) => {
            let processFlowStatus = [];
            props.setMessage(response.data.message, response.data.messageType);
            if(response.data.processBlock !== null && typeof(response.data.processBlock) !== "undefined" && response.data.processBlock.length > 0){
              props.updateProcessFlowBlockBySchemaName(props.node.study.schemaName, response.data.processBlock);
            }
            if(response.data.processFlow !== null && typeof(response.data.processFlow) !== "undefined" && response.data.processFlow.length > 0){
              response.data.processFlow.forEach((flow) => {
                processFlowStatus.push({processId:flow.processId,processSyncFlag:flow.processSyncFlag, studyId: flow.studyId, isSampleFiltered: flow.isSampleFiltered})
              });
              props.processFlowList.forEach((processFlow, index) => {
                response.data.processFlow.forEach((flow) => {
                  if (processFlow.process.studyId === flow.studyId && processFlow.process.processId === flow.processId) {
                    props.updateProcessFlowSyncFlagByTabIndex(index, flow.processSyncFlag);
                    props.updateProcessflowBlockRenderStatus(index, true);
                  }});
                });
            }
            updateFlowIconInTreeView(processFlowStatus);
            $(".loader").hide();
            props.closeEvent(null, {}, true, props.node.study.schemaName)
        })).catch(error => {
              $(".loader").hide();
              props.setMessage("Error occurred while fetching data!", MessageTypeConst.ERROR_MESSAGE);
        });
    } else {
      props.setMessage("please select at least one block out of list!", MessageTypeConst.ERROR_MESSAGE);
    }
  }

  const updateFlowIconInTreeView = (processFlowStatus) => {
    let treeView = props.treeView;
    treeView.children.forEach((project, index) => {
      project.children.forEach((study) => {
        processFlowStatus.forEach((updatedFlow)=>{
          if (study.study.studyId === updatedFlow.studyId) {
            study.children.forEach((studyElement) => {
              if (studyElement.name === 'ProcessFlows') {
                studyElement.children.forEach((classfier) => {
                  classfier.children.forEach((processGroup) => {
                    processGroup.children.map((flow)=>{
                      if(updatedFlow.processId === flow.process.processId){
                        flow.process.processSyncFlag = updatedFlow.processSyncFlag;
                        if(updatedFlow.processSyncFlag === 2){
                          flow.iconClass = "fa fa-exclamation-triangle yellow";
                        }
                        else if(updatedFlow.processSyncFlag === 3){
                          flow.iconClass = "fa fa-exclamation-circle red";
                        }
                        else if(updatedFlow.processSyncFlag === 1){
                          flow.iconClass = "fa fa-microchip";
                        }
                        if (typeof(updatedFlow.isSampleFiltered) !== 'undefined') {
                            flow.process.isSampleFiltered = updatedFlow.isSampleFiltered
                        }
                      }
                      return flow;
                    })
                  });
                })
              }
            })
          }
        })
      });
    })
    props.setTreeViewData(treeView);
  }
  return (
    React.createElement('div', {className: "search-replace referring-block"},
      React.createElement(Dialog, {title: `Search/Replace - ${props.node.name}`, width: "84%", height: "calc(100vh - 50px)", modal: true, onClose:props.closeEvent},
        React.createElement(Row, {className: "m-t-5"},
          React.createElement(Col, {md: "6", className: "p-r-5"},
            React.createElement("fieldset", {},
              React.createElement("div", {className: "field_group"},
                React.createElement("legend", {}, "Search Source"),
                React.createElement(Row, {className: "m-t-5"},
                  React.createElement(Col, {md: "3"},
                    React.createElement("div", {className: "am-form-field"},
                      React.createElement(DropDownList, {data: searchTypeList, textField: 'name', dataItemKey:'value', onChange: (event) => setSourceType(event.target.value), value:sourceType, className:"w-100", disabled: true}, null),
                    )
                  ),
                  React.createElement(Col, {md: "7", className: "p-0"},
                    React.createElement("div", {className: "am-form-field"},
                      React.createElement(Input, {onChange: (event) => setSourceText(event.target.value), value:sourceText, className:"w-100", disabled: !sourceBlockStatus}, null),
                    )
                  ),
                  React.createElement(Col, {md: "2"},
                    React.createElement("div", {className: "am-form-field"},
                      React.createElement(Button, {primary: true, onClick: (event) => handleSearchClick('source'), className:"w-100", disabled: sourceText === '' || !sourceBlockStatus}, 'Find'),
                    )
                  )
                ),
                React.createElement(Row, {className: "m-t-5"},
                  React.createElement(Col, {md: "12", className: `full-height-grid${!sourceBlockStatus? ' disabled-grid' : ' row-selectable single'}`},
                    React.createElement(RichGridTable, {...blankTable, id: "source-table-grid", rows: sourceBlockList, key: refreshSourceKey}, null)
                  )
                )
              )
            )
          ),
          React.createElement(Col, {md: "6"},
            React.createElement("fieldset", {},
              React.createElement("div", {className: "field_group"},
                React.createElement("legend", {}, "Search Target"),
                React.createElement(Row, {className: "m-t-5"},
                  React.createElement(Col, {md: "3"},
                    React.createElement("div", {className: "am-form-field"},
                      React.createElement(DropDownList, {data: searchTypeList, textField: 'name', dataItemKey:'value', onChange: (event) => setTargetType(event.target.value), value:targetType, className:"w-100", disabled: true}, null),
                    )
                  ),
                  React.createElement(Col, {md: "7", className: "p-0"},
                    React.createElement("div", {className: "am-form-field"},
                      React.createElement(Input, {onChange: (event) => setTargetText(event.target.value), value:targetText, className:"w-100"}, null),
                    )
                  ),
                  React.createElement(Col, {md: "2"},
                    React.createElement("div", {className: "am-form-field"},
                      React.createElement(Button, {primary: true, onClick: (event) => handleSearchClick('target'), className:"w-100", disabled: targetText === ''}, 'Find'),
                    )
                  )
                ),
                React.createElement(Row, {className: "m-t-5"},
                  React.createElement(Col, {md: "12", className: "full-height-grid row-selectable single"},
                    React.createElement(RichGridTable, {...blankTable, id: "target-table-grid", rows: targetBlockList, key: refreshTargetKey}, null)
                  )
                )
              )
            )
          )
        ),
        React.createElement(Row, {className: "m-t-5"},
          React.createElement(Col, {md: "12"},
            React.createElement("fieldset", {},
              React.createElement("div", {className: "field_group"},
                React.createElement("legend", {}, "Replace"),
                React.createElement(Row, {className: "m-t-5"},
                  React.createElement(Col, {md: "2", className: "offset-4"},
                    React.createElement("div", {className: "am-form-field"},
                      React.createElement(DropDownList, {data: replaceTypeList, textField: 'name', dataItemKey:'value', onChange: (event) => setReplaceType(event.target.value), value:replaceType, className:"w-100", disabled: true}, null),
                    )
                  ),
                  React.createElement(Col, {md: "2", className: "p-0"},
                    React.createElement("div", {className: "am-form-field"},
                      React.createElement(Button, {primary: true, onClick: handleReplaceClick, className:"w-100"}, 'Replace'),
                    )
                  )
                )
              )
            )
          )
        )
      )
    )
  )
}

Dialog.propTypes = {
    height: PropTypes.oneOfType([
        PropTypes.string
    ]),
    width: PropTypes.oneOfType([
        PropTypes.string
    ])
}
const mapStateToProps = createSelector(
  state => state.processFlow.tenantId,
  state => state.processFlow.processFlowList,
  state => state.treeView.data,
  (tenantId, processFlowList, treeView) => ({
    tenantId,
    processFlowList,
    treeView
  })
);

const mapActionsToProps = {
  setMessage: setMessage,
  updateProcessFlowBlockBySchemaName: updateProcessFlowBlockBySchemaName,
  updateProcessFlowSyncFlagByTabIndex: updateProcessFlowSyncFlagByTabIndex,
  updateProcessflowBlockRenderStatus: updateProcessflowBlockRenderStatus,
  setTreeViewData: setTreeViewData
}
export default connect(mapStateToProps, mapActionsToProps)(SearchReplace);
