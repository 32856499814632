import React from "react";
import axios from 'axios';
import cloneDeep from 'lodash/cloneDeep';
import restClient from '../../../../restClient';
import { GET_ONE , GET_ALL } from '../../../../restClient/types';
import { AlignmentCell, AlignmentForCharCell, SpacingCell, CaseCell, SeachPolicyCell, SeachConditionCell, SplitOperationCell, DateTypeCell, OutputTypeCell, ConversionTypeCell, DecimalFormatCell, SearchCriteriaCell, NewColumnNameCell, NewColumnNameForCharCell, BasicCheckBoxForCharCell, CaseSensitiveCell, OperatorCell, ObyProcessCell, SortOpsGbyCheckBoxCell, BasicGbyCheckBoxCell, ReportingOpsGBYCell, RankOpsNewColumnNameCell, ReportingOpsRowCell, ReportingOpsCheckboxCell, NewColumnSpanCell } from "../common";
import GridLayoutTemplate from './GridLayout.jsx';
import DataOpsLayoutTemplate from './DataOpsLayout.jsx';
import DecollapseOneLayoutTemplate from './DecollapseOneLayout.jsx';
import SortOpsLayoutTemplate from './SortOpsLayout.jsx';
import RankOpsLayoutTemplate from './RankOpsLayout.jsx';
import CriteriaOpsLayoutTemplate from './CriteriaOpsLayout.jsx';
import PivotLayoutTemplate from './PivotLayout.jsx';
import PivotMatrixLayoutTemplate from './PivotMatrixLayout.jsx';
import VariableMappingLayoutTemplate from './VariableMappingLayout.jsx';
import AppendLayoutTemplate from './AppendLayout.jsx';
import JoinLayoutTemplate from './JoinLayout.jsx';
import CompareLayoutTemplate from './CompareLayout.jsx';
import DecollapseTwoLayoutTemplate from './DecollapseTwoLayout.jsx';
import LinkLayoutTemplate from './LinkLayout.jsx';
import CodeEditorLayoutTemplate from './CodeEditorLayout.jsx';
import ReportingOpsLayoutTemplate from './ReportingOpsLayout.jsx';
import {SaveDefaultParametersToGlobal, SaveDefaultParametersToGlobalForR} from './ProcReportLayout.jsx';
import ProcReportNewLayoutTemplate from './ProcReportNewLayout.jsx';
import OneTableTemplate from './Reference/OneTable.jsx';
import TwoTableTemplate from './Reference/TwoTable.jsx';
import CompositeTableTemplate from './Reference/CompositeTable.jsx';
import ReplaceTableTemplate from './Reference/ReplaceTable.jsx';
import CombineTableTemplate from './Reference/CombineTable.jsx';
import GraphLayoutTemplate, {saveDefaultParametersToGlobalForGraph} from './GraphLayout.jsx'
import { setReferencePoints, enableExecuteButton } from '../../../../actions/actionDataOperation';
import { setMessage } from '../../../../actions/actionNotification';
import MessageTypeConst from "../../../MessageTypeConst";
import { connect } from 'react-redux';
import { createSelector } from 'reselect';
import $ from 'jquery';
import DataOpsIdentifierConstants from "../../../DataOpsIdentifierConstants";
import ConfirmBox from './../common/ConfirmBox';

class ProcessOption extends React.Component {
  constructor(props) {
    super(props);

    this.state={
      raiseExecute:props.raiseExecute,
      tableList:[],
      template: GridLayoutTemplate,
      tabSelected:0,
      subtabSelected:0,
      inputTable: typeof(props.processFlowTable) !== 'undefined' && props.processFlowTable !== null  ? props.processFlowTable.split(',') : [],
      inputColumns: [],
      tenantID: props.tenantID,
      processFlowAction: props.processFlowAction,
      processFlowTable: props.processFlowTable,
      processFlowTableId: props.processFlowTableId,
      studyList: props.studyList,
      quickcondition:props.quickcondition,
      inputTableStructure: [],
      activeProcessFlow:props.activeProcessFlow,
      processFlowList:props.processFlowList,
      latestInputTable:this.getLatestInputTable(props.activeProcessFlow,props.processFlowList, props.processFlowTableId),
      referencePoints:props.referencePoints,
      referencePointsName:props.referencePointsName,
      gridKey:0
    };

    this.handleSeachDataChangeEvent = this.handleSeachDataChangeEvent.bind(this);
    this.initialProcessOption = this.initialProcessOption.bind(this);
    this.prePareProcessOptionGrid = this.prePareProcessOptionGrid.bind(this);
    this.handleSelectTabPanel = this.handleSelectTabPanel.bind(this);
    this.handleSelectSubTabPanel = this.handleSelectSubTabPanel.bind(this);
    this.getInputColumnList = this.getInputColumnList.bind(this);
    this.updateGlobalColumnAlwaysDisplay = this.updateGlobalColumnAlwaysDisplay.bind(this);
    this.recursiveSelectedInputTables = this.recursiveSelectedInputTables.bind(this);
    this.getLatestInputTable = this.getLatestInputTable.bind(this);
    this.checkWhetherDateTimeColumnsPresent = this.checkWhetherDateTimeColumnsPresent.bind(this);
    this.validateModule = this.validateModule.bind(this);

    global.ColumnAlwaysNeedsToBeDisplay = ['APP_KEY', '_app_pkey'];
    if (global.updateBlock === false) {
      global.processBlockControlValues.forEach((control, index) => {
        if (control.controlId === -97) {
          global.processBlockControlValues[index].controlValue = props.processFlowTable;
        }
      });
      global.processBlockControlValues.forEach((control, index) => {
        if (control.controlId === -95) {
          global.processBlockControlValues[index].controlValue = props.processFlowTableId;
        }
      });
    }

  }

  getLatestInputTable(activeProcessFlow,processFlowList, processFlowTableId){
    var latestInputTable = [];
    let processFlow = processFlowList[activeProcessFlow];
    if(processFlow !== null && typeof(processFlow) !== "undefined"){
      let filterLinks = processFlow.process.processBlockLink.filter(item => item.blockId2 === parseInt(processFlowTableId, 10));
      if(filterLinks.length> 0){
        var inputBlocks = [];
          filterLinks.forEach((filterLink) => {
              inputBlocks.push(...processFlow.process.processBlock.filter(block => block.blockId === filterLink.blockId1));
          });
          if(inputBlocks.length > 0){
            inputBlocks.forEach((block)=>{
              latestInputTable.push(block.blockTable);
            })
          }
      }
    }
    return latestInputTable;
  }
  updateGlobalColumnAlwaysDisplay (newColumn, oldColumn = ''){
    if(oldColumn === null || oldColumn === ''){
      //add new value
      if(typeof(global.ColumnAlwaysNeedsToBeDisplay) !== 'undefined'){
        let columnFound = false;
        global.ColumnAlwaysNeedsToBeDisplay.forEach((column) => {
          if(column === newColumn){
            columnFound = true;
          }
        });
        if(!columnFound  && newColumn !== '' && newColumn !== null){
          global.ColumnAlwaysNeedsToBeDisplay.push(newColumn);
        }
      }

    }else{
      //replacing old value
      if(typeof(global.ColumnAlwaysNeedsToBeDisplay) !== 'undefined'){
        let columnFound = false;
        global.ColumnAlwaysNeedsToBeDisplay.forEach((column , index) => {
          if(column === oldColumn){
            global.ColumnAlwaysNeedsToBeDisplay[index] = newColumn === null ? '' : newColumn;
            columnFound = true;
          }
        });
        if(!columnFound && newColumn !== '' && newColumn !== null){
          global.ColumnAlwaysNeedsToBeDisplay.push(newColumn);
        }
      }

    }
  }
  updateTableRowAccoringToDisplayColumn(tableIndex, columnNameArrayTosetTrue, columnNameArrayToSetFalse){
    if(this.state.tableList.length > tableIndex){
      let tableColumnMappedList = tableIndex === 0 ? this.getGlobalValue(26) : this.getGlobalValue(27);

      if (typeof(tableColumnMappedList) !== "undefined" && tableColumnMappedList !== null && tableColumnMappedList !== "") {
        tableColumnMappedList = JSON.parse(tableColumnMappedList)
      } else {
        tableColumnMappedList = [];
      }
      if(typeof(columnNameArrayTosetTrue) !== 'undefined' && columnNameArrayTosetTrue.length > 0) {
        columnNameArrayTosetTrue.forEach((columnName) => {
          if(typeof(this.state.tableList[tableIndex]) !== 'undefined' && typeof(this.state.tableList[tableIndex].rows) !== 'undefined'){
            this.state.tableList[tableIndex].rows.forEach((item,  index) => {
              if((typeof(item['column']) !== 'undefined' && item['column'] === columnName && typeof(item['selected']) !== 'undefined') || tableColumnMappedList.indexOf(item['column']) >-1){
                this.state.tableList[tableIndex].rows[index]['selected'] = true;
              }
            });
          }
        });
      }
      if(typeof(columnNameArrayToSetFalse) !== 'undefined' && columnNameArrayToSetFalse.length > 0) {
        columnNameArrayToSetFalse.forEach((columnName) => {
          if(typeof(this.state.tableList[tableIndex]) !== 'undefined' && typeof(this.state.tableList[tableIndex].rows) !== 'undefined'){
            this.state.tableList[tableIndex].rows.forEach((item,  index) => {
              if(typeof(item['column']) !== 'undefined' && item['column'] === columnName && typeof(item['selected']) !== 'undefined' && tableColumnMappedList.indexOf(item['column']) === -1){
                this.state.tableList[tableIndex].rows[index]['selected'] = false;
              }
            });
          }
        });
      }

      if (tableIndex === 0) {
        this.saveGlobalValue(3, JSON.stringify(this.state.tableList[tableIndex].rows))
      }
    }
  }

  fetchDataViewer = (headers, params) => restClient(GET_ONE, 'dataviewer', params, headers);

  componentDidMount(){
    let that = this;
    this.props.enableExecuteButton(false);
    var processFlowAction = this.state.processFlowAction;
    // $(".loader").show();
    if (typeof(global.updateBlock) === 'undefined' || global.updateBlock === false) {

      this.recursiveSelectedInputTables(0, [], []);
    } else {
      let selectedTable =  [];
      global.processBlockControlValues.forEach((control, index) => {
        if (control.controlId === -97) {
          selectedTable = control.controlValue.split(',');
          return true;
        }
      });
      let gridPrepare = false;
      if(selectedTable.length > 0){
        global.processBlockControlValues.forEach((control, index) => {
          if (control.controlId === 3 && control.controlValue !== "") {
            let initialProcessOptionGrid = JSON.parse(control.controlValue);
            global.inputGrid = initialProcessOptionGrid;
            this.state.tableList =[this.prePareProcessOptionGrid(initialProcessOptionGrid, selectedTable[0])];
            gridPrepare = true;
            return true;
          }
        });
        if(!gridPrepare){
          this.prePareProcessOptionGrid([], selectedTable[0])
        }
      }
      if(selectedTable.length > 1){
        global.processBlockControlValues.forEach((control, index) => {
          if (control.controlId === 24 && control.controlValue !=='') {
            let initialProcessOptionGrid = JSON.parse(control.controlValue);
            global.inputGrid = initialProcessOptionGrid;
            this.state.tableList.push(this.prePareProcessOptionGrid(initialProcessOptionGrid, selectedTable[1]));
            return true;
          }
        });
      } else{
        //using first table as right table if controlId =24 found for variable mapping
        if(processFlowAction === DataOpsIdentifierConstants.VARMAPPING_OP){
          global.processBlockControlValues.forEach((control, index) => {
            if (control.controlId === 24 && control.controlValue !== "") {
              let initialProcessOptionGrid = JSON.parse(control.controlValue);
              global.inputGrid = initialProcessOptionGrid;
              this.state.tableList.push(this.prePareProcessOptionGrid(initialProcessOptionGrid, selectedTable[0]));
              return true;
            }
          });

        }
      }
      this.setState({ tableList : this.state.tableList },()=>{
        that.props.enableExecuteButton(true);
      });
    }

    switch(processFlowAction){
      case DataOpsIdentifierConstants.REFERENCEPOINT_COMPOSITE_OP:
      case DataOpsIdentifierConstants.REFERENCEPOINT_REPLACE_OP:
      case DataOpsIdentifierConstants.REFERENCEPOINT_COMBINE_OP:
          //making call to fetch all reference points
          let fecthPreference = (headers, params) => restClient(GET_ALL, 'reference/name', params, headers);
          let tableName = this.state.processFlowTable;
          if(global.updateBlock){
            global.processBlockControlValues.forEach((control, index) => {
            if (control.controlId === -97) {
              tableName = global.processBlockControlValues[index].controlValue;
              return true;
            }});
          }
            let refsDataComposite = [];
            let refNamesCollectionComposite = [];
            let that = this;
            axios.all([fecthPreference({'Tenant-ID': this.state.tenantID},{'tableName':tableName})])
            .then(axios.spread((response) => {
             if(typeof(response.data) !== 'undefined' && response.data !== "") {
              if(typeof(response.data.messageType) !== 'undefined' && response.data.messageType !== "" &&
              response.data.messageType !== MessageTypeConst.SUCCESS_MESSAGE){
                that.props.setMessage(response.data.message, response.data.messageType);
              }else{
                //refsData = response.data;
                response.data.forEach((item)=>{
                refsDataComposite.push(item);
                if(refNamesCollectionComposite.indexOf(item.RF_NM) === -1){
                  refNamesCollectionComposite.push(item.RF_NM);
                }
              });
                that.props.setReferencePoints(refsDataComposite,refNamesCollectionComposite);
                that.setState({referencePoints:refsDataComposite,referencePointsName:refNamesCollectionComposite});

              }
             }
            })).catch(error => {
              that.props.setMessage("Error occurred while fetching Reference Points.", MessageTypeConst.ERROR_MESSAGE);
              that.props.setReferencePoints([],[]);
            });
      break;
      default:
      break;
    }
  }

  recursiveSelectedInputTables(index, tableList, inputTableStructure) {
    if (typeof(this.state.inputTable[index]) !== 'undefined') {
      var table = this.state.inputTable[index];
      //var processFlowAction = localStorage.getItem("process-flow-action");
      var processFlowAction = this.state.processFlowAction;
      $(".loader").show();
      axios.all([this.fetchDataViewer({'Tenant-ID': this.state.tenantID}, {id: table+'_extended'})])
            .then(axios.spread((dataViewer) => {
                if(typeof(dataViewer.data) !== 'undefined' && typeof(dataViewer.data.records) !== 'undefined') {
                  var initialProcessOptionGrid = this.initialProcessOption(dataViewer.data.records);
                  var inputColString = this.prepareSturctureJson(dataViewer.data.records);
                  var structure = {
                    "tableName": table,
                    "tableColumns":inputColString
                  }
                  global.inputGrid = initialProcessOptionGrid;
                  inputTableStructure = [...inputTableStructure, structure];
                  tableList = [...tableList, this.prePareProcessOptionGrid(initialProcessOptionGrid, table)];
                  if (processFlowAction === DataOpsIdentifierConstants.VARMAPPING_OP && this.state.inputTable.length === 1) {
                    tableList = [cloneDeep(tableList[0]), cloneDeep(tableList[0])];
                  }

                  if(index === 0){
                    global.processBlockControlValues.forEach((control, index) => {
                      if (control.controlId === 3 && control.controlValue === "") {
                        global.processBlockControlValues[index].controlValue = JSON.stringify(tableList[0].rows);
                      }
                    });

                  }
                  if(index === 1){
                    global.processBlockControlValues.forEach((control, index) => {
                      if (control.controlId === 24 && control.controlValue === "") {
                        global.processBlockControlValues[index].controlValue = JSON.stringify(tableList[1].rows);
                      }
                    });

                  }
                  index++;

                  if (typeof(this.state.inputTable[index]) !== 'undefined') {
                    tableList = this.recursiveSelectedInputTables(index, tableList, inputTableStructure)
                  } else {
                    global.inputTableStructure = inputTableStructure;
                    this.setState({tableList : tableList , inputTableStructure:inputTableStructure}, () =>{
                      this.validateModule();
                    });

                  }
                  $(".loader").hide();
                  return tableList;
                }
              })
        ).catch(error => {
          this.props.setMessage("Error occurred while fetching inputs.", MessageTypeConst.ERROR_MESSAGE);
        });
    }
  }

  prepareSturctureJson(data){
    let inputObject = [];
    data.forEach((row, index) => {
      inputObject.push({
        "colName" : typeof(row.column_header) !== 'undefined' ? row.column_header : "",
        "colType" : typeof(row.column_data_type) !== 'undefined' ? row.column_data_type : "",
        "colGNType" : typeof(row.column_gndata_type) !== 'undefined' ? row.column_gndata_type : "",
        "colLength" : typeof(row.column_data_length) !== 'undefined' ? row.column_data_length : "",
        "colLabel" : typeof(row.column_label) !== 'undefined' ? row.column_label : "",
      });
    })
    return inputObject;
  }

  initialProcessOption(data) {
    var initialProcessOptionGrid = [];
    data.forEach((row, index) => {
      initialProcessOptionGrid.push({
        "columnId" : index,
        "selected" : true,
        "column" : typeof(row.column_header) !== 'undefined' ? row.column_header : "",
        "label" : typeof(row.column_label) !== 'undefined' ? row.column_label : "",
        "type" : typeof(row.column_data_type) !== 'undefined' ? row.column_data_type : "",
        "length" : typeof(row.column_data_length) !== 'undefined' ? parseInt(row.column_data_length, 10) : "",
        "columnFormat" : typeof(row.column_format) !== 'undefined' ? row.column_format : "",
      });
    });
    initialProcessOptionGrid = initialProcessOptionGrid.sort((a,b)=> {return a.column.localeCompare(b.column)});
    return initialProcessOptionGrid;
  }

  prePareProcessOptionGrid(data, table) {
    //var processFlowAction = localStorage.getItem("process-flow-action");
    var processFlowAction = this.state.processFlowAction;
    var columnProperty = [];
    var resize = true;
    var filterable = true;
    var dragAndDrop = false;
    var selectedField = 'selected';
    var height = 500;
    var controlId = 3;
    var appendProcessRequire = true;
    var gridClassName = '';
    if (global.updateBlock === true) {
      appendProcessRequire = false;
    }
    var newVariableRule = [];

    switch (processFlowAction) {
      case DataOpsIdentifierConstants.STRINGOPS_ALIGN_OP:
        newVariableRule = ["display"];
        if (appendProcessRequire === true) {
            data = this.appendProcessOptionColumns(data, {"newColumnName" :"", "alignment" : ""});
        }

        columnProperty = [
          { field: "column", title: "Variable", filterable: false, width:"150px", cell: NewColumnSpanCell, orderIndex:2},
          { field: "label", orderIndex:3, sortable:false, width:"260px",filterable: false, orderIndex:3},
          { field: "type", filterable: false, width:"50px", sortable:false, className: "text-center", orderIndex:4},
          { field: "length", filterable: false, width:"60px", sortable:false, className: "text-center", orderIndex:5},
          { field: "alignment", filterable: false, width: "130px", sortable:false, cell: AlignmentForCharCell, orderIndex:7 },
          { field: "newColumnName", title: "New Variable Name", filterable: false, sortable:false, cell: NewColumnNameForCharCell, orderIndex:6 },
          { field: "gby", show:false},
          { field: "oby", show:false},
          { field: "newSASLabel", show:false}
        ];
        gridClassName = "String_Ops";
      break;
      case DataOpsIdentifierConstants.STRINGOPS_INDUCESPACING_OP:
        newVariableRule = ["display"];
        if (appendProcessRequire === true) {
          data = this.appendProcessOptionColumns(data, {"newColumnName" :"", "lengthNew" : "", "spacing" : ""});
        }
        columnProperty = [
          { field: "column", title: "Variable", filterable: false, width:"150px", cell: NewColumnSpanCell, orderIndex:2},
          { field: "label", orderIndex:3, sortable:false, width:"260px",filterable: false},
          { field: "type", filterable: false, width:"50px", sortable:false, className: "text-center", orderIndex:4},
          { field: "length", filterable: false, width:"60px", sortable:false, className: "text-center", orderIndex:5},
          { field: "newColumnName", title: "New Variable Name", filterable: false, sortable:false, cell: NewColumnNameForCharCell, orderIndex:6 },
          { field: "lengthNew", title:"length", filterable: false, sortable:false, cell: NewColumnNameForCharCell, orderIndex:7 },
          { field: "spacing", title:"Space Position", width: "130px", sortable:false, filterable: false, cell: SpacingCell, orderIndex:8 },,
          { field: "gby", show:false},
          { field: "oby", show:false},
          { field: "newSASLabel", show:false}
        ];
        gridClassName = "String_Ops";
        break;
      case DataOpsIdentifierConstants.STRINGOPS_TRIMCOMPRESS_OP:
        newVariableRule = ["display"];
        if (appendProcessRequire === true) {
          data = this.appendProcessOptionColumns(data, {"newColumnName" :"", "leftTrim" : false, "rightTrim" : false, "compress" : false});
        }
        columnProperty = [
          { field: "column", title: "Variable", filterable: false, width:"150px", cell: NewColumnSpanCell, orderIndex:2},
          { field: "label", orderIndex:3, sortable:false, width:"260px",filterable: false},
          { field: "type", filterable: false, width:"50px", sortable:false, className: "text-center", orderIndex:4},
          { field: "length", filterable: false, width:"60px", sortable:false, className: "text-center", orderIndex:5},
          { field: "newColumnName", title: "New Variable Name", filterable: false, sortable:false, cell: NewColumnNameForCharCell, orderIndex:6 },
          { field: "leftTrim", width: "66px", filterable: false, sortable:false, cell: BasicCheckBoxForCharCell, orderIndex:7 },
          { field: "rightTrim", width: "75px", filterable: false, sortable:false, cell: BasicCheckBoxForCharCell, orderIndex:8 },
          { field: "compress", width: "75px", filterable: false, sortable:false, cell: BasicCheckBoxForCharCell, orderIndex:9 },,
          { field: "gby", show:false},
          { field: "oby", show:false},
          { field: "newSASLabel", show:false}
        ];
        gridClassName = "String_Ops";
        break;
      case DataOpsIdentifierConstants.STRINGOPS_CHANGECASE_OP:
        newVariableRule = ["display"];
        if (appendProcessRequire === true) {
          data = this.appendProcessOptionColumns(data, {"newColumnName" :"", "charCase" : ""});
        }
        columnProperty = [
          { field: "column", title: "Variable", filterable: false, width:"150px", cell: NewColumnSpanCell, orderIndex:2},
          { field: "label", orderIndex:3, sortable:false, width:"260px",filterable: false},
          { field: "type", filterable: false, width:"50px", sortable:false, className: "text-center", orderIndex:4},
          { field: "length", filterable: false, width:"60px", sortable:false, className: "text-center", orderIndex:5},
          { field: "newColumnName", title: "New Variable Name", filterable: false, sortable:false, cell: NewColumnNameForCharCell, orderIndex:6  },
          { field: "charCase", filterable: false, sortable:false, width: "150px", cell: CaseCell, orderIndex:7 },
          { field: "gby", show:false},
          { field: "oby", show:false},
          { field: "newSASLabel", show:false}
        ];
        gridClassName = "String_Ops";
      break;
      case DataOpsIdentifierConstants.STRINGOPS_SEARCHNREPLACE_OP:
        newVariableRule = ["display"];
        if (appendProcessRequire === true) {
          data = this.appendProcessOptionColumns(data, {"newColumnName" :"", "seachPolicy" : "", "condition" : "", "searchCriteria" : ""});
        }
        columnProperty = [
          { field: "column", title: "Variable", filterable: false, width:"150px", cell: NewColumnSpanCell, orderIndex:2},
          { field: "label", orderIndex:3, sortable:false, width:"260px",filterable: false},
          { field: "type", filterable: false, width:"50px", sortable:false, className: "text-center", orderIndex:4},
          { field: "length", filterable: false, width:"60px", sortable:false, className: "text-center", orderIndex:5},
          { field: "newColumnName", title: "New Variable Name", filterable: false, sortable:false, cell: NewColumnNameForCharCell, orderIndex:6 },
          { field: "seachPolicy", filterable: false, sortable:false, width:"120px", cell: SeachPolicyCell, orderIndex:7 },
          { field: "condition", filterable: false, sortable:false, width:"160px", cell: SeachConditionCell, orderIndex:8 },
          { field: "searchCriteria", filterable: false, sortable:false, width:"140px", cell: SearchCriteriaCell, orderIndex:9 },,
          { field: "gby", show:false},
          { field: "oby", show:false},
          { field: "newSASLabel", show:false}
        ];
        gridClassName = "String_Ops";
      break;
      case DataOpsIdentifierConstants.STRINGOPS_OCCURENCE_OP:
        newVariableRule = ["display"];
        if (appendProcessRequire === true) {
          data = this.appendProcessOptionColumns(data, {"newColumnName" :"", "keyString" : "", "caseSensitive" : false});
        }
        columnProperty = [
          { field: "column", title: "Variable", filterable: false, width:"150px", cell: NewColumnSpanCell, orderIndex:2},
          { field: "label", orderIndex:3, sortable:false, width:"260px",filterable: false},
          { field: "type", filterable: false, width:"50px", sortable:false, className: "text-center", orderIndex:4},
          { field: "length", filterable: false, width:"60px", sortable:false, className: "text-center", orderIndex:5},
          { field: "newColumnName", title: "New Variable Name", filterable: false, sortable:false, cell: NewColumnNameForCharCell, orderIndex:6 },
          { field: "keyString", filterable: false, sortable:false, cell: NewColumnNameForCharCell, orderIndex:7 },
          { field: "caseSensitive", filterable: false, sortable:false, width: "120px", cell: CaseSensitiveCell, orderIndex:8 },,
          { field: "gby", show:false},
          { field: "oby", show:false},
          { field: "newSASLabel", show:false}
        ];
        gridClassName = "String_Ops";
      break;
      case DataOpsIdentifierConstants.STRINGOPS_SPLIT_OP:
        newVariableRule = ["display"];
        if (appendProcessRequire === true) {
          data = this.appendProcessOptionColumns(data, {"splitOperation" :"", "splitParam" : ""});
        }
//"rangeScale" : ""
        columnProperty = [
          { field: "column", title: "Variable", filterable: false, width:"150px", cell: NewColumnSpanCell, orderIndex:2},
          { field: "label", orderIndex:3, sortable:false, width:"260px",filterable: false},
          { field: "type", filterable: false, width:"50px", sortable:false, className: "text-center", orderIndex:4},
          { field: "length", filterable: false, width:"60px", sortable:false, className: "text-center", orderIndex:5},
          { field: "splitOperation", width: "150px", sortable:false, filterable: false, cell: SplitOperationCell, orderIndex:6 },
          { field: "splitParam", filterable: false, sortable:false, cell: NewColumnNameForCharCell, orderIndex:7 },,
          { field: "gby", show:false},
          { field: "oby", show:false},
          { field: "newSASLabel", show:false}
          //{ field: "rangeScale", filterable: false, cell: RangeScaleCell },
        ];
        gridClassName = "String_Ops";
      break;
      case DataOpsIdentifierConstants.TABLEOPS_RENAME_OP:
        newVariableRule = ["display"];
        if (appendProcessRequire === true) {
          data = this.appendProcessOptionColumns(data, {"newColumnName" :"", "newSASLabel" : ""});
        }
        columnProperty = [
          { field: "column", title: "Variable", filterable: false, width:"150px", cell: NewColumnSpanCell, orderIndex:2},
          { field: "label", orderIndex:3, sortable:false, width:"260px",filterable: false},
          { field: "type", filterable: false, width:"50px", sortable:false, className: "text-center", orderIndex:4},
          { field: "length", filterable: false, width:"60px", sortable:false, className: "text-center", orderIndex:5},
          { field: "newColumnName", title: "New Variable Name", filterable: false, sortable:false, cell: NewColumnNameCell, orderIndex:6 },
          { field: "newSASLabel", filterable: false, sortable:false, cell: NewColumnNameCell, orderIndex:7 },,
          { field: "gby", show:false},
          { field: "oby", show:false}
        ];
        gridClassName = "String_Ops";
      break;
      case DataOpsIdentifierConstants.TABLEOPS_LENGTH_ADJ_OP:
        newVariableRule = ["display"];
        columnProperty = [
          { field: "column", title: "Variable", filterable: false, width:"150px", cell: NewColumnSpanCell, orderIndex:2},
          { field: "label", orderIndex:3, sortable:false, width:"260px",filterable: false},
          { field: "type", filterable: false, width:"50px", sortable:false, className: "text-center", orderIndex:4},
          { field: "length", filterable: false, width:"60px", sortable:false, className: "text-center", orderIndex:5},
          { field: "gby", show:false},
          { field: "oby", show:false}
        ];
        gridClassName = "String_Ops";
      break;
      case DataOpsIdentifierConstants.TYPECONVERSION_DATETIME_OP:
        newVariableRule = ["display"];
        if (appendProcessRequire === true) {
          data = this.appendProcessOptionColumns(data, {"newColumnName": "", "alignType": "", "inputDateFormat": "", "outputDateFormat": "", "dateType" : "", "outputType" : ""});
        }
        columnProperty = [
          { field: "column", title: "Variable", filterable: false, width:"150px", cell: NewColumnSpanCell, orderIndex:2},
          { field: "label", orderIndex:3, sortable:false, width:"260px",filterable: false},
          { field: "type", filterable: false, width:"50px", sortable:false, className: "text-center", orderIndex:4},
          { field: "length", filterable: false, width:"60px", sortable:false, className: "text-center", orderIndex:5},
          { field: "newColumnName", title: "New Variable Name", filterable: false, sortable:false,  width: "150px", cell: NewColumnNameForCharCell, orderIndex:6 },
          { field: "alignType", filterable: false, sortable:false, width: "100px", cell: AlignmentForCharCell, orderIndex:7 },
          { field: "inputDateFormat", filterable: false, sortable:false, width: "150px", cell: NewColumnNameForCharCell, orderIndex:8 },
          { field: "outputDateFormat", filterable: false, sortable:false, width: "150px", cell: NewColumnNameForCharCell, orderIndex:9 },
          { field: "dateType", filterable: false, sortable:false,  width: "130px", cell: DateTypeCell, orderIndex:10 },
          { field: "outputType", filterable: false, sortable:false,  width: "100px", cell: OutputTypeCell, orderIndex:11 },
          { field: "gby", show:false},
          { field: "oby", show:false},
          { field: "newSASLabel", show:false}
        ];
        gridClassName = "String_Ops";
      break;
      case DataOpsIdentifierConstants.TYPECONVERSION_CHARNUM_OP:
        newVariableRule = ["display"];
        if (appendProcessRequire === true) {
          data = this.appendProcessOptionColumns(data, {"newColumnName": "", "conversionType": "", "decimalFormat": ""});
        }
        columnProperty = [
          { field: "column", title: "Variable", filterable: false, width:"150px", cell: NewColumnSpanCell, orderIndex:2},
          { field: "label", orderIndex:3, sortable:false, width:"260px",filterable: false},
          { field: "type", filterable: false, width:"50px", sortable:false, className: "text-center", orderIndex:4},
          { field: "length", filterable: false, width:"60px", sortable:false, className: "text-center", orderIndex:5},
          { field: "newColumnName", title: "New Variable Name", filterable: false, sortable:false, cell: NewColumnNameCell, orderIndex:6 },
          { field: "conversionType", filterable: false, sortable:false, width: "130px", cell: ConversionTypeCell, orderIndex:7 },
          { field: "decimalFormat", filterable: false, sortable:false, width: "150px", cell: DecimalFormatCell, orderIndex:8 },
          { field: "gby", show:false},
          { field: "oby", show:false},
          { field: "newSASLabel", show:false}
        ];
        gridClassName = "String_Ops";
      break;
      case DataOpsIdentifierConstants.CONTINUOUSDATE_OP:
      newVariableRule = ["display"];
      if (appendProcessRequire === true) {
        data = this.appendProcessOptionColumns(data, {"gby": false}, 'selected');
      }
      columnProperty = [
          { field: "column", title: "Variable", filterable: false, width:"150px", cell: NewColumnSpanCell, orderIndex:2 },
          { field: "label", orderIndex:3, sortable:false, width:"260px",filterable: false},
          { field: "type", filterable: false, sortable:false, width:"50px", className: "text-center", orderIndex:4 },
          { field: "length", filterable: false, sortable:false, width:"60px", className: "text-center", orderIndex:5 },
		      { field: "gby", filterable: false, sortable:false, width:"35px", cell: BasicGbyCheckBoxCell, orderIndex:1 },
          { field: "oby", show:false},
          { field: "newSASLabel", show:false}
        ];
        gridClassName = "Continue_Ops";
      break;
      case DataOpsIdentifierConstants.DATAOPS_OP:
        newVariableRule = ["display"];
        if (appendProcessRequire === true) {
          data = this.appendProcessOptionColumns(data, {"gby": false}, 'selected');
          data = this.appendProcessOptionColumns(data, {"operator": ''});
        }
        columnProperty = [
          { field: "column", title: "Variable", filterable: false, width:"150px", cell: NewColumnSpanCell, orderIndex:2},
          { field: "label", orderIndex:3, sortable:false, width:"260px",filterable: false},
          { field: "type", filterable: false, width:"50px", sortable:false, className: "text-center", orderIndex:4},
          { field: "length", filterable: false, width:"60px", sortable:false, className: "text-center", orderIndex:5},
          { field: "gby", filterable: false, width:"35px", sortable:false, cell: BasicGbyCheckBoxCell , orderIndex:1},
          { field: "operator", filterable: false, sortable:false, width:"150px", cell: OperatorCell, orderIndex:6 },
          { field: "oby", show:false},
          { field: "newSASLabel", show:false}
        ];

        this.setState({ template : DataOpsLayoutTemplate});
      break;
      case DataOpsIdentifierConstants.DECOLLAPSE_ONETABLE_OP:
        newVariableRule = ["display"];
        if (appendProcessRequire === true) {
          data = this.appendProcessOptionColumns(data, {"gby": false}, 'selected');
        }
        columnProperty = [
          { field: "column", title: "Variable", filterable: false, width:"150px", cell: NewColumnSpanCell, orderIndex:2},
          { field: "label", orderIndex:3, sortable:false, width:"260px",filterable: false},
          { field: "type", filterable: false, sortable:false, width:"50px", className: "text-center", orderIndex:4 },
          { field: "length", filterable: false, sortable:false, width:"60px", className: "text-center", orderIndex:5 },
          { field: "gby", filterable: false, sortable:false, width:"35px", cell: BasicGbyCheckBoxCell, orderIndex:1 },
          { field: "oby", show:false},
          { field: "newSASLabel", show:false}
        ];

        this.setState({ template : DecollapseOneLayoutTemplate});
        gridClassName = "Decollapse_Ops";
      break;

      case DataOpsIdentifierConstants.SORTOPS_OP:
        newVariableRule = ["display"];
        if (appendProcessRequire === true) {
          data = this.appendProcessOptionColumns(data, {"gby": false, "oby" :""}, 'selected');
        }
        columnProperty = [
          { field: "column", title: "Variable", filterable: false, width:"150px", cell: NewColumnSpanCell, orderIndex:3 },
          { field: "label", orderIndex:4, sortable:false, width:"260px",filterable: false},
          { field: "type", filterable: false, sortable:false, width:"50px", className: "text-center", orderIndex:5},
          { field: "length", filterable: false, sortable:false, width:"60px", className: "text-center", orderIndex:6},
          { field: "gby", filterable: false, sortable:false, width:"35px", cell: SortOpsGbyCheckBoxCell, orderIndex:1 },
          { field: "oby", filterable: false, sortable:false, width:"60px", cell: ObyProcessCell, orderIndex:2 },
          { field: "newSASLabel", show:false}
        ];

        this.setState({ template : SortOpsLayoutTemplate});
        gridClassName = "Decollapse_Ops";
      break;
      case DataOpsIdentifierConstants.RANKOPS_OP:
        newVariableRule = ["display"];
        if (appendProcessRequire === true) {
          data = this.appendProcessOptionColumns(data, {"gby": false, "oby" :""}, 'selected');
          data = this.appendProcessOptionColumns(data, {"newColumnName": ""});
        }
        columnProperty = [
          { field: "column", title: "Variable",filterable: false, width: "150px", cell: NewColumnSpanCell, orderIndex:3 },
          { field: "label", orderIndex:4, sortable:false, width:"260px",filterable: false},
          { field: "type", filterable: false, sortable:false, width:"50px", className: "text-center", orderIndex:5},
          { field: "length", filterable: false, sortable:false, width:"60px", className: "text-center", orderIndex:6},
          { field: "gby", filterable: false, sortable:false, width:"35px", cell: SortOpsGbyCheckBoxCell , orderIndex:1},
          { field: "oby", filterable: false, sortable:false, width:"60px", cell: ObyProcessCell, orderIndex:2 },
          { field: "newColumnName", title: "New Variable Name", filterable: false, sortable:false, cell: RankOpsNewColumnNameCell, orderIndex:7 },
          { field: "newSASLabel", show:false}
        ];

        this.setState({ template : RankOpsLayoutTemplate});
      break;

      case DataOpsIdentifierConstants.SEQUQENCEOPS_OP:
        newVariableRule = ["display"];
        if (appendProcessRequire === true) {
          data = this.appendProcessOptionColumns(data, {"gby": false, "oby" :""}, 'selected');
          data = this.appendProcessOptionColumns(data, {"newColumnName": ""});
        }
        columnProperty = [
          { field: "column", title: "Variable",filterable: false, width: "150px", cell: NewColumnSpanCell, orderIndex:3 },
          { field: "label", orderIndex:4, sortable:false, width:"260px",filterable: false},
          { field: "type", filterable: false, sortable:false, width:"50px", className: "text-center", orderIndex:5},
          { field: "length", filterable: false, sortable:false, width:"60px", className: "text-center", orderIndex:6},
          { field: "gby", filterable: false, sortable:false, width:"35px", cell: SortOpsGbyCheckBoxCell , orderIndex:1},
          { field: "oby", filterable: false, sortable:false, width:"60px", cell: ObyProcessCell, orderIndex:2 },
          { field: "newColumnName", title: "New Variable Name", filterable: false, sortable:false, cell: RankOpsNewColumnNameCell, orderIndex:7 },
          { field: "newSASLabel", show:false}
        ];

        this.setState({ template : RankOpsLayoutTemplate});
      break;
      case DataOpsIdentifierConstants.CRITERIAOPS_OP:
        this.setState({ template : CriteriaOpsLayoutTemplate, inputColumns : this.getInputColumnList(data)});
      break;
      case DataOpsIdentifierConstants.REFERENCEPOINT_ONETABLE_OP:
        this.setState({ template : OneTableTemplate , inputColumns : this.getInputColumnList(data) });
      break;
      case DataOpsIdentifierConstants.REFERENCEPOINT_TWOTABLE_OP:
        this.setState({ template : TwoTableTemplate });
      break;
      case DataOpsIdentifierConstants.REFERENCEPOINT_COMPOSITE_OP:


        this.setState({ template : CompositeTableTemplate });
      break;
      case DataOpsIdentifierConstants.REFERENCEPOINT_REPLACE_OP:      //making call to fetch all reference points


        this.setState({ template : ReplaceTableTemplate});
      break;
      case DataOpsIdentifierConstants.REFERENCEPOINT_COMBINE_OP:
        this.setState({ template : CombineTableTemplate });
      break;
      case DataOpsIdentifierConstants.PIVOT_RTC_OP:
        newVariableRule = ["gby"];
        filterable = false;
        if (appendProcessRequire === true) {
          data = this.appendProcessOptionColumns(data, {"gby": false}, 'selected');
          data.forEach((row, index) => {
            data[index].selected = false;
          });
        }
        columnProperty = [
          { field: "selected", show: false},
          { field: "column", title: "Variable", width:"150px", cell: NewColumnSpanCell, orderIndex:2},
          { field: "label", sortable:false, width:"260px", orderIndex:3},
          { field: "type", sortable:false, width:"50px", className: "text-center", orderIndex:4},
          { field: "length", sortable:false, width:"auto", className: "text-center", orderIndex:5},
          { field: "gby", sortable:false, filterable: false, width:"35px", cell: BasicGbyCheckBoxCell, orderIndex:1 },
          { field: "oby", show:false},
          { field: "newSASLabel", show:false}
        ];
        dragAndDrop = true;
        height = 300;
        this.setState({ template : PivotLayoutTemplate, inputColumns : this.getInputColumnList(data) });
      break;
      case DataOpsIdentifierConstants.PIVOT_CTR_OP:
        newVariableRule = ["gby"];
        filterable = false;
        if (appendProcessRequire === true) {
          data = this.appendProcessOptionColumns(data, {"gby": false}, 'selected');
          data.forEach((row, index) => {
            data[index].selected = false;
          });
        }
        columnProperty = [
          { field: "selected", show: false},
          { field: "column", title: "Variable", width:"150px", cell: NewColumnSpanCell, orderIndex:2},
          { field: "label", sortable:false, width:"260px", orderIndex:3},
          { field: "type", sortable:false, width:"50px", className: "text-center", orderIndex:4},
          { field: "length", sortable:false, width:"auto", className: "text-center", orderIndex:5},
          { field: "gby", sortable:false, filterable: false, width:"35px", cell: BasicGbyCheckBoxCell, orderIndex:1 },
          { field: "oby", show:false},
          { field: "newSASLabel", show:false}
        ];
        dragAndDrop = true;
        height = 300;
        this.setState({ template : PivotLayoutTemplate, inputColumns : this.getInputColumnList(data) });
      break;
      case DataOpsIdentifierConstants.PIVOT_MATRIX:
        newVariableRule = ["gby"];
        filterable = false;
        if (appendProcessRequire === true) {
          data = this.appendProcessOptionColumns(data, {"gby": false}, 'selected');
          data.forEach((row, index) => {
            data[index].selected = false;
          });
        }
        columnProperty = [
          { field: "selected", show: false},
          { field: "column", title: "Variable", width:"150px", cell: NewColumnSpanCell, orderIndex:2},
          { field: "label", sortable:false, width:"260px", orderIndex:3},
          { field: "type", sortable:false, width:"50px", className: "text-center", orderIndex:4},
          { field: "length", sortable:false, width:"60px", className: "text-center", orderIndex:5},
          { field: "gby", sortable:false, filterable: false, width:"35px", cell: BasicGbyCheckBoxCell, orderIndex:1 },
          { field: "oby", show:false},
          { field: "newSASLabel", show:false}
        ];
        dragAndDrop = true;
        height = 300;
        this.setState({ template : PivotMatrixLayoutTemplate, inputColumns : this.getInputColumnList(data) });
      break;
      case DataOpsIdentifierConstants.VARMAPPING_OP:
        newVariableRule = ["display"];
        if (appendProcessRequire === true) {
          data.forEach((row, index) => {
            data[index].selected = false;
          });
        }
        columnProperty = [
          { field: "gby", show:false},
          { field: "oby", show:false},
          { field: "newSASLabel", show:false}
        ];
        this.setState({ template : VariableMappingLayoutTemplate });
        gridClassName ="String_Ops"
        break;
      case DataOpsIdentifierConstants.SPLIT_OP:
        newVariableRule = ["display"];
        this.action_title = DataOpsIdentifierConstants.SPLIT_OP;
        columnProperty = [
          { field: "column", title: "Variable", filterable: false, width:"150px", cell: NewColumnSpanCell, orderIndex:1},
          { field: "label", sortable:false, width:"260px", orderIndex:2},
          { field: "type", filterable: false, sortable:false, width:"50px", className: "text-center", orderIndex:3},
          { field: "length", filterable: false, sortable:false, width:"60px", className: "text-center", orderIndex:4},
          { field: "gby", show:false},
          { field: "oby", show:false},
          { field: "newSASLabel", show:false},
          { field: "newColumnName", show:false}
        ];
        gridClassName="split_Table"
        break;
        case DataOpsIdentifierConstants.APPEND_OP:
        newVariableRule = ["display"];
        if (appendProcessRequire === true) {
          data.forEach((row, index) => {
            data[index].selected = false;
          });
        }
        columnProperty = [
          { field: "gby", show:false},
          { field: "oby", show:false},
          { field: "newSASLabel", show:false}
        ];
        this.setState({ template : AppendLayoutTemplate });
        gridClassName ="String_Ops"
        break;
      case DataOpsIdentifierConstants.APPEND_QUICK_OP:
        newVariableRule = ["display"];
        global.columnMapperNodeList = undefined;
        if (appendProcessRequire === true) {
          data.forEach((row, index) => {
            data[index].selected = false;
          });
        }
        columnProperty = [
          { field: "gby", show:false},
          { field: "oby", show:false},
          { field: "newSASLabel", show:false}
        ];
        this.setState({ template : AppendLayoutTemplate });
        gridClassName ="String_Ops"
        break;
      case DataOpsIdentifierConstants.JOIN_OP:
        newVariableRule = ["display"];
        if (appendProcessRequire === true) {
          data.forEach((row, index) => {
            data[index].selected = false;
          });
        }
        columnProperty = [
          { field: "gby", show:false},
          { field: "oby", show:false},
          { field: "newSASLabel", show:false}
        ];
        this.setState({ template : JoinLayoutTemplate, operation:'Full Outer Join (IF A or B)' });
        break;
      case DataOpsIdentifierConstants.JOIN_QUICK_OP:
        newVariableRule = ["display"];
        global.columnMapperNodeList = undefined;
        if (appendProcessRequire === true) {
          data.forEach((row, index) => {
            data[index].selected = false;
          });
        }
        columnProperty = [
          { field: "gby", show:false},
          { field: "oby", show:false},
          { field: "newSASLabel", show:false}
        ];
        this.setState({ template : JoinLayoutTemplate,operation: this.state.quickcondition });
        break;
      case DataOpsIdentifierConstants.COMPARE_OP:
        if (appendProcessRequire === true) {
          data = this.appendProcessOptionColumns(data, {"gby": false, "oby" :""}, 'selected');
          data.forEach((row, index) => {
            data[index].selected = false;
          });
        }
        columnProperty = [
          { field: "newSASLabel", show:false}
        ];
        this.setState({ template : CompareLayoutTemplate });
        break;
      case DataOpsIdentifierConstants.DECOLLAPSE_TWOTABLE_OP:
        newVariableRule = ["display"];
        if (appendProcessRequire === true) {
          data.forEach((row, index) => {
            data[index].selected = false;
          });
        }
        columnProperty = [
          { field: "selected", show : true},
          { field: "gby", show:false},
          { field: "oby", show:false},
          { field: "newSASLabel", show:false}
        ];
        this.setState({ template : DecollapseTwoLayoutTemplate });
        gridClassName = "String_Ops";
        break;
      case DataOpsIdentifierConstants.LINK_OP:
        newVariableRule = ["display"];
        if (appendProcessRequire === true) {
          data = this.appendProcessOptionColumns(data, {"gby": false, "oby" :""}, 'selected');
          data.forEach((row, index) => {
            data[index].selected = false;
          });
        }
        columnProperty = [
          { field: "newSASLabel", show:false}
        ];
        this.setState({ template : LinkLayoutTemplate });
        break;
      case DataOpsIdentifierConstants.REPORTINGOPS_CUSTOM_OP:
        if (appendProcessRequire === true) {
          data = this.appendProcessOptionColumns(data, {"gby": false}, 'selected');
          data = this.appendProcessOptionColumns(data, {"rowCheckox": false, "columnCheckbox": false});
          data.forEach((row, index) => {
            data[index].selected = false;
          });
        }
        columnProperty = [
          { field: "selected", show: false},
          { field: "column", title: "Variable", filterable: false, width:"150px", cell: NewColumnSpanCell, orderIndex:2},
          { field: "label", filterable: false, sortable:false, width:"260px", orderIndex:3},
          { field: "type", filterable: false, sortable:false, width:"50px", className: "text-center", orderIndex:4},
          { field: "length", filterable: false, sortable:false, width:"60px", className: "text-center", orderIndex:5},
          { field: "gby", filterable: false, sortable:false, width:"35px", cell: ReportingOpsGBYCell, orderIndex:1 },
          { field: "rowCheckox", filterable: false, sortable:false, width:"45px", title:"row", cell: ReportingOpsRowCell, orderIndex:6 },
          { field: "columnCheckbox", filterable: false, sortable:false, width:"70px", title:"column", cell: ReportingOpsCheckboxCell, orderIndex:7 },
          { field: "oby", show:false},
          { field: "newSASLabel", show:false},
          { field: "newColumnName", show:false}
        ];

        this.setState({ template : ReportingOpsLayoutTemplate });
          gridClassName="String_Ops";
      break;
      case DataOpsIdentifierConstants.REPORTINGOPS_DESCRIPTIVE_OP:
        if (appendProcessRequire === true) {
            data = this.appendProcessOptionColumns(data, {"gby": false}, 'selected');
            data = this.appendProcessOptionColumns(data, {"rowCheckox": false, "columnCheckbox": false});
            data.forEach((row, index) => {
              data[index].selected = false;
            });
            //default analysis grid data
            let defaultRowData = [
              {"rowId": 0,"columnId": 0, "selected": false, "order":0, "label" : "n", "pictureFormat" : "999", "expression" : "SMALLN"},
              {"rowId": 1,"columnId": 1, "selected": false, "order":1, "label" : "Mean (SD)", "pictureFormat" : "999.99 (999.99)", "expression" : "MEAN (STD)"},
              {"rowId": 2,"columnId": 2, "selected": false, "order":2, "label" : "Median", "pictureFormat" : "999", "expression" : "MEDIAN"},
              {"rowId": 3,"columnId": 3, "selected": false, "order":3, "label" : "Q1, Q3", "pictureFormat" : "999, 999", "expression" : "Q1, Q3"},
              {"rowId": 4,"columnId": 4, "selected": false, "order":4, "label" : "Min, Max", "pictureFormat" : "999.99, 999.99", "expression" : "MIN, MAX"}
            ];
            global.processBlockControlValues.forEach((control, index) => {
              if (control.controlId === 87) {
                global.processBlockControlValues[index].controlValue = JSON.stringify(defaultRowData);
                return true;
              }
            });
        }
        columnProperty = [
          { field: "selected", show: false},
          { field: "column", title: "Variable", filterable: false, width:"150px", cell: NewColumnSpanCell, orderIndex:2},
          { field: "label", filterable: false, sortable:false, width:"260px", orderIndex:3},
          { field: "type", filterable: false, sortable:false, width:"50px", className: "text-center", orderIndex:4},
          { field: "length", filterable: false, sortable:false, width:"60px", className: "text-center", orderIndex:5},
          { field: "gby", title: "BY", filterable: false, sortable:false, width:"35px", cell: ReportingOpsGBYCell, orderIndex:1 },
          { field: "rowCheckox", filterable: false, sortable:false, width:"45px", title:"row", cell: ReportingOpsRowCell, orderIndex:6 },
          { field: "columnCheckbox", filterable: false, sortable:false, width:"70px", title:"column", cell: ReportingOpsCheckboxCell, orderIndex:7 },
          { field: "oby", show:false},
          { field: "newSASLabel", show:false},
          { field: "newColumnName", show:false}
        ];

        this.setState({ template : ReportingOpsLayoutTemplate });
              gridClassName="String_Ops";
          break;
      case DataOpsIdentifierConstants.REPORTINGOPS_CATEGORICAL_OP:
        if (appendProcessRequire === true) {
            data = this.appendProcessOptionColumns(data, {"gby": false}, 'selected');
            data = this.appendProcessOptionColumns(data, {"rowCheckox": false, "columnCheckbox": false});
            data.forEach((row, index) => {
              data[index].selected = false;
            });
            //default analysis grid data
            let defaultRowData = [
              {"rowId": 0,"columnId": 0, "selected": false, "order":0, "label" : "n (PCT)", "pictureFormat" : "999 (999.99)", "expression" : "SMALLN (PCT%)"},
            ];
            global.processBlockControlValues.forEach((control, index) => {
              if (control.controlId === 87) {
                global.processBlockControlValues[index].controlValue = JSON.stringify(defaultRowData);
                return true;
              }
            });
        }
        columnProperty = [
          { field: "selected", show: false},
          { field: "column", title: "Variable", filterable: false, width:"150px", cell: NewColumnSpanCell, orderIndex:2},
          { field: "label", filterable: false, sortable:false, width:"260px", orderIndex:3},
          { field: "type", filterable: false, sortable:false, width:"50px", className: "text-center", orderIndex:4},
          { field: "length", filterable: false, sortable:false, width:"60px", className: "text-center", orderIndex:5},
          { field: "gby", title: "BY", filterable: false, sortable:false, width:"35px", cell: ReportingOpsGBYCell, orderIndex:1 },
          { field: "rowCheckox", filterable: false, sortable:false, width:"45px", title:"row", cell: ReportingOpsRowCell, orderIndex:6 },
          { field: "columnCheckbox", filterable: false, sortable:false, width:"70px", title:"column", cell: ReportingOpsCheckboxCell, orderIndex:7 },
          { field: "oby", show:false},
          { field: "newSASLabel", show:false},
          { field: "newColumnName", show:false}
        ];
        this.setState({ template : ReportingOpsLayoutTemplate });
              gridClassName="String_Ops";
          break;
      case DataOpsIdentifierConstants.REPORTINGOPS_CATEGORICAL_TOTAL_OP:
        if (appendProcessRequire === true) {
            data = this.appendProcessOptionColumns(data, {"gby": false}, 'selected');
            data = this.appendProcessOptionColumns(data, {"rowCheckox": false, "columnCheckbox": false});
            data.forEach((row, index) => {
              data[index].selected = false;
            });
            //default analysis grid data
            let defaultRowData = [
              {"rowId": 0,"columnId": 0, "selected": false, "order":0, "label" : "n (PCT)", "pictureFormat" : "999 (999.99)", "expression" : "SMALLN (PCT%)"},
            ];
            global.processBlockControlValues.forEach((control, index) => {
              if (control.controlId === 87) {
                global.processBlockControlValues[index].controlValue = JSON.stringify(defaultRowData);
                return true;
              }
            });
        }
        columnProperty = [
          { field: "selected", show: false},
          { field: "column", title: "Variable", filterable: false, width:"150px", cell: NewColumnSpanCell, orderIndex:2},
          { field: "label", filterable: false, sortable:false, width:"260px", orderIndex:3},
          { field: "type", filterable: false, sortable:false, width:"50px", className: "text-center", orderIndex:4},
          { field: "length", filterable: false, sortable:false, width:"60px", className: "text-center", orderIndex:5},
          { field: "gby", title: "BY", filterable: false, sortable:false, width:"35px", cell: ReportingOpsGBYCell, orderIndex:1 },
          { field: "rowCheckox", filterable: false, sortable:false, width:"45px", title:"row", cell: ReportingOpsRowCell, orderIndex:6 },
          { field: "columnCheckbox", filterable: false, sortable:false, width:"70px", title:"column", cell: ReportingOpsCheckboxCell, orderIndex:7 },
          { field: "oby", show:false},
          { field: "newSASLabel", show:false},
          { field: "newColumnName", show:false}
        ];
        this.setState({ template : ReportingOpsLayoutTemplate });
              gridClassName="reporting_categorical_total";
          break;
          case DataOpsIdentifierConstants.REPORTINGOPS_CATEGORICAL_TOTAL_UNIQUE_OP:
              if (appendProcessRequire === true) {
                  data = this.appendProcessOptionColumns(data, {"gby": false}, 'selected');
                  data = this.appendProcessOptionColumns(data, {"rowCheckox": false, "columnCheckbox": false});
                  data.forEach((row, index) => {
                    data[index].selected = false;
                  });
                  //default analysis grid data
                  let defaultRowData = [
                    {"rowId": 0,"columnId": 0, "selected": false, "order":0, "label" : "n (PCT)", "pictureFormat" : "999 (999.99)", "expression" : "SMALLN (PCT%)"},
                  ];
                  global.processBlockControlValues.forEach((control, index) => {
                    if (control.controlId === 87) {
                      global.processBlockControlValues[index].controlValue = JSON.stringify(defaultRowData);
                      return true;
                    }
                  });
              }
              columnProperty = [
                { field: "selected", show: false},
                { field: "column", title: "Variable", filterable: false, width:"150px", cell: NewColumnSpanCell, orderIndex:2},
                { field: "label", filterable: false, sortable:false, width:"260px", orderIndex:3},
                { field: "type", filterable: false, sortable:false, width:"50px", className: "text-center", orderIndex:4},
                { field: "length", filterable: false, sortable:false, width:"60px", className: "text-center", orderIndex:5},
                { field: "gby", title: "BY", filterable: false, sortable:false, width:"35px", cell: ReportingOpsGBYCell, orderIndex:1 },
                { field: "rowCheckox", filterable: false, sortable:false, width:"45px", title:"row", cell: ReportingOpsRowCell, orderIndex:6 },
                { field: "columnCheckbox", filterable: false, sortable:false, width:"70px", title:"column", cell: ReportingOpsCheckboxCell, orderIndex:7 },
                { field: "oby", show:false},
                { field: "newSASLabel", show:false},
                { field: "newColumnName", show:false}
              ];
              this.setState({ template : ReportingOpsLayoutTemplate });
              gridClassName="reporting_categorical_total"
              break;

      case DataOpsIdentifierConstants.REPORTINGOPS_CATEGORICAL_HIERARCHICAL_OP:
        if (appendProcessRequire === true) {
            data = this.appendProcessOptionColumns(data, {"gby": false}, 'selected');
            data = this.appendProcessOptionColumns(data, {"rowCheckox": false, "columnCheckbox": false});
            data.forEach((row, index) => {
              data[index].selected = false;
            });
            //default analysis grid data
            let defaultRowData = [
              {"rowId": 0,"columnId": 0, "selected": false, "order":0, "label" : "n (PCT)", "pictureFormat" : "999 (999.99)", "expression" : "SMALLN (PCT%)"},
            ];
            global.processBlockControlValues.forEach((control, index) => {
              if (control.controlId === 87) {
                global.processBlockControlValues[index].controlValue = JSON.stringify(defaultRowData);
                return true;
              }
            });
        }
        columnProperty = [
          { field: "selected", show: false},
          { field: "column", title: "Variable", filterable: false, width:"150px", cell: NewColumnSpanCell, orderIndex:2},
          { field: "label", filterable: false, sortable:false, width:"260px", orderIndex:3},
          { field: "type", filterable: false, sortable:false, width:"50px", className: "text-center", orderIndex:4},
          { field: "length", filterable: false, sortable:false, width:"60px", className: "text-center", orderIndex:5},
          { field: "gby", title: "BY", filterable: false, sortable:false, width:"35px", cell: ReportingOpsGBYCell, orderIndex:1 },
          { field: "rowCheckox", filterable: false, sortable:false, width:"45px", title:"row", cell: ReportingOpsRowCell, orderIndex:6 },
          { field: "columnCheckbox", filterable: false, sortable:false, width:"70px", title:"column", cell: ReportingOpsCheckboxCell, orderIndex:7 },
          { field: "oby", show:false},
          { field: "newSASLabel", show:false},
          { field: "newColumnName", show:false}
        ];
        this.setState({ template : ReportingOpsLayoutTemplate });
              gridClassName="reporting_categorical_total";
          break;
      case DataOpsIdentifierConstants.REPORTINGOPS_CATEGORICAL_HIERARCHICAL_UNIQUE_OP:
          if (appendProcessRequire === true) {
              data = this.appendProcessOptionColumns(data, {"gby": false}, 'selected');
              data = this.appendProcessOptionColumns(data, {"rowCheckox": false, "columnCheckbox": false});
              data.forEach((row, index) => {
                data[index].selected = false;
              });
              //default analysis grid data
              let defaultRowData = [
                {"rowId": 0,"columnId": 0, "selected": false, "order":0, "label" : "n (PCT)", "pictureFormat" : "999 (999.99)", "expression" : "SMALLN (PCT%)"},
              ];
              global.processBlockControlValues.forEach((control, index) => {
                if (control.controlId === 87) {
                  global.processBlockControlValues[index].controlValue = JSON.stringify(defaultRowData);
                  return true;
                }
              });
          }
          columnProperty = [
            { field: "selected", show: false},
            { field: "column", title: "Variable", filterable: false, width:"150px", cell: NewColumnSpanCell, orderIndex:2},
            { field: "label", filterable: false, sortable:false, width:"260px", orderIndex:3},
            { field: "type", filterable: false, sortable:false, width:"50px", className: "text-center", orderIndex:4},
            { field: "length", filterable: false, sortable:false, width:"60px", className: "text-center", orderIndex:5},
            { field: "gby", title: "BY", filterable: false, sortable:false, width:"35px", cell: ReportingOpsGBYCell, orderIndex:1 },
            { field: "rowCheckox", filterable: false, sortable:false, width:"45px", title:"row", cell: ReportingOpsRowCell, orderIndex:6 },
            { field: "columnCheckbox", filterable: false, sortable:false, width:"70px", title:"column", cell: ReportingOpsCheckboxCell, orderIndex:7 },
            { field: "oby", show:false},
            { field: "newSASLabel", show:false},
            { field: "newColumnName", show:false}
          ];
          this.setState({ template : ReportingOpsLayoutTemplate });
          gridClassName="ReportingOps_hierarchical_unique"
          break;
        case DataOpsIdentifierConstants.R_Report_OP:
          let fecthAnalysisCharPreference1 = () => restClient(GET_ALL, 'report/list/r/char', {}, {'Tenant-ID':'master'});
          let responseObject1 ={};
          if(global.updateBlock === false)
          {
            axios.all([fecthAnalysisCharPreference1()])
            .then(axios.spread((response) => {
            if(typeof(response.data) !== 'undefined' && response.data !== "") {
                responseObject1 = response.data;
                this.setState({ preOptions : responseObject1}, ()=>{
                  SaveDefaultParametersToGlobalForR(responseObject1, this.getInputColumnList(data), data, this.props.treeView, this.props.processFlowList[this.props.activeProcessFlow]);
                  this.setState({ inputColumns : this.getInputColumnList(data)});
                } );
            }
            })).catch(error => {
              this.props.setMessage("Error occurred while fetching Analysis Char Preference.", MessageTypeConst.ERROR_MESSAGE);
            });
          }
          this.setState({ template : ProcReportNewLayoutTemplate , inputColumns : this.getInputColumnList(data), preOptions: responseObject1 });
          break;
      case DataOpsIdentifierConstants.PROCREPORT_ANALYSIS_CHAR_OP:
        let fecthAnalysisCharPreference = () => restClient(GET_ALL, 'report/list/char', {}, {'Tenant-ID':'master'});
        let responseObject ={};
        if(global.updateBlock === false)
        {
          axios.all([fecthAnalysisCharPreference()])
          .then(axios.spread((response) => {
          if(typeof(response.data) !== 'undefined' && response.data !== "") {
              responseObject = response.data;
              this.setState({ preOptions : responseObject}, ()=>{
                SaveDefaultParametersToGlobal(responseObject, this.getInputColumnList(data), data, this.props.treeView, this.props.processFlowList[this.props.activeProcessFlow]);
                this.setState({ inputColumns : this.getInputColumnList(data)});
              } );
          }
          })).catch(error => {
            this.props.setMessage("Error occurred while fetching Analysis Char Preference.", MessageTypeConst.ERROR_MESSAGE);
          });
        }
        this.setState({ template : ProcReportNewLayoutTemplate , inputColumns : this.getInputColumnList(data), preOptions: responseObject });
        break;
      case DataOpsIdentifierConstants.PROCREPORT_ANALYSIS_NUM_OP:
          let fecthAnalysisNumPreference = () => restClient(GET_ALL, 'report/list/num', {}, {'Tenant-ID':'master'});
          let responseNumObject ={};
          if(global.updateBlock === false)
          { axios.all([fecthAnalysisNumPreference()])
            .then(axios.spread((response) => {
            if(typeof(response.data) !== 'undefined' && response.data !== "") {
              responseNumObject = response.data;
              this.setState({ preOptions : responseNumObject }, ()=>{
                SaveDefaultParametersToGlobal(responseNumObject, this.getInputColumnList(data), data, this.props.treeView, this.props.processFlowList[this.props.activeProcessFlow]);
                this.setState({ inputColumns : this.getInputColumnList(data)});
              });
            }
            })).catch(error => {
              this.props.setMessage("Error occurred while fetching Analysis Num Preference.", MessageTypeConst.ERROR_MESSAGE);
            });
          }
          this.setState({ template : ProcReportNewLayoutTemplate , inputColumns : this.getInputColumnList(data), preOptions: responseNumObject });
          break;

      case DataOpsIdentifierConstants.CODEEDITOR_TABLE_OP:
        this.setState({ template : CodeEditorLayoutTemplate });
        break;
      case DataOpsIdentifierConstants.CODEEDITOR_FILE_OP:
        this.setState({ template : CodeEditorLayoutTemplate, inputColumns : this.getInputColumnList(data) });
        break;
      case DataOpsIdentifierConstants.GRAPH_OP:
        if(global.updateBlock === false)
        {
          axios.all([restClient(GET_ALL, 'report/list/r/char', {}, {'Tenant-ID':'master'})])
          .then(axios.spread((response) => {
          if(typeof(response.data) !== 'undefined' && response.data !== "") {
              this.setState({ preOptions : response.data}, () => {
                saveDefaultParametersToGlobalForGraph(response.data, this.getInputColumnList(data), this.props.treeView, this.props.processFlowList[this.props.activeProcessFlow]);
              } );
          }
          })).catch(error => {
            this.props.setMessage("Error occurred while fetching Analysis Char Preference.", MessageTypeConst.ERROR_MESSAGE);
          });
        }
        this.setState({ template : GraphLayoutTemplate, inputColumns : this.getInputColumnList(data), preOptions: {} })
        break;
      default:
        break;
    }
      //need to set select=true for column 'APP_KEY' for all operation
      if(typeof(global.ColumnAlwaysNeedsToBeDisplay) !== 'undefined' && global.ColumnAlwaysNeedsToBeDisplay.length > 0)
      global.ColumnAlwaysNeedsToBeDisplay.forEach((columnName) => {
        data.forEach((item,  index) => {
          if(typeof(item['column']) !== 'undefined' && item['column'] === columnName && typeof(item['selected']) !== 'undefined' ){
            data[index]['selected'] = true;
          }
        });
      });

      //save newVariableRule in JSON
      let controlIndex = global.processBlockControlValues.findIndex(control => control.controlId === -93);
      if(controlIndex === -1){
          global.processBlockControlValues.push({
          "controlId": -93,
          "controlType": -93,
          "controlValue": JSON.stringify(newVariableRule)
        });
      }else if(typeof(global.processBlockControlValues[controlIndex].controlValue) === "undefined" ||
      global.processBlockControlValues[controlIndex].controlValue === null || global.processBlockControlValues[controlIndex].controlValue === ""){
        global.processBlockControlValues[controlIndex].controlValue = JSON.stringify(newVariableRule);
      }

    this.setState({ processFlowAction: processFlowAction });

    columnProperty.push({ field: "tableName", show:false});
    columnProperty.push({ field: "tableTitle", show:false});

    data.map((row, rowIndex) => {
      row.id = rowIndex+1;
      return row
    })
    return {
      id: table,
      rows: data,
      resize: resize,
      filterable: filterable,
      selectedField: selectedField,
      columnProperty: columnProperty,
      dragAndDrop: dragAndDrop,
      height: height,
      controlId: controlId,
      gridClassName: gridClassName,
      showSearch: true,
      showColumnVisibilityManager: false
    }
  }

  handleSeachDataChangeEvent(event) {
    //this.setState({ gridData: filterBy(process_option_grid.slice(), this.state.filter)})
    //filterBy(process_option_grid.slice(), this.state.filter)
    //this.setState({table: {id:"process-options", rows: filterBy(process_option_grid.slice(), this.state.filter)}})
  }

  appendProcessOptionColumns(data, newFields, position='') {
    data.forEach((row, index) => {
      if (position !== '') {
        var beforePosition = {};
        var afterPosition = {};
        for (var key in row) {
          if(key === position) {
            beforePosition = {...beforePosition, ...{ [key]:row[key]}}
          } else {
            afterPosition = {...afterPosition, ...{ [key]:row[key]}}
          }
        }
        data[index] = { ...beforePosition, ...newFields, ...afterPosition};
      } else {
        data[index] = { ...row, ...newFields};
      }
    });

    return data;
  }

  handleSelectTabPanel = (e) => {
        this.setState({tabSelected: e.selected})
  }

  handleSelectSubTabPanel = (e) => {
    this.setState({subtabSelected: e.selected})
}

  getInputColumnList = (data) => {
    var columns = [];
    data.forEach((row) => {
      columns = [...columns, row.column];
    });

    return columns.sort();
  }

  checkWhetherDateTimeColumnsPresent = () => {
    let columnFound = true;
    if(this.state.tableList){
      this.state.tableList.forEach((table)=>{
        let dateObjects = table.rows.filter(row=>row.type === 'DATE' || row.type === 'DATETIME');
        if(dateObjects.length === 0 ){
          columnFound = false;
        }
      });
    }
    return columnFound;
  }

  validateModule = () =>{
    let isDateColExists = true;
    this.props.enableExecuteButton(true);
    switch(this.state.processFlowAction.toLowerCase()){
      case DataOpsIdentifierConstants.CONTINUOUSDATE_OP:
      isDateColExists = this.checkWhetherDateTimeColumnsPresent();
      if(!isDateColExists){
        ConfirmBox.alert("Any DATE / DATETIME column(s) not found. Hence Continues Date operation will not work properly.")
      }
      break;
      case DataOpsIdentifierConstants.DECOLLAPSE_ONETABLE_OP:
      isDateColExists = this.checkWhetherDateTimeColumnsPresent();
      if(!isDateColExists){
        ConfirmBox.alert("Any DATE / DATETIME column(s) not found. Hence Decollapse(One Table) operation will not work properly.")
      }
      break;
      case DataOpsIdentifierConstants.DECOLLAPSE_TWOTABLE_OP:
      isDateColExists = this.checkWhetherDateTimeColumnsPresent();
      if(!isDateColExists){
        ConfirmBox.alert("Any DATE / DATETIME column(s) not found. Hence Decollapse(Two Table) operation will not work properly.")
      }
      break;
      case DataOpsIdentifierConstants.LINK_OP:
      isDateColExists = this.checkWhetherDateTimeColumnsPresent();
      if(!isDateColExists){
        ConfirmBox.alert("Any DATE / DATETIME column(s) not found. Hence Link operation will not work properly.")
      }
      break;
      default:
      break;
    }
  }

  getGlobalValue(controlIndex){
    let controlValue = '';
    global.processBlockControlValues.forEach((control, index) => {
      if (control.controlId === controlIndex) {
        controlValue = global.processBlockControlValues[index].controlValue;

      }
    });
    return controlValue;
  }

  saveGlobalValue(controlIndex, value){
    global.processBlockControlValues.forEach((control, index) => {
      if (control.controlId === controlIndex) {
        global.processBlockControlValues[index].controlValue = value;
      }
    });
  }

  render() {
      return this.state.template.call(this);
  }
}

const mapStateToProps = createSelector(
  state => state.processFlow.tenantId,
  state => state.dataOperation.referencePoints,
  state => state.dataOperation.referencePointsName,
  state => state.processFlow.processFlowList,
  state => state.treeView.data,
  (tenantId, referencePoints, referencePointsName, processFlowList, treeView) => ({
    tenantId,
    referencePoints,
    referencePointsName,
    processFlowList,
    treeView
  })
);

const mapActionsToProps = {
  setReferencePoints:setReferencePoints,
  setMessage:setMessage,
  enableExecuteButton: enableExecuteButton
}


export default connect(mapStateToProps, mapActionsToProps)(ProcessOption);
