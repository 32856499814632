import React from 'react';
import { connect } from 'react-redux';
import { PropTypes } from 'prop-types';
import { createSelector } from 'reselect';
import MessageTypeConst from "../../MessageTypeConst";
import axios from 'axios';
import restClient from '../../../restClient';
import { CREATE, GET_ALL } from '../../../restClient/types';
import { setMessage } from '../../../actions/actionNotification';
import ExportTypeConst from "../../ExportTypeConst";
import $ from 'jquery';
import Dialog from 'react-dialog';
import { Button } from './../DataOperation/common/Button/StandardButton';
import { SERVER_URL } from '../../../config/index.js';
import BlockCreationType from '../../BlockCreationType';
import BlockType from '../../BlockType';
import { MultiSelectDropDownList } from './../DataOperation/common';
import ConfirmBox from './../DataOperation/common/ConfirmBox';

class Export extends React.Component {

    constructor(props){
        super(props);
        this.state = {
            tenantId:props.tenantId,
            processFlowTableId: props.processFlowTableId,
            processFlowList:props.processFlowList,
            activeProcessFlow:props.activeProcessFlow,
            closeEvent : this.props.closeEvent,
            params:this.props.params,
            exportTitle : this.getExportTitle(this.props.params.exportType),
            exportCode:false,
            exportLog:false,
            exportList: false,
            exportData:false,
            exportDocument:false,
            exportReport:false,
            exportAsCsv:true,
            exportAsXpt:false,
            exportAsSas7bdat:false,
            exportDataExtension:".csv" ,
            filePath:"",
            fileName:"",
            url:"",
            disabledExportCode : false,
            disabledExportLog : false,
            disabledExportData : false,
            disabledExportDocument : false,
            disabledExportReport : false,
            disabledExportList : false,
            blockClassifierList: [],
            selectedBlockClassifier: [],
            allBlockClassifierSelected: true,
            isBlockClassifierOpen: false
        }
        this.processBlock = this.getProcessBlock();
        this.exportApi = this.exportApi.bind(this);
        this.handleExportClickEvent = this.handleExportClickEvent.bind(this);
        this.handleExportCodeChange = this.handleExportCodeChange.bind(this);
        this.handleExportLogChange = this.handleExportLogChange.bind(this);
        this.handleExportListChange = this.handleExportListChange.bind(this);
        this.handleExportDataChange = this.handleExportDataChange.bind(this);
        this.handleExportDocumentChange = this.handleExportDocumentChange.bind(this);
        this.handleExportReportChange = this.handleExportReportChange.bind(this);
        this.handleDataFormatChange = this.handleDataFormatChange.bind(this);
        this.handleChangeBlockClassifier = this.handleChangeBlockClassifier.bind(this);
    }

    componentDidMount(){
      let fecthClassifier = (headers, params) => restClient(GET_ALL, 'processblock/classifier', params, headers);
        $(".loader").show();
        axios.all([fecthClassifier({'Tenant-ID': this.state.tenantId},{})])
        .then(axios.spread((response) => {
          if(typeof(response.data) !== 'undefined' && response.data !== null && response.data.length > 0) {
            // classifierId:7 = Document. Which we need to ignore as a part of AM-148
            const ignoreClassifierIds = [7];
            const blockClassifierList = response.data.filter(blockClassifier => !ignoreClassifierIds.includes(blockClassifier.classifierId));
            this.setState({
              blockClassifierList: blockClassifierList
            });
            if(this.props.params.exportType === ExportTypeConst.EXPORT_PROCESS_FLOW_GROUP) {
              this.setState({
                selectedBlockClassifier: [...blockClassifierList.filter(blockClassifier => blockClassifier.classifierId === this.props.params.classifierId)],
                exportData: true
              });
            }else if(this.props.params.exportType !== ExportTypeConst.EXPORT_PROCESS_FLOW){
              this.setState({
                selectedBlockClassifier: [...blockClassifierList]
              });
            }
            $(".loader").hide();
        }
        })).catch(error => {
            $(".loader").hide();
            this.props.setMessage("Error occurred while fetching block classifier.", MessageTypeConst.ERROR_MESSAGE);
        });
        if(this.state.params.exportType === ExportTypeConst.EXPORT_PROCESS_BLOCK && this.processBlock !== null){
            let disabledExportCode = true;
            let disabledExportLog = true;
            let disabledExportData = true;
            let disabledExportDocument = true;
            let disabledExportReport = true;
            let disabledExportList = true;
            let exportData = false;
            let exportDocument = false;
            let exportReport = false;
            let exportCode = false;
            let exportLog = false;
            switch(this.processBlock.blockCreationType){
                case BlockCreationType.IMPORT_META_TABLE:
                case BlockCreationType.IMPORT_SOURCE_TABLE:
                case BlockCreationType.DATA_LINK:
                case BlockCreationType.DATA_COPy:
                disabledExportData = false;
                exportData = true;
                break;
                case BlockCreationType.IMPORT_SOURCE_DOCUMENT:
                disabledExportDocument = false;
                exportDocument = true;
                break;
                case BlockCreationType.CREATE_UPDATE_BLOCK:
                if(this.processBlock.blockType === BlockType.FILE){
                    disabledExportCode = false;
                    disabledExportLog = false;
                    disabledExportReport = false;
                    disabledExportList = false;
                    exportCode = true;
                    exportReport = true;
                    exportLog = true;
                }else if(this.processBlock.blockType === BlockType.TABLE){
                    if(this.processBlock.tableExists === 1){
                        disabledExportCode = false;
                        disabledExportLog = false;
                        disabledExportData = false;
                        disabledExportList = false;
                        exportCode = true;
                        exportLog = true;
                        exportData = true;
                    }else{
                        disabledExportCode = false;
                        disabledExportLog = false;
                        disabledExportList = false;
                        exportCode = true;
                        exportLog = true;
                    }
                }
                break;
                default:
                disabledExportCode = false;
                disabledExportList = false;
                disabledExportLog = false;
                disabledExportData = false;
                disabledExportDocument = false;
                disabledExportReport = false;
                break;
            }
            this.setState({disabledExportCode, disabledExportLog, disabledExportData, disabledExportDocument, disabledExportReport,
                exportData, exportDocument, exportReport, exportCode, exportLog, disabledExportList });
        }else if(this.state.params.exportType === ExportTypeConst.EXPORT_STUDY){
            let exportData = true;
            let exportDocument = true;
            let exportReport = true;
            let exportCode = true;
            let exportLog = true;
            let disabledExportCode = false;
            let disabledExportList = false;
            let disabledExportLog = false;
            let disabledExportData = false;
            let disabledExportDocument = false;
            let disabledExportReport = false;
            this.setState({disabledExportCode, disabledExportLog, disabledExportData, disabledExportDocument, disabledExportReport,
                exportData, exportDocument, exportReport, exportCode, exportLog, disabledExportList });
        }
    }

    getExportTitle(exportType){
        let title="";
        switch(exportType){
            case ExportTypeConst.EXPORT_PROCESS_BLOCK:
            title="Export Block";
            break;
            case ExportTypeConst.EXPORT_PROCESS_FLOW:
            title="Export Flow";
            break;
            case ExportTypeConst.EXPORT_PROCESS_FLOW_GROUP:
            title="Export Group";
            break;
            case ExportTypeConst.EXPORT_STUDY:
            title="Export Study";
            break;
            default:
            break;
        }
        return title;
    }

    exportApi(requestObj) {
        var that = this;
        $(".loader").show();
        axios.all([restClient(CREATE, 'export', { data: requestObj }, { 'Tenant-ID': this.state.tenantId })])
            .then(axios.spread(function (response) {
                if(response !== null && response.data !== null && response.data.messageType !== MessageTypeConst.SUCCESS_MESSAGE){
                    ConfirmBox.alert(response.data.message);
                }

                if(response !== null && response.data !== null && response.data.fileNames !== null &&
                    typeof(response.data.fileNames) !== "undefined" && response.data.fileNames.length > 0){
                        let file = response.data.fileNames[0];
                        //let path = response.data.executeFolderPath + "/" + file;
                        let url =  `${SERVER_URL}/downloadFile`;
                        if (response.data.messageType === MessageTypeConst.SUCCESS_MESSAGE) {
                            if(response.data.message !== "" && response.data.message !== null){
                                ConfirmBox.alert(response.data.message);
                            }
                            that.setState({filePath:response.data.executeFolderPath, url:url, fileName:file,},()=>{
                                $('#downloadExportForm').submit();
                                $(".loader").hide();
                                that.state.closeEvent();
                            });
                        }
                        else {
                            that.props.setMessage(response.data.message, response.data.messageType);
                            $(".loader").hide();
                            that.state.closeEvent();
                        }
                    }

            })).catch(error => {
                that.state.closeEvent();
                $(".loader").hide();
                that.props.setMessage("Error occurred while exporting.", MessageTypeConst.ERROR_MESSAGE);
            });
    }

    getProcessBlock() {
        var processBlock = [];
        if(this.state.processFlowList !== null && this.state.processFlowList.length >0 &&
            this.state.processFlowList[this.state.activeProcessFlow] !== null && typeof(this.state.processFlowList[this.state.activeProcessFlow]) !== "undefined"){
            let processFlow = this.state.processFlowList[this.state.activeProcessFlow].process;
            if(typeof(processFlow) !== 'undefined' && processFlow !== null){
                processBlock = processFlow.processBlock;
            }

        }
        //var processBlock = JSON.parse(localStorage.getItem("processBlock"));
        var processFlowTableId = parseInt(this.state.processFlowTableId, 10);
        var actionBlock = {};
        processBlock.forEach((block, index) => {
          if (processFlowTableId === block.blockId) {
            actionBlock = block
            return block;
          }
        })
        return actionBlock;
    }

    handleExportClickEvent(event){
        event.target.disabled = true;
        let requestObj = Object.assign({}, this.state.params, {
          exportCode: this.state.exportCode,
          exportLog: this.state.exportLog,
          exportList: this.state.exportList,
          exportData: this.state.exportData,
          exportDocument: this.state.exportDocument,
          exportReport: this.state.exportReport,
          exportDataExtension: this.state.exportDataExtension,
          classifierList: this.state.selectedBlockClassifier
        });
        switch(this.state.params.exportType){
            case ExportTypeConst.EXPORT_PROCESS_BLOCK:
            requestObj = Object.assign({},requestObj,{
                    studyId:this.processBlock.studyId,
                    processGroupId:0,
                    processId:this.processBlock.processId,
                    blockId:this.processBlock.blockId
                });
            break;
            default:
            break;
        }
        this.exportApi(requestObj);
    }

    handleExportCodeChange(event){
        this.setState({exportCode:event.target.checked});
    }

    handleExportLogChange(event){
        this.setState({exportLog:event.target.checked});
    }

    handleExportListChange(event){
        this.setState({exportList: event.target.checked});
    }

    handleExportDataChange(event){
        this.setState({exportData:event.target.checked});
    }

    handleExportDocumentChange(event){
        this.setState({exportDocument:event.target.checked});
    }

    handleExportReportChange(event){
        this.setState({exportReport:event.target.checked});
    }

    handleDataFormatChange(event){
        let exportAsCsv = false;
        let exportAsSas7bdat = false;
        let exportAsXpt = false;
        let exportDataExtension = "";
        switch(event.target.id){
            case "csvFormat":
            exportAsCsv = true;
            exportDataExtension = ".csv";
            break;
            case "sas7bdatFormat":
            exportAsSas7bdat = true;
            exportDataExtension = ".sas7bdat";
            break;
            case "xptFormat":
            exportAsXpt = true;
            exportDataExtension = ".xpt";
            break;
        }
        this.setState({exportAsCsv, exportAsSas7bdat,exportAsXpt, exportDataExtension});
    }

    handleChangeBlockClassifier(e){
      this.setState({
        selectedBlockClassifier: e.value,
        allBlockClassifierSelected: this.state.blockClassifierList.length === e.value.length
      });
    }

    handleOpenBlockClassifier = (e) => {
      this.setState({
        isBlockClassifierOpen: true
      })
    }

    handleBlurBlockClassifier = (e) => {
      this.setState({
        isBlockClassifierOpen: false
      });
    };

     render(){
         return(
          <div className="export-object">
            <Dialog className="study-properties" title={this.state.exportTitle} width="475px" height="auto" modal={true} onClose={this.state.closeEvent} buttons={[<Button primary={true} onClick={this.handleExportClickEvent} disabled={!this.state.exportCode && !this.state.exportLog && !(this.state.exportData && this.state.selectedBlockClassifier.length > 0) && !this.state.exportDocument && !this.state.exportReport && !this.state.exportList} key="btn-export"> Export</Button>,<Button onClick={this.state.closeEvent} key="btn-cancel"> Cancel</Button>]}>
                <div>
                    <fieldset>
                        <div className="field_group m-t-10 m-b-10">
                            <legend>Export </legend>
                            <div className="row m-t-10">
                                <div className="col-sm-3">
                                    <label className="am-form-field"><input className="m-0" type="checkbox" name="chkExportCode"
                                        value="Code" checked={this.state.exportCode} onChange={this.handleExportCodeChange}
                                        id="studyExportTypeStudy" disabled={this.state.disabledExportCode} /> Code</label>
                                </div>
                                <div className="col-sm-3">
                                    <label className="am-form-field"><input className="m-0" type="checkbox" name="chkExportLog"
                                        value="Log" checked={this.state.exportLog} onChange={this.handleExportLogChange}
                                        id="chkExportLog" disabled={this.state.disabledExportLog} /> Log</label>
                                </div>
                                <div className="col-sm-3">
                                    <label className="am-form-field"><input className="m-0" type="checkbox"
                                        name="chkExportDocument" value="Document" checked={this.state.exportDocument}
                                        onChange={this.handleExportDocumentChange} id="chkExportDocument"
                                        disabled={this.state.disabledExportDocument} /> Document</label>
                                </div>
                                <div className="col-sm-3">
                                    <label className="am-form-field"><input className="m-0" type="checkbox"
                                        name="chkExportReport" value="Report" checked={this.state.exportReport}
                                        onChange={this.handleExportReportChange} id="chkExportReport"
                                        disabled={this.state.disabledExportReport} /> Report</label>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-sm-3">
                                    <label className="am-form-field checkbox-label-m">
                                        <input className="m-0" type="checkbox" name="chkExportData" value="Data"
                                            checked={this.state.exportData} onChange={this.handleExportDataChange}
                                            id="chkExportData" disabled={this.state.disabledExportData} /> Data</label>
                                </div>
                                <div className="col-sm-3">
                                    <label className="am-form-field checkbox-label-m">
                                        <input className="m-0" type="checkbox" name="chkExportList" value="List"
                                            checked={this.state.exportList} onChange={this.handleExportListChange}
                                            id="chkExportList" disabled={this.state.disabledExportList} /> List</label>
                                </div>
                                {this.props.params.exportType !== ExportTypeConst.EXPORT_PROCESS_BLOCK &&
                                  <div className="col-sm-6">
                                      <span className="am-form-field export-multiselect">
                                          <MultiSelectDropDownList
                                            data={this.state.blockClassifierList}
                                            value={this.state.selectedBlockClassifier}
                                            onChange={this.handleChangeBlockClassifier}
                                            textField="classifierName"
                                            dataItemKey="classifierId"
                                            disabled={!this.state.exportData}
                                          />
                                      </span>
                                  </div>
                                }
                                <div className="col-sm-12">
                                    <fieldset>
                                        <div className="field_group m-t-10 m-b-10">
                                            <legend>Data Format</legend>
                                            <label className="am-form-field"><input className="m-0" type="radio" name="csvFormat"
                                                value="csv" checked={this.state.exportAsCsv}
                                                onChange={this.handleDataFormatChange} id="csvFormat"
                                                disabled={!this.state.exportData} /> .csv</label>
                                            <br />
                                            <label className="am-form-field"><input className="m-0" type="radio" name="xptFormat"
                                                value="xpt" checked={this.state.exportAsXpt}
                                                onChange={this.handleDataFormatChange} id="xptFormat"
                                                disabled={!this.state.exportData} /> .xpt</label>
                                            <br />
                                            <label className="am-form-field"><input className="m-0" type="radio"
                                                name="sas7bdatFormat" value="sas7bdat" checked={this.state.exportAsSas7bdat}
                                                onChange={this.handleDataFormatChange} id="sas7bdatFormat"
                                                disabled={!this.state.exportData} /> .sas7bdat</label>

                                        </div>
                                    </fieldset>
                                </div>
                            </div>
                        </div>
                    </fieldset>
                </div>
                <form action={this.state.url} method="POST" id="downloadExportForm">
                    <input type="hidden" name="fileName" id="fileName" value={this.state.fileName} />
                    <input type="hidden" name="filePath" id="filePath" value={this.state.filePath} />
                </form>
            </Dialog>
        </div>
         );
     }
 }

 const mapStateToProps = createSelector(
    state => state.processFlow.processFlowList,
    state => state.processFlow.tenantId,
    state => state.processFlow.processFlowTable,
    state => state.processFlow.processFlowTableId,
    state => state.processFlow.activeProcessFlow,
    (processFlowList, tenantId, processFlowTable, processFlowTableId, activeProcessFlow) => ({
      processFlowList,
      tenantId,
      processFlowTable,
      processFlowTableId,
      activeProcessFlow
    })
  );

  const mapActionsToProps = {
    setMessage: setMessage
  }

  Export.propTypes = {
    tenantId: PropTypes.string,
  }

  Dialog.propTypes = {
      height: PropTypes.oneOfType([
          PropTypes.string
      ]),
      width: PropTypes.oneOfType([
          PropTypes.string
      ])
  }
  export default connect(mapStateToProps, mapActionsToProps)(Export);
