import React from 'react';
import { connect } from 'react-redux';
import { createSelector } from 'reselect';
import { DropDownList } from './../DropDown/StandardDropDownList';
import { Button } from './../Button/StandardButton';
import { ComboBox } from './../DropDown/StandardComboBox';
import RichGridTable from '../../../RichGridTable';
import { RefPfNameCell, RefPfElementIdCell, StartStopCell, RefPfElementIdReplaceCell, BasicCheckBoxCell } from '../../common';

class BaseCompositeGrid extends React.Component {
  RefStopOptionIndex = 69;
  RefStartOptionIndex = 64;
  StartOptionGridIndex = 32;
  StopOptionGridIndex = 33;
  constructor(props) {
    super(props);
    var replaceType = false;
    let refOption = "Min";
    let rowData = [{"rowId": 0, "columnId": 0,"selected": false, "refPfName" : "", "refPfElementId" : "", "startStop" : ""}];
    if(this.getGlobalValue(this.RefStartOptionIndex) === ""){
      this.saveGlobalValue(this.RefStartOptionIndex, "Min");
    }if(this.getGlobalValue(this.RefStopOptionIndex) === ""){
      this.saveGlobalValue(this.RefStopOptionIndex, "Min");
    }
    if(props.optionType === 'startOption'){
      refOption = this.getGlobalValue(this.RefStartOptionIndex);
      if(this.getGlobalValue(this.StartOptionGridIndex) !== ""){
        let data =  JSON.parse(this.getGlobalValue(this.StartOptionGridIndex));
        if(data.length > 0){
          rowData = data;
        }
      }
    } else if (props.optionType === 'stopOption'){
      refOption = this.getGlobalValue(this.RefStopOptionIndex);
      if(this.getGlobalValue(this.StopOptionGridIndex) !== ""){
        let data =  JSON.parse(this.getGlobalValue(this.StopOptionGridIndex));
        if(data.length > 0){
          rowData = data;
        }
      }
    }
    if (typeof(props.replaceType) !== 'undefined') {
      replaceType = props.replaceType;
    }
    global.referencePointsName = props.referencePointsName;
    global.referencePoints = props.referencePoints;
    this.state = {
      optionType : props.optionType,
      refOption : refOption,
      tableList:[{
        id: `reporting-ops-composite-grid-${props.optionType}`,
        rows: rowData,
        resize: true,
        filterable: false,
        selectedField: "selected",
        columnProperty: [
          { field: "selected", className: props.optionType, width:"25px",sortable:false, cell:BasicCheckBoxCell },
          { field: "rowId", show: false },
          { field: "columnId", show: false },
          { field: "refPfName", width: "330px",cell: RefPfNameCell, title: "Name" },
          { field: "refPfElementId", width: "130px", cell: replaceType ?  RefPfElementIdReplaceCell : RefPfElementIdCell, title: "Element Id" },
          { field: "startStop", width: "100px", title: "Start/Stop", cell: StartStopCell }
        ]
      }]
    }

    this.handleAddRowClick = this.handleAddRowClick.bind(this);
    this.handleRemoveRowClick = this.handleRemoveRowClick.bind(this);
    this.saveGlobalValue = this.saveGlobalValue.bind(this);
    this.getGlobalValue = this.getGlobalValue.bind(this);
    this.handleRefOptionChange = this.handleRefOptionChange.bind(this);
  }


  handleRefOptionChange = (event)=>{
    this.setState({refOption:event.target.value});
    if(this.state.optionType === 'startOption'){
      this.saveGlobalValue(this.RefStartOptionIndex, event.target.value);

    } else if (this.state.optionType === 'stopOption'){
      this.saveGlobalValue(this.RefStopOptionIndex, event.target.value);
    }
  }

  getGlobalValue(controlIndex){
    let controlValue = '';
    global.processBlockControlValues.forEach((control, index) => {
      if (control.controlId === controlIndex) {
        controlValue = global.processBlockControlValues[index].controlValue;

      }
    });
    return controlValue;
  }

  saveGlobalValue(controlIndex, value){
    global.processBlockControlValues.forEach((control, index) => {
      if (control.controlId === controlIndex) {
        global.processBlockControlValues[index].controlValue = value;
      }
    });
  }


    handleAddRowClick(event) {
      var  tableList = this.state.tableList;
      var newRowId = 0;

      if (tableList[0].rows.length > 0) {
        newRowId = tableList[0].rows[tableList[0].rows.length - 1].rowId + 1
      }

      var addRow = { "rowId": newRowId, "columnId": newRowId, "selected":false, "refPfName" : "", "refPfElementId" : "", "startStop" : ""};

      tableList[0].rows.push(addRow);

      this.setState({ tableList: tableList });
    }

    handleRemoveRowClick(event) {
      let tableList = this.state.tableList;
      const { rows } = this.state.tableList[0];
      if(typeof(rows) !== 'undefined') {
        tableList = this.removeTableRowsRecursiveElements(tableList)
      }

      this.setState({ tableList: tableList });
    }

    removeTableRowsRecursiveElements(tableList) {
      var index;
      for (index = 0; index < tableList[0].rows.length; index++) {
        if (tableList[0].rows[index].selected === true) {
          tableList[0].rows.splice(index, 1);
        }
      }

      for (index = 0; index < tableList[0].rows.length; index++) {
        if (tableList[0].rows[index].selected === true) {
          tableList = this.removeTableRowsRecursiveElements(tableList);
        }
      }

      return tableList;
    }

    UNSAFE_componentWillReceiveProps(nextProps){
      if(typeof(nextProps.referencePoints) !== "undefined" && typeof(nextProps.referencePointsName) !== "undefined"){
        global.referencePointsName = nextProps.referencePointsName;
        global.referencePoints = nextProps.referencePoints;
      }
    }

  render() {
      return (
        <div className="composite-grid">
          <div className="field_group">
            <div className="container">
              <div className="row">
                <div className="filter-area am-form-inline col-12 m-0">
                  <label className="am-form-field m-0">
                    <span className="col-6"><b>Reference Point Operation:</b></span>
                    <ComboBox className="width-full" data={["Min", "Max"]} value={this.state.refOption} onChange={this.handleRefOptionChange}/>
                  </label>
                </div>
              </div>
            </div>
          </div>
          <div className="field_group">
            <div className="container">
                <div className="row">
                  <div className="filter-area am-form-inline col-12 clearfix m-0 text-center width-full">
                    <Button primary={true} onClick={this.handleAddRowClick}>Add</Button>
                    <Button primary={true} className="m-left" onClick={this.handleRemoveRowClick}>Delete</Button>
                  </div>
                </div>
              <div className="row">
                <div className="filter-area am-form-inline col-12">
                  <RichGridTable {...this.state.tableList[0]} />
                </div>
              </div>
            </div>
          </div>
        </div>
      )
    }
}

const mapStateToProps = createSelector(
  state => state.dataOperation.referencePoints,
  state => state.dataOperation.referencePointsName,
  (referencePoints, referencePointsName) => ({
    referencePoints,
    referencePointsName,
  })
);

const mapActionsToProps = {

}

export const CompositeGrid = connect(mapStateToProps, mapActionsToProps)(BaseCompositeGrid);
