import React from "react";
import GridMapper from '../../GridMapper';
import { JoinTypeDropDown } from '../common';
import cartesian from './../../../../assets/img/join/CartesianProduct.png';
import inner from './../../../../assets/img/join/innerjoin.jpg';
import fullJoin1 from './../../../../assets/img/join/fulljoin1.jpg';
import fullJoin2 from './../../../../assets/img/join/fulljoin2.jpg';
import leftJoin1 from'./../../../../assets/img/join/LeftModifiedJoin.jpg';
import leftJoin2 from './../../../../assets/img/join/leftjoin2.jpg';
import rightJoin1 from './../../../../assets/img/join/RightModifiedJoin.jpg';
import rightJoin2 from './../../../../assets/img/join/rightjoin2.jpg';

function JoinLayoutTemplate() {
  var joinOperation = [
          "Left Join (If A)",
          "Left Join (If A + NOT B)",
          "Right Join (If B)",
          "Right Join (If B + NOT A)",
          "Full Outer Join (IF A and B EXCEPT INTERSECT)",
          "Full Outer Join (IF A or B)",
          "Inner Join (IF A and B)",
          "CARTESIAN Join (A x B)"
        ];
let operation = this.state.operation;
global.processBlockControlValues.forEach((control, index) => {
  if (control.controlId === 25) {
    if(global.processBlockControlValues[index].controlValue !== "" && !isNaN(global.processBlockControlValues[index].controlValue)){
      let index1  = parseInt(global.processBlockControlValues[index].controlValue, 10) - 1;
      operation = joinOperation[index1];
    }else{
      global.processBlockControlValues[index].controlValue = joinOperation.indexOf(operation) + 1;
    }
  }
});

var handleOperationChange = (event) => {
  global.processBlockControlValues.forEach((control, index) => {
    if (control.controlId === 25) {
      let index1  = joinOperation.indexOf(event.value);
      if(index1>-1)
      {
        global.processBlockControlValues[index].controlValue = index1 + 1;
        operation = event.value;
      }
      this.setState({...this.state.tableList});
      return true;
    }
  });
}

  let imageIcon = '';
  if (operation === "CARTESIAN Join (A x B)") {
    imageIcon = cartesian;
  } else if (operation === "Left Join (If A)") {
    imageIcon = leftJoin1;
  } else if (operation === "Left Join (If A + NOT B)") {
    imageIcon = leftJoin2;
  } else if (operation === "Right Join (If B)") {
    imageIcon = rightJoin1;
  } else if (operation === "Right Join (If B + NOT A)") {
    imageIcon = rightJoin2;
  } else if (operation === "Full Outer Join (IF A and B EXCEPT INTERSECT)") {
    imageIcon = fullJoin1;
  } else if (operation === "Full Outer Join (IF A or B)") {
    imageIcon = fullJoin2;
  } else if (operation === "Inner Join (IF A and B)") {
    imageIcon = inner;
  }

  return (
    <div className="process-option standard">
      <div className="filter-area am-form-inline col-5">
        <label className="am-form-field">
          <span><b>Select Join Operation:</b></span>
          <JoinTypeDropDown  data={joinOperation} value={operation} onChange={handleOperationChange}/>
          {imageIcon !== '' &&
            <span className="m-l-10"><img src={imageIcon} width="40px"/></span>
          }
        </label>
      </div>
      <div className="join_layout">
        <GridMapper {...this.state}/>
      </div>

    </div>
  );
};

export default JoinLayoutTemplate;
