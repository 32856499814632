import React from 'react';
import { Button } from './../DataOperation/common/Button/StandardButton';
import { connect } from 'react-redux';
import { createSelector } from 'reselect';
import { addProcessFlow, setActiveProcessFlow, setJumpToBlockIdProcessId, removeProcessFlow, setALLProcessFlowRenderStatus } from '../../../actions/actionProcessFlow';
import { setMessage } from '../../../actions/actionNotification';
import ConfirmBox from './../DataOperation/common/ConfirmBox';

export const JumpToBlockButton = ({ tableManager, value, data, column, colIndex, rowIndex }) => {
    if (typeof(tableManager) === 'undefined') {
      return (null)
    } else {
      return (
          <div className='rgt-cell-inner' style={{ alignItems: 'center', overflow: 'hidden'}}>
              <JumpToBlockButtonClass dataItem={data} field={column.field} value={value} tableManager={tableManager}/>
          </div>
      )
    }
}


class BaseJumpToBlockButton extends React.Component {

    fetchProcessFlow = (studyId, processId) =>{
        var that = this;
        let treeView = that.props.treeView.data;
        let selectedFlow = null;
        treeView.children.forEach((project, index) => {
          project.children.forEach((study) => {
            if (study.study.studyId === studyId) {
              study.children.forEach((studyElement) => {
                if (studyElement.name === 'ProcessFlows') {
                  studyElement.children.forEach((classifier) => {
                    classifier.children.forEach((processGroup) => {
                      processGroup.children.forEach((flow)=>{
                        if(processId === flow.process.processId){
                          selectedFlow = flow;
                          return selectedFlow;
                        }
                      })
                    });
                  })
                }
              })
            }
         });
        })
        return selectedFlow;
      }

    onJumpTo = (e) => {
        let that = this;
        let studyId = this.props.dataItem.studyId;
        let flowId = this.props.dataItem.flowId;
        let blockId = this.props.dataItem.blockId;
        if(this.props.field === "targetBlockId"){
          studyId = this.props.dataItem.targetStudyId;
          flowId = this.props.dataItem.targetProcessId;
          blockId = this.props.dataItem.targetBlockId;
       }
        let process = that.fetchProcessFlow(studyId, flowId );
        if(process !== null){
          that.props.processFlowList.forEach((flow, index) => {
            if(flow.process.processId === process.process.processId && flow.process.studyId === process.process.studyId && flow.process.processGroupId === process.process.processGroupId){
              that.props.removeProcessFlow(flow.process.processId, flow.process.studyId);
            }
          });
          that.props.addProcessFlow(process);
          that.props.setALLProcessFlowRenderStatus(true);
          that.props.setJumpToBlockIdProcessId(blockId, flowId);
          that.props.dataItem.closeEvent();
        }else{
          ConfirmBox.alert("Study for selected jump to block not included. Please select appropriate Study using ‘Study Select’ link.");
        }
      }

    handleChange(e) {
        this.props.dataItem[this.props.field] = e.target.value;
        this.props.tableManager.rowsApi.setRows(this.props.tableManager.rowsApi.rows)
    }

    render() {
       let title = "Jump To Source";
       let disabled = this.props.dataItem.blockId === 0 ;
       if(this.props.field === "targetBlockId"){
          title = "Jump To Target";
          disabled = this.props.dataItem.targetBlockId === 0 ;
       }
        return (
            <div className="text-center">
              <Button primary={true} iconClass="fa fa-exchange" onClick={this.onJumpTo} disabled={disabled}>{title}</Button>
            </div>
        );
    }
}

const mapStateToProps = createSelector(
    state => state.processFlow.tenantId,
    state => state.processFlow.processFlowList,
    state => state.processFlow.activeProcessFlow,
    state => state.treeView,
    (tenantId, processFlowList, activeProcessFlow, treeView) => ({
        tenantId,
        processFlowList,
        activeProcessFlow,
        treeView
    })
  );

  const mapActionsToProps = {
    setActiveProcessFlow: setActiveProcessFlow,
    setJumpToBlockIdProcessId: setJumpToBlockIdProcessId,
    addProcessFlow: addProcessFlow,
    setMessage: setMessage,
    removeProcessFlow: removeProcessFlow,
    setALLProcessFlowRenderStatus: setALLProcessFlowRenderStatus
  }

const JumpToBlockButtonClass = connect(mapStateToProps, mapActionsToProps)(BaseJumpToBlockButton);
