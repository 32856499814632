import React from 'react';
import { DropDownList } from './StandardDropDownList';

export const GroupClassifierCell = ({ tableManager, value, data, column, colIndex, rowIndex }) => {
    if (typeof(tableManager) === 'undefined') {
      return (null)
    } else {
      return (
          <div className='rgt-cell-inner' style={{display: 'flex', alignItems: 'center', overflow: 'hidden'}}>
              <GroupClassifierCellClass dataItem={data} field={column.field} value={typeof(data[column.field]) !== 'undefined' ? data[column.field]: value} column={column} tableManager={tableManager}/>
          </div>
      )
    }
}

class GroupClassifierCellClass extends React.Component {
  handleChange(e) {
    this.props.dataItem[this.props.field] = e.target.value;
    this.props.tableManager.rowsApi.setRows(this.props.tableManager.rowsApi.rows)
  }

  render() {
    let data = [];
    let value = this.props.dataItem[this.props.field];

    if (typeof(this.props.dataItem['groupClassifierList']) !== 'undefined') {
      data = this.props.dataItem['groupClassifierList'];
    }

    return (
        <>
          <DropDownList className="width-full group-classifier-dropdown" onChange={this.handleChange.bind(this)} data={data} value={value}/>
        </>
      );
    }
}
