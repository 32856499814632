import 'react-dropdown/style.css';
import React from 'react';
import cloneDeep from 'lodash/cloneDeep';
import Select from 'react-select';

export class ComboBox extends React.Component {
  render() {
    let props = cloneDeep(this.props);
    let options = [];
    let value = props.value;

    if (typeof(this.props.defaultValue) !== 'undefined') {
      value = this.props.defaultValue
    }

    if (typeof(props.data) !== 'undefined' && props.data.length > 0) {
      if (typeof(props.dataItemKey) !== 'undefined' && typeof(props.textField) !== 'textField') {
        props.data.forEach(option => {
          options.push({
            label: option[props.textField] === '' ? ' ': option[props.textField],
            value: option[props.dataItemKey]
          })
        })

        if (typeof(props.value[props.textField]) !== 'undefined') {
            value = {label: props.value[props.textField] === '' ? ' ': props.value[props.textField], value: props.value[props.dataItemKey]};
        }
      } else {
        props.data.forEach(option => {
          options.push({
            label: option,
            value: option
          })
        })

        if (typeof(value) !== 'undefined' && value !== '') {
          value = {label: value, value: value}
        }
      }
    }

    props = {
      ...props,
      options: options,
      placeholder: typeof(props.placeholder) !== 'undefined' ? props.placeholder : '',
      onChange: (event) => this.props.onChange({...event, target: {value: (typeof(this.props.dataItemKey) !== 'undefined' && typeof(this.props.textField) !== 'textField') && event !== null && typeof(event.value) !== 'undefined' ? this.props.data.find(option => (option[this.props.textField] === event.label || option[this.props.textField] === event.label.trim()) && option[this.props.dataItemKey] === event.value) : (event !== null ? event.value : '')}}),
      className: 'am-combobox'+ (typeof(props.className) !== 'undefined' && props.className !== '' ? ' '+ props.className: ''),
      value: value,
      isClearable: true
    }

    if (typeof(this.props.defaultValue) !== 'undefined') {
      delete props.defaultValue;
    }

    return (
      React.createElement(Select, {...props}, null)
    )
  }
}
