import React from 'react';
import { Input } from './StandardInput';

export const NewColumnNameCell = ({ tableManager, value, data, column, colIndex, rowIndex }) => {
    if (typeof(tableManager) === 'undefined') {
      return (null)
    } else {
      return (
          <div className='rgt-cell-inner' style={{display: 'flex', alignItems: 'center', overflow: 'hidden'}}>
              <NewColumnNameCellClass dataItem={data} field={column.field} value={typeof(data[column.field]) !== 'undefined' ? data[column.field]: value} column={column} tableManager={tableManager}/>
          </div>
      )
    }
}

class NewColumnNameCellClass extends React.Component {

    handleChange(e) {
      this.props.dataItem[this.props.field] = e.target.value;
      this.props.tableManager.rowsApi.setRows(this.props.tableManager.rowsApi.rows)

      if (typeof(this.props.dataItem.highlightFieldList) !== 'undefined' && typeof(global.executionPlanUserChangeList) !== 'undefined' && typeof(this.props.dataItem.inputTableNameList) !== 'undefined' && typeof(this.props.dataItem.operationDetail.originalSourceTableName) !== 'undefined' && typeof(this.props.dataItem.systemSuggestedInputTables) !== 'undefined') {
        if (["preWhereLeftTable", "preWhereRightTable"].indexOf(this.props.field) >= 0) {
          let inputTable = this.props.dataItem.systemSuggestedInputTables[0];

          if (this.props.field === "preWhereRightTable") {
            inputTable = this.props.dataItem.systemSuggestedInputTables[1];
          }

          if (this.props.dataItem.inputTableNameList.filter(inputTableName => inputTableName.tableName === inputTable ).length > 0) {
            global.executionPlanUserChangeList = global.executionPlanUserChangeList.filter(executionPlanUserChange => !(executionPlanUserChange.targetTable === this.props.dataItem.targetTable && executionPlanUserChange.sourceTable === this.props.dataItem.operationDetail.originalSourceTableName && executionPlanUserChange.inputTable === inputTable && executionPlanUserChange.field === this.props.field));

            if (e.target.value !== '') {
              global.executionPlanUserChangeList.push({
                targetTable: this.props.dataItem.targetTable,
                sourceTable: this.props.dataItem.operationDetail.originalSourceTableName,
                inputTable: inputTable,
                field: this.props.field,
                value: e.target.value
              })
            }
          }
        }
      }
    }
    render() {
        return (
            <div className={typeof(this.props.dataItem.highlightFieldList) !== 'undefined' && this.props.dataItem.highlightFieldList.indexOf(this.props.field) > -1 ? 'highlight-yellow' : 'width-full'}>
              <Input className="width-full" onChange={this.handleChange.bind(this)} value={this.props.dataItem[this.props.field]} length={40}/>
            </div>
        );
    }
}
