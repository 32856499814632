import React from 'react';
import { DropDownList } from './StandardDropDownList';

export class SourceInputTableDropDown extends React.Component {
  handleChange(e) {
    this.props.onChange({
      data: this.props.data,
      syntheticEvent: e.syntheticEvent,
      value: e.target.value,
      inputTableName: this.props.inputTableName
    });
  }
  render() {
      return (
        <DropDownList className="m-0" onChange={this.handleChange.bind(this)} data={this.props.data} value={this.props.value}/>
      );
    }
}
