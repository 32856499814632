import React from 'react';
import { ComboBox } from './../DropDown/StandardComboBox';
import { Button } from './../Button/StandardButton';
import { Input } from './../InputBox/StandardInput';
import DataOpsIdentifierConstants from "../../../../DataOpsIdentifierConstants";

export class Title extends React.Component {
  ProcReportTitlesIndex  = 12;
  ProcReportTitleOptionType =  36;
  ProcReportDefineIndex  = 14;
  constructor(props){
    super(props);
    this.state = {
      rows:[],
      inputColumns: [],
      optionType: "TEXT",
      isTextOption: true,
      titleObject : {
        title1:"",
        title2:"",
        title3:"",
        title4:"",
        title5:"",
        title6:"",
        title7:"",
        title8:"",
        title9:"",
        title10:""
      },
      processFlowAction: props.processFlowAction
    }
    if(this.getGlobalValue(this.ProcReportDefineIndex) !== ""){
      this.state.rows = JSON.parse(this.getGlobalValue(this.ProcReportDefineIndex));
    }else{
      this.state.rows = [].concat(this.state.rows);
    }
    this.state.inputColumns = this.getInputColumnList(this.state.rows);
    if(typeof(this.getGlobalValue(this.ProcReportTitleOptionType)) !== 'undefined' && this.getGlobalValue(this.ProcReportTitleOptionType) !== ""){
        this.state.optionType = this.getGlobalValue(this.ProcReportTitleOptionType);
    }else{
      this.state.optionType = this.isTitleColumnPresent(this.state.inputColumns) ? "DROPDOWN" : "TEXT"
      this.saveGlobalValue(this.ProcReportTitleOptionType, this.state.optionType );
    }
    if(this.state.optionType === "DROPDOWN"){
      this.state.isTextOption = false;
    }
    if(typeof(this.getGlobalValue(this.ProcReportTitlesIndex)) !== 'undefined' && this.getGlobalValue(this.ProcReportTitlesIndex) !== ""){
        this.state.titleObject = JSON.parse(this.getGlobalValue(this.ProcReportTitlesIndex));
    }else{
      if(this.state.optionType === "DROPDOWN"){
        this.state.titleObject = this.populateTitle(this.state.inputColumns, this.state.titleObject);
      }
    }
    this.handleOnChange = this.handleOnChange.bind(this);
    this.handleOptionTypeSelectionChange = this.handleOptionTypeSelectionChange.bind(this);
    this.populateTitle = this.populateTitle.bind(this);
    this.handleResetToDefault = this.handleResetToDefault.bind(this);
  }

  getInputColumnList = (data) => {
    var columns = [];
    data.forEach((row) => {
      columns = [...columns, row.column];
    });

    return columns.sort();
  }

  handleOptionTypeSelectionChange = (event) => {
    let isTextOption = event.target.checked;
    let optionType = "TEXT";
    let titleObject = this.state.titleObject;
    if(event.target.id === "optTitleDropDown"){
      isTextOption = !isTextOption;
      optionType= "DROPDOWN";
      titleObject = this.populateTitle(this.state.inputColumns, titleObject);
    }
    this.saveGlobalValue(this.ProcReportTitleOptionType,optionType);
    this.setState({isTextOption:isTextOption, optionType: optionType, titleObject: titleObject});

  }

  populateTitle = (inputColumns, titleObject)=>{
    for(let i=1 ; i<=10 ; i++){
      titleObject[`title${i}`] = this.getColumnValue(titleObject[`title${i}`], inputColumns, `title${i}`);
    }
    this.saveGlobalValue(this.ProcReportTitlesIndex, JSON.stringify(titleObject));
    return titleObject;
  }

  isTitleColumnPresent = (inputColumns) => {
    let titleColPresent = false;
    for(let i=1 ; i<=10 ; i++){
      let col = this.getColumnValue("", inputColumns, `title${i}`);
      if(col !== ""){
        titleColPresent = true;
        return titleColPresent;
      }
    }
    return titleColPresent;

  }

  getColumnValue(title, inputColumns, colName) {
    if (title === "" || title === null || typeof(title) === "undefined") {
      let selectedValue = inputColumns.filter(item => item.toLowerCase() === colName.toLowerCase());
      if (selectedValue.length > 0) {
        title = selectedValue[0];
      }
    }
    else {
      let selectedValue = inputColumns.filter(item => item.toLowerCase() === title.toLowerCase());
      if (selectedValue.length > 0) {
        title = selectedValue[0];
      }
      else {
        let selectedValue = inputColumns.filter(item => item.toLowerCase() === colName.toLowerCase());
        if (selectedValue.length > 0) {
          title = selectedValue[0];
        }else{
          title = "";
        }
      }
    }
    return title;
  }

  handleResetToDefault = (event) => {
    let titleObject = this.state.titleObject;
    for(let i=1 ; i<=10 ; i++){
      titleObject[`title${i}`] = "";
    }
    if(!this.state.isTextOption){
      titleObject = this.populateTitle(this.state.inputColumns, titleObject)
    }
    this.setState({titleObject: titleObject});
  }

  getGlobalValue(controlIndex){
    let controlValue = '';
    global.processBlockControlValues.forEach((control, index) => {
      if (control.controlId === controlIndex) {
        controlValue = global.processBlockControlValues[index].controlValue;

      }
    });
    return controlValue;
  }

  saveGlobalValue(controlIndex, value){
    global.processBlockControlValues.forEach((control, index) => {
      if (control.controlId === controlIndex) {
        global.processBlockControlValues[index].controlValue = value;
      }
    });
  }
  handleOnChange = (event)=>{
    let obj = this.state.titleObject;
    let id = event.target.id;
    if(id === null || typeof(id) === "undefined"){
      id = event.target.name;
    }
    obj[id] = event.target.value === null ? "" : event.target.value;
    this.setState({titleObject:obj });
    this.saveGlobalValue(this.ProcReportTitlesIndex, JSON.stringify(obj));
  }

  renderTextBox = (key, index) => {
    return (
      <label key={`${key}-${index+1}`} className="am-form-field">
      <span className="col-1"><b>{`${this.state.processFlowAction === DataOpsIdentifierConstants.R_Report_OP ? "Header" : "Title"} ${index+1}:`}</b></span>
      <Input type="text" id={key} className="width-full m-0"  value={this.state.titleObject[key]}
      onChange={this.handleOnChange}/>
       </label>
    );
  }

  renderDropDown = (key, index) => {
    return (
      <label key={`${key}-${index+1}`} className="am-form-field">
        <span className="col-1"><b>{`${this.state.processFlowAction === DataOpsIdentifierConstants.R_Report_OP ? "Header" : "Title"} ${index+1}:`}</b></span>
        <ComboBox data={this.state.inputColumns} allowCustom={false} name={key} value={this.state.titleObject[key]}
        className="width-full m-0"  onChange={this.handleOnChange}/>
      </label>
    );
  }

  render() {
    let tiltleObject = this.state.titleObject;
      return (
        <div className="proc-report-title">
          <div className="container-fluid p_15">

          <div className="row">
              <div className="filter-area am-form-inline col-12">
                <label className="am-form-field m_t_5">
                  <span className="col-1"><b>{this.state.processFlowAction === DataOpsIdentifierConstants.R_Report_OP ? "Header Type:" : "Title Type:"} </b></span>
                   <input type="radio" name="optTitleType" value="Text" checked={this.state.isTextOption}
                    onChange={this.handleOptionTypeSelectionChange} id="optTitleText" className="am-radio" />
                    <label className="am-radio-label" htmlFor="optTitleText">Text</label> &nbsp;&nbsp;
                    <input type="radio" name="optTitleType" value="Destination" checked={!this.state.isTextOption}
                    onChange={this.handleOptionTypeSelectionChange} id="optTitleDropDown" className="am-radio"/>
                    <label className="am-radio-label" htmlFor="optTitleDropDown">DropDown</label>
                    <Button onClick={this.handleResetToDefault}  > Reset to Default</Button>
                </label>
            { this.state.isTextOption && <div>
                {
                  Object.keys(this.state.titleObject).map(this.renderTextBox)
                }
                </div>
            }
            { !this.state.isTextOption && <div>
              {
                Object.keys(this.state.titleObject).map(this.renderDropDown)
              }
              </div>
            }
            </div>
          </div>
          </div>
          </div>
      )
  }
}
