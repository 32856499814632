import React from "react";
import { TabStrip, TabStripTab  } from './../common/Layout';
import { ProcessOptionGrid } from "../common/gridOperation";
import { SortOrder } from "../common";

function SortOpsLayoutTemplate() {

  let dupkeychecked = false;
  let dupoutchecked = false;

  global.processBlockControlValues.forEach((control, index) => {
    if (control.controlId === 7) {
      dupkeychecked = global.processBlockControlValues[index].controlValue !== "" ? JSON.parse(global.processBlockControlValues[index].controlValue) : false;
    }
    if (control.controlId === 8) {
      dupoutchecked = global.processBlockControlValues[index].controlValue !== "" ? JSON.parse(global.processBlockControlValues[index].controlValue): false;
    }
  });

  var handleDupKeyCheckBoxChange = (event) => {
    global.processBlockControlValues.forEach((control, index) => {
      if (control.controlId === 7) {
        global.processBlockControlValues[index].controlValue = event.target.checked;
        dupkeychecked = event.target.checked;
        this.setState({...this.state.tableList});
        return true;
      }
    });
  }

  var handleDUPOUTCheckBoxChange = (event) => {
    global.processBlockControlValues.forEach((control, index) => {
      if (control.controlId === 8) {
        global.processBlockControlValues[index].controlValue = event.target.checked;
        dupoutchecked = event.target.checked;
        this.setState({...this.state.tableList});
        return true;
      }
    });

    global.processBlockControlValues.forEach((control, index) => {
      if (control.controlId === 7) {
        dupkeychecked = event.target.checked;
        global.processBlockControlValues[index].controlValue = dupkeychecked;
        this.setState({...this.state.tableList});
        return true;
      }
    });
  }

  return (
    <div className="process-option">
      <TabStrip selected={this.state.tabSelected} onSelect={this.handleSelectTabPanel}>
        <TabStripTab title="Process">
          <div className="m-0 row p-0">
            <div className="m-0 col-12 p-0">
              <fieldset>
                <div className="field_group">
                  <legend>Output Option</legend>
                  <div className="filter-area am-form-inline col-12 m-0">
                    <div className="am-form-field m-0 p-t-b-4">
                      <input type="checkbox" id="no_dupkey_gby" className="am-checkbox" checked={dupkeychecked} onChange={handleDupKeyCheckBoxChange}/>
                      <label className="am-checkbox-label" htmlFor="no_dupkey_gby">No Dupkey(GBY)</label>
                      <input type="checkbox" id="dupout" className="am-checkbox" checked={dupoutchecked} onChange={handleDUPOUTCheckBoxChange}/>
                      <label className="am-checkbox-label m-l-5" htmlFor="dupout">DUPOUT</label>
                    </div>
                  </div>
                </div>
              </fieldset>
            </div>
          </div>

          <div className="field_group">
            <ProcessOptionGrid tableList={this.state.tableList} />
          </div>
        </TabStripTab>
        <TabStripTab title="GBY & OBY Order">
          {this.state.tableList.length > 0 ? (
            <SortOrder table={this.state.tableList[0]} />
          ) : (<></>)}
        </TabStripTab>
      </TabStrip>
    </div>
  );
};

export default SortOpsLayoutTemplate;
