import "./../UserManagement.css";
import React from "react";
import { PropTypes } from "prop-types";
import { Button } from './../../DataOperation/common/Button/StandardButton';
import { Input } from './../../DataOperation/common/InputBox/StandardInput';
import { TabStrip, TabStripTab  } from './../../DataOperation/common/Layout';
import RichGridTable from './../../RichGridTable';
import { StandardDropDown } from './../../DataOperation/common';
import AccessControlConstants from './../../../AccessControlConstants';
import Dialog from 'react-dialog';

function template() {
  const isProjectlevelAccessControl = this.state.objectType === AccessControlConstants.PROJECT;
  const isManageUsersGroupsPermission =  this.props.userPermission.indexOf(AccessControlConstants.MANAGE_USERS_GROUPS) > -1
  return (
    React.createElement(Dialog, {title: `Access Control - ${this.state.node.name}`, width:"900px", height:"calc(100vh - 40px)", modal:true, onClose: (event) => this.state.onClose(event), buttons: [React.createElement(Button, {primary: true, onClick: (event) => this.handleApplyClick(event), key: "btn-apply"}, 'Apply'),React.createElement(Button, {onClick: (event) => this.state.onClose(event), key: "btn-cancel"}, 'Cancel')]},
      React.createElement('div', {className: `access-control-wrapper ${this.state.isRootPermission === false ? 'access': 'permission'}`},
        React.createElement('div', {className: "row"},
          React.createElement('div', {className: "col-12"},
          React.createElement(TabStrip, {keepTabsMounted: false, selected: this.state.selected, onSelect: this.handleSelect},
            React.createElement(TabStripTab, {title: "Group"},
              React.createElement('div', {className: `list-wrapper ${isProjectlevelAccessControl === false ? 'inherit-message-note' : ''}`},
                React.createElement('ul', {className: "list"},
                  this.props.groupList.map((group, key) => [React.createElement('li', {key: "group-index-"+key, className: `${this.state.selectedItem.id === group.groupId && this.state.selectedItem.type === 'group' ? "selected" : ""} ${key%2 === 0 ? 'even' : 'odd'}`, onClick: (event) => this.handleItemSelection(event, 'group', group.groupId, group.name)},
                      group.name,
                      (this.state.groupAccessList.filter(groupAccess => groupAccess.groupId === group.groupId && ((this.state.isRootPermission === true && (groupAccess.createProject === 1 || groupAccess.manageUserGroup === 1)) || (this.state.isRootPermission === false && (groupAccess.readOnly === 1 || groupAccess.writeOnly === 1 || groupAccess.delegateOnly === 1)))).length > 0) ? React.createElement('div', {className: "fa fa-check-circle green status"}, null) : null,
                      (this.state.isRootPermission === false && this.state.groupAccessList.filter(groupAccess => groupAccess.groupId === group.groupId && (groupAccess.readOnly === 1 || groupAccess.writeOnly === 1 || groupAccess.delegateOnly === 1) && groupAccess.inheritFromParent === true).length ? React.createElement('div', {className: "fa red-asterisk"}, '*') : null),
                      (group.users.filter(groupUser => groupUser.userName !== this.props.token_detail['email']).length > 0) ? (React.createElement('span', {className: `k-icon k-i-arrow-${this.state.selectedItem.id === group.groupId && this.state.selectedItem.type === 'group' ? 'n' : 's'} k-panelbar-expand`}, null)): (null)
                    ),
                      (group.users.filter(groupUser => groupUser.userName !== this.props.token_detail['email']).length > 0 && this.state.selectedItem.id === group.groupId && this.state.selectedItem.type === 'group') ? (
                        React.createElement('ul', {key: "group-index-sub-list"+key, className: "sub-list"},
                          group.users.filter(groupUser => groupUser.userName !== this.props.token_detail['email']).map((groupUser, groupUserKey) =>
                            React.createElement('li', {key: "group-user-index-"+groupUserKey,}, groupUser.firstName+" "+groupUser.lastName + " ("+groupUser.userName+")",)
                          )
                        )
                      ): (null)
                    ]
                  )
                )
              )
            ),
            React.createElement(TabStripTab, {title: "User"},
              React.createElement('div', {className: `list-wrapper ${isProjectlevelAccessControl === false ? 'inherit-message-note' : ''}`},
                React.createElement('ul', {className: "list"},
                  this.props.userList.filter(user => user.userName !== this.props.token_detail['email']).map((user, key) => React.createElement('li', {key: "user-index-"+key, className: `${this.state.selectedItem.id === user.userId && this.state.selectedItem.type === 'user' ? "selected" : ""} ${user.isActive === 0 ? "disabled" : ""} ${key%2 === 0 ? 'even' : 'odd'}`, onClick: (event) => this.handleItemSelection(event, 'user', user.isActive === 0 ? user.isActive : user.userId, user.userName + "("+user.firstName+" "+user.lastName+")")},
                    user.firstName+" "+user.lastName + " ("+user.userName+")",
                    (this.state.userAccessList.filter(userAccess => userAccess.userId === user.userId && ((this.state.isRootPermission === true && (userAccess.createProject === 1 || userAccess.manageUserGroup === 1)) || (this.state.isRootPermission === false && (userAccess.readOnly === 1 || userAccess.writeOnly === 1 || userAccess.delegateOnly === 1)))).length > 0) ? React.createElement('div', {className: "fa fa-check-circle green status"}, null) : null,
                    (this.state.isRootPermission === false && this.state.userAccessList.filter(userAccess => userAccess.userId === user.userId && (userAccess.readOnly === 1 || userAccess.writeOnly === 1 || userAccess.delegateOnly === 1) && userAccess.inheritFromParent === true).length ? React.createElement('div', {className: "fa red-asterisk"}, '*') : null)
                  ))
                )
              )
            )
          )
          ),
          React.createElement('div', {className: "col-12 m-t-10"},
            React.createElement('fieldset', {},
              React.createElement('div', {className: "field_group clearfix"},
                React.createElement('legend', {},
                  'Permission '+ (this.state.selectedItem.title !== '' ? "for " + this.state.selectedItem.title : "")
                ),
                this.state.isRootPermission === true ? (React.createElement('div', {className: "permission-wrapper"},
                  this.props.allPermission.map((permission, key) => React.createElement('div', {className: "col-4 float-left", key: "user-permission-"+key},
                    React.createElement('input', {type: "checkbox", className: "am-checkbox", id: "checkbox-permission-"+key, onClick: (event) => this.handlePermissionSelection(event, permission.name === AccessControlConstants.CREATE_PROJECT ? "createProject" : "manageUserGroup"), checked: this.getPermissionValue(permission.name === AccessControlConstants.CREATE_PROJECT ? "createProject" : "manageUserGroup"), disabled: this.state.selectedItem.id === 0},
                        null
                    ),
                    React.createElement('label', {className: "am-checkbox-label", htmlFor: "checkbox-permission-"+key},
                        permission.name
                    )
                  ))
                )): (React.createElement('div', {className: "permission-wrapper"},
                  React.createElement('div', {className: "col-4 float-left"},
                    React.createElement('input', {type: "checkbox", className: "am-checkbox", id: "checkbox-write", onClick: (event) => this.handlePermissionSelection(event, 'writeOnly'), checked: this.getPermissionValue("writeOnly"), disabled: this.state.selectedItem.id === 0},
                        null
                    ),
                    React.createElement('label', {className: "am-checkbox-label", htmlFor: "checkbox-write"},
                        "Write"
                    )
                  ),
                  React.createElement('div', {className: "col-4 float-left"},
                    React.createElement('input', {type: "checkbox", className: "am-checkbox", id: "checkbox-read", onClick: (event) => this.handlePermissionSelection(event, "readOnly"), checked: this.getPermissionValue("readOnly"), disabled: this.state.selectedItem.id === 0},
                        null
                    ),
                    React.createElement('label', {className: "am-checkbox-label", htmlFor: "checkbox-read"},
                        "Read"
                    )
                  ),
                  React.createElement('div', {className: "col-4 float-left"+(isManageUsersGroupsPermission === false && this.state.selected === 0 ? " group-delegate-hidden" : '')},
                    React.createElement('input', {type: "checkbox", className: "am-checkbox", id: "checkbox-delegate", onClick: (event) => this.handlePermissionSelection(event, 'delegateOnly'), checked: this.getPermissionValue("delegateOnly"), disabled: this.state.selectedItem.id === 0},
                        null
                    ),
                    React.createElement('label', {className: "am-checkbox-label", htmlFor: "checkbox-delegate"},
                        "Delegate"
                    )
                  )
                ))
              )
            )
          ),
          this.state.isRootPermission === false ? (
            React.createElement('div', {className: "col-12 m-t-10"},
              React.createElement('fieldset', {},
                React.createElement('div', {className: "field_group clearfix"},
                  React.createElement('legend', {},
                    'Note '
                  ),
                  React.createElement('div', {className: "note-wrapper"},
                    React.createElement('p', {},
                      '1. Read access is mandatory while giving Write access.'
                    ),
                    React.createElement('p', {},
                      '2. Read and Write access are mandatory while giving Delegate access.'
                    ),
                    (isProjectlevelAccessControl === false ? (
                      React.createElement('p', {},
                        '3. (*) asterisk access are inherited from parents node.'
                      )
                    ) : null)
                  )
                )
              )
            )
          ) : (null)
        )
      )
    )
  )
}


Dialog.propTypes = {
    height: PropTypes.oneOfType([
        PropTypes.string
    ]),
    width: PropTypes.oneOfType([
        PropTypes.string
    ])
}
export default template;
