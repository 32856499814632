const CopyAssessmentStatus = Object.freeze({
    NONE:0,
    FLOW_NEWLY_ADDED:1,
    FLOW_DELETED:2, 
    FLOW_UPDATED:3,
    FLOW_SAME_AS_SOURCE:4,
    BLOCK_NEWLY_ADDED:5,
    BLOCK_DELETED:6,
	GROUP_NEWLY_ADDED:7,
	GROUP_DELETED:8,
	FLOW_NEW_AT_TARGET:9,
    GROUP_NEW_AT_TARGET:10,
    GROUP_SAME_AS_SOURCE:11,
    GROUP_UPDATED:12,
    SOURCE_BLOCK_UPDATED:13,
    BLOCK_SAME_AS_SOURCE:14,
    BLOCK_NEW_AT_TARGET:15,
	NULL:-1,
});

export default CopyAssessmentStatus;