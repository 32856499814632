import React from 'react';

export const GroupNameCell = ({ tableManager, value, data, column, colIndex, rowIndex }) => {
    if (typeof(tableManager) === 'undefined') {
      return (null)
    } else {
      return (
          <div className='rgt-cell-inner' style={{display: 'flex', alignItems: 'center', overflow: 'hidden'}}>
              <GroupNameCellClass dataItem={data} field={column.field} value={value} tableManager={tableManager}/>
          </div>
      )
    }
}


class GroupNameCellClass extends React.Component {

    handleChange(e) {
      this.props.dataItem[this.props.field] = e.target.value;
      this.props.tableManager.rowsApi.setRows(this.props.tableManager.rowsApi.rows)
    }

    render() {
        let name = this.props.dataItem.processGroupName;
        if(this.props.field === "sourceGroupName"){
            name = this.props.dataItem.sourceGroupName;
        }
        return (
            <div className="text-center">
              {name}
           </div>
            );
    }
}
