import React from 'react';
// import ReactDOM from 'react-dom';
import { connect } from 'react-redux';
import { createSelector } from 'reselect';
import { PropTypes } from 'prop-types';
import { TabStrip, TabStripTab } from './../Resource/DataOperation/common/Layout';
import $ from 'jquery';
import ConfirmBox from './../Resource/DataOperation/common/ConfirmBox';
import Global_Context from '../../views/SideTreeView/ShowTree/Global_Static_Node';
import { Button } from './../Resource/DataOperation/common/Button/StandardButton';
import { CREATE, DELETE, UPDATE, GET_ALL, GET, POST_PARAM_BODY } from '../../restClient/types';
import axios from 'axios';
import restClient from '../../restClient';
import Dialog from 'react-dialog';
import './processFlows.css';
import ShortNamesConst from '../ShortNameConst';
import DataOpsIdentifierConstants from '../DataOpsIdentifierConstants';
import copyDataImg from './img/copyData.png';
import metaTable from './img/database.svg';
import sourceTable from './img/table.svg';
import referencePointImg from './img/referncePoint.jpg';
import fileImg from './img/file.svg';
import tempImg from './img/temp.png';
import persistentImg from './img/persistent.png';
import linkDataImg from './img/link.png';
import timelineImg from './img/timeline.png';
import invalidImg from './img/invalid.png';
import validImg from './img/valid.svg';
import downImg from './img/down.png';
import upImg from './img/up.png';
import warningImg from './img/warning.png';
import sasLogo from './img/SAS_logo.png';
import rLogo from './img/R_logo.png';
import BlockType from '../BlockType';
import CheckOutCheckinFalg from '../CheckOutCheckInFlag';
import BlockStatus from './BlockStatus';
import BlockCreationType from '../BlockCreationType';
import SingleBlockPasteDialog from './SingleBlockPasteDialog';
import MultipleBlockPasteDialog from './MultipleBlockPasteDialog';
import { removeProcessFlow, setTenantId, removeProcessFlowBlockByTabIndex, addProcessFlowBlockByTabIndex,
    addProcessFlowBlockLinkByTabIndex, setProcessFlowAction, setProcessFlowTable, setProcessFlowTableId,
    setQuickCondition, setSubMenuColumns, updateProcessFlowBlockByTabIndex, updateProcessflowBlockRenderStatus,
    setActiveProcessFlow, setALLProcessFlowRenderStatus, selectItemsForCopyPaste, removeProcessFlowBlockLinkByTabIndex, refreshProcessFlow,
    updateProcessFlowSyncFlagByTabIndex, updateProcessFlowBlockBySchemaName, updateProcessFlowCheckedOutStatusByTabIndex, setJumpToBlockIdProcessId,
    removeProcessFlowNoteByTabIndex, addProcessFlowNoteByTabIndex, updateProcessFlowNoteByTabIndex, updateProcessFlowNoteBySchemaName } from '../../actions/actionProcessFlow';
import { setTreeViewData } from '../../actions/actionTreeView';
import MessageTypeConst from '../MessageTypeConst';
import BlockSaveType from '../BlockSaveType';
import { setMessage } from '../../actions/actionNotification';
import Export from '../Resource/Export/Export';
import ExportTypeConst from '../ExportTypeConst';
import BlocksAssessment from '../Resource/Copying/BlocksAssessment';
import notesBackGround from './img/notes.png';
import virualImg from './img/virtual.png';
import AccessControlConstants from './../AccessControlConstants';
import TaskList from './../Resource/TaskManagement/TaskList';
import CreateTask from './../Resource/TaskManagement/CreateTask';
import RichTextEditor from "./../Resource/RichTextEditor";
import Breadcrumb from "./../Resource/Breadcrumb";

var selectnote = null;
var selectnodeid = null;
//var dataTabs = null;
var selectNoteList = [];
var selectNodeIdList = [];
var dataNew = [];
//var pasteDialog ="";
var My_this =null;
var X=null;
var Y=null;
var selectedProcessBlocks = [];
var selectedNotes = [];
var selectedLinks = [];
var connectionsData= [];
var nodeStatus="";
var selectBlockName = "";
var flowDiagram = "";
var isNotesSelected = false;
const elementTitle = ( content, handleClose, studyId, id ) => {
    return <span>{content}<span onClick={ e=> handleClose(e, studyId)} id={id}  className="fa fa-close"/></span>;
};

//create notes shape
export function createNotes(options) {
    var XY_Points = options.noteCoord.split(",");
    var X_points = parseInt(XY_Points[0], 10);
    var Y_points = parseInt(XY_Points[1], 10);
    var visuals = function(e){
        var dataviz = kendo.dataviz;
        var g = new dataviz.diagram.Group();
        g.append(new dataviz.diagram.Image({
            source: notesBackGround,
            width: 140,
            height: 140,
        }));

        var layout = new dataviz.diagram.Layout(new dataviz.diagram.Rect(40, 40, 50, 80), {
            alignContent: "center",
            justifyContent: "center",
            alignItems: "center",
        });
        g.append(layout);
        let noteText = options.data.replace(/<[^>]+>/g, ' ').replace('  ', ' ').trim();
        var texts = noteText.split("\n");
        let lineNum = 4;
        if(texts.length < lineNum){
            lineNum = texts.length;
        }
        let numLines = 0;
        let isExtensionAdded = false;
        for (var i = 0; i < lineNum; i++) {
            let data = "";
            let len= 0;
            while(len <= texts[i].length){
              data = texts[i].substr(len,28);
              layout.append(new dataviz.diagram.TextBlock({
                text: data,
                fontSize:8,
              }));
              numLines = numLines + 1;
              if(numLines > 7){
                layout.append(new dataviz.diagram.TextBlock({
                  text: "...",
                  fontSize:8,
                }));
                isExtensionAdded = true;
                break;
              }
              len = len +28;
            }
            layout.append(new dataviz.diagram.TextBlock({
              text: "                                  ",
              fontSize:8,
            }));
            numLines = numLines + 1;
            if(numLines > 7 && ! isExtensionAdded && ((i+1) !== lineNum)){
                layout.append(new dataviz.diagram.TextBlock({
                  text: "...",
                  fontSize:8,
                }));
                break;
            }
            if(isExtensionAdded){
                break;
            }
        }
        layout.reflow();
        return g;
    }
        // openclick(options);
    var shapeOptions = {

        id:`n-${options.noteId}`,
        x: X_points,
        y: Y_points,
        width: 90,
        height: 90,
        type: 'rectangle',
        path: options.path || undefined,
        visual: visuals,
        shapeType:'notes',
        options: options,
        fill: options.fillColor || '#fff',
        stroke: {
            color: '#008000'
        }
    };

    var shape = new kendo.dataviz.diagram.Shape(shapeOptions);
    return shape;
}

// create diagram Shapes
export function createShape(options) {
    var XY_Points = options.blockCoord.split(",");
    var X_points = parseInt(XY_Points[0], 10);
    var Y_points = parseInt(XY_Points[1], 10);
    //var textview =  options.blockName;
    var operationType = options.dataop;
    var strOperationType = "" ;


    if (operationType == null) {
        operationType = "";
    }
    //convert to log dataop name to short name
    switch (operationType) {

        case DataOpsIdentifierConstants.SORTOPS_OP:
        case DataOpsIdentifierConstants.SORT_QUICK_OP:
            strOperationType = ShortNamesConst.SORTOPS_OP;
            break;
        case DataOpsIdentifierConstants.APPEND_OP:
        case DataOpsIdentifierConstants.APPEND_QUICK_OP:
            strOperationType = ShortNamesConst.APPEND_OP;
            break;
        case DataOpsIdentifierConstants.COMPARE_OP:
            strOperationType = ShortNamesConst.COMPARE_OP;
            break;
        case DataOpsIdentifierConstants.CONTINUOUSDATE_OP:
            strOperationType = ShortNamesConst.CONTINUOUSDATE_OP;
            break;
        case DataOpsIdentifierConstants.CRITERIAOPS_OP:
            strOperationType = ShortNamesConst.CRITERIAOPS_OP;
            break;
        case DataOpsIdentifierConstants.DATAOPS_OP:
            strOperationType = ShortNamesConst.DATAOPS_OP;
            break;
        case DataOpsIdentifierConstants.DECOLLAPSE_ONETABLE_OP:
            strOperationType = ShortNamesConst.DECOLLAPSE_ONETABLE_OP;
            break;
        case DataOpsIdentifierConstants.DECOLLAPSE_TWOTABLE_OP:
            strOperationType = ShortNamesConst.DECOLLAPSE_TWOTABLE_OP;
            break;
        case DataOpsIdentifierConstants.IFELSEOPS_OP:
            strOperationType = ShortNamesConst.IFELSEOPS_OP;
            break;
        case DataOpsIdentifierConstants.JOIN_OP:
        case DataOpsIdentifierConstants.JOIN_QUICK_OP:
            strOperationType = ShortNamesConst.JOIN_OP;
            break;
        case DataOpsIdentifierConstants.LINK_OP:
            strOperationType = ShortNamesConst.LINK_OP;
            break;
        case DataOpsIdentifierConstants.PIVOT_CTR_OP:
            strOperationType = ShortNamesConst.PIVOT_CTR_OP;
            break;
        case DataOpsIdentifierConstants.PIVOT_RTC_OP:
            strOperationType = ShortNamesConst.PIVOT_RTC_OP;
            break;
        case DataOpsIdentifierConstants.REFERENCEPOINT_COMBINE_OP:
            strOperationType = ShortNamesConst.REFERENCEPOINT_COMBINE_OP;
            break;
        case DataOpsIdentifierConstants.REFERENCEPOINT_COMPOSITE_OP:
            strOperationType = ShortNamesConst.REFERENCEPOINT_COMPOSITE_OP;
            break;
        case DataOpsIdentifierConstants.REFERENCEPOINT_ONETABLE_OP:
            strOperationType = ShortNamesConst.REFERENCEPOINT_ONETABLE_OP;
            break;
        case DataOpsIdentifierConstants.REFERENCEPOINT_TWOTABLE_OP:
            strOperationType = ShortNamesConst.REFERENCEPOINT_TWOTABLE_OP;
            break;
        case DataOpsIdentifierConstants.REFERENCEPOINT_REPLACE_OP:
            strOperationType = ShortNamesConst.REFERENCEPOINT_REPLACE_OP;
            break;
        case DataOpsIdentifierConstants.TABLEOPS_RENAME_OP:
            strOperationType = ShortNamesConst.TABLEOPS_RENAME_OP;
            break;
        case DataOpsIdentifierConstants.TABLEOPS_LENGTH_ADJ_OP:
            strOperationType = ShortNamesConst.TABLEOPS_LENGTH_ADJ_OP;
            break;
        case DataOpsIdentifierConstants.REPORTINGOPS_DESCRIPTIVE_OP:
        case DataOpsIdentifierConstants.REPORTINGOPS_CATEGORICAL_OP:
        case DataOpsIdentifierConstants.REPORTINGOPS_CATEGORICAL_TOTAL_OP:
        case DataOpsIdentifierConstants.REPORTINGOPS_CATEGORICAL_HIERARCHICAL_OP:
        case DataOpsIdentifierConstants.REPORTINGOPS_CATEGORICAL_HIERARCHICAL_UNIQUE_OP:
        case DataOpsIdentifierConstants.REPORTINGOPS_CATEGORICAL_TOTAL_UNIQUE_OP:
        case DataOpsIdentifierConstants.REPORTINGOPS_CUSTOM_OP:
            strOperationType = ShortNamesConst.REPORTINGOPS_OP;
            break;
        case DataOpsIdentifierConstants.SPLIT_OP:
        case DataOpsIdentifierConstants.SPLIT_QUICK_OP:
            strOperationType = ShortNamesConst.SPLIT_OP;
            break;
        case DataOpsIdentifierConstants.TYPECONVERSION_CHARNUM_OP:
            strOperationType = ShortNamesConst.TYPECONVERSION_CHARNUM_OP;
            break;
        case DataOpsIdentifierConstants.TYPECONVERSION_DATETIME_OP:
            strOperationType = ShortNamesConst.TYPECONVERSION_DATETIME_OP;
            break;
        case DataOpsIdentifierConstants.VARMAPPING_OP:
            strOperationType = ShortNamesConst.VARMAPPING_OP;
            break;
        case DataOpsIdentifierConstants.STRINGOPS_ALIGN_OP:
            strOperationType = ShortNamesConst.STRINGOPS_ALIGN_OP;
            break;
        case DataOpsIdentifierConstants.STRINGOPS_CHANGECASE_OP:
            strOperationType = ShortNamesConst.STRINGOPS_CHANGECASE_OP;
            break;
        case DataOpsIdentifierConstants.STRINGOPS_INDUCESPACING_OP:
            strOperationType = ShortNamesConst.STRINGOPS_INDUCESPACING_OP;
            break;
        case DataOpsIdentifierConstants.STRINGOPS_OCCURENCE_OP:
            strOperationType = ShortNamesConst.STRINGOPS_OCCURENCE_OP;
            break;
        case DataOpsIdentifierConstants.STRINGOPS_SEARCHNREPLACE_OP:
            strOperationType = ShortNamesConst.STRINGOPS_SEARCHNREPLACE_OP;
            break;
        case DataOpsIdentifierConstants.STRINGOPS_SPLIT_OP:
            strOperationType = ShortNamesConst.STRINGOPS_SPLIT_OP;
            break;
        case DataOpsIdentifierConstants.STRINGOPS_TRIMCOMPRESS_OP:
            strOperationType = ShortNamesConst.STRINGOPS_TRIMCOMPRESS_OP;
            break;
        case DataOpsIdentifierConstants.PROCREPORT_ANALYSIS_CHAR_OP:
        case DataOpsIdentifierConstants.PROCREPORT_ANALYSIS_NUM_OP:
            strOperationType = ShortNamesConst.PROCREPORT_OP;
            break;
        case DataOpsIdentifierConstants.R_Report_OP:
            strOperationType = ShortNamesConst.R_Report_OP;
            break;
        case DataOpsIdentifierConstants.MAP_ANNOTATE_OP:
            strOperationType = ShortNamesConst.AUTO_OP;
            break;
        default:
            strOperationType = operationType;
            break;
    }

    var visuals = function(e){
        var dataviz = kendo.dataviz;
        var g = new dataviz.diagram.Group();
        var fontColor = null;
        if(options.blockColor === 'yellow' || options.blockColor === 'orange'){
            fontColor = '#000'
        }
        else{
            fontColor = '#fff'
        }
        g.append(new dataviz.diagram.Rectangle({

            width: 85,
            height: 85,
            stroke: {
                color: options.blockColor ||'#008000',
            },
            fill: this.isSelected ? "#aab3c1" : '#fff',
        }));


        var layout = new dataviz.diagram.Layout(new dataviz.diagram.Rect(5, 0, 85 - 13, 85), {
            alignContent: "center",
            alignItems: "center",
            justifyContent: "center",
            orientation: "vertical"
        });
        g.append(layout);
        var layout3 = new dataviz.diagram.Layout(new dataviz.diagram.Rect(5, 0, 85 - 13, 75+50), {
            alignContent: "center",
            alignItems: "center",
            justifyContent: "center",
            orientation: "vertical"
        });
        g.append(layout3);

        if(options.blockName !== "" && options.blockName !== null){
            // 2nd Line to display Process Block Name (max 15 character text)
            layout3.append(new dataviz.diagram.TextBlock({
                text: options.blockName,
                // x: 3,
                //y: 25,
                fontSize: "8",
                fill: "#000"
            }));
        }
        else{
            // 3rd Line to display Process Block ID in "(" and ")" brackets
            layout3.append(new dataviz.diagram.TextBlock({
                text: options.blockTable,
                // x: 3,
                // y: 25,
                fontSize:8,
                fill: "#000"
            }));
        }

        // 4th Line to display data operation (max 10 character text)
        if(strOperationType != null){
            layout.append(new dataviz.diagram.TextBlock({
                text: strOperationType,
                // x: 3,
                y: 25,
                fontSize:8,
                fill: "#000"
            }));
        }
        // if dataop null value
        if(options.blockCreationType === BlockCreationType.IMPORT_SOURCE_TABLE){
            layout.append(new dataviz.diagram.TextBlock({
                text: "DATA",
                // x: 3,
                y: 25,
                fontSize:8,
                fill: "#000"
            }));
        }else if(options.blockCreationType === BlockCreationType.IMPORT_META_TABLE){
            layout.append(new dataviz.diagram.TextBlock({
                text: "META DATA",
                // x: 3,
                y: 25,
                fontSize:8,
                fill: "#000"
            }));
        }

        var inputBlockLayout = new dataviz.diagram.Layout(new dataviz.diagram.Rect(1, 40, 30, 30), {
            alignContent: "start",
            alignItems: "start",
            justifyContent: "start",
            orientation: "horizontal"
        });
        var outputBlockLayout = new dataviz.diagram.Layout(new dataviz.diagram.Rect(60, 35, 30, 30), {
            alignContent: "end",
            alignItems: "end",
            justifyContent: "end",
            orientation: "horizontal"
        });
        g.append(outputBlockLayout);
        g.append(inputBlockLayout);
        if(options.functionInputBlock === 1){
            inputBlockLayout.append(new dataviz.diagram.Image({
                source: downImg,
                y:1,
                x:10,
                width: 30,
                height: 30
            }));
        }
        if(options.engineCode === 2){
            layout.append(new dataviz.diagram.Image({
                source: sasLogo,
                y:1,
                x:5,
                width: 42,
                height: 18
            }));
        }else if(options.engineCode === 3){
            layout.append(new dataviz.diagram.Image({
                source: rLogo,
                y:1,
                x:13,
                width: 24,
                height: 18
            }));
        }
        if(options.functionOutputBlock === 1){
            outputBlockLayout.append(new dataviz.diagram.Image({
                source: upImg,
                y:1,
                x:10,
                width: 30,
                height: 30
            }));
        }
        // Rectangle only Display No. of line...
        g.append(new dataviz.diagram.Rectangle({
            x: 0.5,
            y: 71,
            width: 84,
            height: 14,
            stroke: {
                width: 0
            },
            fill: {
                color: options.blockColor ||'#008000',
            }
        }));

        //layout2 is handle blockRowCount
        var layout2 = new dataviz.diagram.Layout(new dataviz.diagram.Rect(5, 72, 85 - 13, 14), {
            alignContent: "center",
            alignItems: "center",
            justifyContent: "center",
            orientation: "vertical"
        });

        let rowCount = options.blockRowCount;
        if(options.blockRowCount === -1){
            rowCount = 0;
        }
        // 5th Line to display number of row (numerical number).

        if(options.tableExists === 1){
            layout2.append(new dataviz.diagram.TextBlock({
                text: rowCount,
                // x: 3,
                // y: 75,
                fontSize:8,
                fill: fontColor
            }));
            g.append(layout2); //bind layout2 in group
        }


        //block save type = temp
        if(options.blockSaveType === BlockSaveType.PERSISTENT){
            g.append(new dataviz.diagram.Image({
                source: persistentImg,
                x: 2,
                y: 3,
                width: 16,
                height: 16
            }));
        }else{
            g.append(new dataviz.diagram.Image({
                source: tempImg,
                x: 2,
                y: 3,
                width: 16,
                height: 16
            }));
        }

        //block type = file
        if(options.blockType === BlockType.FILE){
            g.append(new dataviz.diagram.Image({
                source: fileImg,
                x: 18,
                y: 4,
                width: 14,
                height: 14
            }));
        }

        //data operation = reference point block
        if(options.dataop !== '' && options.dataop !== null && options.dataop.indexOf("Reference") !== -1){
            g.append(new dataviz.diagram.Image({
                source: referencePointImg,
                x: 32,
                y: 3,
                width: 16,
                height: 16
            }));
        }

        if(options.blockCreationType === BlockCreationType.IMPORT_SOURCE_TABLE){
            g.append(new dataviz.diagram.Image({
                source: sourceTable,
                x: 48,
                y: 3,
                width: 14,
                height: 14
            }));
        }else if(options.blockCreationType === BlockCreationType.IMPORT_META_TABLE){
            g.append(new dataviz.diagram.Image({
                source: metaTable,
                x: 48,
                y: 3,
                width: 12,
                height: 14
            }));
        }else if(options.blockCreationType === BlockCreationType.DATA_COPY){
            g.append(new dataviz.diagram.Image({
                source: copyDataImg,
                x: 48,
                y: 3,
                width: 16,
                height: 16
            }));
        }else if(options.blockCreationType === BlockCreationType.DEF_COPY) {
            g.append(new dataviz.diagram.Image({
                source: copyDataImg,
                x: 48,
                y: 3,
                width: 16,
                height: 16
            }));
        }else if(options.blockCreationType === BlockCreationType.DATA_LINK){
            g.append(new dataviz.diagram.Image({
                source: linkDataImg,
                x: 48,
                y: 3,
                width: 16,
                height: 16
            }));
        }else if(options.blockType === BlockType.TIMELINE){
            g.append(new dataviz.diagram.Image({
                source: timelineImg,
                x: 48,
                y: 3,
                width: 16,
                height: 16
            }));
        }

        if (options.blockType !== BlockType.TIMELINE) {
          if(options.blockSyncFlag === BlockStatus.VALID){
              g.append(new dataviz.diagram.Image({
                  source: validImg,
                  x: 65,
                  y: 3,
                  width: 16,
                  height: 16
              }));
          } else if(options.blockSyncFlag === BlockStatus.INVALID){
              g.append(new dataviz.diagram.Image({
                  source: invalidImg,
                  x: 65,
                  y: 3,
                  width: 16,
                  height: 16
              }));
          } else if(options.blockSyncFlag === BlockStatus.WARNING){
              g.append(new dataviz.diagram.Image({
                  source: warningImg,
                  x: 65,
                  y: 3,
                  width: 16,
                  height: 16
              }));
          } else if(options.blockSyncFlag === BlockStatus.VIRTUAL){
              g.append(new dataviz.diagram.Image({
                  source: virualImg,
                  x: 65,
                  y: 3,
                  width: 16,
                  height: 16
              }));
          }
        }

        if(options.isSampleFiltered === true) {
          g.append(new dataviz.diagram.TextBlock({
              text: String.fromCharCode(0xf0b0),
              x: 20,
              y: 8,
              fill: "#FF0000",
              fontFamily: "FontAwesome !important",
              fontSize: "15px"
          }));
        }

        g.append(new dataviz.diagram.TextBlock({
            text: "___________________",
            x: 0,
            y: 12,
            fontSize:"8",
            fill: "#000"
        }));
        inputBlockLayout.reflow();
        outputBlockLayout.reflow();
        layout.reflow();
        layout2.reflow();
        layout3.reflow();
        return g;
    }
        // openclick(options);
    var shapeOptions = {

        id: options.blockId,
        x: X_points,
        y: Y_points,
        width: options.width || 85,
        height: options.height || 85,
        type: 'rectangle',
        path: options.path || undefined,
        visual: visuals,
        // content:{
        //     text: options.blockTable
        // },
        connectionsData:connectionsData,
        blockRefStudy: options.blockRefStudy,
        blockTable: options.blockTable,
        shapeType:'blocks',
        options: options,
        fill: options.fillColor || '#fff',
        stroke: {
            color: '#008000'
        }
    };

    var shape = new kendo.dataviz.diagram.Shape(shapeOptions);
    return shape;
}

class ProccessflowView extends React.Component {

    constructor(props) {
        super(props);
        let isFlowCheckedOutByLoggedInUser = false;
        let flowCheckOut = false;
        let flow = this.props.processFlowList[this.props.activeProcessFlow];
        let userName = this.props.token_detail['email'];
        let flowCheckedOutByuser = "";
        if(typeof(flow) !== "undefined" && flow !== null && flow.process !== null ){
            if(flow.process.checkOutFlag === CheckOutCheckinFalg.CHECK_OUT_FLAG){
                flowCheckOut = true;
                flowCheckedOutByuser = flow.process.userName;
            }
            if(typeof(userName) !== "undefined" && userName !== null && flow.process.userName === userName){
                isFlowCheckedOutByLoggedInUser = true;
            }
        }
        this.state = {
            data: props.blocklist,
            selected: 0,
            itemss: [],
            breadcrumb:localStorage.getItem("breadcrumb"),
            dataTabsView: [],
            pastepopupModel:false,
            alertMsgInfo: '',
            alertMsgWarning: '',
            alertMsgError: '',
            currentStudyId:'',
            currentSchemaName:'',
            currentProcessID:'',
            key :0,
            refreshToken : props.refreshToken,
            disabledCancel:false,
            isFlowCheckedOut : flowCheckOut,
            isFlowCheckedOutByLoggedInUser : isFlowCheckedOutByLoggedInUser,
            flowCheckedOutByuser:flowCheckedOutByuser,
            exportNode:false,
            showBlockAssessmentDialog:false,
            showAddNotesDialog:false,
            notesContent:"",
            newNotes:true,
            taskListDialogStatus: false,
            filters: {status: "", userName: "", processId: 0},
            processFlowSchema: '',
            triggerNode: '',
            createTaskDialogStatus: false
        };
        this.onLoading = this.onLoading.bind(this);
        this.handlePasteOpen = this.handlePasteOpen.bind(this);
        this.handlePasteOk = this.handlePasteOk.bind(this);
	      this.handlePasteOkMultiple = this.handlePasteOkMultiple.bind(this);
        this.myAlertTop = this.myAlertTop.bind(this);
        My_this = this;
        this.resetProcessFlowStatusInTreeView = this.resetProcessFlowStatusInTreeView.bind(this);
        this.updateActiveProcessFlowTitle = this.updateActiveProcessFlowTitle.bind(this);
        this.displayProcessFlowDiagram = this.displayProcessFlowDiagram.bind(this);
        this.getProcessFlowBlocks = this.getProcessFlowBlocks.bind(this);
        this.getProcessFlowBlockLinks = this.getProcessFlowBlockLinks.bind(this);
        this.updateCurrentStudyDetails = this.updateCurrentStudyDetails.bind(this);
        this.handleClose = this.handleClose.bind(this);
        this.handleRefresh = this.handleRefresh.bind(this);
        this.updateFlowIconInTreeView = this.updateFlowIconInTreeView.bind(this);
        this.multiplePasteRef = null;
        this.handleCheckedOut = this.handleCheckedOut.bind(this);
        this.handleCheckedIn = this.handleCheckedIn.bind(this);
        this.checkInCheckOutProcessFlow = this.checkInCheckOutProcessFlow.bind(this);
        this.updateFlowCheckedOutStatus = this.updateFlowCheckedOutStatus.bind(this);
        this.toggleExportNode = this.toggleExportNode.bind(this);
        this.handleAutoArrange = this.handleAutoArrange.bind(this);
        this.handleSelect = this.handleSelect.bind(this);
        this.getWriteAcStatus = this.getWriteAcStatus.bind(this);
    }

    componentDidMount(){
      if (this.state.selected !== this.props.activeProcessFlow) {
        this.setState({ selected : this.props.activeProcessFlow })
      }
    }

    handleCreateTaskDialogClick = (event, triggerNode = '') => {
      this.setState({createTaskDialogStatus: !this.state.createTaskDialogStatus, triggerNode})
    }

    toggleExportNode(){
        this.setState({exportNode:false});
    }

    componentWillUnmount() {
      this.props.setALLProcessFlowRenderStatus(true);
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
      if(JSON.stringify(nextProps) !== '') {
        if (typeof(nextProps.treeView) !== 'undefined') {
          this.resetProcessFlowStatusInTreeView(nextProps);
          this.updateActiveProcessFlowTitle(nextProps);
          this.updateCurrentStudyDetails(nextProps);
          this.updateFlowCheckedOutStatus(nextProps);
        }

        if (nextProps.activeProcessFlow !== this.state.selected) {
          this.setState({ selected: nextProps.activeProcessFlow });
        }
        this.setState({refreshToken:nextProps.refreshToken});
      }
    }

    handleCheckedIn(){
        this.checkInCheckOutProcessFlow(CheckOutCheckinFalg.CHECK_IN_FLAG);
    }

    handleCheckedOut(){
        this.checkInCheckOutProcessFlow(CheckOutCheckinFalg.CHECK_OUT_FLAG);
    }

    handleTaskListDialog = (event, processId = 0, processFlowSchema = '') => {
      let filters = this.state.filters;
      if (processId > 0 && this.state.taskListDialogStatus === false) {
        filters.processId = processId
      } else {
        filters.processId = 0;
      }
      this.setState({taskListDialogStatus: !this.state.taskListDialogStatus, filters: filters, processFlowSchema: processFlowSchema})
    }

    checkInCheckOutProcessFlow(checkOutFlag){
        let that = this;
        let flow = this.props.processFlowList[this.props.activeProcessFlow];
        if(flow === null || flow.process === null){
            return;
        }
        axios.all([
            restClient(GET, 'processflow/checkOutCheckIn?studyId='+flow.process.studyId+'&processId='+flow.process.processId+'&checkOutFlag='+checkOutFlag, {}, {'Tenant-ID': flow.schemaName})
          ])
          .then(axios.spread((response) => {
              if (typeof(response.data) !== "undefined" && response.data !== null) {
                  if(response.data.messageType === MessageTypeConst.SUCCESS_MESSAGE){
                    if(response.data.processFlow !== null && typeof(response.data.processFlow) !== "undefined"){
                        let flow = response.data.processFlow;
                        that.props.processFlowList.forEach((processFlow, index) => {
                            if (processFlow.process.studyId === flow.studyId && processFlow.process.processId === flow.processId) {
                                that.props.updateProcessFlowCheckedOutStatusByTabIndex(index, flow.checkOutFlag, flow.userName);
                                that.updateFlowCheckedOutStatus({processFlowList:that.props.processFlowList, activeProcessFlow:that.props.activeProcessFlow});
                                that.props.setMessage(response.data.message,response.data.messageType);
                            }
                        });
                    }
                  }
                  else{
                    that.props.setMessage(response.data.message,response.data.messageType);
                  }
                  this.handleRefresh();
              }
            })).catch(error => {
                if(checkOutFlag === CheckOutCheckinFalg.CHECK_IN_FLAG){
                    that.props.setMessage("Error occurred while performing Check-In.",MessageTypeConst.ERROR_MESSAGE);
                }else{
                    that.props.setMessage("Error occurred while performing Check-Out.",MessageTypeConst.ERROR_MESSAGE);
                }
            });
    }

    resetProcessFlowStatusInTreeView = ({treeView, processFlowList}) => {
        treeView.children.forEach((project) => {
          project.children.forEach((study) => {
            study.children.forEach((studyElement) => {
              if (studyElement.name === 'ProcessFlows') {
                studyElement.children.forEach((classifier) => {
                  classifier.children.forEach((processFlowGroup) => {
                    processFlowGroup.children.map((process) => {
                      let processFlow = processFlowList.filter( activeProcessFlow => activeProcessFlow.process.processId === process.process.processId && activeProcessFlow.process.studyId === process.process.studyId );
                      if (processFlow.length === 0) {
                        process.activeProcess = false;
                      }

                      return process;
                    })
                  })
                })
              }
            })

          })
        });

        this.props.setTreeViewData(treeView);

    }

    updateActiveProcessFlowTitle = ({processFlowList, processFlowTitle}) => {
      processFlowList.map((processFlow) => {
        let title = processFlowTitle.filter((title) => title.schemaName === processFlow.schemaName && title.processId === processFlow.process.processId );

        if (title.length > 0) {
          processFlow.title = title[0].title;
        }

        return processFlow;
      })
    }


    updateCurrentStudyDetails = ({processFlowList, activeProcessFlow}) => {
        let processFlow = processFlowList[activeProcessFlow];
        if(processFlow  !== null && typeof(processFlow) !== "undefined"){
            this.setState({currentStudyId:processFlow.process.studyId,currentSchemaName:processFlow.schemaName,currentProcessID:processFlow.process.processId});

        }
    }

    updateFlowCheckedOutStatus = ({processFlowList, activeProcessFlow}) =>{
        let isFlowCheckedOutByLoggedInUser = false;
        let flowCheckOut = false;
        let flow = processFlowList[activeProcessFlow];
        let userName = this.props.token_detail['email'];
        let flowCheckedOutByuser = "";
        if(typeof(flow) !== "undefined" && flow !== null && flow.process !== null ){
            if(flow.process.checkOutFlag === CheckOutCheckinFalg.CHECK_OUT_FLAG){
                flowCheckOut = true;
                flowCheckedOutByuser=flow.process.userName;
            }
            if(typeof(userName) !== "undefined" && userName !== null && flow.process.userName === userName){
                isFlowCheckedOutByLoggedInUser = true;
            }
        }

        this.setState({isFlowCheckedOut : flowCheckOut, isFlowCheckedOutByLoggedInUser : isFlowCheckedOutByLoggedInUser,flowCheckedOutByuser:flowCheckedOutByuser})
    }

    getWriteAcStatus = (schemaName) => {
      let isWriteAC = true;
      this.props.treeView.children.filter(project => {
        project.children.filter(study => study.study.schemaName === schemaName).forEach(study => {
          isWriteAC = study.study.enableActions.indexOf(AccessControlConstants.WRITE) > -1
        })
      })

      return isWriteAC;
    }

    displayProcessFlowDiagram = (element, item, index) => {
      const { process } = item;

      if (item.blockRender && this.state.selected === index) {
        setTimeout(()=>{
            handleBlocksView(element, process.processBlock, process.processBlockLink, process.studyId, item.schemaName, process.processId, this, process.processFlowNotes);
            this.props.updateProcessflowBlockRenderStatus(index, false);
            item.blockRender = false;
            this.props.setTenantId(item.schemaName);
            this.setState({currentStudyId:process.studyId,currentSchemaName:item.schemaName,currentProcessID:process.processId});

        },1)
      }

      $(".loader").hide();

      //
    }

    getProcessFlowBlocks = (tabIndex = null) => {
      tabIndex = tabIndex === null ? this.state.selected : tabIndex;

      if (typeof(this.props.processFlowList[tabIndex]) !== 'undefined') {
          return this.props.processFlowList[tabIndex].process.processBlock;
      }

      return [];
    }

    getProcessFlowBlockLinks = (tabIndex = null) => {
        tabIndex = tabIndex === null ? this.state.selected : tabIndex;

        if (typeof(this.props.processFlowList[tabIndex]) !== 'undefined') {
            return this.props.processFlowList[tabIndex].process.processBlockLink;
        }

        return [];
      }

    handleKey = e => {
        if(e.key === "Delete" ){
            //delete key pressed
            handleDelete();
            return;
        }
        CopyPaste(e, this);
    }
    onLoading(){
/*
        var viewTabArrMainIInt = JSON.parse(localStorage.getItem("processBlockListMain"));
        var viewTabArrMain=[];
         // var grades = {};
         // viewTabArrMainIInt.forEach( function( item ) {
         //     var grade = grades[item.Grade] = grades[item.Grade] || {};
         //     grade[item.Domain] = true;
         // });

         if(viewTabArrMainIInt != null && viewTabArrMainIInt !=undefined && viewTabArrMainIInt !="undefined")
          viewTabArrMain = unique(viewTabArrMainIInt);

         // var comIndex=[];
         // if(viewTabArrMainIInt != null && viewTabArrMainIInt !=undefined)
         // {
         //     for(var c=0;c<viewTabArrMainIInt.length;c++){
         //         if($.inArray(viewTabArrMainIInt[c].processFlowId,comIndex)==-1 )
         //         {
         //             comIndex.push(viewTabArrMainIInt[c].processFlowId);
         //             viewTabArrMain.push(viewTabArrMainIInt[c]);
         //         }

         //     }
         // }
         if(viewTabArrMain == null){
             this.setState({
                 itemss: null
             })
         }
        else{
            var viewTabArrOpen =[];
            var viewTabArrOpenFinal =[];
            $(".loader").show();
            for(var i=0; i<viewTabArrMain.length; i++){
                var tempsv = "";
                var temps = viewTabArrMain[i];
                // var totalProcessBlock = temps.BlocksList.length;
                tempsv = { title: elementTitle(temps.TabName, this.handleClose, temps.studyId, i),"tabvalue": temps.TabName, "processFlowId":temps.processFlowId, studyId:temps.studyId,studyName:temps.studyName, content: temps.BlocksList, totalBlock: 0 ,"id":i}

                viewTabArrOpen.push(tempsv);
                var studyId = temps.studyId;
                var studyName = temps.studyName;
                var processFlowId = temps.processFlowId;
                dataTabs = temps.BlocksList;
                // this.setState({
                //     selected: i
                // })
            }
            var arrayIndexForSeletedTab=0;

            var deletedTab = localStorage.getItem("deletedTab");
            if(deletedTab == 0){
                $(".loader").hide();
            }
            if(deletedTab==-1 || deletedTab == undefined || deletedTab ==null)
            {
                for(var c=0;c<viewTabArrOpen.length;c++){
                    if(viewTabArrOpen[c].processFlowId==parseInt(localStorage.getItem("selectedTab")))
                    {
                        if(viewTabArrOpen[c].studyId == parseInt(localStorage.getItem("selectedTabStudy")) )
                            {
                            arrayIndexForSeletedTab=c;
                            this.setState({
                                selected: arrayIndexForSeletedTab
                            })
                            // this.handleSelect();
                            break;
                        }
                    }
                }
                $(".loader").hide();

            }
            else{

                if(deletedTab==0)
                {
                    arrayIndexForSeletedTab=0;
                }
                else{
                    arrayIndexForSeletedTab=deletedTab-1;
                }
                if(viewTabArrMain != null && viewTabArrMain !=undefined && viewTabArrMain !="undefined" && viewTabArrMain.length !=0)
                {
                    studyId = viewTabArrMain[arrayIndexForSeletedTab].studyId;
                    studyName = viewTabArrMain[arrayIndexForSeletedTab].studyName;
                    processFlowId = viewTabArrMain[arrayIndexForSeletedTab].processFlowId;
                }


                this.setState({
                    selected: arrayIndexForSeletedTab
                })

            }

            this.setState({
                itemss: viewTabArrOpen
            })




        }

        var that = this;
        $('document').ready(function () {

            if (typeof(studyId) !== 'undefined') {
                if(localStorage.getItem("functionalFlag")=="1" )
                {
                    that.onTreeProcessSelected(arrayIndexForSeletedTab);
                    localStorage.setItem("functionalFlag","2");

                }
                else{
                    setTimeout(() => {
                        handleBlocksWithLink(dataTabs, studyId, studyName, processFlowId, that);
                    }, 200);

                }

            }

        });*/
    }

    /*onTreeProcessSelected(seletedIndex)
    {
        // this.setState({selected: e.selected});
         var tabshow = "";
        tabshow = seletedIndex;
        var contentClick = JSON.parse(localStorage.getItem("processBlockListMain"));
        contentClick = unique(contentClick);
        var tebnotes = null;
        var studyId = null;
        var studyId_current =null;
        var studyName_current = null;
        var processFlowId_current = null;
        if(contentClick != undefined && contentClick != null && contentClick!="")
        {
            for(var i=0; i<contentClick.length; i++){
                var temps = contentClick[i];
                var TabName = temps.TabName;
                    studyId = temps.studyId;
                var blocksNameList = temps.BlocksList;
                if(i == tabshow){
                    tebnotes = blocksNameList;
                    studyId_current = temps.studyId;
                    studyName_current = temps.studyName;
                    processFlowId_current = temps.processFlowId;
                }
            }
        }


        var that = this;
        $('document').ready(function () {
            if (typeof(studyId_current) !== 'undefined') {
                handleBlocksWithLink(tebnotes, studyId_current, studyName_current, processFlowId_current, that);
            }

        });
    }*/

    handleSelect = (e) => {
        this.setState({selected: e.selected});
        this.props.setActiveProcessFlow(e.selected);
        if(this.props.jumpToBlockId !== 0){
            if(typeof(flowDiagram) !== "undefined" && flowDiagram !== null){
                flowDiagram.select(null);
            }
            this.props.setJumpToBlockIdProcessId(0,0);
        }
        let processFlow = this.props.processFlowList[e.selected];
        if(typeof(processFlow) !== "undefined" && processFlow !== null){
            flowDiagram = $(`#process-flow-diagram-${processFlow.process.processId}-${e.selected}-${processFlow.schemaName}`).getKendoDiagram();
        }
    }

    handleClose = (e, studyId) => {
        let selected = this.state.selected;
        if((this.props.processFlowList.length-1) === this.props.activeProcessFlow && this.props.activeProcessFlow !== 0){
            selected = this.props.activeProcessFlow - 1;

            this.setState({selected: selected});
            this.props.setActiveProcessFlow(selected);
            let processFlow = this.props.processFlowList[selected];
            if(typeof(processFlow) !== "undefined" && processFlow !== null){
                flowDiagram = $(`#process-flow-diagram-${processFlow.process.processId}-${selected}-${processFlow.schemaName}`).getKendoDiagram();
            }
        }

        this.props.removeProcessFlow(parseInt(e.target.id,10), studyId);
        this.props.updateProcessflowBlockRenderStatus(selected, true);
        if(this.props.jumpToBlockId !== 0){
            this.props.setJumpToBlockIdProcessId(0,0);
        }
        this.componentWillUnmount();

    }
    handleDiagrams(e){
        //find X and Y coordinates...
        X = e.clientX - 200;
        Y = e.clientY - 200;
        if(flowDiagram !== null && typeof(flowDiagram) !== "undefined"){
            var point = flowDiagram.documentToModel(new kendo.dataviz.diagram.Point(e.pageX, e.pageY));
            global.blockXY = point.x.toFixed()+","+point.y.toFixed();
            X = point.x.toFixed();
            Y = point.y.toFixed();
        }
    }
    handlePasteOpen(diagram){
        flowDiagram = diagram;
        if(typeof(this.props.selectsItems) !== "undefined" && this.props.selectsItems.length === 1){
            if( this.props.selectsItems[0].blockCreationType === BlockCreationType.MAP_ANNOTATE){
                    kendo.alert("Map-Annotate block can't be copied.");
                return;
            }
            if( this.props.selectsItems[0].blockCreationType === BlockCreationType.TIMELINE){
                kendo.alert("Timeline block can't be copied.");
            return;
            }
            if((this.state.currentProcessID === this.props.selectsItems[0].processId &&
                this.state.currentStudyId === this.props.selectsItems[0].studyId )&&
            ( this.props.selectsItems[0].blockCreationType === BlockCreationType.IMPORT_META_TABLE ||
                this.props.selectsItems[0].blockCreationType === BlockCreationType.IMPORT_SOURCE_TABLE ||
                this.props.selectsItems[0].blockCreationType === BlockCreationType.DATA_COPY ||
                this.props.selectsItems[0].blockCreationType === BlockCreationType.DATA_LINK)){
                    kendo.alert("Data block can't be copied in same process flow.");
                return;
            }
            if((this.props.selectsItems[0].blockCreationType === BlockCreationType.IMPORT_META_TABLE ||
                this.props.selectsItems[0].blockCreationType === BlockCreationType.IMPORT_SOURCE_TABLE ||
                this.props.selectsItems[0].blockCreationType === BlockCreationType.DATA_COPY ||
                this.props.selectsItems[0].blockCreationType === BlockCreationType.DATA_LINK ) && this.props.selectsItems[0].tableExists === 0){
                    kendo.alert("Table does not exist for copied block.");
                    return;
                }
            if(this.props.selectsItems[0].blockCreationType === BlockCreationType.IMPORT_SOURCE_DOCUMENT){
                kendo.alert("Document block can not be copied.");
                return;
            }
        }
        this.setState({
            pastepopupModel: !this.state.pastepopupModel
        })
    }

    handlePasteOkMultiple(event){
        event.preventDefault();
        let options = this.multiplePasteRef.getWrappedInstance().getPasteData();
        if(options !== null && options.length === 0){
            return;
        }
        event.target.disabled = true;
        let buttonTarget = event.target;
        this.setState({
            disabledCancel:true
        },()=>{
        if(options !== null && options.length > 0){
            var that=this;
            $(".loader").show();
            var params = [];
            for (var i = 0; i < options.length; i++) {
                params.push({
                    "blockCreationType":options[i].copyAs.value,
                    "newBlockName": options[i].destinationBlock,
                    "blockSaveType":options[i].copyAs.value,
                    "xCordinate":X,
                    "yCordinate":Y,
                    "processBlock":options[i].item ,
                    "destStudyName": that.state.currentSchemaName,
                    "destStudyId": that.state.currentStudyId,
                    "destProcessFlowId": that.state.currentProcessID
                });
            }

            axios.all([restClient(CREATE, 'processflow/paste', {data : params}, {'Tenant-ID': that.state.currentSchemaName})])
            .then(axios.spread((response) => {
                $(".loader").show();
                if(response.data !== null && typeof(response.data) !== "undefined" && typeof(response.data.processBlock) !== 'undefined' && response.data.processBlock.length > 0){
                    let processFlowStatus = [];
                    if(typeof(response.data.processBlock) !== "undefined" && response.data.processBlock !== null &&
                        response.data.processBlock.length !== 0)
                    {
                        response.data.processBlock.forEach((block) => {
                            that.props.addProcessFlowBlockByTabIndex(that.state.selected, block);
                            flowDiagram.addShape(createShape(block));
                        });
                    }

                    if(typeof(response.data.processBlockLink) !== "undefined" && response.data.processBlockLink !== null &&
                    response.data.processBlockLink.length !== 0){
                        response.data.processBlockLink.forEach((link) => {
                            that.props.addProcessFlowBlockLinkByTabIndex(that.state.selected, link);
                            var sourceShapeX = link.blockId1;
                            var targetShapeY = link.blockId2;
                            var sourceShape = flowDiagram.getShapeById(sourceShapeX);
                            var targetShape = flowDiagram.getShapeById(targetShapeY);
                            flowDiagram.connect(sourceShape, targetShape);
                        });
                    }
                    if(response.data.processFlow !== null && typeof(response.data.processFlow) !== "undefined"
                                            && response.data.processFlow.length > 0){
                    response.data.processFlow.forEach((flow) => {
                        if(processFlowStatus.length > 0){
                            let existingFlow = processFlowStatus.filter(item => item.processId === flow.processId && item.processSyncFlag === flow.processSyncFlag);
                            if(existingFlow.length <= 0){
                                processFlowStatus.push({processId:flow.processId,processSyncFlag:flow.processSyncFlag, studyId: flow.studyId});
                            }
                        }else{
                            processFlowStatus.push({processId:flow.processId,processSyncFlag:flow.processSyncFlag, studyId: flow.studyId});
                        }
                        });
                        that.props.processFlowList.forEach((processFlow, index) => {
                            response.data.processFlow.forEach((flow) => {
                            if (processFlow.process.studyId === flow.studyId && processFlow.process.processId === flow.processId) {
                                that.props.updateProcessFlowSyncFlagByTabIndex(index, flow.processSyncFlag);
                            }});
                            });
                    }
                    that.updateFlowIconInTreeView(processFlowStatus);

                    //that.props.updateProcessflowBlockRenderStatus(that.state.selected, true);
                    //handleBlocksWithLink(dataTabs, that.state.currentStudyId, that.state.currentSchemaName, that.state.currentProcessID, that);

                    that.props.setMessage("PROCESS BLOCK(s) have been pasted Successfully!", MessageTypeConst.SUCCESS_MESSAGE);
                    that.handlePasteOpen(flowDiagram);

                } else if (typeof(response.data.message) !== 'undefined' && typeof(response.data.messageType) !== 'undefined' && response.data.messageType === MessageTypeConst.WARNING_MESSAGE) {
                  that.props.setMessage(response.data.message, MessageTypeConst.WARNING_MESSAGE);
                }
                else{
                    that.props.setMessage("PROCESS BLOCK paste operation Failed!", MessageTypeConst.ERROR_MESSAGE);
                }
                that.setState({
                    disabledCancel:false
                });
                buttonTarget.disabled = false;
                $(".loader").hide();
            })).catch(error => {
                buttonTarget.disabled = false;
                $(".loader").hide();
                that.setState({
                    pastepopupModel: false,
                    disabledCancel:false
                })
                that.props.setMessage("PROCESS BLOCK paste operation Failed!", MessageTypeConst.ERROR_MESSAGE);
              });

            for (var i = 0; i < options.length; i++) {
                data.push(options[i].item);
            }
        }
        });

    }

    handlePasteOk(event)
    {
        event.preventDefault();
        var that=this;
        var blockNametemp=$("#txtblockname").val();
        if(that.props.selectsItems !== undefined && that.props.selectsItems !== null && that.props.selectsItems !== "" && that.props.selectsItems.length > 0)
        {
            var finalParse = that.props.selectsItems[0];
            if(finalParse.blockSaveType === 1 && blockNametemp.trim() !== ""){
                let pattern = new RegExp("^([a-z])+[0-9a-z_]*$","ig");
                if(!pattern.test(blockNametemp.trim())){
                  that.props.setMessage("Persistent block name should begin with character and it should contain only character, underscore and numbers.", MessageTypeConst.WARNING_MESSAGE);
                  return;
                }
              }
        }
        event.target.disabled = true;
        let buttonTarget = event.target;
        this.setState({
            disabledCancel:true
        },()=>{
        $(".loader").show();
        var optionType=$('input[name=optradio]:checked').val();
        if(that.props.selectsItems !== undefined && that.props.selectsItems !== null && that.props.selectsItems !== "")
        {
            var finalParse = that.props.selectsItems
            var params = [];
            for (var i = 0; i < finalParse.length; i++) {
                params.push({
                    "blockCreationType":optionType,
                    "newBlockName": blockNametemp.trim(),
                    "blockSaveType":optionType,
                    "xCordinate":X,
                    "yCordinate":Y,
                    "processBlock":finalParse[i] ,
                    "destStudyName": that.state.currentSchemaName,
                    "destStudyId": that.state.currentStudyId,
                    "destProcessFlowId": that.state.currentProcessID
                });

                axios.all([restClient(CREATE, 'processflow/paste', {data : params}, {'Tenant-ID': that.props.tenantId})])
                .then(axios.spread((response) => {
                    if(typeof(response.data.processBlock) !== "undefined" && response.data.processBlock !== null &&
                    response.data.processBlock.length > 0)
                    {
                        let processFlowStatus = [];
                        that.props.addProcessFlowBlockByTabIndex(that.state.selected, response.data.processBlock[0]);
                        that.props.updateProcessflowBlockRenderStatus(that.state.selected, true);
                        if(response.data.processFlow !== null && typeof(response.data.processFlow) !== "undefined"
                                            && response.data.processFlow.length > 0){
                            response.data.processFlow.forEach((flow) => {
                                processFlowStatus.push({processId:flow.processId,processSyncFlag:flow.processSyncFlag, studyId: flow.studyId});
                                });
                            that.props.processFlowList.forEach((processFlow, index) => {
                                response.data.processFlow.forEach((flow) => {
                                if (processFlow.process.studyId === flow.studyId && processFlow.process.processId === flow.processId) {
                                    that.props.updateProcessFlowSyncFlagByTabIndex(index, flow.processSyncFlag);
                                    that.props.updateProcessflowBlockRenderStatus(index, true);
                                }});
                            });
                        }
                        that.updateFlowIconInTreeView(processFlowStatus);
                        flowDiagram.addShape(createShape(response.data.processBlock[0]));

                        //handleBlocksWithLink(dataTabs, that.state.currentStudyId, that.state.currentSchemaName, that.state.currentProcessID, that);

                        that.props.setMessage("PROCESS BLOCK(s) have been pasted Successfully!", MessageTypeConst.SUCCESS_MESSAGE);
                        that.handlePasteOpen(flowDiagram);

                    } else if (typeof(response.data.message) !== 'undefined' && typeof(response.data.messageType) !== 'undefined' && response.data.messageType === MessageTypeConst.WARNING_MESSAGE) {
                      that.props.setMessage(response.data.message, MessageTypeConst.WARNING_MESSAGE);
                    } else{
                        that.props.setMessage("PROCESS BLOCK paste operation Failed!", MessageTypeConst.ERROR_MESSAGE);
                    }
                    that.setState({
                        disabledCancel:false
                    });
                    buttonTarget.disabled = false;
                    $(".loader").hide();
                })).catch(error => {
                    $(".loader").hide();
                    buttonTarget.disabled = false;
                    that.setState({
                        pastepopupModel: false,
                        disabledCancel:false
                    })
                    that.props.setMessage("PROCESS BLOCK paste operation Failed!", MessageTypeConst.ERROR_MESSAGE);
                  });
                data.push(finalParse[i].options);
            }
        }
        else{
            that.handlePasteOpen(flowDiagram);
            $(".loader").hide();
        }
    });
    }

    myAlertTop(alertMsgType){
        if(alertMsgType === "success"){
            $(".myAlertInfo-top").show();
            setTimeout(function(){
              $(".myAlertInfo-top").hide();
            }, 3000);
        }
        else if(alertMsgType === "warning") {
            $(".myAlertWarrning-top").show();
            setTimeout(function(){
                $(".myAlertWarrning-top").hide();
            }, 3000);
        }
        else{
            $(".myAlertError-top").show();
            setTimeout(function(){
                $(".myAlertError-top").hide();
            }, 3000);
        }
    }

    handleAutoArrange(){
        let that = this;
        let flow = this.props.processFlowList[this.props.activeProcessFlow];
        if(flow === null || flow.process === null || typeof(flow.process) === "undefined"){
            return;
        }
        if(typeof(flow.process.processBlock) !== "undefined" && flow.process.processBlock !== null && flow.process.processBlock.length === 0){
            that.props.setMessage("Not able to perform auto arrange. As flow does not contain any blocks.", MessageTypeConst.WARNING_MESSAGE);
            return;
        }

        ConfirmBox.open("Are you sure you want to auto arrange? The operation is irreversible.").then(function(){
        $(".loader").show();
        axios.all([
            restClient(GET, 'processblock/autoarrange?studyId='+flow.process.studyId+'&processId='+flow.process.processId, {}, {'Tenant-ID': that.props.tenantId})
          ])
          .then(axios.spread((response) => {
              if (typeof(response.data) !== 'undefined' && response.data != null) {
                if (typeof(response.data) !== 'undefined' && typeof(response.data.messageType) !== 'undefined' && response.data.messageType !== null && response.data.messageType ===  MessageTypeConst.WARNING_MESSAGE) {
                    that.props.setMessage(response.data.message, response.data.messageType);
                    that.setState({key:that.state.key+1});
                    $(".loader").hide();
                    return;
                }
                    response.data.forEach((processblock) => {
                        flow.process.processBlock.forEach((block, blockIndex) => {
                            if (processblock.blockId === block.blockId) {
                                that.props.updateProcessFlowBlockByTabIndex(that.props.activeProcessFlow, blockIndex, processblock);
                            }
                        });
                    });
                }
                that.props.updateProcessflowBlockRenderStatus(that.props.activeProcessFlow, true);
                that.setState({key:that.state.key+1});
              $(".loader").hide();
            })
          ).catch(error=>{
            $(".loader").hide();
            that.props.setMessage("Error occurred while auto arranging blocks.", MessageTypeConst.ERROR_MESSAGE);
          });
        });
    }

    handleRefresh(){
        $(".loader").show();
        this.props.refreshProcessFlow(this.props.processFlowList[this.state.selected]);
        this.props.updateProcessflowBlockRenderStatus(this.state.selected, true);
        setTimeout(()=>{
            this.setState({key:this.state.key+1});
            $(".loader").hide();
        },2000);
    }

    handleSyncClick = (event) =>{
        this.setState({showBlockAssessmentDialog:true});
    }

    closeBlockAssessmentDialog = () => {
        this.setState({showBlockAssessmentDialog:false});
    }

    openAddNotesDialog = () => {
        this.setState({showAddNotesDialog:true, notesContent:"",newNotes:true});
    }

    closeAddNotesDialog = () => {
        this.setState({showAddNotesDialog:false, notesContent:"", newNotes:true});
    }

    enterKeyPressForAddNotes = (e) => {
        if(e.keyCode === 13){
          //enter key pressed
          //this.handleAddNotesOKButton(e);
        }
        if(e.keyCode === 27){
          //escape key pressed
          this.closeAddNotesDialog();
        }
    }

    handleAddNotesChange = (event) => {
      this.setState({ notesContent: event });
    }

    handleAddNotesOKButton = (e) => {
        if(this.state.notesContent === null || this.state.notesContent.trim() === ""){
            this.props.setMessage("Notes can't be blank.", MessageTypeConst.WARNING_MESSAGE);
            return;
        }

        let selectedProcessFlow =  this.props.processFlowList[this.props.activeProcessFlow];
        if(selectedProcessFlow !== null && typeof(selectedProcessFlow) !== "undefined"){
            let notes = {
                noteId :0,
                studyId:selectedProcessFlow.process.studyId,
                processId:selectedProcessFlow.process.processId,
                data:this.state.notesContent,
                noteCoord:global.blockXY
            }
            if(!this.state.newNotes){
                let selectedNotes  = selectedProcessFlow.process.processFlowNotes.filter(item => item.noteId === parseInt(this.props.processFlowTableId,10));
                if(selectedNotes.length > 0){
                    notes = Object.assign({},selectedNotes[0]);
                }
                notes.data = this.state.notesContent;
            }
            let that = this;
            $(".loader").show();
            axios.all([restClient(CREATE, 'note/add', {data:notes}, {'Tenant-ID': this.state.currentSchemaName})])
            .then(axios.spread((response) => {
                    if (typeof(response.data) !== 'undefined') {
                        if (typeof(response.data.messageType) !== 'undefined' && response.data.messageType !== null && response.data.messageType === MessageTypeConst.WARNING_MESSAGE){
                            that.props.setMessage(response.data.message, response.data.messageType);
                            that.setState({key:that.state.key+1});
                            return true;
                        }
                        if(!that.state.newNotes){
                            that.props.processFlowList.forEach((processFlow, index) => {
                                if (processFlow.schemaName === that.state.currentSchemaName) {
                                processFlow.process.processFlowNotes.forEach((note, noteIndex) => {
                                    if (response.data.noteId === note.noteId) {
                                        that.props.updateProcessFlowNoteByTabIndex(index, noteIndex, response.data);
                                        let shape = flowDiagram.getShapeById("n-"+response.data.noteId);
                                        if(shape !== null && typeof(shape) !== 'undefined' && shape.options.shapeType === "notes"){
                                            flowDiagram.remove(shape);
                                        }
                                        flowDiagram.addShape(createNotes(response.data));
                                        that.setState({key:that.state.key+1});
                                        return true;
                                        }
                                })
                                }
                            })
                            that.props.setMessage("Notes have been updated Successfully!", MessageTypeConst.SUCCESS_MESSAGE);
                        }else{
                            that.props.addProcessFlowNoteByTabIndex(that.state.selected, response.data);
                            flowDiagram.addShape(createNotes(response.data));
                            that.setState({key:that.state.key+1});
                            that.props.setMessage("Notes have been created Successfully!", MessageTypeConst.SUCCESS_MESSAGE);
                        }
                    }
                    else{
                        that.props.setMessage("Failed to add Notes!", MessageTypeConst.ERROR_MESSAGE);
                    }
                    $(".loader").hide();
                    that.closeAddNotesDialog();
                })
            ).catch(error => {
                $(".loader").hide();
                that.closeAddNotesDialog();
                that.props.setMessage("Failed to add Notes", MessageTypeConst.ERROR_MESSAGE);
            });
        }
    }

    editNotes = (e) => {
        let selectedProcessFlow =  this.props.processFlowList[this.props.activeProcessFlow];
        if(selectedProcessFlow !== null && typeof(selectedProcessFlow) !== "undefined"){
            let notes  = selectedProcessFlow.process.processFlowNotes.filter(item => item.noteId === parseInt(this.props.processFlowTableId,10));
            if(notes.length > 0){
                this.setState({ notesContent: notes[0].data, showAddNotesDialog : true, newNotes:false });
            }
        }
    }

    deleteNotes = (e) => {
        let that = this;
        let selectedProcessFlow =  this.props.processFlowList[this.props.activeProcessFlow];
        if(selectedProcessFlow !== null && typeof(selectedProcessFlow) !== "undefined"){
            let selectedNotes  = selectedProcessFlow.process.processFlowNotes.filter(item => item.noteId === parseInt(this.props.processFlowTableId,10));
            if(selectedNotes.length > 0){
                ConfirmBox.open("Are you sure you want to delete notes ?").then(function () {
                    $(".loader").show();

                    axios.all([restClient(DELETE, 'note/delete', {id: parseInt(that.props.processFlowTableId,10)}, {'Tenant-ID': that.state.currentSchemaName})])
                    .then(axios.spread((response) => {
                      $(".loader").hide();
                      if(response.data.messageType !== MessageTypeConst.SUCCESS_MESSAGE){
                        that.props.setMessage(response.data.message, response.data.messageType);
                      } else{
                        that.props.removeProcessFlowNoteByTabIndex(that.state.selected, parseInt(selectedNotes[0].noteId, 10));
                        let shape = flowDiagram.getShapeById("n-"+selectedNotes[0].noteId);
                        if(shape !== null && typeof(shape) !== 'undefined' && shape.options.shapeType === "notes"){
                            flowDiagram.remove(shape);
                        }
                        that.setState({key:that.state.key+1});
                        that.props.setMessage(response.data.message, MessageTypeConst.SUCCESS_MESSAGE);
                      }
                    })).catch(error => {
                        $(".loader").hide();
                        that.props.setMessage("Process Notes deletion Failed!", MessageTypeConst.ERROR_MESSAGE);
                      });
                });
            }
        }
    }

    render(){
        return(
            <div className="processflow-scroll">
                {/* <span className="breadcrumb" id="breadcrumb">Root / {this.state.breadcrumb}</span> */}
                <div className="processflow-tab-outer">
                  <div className="tabstrip-top">
                    {
                        this.props.processFlowList != null ? (
                            <TabStrip keepTabsMounted={true} selected={this.state.selected} onSelect={this.handleSelect} >
                                {this.props.processFlowList.map((item, index) => {
                                    let title = item.title;
                                    let itemList = [];
                                    this.props.treeView.children.filter(project => project.projectId === item.projectId).forEach(project => {
                                      project.children.filter(study => study.study.studyId === item.process.studyId).forEach(study => {
                                        title = study.name+':'+item.name
                                        itemList.push({iconClass: "fa fa-briefcase", name: project.name})
                                        itemList.push({iconClass: "fa fa-tag", name: study.study.classifier})
                                        itemList.push({iconClass: "fa fa-folder-open", name: study.name})
                                        if (typeof(item.process) !== 'undefined') {
                                          study.children.filter(studyElement => studyElement.name === 'ProcessFlows').forEach((studyElement) => {
                                            studyElement.children.forEach((classifier) => {
                                              classifier.children.forEach((processGroup) => {
                                                  if (processGroup.processGroupId === item.process.processGroupId) {
                                                    itemList.push({iconClass: "fa fa-object-group", name: processGroup.name})
                                                  }
                                                })
                                              })
                                          })
                                        }
                                        itemList.push({iconClass: "fa fa-microchip", name: item.name})
                                      })
                                    })
                                    return (
                                        <TabStripTab key={`${index}-${item.title}`} title={elementTitle( title, this.handleClose, item.process.studyId, item.process.processId )} >
                                            {/* <span className="total-pf-block">Total number of process blocks :<span >&nbsp;<span id="totalBlock">{item.totalBlock}</span> </span></span><br /> */}
                                            <Breadcrumb itemList={itemList}/>
                                            <div>
                                            <Button iconClass={this.state.isFlowCheckedOut ? 'fa fa-sign-in toolBarCheckInButton newFontSize p-r-5' : 'fa fa-sign-in toolBarCheckInButton newFontSizeVisible p-r-5' }
                                            id="btnCheckedOut" title="Check-Out" onClick={this.handleCheckedOut} disabled={this.state.isFlowCheckedOut || !this.getWriteAcStatus(item.schemaName)} className={'m-r-16'}>
                                            Check-Out
                                            </Button>
                                            <Button iconClass={this.state.isFlowCheckedOutByLoggedInUser && this.state.isFlowCheckedOut ? 'fa fa-sign-out toolBarButton newFontSizeVisible p-r-5' :'fa fa-sign-out toolBarButton newFontSize p-r-5'}
                                            id="btnCheckedIn" title="Check-In" onClick={this.handleCheckedIn} disabled={!(this.state.isFlowCheckedOutByLoggedInUser && this.state.isFlowCheckedOut)} className={'m-r-16'}>
                                            Check-In
                                            </Button>
                                            {
                                                item.process.isCopy ?
                                                <Button className={'m-r-16'} id="btnSync"  title="Sync"
                                                 onClick={this.handleSyncClick} iconClass="fa fa-random toolBarSyncButton newFontSizeVisible p-r-5"> Flow Compare</Button>
                                                : ""
                                                }
                                            <Button className="m-r-0" title="Task Management" onClick={event => this.handleTaskListDialog(event, item.process.processId, item.schemaName)}>Task Management</Button>
                                            {/*<span className='newFontSize'>&nbsp;&nbsp;|</span>
                                            <span className='fa fa-database newFontSize ' id="spanData" data-toggle="tooltip" title="Data"></span>
                                            <span className='fa fa-code newFontSize' id="spanCode" data-toggle="tooltip" title="Code"></span>
                                            <span className='fa fa-file-zip-o newFontSize'  id="spanLog" data-toggle="tooltip" title="Log"></span>
                                            <span className='fa fa-newspaper-o newFontSize'  id="spanEA" data-toggle="tooltip" title="Extended Attribute"></span>
                                            <span className='fa fa-archive newFontSize' id="spanProperty" data-toggle="tooltip" title="Property">&nbsp;&nbsp;|</span>
                                            <span className='fa fa-copyright newFontSize' id="spanCopy" data-toggle="tooltip" title="Copy"></span>
                                            <span className='fa fa-file-powerpoint-o newFontSize'  id="spanPaste" data-toggle="tooltip" title="Paste"></span>
                                            <span className='fa fa-crop newFontSize' id="spanDelete"  data-toggle="tooltip" title="Delete"></span>
                                            <span className='fa fa-edit newFontSize'  id="spanUpdate" data-toggle="tooltip" title="Update">&nbsp;&nbsp;|</span>
                                            <span className='fa fa-plus newFontSize'  id="spanAddLink" data-toggle="tooltip" title="Add Link"></span>
                                            <span className='fa fa-times newFontSize' id="spanDeleteLink"  data-toggle="tooltip" title="Delete Link">&nbsp;&nbsp;|</span>
                                            <span className='fa fa-cloud-download newFontSize' id="spanExport"  data-toggle="tooltip" title="Export">&nbsp;&nbsp;|</span>
                                            <span className='fa fa-sitemap newFontSize' id="spanTP"  data-toggle="tooltip" title="Timeline Profile">&nbsp;&nbsp;|</span>
                                            */}

                                            <button className={typeof(global.login_token) === 'undefined'  || (this.state.isFlowCheckedOutByLoggedInUser && this.state.isFlowCheckedOut) ? 'fa fa-refresh refreshBTN newFontSizeVisible' :'fa fa-refresh refreshBTN newFontSize'} id="btnRefresh"  title="Refresh" style={{float:'right'}}
                                             onClick={this.handleRefresh} disabled={typeof(global.login_token) !== 'undefined' && !(this.state.isFlowCheckedOutByLoggedInUser && this.state.isFlowCheckedOut)}></button>
                                            <button className={typeof(global.login_token) === 'undefined'  || (this.state.isFlowCheckedOutByLoggedInUser && this.state.isFlowCheckedOut) ? 'fa fa-sliders refreshBTN newFontSizeVisible' :'fa fa-sliders refreshBTN newFontSize'} id="btnAutoArrange"  title="Auto Arrange" style={{float:'right'}}
                                             onClick={this.handleAutoArrange} disabled={typeof(global.login_token) !== 'undefined' && !(this.state.isFlowCheckedOutByLoggedInUser && this.state.isFlowCheckedOut)}></button>
                                          {/*  <span className='newFontSizeVisible'>#<span id="totalBlock">{item.totalBlock}</span> </span> */}

                                            </div>
                                            <div>{
                                                item.process.containsNull ? "Process Flow contains NULL block(s)." :""
                                                }
                                            </div>
                                            <div>{this.state.isFlowCheckedOut ? <div> {'Process Flow checked-out by '}<div className="checkedOutUserName"> {this.state.flowCheckedOutByuser} </div> </div>: ""}</div>
                                            {/*ref={element => this.displayProcessFlowDiagram(element, item)} onKeyDown={this.handleKey} */}
                                            {/* <span className="pf-checkout">User root is checked-out.</span><br />
                                            <span><Button className="check-InOut-btn">Check Out</Button></span> */}
                                            <div className="process_flow">
                                                <div className="custcontext-menu context-menu2"></div>
                                                <div className="myclasssss custcontext-menu context-menu" ></div>
                                                <div className="exView">
                                                    <div id={`process-flow-diagram-${item.process.processId}-${index}-${item.schemaName}`} className="overflowview diagram" onClick={this.handleDiagrams} onKeyDown={this.handleKey} />
                                                    {this.displayProcessFlowDiagram(`#process-flow-diagram-${item.process.processId}-${index}-${item.schemaName}`, item, index)}
                                                </div>
                                            </div>
                                        </TabStripTab>
                                    );
                                })}
                            </TabStrip>
                        ):(
                            <div className=""></div>
                        )
                    }
                </div>
              </div>

                <div className="study-properties-main">
                        {this.state.pastepopupModel && this.props.selectsItems.length === 1 && <Dialog className="study-properties" title={"Paste Option"} width="450px" height="auto" modal={true} onClose={this.handlePasteOpen} buttons={[<Button primary={true} className="k-button" onClick={this.handlePasteOk} key="btn-ok">OK</Button>,<Button className="k-button" onClick={this.handlePasteOpen} disabled={this.state.disabledCancel} key="btn-cancel">Cancel</Button>]}>
                                        <SingleBlockPasteDialog selectsItems={this.props.selectsItems} currentStudyId={this.state.currentStudyId} currentProcessId={this.state.currentProcessID} />
                                    </Dialog>
                        }
                </div>
                <div className="study-properties-main multiple-copy-paste">
			{this.state.pastepopupModel && this.props.selectsItems.length > 1 && <Dialog className="study-properties" title={"Paste Option"} width="670px" height="auto" modal={true} onClose={this.handlePasteOpen} buttons={[<Button primary={true} className="k-button" onClick={this.handlePasteOkMultiple} key="btn-ok">OK</Button>,<Button className="k-button" onClick={this.handlePasteOpen} disabled={this.state.disabledCancel} key="btn-cancel">Cancel</Button>]}>
                                        <MultipleBlockPasteDialog selectedItems={this.props.selectsItems} currentProcessId = {this.state.currentProcessID} currentStudyId={this.state.currentStudyId} ref = {ref => this.multiplePasteRef = ref} />
                                    </Dialog>
                        }
                        {
                            this.state.exportNode && <Export closeEvent={this.toggleExportNode} params={{exportType:ExportTypeConst.EXPORT_PROCESS_BLOCK}}/>
                        }
                </div>

                {
                    this.state.showBlockAssessmentDialog &&
                    <BlocksAssessment closeEvent={this.closeBlockAssessmentDialog} targetProcessId={this.state.currentProcessID}/>
                }

                {
                    this.state.showAddNotesDialog &&
                    <div className="study-properties-main note">
                    <Dialog className="study-properties" title="Notes" width="calc(100% - 10px)" height="calc(100vh - 20px)" modal={true} onClose={this.closeAddNotesDialog} buttons={[<Button primary={true} onClick={this.handleAddNotesOKButton} disabled={typeof(global.login_token) !== 'undefined'  && !(this.state.isFlowCheckedOutByLoggedInUser && this.state.isFlowCheckedOut)} key="btn-save"> Save</Button>,<Button onClick={this.closeAddNotesDialog} key="btn-cancel"> Cancel</Button>]}>
                      <div className="container-fluid m-t-5">
                        <div className="row">
                          <div className="am-form-field">
                            <span className="title"><b>Notes Details:</b></span>
                              <RichTextEditor content={this.state.notesContent} onChange={this.handleAddNotesChange}/>
                          </div>
                        </div>
                      </div>
                    </Dialog>
                    </div>

                }

                <div id="myAlert-top"></div>
                {this.state.alertMsgInfo !== '' &&
                    <div className="myAlertInfo-top alert alert-success">
                       <span> {this.state.alertMsgInfo}</span>
                    </div>
                }
                <div className="myAlertWarrning-top alert alert-warning">
                     <span>{this.state.alertMsgWarning}</span>
                </div>
                <div className="myAlertError-top alert alert-danger">
                    <span>{this.state.alertMsgError}</span>
                </div>

                {/* This is a Properties Save Alert */}
                {/* {global.alertMsgNoteInfo !== '' &&
                    <div className="myAlertInfo-top alert alert-success">
                       <span> {global.alertMsgNoteInfo}</span>
                    </div>
                } */}

            {this.state.taskListDialogStatus === true &&
                <div className="task-management">
                  <TaskList schemaName={this.state.processFlowSchema} filters={this.state.filters} closeEvent={this.handleTaskListDialog}/>
                </div>
            }

            {this.state.createTaskDialogStatus === true &&
              <CreateTask node={this.state.triggerNode} closeEvent={this.handleCreateTaskDialogClick} />
            }

            </div>
         );
    }

    updateFlowIconInTreeView(processFlowStatus){
        //var that = this;
        let treeView = this.props.treeView;
        treeView.children.forEach((project, index) => {
          project.children.forEach((study) => {
            processFlowStatus.forEach((updatedFlow)=>{
              if (study.study.studyId === updatedFlow.studyId) {
                study.children.forEach((studyElement) => {
                  if (studyElement.name === 'ProcessFlows') {
                    studyElement.children.forEach((classifier) => {
                      classifier.children.forEach((processGroup) => {
                        processGroup.children.map((flow)=>{
                          if(updatedFlow.processId === flow.process.processId){
                            flow.process.processSyncFlag = updatedFlow.processSyncFlag;
                            if(updatedFlow.processSyncFlag === 2){
                              flow.iconClass = "fa fa-exclamation-triangle yellow";
                            }
                            else if(updatedFlow.processSyncFlag === 3){
                              flow.iconClass = "fa fa-exclamation-circle red";
                            }
                            else if(updatedFlow.processSyncFlag === 1){
                              flow.iconClass = "fa fa-microchip";
                            }
                            if (typeof(updatedFlow.isSampleFiltered) !== 'undefined') {
                                flow.process.isSampleFiltered = updatedFlow.isSampleFiltered
                            }
                          }
                          return flow;
                        })
                      });
                    })

                  }
                })
              }
            })


         });
        })
        this.props.setTreeViewData(treeView);
      }
}
var data=null;
function unique(obj){
    var uniques=[];
    var stringify={};
    for(var i=0;i<obj.length;i++){
       var keys=Object.keys(obj[i]);
       keys.sort(function(a,b) {return a-b});
       var str='';
        for(var j=0;j<keys.length;j++){
           str+= JSON.stringify(keys[j]);
           str+= JSON.stringify(obj[i][keys[j]]);
        }
        if(!stringify.hasOwnProperty(str)){
            uniques.push(obj[i]);
            stringify[str]=true;
        }
    }
    return uniques;
}
function handleBlocksView(element, tebnotes, blockLink, studyId, schemaName, processFlowId, that, processFlowNotes){
    $(".loader").hide();

    var diagram = $(element).kendoDiagram({
        layout:{
            type: "tree",
            subtype: "down",
            horizontalSeparation: 50,
            verticalSeparation: 20
        },
        shapeDefaults: {

            connectors: [{
              name: "Upstream",
              position: function(shape) {
                return shape._transformPoint(shape.bounds().top());
              }
            }, {
              name: "Bottom",
              position: function(shape) {
                return shape._transformPoint(shape.bounds().bottom());
              }
            }]
        },
        selectable: {
            stroke: {
              dashType: "longDash",
              color: "blue",
              width: 0.4
            }
        },
        pannable: false,
        pannable: {
            key: "ctrl"
        },
        select: onSelect,
        dragEnd: onDragEnd,
        remove: onRemove,
        add: onAdd,
        copy:false,
        click: function(e) {
            if(isNotesSelected){
                return;
            }

            if(e.item instanceof kendo.dataviz.diagram.Shape){

                //     e.item.shapeVisual.children[0].redraw({
                //         fill: "#aab3c1",
                //         stroke: {
                //         width: 3
                //     }
                //     });

                var connectionData = e.item.options.connectionsData;
                if(connectionData !== null && typeof(connectionData) !== "undefined"){
                    for(var i=0;i<=connectionData.length;i++){
                        try{
                            if(connectionData[i].fromShapeId !== e.item.options.options.blockId){
                                nodeStatus ="Last Node"
                            }
                            else{
                                nodeStatus ="Not Last Node";
                                break;
                            }
                        }catch(e){}
                    }

                }
                //selectNoteList = [];
                //selectNodeIdList = [];
                //selectnote = e.item.options.blockTable;
                //localStorage.setItem("Tenant-ID", e.item.options.blockRefStudy);
                //selectnodeid = e.item.id;
                selectBlockName = e.item.options.options.blockName;
                global.dataViewerTemplate = false;
                global.fileViewerTemplate = false;
                //BlockType = 1 for table,
                //BlockType = 2 for File
                if(e.item.options.options.blockType === 1){
                    global.dataViewerTemplate = true;
                }else if(e.item.options.options.blockType === 2){
                    global.fileViewerTemplate = true;
                }
                /*setTimeout(()=>{
                  e.item.diagram._selectedItems.forEach((selectedItem) => {
                    selectNoteList.push(selectedItem.options.blockTable);
                    selectNodeIdList.push(selectedItem.id);
                  });
                  selectnote = selectNoteList.join(',');
                  selectnodeid = selectNodeIdList.join(',');

                }, 1);*/
            }else{
            }
        },
        zoomStart: function(e){
            try
            {

            //scrolling mouse wheel
            var zoomDirectionX =  "x";
            var diagram01 = e.sender,
                panPoint = diagram01.pan();
            if(panPoint.x !== -3){
                panPoint[zoomDirectionX] = panPoint[zoomDirectionX] + e.meta.delta;
                diagram01.pan(panPoint);
                e.preventDefault();
            }
            else{
                e.preventDefault();
            }
            }catch(error){

            }
        },
        editable:{
            drag:false,
            remove:true,
            rotate:false,
            resize:false
            },
        zoomRate: 0,
        zoom:false,
        connectionDefaults: {
            type:"polyline",
            endCap: {
            type: 'ArrowEnd',
                fill: {
                    color: "#222222"
                }
            },
            hover: {
                stroke: {
                    color: "#20a8d8"
                },
                tooltip: {
                    visible: false,
                    template: "#= series name #: #= value #"
                  },
            }
        }
    }).bind("dblclick", function(e){
        if(isNotesSelected && selectedNotes.length){
            My_this.editNotes();
            that.props.setProcessFlowTableId(selectedNotes[0].noteId).toString();
            return;
        }

        if (selectedProcessBlocks.length === 1) {

            if(selectedProcessBlocks[0].blockSyncFlag === BlockStatus.VIRTUAL){
                kendo.alert("Virtual block does not have any data.");
                return;
            }
            global.selectedProcessBlocks = selectedProcessBlocks[0].blockTable;
            global.updateBlock = false;
            global.dataViewerTemplate = false;
            global.fileViewerTemplate = false;
            //BlockType = 1 for table,
            //BlockType = 2 for File
            if(selectedProcessBlocks[0].blockType === 1 && (selectedProcessBlocks[0].blockCreationType === BlockCreationType.IMPORT_SOURCE_TABLE
                || selectedProcessBlocks[0].blockCreationType === BlockCreationType.IMPORT_META_TABLE
                || selectedProcessBlocks[0].blockCreationType === BlockCreationType.DATA_LINK
                || selectedProcessBlocks[0].blockCreationType === BlockCreationType.DATA_COPY)){
                if(selectedProcessBlocks[0].tableExists === 0){
                    kendo.alert("Table does not exist for '"+ selectedProcessBlocks[0].blockName+"' block. Generate table using Execute All at Process Block level.");
                    return;
                }else{
                    global.dataViewerTemplate = true;
                }
            }else if(selectedProcessBlocks[0].blockType === 2 && selectedProcessBlocks[0].blockCreationType === BlockCreationType.IMPORT_SOURCE_DOCUMENT){
                global.fileViewerTemplate = true;
            }else if(selectedProcessBlocks[0].blockCreationType === BlockCreationType.DEF_COPY || selectedProcessBlocks[0].blockCreationType === BlockCreationType.CREATE_UPDATE_BLOCK
                || selectedProcessBlocks[0].blockCreationType === BlockCreationType.MAP_ANNOTATE || selectedProcessBlocks[0].blockCreationType === BlockCreationType.TIMELINE){
                    if(true || allowUpdateBlock(selectedProcessBlocks[0], that)){
                        global.updateBlock = true;
                        that.props.setTenantId(selectedProcessBlocks[0].schemaName);
                        that.props.setProcessFlowTable(selectedProcessBlocks[0].blockTable);
                        that.props.setProcessFlowTableId(selectedProcessBlocks[0].blockId.toString());
                        that.props.history.push("/update-block");
                        return;
                    }else{
                        return;
                    }
            }

            that.props.setTenantId(selectedProcessBlocks[0].schemaName);
            that.props.setProcessFlowTable(selectedProcessBlocks[0].blockTable);
            that.props.setProcessFlowTableId(selectedProcessBlocks[0].blockId.toString());

            that.props.history.push("/data-operation");
        }
    }).getKendoDiagram();

    $(element).unbind("mousewheel");
    $(element).unbind("DOMMouseScroll");

    flowDiagram = diagram;

    function onAdd(e){
        // short cut key ctrl+v paste
    }

    function onRemove(e) {
        e.preventDefault();
    }

    function onDragEnd(e) {
        if (My_this.getWriteAcStatus(My_this.state.currentSchemaName) === false) {
          return false;
        }
        // onDragEnd diagram events
        var objectOfProcessFlow = null;
        var Tenant_ID = null;
        var blockId = null;
        //var studyIdObj = null;
        //var processIdObj = null;
        var Xcoord = null;
        var Ycoord = null;
        var draggedShapes = e.shapes; // diagram shapes values store...

        for(var i = 0; i < draggedShapes.length; i++){
            var shape = draggedShapes[i];
            objectOfProcessFlow = shape.options.options; //selected object store
            let shapeType = shape.options.shapeType;
            Xcoord = parseInt(shape.position().x, 10); // diagram's x coordinate
            Ycoord = parseInt(shape.position().y, 10); // diagram's y coordinate

            Tenant_ID = shape.options.options.schemaName; //Tenant ID store here...
            blockId = objectOfProcessFlow.blockId;  //process block Id  store here...
            //studyIdObj = objectOfProcessFlow.studyId;
            //processIdObj = objectOfProcessFlow.processId;

            if(Xcoord && Ycoord){
                var NewProcessFlowObject = objectOfProcessFlow;
                var X_YString = Xcoord+","+Ycoord;
                var tempCoordinate = X_YString;
                // $(".loader").show();

                if(shapeType === "blocks"){
                    NewProcessFlowObject["blockCoord"] = tempCoordinate;
                    var params = NewProcessFlowObject;
                    axios.all([restClient(UPDATE, 'processblock', {data: params, id: blockId}, {'Tenant-ID': Tenant_ID})])
                    .then(axios.spread((response) => {
                        if (typeof(response.data) !== 'undefined' && typeof(response.data.processBlock) !== 'undefined' &&
                        response.data.processBlock !== null){

                        that.props.processFlowList.forEach((processFlow, index) => {
                            if (processFlow.schemaName === Tenant_ID) {
                            processFlow.process.processBlock.forEach((block, blockIndex) => {
                                response.data.processBlock.forEach((updatedBlock)=>{
                                    if (updatedBlock.blockId === block.blockId) {
                                    that.props.updateProcessFlowBlockByTabIndex(index, blockIndex, updatedBlock);
                                    return true;
                                    }

                                })
                            })
                            }
                        })
                        //createShape(response.data);
    /*
                            //update localStorage processBlock values
                            var processBlock = JSON.parse(localStorage.getItem("processBlock"));
                            var modifyBlock = response.data;
                            var blockIndex = '';
                            processBlock.forEach((block, index) => {
                                if (block.blockId === modifyBlock.blockId) {
                                blockIndex = index;
                                }
                            });
                            if (blockIndex !== '') {
                                processBlock[blockIndex] = modifyBlock;
                            }
                            localStorage.setItem("processBlock",JSON.stringify(processBlock));

                            //update localStorage processBlockListMain values
                            var processBlockListMain = JSON.parse(localStorage.getItem("processBlockListMain"));
                            if (processBlockListMain !== null) {
                                processBlockListMain.forEach((procesflow, index) => {
                                    if (procesflow.studyId === studyId) {
                                        if (procesflow.BlocksList === null) {
                                            processBlockListMain[index].BlocksList = [];
                                        }

                                        if (typeof(processBlockListMain[index].BlocksList) !== 'undefined') {

                                            var blockIndex = '';
                                            processBlockListMain[index].BlocksList.forEach((block, index) => {
                                                if (block.blockId === modifyBlock.blockId) {
                                                blockIndex = index;
                                                }
                                            });

                                            if (blockIndex !== '') {
                                                processBlockListMain[index].BlocksList[blockIndex] = modifyBlock;
                                            }
                                        }
                                    }
                                })
                                localStorage.setItem("processBlockListMain",JSON.stringify(processBlockListMain)); // updated processBlockListMain
                                createShape(response.data); //re-render processFlow diagrams createShape.
                                // $(".loader").hide();
                            }*/
                        }
                    })).catch(error => {
                        that.props.setMessage("Failed to update block co-ordinate.",MessageTypeConst.ERROR_MESSAGE);
                    });

                }else if(shapeType === "notes"){
                    NewProcessFlowObject["noteCoord"] = tempCoordinate;
                    var params = NewProcessFlowObject;
                    axios.all([restClient(CREATE, 'note/add', {data: params}, {'Tenant-ID': My_this.state.currentSchemaName})])
                    .then(axios.spread((response) => {
                        if (typeof(response.data) !== 'undefined' && response.data !== null){
                            if (typeof(response.data.messageType) !== 'undefined' && response.data.messageType !== null && response.data.messageType === MessageTypeConst.WARNING_MESSAGE){
                                that.props.setMessage(response.data.message, response.data.messageType);
                                that.setState({key:that.state.key+1});
                                return true;
                            }
                            that.props.processFlowList.forEach((processFlow, index) => {
                                if (processFlow.schemaName === My_this.state.currentSchemaName) {
                                processFlow.process.processFlowNotes.forEach((note, noteIndex) => {
                                    if (response.data.noteId === note.noteId) {
                                        that.props.updateProcessFlowNoteByTabIndex(index, noteIndex, response.data);
                                        that.setState({key:that.state.key+1});
                                        return true;
                                        }
                                })
                                }
                            })
                        }
                    })).catch(error => {
                        that.props.setMessage("Failed to update notes co-ordinate.",MessageTypeConst.ERROR_MESSAGE);
                    });

                }
            }
        }

    }
    function onSelect(e) {
        dataNew = [];
        isNotesSelected = false;
        var isNodeSelected = e.sender._selectedItems.filter(sel => sel.options.shapeType === "notes");
        if(isNodeSelected.length > 0){
            isNotesSelected = true;
        }
        try{
            var connectionData = e.selected[0].options.connectionsData;
            for(var i=0;i<=connectionData.length;i++){
                if(connectionData[i].fromShapeId !== e.selected[0].options.options.blockId){
                    nodeStatus ="Last Node";
                    selectBlockName = e.selected[0].options.options.blockName;
                    selectnodeid = e.selected[0].options.options.blockId;
                    that.props.setProcessFlowTable(selectedProcessBlocks[0].blockTable);
                    that.props.setProcessFlowTableId(e.selected[0].options.options.blockId.toString());

                }
                else{
                    nodeStatus ="Not Last Node";
                    selectBlockName = e.selected[0].options.options.blockName;
                    break;
                }
            }

            if (connectionData.length === 0) {
              nodeStatus ="Last Node";
            }
        }catch(e){}
        if (e.selected.length) {
            //action = "Selected";
                  if(e.selected.length === 1)
                    {
                        $("#spanProperty").removeClass("newFontSize");
                        $("#spanEA").removeClass("newFontSize");
                        $("#spanLog").removeClass("newFontSize");
                        $("#spanCode").removeClass("newFontSize");
                        $("#spanData").removeClass("newFontSize");
                        $("#spanProperty").addClass("newFontSizeVisible");
                        $("#spanEA").addClass("newFontSizeVisible");
                        $("#spanLog").addClass("newFontSizeVisible");
                        $("#spanCode").addClass("newFontSizeVisible");
                        $("#spanData").addClass("newFontSizeVisible");
                        $("#spanCopy").removeClass("newFontSize");
                        $("#spanCopy").addClass("newFontSizeVisible");
                        $("#spanPaste").removeClass("newFontSize");
                        $("#spanPaste").addClass("newFontSizeVisible");
                        $("#spanDelete").removeClass("newFontSize");
                        $("#spanDelete").addClass("newFontSizeVisible");

                        $("#spanUpdate").removeClass("newFontSize");
                        $("#spanUpdate").addClass("newFontSizeVisible");

                        $("#spanAddLink").removeClass("newFontSize");
                        $("#spanAddLink").addClass("newFontSizeVisible");


                        $("#spanDeleteLink").removeClass("newFontSize");
                        $("#spanDeleteLink").addClass("newFontSizeVisible");

                        $("#spanExport").removeClass("newFontSize");
                        $("#spanExport").addClass("newFontSizeVisible");

                        $("#spanTP").removeClass("newFontSize");
                        $("#spanTP").addClass("newFontSizeVisible");





                    }
                    else{
                        $("#spanProperty").removeClass("newFontSizeVisible");
                        $("#spanEA").removeClass("newFontSizeVisible");
                        $("#spanLog").removeClass("newFontSizeVisible");
                        $("#spanCode").removeClass("newFontSizeVisible");
                        $("#spanData").removeClass("newFontSizeVisible");
                        $("#spanProperty").addClass("newFontSize");
                        $("#spanEA").addClass("newFontSize");
                        $("#spanLog").addClass("newFontSize");
                        $("#spanCode").addClass("newFontSize");
                        $("#spanData").addClass("newFontSize");

                        $("#spanCopy").removeClass("newFontSize");
                        $("#spanCopy").addClass("newFontSizeVisible");

                        $("#spanPaste").removeClass("newFontSize");
                        $("#spanPaste").addClass("newFontSizeVisible");

                        $("#spanDelete").removeClass("newFontSize");
                        $("#spanDelete").addClass("newFontSizeVisible");

                        $("#spanUpdate").removeClass("newFontSize");
                        $("#spanUpdate").addClass("newFontSizeVisible");

                        $("#spanAddLink").removeClass("newFontSize");
                        $("#spanAddLink").addClass("newFontSizeVisible");


                        $("#spanDeleteLink").removeClass("newFontSize");
                        $("#spanDeleteLink").addClass("newFontSizeVisible");

                        $("#spanExport").removeClass("newFontSize");
                        $("#spanExport").addClass("newFontSizeVisible");

                        $("#spanTP").removeClass("newFontSize");
                        $("#spanTP").addClass("newFontSizeVisible");
                    }

            //items = e.selected;
            e.sender._selectedItems.forEach((selectedItem) => {
                if (typeof(selectedItem.options.options) !== 'undefined') {
                    dataNew.push(selectedItem.options.options);
                }
            });
        } else if (e.deselected.length) {
            $("#spanProperty").removeClass("newFontSizeVisible");
            $("#spanEA").removeClass("newFontSizeVisible");
            $("#spanLog").removeClass("newFontSizeVisible");
            $("#spanCode").removeClass("newFontSizeVisible");
            $("#spanData").removeClass("newFontSizeVisible");
            $("#spanProperty").addClass("newFontSize");
            $("#spanEA").addClass("newFontSize");
            $("#spanLog").addClass("newFontSize");
            $("#spanCode").addClass("newFontSize");
            $("#spanData").addClass("newFontSize");

            $("#spanCopy").addClass("newFontSize");
                        $("#spanCopy").removeClass("newFontSizeVisible");

                        $("#spanPaste").addClass("newFontSize");
                        $("#spanPaste").removeClass("newFontSizeVisible");

                        $("#spanDelete").addClass("newFontSize");
                        $("#spanDelete").removeClass("newFontSizeVisible");

                        $("#spanUpdate").addClass("newFontSize");
                        $("#spanUpdate").removeClass("newFontSizeVisible");

                        $("#spanAddLink").addClass("newFontSize");
                        $("#spanAddLink").removeClass("newFontSizeVisible");


                        $("#spanDeleteLink").addClass("newFontSize");
                        $("#spanDeleteLink").removeClass("newFontSizeVisible");

                        $("#spanExport").addClass("newFontSize");
                        $("#spanExport").removeClass("newFontSizeVisible");

                        $("#spanTP").addClass("newFontSize");
                        $("#spanTP").removeClass("newFontSizeVisible");
            //action = "Deselected";
            //items = e.deselected;
            // dataNew = [];
            // localStorage.setItem("SelectsItems", "");
        }

        // processFlowObj selected prrocessBlock array bind
        var shapes = this.shapes;
        var processFlowObj = [];
        var notes = [];
        for(var i = 0; i < shapes.length; i++){
          shapes[i].redrawVisual();
          if(shapes[i].isSelected === true && shapes[i].options.shapeType === "blocks"){
            processFlowObj.push(shapes[i].options.options); //add processFlow Blocks
          }else if(shapes[i].isSelected === true && shapes[i].options.shapeType === "notes"){
            notes.push(shapes[i].options.options); //add processFlow Notes
          }
        }

        var selectedLinkList = [];
        var connections = this.connections;
        for(var i = 0; i < connections.length; i++){
            if(connections[i].isSelected === true){
                selectedLinkList.push({blockId1: connections[i].from.id, blockId2:connections[i].to.id}); //add processFlow links
            }
        }
        selectedLinks = selectedLinkList;

        selectedProcessBlocks = processFlowObj;
        selectedNotes = notes;
        selectNoteList = [];
        selectNodeIdList = [];

        /*selectedProcessBlocks.forEach((selectedItem) => {
          selectNoteList.push(selectedItem.blockTable);
          selectNodeIdList.push(selectedItem.blockId);
        });*/

        setTimeout(()=>{
          e.sender._selectedItems.forEach((selectedItem) => {
            if (selectNodeIdList.indexOf(selectedItem.id) === -1 && typeof(selectedItem.options.blockTable) !== 'undefined') {
              selectNoteList.push(selectedItem.options.blockTable);
              let id = selectedItem.id;
              if(selectedItem.options.shapeType === "notes"){
                id = selectedItem.id.replace("n-","");
              }
              selectNodeIdList.push(id);
            }
          });
          selectnote = selectNoteList.join(',');
          selectnodeid = selectNodeIdList.join(',');
        }, 1);
    }


    if(tebnotes !=  null){
        try{
            data = tebnotes.filter(item => item !== null && typeof(item) !== "undefined");
        }catch(e){}
    }

    // block find shapes data
    var APIData_Block = blockLink;
    try{
        var connectionsData= [];
        for(var p=0; p<APIData_Block.length; p++){
            var dataA = APIData_Block[p].blockId1;
            var dataB = APIData_Block[p].blockId2;
            var fromToString = {"fromShapeId": dataA, "toShapeId": dataB};
            connectionsData.push(fromToString);

        }
    }catch(e){}

    // processFlow Data create the shapes
    if(data !== null){
        try{
            for(var i = 0; i < data.length; i++) {
                diagram.addShape(createShape(data[i]));
            }
        }
        catch(e){}
    }

    //if process Flow notes, then add Notes
    if(processFlowNotes !== null && typeof(processFlowNotes) !== "undefined"){
        try{
            for(var i = 0; i < processFlowNotes.length; i++) {
                diagram.addShape(createNotes(processFlowNotes[i]));
            }
        }
        catch(e){}
    }

    // block connections
    for(var j = 0; j < connectionsData.length; j++){
        try{
            var sourceShapeX = connectionsData[j].fromShapeId;
            var targetShapeY = connectionsData[j].toShapeId;
            var sourceShape = diagram.getShapeById(sourceShapeX);
            var targetShape = diagram.getShapeById(targetShapeY);
            diagram.connect(sourceShape, targetShape);
        }
        catch(e){}
    }

    if(that.props.jumpToBlockId !== 0){
        let selectedShape = diagram.getShapeById(that.props.jumpToBlockId);
        if(typeof(selectedShape) !== "undefined" && selectedShape !== null){
            diagram.select(selectedShape);
        }
    }

    $(element).parents('.process_flow').find('.context-menu2').kendoContextMenu({ //process flow out-box context-menu
        animation: {
            open: { effects: "fadeIn" },
            duration: 10,
        },
        target : element,
        filter : "svg",
        select: function(e) {
            if (typeof(e.sender.target) !== 'undefined') {
                diagram = e.sender.target.getKendoDiagram();
            }

            if(e.item instanceof kendo.dataviz.diagram.Shape){
                //var selectnoteas = e.item.options.content.text;

            }else{
                var button = $(e.item);
                var node = $(e.target);
                var seleced_value = '';
                var parent_seleced_value = '';
                button.parents('.k-animation-container').siblings('span').each(function(index, parent){
                    if (seleced_value === '') {
                        parent_seleced_value = $(parent).text();
                    } else {
                        parent_seleced_value = parent_seleced_value + '_' +$(parent).text();
                    }
                });
                var importType = null;
                parent_seleced_value = parent_seleced_value.toLowerCase();
                seleced_value = (kendo.format(button.text(), node.text()));
                if(parent_seleced_value === "select source table" || parent_seleced_value === "select meta table" || parent_seleced_value === "select document"){
                    if(parent_seleced_value === "select source table"){
                    importType = "0";
                    seleced_value = seleced_value.toLowerCase();
                    }else if (parent_seleced_value === "select meta table"){
                    importType = "1";
                    seleced_value = seleced_value.toLowerCase();
                    }else if (parent_seleced_value === "select document"){
                        importType = "2";
                    }

                    ConfirmBox.open("Are you sure you want to create new BLOCK ?").then(function (data) {
                        axios.all([restClient(CREATE, 'processblock/add?tableName='+seleced_value+'&studyName='+that.state.currentSchemaName+'&studyId='+that.state.currentStudyId+'&processId='+that.state.currentProcessID+'&blockXY='+global.blockXY+'&importType='+importType, {}, {'Tenant-ID': that.state.currentSchemaName})])
                            .then(axios.spread((processblockresponse) => {
                                    if (typeof(processblockresponse.data) !== 'undefined' && typeof(processblockresponse.data.messageType) !== 'undefined' && processblockresponse.data.messageType !== null && processblockresponse.data.messageType ===  MessageTypeConst.WARNING_MESSAGE) {
                                        that.props.setMessage(processblockresponse.data.message, processblockresponse.data.messageType);
                                        return;
                                    }
                                    if (typeof(processblockresponse.data) !== 'undefined') {
                                      that.props.addProcessFlowBlockByTabIndex(that.state.selected, processblockresponse.data);
                                      diagram.addShape(createShape(processblockresponse.data));
                                      that.props.setMessage("New '"+ seleced_value +"' PROCESS BLOCK have been created Successfully!", MessageTypeConst.SUCCESS_MESSAGE)
                                    }
                                    else{
                                        that.props.setMessage("New '"+ seleced_value +"' PROCESS BLOCK creation Failed!", MessageTypeConst.ERROR_MESSAGE);
                                    }
                                })
                            ).catch(error => {
                                that.props.setMessage("New '"+ seleced_value +"' PROCESS BLOCK creation Failed!", MessageTypeConst.ERROR_MESSAGE);
                              });
                      })
                }
                else if(seleced_value.toLowerCase() === "paste"){
                    pastePopup(diagram);
                }
                else if(seleced_value.toLowerCase() === "add notes"){
                    My_this.openAddNotesDialog();
                } else if(seleced_value.toLowerCase() === "mapper" || seleced_value.toLowerCase() === "tlf"){
                    that.props.setProcessFlowTableId(0);
                    window.location.hash='#annotate?type='+seleced_value.toLowerCase();
                }

            }
        },
        open: function (e) {
            if(e.event){
                try{
                    if(typeof(global.login_token) !== 'undefined' && !(that.state.isFlowCheckedOut && that.state.isFlowCheckedOutByLoggedInUser)){
                        e.preventDefault();
                        return;
                    }
                var diagram = $(element).getKendoDiagram();
                var point = diagram.documentToModel(new kendo.dataviz.diagram.Point(e.event.pageX, e.event.pageY));
                global.blockXY = point.x.toFixed()+","+point.y.toFixed();
                X = point.x.toFixed();
                Y = point.y.toFixed();
                }catch(e){
                }

                try {
                    if(e.target){
// alert(localStorage.getItem("fullJsonObj"));
// alert(studyId);

                        /*var sideTreeView = $("[data-role='treeview']").data("kendoTreeView").dataSource.options.data;

                        var sourceTableList = [];
                        var itemss = null;
                        var metaTableList = [];
                        var fullJsonObjProcess=JSON.parse(localStorage.getItem("fullJsonObj"));
                        if (studyId !== null && sideTreeView[0].projectList !== 'undefined') {
                        for(var i=0;i<fullJsonObjProcess.length;i++)
                        {
                            if(studyId==fullJsonObjProcess[i].studyId)
                            {

                                for(var j=0;j<fullJsonObjProcess[i].sourceTable.length;j++)
                        {
                            // alert(JSON.stringify(fullJsonObjProcess[i].sourceTable[j].srcTableName));
                            sourceTableList.push({ text:fullJsonObjProcess[i].sourceTable[j].srcTableName});
                        }
                        for(var j=0;j<fullJsonObjProcess[i].metaTable.length;j++)
                        {
                            metaTableList.push({ text:fullJsonObjProcess[i].metaTable[j].srcTableName});
                        }
                            }
                        }
                        }

// alert(JSON.stringify(sourceTableList));

                        // if (studyId !== null && sideTreeView[0].projectList !== 'undefined') {
                        //      sideTreeView[0].projectList.forEach((project) => {
                        //          if (typeof(project.projectStudyList) !== 'undefined' && project.projectStudyList.length > 0) {
                        //             project.projectStudyList.forEach((study, studykey) => {
                        //                 if (studyId === study.studyId) {
                        //                     study.staticObject[0].processFlowGroup.forEach((sourceTable) => {
                        //                         sourceTableList.push({ text: sourceTable.processGroupName });
                        //                     })

                        //                     study.staticObject[1].processFlowGroup.forEach((sourceTable) => {
                        //                         metaTableList.push({ text: sourceTable.processGroupName });
                        //                     })
                        //                 }
                        //               })
                        //          }

                        //     })
                        // }

*/
                        var sourceTableList = [];
                        var itemss = null;
                        var metaTableList = [];
                        var documentsList = [];

                        if (typeof(that.props.treeView) !== 'undefined') {
                          that.props.treeView.children.forEach((project) => {
                            project.children.forEach((study) => {
                              if (study.study.studyId === studyId) {
                                if (typeof(study.study.sourceTable) !== 'undefined') {
                                  study.study.sourceTable.forEach((sourceTable) => {
                                    sourceTableList.push({ text: sourceTable.srcTableName });
                                  })
                                }

                                if (typeof(study.study.metaTable) !== 'undefined') {
                                  study.study.metaTable.forEach((metaTable) => {
                                    metaTableList.push({ text: metaTable.srcTableName });
                                  })
                                }
                                if (typeof(study.study.studyDocuments) !== 'undefined') {
                                    study.study.studyDocuments.forEach((doc) => {
                                        documentsList.push({ text: doc.filename });
                                    })
                                  }
                              }

                            })
                          })
                        }

                        that.props.importTable.forEach((table) => {
                          if (table.schemaName === schemaName) {
                            if (table.tableType === 0) {
                              var sourceTable = sourceTableList.filter( sourceTable => sourceTable.text === table.name );

                              if (sourceTable.length === 0) {
                                  sourceTableList.push({ text: table.name });
                              }

                            } else if(table.tableType === 1) {
                              var metaTable = metaTableList.filter( metaTable => metaTable.text === table.name );

                              if (metaTable.length === 0) {
                                  metaTableList.push({ text: table.name});
                              }
                            }
                          }
                        })

                        var itemss = [
                            { text: Global_Context.mapAndAnnotateItem, items: [{text: Global_Context.autoMappingItem}, {text: Global_Context.autoTableItem}] },
                            { text: Global_Context.addNotesItem },
                            // { text: Global_Context.integrityCheckItem },
                            // { text: Global_Context.autoArrangeBlockItem },
                            { text: Global_Context.dataWizardItem },
                            // { text: Global_Context.zoomInItem },
                            // { text: Global_Context.zoomOutItem },
                            { text: Global_Context.pasteBlockItem },
                            { text: Global_Context.importTable,
                                items: sourceTableList.sort(function(a, b){
                                    var x = a.text.toLowerCase();
                                    var y = b.text.toLowerCase();
                                    if (x < y) {return -1;}
                                    if (x > y) {return 1;}
                                    return 0;
                                  })
                            },
                            { text: Global_Context.importMetaTable,
                                items: metaTableList.sort(function(a, b){
                                    var x = a.text.toLowerCase();
                                    var y = b.text.toLowerCase();
                                    if (x < y) {return -1;}
                                    if (x > y) {return 1;}
                                    return 0;
                                  })
                             },
                             { text: Global_Context.importDocuments,
                                items: documentsList.sort(function(a, b){
                                    var x = a.text.toLowerCase();
                                    var y = b.text.toLowerCase();
                                    if (x < y) {return -1;}
                                    if (x > y) {return 1;}
                                    return 0;
                                  })
                             }
                        ];

                        this.setOptions({
                            dataSource: itemss
                        })
                        /*var breadcrumb = localStorage.getItem("breadcrumb");
                        var tenant_ID = breadcrumb.split("/")[1].trim();
                        this.tenant_ID = tenant_ID.trim();
                        var fetchImportDataList = (headers) => restClient(GET_LIST, 'table/sourceTable', {pagination: { page: 1, perPage: -1 }, sort: { field: 'id', order: 'ASC' }}, headers);

*/





                    }

                } catch (error) {
                }
            }

        }
    });

    $(element).parents('.process_flow').find('.context-menu').kendoContextMenu({ //process flow box context-menu
        animation: {
            open: { effects: "fadeIn" },
            duration: 10,
        },
        target : element,
        filter : "g",
        select: function(e) {

            var currentDiagram = null;
            if(e.sender !== null && e.sender.target !== null){
                currentDiagram = e.sender.target.getKendoDiagram();
            }
            if(e.item instanceof kendo.dataviz.diagram.Shape){
                //var selectnoteas = e.item.options.content.text;

            }else{
                if ( !$(e.item).hasClass("selectable") ) {
                    e.preventDefault();
                    return;
                }
                var button = $(e.item);
                var node = $(e.target);
                var seleced_value = '';

                that.props.setQuickCondition('');

                button.parents('.k-animation-container').siblings('span').each(function(index, parent){
                    let submenucols = JSON.parse(that.props.submenucolumns);
                    if(submenucols === null){
                        submenucols = [];
                    }
                    if(submenucols.indexOf($(parent).text()) === -1){
                        that.props.setQuickCondition($(parent).text());
                    }
                    if (seleced_value === '') {
                    seleced_value = $(parent).text();
                    } else {
                    seleced_value = seleced_value + '_' +$(parent).text();
                    }
                });

                if (seleced_value === '') {
                    seleced_value = (kendo.format(button.text(), node.text())).toLowerCase();
                } else {
                    seleced_value = (seleced_value + '_' +kendo.format(button.text(), node.text())).toLowerCase();
                }

                let actionName = $(e.item).attr("actionname");
                if(typeof(actionName) !== 'undefined' && actionName !== ''){
                    //localStorage.setItem("process-flow-action", actionName);
                    that.props.setProcessFlowAction(actionName);
                }else{
                    seleced_value = seleced_value.replace(/ /g, '_');
                    //localStorage.setItem("process-flow-action", seleced_value);
                    that.props.setProcessFlowAction(seleced_value);
                }
                let quickColumns = [];
                if(typeof(actionName) !== 'undefined' && actionName !== ''){
                    //seleced_value = seleced_value.replace(localStorage.getItem('quickcondition').toLowerCase(),"").replace(/ /g, '_');
                    seleced_value = seleced_value.replace(that.props.quickcondition.toLowerCase(),"").replace(/ /g, '_');
                    let quickCol = seleced_value.replace(actionName+"_","").replace("_done","");
                    quickColumns = quickCol.toUpperCase().split("_").filter(item=>item);
                }

                /*localStorage.setItem("quickcolumns",JSON.stringify(quickColumns));
                localStorage.setItem("process-flow-table", selectnote);
                localStorage.setItem("process-flow-table-id", selectnodeid);*/

                that.props.setQuickCondition(JSON.stringify(quickColumns));
                that.props.setProcessFlowTable(selectnote);
                that.props.setProcessFlowTableId(selectnodeid);
                that.props.setTenantId(schemaName);

                // We have used jQuery so we dont got React Props and stats then we use javascript for render component
                // window.location.hash='#data-operation';
                if(seleced_value === 'view_data' && typeof(selectedProcessBlocks) !== "undefined" && selectedProcessBlocks !== null && selectedProcessBlocks.length > 0 &&
                selectedProcessBlocks[0].blockType === 1 && (selectedProcessBlocks[0].blockCreationType === BlockCreationType.IMPORT_SOURCE_TABLE
                    || selectedProcessBlocks[0].blockCreationType === BlockCreationType.IMPORT_META_TABLE
                    || selectedProcessBlocks[0].blockCreationType === BlockCreationType.DATA_LINK
                    || selectedProcessBlocks[0].blockCreationType === BlockCreationType.CREATE_UPDATE_BLOCK
                    || selectedProcessBlocks[0].blockCreationType === BlockCreationType.DATA_COPY)){
                    if(selectedProcessBlocks[0].tableExists === 0){
                        kendo.alert("Table does not exist for '"+ selectedProcessBlocks[0].blockName+"' block. Generate table using Execute All at Process Block level.");
                        return;
                    }else{
                        global.dataViewerTemplate = true;
                    }
                }else if(seleced_value === 'view_report/document' && typeof(selectedProcessBlocks) !== "undefined" && selectedProcessBlocks !== null && selectedProcessBlocks.length > 0 &&
                (selectedProcessBlocks[0].blockType === 2 || selectedProcessBlocks[0].blockType === 5) && (selectedProcessBlocks[0].blockCreationType === BlockCreationType.IMPORT_SOURCE_DOCUMENT
                    || selectedProcessBlocks[0].blockCreationType === BlockCreationType.CREATE_UPDATE_BLOCK || selectedProcessBlocks[0].blockCreationType === BlockCreationType.MAP_ANNOTATE )){
                    global.fileViewerTemplate = true;
                }
                if (seleced_value !== 'view_data' && seleced_value !== 'view_report/document' ) {
                    global.dataViewerTemplate = false;
                    global.fileViewerTemplate = false;
                }
                if (seleced_value === 'add_link') {
                    window.location.hash='#addLink';
                }
                else if (seleced_value === 'view_extended_attributes') {
                    window.location.hash='#extendedattributes';
                }
                else if (seleced_value === 'view_log') {
                    window.location.hash='#log';
                }
				        else if (seleced_value === 'view_code') {
                    window.location.hash='#code';
                }
                else if (seleced_value === 'update_block') {
                    window.location.hash='#update-block';
                } else if(seleced_value === 'properties'){

                    window.location.hash='#data-properties';
                } else if (seleced_value === 'block_options_delete') {
                    let flg = 0;

                    if(nodeStatus === '' || nodeStatus.toLowerCase() === "last node"){
                        flg = 1;
                    }
                    else{
                        var message = selectBlockName + " cannot be deleted, Please delete child blocks of exisintg block!";
                        that.props.setMessage(message , MessageTypeConst.WARNING_MESSAGE);
                    }
                    if(flg === 1){
                        var blockName = "";
                        let selectedBlock = selectedProcessBlocks.filter(a=>parseInt(a.blockId,10) === parseInt(selectnodeid,10));
                        if(selectedBlock.length >0){
                            blockName = selectedBlock[0].blockName;
                            if(blockName === "" || blockName === null){
                                blockName = selectedBlock[0].blockTable;
                            }
                        }
                        ConfirmBox.open("Are you sure you want to delete block '"+blockName+"' ?").then(function (data) {
                            $(".loader").show();
                            axios.all([restClient(DELETE, 'processblock', {id: selectnodeid}, {'Tenant-ID': schemaName})])
                            .then(axios.spread((response) => {
                                let processFlowStatus = [];
                                    if(response.data.messageType !== MessageTypeConst.SUCCESS_MESSAGE){
                                    that.props.setMessage(response.data.message, response.data.messageType);
                                   } else{
                                    that.props.removeProcessFlowBlockByTabIndex(that.state.selected, parseInt(selectnodeid,10));
                                    let shape = currentDiagram.getShapeById(parseInt(selectnodeid, 10));
                                    if(shape !== null && typeof(shape) !== 'undefined'){
                                        shape.connections().forEach((connection) => {
                                            currentDiagram.remove(connection);
                                        })
                                        currentDiagram.remove(shape);
                                    }
                                    if(response.data.processBlock !== null && typeof(response.data.processBlock) !== "undefined" &&
                                    response.data.processBlock.length > 0){
                                        that.props.updateProcessFlowBlockBySchemaName(schemaName, response.data.processBlock);
                                    }
                                    if(response.data.processFlow !== null && typeof(response.data.processFlow) !== "undefined"
                                    && response.data.processFlow.length > 0){
                                        response.data.processFlow.forEach((flow) => {
                                            processFlowStatus.push({processId:flow.processId,processSyncFlag:flow.processSyncFlag, studyId: flow.studyId});
                                        });
                                        that.props.processFlowList.forEach((processFlow, index) => {
                                            response.data.processFlow.forEach((flow) => {
                                            if (processFlow.process.studyId === flow.studyId && processFlow.process.processId === flow.processId) {
                                                that.props.updateProcessFlowSyncFlagByTabIndex(index, flow.processSyncFlag);
                                            }});
                                            });
                                    }

                                    that.updateFlowIconInTreeView(processFlowStatus);
                                    if(response.data.processBlockLink !== null && typeof(response.data.processBlockLink) !== "undefined"
                                    && response.data.processBlockLink.length > 0){
                                    that.props.processFlowList.forEach((processFlow, index) => {
                                        response.data.processBlockLink.forEach((link) => {
                                        if (processFlow.process.studyId === link.studyId && processFlow.process.processId === link.processId) {
                                            that.props.removeProcessFlowBlockLinkByTabIndex(index, link.linkId);
                                        }});
                                        });
                                    }
                                    that.setState({key:that.state.key+1});
                                  }

                                  that.props.setMessage("Process block have been deleted Successfully!", MessageTypeConst.SUCCESS_MESSAGE);
                                  $(".loader").hide();
                                })
                            ).catch(error => {
                                $(".loader").hide();
                                that.props.setMessage("PROCESS BLOCK deletion Failed!",MessageTypeConst.ERROR_MESSAGE);
                              });
                        })
                    }
                }
                else if(seleced_value === 'block_options_copy'){
                    // Copy diagrams
                    that.props.selectItemsForCopyPaste(dataNew);

                    that.props.setMessage("PROCESS BLOCK(s) have been copied Successfully!", MessageTypeConst.SUCCESS_MESSAGE);
                }
                else if (seleced_value === 'timeline_profile') {
                  window.location.hash='#timeline-profile';
                }
                else if (seleced_value === 'manage_version') {
                    window.location.hash='#manageVersion';
                }
                else if (seleced_value === 'export_object') {
                    that.setState({exportNode:true})
                }
                else if (seleced_value === 'execute_all_(batch)') {
                    window.location.hash='#executeallblock';
                } else if (seleced_value === 'execute_all_(interactive)') {
                    window.location.hash='#executeallinteractiveblock';
                } else if (seleced_value === 'auto_mapper') {
                    window.location.hash='#annotate?type=mapper';
                } else if (seleced_value === 'auto_tlf') {
                    window.location.hash='#annotate?type=tlf';
                } else if(seleced_value === "edit_notes") {
                    My_this.editNotes();
                }else if (seleced_value === "delete_notes") {
                    My_this.deleteNotes();
                }else if (seleced_value === 'create_task'){
                  let selectedBlock = selectedProcessBlocks.find(a=>parseInt(a.blockId,10) === parseInt(selectnodeid,10));
                  let triggerNode = {blockId: selectnodeid, blockName: selectedBlock.blockName, blockProcessId: selectedBlock.processId, schemaName: schemaName};
                  $(".loader").show();
                  that.handleCreateTaskDialogClick(e, triggerNode)
                } else {
                  global.updateBlock = false;
                  window.location.hash='#data-operation';
                }
            }
        },
        open: function (e) {
            if(e.target.children !== null && typeof(e.target.children) !== 'undefined' && e.target.children.length > 0){
                //if block is not selected then fill is white (#ffffff) so prevent opening of context menu
                if(e.target.children[0].getAttribute("fill") === "#ffffff"){
                    e.preventDefault();
                }
            }

            if(dataNew.length === 0){
                e.preventDefault();
            }
            else{
                var selectnoteLength=0;
                let selectedTables = [];
                let selectedBlockIds = [];
                var processBlock = that.getProcessFlowBlocks();
                var processBlockLink = that.getProcessFlowBlockLinks();
                var isDocumentAutoBlock = false;
                if(selectnote !== null && typeof(selectnote) !== "undefined" && selectnote !== ""){
                    selectedTables = selectnote.split(",");
                }
                if(selectnodeid !== null && typeof(selectnodeid) !== "undefined" && selectnodeid !== ""){
                    selectnoteLength = selectnodeid.split(",").length; //processflow block seletion length...
                    selectedBlockIds = selectnodeid.split(",");
                }

                $(document).ready(function(){
                    if(isNotesSelected){
                        $(".notForNotes").addClass("enable-false");
                    }else{
                        $(".notForBlocks").addClass("enable-false");
                    }
                    if(selectnoteLength === 1){
                        $(".selectionPF1").addClass("enable-false");
                        var actionBlock = {};

                        processBlock.forEach((pblock, index) => {
                            if (parseInt(selectnodeid, 10) === parseInt(pblock.blockId, 10)) {
                                actionBlock = pblock;
                            }
                        })

                        var links = processBlockLink.filter((link)=> link.blockId2 === actionBlock.blockId);
                        var allParentBlockPersistent = true;
                        var allParentBlockValid = true;

                        if (links.length > 0 && actionBlock.blockCreationType === BlockCreationType.MAP_ANNOTATE) {
                          isDocumentAutoBlock = true;
                        }

                        links.forEach((link)=>{
                            var pBlock = processBlock.filter(block=>block.blockId === link.blockId1);
                            if(pBlock.length > 0 && pBlock[0].blockSyncFlag === BlockStatus.INVALID){
                                allParentBlockPersistent = false;
                                allParentBlockValid = false;
                            }
                        })

                        if(!allParentBlockPersistent && !allParentBlockValid && (actionBlock.blockSyncFlag === BlockStatus.INVALID || actionBlock.blockSyncFlag === BlockStatus.WARNING)){
                            $(".disableIfParentNotPersistent").addClass("enable-false");
                        }
                        if(parseInt(actionBlock.blockLevel, 10) === 0 && actionBlock.blockType !== BlockType.MAP_ANNOTATE){
                            $(".notInRoot").addClass("enable-false");
                        }
                        if(parseInt(actionBlock.tableExists, 10) === 0 && actionBlock.blockType !== 2){
                            $(".notHavingData").addClass("enable-false");
                        }
                        if(typeof(processBlockLink) !== "undefined" && processBlockLink !== null){
                            let forCodeProcReportMApAnnoate = true;
                            let blockLinks = processBlockLink.filter(link=>link.blockId2 === actionBlock.blockId);
                            blockLinks.forEach((plink)=>{
                                if(//plink.dataop !== DataOpsIdentifierConstants.CODEEDITOR_FILE_OP &&
                                    //plink.dataop !== DataOpsIdentifierConstants.PROCREPORT_ANALYSIS_CHAR_OP &&
                                    //plink.dataop !== DataOpsIdentifierConstants.PROCREPORT_ANALYSIS_NUM_OP &&
                                    //plink.dataop !== DataOpsIdentifierConstants.R_Report_OP &&
                                    plink.dataop !== DataOpsIdentifierConstants.MAP_ANNOTATE_OP &&
                                    plink.dataop !== DataOpsIdentifierConstants.TIMELINE_OP){
                                        forCodeProcReportMApAnnoate = false;
                                }
                            });
                            if(actionBlock.inputTableCount !== blockLinks.length){
                                $(".invalidInputLinks").addClass("enable-false");
                            }
                            let parentBlocks = [];
                            blockLinks.forEach((blocklink)=>{
                                parentBlocks.push(...processBlock.filter(block => block.blockId === blocklink.blockId1));
                            });
                            if(parentBlocks.length > 0){
                                /*let allparentHaveData = true;
                                parentBlocks.forEach((parentblock)=>{
                                    if(parentblock.tableExists === 0){
                                        allparentHaveData = false;
                                    }
                                });
                                if(!allparentHaveData && !forCodeProcReportMApAnnoate){
                                    $(".notHavingDataForUpdate").addClass("enable-false");
                                }*/
                            }
                        }else{
                            $(".notHavingDataForUpdate").addClass("enable-false");
                        }
                        if(actionBlock.blockSyncFlag === BlockStatus.INVALID || actionBlock.blockSyncFlag === BlockStatus.WARNING){
                            $(".invalidBlockDisable").addClass("enable-false");
                        }
                        if(actionBlock.blockSyncFlag === BlockStatus.INVALID){
                            $(".notForOnlyInvalidBlock").addClass("enable-false");
                        }
                        if(actionBlock.blockSyncFlag === BlockStatus.WARNING){
                            $(".warningBlockDisable").addClass("enable-false");
                        }
                        if(actionBlock.blockSyncFlag === BlockStatus.VIRTUAL){
                            $(".virtualBlockDisable").addClass("enable-false");
                        }
                        if(actionBlock.blockCreationType === BlockCreationType.DATA_LINK){
                            $(".notForLinkDataBlock").addClass("enable-false");
                        }
                        if(actionBlock.blockCreationType === BlockCreationType.DATA_COPY){
                            $(".notForCopyDataBlock").addClass("enable-false");
                        }
                        if(actionBlock.blockCreationType === BlockCreationType.IMPORT_SOURCE_DOCUMENT){
                            $(".notForStudyDocumentBlock").addClass("enable-false");
                        }
                        if(actionBlock.blockCreationType === BlockCreationType.DATA_COPY){
                            $(".notForCopyDataBlock").addClass("enable-false");
                        }
                        if(actionBlock.blockCreationType === BlockCreationType.MAP_ANNOTATE){
                            $(".notForMapAnnotateBlock").addClass("enable-false");
                        }
                        if(actionBlock.blockCreationType === BlockCreationType.TIMELINE){
                            $(".notForTimelineBlock").addClass("enable-false");
                        }
                        if(actionBlock.blockType === 2 || actionBlock.blockType === 5){
                            $(".notForFileTypeBlock").addClass("enable-false");
                        }
                        if(actionBlock.blockType === 1){
                            $(".notForDataTypeBlock").addClass("enable-false");
                        }
                        if(typeof(global.login_token) !== 'undefined' && !(that.state.isFlowCheckedOut  && that.state.isFlowCheckedOutByLoggedInUser)){
                            $(".notForCheckedOutUser").addClass("enable-false");
                        }

                        if (isDocumentAutoBlock) {
                          $(".notForDocumentMapAnnotateBlock").removeClass("enable-false")
                        }

                        if (typeof(actionBlock.blockTable) !== 'undefined' && actionBlock.blockTable.indexOf('.pdf') === -1 && actionBlock.blockTable.indexOf('.docx') === -1 && actionBlock.blockCreationType === BlockCreationType.IMPORT_SOURCE_DOCUMENT) {
                          $(".notHavingPDFFileDocument").addClass("enable-false");
                        }

                        if (typeof(actionBlock.blockTable) !== 'undefined' && actionBlock.blockTable.indexOf('.docx') === -1 && actionBlock.blockCreationType === BlockCreationType.IMPORT_SOURCE_DOCUMENT) {
                          $(".havingDOCXFileDocument").addClass("enable-false");
                        }

                        if (typeof(actionBlock.blockTable) !== 'undefined' && actionBlock.blockTable.indexOf('.docx') > -1 && actionBlock.blockCreationType === BlockCreationType.IMPORT_SOURCE_DOCUMENT) {
                          $(".notHavingDOCXFileDocument").addClass("enable-false");
                        }
                        /*if(actionBlock.blockCreationType == BlockCreationType.DEF_COPY && actionBlock.blockSyncFlag == BlockStatus.WARNING){
                            $(".notForCopyDefBlockWithWarning").addClass("enable-false");
                        }*/

                        if (actionBlock.blockSyncFlag === BlockStatus.INVALID) {
                          $(".isInvalidBlock").removeClass("enable-false");
                        }
                    }
                    else if(selectnoteLength === 2){
                        $(".selectionPF2").addClass("enable-false");
                        if(typeof(global.login_token) !== 'undefined' && !(that.state.isFlowCheckedOut  && that.state.isFlowCheckedOutByLoggedInUser)){
                            $(".notForCheckedOutUser").addClass("enable-false");
                        }
                        let allBlocksHaveData = true;
                        let blockNames = [];
                        let isDcumentBlock = false;
                        let isAutoBlock = false;
                        let isFoundInValid = false;
                        processBlock.forEach((pblock, index) => {
                            selectedBlockIds.forEach((id) => {
                                if (parseInt(id, 10) === parseInt(pblock.blockId, 10)) {
                                    if(pblock.tableExists === 0){
                                        blockNames.push(pblock.blockName);
                                        allBlocksHaveData = false;
                                        if(pblock.blockCreationType === BlockCreationType.MAP_ANNOTATE){
                                            $(".notForMapAnnotateBlock").addClass("enable-false");
                                            isAutoBlock = true;
                                        }
                                        if(pblock.blockCreationType === BlockCreationType.TIMELINE){
                                            $(".notForTimelineBlock").addClass("enable-false");
                                        }
                                        if(pblock.blockCreationType === BlockCreationType.IMPORT_SOURCE_DOCUMENT){
                                          isDcumentBlock = true;
                                        }
                                    }
                                    if(pblock.blockCreationType === BlockCreationType.DEF_COPY && pblock.blockSyncFlag === BlockStatus.WARNING){
                                        $(".notForCopyDefBlockWithWarning").addClass("enable-false");
                                    }
                                    if(pblock.blockSyncFlag === BlockStatus.INVALID || pblock.blockSyncFlag === BlockStatus.WARNING){
                                        $(".invalidBlockDisable").addClass("enable-false");
                                    }
                                    if(pblock.blockSyncFlag === BlockStatus.INVALID){
                                        $(".notForOnlyInvalidBlock").addClass("enable-false");
                                        isFoundInValid = true;
                                    }
                                  }
                            });
                        })

                        if(!allBlocksHaveData){
                            $(".notHavingData").addClass("enable-false");
                        }

                        if (isDcumentBlock === true && isAutoBlock === true) {
                          $(".documentAutoPairBlock").removeClass("enable-false");
                        }

                        if (selectedBlockIds.length === 2 && processBlockLink.filter(blockLink => (blockLink.blockId1 == selectedBlockIds[0] && blockLink.blockId2 == selectedBlockIds[1]) || (blockLink.blockId1 == selectedBlockIds[1] && blockLink.blockId2 == selectedBlockIds[1])).length > 0) {
                          $(".inValidLink").addClass("enable-false");
                        } else {
                          $(".inValidLink").removeClass("enable-false");
                        }
                    }
                    else{
                        $(".selectionPF3").addClass("enable-false");
                        if(typeof(global.login_token) !== 'undefined' && !(that.state.isFlowCheckedOut  && that.state.isFlowCheckedOutByLoggedInUser)){
                            $(".notForCheckedOutUser").addClass("enable-false");
                        }
                        let allBlocksHaveData = true;
                        let blockNames = [];
                        processBlock.forEach((pblock, index) => {
                            selectedBlockIds.forEach((id) => {
                                if (parseInt(id,10) === parseInt(pblock.blockId,10)) {
                                    if(pblock.tableExists === 0){
                                        blockNames.push(pblock.blockName);
                                        allBlocksHaveData = false;
                                    }
                                    if(pblock.blockCreationType === BlockCreationType.DEF_COPY && pblock.blockSyncFlag === BlockStatus.WARNING){
                                        $(".notForCopyDefBlockWithWarning").addClass("enable-false");
                                    }
                                    if(pblock.blockSyncFlag === BlockStatus.INVALID || pblock.blockSyncFlag === BlockStatus.WARNING){
                                        $(".invalidBlockDisable").addClass("enable-false");
                                    }
                                    if(pblock.blockSyncFlag === BlockStatus.INVALID){
                                        $(".notForOnlyInvalidBlock").addClass("enable-false");
                                    }
                                  }
                            });
                        })
                        if(!allBlocksHaveData){
                            $(".notHavingData").addClass("enable-false");
                        }
                    }
                });




                if(e.item.id === 'joinQuickItem'){
                    localStorage.setItem('submenucolumns',JSON.stringify([]));
                    let itemsNew = [];
                    let fetchDataViewer = (headers, params) => restClient(GET_ALL, 'quickjoin/column', params, headers);
                    axios.all([fetchDataViewer({'Tenant-ID': localStorage.getItem("Tenant-ID")}, {'sourceTable': selectedTables[0], 'targetTable':selectedTables[1]})])
                            .then(axios.spread((dataViewer) => {
                                if(typeof(dataViewer.data) !== 'undefined' && typeof(dataViewer.data.commonColumns) !== 'undefined') {
                                    let columns = dataViewer.data.commonColumns;
                                    localStorage.setItem('submenucolumns',JSON.stringify(columns));
                                    itemsNew = prepareJoinQuickMenu(columns, $(e.item).attr('actionname'));

                                    var contextMenu = $("#context-menu").kendoContextMenu().data("kendoContextMenu");
                                    contextMenu.append([...itemsNew],$("#joinQuickItemCondition1"));
                                    contextMenu.append([...itemsNew],$("#joinQuickItemCondition2"));
                                    contextMenu.append([...itemsNew],$("#joinQuickItemCondition3"));
                                    contextMenu.append([...itemsNew],$("#joinQuickItemCondition4"));
                                    contextMenu.append([...itemsNew],$("#joinQuickItemCondition5"));
                                    contextMenu.append([...itemsNew],$("#joinQuickItemCondition6"));
                                    contextMenu.append([...itemsNew],$("#joinQuickItemCondition7"));
                                    contextMenu.append([...itemsNew],$("#joinQuickItemCondition8"));
                                }
                                })).catch(error => {
                                    this.props.setMessage("Error occurred while fetching data.",MessageTypeConst.ERROR_MESSAGE);
                                });
                }
                if (e.event) {
                    try {
                        var shapesBox = e.target;

                        if(shapesBox){
                            var items = [
                                        { text: Global_Context.dataOpMenu, cssClass: "hr-view invalidBlockDisable virtualBlockDisable notForCheckedOutUser notForNotes notHavingData selectionPF3 notForStudyDocumentBlock",
                                        items: [
                                            { text: Global_Context.continueDateItem, cssClass: "selectionPF2 selectionPF3 selectable", attr:{ actionname: DataOpsIdentifierConstants.CONTINUOUSDATE_OP} },
                                            { text: Global_Context.dataOpsItem, cssClass: "selectionPF2 selectionPF3 selectable", attr:{ actionname: DataOpsIdentifierConstants.DATAOPS_OP} },
                                            { text: Global_Context.decollapseItem1, cssClass: "selectionPF2 selectionPF3 selectable", attr:{ actionname: DataOpsIdentifierConstants.DECOLLAPSE_ONETABLE_OP} },
                                            { text: Global_Context.pivotItem, cssClass: "selectionPF2 selectionPF3",
                                            items: [
                                                { text : Global_Context.rowToColumn, cssClass: "selectable", attr:{ actionname: DataOpsIdentifierConstants.PIVOT_RTC_OP} },
                                                { text : Global_Context.columnToRow, cssClass: "selectable", attr:{ actionname: DataOpsIdentifierConstants.PIVOT_CTR_OP} },
                                                { text : Global_Context.pivotMatrix, cssClass: "selectable", attr:{ actionname: DataOpsIdentifierConstants.PIVOT_MATRIX} },
                                            ]
                                            },
                                            { text: Global_Context.stringOpsMenu, cssClass: "selectionPF2 selectionPF3",
                                                items: [
                                                    { text: Global_Context.stgOpAlignItem, cssClass: "selectable", attr:{ actionname: DataOpsIdentifierConstants.STRINGOPS_ALIGN_OP} },
                                                    { text: Global_Context.stgOpSpacingItem, cssClass: "selectable", attr:{actionname:DataOpsIdentifierConstants.STRINGOPS_INDUCESPACING_OP} },
                                                    { text: Global_Context.stgOpTrimItem, cssClass: "selectable", attr:{actionname: DataOpsIdentifierConstants.STRINGOPS_TRIMCOMPRESS_OP} },
                                                    { text: Global_Context.stgOpCaseItem, cssClass: "selectable", attr:{actionname: DataOpsIdentifierConstants.STRINGOPS_CHANGECASE_OP} },
                                                    { text: Global_Context.stgOpSearchItem, cssClass: "selectable", attr:{actionname: DataOpsIdentifierConstants.STRINGOPS_SEARCHNREPLACE_OP} },
                                                    { text: Global_Context.stgOpOccItem, cssClass: "selectable", attr:{actionname: DataOpsIdentifierConstants.STRINGOPS_OCCURENCE_OP} },
                                                    { text: Global_Context.stgOpSplitItem, cssClass: "selectable", attr:{actionname: DataOpsIdentifierConstants.STRINGOPS_SPLIT_OP} },
                                                ]
                                            },
                                            { text: Global_Context.tableOpsMenu, cssClass: "selectionPF2 selectionPF3",
                                                items: [
                                                    { text: Global_Context.tbOpRenameItem, cssClass: "selectable", attr:{actionname: DataOpsIdentifierConstants.TABLEOPS_RENAME_OP} },
                                                    { text: Global_Context.tbOpLengthAdjItem, cssClass: "selectable", attr:{actionname: DataOpsIdentifierConstants.TABLEOPS_LENGTH_ADJ_OP} }
                                                ]
                                            },
                                            { text: Global_Context.sortOpsMenuItem, cssClass: "selectionPF2 selectionPF3 selectable", attr:{actionname: DataOpsIdentifierConstants.SORTOPS_OP} },
                                            { text: Global_Context.rankOpsMenuItem, cssClass: "selectionPF2 selectionPF3 selectable", attr:{actionname: DataOpsIdentifierConstants.RANKOPS_OP} },
                                            { text: Global_Context.seqOpsMenuItem, cssClass: "selectionPF2 selectionPF3 selectable", attr:{actionname: DataOpsIdentifierConstants.SEQUQENCEOPS_OP} },
                                            { text: Global_Context.typeConvMenu, cssClass: "selectionPF2 selectionPF3",
                                                items: [
                                                    { text: Global_Context.dtmConvTConItem, cssClass: "selectable", attr:{actionname: DataOpsIdentifierConstants.TYPECONVERSION_DATETIME_OP} },
                                                    { text: Global_Context.cnConvTConItem, cssClass: "selectable", attr:{actionname: DataOpsIdentifierConstants.TYPECONVERSION_CHARNUM_OP} },
                                                ]
                                            },
                                            { text: Global_Context.criteriaOpsItem, cssClass:"selectionPF1 selectionPF3 selectable", attr:{actionname: DataOpsIdentifierConstants.CRITERIAOPS_OP} },
                                            { text: Global_Context.refPtMenu,
                                                items: [
                                                    { text: Global_Context.refPointItem1, cssClass: "selectionPF2 selectionPF3 selectable", attr:{actionname: DataOpsIdentifierConstants.REFERENCEPOINT_ONETABLE_OP} },
                                                    { text: Global_Context.refPointItem2, cssClass: "selectionPF1 selectionPF3 selectable", attr:{actionname: DataOpsIdentifierConstants.REFERENCEPOINT_TWOTABLE_OP} },
                                                    { text: Global_Context.refPtCompItem, cssClass: "selectable selectionPF3", attr:{actionname: DataOpsIdentifierConstants.REFERENCEPOINT_COMPOSITE_OP} },
                                                    { text: Global_Context.retPtReplaceItem, cssClass: "selectable selectionPF3", attr:{actionname: DataOpsIdentifierConstants.REFERENCEPOINT_REPLACE_OP} },
                                                    { text: Global_Context.retPtCombineItem, cssClass: "selectable selectionPF3", attr:{actionname: DataOpsIdentifierConstants.REFERENCEPOINT_COMBINE_OP} },
                                                ]
                                            },
                                            { text: Global_Context.varMappingItem, cssClass: "hr-view selectionPF3 selectable", attr:{actionname: DataOpsIdentifierConstants.VARMAPPING_OP} },
                                            { text: Global_Context.quickAppendMenu, cssClass: "selectionPF1 selectionPF3 selectable",attr:{ actionname:DataOpsIdentifierConstants.APPEND_OP},
                                                /*items: [
                                                    { text: Global_Context.appendDetailItem, cssClass: "selectable" },
                                                   // { text: Global_Context.appendQuickItem, cssClass: "selectable" },
                                                ]*/
                                            },
                                            { text: Global_Context.quickJoinMenu, cssClass:"selectionPF1 selectionPF3 selectable", attr:{id:'joinMenu', actionname:DataOpsIdentifierConstants.JOIN_OP},
                                                /*items: [
                                                    { text: Global_Context.joinDetailItem, cssClass: "selectable" },
                                                     /*{ text: Global_Context.JoinQuickItem, attr:{ actionname:DataOpsIdentifierConstants.JOIN_QUICK_OP, id:'joinQuickItem'},
                                                        items:[{ text: 'Left Join (If A)', attr:{ actionname:DataOpsIdentifierConstants.JOIN_QUICK_OP,id:'joinQuickItemCondition1', joincondition:'Left Join (If A)'},
                                                                items:[]},
                                                                { text: 'Left Join (If A + NOT B)', attr:{ actionname:DataOpsIdentifierConstants.JOIN_QUICK_OP,id:'joinQuickItemCondition2', joincondition:'Left Join (If A + NOT B)'},
                                                                items:[]},
                                                                { text: 'Right Join (If B)', attr:{ actionname:DataOpsIdentifierConstants.JOIN_QUICK_OP,id:'joinQuickItemCondition3', joincondition:'Right Join (If B)'},
                                                                items:[]},
                                                                { text: 'Right Join (If B + NOT A)', attr:{ actionname:DataOpsIdentifierConstants.JOIN_QUICK_OP,id:'joinQuickItemCondition4', joincondition:'Right Join (If B + NOT A)'},
                                                                items:[]},
                                                                { text: 'Full Outer Join (IF A and B EXCEPT INTERSECT)', attr:{ actionname:DataOpsIdentifierConstants.JOIN_QUICK_OP,id:'joinQuickItemCondition5',  joincondition:'Full Outer Join (IF A and B EXCEPT INTERSECT)'},
                                                                items:[]},
                                                                { text: 'Full Outer Join (IF A or B)', attr:{ actionname:DataOpsIdentifierConstants.JOIN_QUICK_OP,id:'joinQuickItemCondition6', joincondition:'Full Outer Join (IF A or B)'},
                                                                items:[]},
                                                                { text: 'Inner Join (IF A and B)', attr:{ actionname:DataOpsIdentifierConstants.JOIN_QUICK_OP,id:'joinQuickItemCondition7', joincondition:'Inner Join (IF A and B)'},
                                                                items:[]},
                                                                { text: 'CARTESIAN Join (A x B)', attr:{ actionname:DataOpsIdentifierConstants.JOIN_QUICK_OP,id:'joinQuickItemCondition8', joincondition:'CARTESIAN Join (A x B)'},
                                                                items:[]}
                                                            ]
                                                     }
                                                    ]*/
                                            },
                                            { text: Global_Context.stgOpSplitItem, cssClass: "hr-view selectionPF2 selectionPF3 selectable",attr:{ actionname:DataOpsIdentifierConstants.SPLIT_OP},
                                                /*items: [
                                                    { text: Global_Context.splitDetailItem, cssClass: "selectable" },
                                                    // { text: Global_Context.splitQuickItem },
                                                ]*/
                                            },
                                            { text: Global_Context.compareItem, cssClass: "selectionPF1 selectionPF3 selectable", attr:{ actionname:DataOpsIdentifierConstants.COMPARE_OP} },
                                            { text: Global_Context.decollapseItem2, cssClass: "selectionPF1 selectionPF3 selectable", attr:{ actionname:DataOpsIdentifierConstants.DECOLLAPSE_TWOTABLE_OP} },
                                            { text: Global_Context.linkItem, cssClass: "hr-view selectionPF1 selectionPF3 selectable", attr:{ actionname:DataOpsIdentifierConstants.LINK_OP} },
                                            { text: Global_Context.codeEditorItem, cssClass: "hr-view selectionPF2 selectionPF3",
                                                items: [
                                                    { text: Global_Context.codeEditorTable, cssClass: "selectable", attr:{ actionname:DataOpsIdentifierConstants.CODEEDITOR_TABLE_OP} },
                                                    { text: Global_Context.codeEditorFile, cssClass: "selectable", attr:{ actionname:DataOpsIdentifierConstants.CODEEDITOR_FILE_OP} },
                                                ]
                                            },
                                            { text: Global_Context.reportOpsMenu, cssClass: "hr-view selectionPF3",
                                                items: [
                                                    { text: Global_Context.descriptiveReporting, cssClass: "selectable", attr:{ actionname:DataOpsIdentifierConstants.REPORTINGOPS_DESCRIPTIVE_OP} },
                                                    { text: Global_Context.categoricalReporting, cssClass: "selectable", attr:{ actionname:DataOpsIdentifierConstants.REPORTINGOPS_CATEGORICAL_OP} },
                                                    { text: Global_Context.categoricalTotalReporting, cssClass: "selectable", attr:{ actionname:DataOpsIdentifierConstants.REPORTINGOPS_CATEGORICAL_TOTAL_OP} },
                                                    { text: Global_Context.categoricalHierarchicalReporting, cssClass: "selectable", attr:{ actionname:DataOpsIdentifierConstants.REPORTINGOPS_CATEGORICAL_HIERARCHICAL_OP} },
                                                    { text: Global_Context.customReportingOps, cssClass: "selectable", attr:{ actionname:DataOpsIdentifierConstants.REPORTINGOPS_CUSTOM_OP} },
                                                ] },
                                                { text: Global_Context.procReportMenu, cssClass: "selectionPF2 selectionPF3 selectable", attr:{ actionname:DataOpsIdentifierConstants.PROCREPORT_ANALYSIS_CHAR_OP}},
                                                { text: Global_Context.R_ReportItem, cssClass: "hr-view selectionPF2 selectionPF3 selectable", attr:{ actionname:DataOpsIdentifierConstants.R_Report_OP}},
                                                { text: Global_Context.GraphItem, cssClass: "hr-view selectionPF2 selectionPF3 selectable", attr:{ actionname:DataOpsIdentifierConstants.GRAPH_OP}},
                                                {text: Global_Context.Interactive, cssClass: "selectionPF3",
                                                  items:[
                                                    { text: Global_Context.RShinny, cssClass: "selectionPF2 selectionPF3 selectable", attr: '' },
                                                    { text: Global_Context.DASH, cssClass: "selectionPF2 selectionPF3 selectable", attr: '' }
                                                  ]
                                                }
                                                // { text: Global_Context.procReportMenu, cssClass: "selectionPF2 selectionPF3",
                                                //     items: [
                                                //         { text: Global_Context.procReportAnalysisCharItem, cssClass: "selectable", attr:{ actionname:DataOpsIdentifierConstants.PROCREPORT_ANALYSIS_CHAR_OP} },
                                                //         { text: Global_Context.procReportAnalysisNumItem, cssClass: "selectable", attr:{ actionname:DataOpsIdentifierConstants.PROCREPORT_ANALYSIS_NUM_OP} },
                                                //     ] },
                                        ]
                                    },
                                    /*{
                                        text:Global_Context.ExecuteAllItem,cssClass:"selectionPF2 selectionPF3 selectable notForNotes virtualBlockDisable notInRoot notForCheckedOutUser notForStudyDocumentBlock notForMapAnnotateBlock notForTimelineBlock invalidInputLinks notForOnlyInvalidBlock"
                                    },*/
                                    {
                                        text:Global_Context.ExecuteAllItemInteractive,cssClass:"hr-view selectionPF2 selectionPF3 selectable notForNotes virtualBlockDisable notInRoot notForCheckedOutUser notForStudyDocumentBlock notForMapAnnotateBlock notForTimelineBlock invalidInputLinks notForOnlyInvalidBlock isInvalidBlock"
                                    },
                                    {
                                        text:Global_Context.ExportItem,cssClass:"hr-view selectionPF2 selectionPF3 selectable notForNotes virtualBlockDisable notForMapAnnotateBlock notForTimelineBlock"
                                    },
                                    {
                                        text:Global_Context.ManageVersion,cssClass:"hr-view selectionPF2 selectionPF3 virtualBlockDisable notForNotes selectable notForLinkDataBlock notForCopyDataBlock notForStudyDocumentBlock"
                                    },
                                    { text: Global_Context.viewMenu,cssClass: "selectionPF2 selectionPF3 notForNotes virtualBlockDisable notForMapAnnotateBlock notForTimelineBlock notForDocumentMapAnnotateBlock",
                                        items: [
                                            { text: Global_Context.viewDataItem, cssClass: "selectable selectionPF2 selectionPF3 notHavingData notForFileTypeBlock"  },
                                            { text: Global_Context.viewReportItem, cssClass: "selectable selectionPF2 selectionPF3 notHavingData notForDataTypeBlock notForDocumentMapAnnotateBlock"  },
                                            { text: Global_Context.viewExtItem , cssClass: "selectionPF2 selectionPF3 selectable notHavingData notForFileTypeBlock"},
                                            { text: Global_Context.viewCodeItem, cssClass: "selectionPF2 selectionPF3 notInRoot selectable notForMapAnnotateBlock" },
                                            { text: Global_Context.viewLogItem, cssClass: "selectionPF2 selectionPF3 notInRoot selectable notForMapAnnotateBlock" },
                                        ]
                                    },
                                    { text: Global_Context.timelineChartNGItem, cssClass: "hr-view selectable invalidBlockDisable notForNotes virtualBlockDisable notForCheckedOutUser notHavingData notForStudyDocumentBlock",  },
                                    { text: Global_Context.mapAndAnnotateItem, cssClass: "hr-view selectionPF2 selectionPF3 notHavingData notForDataTypeBlock notHavingPDFFileDocument",
                                      items: [
                                        { text: Global_Context.autoMappingItem, cssClass: "hr-view selectable selectionPF2 selectionPF3 notHavingData notForDataTypeBlock notHavingPDFFileDocument notHavingDOCXFileDocument" },
                                        { text: Global_Context.autoTableItem , cssClass: "hr-view selectable selectionPF2 selectionPF3 notHavingData notForDataTypeBlock notHavingPDFFileDocument havingDOCXFileDocument"},
                                      ]
                                    },
                                    { text: Global_Context.addLinkItem, cssClass: "selectionPF1 selectionPF3 selectable virtualBlockDisable notForNotes notForCheckedOutUser notForStudyDocumentBlock notForMapAnnotateBlock notForTimelineBlock documentAutoPairBlock inValidLink" },
                                    { text: Global_Context.deleteLinkItem, cssClass: "selectionPF1 selectionPF2 virtualBlockDisable selectionPF3 notForNotes selectable notForCheckedOutUser notForStudyDocumentBlock" },
                                    { text: Global_Context.updateItemBlock, cssClass: "hr-view notInRoot virtualBlockDisable notForLinkDataBlock notForNotes notForCopyDataBlock disableIfParentNotPersistent selectable selectionPF2 selectionPF3 notHavingDataForUpdate notForStudyDocumentBlock invalidInputLinks isInvalidBlock" },
                                    { text: Global_Context.blockOpMenu, cssClass:"hr-view notForNotes",
                                        items: [
                                            { text: Global_Context.copyBlockItem, cssClass: "selectable notForStudyDocumentBlock virtualBlockDisable" },
                                            { text: Global_Context.deleteBlockItem , cssClass: "selectable selectionPF2 selectionPF3 notForCheckedOutUser"},
                                            //{ text: Global_Context.pasteBlockItem, cssClass: "selectable" },
                                            /*{ text: Global_Context.groupBlockItem },
                                            { text: Global_Context.ungroupBlockItem },*/
                                        ]
                                    },
                                    { text: Global_Context.exportItem, cssClass: "selectionPF1 selectionPF2 selectionPF3 selectable virtualBlockDisable notForNotes notHavingData notForStudyDocumentBlock" },
                                    { text: Global_Context.createTaskItem, cssClass: "hr-view selectionPF2 selectionPF3 selectable notForNotes" },
                                    { text: Global_Context.propertiesItem, cssClass: "selectionPF2 selectionPF3 selectable notForNotes" },
                                    { text: Global_Context.editNotesItem, cssClass: "selectionPF2 selectionPF3 selectable notForBlocks notForCheckedOutUser"},
                                    { text: Global_Context.deleteNotesItem, cssClass: "selectionPF2 selectionPF3 selectable notForBlocks notForCheckedOutUser"},
                                ]

                                if (that.getWriteAcStatus(schemaName) === false) {
                                    items = items.filter(item => item.text !== Global_Context.blockOpMenu);
                                }
                            this.setOptions({
                                dataSource: items
                            })
                        }

                    } catch (e) {
                    }
                }
            }
        }
    });
}

    function allowUpdateBlock(actionBlock, that){
        let allowUpdateBlock = true;
        let processBlock = that.getProcessFlowBlocks();
        let processBlockLink = that.getProcessFlowBlockLinks();
        let links = processBlockLink.filter((link)=> link.blockId2 === actionBlock.blockId);
        let allParentBlockPersistent = true;
        let allParentBlockValid = true;
        let parentBlocks = [];
        if(actionBlock.inputTableCount !== links.length){
            allowUpdateBlock = false;
            that.props.setMessage("Block can't be updated, as input block(s) are missing.",MessageTypeConst.WARNING_MESSAGE);
        }
        let forCodeProcReportMApAnnoate = true;
        links.forEach((plink)=>{
            let pBlock = processBlock.filter(block=>block.blockId === plink.blockId1);
            if(pBlock.length > 0 && pBlock[0].blockSyncFlag === BlockStatus.INVALID){
                allParentBlockPersistent = false;
                allParentBlockValid = false;
            }

            if(//plink.dataop !== DataOpsIdentifierConstants.CODEEDITOR_FILE_OP &&
               // plink.dataop !== DataOpsIdentifierConstants.PROCREPORT_ANALYSIS_CHAR_OP &&
               // plink.dataop !== DataOpsIdentifierConstants.PROCREPORT_ANALYSIS_NUM_OP &&
               // plink.dataop !== DataOpsIdentifierConstants.R_Report_OP &&
                plink.dataop !== DataOpsIdentifierConstants.MAP_ANNOTATE_OP &&
                plink.dataop !== DataOpsIdentifierConstants.TIMELINE_OP){
                    forCodeProcReportMApAnnoate = false;
            }
            parentBlocks.push(...processBlock.filter(block => block.blockId === plink.blockId1));
         })
        if(!allParentBlockPersistent && !allParentBlockValid && (actionBlock.blockSyncFlag === BlockStatus.INVALID || actionBlock.blockSyncFlag === BlockStatus.WARNING)){
            allowUpdateBlock = false;
            that.props.setMessage("Block can't be updated, as input block(s) are invalid.",MessageTypeConst.WARNING_MESSAGE);
        }
        /*if(parentBlocks.length > 0){
            let allparentHaveData = true;
            parentBlocks.forEach((parentblock)=>{
                if(parentblock.tableExists === 0){
                    allparentHaveData = false;
                }
            });
            if(!allparentHaveData && !forCodeProcReportMApAnnoate){
                allowUpdateBlock = false;
                that.props.setMessage("Block can't be updated, as input block(s) does not have data.",MessageTypeConst.WARNING_MESSAGE);
            }
        }*/
        return allowUpdateBlock;
    }

    function handleDelete(){
        let that = My_this;
        // short cut key Delete diagrams
        if(typeof(global.login_token) !== 'undefined' && !(that.state.isFlowCheckedOut && that.state.isFlowCheckedOutByLoggedInUser)){
            that.props.setMessage("Please check-out Process Flow before performing delete operation.", MessageTypeConst.WARNING_MESSAGE);
            return;
        }

        if((selectedProcessBlocks.length > 0 && selectedLinks.length > 0)
        || (selectedProcessBlocks.length > 0 && selectedNotes.length > 0)
        || (selectedProcessBlocks.length > 0)){
            deleteBlockList(selectedProcessBlocks);
            return;
        }
        else if((selectedNotes.length > 0 && selectedLinks.length > 0) || (selectedNotes.length > 0)){
            deleteNotesList(selectedNotes);
            return;
        }else if(selectedLinks.length > 0){
            deleteLinkList(selectedLinks);
            return;
        }
    }

    function deleteNotesList(){
        let that = My_this;
        ConfirmBox.open("Are you sure you want to delete notes ?").then(function () {
            $(".loader").show();
            let notesIds = [];
            for(let k=0;k<selectedNotes.length;k++){
                notesIds.push(selectedNotes[k].noteId);
            }
            axios.all([restClient(POST_PARAM_BODY, 'notes/delete', {data:notesIds}, {'Tenant-ID': My_this.props.tenantId})])
            .then(axios.spread((response) => {
              $(".loader").hide();
              if(response.data.messageType !== MessageTypeConst.SUCCESS_MESSAGE){
                that.props.setMessage(response.data.message, response.data.messageType);
              } else{
                for(let m=0;m<notesIds.length;m++){
                    that.props.removeProcessFlowNoteByTabIndex(that.state.selected, parseInt(notesIds[m], 10));
                    let shape = flowDiagram.getShapeById("n-"+notesIds[m]);
                    if(shape !== null && typeof(shape) !== 'undefined' && shape.options.shapeType === "notes"){
                        flowDiagram.remove(shape);
                    }
                }
                that.setState({key:that.state.key+1});
                that.props.setMessage(response.data.message, MessageTypeConst.SUCCESS_MESSAGE);
              }
              selectedNotes = [];
              flowDiagram.select(null);
            })).catch(error => {
                selectedNotes = [];
                flowDiagram.select(null);
                $(".loader").hide();
                that.props.setMessage("Process Notes deletion Failed!", MessageTypeConst.ERROR_MESSAGE);
              });
        });

    }

    function deleteLinkList(){
        let that = My_this;
        ConfirmBox.open(`Are you sure you want to delete blocks links ?`).then(function () {
            $(".loader").show();
            let blocklinks = [];
            let flow = that.props.processFlowList[that.state.selected];
            if(flow !== null && flow.process !== null &&
                flow.process.processBlockLink !== null && flow.process.processBlockLink.length > 0){
                    for(let k=0;k<selectedLinks.length;k++){
                        let links = flow.process.processBlockLink.filter((item) => item.blockId1 === selectedLinks[k].blockId1
                        && item.blockId2 === selectedLinks[k].blockId2);
                        if(links.length > 0){
                            blocklinks.push(links[0]);
                        }
                    }
            }
            axios.all([restClient(POST_PARAM_BODY, 'link/deletelinks', {data: blocklinks}, {'Tenant-ID': My_this.props.tenantId})])
            .then(axios.spread((response) => {
              $(".loader").hide();
              let processFlowStatus = [];
              if(response.data.messageType !== MessageTypeConst.SUCCESS_MESSAGE){
                that.props.setMessage(response.data.message, response.data.messageType);
              } else{
                  if(response.data.processBlock !== null && typeof(response.data.processBlock) !== "undefined" &&
                      response.data.processBlock.length > 0){
                      that.props.updateProcessFlowBlockBySchemaName(My_this.props.tenantId, response.data.processBlock);
                  }
                  if(response.data.processFlow !== null && typeof(response.data.processFlow) !== "undefined"
                  && response.data.processFlow.length > 0){
                      response.data.processFlow.forEach((flow) => {
                          processFlowStatus.push({processId:flow.processId,processSyncFlag:flow.processSyncFlag, studyId: flow.studyId})
                      });

                      that.props.processFlowList.forEach((processFlow, index) => {
                          response.data.processFlow.forEach((flow) => {
                          if (processFlow.process.studyId === flow.studyId && processFlow.process.processId === flow.processId) {
                              that.props.updateProcessFlowSyncFlagByTabIndex(index, flow.processSyncFlag);
                          }});
                          });
                  }
                  that.updateFlowIconInTreeView(processFlowStatus);
                  for(let k=0;k<blocklinks.length;k++){
                    that.props.removeProcessFlowBlockLinkByTabIndex(that.state.selected,blocklinks[k].linkId);
                  }
                  that.setState({key:that.state.key+1});
                  that.props.setMessage("Process block link(s) have been deleted successfully!",MessageTypeConst.SUCCESS_MESSAGE);
              }
              selectedLinks = [];
              flowDiagram.select(null);
            })).catch(error => {
                selectedLinks = [];
                flowDiagram.select(null);
                $(".loader").hide();
                that.props.setMessage("Failed to delete process block link(s).", MessageTypeConst.ERROR_MESSAGE);
              });
        });
    }

    function deleteBlockList(blockLists){
        let that = My_this;
        let blockIds = [];
        let blockName = [];
        for(let k=0;k<blockLists.length;k++){
            blockIds.push(blockLists[k].blockId);
            blockName.push(`'${blockLists[k].blockName}'`);
        }
        ConfirmBox.open(`Are you sure you want to delete blocks ${blockName.join(', ')} ?`).then(function () {
            $(".loader").show();
            var params = {
                studyId:blockLists[0].studyId,
                processId:blockLists[0].processId
            }
            axios.all([restClient(POST_PARAM_BODY, `processblock/deleteBlocks`, {data: blockIds,params:params}, {'Tenant-ID': My_this.props.tenantId})])
            .then(axios.spread((response) => {
              $(".loader").hide();
              let processFlowStatus = [];
              if(response.data.messageType !== MessageTypeConst.SUCCESS_MESSAGE){
                that.props.setMessage(response.data.message, response.data.messageType);
              } else{
                if(response.data.processBlock !== null && typeof(response.data.processBlock) !== "undefined"){
                    for(let k=0; k<blockIds.length; k++){
                        let blockExists = response.data.processBlock.filter(block=>block.blockId ===  blockIds[k]);
                        if(blockExists.length <=  0){
                            let blockId = blockIds[k];
                            that.props.removeProcessFlowBlockByTabIndex(that.state.selected, parseInt(blockId, 10));
                            let shape = flowDiagram.getShapeById(parseInt(blockId, 10));
                            if(shape !== null && typeof(shape) !== 'undefined'){
                                shape.connections().forEach((connection) => {
                                    flowDiagram.remove(connection);
                                })
                                flowDiagram.remove(shape);
                            }
                        }
                    }
                    if(response.data.processBlock.length > 0){
                        that.props.updateProcessFlowBlockBySchemaName( My_this.props.tenantId, response.data.processBlock);
                    }
                }
                if(response.data.processFlow !== null && typeof(response.data.processFlow) !== "undefined"
                && response.data.processFlow.length > 0){
                    response.data.processFlow.forEach((flow) => {
                        processFlowStatus.push({processId:flow.processId,processSyncFlag:flow.processSyncFlag, studyId: flow.studyId, isSampleFiltered: flow.isSampleFiltered})
                    });
                    that.props.processFlowList.forEach((processFlow, index) => {
                        response.data.processFlow.forEach((flow) => {
                        if (processFlow.process.studyId === flow.studyId && processFlow.process.processId === flow.processId) {
                            that.props.updateProcessFlowSyncFlagByTabIndex(index, flow.processSyncFlag);
                        }});
                        });
                    }
                that.updateFlowIconInTreeView(processFlowStatus);
                if(response.data.processBlockLink !== null && typeof(response.data.processBlockLink) !== "undefined"
                && response.data.processBlockLink.length > 0){
                that.props.processFlowList.forEach((processFlow, index) => {
                    response.data.processBlockLink.forEach((link) => {
                    if (processFlow.process.studyId === link.studyId && processFlow.process.processId === link.processId) {
                        that.props.removeProcessFlowBlockLinkByTabIndex(index, link.linkId);
                    }});
                    });
                }
                that.setState({key:that.state.key+1});
                that.props.setMessage(response.data.message, MessageTypeConst.SUCCESS_MESSAGE);
                selectedProcessBlocks = [];
                flowDiagram.select(null);
              }
            })).catch(error => {
                selectedProcessBlocks = [];
                flowDiagram.select(null);
                $(".loader").hide();
                that.props.setMessage("Failed to delete process block(s).", MessageTypeConst.ERROR_MESSAGE);
              });
        });
    }

/*
function handleBlocksWithLink(tebnotes, studyId, studyName, processFlowId, that) {
    $(".loader").show();
    localStorage.setItem("deletedTab",-1);
    if(studyName !=null && studyName != undefined && studyName !="" && studyName != "null")
    {
        axios.all([restClient(CREATE, 'processblock/list?studyId='+studyId+'&processId='+processFlowId, {}, {'Tenant-ID': studyName})])
        .then(axios.spread((blockLinksParent) => {
            // + " StudyID : "+ studyId+" , StudyName : "+ studyName+" , ProcessFlowIDID : "+processFlowId
            $("#totalBlock").text(Object.values(blockLinksParent.data).length );
        var blocknode=Object.values(blockLinksParent.data);
            axios.all([restClient(CREATE, 'link/list?studyId='+studyId+'&processId='+processFlowId, {}, {'Tenant-ID': studyName})])
            .then(axios.spread((blockLinks) => {
                        var blockLink = [];
                        if (typeof(blockLinks.data) !== 'undefined') {
                            blockLink = Object.values(blockLinks.data);
                            localStorage.setItem("CurrentProcessFlowTabBlockLink",JSON.stringify(blockLink));

                        }

                        handleBlocksView(blocknode, studyId, studyName, processFlowId, that, blockLink);
                    }
                )
            );
                }
            )
        );
    }
    // $(".loader").hide();
}
*/
function pastePopup(diagram)
{

    var _ = My_this;
    _.handlePasteOpen(diagram)

     // var finalParse = JSON.parse(localStorage.getItem("SelectsItems"));
                    // for (var i = 0; i < finalParse.length; i++) {
                    //     data.push(finalParse[i].options);
                    // }
                    // handleBlocksView(); //reload tab diagrams
}

function prepareJoinQuickMenu(columns, actionname){
    let itemsNew = [];
    columns.forEach((row, index) => {
        let filterArray = columns.filter(item => item !== row);
        let object = {text:row, attr:{actionname:actionname}};
        if(filterArray.length === 0){
            object.items = [{text:'Done', attr:{actionname:actionname}, cssClass: "selectable" }];
        }else{
            object.items =prepareJoinQuickMenu(filterArray, actionname);
        }
        itemsNew.push(object);
        });
    itemsNew.push({text:'Done', attr:{actionname:actionname}, cssClass: "selectable"});
    return itemsNew;
}
function CopyPaste(e, that){
	e = e || window.event;
	var key = e.which || e.keyCode; // keyCode detection
	var ctrl = e.ctrlKey ? e.ctrlKey : ((key === 17) ? true : false); // ctrl detection
    var _ = My_this;
	if ( key === 86 && ctrl ) {
        if(typeof(global.login_token) !== 'undefined' && !(that.state.isFlowCheckedOut && that.state.isFlowCheckedOutByLoggedInUser)){
            that.props.setMessage("Please check-out Process Flow before performing Paste operation.", MessageTypeConst.WARNING_MESSAGE);
            e.preventDefault();
            return;
        }
        var currentDiagram = $('#'+e.target.id).getKendoDiagram();
        _.handlePasteOpen(currentDiagram);
	} else if ( key === 67 && ctrl ) {
        if(dataNew.length === 0){
            dataNew = [];
            that.props.selectItemsForCopyPaste(dataNew);
            return;
        }
        if(dataNew.length > 0 && dataNew.filter(item => typeof(item.noteId) !== "undefined").length >0) {
            dataNew = [];
            that.props.selectItemsForCopyPaste(dataNew);
            return;
        }
        if(dataNew.length > 0 && dataNew.filter(item => item.blockSyncFlag === BlockStatus.VIRTUAL).length >0) {
            dataNew = [];
            that.props.setMessage("Virtual blocks can't be copied.",MessageTypeConst.WARNING_MESSAGE);
            that.props.selectItemsForCopyPaste(dataNew);
            return;
        }

        that.props.setALLProcessFlowRenderStatus(dataNew);

        that.props.selectItemsForCopyPaste(dataNew);
        that.props.setMessage("PROCESS BLOCK(s) have been copied Successfully!",MessageTypeConst.SUCCESS_MESSAGE);
	}
}

const mapStateToProps = createSelector(
  state => state.processFlow.processFlowList,
  state => state.treeView.data,
  state => state.processFlow.tenantId,
  state => state.processFlow.processFlowAction,
  state => state.processFlow.processFlowTable,
  state => state.processFlow.processFlowTableId,
  state => state.processFlow.quickcondition,
  state => state.processFlow.submenucolumns,
  state => state.processFlow.importTable,
  state => state.processFlow.processFlowTitle,
  state => state.processFlow.activeProcessFlow,
  state => state.processFlow.selectsItems,
  state => state.processFlow.refreshToken,
  state => state.login.token_detail,
  state => state.processFlow.jumpToBlockId,
  state => state.processFlow.jumpToProcessId,
  (processFlowList, treeView, tenantId, processFlowAction, processFlowTable, processFlowTableId, quickcondition, submenucolumns, importTable, processFlowTitle, activeProcessFlow, selectsItems, refreshToken, token_detail, jumpToBlockId, jumpToProcessId) => ({
    processFlowList,
    treeView,
    tenantId,
    processFlowAction,
    processFlowTable,
    processFlowTableId,
    quickcondition,
    submenucolumns,
    importTable,
    processFlowTitle,
    activeProcessFlow,
    selectsItems,
    refreshToken,
    token_detail,
    jumpToBlockId,
    jumpToProcessId
  })
);

const mapActionsToProps = {
  removeProcessFlow: removeProcessFlow,
  setTreeViewData: setTreeViewData,
  setTenantId: setTenantId,
  removeProcessFlowBlockByTabIndex: removeProcessFlowBlockByTabIndex,
  addProcessFlowBlockByTabIndex: addProcessFlowBlockByTabIndex,
  addProcessFlowBlockLinkByTabIndex:addProcessFlowBlockLinkByTabIndex,
  setProcessFlowAction: setProcessFlowAction,
  setProcessFlowTable: setProcessFlowTable,
  setProcessFlowTableId: setProcessFlowTableId,
  setQuickCondition: setQuickCondition,
  setSubMenuColumns: setSubMenuColumns,
  updateProcessFlowBlockByTabIndex: updateProcessFlowBlockByTabIndex,
  updateProcessflowBlockRenderStatus: updateProcessflowBlockRenderStatus,
  setActiveProcessFlow: setActiveProcessFlow,
  setALLProcessFlowRenderStatus: setALLProcessFlowRenderStatus,
  selectItemsForCopyPaste: selectItemsForCopyPaste,
  removeProcessFlowBlockLinkByTabIndex: removeProcessFlowBlockLinkByTabIndex,
  refreshProcessFlow: refreshProcessFlow,
  updateProcessFlowBlockBySchemaName : updateProcessFlowBlockBySchemaName,
  updateProcessFlowSyncFlagByTabIndex: updateProcessFlowSyncFlagByTabIndex,
  updateProcessFlowCheckedOutStatusByTabIndex: updateProcessFlowCheckedOutStatusByTabIndex,
  setMessage: setMessage,
  setJumpToBlockIdProcessId: setJumpToBlockIdProcessId,
  removeProcessFlowNoteByTabIndex: removeProcessFlowNoteByTabIndex,
  addProcessFlowNoteByTabIndex: addProcessFlowNoteByTabIndex,
  updateProcessFlowNoteByTabIndex: updateProcessFlowNoteByTabIndex,
  updateProcessFlowNoteBySchemaName: updateProcessFlowNoteBySchemaName,
}

ProccessflowView.propTypes = {
  processFlowList: PropTypes.array,
  treeView: PropTypes.object,
  tenantId: PropTypes.string,
  processFlowAction: PropTypes.string,
  processFlowTable: PropTypes.string,
  quickcondition: PropTypes.string,
  submenucolumns: PropTypes.string,
  importTable: PropTypes.array
}

Dialog.propTypes = {
    height: PropTypes.oneOfType([
        PropTypes.string
    ]),
    width: PropTypes.oneOfType([
        PropTypes.string
    ])
}
export default connect(mapStateToProps, mapActionsToProps)(ProccessflowView);
