import React    from "react";
import { connect } from 'react-redux';
import { createSelector } from 'reselect';
import { Button } from './../DataOperation/common/Button/StandardButton';
import { StandardDropDown } from './../DataOperation/common';
import { addExpression, removeExpression, manageMappedColumnList, setTreeElement } from './../../../actions/actionAnnotate';
import cloneDeep from 'lodash/cloneDeep';
import DrawColumnMappingLine from "./DrawColumnMappingLine";
import MappedColumnValue from "../Annotate/MappedColumnValue";

const groupList = ["Group 1", "Group 2", "Group 3", "Group 4", "Group 5", "Group 6", "Group 7", "Group 8", "Group 9", "Group 10"]

const pivotOutputList = ["Q_VALUE", "Q_NAME", "Q_LABEL"]

class ExpressionDialog extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      node: props.node,
      sourceNodeList: [],
      tableList:[],
      triggerExpressClick: props.triggerExpressClick,
      cancel: props.handleExpressionClick,
      isExpressionButton: typeof(props.isExpressionButton) !== 'undefined' ? true: false,
      isKnowledgeExpression: typeof(props.isKnowledgeExpression) !== 'undefined' ? true: false,
      save: typeof(props.isExpressionButton) !== 'undefined' ? props.handleExpressionSaveClick : '',
      columnMappedList: typeof(props.isExpressionButton) !== 'undefined' ? props.columnNodeMappedList : [],
      isMultipleExpressionDialog: typeof(props.isMultipleExpressionDialog) !== 'undefined' ? props.isMultipleExpressionDialog : false,
      handlePivotMatrixClick: props.handlePivotMatrixClick
    };

    if (typeof(props.node.id) !== 'undefined') {
      let sourceColumnList = this.props.columnMappedList.filter((collumnMap) => collumnMap.node.target === props.node.id);
      let tableList = this.state.tableList;
      let nodeExpression = cloneDeep(this.props.expressionList.filter(expression => expression.id === props.node.id));
      let renameColumnList = this.props.renameColumnList;

      if (this.state.isExpressionButton === true) {
        nodeExpression = [];
        nodeExpression.push(props.expressionDetail);
        sourceColumnList = this.state.columnMappedList.filter((collumnMap) => collumnMap.node.target === props.node.id);
      }

      let reCalculateExpression = true;
      if (nodeExpression.length > 0 && this.state.isKnowledgeExpression === false) {
        //If mapping removed then we need to recalculate expression
        reCalculateExpression = false;
        tableList.length = 0;
        nodeExpression[0].expressionDetail.forEach((exp) => {
          let mappedCol = sourceColumnList.filter((collumnMap) => collumnMap.table.source === exp.tableName);
          if(mappedCol.length !== exp.children.length){
            reCalculateExpression = true;
          }
        });
        if(!reCalculateExpression){
          tableList.push(...nodeExpression[0].expressionDetail)
        }
      }

      if (this.state.isKnowledgeExpression === true) {
        tableList.length = 0;
        tableList.push(...nodeExpression[0].expressionDetail)
      }

      if(reCalculateExpression && this.state.isKnowledgeExpression === false) {
        sourceColumnList.forEach((columnMapped) => {
            let sourceElement = '';
            let tableExpressionType = 'Single';
            let tableOperation = 'No Pivot';
            let tableExpression = '';
            let elementExpression = '';
            let sourceColumnName = '';
            let group = 'Group 1';
            let groupExpressionDetail = [];
            let renameColumnHeader = null;
            let tablePivotOutput = 'Q_VALUE';
            if (columnMapped.mappingType != '') {
              let columnType = columnMapped.mappingType.split('-');
              let renameColumnDetail = renameColumnList.filter(renameColumnDetail => renameColumnDetail.id === columnMapped.node.source);


              if (renameColumnDetail.length > 0){
                renameColumnHeader = renameColumnDetail[0].renameColumn.column;
              }

              if (columnType[0] === 'column' && typeof(columnMapped.column.source.column_header) !== 'undefined') {
                sourceElement =   columnMapped.table.source + '->' + columnMapped.column.source.column_header;
                elementExpression = this.getExpressionString(columnMapped.column.source, columnMapped.column.target, renameColumnHeader);
                sourceColumnName = columnMapped.column.source.column_header;
              } else if(columnType[0] === 'value' && typeof(columnMapped.parentColumn.source.column_header) !== 'undefined')  {
                sourceElement =   columnMapped.table.source + '->' + columnMapped.parentColumn.source.column_header + '->' + columnMapped.columnTitle.source;
                elementExpression = this.getExpressionString(columnMapped.parentColumn.source, columnMapped.parentColumn.target, renameColumnHeader);
                sourceColumnName = columnMapped.parentColumn.source.column_header;
              }

              if (renameColumnDetail.length > 0){
                sourceElement += ' ('+renameColumnDetail[0].renameColumn.column+')';
              }

              if (tableExpressionType === 'Single') {
                tableExpression = elementExpression;
              }
            }

            let table = tableList.filter(table => table.tableName === columnMapped.table.source);

            if (table.length > 0) {
              table[0].children.push({sourceElement: sourceElement,expression: elementExpression, sourceColumnName: sourceColumnName, group: group, renameSourceColumnName: renameColumnHeader, carrySourceColumn: false, whereCondition: ''});
              table[0].children.sort((a,b) => ('' + a.group).localeCompare(b.group));
              table[0].expressionType = 'Multiple';
              table[0].tableOperation = tableOperation;
            } else {
              tableList.push({tableName: columnMapped.table.source, expressionType: tableExpressionType, tableOperation: tableOperation, expression: tableExpression, mappingGrid: [], targetValues:[], children:[{sourceElement: sourceElement,expression: elementExpression, sourceColumnName: sourceColumnName, group:group, renameSourceColumnName: renameColumnHeader}], groupExpressionDetail: groupExpressionDetail, columnPivotMatrix: {}, pivotOutput: tablePivotOutput, columnWhereMatrix: {}, error: false})
            }
        })
      }
    }

    this.handleGroupChange = this.handleGroupChange.bind(this);
    this.handleExpressTypeChange = this.handleExpressTypeChange.bind(this);
    this.handleExpressChange = this.handleExpressChange.bind(this);
    this.handleAddExpressClick = this.handleAddExpressClick.bind(this);
    this.getSingleGroupExpression = this.getSingleGroupExpression.bind(this);
    this.handleOperationChange = this.handleOperationChange.bind(this);
    this.handleRemoveExpressClick = this.handleRemoveExpressClick.bind(this);
    this.handleExpressionSaveClick = this.handleExpressionSaveClick.bind(this);
    this.resetColumnValueMapping = this.resetColumnValueMapping.bind(this);
    this.getExpressionString = this.getExpressionString.bind(this);
  }

  tableElement = (table, index) => {
    return (
      table.children.map((item, index) => this.rowElement(item, index, table))
    )
  }

  getExpressionString = (source, target, renameColumnHeader = null) => {
    let columnHeader = source.column_header;
    let expression = '';

    if (renameColumnHeader != null) {
      columnHeader = renameColumnHeader
    }

    if ((source.column_data_type === 'DATE' || source.column_data_type === 'DATETIME') && target.column_data_type === 'CHAR') {
        expression = 'put('+columnHeader+',is8601'+(source.column_data_type == 'DATE' ? 'da' :'dt')+'.)';
      } else if(source.column_data_type === 'CHAR' && target.column_data_type === 'NUM') {
        expression = 'input('+columnHeader+', best.)';
      } else if(source.column_data_type === 'NUM' && target.column_data_type === 'CHAR') {
        expression = 'put('+columnHeader+', best.-L)'
      } else {
        expression = columnHeader;
      }

      return expression;
  }

  handleExpressTypeChange = (event, tableName, group) => {
    let tableList = this.state.tableList;

    tableList.forEach((table) => {
      if (table.tableName === tableName) {

        let groupExpressionDetail = table.groupExpressionDetail.filter(groupExpressionDetail => groupExpressionDetail.group === group);

        if (groupExpressionDetail.length === 0) {
          table.expressionType = event.value;

          if (event.value === 'Multiple') {
            table.tableOperation = 'Pivot';
          } else {
            table.pivotOutput = 'Q_VALUE';
          }
        } else {
          table.groupExpressionDetail.forEach((groupExpressionDetail) => {
            if (groupExpressionDetail.group === group) {
              groupExpressionDetail.expressionType = event.value;

              if (event.value === 'Multiple') {
                groupExpressionDetail.tableOperation = 'Pivot';
              } else {
                groupExpressionDetail.pivotOutput = 'Q_VALUE';
              }
            }
          })
        }

        if (event.value === 'Single') {
          let expression = this.getSingleGroupExpression(tableName, group);

          table.tableOperation = table.children.length > 1 ?  'No Pivot' : 'Pivot'

          if (groupExpressionDetail.length === 0) {
            table.expression = expression;
          } else {
            table.groupExpressionDetail.forEach((groupExpressionDetail) => {
              if (groupExpressionDetail.group === group) {
                groupExpressionDetail.expression = expression;
              }
            })
          }
        }
      }
    })

    this.setState({ tableList: tableList });
  }

  handleAddExpressClick = (event) => {
    this.props.addExpression({id: this.state.node.id, node: this.state.node, expressionDetail: this.state.tableList.map(table => {table.error = false; return table}), isUserSaved: true});
    this.state.triggerExpressClick(true);
    this.resetColumnValueMapping();
  }

  handleRemoveExpressClick = (event) => {
    this.props.removeExpression(this.state.node.id)
    this.state.triggerExpressClick(false);
    this.resetColumnValueMapping();
  }

  resetColumnValueMapping = () => {
    //let { nodeList } = DrawColumnMappingLine.getState();
    let { targetVariable } = MappedColumnValue.getMappedColumnValueState();
    //nodeList = nodeList.filter(node => node.targetId !== this.state.node.id);
    //DrawColumnMappingLine.setNodeListState(nodeList);
    //this.props.manageMappedColumnList(nodeList);

    if(this.state.node.tableName !== "" && typeof(targetVariable.id) !== 'undefined' && targetVariable.id === this.state.node.id && targetVariable.id !== this.props.lastMappedTargetColumnId) {
      setTimeout(()=>{
        MappedColumnValue.populateSelectedTargetMapping(this.state.node, null, true);
      },1)
    }

    let treeElement = this.props.treeElement;

    if (typeof(treeElement.metadataTableTree) !== 'undefined' && this.state.node.columnCTStatus !== '') {
      treeElement.metadataTableTree.children.filter(table => table.tableName === this.state.node.tableName).map((table) => {
        table.children.filter(column => column.id === this.state.node.id && typeof(column.columnCTStatus) !== 'undefined' && column.columnCTStatus !== '').map((column) => {
          column.columnStatus = column.columnCTStatus === 'No' ? 'red' : 'yellow'
          return column
        })

        return table
      })

      this.props.setTreeElement(treeElement)
    }
  }

  handleExpressChange = (event, tableName, sourceElement, expressionType) => {
    let tableList = this.state.tableList;

    tableList.forEach((table) => {
      if (table.tableName === tableName) {
        if (expressionType === 'Single') {
          table.expression = event.target.value;
        }

        table.children.forEach((tableElement) => {
          if (tableElement.sourceElement === sourceElement) {
            if (expressionType === 'Multiple' || table.children.length === 1) {
            tableElement.expression = event.target.value;
            }

            if(table.groupExpressionDetail.length !== 0){
              let groupExpressionDetail = table.groupExpressionDetail.filter(groupExpressionDetail => groupExpressionDetail.group === tableElement.group);
              if(groupExpressionDetail.length > 0){
                groupExpressionDetail[0].expression = event.target.value;

                if (groupExpressionDetail.length === 1) {
                  tableElement.expression = event.target.value;
                }
              }
            }
          }
        })
      }
    })

    this.setState({ tableList: tableList });
  }

  handleWhereConditionChange = (event, tableName, sourceElement) => {
    let tableList = this.state.tableList;

    tableList.forEach((table) => {
      if (table.tableName === tableName) {
        table.children.forEach((tableElement) => {
          if (tableElement.sourceElement === sourceElement) {
            tableElement.whereCondition = event.target.value;
          }
        })
      }
    })

    this.setState({ tableList: tableList });
  }

  handleGroupChange = (event, tableName, sourceElement) => {
      let tableList = this.state.tableList;

      tableList.forEach((table) => {
        if (table.tableName === tableName) {
          table.children.forEach((row) => {
            if (row.sourceElement === sourceElement) {
              row.group = event.value;
            }

            let groupExpressionDetail = table.groupExpressionDetail.filter(groupExpressionDetail => groupExpressionDetail.group === row.group);

            if (groupExpressionDetail.length == 0) {
              table.groupExpressionDetail.push({group: row.group, expressionType: 'Single', expression: '', tableOperation: table.tableOperation, removeGroup: false })
            }
          })

          table.children.sort((a,b) => ('' + a.group).localeCompare(b.group));

          table.groupExpressionDetail.forEach((groupExpressionDetail) => {
            if (groupExpressionDetail.expressionType === 'Single') {
              groupExpressionDetail.expression = this.getSingleGroupExpression(tableName, groupExpressionDetail.group);
            }

            if (table.groupExpressionDetail.length > 1) {
              groupExpressionDetail.tableOperation = 'Pivot';
            }

            groupExpressionDetail.removeGroup = table.children.filter(row => row.group === groupExpressionDetail.group).length > 0 ? false: true;
          })

          table.groupExpressionDetail = table.groupExpressionDetail.filter(groupExpressionDetail => groupExpressionDetail.removeGroup === false);
        }
      })

      this.setState({ tableList: tableList });
  }

  getSingleGroupExpression = (tableName, group) => {
    let sourceVariableList = this.state.tableList.filter( sourceTable => sourceTable.tableName === tableName);
    let sourceColumnList = this.props.columnMappedList.filter((collumnMap) => collumnMap.node.target === this.state.node.id);
    let expression = '';

    if (this.state.isExpressionButton === true) {
      sourceColumnList = this.state.columnMappedList.filter((collumnMap) => collumnMap.node.target === this.state.node.id);
    }

    if (sourceColumnList.length > 0) {
      let { column, parentColumn, mappingType } = sourceColumnList[0];
      let columnType = mappingType.split('-');
      let targetType = '';
      if (columnType[1] === 'column' && typeof(column.target.column_header) !== 'undefined') {
        targetType = column.target.column_data_type;
      } else if(columnType[1] === 'value' && typeof(parentColumn.target.column_header) !== 'undefined')  {
        targetType = parentColumn.target.column_data_type;
      }

      if (targetType !== '') {
        let expressionList = [];

        if (sourceVariableList.length > 0) {
          sourceVariableList[0].children.filter(sourceElement => sourceElement.group === group).forEach((sourceVariable) => {
            let expressString = sourceVariable.expression;

            if (targetType === 'CHAR' && sourceVariableList[0].children.filter(sourceElement => sourceElement.group === group).length > 1) {
              expressString = 'trim('+expressString+')';
            }

            expressionList.push(expressString);
          })

          if (targetType !== 'CHAR') {
            expression = expressionList.join('+');
          } else {
            expression = expressionList.join('||" "||');
          }
        }
      }
    }

    return expression;
  }

  handleOperationChange = (event, tableName, group) => {
    let tableList = this.state.tableList;

    tableList.forEach((table) => {
      if (table.tableName === tableName) {

        let groupExpressionDetail = table.groupExpressionDetail.filter(groupExpressionDetail => groupExpressionDetail.group === group);

        if (groupExpressionDetail.length === 0) {
          table.tableOperation = event.value;

          if (table.tableOperation !== 'Pivot') {
            table.pivotOutput = 'Q_VALUE';
          }
        } else {
          table.groupExpressionDetail.forEach((groupExpressionDetail) => {
            if (groupExpressionDetail.group === group) {
              groupExpressionDetail.tableOperation = event.value;
              if (groupExpressionDetail.tableOperation !== 'Pivot') {
                groupExpressionDetail.pivotOutput = 'Q_VALUE';
              }
            }
          })
        }
      }
    })

    this.setState({ tableList: tableList });
  }

  handlePivotOutputChange = (event, tableName, group) => {
    let tableList = this.state.tableList;

    tableList.forEach((table) => {
      if (table.tableName === tableName) {

        let groupExpressionDetail = table.groupExpressionDetail.filter(groupExpressionDetail => groupExpressionDetail.group === group);

        if (groupExpressionDetail.length === 0) {
          table.pivotOutput = event.value;
        } else {
          table.groupExpressionDetail.forEach((groupExpressionDetail) => {
            if (groupExpressionDetail.group === group) {
              groupExpressionDetail.pivotOutput = event.value;
            }
          })
        }
      }
    })

    this.setState({ tableList: tableList });
  }

  rowElement = (element, index, table) => {
    let groupSourceElement = table.children.filter( row => row.group === element.group);
    let expressionType = table.expressionType;
    let tableOperation = table.tableOperation;
    let groupExpressionDetail = table.groupExpressionDetail.filter(groupExpressionDetail => groupExpressionDetail.group === element.group);
    let expression = table.expression;
    let sourceTable = null;
    let tableName = table.tableName;
    let sourceElement = element.sourceElement;
    const souceColumnTitle =  element.sourceColumnName+(element.renameSourceColumnName !== null ? "("+element.renameSourceColumnName+")" : '');
    let tablePivotOutput = table.pivotOutput;
    let whereCondition = typeof(element.whereCondition) !== 'undefined' ? element.whereCondition : ''

    if (typeof(tablePivotOutput) === 'undefined') {
      tablePivotOutput = 'Q_VALUE';
    }

    if (groupExpressionDetail.length > 0) {
      expressionType = groupExpressionDetail[0].expressionType;
      tableOperation = groupExpressionDetail[0].tableOperation;
      expression = groupExpressionDetail[0].expression;
    }

    if (typeof(this.props.treeElement.sourceTableTree) !== 'undefined') {
      sourceTable = this.props.treeElement.sourceTableTree.children.filter(sourceTable => sourceTable.tableName === table.tableName);

      /*if (sourceTable.length === 0) {
          sourceTable = this.props.treeElement.leftPersistentTableTree.children.filter(sourceTable => sourceTable.tableName === table.tableName);
      }*/

      if (sourceTable.length > 0) {
        sourceElement = sourceElement.replace(tableName, sourceTable[0].name);
        tableName = sourceTable[0].name;
      }
    }
    return (
      <tr key={`row-${element.sourceElement}-${index}`} className={index === 0 ? 'first-row' : ''}>
        {index===0 &&
          <td rowSpan={table.children.length} className="valign-middle table-name">
            {tableName}
          </td>
        }
        <td>
          {souceColumnTitle}
        </td>
        {/*
          <td className="valign-middle"><StandardDropDown data={groupList} onChange={(e) => this.handleGroupChange(e, table.tableName, element.sourceElement)} value={element.group} /></td>
          */}
        {/*
          <td>
            <input className="am-textbox width-full" onChange={(event) => this.handleWhereConditionChange(event, table.tableName, element.sourceElement)} value={whereCondition}></input>
          </td>
          */}
        {groupSourceElement[0].sourceElement === element.sourceElement &&
          <td rowSpan={groupSourceElement.length} className="valign-middle">
            {groupSourceElement.length > 1 &&
              <StandardDropDown data={["Multiple", "Single"]} onChange={(e) => this.handleExpressTypeChange(e, table.tableName, element.group)} value={expressionType} disabled />
            }

            {groupSourceElement.length === 1 &&
              <StandardDropDown data={["Single"]} onChange={(e) => this.handleExpressTypeChange(e, table.tableName, element.group)} value={expressionType} disabled />
            }
          </td>
        }
        {groupSourceElement[0].sourceElement === element.sourceElement &&
          <td rowSpan={groupSourceElement.length} className="valign-middle">
            {expressionType === 'Single' && (table.groupExpressionDetail.length === 0 || table.groupExpressionDetail.length === 1) &&
              <StandardDropDown data={table.children.length > 1 ? ["No Pivot"] : ["No Pivot", "Pivot"]} onChange={(e) => this.handleOperationChange(e, table.tableName, element.group)} value={tableOperation} disabled/>
            }
            {(expressionType !== 'Single' || table.groupExpressionDetail.length > 1) &&
              <StandardDropDown data={["Pivot"]} onChange={(e) => this.handleOperationChange(e, table.tableName, element.group)} value={tableOperation} disabled />
            }
          </td>
        }
        {groupSourceElement[0].sourceElement === element.sourceElement &&
          <td rowSpan={groupSourceElement.length} className="valign-middle">
            {tableOperation === 'Pivot' &&
              <StandardDropDown data={pivotOutputList} onChange={(e) => this.handlePivotOutputChange(e, table.tableName, element.group)} value={tablePivotOutput} disabled/>
            }
            {tableOperation !== 'Pivot' &&
              <StandardDropDown data={["Q_VALUE"]} onChange={(e) => this.handlePivotOutputChange(e, table.tableName, element.group)} value={tablePivotOutput} />
            }
          </td>
        }
        {groupSourceElement[0].sourceElement === element.sourceElement && expressionType === 'Single' && tableOperation !== 'Pivot' &&
          <td rowSpan={groupSourceElement.length} className="valign-middle">
            {typeof(element.extraExpressionList) !== 'undefined' &&
              <div className="conflict-expression">
                  <div className="input-conflic">
                    <input type="radio" name={`${table.tableName}-${element.sourceElement}`} value={'0'} onChange={(event) => this.handleExtraExpressChange(event, table.tableName, element.sourceElement, -1, 'radio')} checked={element.extraExpressionList.filter(extraExpression => extraExpression.selected === true).length ? false: true}/>
                    <input className={`am-textbox width-full${table.error === true ?' bg-red': ''}`} onChange={(event) => this.handleExpressChange(event, table.tableName, element.sourceElement, expressionType)} value={element.expression}></input>
                  </div>
                  {element.extraExpressionList.map((expression, key) => this.extraExpressionElement(expression, key, table.tableName, element.sourceElement))}
              </div>
            }
            {typeof(element.extraExpressionList) === 'undefined' && typeof(table.expressionConflictStatus) !== 'undefined' && table.expressionConflictStatus === true &&
              <div className="conflict-expression">
                <input className={`am-textbox width-full${table.error === true ?' bg-red': ''}`} onChange={(event) => this.handleExpressChange(event, table.tableName, element.sourceElement, expressionType)} value={expression}></input>
              </div>
            }
            {typeof(element.extraExpressionList) === 'undefined' && (typeof(table.expressionConflictStatus) === 'undefined' || (typeof(table.expressionConflictStatus) !== 'undefined' && table.expressionConflictStatus === false)) &&
              <input className={`am-textbox width-full${table.error === true ?' bg-red': ''}`} onChange={(event) => this.handleExpressChange(event, table.tableName, element.sourceElement, expressionType)} value={expression}></input>
            }
          </td>
        }

        {expressionType === 'Multiple' && false  &&
          <td>
            {typeof(element.extraExpressionList) !== 'undefined' &&
              <div className="conflict-expression">
                  <div className="input-conflic">
                    <input type="radio" name={`${table.tableName}-${element.sourceElement}`} value={'0'} onChange={(event) => this.handleExtraExpressChange(event, table.tableName, element.sourceElement, -1, 'radio')} checked={element.extraExpressionList.filter(extraExpression => extraExpression.selected === true).length ? false: true}/>
                    <input className="am-textbox width-full" onChange={(event) => this.handleExpressChange(event, table.tableName, element.sourceElement, expressionType)} value={element.expression}></input>
                  </div>
                  {element.extraExpressionList.map((expression, key) => this.extraExpressionElement(expression, key, table.tableName, element.sourceElement))}
              </div>
            }
            {typeof(element.extraExpressionList) === 'undefined' &&
              <input className="am-textbox width-full" onChange={(event) => this.handleExpressChange(event, table.tableName, element.sourceElement, expressionType)} value={element.expression}></input>
            }

          </td>
        }

        {groupSourceElement[0].sourceElement === element.sourceElement && tableOperation === 'Pivot' &&
          <td rowSpan={groupSourceElement.length} className="valign-middle">
            <Button primary={true} onClick={(event) => this.handlePivotMatrixClick(table.tableName, this.state.node.tableName)} disabled={this.state.isKnowledgeExpression === true}>Pivot Metrics</Button>
          </td>
        }
      </tr>
    )
  }

  extraExpressionElement = (expression, index, tableName, sourceElement) => {
    return (
      <div key={`extra-expression-${tableName}-${sourceElement}-${index}`} className="input-conflic">
        <input type="radio" name={`${tableName}-${sourceElement}`} value={index+1} onChange={(event) => this.handleExtraExpressChange(event, tableName, sourceElement, index, 'radio')} checked={expression.selected} />
        <input className="am-textbox width-full" onChange={(event) => this.handleExtraExpressChange(event, tableName, sourceElement, index)} value={expression.expression}></input>
      </div>
    )
  }

  handleExtraExpressChange = (event, tableName, sourceElement, index, type = 'input') => {
    let tableList = this.state.tableList;

    tableList.forEach((table) => {
      if (table.tableName === tableName) {
        table.children.forEach((tableElement) => {
          if (tableElement.sourceElement === sourceElement && typeof(tableElement.extraExpressionList) !== 'undefined') {
            if (index > -1 && typeof(tableElement.extraExpressionList[index]) !== 'undefined') {
              if (type === 'input') {
                tableElement.extraExpressionList[index].expression = event.target.value
              } else if (type == 'radio') {
                tableElement.extraExpressionList.map(extraExpression => extraExpression.selected = false);
                tableElement.extraExpressionList[index].selected = true
              }
            } else if (index === -1 && type === 'radio') {
              tableElement.extraExpressionList.map(extraExpression => extraExpression.selected = false);
            }
          }
        })
      }
    })

    this.setState({ tableList: tableList });
  }

  handleExpressionSaveClick(e) {
    this.state.save(e, this.state.tableList);
  }

  handlePivotMatrixClick = (sourceTable, targetTable) => {
    this.state.cancel();
    this.state.handlePivotMatrixClick(sourceTable, targetTable)
  }

  render() {
    return (
      <div className="expression-detail table-inner-scroll">
        <table className="table">
          <thead>
            <tr>
              <th>Source Table</th>
              <th>Source Variables</th>
              {/* <th>Where Condition</th> */}
              {/*<th>Group</th>*/}
              <th>Type</th>
              <th>Operation</th>
              <th>Pivot Output</th>
              <th>Expression</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td colSpan="6">
                <div className="table-scroll">
                  <table>
                    <tbody>
                      {this.state.tableList.map(this.tableElement)}
                    </tbody>
                  </table>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
        <div className="ui-dialog-buttonpane">
        {!this.state.isMultipleExpressionDialog &&
          <div className="ui-dialog-buttonset">
            {this.state.isExpressionButton === true &&
              <div>
                <Button primary={true} onClick={this.handleExpressionSaveClick}>Save</Button>
                <Button onClick={this.state.cancel}>Cancel</Button>
              </div>
            }

            {this.state.isExpressionButton === false &&
              <div>
                <Button primary={true} onClick={this.handleAddExpressClick}>Save</Button>
                {false &&
                  <Button primary={true} onClick={this.handleRemoveExpressClick}>Remove</Button>
                }

                <Button onClick={this.state.cancel}>Cancel</Button>
              </div>
            }
          </div>
        }

      </div>

      </div>
    )
  }
}

const mapStateToProps = createSelector(
  state => state.annotate.columnMappedList,
  state => state.annotate.expressionList,
  state => state.annotate.renameColumnList,
  state => state.annotate.treeElement,
  state => state.annotate.lastMappedTargetColumnId,
  state => state.annotate.lastExecuteEngineValue,
  (columnMappedList, expressionList, renameColumnList, treeElement, lastMappedTargetColumnId, lastExecuteEngineValue) => ({
    columnMappedList,
    expressionList,
    renameColumnList,
    treeElement,
    lastMappedTargetColumnId,
    lastExecuteEngineValue
  })
);
const mapActionsToProps = {
  addExpression: addExpression,
  removeExpression: removeExpression,
  manageMappedColumnList: manageMappedColumnList,
  setTreeElement: setTreeElement
}
export default connect(mapStateToProps, mapActionsToProps)(ExpressionDialog);
