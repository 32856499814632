import React from 'react';
import { ComboBox } from './StandardComboBox';

export class StandardAutoComplete extends React.Component {
  handleChange(e) {
    this.props.onChange({
      data: this.props.data,
      syntheticEvent: e.syntheticEvent,
      value: e.target.value,
      disabled: e.target.disabled
    });
  }

  handleFocusChange(e) {
    if(typeof(this.props.onFocus) !== 'undefined'){
      this.props.onFocus({
        data: this.props.data,
        syntheticEvent: e.syntheticEvent,
        value: e.target.value,
        disabled: e.target.disabled
      });
    }
  }
  render() {
      return (
        <ComboBox className="m-0" onChange={this.handleChange.bind(this)} data={this.props.data} value={this.props.value} disabled={typeof(this.props.disabled) !== 'undefined' ?this.props.disabled : false} onFocus={this.handleFocusChange.bind(this)} />
      );
    }
}
