import React    from "react";
import { connect } from 'react-redux';
import cloneDeep from 'lodash/cloneDeep';
import { createSelector } from 'reselect';
import { DropDownList } from './../DataOperation/common/DropDown/StandardDropDownList';
import { Button } from './../DataOperation/common/Button/StandardButton';
import axios from 'axios';
import $ from 'jquery';
import { GET_ALL, GET_ALL_FROM_BODY_PARAM } from './../../../restClient/types';
import restClient from './../../../restClient';
import TreeView from './../TreeView';
import DrawColumnMappingLine from "./DrawColumnMappingLine";
import { setColumnMappingTargetId, manageMappingGridExpression, addMultipleColumnMappedList, manageMappedColumnList, manageCustomUnmapValueList } from './../../../actions/actionAnnotate';
import { setMessage } from '../../../actions/actionNotification';
import MessageTypeConst from "../../MessageTypeConst";

function removeExtraCharacter(columnValue) {
  return columnValue.replace(/[^a-zA-Z0-9]/g,'_')
}

class BaseMappedColumnValue extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      targetVariable: {
        id: '',
        tableName:'',
        text:''
      },
      targetVariableList: [],
      filtertargetVariableList: [],
      targetVariableValueMapping: [],
      sourceValueTree: {
        name: 'Source Value',
        cssClassName: 'manage-source-tables',
        iconClass: 'fa fa-medkit',
        loading: true,
        toggled: true,
        children: [],
        id:'sourceValue',
        draggable: false
      },
      targetValueTree: {
        name: 'Target Value',
        cssClassName: 'manage-source-tables',
        iconClass: 'fa fa-medkit',
        loading: true,
        toggled: true,
        children: [],
        id:'targetValue',
        draggable: false
      },
      treeKey:0,
      targetTableList:[{id:"All",tableName:''}],
      selectedTargetTable:'',
      displaycolumnValueTree: false,
      dataRefresh: false,
      exactValueList: [],
      sourceTableColumnValueWithExpressionList: []
    }
    this.handleTargetVariableChange = this.handleTargetVariableChange.bind(this);
    this.recursiveSourceTableExpressionColumnList = this.recursiveSourceTableExpressionColumnList.bind(this);
    this.getDisplayTableNameMapping = this.getDisplayTableNameMapping.bind(this);
    MappedColumnValue.populateSelectedTargetMapping = this.populateSelectedTargetMapping.bind(this);
    MappedColumnValue.getMappedColumnValueState = this.getMappedColumnValueState.bind(this);
    MappedColumnValue.removeTargetColumn = this.removeTargetColumn.bind(this);
    this.handleAutoValueMappingClick = this.handleAutoValueMappingClick.bind(this);
    MappedColumnValue.triggerActiveTargetColumnValue = this.triggerActiveTargetColumnValue.bind(this);
    MappedColumnValue.saveCustomUnmapValueList = this.saveCustomUnmapValueList.bind(this)
  }

  fetchTableMultiColumnValues = (headers, params) => restClient(GET_ALL_FROM_BODY_PARAM, 'multiple/mapvalues', params, headers);

  fecthPreference = (headers, params) => restClient(GET_ALL, 'distinct/mapvalues', params, headers);

  fetchValueMappingKnowledge = (headers, params) => restClient(GET_ALL_FROM_BODY_PARAM, 'valueMapper', params, headers);

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (JSON.stringify(nextProps) !== '') {
      let sourceValueTree = this.state.sourceValueTree;
      let targetValueTree = this.state.targetValueTree;
      let targetVariableList = [];
      let targetTableList = [];
      let getDisplayTableNameMapping = this.getDisplayTableNameMapping(typeof(nextProps.tableExtendedList) !== 'undefined' ? nextProps.tableExtendedList : {});
      let selectedTargetTable = this.state.selectedTargetTable;
      let targetVariable = this.state.targetVariable;
      let targetId = null;
      let nextPropsColumnMappedListCount = nextProps.columnMappedList.length;
      let oldColumnMappedListCount = this.props.columnMappedList.length;
      let virtualTargetTableList = typeof(this.props.tableExtendedList.virtualTargetTableList) !== 'undefined' ? this.props.tableExtendedList.virtualTargetTableList : [];
      const oldCustomUnmapValueList = typeof(this.props.customUnmapValueList) !== 'undefined' ? this.props.customUnmapValueList.length : 0
      const nextPropsCustomUnmapValueList = typeof(nextProps.customUnmapValueList) !== 'undefined' ? nextProps.customUnmapValueList.length : 0

      nextProps.columnMappedList.filter(columnMap => columnMap.targetId == null && typeof(columnMap.column.target.blockName) !== 'undefined' && virtualTargetTableList.indexOf(columnMap.column.target.blockName) === -1).forEach((columnMap) => {
        let { node, column, table, mappingType } = columnMap;
        targetVariableList = targetVariableList.filter((target) => target.id !== node.target );
        targetTableList = targetTableList.filter((tableName) => tableName.id !== table.target );

        if (targetId === null && this.state.targetVariable.id === node.target) {
          targetId = node.target;
        }
        let splitNodeSource = mappingType.split('-');

        let valueMapped = (splitNodeSource[0] === 'value') ? true: false;

        if (valueMapped === false && typeof(table.target) !== 'undefined' && typeof(column.target.column_header) !== 'undefined') {
            targetVariableList.push({ id: node.target, text: column.target.column_header, tableName:table.target});
            let displayTableName = getDisplayTableNameMapping.filter(displayTableName => displayTableName.tableName === table.target);
            if (displayTableName.length > 0) {
              displayTableName = displayTableName[0].displayTableName;
            }else{
              displayTableName = table.target;
            }
            targetTableList.push({id:table.target, tableName:displayTableName});
        }
      })

      nextProps.columnMappedList.filter(columnMap => columnMap.targetId != null && columnMap.targetId === targetId).forEach((columnMap) => {
        let { mappingType } = columnMap;

        let splitNodeSource = mappingType.split('-');

        let valueMapped = (splitNodeSource[0] === 'value') ? true: false;

        if (valueMapped === true) {
          sourceValueTree.children.forEach((tableNode) => {
            if (tableNode.tableName === columnMap.table.source) {
              tableNode.children.forEach((columnNode) => {
                columnNode.children.filter(columnValue => columnValue.id === columnMap.node.source && columnNode.name === columnMap.parentColumn.source).forEach((columnValue) => {
                  columnValue.carryForward = false;
                })
              })
            }
          })
        }
      })
      let filtertargetVariableList = targetVariableList;
      if(this.state.selectedTargetTable.tableName !== ""){
        filtertargetVariableList = targetVariableList.filter(item => item.tableName === this.state.selectedTargetTable.id);
      }

      if (nextProps.columnMappedList.length === 0) {

        targetVariableList = [];
        sourceValueTree.children = [];
        sourceValueTree.loading = true;
        sourceValueTree.toggled = true;
        targetValueTree.loading = true;
        targetValueTree.toggled = true;
        targetValueTree.children = [];
        targetTableList = [{id:"All",tableName:''}];
        filtertargetVariableList = [];
        selectedTargetTable = '';
        targetVariable = {
          id: '',
          tableName: '',
          text: ''
        };
        this.setState({targetValueTree: targetValueTree})
      }

      if (typeof(targetVariable.id) !== 'undefined' && targetVariable.id !== '' && targetVariableList.filter(mappedTargetVariable => mappedTargetVariable.id === targetVariable.id).length === 0) {
        targetVariable = { id: '', tableName: '', text: '' }
        selectedTargetTable = '';
      }
      this.setState({ targetVariableList: targetVariableList, sourceValueTree: sourceValueTree, targetTableList: targetTableList, filtertargetVariableList: filtertargetVariableList, targetVariable: targetVariable, selectedTargetTable: selectedTargetTable }, () => {
        let sourceTreeVariableList = [];
        sourceValueTree.children.forEach(sourceTable => {
          sourceTable.children.forEach(sourceVariable => {
            sourceTreeVariableList.push(...sourceVariable.name.split(','))
          })
        })
        if (targetId !== null && nextProps.lastMappedTargetColumnId === targetId && ((this.state.dataRefresh === false && nextPropsColumnMappedListCount === oldColumnMappedListCount && oldCustomUnmapValueList === nextPropsCustomUnmapValueList) || (this.state.dataRefresh === true && nextProps.columnMappedList.filter(columnMap => columnMap.targetId === null && columnMap.node.target === targetId).length !== sourceTreeVariableList.length))) {
          setTimeout(() => {
            this.populateSelectedTargetMapping({tableName: '',title: ''}, targetId);
          }, 1)
        }
      });
    }
  }

  UNSAFE_componentWillMount() {
    this.UNSAFE_componentWillReceiveProps(this.props)
  }

  populateSelectedTargetMapping(node, targetId = null, reload = false){
    if (targetId !== null) {
      node = {
        tableName: '',
        title: ''
      };

      if (targetId === this.state.targetVariable.id) {
        node.tableName = this.state.targetVariable.tableName;
        node.title = this.state.targetVariable.text;
      }
    }

    let targetTable = node.tableName;
    let filtertargetVariableList = this.state.targetVariableList;
    let selectedColumn = node.title;

    if((this.state.targetVariable.text === node.title && this.state.targetVariable.tableName === targetTable && targetId === null && reload === false) || (targetId !== null &&  this.state.targetVariable.id !== targetId) || (targetTable === '')){
      return;
    }

    let targetVariable = {id:'',tableName:'',text:''};
    let selectedTargetTable = {id:'',tableName:''};
    if(targetTable !== ''){
      filtertargetVariableList = filtertargetVariableList.filter(item => item.tableName === targetTable);
      let selectedVariable = this.state.targetVariableList.filter(item1 => item1.tableName === targetTable && item1.text === selectedColumn);
      if(selectedVariable.length > 0){
        targetVariable = selectedVariable[0];
        let selectedTable = this.state.targetTableList.filter(table => table.id === targetTable);
        if(selectedTable.length > 0){
          selectedTargetTable = selectedTable[0];
        }

        this.setState({filtertargetVariableList : filtertargetVariableList, targetVariable: targetVariable, selectedTargetTable: selectedTargetTable});
        let event ={target:{
          value:targetVariable
        }}
        this.handleTargetVariableChange(event);
      }
    }
  }

  handleTargetVariableChange = (event) => {
      let targetId = event.target.value.id;
      let sourceColumnList = this.props.columnMappedList.filter((columnMap) => columnMap.node.target === targetId);
      let selectedTargetTable = this.state.selectedTargetTable;

      if (sourceColumnList.length > 0) {
        let tenantId = this.props.tenantId;
        let params = [];
        //tenantId = 'schema_1_50';
        let targetColumn = sourceColumnList[0];
        let targetValueTree = this.state.targetValueTree;
        let sourceValueTree = this.state.sourceValueTree;
        let targetExpression = this.props.expressionList.filter(expression => expression.id === targetId);
        let uniqueSourceTableList = [];
        let sourceTableColumnValueWithExpressionList = [];

        if (targetExpression.length > 0) {
          targetExpression = targetExpression[0].expressionDetail;
        }

        targetValueTree.children = []
        sourceValueTree.children = [];

        params.push({columnName: targetColumn.column.target.column_header, tableName: targetColumn.table.target})

        let tableId = targetColumn.table.target+'-targetValue';

        targetValueTree.loading = false;
        targetValueTree.children.push(this.manageTableNameInTreeView(targetColumn.table.target, [this.manageColumnNameInTreeView(targetColumn.column.target, targetColumn.table.target, [], tableId+'-'+targetColumn.column.target.column_header+'-targetValue', 'target')], 'fa fa-table', tableId));

        sourceColumnList.forEach((columnMapped, index) => {
            let singleColumnValue = targetExpression.filter( sourceExpression => sourceExpression.tableName === columnMapped.table.source && sourceExpression.expressionType === 'Single' && sourceExpression.expression !== '' );
            if (columnMapped.columnValue.source.length === 0 && columnMapped.mappingType === 'column-column' && singleColumnValue.length === 0) {
                params.push({columnName: columnMapped.column.source.column_header, tableName: columnMapped.table.source})
            }

            if (uniqueSourceTableList.indexOf(columnMapped.table.source) === -1) {
              uniqueSourceTableList.push(columnMapped.table.source);
            }
        });

        uniqueSourceTableList.forEach((sourceTableName, tableIndex) => {
          let sourceColumns = sourceColumnList.filter((columnMapped) => columnMapped.table.source === sourceTableName );
          let tableColumnList = [];
          let tableId = sourceTableName+'-sourceValue-'+tableIndex;
          let sourceMappedColumn = targetExpression.filter( sourceExpression => sourceExpression.tableName === sourceTableName);

          if (sourceMappedColumn.length > 0) {
            sourceMappedColumn = sourceMappedColumn[0];
            if (sourceMappedColumn.groupExpressionDetail.length > 0) {
              sourceMappedColumn.groupExpressionDetail.forEach((groupExpression) => {
                let sourceMappedColumnList = sourceMappedColumn.children.filter(column => column.group === groupExpression.group);
                if (groupExpression.expressionType === 'Single') {
                  let sourceColumnExpressionList = [];
                  let groupExpressionExpression = groupExpression.expression
                  if (sourceMappedColumnList.length > 0) {
                    sourceMappedColumnList.forEach((sourceColumn) => {
                      sourceColumnExpressionList.push(sourceColumn.sourceColumnName);
                      let renameSourceColumnDetail = this.props.renameColumnList.filter(renameColumn => renameColumn.id.indexOf('-'+sourceColumn.sourceColumnName+'-') > 0);

                      if (renameSourceColumnDetail.length > 0) {
                        groupExpressionExpression = groupExpressionExpression.replace(renameSourceColumnDetail[0].renameColumn.column, sourceColumn.sourceColumnName)
                      }
                    })
                  }

                  sourceTableColumnValueWithExpressionList.push({
                        sourceTableName: sourceTableName,
                        targetTableName:targetColumn.table.target,
                        sourceColumn: sourceColumnExpressionList.join(','),
                        targetColumn:targetColumn.column.target.column_header,
                        expression: groupExpressionExpression,
                        pivotOutput: sourceMappedColumn.pivotOutput,
                        sourceColumnLabel: ''
                      })
                  tableColumnList.push(this.manageColumnNameInTreeView(sourceColumnExpressionList.join(','), sourceTableName, [], tableId+'-'+sourceColumnExpressionList.join('__')+'-sourceValue-'+tableColumnList.length));
                } else {
                  sourceMappedColumnList.forEach((groupColumn) => {
                    let groupColumnExpression = groupColumn.expression;
                    let renameSourceColumnDetail = this.props.renameColumnList.filter(renameColumn => renameColumn.id.indexOf('-'+groupColumn.sourceColumnName+'-') > 0);

                    if (renameSourceColumnDetail.length > 0) {
                      groupColumnExpression = groupColumnExpression.replace(renameSourceColumnDetail[0].renameColumn.column, groupColumn.sourceColumnName)
                    }
                    let sourceColumn = sourceColumns.filter(sourceColumn => sourceColumn.mappingType === 'column-column' && sourceColumn.columnTitle.source === groupColumn.sourceColumnName);
                    sourceTableColumnValueWithExpressionList.push({
                          sourceTableName: sourceTableName,
                          targetTableName:targetColumn.table.target,
                          sourceColumn: groupColumn.sourceColumnName,
                          targetColumn:targetColumn.column.target.column_header,
                          expression: groupColumnExpression,
                          pivotOutput: sourceMappedColumn.pivotOutput,
                          sourceColumnLabel: sourceColumn.length > 0 ? sourceColumn[0].column.source.column_label : ''
                        })
                    tableColumnList.push(this.manageColumnNameInTreeView(groupColumn.sourceColumnName, sourceTableName, [], tableId+'-'+groupColumn.sourceColumnName+'-sourceValue-'+tableColumnList.length));
                  })
                }
              })
            } else {
              let sourceMappedColumnList = sourceMappedColumn.children;

              if (sourceMappedColumn.expressionType === 'Single') {
                let sourceColumnExpressionList = [];
                let sourceMappedColumnExpression = sourceMappedColumn.expression
                if (sourceMappedColumnList.length > 0) {
                  sourceMappedColumnList.forEach((sourceColumn) => {
                    sourceColumnExpressionList.push(sourceColumn.sourceColumnName);
                    let renameSourceColumnDetail = this.props.renameColumnList.filter(renameColumn => renameColumn.id.indexOf('-'+sourceColumn.sourceColumnName+'-') > 0);

                    if (renameSourceColumnDetail.length > 0) {
                      sourceMappedColumnExpression = sourceMappedColumnExpression.replace(renameSourceColumnDetail[0].renameColumn.column, sourceColumn.sourceColumnName)
                    }
                  })
                }

                sourceTableColumnValueWithExpressionList.push({
                      sourceTableName: sourceTableName,
                      targetTableName:targetColumn.table.target,
                      sourceColumn: sourceColumnExpressionList.join(','),
                      targetColumn:targetColumn.column.target.column_header,
                      expression: sourceMappedColumnExpression,
                      pivotOutput: sourceMappedColumn.pivotOutput,
                      sourceColumnLabel: ''
                })
                tableColumnList.push(this.manageColumnNameInTreeView(sourceColumnExpressionList.join(','), sourceTableName, [], tableId+'-'+sourceColumnExpressionList.join('__')+'-sourceValue-'+tableColumnList.length));
              } else {
                sourceMappedColumnList.forEach((groupColumn) => {
                  let groupColumnExpression = groupColumn.expression;
                  let renameSourceColumnDetail = this.props.renameColumnList.filter(renameColumn => renameColumn.id.indexOf('-'+groupColumn.sourceColumnName+'-') > 0);

                  if (renameSourceColumnDetail.length > 0) {
                    groupColumnExpression = groupColumnExpression.replace(renameSourceColumnDetail[0].renameColumn.column, groupColumn.sourceColumnName)
                  }
                  let sourceColumn = sourceColumns.filter(sourceColumn => sourceColumn.mappingType === 'column-column' && sourceColumn.columnTitle.source === groupColumn.sourceColumnName);

                  sourceTableColumnValueWithExpressionList.push({
                        sourceTableName: sourceTableName,
                        targetTableName:targetColumn.table.target,
                        sourceColumn: groupColumn.sourceColumnName,
                        targetColumn:targetColumn.column.target.column_header,
                        expression: groupColumnExpression,
                        pivotOutput: sourceMappedColumn.pivotOutput,
                        sourceColumnLabel: sourceColumn.length > 0 ? sourceColumn[0].column.source.column_label : ''
                  })
                  tableColumnList.push(this.manageColumnNameInTreeView(groupColumn.sourceColumnName, sourceTableName, [], tableId+'-'+groupColumn.sourceColumnName+'-sourceValue-'+tableColumnList.length));
                })
              }
            }
          }

          sourceValueTree.loading = false;
          sourceValueTree.children.push(this.manageTableNameInTreeView(sourceTableName, tableColumnList, 'fa fa-table', tableId));
        })

        if (sourceTableColumnValueWithExpressionList.length > 0) {
          $(".loader").show();
        }

        sourceValueTree.loading = true;
        targetValueTree.loading = true;
        axios.all([this.fetchTableMultiColumnValues({'Tenant-ID': tenantId}, {data: sourceTableColumnValueWithExpressionList})])
            .then(axios.spread((response) => {
              if(response!= null && response.data !== null && typeof(response.data.messageType) !== "undefined"
              &&  response.data.messageType !== null && response.data.messageType === MessageTypeConst.WARNING_MESSAGE )
              {
                  this.props.setMessage(response.data.message, response.data.messageType);
                  $(".loader").hide();
                  return;
              }
              if (typeof(response.data) !== 'undefined' && response.data.length > 0) {
                let targetTableId = null;
                let targetValues = ["--Keep--",""];
                let exactValueList = [];
                let customTargetValueList = [];
                let columnMappedList = this.props.columnMappedList;

                response.data.map(mappedColumnValue => {
                  if (typeof(this.props.customUnmapValueList) !== 'undefined') {
                    this.props.customUnmapValueList.filter(customUnmapValue => customUnmapValue.tableName === mappedColumnValue.targetTable && customUnmapValue.targetVariable === mappedColumnValue.targetColumn && mappedColumnValue.target.indexOf(customUnmapValue.targetValue) === -1).forEach(customUnmapValue => {
                      customTargetValueList.push(customUnmapValue.targetValue)
                    })
                    const removeCustomUnmapValueList = this.props.customUnmapValueList.filter(customUnmapValue => customUnmapValue.tableName === mappedColumnValue.targetTable && customUnmapValue.targetVariable === mappedColumnValue.targetColumn && mappedColumnValue.target.indexOf(customUnmapValue.targetValue) > -1)
                    removeCustomUnmapValueList.forEach(removeCustomUnmapValue => {
                      this.saveCustomUnmapValueList(removeCustomUnmapValue.tableName, removeCustomUnmapValue.targetVariable, removeCustomUnmapValue.targetValue, 'remove')
                    })
                  }

                  this.props.expressionList.filter(expression => typeof(expression.node) !== 'undefined' && expression.node.tableName === mappedColumnValue.targetTable && typeof(expression.node.column) !== 'undefined' && expression.node.column.column_header === mappedColumnValue.targetColumn).forEach(expression => {
                    if (expression.expressionDetail.length > 0 && typeof(expression.expressionDetail[0].targetValues) !== 'undefined') {
                      expression.expressionDetail[0].targetValues.filter(targetValue => targetValue !== '' && targetValue !== '--Keep--').forEach(targetValue => {
                        if (mappedColumnValue.target.indexOf(targetValue) === -1 && customTargetValueList.indexOf(targetValue) === -1 && columnMappedList.filter(columnMapped => columnMapped.targetId === expression.id && columnMapped.table.target === mappedColumnValue.targetTable && columnMapped.columnTitle.target.toLowerCase() === targetValue.toLowerCase() && mappedColumnValue.source.filter(sourceValue => sourceValue.toLowerCase() === columnMapped.columnTitle.source.toLowerCase()).length > 0 && columnMapped.customValue === true).length > 0) {
                          customTargetValueList.push(targetValue)
                        }
                      })
                    }
                  })

                  columnMappedList.filter(columnMapped => columnMapped.customValue === true && columnMapped.targetId === targetId && columnMapped.table.source === mappedColumnValue.sourceTable && columnMapped.table.target === mappedColumnValue.targetTable && columnMapped.parentColumn.source === mappedColumnValue.sourceColumn && customTargetValueList.indexOf(columnMapped.columnTitle.target) === -1 && (mappedColumnValue.source.indexOf(columnMapped.columnTitle.source) === -1 || mappedColumnValue.target.indexOf(columnMapped.columnTitle.target) === -1)).forEach(columnMapped => {
                    customTargetValueList.push(columnMapped.columnTitle.target)
                    this.saveCustomUnmapValueList(columnMapped.table.target, columnMapped.parentColumn.target.column_header, columnMapped.columnTitle.target)
                  })
                  return mappedColumnValue;
                })

                customTargetValueList.sort()
                response.data.map(mappedColumnValue => {
                  customTargetValueList.forEach(customTargetValue => {
                    if (mappedColumnValue.target.indexOf(customTargetValue) === -1) {
                      mappedColumnValue.target.push(customTargetValue);
                    }
                  })

                  columnMappedList = columnMappedList.filter(columnMapped => !(columnMapped.targetId === targetId && columnMapped.table.source === mappedColumnValue.sourceTable && columnMapped.table.target === mappedColumnValue.targetTable && columnMapped.parentColumn.source === mappedColumnValue.sourceColumn && (mappedColumnValue.source.indexOf(columnMapped.columnTitle.source) === -1 || mappedColumnValue.target.indexOf(columnMapped.columnTitle.target) === -1)))
                  return mappedColumnValue;
                })

                let targetColumnValueMappedList = columnMappedList.filter(columnMapped => columnMapped.targetId === targetId);
                targetValueTree.children.forEach((tableNode) => {
                  let targetTableMapped = targetColumnValueMappedList.filter(targetTableMapped => targetTableMapped.node.target === tableNode.id);

                  if (targetTableMapped.length > 0) {
                    tableNode.removeMappedLine = true;
                  }
                  tableNode.children.forEach((columnNode) => {
                    let targetColumnMapped = targetColumnValueMappedList.filter(targetColumnMapped => targetColumnMapped.node.target === columnNode.id);

                    columnNode.removeMappedLine = targetColumnMapped.length > 0;
                    targetTableId = columnNode.tableName;
                    //let columnDetail = response.data.filter(columnDetail => columnDetail.columnName === columnNode.column.column_header && columnDetail.tableName === columnNode.tableName);
                    response.data[0].target.filter(columnValue =>  columnValue !== '').forEach((columnValue, valueIndex) => {
                      let child = columnNode.children.filter(child => child.name === columnValue)

                      if (child.length === 0) {
                        targetValues.push(columnValue);
                        let columnValueNode = this.manageColumnValueInTreeView(columnValue, columnNode.tableName, columnNode.column.column_header, columnNode.column, columnNode.id + '-targetValue-' + valueIndex, false, customTargetValueList.indexOf(columnValue) > -1)
                        let targetColumnValueMapped = targetColumnValueMappedList.filter(targetColumnValueMapped => targetColumnValueMapped.table.target === columnNode.tableName && targetColumnValueMapped.parentColumn.target.column_header === columnNode.column.column_header && targetColumnValueMapped.columnTitle.target === columnValue);

                        if (targetColumnValueMapped.length > 0) {
                          columnValueNode.removeMappedLine = true
                          columnNode.toggled = true;
                        }

                        targetColumnValueMappedList.filter((columnMap) => columnMap.targetId === targetId && columnMap.table.target === columnValueNode.tableName && columnMap.parentColumn.target.column_header === columnValueNode.columnName && columnMap.columnTitle.target === columnValueNode.title).map(columnMap => {
                            columnMap.node.target = columnValueNode.id
                            columnValueNode.removeMappedLine = true
                            return columnMap
                        })

                        columnNode.children.push(columnValueNode);
                      }
                    })

                    columnNode.children.sort((a, b) => (a.customValue < b.customValue) ? 1 : ((a.customValue > b.customValue) ? -1 : 0))
                  })
                })

                sourceValueTree.children.forEach((tableNode, tableIndex) => {
                  let mappingGrid = [];
                  tableNode.children.forEach((columnNode, columnIndex) => {
                    let sourceTableColumnValue = [];
                    sourceTableColumnValueWithExpressionList.forEach((sourceTableColumnValueWithExpression, sourceTableColumnValueWithExpressionIndex) => {
                      if (sourceTableColumnValueWithExpression.sourceColumn === columnNode.column && sourceTableColumnValueWithExpression.sourceTableName === tableNode.tableName && response.data[sourceTableColumnValueWithExpressionIndex].source !== null) {
                        sourceTableColumnValue = response.data[sourceTableColumnValueWithExpressionIndex].source;

                        if (typeof(sourceTableColumnValueWithExpression.pivotOutput) !== 'undefined' && sourceTableColumnValueWithExpression.pivotOutput !== 'Q_VALUE') {
                            sourceTableColumnValue = sourceTableColumnValueWithExpression.pivotOutput === 'Q_NAME' ? [sourceTableColumnValueWithExpression.sourceColumn] : [sourceTableColumnValueWithExpression.sourceColumnLabel]
                        }
                      }
                    })
                    sourceTableColumnValue.filter(columnValue =>  columnValue !== '').forEach((columnValue, valueIndex) => {
                      let child = columnNode.children.filter(child => child.name === columnValue);

                      if (child.length === 0) {
                        let columnValueNode = this.manageColumnValueInTreeView(columnValue, columnNode.tableName, columnNode.name, columnNode.column, columnNode.id + '-sourceValue-' + valueIndex, true)
                        let sourceColumnValueMapped = targetColumnValueMappedList.filter(sourceColumnValueMapped => sourceColumnValueMapped.table.source === columnNode.tableName && sourceColumnValueMapped.parentColumn.source === columnNode.column && sourceColumnValueMapped.columnTitle.source === columnValue)

                        if (sourceColumnValueMapped.length > 0) {
                          columnValueNode.removeMappedLine = true
                          columnValueNode.carryForward = false
                          columnNode.toggled = true;
                        }

                        targetColumnValueMappedList.filter((columnMap) => columnMap.targetId === targetId && columnMap.table.source === columnValueNode.tableName && columnMap.parentColumn.source === columnValueNode.parentColumn && columnMap.columnTitle.source === columnValueNode.title).map(columnMap =>  {
                          columnMap.node.source = columnValueNode.id
                          columnValueNode.carryForward = false
                          return columnMap;
                        })

                        columnNode.children.push(columnValueNode);
                        if (targetValues.filter(targetValue => targetValue.toLowerCase() === columnValue.toLowerCase()).length > 0) {
                          exactValueList.push(columnValue)
                        }

                        mappingGrid.push({
                          rowId: valueIndex+1,
                          source: columnValue,
                          target:"--Keep--",
                          targetValues:[]})
                      }
                    })
                  })

                  if (mappingGrid.length > 0) {
                    this.props.manageMappingGridExpression(targetId, tableNode.tableName, mappingGrid)
                  }
                })

                /*if (sourceTableColumnValueWithExpressionList.length > 0) {
                  //this.recursiveSourceTableExpressionColumnList(0, sourceTableColumnValueWithExpressionList, targetId);
                } else {
                  //$(".loader").hide();
                }*/

                $(".loader").hide();
                sourceValueTree.loading = false;
                targetValueTree.loading = false;

                if (targetTableId !== null) {
                  let selectedTable = this.state.targetTableList.filter(table => table.id === targetTableId);

                  if(selectedTable.length > 0){
                    selectedTargetTable = selectedTable[0];
                  }
                }

                this.props.columnMappedList.length = 0;
                this.props.columnMappedList.push(...columnMappedList)
                this.props.manageMappedColumnList(columnMappedList);
                DrawColumnMappingLine.setNodeListState(columnMappedList)
                this.setState({sourceValueTree: sourceValueTree, targetValueTree: targetValueTree, treeKey: this.state.treeKey+1, displaycolumnValueTree: true, dataRefresh: true, targetVariable: event.target.value, selectedTargetTable: selectedTargetTable, exactValueList: exactValueList, sourceTableColumnValueWithExpressionList: sourceTableColumnValueWithExpressionList}, () => {
                  this.props.setColumnMappingTargetId(targetId);
                  DrawColumnMappingLine.setColumnValueMappingFilter(targetId);
                })
              }
            })
          ).catch(error => {
              this.props.setMessage("Error occurred while fetching unique column values.", MessageTypeConst.ERROR_MESSAGE);
              $(".loader").hide();
          });
        }


      DrawColumnMappingLine.setColumnValueMappingFilter(null);
      this.setState({ targetVariable: event.target.value, dataRefresh: true });

  }

  getDisplayTableNameMapping = (nextPropsTableExtendedList) => {
    let {sourceTables, metaTableTables} = this.props.tableExtendedList;
    let displayTableNameMapping = [];
    let tableName = null;
    let displayTableName = null;
    let tableVersion = null;

    if (typeof(nextPropsTableExtendedList) !== 'undefined') {
      if (typeof(nextPropsTableExtendedList.sourceTables) !== 'undefined') {
        sourceTables = nextPropsTableExtendedList.sourceTables
      }

      if (typeof(nextPropsTableExtendedList.metaTableTables) !== 'undefined') {
        metaTableTables = nextPropsTableExtendedList.metaTableTables
      }
    }

    if (typeof(sourceTables) !== 'undefined') {
      sourceTables.forEach((sourceTable) => {
        tableName = sourceTable.tableName.replace('_extended', '');
        tableVersion = tableName.split('_');
        tableVersion = tableVersion[tableVersion.length-1];
        displayTableName = sourceTable.blockName + ' ('+tableVersion+')';

        displayTableNameMapping.push({tableName: tableName, displayTableName: displayTableName, type: "source"});
      })
    }

    if (typeof(metaTableTables) !== 'undefined') {
      metaTableTables.forEach((metadataTable) => {
        tableName = metadataTable.tableName.replace('_extended', '');
        tableVersion = tableName.split('_');
        tableVersion = tableVersion[tableVersion.length-1];
        displayTableName = this.getBlockName(metadataTable.blockName) + ' ('+tableVersion+')';

        displayTableNameMapping.push({tableName: tableName, displayTableName: displayTableName, type: "metadata"});
      })
    }

    /*if (typeof(persistentTables) !== 'undefined') {
      persistentTables.forEach((persistentTable) => {
        tableName = persistentTable.tableName.replace('_extended', '');
        tableVersion = tableName.split('_');
        tableVersion = tableVersion[tableVersion.length-1];
        displayTableName = persistentTable.blockName + ' ('+tableVersion+')';

        displayTableNameMapping.push({tableName: tableName, displayTableName: displayTableName, type: "persistent"});
      })
    }*/

    return displayTableNameMapping;
  }

  recursiveSourceTableExpressionColumnList = (index, sourceTableColumnValueWithExpressionList, targetId) => {
    if (typeof(sourceTableColumnValueWithExpressionList[index]) !== 'undefined') {
      let that = this;
      let params = sourceTableColumnValueWithExpressionList[index];
      let tenantId = this.props.tenantId;
      //tenantId = 'schema_1_50';
      params = Object.assign(params, {engineId: 0})
      $(".loader").show();
      axios.all([this.fecthPreference({'Tenant-ID': tenantId}, params)])
          .then(axios.spread((response) => {
            if(response!= null && response.data !== null && typeof(response.data.messageType) !== "undefined"
            &&  response.data.messageType !== null && response.data.messageType === MessageTypeConst.WARNING_MESSAGE )
            {
                this.props.setMessage(response.data.message, response.data.messageType);
                $(".loader").hide();
                return;
            }
            if (typeof(response.data) !== 'undefined') {
              let sourceValueTree = that.state.sourceValueTree;
              let tableId = params.targetTableName+'-targetValue-0';
              let mappingGrid = [];
              let targetValues = ["--Keep--",""];
              targetValues.push(...response.data.target);
              sourceValueTree.children.forEach((tableNode, tableIndex) => {
                if (tableNode.name === params.sourceTableName) {
                  let columnValueList = [];
                  let id = tableId+'-'+params.sourceColumn+'-sourceValue-'+index;

                  if (response.data.source.length > 0) {
                    response.data.source.filter(columnValue =>  columnValue !== '').forEach((columnValue, valueIndex) => {
                        columnValueList.push(that.manageColumnValueInTreeView(columnValue, params.sourceTableName, params.sourceColumn, params.sourceColumn, id + '-sourceValue-' + valueIndex, true))
                        mappingGrid.push({
                          rowId: valueIndex+1,
                          source: columnValue,
                          target:"--Keep--",
                          targetValues:targetValues})
                    })
                  }
                  tableNode.loading = false;
                  tableNode.toggled = true;
                  tableNode.children.push(this.manageColumnNameInTreeView(params.sourceColumn, params.sourceTableName, columnValueList, id));
                }
              })

              if (mappingGrid.length > 0) {
                that.props.manageMappingGridExpression(targetId, params.sourceTableName, mappingGrid)
              }

              that.setState({treeKey: that.state.treeKey++});

              index++;
              if (typeof(sourceTableColumnValueWithExpressionList[index]) !== 'undefined') {
                this.recursiveSourceTableExpressionColumnList(index, sourceTableColumnValueWithExpressionList, targetId);
              } else {
                $(".loader").hide();
              }
            }
          })
        );
    } else {
      $(".loader").hide();
      return true;
    }
  }

  manageTableNameInTreeView = (tableName, columnList, iconClass = 'fa fa-table', id = null) => {
    let getDisplayTableNameMapping = this.getDisplayTableNameMapping();
    let name = tableName;

    if (id === null) {
      id = tableName
    }

    let displayTableName = getDisplayTableNameMapping.filter(displayTableName => displayTableName.tableName === name);

    if (displayTableName.length > 0) {
      displayTableName = displayTableName[0].displayTableName;
    }

    return {
      name: displayTableName,
      title: displayTableName,
      cssClassName: 'manage-table-name',
      iconClass: iconClass,
      children: columnList,
      draggable: false,
      id: id,
      toggled: columnList.length > 0 ? true : false,
      carryForward: false,
      tableName:tableName,
      loading: false,
      removeMappedLine: false
    }
  }

  manageColumnNameInTreeView = (column, tableName, children = [], id = null, type = 'source') => {
    if (id === null) {
      id = tableName+'-'+column.column_header;
    }
    let name = '';
    let title = ''
    let data_type = '';
    if (typeof(column.column_header) !== 'undefined') {
      name = column.column_header + '-' + column.column_data_type + ' ('+column.column_data_length+')';
      title = column.column_label;
      data_type = column.column_data_type
    } else {
      name = column;
      title = column;
      data_type = 'expression';
    }

    if (type === 'source') {
      let namesplit = [];
      name.split(',').forEach(sourceColumnName => {
        this.props.renameColumnList.forEach(renameColumn => {
          if (tableName === renameColumn.renameColumn.tableName) {
            let sourceColumn = this.props.columnMappedList.filter(columnMapped => columnMapped.node.source === renameColumn.id)

            if (sourceColumn.length > 0 && sourceColumn[0].columnTitle.source === sourceColumnName) {
              sourceColumnName = renameColumn.renameColumn.column;
              return true
            }
          }
        })

        namesplit.push(sourceColumnName)
      })

      name = namesplit.join(',');
    }

    let columnDetail = {
      name: name,
      title: title,
      cssClassName: type+'Value-manage-column-name',
      iconClass: 'fa fa-columns',
      draggable: false,
      id: id,
      column: column,
      tableName:tableName,
      data_type: data_type,
      children: children
    }

    return columnDetail;
  }

  manageColumnValueInTreeView = (columnValue, tableName, columnName, parentColumn, id = null, carryForward = false, customValue = false) => {
    if (id === null) {
      id = tableName+'-'+columnName+'-'+columnValue + '-' +Math.ceil(Math.random()*10000)
    }
    return {
      name: columnValue,
      title: columnValue,
      label: columnValue,
      cssClassName: customValue === false ? 'manage-table-name' : 'manage-custom-variable-value',
      iconClass: 'fa fa-columns',
      id: id,
      draggable: true,
      tableName: tableName,
      columnName: columnName,
      parentColumn: parentColumn,
      carryForward: carryForward,
      removeMappedLine: false,
      customValue: customValue,
      searchNode: true
    }
  }

  handleTargetTableChange = (event) => {
    let targetTable = event.target.value.id;
    let filtertargetVariableList = this.state.targetVariableList;
    let targetVariable = this.state.targetVariable;
    let sourceValueTree =  this.state.sourceValueTree;
    let targetValueTree = this.state.targetValueTree;
    if(targetTable !== ''){
      filtertargetVariableList = filtertargetVariableList.filter(item => item.tableName === targetTable);
    }else{
      targetVariable = {id: '', tableName:'', text:''};
    }
    if(targetVariable !== '' && typeof(targetVariable.tableName) !== "undefined" && targetVariable.tableName !== null && targetVariable.tableName.indexOf(targetTable) === -1){
      targetVariable = {id: '', tableName:'', text:''};
    }
    if(targetVariable.id === ""){
      sourceValueTree = {
        name: 'Source Value',
        cssClassName: 'manage-source-tables',
        iconClass: 'fa fa-medkit',
        loading: true,
        toggled: true,
        children: [],
        id:'sourceValue'
      };
      targetValueTree = {
        name: 'Target Value',
        cssClassName: 'manage-source-tables',
        iconClass: 'fa fa-medkit',
        loading: true,
        toggled: true,
        children: [],
        id:'targetValue'
      };
    }

    DrawColumnMappingLine.setColumnValueMappingFilter(null);

    this.setState({filtertargetVariableList : filtertargetVariableList, selectedTargetTable: event.target.value,
      sourceValueTree: sourceValueTree, targetValueTree: targetValueTree, targetVariable: targetVariable});
  }

  getMappedColumnValueState = () => {
    return this.state;
  }

  removeTargetColumn = (targetId) => {
    let targetVariableList = [];
    let targetTableList = [{id:"All",tableName:''}];
    let { selectedTargetTable, sourceValueTree, targetValueTree, targetVariable} = this.state;
    let getDisplayTableNameMapping = this.getDisplayTableNameMapping();
    let columnMappedList = this.props.columnMappedList.filter(columnMapped => columnMapped.node.target !== targetId);

    columnMappedList.filter(columnMap => columnMap.targetId == null).forEach((columnMap) => {
      let { node, column, table, mappingType } = columnMap;
      targetVariableList = targetVariableList.filter((target) => target.id !== node.target );
      targetTableList = targetTableList.filter((tableName) => tableName.id !== table.target );

      let splitNodeSource = mappingType.split('-');

      let valueMapped = (splitNodeSource[0] === 'value') ? true: false;

      if (valueMapped === false && typeof(table.target) !== 'undefined' && typeof(column.target.column_header) !== 'undefined') {
          targetVariableList.push({ id: node.target, text: column.target.column_header, tableName:table.target});
          let displayTableName = getDisplayTableNameMapping.filter(displayTableName => displayTableName.tableName === table.target);
          if (displayTableName.length > 0) {
            displayTableName = displayTableName[0].displayTableName;
          }else{
            displayTableName = table.target;
          }
          targetTableList.push({id:table.target, tableName:displayTableName});
      }
    })

    if (typeof(selectedTargetTable.id) !== 'undefined' && targetTableList.filter(targetTable => targetTable.id === selectedTargetTable.id).length === 0) {
      selectedTargetTable = { id: '', tableName: '' }
    }

    if (typeof(targetVariable.id) !== 'undefined' && targetVariableList.filter(variable => variable.id === targetVariable.id).length === 0) {
      targetVariable = { id: '', tableName:'', text:'' }
      sourceValueTree.children = [];
      sourceValueTree.loading = true;
      sourceValueTree.toggled = true;
      targetValueTree.loading = true;
      targetValueTree.toggled = true;
      targetValueTree.children = [];
    }

    this.setState({targetVariableList: targetVariableList, targetTableList: targetTableList, selectedTargetTable: selectedTargetTable, targetVariable: targetVariable, sourceValueTree: sourceValueTree, targetValueTree: targetValueTree })
  }

  getBlockName = (blockName) => {
    if (blockName !== null && blockName !== '') {
      let blockNameSplit = blockName.split('_meta');

      if (blockNameSplit[blockNameSplit.length - 1] === '') {
        blockNameSplit.length = blockNameSplit.length - 1;
      }

      blockName = blockNameSplit.join('_meta')
    }

    return blockName;
  }

  handleAutoValueMappingClick = () => {
    let { sourceValueTree, targetValueTree, exactValueList, targetVariable, sourceTableColumnValueWithExpressionList } = this.state;
    let valueMappingList = this.props.columnMappedList.filter((columnMap) => columnMap.targetId === this.state.targetVariable.id);
    let tenantId = this.props.tenantId;
    let that = this;
    //tenantId = 'schema_1_50';

    if (typeof(targetValueTree.children) !== 'undefined' && targetValueTree.children.length > 0) {
      targetValueTree.children.forEach(table => {
        table.children.forEach(column => {
          column.children.filter(columnValue => columnValue.customValue === true && exactValueList.filter(exactValue => exactValue.toLowerCase() === columnValue.name.toLowerCase()).length === 0).forEach(columnValue => {
            if (typeof(sourceValueTree.children) !== 'undefined' && sourceValueTree.children.length > 0) {
              sourceValueTree.children.forEach(sourceTable => {
                sourceTable.children.forEach(sourceColumn => {
                  if (sourceColumn.children.filter(sourceValue => sourceValue.name.toLowerCase() === columnValue.name.toLowerCase()).length > 0 && exactValueList.filter(exactValue => exactValue.toLowerCase() === columnValue.name.toLowerCase()).length === 0) {
                    exactValueList.push(columnValue.name)
                  }
                })
              })
            }
          })
        })
      })
    }

    if (sourceTableColumnValueWithExpressionList.length > 0) {
      $(".loader").show();
      axios.all([this.fetchValueMappingKnowledge({'Tenant-ID': tenantId}, {data: sourceTableColumnValueWithExpressionList})])
          .then(axios.spread((response) => {
              let exactValueMappingList = []
              if (typeof(response.data) !== 'undefined' && response.data.length > 0) {
                exactValueMappingList.push(...response.data.sort((a, b) => b.frequency - a.frequency))
              }

              if (exactValueList.length > 0) {
                exactValueList.forEach(exactValue => {
                  if (exactValueMappingList.filter(valueMapping => valueMapping.sourceValue.toLowerCase() === exactValue.toLowerCase() && valueMapping.targetValue.toLowerCase() === exactValue.toLowerCase()).length === 0) {
                    exactValueMappingList.push({sourceValue: exactValue, targetValue: exactValue, frequency: 1})
                  }
                })
              }

              if (exactValueMappingList.length > 0) {
                exactValueMappingList.forEach(valueMapping => {
                  if (valueMappingList.filter(valueMapping => valueMapping.table.target === targetVariable.tableName && valueMapping.columnTitle.source === valueMapping.sourceValue && valueMapping.columnTitle.target === valueMapping.targetValue).length === 0) {
                    valueMappingList.push({
                            node: { source: "", target: ""},
                            line: { startX: 0, startY: 0, stopX: 0, stopY: 0 },
                            strokeColor: 'green',
                            column: { source: {}, target: {} },
                            table: { source: '',target: targetVariable.tableName },
                            columnValue: { source: [], target: [] },
                            mappingType: 'value-value',
                            parentColumn: {source: '', target: '' },
                            targetId: targetVariable.id,
                            columnTitle: { source: valueMapping.sourceValue, target: valueMapping.targetValue },
                            display: 'show',
                            sortOrder: { source: 1, target: 2 },
                            customValue: false
                        })
                  }
                })

                sourceValueTree.children.map(sourceTable =>{
                  sourceTable.children.map(sourceTableRow => {
                    sourceTableRow.children.filter(sourceValue => sourceValue.carryForward === true).map(sourceValue =>{
                      if (valueMappingList.filter(valueMapping => valueMapping.node.source === '' && valueMapping.table.source === '' && valueMapping.parentColumn.source === '' && valueMapping.columnTitle.source.toLowerCase() === sourceValue.title.toLowerCase()).length > 0) {
                        valueMappingList.filter(valueMapping => valueMapping.node.source === '' && valueMapping.table.source === '' && valueMapping.parentColumn.source === '' && valueMapping.columnTitle.source.toLowerCase() === sourceValue.title.toLowerCase()).map((valueMapping, valueMappingIndex) => {
                          if (valueMappingIndex === 0) {
                            sourceValue.carryForward = false
                            valueMapping.table.source = sourceValue.tableName
                            valueMapping.columnTitle.source = sourceValue.title
                            valueMapping.node.source = sourceValue.id
                            valueMapping.parentColumn.source = sourceValue.parentColumn
                          }
                          return valueMapping
                        })
                      } else if (valueMappingList.filter(valueMapping => valueMapping.node.source !== sourceValue.id && valueMapping.table.source !== sourceValue.tableName && valueMapping.columnTitle.source.toLowerCase() === sourceValue.title.toLowerCase()).length > 0) {
                          sourceValue.carryForward = false
                          let valueMapping = cloneDeep(valueMappingList.filter(valueMapping => valueMapping.node.source !== sourceValue.id && valueMapping.table.source !== sourceValue.tableName && valueMapping.columnTitle.source.toLowerCase() === sourceValue.title.toLowerCase()))
                          valueMapping[0].table.source = sourceValue.tableName
                          valueMapping[0].columnTitle.source = sourceValue.title
                          valueMapping[0].node.source = sourceValue.id
                          valueMapping[0].parentColumn.source = sourceValue.parentColumn
                          valueMappingList.push(valueMapping[0])
                      }

                      return sourceValue;
                    })
                    return sourceTableRow
                  })
                  return sourceTable;
                })

                targetValueTree.children.map(targetTable =>{
                  targetTable.children.map(targetTableRow => {
                    targetTableRow.children.map(targetValue =>{
                      valueMappingList.filter(valueMapping => valueMapping.node.source !== '' && valueMapping.table.source !== '' && valueMapping.parentColumn.source !== '' && valueMapping.node.target === '' && valueMapping.parentColumn.target === '' && valueMapping.columnTitle.target.toLowerCase() === targetValue.title.toLowerCase()).map(valueMapping => {
                        targetValue.removeMappedLine = true
                        valueMapping.node.target = targetValue.id
                        valueMapping.columnTitle.target = targetValue.title
                        valueMapping.parentColumn.target = targetValue.parentColumn
                        valueMapping.customValue = typeof(targetValue.customValue) !== 'undefined' ? targetValue.customValue : valueMapping.customValue
                        return valueMapping
                      })
                      return targetValue;
                    })
                    return targetTableRow
                  })
                  return targetTable;
                })

                that.setState({ sourceValueTree: sourceValueTree, targetValueTree: targetValueTree, exactValueList: exactValueList, treeKey: that.state.treeKey+1}, () => {
                  valueMappingList = valueMappingList.filter(valueMapping => !(valueMapping.node.source === '' || valueMapping.node.target === ''))
                  valueMappingList.filter(valueMapping => valueMapping.customValue === true && valueMapping.mappingType === 'value-value').forEach(valueMapping => {
                    that.saveCustomUnmapValueList(valueMapping.table.target, valueMapping.parentColumn.target.column_header, valueMapping.columnTitle.target, 'remove')
                  })
                  that.props.addMultipleColumnMappedList(valueMappingList)
                  setTimeout(()=>{
                    DrawColumnMappingLine.handleScrollCallback();
                  },500)
                })
              }

              $(".loader").hide();
          })).catch(error => {
              that.props.setMessage("Error occurred while fetching column value mapping knowledge.", MessageTypeConst.ERROR_MESSAGE);
              $(".loader").hide();
          });
    }
  }

  triggerActiveTargetColumnValue = () => {
    if (typeof(this.state.targetVariable.id) !== '' && this.state.targetVariable.id !== '') {
        this.populateSelectedTargetMapping({tableName: '',title: ''}, this.state.targetVariable.id, true);
    }
  }

  saveCustomUnmapValueList = (tableName, targetVariable, targetValue, type = 'add', replaceTargetValue = '') => {
    let customUnmapValueList = this.props.customUnmapValueList;

    if (typeof(customUnmapValueList) !== 'undefined' && customUnmapValueList === '' && customUnmapValueList === null) {
      customUnmapValueList = [];
    }

    if (type === 'remove') {
      customUnmapValueList = customUnmapValueList.filter(customUnmapValue => !(customUnmapValue.tableName === tableName && customUnmapValue.targetVariable === targetVariable && customUnmapValue.targetValue === targetValue))
    } else if (type === 'replace' && replaceTargetValue !== '') {
      customUnmapValueList.filter(customUnmapValue => customUnmapValue.tableName === tableName && customUnmapValue.targetVariable === targetVariable && customUnmapValue.targetValue === replaceTargetValue).map(customUnmapValue => {
        customUnmapValue.targetValue = targetValue
        return customUnmapValue
      })
    } else {
      if (customUnmapValueList.filter(customUnmapValue => customUnmapValue.tableName === tableName && customUnmapValue.targetVariable === targetVariable && customUnmapValue.targetValue === targetValue).length === 0) {
        customUnmapValueList.push({tableName: tableName, targetVariable: targetVariable, targetValue: targetValue})
      }
    }

    this.props.manageCustomUnmapValueList(customUnmapValueList)
  }

  render() {
    return (
      <div className={'col-12 mapped-column-wrapper'}>
        <div className="wrapper-content clearfix">
          <div className="row">
            {this.state.targetVariableList.length > 0 &&
              <div className="col-12 align-center">
                <div className="bottom-border">
                  <label className="am-form-field variable-row col-2 m_b_0 ">
                    <span><b>Target Table: </b></span>
                    <DropDownList data={this.state.targetTableList} textField="tableName" dataItemKey="id" onChange={this.handleTargetTableChange} value={this.state.selectedTargetTable}/>
                  </label>
                  <label className="am-form-field variable-row col-2 m_b_0 ">
                    <span><b>Target Variable: </b></span>
                    <DropDownList data={this.state.filtertargetVariableList} textField="text" dataItemKey="id" onChange={this.handleTargetVariableChange} value={this.state.targetVariable}/>
                  </label>
                </div>
                <Button primary={true} onClick={this.handleAutoValueMappingClick} className="auto-value-map-btn" disabled={this.state.sourceValueTree.loading}>Auto Map</Button>
              </div>
            }
          </div>
          <div className="row drawline_limit">
            <div className={`col-2 value-section float-left${this.state.targetVariableList.length === 0 ? ' type-1' : ''}`}>
              <div className="wrapper-content treeview-content">
                <div className={'source'}>
                  {this.state.sourceValueTree.loading === false &&
                    <TreeView treeView={this.state.sourceValueTree} id="source-value-tree" key={`${this.state.treeKey}-source-value-tree`}  />
                  }
                </div>
              </div>
            </div>
            <div className={`col-8 value-section float-left${this.state.targetVariableList.length === 0 ? ' type-1' : ''}`}>
              &nbsp;
            </div>
            <div className={`col-2 value-section float-right${this.state.targetVariableList.length === 0 ? ' type-1' : ''}`}>
              <div className={'target treeview-content'}>
                {this.state.targetValueTree.loading === false &&
                  <TreeView treeView={this.state.targetValueTree} id="target-value-tree" key={`${this.state.treeKey}-target-value-tree`} />
                }
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

const mapStateToProps = createSelector(
  state => state.annotate.columnMappedList,
  state => state.processFlow.tenantId,
  state => state.annotate.expressionList,
  state => state.annotate.tableExtendedList,
  state => state.annotate.lastMappedTargetColumnId,
  state => state.annotate.renameColumnList,
  state => state.annotate.customUnmapValueList,
  (columnMappedList, tenantId, expressionList, tableExtendedList, lastMappedTargetColumnId, renameColumnList, customUnmapValueList) => ({
    columnMappedList,
    tenantId,
    expressionList,
    tableExtendedList,
    lastMappedTargetColumnId,
    renameColumnList,
    customUnmapValueList
  })
);
const mapActionsToProps = {
  setColumnMappingTargetId: setColumnMappingTargetId,
  manageMappingGridExpression: manageMappingGridExpression,
  setMessage: setMessage,
  addMultipleColumnMappedList: addMultipleColumnMappedList,
  manageMappedColumnList: manageMappedColumnList,
  manageCustomUnmapValueList: manageCustomUnmapValueList
}
const MappedColumnValue = connect(mapStateToProps, mapActionsToProps)(BaseMappedColumnValue);
export default MappedColumnValue;
