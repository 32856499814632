import './RichGridTable.css'
import React from 'react';
import GridTable from '@nadavshaar/react-grid-table';

function template() {
  if (this.state.isPaginated === true) {
    return (
      <GridTable className={`am-rich-grid-table${this.state.dragAndDrop === true ? ' drag-and-drop' : ''} ${this.state.gridClassName}`} columns={this.state.columns} rows={this.rowsRef.current} isPaginated={this.state.isPaginated} showRowsInformation={this.state.isPaginated} pageSize={this.state.dataState.take} totalRows={this.state.total} onRowsRequest={this.onRowsRequest} showSearch={this.state.showSearch} showColumnVisibilityManager={this.state.showColumnVisibilityManager} isVirtualScroll={false} enableColumnsReorder={false} requestDebounceTimeout={500} onRowsReset={this.onRowsReset} refs={this.state.ref}/>
    );
  } else {
    return (
      <GridTable className={`am-rich-grid-table${this.state.dragAndDrop === true ? ' drag-and-drop' : ''} ${this.state.gridClassName}`} columns={this.state.columns} rows={this.state.gridData} showSearch={this.state.showSearch} showColumnVisibilityManager={this.state.showColumnVisibilityManager} isPaginated={this.state.isPaginated} showRowsInformation={this.state.isPaginated} onRowsChange={this.onRowsChange} onRowClick={this.onRowClick} isVirtualScroll={false} enableColumnsReorder={false} refs={this.state.ref}/>
    );
  }
}

export default template;
