import React from "react";
import { PropTypes } from "prop-types";
import Dialog from 'react-dialog';
import { TabStrip, TabStripTab  } from './../DataOperation/common/Layout';
import { Button } from './../DataOperation/common/Button/StandardButton';
import GridMapper from '../GridMapper';
import RichGridTable from '../RichGridTable';

function getTableName (processFlowList, tableName, processFlowAction = '', applyProcessFlow = {}) {
  let blockFound = false;
  if (processFlowList.length > 0) {
    processFlowList.forEach(processFlow => {
      if (typeof(processFlow.process) !== 'undefined' && typeof(processFlow.process.processBlock) !== 'undefined' && processFlow.process.processBlock.length > 0 && blockFound === false) {
        let block = processFlow.process.processBlock.filter(block => block.blockTable === tableName);

        if (block.length > 0 && tableName.search(block[0].blockName) === -1) {
          tableName = block[0].blockName +" ("+tableName+")";
          blockFound = true;
        }
      }
    })
  }

  if (blockFound === false && processFlowAction === 'applyProcessFlow' && typeof(applyProcessFlow) !== 'undefined' && typeof(applyProcessFlow.id) !== 'undefined') {
    if (applyProcessFlow.tableName === tableName) {
      tableName = applyProcessFlow.displayTableName;
      blockFound = true;
    }
  }
  return tableName;
}

function template() {
  let that = this;
  return (
    <div className="constructive-operation">
    <div>
        {this.state.inputTableStructure.length > 1 &&
          <div className="mapping-button-wrapper">
            <Button primary={true} onClick={this.handleInputTableMappingClick}>Input Table Mapping</Button>
          </div>
        }

        { this.state.inputTableMaapingDialog &&
            <div className="custom_save_pop input-table-mapping-wrapper">
              <Dialog className="save-dialog" title={'Input Table Mapping'} onClose={this.handleInputTableMappingClick} buttons={[<Button primary={true} onClick={this.handleSaveInputTableMappingClick} key="btn-ok">Ok</Button>,<Button onClick={this.handleInputTableMappingClick} key="btn-cancel">Cancel</Button>]}>
                <RichGridTable {...this.state.inputTableRichGrid} />
              </Dialog>
            </div>
        }
        {this.state.inputTableListExtended.length > 0 &&
          <TabStrip keepTabsMounted={true} unmountOnExit={true} selected={this.state.selected} onSelect={this.handleSelect}>
            {
              this.state.inputTableStructure.filter(inputTable => inputTable.selected === true).map((inputTable, index) => {
                  let tableList = this.findTableListForTableGrid(inputTable, index);
                  return (<TabStripTab title={getTableName(this.props.processFlowList, tableList.length === 2 ? tableList[1].id : inputTable.tableName, this.state.processFlowAction, this.state.applyProcessFlow)} key={index}>
                            <GridMapper {...Object.assign({},that.state,{tableList:tableList, processFlowList: this.props.processFlowList})} handleSourceInputTableGridChange={this.handleSourceInputTableGridChange} key={index} />
                        </TabStripTab>);
              })
            }
          </TabStrip>
        }

      </div>
      <div className="ui-dialog-buttonpane">
        <div className="ui-dialog-buttonset input-button-wrapper">
          <Button primary={true} onClick={this.handleInputStructureUpdateClick}>OK</Button>
        </div>
      </div>
      </div>
  );
};


Dialog.propTypes = {
    height: PropTypes.oneOfType([
        PropTypes.string
    ]),
    width: PropTypes.oneOfType([
        PropTypes.string
    ])
}
export default template;
