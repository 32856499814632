import React from 'react';
import { POST_FILE_REQUEST } from '../../../../../restClient/types';
import restClient from '../../../../../restClient';
import { Button } from './../Button/StandardButton';
import { SERVER_URL } from '../../../../../config/index.js';

 export class FileExplorer extends React.Component {
    downloadFileCall = (headers, params) => restClient(POST_FILE_REQUEST, '/downloadFile', params, headers);

    constructor(props) {
      super(props);
      this.state={
        executeFolderPath: props.folderPath,
        fileNames: props.fileNames,
        tenantId:props.tenantId,
        fileUrl:''
      }
    }
    UNSAFE_componentWillReceiveProps(nextProps) {
        if(JSON.stringify(nextProps) !== '') {
          this.setState({executeFolderPath: nextProps.folderPath,
            fileNames: nextProps.fileNames });
        }
    }
    render()
    {
        return (
            <div>
                {
                this.state.fileNames.map((file) => {
                    let fileIconType = 'file';
                    let fileSplit = file.split('.');

                    if (fileSplit[fileSplit.length - 1] !== '') {
                      switch (fileSplit[fileSplit.length - 1].toLowerCase()) {
                        case 'pdf':
                          fileIconType = 'file-pdf-o';
                          break;
                        case 'html':
                          fileIconType = 'file-code-o';
                          break;
                        case 'rtf':
                          fileIconType = 'file-text-o';
                          break;
                      }
                    }
                    return (
                      <div key={file} className="btn-wrapper">
                        <form action={`${SERVER_URL}/downloadFile`} method="POST">
                          <input type="hidden" name="filePath" value={`${this.state.executeFolderPath}`} />
                          <input type="hidden" name="fileName" value={`${file}`} />
                          <Button type="submit" primary={true} className="col-12" icon={`fa fa fa-${fileIconType}`} id={file}>{file}</Button>
                        </form>
                      </div>
                    )
                })
                }
            </div>
        );
     }
}
