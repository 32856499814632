import React from "react";
import GridMapper from '../../GridMapper';

function getGlobalValue(controlIndex){
  let controlValue = '';
  global.processBlockControlValues.forEach((control, index) => {
    if (control.controlId === controlIndex) {
      controlValue = global.processBlockControlValues[index].controlValue;

    }
  });
  return controlValue;
}

function saveGlobalValue(controlIndex, value){
  global.processBlockControlValues.forEach((control, index) => {
    if (control.controlId === controlIndex) {
      global.processBlockControlValues[index].controlValue = value;
    }
  });
}

function CompareLayoutTemplate() {

  let selectedCheckBox = getGlobalValue(45) !== '' ? getGlobalValue(45) : "Column" ;
  let tablechecked =false;
  switch(selectedCheckBox){
    case "Column":
    tablechecked = false;
    saveGlobalValue(45,"Column");
    break;
    case "Table":
    tablechecked = true;
    saveGlobalValue(45,"Table");
    break;
    default:
    break;
  }
  var handleDisplayFormatChange = (event) =>{
    switch(event.target.id){
      case "name_column":
        if(event.target.checked){
          saveGlobalValue(45,"Column");
          tablechecked = false;
        }
      break;
      case "name_table":
      if(event.target.checked){
        saveGlobalValue(45,"Table");
        tablechecked = true;
      }
      break;
      default:
      break;
    }
    this.setState({...this.state.tableList});
  }

  return (
    <div className="process-option standard-two">
      <div className="field_group m_b_0">
        <div className="filter-area am-form-inline col-6 m-0">
          <label className="am-form-field m-0">
            <span>Output Display Format:</span>
            <input type="radio" name="display_Format" value="name_table" id="name_table" className="am-radio"
            onChange={handleDisplayFormatChange} checked={tablechecked}/>
            <label className="am-radio-label" htmlFor="name_table">Table by Table</label>
            <input type="radio" name="display_Format" value="name_column" id="name_column" className="am-radio"
            onChange={handleDisplayFormatChange} checked={!tablechecked}/>
            <label className="am-radio-label" htmlFor="name_column">Column by Column</label>
          </label>
        </div>
      </div>
      <div className="compare-grid-mapper-height">
        <GridMapper {...this.state}/>
      </div>

    </div>
  );
};

export default CompareLayoutTemplate;
