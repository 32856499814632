import React from 'react';
import BlockSaveType from './../../../../BlockSaveType';
import BlockType from './../../../../BlockType';

export const PermanentFlagCheckBoxCell = ({ tableManager, value, data, column, colIndex, rowIndex }) => {
    if (typeof(tableManager) === 'undefined') {
      return (null)
    } else {
      return (
          <div className='rgt-cell-inner' style={{display: 'flex', alignItems: 'center', overflow: 'hidden'}}>
              <PermanentFlagCheckBoxCellClass dataItem={data} field={column.field} value={typeof(data[column.field]) !== 'undefined' ? data[column.field]: value} tableManager={tableManager}/>
          </div>
      )
    }
}

class PermanentFlagCheckBoxCellClass extends React.Component {

    handleChange(e) {
        this.props.dataItem[this.props.field] = e.target.checked;
        this.props.dataItem["selected"] = true;
        this.props.tableManager.rowsApi.setRows(this.props.tableManager.rowsApi.rows)
    }
    render() {
        let checked = false;
        if (this.props.dataItem[this.props.field]) {
          checked = true;
        }
        let colId = "columnId";
        let disabled = false;

        if (typeof(this.props.dataItem['block']) !== 'undefined' && this.props.dataItem['block'] !== null && this.props.dataItem['block'] !== '') {
          const block = JSON.parse(this.props.dataItem['block'])
          if (block.blockSaveType === 2) {
            disabled = true;
          }
        }
        return (
            <div className="text-center width-full">
               <input type="checkbox" className="am-checkbox" id={`checkbox-${this.props.dataItem[colId]}`} onChange={this.handleChange.bind(this)}
               checked={checked} disabled={disabled}/>
               <label className="am-checkbox-label" htmlFor={`checkbox-${this.props.dataItem[colId]}`}></label>

            </div>
        );
    }
}
