import React    from "react";
import { Button } from './../DataOperation/common/Button/StandardButton';
import ExpressionDialog from './ExpressionDialog';
class MultipleExpressionDialog extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      combineExpressionList: props.combineExpressionList,
      columnNodeMappedList: props.columnNodeMappedList,
      handleExpressionSaveClick: props.handleExpressionSaveClick,
      handleExpressionClick: props.handleExpressionClick
    };
    this.handleSelectExpression = this.handleSelectExpression.bind(this);
    this.handleExpressionSaveClick = this.handleExpressionSaveClick.bind(this);
  }

  handleSelectExpression = (event) => {
    let combineExpressionList = this.state.combineExpressionList;
    let value = eval(event.target.value)
    combineExpressionList.map((combineExpression, index) => {
        combineExpression.selected = false;
        if (value === index) {
          combineExpression.selected = true;
        }
        return combineExpression;
    });

    this.setState({combineExpressionList: combineExpressionList})
  }

  handleExpressionSaveClick(e) {
    let combineExpression = this.state.combineExpressionList.filter(combineExpression => combineExpression.selected === true)

    if (combineExpression.length > 0) {
      this.state.handleExpressionSaveClick(e, combineExpression[0], true);
    }
  }


  expressionElement = (combineExpression, key) => {
    return (
      <div key={`${key}-${combineExpression.id}`} className="conflict-expression-dialog">
        <div>
        <div className="d-table">
          <div className="d-table-cell">
            <input type="radio" value={key} name={combineExpression.id} onChange={this.handleSelectExpression} checked={combineExpression.selected} />
          </div>
        </div>
        </div>
        <ExpressionDialog  expressionDetail={combineExpression} columnNodeMappedList={this.state.columnNodeMappedList} isKnowledgeExpression={true} isExpressionButton={true} node={combineExpression.node} handleExpressionClick={this.state.handleExpressionClick} handleExpressionSaveClick={this.handleExpressionSaveClick} isMultipleExpressionDialog={true}/>
      </div>
    )
  }

  render() {
    return (
        <div className="multiple-expression-dialog">
          <div className="expression-wrapper">
            {this.state.combineExpressionList.map(this.expressionElement)}
          </div>

          <div className="ui-dialog-buttonpane">
            <div className="ui-dialog-buttonset">
              <Button primary={true} onClick={this.handleExpressionSaveClick} disabled={this.state.combineExpressionList.filter(combineExpression => combineExpression.selected === true).length === 0}>Save</Button>
              <Button onClick={this.state.handleExpressionClick}>Cancel</Button>
            </div>
          </div>
        </div>
      )
  }
}

export default MultipleExpressionDialog;
