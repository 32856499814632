import React from "react";
import axios from 'axios';
import { GridOperation } from "../common/gridOperation";
import { connect } from 'react-redux';
import restClient from '../../../../restClient';
import { GET_ONE, CREATE } from '../../../../restClient/types';
import $ from 'jquery';
import MessageTypeConst from "../../../MessageTypeConst";
import { setMessage } from '../../../../actions/actionNotification';
import { createSelector } from 'reselect';


function getTableName (processFlowList, tableName) {
  if (processFlowList.length > 0) {
    let blockFound = false;
    processFlowList.forEach(processFlow => {
      if (typeof(processFlow.process) !== 'undefined' && typeof(processFlow.process.processBlock) !== 'undefined' && processFlow.process.processBlock.length > 0 && blockFound === false) {
        let block = processFlow.process.processBlock.filter(block => block.blockTable === tableName);

        if (block.length > 0 && tableName.search(block[0].blockName) === -1) {
          tableName = block[0].blockName +" ("+tableName+")";
          blockFound = true;
        }
      }
    })
  }
  return tableName
}

class PreWhere extends React.Component {

  getColumnFields = (row) => {
    var columns = [];
    let studyIndentifier1 = "";
    let studyIndentifier2 = "";
    let studyIndentifier3 = "";
    let rowColumns = Object.keys(row);
    let selectedStudy = this.props.studyList.filter(study => study.schemaName === this.props.tenantId);
    if(selectedStudy.length > 0){
      studyIndentifier1 = typeof(selectedStudy[0].identifier1) !== "undefined" && selectedStudy[0].identifier1 !== null ? selectedStudy[0].identifier1 : "";
      studyIndentifier2 = typeof(selectedStudy[0].identifier2) !== "undefined" && selectedStudy[0].identifier2 !== null ? selectedStudy[0].identifier2 : "";
      studyIndentifier3 = typeof(selectedStudy[0].identifier3) !== "undefined" && selectedStudy[0].identifier3 !== null ? selectedStudy[0].identifier3 : "";
    }
    if(rowColumns.indexOf(studyIndentifier1) !== -1){
      columns.push(studyIndentifier1);
    }
    if(rowColumns.indexOf(studyIndentifier2) !== -1){
      columns.push(studyIndentifier2);
    }
    if(rowColumns.indexOf(studyIndentifier3) !== -1){
      columns.push(studyIndentifier3);
    }
    let nonkeyColums = rowColumns.filter(name => name.indexOf('_app_') === -1 && name.indexOf('APP_') === -1 &&
    name !== studyIndentifier1 && name !== studyIndentifier2 && name !== studyIndentifier3);
    nonkeyColums = nonkeyColums.sort();
    for (let i =0; i<nonkeyColums.length;i++) {
      columns.push(nonkeyColums[i]);
    }
    let appkeyColums = rowColumns.filter(name => name.indexOf('APP_KEY') !== -1);
    for (let i =0; i<appkeyColums.length;i++) {
      columns.push(appkeyColums[i]);
    }
    let appPkeyColums = rowColumns.filter(name => name.indexOf('APP_PKEY') !== -1);
    for (let i =0; i<appPkeyColums.length;i++) {
      columns.push(appPkeyColums[i]);
    }
    let appRkeyColums = rowColumns.filter(name => name.indexOf('APP_RKEY') !== -1);
    for (let i =0; i<appRkeyColums.length;i++) {
      columns.push(appRkeyColums[i]);
    }
    let appLkeyColums = rowColumns.filter(name => name.indexOf('APP_LKEY') !== -1);
    for (let i =0; i<appLkeyColums.length;i++) {
      columns.push(appLkeyColums[i]);
    }
    let appWarnColums = rowColumns.filter(name => name.indexOf('APP_WARN') !== -1);
    for (let i =0; i<appWarnColums.length;i++) {
      columns.push(appWarnColums[i]);
    }
    let keyColums = rowColumns.filter(name => (name.indexOf('_app_') !== -1 || name.indexOf('APP_') !== -1) && (name.indexOf('APP_KEY') === -1
    && name.indexOf('APP_PKEY') === -1 && name.indexOf('APP_RKEY') === -1 && name.indexOf('APP_LKEY') === -1 && name.indexOf('APP_WARN') === -1));
    for (let i =0; i<keyColums.length;i++) {
      columns.push(keyColums[i]);
    }

    return columns.join(',');
  };

  getColumnProperty(row) {
    let columnProperty = [];
    if(typeof(row) === 'undefined' || row === null){
      return columnProperty;
    }
    let studyIndentifier1 = "";
    let studyIndentifier2 = "";
    let studyIndentifier3 = "";
    let selectedStudy = this.props.studyList.filter(study => study.schemaName === this.props.tenantId);
    if(selectedStudy.length > 0){
      studyIndentifier1 = typeof(selectedStudy[0].identifier1) !== "undefined" && selectedStudy[0].identifier1 !== null ? selectedStudy[0].identifier1 : "";
      studyIndentifier2 = typeof(selectedStudy[0].identifier2) !== "undefined" && selectedStudy[0].identifier2 !== null ? selectedStudy[0].identifier2 : "";
      studyIndentifier3 = typeof(selectedStudy[0].identifier3) !== "undefined" && selectedStudy[0].identifier3 !== null ? selectedStudy[0].identifier3 : "";

    }
    let columns = Object.keys(row);
    let orderIndex = 3 ;
    let nonkeyColums = columns.filter(name => name.indexOf('_app_') === -1 && name.indexOf('APP_') === -1);
    let keyColums = columns.filter(name => name.indexOf('_app_') !== -1 || name.indexOf('APP_') !== -1);
    let app_key_Index = 1;
    let app_pkey_Index = 2;
    let app_rkey_Index = 3;
    let app_lkey_Index = 4;
    let app_warn_Index = 5;
    nonkeyColums = nonkeyColums.sort();
    keyColums = keyColums.sort();
    for (var i =0; i<nonkeyColums.length;i++) {
      let column = nonkeyColums[i];
      let item = {field:column, show:true};
      if(column.toLowerCase() === studyIndentifier1.toLowerCase()){
        item.orderIndex = 0;
      }
      else if(column.toLowerCase() === studyIndentifier2.toLowerCase()){
        item.orderIndex = 1;
      }
      else if(column.toLowerCase() === studyIndentifier3.toLowerCase()){
        item.orderIndex = 2;
      }
      else if(column.indexOf('_app_') === -1 && column.indexOf('APP_') === -1){
        item.orderIndex = orderIndex;
        orderIndex = orderIndex + 1;
      }
      columnProperty.push(item);
    }
    for (var i =0; i<keyColums.length;i++) {
      let column = keyColums[i];
      let item = {field:column, show:true};
      if(column.indexOf('_app_') !== -1 || column.indexOf('APP_') !== -1){
        switch (column.toUpperCase()) {
          case 'APP_KEY':
            item.orderIndex = orderIndex + app_key_Index;
            break;
          case 'APP_PKEY':
            item.orderIndex = orderIndex + app_pkey_Index;
            break;
          case 'APP_RKEY':
            item.orderIndex = orderIndex + app_rkey_Index;
            break;
          case 'APP_LKEY':
            item.orderIndex = orderIndex + app_lkey_Index;
            break;
          case 'APP_WARN':
            item.orderIndex = orderIndex + app_warn_Index;
            break;
          default:
            orderIndex = orderIndex + 6;
            item.orderIndex = orderIndex;
            break;
        }
        columnProperty.push(item);
      }
    }
    return columnProperty;
  }

  getGlobalValue(controlIndex){
    let controlValue = '';
    global.processBlockControlValues.forEach((control, index) => {
      if (control.controlId === controlIndex) {
        controlValue = global.processBlockControlValues[index].controlValue;

      }
    });
    return controlValue;
  }

  saveGlobalValue(controlIndex, value){
    global.processBlockControlValues.forEach((control, index) => {
      if (control.controlId === controlIndex) {
        global.processBlockControlValues[index].controlValue = value;
      }
    });
  }
  constructor(props) {
    super(props);
    this.state={
      inputTable: [],
      tableList: [
        //  {id: "table1",name:"table 1",rows:table_one, status:true}
        ],
        tenantID: props.tenantID,
        changeKey: 0
    }
    this.handleTableStateChangeEvent = this.handleTableStateChangeEvent.bind(this);
    this.getUniqueTables = this.getUniqueTables.bind(this);
    this.recursiveSelectedInputTables = this.recursiveSelectedInputTables.bind(this)
  }

  fetchDataViewer = (headers, params) => restClient(GET_ONE, 'dataviewer', params, headers);

  fecthTableSampleFilterList = (headers, params) => restClient(CREATE, 'sampleFilter/fetch', params, headers);

  componentDidMount(){
    var inputTable = this.getGlobalValue(-97).split(',');
    global.sampleFilterList = []
    this.recursiveSelectedInputTables(0, [], inputTable)

    this.setState({ inputTable: inputTable });
  }

  recursiveSelectedInputTables(index, tableList, inputTable) {
    let that = this;
    if (typeof(inputTable[index]) !== 'undefined') {
      if (index === 0) {
        $(".loader").show();
      }

      var table = inputTable[index];
      axios.all([this.fetchDataViewer({'Tenant-ID': this.state.tenantID}, {id: table, pagination:{ page: 1, perPage: 50 }}), this.fecthTableSampleFilterList({'Tenant-ID': this.state.tenantID}, {data: {tableNames: [table]}})])
          .then(axios.spread((dataViewer, tableSampleFilterResponse) => {
              if(typeof(dataViewer.data) !== 'undefined') {
                const sampleFilter = typeof(tableSampleFilterResponse.data) !== 'undefined' && typeof(tableSampleFilterResponse.data.sampleFilterDatas) !== 'undefined' && tableSampleFilterResponse.data.sampleFilterDatas.length > 0 && typeof(tableSampleFilterResponse.data.sampleFilterDatas[0].sampleFilter) !== 'undefined' && tableSampleFilterResponse.data.sampleFilterDatas[0].sampleFilter !== null && tableSampleFilterResponse.data.sampleFilterDatas[0].sampleFilter !== '' ? JSON.parse(tableSampleFilterResponse.data.sampleFilterDatas[0].sampleFilter) : [];
                tableList = [...tableList,
                {id: table, name: table, fullTableName: getTableName(that.props.processFlowList, table), rows: dataViewer.data.records, status:true, resize: true,
                  columns:{columnField : dataViewer.data.records.length > 0 ? this.getColumnFields(dataViewer.data.records[0]) : ''},
                  sampleFilter: sampleFilter,
                  displaySampleFilter: true,
                  columnProperty : dataViewer.data.records.length > 0 ? this.getColumnProperty(dataViewer.data.records[0]) : [], pageable: true, total: dataViewer.data.count, take:50, skip:0, pagination: { action: 'dataviewer',  tenant_id: this.state.tenantID} }];                index++;

                if (sampleFilter.length > 0) {
                  global.sampleFilterList.push({tableName: table, sampleFilter: sampleFilter});
                }

                if (typeof(inputTable[index]) !== 'undefined') {
                  tableList = this.recursiveSelectedInputTables(index, tableList, inputTable)
                } else {
                  this.setState({ tableList : tableList });
                }
                this.props.setMessage(dataViewer.data.message, dataViewer.data.messageType);
                $(".loader").hide();
                return tableList;
              }
            })
      ).catch(error => {
        $(".loader").hide();
        this.props.setMessage("Error occurred while fetching data.", MessageTypeConst.ERROR_MESSAGE);
      });
    }
  }

  handleTableStateChangeEvent(event) {
    $(".loader").show();
    let {tableList, changeKey} = this.state;

    tableList.filter( table => table.name === event.target.value ).map(table => {
      table.status = event.target.checked;
      changeKey++;
      return table;
    })
    this.setState({ tableList : tableList, changeKey: changeKey });
    $(".loader").hide();
  }

  TableStatusBar = (table, tableIndex) => {
      let checked = table.status;
      return (
        <span key={tableIndex} className="m-right tableName badge badge-pill badge-warning">
              <input
                type="checkbox"
                id={`table-status-checkbox-${table.id}`}
                className="am-checkbox"
                checked={checked}
                onChange={this.handleTableStateChangeEvent}
                value={table.id}
              />
            <label className="am-checkbox-label" htmlFor={`table-status-checkbox-${table.id}`}>{table.fullTableName}</label>
            </span>
      )
  };
  getUniqueTables(tableList){
    let tableAdded = [...new Set(tableList.map(item => item.name))];
    let uniqueObject = [];
    tableAdded.forEach((item) =>{
      let selectedTable = tableList.filter((table) => table.name === item);
      if(selectedTable.length > 0){
        uniqueObject.push(selectedTable[0]);
      }
    })
    return uniqueObject;
  }
  render() {
      return (
        <div className="dataviewer-grid">
          <div className="field_group text-center">
            <div className="am-form-field gird-status-bar">
              { this.getUniqueTables(this.state.tableList).map(this.TableStatusBar)}
            </div>
            <div className="am-form-field">
              <input type="checkbox" id="ch1-preWhere" className="am-checkbox" />
              <label className="am-checkbox-label" htmlFor="ch1-preWhere">Synchronize</label>
            </div>

          </div>
          <GridOperation tableList={this.state.tableList} type={'preWhere'} tenantID={this.state.tenantID} key={this.state.changeKey}/>

        </div>
      );
    }
}

const mapStateToProps = createSelector(
  state => state.processFlow.tenantId,
  state => state.study.studyList,
  state => state.processFlow.processFlowList,
  (tenantId,studyList, processFlowList) => ({
    tenantId,
    studyList,
    processFlowList
  })
);

const mapActionsToProps = {
  setMessage:setMessage
}
export default connect(mapStateToProps, mapActionsToProps)(PreWhere);
