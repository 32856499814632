import React from "react";
import { connect } from 'react-redux';
import { Button } from './../DataOperation/common/Button/StandardButton';
import { Input } from './../DataOperation/common/InputBox/StandardInput';
import { PropTypes } from "prop-types";
import Dialog from 'react-dialog';
import { TabStrip, TabStripTab } from './../DataOperation/common/Layout';
import { DropDownList } from './../DataOperation/common/DropDown/StandardDropDownList';
import cloneDeep from 'lodash/cloneDeep';
import { createSelector } from 'reselect';
import MessageTypeConst from '../../MessageTypeConst';
import { setMessage } from './../../../actions/actionNotification';
import { NewColumnNameCell, PowerGrid, CategoryCell, IdCell, ReferenceTypeCell, RefDocumentIdCell, PageRefRefTypeCell } from './../DataOperation/common';
import RichGridTable from './../RichGridTable';

class ReportingEvent extends React.Component {
    constructor(props) {
        super(props);
        const reportingEventData = props.getReportingEventData();

        this.state = {
          selected: 0,
          reportingEventName: reportingEventData.reportingEventName,
          referenceDocumentTable:{
            id: 'annotate-tlf-reference-document-grid',
            rows: reportingEventData.referenceDocumentList,
            resize: true,
            filterable: false,
            selectedField: "selected",
            dragAndDrop: false,
            columnProperty: [
              { field: "rowId", show: false },
              { field: "columnId", show: false },
              { field: "order", show: false },
              { field: "document_id", title: 'ID'},
              { field: "name", cell: NewColumnNameCell}
            ]
          },
          globalDisplaySectionTable:{
            id: 'global-display-section-grid',
            rows: reportingEventData.globalDisplaySectionList,
            resize: true,
            filterable: false,
            selectedField: "selected",
            dragAndDrop: false,
            columnProperty: [
              { field: "rowId", show: false },
              { field: "columnId", show: false },
              { field: "order", show: false },
              { field: "selected", show: false },
              { field: "subSectionText", cell: NewColumnNameCell},
              { field: "oldSubSectionText", show: false}
            ]
          },
          categorizationsTable:{
            id: 'global-categorizations-grid',
            rows: reportingEventData.categorizationList,
            blankRow: {"rowId": 0,"columnId": 0, "selected": true, "cId": "", "label": "", "parentCategoryId": "", "categoryId": "", "categoryLabel": ""},
            resize: true,
            filterable: false,
            selectedField: "selected",
            dragAndDrop: false,
            columnProperty: [
              { field: "rowId", show: false },
              { field: "columnId", show: false },
              { field: "order", show: false },
              { field: "cId", title: "ID", cell: NewColumnNameCell },
              { field: "label", cell: NewColumnNameCell },
              { field: "parentCategoryId", cell: CategoryCell, width:"270px", fetchData:this.fetchData, multiple: false },
              { field: "categoryId", cell: NewColumnNameCell },
              { field: "categoryLabel", cell: NewColumnNameCell }
            ]
          },
          outputTable:{
            id: 'global-output-grid',
            rows: reportingEventData.outputList,
            resize: true,
            filterable: false,
            selectedField: "selected",
            dragAndDrop: false,
            columnProperty: [
              { field: "rowId", show: false },
              { field: "columnId", show: false },
              { field: "order", show: false },
              { field: "selected", show: false },
              { field: "outputId", title: "ID" },
              { field: "categoryIds", width: "250px", cell: CategoryCell, fetchData:this.fetchData, multiple: true },
              { field: "display1IdName", cell: NewColumnNameCell },
              { field: "fileSpecificationFileName", cell: NewColumnNameCell }
            ]
          },
          outputDocumentRefsTable:{
            id: 'global-output-document-refs-grid',
            rows: reportingEventData.outputDocumentRefList,
            blankRow: {"rowId": 0,"columnId": 0, "selected": true, "outputId": "", "referenceType": "", "refDocumentId": "", "pageRef_refType": "", "pageRef_label": "", "pageRef_pages": ""},
            resize: true,
            filterable: false,
            selectedField: "selected",
            dragAndDrop: false,
            columnProperty: [
              { field: "rowId", show: false },
              { field: "columnId", show: false },
              { field: "order", show: false },
              { field: "outputId", title: "output_id", width: "270px", cell: IdCell, fetchData:this.fetchData},
              { field: "referenceType", width: "170px", cell: ReferenceTypeCell, title: "referenceType" },
              { field: "refDocumentId", width: "170px", cell: RefDocumentIdCell, fetchData:this.fetchData, title: "refDocumentId" },
              { field: "pageRef_refType", width: "170px", cell: PageRefRefTypeCell, title: "pageRef_refType" },
              { field: "pageRef_label", cell: NewColumnNameCell, title: "pageRef_label" },
              { field: "pageRef_pages", cell: NewColumnNameCell, title: "pageRef_pages" }
            ]
          },
          analysisDocumentRefsTable:{
            id: 'global-analysis-document-refs-grid',
            rows: reportingEventData.analysisDocumentRefList,
            blankRow: {"rowId": 0,"columnId": 0, "selected": true, "analysisId": "", "referenceType": "", "refDocumentId": "", "pageRef_refType": "", "pageRef_label": "", "pageRef_pages": ""},
            resize: true,
            filterable: false,
            selectedField: "selected",
            dragAndDrop: false,
            columnProperty: [
              { field: "rowId", show: false },
              { field: "columnId", show: false },
              { field: "order", show: false },
              { field: "analysisId", title: "analysis_id", width: "350px", cell: IdCell, fetchData:this.fetchData},
              { field: "referenceType", width: "170px", cell: ReferenceTypeCell, title: "referenceType" },
              { field: "refDocumentId", width: "170px", cell: RefDocumentIdCell, fetchData:this.fetchData, title: "refDocumentId" },
              { field: "pageRef_refType", width: "170px", cell: PageRefRefTypeCell, title: "pageRef_refType" },
              { field: "pageRef_label", cell: NewColumnNameCell, title: "pageRef_label" },
              { field: "pageRef_pages", cell: NewColumnNameCell, title: "pageRef_pages" }
            ]
          },
          analysisMethodDocumentRefsTable:{
            id: 'global-analysis-method-document-refs-grid',
            rows: reportingEventData.analysisMethodDocumentRefList,
            blankRow: {"rowId": 0,"columnId": 0, "selected": true, "methodId": "", "referenceType": "", "refDocumentId": "", "pageRef_refType": "", "pageRef_label": "", "pageRef_pages": ""},
            resize: true,
            filterable: false,
            selectedField: "selected",
            dragAndDrop: false,
            columnProperty: [
              { field: "rowId", show: false },
              { field: "columnId", show: false },
              { field: "order", show: false },
              { field: "methodId", title: "method_id", width: "350px", cell: IdCell, fetchData:this.fetchData},
              { field: "referenceType", width: "170px", cell: ReferenceTypeCell, title: "referenceType" },
              { field: "refDocumentId", width: "170px", cell: RefDocumentIdCell, fetchData:this.fetchData, title: "refDocumentId" },
              { field: "pageRef_refType", width: "170px", cell: PageRefRefTypeCell, title: "pageRef_refType" },
              { field: "pageRef_label", cell: NewColumnNameCell, title: "pageRef_label" },
              { field: "pageRef_pages", cell: NewColumnNameCell, title: "pageRef_pages" }
            ]
          },
        }
        this.handleReportingEventNameChange = this.handleReportingEventNameChange.bind(this);
        this.fetchData = this.fetchData.bind(this);
    }

    handleSelect = (e) => {
      this.setState({selected: e.selected});
    }

    handleReportingEventNameChange = (event) => {
      this.setState({reportingEventName: event.target.value})
    }

    fetchData = (type) => {
      let {referenceDocumentTable, categorizationsTable, outputTable} = this.state;
      let returnIds = [""]

      if (type === 'categoryIds' || type === 'parentCategoryId') {
        categorizationsTable.rows.forEach(row => {
          if (row.categoryId != '') {
            returnIds.push(row.categoryId)
          }
        })
      } else if (type === 'outputId') {
        outputTable.rows.forEach(row => {
          if (row.outputId != '') {
            returnIds.push(row.outputId)
          }
        })
      } else if (type === 'refDocumentId') {
        referenceDocumentTable.rows.forEach(row => {
          if (row.outputId != '') {
            returnIds.push(row.document_id)
          }
        })
      } else if (type === 'analysisId') {
        const reportingEventData = this.props.getReportingEventData();
        if (typeof(reportingEventData.analysisIdList) !== 'undefined') {
          returnIds = [...reportingEventData.analysisIdList]
        }
      } else if (type === 'methodId') {
        const reportingEventData = this.props.getReportingEventData();
        if (typeof(reportingEventData.methodIdList) !== 'undefined') {
          returnIds = [...reportingEventData.methodIdList]
        }
      }

      returnIds = [...new Set(returnIds)];

      return returnIds
    }

    render(){
        return(
          <div className="where-order-dialog additional-wherecondtion reporting-event">
            <Dialog title={`Properties`} width="90%" height="80%" onClose={this.props.closeEvent} buttons={[<Button primary={true} onClick={(event) => this.props.handleReportingEventChange(this.state)} key="btn-save"> Save</Button>, <Button onClick={this.props.closeEvent} key="btn-cancel"> Cancel</Button>]}>
              <div className="regex-columnMaaping-detail">
                <div className="container-fluid">
                  <TabStrip keepTabsMounted={true} selected={this.state.selected} onSelect={this.handleSelect}>
                    <TabStripTab title={'Reporting Event'}>
                      <div className="row">
                        <div className="col-11 m-t-5">
                          <div className="am-form-field">
                            <span htmlFor="text-indentation">Name</span>
                            <Input className="width-full m-l-15" onChange={this.handleReportingEventNameChange} value={this.state.reportingEventName} />
                          </div>
                        </div>
                      </div>
                    </TabStripTab>
                    <TabStripTab title={'TLF Attributes'}>
                      <div className="height_fixed_step1 full-width-table m-t-10 m-b-10">
                        <RichGridTable {...this.state.outputTable} key={this.state.outputTable.id} />
                      </div>
                    </TabStripTab>
                    <TabStripTab title={'Reference Documents'}>
                      <div className="height_fixed_step1 full-width-table m-t-10 m-b-10">
                        <RichGridTable {...this.state.referenceDocumentTable} key={this.state.referenceDocumentTable.id} />
                      </div>
                    </TabStripTab>
                    <TabStripTab title={'Global Display Sections'}>
                      <div className="height_fixed_step1 full-width-table m-t-10 m-b-10">
                        <RichGridTable {...this.state.globalDisplaySectionTable} key={this.state.globalDisplaySectionTable.id} />
                      </div>
                    </TabStripTab>
                    <TabStripTab title={'Categorizations'}>
                      <div className="height_fixed_step1 full-width-table m-t-10 m-b-10">
                        <PowerGrid table={this.state.categorizationsTable} key={this.state.categorizationsTable.id}/>
                      </div>
                    </TabStripTab>
                    <TabStripTab title={'OutputDocumentRefs'}>
                      <div className="height_fixed_step1 full-width-table m-t-10 m-b-10">
                        <PowerGrid table={this.state.outputDocumentRefsTable} key={this.state.outputDocumentRefsTable.id} />
                      </div>
                    </TabStripTab>
                    <TabStripTab title={'AnalysisDocumentRefs'}>
                      <div className="height_fixed_step1 full-width-table m-t-10 m-b-10">
                        <PowerGrid table={this.state.analysisDocumentRefsTable} key={this.state.analysisDocumentRefsTable.id} />
                      </div>
                    </TabStripTab>
                    <TabStripTab title={'AnalysisMethodDocumentRefs'}>
                      <div className="height_fixed_step1 full-width-table m-t-10 m-b-10">
                        <PowerGrid table={this.state.analysisMethodDocumentRefsTable} key={this.state.analysisMethodDocumentRefsTable.id} />
                      </div>
                    </TabStripTab>
                  </TabStrip>
                </div>
              </div>
            </Dialog>
          </div>
        )
    }
}

Dialog.propTypes = {
    height: PropTypes.oneOfType([
        PropTypes.string
    ]),
    width: PropTypes.oneOfType([
        PropTypes.string
    ])
}

export default ReportingEvent
