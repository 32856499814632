import React from 'react';
import { Input as RSInput } from 'reactstrap';
import cloneDeep from 'lodash/cloneDeep';

export class Input extends React.Component {
  render() {
    let props = cloneDeep(this.props);

    props = {
      ...props,
      className: 'am-textbox'+ (typeof(props.className) !== 'undefined' && props.className !== '' ? ' '+ props.className: '')
    }

    return (
      React.createElement('input', {...props}, null)
    )
  }
}
