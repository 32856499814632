import React from 'react';
import { DropDownList } from './StandardDropDownList';

export const RefPfElementIdCell = ({ tableManager, value, data, column, colIndex, rowIndex }) => {
    if (typeof(tableManager) === 'undefined') {
      return (null)
    } else {
      return (
          <div className='rgt-cell-inner' style={{display: 'flex', alignItems: 'center', overflow: 'hidden'}}>
              <BaseRefPfElementIdCell dataItem={data} field={column.field} value={typeof(data[column.field]) !== 'undefined' ? data[column.field]: value} column={column} tableManager={tableManager}/>
          </div>
      )
    }
}

class BaseRefPfElementIdCell extends React.Component {
  getGlobalValue(controlIndex){
    let controlValue = '';
    global.processBlockControlValues.forEach((control, index) => {
      if (control.controlId === controlIndex) {
        controlValue = global.processBlockControlValues[index].controlValue;
      }
    });
    return controlValue;
  }

  saveGlobalValue(controlIndex, value){
    global.processBlockControlValues.forEach((control, index) => {
      if (control.controlId === controlIndex) {
        global.processBlockControlValues[index].controlValue = value;
      }
    });
  }
  constructor(props){
    super(props);
  }

  handleChange(e) {
    this.props.dataItem[this.props.field] = e.target.value;
    this.props.tableManager.rowsApi.setRows(this.props.tableManager.rowsApi.rows)
  }

  render() {
    let refElement = this.props.dataItem['refPfName'];
    let refIdsCollection = [];
    if(refElement !== "" && typeof(global.referencePoints) !== 'undefined'){
      global.referencePoints.forEach((item)=>{
        if(item.RF_NM === refElement)
        refIdsCollection.push(item.RFE_ID);
       });
       refIdsCollection.sort();
       refIdsCollection.push("Min");
       refIdsCollection.push("Max");
     }
    return (
        <>
          <DropDownList className="width-full" onChange={this.handleChange.bind(this)} data={refIdsCollection} value={this.props.dataItem[this.props.field]}/>
        </>
      );
    }
}
