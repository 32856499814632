import React from 'react';
import { TabStrip, TabStripTab  } from './../Layout';
import { Button } from './../Button/StandardButton';
import { Input } from './../InputBox/StandardInput';
import { StandardDropDown } from '../DropDown/StandardDropDown';
import { MultiSelectDropDownList } from '../DropDown/StandardMultiSelectDropDownList';

export class Graph extends React.Component {
  constructor(props){
    super(props);
    this.state = {
      rows:[],
      inputColumns: typeof(props.inputColumns) !== 'undefined' ? props.inputColumns : [],
      processFlowAction: props.processFlowAction,
      container:{
        size: {
          width: '300',
          height: '300',
          unit: 'Pixel'
        },
        type: 'Constant',
        selectedVariables: [],
        metrics: {
          column: 1,
          row: 1
        },
        totalContainer: 1,
        list:[{containerId:1, containerDetails: {definition: {layerList: [{layerId:1}, {layerId:2}], layerSelected: 0}}, selected:0, size:{width: '300', height: '300'}, metrics: {column: 1, row: 1}}]
      },
      selected: 0
    }
  }

  getGlobalValue(controlIndex){
    let controlValue = '';
    global.processBlockControlValues.forEach((control, index) => {
      if (control.controlId === controlIndex) {
        controlValue = global.processBlockControlValues[index].controlValue;

      }
    });
    return controlValue;
  }

  saveGlobalValue(controlIndex, value){
    global.processBlockControlValues.forEach((control, index) => {
      if (control.controlId === controlIndex) {
        global.processBlockControlValues[index].controlValue = value;
      }
    });
  }

  handleContainerConfigurationChange = (event, key) => {
    let container = this.state.container;
    if (key === 'width' || key === 'height' || key === 'unit') {
      container.size[key] = event.target.value;
    } else if (key === 'type') {
      container[key] = event.value;
    } else if (key === 'column' || key === 'row') {
      container.metrics[key] = typeof(event.target.value) !== '' ? eval(event.target.value) : event.target.value;
      container.totalContainer = container.metrics.column * container.metrics.row;
    } else if(key === 'selectedVariables') {
      container[key] = event.value;
    }

    this.setState({container: container})
  }

  handleSelectSubTabPanel = (event, containerId, key = '') => {
    let container = this.state.container;
    container.list.filter(containerNode => containerNode.containerId === containerId).map(containerNode => {
      if (key === '') {
        containerNode.selected = event.selected
      } else if (key === 'definitionLayer') {
        containerNode.containerDetails.definition.layerSelected = event.selected
      }

      return containerNode
    })

    this.setState({container: container})
  }

  handleApplyClick = (event) => {
    let container = this.state.container;
    const containerListCount = container.list.length

    if (containerListCount > container.totalContainer) {
      container.list = container.list.filter(containerNode => containerNode.containerId <= container.totalContainer)
    } else if (containerListCount < container.totalContainer) {
      for (var i = containerListCount+1; i <= container.totalContainer; i++ ) {
        container.list.push({containerId: i, containerDetails: {definition: {layerList: [{layerId:1}, {layerId:2}], layerSelected: 0}}, selected:0, size: container.size, metrics: container.metrics})
      }
    }

    container.list.map(containerNode => {
      containerNode.size = {
        ...containerNode.size,
        ...container.size
      }

      containerNode.metrics = {
        ...containerNode.metrics,
        ...container.metrics
      }

      return containerNode
    })

    if (container.type === 'Dynamic') {
      container.totalContainer = 1;
      container.list = container.list.filter(containerNode => containerNode.containerId === 1)
    }

    this.setState({container: container})
  }

  ContainerBoxTemplate = (containerNode, key) => {
    return (
      <TabStripTab title={`Container ${containerNode.containerId}`} key={`container-node-${containerNode.containerId}-${key}`} >
        <div className="container-node-wrapper">
          <TabStrip keepTabsMounted={true} selected={containerNode.selected} onSelect={(event) => this.handleSelectSubTabPanel(event, containerNode.containerId)}>
            <TabStripTab title={"Header"}>
              Header
            </TabStripTab>
            <TabStripTab title={"Footer"}>
              Footer
            </TabStripTab>
            <TabStripTab title={"Definition"}>
              <div className="definition-wrapper">
                <TabStrip keepTabsMounted={true} selected={containerNode.containerDetails.definition.layerSelected} onSelect={(event) => this.handleSelectSubTabPanel(event, containerNode.containerId, 'definitionLayer')}>
                {
                  containerNode.containerDetails.definition.layerList.map((layer, i) => {
                    return (
                      <TabStripTab key={`container-layer-${containerNode.containerId}-${layer.layerId}`}title={`Layer ${layer.layerId}`}>
                        {`Layer ${layer.layerId}`}
                      </TabStripTab>
                    )
                  })
                }
                </TabStrip>
              </div>
            </TabStripTab>
            <TabStripTab title={"Output"}>
              Output
            </TabStripTab>
          </TabStrip>
        </div>
      </TabStripTab>
    )
  }

  listToMatrix(list, elementsPerSubArray) {
    var matrix = [], i, k;

    for (i = 0, k = -1; i < list.length; i++) {
        if (i % elementsPerSubArray === 0) {
            k++;
            matrix[k] = [];
        }

        matrix[k].push(list[i]);
    }

    return matrix;
  }

  handleSelectTabPanel = (event) => {
    this.setState({selected: event.selected})
  }

  render() {
    //const matrix = this.listToMatrix(this.state.container.list, typeof(this.state.container.list[0]) !== 'undefined' ? this.state.container.list[0].metrics.column : 0);
    return (
      <div className="graph-wrapper">
        <fieldset>
          <div className="field_group">
            <div className="row">
              <div className="col-12 action-bar">
                <span><b>Container Size: </b></span>
                <Input type="number" className="width-70" value={this.state.container.size.width} onChange={(event) => this.handleContainerConfigurationChange(event, 'width')}/>
                <span className="times"><i className="fa fa-times" aria-hidden="true"></i></span>
                <Input type="number" className="width-70" value={this.state.container.size.height} onChange={(event) => this.handleContainerConfigurationChange(event, 'height')}/>
                <span className="unit"><StandardDropDown data={["Pixel", "Inche"]} value={this.state.container.size.unit} onChange={(event) => this.handleContainerConfigurationChange(event, 'unit')} /></span>
                <span className="container-type m-l-50"><b>Container Type: </b></span>
                <StandardDropDown data={["Constant", "Dynamic"]} value={this.state.container.type} onChange={(event) => this.handleContainerConfigurationChange(event, 'type')} />
                {this.state.container.type === 'Constant' &&
                  <span className="container-type m-l-50"><b>Container Metrics : </b></span>
                }
                {this.state.container.type === 'Constant' &&
                  <Input type="number" className="width-70" value={this.state.container.metrics.column} onChange={(event) => this.handleContainerConfigurationChange(event, 'column')}/>
                }
                {this.state.container.type === 'Constant' &&
                  <span className="metrics-label"><label>Columns</label></span>
                }
                {this.state.container.type === 'Constant' &&
                  <Input type="number" className="width-70" value={this.state.container.metrics.row} onChange={(event) => this.handleContainerConfigurationChange(event, 'row')}/>
                }
                {this.state.container.type === 'Constant' &&
                  <span className="metrics-label"><label>Rows</label></span>
                }
                {this.state.container.type === 'Dynamic' &&
                  <span className="container-type m-l-50"><b>Variable List : </b></span>
                }
                {this.state.container.type === 'Dynamic' &&
                  <MultiSelectDropDownList data={this.state.inputColumns} onChange={(event) => this.handleContainerConfigurationChange(event, 'selectedVariables')} value={this.state.container.selectedVariables}/>
                }
                <Button primary={true} onClick={this.handleApplyClick} className="m-left" > Apply </Button>
              </div>
            </div>
          </div>
        </fieldset>
        <fieldset>
          <div className="field_group container-wrapper">
            <TabStrip keepTabsMounted={true} selected={this.state.selected} onSelect={this.handleSelectTabPanel}>
              {
                this.state.container.list.map(this.ContainerBoxTemplate)
              }
            </TabStrip>
          </div>
        </fieldset>
      </div>
    )
  }
}
