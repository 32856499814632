import React from "react";
import RichTextEditor from '../../RichTextEditor';

class Spec extends React.Component {

  handleOnChange = (code) =>{
    global.blockSpec = JSON.stringify(code.split("\n").filter(a=>a));
  }

  render() {
    let value = "";
    if(typeof(global.blockSpec) !== "undefined" && global.blockSpec !== null && global.blockSpec.trim() !== ""){
      if(!Array.isArray(global.blockSpec)){
        value = JSON.parse(global.blockSpec).join('\n');
      }else{
        value = global.blockSpec.join('\n');
      }
    }
      return (
        <div>
          <RichTextEditor content={value} onChange= {this.handleOnChange} height={`calc(100vh - 182px)`}/>
        </div>
      );
    }
}

export default Spec;
