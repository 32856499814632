import axios from 'axios';
import restClient from '../restClient';
import { GET_LIST } from '../restClient/types';
import { FETCH_STUDY, ADD_STUDY, REMOVE_STUDY, REMOVE_STUDY_BY_PROJECT_ID, SET_ERROR_MESSAGE, SET_WARNING_MESSAGE, ADD_MULTIPLE_STUDY, SET_STUDY_MANAGER, SET_STUDY_DESCRIPTION } from './actionsTypes';

const fetchStudyList = (headers, projectId) => restClient(GET_LIST, 'study/'+projectId, {pagination: { page: 1, perPage: -1 }, sort: { field: 'id', order: 'ASC' }}, headers);

export const fetchStudy = (projectId = 0) => dispatch => {
  axios.all([fetchStudyList({'Tenant-ID': 'master'}, projectId)])
       .then(axios.spread((response) => {
         if(response.data !== null){
           if(response.data.length === 0){
          dispatch({
            type: SET_WARNING_MESSAGE,
            payload: "Project does not contain any study.",
            projectId: projectId
          });
        }
         dispatch({
           type: FETCH_STUDY,
           payload: response.data,
           projectId: projectId
         });
        }
       }
    )
  ).catch(error=>{
    dispatch({
      type: SET_ERROR_MESSAGE,
      payload: "Error occurred while fetching study."
    });
  });
};

export const addStudy = study => ({
    type: ADD_STUDY,
    payload: study
})

export const removeStudy = studyId => ({
    type: REMOVE_STUDY,
    payload: studyId
})

export const removeStudyByProjectId = projectId => ({
    type: REMOVE_STUDY_BY_PROJECT_ID,
    payload: projectId
})

export const addMultipleStudy = studyList => ({
    type: ADD_MULTIPLE_STUDY,
    payload: studyList
})

export const setStudyManager = schemaName => dispatch => {
  dispatch({
    type: SET_STUDY_MANAGER,
    payload: schemaName
  });
}

export const setStudyDescription = studyDetail => dispatch => {
  dispatch({
    type: SET_STUDY_DESCRIPTION,
    payload: studyDetail
  });
}
