import React from 'react';
import { Input } from './StandardInput';

export const MultipleSourceColumnExpressionCell = ({ tableManager, value, data, column, colIndex, rowIndex }) => {
    if (typeof(tableManager) === 'undefined') {
      return (null)
    } else {
      return (
          <div className='rgt-cell-inner' style={{display: 'flex', alignItems: 'center', overflow: 'hidden'}}>
              <MultipleSourceColumnExpressionCellClass dataItem={data} field={column.field} value={typeof(data[column.field]) !== 'undefined' ? data[column.field]: value} column={column} tableManager={tableManager}/>
          </div>
      )
    }
}

class MultipleSourceColumnExpressionCellClass extends React.Component {

    handleChange(e) {
      if (this.props.dataItem['expressionType'] === 'Single') {
        this.props.dataItem[this.props.field] = e.target.value;
      } else {
        let sourceColumnList = JSON.parse(this.props.dataItem['sourceVariableList'])
        if (typeof(sourceColumnList[parseInt(e.target.alt)]) !== 'undefined') {
          sourceColumnList[parseInt(e.target.alt)].expression = e.target.value
        }

        this.props.dataItem['sourceVariableList'] = JSON.stringify(sourceColumnList);
      }

      this.props.tableManager.rowsApi.setRows(this.props.tableManager.rowsApi.rows)
    }

    render() {
      let columnExpressionList = [];

      if (typeof(this.props.dataItem[this.props.field]) !== 'undefined' && this.props.dataItem[this.props.field] !== null && typeof(this.props.dataItem['sourceVariableList']) !== 'undefined') {
        const sourceColumnList = JSON.parse(this.props.dataItem['sourceVariableList']);
        if (this.props.dataItem['expressionType'] === 'Single') {
          columnExpressionList.push({column: sourceColumnList[0].column, expression: this.props.dataItem[this.props.field], oldExpression: this.props.dataItem.oldExpression})
        } else {
          sourceColumnList.forEach(sourceColumn => {
            columnExpressionList.push({column: sourceColumn.column, expression: sourceColumn.expression, oldExpression: sourceColumn.oldExpression})
          })
        }

      }
        return (
            <>
              <ul className="variable-list">
              {columnExpressionList.map((column, key) => {
                  return (
                    <li key={key} className="variable-title">
                      {this.props.field === 'oldExpression' ? (<span>{column.oldExpression}</span>) : (<Input className="width-full" onChange={this.handleChange.bind(this)} alt={key} value={column.expression}/>)}
                    </li>
                  )
              })}
              </ul>
            </>
        );
    }
}
