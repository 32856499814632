import React from 'react';

export const BasicGbyCheckBoxCell = ({ tableManager, value, data, column, colIndex, rowIndex, onChange }) => {
    if (typeof(tableManager) === 'undefined') {
      return (null)
    } else {
      return (
          <div className='rgt-cell-inner' style={{display: 'flex', alignItems: 'center', overflow: 'hidden'}}>
              <BasicGbyCheckBoxCellClass dataItem={data} field={column.field} value={typeof(data[column.field]) !== 'undefined' ? data[column.field]: value} tableManager={tableManager}/>
          </div>
      )
    }
}

class BasicGbyCheckBoxCellClass extends React.Component {
    handleChange(e) {
      this.props.dataItem[this.props.field] = e.target.checked;
      this.props.tableManager.rowsApi.setRows(this.props.tableManager.rowsApi.rows)

      global.processBlockControlValues.forEach((control, index) => {
        if (control.controlId === 9 ) {
          if(global.processBlockControlValues[index].controlValue !== ""){
            let grpList = JSON.parse(global.processBlockControlValues[index].controlValue);
            if(e.target.checked)
            {
              if(grpList.indexOf(this.props.dataItem['column']) === -1)
              {
                //if check box checked add item into list
                grpList.push(this.props.dataItem['column']);

              }
            } else{
              //if check box unchecked remove item from list
              if(grpList.indexOf(this.props.dataItem['column']) !== -1)
              {
                var filtered = grpList.filter((value, index, arr) => {
                  return value !== this.props.dataItem['column'];
                });
                grpList = filtered;
              }
            }
            global.processBlockControlValues[index].controlValue = JSON.stringify(grpList);
          }
          else{
            let grpList = [];
            if(e.target.checked)
            {
              grpList.push(this.props.dataItem['column']);
              global.processBlockControlValues[index].controlValue = JSON.stringify(grpList);
            }
          }
        }
        });
    }

    render() {
        let checked = false;
        //Needed to differentiate columns in grid mapper
        var position = typeof(this.props.className) !== 'undefined' ? this.props.className : '';
        if (this.props.dataItem[this.props.field]) {
          checked = true;
        }
        return (
            <div className="text-center width-full">
               <input type="checkbox" className="am-checkbox" id={`checkbox-${this.props.field.replace(' ', '_')}-${this.props.dataItem['columnId']}-${position}-${this.props.dataItem['id']}`} onChange={this.handleChange.bind(this)}
               checked={checked}/>
               <label className="am-checkbox-label" htmlFor={`checkbox-${this.props.field.replace(' ', '_')}-${this.props.dataItem['columnId']}-${position}-${this.props.dataItem['id']}`}></label>
            </div>
        );
    }
}
