import React from 'react';
import { DropDownList } from './StandardDropDownList';
import { connect } from 'react-redux';
import { PropTypes } from 'prop-types';
import { createSelector } from 'reselect';
import { setMessage } from '../../../../../actions/actionNotification';

export const RefPfElementIdReplaceCell = ({ tableManager, value, data, column, colIndex, rowIndex }) => {
    if (typeof(tableManager) === 'undefined') {
      return (null)
    } else {
      return (
          <div className='rgt-cell-inner' style={{display: 'flex', alignItems: 'center', overflow: 'hidden'}}>
              <RefPfElementIdReplaceCellClass dataItem={data} field={column.field} value={typeof(data[column.field]) !== 'undefined' ? data[column.field]: value} column={column} tableManager={tableManager}/>
          </div>
      )
    }
}

class BaseRefPfElementIdReplaceCell extends React.Component {
  getGlobalValue(controlIndex){
    let controlValue = '';
    global.processBlockControlValues.forEach((control, index) => {
      if (control.controlId === controlIndex) {
        controlValue = global.processBlockControlValues[index].controlValue;

      }
    });
    return controlValue;
  }

  saveGlobalValue(controlIndex, value){
    global.processBlockControlValues.forEach((control, index) => {
      if (control.controlId === controlIndex) {
        global.processBlockControlValues[index].controlValue = value;
      }
    });
  }
  constructor(props){

    super(props);
    let refsData  = [];
    if(typeof(props.referencePoints) !== "undefined" && props.referencePoints !== null){
      refsData = props.referencePoints
    }

    this.state = {
      refsData:refsData,
      refreshKey: 0
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps){
    if(JSON.stringify(nextProps.referencePoints) !== "" ){
      let refreshKey = this.state.refreshKey+1;
      this.setState({refsData:nextProps.referencePoints, refreshKey: refreshKey});
    }
  }
  handleChange(e) {
    this.props.dataItem[this.props.field] = e.target.value;
    this.props.tableManager.rowsApi.setRows(this.props.tableManager.rowsApi.rows)
  }

  render() {
    let refElement = this.props.dataItem['refPfName'];
    let refIdsCollection = [];
    if(refElement !== ""){
      this.state.refsData.forEach((item)=>{
        if(item.RF_NM === refElement)
        refIdsCollection.push(item.RFE_ID);
       });
       refIdsCollection.sort();
       refIdsCollection.push("Min");
       refIdsCollection.push("Max");

    }
    return (
        <>
          <DropDownList className="width-full" onChange={this.handleChange.bind(this)} data={refIdsCollection} value={this.props.dataItem[this.props.field]} key={this.state.refreshKey}/>
        </>
      );
    }
}

const mapStateToProps = createSelector(
  state => state.dataOperation.referencePoints,
  state => state.dataOperation.referencePointsName,
  (referencePoints, referencePointsName) => ({
    referencePoints,
    referencePointsName,
  })
);

const mapActionsToProps = {
  setMessage: setMessage
}

BaseRefPfElementIdReplaceCell.propTypes = {
  tenantId: PropTypes.string
}

const RefPfElementIdReplaceCellClass = connect(mapStateToProps, mapActionsToProps)(BaseRefPfElementIdReplaceCell);
