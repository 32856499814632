import "./../TaskManagement.css";
import React from "react";
import { Button } from './../../DataOperation/common/Button/StandardButton';
import { Input } from './../../DataOperation/common/InputBox/StandardInput';
import { PropTypes } from "prop-types";
import Dialog from 'react-dialog';
import { DropDownList } from './../../DataOperation/common/DropDown/StandardDropDownList';
import moment from 'moment';

function template() {
  return (
    <div className={`create-task-dialog bulk-action-task-dialog`}>
      <Dialog title={`Bulk Action Task`} width="50%" height="300px" onClose={this.props.closeEvent} buttons={[<Button primary={true} onClick={this.handleSaveTaskClick} disabled={this.state.commentDescription.trim() === ''} key="btn-save">Save</Button>,<Button onClick={this.props.closeEvent} key="btn-cancel">Cancel</Button>]}>
        <div className="container-fluid m-t-5">
          <div className="row task-detail">
            <div className="col-12">
              <div className="am-form-field">
                <span className="title">New Comment:</span>
                <textarea className="text-box-display am-textbox textarea-box" defaultValue={this.state.commentDescription} onChange={this.handleCommentDescriptionChange} />
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-4">
              <div className="am-form-field">
                <span className="title">Status:</span>
                <DropDownList data={this.state.statusList} textField={'statusName'} dataItemKey={'statusId'} onChange={this.handleStatusChange} value={this.state.selectedStatus}/>
              </div>
            </div>
            <div className="col-6">
              <div className="am-form-field">
                <span className="title">Assign User:</span>
                <DropDownList data={this.state.userList} textField={'userName'} dataItemKey={'userId'} onChange={this.handleUserChange} value={this.state.selectedUser}/>
              </div>
            </div>
          </div>
        </div>
      </Dialog>
    </div>
  )
}


Dialog.propTypes = {
    height: PropTypes.oneOfType([
        PropTypes.string
    ]),
    width: PropTypes.oneOfType([
        PropTypes.string
    ])
}
export default template;
