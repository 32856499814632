import React from "react";
import { Button } from './../Button/StandardButton';
import { Input } from './../InputBox/StandardInput';
import { PanelBar, PanelBarItem } from './../Layout';
import restClient from '../../../../../restClient';
import { GET_ALL } from '../../../../../restClient/types';
import $ from 'jquery';
import axios from 'axios';
import MessageTypeConst from "../../../../MessageTypeConst";
import { connect } from 'react-redux';
import { PropTypes } from 'prop-types';
import { createSelector } from 'reselect';
import { setMessage } from '../../../../../actions/actionNotification';
import DataOpsIdentifierConstants from "../../../../DataOpsIdentifierConstants";

let outputTableColumnList = null;
let outputTableIsDistinct = false;
class DataExploratoryOperation extends React.Component {
  fetchDataViewer = (headers, params) => restClient(GET_ALL, 'exploratory/filter', params, headers);
  constructor(props) {
    super(props);

    this.state={
      tableList: props.tableList,
      wrapperClassName: '',
      gridType:props.type,
      expandedclassName: [],
      tenantID: props.tenantID,
      postKey:0
    }
    this.handleTableStateChangeEvent = this.handleTableStateChangeEvent.bind(this);
    this.handleWhereCondtionChange = this.handleWhereCondtionChange.bind(this);
    this.getPreWhereCondition = this.getPreWhereCondition.bind(this);
    this.addUpdateValueinGlobalVariable = this.addUpdateValueinGlobalVariable.bind(this);
    this.handlePanelSelect = this.handlePanelSelect.bind(this);
    this.handleApplyButton = this.handleApplyButton.bind(this);
    this.fetchData = this.fetchData.bind(this);
    this.handleDistinctChange = this.handleDistinctChange.bind(this);

    global.dataExplorerTablePanelBarItemStatus = [];
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if(JSON.stringify(nextProps) !== '') {
      var wrapperClassName = '';
      let uniqueObject = this.getUniqueTables(nextProps.tableList);
      if (uniqueObject.length === 2) {
        wrapperClassName = 'two-grid';
      } else if(uniqueObject.length > 2) {
        wrapperClassName = 'two-grid more-than-two-grid';
      }
      this.setState({tableList: nextProps.tableList, wrapperClassName: wrapperClassName});
    }
  }
  getPreWhereCondition(table){
    let condition = '';
    if(typeof(table) !== 'undefined'){
      let preWhereData = '';
      if(this.state.gridType === 'preWhere'){
        preWhereData = this.getGlobalValue(-99);
      } else if(this.state.gridType === 'data'){
        preWhereData = this.getGlobalValue(-96);
      }
      if(typeof(preWhereData) !== 'undefined' && preWhereData !== ''  && preWhereData !== null){
          let preWhere = JSON.parse(preWhereData);
          let selectedObj = preWhere.filter((obj => obj.tableName === table.name));
          if(selectedObj.length > 0){
              condition = selectedObj[0].preWhereCondition;
          }
      }
    }
    return condition;
  }
  getGlobalValue(controlIndex){
    let controlValue = '';
    global.processBlockControlValues.forEach((control, index) => {
      if (control.controlId === controlIndex) {
        controlValue = global.processBlockControlValues[index].controlValue;
      }
    });
    return controlValue;
  }
  saveGlobalValue(controlIndex, value){
    global.processBlockControlValues.forEach((control, index) => {
      if (control.controlId === controlIndex) {
        global.processBlockControlValues[index].controlValue = value;
      }
    });
  }

  handleTableStateChangeEvent(event) {
    this.fetchData(event,true);
  }

  handleApplyButton(event){
    let isReset = false;
    this.fetchData(event,isReset);

  }

  handleDistinctChange = (event, tableIndex) => {
    let tableList = this.state.tableList;

    if (typeof(tableList[tableIndex]) !== 'undefined') {
      tableList[tableIndex] = Object.assign({}, tableList[tableIndex], {distinctChecked: event.target.checked})
    }

    this.setState({tableList: tableList});
  }

  fetchData(event, isReset = false)
  {
    let tableName = event.target.value;
    let tableIndex = 0;
    this.state.tableList.forEach((table, index) =>{
        if(table.name === tableName){
          tableIndex = index;
        }
    })
    let selectedTable = this.state.tableList.filter((table)=> table.name === tableName);
    if(selectedTable.length === 0){
      return;
    }
    let tenantID = this.state.tenantID;
    let whereCondition = '';
    let columnList = '';
    let isDistinct = '';
    let firstRow = selectedTable[0].rows[0];

    if (typeof(document.getElementById(`${tableName}-${this.state.gridType}`)) !== 'undefined' && document.getElementById(`${tableName}-${this.state.gridType}`) !== null) {
      whereCondition = document.getElementById(`${tableName}-${this.state.gridType}`).value;
    } else {
      whereCondition = this.getPreWhereCondition(tableName);
    }

    if (typeof(document.getElementsByClassName('exploratory_variables_input_'+tableName+'_'+this.state.gridType)) !== 'undefined' && document.getElementsByClassName('exploratory_variables_input_'+tableName+'_'+this.state.gridType).length > 0) {
      columnList = document.getElementsByClassName('exploratory_variables_input_'+tableName+'_'+this.state.gridType)[0].value;
    } else {
      columnList = outputTableColumnList;
    }

    if (typeof(document.getElementById(tableName+'-nodupkey-'+ this.state.gridType)) !== 'undefined' && document.getElementById(tableName+'-nodupkey-'+ this.state.gridType) !== null) {
      isDistinct = document.getElementById(tableName+'-nodupkey-'+ this.state.gridType).checked;
    } else {
      isDistinct = outputTableIsDistinct;
    }

    var that = this;
    if (!isReset && (columnList === '' || columnList === null)) {
      that.props.setMessage("Please enter variable", MessageTypeConst.WARNING_MESSAGE);
    } else {
      $(".loader").show();
      //, engineId: typeof(that.props.selectedEngine) !== 'undefined' && typeof(that.props.selectedEngine.value) !== 'undefined' ? that.props.selectedEngine.value : 0
      axios.all([this.fetchDataViewer({'Tenant-ID': tenantID}, {whereCondition: whereCondition,isOutputTable:global.outputBlockTable === tableName && global.processBlockExecuteBlockId > 0,
        columnList:columnList,isReset:isReset,isDistinct:isDistinct,tableName:tableName,
         pagination:{ page: 1, perPage: 50 }})])
          .then(axios.spread((dataViewer) => {
              $(".loader").hide();
              if(typeof(dataViewer.data) !== 'undefined' && typeof(dataViewer.data.htmlContent) !== 'undefined') {
                let postKey = this.state.postKey + 1
                let tableList=  this.state.tableList;
                tableList[tableIndex]['htmlContent'] = dataViewer.data.htmlContent;
                tableList[tableIndex]['columns']['columnField'] = columnList
                if (isReset === true) {
                  tableList[tableIndex]['columns']['columnField'] = tableList[tableIndex]['columns']['originColumnField']
                }
                this.setState({postKey: postKey, tableList:tableList});
                that.props.setMessage("Data refreshed successfully according to condition.", MessageTypeConst.SUCCESS_MESSAGE);
              } else {
                $(".loader").hide();
                that.props.setMessage("Failed to fetch the data please check the variables and condition.", MessageTypeConst.ERROR_MESSAGE);
              }

              })).catch(error => {
                  $(".loader").hide();
                  that.props.setMessage("Failed to fetch the data please check the variables and condition.", MessageTypeConst.ERROR_MESSAGE);
              });
    }
  }

  handleWhereCondtionChange(event) {
    if(this.state.gridType === 'preWhere'){
      let tableName = event.target.id.substr(0,event.target.id.lastIndexOf('-'));
      this.addUpdateValueinGlobalVariable(-99, tableName,event.target.value);
    } else if(this.state.gridType === 'data'){
      let tableName = event.target.id.substr(0,event.target.id.lastIndexOf('-'));
      this.addUpdateValueinGlobalVariable(-96, tableName,event.target.value);
    }
      this.setState({tableList: this.state.tableList});
  }

  addUpdateValueinGlobalVariable(GlobalId,  tableName, value){
    let preWhereData = this.getGlobalValue(GlobalId);
    if(preWhereData !== '' && preWhereData !== null){
      let preWhere = JSON.parse(preWhereData);
      let objectFound = false;
      let objIndex = preWhere.findIndex((obj => obj.tableName === tableName));
      if(objIndex > -1){
        preWhere[objIndex].preWhereCondition = value;
        objectFound = true;
      }
      //add new condition
      if(!objectFound){
        let condition = {tableName:tableName,preWhereCondition:value};
        preWhere.push(condition);
      }
      if(GlobalId !== -96){
        preWhere = this.arrangePreWhereAccordingToInputTableSequence(preWhere);
      }
      this.saveGlobalValue(GlobalId,JSON.stringify(preWhere));
    } else{
      //no control value present, so directy add condition
      let preWhere = [];
      let condition = {tableName:tableName,preWhereCondition:value};
      preWhere.push(condition);
      if(GlobalId !== -96){
        preWhere = this.arrangePreWhereAccordingToInputTableSequence(preWhere);
      }
      this.saveGlobalValue(GlobalId,JSON.stringify(preWhere));
    }
  }

  arrangePreWhereAccordingToInputTableSequence = (preWhere) => {
    let inputTables = this.getGlobalValue(-97) !== null  ? this.getGlobalValue(-97).split(',') : [];
    let newPreWhere = [];
    inputTables.forEach((tableName) => {
      let objectFound = false;
      let objIndex = preWhere.findIndex((obj => obj.tableName === tableName));
      if(objIndex > -1){
        newPreWhere.push(preWhere[objIndex]);
        objectFound = true;
      }
      //add new condition
      if(!objectFound){
        let condition = {tableName:tableName,preWhereCondition:""};
        newPreWhere.push(condition);
      }
    });
    return newPreWhere;
  }

  getColumnFields = (row, tableName) => {
    var columns = [];
    let studyIndentifier1 = "";
    let studyIndentifier2 = "";
    let studyIndentifier3 = "";
    let rowColumns = Object.keys(row);
    let selectedStudy = this.props.studyList.filter(study => study.schemaName === this.props.tenantId);
    if(selectedStudy.length > 0){
      studyIndentifier1 = typeof(selectedStudy[0].identifier1) !== "undefined" && selectedStudy[0].identifier1 !== null ? selectedStudy[0].identifier1 : "";
      studyIndentifier2 = typeof(selectedStudy[0].identifier2) !== "undefined" && selectedStudy[0].identifier2 !== null ? selectedStudy[0].identifier2 : "";
      studyIndentifier3 = typeof(selectedStudy[0].identifier3) !== "undefined" && selectedStudy[0].identifier3 !== null ? selectedStudy[0].identifier3 : "";
    }
    if(rowColumns.indexOf(studyIndentifier1) !== -1){
      columns.push(studyIndentifier1);
    }
    if(rowColumns.indexOf(studyIndentifier2) !== -1){
      columns.push(studyIndentifier2);
    }
    if(rowColumns.indexOf(studyIndentifier3) !== -1){
      columns.push(studyIndentifier3);
    }
    let nonkeyColums = rowColumns.filter(name => name.indexOf('_app_') === -1 && name.indexOf('APP_') === -1 &&
    name !== studyIndentifier1 && name !== studyIndentifier2 && name !== studyIndentifier3);
    if(this.getGlobalValue(-1) !== DataOpsIdentifierConstants.COMPARE_OP &&
    typeof(global.outputBlockTable) !== "undefined" && global.outputBlockTable !== null && global.outputBlockTable !== "" && global.outputBlockTable !== tableName){
      nonkeyColums = nonkeyColums.sort();
    }
    for (let i =0; i<nonkeyColums.length;i++) {
      columns.push(nonkeyColums[i]);
    }
    let appkeyColums = rowColumns.filter(name => name.indexOf('APP_KEY') !== -1);
    for (let i =0; i<appkeyColums.length;i++) {
      columns.push(appkeyColums[i]);
    }
    let appPkeyColums = rowColumns.filter(name => name.indexOf('APP_PKEY') !== -1);
    for (let i =0; i<appPkeyColums.length;i++) {
      columns.push(appPkeyColums[i]);
    }
    let appRkeyColums = rowColumns.filter(name => name.indexOf('APP_RKEY') !== -1);
    for (let i =0; i<appRkeyColums.length;i++) {
      columns.push(appRkeyColums[i]);
    }
    let appLkeyColums = rowColumns.filter(name => name.indexOf('APP_LKEY') !== -1);
    for (let i =0; i<appLkeyColums.length;i++) {
      columns.push(appLkeyColums[i]);
    }
    let appWarnColums = rowColumns.filter(name => name.indexOf('APP_WARN') !== -1);
    for (let i =0; i<appWarnColums.length;i++) {
      columns.push(appWarnColums[i]);
    }
    let keyColums = rowColumns.filter(name => (name.indexOf('_app_') !== -1 || name.indexOf('APP_') !== -1) && (name.indexOf('APP_KEY') === -1
    && name.indexOf('APP_PKEY') === -1 && name.indexOf('APP_RKEY') === -1 && name.indexOf('APP_LKEY') === -1 && name.indexOf('APP_WARN') === -1));
    for (let i =0; i<keyColums.length;i++) {
      columns.push(keyColums[i]);
    }

    return columns.join(' ');
  };

  handlePanelSelect = (event, tableIndex, tableName) => {
    let expandedclassName = this.state.expandedclassName;
    expandedclassName[tableIndex] = event.target.props.expanded ? 'increase-grid-height' :'';

    global.dataExplorerTablePanelBarItemStatus = global.dataExplorerTablePanelBarItemStatus.filter( table => table.name !== tableName );

    global.dataExplorerTablePanelBarItemStatus.push({ name: tableName, status: !event.target.props.expanded });

    if (typeof(global.outputBlockTable) !== 'undefined' && global.outputBlockTable !== '' && global.outputBlockTable !== null) {
      if (global.outputBlockTable === tableName) {
          if (typeof(document.getElementsByClassName('exploratory_variables_input_'+tableName+'_'+this.state.gridType)) !== 'undefined') {
              outputTableColumnList = document.getElementsByClassName('exploratory_variables_input_'+tableName+'_'+this.state.gridType)[0].value;
          }

          if (typeof(document.getElementById(tableName+'-nodupkey-'+ this.state.gridType)) !== 'undefined') {
              outputTableIsDistinct = document.getElementById(tableName+'-nodupkey-'+ this.state.gridType).checked;
          }
      }
    }


    this.setState({ expandedclassName: expandedclassName });
  }

  applyButtonRef = (element, tableName) => {
    if (typeof(global.outputBlockTable) !== 'undefined' && global.outputBlockTable !== '' && global.outputBlockTable !== null && element !== null) {
      if (global.outputBlockTable === tableName && typeof(global.restOutPutTableInDataViewer) !== 'undefined' && global.restOutPutTableInDataViewer === true) {
            element.click();
            global.restOutPutTableInDataViewer = false;
      }
    }
  }

  TableDataBar = (table, tableIndex) => {
    let hidden = table.status ? '' : 'hidden';
    let whereCondition = this.getPreWhereCondition(table);
    let expandedclassName = this.state.expandedclassName;
    let distinctChecked = false;
    let expanded = this.state.gridType === 'preWhere' ? true : false;
    expandedclassName.push(["increase-grid-height"])

    if (this.state.gridType === 'data') {
      if (typeof(global.dataExplorerTablePanelBarItemStatus) !== 'undefined') {
        let tablePanelBarItem = global.dataExplorerTablePanelBarItemStatus.filter( item => item.name === table.name );

        if (tablePanelBarItem.length > 0) {
          expanded = tablePanelBarItem[0].status;
        }
      }
    }

    if(typeof(table.distinctChecked) !== 'undefined') {
      distinctChecked = table.distinctChecked;
    }

    table = Object.assign(table, {isDynamicGrid: true});
    return (
      <div className={`card ${hidden}`} key={`${table.name}_${this.state.postKey}`}>
        <div className="card-block">
          <div className="">
          <fieldset>
              <PanelBar onSelect={event => this.handlePanelSelect(event, tableIndex, table.name)} openId={[expanded === true ? tableIndex+'-table' : '']}>
                <PanelBarItem key={tableIndex} id={tableIndex+'-table'} expanded={expanded} title={typeof(table.fullTableName) !== 'undefined' ? table.fullTableName : table.name}>
                  <div className="field_group">
                    <div className="am-form-inline">
                      <div className="am-form-field">
                        <span>Variables:</span>
                        <Input className={`exploratory_variables_input_${table.name}_${this.state.gridType}`} name={`variables[${table.name}]`} defaultValue={table.columns.columnField} placeholder="Variables" />
                        <Button primary={true} className="m-left" value={table.name} onClick={this.handleTableStateChangeEvent}>Reset Variable</Button>
                      </div>
                      <div className="am-form-field">
                        <span>Where condition:</span>
                        <Input placeholder="Where condition" onChange={this.handleWhereCondtionChange} id={`${table.name}-${this.state.gridType}`} value={whereCondition}/>
                        <div className="am-form-field m_b_0 m-left">
                          <input type="checkbox" id={`${table.name}-nodupkey-${this.state.gridType}`} className="am-checkbox" checked={distinctChecked} onChange={event => this.handleDistinctChange(event, tableIndex)}/>
                          <label className="am-checkbox-label" htmlFor={`${table.name}-nodupkey-${this.state.gridType}`}>Distinct </label>
                          <button type="button" className="k-button k-primary m-left" value={table.name} onClick={this.handleApplyButton}>Apply</button>

                        </div>
                      </div>
                    </div>
                  </div>
                </PanelBarItem>
              </PanelBar>

              {/* <div className="am-form-field">
              </div> */}
              <div className={`height_fixed_pre ${expandedclassName[tableIndex]}`}>
                {typeof(table.htmlContent) !== 'undefined' && table.htmlContent !== '' &&
                  <iframe srcDoc={table.htmlContent} className="html-preview" />
                }
                {typeof(table.htmlContent) === 'undefined' &&
                    <h5>No HTML Found</h5>
                }
              </div>

              <button type="button" className="k-button k-primary m-left hidden" value={table.name} onClick={this.handleApplyButton} ref={element => this.applyButtonRef(element, table.name)}>Apply</button>
          </fieldset>
          </div>
        </div>
      </div>

    )
  };

  getUniqueTables(tableList){
    let tableAdded = [...new Set(tableList.map(item => item.name))];
    let uniqueObject = [];
    tableAdded.forEach((item) =>{
      let selectedTable = tableList.filter((table) => table.name === item && table.status === true);
      if(selectedTable.length > 0){
        uniqueObject.push(selectedTable[0]);
      }
    })
    return uniqueObject;
  }

  render() {
      let uniqueObject = this.getUniqueTables(this.state.tableList);
      return (
        <div className="grid-operation clearfix">
          <div className={`dataViewer ${this.state.wrapperClassName}`}>
            <div className="side_by_side_grid">
            {
              uniqueObject.map(this.TableDataBar)
            }
            </div>
          </div>
        </div>
      );
    }
}
const mapStateToProps = createSelector(
  state => state.processFlow.tenantId,
  state => state.study.studyList,
  state => state.dataOperation.selectedEngine,
  (tenantId,studyList, selectedEngine) => ({
    tenantId,
    studyList,
    selectedEngine
  })
);

const mapActionsToProps = {
  setMessage: setMessage
}

DataExploratoryOperation.propTypes = {
  tenantId: PropTypes.string
}

export const ExploratoryOperation = connect(mapStateToProps, mapActionsToProps)(DataExploratoryOperation);
