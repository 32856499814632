import React from 'react';
import { Button } from './StandardButton';
import { PropTypes } from "prop-types";
import Dialog from 'react-dialog';
import { POST_PARAM_BODY } from '../../../../../restClient/types';
import restClient from '../../../../../restClient';
import axios from 'axios';
import $ from 'jquery';
import {Controlled as CodeMirror} from 'react-codemirror2';
import { connect } from 'react-redux';
import { createSelector } from 'reselect';
import MessageTypeConst from "../../../../MessageTypeConst";
import { setMessage } from '../../../../../actions/actionNotification';

export const CodeButton = ({ tableManager, value, data, column, colIndex, rowIndex }) => {
    if (typeof(tableManager) === 'undefined') {
      return (null)
    } else {
      return (
          <div className='rgt-cell-inner' style={{display: 'flex', alignItems: 'center', overflow: 'hidden'}}>
              <CodeButtonClass dataItem={data} field={column.field} value={typeof(data[column.field]) !== 'undefined' ? data[column.field]: value} column={column} tableManager={tableManager}/>
          </div>
      )
    }
}

class BaseCodeButton extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
          code:'',
          visible: false
        };
        this.closeDialog = this.closeDialog.bind(this);
        this.openDialog = this.openDialog.bind(this);

      }
      openDialog(){
        var code = this.props.dataItem['code'];
        let that = this;
        if(code !== null && code !== ""){
            this.setState({code:code, visible: true})
        }else{
            let fetchCode = (headers, params) => restClient(POST_PARAM_BODY, 'dataviewer/code', params, headers);
            var params = null;
            if (typeof(this.props.dataItem['outTableBlock']) !== "undefined" && this.props.dataItem['outTableBlock'].length > 0) {
              params = this.props.dataItem['outTableBlock'][0]
            } else {
              params = JSON.parse(this.props.dataItem['block']);
              params = Object.assign({},params,{blockVersion:this.props.dataItem['versionNo']});
            }

            let Tenant_ID = this.props.tenantId;
            $(".loader").show();
            axios.all([fetchCode({'Tenant-ID': Tenant_ID}, { data: params })])
                .then(axios.spread((codeResponse) => {
                    if (typeof(codeResponse.data) !== 'undefined') {
                    if (typeof(codeResponse.data.code) !== 'undefined' && codeResponse.data.code !== null) {
                        that.setState({code:codeResponse.data.code, visible: true});
                        that.props.dataItem['code'] = codeResponse.data.code;
                    }
                    }
                    $(".loader").hide();
                }
                )).catch(error => {
                    $(".loader").hide();
                    that.props.setMessage("Failed to fetch code.",MessageTypeConst.ERROR_MESSAGE)
                });
        }
      }
      closeDialog() {
        this.setState({
            visible: false
          });
      }

      render() {
          let disabled = false;
          let block = typeof(this.props.dataItem['block']) !== "undefined" && this.props.dataItem['block'] !== null && this.props.dataItem['block'] !== "" ?
          JSON.parse(this.props.dataItem['block']) : {};
          if((typeof(block.blockLevel) !== "undefined" && block.blockLevel !== null && block.blockLevel === 0) || (typeof(this.props.dataItem['jobId']) !== 'undefined' && (this.props.dataItem['code'] === '' || this.props.dataItem['code'] === null))){
            disabled = true;
          }

          if (typeof(this.props.dataItem['outTableBlock']) !== "undefined" && this.props.dataItem['outTableBlock'].length > 0) {
            disabled = false;
          }

          return (
              <div className="text-center width-full text-editor">
                <Button onClick={this.openDialog} disabled={disabled}> Code </Button>
                  {this.state.visible &&
                    <div className="data-operation code-dialog log-dialog">
                      <Dialog className="data-properties" title={'Code'} width="calc(100% - 200px)" height="calc(100vh - 120px)" onClose={this.closeDialog}>
                          <div>
                              <div className="log_Editor text-editor execute_all">
                                <CodeMirror value={this.state.code} options = { {'lineNumbers': true} }/>
                              </div>
                          </div>
                      </Dialog>
                    </div>
                }
              </div>
          );
      }
}

const mapStateToProps = createSelector(
    state => state.processFlow.tenantId,
    (tenantId) => ({
        tenantId
    })
  );

  const mapActionsToProps = {
    setMessage: setMessage
  }

  BaseCodeButton.propTypes = {
    tenantId: PropTypes.string
  }

  Dialog.propTypes = {
      height: PropTypes.oneOfType([
          PropTypes.string
      ]),
      width: PropTypes.oneOfType([
          PropTypes.string
      ])
  }
  const CodeButtonClass = connect(mapStateToProps, mapActionsToProps)(BaseCodeButton);
