import { FETCH_STUDY, ADD_STUDY, REMOVE_STUDY, REMOVE_STUDY_BY_PROJECT_ID, ADD_MULTIPLE_STUDY, SET_STUDY_MANAGER, SET_STUDY_DESCRIPTION, PROJECT_UPDATE_STUDY_ACTION } from '../actions/actionsTypes';

const INITIAL_DATA = {
  studyList:[],
  study:{},
  fetchedStudyProjectIds: [],
  studyManagerSchemaName: ''
};

export default function studyReducer(state=INITIAL_DATA, action) {
  let studyList;
  switch (action.type) {
    case FETCH_STUDY:
      let fetchedStudyProjectIds = state.fetchedStudyProjectIds.slice();
      fetchedStudyProjectIds.splice(fetchedStudyProjectIds.length, 0, action.projectId);

      return {
        ...state,
        studyList: state.studyList.filter( study => study.projectId !== action.projectId).concat(action.payload),
        fetchedStudyProjectIds: fetchedStudyProjectIds
      }
    case ADD_STUDY:
      studyList = state.studyList.filter(study => study.studyId !== action.payload.studyId).slice();
      studyList.splice(studyList.length, 0, action.payload);

      return {
        ...state,
        studyList: studyList
      }
    case REMOVE_STUDY:
      return {
          ...state,
          studyList: state.studyList.filter( study => study.studyId !== action.payload )
      }
    case REMOVE_STUDY_BY_PROJECT_ID:
      return {
          ...state,
          studyList: state.studyList.filter( study => study.projectId !== action.payload )
      }
    case ADD_MULTIPLE_STUDY:
      studyList = state.studyList
      action.payload.forEach(studyItem => {
        studyList = studyList.filter(study => study.studyId !== studyItem.studyId).slice();
        studyList.splice(studyList.length, 0, studyItem);
      })
      return {
          ...state,
          studyList: studyList
      }
    case SET_STUDY_MANAGER:
      return {
          ...state,
          studyManagerSchemaName: action.payload
      }
    case SET_STUDY_DESCRIPTION:
      studyList = state.studyList
      studyList.filter(study => study.studyId === action.payload.studyId).map(study => {
        study.description = action.payload.description
        return study
      })
      return {
        ...state,
        studyList: studyList
      }
    case PROJECT_UPDATE_STUDY_ACTION:
      state.studyList.filter(study => study.studyId === action.payload.studyId).map(study => {
        if (typeof(study.enableActions) !== 'undefined') {
          study.enableActions.length = 0
          study.enableActions.push(...action.payload.enableActions)
        }
        return study
      })
      return {
        ...state,
        studyList: state.studyList
      }
    default:
      return state;
  }
}
