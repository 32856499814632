import React from 'react';
import { PropTypes } from "prop-types";
import { Button } from './../DataOperation/common/Button/StandardButton';
import Dialog from 'react-dialog';
import { GET } from '../../../restClient/types';
import restClient from '../../../restClient';
import axios from 'axios';
import $ from 'jquery';
import { connect } from 'react-redux';
import { createSelector } from 'reselect';
import { createShape, createNotes } from '../../ProcessFlow/ProcessFlows.js';
import MessageTypeConst from '../../MessageTypeConst';
import { setMessage } from '../../../actions/actionNotification';
import { addProcessFlow, setActiveProcessFlow } from '../../../actions/actionProcessFlow';
import ConfirmBox from './../DataOperation/common/ConfirmBox';
import ProcessFlow from './../ProcessFlow';

export const CompareFlowButton = ({ tableManager, value, data, column, colIndex, rowIndex }) => {
    if (typeof(tableManager) === 'undefined') {
      return (null)
    } else {
      return (
          <div className='text-center rgt-cell-inner' style={{display: 'flex', alignItems: 'center', overflow: 'hidden'}}>
              <CompareFlowButtonClass dataItem={data} field={column.field} value={value}/>
          </div>
      )
    }
}

class BaseCompareFlowButtonClass extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            sourceBlockAndLinkData:{processBlock:[],processBlockLink:[],processFlowNotes:[]},
            targetBlockAndLinkData:{processBlock:[],processBlockLink:[],processFlowNotes:[]},
            showCompareDialog:false,
            className:'col-md-6'
        }
    }

    handleChange(e) {
        this.props.dataItem[this.props.field] = e.target.value;
        this.props.tableManager.rowsApi.setRows(this.props.tableManager.rowsApi.rows)
    }

    handleOnClick = (event) => {
        let that = this;
        let className = "col-md-12";
        if(that.props.dataItem.processFlowId !== 0 && that.props.dataItem.sourceFlowId !== 0){
            className = "col-md-6";
        }
        this.setState({showCompareDialog:true, className: className},()=>{
            if(that.props.dataItem.processFlowId !== 0){
                that.getBlockAndLinkFromProcessFlow(that.props.dataItem.processStudyId, that.props.dataItem.processFlowId, "targetDiagram")
            }
            if(that.props.dataItem.sourceFlowId !== 0){
                that.getBlockAndLinkFromProcessFlow(that.props.dataItem.sourceStudyId, that.props.dataItem.sourceFlowId, "sourceDiagram")
            }
        });
    }

    closeDialog = (event) => {
        this.setState({showCompareDialog:false});
    }

    getBlockAndLinkFromProcessFlow = (studyId, processId, diagramId) => {
        var data = {processBlock:[],processBlockLink:[], processFlowNotes:[] };
        let treeView = this.props.treeView;
        var that = this;
        let schemaName = '';
        treeView.data.children.forEach((project)=>{
                project.children.forEach((study)=>{
                    if(study.study.studyId === studyId){
                        schemaName = study.study.schemaName;
                        study.children.forEach((studyElement) => {
                            if (studyElement.name === 'ProcessFlows') {
                              studyElement.children.forEach((classifier)=>{
                                classifier.children.forEach((group)=>{
                                    group.children.forEach((flow)=>{
                                    if(flow.process.processId === processId){
                                        if(flow.process.processBlock === null || typeof(flow.process.processBlock) === "undefined" ||
                                        flow.process.processBlockLink === null || typeof(flow.process.processBlockLink) === "undefined"){
                                        //make api call to populate blocks and links

                                        $(".loader").show();
                                        axios.all([
                                            restClient(GET, 'processblock/blockAndLinkList?studyId='+studyId+'&processId='+processId, {}, {'Tenant-ID':schemaName})
                                            ])
                                            .then(axios.spread((response) => {
                                                if (typeof(response.data) !== 'undefined') {
                                                    if (typeof(response.data) !== 'undefined' && typeof(response.data.messageType) !== 'undefined' && response.data.messageType !== null && response.data.messageType ===  MessageTypeConst.WARNING_MESSAGE) {
                                                        that.props.setMessage(response.data.message, response.data.messageType);
                                                        $(".loader").hide();
                                                        return;
                                                    }
                                                flow.process.processBlock = []
                                                if (response.data.processBlock !== null && response.data.processBlock !== '' && response.data.processBlock.length > 0) {

                                                    flow.process.processBlock.push(...response.data.processBlock);
                                                    data.processBlock = flow.process.processBlock;
                                                }

                                                flow.process.processBlockLink = []
                                                if (response.data.processBlockLink !== null && response.data.processBlockLink !== '' && response.data.processBlockLink.length > 0) {
                                                    flow.process.processBlockLink.push(...response.data.processBlockLink);
                                                    data.processBlockLink = flow.process.processBlockLink;
                                                }
                                                }

                                                flow.process.processFlowNotes = []
                                                if (response.data.processFlowNotes !== null && response.data.procprocessFlowNotesessBlock !== '' && response.data.processFlowNotes.length > 0) {

                                                    flow.process.processFlowNotes.push(...response.data.processFlowNotes);
                                                    data.processFlowNotes = flow.process.processFlowNotes;
                                                }
                                                if(diagramId === "targetDiagram"){
                                                    that.setState({targetBlockAndLinkData:data});

                                                }else if(diagramId === "sourceDiagram"){
                                                    //make api call to populate blocks and links
                                                    that.setState({sourceBlockAndLinkData:data});

                                                }

                                                $(".loader").hide();
                                            }))
                                            .catch(error => {
                                                $(".loader").hide();
                                                that.props.setMessage("Failed to fetch blocks and links!",MessageTypeConst.ERROR_MESSAGE);
                                            });
                                        }
                                        else{
                                            data.processBlock = flow.process.processBlock;
                                            data.processBlockLink = flow.process.processBlockLink;
                                            data.processFlowNotes = flow.process.processFlowNotes;
                                            if(diagramId === "targetDiagram"){
                                                that.setState({targetBlockAndLinkData:data});

                                            }else if(diagramId === "sourceDiagram"){
                                                //make api call to populate blocks and links
                                                that.setState({sourceBlockAndLinkData:data});

                                            }
                                        }
                                    }
                                  });
                                });
                              })

                    }
                })
                    }
                });
        });
        return data;
    }

    handleOnSourceFlowClick = (event) => {
        let that = this;
        let process = that.fetchProcessFlow(that.props.dataItem.sourceStudyId, that.props.dataItem.sourceFlowId );
        if(process !== null){
          let flowAlreadyOpen = false;
          that.props.processFlowList.forEach((flow, index) => {
            if(flow.process.processId === process.process.processId && flow.process.studyId === process.process.studyId && flow.process.processGroupId === process.process.processGroupId){
              flowAlreadyOpen = true;
              that.props.setActiveProcessFlow(index);
            }
          });
          if(!flowAlreadyOpen){
            that.props.addProcessFlow(process);
          }
          window.location.hash='#process-flow-list';
          that.props.dataItem.closeEvent();
        }else{
          ConfirmBox.alert("Study for selected jump to block not included. Please select appropriate Study using ‘Study Select’ link.");
        }
    }

    handleOnCopyFlowClick = (event) => {
        let that = this;
        let process = that.fetchProcessFlow(that.props.dataItem.processStudyId,that.props.dataItem.processFlowId );
        if(process !== null){
          let flowAlreadyOpen = false;
          that.props.processFlowList.forEach((flow, index) => {
            if(flow.process.processId === process.process.processId && flow.process.studyId === process.process.studyId && flow.process.processGroupId === process.process.processGroupId){
              flowAlreadyOpen = true;
              that.props.setActiveProcessFlow(index);
            }
          });
          if(!flowAlreadyOpen){
            that.props.addProcessFlow(process);
          }
          window.location.hash='#process-flow-list';
          that.props.dataItem.closeEvent();
        }else{
          ConfirmBox.alert("Study for selected jump to block not included. Please select appropriate Study using ‘Study Select’ link.");
        }
    }

    fetchProcessFlow = (studyId, processId) =>{
        var that = this;
        let treeView = that.props.treeView.data;
        let selectedFlow = null;
        treeView.children.forEach((project, index) => {
          project.children.forEach((study) => {
            if (study.study.studyId === studyId) {
              study.children.forEach((studyElement) => {
                if (studyElement.name === 'ProcessFlows') {
                  studyElement.children.forEach((classifier) => {
                    classifier.children.forEach((processGroup) => {
                      processGroup.children.forEach((flow)=>{
                        if(processId === flow.process.processId){
                          selectedFlow = flow;
                          return selectedFlow;
                        }
                      })
                    });
                  })
                }
              })
            }
         });
        })
        return selectedFlow;
      }

    render() {
        return (
            <div className="text-center">
              <Button primary={true} onClick={this.handleOnClick}> Compare Flows</Button>
            <div className="compare-flows">

            {
                this.state.showCompareDialog && <div className="data-properties">
                <Dialog className={this.className} title={"Flows"} width="calc(100% - 10px)" height="calc(100vh - 20px)" modal={true} onClose={this.closeDialog}>
                    <div className="data-properties" >
                        <div className="row m-t-10">
                        {
                            this.props.dataItem.sourceFlowId !== 0 &&

                            <div className={this.state.className}>
                            <fieldset>
                              <div className="field_group">
                                <legend>{`Process Flow Diagram of Source '${this.props.dataItem.sourceFlowName}'`}&nbsp;&nbsp;<Button primary={true} onClick={this.handleOnSourceFlowClick}> Open Flow</Button></legend>
                                <div className="process_flow">
                                  <div className="exView">
                                    <ProcessFlow id={`process-flow-source`} schemaName={''} {...this.state.sourceBlockAndLinkData} readOnly={true} isFlowCheckedOut={false} isFlowCheckedOutByLoggedInUser={false} key={`process-flow-source`} />
                                  </div>
                                </div>
                              </div>
                            </fieldset>
                            </div>
                        }{
                            this.props.dataItem.processFlowId !== 0 &&
                            <div className={this.state.className}>
                              <fieldset>
                                <div className="field_group">
                                  <legend>{`Process Flow Diagram of Target '${this.props.dataItem.processFlowName}'`}&nbsp;&nbsp;<Button primary={true} onClick={this.handleOnCopyFlowClick}> Open Flow</Button></legend>
                                  <div className="process_flow">
                                    <div className="exView">
                                      <ProcessFlow id={`process-flow-target`} schemaName={''} {...this.state.targetBlockAndLinkData} readOnly={true} isFlowCheckedOut={false} isFlowCheckedOutByLoggedInUser={false} key={`process-flow-target`} />
                                    </div>
                                  </div>
                                </div>
                            </fieldset>
                            </div>
                        }
                        </div>
                        </div>
                </Dialog>
                </div>
            }
            </div>
            </div>
        );
    }
}

const mapStateToProps = createSelector(
    state => state.processFlow.tenantId,
    state => state.treeView,
    state => state.processFlow.processFlowList,
    (tenantId, treeView, processFlowList) => ({
        tenantId,
        treeView,
        processFlowList
    })
  );

  const mapActionsToProps = {
    setMessage: setMessage,
    addProcessFlow: addProcessFlow,
    setActiveProcessFlow: setActiveProcessFlow
  }

  Dialog.propTypes = {
      height: PropTypes.oneOfType([
          PropTypes.string
      ]),
      width: PropTypes.oneOfType([
          PropTypes.string
      ])
  }
const CompareFlowButtonClass =  connect(mapStateToProps, mapActionsToProps)(BaseCompareFlowButtonClass);
