import React from "react";
import { PropTypes } from "prop-types";
import Dialog from 'react-dialog';
import DataExplorer from './dataExplorer';

function template() {
  return (
    <div className="data-operation data_viewer_popup">
      <div className="DaveViewer_height" >
        <Dialog title={"Data Viewer"} width="calc(100% - 10px)" height="calc(100vh - 20px)" modal={true} onClose={this.toggleDialog}>
          <DataExplorer tenantID={this.tenant_ID} key={0} selectInputTable={true} autoLoaded={true}/>
        </Dialog>
      </div>
    </div>
  );
};


Dialog.propTypes = {
    height: PropTypes.oneOfType([
        PropTypes.string
    ]),
    width: PropTypes.oneOfType([
        PropTypes.string
    ])
}
export default template;
