import React    from "react";
import axios from 'axios';
import { connect } from 'react-redux';
import { createSelector } from 'reselect';
import $ from 'jquery';
import moment from 'moment';
import template from "./MockTLFSpecs.jsx";
import restClient from './../../../restClient';
import { GET, CREATE } from './../../../restClient/types';
import {setMessage} from './../../../actions/actionNotification';
import MessageTypeConst from "./../../MessageTypeConst"

class MockTLFSpecs extends React.Component {
  sheetWidgetRef;
  signaturePadRef;
  constructor(props) {
    super(props);

    let data = [];
    let blankColumn = [];

    for(var i = 0; i < 25; i++) {
      blankColumn.push({})
    }

    for(var i = 0; i < 25; i++) {
      data.push(blankColumn)
    }

    this.state = {
      node: this.props.node,
      selected: 0,
      displaySheetData: data,
      mockTLFTreeView: {
        name: 'Mock TLF List',
        cssClassName: 'manage-source-tables',
        iconClass: 'fa fa-get-pocket',
        loading: false,
        toggled: true,
        children: [{
          name: 'Tables',
          id: 'mock-tlf-tables',
          cssClassName: 'manage-tables',
          iconClass: 'fa fa-table',
          loading: false,
          toggled: true,
          children: [{
            name: 'Table 1',
            id: 'mock-tlf-tables-table-1',
            cssClassName: 'manage-table',
            iconClass: 'fa fa-columns',
          },
          {
            name: 'Table 2',
            id: 'mock-tlf-tables-table-2',
            cssClassName: 'manage-table',
            iconClass: 'fa fa-columns',
          },
          {
            name: 'Table 3',
            id: 'mock-tlf-tables-table-3',
            cssClassName: 'manage-table',
            iconClass: 'fa fa-columns',
          }
          ],
        },
        {
          name: 'Figure',
          id: 'mock-tlf-figures',
          cssClassName: 'manage-figure',
          iconClass: 'fa fa-heart',
          loading: false,
          toggled: true,
          children: [{
            name: 'Figure 1',
            id: 'mock-tlf-figures-figure-1',
            cssClassName: 'manage-table',
            iconClass: 'fa fa-columns',
          },
          {
            name: 'Figure 2',
            id: 'mock-tlf-figures-figure-2',
            cssClassName: 'manage-table',
            iconClass: 'fa fa-columns',
          },
          {
            name: 'Figure 3',
            id: 'mock-tlf-figures-figure-3',
            cssClassName: 'manage-table',
            iconClass: 'fa fa-columns',
          }],
        },
        {
          name: 'Listing',
          id: 'mock-tlf-listings',
          cssClassName: 'manage-listing',
          iconClass: 'fa fa-th-list',
          loading: false,
          toggled: true,
          children: [{
            name: 'Listing 1',
            id: 'mock-tlf-listings-listing-1',
            cssClassName: 'manage-table',
            iconClass: 'fa fa-columns',
          },
          {
            name: 'Listing 2',
            id: 'mock-tlf-listings-listing-2',
            cssClassName: 'manage-table',
            iconClass: 'fa fa-columns',
          },
          {
            name: 'Listing 3',
            id: 'mock-tlf-listings-listing-3',
            cssClassName: 'manage-table',
            iconClass: 'fa fa-columns',
          }],
        }
        ],
        id:'standard-treeview'
      }
    }
  }

  handleSelect = (e) => {
    this.setState({ selected: e.selected });
  }

  handleClearClick = (e) => {
    this.signaturePadRef.signaturePad.clear()
  }

  render() {
    return template.call(this);
  }
}


const mapStateToProps = createSelector(
  state => state.processFlow.tenantId,
  state => state.treeView.data,
  (tenantId, treeView) => ({
    tenantId,
    treeView
  })
);

const mapActionsToProps = {
  setMessage: setMessage,
}

export default connect(mapStateToProps, mapActionsToProps)(MockTLFSpecs);
