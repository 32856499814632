import 'react-dropdown/style.css';
import React from 'react';
import Dropdown from 'react-dropdown';
import cloneDeep from 'lodash/cloneDeep';

export class DropDownList extends React.Component {
  render() {
    let props = cloneDeep(this.props);
    let options = [];
    let value = props.value;

    if (typeof(this.props.defaultValue) !== 'undefined') {
      value = this.props.defaultValue
    }

    if (typeof(props.data) !== 'undefined' && props.data.length > 0) {
      if (typeof(props.dataItemKey) !== 'undefined' && typeof(props.textField) !== 'textField') {
        props.data.forEach(option => {
          if (option[props.textField] === '') {
            options.push({
              label: ' ',
              value: option[props.dataItemKey],
              className: "blank-option"
            })
          } else {
            options.push({
              label: option[props.textField],
              value: option[props.dataItemKey]
            })
          }

        })

        if (typeof(props.value[props.textField]) !== 'undefined') {
          if (props.value[props.textField] === '') {
            value = {label: ' ', value: props.value[props.dataItemKey], className: "blank-option"};
          } else {
            value = {label: props.value[props.textField], value: props.value[props.dataItemKey]};
          }
        }
      } else {
        options.push(...props.data);
      }
    }

    props = {
      ...props,
      options: options,
      placeholder: '',
      onChange: (event) => this.props.onChange({...event, target: {value: (typeof(this.props.dataItemKey) !== 'undefined' && typeof(this.props.textField) !== 'textField') ? this.props.data.find(option => (option[this.props.textField] === event.label || option[this.props.textField] === event.label.trim()) && option[this.props.dataItemKey] === event.value) : event.value}}),
      className: 'am-widget am-dropdown'+ (typeof(props.className) !== 'undefined' && props.className !== '' ? ' '+ props.className: ''),
      value: value,
      onFocus: (event) => {
        setTimeout(()=>{
          const totalOpenDropDownLength = document.querySelectorAll('.Dropdown-root.is-open .Dropdown-menu').length
          if (totalOpenDropDownLength > 0) {
            document.querySelectorAll('.Dropdown-root.is-open .Dropdown-menu').forEach(dropDownElement => {
              dropDownElement.style.width = (Math.round(dropDownElement.parentElement.getBoundingClientRect().width) - 6)+'px';
              dropDownElement.style.top = (Math.round(dropDownElement.parentElement.getBoundingClientRect().top))+'px';
              dropDownElement.style.left = (Math.round(dropDownElement.parentElement.getBoundingClientRect().left))+'px';
              dropDownElement.style.display = 'inherit';
            })

            if (event === false) {
              window.addEventListener('scroll', (event) => {
                if (document.querySelectorAll('.Dropdown-root.is-open .Dropdown-menu').length > 0 && !event.target.classList.contains('Dropdown-menu')) {
                    document.getElementById('root').click()
                    window.removeEventListener('scroll', () => {}, true);
                }
              }, true)
            }
          }
        }, 1)
      }
    }

    if (typeof(this.props.defaultValue) !== 'undefined') {
      delete props.defaultValue;
    }
    return (
      React.createElement(Dropdown, {...props}, null)
    )
  }
}
