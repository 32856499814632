import React from 'react';
import CopyAssessmentStatus from './CopyAssessmentStatus';

export const CopyObjectStatusCell = ({ tableManager, value, data, column, colIndex, rowIndex }) => {
    if (typeof(tableManager) === 'undefined') {
      return (null)
    } else {
      return (
          <div className='text-center rgt-cell-inner' style={{alignItems: 'center', overflow: 'hidden'}}>
              <CopyObjectStatusCellClass dataItem={data} field={column.field} value={value}/>
          </div>
      )
    }
}

class CopyObjectStatusCellClass extends React.Component {

    getTitle = (dataItem) =>{
        let title = "";
        if(dataItem !== null && typeof(dataItem) !== "undefined"){
            switch(dataItem.status){
                case CopyAssessmentStatus.FLOW_NEWLY_ADDED:
                case CopyAssessmentStatus.BLOCK_NEWLY_ADDED:
                case CopyAssessmentStatus.GROUP_NEWLY_ADDED:
                    title="Newly Added At Source";
                    break;
                case CopyAssessmentStatus.FLOW_DELETED:
                case CopyAssessmentStatus.BLOCK_DELETED:
                case CopyAssessmentStatus.GROUP_DELETED:
                    title="Deleted At Source";
                    break;
                case CopyAssessmentStatus.FLOW_UPDATED:
                case CopyAssessmentStatus.GROUP_UPDATED:
                    title="Out Of Sync";
                    break;
                case CopyAssessmentStatus.FLOW_SAME_AS_SOURCE:
                case CopyAssessmentStatus.GROUP_SAME_AS_SOURCE:
                case CopyAssessmentStatus.BLOCK_SAME_AS_SOURCE:
                    title="In Sync";
                    break;
                case CopyAssessmentStatus.FLOW_NEW_AT_TARGET:
                case CopyAssessmentStatus.GROUP_NEW_AT_TARGET:
                case CopyAssessmentStatus.BLOCK_NEW_AT_TARGET:
                    title="New At Target";
                    break;
                case CopyAssessmentStatus.SOURCE_BLOCK_UPDATED:
                    title="Source block updated";
                    break;
                default:
                    break;
            }
        }
        return title;
    }

    handleChange(e) {
      this.props.dataItem[this.props.field] = e.target.checked;
      this.props.tableManager.rowsApi.setRows(this.props.tableManager.rowsApi.rows)
    }

    render() {
        let title = this.getTitle(this.props.dataItem);
        return (
            <div className="text-center">
              <span>{title}</span>
            </div>
        );
    }
}
