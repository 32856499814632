import React from 'react';
import { MultiSelect } from "react-multi-select-component";
import cloneDeep from 'lodash/cloneDeep';

export class MultiSelectDropDownList extends React.Component {
  render() {
    let props = cloneDeep(this.props);
    let options = [];
    let value = [];
    if  (typeof(props.data) !== 'undefined') {
      props.data.forEach(option => {
        options.push({label: typeof(props.textField) !== 'undefined' ? option[props.textField] : option, value: typeof(props.dataItemKey) !== 'undefined' ? option[props.dataItemKey] : option})
      })
    }

    if (typeof(props.value) !== 'undefined') {
      props.value.map(selectedValue => {
        value.push({label: typeof(props.textField) !== 'undefined' ? selectedValue[props.textField] : selectedValue, value: typeof(props.dataItemKey) !== 'undefined' ? selectedValue[props.dataItemKey] : selectedValue})
      })
    }
    props = {
      ...props,
      className: 'multi-select am-multi-select'+ (typeof(props.className) !== 'undefined' && props.className !== '' ? ' '+ props.className: ''),
      labelledBy: 'Select',
      valueRenderer: (selected, _options) => selected.length ? selected.length+ ' items' : "",
      options: options,
      value: value,
      onChange: (event) => {
        let value = []
        event.map(selectedValue => {
          if (typeof(this.props.dataItemKey) !== 'undefined' && typeof(this.props.data) !== 'undefined') {
            value.push(this.props.data.find(option => option[this.props.dataItemKey] === selectedValue.value))
          } else {
            value.push(selectedValue.value)
          }
        })
        return this.props.onChange({value: value})
      },
      onMenuToggle: (event) => {
        if (event === true) {
          setTimeout(()=>{
            document.querySelectorAll('.dropdown-content').forEach(dropDownElement => {
              dropDownElement.style.width = (Math.round(dropDownElement.parentElement.getBoundingClientRect().width) - 6)+'px';
              dropDownElement.style.display = 'inherit';
            })
          }, 1)
        }

      }
    }

    if (typeof(props.textField) !== 'undefined') {
      delete props.textField;
    }

    if (typeof(props.dataItemKey) !== 'undefined') {
      delete props.dataItemKey;
    }

    if (typeof(props.data) !== 'undefined') {
      delete props.data;
    }

    return (
      React.createElement(MultiSelect, {...props}, null)
    )
  }
}
