import React, { Component } from 'react';
import { connect } from 'react-redux';
import { createSelector } from 'reselect';
import { DropdownItem, DropdownMenu, DropdownToggle, Nav, NavItem, NavLink, UncontrolledDropdown } from 'reactstrap';
import PropTypes from 'prop-types';

import { AppHeaderDropdown, AppNavbarBrand, AppSidebarToggler } from '@coreui/react';
import analysisMateLogo from '../../assets/img/brand/AnalysisMate.png'

import { CognitoUserPool, CognitoUser } from 'amazon-cognito-identity-js';

import { DEFAULT_LOGIN_URL, DEFAULT_LOGOUT_URL, DEFAULT_CLIENT_ID } from './../../config';
import AccessControlConstants from './../../views/AccessControlConstants';

const propTypes = {
  children: PropTypes.node,
};

const defaultProps = {};

class DefaultHeader extends Component {
  constructor(props) {
      super(props);

      this.state = {
        selectStudydisplayStatus: true
      }
      this.handleLogout = this.handleLogout.bind(this)
  }

  handleLogout(){
    localStorage.clear();
    //window.location = '#/login';

    let cognitoUser = this.getCognitoUser(this.props.token_detail);

    if (cognitoUser != null) {
        cognitoUser.signOut();
    }

    window.location.href = DEFAULT_LOGOUT_URL;
  }

  getCognitoUser = (tokenDetail) => {
    if (this.props.cognito_user_pool_id !== null) {
      const poolData = {
        UserPoolId: this.props.cognito_user_pool_id,
        ClientId: DEFAULT_CLIENT_ID
      };
      const userPool = new CognitoUserPool(poolData);
      const userData = {
        Username: tokenDetail.email,
        Pool: userPool
      };
      return new CognitoUser(userData);
    } else {
      return this.props.cognito_user_pool_id
    }
  }

  handleActivityLogClick = () => {
    window.location.hash = 'log-manager';
  }

  handleJobQueueClick = () => {
    window.location.hash = 'job-queue';
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (typeof(nextProps.userPreference) !== 'undefined') {
      this.setState({ selectStudydisplayStatus: nextProps.userPreference.isTreePrepared})
    }
  }

  render() {

    // eslint-disable-next-line
    const { children, ...attributes } = this.props;
    return (
      <React.Fragment>
        <AppSidebarToggler className="d-lg-none" display="md" mobile />
        <div className="header-logo">
          <AppNavbarBrand
            full={{ src: analysisMateLogo, width: 100, height: 50, alt: 'Analysis Mate' }}
            minimized={{ src: analysisMateLogo, width: 30, height: 30, alt: 'Analysis Mate' }}
          />
        </div>

        {/* <AppSidebarToggler className="d-md-down-none" display="lg" /> */}

        <Nav className="d-md-down-none" navbar>
          <NavItem className="study-select-label">
            <NavLink href={`#${this.state.selectStudydisplayStatus ? "study" : ""}`} >Study Select</NavLink>
          </NavItem>
          {this.props.userPreference.userPermission.indexOf(AccessControlConstants.MANAGE_USERS_GROUPS) > -1 &&
            <UncontrolledDropdown className="admin-select-label" nav inNavbar>
                <DropdownToggle nav caret>
                  Admin
                </DropdownToggle>
                <DropdownMenu end>
                  <DropdownItem>
                    <NavLink href={`#user-management`} >User Management</NavLink>
                  </DropdownItem>
                </DropdownMenu>
            </UncontrolledDropdown>
          }
          <NavItem className="automation-center-label">
            <NavLink href={`#automation-center`} >Automation Center</NavLink>
          </NavItem>
        </Nav>

        <Nav className="ml-auto" navbar>
          <NavItem className="d-md-down-none search-bar hidden">
            <input className="am-textbox" placeholder="Search Here"/>
          </NavItem>
          <NavItem className="d-md-down-none hidden">
            <NavLink href="#"><i className="icon-bell"></i>
                {/* <Badge pill color="danger">5</Badge> */}
              </NavLink>
          </NavItem>
          <NavItem className="d-md-down-none">
            <NavLink href="#" onClick={this.handleJobQueueClick}>Job Queue
            </NavLink>
          </NavItem>
          {/* <NavItem className="d-md-down-none">
            <NavLink href="#"><i className="icon-list"></i></NavLink>
          </NavItem> */}
          {/* <NavItem className="d-md-down-none">
            <NavLink href="#"><i className="icon-location-pin"></i></NavLink>
          </NavItem> */}
          <AppHeaderDropdown direction="down">
            <DropdownToggle nav>
              <img src={'assets/img/avatars/user2.png'} className="img-avatar" alt="admin@bootstrapmaster.com" />
              <span className="checkedOutUserName">{ this.props.token_detail['given_name'] }</span>
            </DropdownToggle>
            <DropdownMenu end style={{ right: 'auto' }}>
              {/* <DropdownItem header tag="div" className="text-center"><strong>Account</strong></DropdownItem>
              <DropdownItem><i className="fa fa-bell-o"></i> Updates<Badge color="info">42</Badge></DropdownItem>
              <DropdownItem><i className="fa fa-envelope-o"></i> Messages<Badge color="success">42</Badge></DropdownItem>
              <DropdownItem><i className="fa fa-tasks"></i> Tasks<Badge color="danger">42</Badge></DropdownItem>
              <DropdownItem><i className="fa fa-comments"></i> Comments<Badge color="warning">42</Badge></DropdownItem> */}
              <DropdownItem header tag="div" className="text-center"><strong>Settings</strong></DropdownItem>
              <DropdownItem onClick={this.handleActivityLogClick}><i className="fa fa-history"></i> Activity Log</DropdownItem>

              {/* <DropdownItem><i className="fa fa-wrench"></i> Settings</DropdownItem>
              <DropdownItem><i className="fa fa-usd"></i> Payments<Badge color="secondary">42</Badge></DropdownItem>
              <DropdownItem><i className="fa fa-file"></i> Projects<Badge color="primary">42</Badge></DropdownItem> */}
              {/* <DropdownItem divider /> */}
              {/* <DropdownItem><i className="fa fa-shield"></i> Lock Account</DropdownItem> */}
              <DropdownItem onClick={this.handleLogout}><i className="fa fa-lock"></i> Logout</DropdownItem>
            </DropdownMenu>
          </AppHeaderDropdown>
        </Nav>
        {/* <AppAsideToggler className="d-md-down-none" /> */}
        {/*<AppAsideToggler className="d-lg-none" mobile />*/}
      </React.Fragment>
    );
  }
}

DefaultHeader.propTypes = propTypes;
DefaultHeader.defaultProps = defaultProps;

const mapStateToProps = createSelector(
  state => state.login.token_detail,
  state => state.login.cognito_user_pool_id,
  state => state.userPreference,
  (token_detail, cognito_user_pool_id, userPreference) => ({
    token_detail,
    cognito_user_pool_id,
    userPreference
  })
);

const mapActionsToProps = {
}

export default connect(mapStateToProps, mapActionsToProps)(DefaultHeader);
