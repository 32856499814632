import React from 'react';
import { Input } from './StandardInput';

export const TargetColumnNameCell = ({ tableManager, value, data, column, colIndex, rowIndex }) => {
    if (typeof(tableManager) === 'undefined') {
      return (null)
    } else {
      return (
          <div className='rgt-cell-inner' style={{display: 'flex', alignItems: 'center', overflow: 'hidden'}}>
              <TargetColumnNameCellClass dataItem={data} field={column.field} value={typeof(data[column.field]) !== 'undefined' ? data[column.field]: value} column={column} tableManager={tableManager}/>
          </div>
      )
    }
}

class TargetColumnNameCellClass extends React.Component {

    render() {
      let columnList = [];

      if (typeof(this.props.dataItem[this.props.field]) !== 'undefined' && this.props.dataItem[this.props.field] !== null && typeof(this.props.dataItem['targetColumnDetail'])) {
        const targetColumnDetail = this.props.dataItem['targetColumnDetail'];
        columnList.push({oldTitle: typeof(targetColumnDetail.oldColumnDetail) !== 'undefined' ? (targetColumnDetail.oldColumn + '-'+ targetColumnDetail.oldColumnDetail.type+' ('+targetColumnDetail.oldColumnDetail.length+')') : '', title: typeof(targetColumnDetail.newColumnDetail) !== 'undefined' ? (targetColumnDetail.newColumn + '-'+ targetColumnDetail.newColumnDetail.type+' ('+targetColumnDetail.newColumnDetail.length+')') : this.props.dataItem[this.props.field]})
      }
        return (
            <>
              <ul className="variable-list">
              {columnList.map((column, key) => {
                  return (
                    <li key={key} className="variable-title"><span>{column.title}</span>{column.oldTitle !== '' ? (<span className="old-title">{column.oldTitle}</span>) : (null)}</li>
                  )
              })}
              </ul>
            </>
        );
    }
}
