import { combineReducers } from 'redux';
import studyReducer from './studyReducer';
import projectReducer from './projectReducer';
import treeViewReducer from './treeViewReducer';
import processFlowReducer from './processFlowReducer';
import loginReducer from './loginReducer';
import dataOperationReducer from './dataOperationReducer';
import notificationReducer from './notificationReducer';
import annotateReducer from './annotateReducer';
import userPreferenceReducer from './userPreferenceReducer';

export default combineReducers({
  study: studyReducer,
  project: projectReducer,
  treeView: treeViewReducer,
  processFlow: processFlowReducer,
  login: loginReducer,
  dataOperation: dataOperationReducer,
  notification: notificationReducer,
  annotate: annotateReducer,
  userPreference: userPreferenceReducer
})
