import React from 'react';
import BlockCreationType from '../BlockCreationType';
import { NewColumnNameCell , MultiplePasteCopyAsCell, DestinationBlockNameCell} from '../Resource/DataOperation/common';
import RichGridTable from '../Resource/RichGridTable';
import { connect } from 'react-redux';
import { PropTypes } from 'prop-types';
import { createSelector } from 'reselect';
import MessageTypeConst from "../MessageTypeConst";
import { setMessage } from '../../actions/actionNotification';

class MultipleBlockPasteDialog extends React.Component{
    constructor(props){
        super(props);
        let rowData= [];
        let blockSkip = [];
        let documentBlock = [];
        let message = '';
        let documentMessage = '';
        let annotateMessage = '';
        let timelineMessage = '';
        let annotateBlock = [];
        let timelineBlock = [];
        if(this.props.selectedItems !== undefined && this.props.selectedItems !== null && this.props.selectedItems !== "" && this.props.selectedItems.length >0){
            this.props.selectedItems.sort(this.compare).forEach((item) => {
                let row= {"sourceBlock" : item.blockName, "destinationBlock" : item.blockName, "copyAs" : "",
                "blockType":item.blockType,"blockCreationType":item.blockCreationType,"blockStudyId":item.studyId,
                "destinationStudyId":this.props.currentStudyId,item:item, destinationProcessId :this.props.currentProcessId,
                sourceProcessId:item.processId,blockSyncFlag:item.blockSyncFlag,tableExists:item.tableExists, blockSaveType:item.blockSaveType};

                if(row.destinationProcessId === row.sourceProcessId && row.blockStudyId === row.destinationStudyId &&
                (row.blockCreationType === BlockCreationType.IMPORT_META_TABLE ||
                    row.blockCreationType === BlockCreationType.IMPORT_SOURCE_TABLE ||
                    row.blockCreationType === BlockCreationType.DATA_COPY ||
                    row.blockCreationType === BlockCreationType.DATA_LINK)){
                        blockSkip.push(row);
                }else if(row.tableExists === 0 &&
                    (row.blockCreationType === BlockCreationType.IMPORT_META_TABLE ||
                        row.blockCreationType === BlockCreationType.IMPORT_SOURCE_TABLE ||
                        row.blockCreationType === BlockCreationType.DATA_COPY ||
                        row.blockCreationType === BlockCreationType.DATA_LINK)){
                            blockSkip.push(row);
                }else if(row.blockCreationType === BlockCreationType.IMPORT_SOURCE_DOCUMENT){
                    documentBlock.push(row);
                }else if(row.blockCreationType === BlockCreationType.MAP_ANNOTATE){
                    annotateBlock.push(row);
                }else if(row.blockCreationType === BlockCreationType.TIMELINE){
                    timelineBlock.push(row);
                }
                else{
                    rowData.push(row);
                }
            });
        }
        if(blockSkip.length > 0){
            message = "Block(s) - "
            blockSkip.forEach((block)=>{
                message = message + "'" + block.sourceBlock +"', ";
            });
            message = message.trim();
            message = message.substr(0,message.length -1);
            message = message + " can't be copied. It might be due to block doesn't have any copy definition attached and was pasted in same process flow OR copied block does not have any data."
        }
        if(documentBlock.length > 0){
            documentMessage = "Document block(s) ";
            documentBlock.forEach((block)=>{
                documentMessage = documentMessage + "'" + block.sourceBlock +"', ";
            });
            documentMessage = documentMessage.trim();
            documentMessage = documentMessage.substr(0,documentMessage.length -1);
            documentMessage = documentMessage + " can't be copied.";
        }
        if(annotateBlock.length > 0){
            annotateMessage = "Map-Annotate block(s) ";
            annotateBlock.forEach((block)=>{
                annotateMessage = annotateMessage + "'" + block.sourceBlock +"', ";
            });
            annotateMessage = annotateMessage.trim();
            annotateMessage = annotateMessage.substr(0,annotateMessage.length -1);
            annotateMessage = annotateMessage + " can't be copied.";
        }
        if(timelineBlock.length > 0){
            timelineMessage = "Timeline block(s) ";
            timelineBlock.forEach((block)=>{
                timelineMessage = timelineMessage + "'" + block.sourceBlock +"', ";
            });
            timelineMessage = timelineMessage.trim();
            timelineMessage = timelineMessage.substr(0,timelineMessage.length -1);
            timelineMessage = timelineMessage + " can't be copied.";
        }

        rowData.map(row => {
          return row;
        })
        this.state = {
            currentStudyId: this.props.currentStudyId,
            message:message,
            documentMessage:documentMessage,
            annotateMessage:annotateMessage,
            timelineMessage:timelineMessage,
            tableList:[{
                id: 'multiple-paste-ops-grid',
                rows:rowData,
                resize: true,
                filterable: false,
                columnProperty: [
                  { field: "tableExists", show: false},
                  { field: "blockSyncFlag", show: false },
                  { field: "destinationProcessId", show: false },
                  { field: "sourceProcessId", show: false },
                  { field: "blockType", show: false },
                  { field: "item", show: false },
                  { field: "blockCreationType", show: false },
                  { field: "blockStudyId", show: false },
                  { field: "destinationStudyId", show: false },
                  { field: "sourceBlock", sortable:false, width: "230px",title:"Source Block Name" },
                  { field: "destinationBlock", sortable:false, width: "", cell: DestinationBlockNameCell, title:"Destination Block Name" },
                  { field: "copyAs", title:"Paste As", sortable:false, cell: MultiplePasteCopyAsCell },
                  { field: "blockSaveType", show: false },
                ],
              }]
        };
        this.handleOkClick = this.handleOkClick.bind(this);
        MultipleBlockPasteDialog.getPasteData = this.getPasteData.bind(this);
        this.setMessage = setMessage;
    }

    compare(a,b) {
        if (a.blockLevel < b.blockLevel)
          return -1;
        if (a.blockLevel > b.blockLevel)
          return 1;
        return 0;
    }

    getPasteData(){
        let warning = 0;
        let emptyDestNames = this.state.tableList[0].rows.filter(item => item.destinationBlock === "");
        if(emptyDestNames.length > 0){
            warning = 1;
            this.props.setMessage("Please enter Destination Block Name.", MessageTypeConst.WARNING_MESSAGE);
            return [];
        }
        let emptyCopyAs = this.state.tableList[0].rows.filter(item => item.copyAs === "");
        if(emptyCopyAs.length > 0){
            warning = 1;
            this.props.setMessage("Please select Paste As.", MessageTypeConst.WARNING_MESSAGE);
            return [];
        }
        let persistentBlocks = this.state.tableList[0].rows.filter(item => item.blockSaveType === 1);
        if(persistentBlocks !== null && persistentBlocks.length > 0){
            persistentBlocks.forEach((row) => {
                let blockName = row.destinationBlock;
                let pattern = new RegExp("^([a-z])+[0-9a-z_]*$","ig");
                if(!pattern.test(blockName.trim())){
                    warning = 1;
                }
            });
            if(warning === 1){
                this.props.setMessage("Persistent block name should begin with character and it should contain only character, underscore and numbers.", MessageTypeConst.WARNING_MESSAGE);
                return [];
            }
        }
        if(warning === 0){
            return this.state.tableList[0].rows;
        }
    }

    handleOkClick(event){

    }
    handleCancelClick(event){

    }
    render(){
        return (
            <div>
                <div>
                {
                    this.state.message !== "" && <p>{this.state.message}</p>
                }
                {
                    this.state.documentMessage !== "" && <p>{this.state.documentMessage}</p>
                }
                {
                    this.state.annotateMessage !== "" && <p>{this.state.annotateMessage}</p>
                }
                {
                    this.state.timelineMessage !== "" && <p>{this.state.timelineMessage}</p>
                }
                </div>
                <RichGridTable {...this.state.tableList[0]} />

            </div>
    );
    }
}

const mapStateToProps = createSelector(
    state => state.processFlow.tenantId,
    (tenantId) => ({
      tenantId
    })
  );

  const mapActionsToProps = {
    setMessage: setMessage
  }

  MultipleBlockPasteDialog.propTypes = {
    tenantId: PropTypes.string,
  }

  export default connect(mapStateToProps, mapActionsToProps, null, { withRef: true })(MultipleBlockPasteDialog);
