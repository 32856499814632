import React from 'react';
import { connect } from 'react-redux';
import { PropTypes } from 'prop-types';
import { createSelector } from 'reselect';
import { setMessage } from '../../../../../actions/actionNotification';
import MessageTypeConst from "../../../../MessageTypeConst";

export const BasicCheckBoxCell = ({ tableManager, value, data, column, colIndex, rowIndex, onChange }) => {
    if (typeof(tableManager) === 'undefined') {
      return (null)
    } else {
      return (
          <div className='rgt-cell-inner' style={{textAlign: 'center', overflow: 'hidden'}}>
              <BasicCheckBoxCellClass dataItem={data} field={column.field} value={typeof(data[column.field]) !== 'undefined' ? data[column.field]: value} tableManager={tableManager} onChange={onChange} className={column.className}/>
          </div>
      )
    }
}

export class BasicCheckBoxCustomCellClass extends React.Component {

    handleChange(e) {
      let columnRestricted = false;
      if(typeof(global.ColumnAlwaysNeedsToBeDisplay) !== 'undefined' && global.ColumnAlwaysNeedsToBeDisplay.length > 0)
      global.ColumnAlwaysNeedsToBeDisplay.forEach((columnName) => {
        if(this.props.dataItem['column'] === columnName && !e.target.checked && this.props.field === 'selected'){
          columnRestricted = true;
          e.preventDefault();
          e.stopPropagation();
          this.props.setMessage(`'${columnName}' column must be set as Display column.`, MessageTypeConst.WARNING_MESSAGE);
          return;
        }
      });
      if(!columnRestricted){
        this.props.dataItem[this.props.field] = e.target.checked;
        if (typeof(this.props.dataItem['study']) !== 'undefined' && typeof(this.props.dataItem['projectId']) !== 'undefined') {
          this.props.dataItem.study.map(studyObject => {
            studyObject.selected = this.props.dataItem[this.props.field];
            return studyObject
          })
        }

        if (typeof(this.props.dataItem['studyId']) !== 'undefined' && typeof(this.props.dataItem['projectId']) !== 'undefined' && typeof(this.props.dataItem['studyName']) !== 'undefined' && typeof(this.props.dataItem['selectionChange']) !== 'undefined') {
          this.props.dataItem.selectionChange(this.props.dataItem)
        }
        this.props.tableManager.rowsApi.setRows(this.props.tableManager.rowsApi.rows)

        if (typeof(this.props.onChange) !== 'undefined') {
          this.props.onChange();
        }
        if (typeof(this.props.dataItem.refreshTabList) !== 'undefined') {
            this.props.dataItem.refreshTabList();
        }
      }
    }
    render() {
        let checked = false;
        let id = typeof(this.props.dataItem['id']) ? this.props.dataItem['id'] : 0;
        //Needed to differentiate columns in grid mapper
        var position = typeof(this.props.className) !== 'undefined' ? this.props.className : '';
        if (this.props.dataItem[this.props.field]) {
          checked = true;
        }
        let colId = "columnId";
        if(typeof(this.props.dataItem[colId]) === "undefined"){
          colId = "columnNewId";
        }
        return (
            <>
               <input type="checkbox" className={`am-checkbox checkbox-${this.props.field.replace(' ', '_')}-${colId}-${this.props.dataItem[colId]}-${position}`} id={`checkbox-${this.props.field.replace(' ', '_')}-${colId}-${this.props.dataItem[colId]}-${position}-${id}`} onChange={this.handleChange.bind(this)}
               checked={checked}/>
               <label className="am-checkbox-label" htmlFor={`checkbox-${this.props.field.replace(' ', '_')}-${colId}-${this.props.dataItem[colId]}-${position}-${id}`}></label>
            </>
        );
    }
}
const mapStateToProps = createSelector(
  state => state.processFlow.tenantId,
  (tenantId) => ({
    tenantId,
  })
);

const mapActionsToProps = {
  setMessage: setMessage
}

BasicCheckBoxCustomCellClass.propTypes = {
  tenantId: PropTypes.string
}

export const BasicCheckBoxCellClass = connect(mapStateToProps, mapActionsToProps)(BasicCheckBoxCustomCellClass);
