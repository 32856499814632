import React from 'react';
import cloneDeep from 'lodash/cloneDeep';
import { DropDownList } from './../DropDown/StandardDropDownList';
import { Input } from './StandardInput';

export const SourceColumnCell = ({ tableManager, value, data, column, colIndex, rowIndex }) => {
    if (typeof(tableManager) === 'undefined') {
      return (null)
    } else {
      return (
          <div className='rgt-cell-inner' style={{display: 'grid', alignItems: 'center', overflow: 'hidden'}}>
              <SourceColumnCellClass dataItem={data} field={column.field} value={typeof(data[column.field]) !== 'undefined' ? data[column.field]: value} column={column} tableManager={tableManager}/>
          </div>
      )
    }
}

export class SourceColumnCellClass extends React.Component {

    constructor(props) {
      super(props)

    }

    handleChange(e) {
      let value = e.target.value;
      if (this.props.dataItem['blankCell'].indexOf(this.props.field) === -1 && value === '[Enter Text]') {
        let blankCell = this.props.dataItem['blankCell'];
        blankCell.push(this.props.field)
        this.props.dataItem['blankCell'] = blankCell
        this.props.dataItem['selectedSourceColumnList'] = this.props.dataItem['selectedSourceColumnList'].filter(selectedSourceColumn => selectedSourceColumn.groupTitle !== this.props.field);
        value = '';
      } else if (typeof(this.props.dataItem['selectedSourceColumnList']) !== 'undefined' && value !== '' && value !== '[Enter Text]' && this.props.dataItem['blankCell'].indexOf(this.props.field) === -1){
        this.props.dataItem['selectedSourceColumnList'] = this.props.dataItem['selectedSourceColumnList'].filter(selectedSourceColumn => selectedSourceColumn.groupTitle !== this.props.field);
        this.props.dataItem['selectedSourceColumnList'].push({groupTitle: this.props.field, column: value})
        value = typeof(this.props.dataItem['getSouceVariableExpression']) !== 'undefined' && this.props.dataItem['pivotOutput'] === 'Q_VALUE' ? this.props.dataItem.getSouceVariableExpression(value, this.props.dataItem['targetColumn']) : value
      }

      this.props.dataItem[this.props.field] = value;
      this.props.tableManager.rowsApi.setRows(this.props.tableManager.rowsApi.rows)
    }

    handleExpressionChange(e) {
      if (typeof(this.props.dataItem['selectedSourceColumnList']) !== 'undefined' && this.props.dataItem['selectedSourceColumnList'].filter(selectedSourceColumn => selectedSourceColumn.groupTitle === this.props.field).length === 0 && this.props.dataItem[this.props.field] !== '' && this.props.dataItem[this.props.field] !== '[Enter Text]' && this.props.dataItem['blankCell'].indexOf(this.props.field) === -1){
        this.props.dataItem['selectedSourceColumnList'].push({groupTitle: this.props.field, column: this.props.dataItem[this.props.field]})
      }

      this.props.dataItem[this.props.field] = e.target.value;
      this.props.tableManager.rowsApi.setRows(this.props.tableManager.rowsApi.rows)
    }

    handleUndoColumnClick(e) {
      this.props.dataItem['blankCell'] = this.props.dataItem['blankCell'].filter(blankCell => blankCell !== this.props.field);
      let value = ''
      if (typeof(this.props.dataItem['selectedSourceColumnList']) !== 'undefined' && this.props.dataItem['selectedSourceColumnList'].length > 0){
        this.props.dataItem['selectedSourceColumnList'].filter(selectedSourceColumn => selectedSourceColumn.groupTitle === this.props.field).forEach(selectedSourceColumn => {
          value = selectedSourceColumn.column
        })
      }

      this.props.dataItem[this.props.field] = value;
      this.props.tableManager.rowsApi.setRows(this.props.tableManager.rowsApi.rows)
    }

    render() {
        let columnList = cloneDeep(this.props.dataItem['columnList']);
        /*for (var key in this.props.dataItem) {
          if (key.indexOf("group") > -1 && this.props.dataItem[key] !== '') {
            columnList = columnList.filter(column => column !== this.props.dataItem[key])
          }
        }*/
        const groupValue = this.props.dataItem[this.props.field];
        let selectedSourceColumnValue = groupValue;

        if (typeof(this.props.dataItem['selectedSourceColumnList']) !== 'undefined') {
          this.props.dataItem['selectedSourceColumnList'].filter(selectedSourceColumn => selectedSourceColumn.groupTitle === this.props.field).forEach(selectedSourceColumn => {
            selectedSourceColumnValue = selectedSourceColumn.column
          })
        }
        return (
            <>
              {columnList.length > 0 && this.props.dataItem['blankCell'].indexOf(this.props.field) === -1 &&
                <>
                <DropDownList data={columnList} onChange={this.handleChange.bind(this)} value={selectedSourceColumnValue} className="width-full m-t-5"/>
                <Input className="width-full m-t-5 m-b-5" onChange={this.handleExpressionChange.bind(this)} value={this.props.dataItem[this.props.field]} placeholder="expression" />
                </>
              }
              {columnList.length > 0 && this.props.dataItem['blankCell'].indexOf(this.props.field) > -1 &&
                <div>
                  <Input className="w-85" onChange={this.handleChange.bind(this)} value={this.props.dataItem[this.props.field]} />
                  <span className="undo-wrapper"><i className={'fa fa-undo'} onClick={this.handleUndoColumnClick.bind(this)}/></span>
                </div>
              }
            </>
        );
    }
}
