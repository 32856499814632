import React from 'react';
import cloneDeep from 'lodash/cloneDeep';
import restClient from '../../../../restClient';
import { CREATE, GET } from '../../../../restClient/types';
import axios from 'axios';
import $ from 'jquery';
import { connect } from 'react-redux';
import { PropTypes } from 'prop-types';
import { createSelector } from 'reselect';
import Switch from "react-switch";
import { addProcessFlowBlockLinkByTabIndex, updateProcessflowBlockRenderStatus, updateProcessFlowBlockByTabIndex, updateProcessFlowSyncFlagByTabIndex } from '../../../../actions/actionProcessFlow';
import ExecuteAllTypeConst from './ExecuteAllTypeConst';
import BlockStatus from '../../../ProcessFlow/BlockStatus';
import { Button } from './../common/Button/StandardButton';
import Dialog from 'react-dialog';
import TextEditor from '../../TextEditor';
import RichGridTable from '../../RichGridTable';
import {ExecuteAllLogButton} from '../common/Button/ExecuteAllLogButton';
import { ExecuteAllStatusCell } from './ExecuteAllStatusCell';
import BlockSaveType from '../../../BlockSaveType';
import MessageTypeConst from '../../../MessageTypeConst';
import { setMessage } from '../../../../actions/actionNotification';
import { ExecuteAllPreWhereInput, SampleFilterCondition } from '../common';
import ConfirmBox from './../../DataOperation/common/ConfirmBox';

class ExecuteAll extends React.Component{
    constructor(props){
        super(props);
        this.state = {
            studyId:props.studyId,
            process :props.process,
            projectId : props.projectId,
            schemaName : props.schemaName,
            tenantId : props.tenantId,//here tenantId is coming as master for processLevel operation
            processFlowList:props.processFlowList,
            processFlowTable:props.processFlowTable,
            processFlowTableId:props.processFlowTableId,
            activeProcessFlow:props.activeProcessFlow,
            executeAllType : props.executeAllType,
            treeView:props.treeView,
            processBlock: props.processBlock,
            updateJobs:props.updateJobs,
            jobs:[],
            processIdList:[],
            blockAndLinkData:{processBlocks:[],processBlockLinks:[]},
            showLogPopUp:false,
            logData:'',
            processGroupId:props.processGroupId,
            tableList:[{
                id: 'executeAll-jobs-grid',
                rows: [{jobId:0,inputTable:'',outputTable:'',blockSaveType:'',operation:'',statusIcon:'',log:'',status:false,inputBlockLevel:0}],
                resize: true,
                filterable: false,
                selectedField: "selected",
                message: '',
                columnProperty: [
                  { field: "jobId", sortable:false, title:"Job Id", width:"50px", className:"text-center", orderIndex:1},
                  { field: "processGroup", sortable:false, title:"Process Group", width:"120px", className:"text-center", orderIndex:2},
                  { field: "process", sortable:false, title:"Process Flow", width:"150px", className:"text-center", orderIndex:3},
                  { field: "inputTable", sortable:false, title:"Input", className:"text-center", width:"200px", orderIndex:4, show: false },
                  { field: "displayInputTables", title: "inputTable", sortable:false, title:"Input", className:"text-center", width:"250px", orderIndex:4 },
                  { field: "leftWhereCondition", sortable:false, title:"Pre-Where (Left)", className:"text-center", width:"280px", orderIndex:5, cell:ExecuteAllPreWhereInput},
                  { field: "leftSampleFilter", sortable:false, title:"Sample Filter (Left)", className:"text-center", width:"280px", orderIndex:5, cell:SampleFilterCondition},
                  { field: "rightWhereCondition", sortable:false, title:"Pre-Where (Right)", className:"text-center", width:"280px", orderIndex:6, cell:ExecuteAllPreWhereInput},
                  { field: "rightSampleFilter", sortable:false, title:"Sample Filter (Right)", className:"text-center", width:"280px", orderIndex:6, cell:SampleFilterCondition},
                  { field: "outputTable", sortable:false,title:"Output", width:"180px", className:"text-center", orderIndex:7, show: false  },
                  { field: "displayOutputTable", title: "outputTable", sortable:false,title:"Output", width:"180px", className:"text-center", orderIndex:7  },
                  { field: "blockSaveType", sortable:false, title:"Save Type", className:"text-center", width:"154px", orderIndex:8},
                  { field: "operation", sortable:false, title:"Operation", className:"text-center", width:"200px", orderIndex:9 },
                  { field: "statusIcon", sortable:false, title:"Status",cell:ExecuteAllStatusCell, className:"text-center",width:"50px", orderIndex:10 },
                  { field: "log", sortable:false, title:"Log", cell:ExecuteAllLogButton, className:"text-center", width:"70px", orderIndex:11},
                  { field: "status", sortable:false, title:"Job Status", show:false, className:"text-center",width:"40px", orderIndex:12 },
                  { field: "inputBlockLevel", sortable:false, title:"Input Block Level", show:false, className:"text-center" },
                  { field: "leftEngineCode", show: false },
                  { field: "leftInputSampleFilter", show: false },
                  { field: "rightEngineCode", show: false },
                  { field: "rightInputSampleFilter", show: false },
                  { field: "isSampleFiltered", show: false }
                ]
              }],
              flowcheckedInByApi: [],
              isSampleFilter: false,
              isKeepAllData: false,
              isKeepLinkData: true
        }
        this.prepareJobs = this.prepareJobs.bind(this);
        this.prepareProcessFlowExcuteAllJobs = this.prepareProcessFlowExcuteAllJobs.bind(this);
        this.prepareProcessFlowExcuteAllWarningJobs = this.prepareProcessFlowExcuteAllWarningJobs.bind(this);
        this.prepareProcessBlockExcuteAllJobs = this.prepareProcessBlockExcuteAllJobs.bind(this);
        this.getBlockAndLinkFromProcessFlow = this.getBlockAndLinkFromProcessFlow.bind(this);
        this.startJobPreparation = this.startJobPreparation.bind(this);
        this.handleChangeEvent = this.handleChangeEvent.bind(this);
        ExecuteAll.getJobs = this.getJobs.bind(this);
        ExecuteAll.updateJobStatus = this.updateJobStatus.bind(this);
        this.toggleLogPopUp = this.toggleLogPopUp.bind(this);
        this.showLog = this.showLog.bind(this);
        this.getProcessBlockInActiveFlow = this.getProcessBlockInActiveFlow.bind(this);
        this.recursivePrepareJobs = this.recursivePrepareJobs.bind(this);
        this.sortJobsBasedOnBlockLevel = this.sortJobsBasedOnBlockLevel.bind(this);
        this.processNonRootBlocks = this.processNonRootBlocks.bind(this);
        this.recursiveLogicToPrepareWarningStateJob = this.recursiveLogicToPrepareWarningStateJob.bind(this);
        ExecuteAll.getFlowcheckedInByApi = this.getFlowcheckedInByApi.bind(this);
        ExecuteAll.getStateDetail = this.getStateDetail.bind(this);
    }

    toggleLogPopUp(){
        this.setState({showLogPopUp:!this.state.showLogPopUp});
    }

    showLog(event){
    let index = parseInt(event.target.value, 10);

    let logData = '';
    if(this.state.jobs[index] !== null && typeof(this.state.jobs[index]) !== "undefined"){
        logData = this.state.jobs[index].log;
    }

    this.setState({logData:logData});
    this.toggleLogPopUp();

    }

    getJobs(){
        let jobs = this.state.jobs;
        let tableList = this.state.tableList;
        jobs.map((job)=>{
            let selectedJob = tableList[0].rows.filter(j=>j.outputTable === job.outputTable);
            if(selectedJob.length > 0){
              job.leftSampleFilterApplied = selectedJob[0].leftSampleFilter
              job.rightSampleFilterApplied = selectedJob[0].rightSampleFilter
              job.isSampleFiltered = selectedJob[0].isSampleFiltered;
              if (job.leftSampleFilterApplied === '' && job.rightSampleFilterApplied === '') {
                job.isSampleFiltered = false
              }

              job.rightInputWhereCondition = selectedJob[0].rightWhereCondition;
              job.leftInputWhereCondition = selectedJob[0].leftWhereCondition;
            }
            return job;
        });
        return jobs;
    }

    getFlowcheckedInByApi() {
      return this.state.flowcheckedInByApi
    }

    updateJobStatus(updatedJobs){
        let jobs = this.state.jobs;
        jobs.map((job)=>{
            let selectedJob = updatedJobs.filter(j=>j.outputTable === job.outputTable);
            if(selectedJob.length > 0){
                job.status = selectedJob[0].status;
                job.log = selectedJob[0].log;
                job.statusIcon = selectedJob[0].status ? 'fa fa-circle completed': 'fa fa-circle fail';
            }
            return job;
        });
        let tableList = this.state.tableList;
        tableList[0].rows.map((row)=>{
            let selectedJob = updatedJobs.filter(j=>j.outputTable === row.outputTable);
            if(selectedJob.length > 0){
                row.status = selectedJob[0].status;
                row.log = selectedJob[0].log;
                row.statusIcon = selectedJob[0].status ? 'fa fa-circle completed': 'fa fa-circle fail';
            }
            return row;
        });
        this.setState({jobs:jobs, tableList: tableList},()=>{
            let totalJobsCount = jobs.length;
            let successfulJobs = jobs.filter(job=>job.status === true);
            let successfuleJobsCount = successfulJobs.length;

            ConfirmBox.alert(`Execution finished. ${successfuleJobsCount} out of ${totalJobsCount} jobs succeeded.`)
        });
    }

    componentDidMount(){
        //this.startJobPreparation();
        this.prepareJobs();
    }

    startJobPreparation(){
        switch(this.state.executeAllType){
            case ExecuteAllTypeConst.ProcessFlowLevelExecuteAll :
                this.getBlockAndLinkFromProcessFlow();
            break;
            case ExecuteAllTypeConst.ProcessFlowLevelExecuteAllWarning :
                this.getBlockAndLinkFromProcessFlow();
            break;
            case ExecuteAllTypeConst.ProcessBlockLevelExecuteAll :
                    this.getProcessBlockInActiveFlow();
            break;
            default:
            break;
        }
    }

    prepareJobs(){
        $(".loader").show();
        let that = this;
        let tableList = this.state.tableList;
        tableList[0].rows.pop();
        let params = {
            "studyId":this.state.studyId,
            "processId":this.state.process !== null && typeof(this.state.process) !== "undefined" ? this.state.process.processId : 0,
            "processGroupId":this.state.processGroupId !== null && typeof(this.state.processGroupId) !== "undefined" ? this.state.processGroupId : 0,
            "executeAllType":this.state.executeAllType,
            "blockId":this.state.processBlock !== null && typeof(this.state.processBlock) !== "undefined" ? this.state.processBlock.blockId : 0,
            }
        axios.all([restClient(CREATE, 'executeAll/prepareJobs', {data : params}, {'Tenant-ID': that.state.schemaName})])
                .then(axios.spread(function (response)  {
                    if(response.data.messageType === MessageTypeConst.SUCCESS_MESSAGE){
                        if(response.data.jobs !== null && typeof(response.data.jobs) !== "undefined" && response.data.jobs.length >0){
                            var jobId = 0;
                            response.data.jobs.forEach((job)=>{
                                let displayInputTables = cloneDeep(job.inputTable);
                                displayInputTables.forEach((displayInputTable, index) => {
                                  if (typeof(job.inputTableName[index]) !== 'undefined') {
                                    displayInputTables[index] = job.inputTableName[index] + " ("+displayInputTable+")"
                                  }
                                })
                                jobId = jobId + 1;
                                tableList[0].rows.push({jobId:jobId, processGroup:job.processGroupName, process:job.processName,
                                operation:job.operation, displayInputTables: displayInputTables.join(', '), inputTable: job.inputTable.join(","),
                                rightWhereCondition:job.rightInputWhereCondition,
                                leftWhereCondition:job.leftInputWhereCondition,
                                leftSampleFilter: '',
                                rightSampleFilter: '',
                                outputTable:job.outputTable,
                                displayOutputTable:job.outputTableName +"("+job.outputTable+")",
                                blockSaveType: (job.outputBlockSaveType === 1 ? 'Persistent' : 'Temp'),
                                statusIcon:job.outputBlockSyncFlag === BlockStatus.VALID ? 'fa fa-check-circle green status' :
                                ( job.outputBlockSyncFlag === BlockStatus.INVALID ? 'fa fa-exclamation-circle red status' :
                                'fa fa-exclamation-triangle yellow status'),
                                 log:job.log, status:'', inputBlockLevel:job.inputBlockLevel,
                                 leftEngineCode: job.leftEngineCode,
                                 leftInputSampleFilter: job.leftInputSampleFilter,
                                 rightEngineCode: job.rightEngineCode,
                                 rightInputSampleFilter: job.rightInputSampleFilter,
                                 isSampleFiltered: job.isSampleFiltered
                                });
                            })
                            let message = '';

                            that.setState({jobs:response.data.jobs, tableList: that.applySampleFilterInJob(tableList), message:message, processIdList:response.data.flowcheckedInByApi, flowcheckedInByApi: response.data.flowcheckedInByApi, isKeepLinkData: (params.processId > 0 || params.blockId > 0 ? true: that.state.isKeepLinkData)});
                            that.state.updateJobs(response.data.jobs, response.data.flowcheckedInByApi);
                            $(".loader").hide();
                        }else{
                            let message = 'No jobs to execute';
                            that.setState({jobs:[], tableList: tableList, message:message, flowcheckedInByApi: response.data.flowcheckedInByApi});
                            that.state.updateJobs([], []);
                            $(".loader").hide();
                        }
                    }else{
                        let message = 'No jobs to execute';
                        that.setState({jobs:[], tableList: tableList, message:message, flowcheckedInByApi: response.data.flowcheckedInByApi});
                        that.state.updateJobs([], []);
                        $(".loader").hide();
                        ConfirmBox.alert(response.data.message);
                        that.props.closeEvent();
                        //that.props.setMessage(response.data.message,response.data.messageType);
                    }
                })).catch(error => {
                    $(".loader").hide();
                    that.props.setMessage("Error occurred while preparing jobs.",MessageTypeConst.ERROR_MESSAGE);
                  });


    }

    // prepareJobs(){
    //     $(".loader").show();
    //     let jobs = [];
    //     switch(this.state.executeAllType){
    //         case ExecuteAllTypeConst.ProcessFlowLevelExecuteAll :
    //             jobs = this.prepareProcessFlowExcuteAllJobs();
    //             //jobs = this.sortJobsBasedOnBlockLevel(jobs);
    //         break;
    //         case ExecuteAllTypeConst.ProcessFlowLevelExecuteAllWarning :
    //             jobs = this.prepareProcessFlowExcuteAllWarningJobs();
    //             //jobs = this.sortJobsBasedOnBlockLevel(jobs);
    //         break;
    //         case ExecuteAllTypeConst.ProcessBlockLevelExecuteAll :
    //             jobs = this.prepareProcessBlockExcuteAllJobs();
    //             jobs = this.sortJobsBasedOnBlockLevel(jobs);
    //         break;
    //         default:
    //         break;
    //     }
    //     let tableList = this.state.tableList;
    //     tableList[0].rows.pop();
    //     var jobId = 0;
    //     jobs.forEach((job)=>{
    //         jobId = jobId + 1;
    //         tableList[0].rows.push({jobId:jobId, operation:job.operation, inputTable: job.inputTable.join(","),
    //         outputTable:job.outputTable, blockSaveType: (job.blockSaveType === 1 ? 'Persistent' : 'Temp'),
    //         statusIcon:job.statusIcon, log:job.log, status:'', inputBlockLevel:job.inputBlockLevel
    //     });

    //     })
    //     let message = '';
    //     if(jobs.length === 0){
    //         message = "No jobs to execute";
    //     }
    //     this.setState({jobs:jobs, tableList: tableList, message:message});
    //     this.state.updateJobs(jobs);
    //     $(".loader").hide();

    // }

    compare(a,b) {
        if (a.inputBlockLevel < b.inputBlockLevel)
          return -1;
        if (a.inputBlockLevel > b.inputBlockLevel)
          return 1;
        return 0;
      }


    sortJobsBasedOnBlockLevel(jobs){

        return jobs.sort(this.compare);
    }

    prepareProcessFlowExcuteAllJobs(){
        let jobs = [];
        let data = this.state.blockAndLinkData;
        let processBlocks = data.processBlocks;
        let processBlockLinks = data.processBlockLinks;
        let rootBlocks =  data.processBlocks.filter((block) => block.blockLevel === 0);
        let nonRootBlocks =  data.processBlocks.filter((block) => block.blockLevel !== 0).sort(function (a,b) {
            if (a.blockLevel < b.blockLevel)
              return -1;
            if (a.blockLevel > b.blockLevel)
              return 1;
            return 0;
          });
        if(rootBlocks.length > 0 && nonRootBlocks.length > 0){

            let addedBlocks = data.processBlocks.filter((block) => block.blockSyncFlag === BlockStatus.VALID && block.blockLevel === 0);
            let notAddedBlocks = [];
            //for root blocks directly create jobs
            rootBlocks.forEach((rootBlock)=>{
                let filterLinks = processBlockLinks.filter(item => item.blockId1 === rootBlock.blockId);
                if(filterLinks.length> 0){
                    filterLinks.forEach((filterLink) => {
                        var inputBlocks = [];
                        var outputBlocks = [];
                        let outputBlockLinks = processBlockLinks.filter(item => item.blockId2 === filterLink.blockId2);
                        outputBlockLinks.forEach((outputLink)=>{
                            inputBlocks.push(...processBlocks.filter(block => block.blockId === outputLink.blockId1));
                            outputBlocks.push(...processBlocks.filter(block => block.blockId === outputLink.blockId2));
                        });

                        //check whether all input exists in job list before creating jobs
                        let allInputExists = true;
                        inputBlocks.forEach((ipBlock)=>{
                            let blockExists = addedBlocks.filter( addedBlock => addedBlock.blockId === ipBlock.blockId);
                            if(blockExists.length === 0 ){
                                allInputExists = false;
                            }
                        });
                        if(allInputExists){
                            if(outputBlocks.length > 0){
                                let operation  = outputBlockLinks[0].dataop;
                                addedBlocks.push(...inputBlocks);
                                addedBlocks.push(...outputBlocks);
                                let job = this.createJobObject(inputBlocks, outputBlocks, operation);
                                let existingJob = jobs.filter(j => j.outputBlockId === job.outputBlockId);
                                if(existingJob.length === 0){
                                    jobs.push(job);
                                }
                            }
                        }else{
                            notAddedBlocks.push(rootBlock);
                        }
                    });
                }
            })
            //for non-root, check whether input already exists in job or not
            notAddedBlocks = this.processNonRootBlocks(nonRootBlocks,addedBlocks, notAddedBlocks, processBlockLinks,processBlocks, jobs );
            //for non-root, check whether input already exists in job or not
            let listOfNonAddedBlocks = [...notAddedBlocks];
            this.processRemainingBlocks(listOfNonAddedBlocks,addedBlocks, notAddedBlocks, processBlockLinks,processBlocks, jobs );
        }
        return jobs;
    }

    processNonRootBlocks(blocksToProcessed, addedBlocks, notAddedBlocks, processBlockLinks,processBlocks, jobs ){
        blocksToProcessed.forEach((nonRootBlock)=>{
            let doesRootBlockExistsInJob = addedBlocks.filter(addedBlock => addedBlock.blockId === nonRootBlock.blockId );
            if(doesRootBlockExistsInJob.length > 0){
                let filterLinks = processBlockLinks.filter(item => item.blockId1 === nonRootBlock.blockId);
                if(filterLinks.length> 0){
                    if(filterLinks.length> 0){
                    filterLinks.forEach((filterLink) => {
                        var inputBlocks = [];
                        var outputBlocks = [];
                        //check whether all input exists in job list before creating jobs
                        let allInputExists = true;
                        let outputBlockLinks = processBlockLinks.filter(item => item.blockId2 === filterLink.blockId2);
                        outputBlockLinks.forEach((outputLink)=>{
                            inputBlocks.push(...processBlocks.filter(block => block.blockId === outputLink.blockId1));
                            outputBlocks.push(...processBlocks.filter(block => block.blockId === outputLink.blockId2));
                            inputBlocks.forEach((ipBlock)=>{
                                let blockExists = addedBlocks.filter( addedBlock => addedBlock.blockId === ipBlock.blockId);
                                if(blockExists.length === 0 ){
                                    allInputExists = false;
                                }
                            });

                        });
                        if(allInputExists){
                            if(outputBlocks.length > 0){
                                notAddedBlocks = notAddedBlocks.filter(block => block.blockId !== outputBlocks[0].blockId);
                                let operation  = filterLinks[0].dataop;
                                addedBlocks.push(...inputBlocks);
                                addedBlocks.push(...outputBlocks);
                                let job = this.createJobObject(inputBlocks, outputBlocks, operation);
                                let existingJob = jobs.filter(j => j.outputBlockId === job.outputBlockId);
                                if(existingJob.length === 0){
                                    jobs.push(job);
                                }
                            }

                        }else{
                            notAddedBlocks.push(nonRootBlock);
                        }
                    });
                }
                }

            } else{
                notAddedBlocks.push(nonRootBlock);
            }
        })
        return notAddedBlocks;
    }

    processRemainingBlocks(blocksToProcessed, addedBlocks, notAddedBlocks, processBlockLinks,processBlocks, jobs ){
        blocksToProcessed.forEach((nonRootBlock)=>{
            let filterLinks = processBlockLinks.filter(item => item.blockId2 === nonRootBlock.blockId);
                if(filterLinks.length> 0){
                    if(filterLinks.length> 0){
                    filterLinks.forEach((filterLink) => {
                        var inputBlocks = [];
                        var outputBlocks = [];
                        //check whether all input exists in job list before creating jobs
                        let allInputExists = true;
                        let outputBlockLinks = processBlockLinks.filter(item => item.blockId2 === filterLink.blockId2);
                        outputBlockLinks.forEach((outputLink)=>{
                            inputBlocks.push(...processBlocks.filter(block => block.blockId === outputLink.blockId1));
                            outputBlocks.push(...processBlocks.filter(block => block.blockId === outputLink.blockId2));
                            inputBlocks.forEach((ipBlock)=>{
                                let blockExists = addedBlocks.filter( addedBlock => addedBlock.blockId === ipBlock.blockId);
                                if(blockExists.length === 0 ){
                                    allInputExists = false;
                                }
                            });

                        });
                        if(allInputExists){
                            if(outputBlocks.length > 0){
                                notAddedBlocks = notAddedBlocks.filter(block => block.blockId !== outputBlocks[0].blockId);
                                let operation  = filterLinks[0].dataop;
                                addedBlocks.push(...inputBlocks);
                                addedBlocks.push(...outputBlocks);
                                let job = this.createJobObject(inputBlocks, outputBlocks, operation);
                                let existingJob = jobs.filter(j => j.outputBlockId === job.outputBlockId);
                                if(existingJob.length === 0){
                                    jobs.push(job);
                                }
                            }

                        }else{
                            notAddedBlocks.push(nonRootBlock);
                        }
                    });
                }
                }
        })
        return notAddedBlocks;
    }

    createJobObject(inputBlocks, outputBlocks, operation){
        let inputTable = [];
        let inputBlockId = [];
        let outputTable = '';
        let outputBlockId = '';
        let blockLevel = 0;
        let inputLevels = [];
        inputBlocks.forEach((block)=>{
            inputTable.push(block.blockTable);
            inputBlockId.push(block.blockId);
            inputLevels.push(block.blockLevel);
        });
        blockLevel = Math.min(...inputLevels);
        if(outputBlocks.length > 0){
            outputTable = outputBlocks[0].blockTable;
            outputBlockId = outputBlocks[0].blockId;
        }
        var jobObj = {
            inputTable: inputTable,
            outputTable:outputTable,
            operation:operation,
            inputBlockId:inputBlockId,
            outputBlockId:outputBlockId,
            selected:true,
            statusIcon: outputBlocks[0].blockSyncFlag === BlockStatus.VALID ?
            'fa fa-check-circle green status' : ( outputBlocks[0].blockSyncFlag === BlockStatus.INVALID ? 'fa fa-exclamation-circle red status' :'fa fa-exclamation-triangle yellow status'),
            log:'',
            status : false,
            inputBlockLevel : blockLevel,
            blockSaveType : outputBlocks[0].blockSaveType
        };
        return jobObj;
    }

    createJobObjectBlock(inputBlocks, outputBlocks, operation){
        let inputTable = [];
        let inputBlockId = [];
        let outputTable = '';
        let outputBlockId = '';
        let blockLevel = 0;
        let inputLevels = [];
        inputBlocks.forEach((block)=>{
            inputTable.push(block.blockTable);
            inputBlockId.push(block.blockId);
            inputLevels.push(block.blockLevel);
        });
        blockLevel = Math.max(...inputLevels);
        if(outputBlocks.length > 0){
            outputTable = outputBlocks[0].blockTable;
            outputBlockId = outputBlocks[0].blockId;
        }
        var jobObj = {
            inputTable: inputTable,
            outputTable:outputTable,
            operation:operation,
            inputBlockId:inputBlockId,
            outputBlockId:outputBlockId,
            selected:true,
            statusIcon: outputBlocks[0].blockSyncFlag === BlockStatus.VALID ?
            'fa fa-check-circle green status' : ( outputBlocks[0].blockSyncFlag === BlockStatus.INVALID ? 'fa fa-exclamation-circle red status' :'fa fa-exclamation-triangle yellow status'),
            log:'',
            status : false,
            inputBlockLevel : blockLevel,
            blockSaveType : outputBlocks[0].blockSaveType
        };
        return jobObj;
    }

    getProcessBlockInActiveFlow(){
        if( this.state.processFlowList === null || typeof( this.state.processFlowList) === "undefined"){
            return;
        }
        let processFlow = this.state.processFlowList[this.state.activeProcessFlow];
        if(processFlow !== null && typeof(processFlow) !== "undefined"){
            this.setState({process:processFlow.process});
            var data = {processBlocks:[],processBlockLinks:[]};
            data.processBlocks = processFlow.process.processBlock;
            data.processBlockLinks = processFlow.process.processBlockLink;
            this.setState({blockAndLinkData:data},()=>{
                this.prepareJobs();
            });
        }
    }

    getBlockAndLinkFromProcessFlow(){
        var data = {processBlocks:[],processBlockLinks:[]};
        let treeView = this.state.treeView;
        var that = this;
        treeView.data.children.forEach((project)=>{
            if(project.projectId === that.state.projectId){
                project.children.forEach((study)=>{
                    if(study.study.studyId === that.state.process.studyId && study.study.schemaName === that.state.schemaName){
                        study.children.forEach((studyElement) => {
                            if (studyElement.name === 'ProcessFlows') {
                              studyElement.children.forEach((classfier)=>{
                                classfier.children.forEach((group)=>{
                                    group.children.forEach((flow)=>{
                                    if(flow.process.processId === that.state.process.processId){
                                        if(flow.process.processBlock === null || typeof(flow.process.processBlock) === "undefined" ||
                                        flow.process.processBlockLink === null || typeof(flow.process.processBlockLink) === "undefined"){
                                        //make api call to populate blocks and links

                                        $(".loader").show();
                                        axios.all([
                                            restClient(GET, 'processblock/blockAndLinkList?studyId='+that.state.process.studyId+'&processId='+that.state.process.processId, {}, {'Tenant-ID': that.state.schemaName})
                                            ])
                                            .then(axios.spread((response) => {
                                                if (typeof(response.data) !== 'undefined') {
                                                    if (typeof(response.data) !== 'undefined' && typeof(response.data.messageType) !== 'undefined' && response.data.messageType !== null && response.data.messageType ===  MessageTypeConst.WARNING_MESSAGE) {
                                                        that.props.setMessage(response.data.message, response.data.messageType);
                                                        $(".loader").hide();
                                                        return;
                                                    }
                                                flow.process.processBlock = []
                                                if (response.data.processBlock !== null && response.data.processBlock !== '' && response.data.processBlock.length > 0) {

                                                    flow.process.processBlock.push(...response.data.processBlock);
                                                    data.processBlocks = flow.process.processBlock;
                                                }

                                                flow.process.processBlockLink = []
                                                if (response.data.processBlockLink !== null && response.data.processBlockLink !== '' && response.data.processBlockLink.length > 0) {
                                                    flow.process.processBlockLink.push(...response.data.processBlockLink);
                                                    data.processBlockLinks = flow.process.processBlockLink;
                                                }
                                                }
                                                that.setState({blockAndLinkData:data},()=>{
                                                that.prepareJobs();
                                                $(".loader").hide();
                                                });
                                            }))
                                            .catch(error => {
                                                $(".loader").hide();
                                                that.props.setMessage("Failed to fetch blocks and links!",MessageTypeConst.ERROR_MESSAGE);
                                            });
                                        }
                                        else{
                                            data.processBlocks = flow.process.processBlock;
                                            data.processBlockLinks = flow.process.processBlockLink;
                                            that.setState({blockAndLinkData:data},()=>{
                                                that.prepareJobs();
                                            });

                                        }
                                    }
                                  });
                                });
                              })

                    }
                })
                    }
                });
            }
        });
        return data;
    }

    prepareProcessFlowExcuteAllWarningJobs(){
        let jobs = [];
        let data = this.state.blockAndLinkData;
        let processBlocks = data.processBlocks;
        let processBlockLinks = data.processBlockLinks;
        let warningBlocks =  data.processBlocks.filter((block) => block.blockSyncFlag === BlockStatus.WARNING).sort(function (a,b) {
            if (a.blockLevel < b.blockLevel)
              return -1;
            if (a.blockLevel > b.blockLevel)
              return 1;
            return 0;
          });
        let addedBlocks = data.processBlocks.filter((block) => block.blockSyncFlag === BlockStatus.VALID);
        let notAddedBlocks = [];
        warningBlocks.forEach((warningBlock)=>{
            this.recursiveLogicToPrepareWarningStateJob(processBlockLinks,processBlocks,jobs,warningBlock, addedBlocks, notAddedBlocks);

        });
        let blocks = [...notAddedBlocks];
        blocks.forEach((warningBlock)=>{
            this.recursiveLogicToPrepareWarningStateJob(processBlockLinks,processBlocks,jobs,warningBlock, addedBlocks, notAddedBlocks);

        });
        return jobs;
    }

    recursiveLogicToPrepareWarningStateJob(processBlockLinks,processBlocks,jobs,inputBlock,addedBlocks, notAddedBlocks ){
        let blockLinks = processBlockLinks.filter((link) => link.blockId2 === inputBlock.blockId);
        if(blockLinks.length > 0){
            blockLinks.forEach((inputLink) => {
                let inputBlock = processBlocks.filter(block=>block.blockId === inputLink.blockId1);
                if(inputBlock.length > 0){
                    if(inputBlock[0].blockSaveType === BlockSaveType.TEMP){
                        this.recursiveLogicToPrepareWarningStateJob(processBlockLinks,processBlocks,jobs,inputBlock[0],addedBlocks, notAddedBlocks );
                    }
                }
            });
            var inputBlocks = [];
            var outputBlocks = [];
            //check whether all input exists in job list before creating jobs
            let allInputExists = true;
            let allInputBlockPersistent = true;
            let outputInputBlockIsInValidState = true;
            blockLinks.forEach((filterLink) => {
                inputBlocks.push(...processBlocks.filter(block => block.blockId === filterLink.blockId1));
                outputBlocks.push(...processBlocks.filter(block => block.blockId === filterLink.blockId2));
                inputBlocks.forEach((ipBlock)=>{
                    let blockExists = addedBlocks.filter( addedBlock => addedBlock.blockId === ipBlock.blockId);
                    if(blockExists.length === 0 ){
                        allInputExists = false;
                    }
                });
            });
            let persistentBlocks = inputBlocks.filter(block => block.blockSaveType === BlockSaveType.PERSISTENT && block.blockSyncFlag === BlockStatus.VALID);
            if(persistentBlocks.length !== inputBlocks.length){
                allInputBlockPersistent = false;
            }
            let validInputBlocks = inputBlocks.filter(block => block.blockSyncFlag === BlockStatus.VALID);
            let validOutputBlocks = inputBlocks.filter(block => block.blockSyncFlag === BlockStatus.VALID);
            if((validInputBlocks.length + validOutputBlocks.length) !== (inputBlocks.length + outputBlocks.length)){
                outputInputBlockIsInValidState = false;
            }
            if(outputInputBlockIsInValidState){
                if(outputBlocks.length > 0){
                    notAddedBlocks = notAddedBlocks.filter(block => block.blockId !== outputBlocks[0].blockId);
                    let operation  = blockLinks[0].dataop;
                    let job = this.createJobObject(inputBlocks, outputBlocks, operation);
                    let existingJob = jobs.filter(j => j.outputBlockId === job.outputBlockId);
                    if(existingJob.length === 0){
                        jobs.push(job);
                    }
                    addedBlocks.push(...inputBlocks);
                    addedBlocks.push(...outputBlocks);
                }
            }
            else if(allInputExists){
                if(outputBlocks.length > 0){
                    notAddedBlocks = notAddedBlocks.filter(block => block.blockId !== outputBlocks[0].blockId);
                    let operation  = blockLinks[0].dataop;
                    let job = this.createJobObject(inputBlocks, outputBlocks, operation);
                    let existingJob = jobs.filter(j => j.outputBlockId === job.outputBlockId);
                    if(existingJob.length === 0){
                        jobs.push(job);
                    }
                    addedBlocks.push(...inputBlocks);
                    addedBlocks.push(...outputBlocks);
                }
            } else if (allInputBlockPersistent){
                if(outputBlocks.length > 0){
                    notAddedBlocks = notAddedBlocks.filter(block => block.blockId !== outputBlocks[0].blockId);
                    let operation  = blockLinks[0].dataop;
                    let job = this.createJobObject(inputBlocks, outputBlocks, operation);
                    let existingJob = jobs.filter(j => j.outputBlockId === job.outputBlockId);
                    if(existingJob.length === 0){
                        jobs.push(job);
                    }
                    addedBlocks.push(...inputBlocks);
                    addedBlocks.push(...outputBlocks);
                }
            }else{
                notAddedBlocks.push(inputBlock);
            }

        }

    }

    prepareProcessBlockExcuteAllJobs(){
        var jobs = [];
        let data = this.state.blockAndLinkData;
        let processBlockLinks = data.processBlockLinks;
        let blockLink = processBlockLinks.filter((link) => link.blockId2 === this.state.processBlock.blockId);
        if(blockLink.length === 0){
            return jobs;
        }else{
            blockLink.forEach((link)=>{
                this.recursivePrepareJobs(link);
            })
        }
        return this.state.jobs.reverse();
    }

    recursivePrepareJobs(selectedLink){
        if(selectedLink !== null){
            let jobs = this.state.jobs;
            let data = this.state.blockAndLinkData;
            let processBlocks = data.processBlocks;
            let processBlockLinks = data.processBlockLinks;
            let filterLinks = processBlockLinks.filter(item => item.blockId2 === selectedLink.blockId2);
            if(filterLinks.length> 0){
                var inputBlocks = [];
                var outputBlocks = [];
                filterLinks.forEach((filterLink) => {
                    inputBlocks.push(...processBlocks.filter(block => block.blockId === filterLink.blockId1));
                    outputBlocks.push(...processBlocks.filter(block => block.blockId === filterLink.blockId2));
                });
                if(outputBlocks.length > 0){
                    let operation  = filterLinks[0].dataop;
                    let job = this.createJobObjectBlock(inputBlocks, outputBlocks, operation);
                    let existingJob = jobs.filter(j => j.outputBlockId === job.outputBlockId);
                    if(existingJob.length === 0){
                        jobs.push(job);
                        this.setState({jobs:jobs})
                    }
                }
            }
            let blockLink = processBlockLinks.filter((link) => link.blockId2 === selectedLink.blockId1);
            if(blockLink.length > 0){
                blockLink.forEach((link)=>{
                    this.recursivePrepareJobs(link);
                })
            }
        }
    }

    handleChangeEvent(event){
        let outputTable = event.target.id;
        let jobs = this.state.jobs;
        jobs.map((item,index)=>{
            if(item.outputTable === outputTable){
                item.selected = event.target.checked;
            }
            return item;
        });
        this.setState({jobs:jobs});
    }

    handleSampleFilterStatusChange = (event) => {
      this.setState({isSampleFilter: event}, () => {
        let tableList = this.state.tableList;
        tableList = this.applySampleFilterInJob(this.state.tableList);
        this.setState({tableList: tableList})
      })
    }

    handleKeepLinkDataStatusChange = (event) => {
      this.setState({isKeepLinkData: event})
    }

    handleKeepAllDataStatusChange = (event) => {
      this.setState({isKeepAllData: event, isKeepLinkData : event === true ? event : this.state.isKeepLinkData})
    }


    getStateDetail = () => {
      return this.state;
    }

    applySampleFilterInJob = (tableList) => {
      tableList[0].rows.map(row => {
        row.isSampleFiltered = false;
        if (typeof(row.leftInputSampleFilter) !== 'undefined' && row.leftInputSampleFilter !== '' && row.leftInputSampleFilter !== null && typeof(row.leftInputSampleFilter) !== 'undefined' && row.leftEngineCode !== '') {
            const leftInputSampleFilter = JSON.parse(row.leftInputSampleFilter);

            if (leftInputSampleFilter !== '') {
              leftInputSampleFilter.filter(leftInputSampleFilter => leftInputSampleFilter.engineCode === row.leftEngineCode && leftInputSampleFilter.whereCondition !== '').forEach(leftInputSampleFilter => {
                row.isSampleFiltered = this.state.isSampleFilter
                row.leftSampleFilter = this.state.isSampleFilter === true ? leftInputSampleFilter.whereCondition : ''
              })
            }
        }

        if (typeof(row.rightInputSampleFilter) !== 'undefined' && row.rightInputSampleFilter !== '' && row.rightInputSampleFilter !== null && typeof(row.rightInputSampleFilter) !== 'undefined' && row.rightEngineCode !== '') {
            const rightInputSampleFilter = JSON.parse(row.rightInputSampleFilter);

            if (rightInputSampleFilter !== '') {
              rightInputSampleFilter.filter(rightInputSampleFilter => rightInputSampleFilter.engineCode === row.rightEngineCode && rightInputSampleFilter.whereCondition !== '').forEach(rightInputSampleFilter => {
                row.isSampleFiltered = this.state.isSampleFilter
                row.rightSampleFilter = this.state.isSampleFilter === true ? rightInputSampleFilter.whereCondition : ''
              })
            }
        }
        return row
      })

      return tableList
    }

    render(){
        return(
            <div>
            {this.state.jobs.length !== 0 &&
              <div className="row">
                <div className="col-12">
                  <label className="custom-switch">
                    <span>Sample Filter</span>
                    <Switch onChange={this.handleSampleFilterStatusChange} height={20} width={45} checked={this.state.isSampleFilter} />
                  </label>
                  <label className="custom-switch m-l-10">
                    <span>Keep All Data</span>
                    <Switch onChange={this.handleKeepAllDataStatusChange} height={20} width={45} checked={this.state.isKeepAllData} />
                  </label>
                  <label className="custom-switch m-l-10">
                    <span>Keep Link Data</span>
                    <Switch onChange={this.handleKeepLinkDataStatusChange} height={20} width={45} checked={this.state.isKeepLinkData} disabled={(this.state.process !== null && typeof(this.state.process) !== "undefined" && this.state.process.processId) > 0 || (this.state.processBlock !== null && typeof(this.state.processBlock) !== "undefined" && this.state.processBlock.blockId) > 0 || this.state.isKeepAllData === true } />
                  </label>
                </div>
              </div>
            }

            {this.state.jobs.length === 0 ? (
                <div className="align-center m-t-10">
                  <h4>{this.state.message}</h4>
                </div>
            ) : (<div className="executealljobs">
                <RichGridTable {...this.state.tableList[0]}/>
                </div>
                )}
            <br/>
            { this.state.showLogPopUp &&
                <Dialog className="data-properties" title={'Logs'} width="75%" height="auto" modal={true} onClose={this.toggleLogPopUp} buttons={[<Button primary={true} onClick={this.toggleLogPopUp} key="btn-ok"> OK</Button>]}>
                  <div>
                  <div className="log_Editor execute_all">
                    <TextEditor  options={{ lineNumbers: true, readOnly: true}} code={this.state.logData} />
                  </div>
                </div>
                </Dialog>
            }
            </div>
        );
    }
}

const mapStateToProps = createSelector(
    state => state.processFlow.processFlowList,
    state => state.processFlow.tenantId,
    state => state.processFlow.processFlowTable,
    state => state.processFlow.processFlowTableId,
    state => state.processFlow.activeProcessFlow,
    state => state.treeView,
    (processFlowList, tenantId, processFlowTable, processFlowTableId, activeProcessFlow, treeView) => ({
      processFlowList,
      tenantId,
      processFlowTable,
      processFlowTableId,
      activeProcessFlow,
      treeView
    })
  );

  const mapActionsToProps = {
    addProcessFlowBlockLinkByTabIndex: addProcessFlowBlockLinkByTabIndex,
    updateProcessflowBlockRenderStatus: updateProcessflowBlockRenderStatus,
    updateProcessFlowBlockByTabIndex: updateProcessFlowBlockByTabIndex,
    updateProcessFlowSyncFlagByTabIndex:updateProcessFlowSyncFlagByTabIndex,
    setMessage: setMessage
  }

  ExecuteAll.propTypes = {
    processFlowList: PropTypes.array,
    tenantId: PropTypes.string,
    processFlowTable: PropTypes.string,
    activeProcessFlow: PropTypes.number,
    treeView: PropTypes.object,
  }

  Dialog.propTypes = {
        height: PropTypes.oneOfType([
            PropTypes.string
        ]),
        width: PropTypes.oneOfType([
            PropTypes.string
        ])
    }
  export default connect(mapStateToProps, mapActionsToProps, null, { withRef: true })(ExecuteAll);
