import React from 'react';
import { DropDownList } from './StandardDropDownList';
import ConfirmBox from './../ConfirmBox';

export const DataOpsProcessResultTypeCell = ({ tableManager, value, data, column, colIndex, rowIndex }) => {
    if (typeof(tableManager) === 'undefined') {
      return (null)
    } else {
      return (
          <div className='rgt-cell-inner' style={{display: 'flex', alignItems: 'center', overflow: 'hidden'}}>
              <DataOpsProcessResultTypeCellClass dataItem={data} field={column.field} value={typeof(data[column.field]) !== 'undefined' ? data[column.field]: value} column={column} tableManager={tableManager}/>
          </div>
      )
    }
}

class DataOpsProcessResultTypeCellClass extends React.Component {

    handleChange(e) {
      this.props.dataItem['variableLike'] = '';
      this.props.dataItem['processOption'] = '';
      this.props.dataItem['decimalOption'] = '';
      this.props.dataItem['resultType'] = e.target.value;
      this.props.dataItem['preference'] = '';
      var data = [];

      global.gridData.forEach((row) => {
        if (row['resultType'] !== 'Variable Like' && this.props.dataItem['rowId'] !== row['rowId'] && row['variable'] !== '') {
          data.push(row['variable']);
        }
      })

      if (this.props.dataItem['resultType'] === 'Variable Like' && data.length === 0) {
        ConfirmBox.alert("You don't have any items in Variable Like. Please select one item.", MessageTypeConst.WARNING_MESSAGE);
        this.props.dataItem['resultType'] = '';
      }
      this.props.dataItem[this.props.field] = this.props.dataItem['resultType'];
      this.props.tableManager.rowsApi.setRows(this.props.tableManager.rowsApi.rows)

    }
    render() {
      var data = [""];

      var disabled = false;
      if (this.props.dataItem['rowId'] === '' || this.props.dataItem['variable'] === '') {
        disabled = true;
      }

      if (this.props.dataItem['variable'] !== '') {
        if (typeof(this.props.dataItem['variable'].split('-')[1]) !== 'undefined' && this.props.dataItem['variable'].split('-')[1].split(' ')[0] !== '') {
          var type = this.props.dataItem['variable'].split('-')[1].split(' ')[0];
          if (type === 'CHAR') {
            data = [ "", "Character", "Categorical", "Variable Like"]
          } else if (type === 'NUM') {
            data = [ "", "Numeric", "Variable Like"]
          } else if (type === 'DATE' || type === 'DATETIME') {
            data = [ "", "Date", "Variable Like"]
          }
        }
      }
      data = data.filter(item => item !== "Variable Like");
        return (
            <>
              <DropDownList className="width-full" onChange={this.handleChange.bind(this)} data={data} value={this.props.dataItem[this.props.field] === "Variable Like" ? "" : this.props.dataItem[this.props.field]} disabled={disabled}/>
            </>
        );
    }
}
