
import React    from "react";
import axios from 'axios';
import $ from 'jquery';
import { connect } from 'react-redux';
import { createSelector } from 'reselect';
import restClient from '../../../restClient';
import { GET, CREATE } from '../../../restClient/types';
import template from "./SourceMetaVersionManager.jsx";
import { setMessage } from './../../../actions/actionNotification';
import MessageTypeConst from '../../MessageTypeConst';
import { DataButton, TagValueCell, PermanentFlagCheckBoxCell } from '../DataOperation/common';
import { setTenantId } from './../../../actions/actionProcessFlow';
import { setTreeViewData } from './../../../actions/actionTreeView';
import moment from 'moment';

class SourceMetaVersionManager extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            srcTableName:props.srcTableName,
            treeView:props.treeView,
            projectId:props.projectId,
            studyId:props.studyId,
            tableType:props.tableType,
            closeEvent:props.closeEvent,
            isDataLimitChange :false,
            tenantId:props.tenantId,
            schemaName:props.schemaName,
            processFlowTableId: props.processFlowTableId,
            processFlowList:props.processFlowList,
            activeProcessFlow:props.activeProcessFlow,
            dataVersionLimit:'1',
            tableList:[{
                id: 'version-grid',
                rows: [{"selected":false,"columnId":0,"blockTable":'',"tableVersion":"","tag":"","permanent":false,
                    "versionLimit":"","timestamp":"", "timestampModified": "","data":'',"tableType":0,"srcTableName":""}],
                resize: true,
                filterable: false,
                selectedField: "selected",
                columnProperty: [
                    { field: "srcTableName",className:"text-center", show: false},
                    { field: "tableType",className:"text-center", show: false},
                    { field: "selected",className:"text-center", show: false},
                    { field: "blockTable",sortable:false,className:"text-center","title":"Table Name" },
                    { field: "columnId", show: false},
                    { field: "versionLimit",sortable:false,width:"150px" ,className:"text-center" , show: false},
                    { field: "tableVersion", sortable:false,width:"150px" ,className:"text-center" },
                    { field: "tag", sortable:false,className:"text-center", width:"200px", cell:TagValueCell },
                    { field: "permanent", sortable:false, width:"100px",className:"text-center", cell:PermanentFlagCheckBoxCell },
                    //{ field: "timestamp", sortable:false , width:"150",className:"text-center", title:"Creation Date"},
                    //{ field: "timestampModified", sortable:false , width:"150",className:"text-center", title:"Modified Date"},
                    { field: "username", sortable:false , width:"180px",className:"text-center", title:"User Name"},
                    { field: "data", sortable:false, width:"90px",className:"text-center", cell:DataButton },
                ]
            }]
        }
        this.closeDialog = this.closeDialog.bind(this);
        this.handleDataVersionChange = this.handleDataVersionChange.bind(this);
        this.updateVersionDetails = this.updateVersionDetails.bind(this);
    }

    handleDataVersionChange(event){
        if(isNaN(parseInt(event.target.value, 10))){
            this.setState({dataVersionLimit:event.target.value, isDataLimitChange:true});
        }else{
            this.setState({dataVersionLimit:parseInt(event.target.value,10), isDataLimitChange:true});
        }
    }

    updateVersionDetails(event){
        if(this.state.dataVersionLimit === "" || this.state.dataVersionLimit === null || parseInt(this.state.dataVersionLimit,10) <= 0 ){
            this.props.setMessage("Please provide positive integer value for Version Limit.", MessageTypeConst.WARNING_MESSAGE);
            return;
        }
        event.target.disabled = true;
        let params = [];
        this.state.tableList[0].rows.filter(r => r.selected === true).forEach((row)=>{
            let item = {
                tableType:row.tableType,
                srcTableName:row.srcTableName,
                tableVersion:row.tableVersion,
                tag:row.tag === null ? '' : row.tag,
                permanentFlag:row.permanent ? 1 : 0
            };
            params.push(item);
        })
        if(params.length === 0 && !this.state.isDataLimitChange){
            this.props.setMessage("No data change.All versions are as it is.",MessageTypeConst.SUCCESS_MESSAGE);
            this.closeDialog();
            return;
        }
        $(".loader").show();
        let that = this;
        let versionUpdate = (headers, params) => restClient(CREATE, 'manageVersion/updateTableVersions?tableType='+this.state.tableType+'&updateVersionLimit='+this.state.isDataLimitChange+'&versionLimit='+this.state.dataVersionLimit+'&srcTableName='+this.state.srcTableName, params, headers);
        axios.all([versionUpdate({'Tenant-ID': this.state.schemaName}, {data: params})])
         .then(axios.spread((response) => {
             if(response.data !== null && typeof(response.data) !== "undefined" && response.data.message !== null && typeof(response.data.message) !== "undefined"){
                 if(response.data.messageType === MessageTypeConst.SUCCESS_MESSAGE && this.state.isDataLimitChange){

                    let treeView = this.state.treeView;
                    treeView.children.forEach((project, index) => {
                        if (project.projectId === that.state.projectId) {
                          project.children.forEach((study) => {
                            if (study.study.studyId === that.state.studyId) {
                              if (this.state.tableType === 1 && typeof(study.study.metaTable) !== 'undefined' && study.study.metaTable !== null && study.study.metaTable.length > 0) {
                                study.study.metaTable.forEach((table1, index)=>{
                                    if(this.state.srcTableName === table1.srcTableName){
                                        study.study.metaTable[index].versionLimit = this.state.dataVersionLimit;
                                    }
                                })
                              }
                              if (this.state.tableType ===0 && typeof(study.study.sourceTable) !== 'undefined' && study.study.sourceTable !== null && study.study.sourceTable.length > 0) {
                                study.study.sourceTable.forEach((table1, index)=>{
                                    if(this.state.srcTableName === table1.srcTableName){
                                        study.study.sourceTable[index].versionLimit = this.state.dataVersionLimit;
                                    }
                                })
                              }
                              study.children.forEach((studyElement) => {
                                  studyElement.children.forEach((table, index) => {
                                    if (table.name === this.state.srcTableName) {
                                        studyElement.children[index].versionLimit = this.state.dataVersionLimit;
                                    }
                                  })
                                })
                              };
                            });
                          }
                         });

                    this.props.setTreeViewData(treeView);
                 }
                $(".loader").hide();
                this.closeDialog();
                this.props.setMessage(response.data.message, response.data.messageType);
             }
         })).catch(error => {
             $(".loader").hide();
            this.closeDialog();
            this.props.setMessage("Error occurred while updating table versions.",MessageTypeConst.ERROR_MESSAGE);
          });
    }

    componentDidMount(){
        this.props.setTenantId(this.state.schemaName);
        global.processBlockControlValues = [];
        global.processBlockControlValues.push({
            "controlId": -97,
            "controlType": -97,
            "controlValue": ""
          });
        global.processBlockControlValues.push({
            "controlId": -96,
            "controlType": -96,
            "controlValue": ""
        });
        let that = this;
        $(".loader").show();
        axios.all([restClient(GET, 'manageVersion/getTableVersions?tableType='+this.state.tableType+"&srcTableName="+this.state.srcTableName, {}, {'Tenant-ID': this.state.schemaName})])
        .then(axios.spread((response) => {
            if(response!= null && response.data !== null && typeof(response.data.messageType) !== "undefined"
            &&  response.data.messageType !== null && response.data.messageType === MessageTypeConst.WARNING_MESSAGE )
            {
                that.props.setMessage(response.data.message, response.data.messageType);
                $(".loader").hide();
            }
            if(response!= null && response.data !== null && typeof(response.data.versions) !== "undefined"
            &&  response.data.versions !== null && response.data.versions.length >0 )
            {
                let tableList = that.state.tableList;
                let versionLimit='';
                tableList[0].rows.pop();
                response.data.versions.sort((a,b)=>a.tableVersion-b.tableVersion).forEach((version, index)=>{
                    versionLimit = version.versionLimit;
                    tableList[0].rows.push({
                        "selected":false,
                        "columnId":index,
                        "blockTable":version.tableName,
                        "tag":version.tag,
                        "permanent":version.permFlag === 0 ? false : true,
                        "versionLimit":version.versionLimit,
                        "tableVersion":version.tableVersion,
                        "timestamp":version.audit !== null ? moment.utc(version.audit.createdAt).format('lll') : "",
                        "timestampModified":version.audit !== null ? moment.utc(version.audit.modifiedAt).format('lll') : "",
                        "username":version.audit !== null ? version.audit.createdBy : "",
                        "data":'',
                        "tableType":version.tableType,
                        "srcTableName":version.srcTableName
                    });
                })
                that.setState({tableList:tableList, dataVersionLimit:versionLimit});
            }
            $(".loader").hide();
        })).catch(error=>{
            $(".loader").hide();
            that.props.setMessage("Failed to fetch version.",MessageTypeConst.ERROR_MESSAGE);
          });
    }

    closeDialog(){
        this.state.closeEvent();
    }


    render() {
        return template.call(this);
    }
}

const mapStateToProps = createSelector(
    state => state.processFlow.processFlowList,
    state => state.processFlow.tenantId,
    state => state.processFlow.processFlowTable,
    state => state.processFlow.processFlowTableId,
    state => state.processFlow.activeProcessFlow,
    state => state.treeView.data,
    (processFlowList, tenantId, processFlowTable, processFlowTableId, activeProcessFlow, treeView) => ({
      processFlowList,
      tenantId,
      processFlowTable,
      processFlowTableId,
      activeProcessFlow,
      treeView
    })
  );
  const mapActionsToProps = {
    setMessage: setMessage,
    setTenantId:setTenantId,
    setTreeViewData:setTreeViewData
  }
  export default connect(mapStateToProps, mapActionsToProps)(SourceMetaVersionManager);
