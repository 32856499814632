import axios from 'axios';
import restClient from '../restClient';
import { GET } from '../restClient/types';
import {
    ADD_PROCESSFLOW, REMOVE_PROCESSFLOW, SET_TENANT_ID, REMOVE_PROCESSFLOW_BLOCK_BY_TABINDEX, ADD_PROCESSFLOW_BLOCK_BY_TABINDEX, UPDATE_PROCESSFLOW_BLOCK_BY_TABINDEX, ADD_PROCESSFLOW_BLOCKLINK_BY_TABINDEX,
    SET_PROCESSFLOW_ACTION, SET_PROCESSFLOW_TABLE, SET_PROCESSFLOW_TABLE_ID, SET_QUICK_CONDITION, SET_SUB_MENU_COLUMNS,
    UPDATE_PROCESSFLOW_BLOCK_RENDER_STATUS_BY_TABINDEX, ADD_IMPORT_TABLE, ADD_PROCESSFLOW_TITLE, SET_ACTIVE_PROCESSFLOW, SET_ALL_PROCESSFLOW_RENDER_STATUS, SELECTITEMS_FOR_COPY_PASTE, UPDATE_PROCESSFLOW_BLOCK_BY_SCHEMA_NAME,
    UPDATE_PROCESSFLOW_SYNC_FLAG_BY_TABINDEX, REMOVE_PROCESSFLOW_BLOCKLINK_BY_TABINDEX, REMOVE_IMPORT_TABLE, REFRESH_PROCESS_FLOW, TOGGLE_REFRESH_TOKEN,
    REMOVE_PROCESSFLOW_BY_PROJECT_ID, REMOVE_PROCESSFLOW_BY_STUDY_ID, REMOVE_PROCESSFLOW_BY_PROCESS_GROUP_ID, UPDATE_PROCESSFLOW_CHECKED_OUT_BY_TABINDEX, SET_STUDY_ID, SET_PROJECT_ID, SET_ERROR_MESSAGE, SET_JUMP_TO_BLOCK_ID_PROCESS_ID,
    REMOVE_PROCESSFLOW_NOTE_BY_TABINDEX, ADD_PROCESSFLOW_NOTE_BY_TABINDEX, UPDATE_PROCESSFLOW_NOTE_BY_TABINDEX, UPDATE_PROCESSFLOW_NOTE_BY_SCHEMA_NAME, ADD_MULTIPLE_PROCESSFLOW_BLOCKLINK_BY_TABINDEX, REMOVE_ALL_PROCESSFLOW, PROJECT_UPDATE_STUDY_ACTION
 } from './actionsTypes';

export const addProcessFlow = (process) => dispatch => {
    axios.all([
      restClient(GET, 'processblock/blockAndLinkList?studyId='+process.process.studyId+'&processId='+process.process.processId, {}, {'Tenant-ID': process.schemaName})
    ])
    .then(axios.spread((response) => {
        if (typeof(response.data) !== 'undefined') {

          if (typeof(response.data.processFlow) !== "undefined" && response.data.processFlow !== null && response.data.processFlow !== '' && response.data.processFlow.length > 0) {
            process.process = response.data.processFlow[0];
          }
          process.process.processBlock = []
          if (response.data.processBlock !== null && response.data.processBlock !== '' && response.data.processBlock.length > 0) {

            process.process.processBlock.push(...response.data.processBlock);
          }

          process.process.processBlockLink = []
          if (response.data.processBlockLink !== null && response.data.processBlockLink !== '' && response.data.processBlockLink.length > 0) {
            process.process.processBlockLink.push(...response.data.processBlockLink);
          }

          process.process.processFlowNotes = [];
          if (response.data.processFlowNotes !== null && response.data.processFlowNotes !== '' && response.data.processFlowNotes.length > 0) {
            process.process.processFlowNotes.push(...response.data.processFlowNotes);
          }
        }

        dispatch({
          type: ADD_PROCESSFLOW,
          payload: process
        })

        dispatch({
          type: PROJECT_UPDATE_STUDY_ACTION,
          payload: {studyId: process.process.studyId, enableActions: response.data.enableActions}
        })

      })
    ).catch(error=>{
        dispatch({
          type: SET_ERROR_MESSAGE,
          payload: "Error occurred while fetching blocks and links."
        });
    });
};

export const removeProcessFlow = (processId, studyId) => ({
    type: REMOVE_PROCESSFLOW,
    payload: { processId: processId, studyId: studyId }
})

export const setTenantId = (tenantId) => ({
    type: SET_TENANT_ID,
    payload: tenantId
})

export const removeProcessFlowBlockByTabIndex = (tabIndex, blockId) => ({
    type: REMOVE_PROCESSFLOW_BLOCK_BY_TABINDEX,
    payload: { tabIndex: tabIndex, blockId: blockId }
})

export const addProcessFlowBlockByTabIndex = (tabIndex, block) => ({
    type: ADD_PROCESSFLOW_BLOCK_BY_TABINDEX,
    payload: { tabIndex: tabIndex, block: block }
})

export const addProcessFlowBlockLinkByTabIndex = (tabIndex, blockLink) => ({
    type: ADD_PROCESSFLOW_BLOCKLINK_BY_TABINDEX,
    payload: { tabIndex: tabIndex, blockLink: blockLink }
})

export const updateProcessFlowBlockByTabIndex = (tabIndex, blockIndex, block) => ({
    type: UPDATE_PROCESSFLOW_BLOCK_BY_TABINDEX,
    payload: { tabIndex: tabIndex, blockIndex: blockIndex, block: block }
})

export const setProcessFlowAction = (processFlowAction) => ({
    type: SET_PROCESSFLOW_ACTION,
    payload: processFlowAction
})

export const setProcessFlowTable = (processFlowTable) => ({
    type: SET_PROCESSFLOW_TABLE,
    payload: processFlowTable
})

export const setProcessFlowTableId = (processFlowTableId) => ({
    type: SET_PROCESSFLOW_TABLE_ID,
    payload: processFlowTableId
})

export const setQuickCondition = (quickCondition) => ({
    type: SET_QUICK_CONDITION,
    payload: quickCondition
})

export const setSubMenuColumns = (subMenuColumns) => ({
    type: SET_SUB_MENU_COLUMNS,
    payload: subMenuColumns
})

export const updateProcessflowBlockRenderStatus = (tabIndex, status) => ({
    type: UPDATE_PROCESSFLOW_BLOCK_RENDER_STATUS_BY_TABINDEX,
    payload: { tabIndex: tabIndex, status: status}
})

export const addImportTable = table => ({
    type: ADD_IMPORT_TABLE,
    payload: table
})

export const addProcessFlowTitle = table => ({
    type: ADD_PROCESSFLOW_TITLE,
    payload: table
})

export const setActiveProcessFlow = status => ({
    type: SET_ACTIVE_PROCESSFLOW,
    payload: status
})

export const setALLProcessFlowRenderStatus = status => ({
    type: SET_ALL_PROCESSFLOW_RENDER_STATUS,
    payload: status
})

export const selectItemsForCopyPaste = blockList => ({
  type: SELECTITEMS_FOR_COPY_PASTE,
  payload: blockList
})

export const updateProcessFlowBlockBySchemaName = (schemaName, blockList) => ({
  type: UPDATE_PROCESSFLOW_BLOCK_BY_SCHEMA_NAME,
  payload: { schemaName: schemaName, blockList: blockList }
})

export const updateProcessFlowSyncFlagByTabIndex = (tabIndex, processSyncFlag) => ({
    type: UPDATE_PROCESSFLOW_SYNC_FLAG_BY_TABINDEX,
    payload: { tabIndex: tabIndex, processSyncFlag: processSyncFlag }
})

export const removeProcessFlowBlockLinkByTabIndex = (tabIndex, linkId) => ({
    type: REMOVE_PROCESSFLOW_BLOCKLINK_BY_TABINDEX,
    payload: { tabIndex: tabIndex, linkId: linkId }
})

export const removeImportTable = table => ({
    type: REMOVE_IMPORT_TABLE,
    payload: table
})

export const refreshProcessFlow = (process) => dispatch => {
    axios.all([
      restClient(GET, 'processblock/cleanup?studyId='+process.process.studyId+'&processId='+process.process.processId, {}, {'Tenant-ID': process.schemaName})
    ])
    .then(axios.spread((response) => {
        if (typeof(response.data) !== 'undefined') {
          process.process.processBlock = []
          if (response.data.processBlock !== null && response.data.processBlock !== '' && response.data.processBlock.length > 0) {

            process.process.processBlock.push(...response.data.processBlock);
          }

          process.process.processBlockLink = []
          if (response.data.processBlockLink !== null && response.data.processBlockLink !== '' && response.data.processBlockLink.length > 0) {
            process.process.processBlockLink.push(...response.data.processBlockLink);
          }
          process.blockRender = true;

          dispatch({
            type: REFRESH_PROCESS_FLOW,
            payload: process
          })
        }
      })
    ).catch(error=>{
        dispatch({
          type: SET_ERROR_MESSAGE,
          payload: "Error occurred while refreshing process flow."
        });
    });
};

export const updateProcessFlowCheckedOutStatusByTabIndex = (tabIndex, checkOutFlag, userName) => ({
        type: UPDATE_PROCESSFLOW_CHECKED_OUT_BY_TABINDEX,
        payload: { tabIndex: tabIndex, checkOutFlag: checkOutFlag, userName:userName }
})

export const toggleRefreshToken = () => ({
    type: TOGGLE_REFRESH_TOKEN
})

export const removeProcessFlowByProjectId = (projectId) => ({
    type: REMOVE_PROCESSFLOW_BY_PROJECT_ID,
    payload: projectId
})

export const removeProcessFlowByStudyId = (studyId) => ({
    type: REMOVE_PROCESSFLOW_BY_STUDY_ID,
    payload: studyId
})

export const removeALLProcessFlow = () => ({
    type: REMOVE_ALL_PROCESSFLOW,
    payload: []
})

export const removeProcessFlowByProcessGroupId = (processGroupId, studyId) => ({
    type: REMOVE_PROCESSFLOW_BY_PROCESS_GROUP_ID,
    payload: { processGroupId: processGroupId, studyId: studyId }
})

export const setStudyId = (studyId) => ({
    type: SET_STUDY_ID,
    payload: studyId
})

export const setProjectId = (projectId) => ({
    type: SET_PROJECT_ID,
    payload: projectId
})

export const setJumpToBlockIdProcessId = (blockId, processId) => ({
    type: SET_JUMP_TO_BLOCK_ID_PROCESS_ID,
    payload: { blockId: blockId, processId: processId }
})

export const removeProcessFlowNoteByTabIndex = (tabIndex, noteId) => ({
    type: REMOVE_PROCESSFLOW_NOTE_BY_TABINDEX,
    payload: { tabIndex: tabIndex, noteId: noteId }
})

export const addProcessFlowNoteByTabIndex = (tabIndex, note) => ({
    type: ADD_PROCESSFLOW_NOTE_BY_TABINDEX,
    payload: { tabIndex: tabIndex, note: note }
})

export const updateProcessFlowNoteByTabIndex = (tabIndex, noteIndex, note) => ({
    type: UPDATE_PROCESSFLOW_NOTE_BY_TABINDEX,
    payload: { tabIndex: tabIndex, noteIndex: noteIndex, note: note }
})

export const updateProcessFlowNoteBySchemaName = (schemaName, noteList) => ({
    type: UPDATE_PROCESSFLOW_NOTE_BY_SCHEMA_NAME,
    payload: { schemaName: schemaName, noteList: noteList }
})

export const addMultipleProcessflowBlockLinkByTabIndex = (tabIndex, blockLinkList, reset = true) => ({
    type: ADD_MULTIPLE_PROCESSFLOW_BLOCKLINK_BY_TABINDEX,
    payload: { tabIndex: tabIndex, blockLinkList: blockLinkList, reset: reset }
})
