import React from 'react';
import { PropTypes } from "prop-types";
import { Input } from './../InputBox/StandardInput';
import { Button } from './StandardButton';
import Dialog from 'react-dialog';
import ExpressionDialog from './../../../Annotate/ExpressionDialog';
import ApplyProcessFlow from './../../../Annotate/ApplyProcessFlow';
import MultipleExpressionDialog from './../../../Annotate/MultipleExpressionDialog';
import "../../DataOperation.css";

Dialog.propTypes = {
    height: PropTypes.oneOfType([
        PropTypes.string
    ]),
    width: PropTypes.oneOfType([
        PropTypes.string
    ])
}

export const ExpressionButton = ({ tableManager, value, data, column, colIndex, rowIndex }) => {
    if (typeof(tableManager) === 'undefined') {
      return (null)
    } else {
      return (
          <div className='rgt-cell-inner' style={{display: 'flex', alignItems: 'center', overflow: 'hidden'}}>
              <ExpressionButtonClass dataItem={data} field={column.field} value={typeof(data[column.field]) !== 'undefined' ? data[column.field]: value} column={column} tableManager={tableManager}/>
          </div>
      )
    }
}

class ExpressionButtonClass extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
          visible: false,
          multipleExpressionDialogVisible: false,
          intermediateColumn: '',
          columnHardcode: '',
          expressionDetail: ''
        };
        this.closeDialog = this.closeDialog.bind(this);
        this.openDialog = this.openDialog.bind(this);
        this.handleExpressionSaveClick = this.handleExpressionSaveClick.bind(this);
        this.handleExpressionColumnChange = this.handleExpressionColumnChange.bind(this);
        this.handleApplyProcessSaveClick = this.handleApplyProcessSaveClick.bind(this);
    }

    openDialog() {
        this.setState({
            visible: this.props.dataItem['combineExpressionList'].length === 0 ? true : false,
            multipleExpressionDialogVisible: this.props.dataItem['combineExpressionList'].length > 1 ? true : false,
            intermediateColumn: typeof(this.props.dataItem['expressionDetail'].hardcode) !== 'undefined' ? this.props.dataItem['expressionDetail'].hardcode.intermediateColumn : '',
            columnHardcode: typeof(this.props.dataItem['expressionDetail'].hardcode) !== 'undefined' ? this.props.dataItem['expressionDetail'].hardcode.columnHardcode : '',
            expressionDetail: this.props.dataItem['expressionDetail']
          });
    }

    closeDialog() {
        this.setState({
            visible: false,
            multipleExpressionDialogVisible: false
          });
    }
    handleExpressionSaveClick(e, tableList, replace = false) {
      let expressionDetail = this.props.dataItem['expressionDetail'];

      if (replace === false) {
        if (typeof(expressionDetail.expressionDetail) !== 'undefined' && typeof(tableList) !== 'undefined') {
          expressionDetail.expressionDetail = tableList;
        } else if (typeof(this.props.dataItem['expressionDetail'].hardcode) !== 'undefined') {
          this.props.dataItem['expressionDetail'] = this.state.expressionDetail;
        }
      } else {
        expressionDetail = tableList
        let selectedColumnList = [];
        let columnMappedList = this.props.dataItem['columnMappedList']
        expressionDetail.expressionDetail.forEach(table => {
          table.children.forEach(column => {
            selectedColumnList.push({tableName: table.tableName, columnTitle: column.sourceColumnName})
          })
        })

        columnMappedList.map(columnMapped => {
          columnMapped.selected = selectedColumnList.filter(selectedColumn => selectedColumn.tableName === columnMapped.table.source && selectedColumn.columnTitle === columnMapped.columnTitle.source ).length > 0 ? true :false;

          return columnMapped;
        })
        this.props.dataItem['columnMappedList'] = columnMappedList

        let sourceVariableStatusList = this.props.dataItem['sourceVariableStatusList']
        sourceVariableStatusList.forEach(sourceVariableStatus => {
          if (columnMappedList.filter(columnMapped => columnMapped.selected === false && sourceVariableStatus.tableName === columnMapped.table.source && sourceVariableStatus.column === columnMapped.columnTitle.source).length === 1) {
            sourceVariableStatus.orignalStatus = sourceVariableStatus.status;
            sourceVariableStatus.status = 0;
          } else if (typeof(sourceVariableStatus.orignalStatus) !== 'undefined') {
            sourceVariableStatus.status = sourceVariableStatus.orignalStatus
          }
        })
      }

      if (this.props.dataItem['expressionConflict'] === true) {
        this.props.dataItem['selected'] = true
      }
      this.props.dataItem['expressionConflict'] = false

      this.props.dataItem['expressionDetail'] = expressionDetail;
      this.props.tableManager.rowsApi.setRows(this.props.tableManager.rowsApi.rows)

      this.setState({expressionDetail: ''})
      this.closeDialog();
    }

    handleApplyProcessSaveClick = (e, expressionDetail) => {
      this.props.dataItem['expressionDetail'] = expressionDetail
      this.props.dataItem['expressionConflict'] = false
      this.props.dataItem['expressionDetail'] = expressionDetail;
      this.props.tableManager.rowsApi.setRows(this.props.tableManager.rowsApi.rows)

      this.setState({expressionDetail: ''})
      this.closeDialog();
    }

    handleExpressionColumnChange(e, type) {
      let {intermediateColumn, columnHardcode} = this.state;

      if (type === 'intermediate') {
        intermediateColumn = e.target.value;
      } else if(type === 'hardcode') {
        columnHardcode = e.target.value;
      }

      this.setState({intermediateColumn: intermediateColumn, columnHardcode: columnHardcode})
    }

    extraHardcode = (id, hardcode, index) => {
      return (
        <div key={`extra-expression-${index}`} className="input-conflic">
          <input type="radio" name={`hardcode-${id}`} value={index+1} onChange={(event) => this.handleExtraHardcodeChange(event, index, 'radio')} checked={hardcode.selected} />
          <Input className="width-full" onChange={(event) => this.handleExtraHardcodeChange(event, index)} value={hardcode.columnHardcode} />
        </div>
      )
    }

    extraExpressionIntermediate = (id, expressionIntermediate, index) => {
      return (
        <div key={`extra-expression-intermediate-${index}`} className="expression_tile">
          <div className="expression_tile_radio">
            <input type="radio" name={`expression-intermediate-${id}`} value={index+1} onChange={(event) => this.handleExtraHardcodeChange(event, index, 'radio')} checked={expressionIntermediate.selected} />
          </div>
          <div className="expression_tile_inputs">
            <div className="am-form-line-left">
              <label className="pro-label col-sm-4"><span>Intermediate Column</span></label>
              <Input className="text-box-display" onChange={(event) => this.handleExtraHardcodeChange(event, index, 'input', true)} value={expressionIntermediate.intermediateColumn} />
            </div>
            <div className="am-form-line-left">
              <label className="pro-label col-sm-4"><span>Expression</span></label>
              <Input className="text-box-display" onChange={(event) => this.handleExtraHardcodeChange(event, index)} value={expressionIntermediate.columnHardcode} />
            </div>
          </div>
        </div>
      )
    }

    handleExtraHardcodeChange = (event, index, type = 'input', isIntermediateColumn = false) => {
      let expressionDetail = this.state.expressionDetail
      if (type === 'radio') {
        this.state.expressionDetail.extraHardcodeList.map(extraHardcode => extraHardcode.selected = false);
        if (index > -1) {
          expressionDetail.extraHardcodeList[index].selected = true
        }
      } else if(type === 'input') {
        let columnField = isIntermediateColumn ? 'intermediateColumn' : 'columnHardcode';
        if (index > -1) {
          expressionDetail.extraHardcodeList[index][columnField] = event.target.value
        } else {
          expressionDetail.hardcode[columnField] = event.target.value
        }
      }
      this.setState({expressionDetail: expressionDetail})
    }

    render() {
      let node = {id: '', title: ''};
      let expressionConflict = typeof(this.props.dataItem['expressionConflict']) !== 'undefined' ? this.props.dataItem['expressionConflict'] : false;
      let multipleHardcodeDialog = typeof(this.props.dataItem['expressionDetail'].extraHardcodeList) !== 'undefined' && this.props.dataItem['expressionDetail'].extraHardcodeList.length > 0 ? 'multiple-hardcode' : '';

      if (this.props.dataItem['matchType'] === 'Knowledge' && typeof(this.props.dataItem['expressionDetail']) !== 'undefined' && typeof(this.props.dataItem['combineExpressionList']) !== 'undefined' && this.props.dataItem['expressionButton'] === true) {
        if (typeof(this.props.dataItem['expressionDetail'].id) !== 'undefined' && this.props.dataItem['expressionDetail'].id !== '') {
          node.id = this.props.dataItem['expressionDetail'].id;
          node.title = this.props.dataItem['expressionDetail'].node.title;
        }

        if (this.props.dataItem['combineExpressionList'].length > 0) {
          node.id = this.props.dataItem['combineExpressionList'][0].node.id;
          node.title = this.props.dataItem['combineExpressionList'][0].node.title;
        }
      } else if(this.props.dataItem['expressionButton'] === false && typeof(this.props.dataItem['expressionDetail']) !== 'undefined' && typeof(this.props.dataItem['expressionDetail'].id) !== 'undefined') {
        node.id = this.props.dataItem['expressionDetail'].id;
      }

      if (typeof(this.props.dataItem['applyProcessFlow']) !== 'undefined' && this.props.dataItem['applyProcessFlow'] === true) {
        node = Object.assign(node, {tableName: this.props.dataItem['expressionDetail'].tableName, columnName: this.props.dataItem['expressionDetail'].columnName, name: this.props.dataItem['expressionDetail'].columnName, title: this.props.dataItem['expressionDetail'].column, column: this.props.dataItem['expressionDetail'].column, type: this.props.dataItem['expressionDetail'].type, selected: this.props.dataItem['expressionDetail'].selected})
      }

      return (
              <div className="text-center width-full expression-dialog-button-wrapper">
                {(node.id !== '' || (typeof(this.props.dataItem['combineExpressionList']) !== 'undefined' && this.props.dataItem['combineExpressionList'].length > 1)) && this.props.dataItem['expressionButton'] === true &&
                  <div className={expressionConflict === true ? "warning" : ""}>
                    <Button onClick={this.openDialog} > Expression </Button>
                    <div className="annotate-contextmenu-expression-dialog expression-button">
                      {this.state.visible &&
                        <Dialog title={`Expression - ${this.props.dataItem['displayTargetTable']} [${node.title}]`} width="90%" height="auto" onClose={this.closeDialog}>
                            <ExpressionDialog expressionDetail={this.props.dataItem['expressionDetail']} columnNodeMappedList={this.props.dataItem['columnMappedList']}  isKnowledgeExpression={true} isExpressionButton={true} node={node} handleExpressionClick={this.closeDialog} handleExpressionSaveClick={this.handleExpressionSaveClick}/>
                        </Dialog>
                      }

                      {this.state.multipleExpressionDialogVisible &&
                        <Dialog title={`Multiple Expression - ${this.props.dataItem['displayTargetTable']} [${node.title}]`} width="90%" height="auto" onClose={this.closeDialog}>
                            <MultipleExpressionDialog combineExpressionList={this.props.dataItem['combineExpressionList']} columnNodeMappedList={this.props.dataItem['columnMappedList']} handleExpressionClick={this.closeDialog} handleExpressionSaveClick={this.handleExpressionSaveClick} />
                        </Dialog>
                      }

                    </div>
                  </div>
                }

                {node.id !== '' && this.props.dataItem['expressionButton'] === false && typeof(this.props.dataItem['expressionDetail'].hardcode) !== 'undefined' &&
                  <div className={expressionConflict === true ? "warning" : ""}>
                    {this.props.dataItem['expressionDetail'].hardcode.type === 'Expression' &&
                      <Button onClick={this.openDialog} > Expression (Intermediate) </Button>
                    }

                    {this.props.dataItem['expressionDetail'].hardcode.type === 'Hardcode' &&
                      <Button onClick={this.openDialog} > Hardcode </Button>
                    }

                    <div className={`annotate-contextmenu-hardcode-dialog expression-button ${this.props.dataItem['expressionDetail'].hardcode.type} ${multipleHardcodeDialog}`}>
                      {this.state.visible &&
                        <Dialog title={this.props.dataItem['expressionDetail'].hardcode.type === 'Expression' ? 'Expression (Intermediate)' : 'Hardcode' } width="580px" height="auto" onClose={this.closeDialog} buttons={[<Button primary={true} onClick={this.handleExpressionSaveClick} key="btn-ok">OK</Button>,<Button onClick={this.closeDialog} key="btn-cancel">Cancel</Button>]}>
                          <div className="am-form-line-left">
                              <label className="pro-label col-sm-4"><span>Table Name </span></label>
                              <Input className="text-box-display" name="table_name" defaultValue={this.props.dataItem['displayTargetTable']} disabled />
                          </div>
                          <div className="am-form-line-left">
                              <label className="pro-label col-sm-4"><span>Column Name </span></label>
                              <Input className="text-box-display" name="table_name" defaultValue={this.props.dataItem['expressionDetail'].column} disabled />
                          </div>
                          {multipleHardcodeDialog === '' && this.props.dataItem['expressionDetail'].hardcode.type === 'Expression' &&
                            <div className="am-form-line-left">
                                <label className="pro-label col-sm-4"><span>Intermediate Column</span></label>
                                <Input className="text-box-display" name="intermediate_column" onChange={(event) => this.handleExtraHardcodeChange(event, -1, 'input', true)} value={this.state.expressionDetail.hardcode.intermediateColumn} />
                            </div>
                          }
                          {multipleHardcodeDialog === '' &&
                            <div className="am-form-line-left">
                                <label className="pro-label col-sm-4"><span>{this.props.dataItem['expressionDetail'].hardcode.type}</span></label>
                                <Input className="text-box-display" name="hardcode_condition" onChange={(event) => this.handleExtraHardcodeChange(event, -1)} value={this.state.expressionDetail.hardcode.columnHardcode} />
                            </div>
                          }
                          {multipleHardcodeDialog !== '' && this.props.dataItem['expressionDetail'].hardcode.type !== 'Expression' &&
                            <div className="am-form-line-left conflict-expression">
                                <label className="pro-label col-sm-4"><span>{this.props.dataItem['expressionDetail'].hardcode.type}</span></label>
                                <div className="col-sm-8">
                                  <div className="input-conflic">
                                    <input type="radio" name={`hardcode-${this.state.expressionDetail.id}`} value={'0'} onChange={(event) => this.handleExtraHardcodeChange(event, -1, 'radio')} checked={this.state.expressionDetail.extraHardcodeList.filter(extraExpression => extraExpression.selected === true).length ? false: true}/>
                                    <Input className="am-textbox width-full" name="hardcode_condition" onChange={(event) => this.handleExtraHardcodeChange(event, -1)} value={this.state.expressionDetail.hardcode.columnHardcode} />
                                  </div>
                                  {this.state.expressionDetail.extraHardcodeList.map((hardcode, key) => this.extraHardcode(this.state.expressionDetail.id, hardcode, key)) }
                                </div>
                            </div>
                          }

                          {this.props.dataItem['expressionDetail'].hardcode.type === 'Expression' && this.state.expressionDetail.extraHardcodeList.length > 0 &&
                            <div className="expression_tile_list">
                              <div className="expression_tile">
                                <div className="expression_tile_radio">
                                  <input type="radio" name={`expression-intermediate-${this.state.expressionDetail.id}`} value={0} onChange={(event) => this.handleExtraHardcodeChange(event, -1, 'radio')} checked={this.state.expressionDetail.extraHardcodeList.filter(expressionIntermediate => expressionIntermediate.selected === true).length ? false: true} />
                                </div>
                                <div className="expression_tile_inputs">
                                  <div className="am-form-line-left">
                                    <label className="pro-label col-sm-4"><span>Intermediate Column</span></label>
                                    <Input className="text-box-display" name="intermediate_column" onChange={(event) => this.handleExtraHardcodeChange(event, -1, 'input', true)} value={this.state.expressionDetail.hardcode.intermediateColumn} />
                                  </div>
                                  <div className="am-form-line-left">
                                      <label className="pro-label col-sm-4"><span>Expression</span></label>
                                      <Input className="text-box-display" name="hardcode_condition" onChange={(event) => this.handleExtraHardcodeChange(event, -1)} value={this.state.expressionDetail.hardcode.columnHardcode} />
                                  </div>
                                </div>
                              </div>
                              {this.state.expressionDetail.extraHardcodeList.map((expressionIntermediate, key) => this.extraExpressionIntermediate(this.state.expressionDetail.id, expressionIntermediate, key)) }
                            </div>
                          }
                        </Dialog>
                      }
                    </div>
                  </div>
                }
                {node.id !== '' && typeof(this.props.dataItem['applyProcessFlow']) !== 'undefined' && this.props.dataItem['applyProcessFlow'] === true &&
                  <div className={expressionConflict === true ? "warning" : ""}>
                    <Button onClick={this.openDialog} > Apply Process Flow </Button>
                    {this.state.visible &&
                      <ApplyProcessFlow onClose={this.closeDialog} node={node} autoApplyProcessFlowStatus={true} processFlowList={this.props.dataItem['expressionDetail'].processFlowList} columnMapperNodeList={this.props.dataItem['expressionDetail'].columnMapperNodeList} handleApplyProcessSaveClick={this.handleApplyProcessSaveClick} expressionDetail={this.props.dataItem['expressionDetail']}/>
                    }
                  </div>
                }
                {(typeof(this.props.dataItem['combineExpressionList']) === 'undefined' || this.props.dataItem['targetVariable'] === '-' ) &&
                  <div>---</div>
                }
              </div>
          );
      }
}
