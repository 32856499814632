import React from 'react';
import axios from 'axios';
import $ from 'jquery';
import moment from 'moment';
import { Button } from './StandardButton';
import { PropTypes } from "prop-types";
import Dialog from 'react-dialog';
import { connect } from 'react-redux';
import { createSelector } from 'reselect';
import { setMessage } from '../../../../../actions/actionNotification';
import DataExplorer from '../../dataExplorer';
import ConstructiveOperation from './../../../UpdateBlock/ConstructiveOperation.js';
import restClient from '../../../../../restClient';
import MessageTypeConst from "../../../../MessageTypeConst";
import { CREATE } from '../../../../../restClient/types';
import "../../DataOperation.css";
import RichGridTable from './../../../RichGridTable';
import { NewColumnSpanCell, ColumnStatusCell } from "./../../common";

export const TableCompareButton = ({ tableManager, value, data, column, colIndex, rowIndex }) => {
    if (typeof(tableManager) === 'undefined') {
      return (null)
    } else {
      return (
          <div className='rgt-cell-inner' style={{display: 'flex', alignItems: 'center', overflow: 'hidden'}}>
              <TableCompareButtonClass dataItem={data} field={column.field} value={typeof(data[column.field]) !== 'undefined' ? data[column.field]: value} column={column} tableManager={tableManager}/>
          </div>
      )
    }
}


class BaseTableCompareButton extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
          visible: false,
          tableList: {
            id: 'version-difference-detail-left-grid',
            rows: [],
            resize: true,
            filterable: false,
            selectedField: "selected",
            isDynamicGrid: true,
            columnProperty: [
              { field: "rowId", show: false },
              { field: "column", title: "variable", width:"auto", cell: NewColumnSpanCell},
              { field: "label", title: "label", width:"auto"},
              { field: "type", width: "70px" },
              { field: "length", width: "60px" },
            ]
          },
          records: []
        };
        this.closeDialog = this.closeDialog.bind(this);
        this.openDialog = this.openDialog.bind(this);

      }

      fecthTableVersionDetail = (headers, params) => restClient(CREATE, 'manageVersion/getVersionDetails', params, headers);

      openDialog(){
        let tenantId = this.props.tenantId;
        if(typeof(this.props.dataItem["param"]) !== 'undefined') {
          $(".loader").show();
          axios.all([this.fecthTableVersionDetail({'Tenant-ID': tenantId}, {data: [this.props.dataItem["param"]]})])
              .then(axios.spread((response) => {
                if (typeof(response.data) !== 'undefined') {
                  let {tableList, rightTable} = this.state;
                  const leftTableTitle = response.data[0].blockName + " ("+this.props.dataItem["param"].versions[0]+")"
                  const rightTableTitle = response.data[0].blockName + " ("+this.props.dataItem["param"].versions[1]+")"
                  let records = [];
                  tableList.rows.length = 0;
                  if (typeof(response.data[0].commonColumns) !== 'undefined') {
                    response.data[0].commonColumns.forEach(removedColumn => {
                      let row = {
                        rowId: tableList.rows.length,
                        [leftTableTitle]: 1
                      }

                      if (this.props.dataItem["param"].versions.length > 1) {
                          row = Object.assign(row, {[rightTableTitle]: 1});
                      }

                      row = Object.assign(row, {column: removedColumn.column_header, label: removedColumn.column_label, type: removedColumn.column_data_type,length: removedColumn.column_data_length})
                      tableList.rows.push(row)
                    })
                  }

                  if (typeof(response.data[0].newlyAddedColumns) !== 'undefined') {
                    response.data[0].newlyAddedColumns.forEach(removedColumn => {
                      let row = {
                        rowId: tableList.rows.length,
                        [leftTableTitle]: this.props.dataItem["param"].versions[0] < this.props.dataItem["param"].versions[1] ? 3 : 2
                      }

                      if (this.props.dataItem["param"].versions.length > 1) {
                        row = Object.assign(row, {[rightTableTitle]: this.props.dataItem["param"].versions[0] > this.props.dataItem["param"].versions[1] ? 3 : 2});
                      }

                      row = Object.assign(row, {column: removedColumn.column_header, label: removedColumn.column_label, type: removedColumn.column_data_type, length: removedColumn.column_data_length})
                      tableList.rows.push(row)
                    })
                  }

                  if (typeof(response.data[0].removedColumns) !== 'undefined') {
                    response.data[0].removedColumns.forEach(removedColumn => {
                      let row = {
                        rowId: tableList.rows.length,
                        [leftTableTitle]: this.props.dataItem["param"].versions[0] < this.props.dataItem["param"].versions[1] ? 1 : 0
                      }

                      if (this.props.dataItem["param"].versions.length > 1) {
                        row = Object.assign(row, {[rightTableTitle]: this.props.dataItem["param"].versions[0] > this.props.dataItem["param"].versions[1] ? 1 : 0})
                      }

                      row = Object.assign(row, {column: removedColumn.column_header, label: removedColumn.column_label, type: removedColumn.column_data_type, length: removedColumn.column_data_length});
                      tableList.rows.push(row)
                    })
                  }

                  if (tableList.rows.length > 0) {
                    let columnList = []
                    for(var rowKey in tableList.rows[0]) {
                      columnList.push(rowKey)
                    }

                    tableList.columnProperty = tableList.columnProperty.filter(columnPropertyObject => columnList.indexOf(columnPropertyObject.field) > -1);

                    columnList.forEach(column => {
                      if (tableList.columnProperty.filter(columnPropertyObject => columnPropertyObject.field === column).length === 0 && column !== 'label') {
                        tableList.columnProperty.push({ field: column, width: (column.length *9 + "px"), cell: ColumnStatusCell},)
                      }
                    })
                  }

                  if (typeof(response.data[0].records) !== 'undefined' && response.data[0].records.length > 0) {
                    response.data[0].records.forEach(record => {
                      let tableCount = 0;
                      for(var key in record) {
                        records.push({
                          blockName: tableCount === 0 ? leftTableTitle : rightTableTitle,
                          ...JSON.parse(record[key])
                        })
                        tableCount++
                      }
                    })
                  }
                  this.setState({visible: true,tableList: tableList, records: records});
                }
                $(".loader").hide();
              })).catch(error => {
                $(".loader").hide();
                this.props.setMessage("Error occurred while fetching table version details", MessageTypeConst.ERROR_MESSAGE);
            });
        }
      }
      closeDialog() {
        this.setState({
            visible: false
          });
      }

      render() {
          return (
              <div className={"link-button table-compare-detail-grids"}>
                <Button onClick={this.openDialog} disabled={this.props.dataItem['error'] === 1}>{this.props.dataItem[this.props.field]}</Button>
                {this.state.visible &&
                  <Dialog title={'Table Version Compare'} width="80%" height="calc(100vh - 130px)" onClose={this.closeDialog}>
                    <div className="col-md-12">
                      <fieldset>
                        <div className="field_group">
                          <div className="row">
                            {this.state.records.map((record, index) => {
                              return (
                                <div className="col-md-12 m-b-5" key={`table-detail-${index}`}>
                                  <span className="badge badge-primary">{record.blockName}</span>
                                  {record.audit.modifiedAt !== null &&
                                    <span className="badge badge-secondary m-l-15">{moment.utc(record.audit.modifiedAt).format('lll')}</span>
                                  }
                                  {record.blockTag !== "" &&
                                    <span className="badge badge-success m-l-15">{record.blockTag}</span>
                                  }
                                </div>
                              )
                            })}
                          </div>
                          <RichGridTable {...this.state.tableList} />
                        </div>
                      </fieldset>
                    </div>
                  </Dialog>
                }

              </div>
          );
      }
}

const mapStateToProps = createSelector(
  state => state.processFlow.tenantId,
  (tenantId) => ({
    tenantId
  })
);

const mapActionsToProps = {
  setMessage: setMessage
}

BaseTableCompareButton.propTypes = {
  tenantId: PropTypes.string
}

Dialog.propTypes = {
    height: PropTypes.oneOfType([
        PropTypes.string
    ]),
    width: PropTypes.oneOfType([
        PropTypes.string
    ])
}
const TableCompareButtonClass = connect(mapStateToProps, mapActionsToProps)(BaseTableCompareButton);
