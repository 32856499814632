import React from "react";
import cloneDeep from 'lodash/cloneDeep';
import { PropTypes } from "prop-types";
import { DropDownList } from './../DataOperation/common/DropDown/StandardDropDownList';
import Dialog from 'react-dialog';
import { Container, Row, Col, Breadcrumb, BreadcrumbItem } from 'reactstrap';
import { Button } from './../DataOperation/common/Button/StandardButton';
import RichGridTable from './../RichGridTable';
import { MultiSelectDropDownList } from './../DataOperation/common';

function template() {
  let blockClassifierList = [];
  this.state.classifierList.forEach(classifier => {
    blockClassifierList.push(classifier.classifierName)
  })

  return (
    React.createElement('div', {className: "study-properties-main study-sample-filter"},
      React.createElement(Dialog, {title: "Sample Filter - "+this.state.node.name, width:"calc(100% - 10px)", height:"calc(100vh - 20px)", modal:true, onClose: (event) => this.props.closeEvent(event), buttons: this.state.tableList.rows.length > 0 ? [React.createElement(Button, {primary: true, onClick:this.handleSaveClick, key: "btn-save"}, "Save"),React.createElement(Button, {onClick:this.props.closeEvent, key: "btn-cancel"}, "Cancel")] : []},
        React.createElement(Row, {},
          React.createElement(Col, {md: "12"},
            React.createElement(Row, {},
              React.createElement(Col, {md: "3"},
                React.createElement('div', {className: "am-form-field"},
                  React.createElement('span', {}, 'Classifier List:'),
                  React.createElement(MultiSelectDropDownList, {className: "width-full", data: blockClassifierList, onChange: this.handleBlockClassifierSelectChange, value: this.state.selectedBlockClassifier}, null),
                )
              )
            )
          ),
          (this.state.tableList.rows.length > 0) ? (
            React.createElement(Col, {md: "12", className: "list-wrapper"},
              React.createElement(RichGridTable, {...this.state.tableList}, null)
            )
          ): (null)
        )
      )
    )
  )
}

Dialog.propTypes = {
    height: PropTypes.oneOfType([
        PropTypes.string
    ]),
    width: PropTypes.oneOfType([
        PropTypes.string
    ])
}
export default template;
