import React from "react";
import { DropDownList } from './../../common/DropDown/StandardDropDownList';
import { ComboBox } from './../../common/DropDown/StandardComboBox';
import { Input } from './../../common/InputBox/StandardInput';

function getGlobalValue(controlIndex){
  let controlValue = '';
  global.processBlockControlValues.forEach((control, index) => {
    if (control.controlId === controlIndex) {
      controlValue = global.processBlockControlValues[index].controlValue;

    }
  });
  return controlValue;
}

function saveGlobalValue(controlIndex, value){
  global.processBlockControlValues.forEach((control, index) => {
    if (control.controlId === controlIndex) {
      global.processBlockControlValues[index].controlValue = value;
    }
  });
}

function TwoTableTemplate() {
  const StartDateIndex = 4;
  //const WhereColumnIndex = 34;
  const RefNameIndex = 58;
  const RefDescriptionIndex = 59;
  const StudyIdIndex = 60;
  const SubjectIdIndex = 61;
  const GroupByIndex = 62;
  const StartExpressionIndex = 65;
  const StartPrefixIndex = 66;
  const StartRuleIndex = 67;
  const StartSuffixIndex = 68;
  const StopPrefixIndex = 71;
  const StopRuleIndex = 72;
  const StopSuffixIndex = 73;
  const TargetPrefixIndex = 74;
  const TargetRuleIndex = 75;
  const TargetSuffixIndex = 76;
  const WindowStartVariableIndex = 77;
  const WindowStopVariableIndex = 78;
  const WindowLabelVariableIndex = 79;
  const WindowTargetVariableIndex = 80;
  const LeftColumnArrayIndex = 53;
  var leftDateColumns = [];
  var rightNumColumns = [];
  var leftColumns = [];
  var rightColumns = [];


  if (typeof(this.state.tableList[0]) !== 'undefined') {
      if (this.state.tableList[0].rows.length > 0) {
        this.state.tableList[0].rows.forEach((row) => {
          leftColumns = [...leftColumns, row.column];
          if(row.type === 'DATE' || row.type === 'DATETIME') {
            leftDateColumns = [...leftDateColumns, row.column];
          }
        });
      saveGlobalValue(LeftColumnArrayIndex, JSON.stringify(leftColumns));
      //saving data
      saveGlobalValue(3, JSON.stringify(this.state.tableList[0].rows));
      }
  }

  if (typeof(this.state.tableList[1]) !== 'undefined') {
      if (this.state.tableList[1].rows.length > 0) {
        this.state.tableList[1].rows.forEach((row) => {
          rightColumns = [...rightColumns, row.column];
          if(row.type === 'NUM') {
            rightNumColumns = [...rightNumColumns, row.column];
          }
        });
      }
      //saving data
      saveGlobalValue(24, JSON.stringify(this.state.tableList[1].rows));
  }

  let startDate = getGlobalValue(StartDateIndex);
  //let whereColumn = getGlobalValue(WhereColumnIndex);
  let refName = getGlobalValue(RefNameIndex);
  let refDescription = getGlobalValue(RefDescriptionIndex);
  let studyId = getGlobalValue(StudyIdIndex);
  let subjectId =getGlobalValue(SubjectIdIndex);
  let groupBy = getGlobalValue(GroupByIndex);
  let startExpression = getGlobalValue(StartExpressionIndex);
  let startPrefix = getGlobalValue(StartPrefixIndex);
  let startRule = getGlobalValue(StartRuleIndex) !== "" ? getGlobalValue(StartRuleIndex) :"Autogenerated Number";
  let startSuffix = getGlobalValue(StartSuffixIndex);
  let stopPrefix = getGlobalValue(StopPrefixIndex);
  let stopRule = getGlobalValue(StopRuleIndex) !== "" ? getGlobalValue(StopRuleIndex) :"Autogenerated Number";;
  let stopSuffix = getGlobalValue(StopSuffixIndex);
  let targetPrefix = getGlobalValue(TargetPrefixIndex);
  let targetRule = getGlobalValue(TargetRuleIndex) !== "" ? getGlobalValue(TargetRuleIndex) :"Autogenerated Number";;
  let targetSuffix = getGlobalValue(TargetSuffixIndex);
  let windowStartVariable = getGlobalValue(WindowStartVariableIndex);
  let windowStopVariable = getGlobalValue(WindowStopVariableIndex);
  let windowLabelVariable = getGlobalValue(WindowLabelVariableIndex);
  let windowTargetVariable = getGlobalValue(WindowTargetVariableIndex);
  saveGlobalValue(StartRuleIndex, startRule);
  saveGlobalValue(StopRuleIndex, stopRule);
  saveGlobalValue(TargetRuleIndex, targetRule);

  var handleRefNameChange = (event)=>{
    refName = event.target.value;
    saveGlobalValue(RefNameIndex,event.target.value);
    this.setState({...this.state.tableList});
  }
  var handleRefDescriptionChange = (event)=>{
    refDescription = event.target.value;
    saveGlobalValue(RefDescriptionIndex,event.target.value);
    this.setState({...this.state.tableList});
  }
  var handleSubjectIdChange = (event)=>{
    subjectId = event.target.value === null ? "" : event.target.value;
    saveGlobalValue(SubjectIdIndex,subjectId);
    this.setState({...this.state.tableList});
  }
  var handleStudyIdChange = (event)=>{
    studyId = event.target.value === null ? "" : event.target.value;
    saveGlobalValue(StudyIdIndex,studyId);
    this.setState({...this.state.tableList});
  }
  var handleGroupByChange = (event)=>{
    groupBy = event.target.value === null ? "" : event.target.value;
    saveGlobalValue(GroupByIndex,groupBy);
    this.setState({...this.state.tableList});
  }
  var handleStartExpressionChange = (event)=>{
    startExpression = event.target.value;
    saveGlobalValue(StartExpressionIndex,startExpression);
    this.setState({...this.state.tableList});
  }
  var handleStartPrefixChange = (event)=>{
    startPrefix = event.target.value;
    saveGlobalValue(StartPrefixIndex,event.target.value);
    this.setState({...this.state.tableList});
  }
  var handleStartSuffixChange = (event) =>{
    startSuffix = event.target.value;
    saveGlobalValue(StartSuffixIndex,event.target.value);
    this.setState({...this.state.tableList});
  }
  var handleStartRuleChange = (event) => {
    startRule = event.target.value;
    saveGlobalValue(StartRuleIndex,event.target.value);
    this.setState({...this.state.tableList});
  }
  var handleStartDateChange = (event) =>{
    startDate = typeof(event.target.value) === 'undefined' || event.target.value === null ? "" : event.target.value;
    saveGlobalValue(StartDateIndex,startDate);
    this.setState({...this.state.tableList});
  }
  // var handleWhereColumnChange = (event) =>{
  //   whereColumn = typeof(event.target.value) === 'undefined' ? "" : event.target.value;
  //   saveGlobalValue(WhereColumnIndex,whereColumn);
  //   this.setState({...this.state.tableList});
  // }
  var handleStopPrefixChange = (event) =>{
    stopPrefix = event.target.value;
    saveGlobalValue(StopPrefixIndex,event.target.value);
    this.setState({...this.state.tableList});
  }
  var handleStopSuffixChange = (event) =>{
    stopSuffix = event.target.value;
    saveGlobalValue(StopSuffixIndex,event.target.value);
    this.setState({...this.state.tableList});
  }
  var handleStopRuleChange = (event) =>{
    stopRule = event.target.value;
    saveGlobalValue(StopRuleIndex,event.target.value);
    this.setState({...this.state.tableList});
  }
  var handleTargetPrefixChange = (event) =>{
    targetPrefix = event.target.value;
    saveGlobalValue(TargetPrefixIndex,event.target.value);
    this.setState({...this.state.tableList});
  }
  var handleTargetSuffixChange = (event) =>{
    targetSuffix = event.target.value;
    saveGlobalValue(TargetSuffixIndex,event.target.value);
    this.setState({...this.state.tableList});
  }
  var handleTargetRuleChange = (event) =>{
    targetRule = event.target.value;
    saveGlobalValue(TargetRuleIndex,event.target.value);
    this.setState({...this.state.tableList});
  }
  var handleWindowTargetVariableChange = (event) =>{
    windowTargetVariable = event.target.value === null ? "" : event.target.value;
    saveGlobalValue(WindowTargetVariableIndex,event.target.value);
    this.setState({...this.state.tableList});
  }
  var handleWindowStartVariableChange = (event) =>{
    windowStartVariable = event.target.value === null ? "" : event.target.value;
    saveGlobalValue(WindowStartVariableIndex,event.target.value);
    this.setState({...this.state.tableList});
  }
  var handleWindowStopVariableChange = (event) =>{
    windowStopVariable = event.target.value === null ? "" : event.target.value;
    saveGlobalValue(WindowStopVariableIndex,event.target.value);
    this.setState({...this.state.tableList});
  }
  var handleWindowLabelVariableChange = (event) =>{
    windowLabelVariable = event.target.value === null ? "" : event.target.value;
    saveGlobalValue(WindowLabelVariableIndex,event.target.value);
    this.setState({...this.state.tableList});
  }
  return (
    <div className="process-option reference">
      <div className="container-fluid">
        <div className="row">
          <div className="col-12">
            <div className="field_group">
              <div className="row">
                <div className="col-6">
                  <label className="am-form-field row m-b-0 width-full">
                    <span className="col-6">Reference Point Name:</span>
                    <Input className="width-full col-6 m-0" onChange={handleRefNameChange} value={refName} />
                  </label>
                </div>

                <div className="col-6">
                  <label className="am-form-field row m-b-0 width-full">
                    <span className="col-6">Reference Point Description:</span>
                    <Input className="width-full col-6 m-0" onChange={handleRefDescriptionChange} value={refDescription}/>
                  </label>
                </div>
              </div>
            </div>
          </div>
          <div className="col-6">
            <fieldset>
              <div className="field_group">
                <legend>Data Table</legend>
                <div className="container">
                  <div className="row">
                    <div className="filter-area am-form-inline col-12 m-0">
                      <label className="am-form-field">
                        <span className="col-6">Study Id:</span>
                        <ComboBox className="width-full m-0" data={leftColumns} onChange={handleStudyIdChange} value={studyId}/>
                      </label>
                      <label className="am-form-field">
                        <span className="col-6">Subject Id:</span>
                        <ComboBox className="width-full m-0" data={leftColumns} onChange={handleSubjectIdChange} value={subjectId}/>
                      </label>
                      <label className="am-form-field">
                        <span className="col-6">Group By Variable:</span>
                        <ComboBox className="width-full m-0" data={leftColumns} onChange={handleGroupByChange} value={groupBy}/>
                      </label>
                      <label className="am-form-field">
                        <span className="col-6"><b>Select Start column:</b></span>
                        <ComboBox className="width-full m-0" data={leftDateColumns}  value={startDate} onChange={handleStartDateChange} />
                      </label>
                      {
                      //   <label className="am-form-field">
                      //   <span className="col-6"><b>SQL Where clause:</b></span>
                      //   <input className="am-textbox" value={whereColumn} onChange={handleWhereColumnChange} />
                      // </label>
                      }
                      <label className="am-form-field">
                        <span className="col-6"><b>Reference Start Expression:</b></span>
                        <Input className="m-0" value={startExpression} onChange={handleStartExpressionChange}/>
                      </label>
                    </div>
                  </div>
                </div>
              </div>
            </fieldset>
          </div>
          <div className="col-6">
            <fieldset>
              <div className="field_group">
                <legend>Window Table</legend>
                <div className="container">
                  <div className="row">
                    <div className="filter-area am-form-inline col-12 m-0">
                      <label className="am-form-field">
                        <span className="col-6"><b>Reference Start Column:</b></span>
                        <ComboBox className="width-full m-0" data={rightNumColumns} value={windowStartVariable} onChange={handleWindowStartVariableChange}/>
                      </label>
                      <label className="am-form-field">
                        <span className="col-6"><b>Reference Stop Column:</b></span>
                        <ComboBox className="width-full m-0" data={rightNumColumns} value={windowStopVariable} onChange={handleWindowStopVariableChange}/>
                      </label>
                      <label className="am-form-field">
                        <span className="col-6"><b>Reference Label Column:</b></span>
                        <ComboBox className="width-full m-0" data={rightColumns} value={windowLabelVariable} onChange={handleWindowLabelVariableChange}/>
                      </label>
                      <label className="am-form-field">
                        <span className="col-6"><b>Reference Target Column:</b></span>
                        <ComboBox className="width-full m-0" data={rightColumns} value={windowTargetVariable} onChange={handleWindowTargetVariableChange}/>
                      </label>
                    </div>
                  </div>
                </div>
              </div>
            </fieldset>
          </div>
          <div className="col-12">
            <fieldset>
              <div className="field_group">
                <legend>Element Format</legend>
                <div className="">
                  <div className="row">
                    <div className="filter-area am-form-inline row">
                      <div className="col-6">
                        <label className="am-form-field">
                          <span className="col-6 m-0"><b>Start element format:</b></span>
                          <div className="col-6 p-0">
                            <Input className="width-full" value={startPrefix} onChange={handleStartPrefixChange}/>
                          </div>
                        </label>
                      </div>
                      <div className="col-3">
                        <DropDownList className="width-full" data={["Autogenerated Number", "Group Variable"]} onChange={handleStartRuleChange}
                        value={startRule}/>
                      </div>
                      <div className="col-3">
                        <Input className="width-full" value={startSuffix} onChange={handleStartSuffixChange}/>
                      </div>
                      <div className="col-6">
                        <label className="am-form-field">
                          <span className="col-6 m-0"><b>Stop element format:</b></span>
                          <div className="col-6 p-0">
                            <Input className="width-full" onChange={handleStopPrefixChange} value={stopPrefix}/>
                          </div>
                        </label>
                      </div>
                      <div className="col-3">
                        <DropDownList className="width-full" data={["Autogenerated Number", "Group Variable"]}
                        onChange={handleStopRuleChange} value={stopRule}/>
                      </div>
                      <div className="col-3">
                        <Input className="width-full" onChange={handleStopSuffixChange} value={stopSuffix}/>
                      </div>
                      <div className="col-6">
                        <label className="am-form-field">
                          <span className="col-6 m-0"><b>Target element format:</b></span>
                          <div className="col-6 p-0">
                            <Input className="width-full" onChange={handleTargetPrefixChange} value={targetPrefix}/>
                          </div>
                        </label>
                      </div>
                      <div className="col-3">
                        <DropDownList className="width-full" data={["Autogenerated Number", "Group Variable"]}
                        onChange={handleTargetRuleChange} value={targetRule} />
                      </div>
                      <div className="col-3">
                        <Input className="width-full" onChange={handleTargetSuffixChange} value={targetSuffix}/>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </fieldset>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TwoTableTemplate;
