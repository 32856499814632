import React from 'react';
import { DropDownList } from './StandardDropDownList';

export const DataOpsProcessVariableCell = ({ tableManager, value, data, column, colIndex, rowIndex }) => {
    if (typeof(tableManager) === 'undefined') {
      return (null)
    } else {
      return (
          <div className='rgt-cell-inner' style={{display: 'flex', alignItems: 'center', overflow: 'hidden'}}>
              <DataOpsProcessVariableCellClass dataItem={data} field={column.field} value={typeof(data[column.field]) !== 'undefined' ? data[column.field]: value} column={column} tableManager={tableManager}/>
          </div>
      )
    }
}

class DataOpsProcessVariableCellClass extends React.Component {
  handleChange(e) {
    this.props.dataItem['resultType'] = '';
    this.props.dataItem['variableLike'] = '';
    this.props.dataItem['processOption'] = '';
    this.props.dataItem['decimalOption'] = '';

    this.props.dataItem[this.props.field] = e.target.value;
    this.props.tableManager.rowsApi.setRows(this.props.tableManager.rowsApi.rows)
  }
  render() {
    var data = [];

    if (typeof(this.props.data) !== 'undefined') {
      data = this.props.data;
    } else if(global.inputGrid) {
      global.inputGrid.forEach((row) => {
        data = [...data, row.column+'-'+row.type+' ('+row.length+')']
      })
    }

    var disabled = false;
    if (this.props.dataItem['rowId'] === '') {
      disabled = true;
    }

    return (
        <>
          <DropDownList className="width-full" onChange={this.handleChange.bind(this)} data={data} value={this.props.dataItem[this.props.field]} disabled={disabled} />
        </>
      );
    }
}
