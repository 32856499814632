import React from 'react';
import { PropTypes } from "prop-types";
import Dialog from 'react-dialog';
import { Button } from './StandardButton';
import TextEditor from '../../../TextEditor';

Dialog.propTypes = {
    height: PropTypes.oneOfType([
        PropTypes.string
    ]),
    width: PropTypes.oneOfType([
        PropTypes.string
    ])
}

export const ExecuteAllLogButton = ({ tableManager, value, data, column, colIndex, rowIndex }) => {
    if (typeof(tableManager) === 'undefined') {
      return (null)
    } else {
      return (
          <div className='rgt-cell-inner' style={{display: 'flex', alignItems: 'center', overflow: 'hidden'}}>
              <ExecuteAllLogButtonClass dataItem={data} field={column.field} value={typeof(data[column.field]) !== 'undefined' ? data[column.field]: value} column={column} tableManager={tableManager}/>
          </div>
      )
    }
}

class ExecuteAllLogButtonClass extends React.Component {

    constructor(props) {
      super(props);
      this.state = {
        logData:'',
        visible: false
      };
      this.toggleDialog = this.toggleDialog.bind(this);

    }
    toggleDialog() {
      if(this.state.visible === true){
        this.setState({
          visible: !this.state.visible
        });
        return;
      } else{
        this.setState({
            visible: !this.state.visible
          });
      }
      var log = this.props.dataItem['log'];
      this.setState({logData:log})
    }

    render() {
        return (
            <div className="text-center width-full">
              <Button onClick={this.toggleDialog}> Log </Button>
                {this.state.visible &&
                    <Dialog className="data-properties" title={'Logs'} width="75%" height="auto" modal={true}  onClose={this.toggleDialog} buttons={[<Button primary={true} onClick={this.toggleDialog} key="btn-ok"> OK </Button>]}>
                <div>
                <div className="log_Editor execute_all">
                <TextEditor  options={{ lineNumbers: true, readOnly: true}} code={this.state.logData} />
              </div>

                </div>

                </Dialog>}
            </div>
        );
    }
}
