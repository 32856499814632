import React from 'react';
import { Button } from './StandardButton';
import { PropTypes } from "prop-types";
import Dialog from 'react-dialog';
import { ProcessOptionGrid } from "../gridOperation";
import { NewColumnNameCell } from "../InputBox/NewColumnNameCell";
import { SearchCriteriaCaseCell } from "../DropDown/SearchCriteriaCaseCell";
import { SearchCriteriaFindCell } from "../DropDown/SearchCriteriaFindCell";

Dialog.propTypes = {
    height: PropTypes.oneOfType([
        PropTypes.string
    ]),
    width: PropTypes.oneOfType([
        PropTypes.string
    ])
}
export const SearchCriteriaCell = ({ tableManager, value, data, column, colIndex, rowIndex }) => {
    if (typeof(tableManager) === 'undefined') {
      return (null)
    } else {
      return (
          <div className='rgt-cell-inner' style={{display: 'flex', alignItems: 'center', overflow: 'hidden'}}>
              <SearchCriteriaCellClass dataItem={data} field={column.field} value={typeof(data[column.field]) !== 'undefined' ? data[column.field]: value} column={column} tableManager={tableManager}/>
          </div>
      )
    }
}

class SearchCriteriaCellClass extends React.Component {
    constructor(props) {
      super(props);

      this.state = {
          visible: false,
          tableList:[]
      };

      this.toggleDialog = this.toggleDialog.bind(this);
      this.handleAddRowClick = this.handleAddRowClick.bind(this);
      this.handleRemoveRowClick = this.handleRemoveRowClick.bind(this);
      this.handleSaveGridData = this.handleSaveGridData.bind(this);
    }

    toggleDialog() {
      var rows = [{"rowId": 0,"columnNewId":0, "selected": false, "find": "", "replace": "", "occurence": "All", "charCase": "" }];
      var columnProperty = [
        { field: "rowId", show: false },
        { field: "columnNewId", show: false },
        { field: "find",width:"136px", cell: NewColumnNameCell },
        { field: "replace",width:"136px", cell: NewColumnNameCell },
        { field: "occurence",width:"136px", cell: NewColumnNameCell },
        { field: "charCase",width:"136px", cell: SearchCriteriaCaseCell}
      ];

      if (this.props.dataItem['seachPolicy'] === 'Non Printable') {
        rows = [{"rowId": 0, "columnNewId":0, "selected": false, "find": "", "replace": "" }];
        columnProperty = [
            { field: "rowId", show: false },
            { field: "columnNewId", show: false },
            { field: "find",width:"273px", cell: SearchCriteriaFindCell },
            { field: "replace",width:"273px", cell: NewColumnNameCell },
          ];
      }

      if (this.props.dataItem[this.props.field] !== '') {
        let data = JSON.parse(this.props.dataItem[this.props.field]);
        if(data.length !== 0){
          rows = data;
        }
      }

        this.setState({
            visible: !this.state.visible,
            tableList: [{
              id: 'data-ops-process-grid',
              rows: rows,
              height:"350",
              resize: true,
              filterable: false,
              selectedField: "selected",
              columnProperty: columnProperty
            }]
        });
    }

    handleAddRowClick(event) {
      var tableList = this.state.tableList;
      var newRowId = 0;

      if (tableList[0].rows.length > 0) {
        newRowId = tableList[0].rows[tableList[0].rows.length - 1].rowId + 1
      }

      var addRow = { "rowId": newRowId, "columnNewId": newRowId, "selected":false, "find": "", "replace": "", "occurence": "All", "charCase": "" };

      tableList[0].rows.push(addRow);

      this.setState({ tableList: tableList });
    }

    handleRemoveRowClick(event) {
      let tableList = this.state.tableList;
      const { rows } = this.state.tableList[0];
      if(typeof(rows) !== 'undefined') {
        tableList = this.removeTableRowsRecursiveElements(tableList)
      }

      this.setState({ tableList: tableList });
    }

    removeTableRowsRecursiveElements(tableList) {
      var index;
      for (index = 0; index < tableList[0].rows.length; index++) {
        if (tableList[0].rows[index].selected === true) {
          tableList[0].rows.splice(index, 1);
        }
      }

      for (index = 0; index < tableList[0].rows.length; index++) {
        if (tableList[0].rows[index].selected === true) {
          tableList = this.removeTableRowsRecursiveElements(tableList);
        }
      }

      return tableList;
    }

    handleSaveGridData(e) {
      this.props.dataItem[this.props.field] = JSON.stringify(this.state.tableList[0].rows);
      this.props.tableManager.rowsApi.setRows(this.props.tableManager.rowsApi.rows)

      this.toggleDialog();
    }

    render() {
        var disabled = true;
        if (this.props.dataItem['condition'] === 'Special Criteria') {
          disabled = false;
        }
        return (
            <div className="search-criteria">
              <Button primary={true} disabled={disabled} onClick={this.toggleDialog}>Search Criteria</Button>

                {this.state.visible && <Dialog title={"Search Criteria"} width="600px" height="auto" onClose={this.toggleDialog} buttons={[<Button primary={true} className="k-button" onClick={this.handleSaveGridData} key="btn-ok">Ok</Button>,<Button className="k-button" onClick={this.toggleDialog} key="btn-cancel">Cancel</Button>]}>
                        <div className="clearfix text-center width-full m_5">
                          <Button primary={true} onClick={this.handleAddRowClick}>Add</Button>
                          <Button primary={true} className="m-l-10" onClick={this.handleRemoveRowClick}>Delete</Button>
                        </div>

                        <div className="grid-content search-criteria-grid">
                            <ProcessOptionGrid tableList={this.state.tableList}  />
                        </div>
                    </Dialog>}
            </div>
        );
    }
}
