import React    from "react";
import axios from 'axios';
import { connect } from 'react-redux';
import { createSelector } from 'reselect';
import { PropTypes } from 'prop-types';
import $ from 'jquery';
import cloneDeep from 'lodash/cloneDeep';
import restClient from '../../../restClient';
import { CREATE, DELETE, UPDATE, GET, POST_PARAM_BODY } from '../../../restClient/types';
import * as filters from './NavigationBarMenuFilter';
import template from "./NavigationBarMenu.jsx";
import moment from 'moment';
import {SERVER_URL} from '../../../../src/config/index.js';

import { addProject, removeProject, updateProjectList } from '../../../actions/actionProject';
import { addStudy, removeStudy, removeStudyByProjectId, setStudyManager } from '../../../actions/actionStudy';
import { buildTreeViewStatus, setTreeViewData, reDisplayTree, refreshStudyNode } from '../../../actions/actionTreeView';
import { addProcessFlow, setTenantId, addImportTable, addProcessFlowTitle, updateProcessFlowBlockBySchemaName, removeImportTable, setActiveProcessFlow, setProcessFlowTable, updateProcessFlowSyncFlagByTabIndex, toggleRefreshToken, removeProcessFlow, updateProcessflowBlockRenderStatus, removeProcessFlowByProjectId, removeProcessFlowByStudyId, removeProcessFlowByProcessGroupId, setStudyId, setProjectId, removeALLProcessFlow, setJumpToBlockIdProcessId } from '../../../actions/actionProcessFlow';
import ExecuteAllTypeConst from '../DataOperation/ExecuteAll/ExecuteAllTypeConst';
import MessageTypeConst from "../../MessageTypeConst";
import {setMessage} from '../../../actions/actionNotification';
import ExportTypeConst from '../../ExportTypeConst';
import CopyType from '../../CopyType';
import ASSESSMENT_TYPE from "../Copying/AssessmentType";
import PermanentFlagEventType from '../../PermanentFlagEventType';
import CheckOutCheckinFalg from '../../CheckOutCheckInFlag';
import AccessControlConstants from './../../AccessControlConstants';
import TagContantType from "./../TagManagement/TagContantType";
import ConfirmBox from './../DataOperation/common/ConfirmBox';

var resetTreeView = '';
var sideBarEventTimeOutId = null;

export function manageTagInTreeView (classifier, projectId, children, toggled = false) {
  let cssClassName = 'manage-study-by-tag';

  return {
    name: classifier,
    cssClassName: cssClassName,
    iconClass: 'fa fa-tag',
    children: children,
    projectId: projectId,
    toggled: toggled,
    isTag: true
  }
}

export function manageClassifierInTreeView (classifier, processFlowGroup, study, toggled = false) {
  return {
    name: classifier.classifierName,
    cssClassName: 'manage-study-process-flow-group',
    iconClass: 'fa fa-object-ungroup',
    children: processFlowGroup,
    projectId: study.projectId,
    studyId: study.studyId,
    schemaName: study.schemaName,
    classifierId: classifier.classifierId,
    toggled: toggled
  }
}

export function manageProceeFlowGroupInTreeView (group, processFlow, study, toggled = false) {
  return {
    name: group.processGroupName,
    cssClassName: 'manage-study-process-flow',
    iconClass: 'fa fa-object-group',
    children: processFlow,
    projectId: study.projectId,
    studyId: group.studyId,
    schemaName: study.schemaName,
    processGroupId: group.processGroupId,
    classifierId: group.classifier,
    toggled: toggled,
    isCopy:group.isCopy,
    audit: group.audit,
    sortOrder: group.sortOrder
  }
}

export function manageProcessInTreeView(process, projectId, schemaName, studyName)  {
  return {
    name: process.processName,
    cssClassName: 'manage-study-process',
    iconClass: process.processSyncFlag === 2 ? 'fa fa-exclamation-triangle yellow' :
    process.processSyncFlag === 3 ?  'fa fa-exclamation-circle red' : 'fa fa-microchip',
    isCopy:process.isCopy,
    process: process,
    projectId: projectId,
    schemaName: schemaName,
    studyName: studyName,
    selected: false,
    activeProcess: false,
    isCut: 0
  }
}

export function manageStudyInTreeView(study, studyElements, toggled = false) {
  return {
    name: study.studyName,
    cssClassName: 'manage-study-property',
    iconClass: 'fa fa-folder-open',
    study: study,
    children: studyElements,
    toggled: toggled,
    isCopy:study.isCopy,
  }
}

export function manageStudyElementsInTreeView (study, sourceTable, metaTable, processFlowGroup, documents) {
  return [
    {
      name: 'Documents',
      cssClassName: 'manage-study-documents',
      iconClass: 'fa fa-file',
      children: documents,
      studyId: study.studyId,
      schemaName: study.schemaName,
      projectId:study.projectId,
    },
    {
      name: 'Source Table',
      cssClassName: 'manage-study-source-table',
      iconClass: 'fa fa-table',
      children: sourceTable,
      studyId: study.studyId,
      schemaName: study.schemaName,
      projectId:study.projectId,
    },
    {
      name: 'Meta Table',
      cssClassName: 'manage-study-meta-table',
      iconClass: 'fa fa-database',
      children: metaTable,
      studyId: study.studyId,
      schemaName: study.schemaName,
      projectId:study.projectId,
    },
    {
      name: 'ProcessFlows',
      cssClassName: 'manage-study-classifier-group',
      iconClass: 'icon-shuffle p-r-2',
      children: processFlowGroup,
      studyId: study.studyId,
      schemaName: study.schemaName,
      toggled: true
    },
  ]
}

class NavigationBarMenu extends React.Component {
  constructor(props) {
    super(props);
    this.RENAME_STUDY_EVENT = 1;
    this.ADD_PROJECT_EVENT = 2;
    this.RENAME_PROJECT_EVENT = 3;
    this.ADD_PROCESS_GROUP_EVENT = 4;
    this.RENAME_PROCESS_GROUP_EVENT = 5;
    this.ADD_PROCESS_FLOW_EVENT = 6;
    this.RENAME_PROCESS_FLOW_EVENT = 7;
    let jump = '';
    if (typeof(props.location) !== 'undefined' && typeof(props.location.pathname) !== 'undefined' && props.location.pathname.indexOf('jump=') > -1) {
      jump = window.atob(props.location.pathname.replace('/jump=', ''));
      if (jump !== '') {
        jump = JSON.parse(jump)
      }
    }
    this.state = {
      treeView: {
              name: 'Root',
              cssClassName: 'manage-project',
              iconClass: 'fa fa-medkit',
              loading: true,
              toggled: true,
              children: []
          },
        resetTreeView: {},
        importDialogStatus: false,
        executeAllDialogStatus:false,
        importFiles:[],
        visibleAddStudy: false,
        visiblePropertyStudy: false,
        triggerNode: {},
        study: {
          studyName: '',
          identifier1: 'STUDYID',
          identifier2: 'USUBJID',
          identifier3: '',
          classifier: '',
          description: '',
          projectId: 0,
          isCopy:0
        },
        studyProperty: {
          studyName: '',
          identifier1: '',
          identifier2: '',
          identifier3: '',
          classifier: '',
          description: '',
          importDirectory: '',
          projectName: '',
          studyId: ''
        },
        addStudyButtonDisable: false,
        importDocumentDialogStatus: false,
        disabledOkButton : true,
        executeAllType:0,
        executeAllDataNode:'',
        showLogPopUp: false,
        logData:'',
        disabledCloseButton:false,
        addRenameTitlePopupVisible:false,
        addRenameTitleEventRaiseBy:0,
        addRenameDiableOKButton:false,
        title:'',
        classifier: '',
        jobs:[],
        disabledExecuteButton:true,
        processIdList:[],
        showVersion:false,
        showRootVersion:false,
        showProcessVersion:false,
        exportNode:false,
        copySource:{projectId:0,studyId:0, groupId:0,processId:0, sourceTenantId:0, copyType:0, sourceName:"", description: ""},
        copyTarget:{projectId:0,studyId:0, groupId:0,processId:0, targetTenantId:0, copyType:0},
        showCopyDialog:false,
        showCopyAssessmentDialog:false,
        assessmentNode:{
          copyAssesmentType:0,
          studyId:0,
          projectId:0,
          groupId:0,
          schemaName:'',
          name:''
        },
        flagPermanentDialog:false,
        permanentTag:'',
        permanentMessage:'',
        permanentFlagType:0,
        visibleImportTableFromServerDialogStatus: false,
        importTableType: null,
        selectFileFromServer: [],
        visibleImportStudyDef:false,
        tagTreeViewStatus: true,
        displayTreeView: {},
        groupClassifierList: [],
        selectedGroupClassifier: {},
        accessControlDialog: false,
        createTaskDialogStatus: false,
        selected : 0,
        readOnlyAccess: false,
        createMockTLFSpecsDialog: false,
        processFlowSortingDialog: false,
        executeAllNode: {
          dialogStatus: false,
          node: '',
          nodeType: '',
          classifier: '',
          schemaName: ''
        },
        executeAllInteractiveStatus: false,
        processFlowGroupSortingDialog: false,
        sampleFilterDialog: false,
        searchReplaceDialog: false,
        jump: jump
    }

    this.updateJobs = this.updateJobs.bind(this);
    this.updateTreeViewData = this.updateTreeViewData.bind(this);
    this.onToggle = this.onToggle.bind(this);
    this.toggleDialogAddStudy = this.toggleDialogAddStudy.bind(this);
    this.handleStudyNameChange = this.handleStudyNameChange.bind(this);
    this.enterKeyPressForAddStudy = this.enterKeyPressForAddStudy.bind(this);
    this.handleIdentifierThreeChange = this.handleIdentifierThreeChange.bind(this);
    this.handleSaveStudy = this.handleSaveStudy.bind(this);
    this.handleAddProcessFlowGroup = this.handleAddProcessFlowGroup.bind(this);
    this.handleAddProcessFlow = this.handleAddProcessFlow.bind(this);
    this.handleRemoveProject = this.handleRemoveProject.bind(this);
    this.handleRemoveStudy = this.handleRemoveStudy.bind(this);
    this.handleRemoveProcessFlowGroup = this.handleRemoveProcessFlowGroup.bind(this);
    this.handleRemoveProcess = this.handleRemoveProcess.bind(this);
    this.handleRenameProject = this.handleRenameProject.bind(this);
    this.handleRenameStudy = this.handleRenameStudy.bind(this);
    this.handleRenameProcessFlowGroup = this.handleRenameProcessFlowGroup.bind(this);
    this.handleRenameProcess = this.handleRenameProcess.bind(this);
    this.handleAddMetaTable = this.handleAddMetaTable.bind(this);
    this.handleEditMetaTable = this.handleEditMetaTable.bind(this);
    this.generateTree = this.generateTree.bind(this);
    this.buildTreeView = this.buildTreeView.bind(this);
    this.handleManageStudyProperty = this.handleManageStudyProperty.bind(this);
    this.toggleDialogStudyProperty = this.toggleDialogStudyProperty.bind(this);
    this.handleImportFilesTableFilesChange = this.handleImportFilesTableFilesChange.bind(this);
    this.toggleImportDialog = this.toggleImportDialog.bind(this);
    this.handleImportSourceTable = this.handleImportSourceTable.bind(this);
    this.fileUploadProcess = this.fileUploadProcess.bind(this);
    this.handleImportMetaTable = this.handleImportMetaTable.bind(this);
    this.handleRemoveTable = this.handleRemoveTable.bind(this);
    this.handleExecuteAll = this.handleExecuteAll.bind(this);
    this.closeExecuteAllDialog = this.closeExecuteAllDialog.bind(this);
    this.handleImportDocuments = this.handleImportDocuments.bind(this);
    this.handleImportDocumentsFilesChange = this.handleImportDocumentsFilesChange.bind(this);
    this.toggleImportDocumentDialog = this.toggleImportDocumentDialog.bind(this);
    this.documentUpload = this.documentUpload.bind(this);
    this.documentUploadProcess = this.documentUploadProcess.bind(this);
    this.handleExecuteAllWarning = this.handleExecuteAllWarning.bind(this);
    this.toggleLogPopUp = this.toggleLogPopUp.bind(this);
    this.showLog = this.showLog.bind(this);
    this.executeJobs = this.executeJobs.bind(this);
    this.executeAllRef = null;
    this.handleDeleteDocuments = this.handleDeleteDocuments.bind(this);
    this.updateFlowIconInTreeView = this.updateFlowIconInTreeView.bind(this);
    this.toggleAddRenameTitleDialog = this.toggleAddRenameTitleDialog.bind(this);
    this.handleAddRenameTitleChange = this.handleAddRenameTitleChange.bind(this);
    this.handleAddRenameClassifierChange = this.handleAddRenameClassifierChange.bind(this);
    this.toggleRenameStudyDialog = this.toggleRenameStudyDialog.bind(this);
    this.toggleAddProjectDialog = this.toggleAddProjectDialog.bind(this);
    this.toggleRenameProjectDialog = this.toggleRenameProjectDialog.bind(this);
    this.handleAddRenameOKButton = this.handleAddRenameOKButton.bind(this);
    this.enterKeyPressForAddRenameTitle = this.enterKeyPressForAddRenameTitle.bind(this);
    this.selectedInput=this.selectedInput.bind(this);
    this.toggleAddProcessGroupDialog = this.toggleAddProcessGroupDialog.bind(this);
    this.toggleAddProcessFlowDialog = this.toggleAddProcessFlowDialog.bind(this);
    this.toggleRenameProcessGroupDialog = this.toggleRenameProcessGroupDialog.bind(this);
    this.toggleRenameProcessFlowDialog = this.toggleRenameProcessFlowDialog.bind(this);
    this.handleRemoveProcessFlowApi = this.handleRemoveProcessFlowApi.bind(this);
    this.handleGroupExecuteAll = this.handleGroupExecuteAll.bind(this);
    this.handleManageVersion = this.handleManageVersion.bind(this);
    this.closeManageVersionDialog = this.closeManageVersionDialog.bind(this);
    this.handleRootManageVersion = this.handleRootManageVersion.bind(this);
    this.handleProcessFlowManageVersion = this.handleProcessFlowManageVersion.bind(this);
    this.closeRootManageVersionDialog = this.closeRootManageVersionDialog.bind(this);
    this.closeProcessFlowManageVersionDialog = this.closeProcessFlowManageVersionDialog.bind(this);
    this.getTreeViewData = this.getTreeViewData.bind(this);
    this.handleExportProcessFlow = this.handleExportProcessFlow.bind(this);
    this.handleExportProcessGroup = this.handleExportProcessGroup.bind(this);
    this.handleExportStudy = this.handleExportStudy.bind(this);
    this.handleExportStudyDef = this.handleExportStudyDef.bind(this);
    this.handleExport = this.handleExport.bind(this);
    this.toggleExportNode = this.toggleExportNode.bind(this);
    this.closeCopyDialog = this.closeCopyDialog.bind(this);
    this.handleCopyProcessFlow = this.handleCopyProcessFlow.bind(this);
    this.handleCopyProcessFlowGroup = this.handleCopyProcessFlowGroup.bind(this);
    this.handleCopyProcessFlowLink = this.handleCopyProcessFlowLink.bind(this);
    this.handleCopyStudy = this.handleCopyStudy.bind(this);
    this.handlePasteOnProject = this.handlePasteOnProject.bind(this);
    this.handlePasteOnStudy = this.handlePasteOnStudy.bind(this);
    this.handlePasteOnGroup = this.handlePasteOnGroup.bind(this);
    this.handleFlagPermanent = this.handleFlagPermanent.bind(this);
    this.closeFlagPermanentDialog = this.closeFlagPermanentDialog.bind(this);
    this.handlePermanentTagChange = this.handlePermanentTagChange.bind(this);
    this.enterKeyPressForPermanentFlag = this.enterKeyPressForPermanentFlag.bind(this);
    this.handlePermanentFlagOKButton = this.handlePermanentFlagOKButton.bind(this);
    this.toggleDialogImportSourceTableFromServer = this.toggleDialogImportSourceTableFromServer.bind(this);
    this.handleSelectFileFromServer = this.handleSelectFileFromServer.bind(this);
    this.handleImportSelectedFileFromServerClick = this.handleImportSelectedFileFromServerClick.bind(this);
    this.handleImportStudyDef = this.handleImportStudyDef.bind(this);
    this.closeImportStudyDef = this.closeImportStudyDef.bind(this);
    this.handleTreeviewModeChangeEvent = this.handleTreeviewModeChangeEvent.bind(this);
    this.handleVersionDifference = this.handleVersionDifference.bind(this);
    this.handleContextMenuAccessControl = this.handleContextMenuAccessControl.bind(this);
    this.appendStudyDetailInTreeView = this.appendStudyDetailInTreeView.bind(this);
    this.openStudyManagerDetail = this.openStudyManagerDetail.bind(this);
    this.handleCreateTask = this.handleCreateTask.bind(this);
    this.handleDownloadWithTagClick = this.handleDownloadWithTagClick.bind(this);
    this.handleCreateMockTLFSpecs = this.handleCreateMockTLFSpecs.bind(this);
    this.openSyncOnStudyDialog = this.openSyncOnStudyDialog.bind(this);
    this.handleProcessFlowSorting = this.handleProcessFlowSorting.bind(this);
    this.handleCutProcessFlow = this.handleCutProcessFlow.bind(this);
    this.handleProcessFlowCutToPaste = this.handleProcessFlowCutToPaste.bind(this);
    this.resetProcessFlowCutEvent = this.resetProcessFlowCutEvent.bind(this);
    this.handleExecuteAllAction = this.handleExecuteAllAction.bind(this);
    this.handleRefreshStudy = this.handleRefreshStudy.bind(this);
    this.handleProcessFlowGroupSorting = this.handleProcessFlowGroupSorting.bind(this);
    this.handleSampleFilterStudy = this.handleSampleFilterStudy.bind(this);
    this.updateProcessFlowAndProcessBlockStatus = this.updateProcessFlowAndProcessBlockStatus.bind(this);
    this.handleSearchReplaceStudy = this.handleSearchReplaceStudy.bind(this);
    this.openStudyPropertyDetail = this.openStudyPropertyDetail.bind(this);
    this.openStudyJumpAction = this.openStudyJumpAction.bind(this);
  }

  handleTreeviewModeChangeEvent = (event) => {
    this.setState({tagTreeViewStatus: !this.state.tagTreeViewStatus, displayTreeView: this.state.treeView}, () =>{
      this.updateTreeViewData(this.state.treeView)
    })
  }

  updateJobs(jobs, processIdList){
    let disabledExecuteButton = false;
    if(jobs.length === 0){
      disabledExecuteButton = true;
    }

    if(typeof(processIdList) === "undefined" || processIdList === null){
      processIdList = [];
    }
    this.setState({jobs:jobs, disabledExecuteButton:disabledExecuteButton, processIdList:processIdList});
  }

  fetchTreeView = (params, headers) => restClient(CREATE, 'treeView_v2', params, headers);

  fetchFlowProjectList = (params, headers) => restClient(CREATE, 'treeview', params, headers);

  executeAllCloseProcessFlowList = (params, headers) => restClient(CREATE, 'executeAll/close', params, headers);

  saveTagProperty = (params, headers) => restClient(CREATE,'tag/saveUpdate', params,headers);

  selectedInput(event){
    $('#'+event.target.id).select();
  }

  getTreeViewData(studyListLoad, index, sidebarData) {
    if (typeof(studyListLoad[index]) !== 'undefined')  {
        axios.all([this.fetchFlowProjectList({data: studyListLoad[index]},{'Tenant-ID': studyListLoad[index].schemaName})])
        .then(axios.spread((response) => {
            sidebarData.push(response.data)
            index++;
            if (typeof(studyListLoad[index]) !== 'undefined') {
                sidebarData = this.getTreeViewData(studyListLoad, index, sidebarData)
            } else {
              this.generateTree(sidebarData);
            }
            return sidebarData;
        })).catch(error => {
          this.props.setMessage("Error occurred while fetching study data", MessageTypeConst.ERROR_MESSAGE);
        })
    }
  }

  generateTree(sidebarData) {
    let treeView = this.state.treeView;
    let toggled = false;
    let studyManagerDetail = '';
    let studyDetailList = [];
    treeView.children.forEach(project => {
      project.children.forEach(study => {
        if (study.study.schemaName === this.props.studyManagerSchemaName) {
            studyManagerDetail = study;
        }

        if (typeof(study.study.processFlowGroup) !== 'undefined') {
            studyDetailList.push(study)
        }
      })
    })
    treeView.children.splice(0);
    //let treeView = { name: "Root", cssClassName: 'manage-project', iconClass: 'fa fa-medkit', toggled: true, children: []};
    var projectList = this.props.projectList;
    var treeData = [];

    projectList.forEach((project) => {
        treeData.push(Object.assign({}, project, {children: []}));
        toggled = true;
    })

    sidebarData.forEach((study) => {
      var project = treeData.filter( project => project.projectId === study.projectId)
      if (project.length > 0) {
        project[0].children.push(study)
      }
    });

    treeData.forEach((project, index) => {
      var studyList = []

      project.children.forEach((study, studyIndex) => {
        let studyDetail = manageStudyInTreeView(study, [])
        if (this.props.studyManagerSchemaName !== '' && studyManagerDetail !== '' && study.schemaName === this.props.studyManagerSchemaName) {
          studyDetail = {
            ...studyDetail,
            ...studyManagerDetail
          }
        }
        studyDetailList.filter(fetchStudyDetail => fetchStudyDetail.study.schemaName === study.schemaName).forEach(fetchStudyDetail => {
          studyDetail = {
            ...studyDetail,
            ...fetchStudyDetail
          }
        })

        if (typeof(studyDetail.study) !== 'undefined' && this.state.jump !== '' && typeof(this.state.jump.p) !== 'undefined' && typeof(this.state.jump.s) !== 'undefined' && this.state.jump.p === project.projectId && this.state.jump.s === studyDetail.study.studyId) {
          studyDetail.toggled = true
        }
        studyList.push(studyDetail);
      })
      treeView.children.push(this.manageProjectInTreeView(project, studyList, this.state.jump !== '' && typeof(this.state.jump.p) !== 'undefined' && this.state.jump.p === project.projectId));
    })

    treeView.loading = false;
    treeView.toggled = toggled;
    this.updateTreeViewData(treeView);
  }

  openStudyManagerDetail = (schemaName, isVersionDifference = false) => {
    this.props.setTenantId(schemaName);

    if (isVersionDifference === true) {
      if (this.props.location.pathname !== '/version-difference') {
        this.props.history.push("/version-difference");
      }
    } else {
      this.props.removeALLProcessFlow();
      this.props.setStudyManager(schemaName)
      if (this.props.location.pathname !== '/study-manager') {
        this.props.history.push("/study-manager");
      }
    }
  }

  appendStudyDetailInTreeView = (study, isStudyManager = false, isVersionDifference = false, isSyncOnStudy = false, isStudyProperty = false, isJump = false) => {
    let that = this;
    if (typeof(study) !== 'undefined')  {
      if (isVersionDifference === true || isSyncOnStudy === true || isStudyProperty === true) {
        $(".loader").show();
      }
      axios.all([this.fetchFlowProjectList({data: study},{'Tenant-ID': study.schemaName})])
           .then(axios.spread((response) => {
             if (typeof(response.data) !== 'undefined') {
               let treeView = that.state.treeView;
               const study = response.data

               treeView.children.filter(project => project.projectId === response.data.projectId).map(project => {
                 project.children.filter(blankStudy => blankStudy.study.schemaName === response.data.schemaName).map(blankStudy => {
                   let sourceTable = [];
                   let groupClassifier = []
                   let metaTable = [];
                   let documents = [];

                   if (typeof(study.studyDocuments) !== 'undefined' && study.studyDocuments !== null) {
                     study.studyDocuments.forEach((document) => {
                       documents.push({
                         name: document.filename,
                         cssClassName: 'manage-study-edit-documents',
                         schemaName: study.schemaName,
                         studyId:study.studyId,
                         projectId:project.projectId,
                         fileId: document.fileId,
                         path: document.filepath,
                         tableType: 2
                       })
                     })
                   }

                   if (typeof(study.sourceTable) !== 'undefined' && study.sourceTable !== null) {
                     study.sourceTable.forEach((table) => {
                       sourceTable.push({
                         name: table.srcTableName,
                         cssClassName: 'manage-study-edit-source-table',
                         schemaName: study.schemaName,
                         studyId:study.studyId,
                         projectId:project.projectId,
                         tag:'open-data-viewer',
                         tableName:table.tableName,
                         tableType: 0
                       })
                     })
                   }

                   if (typeof(study.metaTable) !== 'undefined' && study.metaTable !== null) {
                     study.metaTable.forEach((table) => {
                       metaTable.push({
                         name: table.srcTableName,
                         cssClassName: 'manage-study-edit-meta-table',
                         schemaName: study.schemaName,
                         studyId:study.studyId,
                         projectId:project.projectId,
                         tag:'open-data-viewer',
                         tableName:table.tableName,
                         tableType: 1
                       })
                     })
                   }

                   if (typeof(study.groupClassifier) !== 'undefined' && study.groupClassifier !== null) {
                     study.groupClassifier.forEach((classifier) => {
                       let processFlowGroup = [];
                       if (typeof(study.processFlowGroup) !== 'undefined' && study.processFlowGroup !== null) {
                         study.processFlowGroup.filter(group => group.classifier === classifier.classifierId).forEach((group) => {
                           var processFlow = [];

                           if (typeof(group.processFlow) !== 'undefined' && group.processFlow !== null) {
                             group.processFlow.forEach((process) => {
                               processFlow.push(manageProcessInTreeView(process, project.projectId, study.schemaName, study.studyName));
                             })
                           }
                           processFlowGroup.push(manageProceeFlowGroupInTreeView(group, processFlow, study));
                         })
                       }

                       groupClassifier.push(manageClassifierInTreeView(classifier, processFlowGroup, study))
                     })
                   }

                   blankStudy.children.push(...manageStudyElementsInTreeView(study, sourceTable, metaTable, groupClassifier, documents));
                   blankStudy.loading = false
                   blankStudy.study = study
                   return blankStudy
                 })
                 return project
               })

               that.updateTreeViewData(treeView);

               if (isStudyManager === true || isVersionDifference === true) {
                 this.openStudyManagerDetail(study.schemaName, isVersionDifference);
               } else if (isSyncOnStudy === true) {
                 $(".loader").hide();
                 this.openSyncOnStudyDialog(study);
               } else if (isStudyProperty === true) {
                 $(".loader").hide();
                 this.openStudyPropertyDetail(null, study);
               } else if (isJump === true) {
                 this.openStudyJumpAction(study);
               } else {
                 $(".loader").hide();
               }
             }

           })).catch(error => {
             $(".loader").hide();
             this.props.setMessage("Error occurred while fetching study data", MessageTypeConst.ERROR_MESSAGE);
           })
      }
  }

  openStudyJumpAction = (study) => {
    let {jump, treeView} = this.state
    if (jump !== '') {
      treeView.children.filter(project => project.projectId === jump.p).map(project => {
        project.children.filter(study => study.study.studyId === jump.s).map(study => {
          study.children.filter(studyElement => studyElement.name === 'ProcessFlows').map(studyElement => {
            studyElement.children.map(classifier => {
              classifier.children.map(processFlowGroup => {
                processFlowGroup.children.filter(processFlow => processFlow.process.processId === jump.pf).map(processFlow => {
                  processFlowGroup.toggled = true
                  classifier.toggled = true
                  classifier.toggled = true
                  processFlow.activeProcess = true;

                  this.props.addProcessFlow(processFlow);
                  window.location.hash= 'process-flow-list'

                  if (typeof(jump.b) !== 'undefined' && jump.b > 0) {
                    this.props.setJumpToBlockIdProcessId(jump.b, jump.pf)
                  }

                  return processFlow
                })
                return processFlowGroup
              })
              return classifier
            })
            return studyElement
          })
          return study
        })
        return project
      })
      $(".loader").hide();
      this.updateTreeViewData(treeView);
    }

  }

  manageProjectInTreeView = (project, studyList, toggled = false) => {
    return {
      name: project.projectName,
      cssClassName: 'manage-study',
      iconClass: 'fa fa-briefcase',
      projectId: project.projectId,
      children:studyList,
      toggled: toggled
    }
  }

  handleManageVersion(event, data){
    this.props.setTenantId(data.node.schemaName);
    this.setState({showVersion:true, triggerNode: data.node});
  }

  closeManageVersionDialog(){
    this.setState({showVersion:false});
  }

  handleRootManageVersion(event, data){
    this.props.setTenantId(data.node.schemaName);
    this.setState({showRootVersion:true, triggerNode: data.node});
  }

  handleProcessFlowManageVersion(event, data){
    this.props.setTenantId(data.node.schemaName);
    this.setState({showProcessVersion:true, triggerNode: data.node});
  }

  closeRootManageVersionDialog(){
    this.setState({showRootVersion:false});
  }

  closeProcessFlowManageVersionDialog(){
    this.setState({showProcessVersion:false});
  }

  componentDidMount() {
    this.buildTreeView();
  }

  buildTreeView() {
    let treeView = this.state.treeView;
    let projectList = this.props.projectList;
    let studyList = this.props.studyList;
    var loading = false;
    var toggled = false;

    if (studyList.length > 0 && false) {
        loading = true;
        toggled = true;
        this.getTreeViewData(studyList, 0, []);
    } else {
      if (projectList.length > 0 ) {
        this.generateTree(studyList);
        /*projectList.forEach((project) =>{
          treeView.children.push(Object.assign({}, {name: project.projectName,cssClassName: 'manage-study',iconClass: 'fa fa-briefcase',projectId: project.projectId,children: []}));
        });

        toggled = true;*/
      } else {
        treeView.toggled = toggled;
        treeView.loading = loading;

        treeView.children.splice(0);
        this.updateTreeViewData(treeView);
      }
    }
  }

  updateTreeViewData(treeView) {
    this.setState({ treeView: treeView, displayTreeView: this.displayTreeView(treeView) });
    this.props.setTreeViewData(treeView);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (JSON.stringify(nextProps) !== '') {
      if (typeof(this.state.treeView.children) !== 'undefined') {
        this.state.treeView.children.forEach((project) => {
          if (typeof(project.children) !== 'undefined') {
            project.children.forEach((study) => {
              if (typeof(study.children) !== 'undefined') {
                study.children.forEach((studyElement) => {
                  if (studyElement.name === 'ProcessFlows') {
                    if (typeof(studyElement.children) !== 'undefined') {
                      studyElement.children.forEach((classifier) => {
                        classifier.children.forEach((processFlowGroup) => {
                          if (typeof(processFlowGroup.children) !== 'undefined') {
                            processFlowGroup.children.map((process) => {
                              var processFlowActive = this.props.processFlowList.filter( activeProcessFlow => activeProcessFlow.projectId === project.projectId && activeProcessFlow.process.studyId === study.study.studyId && activeProcessFlow.process.processId === process.process.processId );
                              process.activeProcess = processFlowActive.length > 0 ? true : false;

                              return process;
                            })
                          }
                        })
                      })
                    }
                  }
                })
              }
            })
          }
        });
      }

      if (resetTreeView !== '') {
          resetTreeView = this.props.treeView.data;
      }
    }

    if(JSON.stringify(nextProps) !== '' && typeof(nextProps.treeView) !== 'undefined' && nextProps.treeView.build) {

      this.props.projectList.splice(0);
      this.props.projectList.splice(0, 0, ...nextProps.projectList);

      this.props.studyList.splice(0);
      this.props.studyList.splice(0, 0, ...nextProps.studyList);

      this.buildTreeView();
      this.props.buildTreeViewStatus(false);
    }

    if(JSON.stringify(nextProps) !== '' && typeof(nextProps.treeView) !== 'undefined' && nextProps.treeView.reDisplay) {
      this.props.reDisplayTree(false)
      this.updateTreeViewData(this.props.treeView.data);
    }

    if (typeof(this.state.treeView) !== 'undefined') {
      //this.props.treeView.data = Object.assign({}, this.state.treeView);
    }

    if (JSON.stringify(nextProps) !== '' && typeof(nextProps.treeView) !== 'undefined' && typeof(nextProps.treeView.refreshStudyNodeStatus) !== 'undefined' && nextProps.treeView.refreshStudyNodeStatus === true) {
      this.props.refreshStudyNode(false);
      if (nextProps.tenantId !== '') {
        this.state.treeView.children.forEach(project => {
          project.children.filter(study => study.study.schemaName === nextProps.tenantId).forEach(study => {
            this.handleRefreshStudy(null, {node: {study: study.study}});
          })
        })
      }
    }
  }

  onToggle(node, toggled, delay){
    delay = delay || 250;

        if (!sideBarEventTimeOutId) {
            sideBarEventTimeOutId = setTimeout(() => {
              if(this.state.cursor){this.state.cursor.active = false;}
              node.active = true;
              if(node.children){ node.toggled = toggled; }

              if (node.cssClassName === 'manage-study-property' && node.children.length === 0 && node.loading !== true) {
                node.loading = true;
                this.appendStudyDetailInTreeView(node.study)
              }

              this.setState({ cursor: node });
              sideBarEventTimeOutId = null
            }, delay);
        } else {
            sideBarEventTimeOutId = clearTimeout(sideBarEventTimeOutId);

            if(node.children){ node.toggled = true }

            //opening dataviwer
            if(node.tag && node.tag === 'open-data-viewer' && node.tableName  && node.tableName !== ""){
              global.dataViewerTemplate = true;
              this.props.setTenantId(node.schemaName);
              this.props.setProcessFlowTable(node.tableName);
              this.props.history.push("/data-operation");
            }

            if (node.activeProcess) {


              this.props.processFlowList.forEach((processFlow, index) => {
                if (processFlow.schemaName === node.schemaName && processFlow.process.processId === node.process.processId) {
                    this.props.setActiveProcessFlow(index);
                }
              })
            }

            if (typeof(node.process) !== 'undefined' && !node.activeProcess) {
              node.activeProcess = true;

              this.props.addProcessFlow(node);

              if (resetTreeView !== '') {
                resetTreeView.children.forEach((project) => {
                  project.children.forEach((study) => {
                    if (study.study.studyId === node.process.studyId) {

                      study.children.forEach((studyElement) => {
                        if (studyElement.name === 'ProcessFlows') {
                          studyElement.children.forEach((classifier) => {
                            classifier.children.forEach((group) => {
                              group.children.map((process) => {
                                if (process.process.processId === node.process.processId) {
                                  process.activeProcess = true;
                                };

                                return process;
                              })
                            })
                          })

                        }
                      })
                    }
                  })
                })
              }

              this.props.setTenantId(node.schemaName);
              if (this.props.location.pathname !== '/process-flow-list') {
                this.props.history.push("/process-flow-list");
              }
              setTimeout(() => {$(".loader").show();}, 1);
            }

            if (typeof(node.study) !== 'undefined' && typeof(node.study.studyId) !== 'undefined') {

              if (node.children.length === 0) {
                node.loading = true;
                $(".loader").show();
                this.appendStudyDetailInTreeView(node.study, true);
              } else {
                this.openStudyManagerDetail(node.study.schemaName);
              }
            }

            if (typeof(node.cssClassName) !== 'undefined' && node.cssClassName === 'manage-study-edit-documents') {
              if (node.path === '') {
                  this.props.setMessage("Unable to download "+node.name, MessageTypeConst.ERROR_MESSAGE);
              } else {
                  this.downloadDocument(SERVER_URL+'/downloadFile', {filePath: node.path, fileName: node.name})
              }
            }
            this.setState({ cursor: node });
        }

  }

  onFilterMouseUp(e) {
      const filter = e.target.value.trim();
      if (!filter) {
        if (resetTreeView !== '') {
            this.setState({treeView: this.props.treeView.data});
        }

        resetTreeView = '';
        return true;
      } else if(resetTreeView === '') {
        resetTreeView = cloneDeep(this.state.treeView);
      }

      var filtered = filters.filterTree(resetTreeView, filter);
      filtered = filters.expandFilteredNodes(filtered, filter);
      this.setState({treeView: filtered});
  }

  handleAddProject = (event) => {
    let that = this;
    let treeView = this.state.treeView;

    var params = {
        "projectName": this.state.title,
        "classifier": this.state.classifier
    };
    if(params.projectName === null || params.projectName.trim() === "" ){
      that.props.setMessage('Project Name can\'t be empty.', MessageTypeConst.INFORMATION_MESSAGE);
      this.setState({ title:'', addStudyButtonDisable:false});
      $('#txtName').focus();
      return;
    }

    if (!/^[A-Z0-9-_ ]+$/i.test(params.projectName)) {
      that.props.setMessage('Project Name can\'t be allow special characters.', MessageTypeConst.WARNING_MESSAGE);
      this.setState({ addStudyButtonDisable:false});
      $('#txtName').focus();
      return;
    }
    $(".loader").show();
    axios.all([restClient(CREATE, 'project/add', {data : params}, {'Tenant-ID': 'master'})])
        .then(axios.spread(function (response)  {
          if (typeof(response.data) !== 'undefined' && response.data.message != null && response.data.messageType !==  MessageTypeConst.SUCCESS_MESSAGE) {
            $(".loader").hide();
            that.props.setMessage(response.data.message, response.data.messageType);
            that.setState({ addStudyButtonDisable:false});
            $('#txtName').focus();
          }
          else if (typeof(response.data) !== 'undefined' && response.data.projectId != null) {
              let newProject = Object.assign({}, that.manageProjectInTreeView(response.data, []));
              treeView.toggled = true;
              treeView.children.push(newProject);

                that.props.addProject(Object.assign({}, response.data, { selected: true, timestamp:response.data.audit !== null ? moment.utc(response.data.audit.createdAt).format('lll') : "" }));

                if (resetTreeView !== '') {
                  resetTreeView.children.push(newProject);
                }

                that.updateTreeViewData(treeView);
                $(".loader").hide();
                that.setState({ title:'', classifier: '', addStudyButtonDisable:false},()=>{
                  that.toggleAddRenameTitleDialog();
                });
                that.props.setMessage("New project '" + newProject.name +"' have been created successfully!", MessageTypeConst.SUCCESS_MESSAGE);

          }
        })
    ).catch(error => {
      $(".loader").hide();
      that.setState({ addStudyButtonDisable:false});
      $('#txtName').focus();
      that.props.setMessage("Failed to create new project '"+ this.state.title +"'!", MessageTypeConst.ERROR_MESSAGE);
    });
  }

  handleRemoveProject = (event, data) => {
    let that = this;
    let treeView = this.state.treeView;
    let node = data.node;

    ConfirmBox.open("Are you sure you want to delete project node '"+ node.name +"'? All the child nodes will be deleted as well.").then(function () {
        $(".loader").show();
        axios.all([restClient(DELETE, 'project', {id : node.projectId }, {'Tenant-ID': 'master'})])
            .then(axios.spread((response) => {
              if (!(typeof(response.data) !== 'undefined' && response.data.message != null && response.data.messageType !==  MessageTypeConst.SUCCESS_MESSAGE)) {
                treeView.children = treeView.children.filter( project => project.projectId !== node.projectId);
                that.props.removeProject(node.projectId);
                that.props.removeStudyByProjectId(node.projectId);
                that.props.removeProcessFlowByProjectId(node.projectId);

                let openProcessFlowIndex = that.props.processFlowList.length - 1;
                if (openProcessFlowIndex > -1) {
                    that.props.updateProcessflowBlockRenderStatus(openProcessFlowIndex, true);
                    that.props.setActiveProcessFlow(openProcessFlowIndex);
                }
                if (resetTreeView !== '') {
                  resetTreeView.children = resetTreeView.children.filter( project => project.projectId !== node.projectId);
                }

                that.updateTreeViewData(treeView);
              }
              $(".loader").hide();
              that.props.setMessage(response.data.message, response.data.messageType);
            })
        ).catch(error => {
          $(".loader").hide();
          that.props.setMessage("Failed to delete project!",MessageTypeConst.ERROR_MESSAGE);
        });
    });
    $('.k-dialog-title').html('Project deleted');
  }

  toggleDialogAddStudy = (event, data) => {
      let triggerNode = {};
      let study = this.state.study;
      if (typeof(data) !== 'undefined') {
        triggerNode = data.node;
        study.studyName = '';
      }

      study.classifier = typeof(data) !== 'undefined' && typeof(data.node.isTag) !== 'undefined' ? data.node.name : ''
      study.description = '';
      this.setState({
          visibleAddStudy: !this.state.visibleAddStudy,
          triggerNode: triggerNode,
          study: study
      });

  }

  handleStudyNameChange = (event, type = 'name') => {
    let study = this.state.study;

    if (type === 'classifier') {
      study.classifier = event.target.value.toUpperCase()
    } else if(type === 'description') {
      study.description = event.target.value;
    } else {
      study.studyName = event.target.value;
    }

    this.setState({ study: study });
  }

  enterKeyPressForAddStudy(e){
    if(e.keyCode === 13){
      //enter key pressed
      this.handleSaveStudy(e);
    }
    if(e.keyCode === 27){
      //escape key pressed
      this.toggleDialogAddStudy();
    }
 }

  handleIdentifierThreeChange = (event) =>{
    let study = this.state.study;
    study.identifier3 = event.target.value;

    this.setState({ study: study });
  }

  handleSaveStudy = (event) => {
    let that = this;
    let treeView = this.state.treeView;
    let study = this.state.study;
    if(study.studyName === null  || typeof(study.studyName) === "undefined" || study.studyName.trim() === ""){
      that.props.setMessage("Study Name can't be empty.", MessageTypeConst.INFORMATION_MESSAGE);
      $('#txtStudyName').focus();
      return;
    }

    if(!/^[A-Z0-9-_ ]+$/i.test(study.studyName)){
      that.props.setMessage("Study Name can't be allow special characters.", MessageTypeConst.WARNING_MESSAGE);
      $('#txtStudyName').focus();
      return;
    }

    if(study.classifier === null  || typeof(study.classifier) === "undefined" || study.classifier.trim() === ""){
      that.props.setMessage("Classifier Name can't be empty.", MessageTypeConst.INFORMATION_MESSAGE);
      $('#txtIdClassifier').focus();
      return;
    }

    if (typeof(this.state.triggerNode.projectId) !== 'undefined') {
      let selectedProject = that.props.projectList.filter(project => project.projectId === this.state.triggerNode.projectId);
      if(selectedProject.length > 0 && selectedProject[0].studyList !== null && typeof(selectedProject[0].studyList) !== "undefined"){
        let selectedStudy = selectedProject[0].studyList.filter(study1 => study1.schemaName !== study.schemaName && study1.studyName.toLowerCase() === study.studyName.trim().toLowerCase() && study1.classifier.toLowerCase() === study.classifier.trim().toLowerCase());
        if(selectedStudy.length > 0){
          that.props.setMessage(`Study '${study.studyName}' and Classifier '${study.classifier}' already exists. Study name and Classifier must be unique within project.`, MessageTypeConst.INFORMATION_MESSAGE);
          $('#txtStudyName').focus();
          return;
        }
      }
        study.projectId = this.state.triggerNode.projectId
    }

    this.setState({ addStudyButtonDisable: true });
    $(".loader").show();
    axios.all([restClient(CREATE, 'study/add', {data : study}, {'Tenant-ID': 'master'})])
        .then(axios.spread(function (response)  {
          $(".loader").hide();
          if (typeof(response.data) !== 'undefined' && response.data.message != null && response.data.messageType !==  MessageTypeConst.SUCCESS_MESSAGE) {
            let study = that.state.study;
            //study.studyName = '';
            //study.identifier3 = '';
            //study.projectId = 0;
            that.setState({ addStudyButtonDisable: false, study: study });
            that.props.setMessage(response.data.message, response.data.messageType);
            $('#txtStudyName').focus();
          }
          else if (typeof(response.data) !== 'undefined' && response.data.study.studyId > 0 && response.data.study.projectId > 0 && response.data.study.schemaName !== '') {

              let metaTables = [];
              if(response.data.metaTables !== null && typeof(response.data.metaTables) !== 'undefined'){
                  response.data.metaTables.forEach((table) => {
                      metaTables.push({
                        name: table.srcTableName,
                        cssClassName: 'manage-study-edit-meta-table',
                        schemaName: response.data.study.schemaName,
                        studyId:response.data.study.studyId,
                        projectId:response.data.study.projectId,
                        tag:'open-data-viewer',
                        tableName:table.tableName,
                        tableType: 1
                      })
                    })
              }

              let groupClassifier = [];

              if (typeof(response.data.groupClassifier) !== 'undefined' && response.data.groupClassifier !== null && response.data.groupClassifier.length > 0) {
                response.data.groupClassifier.forEach((classifier) => {
                  groupClassifier.push(manageClassifierInTreeView(classifier, [], response.data.study))
                })
              }

              let addStudy = manageStudyInTreeView(response.data.study, manageStudyElementsInTreeView(response.data.study, [], metaTables, groupClassifier, []), true);

              if (addStudy.study.sourceTable === null || typeof (addStudy.study.sourceTable) === "undefined") {
                  addStudy.study.sourceTable = response.data.sourceTables !== null && typeof(response.data.sourceTables) !== 'undefined' ? response.data.sourceTables : [];
              }
              if (addStudy.study.metaTable === null || typeof (addStudy.study.metaTable) === "undefined") {
                addStudy.study.metaTable = response.data.metaTables !== null && typeof(response.data.metaTables) !== 'undefined' ? response.data.metaTables : [];
              }
              if (addStudy.study.studyDocuments === null || typeof (addStudy.study.studyDocuments) === "undefined") {
                  addStudy.study.studyDocuments = response.data.documents !== null && typeof(response.data.documents) !== 'undefined' ? response.data.documents : [];
              }
              if (addStudy.study.groupClassifier === null || typeof (addStudy.study.groupClassifier) === "undefined") {
                  addStudy.study.groupClassifier = response.data.groupClassifier !== null && typeof(response.data.groupClassifier) !== 'undefined' ? response.data.groupClassifier : [];
              }
              if (addStudy.study.processFlowGroup === null || typeof (addStudy.study.processFlowGroup) === "undefined") {
                  addStudy.study.processFlowGroup = response.data.processFlowGroup !== null && typeof(response.data.processFlowGroup) !== 'undefined' ? response.data.processFlowGroup : [];
              }

              treeView.children.forEach((project, index) => {
                if (project.projectId === response.data.study.projectId) {
                  project.toggled = true;
                  addStudy.toggled = true;
                  project.children.push(addStudy);
                }
              })

              if (resetTreeView !== '') {
                resetTreeView.children.forEach((project, index) => {
                  if (project.projectId === response.data.study.projectId) {
                    project.children.push(addStudy);
                  }
                })
              }

              let newStudy = Object.assign({}, response.data.study, { selected: true,  timestamp:response.data.study.audit !== null ? moment.utc(response.data.study.audit.createdAt).format('lll') : ""  });
              that.props.addStudy(newStudy);

              that.props.projectList.map((project) => {
                if (project.projectId === newStudy.projectId) {

                  if (project.study === null || typeof(project.study) === 'undefined') {
                    project.study = [];
                  }

                  let projectStudyList = project.study.slice();
                  projectStudyList.splice(projectStudyList.length, 0, newStudy);
                  project.study.splice(0);
                  project.study.splice(0, 0, ...projectStudyList);

                  if (project.studyList === null || typeof(project.studyList) === 'undefined') {
                    project.studyList = [];
                  }

                  project.studyList.splice(0);
                  project.studyList.splice(0, 0, ...projectStudyList);
                }
                return project;
              });

              that.props.updateProjectList(that.props.projectList);

              study.studyName = '';
              study.identifier3 = '';
              study.classifier = '';
              study.projectId = 0;

              that.setState({ treeView: treeView, study: study, displayTreeView: that.displayTreeView(treeView)  });

              that.props.setTreeViewData(treeView);

              that.setState({ addStudyButtonDisable: false });
              that.toggleDialogAddStudy();
              that.props.setMessage("New '"+ newStudy.studyName +"' STUDY have been created Successfully!", MessageTypeConst.SUCCESS_MESSAGE);
            }
        })
    ).catch(error => {
      this.setState({
        addStudyButtonDisable: false
      })

      that.props.setMessage("New '"+ this.state.study.studyName +"' STUDY creation Failed!", MessageTypeConst.ERROR_MESSAGE);
      $('#txtStudyName').focus();
    });
  }

  handleRemoveStudy = (event, data) => {
    let that = this;
    let treeView = this.state.treeView;
    let node = data.node;

    ConfirmBox.open("Are you sure you want to delete study '"+  node.study.studyName +"'? All data will be lost.").then(function () {
        $(".loader").show();
        axios.all([restClient(DELETE, 'study', {id : node.study.studyId}, {'Tenant-ID': 'master'})])
            .then(axios.spread((response) => {
                $(".loader").hide();
                if (typeof(response.data) !== 'undefined' && response.data.messageType ===  MessageTypeConst.SUCCESS_MESSAGE) {
                  treeView.children.forEach((project, index) => {

                    if (project.projectId === node.study.projectId) {
                      project.children = project.children.filter( study => study.study.studyId !== node.study.studyId );
                      project.toggled = true;
                      that.props.removeStudy(node.study.studyId);

                      that.props.removeProcessFlowByStudyId(node.study.studyId);
                      let openProcessFlowIndex = that.props.processFlowList.length - 1;
                      if (openProcessFlowIndex > -1) {
                          that.props.updateProcessflowBlockRenderStatus(openProcessFlowIndex, true);
                          that.props.setActiveProcessFlow(openProcessFlowIndex);
                      }

                      that.props.projectList.map((project) => {
                        if (project.projectId === node.study.projectId) {
                          let projectStudyList = project.study.filter( study => study.studyId !== node.study.studyId);
                          let projectStudyLists = project.studyList.filter( study => study.studyId !== node.study.studyId);

                          project.study.splice(0);
                          project.study.splice(0, 0, ...projectStudyList);
                          project.studyList.splice(0);
                          project.studyList.splice(0, 0, ...projectStudyLists);
                          project.selected = project.study.filter(studyObject => studyObject.selected === true).length > 0;
                          project.expanded = project.study.filter(studyObject => studyObject.selected === true).length > 0;
                        }
                        return project;
                      })
                      that.props.updateProjectList(that.props.projectList);
                    }
                  })

                  if (resetTreeView !== '') {
                    resetTreeView.children.forEach((project, index) => {
                      if (project.projectId === node.study.projectId) {
                        project.children = project.children.filter( study => study.study.studyId !== node.study.studyId );
                      }
                    })
                  }

                  treeView.children = treeView.children.filter(project => project.children.length > 0);
                  that.props.setStudyManager('');
                  that.updateTreeViewData(treeView);
                }

                if (typeof(response.data) !== 'undefined') {
                    that.props.setMessage(response.data.message, response.data.messageType);
                }
            })
        ).catch(error => {
          that.props.setMessage("STUDY deletion Failed!",MessageTypeConst.ERROR_MESSAGE);
        });
    });
  }


  handleRemoveProcessFlowGroup = (event, data) => {
    let that = this;
    let treeView = this.state.treeView;
    let node = data.node;

    ConfirmBox.open("Are you sure you want to delete process flow group '"+ node.name +"'?").then(function () {
      $(".loader").show();
        axios.all([restClient(DELETE, 'processflowgroups', {id : node.processGroupId }, {'Tenant-ID': node.schemaName})])
            .then(axios.spread((response) => {
              if(response.data.messageType === MessageTypeConst.SUCCESS_MESSAGE)
              {
              treeView.children.forEach((project, index) => {
                if (project.projectId === node.projectId) {
                  project.children.forEach((study) => {
                    if (node.studyId === study.study.studyId) {
                      study.children.forEach((studyElement) => {
                        if (studyElement.name === 'ProcessFlows') {
                          studyElement.children.forEach((classifier) => {
                            if (classifier.classifierId === node.classifierId) {
                              classifier.children = classifier.children.filter( processGroup => processGroup.processGroupId !== node.processGroupId);
                              study.study.processFlowGroup = study.study.processFlowGroup.filter(processGroup => processGroup.processGroupId !== node.processGroupId);
                              that.props.removeProcessFlowByProcessGroupId(node.processGroupId, study.study.studyId);

                              let openProcessFlowIndex = that.props.processFlowList.length - 1;
                              if (openProcessFlowIndex > -1) {
                                  that.props.updateProcessflowBlockRenderStatus(openProcessFlowIndex, true);
                                  that.props.setActiveProcessFlow(openProcessFlowIndex);
                              }

                              project.toggled = true;
                              study.toggled = true;
                              classifier.toggled = true;
                            }
                          })
                        }
                      })
                    }
                  });
                }
              })

              if (resetTreeView !== '') {
                resetTreeView.children.forEach((project, index) => {
                  if (project.projectId === node.projectId) {
                    project.children.forEach((study) => {
                        study.children.forEach((studyElement) => {
                          if (node.studyId === study.study.studyId) {
                            if (studyElement.name === 'ProcessFlows') {
                              studyElement.children = studyElement.children.filter( processGroup => processGroup.processGroupId !== node.processGroupId);
                            }
                          }
                        })
                    });
                  }
                })
              }

              that.updateTreeViewData(treeView);
            }
              $(".loader").hide();
              that.props.setMessage(response.data.message,response.data.messageType);
            })
        ).catch(error => {
          $(".loader").hide();
          that.props.setMessage("Process flow group '"+ node.name +"' deletion failed!",MessageTypeConst.ERROR_MESSAGE);
        });
    });
  }

  handleRemoveProcessFlowApi = (event, data) => {
    let that = this;
    let treeView = this.state.treeView;
    let node = data.node;

    ConfirmBox.open("Are you sure you want to delete process flow '"+ node.name +"'?").then(function () {
        $(".loader").show();
        axios.all([restClient(DELETE, 'processflow', {id : node.process.processId}, {'Tenant-ID': node.schemaName})])
            .then(axios.spread((processFlow) => {
              if(processFlow.data.messageType === MessageTypeConst.SUCCESS_MESSAGE)
              {
              treeView.children.forEach((project, index) => {
                if (project.projectId === node.projectId) {
                  project.children.forEach((study) => {
                    if (study.study.studyId === node.process.studyId) {
                      study.children.forEach((studyElement) => {
                        if (studyElement.name === 'ProcessFlows') {
                          studyElement.children.forEach((classifier) => {
                            classifier.children.forEach((processGroup) => {
                                if (processGroup.processGroupId === node.process.processGroupId) {
                                  processGroup.children = processGroup.children.filter( (processFlow) => processFlow.process.processId !== node.process.processId);
                                  that.props.removeProcessFlow(parseInt(node.process.processId, 10), parseInt(node.process.studyId, 10));
                                  let openProcessFlowIndex = that.props.processFlowList.length - 1;
                                  if (openProcessFlowIndex > -1) {
                                      that.props.updateProcessflowBlockRenderStatus(openProcessFlowIndex, true);
                                      that.props.setActiveProcessFlow(openProcessFlowIndex);
                                  }

                                  project.toggled = true;
                                  study.toggled = true;
                                  classifier.toggled = true;
                                  processGroup.toggled = true;

                                  study.study.processFlowGroup.filter(processGroup => processGroup.processGroupId === node.process.processGroupId).map(processGroup => {
                                    if (processGroup.processFlow === null) {
                                      processGroup.processFlow = [];
                                    }

                                    processGroup.processFlow = processGroup.processFlow.filter(process => process.processId !== node.process.processId)
                                  })
                                }
                            });
                          })

                        }
                      })
                    }
                 });
                }
              })

              if (resetTreeView !== '') {
                resetTreeView.children.forEach((project, index) => {
                  if (project.projectId === node.projectId) {
                    project.children.forEach((study) => {
                      if (study.study.studyId === node.process.studyId) {
                        study.children.forEach((studyElement) => {
                          if (studyElement.name === 'ProcessFlows') {
                            studyElement.children.forEach((processGroup) => {
                                if (processGroup.processGroupId === node.process.processGroupId) {
                                  processGroup.children = processGroup.children.filter( (processFlow) => processFlow.process.processId !== node.process.processId);
                                }
                            });
                          }
                        })
                      }
                   });
                  }
                })
              }

              that.updateTreeViewData(treeView);
            }
              that.props.setMessage(processFlow.data.message,processFlow.data.messageType);
              $(".loader").hide();
            })
        ).catch(error => {
          that.props.setMessage("'"+ node.name +"' PROCESS FLOW deletion Failed!",MessageTypeConst.ERROR_MESSAGE);
        });
    });
    $('.k-dialog-title').html('Process Flow delete');

  }

  handleRemoveProcess = (event, data) => {
    let that = this;
    if(window.location.host === "localhost:3000"){
      that.handleRemoveProcessFlowApi(event, data);
      return;
    }
    if(data.node.process.checkOutFlag === CheckOutCheckinFalg.CHECK_OUT_FLAG && data.node.process.userName === this.props.token_detail['email']){
      that.handleRemoveProcessFlowApi(event, data);
    }
    else if(data.node.process.checkOutFlag === CheckOutCheckinFalg.CHECK_OUT_FLAG){
      that.props.setMessage(`Process Flow checked-out by '${data.node.process.userName}'.`,MessageTypeConst.WARNING_MESSAGE);
    }
    else{
      $(".loader").show();
      let fetchProcessFlow = (params, headers) => restClient(GET, 'processflow/'+data.node.process.processId, params, headers);
      axios.all([fetchProcessFlow({},{'Tenant-ID': data.node.schemaName})]).then(
        axios.spread(function (response)  {
          if(typeof(response) !== "undefined" && response !== null && response.data !== null){
            if(response.data.checkOutFlag === CheckOutCheckinFalg.CHECK_OUT_FLAG && response.data.userName === this.props.token_detail['email']){
              $(".loader").hide();
              that.handleRemoveProcessFlowApi(event, data);
            }else{
              $(".loader").hide();
              that.props.setMessage("Process Flow needs to check-out before deleting.",MessageTypeConst.WARNING_MESSAGE);
            }
          }
        })
      ).catch(error => {
        $(".loader").hide();
        that.props.setMessage("Error occurred while refreshing Process Flow.",MessageTypeConst.ERROR_MESSAGE);
      });
    }
  }

  handleRenameProject = (event, data) => {
    let that = this;
    let treeView = this.state.treeView;
    let node = this.state.triggerNode;

    var params = {
        "projectName": this.state.title,
        "classifier": ""
    };
    if(params.projectName === null || params.projectName.trim() === "" ){
      that.props.setMessage('Project Name can\'t be empty.', MessageTypeConst.INFORMATION_MESSAGE);
      this.setState({ title:'', addStudyButtonDisable:false});
      $('#txtName').focus();
      return;
    }
    if (!/^[A-Z0-9-_ ]+$/i.test(params.projectName)) {
      that.props.setMessage('Project Name can\'t be allow special characters.', MessageTypeConst.WARNING_MESSAGE);
      this.setState({ addStudyButtonDisable:false});
      $('#txtName').focus();
      return;
    }
    $(".loader").show();
    axios.all([restClient(UPDATE, 'project', {data: params, id: node.projectId}, {'Tenant-ID': 'master'})])
        .then(axios.spread((response) => {
          if (typeof(response.data) !== 'undefined' && response.data.message != null && response.data.messageType !==  MessageTypeConst.SUCCESS_MESSAGE) {
            $(".loader").hide();
            that.props.setMessage(response.data.message, response.data.messageType);
            that.setState({ addStudyButtonDisable:false});
            $('#txtName').focus();
            return;
          }
          else if (typeof(response.data) !== 'undefined') {
              treeView.children.map((project, index) => {
                if (project.projectId === node.projectId) {
                  project.name = response.data.projectName;

                  project.children.forEach((study) => {
                    study.children.forEach((studyElement) => {
                      if (studyElement.name === 'ProcessFlows') {
                          studyElement.children.forEach((classifier) => {
                            classifier.children.forEach((processGroup) => {
                              if (typeof(that.props.processFlowList[that.props.activeProcessFlow]) !== 'undefined') {
                                const activeProcessFlow = that.props.processFlowList[that.props.activeProcessFlow];

                                if (activeProcessFlow.schemaName === study.study.schemaName && activeProcessFlow.process.processGroupId === processGroup.processGroupId) {
                                  processGroup.children.filter(processFlow => processFlow.process.processId === activeProcessFlow.process.processId).forEach((processFlow) => {
                                    that.props.addProcessFlowTitle(Object.assign({ schemaName: study.study.schemaName, processId: processFlow.process.processId, title: study.study.studyName +':'+ processFlow.name, name: processFlow.name }))
                                  })
                                }
                              }
                            });
                          })
                      }
                    })
                  })
                }
                return project;
              })

              that.props.projectList.map((project) => {
                if (project.projectId === node.projectId) {
                  project.projectName = response.data.projectName;
                }
                return project;
              })

              if (resetTreeView !== '') {
                resetTreeView.children.map((project, index) => {
                  if (project.projectId === node.projectId) {
                    project.name = response.data.projectName;
                  }
                  return project;
                })
              }

              that.updateTreeViewData(treeView);
              $(".loader").hide();
              that.setState({ title:'',addStudyButtonDisable:false},()=>{
                that.toggleAddRenameTitleDialog();
              });
              that.props.setMessage("'"+ response.data.projectName +"' project name have been updated successfully!", MessageTypeConst.SUCCESS_MESSAGE);
          }
        })
    ).catch(error => {
      $(".loader").hide();
      that.props.setMessage("Failed to rename project '"+ this.state.title +"'!", MessageTypeConst.ERROR_MESSAGE);
      that.setState({ addStudyButtonDisable:false});
      $('#txtName').focus();
      return;
    });
  }

  handleRenameStudy = (message, messageType) => {
    let that = this;
    let treeView = this.state.treeView;
    let node = this.state.triggerNode;
    let title = this.state.studyProperty.studyName;
    let studyId = this.state.studyProperty.studyId;

    if (this.state.triggerNode.name === title) {
      that.props.setMessage(message, messageType);
      that.toggleDialogStudyProperty();
      $(".loader").hide();
      return;
    }

    if(title === null || title.trim() === ''){
      that.props.setMessage('Study Name can\'t be empty.', MessageTypeConst.WARNING_MESSAGE);
      $(".loader").hide();
      return;
    }

    if (typeof(node.study.projectId) !== 'undefined') {
      let selectedProject = that.props.projectList.filter(project => project.projectId === node.study.projectId);
      if(selectedProject.length > 0 && selectedProject[0].studyList !== null && typeof(selectedProject[0].studyList) !== "undefined"){
        let selectedStudy = selectedProject[0].studyList.filter(study1 => study1.studyId !== studyId && study1.studyName.toLowerCase() === title.trim().toLowerCase() && study1.classifier.toLowerCase() === node.study.classifier.trim().toLowerCase());
        if(selectedStudy.length > 0){
          that.props.setMessage(`Study '${title}' and Classifier '${node.study.classifier}' already exists. Study name and Classifier must be unique within project.`, MessageTypeConst.WARNING_MESSAGE);
          $(".loader").hide();
          return;
        }
      }
    }

    if (!/^[A-Z0-9-_ ]+$/i.test(title)) {
      that.props.setMessage('Study Name can\'t be allow special characters.', MessageTypeConst.WARNING_MESSAGE);
      $(".loader").hide();
      return;
    }

    var params = {
      "studyName": title ,
      "classifier": node.study.classifier
    };

    $(".loader").show();
    axios.all([restClient(UPDATE, 'study', {data: params, id: node.study.studyId}, {'Tenant-ID': 'master'})])
      .then(axios.spread((response) => {
        if (typeof(response.data) !== 'undefined' && response.data.message != null && response.data.messageType !==  MessageTypeConst.SUCCESS_MESSAGE) {
          that.props.setMessage(response.data.message, response.data.messageType);
        }
        else if (typeof(response.data) !== 'undefined') {
            treeView.children.forEach((project, index) => {
              if (project.projectId === node.study.projectId) {
                project.children.map((study) => {
                  if (study.study.studyId === node.study.studyId) {
                    study.name = response.data.studyName;
                    study.study.studyName = response.data.studyName;
                    project.toggled = true

                    study.children.forEach((studyElement) => {
                      if (studyElement.name === 'ProcessFlows') {
                          studyElement.children.forEach((classifier) => {
                            classifier.children.forEach((processGroup) => {
                              if (typeof(that.props.processFlowList[that.props.activeProcessFlow]) !== 'undefined') {
                                const activeProcessFlow = that.props.processFlowList[that.props.activeProcessFlow];

                                if (activeProcessFlow.schemaName === study.study.schemaName && activeProcessFlow.process.processGroupId === processGroup.processGroupId) {
                                  processGroup.children.filter(processFlow => processFlow.process.processId === activeProcessFlow.process.processId).forEach((processFlow) => {
                                    that.props.addProcessFlowTitle(Object.assign({ schemaName: study.study.schemaName, processId: processFlow.process.processId, title: study.study.studyName +':'+ processFlow.name, name: processFlow.name }))
                                  })
                                }
                              }
                            });
                          })
                      }
                    })
                  }

                  return study;
                })
              }
            })

            that.props.projectList.forEach((project) => {
              if (project.projectId === node.study.projectId) {
                project.study.map((study) => {
                  if (study.studyId === node.study.studyId) {
                    study.studyName = response.data.studyName;
                  }

                  return study;
                })
              }
            });

            that.props.studyList.map((study) => {
              if (study.studyId === node.study.studyId) {
                study.studyName = response.data.studyName;
              }
              return study;
            })

            if (resetTreeView !== '') {
              resetTreeView.children.forEach((project, index) => {
                if (project.projectId === node.study.projectId) {
                  project.children.map((study) => {
                    if (study.study.studyId === node.study.studyId) {
                      study.name = response.data.studyName;
                    }

                    return study;
                  })
                }
              })
            }

            that.updateTreeViewData(treeView);
            that.setState({ title:'',addStudyButtonDisable:false, triggerNode: {}, addRenameTitlePopupVisible:false, addRenameTitleEventRaiseBy:0, addRenameDiableOKButton:false}, () => {
              that.toggleDialogStudyProperty();
            });
            that.props.setMessage(message, messageType);

        }
        $(".loader").hide();
      })
    ).catch(error => {
      that.props.setMessage("'"+ this.state.title +"' STUDY name update Failed!", MessageTypeConst.ERROR_MESSAGE);
      $(".loader").hide();
      return;
    });
  }

  handleRenameProcessFlowGroup = (event, data) => {
    let that = this;
    let treeView = this.state.treeView;
    let node = this.state.triggerNode;

    var params = {
        "processGroupName": this.state.title,
        "classifier": this.state.selectedGroupClassifier.classifierId
    };
    if(params.processGroupName === null || params.processGroupName.trim() === "" ){
      that.props.setMessage('Group Name can\'t be empty.', MessageTypeConst.INFORMATION_MESSAGE);
      this.setState({ title:'', addStudyButtonDisable:false});
      $('#txtName').focus();
      return;
    }

    let tagList = this.getTagList(node.processGroupId, TagContantType.PROCESS_FLOW_GROUP);

    if (tagList.length > 0 && that.validateTagList(tagList) === false) {
      that.props.setMessage("Custom tag property title should be unique.",MessageTypeConst.WARNING_MESSAGE);
      return false;
    }

    $(".loader").show();
    axios.all(
      tagList.length === 0 ?
      [restClient(UPDATE, 'processflowgroups', {data: params, id: node.processGroupId}, {'Tenant-ID': node.schemaName})] :
      [restClient(UPDATE, 'processflowgroups', {data: params, id: node.processGroupId}, {'Tenant-ID': node.schemaName}), this.saveTagProperty({data: tagList}, {'Tenant-ID': node.schemaName})]
    )
        .then(axios.spread((response) => {
          if (typeof(response.data) !== 'undefined' && response.data.message != null && response.data.messageType !==  MessageTypeConst.SUCCESS_MESSAGE) {
            $(".loader").hide();
            that.props.setMessage(response.data.message, response.data.messageType);
            that.setState({ addStudyButtonDisable:false});
            $('#txtName').focus();
          }
          else if (typeof(response.data) !== 'undefined') {
              treeView.children.forEach((project, index) => {
                if (project.projectId === node.projectId) {
                  project.children.forEach((study) => {
                    if (node.studyId === study.study.studyId) {
                      study.children.forEach((studyElement) => {
                        if (studyElement.name === 'ProcessFlows') {
                          if (this.state.selectedGroupClassifier.classifierId !== node.classifierId) {
                            let copyProcessGroup = '';
                            studyElement.children.forEach((classifier) => {
                              if (classifier.classifierId === node.classifierId) {
                                classifier.children.map((processGroup) => {
                                    if (processGroup.processGroupId === node.processGroupId) {
                                      copyProcessGroup = processGroup;
                                      copyProcessGroup.classifierId = this.state.selectedGroupClassifier.classifierId
                                    }
                                  })

                                 classifier.children = classifier.children.filter(processGroup => processGroup.processGroupId !== node.processGroupId);
                                }
                              })

                              if (copyProcessGroup !== '') {
                                studyElement.children.forEach((classifier) => {
                                  if (classifier.classifierId === this.state.selectedGroupClassifier.classifierId) {
                                    classifier.children.push(copyProcessGroup)
                                  }
                                })

                                node.classifierId = this.state.selectedGroupClassifier.classifierId
                              }
                          }

                          studyElement.children.forEach((classifier) => {
                            if (classifier.classifierId === node.classifierId) {
                              classifier.children.map((processGroup) => {
                                  if (processGroup.processGroupId === node.processGroupId) {
                                    processGroup.name = response.data.processFlowGroup.processGroupName;
                                    project.toggled = true;
                                    study.toggled = true;
                                    classifier.toggled = true;

                                    if (typeof(that.props.processFlowList[that.props.activeProcessFlow]) !== 'undefined') {
                                      const activeProcessFlow = that.props.processFlowList[that.props.activeProcessFlow];

                                      if (activeProcessFlow.schemaName === study.study.schemaName && activeProcessFlow.process.processGroupId === node.processGroupId) {
                                        processGroup.children.filter(processFlow => processFlow.process.processId === activeProcessFlow.process.processId).forEach((processFlow) => {
                                          that.props.addProcessFlowTitle(Object.assign({ schemaName: study.study.schemaName, processId: processFlow.process.processId, title: study.study.studyName +':'+ processFlow.name, name: processFlow.name }))
                                        })
                                      }
                                    }
                                  }

                                  return processGroup;
                              });
                            }
                          })
                        }
                      })

                      if (study.study.processFlowGroup.filter(processGroup => processGroup.processGroupId === response.data.processFlowGroup.processGroupId).length > 0) {
                        study.study.processFlowGroup = study.study.processFlowGroup.filter(processGroup => processGroup.processGroupId !== response.data.processFlowGroup.processGroupId);
                        study.study.processFlowGroup.push(response.data.processFlowGroup);
                      }
                    }
                  });
                }
              })

              if (resetTreeView !== '') {
                resetTreeView.children.forEach((project, index) => {
                  if (project.projectId === node.projectId) {
                    project.children.forEach((study) => {
                      if (node.studyId === study.study.studyId) {
                        study.children.forEach((studyElement) => {
                          if (studyElement.name === 'ProcessFlows') {
                            studyElement.children.map((processGroup) => {
                                if (processGroup.processGroupId === node.processGroupId) {
                                  processGroup.name = response.data.processFlowGroup.processGroupName;
                                }

                                return processGroup;
                            });
                          }
                        })
                      }
                    });
                  }
                })
              }

              if (response.data.processBlocks.length > 0) {
                that.props.updateProcessFlowBlockBySchemaName(node.schemaName, response.data.processBlocks);
              }
              $(".loader").hide();
              that.updateTreeViewData(treeView);
              that.setState({ title:'',addStudyButtonDisable:false},()=>{
                that.toggleAddRenameTitleDialog();
              });
              that.props.setMessage("Process flow group '"+ response.data.processFlowGroup.processGroupName +"' name have been updated successfully!", MessageTypeConst.SUCCESS_MESSAGE);
          }
        })
    ).catch(error => {
      $(".loader").hide();
      that.props.setMessage("Failed to update process flow group '"+ this.state.title +"'!", MessageTypeConst.ERROR_MESSAGE);
      that.setState({ addStudyButtonDisable:false});
      $('#txtName').focus();
      return;
    });
  }

  handleRenameProcess = (event, data) => {
    let that = this;
    let treeView = this.state.treeView;
    let node = this.state.triggerNode;

    var params = {
        "processName": this.state.title,
        "processGroupId": node.process.processGroupId
    };
    if(params.processName === null || params.processName.trim() === "" ){
      that.props.setMessage('Process Flow Name can\'t be empty.', MessageTypeConst.INFORMATION_MESSAGE);
      this.setState({ title:'', addStudyButtonDisable:false});
      $('#txtName').focus();
      return;
    }

    let tagList = this.getTagList(node.process.processId, TagContantType.PROCESS_FLOW);
    if (tagList.length > 0 && that.validateTagList(tagList) === false) {
      that.props.setMessage("Custom tag property title should be unique.",MessageTypeConst.WARNING_MESSAGE);
      return false;
    }

    $(".loader").show();
    axios.all(
      tagList.length === 0 ?
      [restClient(UPDATE, 'processflow', {data: params, id: node.process.processId}, {'Tenant-ID': node.schemaName})] :
      [restClient(UPDATE, 'processflow', {data: params, id: node.process.processId}, {'Tenant-ID': node.schemaName}), this.saveTagProperty({data: tagList}, {'Tenant-ID': node.schemaName})]
    )
        .then(axios.spread((response) => {

        if (typeof(response.data) !== 'undefined' && response.data.message != null && response.data.messageType !==  MessageTypeConst.SUCCESS_MESSAGE) {
          that.props.setMessage(response.data.message, response.data.messageType);
            that.setState({ addStudyButtonDisable:false});
            $('#txtName').focus();
        }
        else if (typeof(response.data) !== 'undefined') {
            treeView.children.map((project, index) => {
              if (project.projectId === node.projectId) {
                project.children.map((study) => {
                  if (study.study.studyId === node.process.studyId) {
                    study.children.map((studyElement) => {
                      if (studyElement.name === 'ProcessFlows') {
                        studyElement.children.forEach((classifier) => {
                          classifier.children.forEach((processGroup) => {
                              if (processGroup.processGroupId === node.process.processGroupId) {
                                processGroup.children.map((processFlow) => {
                                    if (processFlow.process.processId === node.process.processId) {
                                      processFlow.name = response.data.processName;
                                      project.toggled = true;
                                      study.toggled = true;
                                      classifier.toggled = true
                                      processGroup.toggled = true;

                                      processFlow.process = {
                                        ...processFlow.process,
                                        ...response.data
                                      }
                                    //  that.props.updateProcessflowBlockRenderStatus(0, true);
                                      that.props.addProcessFlowTitle(Object.assign({ schemaName: study.study.schemaName, processId: node.process.processId, title: study.study.studyName +':'+ processFlow.name, name: processFlow.name }))

                                      study.study.processFlowGroup.filter(processGroup => processGroup.processGroupId === response.data.processGroupId).map(processGroup => {
                                        if (processGroup.processFlow === null) {
                                          processGroup.processFlow = [];
                                        }

                                        processGroup.processFlow = processGroup.processFlow.filter(process => process.processId !== response.data.processId)
                                        processGroup.processFlow.push(response.data)
                                      })
                                    }
                                    return processFlow;
                                });
                              }

                              return processGroup;
                          });

                          return classifier;
                        })

                      }

                      return studyElement;
                    })

                  }

                  return study;
              });
              }

              return project;
            });

            if (resetTreeView !== '') {
              resetTreeView.children.forEach((project, index) => {
                if (project.projectId === node.projectId) {
                  project.children.forEach((study) => {
                    if (study.study.studyId === node.process.studyId) {
                      study.children.forEach((studyElement) => {
                        if (studyElement.name === 'ProcessFlows') {
                          studyElement.children.forEach((processGroup) => {
                              if (processGroup.processGroupId === node.process.processGroupId) {
                                processGroup.children.map((processFlow) => {
                                    if (processFlow.process.processId === node.process.processId) {
                                      processFlow.name = response.data.processName;
                                    }
                                    return processFlow;
                                });
                              }
                          });
                        }
                      })

                    }
                });
                }
              });
            }

            that.updateTreeViewData(treeView);
            that.setState({ title:'',addStudyButtonDisable:false},()=>{
              that.toggleAddRenameTitleDialog();
            });
            that.props.setMessage("'"+ response.data.processName +"' PROCESS FLOW name have been updated Successfully!", MessageTypeConst.SUCCESS_MESSAGE);
          }
          $(".loader").hide();
        })
    ).catch(error => {
      that.props.setMessage("'"+ this.state.title +"' PROCESS FLOW name update Failed!", MessageTypeConst.ERROR_MESSAGE);
      that.setState({ addStudyButtonDisable:false});
      $('#txtName').focus();
      $(".loader").hide();
      return;
    });
  }

  handleAddProcessFlowGroup = (event, data) => {
    let that = this;
    let treeView = this.state.treeView;
    let node = this.state.triggerNode;

    var params = {
        "processGroupName": this.state.title,
        "studyId": node.studyId,
        "isCopy":0,
        "classifier": typeof(node.classifierId) !== 'undefined' ? node.classifierId : 0
    };
    if(params.processGroupName === null || params.processGroupName.trim() === "" ){
      that.props.setMessage('Group Name can\'t be empty.', MessageTypeConst.INFORMATION_MESSAGE);
      this.setState({ title:'', addStudyButtonDisable:false});
      $('#txtName').focus();
      return;
    }

    $(".loader").show();
    axios.all([restClient(CREATE, 'processflowgroups/add', {data : params}, {'Tenant-ID': node.schemaName })])
        .then(axios.spread((response) => {

          if (typeof(response.data) !== 'undefined' && response.data.message != null && response.data.messageType !==  MessageTypeConst.SUCCESS_MESSAGE) {
            $(".loader").hide();
            that.props.setMessage(response.data.message, response.data.messageType);
            that.setState({ addStudyButtonDisable:false});
            $('#txtName').focus();
          }
          else if (typeof(response.data)  !== 'undefined' && typeof(response.data.processFlowGroup)  !== 'undefined' && response.data.processFlowGroup !== null
          && response.data.processFlowGroup.studyId > 0 && response.data.processFlowGroup.processGroupId > 0) {
              treeView.children.forEach((project) => {
                if (node.projectId === project.projectId) {
                  project.children.forEach((study) => {
                    if (study.study.studyId === response.data.processFlowGroup.studyId) {
                      study.children.forEach((studyElement) => {
                        if (studyElement.name === 'ProcessFlows') {
                          studyElement.toggled = true;
                          studyElement.children.forEach((classifier) => {
                            if (classifier.classifierId === response.data.processFlowGroup.classifier) {
                              classifier.toggled = true;
                              classifier.children.push(manageProceeFlowGroupInTreeView(response.data.processFlowGroup, [], study.study))
                              project.toggled = true;
                              study.toggled = true;

                              if (typeof(study.study.processFlowGroup) === 'undefined') {
                                  study.study.processFlowGroup = [];
                              }
                              study.study.processFlowGroup.push(response.data.processFlowGroup)
                            }
                          })
                        }
                      })
                    }
                  })
                }
              })

              if (resetTreeView !== '') {
                resetTreeView.children.forEach((project) => {
                  if (node.projectId === project.prjectId) {
                    project.children.forEach((study) => {
                      if (study.study.studyId === response.data.processFlowGroup.studyId) {
                        study.children.forEach((studyElement) => {
                          if (studyElement.name === 'ProcessFlows') {
                            studyElement.children.forEach((classifier) => {
                              if (classifier.classifierId === response.data.processFlowGroup.classifier) {
                                let processFloweGroup = classifier.children.filter(processFloweGroup => processFloweGroup.processGroupId === response.data.processFlowGroup.processGroupId );
                                if (processFloweGroup.length === 0) {
                                    classifier.children.push(manageProceeFlowGroupInTreeView(response.data.processFlowGroup, [], study.study));
                                }
                              }
                            })
                          }
                        })
                      }
                    })
                  }
                })
              }
              $(".loader").hide();
              that.updateTreeViewData(treeView);
              that.setState({ title:'',addStudyButtonDisable:false},()=>{
                that.toggleAddRenameTitleDialog();
              });
              that.props.setMessage("New process flow group '" + response.data.processFlowGroup.processGroupName +"' have been created successfully!", MessageTypeConst.SUCCESS_MESSAGE);
          }
        })
    ).catch(error => {
      $(".loader").hide();
      that.setState({ addStudyButtonDisable:false});
      $('#txtName').focus();
      that.props.setMessage("Process flow group '"+ this.state.title +"' creation failed!", MessageTypeConst.ERROR_MESSAGE);
    });
  }

  handleAddProcessFlow = (event, data) => {
    let that = this;
    let treeView = this.state.treeView;
    let node = this.state.triggerNode;
    let sortOrder = 0;
    treeView.children.forEach((project) => {
      project.children.forEach((study) => {
        if (study.study.studyId === node.studyId) {
          study.children.forEach((studyElement) => {
            if (studyElement.name === 'ProcessFlows') {
              studyElement.children.forEach((classifier) => {
                classifier.children.forEach((group) => {
                  if (group.processGroupId === node.processGroupId) {
                    sortOrder = group.children.length
                  }
                })
              })
            }
          })
        }
      })
    })

    var params = {
      "studyId": node.studyId,
      "processName": this.state.title,
      "processGroupId": node.processGroupId,
      "processSyncFlag": 0,
      "checkOutFlag" : CheckOutCheckinFalg.CHECK_IN_FLAG,
      "isCopy":0,
      "sortOrder": sortOrder
    };
    if(params.processName === null || params.processName.trim() === "" ){
      that.props.setMessage('Process Name can\'t be empty.', MessageTypeConst.INFORMATION_MESSAGE);
      this.setState({ title:'', addStudyButtonDisable:false});
      $('#txtName').focus();
      return;
    }

    $(".loader").show();
    axios.all([restClient(CREATE, 'processflow/add', {data : params}, {'Tenant-ID': node.schemaName})])
        .then(axios.spread(function (response)  {
            if (typeof(response.data) !== 'undefined' && response.data.message != null && response.data.messageType !==  MessageTypeConst.SUCCESS_MESSAGE) {
              that.props.setMessage(response.data.message, response.data.messageType);
              that.setState({ addStudyButtonDisable:false});
              $('#txtName').focus();
            }
            else if (typeof(response.data) !== 'undefined' && response.data.processId > 0 && response.data.processGroupId > 0 && response.data.studyId > 0) {
              treeView.children.forEach((project) => {
                project.children.forEach((study) => {
                  if (study.study.studyId === response.data.studyId) {
                    study.children.forEach((studyElement) => {
                      if (studyElement.name === 'ProcessFlows') {
                        studyElement.children.forEach((classifier) => {
                          classifier.children.forEach((group) => {
                            if (group.processGroupId === response.data.processGroupId) {
                              group.toggled = true;
                              group.children.push(manageProcessInTreeView(Object.assign({}, response.data, { selected: false }), node.projectId, node.schemaName, study.study.studyName));
                              project.toggled = true;
                              study.toggled = true;
                              classifier.toggled = true;
                              study.study.processFlowGroup.filter(processGroup => processGroup.processGroupId === response.data.processGroupId).map(processGroup => {
                                if (processGroup.processFlow === null) {
                                  processGroup.processFlow = [];
                                }

                                processGroup.processFlow.push(response.data)
                              })
                            }
                          });
                        })
                      }
                    })
                  }
                })
              })

              that.updateTreeViewData(treeView);
              that.setState({ title:'',addStudyButtonDisable:false},()=>{
                that.toggleAddRenameTitleDialog();
              });
              that.props.setMessage("New '" + response.data.processName +"' PROCESS FLOW have been created Successfully!", MessageTypeConst.SUCCESS_MESSAGE);
            }

            $(".loader").hide();
        })
    ).catch(error => {
      that.setState({ addStudyButtonDisable:false});
      $(".loader").hide();
      $('#txtName').focus();
      that.props.setMessage("New '"+ this.state.title +"' PROCESS FLOW creation Failed!", MessageTypeConst.ERROR_MESSAGE);
    });
  }

  handleAddMetaTable = (event, data) => {
    if (typeof(global.selectedMetaDataTable) !== 'undefined') {
      delete global.selectedMetaDataTable;
    }
    this.props.setProjectId(data.node.projectId);
    this.props.setStudyId(data.node.studyId);
    this.props.setTenantId(data.node.schemaName);
    this.props.history.push("/metadata-editor");
  }

  handleEditMetaTable = (event, data) => {
    global.selectedMetaDataTable = {};
    global.selectedMetaDataTable = {srcTableName: data.node.name};

    this.props.setProjectId(data.node.projectId);
    this.props.setStudyId(data.node.studyId);
    this.props.setTenantId(data.node.schemaName);
    this.props.history.push("/metadata-editor");
  }

  handleManageStudyProperty = (event, data) => {
    if (data.node.children.length === 0) {
      this.appendStudyDetailInTreeView(data.node.study, false, false, false, true)
    } else {
      this.openStudyPropertyDetail(event, data.node.study)
    }
   /* $(".loader").show();
    axios.all([this.fetchStudyImportDirectory({}, {'Tenant-ID': tenantId}, studyProperty.studyName, projectName)])
          .then(axios.spread((response) => {
            if (typeof(response.data) !== 'undefined') {
              studyProperty.importDirectory = response.data.importDirectory;
              this.setState({ studyProperty: studyProperty, visiblePropertyStudy: true });
            }

            $(".loader").hide();
        })
    ).catch(error => {
      $(".loader").hide();
      this.props.setMessage("Error occurred while refreshing study properties.",MessageTypeConst.ERROR_MESSAGE);
    });*/

  }

  openStudyPropertyDetail = (event, nodeStudy) => {
    let studyProperty = this.state.studyProperty;
    let treeView = this.state.treeView;
    let projectName = '';
    let triggerNode = '';
    studyProperty.studyName = nodeStudy.studyName;
    studyProperty.identifier1 = nodeStudy.identifier1;
    studyProperty.identifier2 = nodeStudy.identifier2;
    studyProperty.identifier3 = nodeStudy.identifier3;
    studyProperty.classifier = nodeStudy.classifier;
    studyProperty.description = nodeStudy.description;
    studyProperty.studyId = nodeStudy.studyId

    this.props.studyList.filter(study => study.studyId === nodeStudy.studyId).forEach(study => {
      studyProperty.description = study.description;
    })
    treeView.children.forEach((project, index) => {
      if (projectName === '') {
        project.children.forEach((study) => {
          if (study.study.studyId === nodeStudy.studyId && projectName === '') {
              projectName = project.name
              triggerNode = study;
          }
        });
      }
    });
    studyProperty.projectName = projectName;
    this.props.setTenantId(nodeStudy.schemaName);
    this.setState({ studyProperty: studyProperty, visiblePropertyStudy: true, triggerNode: triggerNode });
  }

  handleImportSourceTable = (event, data) => {
    global.selectedStudy = {
      id: data.node.studyId,
      name: data.node.schemaName,
      tableType: 0,
      projectId:data.node.projectId
    }
    this.refs.import_source_meta_table.click();
  }

  handleImportMetaTable = (event, data) => {
    global.selectedStudy = {
      id: data.node.studyId,
      name: data.node.schemaName,
      tableType: 1,
      projectId:data.node.projectId
    }
    this.refs.import_source_meta_table.click();
  }

  toggleDialogStudyProperty = (event) => {
    this.removeTagRichGrid();
      this.setState({
          visiblePropertyStudy: !this.state.visiblePropertyStudy,
      });
  }

  handleImportFilesTableFilesChange(event, type = "local") {
        event.preventDefault();
        var target = event.target;
        var files = event.target.files;
        var importFiles = [];
        var sourceTables = [];
        var metaTables = [];
        var validFiles = [];

        if (type === 'server') {
          files = [];
          this.state.selectFileFromServer.forEach((file) => {
            files.push({name: file});
          })
        }

        if (files.length > 0) {
          let invalidFileList = [];
          for(var index = 0; index < files.length; index++ ) {
            if(!this.isValidFileName(files[index].name, true)){
              invalidFileList.push(files[index].name)
            }else{
              validFiles.push(files[index].name);
              importFiles.push({name: files[index].name, status: 'file-progress', log:''})
            }
          }

          if (invalidFileList.length > 0) {
            this.props.setMessage(invalidFileList.join(',') +" file "+(invalidFileList.length === 1 ? "is": "are")+" not valid. Only (.csv, .xpt, .sas7bdat, .xls, .xlsx) files are allowed.", MessageTypeConst.WARNING_MESSAGE);
          }
          files = [...files].filter( file => validFiles.indexOf(file.name) !== -1);
          let treeView = this.state.treeView;
          treeView.children.forEach((project, index) => {
              project.children.forEach((study) => {
                if (study.study.studyId === global.selectedStudy.id && study.study.schemaName === global.selectedStudy.name) {
                  if (typeof(study.study.metaTable) !== 'undefined' && study.study.metaTable !== null && study.study.metaTable.length > 0) {
                    metaTables = study.study.metaTable.map((item) => item.srcTableName);
                  }
                  if (typeof(study.study.sourceTable) !== 'undefined' && study.study.sourceTable !== null && study.study.sourceTable.length > 0) {
                    sourceTables = study.study.sourceTable.map((item) => item.srcTableName);
                  }
                  };
                });
             });
             let existingFileName = [];
             if(importFiles.length === 0){
              if(target !== null){
                target.value = '';
              }
              this.setState({ importDialogStatus : false, importFiles: importFiles });
              return;
             }
             importFiles.forEach((file)=>{
               let filename = file.name.substr(0, file.name.lastIndexOf(".")).toLowerCase();
               if(parseInt(global.selectedStudy.tableType, 10) === 0){
                  //check for source table
                  let filiterSourceTables  = sourceTables.filter((table) => table === filename);
                  if(filiterSourceTables.length > 0){
                    existingFileName.push(filename);
                  }
                  }
               else{
                let filiterMetaTables  = metaTables.filter((table) => table === filename+"_meta");
                if(filiterMetaTables.length > 0){
                  existingFileName.push(filename+"_meta");
                }
               }
            });
            if(existingFileName.length > 0){
              let message = "";
              existingFileName.forEach((name)=>{
                message = message + name + " , ";
              });
              message = message.substr(0 ,message.lastIndexOf(","));
              message = message + " Table(s) already exists.Do you want to update the existing version ? If not then please use a different filename.";
              ConfirmBox.open(message).then(()=>{
                this.setState({ importDialogStatus : true, importFiles: importFiles, invalidFileList: invalidFileList.map(temp => Object.assign({name: temp, log: 'Invalid file', status: 'fa fa-download warning'})), disabledOkButton:true });

                this.fileUploadProcess(files, 0, target);

              }).catch(()=>{
                importFiles = [];
                if(target !== null){
                  target.value = '';
                }
                this.setState({ importDialogStatus : false, importFiles: importFiles, disabledOkButton:true });
              });
            }else{
              this.setState({ importDialogStatus : true, importFiles: importFiles, invalidFileList: invalidFileList.map(temp => Object.assign({name: temp, log: '-', status: 'fa fa-download warning'})), disabledOkButton:true });

              this.fileUploadProcess(files, 0, target);
            }
        } else {
          if(target !== null){
            target.value = '';
          }
          this.setState({ importDialogStatus : false, importFiles: importFiles, invalidFileList: [], disabledOkButton:true });
        }
  }

  toggleImportDialog() {
        this.setState({
            importDialogStatus: !this.state.importDialogStatus,
        });
  }

  toggleImportDocumentDialog() {
    this.setState({
      importDocumentDialogStatus: !this.state.importDocumentDialogStatus,
    });
}
  toggleAddRenameTitleDialog(){
    this.removeTagRichGrid();
    if(!this.state.addStudyButtonDisable){
      this.setState({
        addRenameTitlePopupVisible: !this.state.addRenameTitlePopupVisible,
        addRenameTitleEventRaiseBy: 0,
        title:'',
        selected: 0,
        readOnlyAccess: false
      });
    }
  }

  toggleRenameStudyDialog = (event, data) => {
    let triggerNode = {};

    if (typeof(data) !== 'undefined') {
      triggerNode = data.node;
    }
    this.setState({
      addRenameTitlePopupVisible: !this.state.addRenameTitlePopupVisible,
      triggerNode: triggerNode,
      addRenameTitleEventRaiseBy: this.RENAME_STUDY_EVENT,
      title:data.node.name,
    });
  }

  toggleAddProjectDialog = (event, data) => {
    let triggerNode = {};

    if (typeof(data) !== 'undefined') {
      triggerNode = data.node;
    }
    this.setState({
      addRenameTitlePopupVisible: !this.state.addRenameTitlePopupVisible,
      triggerNode: triggerNode,
      addRenameTitleEventRaiseBy: this.ADD_PROJECT_EVENT,
    });
  }

  toggleRenameProjectDialog = (event, data) => {
    let triggerNode = {};

    if (typeof(data) !== 'undefined') {
      triggerNode = data.node;
    }
    this.setState({
      addRenameTitlePopupVisible: !this.state.addRenameTitlePopupVisible,
      triggerNode: triggerNode,
      addRenameTitleEventRaiseBy: this.RENAME_PROJECT_EVENT,
      title:data.node.name,
    });
  }

  toggleAddProcessGroupDialog = (event, data) => {
    let triggerNode = {};

    if (typeof(data) !== 'undefined') {
      triggerNode = data.node;
    }
    this.setState({
      addRenameTitlePopupVisible: !this.state.addRenameTitlePopupVisible,
      triggerNode: triggerNode,
      addRenameTitleEventRaiseBy: this.ADD_PROCESS_GROUP_EVENT,
    });
  }

  toggleAddProcessFlowDialog = (event, data) => {
    let triggerNode = {};

    if (typeof(data) !== 'undefined') {
      triggerNode = data.node;
    }
    this.setState({
      addRenameTitlePopupVisible: !this.state.addRenameTitlePopupVisible,
      triggerNode: triggerNode,
      addRenameTitleEventRaiseBy: this.ADD_PROCESS_FLOW_EVENT,
    });
  }

  toggleRenameProcessGroupDialog = (event, data) => {
    let triggerNode = {};
    let groupClassifierList = [];
    let selectedGroupClassifier = {};
    let readOnlyAccess = this.state.readOnlyAccess;

    if (typeof(data) !== 'undefined') {
      triggerNode = data.node;
    }

    if (typeof(triggerNode.projectId) !== 'undefined') {
      let studyList = this.state.treeView.children.filter(project => project.projectId === triggerNode.projectId);

      if (studyList.length > 0) {
        let study = studyList[0].children.filter(study => study.study.studyId === triggerNode.studyId);

        if (study.length > 0) {
          groupClassifierList = study[0].study.groupClassifier

          if (typeof(study[0].study.enableActions) !== 'undefined') {
            readOnlyAccess = study[0].study.enableActions.indexOf(AccessControlConstants.WRITE) === -1
          }

        }
      }

      if (typeof(triggerNode.classifierId) !== 'undefined' && groupClassifierList.filter(groupClassifier => groupClassifier.classifierId === triggerNode.classifierId).length > 0) {
        selectedGroupClassifier = groupClassifierList.filter(groupClassifier => groupClassifier.classifierId === triggerNode.classifierId)[0]
      }
    }


    this.setState({
      addRenameTitlePopupVisible: !this.state.addRenameTitlePopupVisible,
      triggerNode: triggerNode,
      addRenameTitleEventRaiseBy: this.RENAME_PROCESS_GROUP_EVENT,
      title:data.node.name,
      groupClassifierList: groupClassifierList,
      selectedGroupClassifier: selectedGroupClassifier,
      readOnlyAccess: readOnlyAccess
    });
  }

  handleChangeGroupClassifierEvent = (event) => {
    this.setState({
      selectedGroupClassifier: event.target.value,
    })
  }

  toggleRenameProcessFlowDialog = (event, data) => {
    let triggerNode = {};
    let that = this;
    let readOnlyAccess = this.state.readOnlyAccess;
    if (typeof(data) !== 'undefined') {
      triggerNode = data.node;

      if (typeof(triggerNode.projectId) !== 'undefined') {
        let studyList = this.state.treeView.children.filter(project => project.projectId === triggerNode.projectId);

        if (studyList.length > 0) {
          let study = studyList[0].children.filter(study => study.study.studyId === triggerNode.process.studyId);

          if (study.length > 0) {
            if (typeof(study[0].study.enableActions) !== 'undefined') {
              readOnlyAccess = study[0].study.enableActions.indexOf(AccessControlConstants.WRITE) === -1
            }
          }
        }
      }
    }

    if (typeof(data.node.process.checkOutFlag) !== 'undefined') {
      if (data.node.process.checkOutFlag === CheckOutCheckinFalg.CHECK_OUT_FLAG && data.node.process.userName !== this.props.token_detail['email']) {
        readOnlyAccess = true;
      }

      this.setState({
        addRenameTitlePopupVisible: !this.state.addRenameTitlePopupVisible,
        triggerNode: triggerNode,
        addRenameTitleEventRaiseBy: this.RENAME_PROCESS_FLOW_EVENT,
        title:data.node.name,
        readOnlyAccess: readOnlyAccess
      });
    } else {
      $(".loader").show();
      let fetchProcessFlow = (params, headers) => restClient(GET, 'processflow/'+data.node.process.processId, params, headers);
      axios.all([fetchProcessFlow({},{'Tenant-ID': data.node.schemaName})]).then(
        axios.spread(function (response)  {
          if(typeof(response) !== "undefined" && response !== null && response.data !== null){
            if(response.data.checkOutFlag === CheckOutCheckinFalg.CHECK_OUT_FLAG && response.data.userName !== that.props.token_detail['email']){
              readOnlyAccess = true;
            }

            $(".loader").hide();
            that.setState({
              addRenameTitlePopupVisible: !that.state.addRenameTitlePopupVisible,
              triggerNode: triggerNode,
              addRenameTitleEventRaiseBy: that.RENAME_PROCESS_FLOW_EVENT,
              title:data.node.name,
              readOnlyAccess: readOnlyAccess
            });
          }
        })
      ).catch(error => {
        $(".loader").hide();
        that.props.setMessage("Error occurred while refreshing Process Flow.",MessageTypeConst.ERROR_MESSAGE);
      });
    }
  }

  handleAddRenameOKButton(event){
    this.setState({'addStudyButtonDisable':true});
    switch(this.state.addRenameTitleEventRaiseBy){
      case this.ADD_PROJECT_EVENT:
        this.handleAddProject(event);
        break;
      case this.RENAME_PROJECT_EVENT:
        this.handleRenameProject(event);
        break;
      case this.RENAME_STUDY_EVENT:
        this.handleRenameStudy(event);
        break;
      case this.ADD_PROCESS_GROUP_EVENT:
        this.handleAddProcessFlowGroup(event);
        break;
      case this.ADD_PROCESS_FLOW_EVENT:
        this.handleAddProcessFlow(event);
        break;
      case this.RENAME_PROCESS_GROUP_EVENT:
        this.handleRenameProcessFlowGroup(event);
        break;
      case this.RENAME_PROCESS_FLOW_EVENT:
        this.handleRenameProcess(event);
        break;
      default:
        break;
    }
  }
  handleAddRenameTitleChange(event){
    this.setState({ title: event.target.value.replace(/[^-_\w\s]/gi, '') });
  }

  handleAddRenameClassifierChange(event){
    this.setState({ classifier: event.target.value });
  }

  enterKeyPressForAddRenameTitle(e){
    if(e.keyCode === 13){
      //enter key pressed
      this.handleAddRenameOKButton(e);
    }
    if(e.keyCode === 27){
      //escape key pressed
      this.toggleAddRenameTitleDialog();
    }
 }

  fileUploadProcess(files, index, target) {
        if (typeof(files[index]) !== 'undefined') {
            var that = this;
            this.fileUpload(files[index]).then((response)=>{
              if(typeof(response.data) !== 'undefined' && response.data !== null && response.data.messageType !== null && typeof(response.data.messageType) !== "undefined" && response.data.messageType === MessageTypeConst.WARNING_MESSAGE)
                  {
                   that.props.setMessage(response.data.message , MessageTypeConst.WARNING_MESSAGE);
                    var nextFileIndex = index +  1;
                    that.state.importFiles[index].status = 'fa fa-download fail';
                    if (typeof(response.data.importTables[0]) !== 'undefined' && typeof(response.data.importTables[0].importSuccess) !== 'undefined' && response.data.importTables[0].importSuccess === false)
                    {
                      that.state.importFiles[index].log = response.data.importTables[0].importLog;
                    }
                    while(typeof(files[nextFileIndex]) !== 'undefined'){
                      that.state.importFiles[nextFileIndex].status = 'fa fa-download fail';
                      nextFileIndex = nextFileIndex +  1;
                    }
                    that.setState({importFiles: this.state.importFiles,  disabledOkButton:false });
                    if (typeof(files[nextFileIndex]) === 'undefined') {
                      if(target !== null){
                        target.value = '';
                      }
                      return true;
                   }
                }
              if(response.data.flowsAndBlocks !== null && typeof((response.data.flowsAndBlocks) !== "undefined"))
                {
                  let processFlowStatus = [];
                  if(response.data.flowsAndBlocks.processBlock !== null
                    && typeof(response.data.flowsAndBlocks.processBlock) !== "undefined"
                  && response.data.flowsAndBlocks.processBlock.length > 0){
                    that.props.updateProcessFlowBlockBySchemaName(global.selectedStudy.name, response.data.flowsAndBlocks.processBlock);
                  }
                  if(response.data.flowsAndBlocks.processFlow !== null
                    && typeof(response.data.flowsAndBlocks.processFlow) !== "undefined"
                  && response.data.flowsAndBlocks.processFlow.length > 0){
                    response.data.flowsAndBlocks.processFlow.forEach((flow) => {
                      processFlowStatus.push({processId:flow.processId,processSyncFlag:flow.processSyncFlag, studyId: flow.studyId})
                    });
                    that.props.processFlowList.forEach((processFlow, index) => {
                      response.data.flowsAndBlocks.processFlow.forEach((flow) => {
                        if (processFlow.process.studyId === flow.studyId && processFlow.process.processId === flow.processId) {
                          that.props.updateProcessFlowSyncFlagByTabIndex(index, flow.processSyncFlag);
                          that.props.updateProcessflowBlockRenderStatus(index, true);
                        }});
                      });
                  }
                  that.updateFlowIconInTreeView(processFlowStatus);
                }

                if (typeof(response.data.importTables[0]) !== 'undefined' && typeof(response.data.importTables[0].importSuccess) !== 'undefined' && response.data.importTables[0].importSuccess === true) {
                  that.state.importFiles[index].status = 'fa fa-download completed';
                  that.state.importFiles[index].log = response.data.importTables[0].importLog;
                  that.setState({importFiles: that.state.importFiles });

                    let treeView = this.state.treeView;

                    treeView.children.forEach((project, index) => {
                      project.children.forEach((study) => {
                        if (global.selectedStudy.name === study.study.schemaName) {
                          project.toggled = true;
                          study.toggled = true;
                          study.children.forEach((studyElement) => {
                            let newImportTable = null;

                            if (global.selectedStudy.tableType === 0 && studyElement.name === 'Source Table') {
                              newImportTable = Object.assign({name: response.data.importTables[0].srcTableName,  projectId:project.projectId,
                                studyId:study.study.studyId, cssClassName: 'manage-study-edit-source-table', schemaName: study.study.schemaName, tableType: 0,
                                tag:'open-data-viewer', tableName:response.data.importTables[0].tableName});
                            } else if(global.selectedStudy.tableType === 1 && studyElement.name === 'Meta Table') {
                              newImportTable = Object.assign({name: response.data.importTables[0].srcTableName, projectId:project.projectId,
                                studyId:study.study.studyId, cssClassName: 'manage-study-edit-meta-table',schemaName: study.study.schemaName, tableType: 1,
                                tag:'open-data-viewer',tableName:response.data.importTables[0].tableName});
                            }

                            if (newImportTable !== null) {
                              let tableList = studyElement.children.slice();
                              let importTableIndex = tableList.length;
                              studyElement.children.forEach((table, index) => {
                                if (table.name === response.data.importTables[0].srcTableName) {
                                  importTableIndex = index;
                                }
                              })

                              tableList = tableList.filter(table => table.name !== response.data.importTables[0].srcTableName);

                              tableList.splice(importTableIndex, 0, newImportTable);

                              studyElement.toggled = true;
                              studyElement.children.splice(0);
                              studyElement.children.splice(0, 0, ...tableList);


                              let newTable = null;
                              if(newImportTable.tableType === 0){
                                newTable = Object.assign({srcTableName: response.data.importTables[0].srcTableName,
                                  tableId:response.data.importTables[0].tableId, tableName:response.data.importTables[0].tableName,tableVersion:response.data.importTables[0].tableVersion,
                                  versionLimit:response.data.importTables[0].versionLimit, importLog: '', tableType: 0, timestamp:response.data.importTables[0].timestamp});
                                   if(study.study.sourceTable === null || typeof(study.study.sourceTable) === "undefined"){
                                    study.study.sourceTable  =[];
                                   }
                                  let sourceTable = study.study.sourceTable.filter(table => table.srcTableName !== response.data.importTables[0].srcTableName);
                                  sourceTable.splice(sourceTable.length, 0, newTable);
                                  study.study.sourceTable.splice(0);
                                  study.study.sourceTable.splice(0, 0, ...sourceTable);

                              }else{
                                  newTable = Object.assign({srcTableName: response.data.importTables[0].srcTableName,
                                  tableId:response.data.importTables[0].tableId, tableName:response.data.importTables[0].tableName,tableVersion:response.data.importTables[0].tableVersion,
                                  versionLimit:response.data.importTables[0].versionLimit, importLog: '', tableType: 1, timestamp:response.data.importTables[0].timestamp});
                                  if(study.study.metaTable === null || typeof(study.study.metaTable) === "undefined"){
                                    study.study.metaTable  =[];
                                   }
                                  let metaTable = study.study.metaTable.filter(table => table.srcTableName !== response.data.importTables[0].srcTableName);
                                  metaTable.splice(metaTable.length, 0, newTable);
                                  study.study.metaTable.splice(0);
                                  study.study.metaTable.splice(0, 0, ...metaTable);
                              }

                              that.props.addImportTable(newImportTable);
                            }
                          })
                        }
                      });
                    });

                    that.updateTreeViewData(treeView);

                }else{
                  that.state.importFiles[index].status = 'fa fa-download fail';
                  that.state.importFiles[index].log = response.data.importTables[0].importLog;

                  that.setState({importFiles: this.state.importFiles });
                }

                /*if(typeof(response.data) !== 'undefined' && response.data !== null && typeof(response.data.message) !== "undefined" && typeof(response.data.messageType) !== "undefined") {
                     that.props.setMessage(response.data.message, response.data.messageType);
                }*/
                if(index === files.length -1){
                  that.setState({ disabledOkButton:false});
                }
                var nextFileIndex = index +  1;
                if (typeof(files[nextFileIndex]) !== 'undefined') {
                    return that.fileUploadProcess(files, nextFileIndex, target);
                } else {
                  if(target !== null){
                    target.value = '';
                  }
                    return true;
                }
            }).catch(error => {
              that.props.setMessage("Error occurred while uploading '"+files[index].name+"'.",MessageTypeConst.ERROR_MESSAGE);
              var nextFileIndex = index +  1;
              that.state.importFiles[index].status = 'fa fa-download fail';
              that.setState({importFiles: this.state.importFiles });
              if(index === files.length -1){
                that.setState({ disabledOkButton:false});
              }
              if (typeof(files[nextFileIndex]) === 'undefined') {
                if(target !== null){
                  target.value = '';
                }
                return true;
              }else{
                return that.fileUploadProcess(files, nextFileIndex, target);
              }
            });

          } else {
            if(target !== null){
              target.value = '';
            }
            return true;
        }
    }

  fileUpload(file) {
    let url = SERVER_URL +'/study/import';
    let tenantID = '';
    let formData = new FormData();
    let config = {};

    let authorizationToken = null;
    if (typeof(global.login_token) !== 'undefined') {
      authorizationToken = 'Bearer '+ global.login_token.id_token;
    }

    if (typeof(file.size) !== 'undefined') {
      url = SERVER_URL +'/study/import';
      formData = new FormData();

      formData.append('files', file);

      if (typeof(global.selectedStudy) !== 'undefined') {
        tenantID = global.selectedStudy.name;
        formData.append('studyId', global.selectedStudy.id);
        formData.append('studyName', global.selectedStudy.name);
        formData.append('tableType', global.selectedStudy.tableType);
        formData.append('projectId', global.selectedStudy.projectId);
      }

      config = {
          headers: {
              'Tenant-ID': tenantID,
              'content-type': 'multipart/form-data',
              'Authorization': authorizationToken
          }
      }
    } else {
      let fileName = file.name.split('/');

      if (fileName.length > 0) {
        fileName = fileName[fileName.length - 1];
      }

      if (typeof(global.selectedStudy) !== 'undefined') {
        url = SERVER_URL +'/importServerFile';
        formData = {
        	  fileName: fileName,
            studyName: global.selectedStudy.name,
            filePath: file.name,
            studyId: global.selectedStudy.id,
            projectId: global.selectedStudy.projectId,
            tableType: global.selectedStudy.tableType
          };

        config = {
            headers: {
                'Tenant-ID': global.selectedStudy.schemaName,
                'Access-Control-Allow-Header': '*',
                'Authorization': authorizationToken
            }
        };
      }
    }

    return axios.post(url, formData, config);
  }

  render() {
    return template.call(this);
  }

  handleRemoveTable(event, data){
    let that = this;
    let treeView = this.state.treeView;
    let node = data.node;
    let message = 'Are you sure you want to delete table \'' + data.node.name + '\'?';
    ConfirmBox.open(message).then(()=>{
      $(".loader").show();
      axios.all([restClient(DELETE, 'table/deleteTable', {id:data.node.name}, {'Tenant-ID': data.node.schemaName})])
            .then(axios.spread((response) => {
              if(response !== null && response.data !== null && response.data.message !== ""){
                if(response.data.messageType === MessageTypeConst.SUCCESS_MESSAGE){
                treeView.children.forEach((project, index) => {
                  if (project.projectId === node.projectId) {
                    project.children.forEach((study) => {
                      if (study.study.studyId === node.studyId) {
                        project.toggled = true;
                        study.toggled = true;
                        if (typeof(study.study.metaTable) !== 'undefined' && study.study.metaTable !== null && study.study.metaTable.length > 0) {
                          study.study.metaTable = study.study.metaTable.filter((item) => item.srcTableName !== node.name);
                        }
                        if (typeof(study.study.sourceTable) !== 'undefined' && study.study.sourceTable !== null && study.study.sourceTable.length > 0) {
                          study.study.sourceTable = study.study.sourceTable.filter((item) => item.srcTableName !== node.name);
                        }
                        study.children.forEach((studyElement) => {
                            let tableList = studyElement.children.slice();
                            tableList = tableList.filter(table => table.name !== node.name);
                            studyElement.children.splice(0);
                            studyElement.children.splice(0, 0, ...tableList);
                          })
                        };
                      });
                    }
                   });
                   that.updateTreeViewData(treeView);
                   that.props.removeImportTable(node);
                  }
                }
                that.props.setMessage(response.data.message, response.data.messageType);
                $(".loader").hide();
            })).catch(error => {
              that.props.setMessage("Table deletion Failed!", MessageTypeConst.ERROR_MESSAGE);
              $(".loader").hide();
            });
    });

  }

  handleGroupExecuteAll(event, data, executeAllInteractiveStatus = false){
    this.setState({executeAllDialogStatus : true, executeAllInteractiveStatus: executeAllInteractiveStatus, executeAllType:ExecuteAllTypeConst.ProcessGroupLevelExecuteAll, executeAllDataNode:data.node});
  }

  handleExecuteAll(event, data, executeAllInteractiveStatus = false){
    let that = this;
    let node = Object.assign({},data.node,{studyId:data.node.process.studyId})
    let processFlow = that.props.processFlowList.filter(processFlow => processFlow.projectId === data.node.projectId && processFlow.schemaName === data.node.schemaName && processFlow.process.processId === data.node.process.processId);

    if (processFlow.length > 0) {
      data.node.process.checkOutFlag = processFlow[0].process.checkOutFlag;
      data.node.process.userName = processFlow[0].process.userName;
    }
    if(window.location.host === "localhost:3000"){
      this.setState({executeAllDialogStatus : true, executeAllInteractiveStatus: executeAllInteractiveStatus, executeAllType:ExecuteAllTypeConst.ProcessFlowLevelExecuteAll, executeAllDataNode:node});
      return;
    }
    if(data.node.process.checkOutFlag === CheckOutCheckinFalg.CHECK_OUT_FLAG && data.node.process.userName === this.props.token_detail['email']){
      this.setState({executeAllDialogStatus : true, executeAllInteractiveStatus: executeAllInteractiveStatus, executeAllType:ExecuteAllTypeConst.ProcessFlowLevelExecuteAll, executeAllDataNode:node});
    }
    else if(data.node.process.checkOutFlag === CheckOutCheckinFalg.CHECK_OUT_FLAG){
      that.props.setMessage(`Process Flow checked-out by '${data.node.process.userName}'.`,MessageTypeConst.WARNING_MESSAGE);
    }
    else{
      $(".loader").show();
      let fetchProcessFlow = (params, headers) => restClient(GET, 'processflow/'+data.node.process.processId, params, headers);
      axios.all([fetchProcessFlow({},{'Tenant-ID': data.node.schemaName})]).then(
        axios.spread(function (response)  {
          if(typeof(response) !== "undefined" && response !== null && response.data !== null){
            if(response.data.checkOutFlag === CheckOutCheckinFalg.CHECK_OUT_FLAG && response.data.userName === this.props.token_detail['email']){
              $(".loader").hide();
              this.setState({executeAllDialogStatus : true, executeAllInteractiveStatus: executeAllInteractiveStatus, executeAllType:ExecuteAllTypeConst.ProcessFlowLevelExecuteAll, executeAllDataNode:node});
            }else{
              $(".loader").hide();
              that.props.setMessage("Process Flow needs to check-out before performing 'Execute All'.",MessageTypeConst.WARNING_MESSAGE);
            }
          }
        })
      ).catch(error => {
        $(".loader").hide();
        that.props.setMessage("Error occurred while refreshing Process Flow.",MessageTypeConst.ERROR_MESSAGE);
      });
    }
  }
  handleExecuteAllWarning(event, data, executeAllInteractiveStatus = false){
    let that = this;
    let node = Object.assign({},data.node,{studyId:data.node.process.studyId})
    let processFlow = that.props.processFlowList.filter(processFlow => processFlow.projectId === data.node.projectId && processFlow.schemaName === data.node.schemaName && processFlow.process.processId === data.node.process.processId);

    if (processFlow.length > 0) {
      data.node.process.checkOutFlag = processFlow[0].process.checkOutFlag;
      data.node.process.userName = processFlow[0].process.userName;
    }

    if(window.location.host === "localhost:3000"){
      this.setState({executeAllDialogStatus : true, executeAllInteractiveStatus: executeAllInteractiveStatus, executeAllType:ExecuteAllTypeConst.ProcessFlowLevelExecuteAllWarning, executeAllDataNode:node});
      return;
    }
    if(data.node.process.checkOutFlag === CheckOutCheckinFalg.CHECK_OUT_FLAG && data.node.process.userName === this.props.token_detail['email']){
    this.setState({executeAllDialogStatus : true, executeAllInteractiveStatus: executeAllInteractiveStatus, executeAllType:ExecuteAllTypeConst.ProcessFlowLevelExecuteAllWarning, executeAllDataNode:node});
    }
    else if(data.node.process.checkOutFlag === CheckOutCheckinFalg.CHECK_OUT_FLAG){
      that.props.setMessage(`Process Flow checked-out by '${data.node.process.userName}'.`,MessageTypeConst.WARNING_MESSAGE);
    }
    else{
      $(".loader").show();
      let fetchProcessFlow = (params, headers) => restClient(GET, 'processflow/'+data.node.process.processId, params, headers);
      axios.all([fetchProcessFlow({},{'Tenant-ID': data.node.schemaName})]).then(
        axios.spread(function (response)  {
          if(typeof(response) !== "undefined" && response !== null && response.data !== null){
            if(response.data.checkOutFlag === CheckOutCheckinFalg.CHECK_OUT_FLAG && response.data.userName === this.props.token_detail['email']){
              $(".loader").hide();
              this.setState({executeAllDialogStatus : true, executeAllInteractiveStatus: executeAllInteractiveStatus, executeAllType:ExecuteAllTypeConst.ProcessFlowLevelExecuteAllWarning, executeAllDataNode:node});
            }else{
              $(".loader").hide();
              that.props.setMessage("Process Flow needs to check-out before performing 'Execute All (Warning)'.",MessageTypeConst.WARNING_MESSAGE);
            }
          }
        })
      ).catch(error => {
        $(".loader").hide();
        that.props.setMessage("Error occurred while refreshing Process Flow.",MessageTypeConst.ERROR_MESSAGE);
      });
    }
  }

  isValidFileName(fileName, checkExtension = false){
    if(checkExtension){
      if(!(fileName.endsWith(".csv") || fileName.endsWith(".xpt") || fileName.endsWith(".sas7bdat") || fileName.endsWith(".xls") || fileName.endsWith(".xlsx"))){
        return false;
      }
    }
    return true;
    // fileName = fileName.substring(0, fileName.lastIndexOf('.')) || fileName
    // let isFileValid= false;
    // let fileLength = fileName.length;
    // let newlength = fileName.replace(/[.]/g,"").length;
    // if(/[\s]/.test(fileName)){
    //   isFileValid = false;
    //   return isFileValid;
    // }
    // if(fileLength - newlength > 1){
    //   //file contains more than one '.'
    //   isFileValid = false;
    //   return isFileValid;
    // }
    // fileName = fileName.replace(/[.]/g,"");
    // if(/\b([a-zA-Z0-9_]){1,31}$\b/ig.test(fileName)){
    //   isFileValid = true;
    //   return isFileValid;
    // }
    // return isFileValid;
  }

  executeJobs(event){
    this.setState({disabledCloseButton:true, disabledExecuteButton: true});
    let jobs = this.executeAllRef.getWrappedInstance().getJobs();
    let apiJobObject = [];
    let {isKeepLinkData, isKeepAllData} = this.executeAllRef.getWrappedInstance().getStateDetail();
    jobs.forEach((item)=>{
        apiJobObject.push({
          inputTable: item.inputTable,
          outputTable:item.outputTable,
          operation:item.operation,
          inputBlockId:item.inputBlockId,
          outputBlockId:item.outputBlockId,
          log:'',
          processId:item.processId,
          processGroupId:item.processGroupId,
          rightInputWhereCondition:item.rightInputWhereCondition,
          leftInputWhereCondition:item.leftInputWhereCondition,
          status:false,
          isSampleFiltered: item.isSampleFiltered,
          leftSampleFilterApplied: item.leftSampleFilterApplied,
          rightSampleFilterApplied: item.rightSampleFilterApplied
        });
      }
    )
    var that = this;

    $(".loader").show();
    axios.all([restClient(CREATE, 'processflow/executeAll'+(this.state.executeAllInteractiveStatus === true ? 'Interactive' :'')+'?executeType='+this.state.executeAllType+'&processIdList='+this.state.processIdList.join(",")+'&keepLinkData='+isKeepLinkData+'&keepAllData='+isKeepAllData, {data : apiJobObject}, {'Tenant-ID': this.state.executeAllDataNode.schemaName})])
                .then(axios.spread(function (response)  {
                  let processFlowStatus = [];

                  that.setState({disabledCloseButton:false, disabledExecuteButton: false});
                  $(".loader").hide();
                  if (that.state.executeAllInteractiveStatus === false) {
                    if(response !== null && response.data !== null && typeof(response.data) !== "undefined" && typeof(response.data.message) !== "undefined" && typeof(response.data.messageType) !== "undefined" && response.data.messageType !== null){
                      that.props.setMessage(response.data.message, response.data.messageType);
                    }
                    that.setState({executeAllDialogStatus:false});
                  } else {
                    if(response.data.jobs !== null && typeof(response.data.jobs) !== "undefined"){
                      that.executeAllRef.getWrappedInstance().updateJobStatus(response.data.jobs);
                    }
                    if(response.data.blocks !== null && typeof(response.data.blocks) !== "undefined"){
                      if(response.data.blocks.processBlock !== null && typeof(response.data.blocks.processBlock) !== "undefined" && response.data.blocks.processBlock.length > 0){
                        that.props.updateProcessFlowBlockBySchemaName(that.state.executeAllDataNode.schemaName, response.data.blocks.processBlock);
                      }
                      if(response.data.blocks.processFlow !== null && typeof(response.data.blocks.processFlow) !== "undefined"
                      && response.data.blocks.processFlow.length > 0){
                        response.data.blocks.processFlow.forEach((flow) => {
                          processFlowStatus.push({processId:flow.processId,processSyncFlag:flow.processSyncFlag, studyId: flow.studyId, isSampleFiltered: flow.isSampleFiltered})
                        });
                        that.props.processFlowList.forEach((processFlow, index) => {
                          response.data.blocks.processFlow.forEach((flow) => {
                            if (processFlow.process.studyId === flow.studyId && processFlow.process.processId === flow.processId) {
                              that.props.updateProcessFlowSyncFlagByTabIndex(index, flow.processSyncFlag);
                            }
                          });
                          that.props.updateProcessflowBlockRenderStatus(index, true);
                        });
                      }
                      //target.disabled = false;
                      that.props.toggleRefreshToken();
                      that.updateFlowIconInTreeView(processFlowStatus);
                      //that.props.history.push("/process-flow-list");
                    }

                    if(response !== null && response.data !== null && typeof(response.data) !== "undefined" && typeof(response.data.message) !== "undefined" && typeof(response.data.messageType) !== "undefined" && response.data.messageType !== null && response.data.messageType !== MessageTypeConst.SUCCESS_MESSAGE){
                      that.props.setMessage(response.data.message, response.data.messageType);
                    }
                  }

                })).catch(error => {
                  that.setState({executeAllDialogStatus:false, disabledExecuteButton: false});
                  $(".loader").hide();
                  let message = that.state.executeAllType === ExecuteAllTypeConst.ProcessFlowLevelExecuteAll ? 'Execute All': 'Execute All (Warning)'
                  that.props.setMessage(message + " failed.",MessageTypeConst.ERROR_MESSAGE);
                });
  }

  updateFlowIconInTreeView(processFlowStatus){
    var that = this;
    let treeView = that.state.treeView;
    treeView.children.forEach((project, index) => {
      project.children.forEach((study) => {
        processFlowStatus.forEach((updatedFlow)=>{
          if (study.study.studyId === updatedFlow.studyId) {
            study.children.forEach((studyElement) => {
              if (studyElement.name === 'ProcessFlows') {
                studyElement.children.forEach((classifier) => {
                  classifier.children.forEach((processGroup) => {
                    processGroup.children.map((flow)=>{
                      if(updatedFlow.processId === flow.process.processId){
                        project.toggled = true;
                        study.toggled = true;
                        flow.process.processSyncFlag = updatedFlow.processSyncFlag;

                        if(updatedFlow.processSyncFlag === 2){
                          flow.iconClass = "fa fa-exclamation-triangle yellow";
                        }
                        else if(updatedFlow.processSyncFlag === 3){
                          flow.iconClass = "fa fa-exclamation-circle red";
                        }
                        else if(updatedFlow.processSyncFlag === 1){
                          flow.iconClass = "fa fa-microchip";
                        }
                        if (typeof(updatedFlow.isSampleFiltered) !== 'undefined') {
                            flow.process.isSampleFiltered = updatedFlow.isSampleFiltered
                        }
                      }
                      return flow;
                    })
                  });
                })

              }
            })
          }
        })


     });
    })
    that.updateTreeViewData(treeView);
  }

  closeExecuteAllDialog()
  {
    var that = this;
    if(!this.state.disabledCloseButton){
      let flowcheckedInByApi = this.executeAllRef.getWrappedInstance().getFlowcheckedInByApi();
      if (flowcheckedInByApi !== null && flowcheckedInByApi.length > 0) {

        $(".loader").show();
        axios.all([this.executeAllCloseProcessFlowList({data: flowcheckedInByApi},{'Tenant-ID': this.state.executeAllDataNode.schemaName})])
            .then(axios.spread(function (response)  {
              let processFlowStatus = [];
              if(response !== null && response.data !== null && typeof(response.data) !== "undefined"){
                if(typeof(response.data.processFlow) !== "undefined"){
                  response.data.processFlow.forEach((flow) => {
                    processFlowStatus.push({processId:flow.processId,processSyncFlag:flow.processSyncFlag, studyId: flow.studyId})
                  });
                  that.props.processFlowList.forEach((processFlow, index) => {
                    response.data.processFlow.forEach((flow) => {
                      if (processFlow.process.studyId === flow.studyId && processFlow.process.processId === flow.processId) {
                        that.props.updateProcessFlowSyncFlagByTabIndex(index, flow.processSyncFlag);
                      }
                    });
                    that.props.updateProcessflowBlockRenderStatus(index, true);
                  });
                }

                that.props.toggleRefreshToken();
                that.updateFlowIconInTreeView(processFlowStatus);
              }
              $(".loader").hide();
            })).catch(error => {
              $(".loader").hide();
              that.props.setMessage("Process flow close operation failed.",MessageTypeConst.ERROR_MESSAGE);
            });
      }
      this.setState({executeAllDialogStatus : false});
    } else {
      this.setState({executeAllDialogStatus : false});
    }


  }

  handleImportDocuments(event, data){
    global.selectedStudy = {
      id: data.node.studyId,
      name: data.node.schemaName,
      tableType: 2,
      projectId:data.node.projectId
    }
    this.refs.import_documents.click();

  }

  handleImportDocumentsFilesChange(event) {
    event.preventDefault();
    var target = event.target;
    var files = event.target.files;
    var importFiles = [];
    var documents = [];
    //var validFiles = [];
    if (files.length > 0) {
      for(var index = 0; index < files.length; index++ ) {
        /*if(!this.isValidFileName(files[index].name)){
          kendo.alert(files[index].name +" file is not valid. Only Alpha-Numeric characters are allowed in file name (upto length 32) and it should not contain space.");
          if(target !== null){
            target.value = '';
          }
        }else{
          validFiles.push(files[index].name);
          importFiles.push({name: files[index].name, status: 'file-progress'})
        }*/
        importFiles.push({name: files[index].name, status: 'file-progress'})
      }
      //files = [...files].filter( file => validFiles.indexOf(file.name) !== -1);
      let treeView = this.state.treeView;
      treeView.children.forEach((project, index) => {
          project.children.forEach((study) => {
            if (study.study.studyId === global.selectedStudy.id && study.study.schemaName === global.selectedStudy.name) {
              if (typeof(study.study.studyDocuments) !== 'undefined' && study.study.studyDocuments !== null && study.study.studyDocuments.length > 0) {
                documents = study.study.studyDocuments.map((item) => item.filename);
              }
              };
            });
          });
      let existingFileName = [];
      if(importFiles.length === 0){
        if(target !== null){
          target.value = '';
        }
        this.setState({ importDialogStatus : false, importFiles: importFiles });
        return;
      }
      importFiles.forEach((file)=>{
        if(parseInt(global.selectedStudy.tableType, 10) === 2){
          //check for source table
          let filiterDocs  = documents.filter((doc) => doc === file.name);
          if(filiterDocs.length > 0){
            existingFileName.push(file.name);
          }
          }
      });
      if(existingFileName.length > 0){
        let message = "";
        existingFileName.forEach((name)=>{
          message = message + name + " , ";
        });
        message = message.substr(0 ,message.lastIndexOf(","));
        message = message + " document(s) already exists.Do you want to replace the existing document(s) ?";
        ConfirmBox.open(message).then(()=>{
          this.setState({ importDocumentDialogStatus : true, importFiles:importFiles, disabledOkButton : true});
          this.documentUploadProcess(files, 0, target);

        }).catch(()=>{
          importFiles = [];
          if(target !== null){
            target.value = '';
          }
          this.setState({ importDocumentDialogStatus : false, importFiles:importFiles, disabledOkButton : true });
        });
      }else{
        this.setState({ importDocumentDialogStatus : true, importFiles:importFiles,disabledOkButton : true });

        this.documentUploadProcess(files, 0, target);
      }
    }else {
      if(target !== null){
        target.value = '';
      }
      this.setState({ importDocumentDialogStatus : false, importFiles:importFiles,disabledOkButton : true });
    }

  }

  documentUpload(file) {
    const url = SERVER_URL +'/study/import/document';
    const formData = new FormData();
    var tenantID = '';
    formData.append('files', file);

    if (typeof(global.selectedStudy) !== 'undefined') {
      tenantID = global.selectedStudy.name;
      formData.append('studyId', global.selectedStudy.id);
      formData.append('studyName', global.selectedStudy.name);
      formData.append('tableType', global.selectedStudy.tableType);
      formData.append('projectId', global.selectedStudy.projectId);
    }

    let authorizationToken = null;
    if (typeof(global.login_token) !== 'undefined') {
      authorizationToken = 'Bearer '+ global.login_token.id_token;
    }

    const config = {
        headers: {
            'Tenant-ID': tenantID,
            'content-type': 'multipart/form-data',
            'Authorization': authorizationToken
        }
    }
    return axios.post(url, formData, config)
  }

  documentUploadProcess(files, index, target) {
    let that = this;
    if (typeof(files[index]) !== 'undefined') {
        this.documentUpload(files[index]).then((response)=>{
          if(typeof(response.data) !== 'undefined' && response.data !== null && response.data.messageType !== null && typeof(response.data.messageType) !== "undefined" && response.data.messageType === MessageTypeConst.WARNING_MESSAGE)
          {
           that.props.setMessage(response.data.message , MessageTypeConst.WARNING_MESSAGE);
           var nextFileIndex = index +  1;
           let importFilesState = this.state.importFiles;
           importFilesState[index].status = 'fa fa-download fail';
           while(typeof(files[nextFileIndex]) !== 'undefined'){
            importFilesState[nextFileIndex].status = 'fa fa-download fail';
            nextFileIndex = nextFileIndex +  1;
          }
           this.setState({importFiles: importFilesState, disabledOkButton:false });
           if (typeof(files[nextFileIndex]) === 'undefined') {
            if(target !== null){
              target.value = '';
            }
            return true;
           }
          }
            if (typeof(response.data) !== 'undefined' && response.data !== null &&
                typeof(response.data.studyDocuments) !== 'undefined' && response.data.studyDocuments !== null
                && response.data.studyDocuments.length > 0) {

                let processFlowStatus = [];
                if(response.data.processBlock !== null
                  && typeof(response.data.processBlock) !== "undefined"
                && response.data.processBlock.length > 0){
                  that.props.updateProcessFlowBlockBySchemaName(global.selectedStudy.name, response.data.processBlock);
                }
                if(response.data.processFlow !== null
                  && typeof(response.data.processFlow) !== "undefined"
                && response.data.processFlow.length > 0){
                  response.data.processFlow.forEach((flow) => {
                    processFlowStatus.push({processId:flow.processId,processSyncFlag:flow.processSyncFlag, studyId: flow.studyId})
                  });
                  that.props.processFlowList.forEach((processFlow, index) => {
                    response.data.processFlow.forEach((flow) => {
                      if (processFlow.process.studyId === flow.studyId && processFlow.process.processId === flow.processId) {
                        that.props.updateProcessFlowSyncFlagByTabIndex(index, flow.processSyncFlag);
                        that.props.updateProcessflowBlockRenderStatus(index, true);
                      }});
                    });
                }
                that.updateFlowIconInTreeView(processFlowStatus);

                let importFilesState = this.state.importFiles;
                importFilesState[index].status = 'fa fa-download completed';

                this.setState({importFiles: importFilesState });

                let treeView = this.state.treeView;

                treeView.children.forEach((project, index) => {
                  project.children.forEach((study) => {
                    if (global.selectedStudy.name === study.study.schemaName) {
                      project.toggled = true;
                      study.toggled = true;
                      study.children.forEach((studyElement) => {
                        let newDoc = null;

                        if (global.selectedStudy.tableType === 2 && studyElement.name === 'Documents') {
                          newDoc = Object.assign({name: response.data.studyDocuments[0].filename,  projectId:project.projectId,
                            studyId:study.study.studyId, cssClassName: 'manage-study-edit-documents',
                            fileId: response.data.studyDocuments[0].fileId, path: response.data.studyDocuments[0].filepath,
                            schemaName: study.study.schemaName, tableType: 2});
                        }
                        if(studyElement.children === null || typeof(studyElement.children) === "undefined"){
                          studyElement.children = [];
                        }
                        if (newDoc !== null) {
                          let tableList = studyElement.children.slice();
                          let importTableIndex = tableList.length;
                          studyElement.children.forEach((table, index) => {
                            if (table.name === response.data.studyDocuments[0].filename) {
                              importTableIndex = index;
                            }
                          })

                          tableList = tableList.filter(table => table.name !== response.data.studyDocuments[0].filename);

                          tableList.splice(importTableIndex, 0, newDoc);

                          studyElement.toggled = true;
                          studyElement.children.splice(0);
                          studyElement.children.splice(0, 0, ...tableList);

                          if(newDoc.tableType === 2){
                            if(study.study.studyDocuments === null || typeof(study.study.studyDocuments) === "undefined"){
                              study.study.studyDocuments  = [];
                             }
                              let docuemnts = study.study.studyDocuments.filter(table => table.name !== response.data.studyDocuments[0].filename);;
                              docuemnts.splice(docuemnts.length, 0, response.data.studyDocuments[0]);

                              study.study.studyDocuments.splice(0);
                              study.study.studyDocuments.splice(0, 0, ...docuemnts);
                          }

                          //this.props.updateProcessFlowBlockBySchemaName(study.study.schemaName, response.data.infoUpdates[1])

                          //this.props.addImportTable(newImportTable);
                        }
                      })
                    }
                  });
                });

                this.updateTreeViewData(treeView);
                that.props.toggleRefreshToken();

            } else{
              let importFilesState = this.state.importFiles;
              importFilesState[index].status = 'fa fa-download fail';

              this.setState({importFiles: importFilesState });
            }
            if(index === files.length -1){
              this.setState({ disabledOkButton:false});
            }
            var nextFileIndex = index +  1;
            if (typeof(files[nextFileIndex]) !== 'undefined') {
                return this.documentUploadProcess(files, nextFileIndex, target);
            } else {
              if(target !== null){
                target.value = '';
              }
                return true;
            }
        }).catch(error => {
          that.props.setMessage("Error occurred while uploading '"+files[index].name+"'.",MessageTypeConst.ERROR_MESSAGE);
          var nextFileIndex = index +  1;
          let importFilesState = this.state.importFiles;
          importFilesState[index].status = 'fa fa-download fail';
          this.setState({importFiles: importFilesState });
          if(index === files.length -1){
            that.setState({ disabledOkButton:false});
          }
          if (typeof(files[nextFileIndex]) === 'undefined') {
            if(target !== null){
              target.value = '';
            }
            return true;
          }else{
            return that.documentUploadProcess(files, nextFileIndex, target);
          }
        });

      } else {
        if(target !== null){
          target.value = '';
        }
        return true;
    }
}

  toggleLogPopUp(){
    this.setState({showLogPopUp:!this.state.showLogPopUp});
  }

  showLog(event){
    let index = parseInt(event.target.value, 10);

    let logData = '';
    if(this.state.importFiles[index] !== null && typeof(this.state.importFiles[index]) !== "undefined"){
      logData = this.state.importFiles[index].log;
    } else if (typeof(this.state.importFiles[index]) === 'undefined' && [...this.state.importFiles, ...this.state.invalidFileList][index] !== null && typeof([...this.state.importFiles, ...this.state.invalidFileList][index]) !== 'undefined') {
      logData = [...this.state.importFiles, ...this.state.invalidFileList][index].log;
    }
    this.setState({logData:logData});
    this.toggleLogPopUp();

  }

  handleDeleteDocuments(event, data){
    let that = this;
    let treeView = this.state.treeView;
    let node = data.node;
    let message = 'Are you sure you want to delete document \'' + data.node.name + '\'?';
    ConfirmBox.open(message).then(()=>{
      $(".loader").show();
      axios.all([restClient(DELETE, 'study/document', {id:data.node.fileId}, {'Tenant-ID': data.node.schemaName})])
            .then(axios.spread((response) => {
              if(response !== null && response.data !== null && response.data.message !== ""){
                if(response.data.messageType === MessageTypeConst.SUCCESS_MESSAGE){
                treeView.children.forEach((project, index) => {
                  if (project.projectId === node.projectId) {
                    project.children.forEach((study) => {
                      if (study.study.studyId === node.studyId) {
                        project.toggled = true;
                        study.toggled = true;
                        if (typeof(study.study.studyDocuments) !== 'undefined' && study.study.studyDocuments !== null &&
                         study.study.studyDocuments.length > 0) {
                          study.study.studyDocuments = study.study.studyDocuments.filter((item) => item.filename !== node.name);
                        }
                        study.children.forEach((studyElement) => {
                            let tableList = studyElement.children.slice();
                            /*let importTableIndex = 0;
                            studyElement.children.forEach((table, index) => {
                              if (table.name === node.name) {
                                importTableIndex = index;
                              }
                            })*/

                            tableList = tableList.filter(table => table.name !== node.name);
                            //studyElement.toggled = true;
                            studyElement.children.splice(0);
                            studyElement.children.splice(0, 0, ...tableList);
                          })
                        };
                      });
                    }
                   });
                   that.updateTreeViewData(treeView);
                  }
                  $(".loader").hide();
                that.props.setMessage(response.data.message, response.data.messageType);

                }
            })).catch(error => {
              $(".loader").hide();
              that.props.setMessage("Study Document deletion Failed!", MessageTypeConst.ERROR_MESSAGE);
            });
    });

  }

  handleExportProcessFlow(event, data){
    let processObject = data.node.process;
    this.handleExport(data.node.schemaName,ExportTypeConst.EXPORT_PROCESS_FLOW, processObject.studyId, processObject.processGroupId, processObject.processId);
  }

  handleExportProcessGroup(event, data){
    let grpObj = data.node;
    this.handleExport(data.node.schemaName,ExportTypeConst.EXPORT_PROCESS_FLOW_GROUP,grpObj.studyId,grpObj.processGroupId, 0, '', grpObj.classifierId);
  }

  handleExportStudy(event, data){
    let studyObj = data.node.study;
    this.handleExport(studyObj.schemaName,ExportTypeConst.EXPORT_STUDY, studyObj.studyId, 0, 0, studyObj.studyName);
  }

  handleExportStudyDef(event, data){
    let studyObj = data.node.study;
    this.props.setTenantId(studyObj.schemaName);
    let that= this;
    let params= {studyId:studyObj.studyId};
    $(".loader").show();
    axios.all([restClient(POST_PARAM_BODY, 'exportLibrary', {params:params}, {'Tenant-ID': studyObj.schemaName})])
    .then(axios.spread((response) => {
      if(response !== null && response.data !== null ){
            let url =  `${SERVER_URL}/downloadFile`;
            if (response.data.messageType === MessageTypeConst.SUCCESS_MESSAGE && response.data.filePath !== null &&
              typeof(response.data.filePath) !== "undefined" && response.data.filePath.length > 0) {
                if(response.data.message !== "" && response.data.message !== null){
                  that.props.setMessage(response.data.message, response.data.messageType);
                }
                that.setState({filePath:response.data.filePath, url:url, fileName:response.data.fileName},()=>{
                    $('#downloadForm').submit();
                });
            }
            else {
                that.props.setMessage(response.data.message, response.data.messageType);
            }
        }
      $(".loader").hide();
    })).catch(error => {
        $(".loader").hide();
        this.props.setMessage("Error occurred while exporting Study Definition.", MessageTypeConst.ERROR_MESSAGE);
      });
  }

  handleExport(tenantId, exportType, studyId, processGroupId =0, processId=0, studyName='', classifierId=0){

    this.props.setTenantId(tenantId);
    let triggerNode = {
      exportType:exportType,
      studyId:studyId,
      processGroupId:processGroupId,
      processId:processId,
      blockId:0,
      studyName:studyName,
      classifierId
    };
    this.setState({exportNode:true, triggerNode:triggerNode});
  }

  toggleExportNode(){
    this.setState({exportNode:false});
  }

  closeCopyDialog(){
    let copySource = {projectId:0,studyId:0, groupId:0,processId:0, sourceTenantId:0, copyType:0, sourceName:"", description: ""};
    let copyTarget = {projectId:0,studyId:0, groupId:0,processId:0, targetTenantId:0, copyType:0};
    this.setState({showCopyDialog:false, copySource: copySource,copyTarget:copyTarget });
  }

  handleCopyProcessFlow(event, data){
    let copySource = {
      projectId:data.node.projectId,
      studyId:data.node.process.studyId,
      groupId:data.node.process.processGroupId,
      processId:data.node.process.processId,
      sourceTenantId:data.node.schemaName,
      sourceName:data.node.name,
      copyType:CopyType.COPY_FLOW
    }
    this.resetProcessFlowCutEvent();
    this.setState({copySource:copySource});
  }

  handleCopyProcessFlowGroup(event, data){
    let copySource = {
      projectId:data.node.projectId,
      studyId:data.node.studyId,
      groupId:data.node.processGroupId,
      processId:0,
      sourceTenantId:data.node.schemaName,
      sourceName:data.node.name,
      copyType:CopyType.COPY_GROUP
    }
    this.setState({copySource:copySource});
  }

  handleCopyStudy(event, data){
    let study = this.props.studyList.find(study => study.studyId === data.node.study.studyId && study.projectId === data.node.study.projectId)
    let copySource = {
      projectId:data.node.study.projectId,
      studyId:data.node.study.studyId,
      groupId:0,
      processId:0,
      sourceTenantId:data.node.study.schemaName,
      sourceName:data.node.name,
      description: study !== 'null' ? study.description : data.node.study.description,
      copyType:CopyType.COPY_STUDY_STUDY
    }
    this.setState({copySource:copySource});
  }

  handleImportStudyDef(event , data){
    this.setState({visibleImportStudyDef:true, triggerNode:data});
  }

  closeImportStudyDef(event){
    this.setState({visibleImportStudyDef:false});
  }


  handlePasteOnProject(event, data){
    if(this.state.copySource !== null && this.state.copySource.copyType === CopyType.COPY_STUDY_STUDY){
      let copyTarget = {
        projectId:data.node.projectId,
        studyId:0,
        groupId:0,
        processId:0,
        targetTenantId:"",
        copyType:CopyType.COPY_STUDY_PROJECT,
        classifier: typeof(data.node.isTag) !== 'undefined' ? data.node.name: ''
      }
      this.setState({copyTarget:copyTarget, showCopyDialog:true});
    }else{
      this.props.setMessage("Please copy study before performing paste on Project.", MessageTypeConst.WARNING_MESSAGE);
    }
  }

  handlePasteOnStudy(event, data){
    if(this.state.copySource !== null && (this.state.copySource.copyType === CopyType.COPY_STUDY_STUDY || this.state.copySource.copyType === CopyType.COPY_GROUP)){
      let copyTarget = {
        projectId:data.node.study.projectId,
        studyId:data.node.study.studyId,
        groupId:0,
        processId:0,
        targetTenantId:data.node.study.schemaName,
        copyType:this.state.copySource.copyType
      }
      this.setState({copyTarget:copyTarget, showCopyDialog:true});
    }else{
      this.props.setMessage("Please copy group/study before performing paste on Study.", MessageTypeConst.WARNING_MESSAGE);
    }
  }

  handlePasteOnGroup(event, data){
    if(this.state.copySource !== null && this.state.copySource.copyType === CopyType.COPY_FLOW){
      let copyTarget = {
        projectId:data.node.projectId,
        studyId:data.node.studyId,
        groupId:data.node.processGroupId,
        processId:0,
        targetTenantId:data.node.schemaName,
        copyType:CopyType.COPY_FLOW
      }
      this.setState({copyTarget:copyTarget, showCopyDialog:true});
    }
    else{
      let isCutNodeFound = false;
      let sourceNode = null;
      this.state.treeView.children.forEach((project) => {
        project.children.forEach((study) => {
          study.children.forEach((studyElement) => {
            if (studyElement.name === 'ProcessFlows') {
              studyElement.children.forEach((classifier) => {
                classifier.children.forEach((group) => {
                  group.children.filter(processFlow => processFlow.isCut === 1).forEach(processFlow => {
                    sourceNode = processFlow;
                    isCutNodeFound = true
                  })
                })
              })
            }
          })
        })
      })

      if (isCutNodeFound === false) {
        this.props.setMessage("Please copy flow before performing paste on Group.", MessageTypeConst.WARNING_MESSAGE);
      } else {
        this.handleProcessFlowCutToPaste(sourceNode, data.node);
      }
    }
  }

  handleSyncOnStudy = (event, data) => {
    if (data.node.children.length === 0) {
      this.appendStudyDetailInTreeView(data.node.study, false, false, true)
    } else {
      this.openSyncOnStudyDialog(data.node.study)
    }
  }

  openSyncOnStudyDialog = (study) => {
    let assessmentNode = {
      copyAssesmentType: ASSESSMENT_TYPE.STUDY_ASSESSMENT,
      projectId:study.projectId,
      studyId: study.studyId,
      groupId:0,
      schemaName: study.schemaName,
      name: study.studyName
    }
    this.setState({showCopyAssessmentDialog:true, assessmentNode:assessmentNode });
  }

  handleSyncOnGroup = (event, data) => {
    let isFlowCopy = false;
    if( data.node.children !== null && typeof(data.node.children) !== "undefined"){
      data.node.children.forEach((flow)=> {
        if(flow.isCopy === 1){
          isFlowCopy = true;
        }
      });
    }
    if(isFlowCopy || data.node.isCopy === 1){
      let assessmentNode = {
        copyAssesmentType: ASSESSMENT_TYPE.GROUP_ASSESSMENT,
        projectId:data.node.projectId,
        studyId: data.node.studyId,
        groupId: data.node.processGroupId,
        schemaName: data.node.schemaName,
        name: data.node.name
      }
      this.setState({showCopyAssessmentDialog:true, assessmentNode:assessmentNode });
    }else{
      this.props.setMessage("Group does not contain any copied flow.", MessageTypeConst.WARNING_MESSAGE);
    }
  }

  closeCopyAssessmentDialog = () => {
    let assessmentNode = {
      copyAssesmentType:0,
      studyId:0,
      projectId:0,
      groupId:0,
      schemaName:'',
      name:''
    }
    this.setState({showCopyAssessmentDialog:false, assessmentNode:assessmentNode});
  }

  closeFlagPermanentDialog(){
    this.setState({
      flagPermanentDialog: !this.state.flagPermanentDialog,
      permanentTag:'',
      permanentMessage:'',
      permanentFlagType:0
    });
  }

  enterKeyPressForPermanentFlag(e){
    if(e.keyCode === 13){
      //enter key pressed
      this.handlePermanentFlagOKButton(e);
    }
    if(e.keyCode === 27){
      //escape key pressed
      this.closeFlagPermanentDialog();
    }
 }

  handlePermanentTagChange(event){
    this.setState({ permanentTag: event.target.value });
  }

  handleFlagPermanent(event, data, nodeType){
    let schemaName = data.node.schemaName;
    let permanentMessage = "";
    switch(nodeType){
      case PermanentFlagEventType.STUDY:
        schemaName = data.node.study.schemaName;
        permanentMessage = "Latest version of all meta data tables, source data tables and valid-persistent blocks(except LINK blocks) will be marked as permanent.";
        break;
      case PermanentFlagEventType.FLOW:
        permanentMessage = "Latest version of all valid and persistent blocks(except LINK blocks) will be marked as permanent.";
        break;
      case PermanentFlagEventType.SOURCE:
        permanentMessage = "Latest version of all source data tables will be marked as permanent.";
        break;
      case PermanentFlagEventType.METADATA:
       permanentMessage = "Latest version of all meta data tables will be marked as permanent.";
        break;
      default:
        break;
    }
    this.props.setTenantId(schemaName);
    this.setState({permanentMessage: permanentMessage, permanentFlagType:nodeType, flagPermanentDialog:true, triggerNode: data.node});
  }

  handlePermanentFlagOKButton(event){
    switch(this.state.permanentFlagType){
      case PermanentFlagEventType.STUDY:
        this.markStudyPermanent();
        break;
      case PermanentFlagEventType.FLOW:
        this.markFlowPermanent();
        break;
      case PermanentFlagEventType.SOURCE:
        this.markImportDataPermanent(0);
        break;
      case PermanentFlagEventType.METADATA:
        this.markImportDataPermanent(1);
        break;
      default:
        break;
    }
  }

  markStudyPermanent(){
      let params= {tag:this.state.permanentTag};
      axios.all([restClient(POST_PARAM_BODY, 'study/markpermanent', {params:params}, {'Tenant-ID': this.props.tenantId})])
      .then(axios.spread((response) => {
        $(".loader").hide();
        this.props.setMessage(response.data.message, response.data.messageType);
        this.closeFlagPermanentDialog();
      })).catch(error => {
          $(".loader").hide();
          this.props.setMessage("Error occurred while marking Study as permanent.", MessageTypeConst.ERROR_MESSAGE);
          this.closeFlagPermanentDialog();
        });
  }

  markImportDataPermanent(importType){
    let params= {importDataType:importType,tag:this.state.permanentTag};
    axios.all([restClient(POST_PARAM_BODY, 'importedDatas/markpermanent', {params:params}, {'Tenant-ID': this.props.tenantId})])
    .then(axios.spread((response) => {
      $(".loader").hide();
      this.props.setMessage(response.data.message, response.data.messageType);
      this.closeFlagPermanentDialog();
    })).catch(error => {
        $(".loader").hide();
        this.props.setMessage("Error occurred while marking tables as permanent.", MessageTypeConst.ERROR_MESSAGE);
        this.closeFlagPermanentDialog();
      });
  }

  markFlowPermanent(){
    if(this.state.triggerNode !== null && typeof(this.state.triggerNode) !== "undefined" &&
    this.state.triggerNode.process !== null && typeof(this.state.triggerNode.process) !== "undefined") {
      if(typeof(global.login_token) !== 'undefined' && this.state.triggerNode.process.checkOutFlag === CheckOutCheckinFalg.CHECK_OUT_FLAG &&
      this.state.triggerNode.process.userName !== this.props.token_detail['email'] ){
        this.props.setMessage(`Process Flow checked-out by '${this.state.triggerNode.process.userName}'.`,MessageTypeConst.WARNING_MESSAGE);
      }
      else if(this.state.triggerNode.process.checkOutFlag === CheckOutCheckinFalg.CHECK_IN_FLAG && typeof(global.login_token) !== 'undefined'){
        this.props.setMessage("Process Flow needs to check-out before marking block as permanent.",MessageTypeConst.WARNING_MESSAGE);
      }
      else
      {
        let params= {processId:this.state.triggerNode.process.processId,tag:this.state.permanentTag};
        axios.all([restClient(POST_PARAM_BODY, 'processflow/markpermanent', {params:params}, {'Tenant-ID': this.props.tenantId})])
        .then(axios.spread((response) => {
        $(".loader").hide();
        this.props.setMessage(response.data.message, response.data.messageType);
        this.closeFlagPermanentDialog();
        })).catch(error => {
          $(".loader").hide();
          this.props.setMessage("Error occurred while marking blocks as permanent.", MessageTypeConst.ERROR_MESSAGE);
          this.closeFlagPermanentDialog();
        });
      }
    }
  }

  handleImportSourceTableFromServer = (event, data) => {
    let studyName = '';

    this.state.treeView.children.forEach((project, index) => {
      let study = project.children.filter( study => study.study.studyId === data.node.studyId && study.study.schemaName === data.node.schemaName);

      if (study.length > 0) {
        studyName = study[0].name
      }
    });

    global.selectedStudy = {
      id: data.node.studyId,
      name: data.node.schemaName,
      studyName: studyName,
      schemaName: data.node.schemaName,
      tableType: data.node.name === 'Source Table' ? 0 : 1,
      projectId:data.node.projectId
    }

    this.toggleDialogImportSourceTableFromServer('source', data.node);
  }

  toggleDialogImportSourceTableFromServer = (importTableType = null, triggerNode = {}) => {
    this.setState({visibleImportTableFromServerDialogStatus:!this.state.visibleImportTableFromServerDialogStatus, importTableType: importTableType, triggerNode: triggerNode});
  }

  handleSelectFileFromServer = (selectFileFromServer) => {
    this.setState({selectFileFromServer: selectFileFromServer});
  }

  handleImportSelectedFileFromServerClick = (event) => {
    this.toggleDialogImportSourceTableFromServer();
    this.handleImportFilesTableFilesChange(event, 'server');
  }


  displayTreeView = () => {
    const treeView = this.state.treeView
    let treeViewMode = cloneDeep(treeView);

    if (this.state.tagTreeViewStatus) {
      treeViewMode = this.displayTreeViewByTag(treeView);
    } else {
      treeViewMode = this.displayTreeViewByStudy(treeView);
    }
    return treeViewMode
  }

  displayTreeViewByTag = (treeView) => {
    let tagTreeView = treeView;
    let tagList = [];

    if (treeView.children.length > 0) {

      treeView.children.forEach(project => {
        project.children.forEach(study => {
          let tag = tagList.filter(tag => tag.projectId === project.projectId && tag.classifier === study.study.classifier)

          if (tag.length > 0) {
            tag[0].studyList.push(study)
          } else {
            tagList.push({projectId: project.projectId, classifier: study.study.classifier, studyList: [study]})
          }
        })
      })

      if (tagList.length > 0) {
        tagTreeView = cloneDeep(treeView);
        tagTreeView.children.forEach(project => {
          let openTagList = [];

          let displayTagList = this.state.displayTreeView.children.filter(displayProject => displayProject.projectId === project.projectId);
          if (displayTagList.length > 0) {
            project.toggled = displayTagList[0].toggled
            displayTagList[0].children.forEach(tag => {
              if (typeof(tag.isTag) !== 'undefined' && tag.isTag && tag.toggled) {
                openTagList.push(tag.name)
              }
            })
          }

          project.children = [];
          tagList.filter(tag => tag.projectId === project.projectId).sort((a, b) => (a.classifier).localeCompare(b.classifier)).forEach((tag) => {
            tag.studyList.filter(study => study.toggled === true && openTagList.indexOf(study.study.classifier) === -1).forEach(study => {
              openTagList.push(study.study.classifier)
              if (study.children.length === 0) {
                study.loading = true
                $(".loader").hide();
                this.appendStudyDetailInTreeView(study.study, false, false, false, false, true);
              }
            })
            project.children.push(manageTagInTreeView(tag.classifier, tag.projectId, tag.studyList, openTagList.indexOf(tag.classifier) > -1))
          });
        })
      }
    }

    return tagTreeView;
  }

  displayTreeViewByStudy = (treeView) => {
    let studyTreeView = treeView;
    if (treeView.children.length > 0) {

      studyTreeView = cloneDeep(treeView);
      studyTreeView.children.forEach(project => {
          let studyList = [];
          //project.children = [];

          let displayStudyList = this.state.displayTreeView.children.filter(displayProject => displayProject.projectId === project.projectId);
          project.children.forEach((study) => {
            let openTagList = [];
            if (displayStudyList.length > 0) {
              displayStudyList[0].children.filter(studyObject => studyObject.study.studyName === study.study.studyName).forEach(displayedStudyList => {
                displayedStudyList.children.forEach(tag => {
                  if (typeof(tag.isTag) !== 'undefined' && tag.isTag && tag.toggled) {
                    openTagList.push(tag.name)
                  }
                })
              })

              let displayStudy = displayStudyList[0].children.filter(studyObject => studyObject.study.studyName === study.study.studyName);

              if (displayStudy.length > 0) {
                study.toggled = displayStudy[0].toggled
              }
            }

            let studyObject = studyList.filter(studyObject => studyObject.study.studyName === study.study.studyName);

            if (studyObject.length > 0) {
              studyObject[0].children.push(manageTagInTreeView(study.study.classifier, study.study.projectId, cloneDeep(study.children), openTagList.indexOf(study.study.classifier) > -1))
            } else {
              let studyChildren = cloneDeep(study.children);
              study.children = [];
              study.children.push(manageTagInTreeView(study.study.classifier, study.study.projectId, studyChildren, openTagList.indexOf(study.study.classifier) > -1))
              studyList.push(study)
            }
          })

          project.children = studyList;
        })
    }

    return studyTreeView;
  }

  downloadDocument(path, params, method='post') {
    const form = document.createElement('form');
    form.method = method;
    form.action = path;

    for (const key in params) {
      if (params.hasOwnProperty(key)) {
        const hiddenField = document.createElement('input');
        hiddenField.type = 'hidden';
        hiddenField.name = key;
        hiddenField.value = params[key];

        form.appendChild(hiddenField);
      }
    }

    document.body.appendChild(form);
    form.submit();
    form.remove();
  }

  handleAccessControl = (event, data) => {
    this.setState({accessControlDialog: !this.state.accessControlDialog, triggerNode: typeof(data) !== 'undefined' ? data.node: {}})
  }
  handleVersionDifference = (event, data) => {
    if (data.node.children.length === 0) {
      this.appendStudyDetailInTreeView(data.node.study, false, true)
    } else {
      this.openStudyManagerDetail(data.node.study.schemaName, true)
    }
  }

  handleCreateTask = (event, data) => {
    let triggerNode = {};

    if (typeof(data) !== 'undefined' && typeof(data.node) !== 'undefined') {
        let nodeSchemaName = '';

        if (typeof(data.node.study) !== 'undefined') {
          nodeSchemaName = data.node.study.schemaName;
        } else {
          nodeSchemaName = data.node.schemaName;
        }

        if (nodeSchemaName !== '') {
            this.props.setTenantId(nodeSchemaName);
        }

        triggerNode = data.node
    }
    this.setState({createTaskDialogStatus: !this.state.createTaskDialogStatus, triggerNode: triggerNode});
  }

  handleContextMenuAccessControl = (event) => {
    const {data} = event.detail;

    if (typeof(data) !== 'undefined' && typeof(data.node) !== 'undefined') {
        if (typeof(data.node.cssClassName) !== 'undefined') {
          let enableActions = [];
          if (['manage-study', 'manage-study-by-tag'].indexOf(data.node.cssClassName) >= 0) {
            this.props.projectList.filter(project => project.projectId === data.node.projectId && typeof(project.enableActions)).forEach(project => {
              enableActions = project.enableActions;
            })
          } else if (['manage-study-property'].indexOf(data.node.cssClassName) >= 0) {
            if (typeof(data.node.study) !== 'undefined' && typeof(data.node.study.enableActions) !== 'undefined') {
              enableActions = data.node.study.enableActions;
            }
          } else if (['manage-study-documents', 'manage-study-source-table', 'manage-study-meta-table', 'manage-study-process-flow', 'manage-study-process-flow-group', 'manage-study-edit-meta-table', 'manage-study-edit-documents', 'manage-study-edit-source-table'].indexOf(data.node.cssClassName) >= 0) {
            this.props.studyList.filter(study => study.studyId === data.node.studyId && typeof(study.enableActions) !== 'undefined').forEach(study => {
              enableActions = study.enableActions;
            })
          }

          if (enableActions.length > 0) {
            if (enableActions.indexOf(AccessControlConstants.WRITE) === -1) {
              $('.react-contextmenu .write-ac').addClass('hidden')
            } else {
              $('.react-contextmenu .write-ac').removeClass('hidden')
            }

            if (enableActions.indexOf(AccessControlConstants.DELEGATE) === -1) {
              $('.react-contextmenu .delegate-ac').addClass('hidden')
            } else {
              $('.react-contextmenu .delegate-ac').removeClass('hidden')
            }

            if ($('.react-contextmenu .react-contextmenu-item.hidden:last-child').length > 0 && $('.react-contextmenu .react-contextmenu-item.hidden:last-child').prev('.react-contextmenu-item--divider').length) {
              $('.react-contextmenu .react-contextmenu-item.hidden:last-child').prev('.react-contextmenu-item--divider').addClass('hidden')
            }

            setTimeout(() => {
              if ($('.react-contextmenu.react-contextmenu--visible .react-contextmenu-item.hidden').length === $('.react-contextmenu.react-contextmenu--visible .react-contextmenu-item').length) {
                $('.react-contextmenu.react-contextmenu--visible').addClass('blank-contextmenu');
              } else {
                $('.react-contextmenu.react-contextmenu--visible').removeClass('blank-contextmenu');
              }
            }, 1)
          }

        }
    }
  }

  handleSelect = (e) => {
      this.setState({selected: e.selected});
  }

  getTagList = (objectId, objectType) => {
    let tagList = []
    if (typeof(global.gridRef) !== 'undefined' && global.gridRef.length > 0) {
      global.gridRef.filter(item => item.props.refs === 'tag-management-grid').forEach(item => {
        let tagType = '';
        item.props.data.filter(tag => tag.value !== '' && tag.property !== '').forEach(tag => {
          TagContantType.list.filter(element => element[1] === tag.type).forEach(element => {
            tagType = element[0]
          })

          let additionalTagList = tag.nodeTagList;
          let elementTagList = [];


          if (tagType === 2 && tag.value !== '') {
            if (typeof(tag.value) === 'string') {
              const tagValue = JSON.parse(tag.value)
              if (typeof(tagValue.highlightList) !== 'undefined') {
                  tag.value = tagValue.highlightList
              }
            }

            tag.value.forEach(tagAnnotate => {
              if (tagAnnotate.data.id === 'navigation_annotate') {
                let additionalObjectId = objectType == 2 ? this.state.triggerNode.studyId : this.state.triggerNode.process.studyId

                let additionalTag = additionalTagList.filter(additionalTag => additionalTag.objectId === additionalObjectId && additionalTag.objectType === TagContantType.STUDY)
                if (additionalTag.length > 0) {
                  additionalTag.map(tagObject => {
                    let objectTagValue = JSON.parse(tagObject.tagValue);

                    if (typeof(objectTagValue.highlightList) !== 'undefined') {
                      if (objectTagValue.highlightList.filter(nodeHighlight => nodeHighlight.content.text === tagAnnotate.content.text).length === 0) {
                        objectTagValue.highlightList.push(tagAnnotate);
                      }
                      tagObject.tagValue = JSON.stringify(objectTagValue);
                    }

                    return tagObject;
                  })
                } else {
                  additionalTagList.push({
                    objectId: additionalObjectId,
                    objectType: TagContantType.STUDY,
                    tagProperty: tag.property,
                    tagPropertyId: tag.tagPropertyId,
                    tagType: tagType,
                    tagValue: JSON.stringify({highlightList: [tagAnnotate]}),
                    documentId: typeof(tag.documentId) !== 'undefined' ? tag.documentId : 0,
                    preDefine: tag.isPreDefine
                  })
                }
              } else if(tagAnnotate.data.id.indexOf('_group_') > 0 && tagAnnotate.data.id.indexOf('_flow_') > 0 && tagAnnotate.data.id.indexOf('_block_') > 0) {
                const additionalObjectId = eval(tagAnnotate.data.id.split('_block_')[1].replace('_annotate', ''));
                if(objectId === additionalObjectId && objectType === TagContantType.PROCESS_BLOCK) {
                  if (elementTagList.filter(nodeHighlight => nodeHighlight.content.text === tagAnnotate.content.text).length === 0) {
                    elementTagList.push(tagAnnotate)
                  }
                } else {
                  let additionalTag = additionalTagList.filter(additionalTag => additionalTag.objectId === additionalObjectId && additionalTag.objectType === TagContantType.PROCESS_BLOCK)
                  if (additionalTag.length > 0) {
                    additionalTag.map(tagObject => {
                      let objectTagValue = JSON.parse(tagObject.tagValue);

                      if (typeof(objectTagValue.highlightList) !== 'undefined') {
                        objectTagValue.highlightList.push(tagAnnotate);
                        tagObject.tagValue = JSON.stringify(objectTagValue);
                      }

                      return tagObject;
                    })
                  } else {
                    additionalTagList.push({
                      objectId: additionalObjectId,
                      objectType: TagContantType.PROCESS_BLOCK,
                      tagProperty: tag.property,
                      tagPropertyId: tag.tagPropertyId,
                      tagType: tagType,
                      tagValue: JSON.stringify({highlightList: [tagAnnotate]}),
                      documentId: typeof(tag.documentId) !== 'undefined' ? tag.documentId : 0,
                      preDefine: tag.isPreDefine
                    })
                  }
                }
              } else if(tagAnnotate.data.id.indexOf('_group_') > 0 && tagAnnotate.data.id.indexOf('_flow_') > 0 && tagAnnotate.data.id.indexOf('_block_') === -1) {
                const additionalObjectId = eval(tagAnnotate.data.id.split('_flow_')[1].replace('_annotate', ''));

                if(objectId === additionalObjectId && objectType === TagContantType.PROCESS_FLOW) {
                  if (elementTagList.filter(nodeHighlight => nodeHighlight.content.text === tagAnnotate.content.text).length === 0) {
                    elementTagList.push(tagAnnotate)
                  }
                } else {
                  let additionalTag = additionalTagList.filter(additionalTag => additionalTag.objectId === additionalObjectId && additionalTag.objectType === TagContantType.PROCESS_FLOW)
                  if (additionalTag.length > 0) {
                    additionalTag.map(tagObject => {
                      let objectTagValue = JSON.parse(tagObject.tagValue);

                      if (typeof(objectTagValue.highlightList) !== 'undefined') {
                        objectTagValue.highlightList.push(tagAnnotate);
                        tagObject.tagValue = JSON.stringify(objectTagValue);
                      }

                      return tagObject;
                    })
                  } else {
                    additionalTagList.push({
                      objectId: additionalObjectId,
                      objectType: TagContantType.PROCESS_FLOW,
                      tagProperty: tag.property,
                      tagPropertyId: tag.tagPropertyId,
                      tagType: tagType,
                      tagValue: JSON.stringify({highlightList: [tagAnnotate]}),
                      documentId: typeof(tag.documentId) !== 'undefined' ? tag.documentId : 0,
                      preDefine: tag.isPreDefine
                    })
                  }
                }
              } else if(tagAnnotate.data.id.indexOf('_group_') > 0 && tagAnnotate.data.id.indexOf('_flow_') === -1 && tagAnnotate.data.id.indexOf('_block_') === -1) {
                const additionalObjectId = eval(tagAnnotate.data.id.split('_group_')[1].replace('_annotate', ''));
                if(objectId === additionalObjectId && objectType === TagContantType.PROCESS_FLOW_GROUP) {
                  if (elementTagList.filter(nodeHighlight => nodeHighlight.content.text === tagAnnotate.content.text).length === 0) {
                    elementTagList.push(tagAnnotate)
                  }
                } else {
                  let additionalTag = additionalTagList.filter(additionalTag => additionalTag.objectId === additionalObjectId && additionalTag.objectType === TagContantType.PROCESS_FLOW_GROUP)
                  if (additionalTag.length > 0) {
                    additionalTag.map(tagObject => {
                      let objectTagValue = JSON.parse(tagObject.tagValue);

                      if (typeof(objectTagValue.highlightList) !== 'undefined') {
                        objectTagValue.highlightList.push(tagAnnotate);
                        tagObject.tagValue = JSON.stringify(objectTagValue);
                      }

                      return tagObject;
                    })
                  } else {
                    additionalTagList.push({
                      objectId: additionalObjectId,
                      objectType: TagContantType.PROCESS_FLOW_GROUP,
                      tagProperty: tag.property,
                      tagPropertyId: tag.tagPropertyId,
                      tagType: tagType,
                      tagValue: JSON.stringify({highlightList: [tagAnnotate]}),
                      documentId: typeof(tag.documentId) !== 'undefined' ? tag.documentId : 0,
                      preDefine: tag.isPreDefine
                    })
                  }
                }
              }
            })
          }
          let savedTag = {
            objectId: objectId,
            objectType: objectType,
            tagProperty: tag.property,
            tagPropertyId: tag.tagPropertyId,
            tagType: tagType,
            tagValue: tagType === 2 && tag.value !== '' ? JSON.stringify({highlightList: elementTagList}) : tag.value,
            documentId: typeof(tag.documentId) !== 'undefined' ? tag.documentId : 0,
            preDefine: tag.isPreDefine
          }

          if (tag.tagId > 0) {
            savedTag = Object.assign(savedTag, {tagId: tag.tagId})
          }
          tagList.push(savedTag)

          if (additionalTagList.length > 0) {
            additionalTagList.forEach(additionalTag => {
              if (tagList.filter(tag => tag.objectId === additionalTag.objectId && tag.objectType === additionalTag.objectType).length === 0) {
                  tagList.push(additionalTag);
              }
            })
          }
        })
      })
    }

    if (typeof(global.removedTagList) !== 'undefined' && global.removedTagList.length > 0) {
      global.removedTagList.forEach(removedTag => {
        if (tagList.filter(tag => tag.tagId === removedTag.tagId).length === 0) {
          tagList.push(removedTag)
        }
      })
    }
    return tagList;
  }

  removeTagRichGrid = () => {
    if (typeof(global.gridRef) !== 'undefined' && global.gridRef.length > 0) {
      global.gridRef = global.gridRef.filter(item => item.props.refs !== 'tag-management-grid');
    }
  }

  validateTagList = (tagList) => {
    let customTagProperyList = [];
    let isValid = true;
    tagList.filter(tag => tag.preDefine === false && tag.documentId === 0).forEach(tag => {
      if (customTagProperyList.indexOf(tag.tagProperty.trim()) === -1) {
        customTagProperyList.push(tag.tagProperty.trim());
      } else {
        isValid = false;
      }
    })
    return isValid;
  }

  handleDownloadWithTagClick = (event, data) => {
    let node = data.node;
    if (typeof(node.name) !== 'undefined') {
      this.downloadDocument(SERVER_URL+'/annotate/document', {documentName: node.name, documentId: node.fileId, schemaName: node.schemaName})
    } else {
      this.props.setMessage("Error occurred while downloading document with tag.", MessageTypeConst.ERROR_MESSAGE);
    }

  }

  handleCreateMockTLFSpecs = (event, data) => {
    let triggerNode = {};

    if (typeof(data) !== 'undefined' && typeof(data.node) !== 'undefined') {
        let nodeSchemaName = '';

        if (typeof(data.node.study) !== 'undefined') {
          nodeSchemaName = data.node.study.schemaName;
        } else {
          nodeSchemaName = data.node.schemaName;
        }

        if (nodeSchemaName !== '') {
            this.props.setTenantId(nodeSchemaName);
        }

        triggerNode = data.node
    }
    this.setState({createMockTLFSpecsDialog: !this.state.createMockTLFSpecsDialog, triggerNode: triggerNode});
  }

  handleProcessFlowSorting = (event, data) => {
    let triggerNode = {};

    if (typeof(data) !== 'undefined' && typeof(data.node) !== 'undefined') {
        triggerNode = data.node
    }
    this.setState({processFlowSortingDialog: !this.state.processFlowSortingDialog, triggerNode: triggerNode});
  }

  handleCutProcessFlow = (event, data) => {
    if (typeof(data) !== 'undefined' && typeof(data.node) !== 'undefined') {
        let treeView = this.state.treeView;
        treeView.children.forEach((project) => {
          project.children.forEach((study) => {
            study.children.forEach((studyElement) => {
              if (studyElement.name === 'ProcessFlows') {
                studyElement.children.forEach((classifier) => {
                  classifier.children.forEach((group) => {
                    group.children.map(processFlow => {
                      processFlow.isCut = processFlow.process.studyId === data.node.process.studyId && processFlow.process.processId === data.node.process.processId ? 1 : 0

                      if (processFlow.isCut === 1) {
                        this.setState({copySource: null})
                      }
                      return processFlow
                    })
                  })
                })
              }
            })
          })
        })

        this.updateTreeViewData(treeView)
    }
  }

  handleProcessFlowCutToPaste = (sourceNode, targetNode) => {
    let that = this;
    let warningMessage = ''
    if (sourceNode.schemaName !== targetNode.schemaName) {
      warningMessage = 'Please move process flow within study!';
    }

    if (typeof(sourceNode.process) !== 'undefined' && sourceNode.process.checkOutFlag === 1 && sourceNode.process.userName !== this.props.token_detail['email']) {
      warningMessage = 'Process flow is Check-Out by another user!';
    }

    if (typeof(sourceNode.process) !== 'undefined' && sourceNode.process.processGroupId === targetNode.processGroupId && sourceNode.schemaName === targetNode.schemaName) {
      warningMessage = 'Process flow will not move in same group!';
    }

    if (warningMessage !== '') {
      that.resetProcessFlowCutEvent();
      that.props.setMessage(warningMessage, MessageTypeConst.WARNING_MESSAGE);
      return false;
    }

    ConfirmBox.open("Are you sure you want to move?").then((data) => {
      $(".loader").show();
      let params = {
        processId: sourceNode.process.processId,
        sourceGroupId: sourceNode.process.processGroupId,
        targetGroupId: targetNode.processGroupId,
        studyId: sourceNode.process.studyId
      }

      axios.all([restClient(POST_PARAM_BODY, 'processflow/move', {data: params}, {'Tenant-ID': sourceNode.schemaName})])
            .then(axios.spread((response) => {
              if (typeof(response.data) !== 'undefined' && typeof(response.data.processFlow) !== 'undefined' && response.data.message != null && response.data.messageType ===  MessageTypeConst.SUCCESS_MESSAGE) {
                let treeView = that.state.treeView;
                treeView.children.forEach((project) => {
                  project.children.forEach((study) => {
                    study.children.forEach((studyElement) => {
                      if (studyElement.name === 'ProcessFlows') {
                        studyElement.children.forEach((classifier) => {
                          classifier.children.filter(group => group.studyId === sourceNode.process.studyId && group.processGroupId === sourceNode.process.processGroupId).map((group) => {
                            group.children = group.children.filter(processFlow => processFlow.process.processId !== sourceNode.process.processId)
                            return group;
                          })
                        })

                        studyElement.children.forEach((classifier) => {
                          classifier.children.filter(group => group.studyId === targetNode.studyId && group.processGroupId === targetNode.processGroupId).forEach((group) => {
                            sourceNode.isCut = 0;
                            sourceNode = {
                              ...sourceNode,
                              name: response.data.processFlow.processName,
                              process: {
                                ...sourceNode.process,
                                audit: response.data.processFlow.audit,
                                processName: response.data.processFlow.processName,
                                processGroupId: response.data.processFlow.processGroupId,
                                sortOrder: response.data.processFlow.sortOrder
                              }
                            }
                            group.children.push(sourceNode);
                            group.toggled = true;
                            that.props.addProcessFlowTitle(Object.assign({ schemaName: study.study.schemaName, processId: sourceNode.process.processId, title: study.study.studyName +':'+ sourceNode.name, name: sourceNode.name, processGroupId: targetNode.processGroupId }))
                          })
                        })
                      }
                    })
                  })
                })

                if (response.data.processBlocks.length > 0) {
                  that.props.updateProcessFlowBlockBySchemaName(sourceNode.schemaName, response.data.processBlocks);
                }

                that.updateTreeViewData(treeView)
              } else {
                that.resetProcessFlowCutEvent();
              }

              if (typeof(response.data) !== 'undefined' && typeof(response.data.message) !== 'undefined') {
                that.props.setMessage(response.data.message, response.data.messageType);
              }
              $(".loader").hide();
          })).catch(error => {
              that.resetProcessFlowCutEvent();
              that.props.setMessage("Process flow cut operation failed!", MessageTypeConst.ERROR_MESSAGE);
              $(".loader").hide();
              return;
          });
    })
  }

  resetProcessFlowCutEvent = () => {
    let treeView = this.state.treeView;
    treeView.children.forEach((project) => {
      project.children.forEach((study) => {
        study.children.forEach((studyElement) => {
          if (studyElement.name === 'ProcessFlows') {
            studyElement.children.forEach((classifier) => {
              classifier.children.forEach((group) => {
                group.children.filter(processFlow => processFlow.isCut === 1).map(processFlow => {
                  processFlow.isCut = 0
                  return processFlow
                })
              })
            })
          }
        })
      })
    })

    this.updateTreeViewData(treeView);
  }

  handleExecuteAllAction = (event, data, nodeType) => {
    let that = this;
    let executeAllNode = this.state.executeAllNode;
    const { schemaName } = executeAllNode
    executeAllNode.node = '';
    executeAllNode.nodeType = '';
    executeAllNode.classifier = '';
    if (typeof(data) !== 'undefined' && data !== null) {
      executeAllNode.node = data.node.study;
      executeAllNode.nodeType = nodeType;

      if(typeof(executeAllNode.node) !== 'undefined' && typeof(executeAllNode.node.schemaName) !== 'undefined') {
          executeAllNode.schemaName = executeAllNode.node.schemaName
      }
    }

    if (typeof(executeAllNode.node) === 'undefined') {
      this.state.treeView.children.forEach(project => {
        project.children.filter(study => study.study.studyId === data.node.studyId).forEach(study => {
          executeAllNode.node = study.study
          executeAllNode.schemaName = study.study.schemaName
        })
      })
    }

    if (nodeType === 'Classifier') {
      executeAllNode.classifier = {
        classifierId: data.node.classifierId,
        classifierName: data.node.name
      }
    }

    if (executeAllNode.dialogStatus === true) {
      let flowcheckedInByApi = this.executeAllRef.getWrappedInstance().getFlowcheckedInByApi();
      if (flowcheckedInByApi !== null && flowcheckedInByApi.length > 0) {

        $(".loader").show();
        axios.all([this.executeAllCloseProcessFlowList({data: flowcheckedInByApi},{'Tenant-ID': schemaName})])
            .then(axios.spread(function (response)  {
              let processFlowStatus = [];
              if(response !== null && response.data !== null && typeof(response.data) !== "undefined"){
                if(typeof(response.data.processFlow) !== "undefined"){
                  response.data.processFlow.forEach((flow) => {
                    processFlowStatus.push({processId:flow.processId,processSyncFlag:flow.processSyncFlag, studyId: flow.studyId})
                  });
                  that.props.processFlowList.forEach((processFlow, index) => {
                    response.data.processFlow.forEach((flow) => {
                      if (processFlow.process.studyId === flow.studyId && processFlow.process.processId === flow.processId) {
                        that.props.updateProcessFlowSyncFlagByTabIndex(index, flow.processSyncFlag);
                      }
                    });
                    that.props.updateProcessflowBlockRenderStatus(index, true);
                  });
                }

                that.props.toggleRefreshToken();
                that.updateFlowIconInTreeView(processFlowStatus);
              }

              executeAllNode.dialogStatus = !that.state.executeAllNode.dialogStatus
              executeAllNode.schemaName = '';
              that.setState({executeAllNode: executeAllNode})
              $(".loader").hide();
            })).catch(error => {
              $(".loader").hide();
              that.props.setMessage("Process flow close operation failed.",MessageTypeConst.ERROR_MESSAGE);
            });
      } else {
        executeAllNode.dialogStatus = !this.state.executeAllNode.dialogStatus
        executeAllNode.schemaName = '';
        this.setState({executeAllNode: executeAllNode})
      }
    } else {
      executeAllNode.dialogStatus = !this.state.executeAllNode.dialogStatus
      this.setState({executeAllNode: executeAllNode})
    }
  }

  handleRefreshStudy = (event, data) => {
    let treeView = this.state.treeView;

    treeView.children.filter(project => project.projectId === data.node.study.projectId).map(project => {
      project.children.filter(study => study.study.schemaName === data.node.study.schemaName).map(study => {
        study.toggled = true;
        study.loading = true;
        study.children.length = 0;
        return study;
      })
      return project;
    })

    if (this.props.processFlowList.filter(processFlow => processFlow.process.studyId === data.node.study.studyId).length > 0) {
        this.props.removeProcessFlowByStudyId(data.node.study.studyId);
        if (this.props.processFlowList.filter(processFlow => processFlow.process.studyId === data.node.study.studyId).length < this.props.processFlowList.length) {
            this.props.updateProcessflowBlockRenderStatus(0, true);
            this.props.setActiveProcessFlow(0);
        }
    }
    this.updateTreeViewData(treeView);
    $(".loader").show();
    this.appendStudyDetailInTreeView(data.node.study);
  }

  handleProcessFlowGroupSorting = (event, data) => {
    let triggerNode = {};

    if (typeof(data) !== 'undefined' && typeof(data.node) !== 'undefined') {
        triggerNode = data.node
    }
    this.setState({processFlowGroupSortingDialog: !this.state.processFlowGroupSortingDialog, triggerNode: triggerNode});
  }

  handleSampleFilterStudy = (event, data) => {
    let triggerNode = {};

    if (typeof(data) !== 'undefined' && typeof(data.node) !== 'undefined') {
        triggerNode = data.node
    }
    this.setState({sampleFilterDialog: !this.state.sampleFilterDialog, triggerNode: triggerNode});
  }

  updateProcessFlowAndProcessBlockStatus = (schemaName, blocks) => {
    let processFlowStatus = [];
    if(blocks !== null && typeof(blocks) !== "undefined"){
      if(blocks.processBlock !== null && typeof(blocks.processBlock) !== "undefined" && blocks.processBlock.length > 0){
        this.props.updateProcessFlowBlockBySchemaName(schemaName, blocks.processBlock);
      }
      if(blocks.processFlow !== null && typeof(blocks.processFlow) !== "undefined"
      && blocks.processFlow.length > 0){
        blocks.processFlow.forEach((flow) => {
          processFlowStatus.push({processId:flow.processId,processSyncFlag:flow.processSyncFlag, studyId: flow.studyId, isSampleFiltered: flow.isSampleFiltered})
        });
        this.props.processFlowList.forEach((processFlow, index) => {
          blocks.processFlow.forEach((flow) => {
            if (processFlow.process.studyId === flow.studyId && processFlow.process.processId === flow.processId) {
              this.props.updateProcessFlowSyncFlagByTabIndex(index, flow.processSyncFlag);
            }
          });
          this.props.updateProcessflowBlockRenderStatus(index, true);
        });
      }
      this.props.toggleRefreshToken();
      this.updateFlowIconInTreeView(processFlowStatus);
    }
  }

  handleSearchReplaceStudy = (event, data, refreshStudy = false, schemaName = '') => {
    let triggerNode = {};

    if (typeof(data) !== 'undefined' && typeof(data.node) !== 'undefined') {
        triggerNode = data.node
        this.props.setTenantId(triggerNode.study.schemaName);
    }
    this.setState({searchReplaceDialog: !this.state.searchReplaceDialog, triggerNode: triggerNode}, () => {
      if (refreshStudy === true && schemaName !== '') {
        this.props.refreshStudyNode(false);
        this.state.treeView.children.forEach(project => {
          project.children.filter(study => study.study.schemaName === schemaName).forEach(study => {
            this.handleRefreshStudy(null, {node: {study: study.study}});
          })
        })
      }
    });
  }

  handleCopyProcessFlowLink = (event, data) => {
    let jump = {
      p: eval(data.node.projectId),
      s: data.node.process.studyId,
      pf: data.node.process.processId
    };
    localStorage.setItem("login_feature", "false");
    localStorage.setItem("id_token", global.login_token.id_token);
    navigator.clipboard.writeText(window.location.origin+'/#/jump='+window.btoa(JSON.stringify(jump)));
    this.props.setMessage("Process Flow link copied.", MessageTypeConst.SUCCESS_MESSAGE);
  }
}

const mapStateToProps = createSelector(
  state => state.project.projectList.filter( project => project.selected === true),
  state => state.study.studyList.filter( study => study.selected === true),
  state => state.treeView,
  state => state.processFlow.processFlowList,
  state => state.login.token_detail,
  state => state.processFlow.tenantId,
  state => state.userPreference.userPermission,
  state => state.processFlow.activeProcessFlow,
  state => state.study.studyManagerSchemaName,
  state => state.project.projectList,
  (projectList, studyList, treeView, processFlowList, token_detail, tenantId, userPermission, activeProcessFlow, studyManagerSchemaName, allProjectList) => ({
    projectList,
    studyList,
    treeView,
    processFlowList,
    token_detail,
    tenantId,
    userPermission,
    activeProcessFlow,
    studyManagerSchemaName,
    allProjectList
  })
);

const mapActionsToProps = {
  addProject: addProject,
  removeProject: removeProject,
  addStudy: addStudy,
  removeStudy: removeStudy,
  buildTreeViewStatus: buildTreeViewStatus,
  setTreeViewData: setTreeViewData,
  addProcessFlow: addProcessFlow,
  setTenantId: setTenantId,
  addImportTable: addImportTable,
  addProcessFlowTitle: addProcessFlowTitle,
  updateProcessFlowBlockBySchemaName: updateProcessFlowBlockBySchemaName,
  removeImportTable: removeImportTable,
  setActiveProcessFlow: setActiveProcessFlow,
  removeStudyByProjectId: removeStudyByProjectId,
  setProcessFlowTable: setProcessFlowTable,
  updateProcessFlowSyncFlagByTabIndex: updateProcessFlowSyncFlagByTabIndex,
  toggleRefreshToken: toggleRefreshToken,
  removeProcessFlow: removeProcessFlow,
  updateProcessflowBlockRenderStatus: updateProcessflowBlockRenderStatus,
  removeProcessFlowByProjectId: removeProcessFlowByProjectId,
  removeProcessFlowByStudyId: removeProcessFlowByStudyId,
  removeProcessFlowByProcessGroupId: removeProcessFlowByProcessGroupId,
  setMessage: setMessage,
  setStudyId: setStudyId,
  setProjectId: setProjectId,
  updateProjectList: updateProjectList,
  reDisplayTree: reDisplayTree,
  setStudyManager: setStudyManager,
  removeALLProcessFlow: removeALLProcessFlow,
  refreshStudyNode: refreshStudyNode,
  setJumpToBlockIdProcessId: setJumpToBlockIdProcessId
}

NavigationBarMenu.propTypes = {
  projectList: PropTypes.array,
  studyList: PropTypes.array,
  treeView: PropTypes.object,
  processFlowList: PropTypes.array
}

export default connect(mapStateToProps, mapActionsToProps)(NavigationBarMenu);
