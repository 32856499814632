class TaskConstantType {
    constructor(props){
        this.OPEN = 1;
        this.ASSIGN = 2;
        this.INPROGRESS = 3;
        this.REVIEW = 4;
        this.COMPLETE = 5;
        this.ORPHAN = 6;
        this.STATUSLIST = [[this.OPEN, "Open"], [this.ASSIGN, "Assign"], [this.INPROGRESS, 'Inprogress'], [this.REVIEW, 'Review'], [this.COMPLETE, 'Complete'], [this.ORPHAN, 'Orphan']]
    }
}

export default (new TaskConstantType());
