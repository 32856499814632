import React from 'react';
import { Button } from './StandardButton';
import { PropTypes } from "prop-types";
import Dialog from 'react-dialog';
import { connect } from 'react-redux';
import { createSelector } from 'reselect';
import { setMessage } from '../../../../../actions/actionNotification';
import DataExplorer from '../../dataExplorer';
import FileViewerWrapper from '../../FileViewerWrapper'
import "../../DataOperation.css";

export const MultipleDataButton = ({ tableManager, value, data, column, colIndex, rowIndex }) => {
    if (typeof(tableManager) === 'undefined') {
      return (null)
    } else {
      return (
          <div className='rgt-cell-inner' style={{alignItems: 'center', overflow: 'hidden'}}>
              <MultipleDataButtonClass dataItem={data} field={column.field} value={typeof(data[column.field]) !== 'undefined' ? data[column.field]: value} column={column} tableManager={tableManager}/>
          </div>
      )
    }
}

class BaseMultipleDataButton extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
          visible: false,
          fileViewerVisible: false,
          title: ''
        };
        this.closeDialog = this.closeDialog.bind(this);
        this.openDialog = this.openDialog.bind(this);
        this.closeFileDialog = this.closeFileDialog.bind(this);
      }
      getGlobalValue(controlIndex){
        let controlValue = '';
        global.processBlockControlValues.forEach((control, index) => {
          if (control.controlId === controlIndex) {
            controlValue = global.processBlockControlValues[index].controlValue;

          }
        });
        return controlValue;
      }

      saveGlobalValue(controlIndex, value){
        global.processBlockControlValues.forEach((control, index) => {
          if (control.controlId === controlIndex) {
            global.processBlockControlValues[index].controlValue = value;
          }
        });
      }
      openDialog(event, table){
        let {blockTable, blockType, blockId } = table;
        this.saveGlobalValue(-97, blockTable);
        global.outputBlockTable = "";

        if (blockType === 2 && blockId > 0) {
          global.selectedBlock = table;
          this.setState({
              fileViewerVisible: true,
              title: table.name
            });
        } else {
          this.setState({
              visible: true
            });
        }
      }
      closeDialog() {
        this.setState({
            visible: false
          });
      }
      closeFileDialog() {
        this.setState({
            fileViewerVisible: false,
            title: ''
          });
      }

      render() {
          const blockTableList = JSON.parse(this.props.dataItem[this.props.field])
          return (
              <>
                {!this.state.visible &&
                  blockTableList.map((table, index) => {
                    return (
                      <div className={"link-button"} key={`blockTable-dataviewer-${table.blockTable}-${index}`}>
                        <Button onClick={(event) => this.openDialog(event, table)} className={table.blockType === 2 ? "report-table-btn" : ""} >{table.name}</Button>
                      </div>
                    )
                  })

                }
                {this.state.visible &&
                    <div className="data-operation data_viewer_popup">
                    <div className="DaveViewer_height" >
                      <Dialog title={"Data Viewer"} width="calc(100% - 10px)" height="calc(100vh - 20px)" onClose={this.closeDialog}>
                        <DataExplorer tenantID={this.props.tenantId} key={0} selectInputTable={true} autoLoaded={true} closeEvent={this.closeDialog} blockTableList={blockTableList}/>
                      </Dialog>
                    </div>
                  </div>}
                {this.state.fileViewerVisible &&
                  <div className="data-operation">
                    <div className="file-explorer">
                        <Dialog title={`File Explorer - ${this.state.title}`} width="310px" height="auto" onClose={this.closeFileDialog}>
                            <FileViewerWrapper />
                        </Dialog>
                    </div>
                  </div>
                }
              </>
          );
      }
}

const mapStateToProps = createSelector(
    state => state.processFlow.tenantId,
    (tenantId) => ({
        tenantId
    })
  );

  const mapActionsToProps = {
    setMessage: setMessage,
  }

  Dialog.propTypes = {
      height: PropTypes.oneOfType([
          PropTypes.string
      ]),
      width: PropTypes.oneOfType([
          PropTypes.string
      ])
  }
  const MultipleDataButtonClass = connect(mapStateToProps, mapActionsToProps)(BaseMultipleDataButton);
