import React    from "react";
import { PropTypes } from "prop-types";
import { connect } from 'react-redux';
import { createSelector } from 'reselect';
import Dialog from 'react-dialog';
import { Button } from './../DataOperation/common/Button/StandardButton';
import { DropDownList } from './../DataOperation/common/DropDown/StandardDropDownList';
import { addApplyProcessFlow, removeApplyProcessFlow, setTreeElement } from './../../../actions/actionAnnotate';
import axios from 'axios';
import $ from 'jquery';
import MessageTypeConst from '../../MessageTypeConst';
import restClient from '../../../restClient';
import { CREATE, GET } from '../../../restClient/types';
import { createShape } from '../../ProcessFlow/ProcessFlows.js';
import { setMessage } from './../../../actions/actionNotification';

class ApplyProcessFlowSorting extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
          node: props.node,
          closeEvent:props.onClose,
          itemList: [],
          itemSelected: '',
        }
    }

    componentDidMount(){
        let itemList = [];
        let applyProcessFlowList = this.props.applyProcessFlowList;
        let index = 0;
        if(typeof(applyProcessFlowList) !== "undefined" && applyProcessFlowList !== null && applyProcessFlowList.length>0){
            applyProcessFlowList.filter(node => node.tableName === this.state.node.title).forEach((node, nodeIndex)=>{
                node.processFlowList.forEach((flow)=>{
                  if ((typeof(node.removedColumnList) !== 'undefined' && node.removedColumnList.indexOf(node.column) === -1) || typeof(node.removedColumnList) === 'undefined') {
                    let sortOrder = flow.sortOrder;

                    if (nodeIndex > 0 && sortOrder === 0 && itemList.filter(subSortOrderItem => subSortOrderItem.sortOrder === 0).length > 0) {
                      sortOrder = itemList.length;
                    }
                    let item = {id:flow.id+'-index'+itemList.length,sortOrder: sortOrder,name:`${node.columnName}-${flow.groupName}-${flow.processName}`, type: "column", title: node.column};
                    itemList.push(item);
                    index = index + 1;
                  }

                  if (typeof(node.selectedTargetColumnValueList) !== 'undefined' && node.selectedTargetColumnValueList.length > 0) {
                    node.selectedTargetColumnValueList.forEach(selectedTargetColumnValue => {
                      let sortOrder = flow.sortOrder
                      if (nodeIndex > 0 && sortOrder === 0 && itemList.filter(subSortOrderItem => subSortOrderItem.sortOrder === 0).length > 0) {
                        sortOrder = itemList.length;
                      }
                      if (typeof(flow.sortOrderList) !== 'undefined') {
                        let setValueSortOrder = flow.sortOrderList.filter(setValueSortOrder => setValueSortOrder.type === 'value' && setValueSortOrder.title === selectedTargetColumnValue.setValueName);
                        if (setValueSortOrder.length > 0) {
                          sortOrder = setValueSortOrder[0].sortOrder
                        }
                      }
                      if (typeof(selectedTargetColumnValue.applyProcessFlow.id) !== 'undefined') {
                        flow.groupName = selectedTargetColumnValue.applyProcessFlow.processFlowList[0].groupName
                        flow.processName = selectedTargetColumnValue.applyProcessFlow.processFlowList[0].processName
                      }
                      let item = {id:flow.id+'-index'+itemList.length,sortOrder: sortOrder,name:`${node.columnName} (${selectedTargetColumnValue.setValueName})-${flow.groupName}-${flow.processName}`, type: "value", title: selectedTargetColumnValue.setValueName};
                      itemList.push(item);
                      index = index + 1;
                    })
                  }
                })
            })
        }
        itemList = itemList.sort((a,b)=> a.sortOrder - b.sortOrder);
        itemList.map((item, index) => item.sortOrder = index)
        this.setState({itemList:itemList});
    }

    handleSelectItem = (event) => {
        var itemSelected = event.target.id.replace('item-', '');
        document.getElementsByClassName('list-wrapper')[0].querySelectorAll('.selected').forEach((item, i) => {
          if (event.target.id !== item.id) {
              item.classList.remove('selected')
          }
        });

        event.target.classList.toggle('selected')

        if (!event.target.classList.contains('selected')) {
          itemSelected = '';
        }

        this.setState({ itemSelected : itemSelected });
      }

    displayItem = (item, index) => {
        var selectedClassName = '';

        if (this.state.itemSelected === item.id) {
          selectedClassName = 'selected';
        }

        return (
            <li key={index} onClick={this.handleSelectItem} id={`item-${item.id}`} className={selectedClassName}>{`${item.name}`}</li>
        )
    };

    handleItemUpEvent = (event) => {
        let itemList = this.state.itemList;
        itemList.forEach((item, index) => {
          if (item.id === this.state.itemSelected) {
              if (typeof(itemList[index-1]) !== 'undefined') {
                item.sortOrder = item.sortOrder - 1;
                itemList[index] = item;
                itemList[index-1].sortOrder = itemList[index-1].sortOrder + 1;
              }
            }
        });
        itemList.sort((item1,item2)=>item1.sortOrder-item2.sortOrder)
        itemList.map((item, index) => item.sortOrder = index)
        this.setState({ itemList : itemList});
      }
      handleItemDownEvent = (event) => {
        let itemList = this.state.itemList;
        itemList.forEach((item, index) => {
          if (item.id === this.state.itemSelected) {
              if (typeof(itemList[index+1]) !== 'undefined') {
                  item.sortOrder = item.sortOrder + 1;
                  itemList[index] = item;
                  itemList[index+1].sortOrder = itemList[index+1].sortOrder - 1;
              }


            }
        });
        itemList.sort((item1,item2)=>item1.sortOrder-item2.sortOrder)
        itemList.map((item, index) => item.sortOrder = index)
        this.setState({ itemList : itemList});
      }

      getItems() {
        return this.state.itemList;
      }

      handleSaveClick =(event) =>{
          let that = this;
          let applyProcessFlowList = this.props.applyProcessFlowList.filter(node => node.tableName === this.state.node.title);
          if(applyProcessFlowList.length > 0){
            applyProcessFlowList.forEach((applyProcessFlow ) => {
                applyProcessFlow.processFlowList.forEach((flow,index)=>{
                    let item = that.state.itemList.filter(a=>a.id.indexOf(flow.id) > -1);
                    if(item.length > 0){
                      if (typeof(applyProcessFlow.processFlowList[index].sortOrderList) !== 'undefined') {
                        applyProcessFlow.processFlowList[index].sortOrderList.length = 0;
                      } else {
                        applyProcessFlow.processFlowList[index].sortOrderList = [];
                      }

                      item.forEach(subItem => {
                        applyProcessFlow.processFlowList[index].sortOrderList.push({
                          title: subItem.title, type: subItem.type, sortOrder: subItem.sortOrder
                        })
                      })
                      applyProcessFlow.processFlowList[index].sortOrder = item[0].sortOrder;
                    }
                })
                that.props.addApplyProcessFlow(applyProcessFlow);

            });
          }
          this.state.closeEvent();
      }

    render(){
        let itemList = this.state.itemList.sort((a, b) => a.sortOrder - b.sortOrder);
        return(
            <div className="annotate-contextmenu-sortApplyProcessFlow-dialog">
            <Dialog className="expression" title={"Sort - Apply Process Flow"} width="calc(100% - 200px)" height="calc(100% - 200px)" onClose={this.state.closeEvent} buttons={[<Button primary={true} onClick={this.handleSaveClick} key="btn-save"> Save</Button>,<Button onClick={this.state.closeEvent} key="btn-cancel"> Cancel</Button>]}>
            <div>
            <div className="sort-items">
            <div className="list-wrapper">
              <ul className="list">
                { itemList.map(this.displayItem) }
              </ul>
            </div>
            <div className="button-wrapper">
              <Button primary={true} className="m-left" onClick={this.handleItemUpEvent}> Up</Button>
              <Button primary={true} className="m-left" onClick={this.handleItemDownEvent}> Down</Button>
            </div>
          </div>
            </div>
            </Dialog>
            </div>
        );
    }
}

const mapStateToProps = createSelector(
    state => state.annotate.applyProcessFlowList,
    state => state.project.projectList,
    state => state.treeView,
    state => state.annotate.treeElement,
    state => state.study.studyList,
    (applyProcessFlowList, projectList, treeView, treeElement, studyList) => ({
        applyProcessFlowList,
        projectList,
        treeView,
        treeElement,
        studyList
    })
  );
  const mapActionsToProps = {
    addApplyProcessFlow: addApplyProcessFlow,
    removeApplyProcessFlow: removeApplyProcessFlow,
    setTreeElement: setTreeElement,
    setMessage: setMessage
  }
  Dialog.propTypes = {
      height: PropTypes.oneOfType([
          PropTypes.string
      ]),
      width: PropTypes.oneOfType([
          PropTypes.string
      ])
  }
  export default connect(mapStateToProps, mapActionsToProps)(ApplyProcessFlowSorting);
