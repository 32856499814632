import React from "react";
import { MappingRules } from "../common";
import GridMapper from '../../GridMapper';

function saveGlobalValue(controlIndex, value){
  global.processBlockControlValues.forEach((control, index) => {
    if (control.controlId === controlIndex) {
      global.processBlockControlValues[index].controlValue = value;
    }
  });
}

function VariableMappingLayoutTemplate() {
  if(global.updateBlock === false){
    if (typeof(this.state.tableList[1]) !== 'undefined') {
      saveGlobalValue(24,JSON.stringify(this.state.tableList[1].rows));
    }
  }
  return (
    <div className="process-option standard">
      <div className="row">
        <div className="col-12 align-center">
          <MappingRules tableList = {this.state.tableList} tenantID={this.state.tenantID}/>
        </div>
      </div>
      <div className="variable-grid-height">
        <GridMapper {...this.state}/>
      </div>

    </div>
  );
};

export default VariableMappingLayoutTemplate;
