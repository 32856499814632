import React from 'react';
import { DropDownList } from './../DropDown/StandardDropDownList';
import { Button } from './../Button/StandardButton';
import { ComboBox } from './../DropDown/StandardComboBox';
import RichGridTable from '../../../RichGridTable';
import { connect } from 'react-redux';
import { PropTypes } from 'prop-types';
import { createSelector } from 'reselect';
import { setMessage } from '../../../../../actions/actionNotification';
import MessageTypeConst from "../../../../MessageTypeConst";

class RefCombineGrid extends React.Component {
  LeftTableIndex = 32;
  constructor(props) {
    super(props);
    let refNamesCollection = [];
    if(typeof(props.referencePointsName) !== "undefined" && props.referencePointsName !== null){
      refNamesCollection = props.referencePointsName
    }

    let rowData = [{"rowId": "","columnId" : "", "selected": false, "refPfName" : ""}];
    if(this.getGlobalValue(this.LeftTableIndex) !== ""){
      let data = JSON.parse(this.getGlobalValue(this.LeftTableIndex));
      if(data.length > 0 ){
        rowData.length = 0;
        rowData.push(...data);
      }
    }
    this.state = {
      refsNameCollection:refNamesCollection,
      referencePointsName:refNamesCollection,
      refValue:'',
      tableList:[{
        id: 'reporting-ops-combine-grid',
        rows: rowData,
        resize: true,
        filterable: false,
        selectedField: "selected",
        columnProperty: [
          { field: "rowId", show: false },
          { field: "columnId", show: false },
          { field: "refPfName", width:"1390px", title:"Reference Point Name" }
        ]
      }],
      blankTableList:[{
        id: 'blank-grid-table',
        rows: [],
        resize: true,
        filterable: false,
        selectedField: "selected",
        columnProperty: [
          { field: "rowId", show: false },
          { field: "columnId", show: false },
          { field: "refPfName", width:"1390px", title:"Reference Point Name" }
        ]
      }],
      refreshKey:0
    }

    this.handleAddRowClick = this.handleAddRowClick.bind(this);
    this.handleRemoveRowClick = this.handleRemoveRowClick.bind(this);
    this.getGlobalValue = this.getGlobalValue.bind(this);
    this.saveGlobalValue = this.saveGlobalValue.bind(this);
    this.handleRefPointSelectionChange = this.handleRefPointSelectionChange.bind(this);
    this.updateValue = this.updateValue.bind(this);
  }

  componentDidMount(){
    this.updateValue();
  }

  UNSAFE_componentWillReceiveProps(nextProps){
    if(JSON.stringify(nextProps.referencePointsName) !== "" ){
      this.setState({refsNameCollection:nextProps.referencePointsName, referencePointsName:nextProps.referencePointsName},()=>{
        this.updateValue();
      });
    }
  }

  updateValue(){
    let rowData = [{"rowId": "","columnId" : "", "selected": false, "refPfName" : ""}];
    if(this.getGlobalValue(this.LeftTableIndex) !== ""){
      let data = JSON.parse(this.getGlobalValue(this.LeftTableIndex));
      let names = this.state.referencePointsName;
      if(data.length > 0 ){
        rowData.length = 0;
        data.forEach((item)=>{
          if(this.state.referencePointsName.indexOf(item.refPfName) !== -1){
            names = names.filter(item1 => item1 !== item.refPfName);
            rowData.push(item);
          }
        })
      }
      let tableList = this.state.tableList;
      tableList[0].rows.length = 0;
      tableList[0].rows.push(...rowData);
      this.setState({tableList:tableList, refsNameCollection: names});
    }
  }

  getGlobalValue(controlIndex){
    let controlValue = '';
    global.processBlockControlValues.forEach((control, index) => {
      if (control.controlId === controlIndex) {
        controlValue = global.processBlockControlValues[index].controlValue;

      }
    });
    return controlValue;
  }

  saveGlobalValue(controlIndex, value){
    global.processBlockControlValues.forEach((control, index) => {
      if (control.controlId === controlIndex) {
        global.processBlockControlValues[index].controlValue = value;
      }
    });
  }


    handleAddRowClick(event) {
      var tableList = this.state.tableList;
      var newRowId = 0;
      let refreshKey = this.state.refreshKey;

      if (tableList[0].rows.length > 0) {
        //remove first row if it is blank
        if(tableList[0].rows[0].refPfName === ""){
          tableList[0].rows.length = 0 ;
        }
      }
      if (tableList[0].rows.length > 0) {
        newRowId = tableList[0].rows[tableList[0].rows.length - 1].rowId + 1;
      }
      let checkWhetherRowExists = tableList[0].rows.filter(item => item.refPfName === this.state.refValue);
      if(checkWhetherRowExists.length === 1 ){
        this.props.setMessage(`Reference Point '${this.state.refValue}' already exists.`, MessageTypeConst.WARNING_MESSAGE);
        return;
      }
      var addRow = { "rowId": newRowId, "columnId" : newRowId ,"selected":false, "refPfName" : this.state.refValue};

      tableList[0].rows.push(addRow);

      refreshKey++;
      this.setState({ tableList: tableList, refValue:"", refreshKey: refreshKey },()=>{
        this.updateValue();
      });
      this.saveGlobalValue(this.LeftTableIndex, JSON.stringify(tableList[0].rows));
    }

    handleRemoveRowClick(event) {
      let tableList = this.state.tableList;
      const { rows } = this.state.tableList[0];
      let refreshKey = this.state.refreshKey;

      if(typeof(rows) !== 'undefined') {
        tableList = this.removeTableRowsRecursiveElements(tableList)
      }

      refreshKey++;
      this.setState({ tableList: tableList, refreshKey: refreshKey },()=>{
        this.updateValue();
      });
      this.saveGlobalValue(this.LeftTableIndex, JSON.stringify(tableList[0].rows));
    }

    removeTableRowsRecursiveElements(tableList) {
      var index;
      for (index = 0; index < tableList[0].rows.length; index++) {
        if (tableList[0].rows[index].selected === true) {
          tableList[0].rows.splice(index, 1);
        }
      }

      for (index = 0; index < tableList[0].rows.length; index++) {
        if (tableList[0].rows[index].selected === true) {
          tableList = this.removeTableRowsRecursiveElements(tableList);
        }
      }

      return tableList;
    }

    handleRefPointSelectionChange(event){
      let refValue = event.target.value;
      this.setState({refValue:refValue});
    }

  render() {
      return (
        <div className="composite-grid">
          <div className="">
            <div className="">
              <div className="filter-area am-form-inline col-12 m-0 clearfix">
                <div className="col-4 float-left p-0">
                  <label className="am-form-field reference-points">
                    <span>List of Reference Point:</span>
                    <ComboBox data={this.state.refsNameCollection}  value={this.state.refValue} onChange={this.handleRefPointSelectionChange}/>
                  </label>
                </div>
                <Button primary={true} className="m-l-10" onClick={this.handleAddRowClick}>Add</Button>
                <Button primary={true} className="m-left" onClick={this.handleRemoveRowClick}>Delete</Button>
              </div>
            </div>
            <div className="height_fixed_Point_combine">
              <div className="filter-area am-form-inline col-12">
                {this.state.tableList.length > 0 && typeof(this.state.tableList[0].rows) !== 'undefined' && this.state.tableList[0].rows.filter(row => row.refPfName !== '').length > 0 ? (
                  <RichGridTable {...this.state.tableList[0]} key={`reference-point-${this.state.refreshKey}`}/>
                ) : (
                  <RichGridTable {...this.state.blankTableList[0]} />
                )}
              </div>
            </div>
          </div>
        </div>
      )
    }
}
const mapStateToProps = createSelector(
  state => state.processFlow.tenantId,
  state => state.dataOperation.referencePoints,
  state => state.dataOperation.referencePointsName,
  (tenantId, referencePoints, referencePointsName) => ({
    tenantId,
    referencePoints,
    referencePointsName,
  })
);

const mapActionsToProps = {
  setMessage: setMessage
}

RefCombineGrid.propTypes = {
  tenantId: PropTypes.string
}

export const CombineGrid = connect(mapStateToProps, mapActionsToProps)(RefCombineGrid);
