import React    from "react";
import axios from 'axios';
import $ from 'jquery';
import { connect } from 'react-redux';
import { createSelector } from 'reselect';
import restClient from '../../../restClient';
import { GET, CREATE } from '../../../restClient/types';
import template from "./ProcessNodeVersionManager.jsx";
import { setMessage } from './../../../actions/actionNotification';
import { updateProcessFlowBlockByTabIndex } from './../../../actions/actionProcessFlow';
import MessageTypeConst from '../../MessageTypeConst';
import { CodeButton , LogButton, DataButton, TagValueCell, PermanentFlagCheckBoxCell } from '../DataOperation/common';
import moment from 'moment';
import CheckOutCheckinFalg from '../../CheckOutCheckInFlag';
import ConfirmBox from './../DataOperation/common/ConfirmBox';

class ProcessNodeVersionManager extends React.Component {

    constructor(props) {
        super(props);
        this.processBlock = this.getProcessBlock();
        let isFlowCheckedOut = false;
        let isFlowCheckedOutByLoggedInUser = false;
        let process = this.props.process;
        let userName = this.props.token_detail['email'];
        if(typeof(process) !== "undefined" && process !== null && process.checkOutFlag === CheckOutCheckinFalg.CHECK_OUT_FLAG){
          isFlowCheckedOut = true;
        }
        if(typeof(process) !== "undefined" && process !== null && userName !== null){
          if(process.userName === userName){
            isFlowCheckedOutByLoggedInUser = true;
          }
        }
        let disabledButton = window.location.host !== "localhost:3000" && !(isFlowCheckedOutByLoggedInUser && isFlowCheckedOut);
        this.state = {
            schemaName:props.schemaName,
            closeEvent:props.closeEvent,
            process:props.process,
            tenantId:props.tenantId,
            disabledButton:disabledButton,
            processFlowTableId: props.processFlowTableId,
            processFlowList:props.processFlowList,
            activeProcessFlow:props.activeProcessFlow,
            tableList:[{
                id: 'version-grid',
                rows: [{"blockId":0,"blockName":'',"columnId":0,"blockTable":'',"selected":false,"block":"",
                    "versionNo":"","timeStamp":"", "timestampModified": "","tableExists":false,
                    "tag":"","permanent":"","data":'',"code":'',"log":''
                }],
                resize: true,
                filterable: false,
                selectedField: "selected",
                columnProperty: [
                    { field: "tableExists", show:false},
                    { field: "blockId", sortable:false, width:"100px" , className:"text-center" },
                    { field: "blockName", sortable:false, className:"text-center" },
                    { field: "blockTable", show: false},
                    { field: "columnId", show: false},
                    { field: "selected",className:"text-center", show: false},
                    { field: "block", show: false},
                    { field: "versionNo", sortable:false,width:"100px" ,className:"text-center" },
                    //{ field: "timeStamp", sortable:false , width:"180px",className:"text-center", title:"Creation Date"},
                    //{ field: "timestampModified", sortable:false , width:"180px",className:"text-center", title:"Modified Date"},
                    { field: "tag", sortable:false,className:"text-center", width:"200px", cell:TagValueCell },
                    { field: "permanent", sortable:false, width:"100px",className:"text-center", cell:PermanentFlagCheckBoxCell },
                    { field: "data", sortable:false, width:"70px",className:"text-center", cell:DataButton },
                    { field: "code", sortable:false, cell:CodeButton, className:"text-center", width:"70px"},
                    { field: "log", sortable:false, cell: LogButton, className:"text-center", width:"70px" }
                ]
            }]
        }
        this.closeDialog = this.closeDialog.bind(this);
        this.updateVersionDetails = this.updateVersionDetails.bind(this);
        this.getProcessBlock = this.getProcessBlock.bind(this);
    }

    updateVersionDetails(event){
        event.target.disabled = true;
        let params = [];
        this.state.tableList[0].rows.filter(r => r.selected === true).forEach((row)=>{
            let item = {
                blockId:row.blockId,
                blockVersion:row.versionNo,
                tag:row.tag === null ? '' : row.tag,
                permanentFlag:row.permanent ? 1 : 0
            };
            params.push(item);
        })
        if(params.length === 0 ){
            this.props.setMessage("No data change.All versions are as it is.",MessageTypeConst.SUCCESS_MESSAGE);
            this.closeDialog();
            return;
        }
        $(".loader").show();
        let blockVersionUpdate = (headers, params) => restClient(CREATE, 'manageVersion/updateBlockVersions?blockId=0&updateVersionLimit=false&versionLimit=0&defVersionLimit=0', params, headers);
        axios.all([blockVersionUpdate({'Tenant-ID': this.state.schemaName}, {data: params})])
         .then(axios.spread((response) => {
            $(".loader").hide();
            if(response.data !== null && typeof(response.data) !== "undefined" && response.data.message !== null && typeof(response.data.message) !== "undefined"){
                this.closeDialog();
                this.props.setMessage(response.data.message, response.data.messageType);
            }
         })).catch(error => {
            $(".loader").hide();
            this.closeDialog();
            this.props.setMessage("Error occurred while updating block versions.",MessageTypeConst.ERROR_MESSAGE);
          });
    }

    componentDidMount(){
        global.processBlockControlValues = [];
        global.processBlockControlValues.push({
            "controlId": -97,
            "controlType": -97,
            "controlValue": ""
          });
        global.processBlockControlValues.push({
            "controlId": -96,
            "controlType": -96,
            "controlValue": ""
        });
        let that = this;
        $(".loader").show();
        axios.all([restClient(GET, 'manageVersion/getBlockVersions?processId='+this.state.process.processId+'&studyId='+this.state.process.studyId, {}, {'Tenant-ID': this.state.schemaName})])
        .then(axios.spread((response) => {
            if(response !== null && response.data !== null && typeof(response.data.messageType) !== "undefined"
            &&  response.data.messageType !== null && response.data.messageType === MessageTypeConst.WARNING_MESSAGE )
            {
                that.props.setMessage(response.data.message, response.data.messageType);
                that.closeDialog();
                $(".loader").hide();
                return;
            }
            if(response !== null && response.data !== null && typeof(response.data.versions) !== "undefined"
            &&  response.data.versions !== null && response.data.versions.length >0 )
            {
                let blocks = [];
                if(typeof(response.data.blocks) !== "undefined"
                &&  response.data.blocks !== null && response.data.blocks.length >0 ){
                    blocks.push(...response.data.blocks);
                }
                let tableList = that.state.tableList;
                tableList[0].rows.pop();
                response.data.versions.forEach((version, index)=>{
                    let selectedBlock = blocks.filter(pBlock=>pBlock.blockId === version.blockId);
                    tableList[0].rows.push({
                        "tableExists":version.tableExists === 1 ? true : false,
                        "blockId":version.blockId,
                        "blockName":selectedBlock.length> 0 ? selectedBlock[0].blockName:'',
                        "selected":false,
                        "columnId":index,
                        "blockTable":version.blockTable,
                        "block":selectedBlock.length> 0 ? JSON.stringify(selectedBlock[0]):'',
                        "versionNo":version.blockVersion,
                        "timeStamp":version.audit !== null ? moment.utc(version.audit.createdAt).format('lll') : "",
                        "timestampModified":version.audit !== null ? moment.utc(version.audit.modifiedAt).format('lll') : "",
                        "tag":version.blockTag,"permanent":version.blockPermFlag === 0 ? false : true,"data":'',"code":'',"log":''
                    });
                })
                that.setState({tableList:tableList});
            }
            if(response !== null && response.data !== null && typeof(response.data.blocks) !== "undefined"
            &&  response.data.blocks !== null && response.data.blocks.length <= 0)
            {
                ConfirmBox.alert("Process Flow does not contain any blocks.");
                that.closeDialog();
                $(".loader").hide();
                return;
            }
            if(response !== null && response.data !== null && typeof(response.data.versions) !== "undefined"
            &&  response.data.versions !== null && response.data.versions.length <= 0 )
            {
                ConfirmBox.alert("Blocks in selected process flow does not have any versions.");
                that.closeDialog();
                $(".loader").hide();
                return;
            }
            $(".loader").hide();
        })).catch(error=>{
            $(".loader").hide();
            that.props.setMessage("Failed to fetch version.",MessageTypeConst.ERROR_MESSAGE);
          });
    }

    getProcessBlock() {
        var processBlock = [];
        if(this.props.processFlowList !== null && this.props.processFlowList.length >0 &&
            this.props.processFlowList[this.props.activeProcessFlow] !== null && typeof(this.props.processFlowList[this.props.activeProcessFlow]) !== "undefined"){
            let processFlow = this.props.processFlowList[this.props.activeProcessFlow].process;
            if(typeof(processFlow) !== 'undefined' && processFlow !== null){
                processBlock = processFlow.processBlock;
            }

        }
        //var processBlock = JSON.parse(localStorage.getItem("processBlock"));
        var processFlowTableId = parseInt(this.props.processFlowTableId, 10);
        var actionBlock = {};
        processBlock.forEach((block, index) => {
          if (processFlowTableId === block.blockId) {
            actionBlock = block
            return block;
          }
        })
        return actionBlock;
    }

    closeDialog(){
        this.state.closeEvent();
    }


    render() {
        return template.call(this);
    }
}

const mapStateToProps = createSelector(
    state => state.processFlow.processFlowList,
    state => state.processFlow.tenantId,
    state => state.processFlow.processFlowTable,
    state => state.processFlow.processFlowTableId,
    state => state.processFlow.activeProcessFlow,
    state => state.treeView.data,
    state => state.login.token_detail,
    (processFlowList, tenantId, processFlowTable, processFlowTableId, activeProcessFlow, treeView, token_detail) => ({
      processFlowList,
      tenantId,
      processFlowTable,
      processFlowTableId,
      activeProcessFlow,
      treeView,
      token_detail
    })
  );
  const mapActionsToProps = {
    setMessage: setMessage,
    updateProcessFlowBlockByTabIndex: updateProcessFlowBlockByTabIndex,
  }
  export default connect(mapStateToProps, mapActionsToProps)(ProcessNodeVersionManager);
