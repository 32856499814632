import React from 'react';
import { Input } from './../InputBox/StandardInput';
import { Button } from './StandardButton';
import Dialog from 'react-dialog';
import { connect } from 'react-redux';
import { PropTypes } from 'prop-types';
import { createSelector } from 'reselect';
import { setMessage } from '../../../../../actions/actionNotification';
import RichGridTable from './../../../RichGridTable';
import "../../DataOperation.css";

Dialog.propTypes = {
    height: PropTypes.oneOfType([
        PropTypes.string
    ]),
    width: PropTypes.oneOfType([
        PropTypes.string
    ])
}

export const ValueMappingButton = ({ tableManager, value, data, column, colIndex, rowIndex }) => {
    if (typeof(tableManager) === 'undefined') {
      return (null)
    } else {
      return (
          <div className='rgt-cell-inner' style={{display: 'flex', alignItems: 'center', overflow: 'hidden'}}>
              <ValueMappingButtonClass dataItem={data} field={column.field} value={typeof(data[column.field]) !== 'undefined' ? data[column.field]: value} column={column} tableManager={tableManager}/>
          </div>
      )
    }
}

class ValueMappingButtonClass extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
          visible: false,
          autoColumnValueMappedList: {
            id: 'annotate-auto-column-value-mapped-grid',
            rows: [],
            resize: false,
            filterable: false,
            selectedField: "selected",
            columnProperty: [
              { field: "columnId", show: false }
            ]
          }
        };

        this.openDialog = this.openDialog.bind(this);
        this.closeDialog = this.closeDialog.bind(this);
    }

    openDialog() {
        let autoColumnValueMappedList = this.state.autoColumnValueMappedList;
        if (typeof(this.props.dataItem['valueMappingStatus']) !== 'undefined' && this.props.dataItem['valueMappingStatus'] === true) {
            autoColumnValueMappedList.id += this.props.dataItem['rowId'];
            autoColumnValueMappedList.rows.push(...this.props.dataItem['valueMappingList'])
        }
        this.setState({ visible: true, autoColumnValueMappedList: autoColumnValueMappedList });
    }

    closeDialog() {
        let autoColumnValueMappedList = this.state.autoColumnValueMappedList;
        autoColumnValueMappedList.id = 'annotate-auto-column-value-mapped-grid';
        autoColumnValueMappedList.rows.length = 0;
        this.setState({visible: false, autoColumnValueMappedList: autoColumnValueMappedList});
    }

    render() {
      return (
              <div className="text-center width-full">
                {typeof(this.props.dataItem['valueMappingStatus']) !== 'undefined' && this.props.dataItem['valueMappingStatus'] === true &&
                  <div className="annotate-contextmenu-expression-dialog value-mapping-button">
                    <Button onClick={this.openDialog} > Value Mapping </Button>
                    {this.state.visible &&
                      <Dialog title={`Value Mapping - ${this.props.dataItem['displayTargetTable']} [${this.props.dataItem['targetVariable']}]`} width="650px" height="auto" onClose={this.closeDialog}>
                          <RichGridTable {...this.state.autoColumnValueMappedList} />
                      </Dialog>
                    }
                  </div>
                }
                {typeof(this.props.dataItem['valueMappingStatus']) === 'undefined' || (typeof(this.props.dataItem['valueMappingStatus']) !== 'undefined' && this.props.dataItem['valueMappingStatus'] === false) &&
                  <div>---</div>
                }
              </div>
          );
      }
}
