import { USER_PREFERENCE_FETCH_SELECTED_STUDY, USER_PREFERENCE_SAVE_SELECTED_STUDY, USER_PREFERENCE_SET_TREE_PREPARED, USER_PREFERENCE_ADD_USER_AND_GROUP, USER_PREFERENCE_MANAGE_USER_LIST, USER_PREFERENCE_MANAGE_GROUP_LIST, USER_PREFERENCE_MANAGE_USER_GROUP_PERMISSION } from '../actions/actionsTypes';


const INITIAL_DATA = {
    studyList:[],
    isStudyListFetched :false,
    isTreePrepared : false,
    userList: [],
    groupList: [],
    allPermission: [],
    userPermission: [],
    userGroupPermission: {},
    userGroupListRefreshStatus: false
  };

export default function userPreferenceReducer(state=INITIAL_DATA, action) {
switch (action.type) {
    case USER_PREFERENCE_FETCH_SELECTED_STUDY:
        return {
        ...state,
        studyList : action.payload.studyList,
        isStudyListFetched : action.payload.isStudyListFetched,
        allPermission: action.payload.allPermission,
        userPermission: action.payload.userPermission
        }
    case USER_PREFERENCE_SAVE_SELECTED_STUDY:
    return {
    ...state,
    studyList : action.payload.studyList
    }
    case USER_PREFERENCE_SET_TREE_PREPARED:
    return {
        ...state,
        isTreePrepared : action.payload
        }
    case USER_PREFERENCE_ADD_USER_AND_GROUP:
      return {
        ...state,
        userList: action.payload.userList,
        groupList: action.payload.groupList,
        userGroupListRefreshStatus: !state.userGroupListRefreshStatus
      }
    case USER_PREFERENCE_MANAGE_USER_LIST:
      return {
        ...state,
        userList: action.payload
      }
    case USER_PREFERENCE_MANAGE_GROUP_LIST:
      return {
        ...state,
        groupList: action.payload
      }
    case USER_PREFERENCE_MANAGE_USER_GROUP_PERMISSION:
      return {
        ...state,
        userGroupPermission: action.payload
      }
    default :
    return state;
    }
}
