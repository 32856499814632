import React from 'react';

export const SortOpsGbyCheckBoxCell = ({ tableManager, value, data, column, colIndex, rowIndex }) => {
    if (typeof(tableManager) === 'undefined') {
      return (null)
    } else {
      return (
          <div className='rgt-cell-inner' style={{display: 'flex', alignItems: 'center', overflow: 'hidden'}}>
              <SortOpsGbyCheckBoxCellClass dataItem={data} field={column.field} value={value} tableManager={tableManager}/>
          </div>
      )
    }
}

class SortOpsGbyCheckBoxCellClass extends React.Component {

  OrderListSorting(orderList){
    let newGrpList = [];
    let newOrderList=[];
    global.processBlockControlValues.forEach((control, index) => {
      if (control.controlId === 3 && global.processBlockControlValues[index].controlValue !== null && global.processBlockControlValues[index].controlValue !== "" ) {
        let rowData = JSON.parse(global.processBlockControlValues[index].controlValue);
        rowData.forEach((row) => {
          let isGrpBy = false;
          let selectedOrder = orderList.filter((order) => {
            if(order.column === row.column){
              isGrpBy = row['gby'];
              return order;
            }
          });
          if(selectedOrder.length > 0){
            if(isGrpBy){
              newGrpList.push(selectedOrder[0]);
            }else {
              newOrderList.push(selectedOrder[0]);
            }
          }
        });
      }
    });
    if(newOrderList.length > 0 || newGrpList.length > 0){
      return [...newGrpList,...newOrderList ];
    }else{
      return orderList;
    }
  }

  updateOrderByCell(){
    global.processBlockControlValues.forEach((control, index) => {
      if (control.controlId === 10 ) {
        if(global.processBlockControlValues[index].controlValue !== ""){
          let orderList = JSON.parse(global.processBlockControlValues[index].controlValue);
          let itemFound = false;
          orderList.forEach((item1,index1)=>{
            if(item1.column === this.props.dataItem['column']){
              orderList[index1].oby="A";
              itemFound = true;
            }
          });
          if(!itemFound)
          {
            //if check box checked add item into list
            let orderitem = {"column":this.props.dataItem['column'],"oby":"A"};
            orderList.push(orderitem);
          }
          orderList = this.OrderListSorting(orderList);
          global.processBlockControlValues[index].controlValue = JSON.stringify(orderList);
        }
        else{
          let orderList = [];
          let orderitem = {"column":this.props.dataItem['column'],"oby":"A"};
            orderList.push(orderitem);
            orderList = this.OrderListSorting(orderList);
            global.processBlockControlValues[index].controlValue = JSON.stringify(orderList);
        }
      }
      });
  }

  GroupListSorting(grpList){
    let newGrpList=[];
    global.processBlockControlValues.forEach((control, index) => {
      if (control.controlId === 3 && global.processBlockControlValues[index].controlValue !== null && global.processBlockControlValues[index].controlValue !== "" ) {
        let rowData = JSON.parse(global.processBlockControlValues[index].controlValue);
        rowData.forEach((row) => {
          if(grpList.indexOf(row.column) !== -1){
            newGrpList.push(row.column);
          }
        });
      }
    });
    if(newGrpList.length > 0){
      return newGrpList;
    }else{
      return grpList;
    }
  }

  handleChange(e) {
      if (e.target.checked) {
        this.props.dataItem['oby'] = 'A';
      } else if (typeof(this.props.dataItem['newColumnName']) !== 'undefined') {
        this.props.dataItem['newColumnName'] = '';
      }

      this.props.dataItem[this.props.field] = e.target.checked;
      this.props.tableManager.rowsApi.setRows(this.props.tableManager.rowsApi.rows)

      global.processBlockControlValues.forEach((control, index) => {
        if (control.controlId === 9 ) {
          if(global.processBlockControlValues[index].controlValue !== ""){
            let grpList = JSON.parse(global.processBlockControlValues[index].controlValue);
            if(e.target.checked)
            {
              if(grpList.indexOf(this.props.dataItem['column']) === -1)
              {
                //if check box checked add item into list
                grpList.push(this.props.dataItem['column']);
              }
            } else{
              //if check box unchecked remove item from list
              if(grpList.indexOf(this.props.dataItem['column']) !== -1)
              {
                var filtered = grpList.filter((value, index, arr) => {
                  return value !== this.props.dataItem['column'];
                });
                grpList = filtered;
              }
            }
            this.updateOrderByCell();
            grpList = this.GroupListSorting(grpList);
            global.processBlockControlValues[index].controlValue = JSON.stringify(grpList);
          }
          else{
            let grpList = [];
            if(e.target.checked)
            {
              this.updateOrderByCell();
              grpList.push(this.props.dataItem['column']);
              grpList = this.GroupListSorting(grpList);
              global.processBlockControlValues[index].controlValue = JSON.stringify(grpList);
            }
          }
        }
        });
    }
    render() {
        let checked = false;
        if (this.props.dataItem[this.props.field]) {
          checked = true;
        }
        return (
            <div className="text-center width-full">
               <input type="checkbox" className="am-checkbox" id={`checkbox-${this.props.field.replace(' ', '_')}-${this.props.dataItem['columnId']}`} onChange={this.handleChange.bind(this)}
               checked={checked}/>
               <label className="am-checkbox-label" htmlFor={`checkbox-${this.props.field.replace(' ', '_')}-${this.props.dataItem['columnId']}`}></label>
            </div>
        );
    }
}
