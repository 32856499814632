import { FETCH_PROJECT, ADD_PROJECT, REMOVE_PROJECT, API_CONNECT_PROJECT, UPDATE_PROJECT_LIST, PROJECT_UPDATE_STUDY_ACTION } from '../actions/actionsTypes';

const INITIAL_DATA = {
  projectList:[],
  project:{},
  apiConnect: true,
};

export default function projectReducer(state=INITIAL_DATA, action) {
  switch (action.type) {
    case FETCH_PROJECT:
      return {
        ...state,
        apiConnect: false,
        projectList: updateProjectStatus(state.projectList, action.payload)
      }
    case ADD_PROJECT:
      let projectList = state.projectList.slice();
      projectList.splice(projectList.length, 0, action.payload);
      return {
        ...state,
        projectList: projectList
      }
    case API_CONNECT_PROJECT:
      return {
          ...state,
          apiConnect: action.payload
      }
    case REMOVE_PROJECT:
      return {
          ...state,
          projectList: state.projectList.filter( project => project.projectId !== action.payload )
      }
    case UPDATE_PROJECT_LIST:
    return {
        ...state,
        projectList: updateProjectStatus(action.payload, state.projectList)
    }
    case PROJECT_UPDATE_STUDY_ACTION:
      state.projectList.map(project => {
        project.study.filter(study => study.studyId === action.payload.studyId).map(study => {
          if (typeof(study.enableActions) !== 'undefined') {
            study.enableActions.length = 0
            study.enableActions.push(...action.payload.enableActions)
          }
          return study
        })
        project.studyList.filter(study => study.studyId === action.payload.studyId).map(study => {
          if (typeof(study.enableActions) !== 'undefined') {
            study.enableActions.length = 0
            study.enableActions.push(...action.payload.enableActions)
          }
          return study
        })
        return project
      })
      return {
        ...state,
        projectList: state.projectList
      }
    default:
      return state;
  }
}

const updateProjectStatus = (stateProjectList, projectList) => {
  if (stateProjectList.length > 0) {
    return projectList.map((project) => {
      let stateProject = stateProjectList.filter( stateProject => stateProject.projectId === project.projectId );
      if (stateProject.length > 0) {
        if(project.studyList === null || typeof(project.studyList) === "undefined"){
          project.studyList=[];
        }
        project.study = project.studyList;
        project.study.map((study)=>{
          if(typeof(stateProject[0].study) !== "undefined" && stateProject[0].study !== null){
            let selectedStudy = stateProject[0].study.filter(item=>item.studyId === study.studyId)
            if(selectedStudy.length > 0){
              study.selected = selectedStudy[0].selected;
            }
          }else{
            study.selected = stateProject[0].selected;
          }
          return study;
        })
        project.selected = stateProject[0].selected;
        project.expanded = stateProject[0].expanded;
      }

      return project;
    });
  }

  return projectList;
}
