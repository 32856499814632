import React from "react";
import { connect } from 'react-redux';
import { PropTypes } from 'prop-types';
import { createSelector } from 'reselect';
import template from "./DataOperation.jsx";
import dataViewerTemplate from "./DataViewer.jsx";
import fileViewerTemplate from "./FileViewer.jsx";
import restClient from '../../../restClient';
import { GET_ALL} from '../../../restClient/types';
import axios from 'axios';
import MessageTypeConst from '../../MessageTypeConst';
import { setMessage } from '../../../actions/actionNotification';
import { setALLProcessFlowRenderStatus } from '../../../actions/actionProcessFlow';
import { setConstructiveOperationStatus } from '../../../actions/actionDataOperation';
import DataOpsTitleConstants from '../../DataOpsTitleConstants';
import DataOpsIdentifierConstants from '../../DataOpsIdentifierConstants';
import Global_Context from '../../../views/SideTreeView/ShowTree/Global_Static_Node';
import ShortNameConst from '../../ShortNameConst';

class DataOperation extends React.Component {
  dataViewerOnClose = (headers,params) => restClient(GET_ALL, 'dataviewer/close', params, headers);

  constructor(props) {
    super(props);

    if (props.processFlowAction === null) {
      if ((props.location.pathname === '/data-operation')){
        if(typeof(global.dataViewerTemplate) !== 'undefined' && global.dataViewerTemplate === false
        && typeof(global.fileViewerTemplate) !== 'undefined' && global.fileViewerTemplate === false){
          props.history.push("/process-flow-list");
          return true;
        }
      }
      else if (props.location.pathname !== '/process-flow-list') {
        props.history.push("/process-flow-list");
        return true;
      }
    }

    //this.processFlowAction = localStorage.getItem("process-flow-action");
    this.processFlowAction = this.props.processFlowAction;
    this.processFlowTable = this.props.processFlowTable;
    this.processFlowTableId = this.props.processFlowTableId;
    this.studyList = this.props.studyList;
    this.activeProcessFlow = this.props.activeProcessFlow;
    this.processFlowList =  this.props.processFlowList;
    /*var TenantID = localStorage.getItem("Tenant-ID");
    var tenant_ID = TenantID;
    this.tenant_ID = tenant_ID.trim();*/

    this.tenant_ID = this.props.tenantId.trim();

    var controlValues = [];
    this.action_title = 'Title';
    this.action_name = '';
    this.action_className='';
    if(typeof(global.updateBlock) === 'undefined' || (typeof(global.processBlockControlValues) === 'undefined' || (typeof(global.processBlockControlValues) !== 'undefined' && global.processBlockControlValues.length === 0 ))) {
      global.updateBlock = false;
    }

    if (global.updateBlock === false) {
      global.processBlockControlValues = [];
      global.processOptionTableList = [];
      global.blockCode = '';
      global.blockLog = '';
      global.blockList = '';
      global.blockSpec = '';
      global.outputBlockTable = '';
      global.executeFolderPath = '';
      global.fileNames=[];
      global.blockNewBlockId = 0;
    }

    global.processBlockExecuteBlockId = 0;
    global.tabGridIndex = 0;
    switch (this.processFlowAction) {
      case DataOpsIdentifierConstants.STRINGOPS_ALIGN_OP:
        this.action_title = DataOpsTitleConstants.STRING_OPS_ALIGN_ACTION_TITLE;
        controlValues = [-1, -93, -94, 3, -97,  -96, -99, -98, -95];
        this.action_name = DataOpsIdentifierConstants.STRINGOPS_ALIGN_OP;
        break;
      case DataOpsIdentifierConstants.STRINGOPS_INDUCESPACING_OP:
        this.action_title = DataOpsTitleConstants.STRING_OPS_INSERT_SPACE_ACTION_TITLE;
        controlValues = [-1, -93, -94, 3, -97,  -96, -99, -98, -95];
        this.action_name = DataOpsIdentifierConstants.STRINGOPS_INDUCESPACING_OP;
        break;
      case DataOpsIdentifierConstants.STRINGOPS_TRIMCOMPRESS_OP:
        this.action_title = DataOpsTitleConstants.STRING_OPS_TRIM_ACTION_TITLE;
        controlValues = [-1, -93, -94, 3, -97,  -96, -99, -98, -95];
        this.action_name = DataOpsIdentifierConstants.STRINGOPS_TRIMCOMPRESS_OP;
        break;
      case DataOpsIdentifierConstants.STRINGOPS_CHANGECASE_OP:
        this.action_title = DataOpsTitleConstants.STRING_OPS_CASE_ACTION_TITLE;
        controlValues = [-1, -93, -94, 3, -97,  -96, -99, -98, -95];
        this.action_name = DataOpsIdentifierConstants.STRINGOPS_CHANGECASE_OP;
        break;
      case DataOpsIdentifierConstants.STRINGOPS_SEARCHNREPLACE_OP:
        this.action_title = DataOpsTitleConstants.STRING_OPS_SEARCH_ACTION_TITLE;
        controlValues = [-1, -93, -94, 3, -97,  -96, -99, -98, -95];
        this.action_name = DataOpsIdentifierConstants.STRINGOPS_SEARCHNREPLACE_OP;
        break;
      case DataOpsIdentifierConstants.STRINGOPS_OCCURENCE_OP:
        this.action_title = DataOpsTitleConstants.STRING_OPS_OCCURRENCE_ACTION_TITLE;
        controlValues = [-1, -93, -94, 3, -97,  -96, -99, -98, -95];
        this.action_name = DataOpsIdentifierConstants.STRINGOPS_OCCURENCE_OP;
        break;
      case DataOpsIdentifierConstants.STRINGOPS_SPLIT_OP:
        this.action_title = DataOpsTitleConstants.STRING_OPS_SPLIT_ACTION_TITLE;
        controlValues = [-1, -93, -94, 3, -97,  -96, -99, -98, -95];
        this.action_name = DataOpsIdentifierConstants.STRINGOPS_SPLIT_OP;
        break;
      case DataOpsIdentifierConstants.TABLEOPS_RENAME_OP:
        this.action_title = DataOpsTitleConstants.TABLE_RENAME_ACTION_TITLE;
        controlValues = [-1, -93, -94, 3, -97,  -96, -99, -98, -95];
        this.action_name = DataOpsIdentifierConstants.TABLEOPS_RENAME_OP;
        break;
      case DataOpsIdentifierConstants.TABLEOPS_LENGTH_ADJ_OP:
        this.action_title = DataOpsTitleConstants.TABLE_LENGTH_ADJ_ACTION_TITLE;
        controlValues = [-1, -93, -94, 3, -97,  -96, -99, -98, -95];
        this.action_name = DataOpsIdentifierConstants.TABLEOPS_LENGTH_ADJ_OP;
        break;
      case DataOpsIdentifierConstants.TYPECONVERSION_DATETIME_OP:
        this.action_title = DataOpsTitleConstants.TYPE_CONVERSION_DATETIME_ACTION_TITLE;
        controlValues = [-1, -93, -94, 3, -97,  -96, -99, -98, -95];
        this.action_name = DataOpsIdentifierConstants.TYPECONVERSION_DATETIME_OP;
        break;
      case DataOpsIdentifierConstants.TYPECONVERSION_CHARNUM_OP:
        this.action_title = DataOpsTitleConstants.TYPE_CONVERSION_CHARNUM_ACTION_TITLE;
        controlValues = [-1, -93, -94, 3, -97,  -96, -99, -98, -95];
        this.action_name = DataOpsIdentifierConstants.TYPECONVERSION_CHARNUM_OP;
        break;
      case DataOpsIdentifierConstants.CONTINUOUSDATE_OP:
        this.action_title = Global_Context.continueDateItem;
		    controlValues = [-1, -93, -94, 3, -97,  -96, -99, -98, -95, 4 , 5, 6];
        this.action_name = DataOpsIdentifierConstants.CONTINUOUSDATE_OP;
        break;
      case DataOpsIdentifierConstants.DATAOPS_OP:
        this.action_title = ShortNameConst.DATAOPS_OP;
        controlValues = [-1, -93, -94, 3, -97,  -96, -99, -98, -95, 7, 18, 19, 20, 21, 22, 23];
        this.action_name = DataOpsIdentifierConstants.DATAOPS_OP;
        break;
      case DataOpsIdentifierConstants.DECOLLAPSE_ONETABLE_OP:
        this.action_title = DataOpsTitleConstants.DECOLLAPSE_ONE_ACTION_TITLE;
        controlValues = [-1, -93, -94, 3, -97,  -96, -99, -98, -95, 4, 5, 9];
        this.action_name = DataOpsIdentifierConstants.DECOLLAPSE_ONETABLE_OP;
        break;
      case DataOpsIdentifierConstants.SORTOPS_OP:
        this.action_title = ShortNameConst.SORTOPS_OP;
        controlValues = [-1, -93, -94, 3, -97,  -96, -99, -98, -95, 7, 8, 9, 10 ];
        this.action_name = DataOpsIdentifierConstants.SORTOPS_OP;
        break;
      case DataOpsIdentifierConstants.SEQUQENCEOPS_OP:
        this.action_title = DataOpsTitleConstants.SEQUENCE_OPS_ACTION_TITLE;
        controlValues = [-1, -93, -94, 3, -97,  -96, -99, -98, -95, 9, 10];
        this.action_name = DataOpsIdentifierConstants.SEQUQENCEOPS_OP;
        break;
      case DataOpsIdentifierConstants.RANKOPS_OP:
        this.action_title = DataOpsTitleConstants.RANK_OPS_ACTION_TITLE;
        controlValues = [-1, -93, -94, 3, -97,  -96, -99, -98, -95, 9, 10];
        this.action_name = DataOpsIdentifierConstants.RANKOPS_OP;
        break;
      case DataOpsIdentifierConstants.CRITERIAOPS_OP:
        this.action_title = DataOpsIdentifierConstants.CRITERIAOPS_OP;
        controlValues = [-1, -93, -94, 3, 24, -97,  -96, -99, -98, -95, 6, 32, 33, 34, 35, 36, 37, 38, 39, 40, 41, 42, 46, 47, 48, 49 ];
        this.action_name = DataOpsIdentifierConstants.CRITERIAOPS_OP;
        break;
      case DataOpsIdentifierConstants.REFERENCEPOINT_ONETABLE_OP:
        this.action_title = DataOpsTitleConstants.REF_POINT_ONE_ACTION_TITLE;
        controlValues = [-1, -93, -94, 3, -97,  -96, -99, -98, -95, 4, 5, 58, 59, 60, 61, 62, 63, 64, 65, 66, 67, 68, 69, 70, 71, 72, 73, 53];
        this.action_name = DataOpsIdentifierConstants.REFERENCEPOINT_ONETABLE_OP;
        break;
      case DataOpsIdentifierConstants.REFERENCEPOINT_TWOTABLE_OP:
        this.action_title = DataOpsTitleConstants.REF_POINT_TWO_ACTION_TITLE;
        controlValues = [-1, -93, -94, 3, -97,  -96, -99, -98, -95, 4, 24, 34, 58, 59, 60, 61, 62, 65, 66, 67, 68, 71, 72, 73, 74, 75, 76, 77, 78, 79, 80, 53];
        this.action_name = DataOpsIdentifierConstants.REFERENCEPOINT_TWOTABLE_OP;
        break;
      case DataOpsIdentifierConstants.REFERENCEPOINT_COMPOSITE_OP:
        this.action_title = Global_Context.refPtCompItem;
        controlValues = [-1, -93, -94, 3, -97,  -96, -99, -98, -95, 32, 33, 58, 59, 60, 61, 64, 69];
        this.action_name = DataOpsIdentifierConstants.REFERENCEPOINT_COMPOSITE_OP;
        break;
      case DataOpsIdentifierConstants.REFERENCEPOINT_REPLACE_OP:
        this.action_title = Global_Context.retPtReplaceItem;
        controlValues = [-1, -93, -94, 3, -97,  -96, -99, -98, -95, 32, 33, 56, 57, 58, 59, 60, 61, 64, 69];
        this.action_name = DataOpsIdentifierConstants.REFERENCEPOINT_REPLACE_OP;
        break;
      case DataOpsIdentifierConstants.REFERENCEPOINT_COMBINE_OP:
        this.action_title = Global_Context.retPtCombineItem;
        controlValues = [-1, -93, -94, 3, -97,  -96, -99, -98, -95, 32, 58, 59];
        this.action_name = DataOpsIdentifierConstants.REFERENCEPOINT_COMBINE_OP;
        break;
      case DataOpsIdentifierConstants.PIVOT_RTC_OP:
        this.action_title = DataOpsTitleConstants.PIVOT_ROW_TO_COL_ACTION_TITLE;
        controlValues = [-1, -93, -94, 3, -97,  -96, -99, -98, -95, 14, 15, 16, 17];
        this.action_name = DataOpsIdentifierConstants.PIVOT_RTC_OP;
        break;
      case DataOpsIdentifierConstants.PIVOT_CTR_OP:
        this.action_title = DataOpsTitleConstants.PIVOT_COL_TO_ROW_ACTION_TITLE;
        controlValues = [-1, -93, -94, 3, -97,  -96, -99, -98, -95, 12, 13, 14];
        this.action_name = DataOpsIdentifierConstants.PIVOT_CTR_OP;
        break;
      case DataOpsIdentifierConstants.PIVOT_MATRIX:
        this.action_title = DataOpsTitleConstants.PIVOT_MARIX_ACTION_TITLE;
        controlValues = [-1, -93, -94, 3, -97,  -96, -99, -98, -95, 91, 92];
        this.action_name = DataOpsIdentifierConstants.PIVOT_MATRIX;
        break;
      case DataOpsIdentifierConstants.VARMAPPING_OP:
        this.action_title = Global_Context.varMappingItem;
        controlValues = [-1, -93, -94, 3, -97,  -96, -99, -98, -95, 24, 26, 27, 28, 29, 30, 31, 53, 35];
        this.action_name = DataOpsIdentifierConstants.VARMAPPING_OP;
        break;
      case DataOpsIdentifierConstants.SPLIT_OP:
        this.action_title = DataOpsIdentifierConstants.SPLIT_OP;
        controlValues = [-1, -93, -94, 3, -97,  -96, -99, -98, -95, 11 ];
        this.action_name = DataOpsIdentifierConstants.SPLIT_OP;
        break;
      case DataOpsIdentifierConstants.APPEND_OP:
        this.action_title = DataOpsIdentifierConstants.APPEND_OP;
        controlValues = [-1, -93, -94, 3, -97,  -96, -99, -98, -95, 24, 26, 27, 28, 29, 30, 31];
        this.action_name = DataOpsIdentifierConstants.APPEND_OP;
        break;
      case DataOpsIdentifierConstants.APPEND_QUICK_OP:
          this.action_title = DataOpsIdentifierConstants.APPEND_OP;
          controlValues = [-1, -93, -94, 3, -97,  -96, -99, -98, -95, 24, 26, 27, 28, 29, 30, 31];
          this.action_name = DataOpsIdentifierConstants.APPEND_QUICK_OP;
          break;
      case DataOpsIdentifierConstants.JOIN_OP:
        this.action_title = DataOpsIdentifierConstants.JOIN_OP;
        controlValues = [-1, -93, -94, 3, -97,  -96, -99, -98, -95, 24, 25, 26, 27, 28, 29, 30, 31];
        this.action_name = DataOpsIdentifierConstants.JOIN_OP;
        break;
      case DataOpsIdentifierConstants.JOIN_QUICK_OP:
        this.action_title = DataOpsIdentifierConstants.JOIN_OP;
        controlValues = [-1, -93, -94, 3, -97,  -96, -99, -98, -95, 24, 25, 26, 27, 28, 29, 30, 31];
        this.action_name = DataOpsIdentifierConstants.JOIN_QUICK_OP;
        break;
      case DataOpsIdentifierConstants.COMPARE_OP:
        this.action_title = DataOpsIdentifierConstants.COMPARE_OP;
        controlValues = [-1, -93, -94, 3, -97,  -96, -99, -98, -95, 24, 26, 27, 45];
        this.action_name = DataOpsIdentifierConstants.COMPARE_OP;
        break;
      case DataOpsIdentifierConstants.DECOLLAPSE_TWOTABLE_OP:
        this.action_title = DataOpsTitleConstants.DECOLLAPSE_TWO_ACTION_TITLE;
        controlValues = [-1, -93, -94, 3, -97,  -96, -99, -98, -95, 4, 5, 43, 44, 26, 27, 24];
        this.action_name = DataOpsIdentifierConstants.DECOLLAPSE_TWOTABLE_OP;
        break;
      case DataOpsIdentifierConstants.LINK_OP:
        this.action_title = DataOpsIdentifierConstants.LINK_OP;
        controlValues = [-1, -93, -94, 3, -97,  -96, -99, -98, -95, 4, 5, 43, 44, 50, 51, 52, 26, 27, 24];
        this.action_name = DataOpsIdentifierConstants.LINK_OP;
        break;
      case DataOpsIdentifierConstants.CODEEDITOR_FILE_OP:
        this.action_title = DataOpsTitleConstants.CODE_EDITOR_FILE_ACTION_TITLE;
        controlValues = [-1, -93, -94, 3, -97, -96, -99, -98, -95, 16]
        this.action_name = DataOpsIdentifierConstants.CODEEDITOR_FILE_OP;
        break;
      case DataOpsIdentifierConstants.CODEEDITOR_TABLE_OP:
          this.action_title = DataOpsTitleConstants.CODE_EDITOR_TABLE_ACTION_TITLE;
          controlValues = [-1, -93, -94, 3, -97, -96, -99, -98, -95, 16]
          this.action_name = DataOpsIdentifierConstants.CODEEDITOR_TABLE_OP;
          break;
      case DataOpsIdentifierConstants.REPORTINGOPS_CUSTOM_OP:
        this.action_title = DataOpsTitleConstants.REPO_CUSTOM_ACTION_TITLE;
        controlValues = [-1, -93, -94, 3, -97,  -96, -99, -98, -95, 24, 26, 27, 32, 33, 54, 55, 81, 82, 83, 84, 85, 86, 87, 88, 89, 93, 94];
        this.action_name = DataOpsIdentifierConstants.REPORTINGOPS_CUSTOM_OP;
        this.action_className = 'ReportingOps_Custom';
        break;
      case DataOpsIdentifierConstants.REPORTINGOPS_DESCRIPTIVE_OP:
        this.action_title = DataOpsTitleConstants.REPO_DES_ACTION_TITLE;
        controlValues = [-1, -93, -94, 3, -97,  -96, -99, -98, -95, 24, 26, 27, 32, 33, 54, 55, 81, 82, 83, 84, 85, 86, 87, 88, 89, 93];
        this.action_name = DataOpsIdentifierConstants.REPORTINGOPS_DESCRIPTIVE_OP;
        this.action_className = 'ReportingOps_Descriptive';
        break;
      case DataOpsIdentifierConstants.REPORTINGOPS_CATEGORICAL_OP:
        this.action_title = DataOpsTitleConstants.REPO_CAT_ACTION_TITLE;
        controlValues = [-1, -93, -94, 3, -97,  -96, -99, -98, -95, 24, 26, 27, 32, 33, 54, 55, 81, 82, 83, 84, 85, 86, 87, 88, 89, 93, 94];
        this.action_name = DataOpsIdentifierConstants.REPORTINGOPS_CATEGORICAL_OP;
        this.action_className = 'ReportingOps_Categorical';
        break;
      case DataOpsIdentifierConstants.REPORTINGOPS_CATEGORICAL_TOTAL_OP:
        this.action_title = DataOpsTitleConstants.REPO_CAT_TOTAL_ACTION_TITLE;
        controlValues = [-1, -93, -94, 3, -97,  -96, -99, -98, -95, 24, 26, 27, 32, 33, 54, 55, 81, 82, 83, 84, 85, 86, 87, 88, 89, 93, 94];
        this.action_name = DataOpsIdentifierConstants.REPORTINGOPS_CATEGORICAL_TOTAL_OP;
        this.action_className = 'ReportingOps_CategoricalTotal';
        break;
      case DataOpsIdentifierConstants.REPORTINGOPS_CATEGORICAL_TOTAL_UNIQUE_OP:
        this.action_title = DataOpsTitleConstants.REPO_CAT_UNIQUE_ACTION_TITLE;
        controlValues = [-1, -93, -94, 3, -97,  -96, -99, -98, -95, 24, 26, 27, 32, 33, 54, 55, 81, 82, 83, 84, 85, 86, 87, 88, 89, 93, 94];
        this.action_name = DataOpsIdentifierConstants.REPORTINGOPS_CATEGORICAL_TOTAL_UNIQUE_OP;
        this.action_className = 'ReportingOps_CategoricalTotalUniquePerId';
        break;
      case DataOpsIdentifierConstants.REPORTINGOPS_CATEGORICAL_HIERARCHICAL_OP:
        this.action_title = DataOpsTitleConstants.REPO_CAT_HIERARCHICAL_ACTION_TITLE;
        controlValues = [-1, -93, -94, 3, -97,  -96, -99, -98, -95, 24, 26, 27, 32, 33, 54, 55, 81, 82, 83, 84, 85, 86, 87, 88, 89, 93, 94];
        this.action_name = DataOpsIdentifierConstants.REPORTINGOPS_CATEGORICAL_HIERARCHICAL_OP;
        this.action_className = 'ReportingOps_CategoricalHierarchical';
        break;
      case DataOpsIdentifierConstants.REPORTINGOPS_CATEGORICAL_HIERARCHICAL_UNIQUE_OP:
        this.action_title = DataOpsTitleConstants.REPO_CAT_HIERARCHICAL_UNIQUE_ACTION_TITLE;
        controlValues = [-1, -93, -94, 3, -97,  -96, -99, -98, -95, 24, 26, 27, 32, 33, 81, 84, 85, 86, 87, 88, 89, 93, 94];
        this.action_name = DataOpsIdentifierConstants.REPORTINGOPS_CATEGORICAL_HIERARCHICAL_UNIQUE_OP;
        this.action_className = 'ReportingOps_CategoricalHierarchicalUniquePerId';
        break;
      case DataOpsIdentifierConstants.PROCREPORT_ANALYSIS_CHAR_OP:
        this.action_title = Global_Context.procReportMenu;
        controlValues = [-1, -93, -94, -97,  -96, -99, -98, -95, 9, 11, 12, 13, 14, 17, 18, 19, 20, 21, 22, 23, 25, 26, 27, 28, 29, 30 ,31, 45, 89, 90, 36, 37];
        this.action_name = DataOpsIdentifierConstants.PROCREPORT_ANALYSIS_CHAR_OP;
        break;
      case DataOpsIdentifierConstants.R_Report_OP:
        this.action_title = Global_Context.R_ReportItem;
        controlValues = [-1, -93, -94, -97,  -96, -99, -98, -95, 9, 11, 12, 13, 14, 17, 18, 19, 20, 21, 22, 23, 25, 26, 27, 28, 29, 30 ,31, 45, 89, 90, 36, 37];
        this.action_name = DataOpsIdentifierConstants.R_Report_OP;
        break;
      case DataOpsIdentifierConstants.PROCREPORT_ANALYSIS_NUM_OP:
        this.action_title = Global_Context.procReportAnalysisNumItem;
        controlValues = [-1, -93, -94, -97,  -96, -99, -98, -95, 9, 11, 12, 13, 14, 17, 18, 19, 20, 21, 22, 23, 25, 26, 27, 28, 29, 30 ,31, 45, 89, 90, 36, 37];
        this.action_name = DataOpsIdentifierConstants.PROCREPORT_ANALYSIS_NUM_OP;
        break;
      case DataOpsIdentifierConstants.GRAPH_OP:
        this.action_title = Global_Context.GraphItem;
        controlValues = [-1, -93, -94, -97,  -96, -99, -98, -95, 9, 11, 12, 13, 14, 17, 18, 19, 20, 21, 22, 23, 25, 26, 27, 28, 29, 30 ,31, 45, 89, 90, 36, 37];
        this.action_name = DataOpsIdentifierConstants.GRAPH_OP;
        break;
      default:
        controlValues = [-1, -93, -94, -97,  -96, -99, -98, -95];
      break;
    }

    if (typeof(global.gridRef) !== 'undefined') {
        global.gridRef = [];
    }

    controlValues.forEach((control, index) => {
      var controlValue = '';

      if (control === -1 && this.action_name !== '') {
        controlValue = this.action_name;
      }

      if (typeof(global.dataViewerTemplate) !== 'undefined' && global.dataViewerTemplate === true && control === -97) {
        controlValue = this.props.processFlowTable;
        this.saveGlobalValue(control, controlValue);
      }

      if (global.updateBlock === false) {
        global.processBlockControlValues.push({
          "controlId": control,
          "controlType": control,
          "controlValue": controlValue
        });
      }

    })
  }

  saveGlobalValue(controlIndex, value){
    global.processBlockControlValues.forEach((control, index) => {
      if (control.controlId === controlIndex) {
        global.processBlockControlValues[index].controlValue = value;
      }
    });
  }

  getGlobalValue(controlIndex){
    let controlValue = '';
    global.processBlockControlValues.forEach((control, index) => {
      if (control.controlId === controlIndex) {
        controlValue = global.processBlockControlValues[index].controlValue;

      }
    });
    return controlValue;
  }

  componentWillUnmount() {
      global.dataViewerTemplate = false;
      global.fileViewerTemplate = false;
      this.setState({});
  }

  UNSAFE_componentWillMount() {
    if (this.props.constructiveOperationStatus === true) {
      this.props.setConstructiveOperationStatus(false);
    }
  }

  toggleDialog = () => {
    if(global.dataViewerTemplate){
      let tenantID = this.tenant_ID;
      var inputTable = this.getGlobalValue(-97).split(',');
      inputTable.forEach((table,index) => {
        axios.all([this.dataViewerOnClose({'Tenant-ID': tenantID},{'blockTable':table})])
        .then(axios.spread((response) => {
          if(typeof(response.data) !== 'undefined') {
          }
        })).catch(error => {
          this.props.setMessage("Failed to while closing Data viewer.",MessageTypeConst.ERROR_MESSAGE);
      });

      });

    }
    global.dataViewerTemplate = false;
    global.fileViewerTemplate = false;
    window.location.hash='#process-flow-list';
  }

  render() {
    if (typeof(global.dataViewerTemplate) !== 'undefined' && global.dataViewerTemplate === true) {
      return <div className={this.action_className}>{dataViewerTemplate.call(this)}</div>;
    } else if (typeof(global.fileViewerTemplate) !== 'undefined' && global.fileViewerTemplate === true) {
      return <div className={this.action_className}>{fileViewerTemplate.call(this)}</div>;
    }
     else {
      return <div className={this.action_className}>{template.call(this)}</div>;
    }

  }
}


const mapStateToProps = createSelector(
  state => state.processFlow.processFlowList,
  state => state.processFlow.tenantId,
  state => state.processFlow.processFlowAction,
  state => state.processFlow.processFlowTable,
  state => state.processFlow.processFlowTableId,
  state => state.processFlow.quickcondition,
  state => state.processFlow.submenucolumns,
  state => state.study.studyList,
  state => state.processFlow.activeProcessFlow,
  state => state.dataOperation.constructiveOperationStatus,
  (processFlowList, tenantId, processFlowAction, processFlowTable, processFlowTableId, quickcondition, submenucolumns,studyList, activeProcessFlow, constructiveOperationStatus) => ({
    processFlowList,
    tenantId,
    processFlowAction,
    processFlowTable,
    processFlowTableId,
    quickcondition,
    submenucolumns,
    studyList,
    activeProcessFlow,
    constructiveOperationStatus
  })
);

const mapActionsToProps = {
  setALLProcessFlowRenderStatus: setALLProcessFlowRenderStatus,
  setConstructiveOperationStatus: setConstructiveOperationStatus,
  setMessage:setMessage
}

DataOperation.propTypes = {
  processFlowList: PropTypes.array,
  tenantId: PropTypes.string,
  processFlowAction: PropTypes.string,
  processFlowTable: PropTypes.string,
  quickcondition: PropTypes.string,
  submenucolumns: PropTypes.string,
  studyList :PropTypes.array,
  activeProcessFlow: PropTypes.number
}

export default connect(mapStateToProps, mapActionsToProps)(DataOperation);
