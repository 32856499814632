import React from 'react';

export const StandardCheckBoxCell = ({ tableManager, value, data, column, colIndex, rowIndex}) => {
    if (typeof(tableManager) === 'undefined') {
      return (null)
    } else {
      return (
          <div className='rgt-cell-inner' style={{display: 'flex', alignItems: 'center', overflow: 'hidden'}}>
              <StandardCheckBoxCellClass dataItem={data} field={column.field} value={typeof(data[column.field]) !== 'undefined' ? data[column.field]: value} tableManager={tableManager}/>
          </div>
      )
    }
}

class StandardCheckBoxCellClass extends React.Component {

    handleChange(e) {
      this.props.dataItem[this.props.field] = e.target.checked;
      this.props.tableManager.rowsApi.setRows(this.props.tableManager.rowsApi.rows)
    }
    render() {
        let checked = false;
        if (this.props.dataItem[this.props.field]) {
          checked = true;
        }
        let colId = "columnId";
        return (
            <div className="text-center width-full">
               <input type="checkbox" className="am-checkbox" id={`checkbox-${this.props.dataItem[colId]}`} onChange={this.handleChange.bind(this)}
               checked={checked}/>
               <label className="am-checkbox-label" htmlFor={`checkbox-${this.props.dataItem[colId]}`}></label>

            </div>
        );
    }
}
