import React from "react";
import { connect } from 'react-redux';
import { createSelector } from 'reselect';
import { Button } from './../DataOperation/common/Button/StandardButton';
import { PropTypes } from "prop-types";
import Dialog from 'react-dialog';
import { DropDownList } from './../DataOperation/common/DropDown/StandardDropDownList';
import cloneDeep from 'lodash/cloneDeep';
import MessageTypeConst from '../../MessageTypeConst';
import { addMultipleColumnMappedList, addPivotMatrixList, setDefaultTargetColumnExpression, updateTreeElementViaImmutable, setTreeElement, manageMappedColumnList } from './../../../actions/actionAnnotate';
import { setMessage } from './../../../actions/actionNotification';
import { PivotOutputCell, SourceColumnCell, MultiSelectDropDownList } from './../DataOperation/common';
import DrawColumnMappingLine from './../Annotate/DrawColumnMappingLine';
import MappedColumnValue from "../Annotate/MappedColumnValue";
import RichGridTable from './../RichGridTable';

class PivotMatrix extends React.Component {
    constructor(props) {
        super(props);

        if (typeof(global.gridRef) !== 'undefined') {
          delete global.gridRef;
        }

        this.state = {
          node: props.node,
          closeEvent:props.onClose,
          itemList: [],
          itemSelected: '',
          pivotMatrixList: [],
          displayTargetTableList: [],
          selectedTargetTable: '',
          tableList:{
            id: 'annotate-pivot-matrix-grid',
            rows: [],
            resize: true,
            filterable: false,
            selectedField: "selected",
            dragAndDrop: false,
            columnProperty: [
              { field: "rowId", show: false },
              { field: "getSouceVariableExpression", show: false },
              { field: "sourceColumnList", show: false },
              { field: "blankCell", show: false },
              { field: "targetColumnTitle", show: false },
              { field: "targetColumn", width:"300px", title: "Target Variable"},
              { field: "pivotOutput", cell: PivotOutputCell, width:"100px"}
            ]
          },
          targetTableColumnList: [],
          selectedTargetColumnList: [],
          pivotOperationList: ["Pivot Operation 1"],
          selectedPivotOperationList: ["Pivot Operation 1"],
          columnMappingList: [],
          targetTableName: props.targetTableName
        }

        this.getDisplayTableNameMapping = this.getDisplayTableNameMapping.bind(this);
        this.handleTargetTableChange = this.handleTargetTableChange.bind(this);
        this.handleSaveClick = this.handleSaveClick.bind(this);
        this.getSourceAndTargetColumnList = this.getSourceAndTargetColumnList.bind(this);
        this.handleAddPivotOperationClick = this.handleAddPivotOperationClick.bind(this);
        this.handlePivotOperationChange = this.handlePivotOperationChange.bind(this);
        this.addColumnMapping = this.addColumnMapping.bind(this);
        this.getElementNodeId = this.getElementNodeId.bind(this)
        this.addMultipleTargetColumnExpression = this.addMultipleTargetColumnExpression.bind(this);
        this.removeMappedColumnLine = this.removeMappedColumnLine.bind(this);
        this.getExpressionString = this.getExpressionString.bind(this);
    }

    componentDidMount() {
      let pivotMatrixList = [];
      let getDisplayTableNameMapping = this.getDisplayTableNameMapping();
      let displayTargetTableList = getDisplayTableNameMapping.filter(getDisplayTableName => getDisplayTableName.type === 'metadata' && getDisplayTableName.blockName !== 'temp_meta');

      displayTargetTableList.forEach(displayTargetTable => {
        const savedPivotMatrix = this.props.pivotMatrixList.filter(savedPivotMatrix => savedPivotMatrix.sourceTable === this.state.node.tableName && savedPivotMatrix.tableName === displayTargetTable.tableName )

        if (savedPivotMatrix.length > 0) {
          pivotMatrixList.push(cloneDeep(savedPivotMatrix[0]))
        } else {
          pivotMatrixList.push({sourceTable: this.state.node.tableName, tableName: displayTargetTable.tableName, rows: [], maximumGroupLength: 0})
        }
      })

      this.setState({ displayTargetTableList:  displayTargetTableList, pivotMatrixList: pivotMatrixList }, () => {
        if (displayTargetTableList.length > 0) {
          let displayTargetTable = displayTargetTableList.filter(displayTargetTable => displayTargetTable.tableName === this.state.targetTableName);

          if (displayTargetTable.length === 0) {
            pivotMatrixList.filter(pivotMatrix => pivotMatrix.rows.length > 0).forEach(pivotMatrix => {
              if (displayTargetTable.length === 0 && displayTargetTableList.filter(displayTargetTable => pivotMatrix.tableName === displayTargetTable.tableName).length > 0) {
                displayTargetTable.push(...displayTargetTableList.filter(displayTargetTable => pivotMatrix.tableName === displayTargetTable.tableName))
              }
            })
          }
          this.handleTargetTableChange(null, displayTargetTable.length > 0 ? displayTargetTable[0] : displayTargetTableList[0])
        }
      })
    }

    getDisplayTableNameMapping = () => {
      let {sourceTables, metaTableTables} = this.props.tableExtendedList;
      let displayTableNameMapping = [];
      let tableName = null;
      let displayTableName = null;
      let tableVersion = null;

      if (typeof(sourceTables) !== 'undefined') {
        sourceTables.forEach((sourceTable) => {
          tableName = sourceTable.tableName.replace('_extended', '');
          tableVersion = tableName.split('_');
          tableVersion = tableVersion[tableVersion.length-1];
          displayTableName = sourceTable.blockName + ' ('+tableVersion+')';

          displayTableNameMapping.push({tableName: tableName, displayTableName: displayTableName, type: "source", blockName: sourceTable.blockName});
        })
      }

      if (typeof(metaTableTables) !== 'undefined') {
        metaTableTables.forEach((metadataTable) => {
          tableName = metadataTable.tableName.replace('_extended', '');
          tableVersion = tableName.split('_');
          tableVersion = tableVersion[tableVersion.length-1];
          displayTableName = this.getBlockName(metadataTable.blockName) + ' ('+tableVersion+')';

          displayTableNameMapping.push({tableName: tableName, displayTableName: displayTableName, type: "metadata", blockName: metadataTable.blockName});
        })
      }

      return displayTableNameMapping;
    }

    getBlockName = (blockName) => {
      if (blockName !== null && blockName !== '') {
        let blockNameSplit = blockName.split('_meta');

        if (blockNameSplit[blockNameSplit.length - 1] === '') {
          blockNameSplit.length = blockNameSplit.length - 1;
        }

        blockName = blockNameSplit.join('_meta')
      }

      return blockName;
    }

    handleTargetTableChange = (event, selectedTargetTable = '') => {
      let tableName = selectedTargetTable !== '' ? selectedTargetTable.tableName: event.target.value.tableName;
      let {tableList, pivotMatrixList} = this.state;
      let pivotMatrix = pivotMatrixList.filter(pivotMatrix => pivotMatrix.tableName === tableName);
      let selectedTargetColumnList = [];
      let targetTableColumnList = [];
      let selectedPivotOperationList = ["Pivot Operation 1"];
      let pivotOperationList = ["Pivot Operation 1"];
      let { targetColumnList } = this.getSourceAndTargetColumnList()

      if (pivotMatrix.length > 0) {
        let rows = pivotMatrix[0].rows;
        if (this.state.selectedTargetTable !== '') {
          pivotMatrixList.filter(pivotMatrix => pivotMatrix.tableName === this.state.selectedTargetTable.tableName).map(pivotMatrix => {
            pivotMatrix.rows = cloneDeep(tableList.rows);
            pivotMatrix.maximumGroupLength = this.state.selectedPivotOperationList.length;
            return pivotMatrix
          })
        }

        rows.forEach(row => {
          selectedTargetColumnList.push(row.targetColumn)
        })

        for(let i = 2; i <= pivotMatrix[0].maximumGroupLength; i++) {
          selectedPivotOperationList.push("Pivot Operation "+i)
          pivotOperationList.push("Pivot Operation "+i)
        }

        tableList.rows.length = 0;
        tableList.rows.push(...rows)
      } else {
        tableList.rows.length = 0;
        tableList.columnProperty = [
          { field: "rowId", show: false },
          { field: "sourceColumnList", show: false },
          { field: "selectedSourceColumnList", show: false },
          { field: "blankCell", show: false },
          { field: "targetColumn", width:"300px"},
          { field: "pivotOutput", cell: PivotOutputCell, width:"100px"}
        ]
      }

      if (selectedTargetTable === '') {
        selectedTargetTable = event.target.value
      }

      targetColumnList.filter(targetColumn => targetColumn.tableName === selectedTargetTable.tableName).forEach(targetColumn => {
          targetTableColumnList.push(targetColumn.column_header)
      });
      this.setState({selectedTargetTable: selectedTargetTable, tableList: tableList, pivotMatrixList: pivotMatrixList, targetTableColumnList: targetTableColumnList, selectedTargetColumnList: selectedTargetColumnList, selectedPivotOperationList: selectedPivotOperationList, pivotOperationList: pivotOperationList}, () => {
        if (this.state.selectedTargetColumnList.length > 0) {
          this.handleTargetTableColumnChange(event, this.state.selectedTargetColumnList)
        }
      })
    }

    handleSaveClick = (event) => {
      let {tableList, pivotMatrixList, displayTargetTableList } = this.state;
      let columnMappingList = [];
      let savePivotMatrixStatus = true;
      let pivotMatrixBlankCellStatus = false;
      let pivotMatrixRowAllBlankCellStatus = false;
      let displayWrongTargetTableList = [];
      let removeMappedColumnList = [];
      let removedTargetColumnMappedValueList = [];

      pivotMatrixList.map(pivotMatrix => {
        if (pivotMatrix.tableName === this.state.selectedTargetTable.tableName) {
          pivotMatrix.rows.length = 0
          pivotMatrix.rows.push(...tableList.rows);
          pivotMatrix.maximumGroupLength = this.state.selectedPivotOperationList.length
        }

        /*if (pivotMatrix.rows.length > 0 && pivotMatrix.rows[0].blankCell.length > 0) {
          savePivotMatrixStatus = false;
          displayWrongTargetTableList.push(displayTargetTableList.filter(displayTargetTable => displayTargetTable.tableName === pivotMatrix.tableName)[0].displayTableName)
        }*/

        return pivotMatrix;
      })

      if (savePivotMatrixStatus === true) {
        displayWrongTargetTableList = [];
        let { sourceColumnList, targetColumnList } = this.getSourceAndTargetColumnList()
        pivotMatrixList.forEach(pivotMatrix => {
          pivotMatrix.rows.forEach((row, i) => {
            let targetNodeId = this.getElementNodeId(pivotMatrix.tableName, row.targetColumn, 'metadata')
            let targetColumnDetail = '';
            let targetColumnRecord = targetColumnList.filter(targetColumnRecord => targetColumnRecord.tableName === pivotMatrix.tableName && targetColumnRecord.column_header === row.targetColumn)
            if (targetColumnRecord.length > 0) {
              targetColumnDetail = targetColumnRecord[0].record
            }
            let totalRowBlankCellCount = 0;

            for (let key in row) {
              if (key.indexOf("group") > -1 && row.blankCell.indexOf(key) > -1 && (row[key] === '' || row[key] === '""' || row[key] === "''")) {
                totalRowBlankCellCount++;
              }
              if (key.indexOf("group") > -1 && row[key] === '') {
                savePivotMatrixStatus = false;
                pivotMatrixBlankCellStatus = true;
                let displayWrongTargetTable = displayTargetTableList.filter(displayTargetTable => displayTargetTable.tableName === pivotMatrix.tableName)[0].displayTableName;

                if (displayWrongTargetTableList.indexOf(displayWrongTargetTable) === -1) {
                    displayWrongTargetTableList.push(displayWrongTargetTable)
                }

              } else if (key.indexOf("group") > -1 && row.blankCell.indexOf(key) === -1 && row[key] !== '') {
                let nodeLine = this.addBlankNodeLine();
                let sourceVariableName = row[key];
                if (typeof(row.selectedSourceColumnList) !== 'undefined') {
                  row.selectedSourceColumnList.filter(selectedSourceColumn => selectedSourceColumn.groupTitle === key).forEach(selectedSourceColumn => {
                    sourceVariableName = selectedSourceColumn.column;
                  })
                }
                nodeLine.node.target = targetNodeId;
                nodeLine.node.source = this.getElementNodeId(pivotMatrix.sourceTable, sourceVariableName, 'source')

                nodeLine.column.target = targetColumnDetail;
                let sourceColumnDetail = sourceColumnList.filter(sourceColumnRecord => sourceColumnRecord.tableName === pivotMatrix.sourceTable && sourceColumnRecord.column_header === sourceVariableName)
                if (sourceColumnDetail.length > 0) {
                  nodeLine.column.source = sourceColumnDetail[0].record
                }

                nodeLine.table.target = pivotMatrix.tableName
                nodeLine.table.source = pivotMatrix.sourceTable;

                nodeLine.columnTitle.target = row.targetColumn;
                nodeLine.columnTitle.source = sourceVariableName;

                if (columnMappingList.filter(columnMapped => columnMapped.node.target === targetNodeId && columnMapped.node.source === nodeLine.node.source ).length === 0) {
                  columnMappingList.push(nodeLine)
                }
              }
            }

            if (pivotMatrix.maximumGroupLength === totalRowBlankCellCount) {
              pivotMatrixRowAllBlankCellStatus = true;
              savePivotMatrixStatus = false;
              let displayWrongTargetTable = displayTargetTableList.filter(displayTargetTable => displayTargetTable.tableName === pivotMatrix.tableName)[0].displayTableName;

              if (displayWrongTargetTableList.indexOf(displayWrongTargetTable) === -1) {
                  displayWrongTargetTableList.push(displayWrongTargetTable)
              }
            }
          });

          this.props.pivotMatrixList.filter(savedPivotMatrix => savedPivotMatrix.tableName === pivotMatrix.tableName && savedPivotMatrix.sourceTable === pivotMatrix.sourceTable).forEach(savedPivotMatrix => {
            savedPivotMatrix.rows.forEach(savedRow => {
              let row = pivotMatrix.rows.filter(row => row.targetColumn === savedRow.targetColumn);

              if (row.length > 0 && typeof(savedRow.pivotOutput) !== 'undefined' && row[0].pivotOutput !== savedRow.pivotOutput) {
                removedTargetColumnMappedValueList.push({
                  targetId: this.getElementNodeId(savedPivotMatrix.tableName, savedRow.targetColumn, 'metadata'),
                  sourceTable: pivotMatrix.sourceTable
                })
              }

              for (let savedKey in savedRow) {
                if (savedKey.indexOf("group") > -1 && savedRow.blankCell.indexOf(savedKey) === -1 && savedRow[savedKey] !== '') {
                  let removeSourceColumnStatus = true;
                  let savedSourceVariableName = savedRow[savedKey];

                  if (typeof(savedRow.selectedSourceColumnList) !== 'undefined') {
                    savedRow.selectedSourceColumnList.filter(selectedSourceColumn => selectedSourceColumn.groupTitle === savedKey).forEach(selectedSourceColumn => {
                      savedSourceVariableName = selectedSourceColumn.column;
                    })
                  }
                  if (row.length > 0) {
                    for (let key in row[0]) {
                      let sourceVariableName = row[0][key];

                      if (typeof(row[0].selectedSourceColumnList) !== 'undefined') {
                        row[0].selectedSourceColumnList.filter(selectedSourceColumn => selectedSourceColumn.groupTitle === key).forEach(selectedSourceColumn => {
                          sourceVariableName = selectedSourceColumn.column;
                        })
                      }
                      if (key.indexOf("group") > -1 && row[0].blankCell.indexOf(key) === -1 && sourceVariableName !== '' && sourceVariableName === savedSourceVariableName) {
                        removeSourceColumnStatus = false;
                      }
                    }
                  }

                  if (removeSourceColumnStatus === true && removeMappedColumnList.filter(removeMappedColumn => removeMappedColumn.targetColumn === savedRow.targetColumn && removeMappedColumn.sourceColumn === savedSourceVariableName && removeMappedColumn.tableName === savedPivotMatrix.tableName && removeMappedColumn.sourceTable === pivotMatrix.sourceTable).length === 0) {
                    removeMappedColumnList.push({
                      targetId: this.getElementNodeId(savedPivotMatrix.tableName, savedRow.targetColumn, 'metadata'),
                      sourceId: this.getElementNodeId(pivotMatrix.sourceTable, savedSourceVariableName, 'source'),
                      targetColumn: savedRow.targetColumn,
                      sourceColumn: savedSourceVariableName,
                      tableName: savedPivotMatrix.tableName,
                      sourceTable: pivotMatrix.sourceTable})
                  }
                }
              }
            })
          })
        })
      }

      if (savePivotMatrixStatus) {
        pivotMatrixList.map(pivotMatrix => {
          pivotMatrix.rows.map(row => {
            row.columnList.length = 0;

            if (typeof(row.getSouceVariableExpression) !== 'undefined') {
              delete row.getSouceVariableExpression;
            }
            return row;
          })
          return pivotMatrix
        })

        this.props.addPivotMatrixList(pivotMatrixList)
        this.setState({pivotMatrixList: pivotMatrixList, columnMappingList: columnMappingList}, () => {
          this.addColumnMapping(removeMappedColumnList);
          if (removeMappedColumnList.length > 0 || removedTargetColumnMappedValueList.length > 0) {
            this.removeMappedColumnLine(removeMappedColumnList, removedTargetColumnMappedValueList)
          }

          MappedColumnValue.triggerActiveTargetColumnValue()
          this.state.closeEvent(true)
        })
      } else if (pivotMatrixRowAllBlankCellStatus === true) {
        this.props.setMessage("In Pivot Matrix, all pivot operation should not be empty column in same row. please check-out this target tables[ "+displayWrongTargetTableList.join(', ') +" ]", MessageTypeConst.ERROR_MESSAGE);
      } else {
        this.props.setMessage("In Pivot Matrix, "+(pivotMatrixBlankCellStatus === true ? "matrix should not be empty column" : "first row should not be hardcode/blank column")+". please check-out this target tables[ "+displayWrongTargetTableList.join(', ') +" ]", MessageTypeConst.ERROR_MESSAGE);
      }
    }

    getSourceAndTargetColumnList = (onlySelectedTarget = false) => {
      let {sourceTables, metaTableTables} = this.props.tableExtendedList;
      let sourceColumnList = [];
      let targetColumnList = [];

      sourceTables.filter(sourceTable => sourceTable.tableName.replace('_extended', '') === this.state.node.tableName).forEach((sourceTable) => {
        let tableName = sourceTable.tableName.replace('_extended', '');

        sourceTable.records.filter(record => record.column_header.indexOf('_app_') === -1 && record.column_header.indexOf('APP_') === -1).forEach((record) => {
          record = Object.assign(record, {blockName: sourceTable.blockName});
          sourceColumnList.push({tableName: tableName, column_header: record.column_header, record: record, type: 'source'});
        })
      })

      metaTableTables.filter(metaTable => !(onlySelectedTarget === true && typeof(this.state.selectedTargetTable.tableName) !== 'undefined' && this.state.selectedTargetTable.tableName !== metaTable.tableName.replace('_extended', ''))).forEach((metaTable) => {
        let tableName = metaTable.tableName.replace('_extended', '');
        metaTable.records.filter(record => record.column_header.indexOf('_app_') === -1 && record.column_header.indexOf('APP_') === -1).forEach((record) => {
          record = Object.assign(record, {blockName: metaTable.blockName.replace("_meta", '')});
          targetColumnList.push({tableName: tableName, column_header: record.column_header, record: record, type: 'metadata'});
        })
      })

      return { sourceColumnList: sourceColumnList, targetColumnList: targetColumnList}
    }

    handleTargetTableColumnChange = (event, selectedColumnList = []) => {
      let { tableList, selectedPivotOperationList, pivotOperationList } = this.state;
      let rows = [];
      let columnList = ["", "[Enter Text]"];
      let { sourceColumnList } = this.getSourceAndTargetColumnList()
      let selectedTargetColumnList = selectedColumnList.length > 0 ? selectedColumnList : event.value
      let invalidTargetColumnList = [];
      if (this.state.selectedTargetTable !== '' && typeof(this.state.selectedTargetTable.tableName) !== 'undefined') {
        selectedTargetColumnList.filter(selectedTargetColumn => (this.props.expressionList.filter(expression => typeof(expression.node) !== 'undefined' && expression.node.title === selectedTargetColumn && expression.node.tableName === this.state.selectedTargetTable.tableName && expression.expressionDetail.filter(tableExpression => tableExpression.tableName === this.state.node.tableName && tableExpression.tableOperation === 'No Pivot').length > 0).length > 0) || (this.props.hardcodeList.filter(hardcode => hardcode.tableName === this.state.selectedTargetTable.tableName && hardcode.column === selectedTargetColumn).length > 0)).forEach(selectedTargetColumn => {
          invalidTargetColumnList.push(selectedTargetColumn)
        })

        if (invalidTargetColumnList.length > 0) {
          this.props.setMessage("'"+invalidTargetColumnList.join(',')+"' variable is used as No-Pivot/Hardcode/Expression (Intermediate) operation", MessageTypeConst.WARNING_MESSAGE);
          selectedTargetColumnList = selectedTargetColumnList.filter(selectedTargetColumn => invalidTargetColumnList.indexOf(selectedTargetColumn) === -1);
          this.setState({selectedTargetColumnList: selectedTargetColumnList})
        }
      }

      sourceColumnList.forEach(sourceColumn => {
        columnList.push(sourceColumn.column_header)
      })
      let groupTitleList = [];
      selectedTargetColumnList.forEach(targetColumn => {
        let row = {rowId: rows.length, targetColumn: targetColumn, pivotOutput: 'Q_VALUE', blankCell: [], columnList: columnList, targetColumnTitle: targetColumn, selectedSourceColumnList: [], getSouceVariableExpression: this.getSouceVariableExpression}
        let changeRow = tableList.rows.filter(changeRow => changeRow.targetColumn === targetColumn);

        if (changeRow.length > 0) {
          changeRow = changeRow[0];
          row.blankCell = changeRow.blankCell
          row.pivotOutput = changeRow.pivotOutput

          if (typeof(changeRow.selectedSourceColumnList) !== 'undefined') {
            row.selectedSourceColumnList = changeRow.selectedSourceColumnList
          }
        }
        selectedPivotOperationList.forEach((title, index) => {
          const name = 'group'+(parseInt(title.replace('Pivot Operation ', '')));
          if (groupTitleList.filter(groupTitle => groupTitle.name === name).length === 0) {
            groupTitleList.push({name: name, title: title})
          }
          row = Object.assign(row, {[name]: typeof(changeRow[name]) !== 'undefined' ? changeRow[name] : ''})
        })

        row.blankCell = row.blankCell.filter(blankGroup => groupTitleList.filter(groupTitle => groupTitle.name === blankGroup).length > 0)

        if (typeof(row.selectedSourceColumnList) !== 'undefined') {
          groupTitleList.forEach(groupTitle => {
            if (row.blankCell.indexOf(groupTitle.name) === -1 && typeof(row[groupTitle.name]) !== 'undefined' && row.selectedSourceColumnList.filter(selectedSourceColumn => selectedSourceColumn.groupTitle === groupTitle.name).length === 0 && row[groupTitle.name] !== '') {
              row.selectedSourceColumnList.push({groupTitle: groupTitle.name, column: row[groupTitle.name]})
            }
          })
        }

        row.selectedSourceColumnList = row.selectedSourceColumnList.filter(selectedSourceColumn => groupTitleList.filter(groupTitle => groupTitle.name === selectedSourceColumn.groupTitle).length > 0)

        selectedPivotOperationList.forEach((title, index) => {
          const oldGroupIndex = parseInt(title.replace('Pivot Operation ', ''));
          const oldGroupName = 'group'+(oldGroupIndex);
          const newGroupName = 'group'+ (index+1);

          if (oldGroupName !== newGroupName && oldGroupIndex > (index+1) && typeof(row[newGroupName]) === 'undefined') {
            const oldGroupValue = row[oldGroupName];
            delete row[oldGroupName];
            row = Object.assign(row, {[newGroupName]:oldGroupValue});

            if (row.blankCell.indexOf(oldGroupName) > -1 && row.blankCell.indexOf(newGroupName) === -1) {
              row.blankCell = row.blankCell.filter(blankGroup => blankGroup !== oldGroupName);
              row.blankCell.push(newGroupName)
            }

            if (typeof(row['selectedSourceColumnList']) !== 'undefined') {
              row['selectedSourceColumnList'].filter(selectedSourceColumn => selectedSourceColumn.groupTitle === oldGroupName).map(selectedSourceColumn => {
                selectedSourceColumn.groupTitle = newGroupName
                return selectedSourceColumn
              })
            }
          }
        })

        rows.push(row)
      })

      if (rows.length > 0) {
        for (let key in rows[0]) {
          if (rows[0].hasOwnProperty(key) && key.indexOf("group") > -1) {
            tableList.columnProperty = tableList.columnProperty.filter(columnProperty => columnProperty.field !== key)
            let title = "Pivot Operation "+key.replace('group', '');
            if (groupTitleList.filter(groupTitle => groupTitle.name === key).length > 0) {
              title = groupTitleList.filter(groupTitle => groupTitle.name === key)[0].title;
            }

            tableList.columnProperty.push({ field: key, cell: SourceColumnCell, title: title, width:"250px"})
          }
        }
      }

      tableList.id = 'annotate-pivot-matrix-grid'+'-'+selectedPivotOperationList.join('-');
      tableList.rows.length = 0;
      tableList.rows.push(...rows)

      selectedPivotOperationList.forEach((selectedPivotOperation, index) => {
        selectedPivotOperationList[index] = 'Pivot Operation '+(index+1)
      })

      if (pivotOperationList.length < selectedPivotOperationList.length) {
        pivotOperationList = selectedPivotOperationList;
      }
      this.setState({selectedTargetColumnList: selectedTargetColumnList, tableList: tableList, pivotOperationList: pivotOperationList, selectedPivotOperationList: selectedPivotOperationList})
    }

    handleAddPivotOperationClick = (event) => {
      let {pivotOperationList, selectedPivotOperationList} = this.state;
      const newPivotOperation = 'Pivot Operation '+(pivotOperationList.length+1);
      const addGroup = 'group'+(selectedPivotOperationList.length+1)
      pivotOperationList.push(newPivotOperation);
      selectedPivotOperationList.push('Pivot Operation '+(selectedPivotOperationList.length+1))

      pivotOperationList = [...new Set(pivotOperationList)];
      selectedPivotOperationList = [...new Set(selectedPivotOperationList)];
      this.setState({pivotOperationList: pivotOperationList, selectedPivotOperationList: selectedPivotOperationList}, () => {
        this.addPivotOperation([addGroup])
      })
    }

    handlePivotOperationChange = (event) => {
      let selectedPivotOperationList = event.value
      let removeGroupList = [];
      let addGroupList = [];
      selectedPivotOperationList.sort((a, b) => parseInt(a.replace('Pivot Operation ', '')) - parseInt(b.replace('Pivot Operation ', '')));;

      this.state.selectedPivotOperationList.filter(selectedPivotOperation => selectedPivotOperationList.indexOf(selectedPivotOperation) === -1).forEach(selectedPivotOperation => {
        removeGroupList.push('group'+selectedPivotOperation.replace('Pivot Operation ', ''))
      })

      selectedPivotOperationList.filter(selectedPivotOperation => this.state.selectedPivotOperationList.indexOf(selectedPivotOperation) === -1).forEach(selectedPivotOperation => {
        addGroupList.push('group'+selectedPivotOperation.replace('Pivot Operation ', ''))
      })

      selectedPivotOperationList.forEach((selectedPivotOperation, index) => {
        selectedPivotOperationList[index] = 'Pivot Operation '+(index+1)
      })
      this.setState({selectedPivotOperationList: selectedPivotOperationList}, () => {
        if (removeGroupList.length > 0) {
          this.removePivotOperation(removeGroupList)
        } else if (addGroupList.length > 0) {
          this.addPivotOperation(addGroupList)
        }
      })
    }

    addBlankNodeLine = () => {
      return {
        node: {
          source: '',
          target: ''
        },
        line: {
          startX: 0,
          startY: 0,
          stopX: 0,
          stopY: 0
        },
        strokeColor: 'green',
        column: {
          source: {},
          target: {}
        },
        table: {
          source: '',
          target: ''
        },
        columnValue: {
          source: [],
          target: []
        },
        mappingType: 'column-column',
        parentColumn: {
          source: '',
          target: ''
        },
        targetId: null,
        columnTitle: {
          source: '',
          target: ''
        },
        display: 'hidden',
        sortOrder: {
          source: 0,
          target: 0
        },
        selected: true
      }
    }

    addColumnMapping = (removeMappedColumnList = []) => {
      let newColumnMappedList = [];
      this.state.columnMappingList.forEach(mappedColumn => {
        if (this.props.columnMappedList.filter(columnMapped => columnMapped.node.target === mappedColumn.node.target && columnMapped.node.source === mappedColumn.node.source ).length === 0) {
          this.props.columnMappedList.push(mappedColumn)
          newColumnMappedList.push(mappedColumn)
        }
      })

      let targetIdList = [];

      this.state.columnMappingList.forEach((columnMapped) => {
        if (targetIdList.indexOf(columnMapped.node.target) === -1) {
          targetIdList.push(columnMapped.node.target);
        }
      })

      if (targetIdList.length > 0) {
        this.addMultipleTargetColumnExpression(targetIdList, null, removeMappedColumnList)
        let treeElement = this.props.treeElement;

        if (typeof(treeElement.metadataTableTree) !== 'undefined') {
          treeElement.metadataTableTree.children.map((table) => {
            table.children.filter(column => targetIdList.indexOf(column.id) > -1).map(column => {
              column.columnStatus = this.props.columnMappedList.filter(node => node.mappingType === 'column-column' && node.node.target === column.id && eval(node.column.source.column_data_length) > eval(column.column.column_data_length)).length > 0 ? 'yellow' : column.columnStatus.replace('yellow', '');

              if (typeof(column.columnCTStatus) !== 'undefined' && column.columnCTStatus !== '') {
                column.columnStatus = column.columnCTStatus === 'No' ? 'red' : 'yellow'
              }

              return column;
            })
            return table
          })
        }
        this.props.updateTreeElementViaImmutable(treeElement);
      }

      if (newColumnMappedList.length > 0) {
        this.props.addMultipleColumnMappedList(newColumnMappedList, false, false);
      }
    }

    getElementNodeId = (tableName, columnName, type) => {
      let { sourceTableTree, metadataTableTree } = this.props.treeElement;
      let nodeId = '';
      let tableNode = '';
      if (typeof(sourceTableTree) !== 'undefined' && type === 'source') {
        tableNode = sourceTableTree.children.filter(table => table.tableName === tableName);
      }

      if (typeof(metadataTableTree) !== 'undefined' && type === 'metadata') {
        tableNode = metadataTableTree.children.filter(table => table.tableName === tableName)
      }

      if (tableNode.length > 0) {
        let columnNode = tableNode[0].children.filter(column  => column.title === columnName)
        if (columnNode.length > 0) {
          nodeId = columnNode[0].id
        }
      }

      return nodeId;
    }

    addMultipleTargetColumnExpression = (targetColumnList, renameColumnList = null, removeSourceColumnList = [], resetExression = false) => {
      let columnExpressionList = [];
      const targetRemoveSourceColumnList = cloneDeep(removeSourceColumnList)
      if (renameColumnList === null) {
        renameColumnList = this.props.renameColumnList;
      }

      targetColumnList.forEach((targetColumn) => {
        if (targetRemoveSourceColumnList.length > 0) {
          removeSourceColumnList = []
          targetRemoveSourceColumnList.filter(targetRemoveSourceColumn => targetRemoveSourceColumn.targetId === targetColumn).forEach(targetRemoveSourceColumn => {
            removeSourceColumnList.push(targetRemoveSourceColumn.sourceId)
          })
        }

        if (typeof(targetColumn) !== 'undefined') {
          let sourceColumnList = this.props.columnMappedList.filter((columnMap) => columnMap.node.target === targetColumn);
          let tableList = [];
          let nodeExpression = this.props.expressionList.filter(expression => expression.id === targetColumn);
          let reCalculateExpression = true;
          let isUserSaved = false;
          sourceColumnList.forEach((columnMapped) => {
              let sourceElement = '';
              let tableExpressionType = 'Single';
              let tableOperation = 'Pivot';
              let tableExpression = '';
              let elementExpression = '';
              let sourceColumnName = '';
              let group = 'Group 1';
              let groupExpressionDetail = [];
              let mappingGrid = [];
              let targetValues = [];
              let renameColumnHeader = null;
              let renameColumnDetail = []
              let tablePivotOutput = 'Q_VALUE';
              let pivotTargetColumn = this.state.pivotMatrixList.filter(pivotMatrix => pivotMatrix.tableName === columnMapped.table.target && pivotMatrix.sourceTable === columnMapped.table.source && pivotMatrix.rows.filter(row => row.targetColumn === columnMapped.columnTitle.target).length > 0);

              if (pivotTargetColumn.length === 0) {
                pivotTargetColumn = this.props.pivotMatrixList.filter(pivotMatrix => pivotMatrix.tableName === columnMapped.table.target && pivotMatrix.sourceTable === columnMapped.table.source && pivotMatrix.rows.filter(row => row.targetColumn === columnMapped.columnTitle.target).length > 0);
              }

              if (pivotTargetColumn.length > 0) {
                pivotTargetColumn.forEach(pivotMatrix => {
                  tablePivotOutput = pivotMatrix.rows.filter(row => row.targetColumn === columnMapped.columnTitle.target)[0].pivotOutput;
                })
              }

              if (columnMapped.mappingType !== '') {
                let columnType = columnMapped.mappingType.split('-');
                renameColumnDetail = renameColumnList.filter(renameColumnDetail => renameColumnDetail.id === columnMapped.node.source);

                if (renameColumnDetail.length > 0){
                  renameColumnHeader = renameColumnDetail[0].renameColumn.column;
                }

                if (columnType[0] === 'column' && typeof(columnMapped.column.source.column_header) !== 'undefined') {
                  sourceElement =   columnMapped.table.source + '->' + columnMapped.column.source.column_header;
                  elementExpression = this.getExpressionString(columnMapped.column.source, columnMapped.column.target, renameColumnHeader);

                  if (pivotTargetColumn.length > 0) {
                    pivotTargetColumn.forEach(pivotMatrix => {
                      pivotMatrix.rows.filter(row => row.targetColumn === columnMapped.columnTitle.target && typeof(row.selectedSourceColumnList) !== 'undefined').forEach(row => {
                        row.selectedSourceColumnList.filter(selectedSourceColumn => selectedSourceColumn.column === columnMapped.column.source.column_header).forEach(selectedSourceColumn => {
                          if (typeof(row[selectedSourceColumn.groupTitle]) !== 'undefined') {
                            elementExpression = row[selectedSourceColumn.groupTitle];
                          }
                        })
                      });
                    })
                  }
                  sourceColumnName = columnMapped.column.source.column_header;
                } else if(columnType[0] === 'value' && typeof(columnMapped.parentColumn.source.column_header) !== 'undefined')  {
                  sourceElement =   columnMapped.table.source + '->' + columnMapped.parentColumn.source.column_header + '->' + columnMapped.columnTitle.source;
                  elementExpression = this.getExpressionString(columnMapped.parentColumn.source, columnMapped.parentColumn.target, renameColumnHeader);
                  sourceColumnName = columnMapped.parentColumn.source.column_header;
                }

                if (renameColumnDetail.length > 0){
                  sourceElement += '('+renameColumnDetail[0].renameColumn.column+')';
                }

                if (tableExpressionType === 'Single') {
                  tableExpression = elementExpression;
                }
              }

              let table = tableList.filter(table => table.tableName === columnMapped.table.source);

              if (nodeExpression.length > 0 && nodeExpression[0].expressionDetail.length > 0) {
                reCalculateExpression = false;

                if (nodeExpression[0].expressionDetail.filter(expressionDetail => expressionDetail.tableName === columnMapped.table.source).length === 0) {
                  nodeExpression[0].expressionDetail.push({tableName: columnMapped.table.source, expressionType: tableExpressionType, tableOperation: tableOperation, expression: tableExpression, mappingGrid: mappingGrid, targetValues: targetValues, children:[{sourceElement: sourceElement,expression: elementExpression, sourceColumnName: sourceColumnName, group: group, renameSourceColumnName: renameColumnHeader}], groupExpressionDetail: groupExpressionDetail, columnPivotMatrix: {}, pivotOutput: tablePivotOutput, columnWhereMatrix: {}, error: false})
                }

                nodeExpression[0].expressionDetail.map((expressionDetail, index) => {
                  if (expressionDetail.tableName === columnMapped.table.source) {
                    expressionDetail.children.filter(sourceNode => sourceNode.sourceColumnName === columnMapped.column.source.column_header).map(sourceNode => {
                      if (pivotTargetColumn.length > 0) {
                        pivotTargetColumn.forEach(pivotMatrix => {
                          pivotMatrix.rows.filter(row => row.targetColumn === columnMapped.columnTitle.target && typeof(row.selectedSourceColumnList) !== 'undefined').forEach(row => {
                            row.selectedSourceColumnList.filter(selectedSourceColumn => selectedSourceColumn.column === columnMapped.column.source.column_header).forEach(selectedSourceColumn => {
                              if (typeof(row[selectedSourceColumn.groupTitle]) !== 'undefined' && row[selectedSourceColumn.groupTitle] !== sourceNode.expression) {
                                sourceNode.expression = row[selectedSourceColumn.groupTitle];
                                if (expressionDetail.children.length === 1 && expressionDetail.tableOperation === 'Pivot') {
                                  expressionDetail.expression = sourceNode.expression
                                }
                              }
                            })
                          });
                        })
                      }
                      return sourceNode;
                    })
                    const beforeSingleTableExpression = this.getSingleGroupExpression(nodeExpression[0].id, expressionDetail.children)
                    if (renameColumnDetail.length > 0 && renameColumnHeader !== null){
                      expressionDetail.children = expressionDetail.children.filter(sourceNode => sourceNode.sourceColumnName !== sourceColumnName)
                    }

                    let expressionDetailChildren = expressionDetail.children.filter(sourceNode => sourceNode.sourceColumnName === sourceColumnName);
                    if (expressionDetailChildren.length === 0 ) {
                      expressionDetail.children.push({sourceElement: sourceElement,expression: elementExpression, sourceColumnName: sourceColumnName, group: group, renameSourceColumnName: renameColumnHeader, carrySourceColumn: false, whereCondition: ''});
                      expressionDetail.children.sort((a,b) => ('' + a.group+a.sourceColumnName).localeCompare(b.group+b.sourceColumnName));
                    }

                    if (removeSourceColumnList.length > 0) {
                      expressionDetail.children = expressionDetail.children.filter(sourceColumn => removeSourceColumnList.filter(removeSourceColumn => removeSourceColumn.indexOf(expressionDetail.tableName+'-source') > -1 && removeSourceColumn.indexOf(sourceColumn.sourceColumnName+'-source') > -1).length === 0)
                    }

                    if (expressionDetail.expressionType !== 'Multiple' && expressionDetail.children.length > 1) {
                      nodeExpression[0].expressionDetail[index].expressionType = 'Multiple';
                    }

                    if (nodeExpression[0].expressionDetail[index].tableOperation === 'Pivot') {
                      nodeExpression[0].expressionDetail[index].pivotOutput = tablePivotOutput
                    }

                    if (nodeExpression[0].expressionDetail[index].expressionType === 'Single') {
                      let singleTableExpression = this.getSingleGroupExpression(nodeExpression[0].id, expressionDetail.children)

                      if (beforeSingleTableExpression === nodeExpression[0].expressionDetail[index].expression) {
                        nodeExpression[0].expressionDetail[index].expression = singleTableExpression
                      }
                    }
                  }

                  return expressionDetail
                })
              }

              if (resetExression === true) {
                reCalculateExpression = resetExression
              }

              if (reCalculateExpression === true) {
                if (table.length > 0) {
                  table[0].children.push({sourceElement: sourceElement,expression: elementExpression, sourceColumnName: sourceColumnName, group: group, renameSourceColumnName: renameColumnHeader, carrySourceColumn: false, whereCondition: ''});
                  table[0].children.sort((a,b) => ('' + a.group).localeCompare(b.group));
                  table[0].expressionType = 'Multiple';
                  table[0].tableOperation = tableOperation;
                } else {
                  tableList.push({tableName: columnMapped.table.source, expressionType: tableExpressionType, tableOperation: tableOperation, expression: tableExpression, mappingGrid: mappingGrid, targetValues:targetValues, children:[{sourceElement: sourceElement,expression: elementExpression, sourceColumnName: sourceColumnName, group: group, renameSourceColumnName: renameColumnHeader}], groupExpressionDetail: groupExpressionDetail, columnPivotMatrix: {}, pivotOutput: tablePivotOutput, columnWhereMatrix: {}, error: false})
                }
              }
          })

          if (reCalculateExpression === false) {
            tableList.length = 0;
            tableList.push(...nodeExpression[0].expressionDetail)
          }

          tableList.map(table => {
            table.children = table.children.filter(children => sourceColumnList.filter(sourceColumn => sourceColumn.table.source === table.tableName && sourceColumn.columnTitle.source === children.sourceColumnName).length > 0)

            if (table.tableOperation === 'Pivot') {
              table.expressionType = table.children.length === 1 ? 'Single' : 'Multiple';
            } else {
              table.expressionType = 'Single';
            }

            if (table.children.length === 1) {
              table.expression = table.children[0].expression
            }
            return table
          })

          tableList = tableList.filter(table => table.children.length > 0)
          columnExpressionList.push({id: targetColumn, expressionDetail: tableList, isUserSaved: isUserSaved})
        }
      })
      this.props.setDefaultTargetColumnExpression(columnExpressionList);
    }

    getExpressionString = (source, target, renameColumnHeader = null) => {
      let columnHeader = source.column_header;
      let expression = '';

      if (renameColumnHeader != null) {
        columnHeader = renameColumnHeader
      }
      if ((source.column_data_type === 'DATE' || source.column_data_type === 'DATETIME') && target.column_data_type === 'CHAR') {
          expression = 'put('+columnHeader+',is8601'+(source.column_data_type === 'DATE' ? 'da' :'dt')+'.)';
        } else if(source.column_data_type === 'CHAR' && target.column_data_type === 'NUM') {
          expression = 'input('+columnHeader+', best.)';
        } else if(source.column_data_type === 'NUM' && target.column_data_type === 'CHAR') {
          expression = 'put('+columnHeader+', best.-L)'
        } else {
          expression = columnHeader;
        }

        if (typeof(this.props.lastExecuteEngineValue) !== 'undefined' && typeof(this.props.lastExecuteEngineValue.value) !== 'undefined' && this.props.lastExecuteEngineValue.value === 3) {
          expression = columnHeader;
        }

        return expression;
    }

    getSingleGroupExpression = (targetId, sourceVariableList, group = 'Group 1') => {
      let sourceColumnList = this.props.columnMappedList.filter((collumnMap) => collumnMap.node.target === targetId);
      let expression = '';

      if (sourceColumnList.length > 0) {
        let { column, parentColumn, mappingType } = sourceColumnList[0];
        let columnType = mappingType.split('-');
        let targetType = '';
        if (columnType[1] === 'column' && typeof(column.target.column_header) !== 'undefined') {
          targetType = column.target.column_data_type;
        } else if(columnType[1] === 'value' && typeof(parentColumn.target.column_header) !== 'undefined')  {
          targetType = parentColumn.target.column_data_type;
        }

        if (targetType !== '') {
          let expressionList = [];

          if (sourceVariableList.length > 0) {
            sourceVariableList.filter(sourceElement => sourceElement.group === group).forEach((sourceVariable) => {
              let expressString = sourceVariable.expression;

              if (targetType === 'CHAR' && sourceVariableList.filter(sourceElement => sourceElement.group === group).length > 1) {
                expressString = 'trim('+expressString+')';
              }

              expressionList.push(expressString);
            })

            if (targetType !== 'CHAR') {
              expression = expressionList.join('+');
            } else {
              expression = expressionList.join('||" "||');
            }

            if (typeof(this.props.lastExecuteEngineValue) !== 'undefined' && typeof(this.props.lastExecuteEngineValue.value) !== 'undefined' && this.props.lastExecuteEngineValue.value === 3) {
              expression = expressionList.length > 1 ? 'paste('+expressionList.join(',')+')' : expressionList.join('');
            }
          }
        }
      }

      return expression;
    }

    removeMappedColumnLine = (removeMappedColumnList, removedTargetColumnMappedValueList) => {
      let nodeList = this.props.columnMappedList;
      let treeElement = this.props.treeElement;

      if (removeMappedColumnList.length > 0) {
        nodeList = nodeList.filter(columnMapped => !((removeMappedColumnList.filter(removeMappedColumn => removeMappedColumn.tableName === columnMapped.table.target && removeMappedColumn.sourceTable === columnMapped.table.source && ((removeMappedColumn.targetColumn === columnMapped.columnTitle.target && removeMappedColumn.sourceColumn === columnMapped.columnTitle.source) || (removeMappedColumn.targetColumn === columnMapped.parentColumn.target.column_header && removeMappedColumn.sourceColumn === columnMapped.parentColumn.source))).length > 0)))
        if(typeof(treeElement.metadataTableTree) !== 'undefined') {
          treeElement.metadataTableTree.children.filter(table => removeMappedColumnList.filter(removeMappedColumn => removeMappedColumn.tableName === table.tableName).length > 0 ).map(table => {
            table.children.filter(column => removeMappedColumnList.filter(removeMappedColumn => column.id === removeMappedColumn.targetId).length > 0).map(column => {
              column.removeMappedLine = nodeList.filter(columnMapped => columnMapped.node.target === column.id).length > 0 ? true : false;
              column.expression = column.removeMappedLine
              if (column.removeMappedLine === false) {
                column.cssClassName = column.cssClassName.replace('expression', 'hardcode-expression');
                MappedColumnValue.removeTargetColumn(column.id)
                this.addMultipleTargetColumnExpression([column.id], null, removeMappedColumnList, true)
              } else {
                this.addMultipleTargetColumnExpression([column.id], null, removeMappedColumnList)
              }

              if (typeof(column.columnStatus) !== 'undefined') {
                column.columnStatus = nodeList.filter(node => node.mappingType === 'column-column' && node.node.target === column.id && eval(node.column.source.column_data_length) > eval(column.column.column_data_length)).length > 0 ? 'yellow' : column.columnStatus.replace('yellow', '');

                if (typeof(column.columnCTStatus) !== 'undefined' && column.columnCTStatus !== '') {
                  column.columnStatus = column.columnCTStatus === 'No' ? 'red' : 'yellow'
                }
                if (nodeList.filter(node => node.mappingType === 'column-column' && node.node.target === column.id).length === 0) {
                  column.columnStatus = '';
                }
              }

              return column
            })
            return table;
          })
        }
      }

      if (removedTargetColumnMappedValueList.length > 0) {
        nodeList = nodeList.filter(node => removedTargetColumnMappedValueList.filter(removedTargetColumnMappedValue => removedTargetColumnMappedValue.targetId === node.targetId && removedTargetColumnMappedValue.sourceTable === node.table.source).length === 0 )
      }

      DrawColumnMappingLine.setNodeListState(nodeList);
      this.props.manageMappedColumnList(nodeList);
      this.props.setTreeElement(treeElement);
    }

    getSouceVariableExpression = (source, target) => {
      if (source !== '') {
        let { sourceColumnList, targetColumnList } = this.getSourceAndTargetColumnList(true);
        const sourceColumn = sourceColumnList.filter(sourceColumn => sourceColumn.column_header === source)
        const targetColumn = targetColumnList.filter(targetColumn => targetColumn.column_header === target)

        if (sourceColumn.length > 0 && targetColumn.length > 0) {
          source = this.getExpressionString(sourceColumn[0].record, targetColumn[0].record)
        }
      }
      return source;
    }

    removePivotOperation = (groupList) => {
      let { tableList, selectedPivotOperationList } = this.state;
      groupList.forEach(groupTitle => {
        tableList.rows.map(row => {
          row[groupTitle] = '""';

          if (row.blankCell.indexOf(groupTitle) === -1) {
            row.blankCell.push(groupTitle)
          }

          if (typeof(row['selectedSourceColumnList']) !== 'undefined') {
            row['selectedSourceColumnList'] = row['selectedSourceColumnList'].filter(selectedSourceColumn => selectedSourceColumn.groupTitle !== groupTitle)
          }

          return row;
        })
      })

      let { rows } = this.removeVerticalBlankGroup({rows: cloneDeep(tableList.rows), maximumGroupLength: selectedPivotOperationList.length+groupList.length})
      if (rows.length > 0) {
        tableList.columnProperty = tableList.columnProperty.filter(columnProperty => columnProperty.field.indexOf('group') === -1)
        selectedPivotOperationList.forEach(selectedPivotOperation => {
          tableList.columnProperty.push({ field: 'group'+selectedPivotOperation.replace('Pivot Operation ', ''), cell: SourceColumnCell, title: selectedPivotOperation, width:"250px", sortable: false, orderIndex: tableList.columnProperty.length+1})
        })
      }
      tableList.id = 'annotate-pivot-matrix-grid'+'-'+selectedPivotOperationList.join('-');
      tableList.rows.length = 0;
      tableList.rows.push(...rows)

      this.setState({tableList: tableList})
    }

    removeVerticalBlankGroup = (pivotMatrix) => {
      const pivotMatrixMaximumGroupLength = pivotMatrix.maximumGroupLength;
      let verticalGroupList = [];
      for(let i = 1; i <=pivotMatrixMaximumGroupLength; i++) {
        let totalBlankGroupCount = 0;
        let groupTitle = "group"+i;
        pivotMatrix.rows.map(row => {
          if (typeof(row[groupTitle]) === 'undefined') {
            row[groupTitle] = '""';
            if (row.blankCell.indexOf(groupTitle) === -1) {
              row.blankCell.push(groupTitle)
            }

            if (typeof(row['selectedSourceColumnList']) !== 'undefined') {
              row['selectedSourceColumnList'] = row['selectedSourceColumnList'].filter(selectedSourceColumn => selectedSourceColumn.groupTitle !== groupTitle)
            }
          }

          let pivotSourceColumn = this.findGroupSourceColumn(row, groupTitle);
          const groupSourceColumn = pivotSourceColumn !== '' ? pivotSourceColumn : row[groupTitle];

          if (row.blankCell.indexOf(groupTitle) > -1 && groupSourceColumn === '""') {
            totalBlankGroupCount++
          }
          return row
        })

        if (pivotMatrix.rows.length === totalBlankGroupCount) {
          verticalGroupList.push(groupTitle)
        }
      }

      let maxAvailableGroupList = []
      verticalGroupList.forEach(verticalGroup => {
        pivotMatrix.rows.map(row => {
          row.blankCell = row.blankCell.filter(group => group !== verticalGroup);
          if (typeof(row['selectedSourceColumnList']) !== 'undefined') {
            row['selectedSourceColumnList'] = row['selectedSourceColumnList'].filter(selectedSourceColumn => selectedSourceColumn.groupTitle !== verticalGroup)
          }
          delete row[verticalGroup];

          for (let key in row) {
            if (verticalGroupList.indexOf(key) === -1 && key.indexOf('group') > -1 && maxAvailableGroupList.indexOf(key) === -1) {
              maxAvailableGroupList.push(key);
            }
          }
          return row
        })
        pivotMatrix.maximumGroupLength--;
      })

      maxAvailableGroupList.sort((a, b) => parseInt(a.replace('group', '')) - parseInt(b.replace('group', '')));

      pivotMatrix.rows.map(row => {
        maxAvailableGroupList.forEach((maxAvailableGroup, groupIndex) => {
          const groupTitle = 'group'+(groupIndex+1);
          if (pivotMatrix.maximumGroupLength >= (groupIndex+1)){
            row[groupTitle] = row[maxAvailableGroup];
            if (row.blankCell.filter(group => group === maxAvailableGroup).length > 0) {
              row.blankCell = row.blankCell.filter(group => group !== maxAvailableGroup);
              row.blankCell.push(groupTitle);

              if (typeof(row['selectedSourceColumnList']) !== 'undefined') {
                row['selectedSourceColumnList'] = row['selectedSourceColumnList'].filter(selectedSourceColumn => selectedSourceColumn.groupTitle !== maxAvailableGroup)
                row['selectedSourceColumnList'] = row['selectedSourceColumnList'].filter(selectedSourceColumn => selectedSourceColumn.groupTitle !== groupTitle)
              }

            } else {
              if (typeof(row['selectedSourceColumnList']) !== 'undefined' && groupTitle !== maxAvailableGroup) {
                const selectedSourceColumn = row['selectedSourceColumnList'].filter(selectedSourceColumn => selectedSourceColumn.groupTitle === maxAvailableGroup);

                if (selectedSourceColumn.length > 0) {
                  row['selectedSourceColumnList'].push({groupTitle: groupTitle, column: selectedSourceColumn[0].column})
                  row['selectedSourceColumnList'] = row['selectedSourceColumnList'].filter(selectedSourceColumn => selectedSourceColumn.groupTitle !== maxAvailableGroup);
                }
              }
            }
          }
        })
        return row
      })

      for(let i = 1; i <= pivotMatrix.maximumGroupLength; i++) {
        pivotMatrix.rows.map(row => {
          for(let key in row) {
            if (key.indexOf("group") > -1 && parseInt(key.replace('group', '')) > pivotMatrix.maximumGroupLength) {
                delete row[key];
            }
          }
          return row
        })
      }
      return pivotMatrix
    }

    findGroupSourceColumn = (row, key) => {
      let pivotSourceColumn = '';
      if (typeof(row['selectedSourceColumnList']) !== 'undefined') {
        row['selectedSourceColumnList'].filter(selectedSourceColumn => selectedSourceColumn.groupTitle === key).forEach(selectedSourceColumn => {
          pivotSourceColumn = selectedSourceColumn.column;
        })
      }

      return pivotSourceColumn;
    }

    addPivotOperation = (groupList) => {
      let { tableList, selectedPivotOperationList } = this.state;

      groupList.forEach(groupTitle => {
        tableList.rows.map(row => {
          row = Object.assign(row, {[groupTitle]: ''})

          row.blankCell = row.blankCell.filter(group => group !== groupTitle);
          if (typeof(row['selectedSourceColumnList']) !== 'undefined') {
            row['selectedSourceColumnList'] = row['selectedSourceColumnList'].filter(selectedSourceColumn => selectedSourceColumn.groupTitle !== groupTitle)
          }
          return row
        })
      })


      if (tableList.rows.length > 0) {
        tableList.columnProperty = tableList.columnProperty.filter(columnProperty => columnProperty.field.indexOf('group') === -1)
        selectedPivotOperationList.forEach(selectedPivotOperation => {
          tableList.columnProperty.push({ field: 'group'+selectedPivotOperation.replace('Pivot Operation ', ''), cell: SourceColumnCell, title: selectedPivotOperation, width:"250px", sortable: false, orderIndex: tableList.columnProperty.length+1})
        })
      }
      tableList.id = 'annotate-pivot-matrix-grid'+'-'+selectedPivotOperationList.join('-');
      this.setState({tableList: tableList})
    }

    render(){
        return(
          <div className="pivot-order-dialog">
            <Dialog className="expression" title={`Pivot Metrics - ${this.state.node.name}`} width="calc(100% - 10px)" height="calc(100vh - 20px)" modal={true} onClose={this.state.closeEvent} buttons={this.state.displayTargetTableList.length > 0 ? [<Button primary={true} onClick={this.handleSaveClick} key="btn-save"> Save</Button>,<Button onClick={this.state.closeEvent} key="btn-cancel"> Cancel</Button>]: []}>
              {this.state.displayTargetTableList.length > 0 &&
                <div className="regex-columnMaaping-detail">
                  <div className="field_group_main">
                    <div className="container-fluid">
                      <div className="row">
                        <div className="col-12">
                          <div className="row">
                            <div className="col-12">
                              <div className="row p-0">
                                <div className="col-3">
                                  <div className="am-form-field dropdown-list">
                                    <span><b>Target Table: </b></span>
                                    <DropDownList data={this.state.displayTargetTableList} textField="displayTableName" dataItemKey="tableName" onChange={this.handleTargetTableChange} value={this.state.selectedTargetTable}/>
                                  </div>
                                </div>
                                <div className="col-5">
                                  <div className="am-form-field dropdown-list">
                                    <span><b>Target Variable List: </b></span>
                                    <MultiSelectDropDownList data={this.state.targetTableColumnList} onChange={this.handleTargetTableColumnChange} value={this.state.selectedTargetColumnList}/>
                                  </div>
                                </div>
                                <div className="col-4">
                                  <div className="am-form-field dropdown-list float-left pivot-list">
                                    <span><b>Pivot Operation List: </b></span>
                                    <MultiSelectDropDownList data={[...new Set(this.state.pivotOperationList)]} onChange={this.handlePivotOperationChange} value={[...new Set(this.state.selectedPivotOperationList)]}/>
                                  </div>
                                  <div className="am-form-field dropdown-list">
                                    <Button primary={true} onClick={this.handleAddPivotOperationClick} > Add</Button>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="col-12 matrix-list">
                            <div className="height_fixed_step1 full-width-table">
                              {this.state.tableList.rows.length > 0 &&
                                <RichGridTable {...this.state.tableList} key={this.state.tableList.id} />
                              }
                            </div>
                          <div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              }
              {this.state.displayTargetTableList.length === 0 &&
                <h4 className="align-center">No pivot operation found</h4>
              }
            </Dialog>
          </div>
        )
    }
}

const mapStateToProps = createSelector(
  state => state.annotate.columnMappedList,
  state => state.annotate.expressionList,
  state => state.annotate.tableExtendedList,
  state => state.annotate.pivotMatrixList,
  state => state.annotate.treeElement,
  state => state.annotate.renameColumnList,
  state => state.annotate.lastExecuteEngineValue,
  state => state.annotate.hardcodeList,
  (columnMappedList, expressionList, tableExtendedList, pivotMatrixList, treeElement, renameColumnList, lastExecuteEngineValue, hardcodeList) => ({
      columnMappedList,
      expressionList,
      tableExtendedList,
      pivotMatrixList,
      treeElement,
      renameColumnList,
      lastExecuteEngineValue,
      hardcodeList
    })
);
const mapActionsToProps = {
  setMessage: setMessage,
  addPivotMatrixList: addPivotMatrixList,
  setDefaultTargetColumnExpression: setDefaultTargetColumnExpression,
  addMultipleColumnMappedList: addMultipleColumnMappedList,
  updateTreeElementViaImmutable: updateTreeElementViaImmutable,
  setTreeElement: setTreeElement,
  manageMappedColumnList: manageMappedColumnList,
}
Dialog.propTypes = {
    height: PropTypes.oneOfType([
        PropTypes.string
    ]),
    width: PropTypes.oneOfType([
        PropTypes.string
    ])
}
export default connect(mapStateToProps, mapActionsToProps)(PivotMatrix);
