import React from "react";
import { PropTypes } from "prop-types";
import Dialog from 'react-dialog';
import FileViewerWrapper from './FileViewerWrapper'

function template() {

    return (
      <div className="data-operation">
        <div className="file-explorer">
            <Dialog title={"File Explorer"} width="310px" height="auto" modal={true} onClose={this.toggleDialog}>
                <FileViewerWrapper/>
            </Dialog>
        </div>
      </div>
    );
  };

  Dialog.propTypes = {
      height: PropTypes.oneOfType([
          PropTypes.string
      ]),
      width: PropTypes.oneOfType([
          PropTypes.string
      ])
  }
export default template;
