import React    from "react";
import template from "./TextEditor.jsx";

class TextEditor extends React.Component {
  render() {
    return template.call(this);
  }
}

export default TextEditor;
