import React from "react";
import { ProcessOptionGrid } from "../common/gridOperation";
import DataOpsIdentifierConstants from '../../../DataOpsIdentifierConstants';
import { Input } from './../common/InputBox/StandardInput';
import { ComboBox } from './../common/DropDown/StandardComboBox';

function GridLayoutTemplate() {
  let dateColumns = [];
  if(this.state.tableList){
    //Continues Date operation is applicable to single block only
    if(this.state.tableList[0]){
      let dateObjects = this.state.tableList[0].rows.filter(row=>row.type === 'DATE' || row.type === 'DATETIME' || (this.state.processFlowAction === DataOpsIdentifierConstants.CONTINUOUSDATE_OP && row.type === 'NUM'));
      dateColumns = dateObjects.map(a=>a.column);
    }
  }

  let startDateValue = "";
  let stopDateValue = "";
  let newColValue = "";
  let splitCondition = "";

  global.processBlockControlValues.forEach((control, index) => {
    if (control.controlId === 11) {
      splitCondition = global.processBlockControlValues[index].controlValue;
    }
  });

  global.processBlockControlValues.forEach((control, index) => {
    if (control.controlId === 6) {
      newColValue = global.processBlockControlValues[index].controlValue;
    }
  });

  global.processBlockControlValues.forEach((control, index) => {
    if (control.controlId === 4) {
      startDateValue = global.processBlockControlValues[index].controlValue;
      this.updateGlobalColumnAlwaysDisplay(startDateValue);
      this.updateTableRowAccoringToDisplayColumn(0, global.ColumnAlwaysNeedsToBeDisplay, []);

    }
  });

  global.processBlockControlValues.forEach((control, index) => {
    if (control.controlId === 5) {
      stopDateValue = global.processBlockControlValues[index].controlValue;
      this.updateGlobalColumnAlwaysDisplay(stopDateValue);
      this.updateTableRowAccoringToDisplayColumn(0, global.ColumnAlwaysNeedsToBeDisplay, []);
    }
  });



  var handleSplitConditionChange = (event) => {
    global.processBlockControlValues.forEach((control, index) => {
      if (control.controlId === 11) {
        global.processBlockControlValues[index].controlValue = event.target.value;
        this.setState({...this.state.tableList});
        return true;
      }
    });
  }

  var handleContinuesDateStartColumnChange = (event) => {
    global.processBlockControlValues.forEach((control, index) => {
      if (control.controlId === 4) {
        global.processBlockControlValues[index].controlValue = event.target.value;
        this.updateGlobalColumnAlwaysDisplay(event.target.value, startDateValue);
        this.updateTableRowAccoringToDisplayColumn(0, global.ColumnAlwaysNeedsToBeDisplay, [startDateValue]);
        this.setState({tableList: this.state.tableList, gridKey: this.state.gridKey+1});
        return true;
      }
    });
  }

  var handleContinuesDateEndColumnChange = (event) => {
    global.processBlockControlValues.forEach((control, index) => {
      if (control.controlId === 5) {
        global.processBlockControlValues[index].controlValue = event.target.value;
        this.setState({tableList: this.state.tableList, gridKey: this.state.gridKey+1});
        return true;
      }
    });
  }

  var handleContinuesNewColumnChange = (event) => {
    global.processBlockControlValues.forEach((control, index) => {
      if (control.controlId === 6) {
        global.processBlockControlValues[index].controlValue = event.target.value;
        this.setState({...this.state.tableList});
        return true;
      }
    });
  }

  return (
    <div className="process-option">
        { this.state.processFlowAction === DataOpsIdentifierConstants.CONTINUOUSDATE_OP &&
          <div className={`filter-area am-form-inline m-0 col-12 p-0`}>
            <div className="field_group">
              <div className="am-form-inline">
                <div className="row">
                  <div className="col-md-6">
                    <div className="am-form-field">
                      <span>Start (Date/Num):</span>
                      <ComboBox className="m-0" placeholder="" data={dateColumns} onChange={handleContinuesDateStartColumnChange}  value={startDateValue} />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="am-form-field">
                      <span>Stop (Date/Num):</span>
                      <ComboBox className="m-0" placeholder="" data={dateColumns}
                      onChange={handleContinuesDateEndColumnChange}  value={stopDateValue}/>
                    </div>
                  </div>
                </div>
                <div className="am-form-inline">
                  <div className="am-form-field m-0">
                    <span>New Variable Name:</span>
                    <Input onChange={handleContinuesNewColumnChange}  value={newColValue}/>
                  </div>
                </div>
              </div>
            </div>
          </div>
        }

        { this.state.processFlowAction === DataOpsIdentifierConstants.SPLIT_OP &&
          <div className={`m-0 row p-0`}>
            <div className={`m-0 col-12 p-0`}>
              <fieldset>
                <div className="field_group">
                  <legend>Where Condition</legend>
                  <label className="am-form-field m-0 col-12 p-0">
                    <Input className="width-full" value={splitCondition} onChange={handleSplitConditionChange} />
                  </label>
                </div>
              </fieldset>
            </div>
          </div>
        }
      <div className="field_group m_b_0">
        {/* <label className={`am-form-field`}>
          <span>Find:</span>
          <input className="am-textbox m_l_5" placeholder="Search data" onChange={this.handleSeachDataChangeEvent} />
        </label> */}
        <div className={`grid_height height_fixed ${this.state.gridClassName}`}>
          <ProcessOptionGrid tableList={this.state.tableList} />
        </div>
      </div>
    </div>
  );
};

export default GridLayoutTemplate;
