const SERVER_URL = process.env.REACT_APP_API_URL ||'https://qa.analysismate.com/analysismate/api';

const SYSTEM_IDLE_TIMEOUT = process.env.REACT_APP_DEFAULT_SYSTEM_IDLE_TIMEOUT || 1000 * 60 * 55;

const DEFAULT_CLIENT_ID = process.env.REACT_APP_DEFAULT_CLIENT_ID || 'nbg1mcte9a9u2hlkbtoib8d9q';

const DEFAULT_AWS_REGION = process.env.REACT_APP_DEFAULT_AWS_REGION || 'us-east-1';

const DEFAULT_REDIRECT_URI = process.env.REACT_APP_DEFAULT_REDIRECT_URI || 'https://qa.analysismate.com';

const DEFAULT_CLIENT_DOMAIN = process.env.REACT_APP_DEFAULT_CLIENT_DOMAIN || 'https://qa-analysismate-roles';

const DEFAULT_LOGIN_URL = process.env.REACT_APP_DEFAULT_LOGIN_URL || DEFAULT_CLIENT_DOMAIN+'.auth.'+DEFAULT_AWS_REGION+'.amazoncognito.com/login?response_type=code&client_id='+DEFAULT_CLIENT_ID+'&redirect_uri='+DEFAULT_REDIRECT_URI;

const DEFAULT_LOGOUT_URL = process.env.REACT_APP_DEFAULT_LOGOUT_URL || DEFAULT_CLIENT_DOMAIN+'.auth.'+DEFAULT_AWS_REGION+'.amazoncognito.com/logout?client_id='+DEFAULT_CLIENT_ID+'&logout_uri='+DEFAULT_REDIRECT_URI;

const DEFAULT_APP_VERSION = process.env.REACT_APP_DEFAULT_APP_VERSION || '1.0';

export { SERVER_URL, DEFAULT_LOGIN_URL, DEFAULT_LOGOUT_URL, SYSTEM_IDLE_TIMEOUT, DEFAULT_CLIENT_ID, DEFAULT_AWS_REGION, DEFAULT_REDIRECT_URI, DEFAULT_CLIENT_DOMAIN, DEFAULT_APP_VERSION };
