import {
  ADD_PROCESSFLOW, REMOVE_PROCESSFLOW, SET_TENANT_ID, REMOVE_PROCESSFLOW_BLOCK_BY_TABINDEX, ADD_PROCESSFLOW_BLOCK_BY_TABINDEX,UPDATE_PROCESSFLOW_BLOCK_BY_TABINDEX, ADD_PROCESSFLOW_BLOCKLINK_BY_TABINDEX,
  SET_PROCESSFLOW_ACTION, SET_PROCESSFLOW_TABLE, SET_PROCESSFLOW_TABLE_ID, SET_QUICK_CONDITION, SET_SUB_MENU_COLUMNS,
  UPDATE_PROCESSFLOW_BLOCK_RENDER_STATUS_BY_TABINDEX, ADD_IMPORT_TABLE, ADD_PROCESSFLOW_TITLE, SET_ACTIVE_PROCESSFLOW, SET_ALL_PROCESSFLOW_RENDER_STATUS, SELECTITEMS_FOR_COPY_PASTE, UPDATE_PROCESSFLOW_BLOCK_BY_SCHEMA_NAME,
  UPDATE_PROCESSFLOW_SYNC_FLAG_BY_TABINDEX, REMOVE_PROCESSFLOW_BLOCKLINK_BY_TABINDEX, REMOVE_IMPORT_TABLE, REFRESH_PROCESS_FLOW, TOGGLE_REFRESH_TOKEN,
  REMOVE_PROCESSFLOW_BY_PROJECT_ID, REMOVE_PROCESSFLOW_BY_STUDY_ID, REMOVE_PROCESSFLOW_BY_PROCESS_GROUP_ID, UPDATE_PROCESSFLOW_CHECKED_OUT_BY_TABINDEX, SET_STUDY_ID, SET_PROJECT_ID, SET_JUMP_TO_BLOCK_ID_PROCESS_ID,
  REMOVE_PROCESSFLOW_NOTE_BY_TABINDEX, ADD_PROCESSFLOW_NOTE_BY_TABINDEX, UPDATE_PROCESSFLOW_NOTE_BY_TABINDEX, UPDATE_PROCESSFLOW_NOTE_BY_SCHEMA_NAME, ADD_MULTIPLE_PROCESSFLOW_BLOCKLINK_BY_TABINDEX, REMOVE_ALL_PROCESSFLOW
} from '../actions/actionsTypes';

const INITIAL_DATA = {
  processFlowList:[],
  tenantId: 'master',
  processFlowAction: null,
  processFlowTable: null,
  processFlowTableId: null,
  quickcondition: null,
  submenucolumns: null,
  importTable: [],
  processFlowTitle: [],
  activeProcessFlow: 0,
  selectsItems: [],
  refreshToken:false,
  studyId:'',
  projectId:'',
  jumpToBlockId:0,
  jumpToProcessId:0
};

export default function processFlowReducer(state=INITIAL_DATA, action) {
  let importTable;

  switch (action.type) {
    case ADD_PROCESSFLOW:
      var processFlowList = state.processFlowList;
      let processFlow = state.processFlowList.filter( processFlow => processFlow.process.processId === action.payload.process.processId && processFlow.schemaName === action.payload.schemaName);

      if (processFlow.length === 0) {
        processFlowList = state.processFlowList.slice();
        processFlowList.splice(processFlowList.length, 0, Object.assign({}, action.payload, {title: action.payload.studyName+':'+action.payload.process.processName, projectName: '', studyName: '', groupName: '', blockRender: true}));
      }

      return {
        ...state,
        processFlowList: processFlowList,
        activeProcessFlow: (processFlowList.length - 1)
      }
    case REMOVE_PROCESSFLOW:
      return {
          ...state,
          processFlowList: state.processFlowList.filter( processFlow => !(processFlow.process.processId === action.payload.processId && processFlow.process.studyId === action.payload.studyId) )
      }
    case SET_TENANT_ID:
      return {
        ...state,
        tenantId: action.payload
      }
    case REMOVE_PROCESSFLOW_BLOCK_BY_TABINDEX:
      state.processFlowList.map((processFlow, index) => {
        if (action.payload.tabIndex === index) {
          processFlow.process.processBlock = processFlow.process.processBlock.filter( block => block.blockId !== action.payload.blockId);
          processFlow.process.processBlockLink = processFlow.process.processBlockLink.filter( blockLink => blockLink.blockId2 !== action.payload.blockId);
        }

        return processFlow;
      });

      return {
        ...state,
        processFlowList: state.processFlowList
      }
    case ADD_PROCESSFLOW_BLOCK_BY_TABINDEX:
      state.processFlowList.map((processFlow, index) => {

          if (action.payload.tabIndex === index) {
            let procesFlowBlockList = processFlow.process.processBlock.slice();
            procesFlowBlockList.splice(procesFlowBlockList.length, 0, action.payload.block);

            processFlow.process.processBlock.splice(0);
            processFlow.process.processBlock.splice(0, 0, ...procesFlowBlockList);
          }

          return processFlow;
        });

        return {
          ...state,
          processFlowList: state.processFlowList
        }
    case UPDATE_PROCESSFLOW_BLOCK_BY_TABINDEX:
      state.processFlowList.map((processFlow, index) => {

          if (action.payload.tabIndex === index) {
            processFlow.process.processBlock = processFlow.process.processBlock.filter( block => block.blockId !== action.payload.block.blockId);
            processFlow.process.processBlock.splice(action.payload.blockIndex, 0, action.payload.block);
          }

          return processFlow;
        });

        return {
          ...state,
          processFlowList: state.processFlowList
        }
    case ADD_PROCESSFLOW_BLOCKLINK_BY_TABINDEX:
      state.processFlowList.map((processFlow, index) => {

          if (action.payload.tabIndex === index) {
            let procesFlowBlockLinkList = processFlow.process.processBlockLink.slice();
            procesFlowBlockLinkList.splice(procesFlowBlockLinkList.length, 0, action.payload.blockLink);

            processFlow.process.processBlockLink.splice(0);
            processFlow.process.processBlockLink.splice(0, 0, ...procesFlowBlockLinkList);
          }

          return processFlow;
      });

      return {
        ...state,
        processFlowList: state.processFlowList
      }
    case ADD_MULTIPLE_PROCESSFLOW_BLOCKLINK_BY_TABINDEX:
      state.processFlowList.map((processFlow, index) => {

          if (action.payload.tabIndex === index) {
            if (action.payload.reset === true) {
              processFlow.process.processBlockLink.length = 0;
            }
            processFlow.process.processBlockLink.push(...action.payload.blockLinkList)
          }

          return processFlow;
        });

      return {
        ...state,
        processFlowList: state.processFlowList
      }
    case SET_PROCESSFLOW_ACTION:
      return {
        ...state,
        processFlowAction: action.payload
      }
    case SET_PROCESSFLOW_TABLE:
      return {
        ...state,
        processFlowTable: action.payload
      }
    case SET_PROCESSFLOW_TABLE_ID:
      return {
        ...state,
        processFlowTableId: action.payload
      }
    case SET_QUICK_CONDITION:
      return {
        ...state,
        quickcondition: action.payload
      }
    case SET_SUB_MENU_COLUMNS:
      return {
        ...state,
        submenucolumns: action.payload
      }
    case UPDATE_PROCESSFLOW_BLOCK_RENDER_STATUS_BY_TABINDEX:
      state.processFlowList.map((processFlow, index) => {
        if (action.payload.tabIndex === index) {
          processFlow.blockRender = action.payload.status
        }

        return processFlow;
      });

      return {
        ...state,
        processFlowList: state.processFlowList
      }
    case ADD_IMPORT_TABLE:
        importTable = state.importTable.slice();
        importTable = importTable.filter((table) => !(table.name === action.payload.name && table.schemaName === action.payload.schemaName) );

        importTable.splice(importTable.length, 0, Object.assign({}, action.payload));

        return {
          ...state,
          importTable: importTable
        }
    case ADD_PROCESSFLOW_TITLE:
      let processFlowTitle = state.processFlowTitle.slice();
      processFlowTitle = processFlowTitle.filter((title) => !(title.schemaName === action.payload.schemaName && title.processId === action.payload.processId))
      processFlowTitle.splice(processFlowTitle.length, 0, Object.assign({}, action.payload));

      state.processFlowList.map((processFlow, index) => {
        if(processFlow.process.processId === action.payload.processId && processFlow.schemaName === action.payload.schemaName)
        {
          processFlow.blockRender = true;

          if (typeof(action.payload.name) !== 'undefined') {
              processFlow.name = action.payload.name;
          }
          processFlow.title = action.payload.title;

          if (typeof(action.payload.processGroupId) !== 'undefined') {
              processFlow.process.processGroupId = action.payload.processGroupId;
          }
        }
        return processFlow;
      });
      return {
        ...state,
        processFlowList: state.processFlowList,
        processFlowTitle: processFlowTitle
      }
    case SET_ACTIVE_PROCESSFLOW:
      let tenantId = state.tenantId;
      let processflow  = state.processFlowList[action.payload];
      if(processflow !== null && typeof(processflow) !== "undefined"){
        tenantId = processflow.schemaName;
      }

      return {
        ...state,
        tenantId: tenantId,
        activeProcessFlow: action.payload
      }
    case SET_ALL_PROCESSFLOW_RENDER_STATUS:
      state.processFlowList.map((processFlow, index) => {
        processFlow.blockRender = action.payload;

        return processFlow;
      });
      return {
        ...state,
        processFlowList: state.processFlowList
      }
    case SELECTITEMS_FOR_COPY_PASTE:
      state.selectsItems.splice(0);
      state.selectsItems.splice(0, 0, ...action.payload);
      return {
        ...state,
        selectsItems: state.selectsItems
      }
    case UPDATE_PROCESSFLOW_BLOCK_BY_SCHEMA_NAME:
      action.payload.blockList.forEach((updateBlock) => {
        state.processFlowList.map((processFlow) => {

          if (action.payload.schemaName === processFlow.schemaName && processFlow.process.processId === updateBlock.processId) {
            var blockIndex = processFlow.process.processBlock.length;

            processFlow.process.processBlock.forEach((block, index) => {
              if (block.blockId === updateBlock.blockId) {
                blockIndex = index;
                return true;
              }
            })

            processFlow.process.processBlock = processFlow.process.processBlock.filter( block => block.blockId !== updateBlock.blockId);
            processFlow.process.processBlock.splice(blockIndex, 0, updateBlock);
            processFlow.blockRender = true;
          }

          return processFlow;
        });
      })

      return {
        ...state,
        processFlowList: state.processFlowList
      }

    case UPDATE_PROCESSFLOW_SYNC_FLAG_BY_TABINDEX:
      state.processFlowList.map((processFlow, index) => {

          if (action.payload.tabIndex === index) {
            processFlow.process.processSyncFlag = action.payload.processSyncFlag;
          }

          return processFlow;
        });

        return {
          ...state,
          processFlowList: state.processFlowList
        }
    case REMOVE_PROCESSFLOW_BLOCKLINK_BY_TABINDEX:
      state.processFlowList.map((processFlow, index) => {
        if (action.payload.tabIndex === index) {
           processFlow.process.processBlockLink = processFlow.process.processBlockLink.filter( blockLink => blockLink.linkId !== action.payload.linkId);
        }

        return processFlow;
      });

      return {
        ...state,
        processFlowList: state.processFlowList
      }
    case REMOVE_IMPORT_TABLE:
      importTable = state.importTable.slice();
      importTable = importTable.filter((table) => !(table.name === action.payload.name && table.schemaName === action.payload.schemaName) );
      return {
        ...state,
        importTable: importTable
      }
    case REFRESH_PROCESS_FLOW:
      state.processFlowList.map((activeFlow, index)=>{
        if(index === state.activeProcessFlow){
          return action.payload.process;
        }else{
          return activeFlow;
        }
      })

      return {
        ...state,
        processFlowList: state.processFlowList,
        refreshToken : !state.refreshToken
      }
    case TOGGLE_REFRESH_TOKEN:
      return {
        ...state,
        refreshToken : !state.refreshToken
      }
    case REMOVE_PROCESSFLOW_BY_PROJECT_ID:
      return {
          ...state,
          processFlowList: state.processFlowList.filter( processFlow => processFlow.projectId !== action.payload )
      }
    case REMOVE_PROCESSFLOW_BY_STUDY_ID:
      return {
          ...state,
          processFlowList: state.processFlowList.filter( processFlow => processFlow.process.studyId !== action.payload )
      }
    case REMOVE_PROCESSFLOW_BY_PROCESS_GROUP_ID:
      return {
          ...state,
          processFlowList: state.processFlowList.filter( processFlow => !(processFlow.process.processGroupId === action.payload.processGroupId && processFlow.process.studyId === action.payload.studyId) )
      }
    case REMOVE_ALL_PROCESSFLOW:
        return {
            ...state,
            processFlowList: []
        }
    case UPDATE_PROCESSFLOW_CHECKED_OUT_BY_TABINDEX:

      state.processFlowList.map((processFlow, index) => {
        if (action.payload.tabIndex === index) {
          if(typeof(processFlow.process.checkOutFlag) !== "undefined"){
            processFlow.process.checkOutFlag = action.payload.checkOutFlag;
          }
          if(typeof(processFlow.process.userName) !== "undefined"){
            processFlow.process.userName = action.payload.userName;
          }
        }
        return processFlow;
      });

      return {
        ...state,
        processFlowList: state.processFlowList
      }
    case SET_STUDY_ID:
      return {
        ...state,
        studyId: action.payload
      }
    case SET_PROJECT_ID:
      return {
        ...state,
        projectId: action.payload
      }
    case SET_JUMP_TO_BLOCK_ID_PROCESS_ID:
      return {
        ...state,
        jumpToBlockId: action.payload.blockId,
        jumpToProcessId: action.payload.processId
      }
    case REMOVE_PROCESSFLOW_NOTE_BY_TABINDEX:
      state.processFlowList.map((processFlow, index) => {
        if (action.payload.tabIndex === index) {
          processFlow.process.processFlowNotes = processFlow.process.processFlowNotes.filter( block => block.noteId !== action.payload.noteId);
        }

        return processFlow;
      });

      return {
        ...state,
        processFlowList: state.processFlowList
      }
    case ADD_PROCESSFLOW_NOTE_BY_TABINDEX:
      state.processFlowList.map((processFlow, index) => {

          if (action.payload.tabIndex === index) {
            let procesFlowNoteList = processFlow.process.processFlowNotes.slice();
            procesFlowNoteList.splice(procesFlowNoteList.length, 0, action.payload.note);

            processFlow.process.processFlowNotes.splice(0);
            processFlow.process.processFlowNotes.splice(0, 0, ...procesFlowNoteList);
          }

          return processFlow;
        });

        return {
          ...state,
          processFlowList: state.processFlowList
        }
    case UPDATE_PROCESSFLOW_NOTE_BY_TABINDEX:
      state.processFlowList.map((processFlow, index) => {

          if (action.payload.tabIndex === index) {
            processFlow.process.processFlowNotes = processFlow.process.processFlowNotes.filter( pnotes => pnotes.noteId !== action.payload.note.noteId);
            processFlow.process.processFlowNotes.splice(action.payload.noteIndex, 0, action.payload.note);
          }

          return processFlow;
        });

        return {
          ...state,
          processFlowList: state.processFlowList
      }
      case UPDATE_PROCESSFLOW_NOTE_BY_SCHEMA_NAME:
      action.payload.noteList.forEach((updateNote) => {
        state.processFlowList.map((processFlow) => {

          if (action.payload.schemaName === processFlow.schemaName && processFlow.process.processId === updateNote.processId) {
            var noteIndex = processFlow.process.processFlowNotes.length;

            processFlow.process.processFlowNotes.forEach((note, index) => {
              if (note.noteId === updateNote.noteId) {
                noteIndex = index;
                return true;
              }
            })

            processFlow.process.processFlowNotes = processFlow.process.processFlowNotes.filter( pnotes => pnotes.noteId !== updateNote.noteId);
            processFlow.process.processFlowNotes.splice(noteIndex, 0, updateNote);
            processFlow.blockRender = true;
          }

          return processFlow;
        });
      })

      return {
        ...state,
        processFlowList: state.processFlowList
      }

    default:
      return state;
  }
}
