import React from 'react';
import { Button } from './StandardButton';
import { PropTypes } from "prop-types";
import Dialog from 'react-dialog';
import { POST_PARAM_BODY } from '../../../../../restClient/types';
import restClient from '../../../../../restClient';
import axios from 'axios';
import $ from 'jquery';
import {Controlled as CodeMirror} from 'react-codemirror2';
import { connect } from 'react-redux';
import { createSelector } from 'reselect';
import MessageTypeConst from "../../../../MessageTypeConst";
import { setMessage } from '../../../../../actions/actionNotification';
import { highLightText } from '../../../TextEditor/TextEditor.jsx';

export const LogButton = ({ tableManager, value, data, column, colIndex, rowIndex }) => {
    if (typeof(tableManager) === 'undefined') {
      return (null)
    } else {
      return (
          <div className='rgt-cell-inner' style={{display: 'flex', alignItems: 'center', overflow: 'hidden'}}>
              <LogButtonClass dataItem={data} field={column.field} value={typeof(data[column.field]) !== 'undefined' ? data[column.field]: value} column={column} tableManager={tableManager}/>
          </div>
      )
    }
}

class BaseLogButton extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            log:'',
            errorCount: 0,
            warningCount: 0,
            visible: false
        };
        this.instance = null;
        this.closeDialog = this.closeDialog.bind(this);
        this.openDialog = this.openDialog.bind(this);

      }
      openDialog(){
        var log = this.props.dataItem['log'];
        let that = this;
        if(log !== null && log !== ""){
            var str = log.toLowerCase();;
            var errorCount = str.match(/\berror(\s)*([a-z0-9-])*:/ig) !== null ? str.match(/\berror(\s)*([a-z0-9-])*:/ig).length : 0;
            var warningCount = str.match(/\bwarning:/ig) !== null ? str.match(/\bwarning:/ig).length : 0;
            this.setState({log:log, errorCount:errorCount,warningCount:warningCount, visible: true}, () => {
                highLightText(str, that.instance.editor);
            })
        }else{
            let fetchlog = (headers, params) => restClient(POST_PARAM_BODY, 'dataviewer/log', params, headers);
            var params = null;
            if (typeof(this.props.dataItem['outTableBlock']) !== "undefined" && this.props.dataItem['outTableBlock'].length > 0) {
              params = this.props.dataItem['outTableBlock'][0]
            } else {
              params = JSON.parse(this.props.dataItem['block']);
              params = Object.assign({},params,{blockVersion:this.props.dataItem['versionNo']});
            }

            let Tenant_ID = this.props.tenantId;
            $(".loader").show();
            axios.all([fetchlog({'Tenant-ID': Tenant_ID}, { data: params })])
                .then(axios.spread((logResponse) => {
                    if (typeof(logResponse.data) !== 'undefined') {
                        if (typeof(logResponse.data.log) !== 'undefined' && logResponse.data.log !== null) {
                             var str = logResponse.data.log.toString().toLowerCase();
                             var errorCount = str.match(/\berror(\s)*([a-z0-9-])*:/ig) !== null ? str.match(/\berror(\s)*([a-z0-9-])*:/ig).length : 0;
                             var warningCount = str.match(/\bwarning:/ig) !== null ? str.match(/\bwarning:/ig).length : 0;
                             that.props.dataItem['log'] = logResponse.data.log;
                             that.setState({log:logResponse.data.log,errorCount:errorCount,warningCount:warningCount, visible: true}, () => {
                                highLightText(str, that.instance.editor);
                             });
                        }
                     }
                    $(".loader").hide();
                }
                )).catch(error => {
                    $(".loader").hide();
                    that.props.setMessage("Failed to fetch code.",MessageTypeConst.ERROR_MESSAGE)
                });
        }
      }

      closeDialog() {
        this.setState({
            visible: false
          });
      }

      /*UNSAFE_componentWillReceiveProps(nextProps) {
        if (typeof(nextProps.dataItem.log) !== 'undefined' && typeof(nextProps.dataItem.jobId) !== 'undefined' && nextProps.dataItem.log !== '' && nextProps.dataItem.jobId !== '') {
          var log = nextProps.dataItem.log
          var str = log.toLowerCase();;
          var errorCount = str.match(/\berror(\s)*([a-z0-9-])*:/ig) !== null ? str.match(/\berror(\s)*([a-z0-9-])*:/ig).length : 0;
          var warningCount = str.match(/\bwarning:/ig) !== null ? str.match(/\bwarning:/ig).length : 0;
          this.setState({log:log, errorCount:errorCount,warningCount:warningCount, visible: true}, () => {
              highLightText(str, this.instance.editor);
          })
        }
      }*/

      render() {
          let disabled = false;
          let block = typeof(this.props.dataItem['block']) !== "undefined" && this.props.dataItem['block'] !== null && this.props.dataItem['block'] !== "" ?
          JSON.parse(this.props.dataItem['block']) : {};
          if((typeof(block.blockLevel) !== "undefined" && block.blockLevel !== null && block.blockLevel === 0) || (typeof(this.props.dataItem['jobId']) !== 'undefined' && (this.props.dataItem['log'] === '' || this.props.dataItem['log'] === null))){
            disabled = true;
          }

          if (typeof(this.props.dataItem['outTableBlock']) !== "undefined" && this.props.dataItem['outTableBlock'].length > 0) {
            disabled = false;
          }

          return (
            <div className="text-center width-full text-editor">
            <Button onClick={this.openDialog} disabled={disabled}> Log </Button>
              {this.state.visible &&
            <div className="data-operation code-dialog log-dialog">
            <Dialog title={'Log'} width="calc(100% - 200px)" height="calc(100vh - 120px)" onClose={this.closeDialog}>
                <div className="align-left">
                    <label className="warning">Warning: <span>{this.state.warningCount}</span></label>
                    <label className="error">Error: <span>{this.state.errorCount}</span></label>
                </div>
                <div className="log_Editor">
                    <CodeMirror ref = {ref => this.instance = ref} value={this.state.log} options = { {'lineNumbers': true, readOnly: true, mode:"javascript", styleSelectedText:true} }/>
                </div>
            </Dialog>
            </div>
              }
              </div>

          );
      }
}

const mapStateToProps = createSelector(
    state => state.processFlow.tenantId,
    (tenantId) => ({
        tenantId
    })
  );

  const mapActionsToProps = {
    setMessage: setMessage
  }

  BaseLogButton.propTypes = {
    tenantId: PropTypes.string
  }

  Dialog.propTypes = {
      height: PropTypes.oneOfType([
          PropTypes.string
      ]),
      width: PropTypes.oneOfType([
          PropTypes.string
      ])
  }
const LogButtonClass = connect(mapStateToProps, mapActionsToProps)(BaseLogButton);
