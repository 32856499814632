import React from "react";
import { StandardDropDown, StandardAutoComplete } from '../common';
import { Input } from './../common/InputBox/StandardInput';

function getTableName (processFlowList, tableName) {
  if (processFlowList.length > 0) {
    let blockFound = false;
    processFlowList.forEach(processFlow => {
      if (typeof(processFlow.process) !== 'undefined' && typeof(processFlow.process.processBlock) !== 'undefined' && processFlow.process.processBlock.length > 0 && blockFound === false) {
        let block = processFlow.process.processBlock.filter(block => block.blockTable === tableName);

        if (block.length > 0 && tableName.search(block[0].blockName) === -1) {
          tableName = block[0].blockName +" ("+tableName+")";
          blockFound = true;
        }
      }
    })
  }
  return tableName
}

function getGlobalValue(controlIndex){
  let controlValue = '';
  global.processBlockControlValues.forEach((control, index) => {
    if (control.controlId === controlIndex) {
      controlValue = global.processBlockControlValues[index].controlValue;

    }
  });
  return controlValue;
}

function saveGlobalValue(controlIndex, value){
  global.processBlockControlValues.forEach((control, index) => {
    if (control.controlId === controlIndex) {
      global.processBlockControlValues[index].controlValue = value;
    }
  });
}

function CriteriaOpsLayoutTemplate() {
  if(this.state.inputTable.length > 1){
    //saving source table
    if(getGlobalValue(32) === null || getGlobalValue(32) === ""){
      saveGlobalValue(32,this.state.inputTable[0]);
    }
    //saving formula table
    if(getGlobalValue(33) === null || getGlobalValue(33) === ""){
      saveGlobalValue(33,this.state.inputTable[1]);
    }
  }
  if(global.updateBlock){
    let inputTable = getGlobalValue(-97).split(",");
    saveGlobalValue(32,inputTable[0]);
    saveGlobalValue(33,inputTable[1]);
  }
  let inputColumns = [];
  if(this.state.tableList){
    let inputTable = this.state.tableList.filter(item => item.id === getGlobalValue(32));
    if(inputTable.length > 0){
      if(inputTable[0]){
        inputTable[0].rows.forEach((row) => {
          inputColumns.push(row.column);
        })
      }
    }
  }
  let formulaColumns = [];
  if(this.state.tableList){
    let formulaTable = this.state.tableList.filter(item => item.id === getGlobalValue(33));
    if(formulaTable.length > 0){
      if(formulaTable[0]){
        formulaTable[0].rows.forEach((row) => {
          formulaColumns.push(row.column);
        })
      }
    }
  }
  let sourceTable = getGlobalValue(32);
  let targetTable = getGlobalValue(33);
  let disabledWhereColumn = sourceTable === targetTable ? true : false;
  let newColNameText = disabledWhereColumn ? '' : getGlobalValue(6);
  let newColNameAutoComplete = disabledWhereColumn ? '' : getGlobalValue(49);
  let mutualExclusive = disabledWhereColumn ? false : getGlobalValue(36);
  let whereColumn = disabledWhereColumn ? '' : getGlobalValue(34);
  let outputText =  disabledWhereColumn ? '' : getGlobalValue(46);
  let lengthValue = getGlobalValue(42);
  let typeValue = getGlobalValue(41);
  let applyCriteria = getGlobalValue(35);
  let criteriaMet = getGlobalValue(37);
  let criteriaNotMet = getGlobalValue(39);
  let criteriaMetResultValueText = getGlobalValue(38);
  let criteriaMetResultValueAutoComplete = getGlobalValue(47);
  let criteriaNotMetResultValueText = getGlobalValue(40);
  let criteriaNotMetResultValueAutoComplete = getGlobalValue(48);

  var handleCriteriaMetResultValueAutoCompleteChange = (event) =>{
    saveGlobalValue(47,event.value);
    criteriaMetResultValueAutoComplete = event.value;
    this.setState({...this.state.tableList});
  }

  var handleCriteriaNotMetResultValueAutoCompleteChange = (event) =>{
    saveGlobalValue(48,event.value);
    criteriaNotMetResultValueAutoComplete = event.value;
    this.setState({...this.state.tableList});
  }

  var handleCriteriaMetResultValueTextFocusChange = (event) => {
    //clear CriteriaMetResultValue auto complete
    saveGlobalValue(47,"");
    criteriaMetResultValueAutoComplete = "";
    this.setState({...this.state.tableList});
  }

  var handleCriteriaNotMetResultValueTextFocusChange = (event) => {
    //clear CriteriaNotMetResultValue auto complete
    saveGlobalValue(48,"");
    criteriaNotMetResultValueAutoComplete = "";
    this.setState({...this.state.tableList});
  }

  var handleCriteriaMetResultValueTextChange = (event) =>{
    saveGlobalValue(38,event.target.value);
    criteriaMetResultValueText = event.target.value;
    this.setState({...this.state.tableList});
  }

  var handleCriteriaNotMetResultValueTextChange = (event) =>{
    saveGlobalValue(40,event.target.value);
    criteriaNotMetResultValueText = event.target.value;
    this.setState({...this.state.tableList});
  }

  var handleCriteriaNotMetResultValueAutoCompleteFocusChange = (event) => {
    //clear CriteriaNotMetResultValue text
    saveGlobalValue(40,"");
    criteriaNotMetResultValueText = "";
    this.setState({...this.state.tableList});
  }

  var handleCriteriaMetResultValueAutoCompleteFocusChange = (event) => {
    //clear CriteriaMetResultValue text
    saveGlobalValue(38,"");
    criteriaMetResultValueText = "";
    this.setState({...this.state.tableList});
  }

  var handleCriteriaMetTypeChange = (event) =>{
    saveGlobalValue(37,event.value);
    criteriaMet = event.value;
    this.setState({...this.state.tableList});
  }

  var handleCriteriaNotMetTypeChange = (event) =>{
    saveGlobalValue(39,event.value);
    criteriaNotMet = event.value;
    this.setState({...this.state.tableList});
  }

  var handleMutualExclusiveChange = (event) =>{
    saveGlobalValue(36,event.target.checked);
    mutualExclusive = event.target.checked;
    this.setState({...this.state.tableList});
  }

  var handleLengthValueChange = (event) =>{
    saveGlobalValue(42,event.target.value);
    lengthValue = event.target.value;
    this.setState({...this.state.tableList});
  }

  var handleTypeValueChange = (event) =>{
    saveGlobalValue(41,event.value);
    typeValue = event.value;
    this.setState({...this.state.tableList});
  }

  var handleWhereColumnChange = (event) =>{
    saveGlobalValue(34,event.value);
    whereColumn = event.value;
    this.setState({...this.state.tableList});

  }

  var handleNewColNameAutoCompleteChange = (event) => {
    saveGlobalValue(49,event.value);
    newColNameAutoComplete = event.value;
    this.setState({...this.state.tableList});
  }

  var handleNewColNameTextChange = (event) => {
    saveGlobalValue(6,event.target.value);
    newColNameText = event.target.value;
    this.setState({...this.state.tableList});
  }

  // var handleSourceTableChange = (event) => {
  //   saveGlobalValue(32,event.value);
  //   sourceTable = event.value;
  //   this.setState({...this.state.tableList});
  // }

  var handleApplyCriteriaChange = (event) => {
    saveGlobalValue(35,event.value);
    applyCriteria = event.value;
    if(this.state.tableList){
      let formulaTable = this.state.tableList.filter(item => item.id === getGlobalValue(33));
      if(formulaTable.length > 0){
        if(formulaTable[0]){
          formulaColumns.length = 0;
          formulaTable[0].rows.forEach((row) => {
            formulaColumns.push(row.column);
          })
        }
      }
    }
    this.setState({...this.state.tableList});
  }

  var handleOutputTextChange = (event) => {
    saveGlobalValue(46,event.target.value);
    outputText = event.target.value;
    this.setState({...this.state.tableList});
  }

  var handleNewColumnTextFocusChange = (event) => {
    //clear new column auto complete
    saveGlobalValue(49,"");
    newColNameAutoComplete = "";
    this.setState({...this.state.tableList});
  }

  var handleNewColumnAutoCompleteFocusChange = (event) => {
    //clear new column text
    saveGlobalValue(6,"");
    newColNameAutoComplete = "";
    this.setState({...this.state.tableList});
  }
  // var handleTargetTableChange = (event) => {
  //   saveGlobalValue(33,event.value);
  //   targetTable = event.value;
  //   disabledWhereColumn = false;
  //   if( sourceTable === targetTable){
  //     disabledWhereColumn = true;
  //     saveGlobalValue(34,"");
  //     saveGlobalValue(49,"");
  //     saveGlobalValue(6,"");
  //     saveGlobalValue(46,"");
  //     saveGlobalValue(36,false);


  //   }
  //   this.setState({...this.state.tableList});

  // }
  return (
    <div className="process-option pop-process-option">
      <div className="card-block">
          <fieldset>
            <div className="field_group">
              <legend>Input Tables</legend>
              <div className="am-form-inline col-12 clearfix">
                <div className="row">
                  <div className="am-form-field col-md-6 float-left">
                    <span>Source Table:</span>
                    <span>{getTableName(this.props.processFlowList, sourceTable)}</span>
                    {//<StandardDropDown data={this.state.inputTable} onChange={handleSourceTableChange} value={sourceTable}/>
                    }
                  </div>
                  <div className="am-form-field col-md-6 float-left">
                    <span>Formula Table:</span>
                    <span>{getTableName(this.props.processFlowList, targetTable)}</span>
                    {//<StandardDropDown data={this.state.inputTable} onChange={handleTargetTableChange} value={targetTable}/>
                    }
                  </div>
                </div>
              </div>
            </div>
          </fieldset>
          <fieldset>
            <div className="field_group">
              <legend>Where</legend>
              <div className="am-form-inline clearfix col-12">
                <div className="row">
                  <div className="am-form-field col-md-6 float-left">
                    <span>Where Column:</span>
                    <StandardAutoComplete data={formulaColumns} onChange={handleWhereColumnChange}
                    disabled={disabledWhereColumn} value={whereColumn}/>
                  </div>
                </div>
              </div>
            </div>
          </fieldset>
          <fieldset>
            <div className="field_group">
              <legend>Criteria</legend>
              <div className="am-form-inline clearfix col-12">
                <div className="row">
                  <div className="am-form-field col-md-6 float-left">
                    <span>Criteria (Apply):</span>
                    <StandardAutoComplete data={formulaColumns} onChange={handleApplyCriteriaChange} value={applyCriteria}/>
                  </div>
                </div>
              </div>
              <div className="am-form-inline clearfix col-12">
                <div className="row">
                  <div className="am-form-field col-md-6 float-left">
                    <span>Criteria (Text in Output Table):</span>
                    <Input disabled={disabledWhereColumn} value={outputText}
                    onChange={handleOutputTextChange}/>
                  </div>
                  <div className="am-form-field col-md-6 float-left">
                    <input type="checkbox" id="mutually-exclusive" className="am-checkbox"
                    disabled={disabledWhereColumn} checked={mutualExclusive} onChange={handleMutualExclusiveChange}/>
                    <label className="am-checkbox-label" htmlFor="mutually-exclusive">Mutually Exclusive</label>
                  </div>
                </div>
              </div>
            </div>
          </fieldset>
          <fieldset>
            <div className="field_group_main m_b_0">
              <legend>Result</legend>
                <fieldset>
                  <div className="field_group">
                    <legend>Value</legend>
                    <div className="am-form-inline clearfix col-12">
                      <div className="row">
                        <div className="am-form-field col-md-6 float-left">
                          <span>Criteria Met Result:</span>
                          <StandardDropDown data={["Value", "Expression"]} onChange={handleCriteriaMetTypeChange} value={criteriaMet}/>
                        </div>
                        <div className="am-form-field col-md-6 float-left">
                          <span >Result Value:</span>
                          <StandardAutoComplete data={formulaColumns}
                          onFocus={handleCriteriaMetResultValueAutoCompleteFocusChange}
                          onChange={handleCriteriaMetResultValueAutoCompleteChange} value={criteriaMetResultValueAutoComplete} />
                          <Input className="small-margin"
                          onChange={handleCriteriaMetResultValueTextChange} value={criteriaMetResultValueText}
                          onFocus={handleCriteriaMetResultValueTextFocusChange}/>
                        </div>
                      </div>
                    </div>
                    <div className="am-form-inline clearfix col-12">
                      <div className="row">
                        <div className="am-form-field col-md-6 float-left">
                          <span>Criteria Not Met:</span>
                          <StandardDropDown data={["Value", "Expression"]}  value={criteriaNotMet} onChange={handleCriteriaNotMetTypeChange}/>
                        </div>
                        <div className="am-form-field col-md-6 float-left">
                          <span>Result Value:</span>
                          <StandardAutoComplete data={formulaColumns} onChange={handleCriteriaNotMetResultValueAutoCompleteChange}
                          value={criteriaNotMetResultValueAutoComplete}
                          onFocus={handleCriteriaNotMetResultValueAutoCompleteFocusChange}/>
                          <Input className="small-margin"
                          onChange={handleCriteriaNotMetResultValueTextChange} value={criteriaNotMetResultValueText}
                          onFocus={handleCriteriaNotMetResultValueTextFocusChange}/>
                        </div>
                      </div>
                    </div>
                  </div>
                </fieldset>

                    <fieldset>
                      <div className="field_group">
                        <legend>Name</legend>
                          <div className="am-form-inline clearfix col-12">
                              <div className="row">
                                <div className="am-form-field col-md-12 float-left">
                                  <span>New Column:</span>
                                  <StandardAutoComplete data={formulaColumns}  disabled={disabledWhereColumn}
                                  onChange={handleNewColNameAutoCompleteChange} value={newColNameAutoComplete}
                                  onFocus={handleNewColumnAutoCompleteFocusChange}/>
                                  <Input className="small-margin" disabled={disabledWhereColumn} value={newColNameText}
                                  onChange={handleNewColNameTextChange} onFocus={handleNewColumnTextFocusChange}/>
                                </div>
                              </div>
                          </div>
                        </div>
                    </fieldset>

                    <fieldset>
                      <div className="field_group">
                        <legend>Type And Length</legend>
                        <div className="am-form-inline col-12 clearfix">
                          <div className="row">
                            <div className="am-form-field col-md-6 float-left">
                              <span>Type:</span>
                              <StandardDropDown data={["", "NUM", "CHAR", "DATE"]}
                              onChange={handleTypeValueChange} value={typeValue} />
                            </div>
                            <div className="am-form-field col-md-6 float-left">
                              <span>Length:</span>
                              <Input onChange={handleLengthValueChange} value={lengthValue}/>
                            </div>
                          </div>
                        </div>
                        </div>
                    </fieldset>

              </div>
          </fieldset>
        </div>
    </div>
  );
};

export default CriteriaOpsLayoutTemplate;
