import React from "react";
import { GridOperation } from './GridOperation';

export class GridStatusBar extends GridOperation {
  constructor(props) {
    super(props);

    this.state={
      tableList: props.tableList
    }

    this.handleTableStateChangeEvent = this.handleTableStateChangeEvent.bind(this);
  }

  handleTableStateChangeEvent(event) {
    this.state.tableList[event.target.value].status = event.target.checked;
    this.setState({tableList: this.state.tableList});
  }

  TableStatusBar = (table, tableIndex) => {
      let checked = table.status ? 'checked': '';
      return (
        <span key={tableIndex}>
              <input
                type="checkbox"
                id={`table-status-checkbox-${table.id}`}
                className="am-checkbox"
                defaultChecked={checked}
                onChange={this.handleTableStateChangeEvent}
                value={tableIndex}
              />
              <label className="am-checkbox-label" htmlFor={`table-status-checkbox-${table.id}`}>{table.name}</label>
            </span>
      )
  };


  render() {
      return (
        <div>
          { this.state.tableList.map(this.TableStatusBar)}
        </div>
      );
    }
}
