import React from "react";
import { connect } from 'react-redux';
import { createSelector } from 'reselect';
import { PropTypes } from 'prop-types';
import axios from 'axios';
import $ from 'jquery';
import cloneDeep from 'lodash/cloneDeep';
import template from "./ConstructiveOperation.jsx";
import restClient from '../../../restClient';
import { GET_ALL_WITH_APPEND } from '../../../restClient/types';
import { SourceInputTableDropDownCell } from "../DataOperation/common";
import {DrawMapppingLine} from '../GridMapper/GridMapper.jsx';
import { setMessage } from '../../../actions/actionNotification';
import MessageTypeConst from '../../MessageTypeConst';
import DataOpsIdentifierConstants from '../../DataOpsIdentifierConstants';
import { setConstructiveOperationStatus } from '../../../actions/actionDataOperation';
import ConfirmBox from './../DataOperation/common/ConfirmBox';

var tabGridElement = [];
class ConstructiveOperation extends React.Component {

  getGlobalValue(controlIndex){
    let controlValue = '';
    global.processBlockControlValues.forEach((control, index) => {
      if (control.controlId === controlIndex) {
        controlValue = global.processBlockControlValues[index].controlValue;
      }
    });
    return controlValue;
  }

  saveGlobalValue(controlIndex, value){
    global.processBlockControlValues.forEach((control, index) => {
      if (control.controlId === controlIndex) {
        global.processBlockControlValues[index].controlValue = value;
      }
    });
  }

  constructor(props) {
      super(props);
      let rows = [];

      if (typeof(props.inputTableStructure) !== 'undefined' && typeof(props.inputTableStructure) === 'object') {
        props.inputTableStructure.forEach((inputTable, index) => {
          let newInputTable = "";
          if(props.sourceInputTableList.length > index){
            newInputTable = props.sourceInputTableList[index];
          }
            rows.push({"rowId": index, "columnId": index, "selected": true, "currentInputTable": inputTable.tableName, "newInputTable" :newInputTable, "sourceTableList": props.sourceInputTableList})
        })
      }

      props.inputTableStructure.forEach((inputTable, index) => {
        props.inputTableStructure[index] = Object.assign({selected: true, ...inputTable})
      });

      let inputTableNameMapping = [];
      rows.forEach((row)=>{
        inputTableNameMapping.push({leftGrid:row.currentInputTable,rightGrid:row.newInputTable});
      });

      this.state = {
        sourceInputTableList: props.sourceInputTableList,
        processFlowAction:props.processFlowAction,
        block: props.block,
        tenantId: props.tenantId,
        inputTableStructure: props.inputTableStructure,
        sourceTableStructure: typeof(props.sourceTableStructure) !== 'undefined' ? props.sourceTableStructure : [],
        tableList: [],
        selected: 0,
        constructiveOperation: true,
        inputTableListExtended: [],
        sourceTableListExtended: [],
        inputTableNameMapping:inputTableNameMapping,
        inputTableMaapingDialog: false,
        inputTableRichGrid:{
          id: 'input-table-mapping-gird',
          rows: rows,
          selectedField: "selected",
          columnProperty: [
            { field: "rowId", show: false },
            { field: "columnId", show: false },
            { field: "sourceTableList", show: false},
            { field: "newInputTable", cell: SourceInputTableDropDownCell }
          ],
          height: 100
        },
        applyProcessFlow: typeof(props.applyProcessFlow) !== 'undefined' ? props.applyProcessFlow: {},
        mappedTargetTableColumnList: typeof(props.mappedTargetTableColumnList) !== 'undefined' ? props.mappedTargetTableColumnList: [],
        mappedTargetTableCarryForwardColumnList: typeof(props.mappedTargetTableCarryForwardColumnList) !== 'undefined' ? props.mappedTargetTableCarryForwardColumnList: [],
        tabColumnMappedList: [],
        tableNameReplaceList: typeof(props.tableNameReplaceList) !== 'undefined' ? props.tableNameReplaceList : [],
        tabVisitedIndex: [0]
      }

      this.handleSourceInputTableGridChange = this.handleSourceInputTableGridChange.bind(this);
      this.handleInputTableMappingClick = this.handleInputTableMappingClick.bind(this);
      this.handleSaveInputTableMappingClick = this.handleSaveInputTableMappingClick.bind(this);
      this.findTableListForTableGrid = this.findTableListForTableGrid.bind(this);
      this.handleInputStructureUpdateClick = this.handleInputStructureUpdateClick.bind(this);
      this.storeColumnMapping = this.storeColumnMapping.bind(this);
      this.isInputAndSourceTableSame = this.isInputAndSourceTableSame.bind(this);
      this.storeUnMappedColumnInMapping = this.storeUnMappedColumnInMapping.bind(this);
      this.action = this.props.action;
  }

  fetchDataViewer = (headers, params) => restClient(GET_ALL_WITH_APPEND, 'dataviewer', params, headers);

  storeUnMappedColumnInMapping(mapUnmappedCol){
    this.state.inputTableListExtended.forEach((inputTable)=>{
      let nameMapping = this.state.inputTableNameMapping.filter(map => map.leftGrid === inputTable.id);
      if(nameMapping.length >0){
        let gridUniqueKey = nameMapping[0].leftGrid+"-"+nameMapping[0].rightGrid;
        let globalMapping = global.constructiveOperationGridMapping.filter(mapping=> mapping.gridUniqueKey === gridUniqueKey);
        let sourceInputTables = this.state.sourceTableListExtended.filter(table=>table.id === nameMapping[0].rightGrid);

        if(globalMapping.length > 0){
          inputTable.rows.forEach((row)=>{
            if(row.status === 0 && mapUnmappedCol && globalMapping[0].leftMappedColumn.indexOf(row.column) === -1 && row.column.indexOf('_app_') === -1 && row.column.indexOf('APP_') === -1){
              globalMapping[0].leftMappedColumn.push(row.column);
              globalMapping[0].rightMappedColumn.push("");
            }
            if(sourceInputTables.length > 0){
              let sourceRow = sourceInputTables[0].rows.filter(sourceRow => sourceRow.column.toUpperCase() === row.column.toUpperCase()  && row.column.indexOf('_app_') === -1 && row.column.indexOf('APP_') === -1);
              if(sourceRow.length >0 && row.status === 1 && row.column !== sourceRow[0].column){
                globalMapping[0].leftMappedColumn.push(row.column);
                globalMapping[0].rightMappedColumn.push(sourceRow[0].column);
              }
            }
          });
          global.constructiveOperationGridMapping = global.constructiveOperationGridMapping.filter(mapping=> mapping.gridUniqueKey !== gridUniqueKey);
          global.constructiveOperationGridMapping.push(globalMapping[0]);
        }else{
          //mapping not exists so add one
          let leftMappedColumn = [];
          let rightMappedColumn = [];
          let gridMapping = {gridUniqueKey: gridUniqueKey, leftMappedColumn: leftMappedColumn, rightMappedColumn: rightMappedColumn};
          inputTable.rows.forEach((row)=>{
            if(row.status === 0 && mapUnmappedCol && gridMapping.leftMappedColumn.indexOf(row.column) === -1 && mapUnmappedCol && row.column.indexOf('_app_') === -1 && row.column.indexOf('APP_') === -1){
              gridMapping.leftMappedColumn.push(row.column);
              gridMapping.rightMappedColumn.push("");
            }
            if(sourceInputTables.length > 0){
              let sourceRow = sourceInputTables[0].rows.filter(sourceRow => sourceRow.column.toUpperCase() === row.column.toUpperCase()  && row.column.indexOf('_app_') === -1 && row.column.indexOf('APP_') === -1);
              if(sourceRow.length >0 && row.status === 1 && row.column !== sourceRow[0].column){
                gridMapping.leftMappedColumn.push(row.column);
                gridMapping.rightMappedColumn.push(sourceRow[0].column);
              }
            }
          });
          global.constructiveOperationGridMapping = global.constructiveOperationGridMapping.filter(mapping=> mapping.gridUniqueKey !== gridUniqueKey);
          global.constructiveOperationGridMapping.push(gridMapping);
        }
      }
    });
  }

  handleInputStructureUpdateClick = (mapUnmappedCol = true) => {
    this.storeColumnMapping();
    let columnMappingRules = [];

    if (this.state.processFlowAction === 'applyProcessFlow' && typeof(mapUnmappedCol) === 'object') {
      let { tableList, nodeList } = DrawMapppingLine.getState();

      if (typeof(tableList[0]) !== 'undefined') {
        tableList[0].rows.filter(row => row.column !== null && row.column.indexOf('_app_') === -1 && row.column.indexOf('APP_') === -1).forEach(row => {
          let columnMappingDetail = {
            columnMappingType : null,
            columnName : null,
            columnType : null,
            columnLabel : null,
            columnLength : null,
            columnId: null,
            mappedColumnName : null,
            mappedColumnType : null,
            mappedColumnLength : null,
            mappedColumnLabel : null,
            mappedColumnId : null
          }
          let targetColumn = row.column;

          if (row.status === 0) {
            let leftcolumnMappedIndex = global.constructiveOperationGridMapping[0].leftMappedColumn.indexOf(row.column);
            columnMappingDetail.columnMappingType = leftcolumnMappedIndex === -1 ? 3 : 2

            if (columnMappingDetail.columnMappingType === 2 && typeof(global.constructiveOperationGridMapping[0].rightMappedColumn) !== 'undefined' && typeof(global.constructiveOperationGridMapping[0].rightMappedColumn[leftcolumnMappedIndex]) !== 'undefined') {
              targetColumn = global.constructiveOperationGridMapping[0].rightMappedColumn[leftcolumnMappedIndex];
            }
          } else if (row.status === 1) {
            columnMappingDetail.columnMappingType = 4;
          }

          if (columnMappingDetail.columnMappingType > 1) {
            columnMappingDetail.columnName = row.column;
            columnMappingDetail.columnType = row.type;
            columnMappingDetail.columnLabel = row.label;
            columnMappingDetail.columnLength = row.length;
            columnMappingDetail.columnId = row.columnId;
          }

          if (columnMappingDetail.columnMappingType !== 3 && typeof(tableList[1]) !== 'undefined') {
            let targetColumnDetail = tableList[1].rows.filter(targetRow => targetRow.column !== null && targetRow.column.toLowerCase() === targetColumn.toLowerCase());

            if (targetColumnDetail.length > 0) {
              targetColumnDetail = targetColumnDetail[0];

              columnMappingDetail.mappedColumnName = targetColumnDetail.column;
              columnMappingDetail.mappedColumnType = targetColumnDetail.type;
              columnMappingDetail.mappedColumnLabel = targetColumnDetail.label;
              columnMappingDetail.mappedColumnLength = targetColumnDetail.length;
              columnMappingDetail.mappedColumnId = targetColumnDetail.columnId;
            }
          }

          columnMappingRules.push(columnMappingDetail);

        })
      }

      if (typeof(tableList[1]) !== 'undefined') {
        tableList[1].rows.filter(row => row.column !== null && row.column.indexOf('_app_') === -1 && row.column.indexOf('APP_') === -1).forEach(row => {
          let targetColumn = columnMappingRules.filter(targetColumn => targetColumn.mappedColumnName === row.column);

          if (targetColumn.length === 0) {

            columnMappingRules.push({
              columnMappingType : 1,
              columnName : null,
              columnType : null,
              columnLabel : null,
              columnLength : null,
              columnId: null,
              mappedColumnName : row.column,
              mappedColumnType : row.type,
              mappedColumnLength : row.length,
              mappedColumnLabel : row.label,
              mappedColumnId : row.columnId
            });
          }
        })
      }

      if (typeof(this.state.applyProcessFlow.processFlowList) !== 'undefined') {
        this.state.applyProcessFlow.processFlowList[0].columnMappingRules.length = 0;
        this.state.applyProcessFlow.processFlowList[0].columnMappingRules.push(...columnMappingRules)
      }

      if (typeof(this.state.applyProcessFlow.columnMapperNodeList) !== 'undefined') {
        this.state.applyProcessFlow.columnMapperNodeList = nodeList;
      }

      delete global.constructiveOperationGridMapping;
      this.props.setConstructiveOperationStatus(false);
      this.action(this.state.applyProcessFlow)

      return true;
    }

    if (this.state.processFlowAction === 'annotate' && typeof(mapUnmappedCol) === 'object') {
      if (this.state.inputTableStructure.length === 1 || (this.state.inputTableStructure.length > 1 && this.state.tabVisitedIndex.length === this.state.inputTableStructure.length)) {
        let tabColumnMappedList = this.state.tabColumnMappedList;
        let { tableList, nodeList } = DrawMapppingLine.getState();
        let type =  tableList[0].id === 'Source Table' ? "source" : "target"
        let replaceColumnList = this.getReplaceColumnMappedList(tableList, nodeList, type)

        delete global.constructiveOperationGridMapping;
        this.props.setConstructiveOperationStatus(false);
        tabColumnMappedList = tabColumnMappedList.filter(tabColumnMapped => tabColumnMapped.type !== type);
        tabColumnMappedList.push(...replaceColumnList)
        this.action(tabColumnMappedList, this.state.tableNameReplaceList)
      } else {
        this.props.setMessage("Please verify both new version source & target tabs.",MessageTypeConst.WARNING_MESSAGE);
      }

      return true;
    }

    this.storeUnMappedColumnInMapping(mapUnmappedCol);

    let leftTableString = this.getGlobalValue(3);
    let rightTableString = this.getGlobalValue(24);
    try{
      JSON.parse(rightTableString);
    }catch(err){
      rightTableString = "";
    }
    let tables = this.getGlobalValue(-97).split(",");
    let leftTable = "";
    let rightTable = "";
    let leftTableName = "";
    let rightTableName = "";
    let newMappedTables = [];
    if(tables.length > 0){
      leftTableName = tables[0];
    }
    if(tables.length > 1){
      rightTableName = tables[1];
    }else{
      rightTableName = tables[0];
    }
    if(leftTableString !== ""){
      leftTable = JSON.parse(leftTableString);
    }
    if(rightTableString !== ""){
      rightTable = JSON.parse(rightTableString);
    }
    let newRightRows = [];
    let newLeftRows = [];
    let leftMappedTable = "";
    let rightMappedTable = "";
    let mappedTables = this.state.inputTableRichGrid.rows.filter(a=>a.currentInputTable === leftTableName);
    if(mappedTables.length > 0){
      leftMappedTable = mappedTables[0].newInputTable;
      let sourceLeftTable = this.state.sourceTableListExtended.filter(table=>table.id === leftMappedTable);
      if(sourceLeftTable.length > 0){
        newLeftRows = sourceLeftTable[0].rows.map((row)=>{
          delete row.usage;
          delete row.status;
          return row;
        });
        let selectedMapping = global.constructiveOperationGridMapping.filter(mapping=>mapping.gridUniqueKey === mappedTables[0].currentInputTable+"-"+mappedTables[0].newInputTable);
        if(selectedMapping.length > 0){
          let mapping = selectedMapping[0];
          newLeftRows = this.getNewTransformTable(sourceLeftTable[0], leftTable, mapping);
          this.replaceLeftTableControlValues(mapping.leftMappedColumn, mapping.rightMappedColumn);
          if(rightTableString === ""){
            this.replaceRightTableControlValues(mapping.leftMappedColumn, mapping.rightMappedColumn);
            // 53 for variable
            this.replaceVariableMappingRulesForRighttTable(mapping.leftMappedColumn, mapping.rightMappedColumn);
          }
          this.replaceLeftDataWhichToBeTreatedAsTable(sourceLeftTable[0], mapping);
          // 26 - Left mapped column
          this.replaceTableMappedColumn(26, mapping.leftMappedColumn, mapping.rightMappedColumn);
          // 28 Left mapped column length - need to add ask not for Proc Report
          this.replaceTableMappedColumnLength(28, sourceLeftTable[0], 26);
          // 30 Left mapped column Type - need to add ask not for Proc Report
          this.replaceTableMappedColumnType(30 , sourceLeftTable[0], 26);
          // 53 for variable
          this.replaceVariableMappingRulesForLeftTable(mapping.leftMappedColumn, mapping.rightMappedColumn);
          // 23 for DataOsp
          this.replaceLeftDataWhichToBeTreatedAsTableForDataOps(sourceLeftTable[0]);
          this.mapPreWhereCondition(leftTableName,sourceLeftTable[0].id,mapping.leftMappedColumn, mapping.rightMappedColumn, 0);
        }
      }
    }
    mappedTables = this.state.inputTableRichGrid.rows.filter(a=>a.currentInputTable === leftTableName);
    if(rightTableString !== ""){
      mappedTables = this.state.inputTableRichGrid.rows.filter(a=>a.currentInputTable === rightTableName);
      if(mappedTables.length > 0){
        rightMappedTable = mappedTables[0].newInputTable;
        let sourceRightTable = this.state.sourceTableListExtended.filter(table=>table.id === rightMappedTable);
        if(sourceRightTable.length > 0){
          newRightRows = sourceRightTable[0].rows.map((row)=>{
            delete row.usage;
            delete row.status;
            return row;
          });

        let selectedMapping = global.constructiveOperationGridMapping.filter(mapping=>mapping.gridUniqueKey === mappedTables[0].currentInputTable+"-"+mappedTables[0].newInputTable);
        if(selectedMapping.length > 0){
          let mapping = selectedMapping[0];
          newRightRows = this.getNewTransformTable(sourceRightTable[0], rightTable, mapping);
          this.replaceRightTableControlValues(mapping.leftMappedColumn, mapping.rightMappedColumn);
          // 27 - Right mapped column
          this.replaceTableMappedColumn(27, mapping.leftMappedColumn, mapping.rightMappedColumn);
          // 29 Right mapped column length - need to add ask not for Proc Report
          this.replaceTableMappedColumnLength(29, sourceRightTable[0], 27);
          // 31 Right mapped column Type - need to add ask not for Proc Report
          this.replaceTableMappedColumnType(31 , sourceRightTable[0], 27);
          // 53 for variable
          this.replaceVariableMappingRulesForRighttTable(mapping.leftMappedColumn, mapping.rightMappedColumn);
          this.mapPreWhereCondition(rightTableName,sourceRightTable[0].id,mapping.leftMappedColumn, mapping.rightMappedColumn, 1);
        }
        }
      }
    }
    tables.forEach((table)=>{
      let mappedTables = this.state.inputTableRichGrid.rows.filter(a=>a.currentInputTable === table);
      if(mappedTables.length > 0){
        newMappedTables.push(mappedTables[0].newInputTable);
      }
    });
    this.saveGlobalValue(-97,newMappedTables.join(","))
    if(newLeftRows.length > 0){
      this.saveGlobalValue(3, JSON.stringify(newLeftRows));
    }else{
      this.saveGlobalValue(3, leftTableString);
    }

    if(newRightRows.length > 0){
      this.saveGlobalValue(24, JSON.stringify(newRightRows));
    }else{
      if(rightTableString !== ""){
        this.saveGlobalValue(24, rightTableString);
      }
    }
    this.updatePivotMetrix();
    this.balanceTableColumnMappedList();
    this.updateInputStructure(this.state.sourceTableListExtended);
    this.action();
  }

  getReplaceColumnMappedList = (tableList, nodeList, type) => {
    let replaceColumnList = [];
    tableList[0].rows.filter(row => row.status !== 1).forEach((row) => {
      let replaceColumn = {
        oldTableName : row.tableName,
        oldColumn: row.column,
        oldColumnDetail: {
          label: row.label,
          length: row.length,
          type: row.type
        },
        newTableName: '',
        newColumn: '',
        newColumnDetail: '',
        type: type,

      };

      let node = nodeList.filter(node => node.node.source === row.columnId);

      if (node.length > 0 && typeof(tableList[1]) !== 'undefined') {
        let targetNode = tableList[1].rows.filter(targetRow => targetRow.columnId === node[0].node.target && targetRow.status !== 1);

        if (targetNode.length > 0) {
          replaceColumn.newTableName = targetNode[0].tableName;
          replaceColumn.newColumn = targetNode[0].column
          replaceColumn.newColumnDetail = {
            label: targetNode[0].label,
            length: targetNode[0].length,
            type: targetNode[0].type
          }
        }
      }

      replaceColumnList.push(replaceColumn)
    });

    return replaceColumnList;
  }

  mapPreWhereCondition(inputTable, mappedSourceTable, leftColumnMapping, rightColumnMapping, preWhereIndex ){
    //For PreWhere
    this.mapWhereCondition(-99,inputTable, mappedSourceTable, leftColumnMapping, rightColumnMapping, preWhereIndex );
    //For Data Where
    this.mapWhereCondition(-96,inputTable, mappedSourceTable, leftColumnMapping, rightColumnMapping, preWhereIndex );
  }

  mapWhereCondition(index, inputTable, mappedSourceTable, leftMappedColumn, rightMappedColumn, preWhereIndex ){
    let preWhere = this.getGlobalValue(index);
    if(preWhere !== null && preWhere !== ""){
      let preWhereCondition = JSON.parse(preWhere);
      if(preWhereCondition.length > preWhereIndex){
        let objIndex = preWhereIndex;
        if(objIndex > -1){
          preWhereCondition[objIndex].tableName = mappedSourceTable;
          try{
            let count = leftMappedColumn.length;
            for(var i = 0; i< count; i++){
              if(preWhereCondition[objIndex].preWhereCondition.toLowerCase().indexOf(leftMappedColumn[i].toLowerCase()) !== -1 && rightMappedColumn[i] === ""){
                preWhereCondition[objIndex].preWhereCondition="";
              }else{
                if (rightMappedColumn.indexOf(leftMappedColumn[i]) === -1) {
                  preWhereCondition[objIndex].preWhereCondition=preWhereCondition[objIndex].preWhereCondition.replace(new RegExp("\\b"+leftMappedColumn[i]+"\\b",'ig'),rightMappedColumn[i]);
                }
              }
            }
          }catch(err){

          }
        }
        this.saveGlobalValue(index,JSON.stringify(preWhereCondition));
      }
    }
  }

  getIdsWhereWeNeedToUpdateOtherDetails(){
    return [14, 18, 19]; //85, 86, 88
  }

  replaceLeftDataWhichToBeTreatedAsTableForDataOps(sourceInputTable){

    let ids = [23];
    ids.forEach((id)=>{
      global.processBlockControlValues.forEach((control, index)=>{
        if (control.controlId === id) {
          let oldData = control.controlValue;
          if(oldData !== ""){
            try{
              let oldRows= JSON.parse(oldData);
              if(Array.isArray(oldRows)){
                //variable
                //variableLike
                oldRows.forEach((row, index)=>{
                  let variable = row.variable;
                  let selectedRow = sourceInputTable.rows.filter(item=>item.column.toUpperCase() === row.variable.split("-")[0].toUpperCase());
                  if(selectedRow.length > 0){
                    variable = selectedRow[0].column+"-"+selectedRow[0].type+" ("+selectedRow[0].length+")";
                  }
                  oldRows[index].variable = variable;
                })
                global.processBlockControlValues[index].controlValue = JSON.stringify(oldRows);
              }

            }catch(err){

            }
          }
        }
      })
    })
  }

  replaceLeftDataWhichToBeTreatedAsTable(sourceInputTable, mapping){
    let ids = this.getIdsWhereWeNeedToUpdateOtherDetails();
    let actionName = this.getGlobalValue(-1);
    ids.forEach((id)=>{
      global.processBlockControlValues.forEach((control, index)=>{
        if (control.controlId === id) {
          let oldData = control.controlValue;
          if(oldData !== ""){
            try{
              let oldRows= JSON.parse(oldData);
              let newRows = [];
              if(Array.isArray(oldRows)){
                if (id === 14 && (actionName === DataOpsIdentifierConstants.PIVOT_RTC_OP || actionName === DataOpsIdentifierConstants.PIVOT_CTR_OP)) {
                  newRows.push(...oldRows.filter(oldRow => oldRow.column !== ''))

                  if (newRows.length === 0) {
                    newRows = [{"selected": false,"gby":false, "column": "", "label": "", "type": "", "length": "" }]
                  }
                } else {
                  oldRows.forEach((row)=>{
                    let selectedRow = sourceInputTable.rows.filter(item=>item.column.toUpperCase() === row.column.toUpperCase());
                    if(selectedRow.length > 0){
                      let newRow = Object.assign({},row,{column:selectedRow[0].column,label:selectedRow[0].label,type:selectedRow[0].type,columnId:selectedRow[0].columnId,length:selectedRow[0].length});
                      newRows.push(newRow);
                    }
                  })

                  sourceInputTable.rows.filter(item=> newRows.filter(newRow => newRow.column.toUpperCase() === item.column.toUpperCase()).length === 0).forEach(item => {
                    let newItem = Object.assign({},cloneDeep(newRows[0]),{column:item.column, label:item.label, type:item.type, columnId:newRows.length, length:item.length});
                    newRows.push(newItem);
                  })

                  newRows.sort((a,b)=> {return a.column.localeCompare(b.column)});

                  newRows.map((newRow, index) => {
                    newRow.columnId = index;
                    return newRow
                  })
                }

                global.processBlockControlValues[index].controlValue = JSON.stringify(newRows);
              }

            }catch(err){

            }
          }
        }
      })
    })
  }

  getRightMappedControlValue(){
    //control id whihc contains data from right table
    let rightMapsArray = [27, 35, 43, 44, 34, 47, 48, 49];
    // 27 Right mapped column
    // 35,34, 47, 48, 49 Criteria / Right click column in variable mapping
    // 43 Right table start date in Decollapse and Link
    // 44 Right table stop date in Decollapse and Link
    return rightMapsArray;
  }

  replaceLeftTableControlValues(leftMappedColumn, rightMappedColumn){
    let rightMapsArray = this.getRightMappedControlValue();
    rightMapsArray.push(...[3, 24, 53]);
    let count = leftMappedColumn.length;
    for(var i = 0; i< count; i++){
      global.processBlockControlValues.forEach((control, index)=>{
        if (rightMapsArray.indexOf(control.controlId) === -1 && rightMappedColumn.indexOf(leftMappedColumn[i]) === -1) {
          global.processBlockControlValues[index].controlValue = control.controlValue.replace(new RegExp("\\b"+leftMappedColumn[i]+"\\b",'ig'),rightMappedColumn[i]);
        }
      })
    }
  }
  replaceRightTableControlValues(leftMappedColumn, rightMappedColumn){
    let rightMapsArray = this.getRightMappedControlValue();
    let count = leftMappedColumn.length;
    for(var i = 0; i< count; i++){
      global.processBlockControlValues.forEach((control, index)=>{
        if (rightMapsArray.indexOf(control.controlId) !== -1 && rightMappedColumn.indexOf(leftMappedColumn[i]) === -1) {
          global.processBlockControlValues[index].controlValue = control.controlValue.replace(new RegExp("\\b"+leftMappedColumn[i]+"\\b",'ig'),rightMappedColumn[i]);
        }
      })
    }
  }

  updateInputStructure(sourceInputTables){
    let inputTableStructure = [];
    sourceInputTables.forEach((table)=>{
      let inputObject = [];
      table.rows.forEach((row, index) => {
        inputObject.push({
          "colName" : typeof(row.column) !== 'undefined' ? row.column : "",
          "colType" : typeof(row.type) !== 'undefined' ? row.type : "",
          "colGNType" : typeof(row.type) !== 'undefined' ? row.type : "",
          "colLength" : typeof(row.length) !== 'undefined' ? row.length : "",
          "colLabel" : typeof(row.label) !== 'undefined' ? row.label : "",
        });
      });
      var structure = {
        "tableName": table.id,
        "tableColumns":inputObject
      }
      inputTableStructure = [...inputTableStructure, structure];
    })
    global.inputTableStructure = inputTableStructure;
  }

  replaceTableMappedColumnLength(controlId, sourceTableRows, columnMappedId){
    let actionName = this.getGlobalValue(-1);
    if(actionName === DataOpsIdentifierConstants.R_Report_OP || actionName === DataOpsIdentifierConstants.PROCREPORT_ANALYSIS_CHAR_OP || actionName === DataOpsIdentifierConstants.PROCREPORT_ANALYSIS_NUM_OP){
      return;
    }
    let lengthStr = this.getGlobalValue(controlId);
    if(typeof(lengthStr) !== "undefined" && lengthStr!== null && lengthStr !== ""){
      let lengthArr = JSON.parse(lengthStr);
      let columnStr = this.getGlobalValue(columnMappedId);
      if(columnStr !== ""){
        let columnArray = JSON.parse(columnStr);
        if(Array.isArray(columnArray)){
          for(var i = 0; i< columnArray.length; i++){
            let selectedRow = sourceTableRows.rows.filter( row=> row.column.toUpperCase() === columnArray[i].toUpperCase());
            if(selectedRow.length > 0){
              lengthArr[i] = selectedRow[0].length;
            }
          }
          this.saveGlobalValue( controlId, JSON.stringify(lengthArr))
        }
      }
    }
  }

  replaceTableMappedColumn(controlId, leftMappedColumn, rightMappedColumn){
    try{
      let count = leftMappedColumn.length;
      let columnStr = this.getGlobalValue(controlId);
      if(typeof(columnStr) !== "undefined" && columnStr!== null && columnStr !== ""){
        let columnArr = JSON.parse(columnStr);
        if(Array.isArray(columnArr)){
          for(var i = 0; i< count; i++){
            columnArr.forEach((col, index)=>{
              if (rightMappedColumn.indexOf(leftMappedColumn[i]) === -1) {
                columnArr[index]=columnArr[index].replace(new RegExp("\\b"+leftMappedColumn[i]+"\\b",'ig'),rightMappedColumn[i]);
              }
            });
          }
          this.saveGlobalValue( controlId, JSON.stringify(columnArr))
        }
      }
    }catch(err){

    }
  }

  isJsonString(str) {
    try {
        JSON.parse(str);
    } catch (e) {
        return false;
    }
    return true;
  }

  balanceTableColumnMappedList() {
    let leftTableRowList = this.getGlobalValue(3);
    let rightTableRowList = this.getGlobalValue(24);
    let leftTableColumnList = this.getGlobalValue(26);
    let rightTableColumnList = this.getGlobalValue(27);
    let leftTableColumnLengthList = this.getGlobalValue(28);
    let rightTableColumnLengthList = this.getGlobalValue(29);
    let leftTableColumnTypeList = this.getGlobalValue(30);
    let rightTableColumnTypeList = this.getGlobalValue(31);
    let isValidBalanceTableList = false;

    if(typeof(leftTableRowList) !== "undefined" && leftTableRowList !== null && leftTableRowList !== "" && this.isJsonString(leftTableRowList)){
      leftTableRowList = JSON.parse(leftTableRowList);
    }

    if(typeof(rightTableRowList) !== "undefined" && rightTableRowList !== null && rightTableRowList !== "" && this.isJsonString(rightTableRowList)){
      rightTableRowList = JSON.parse(rightTableRowList);
    }

    if(typeof(leftTableColumnList) !== "undefined" && leftTableColumnList !== null && leftTableColumnList !== "" && this.isJsonString(leftTableColumnList)){
      leftTableColumnList = JSON.parse(leftTableColumnList);
    }

    if(typeof(rightTableColumnList) !== "undefined" && rightTableColumnList !== null && rightTableColumnList !== "" && this.isJsonString(rightTableColumnList)){
      rightTableColumnList = JSON.parse(rightTableColumnList);
    }

    if(typeof(leftTableColumnLengthList) !== "undefined" && leftTableColumnLengthList !== null && leftTableColumnLengthList !== "" && this.isJsonString(leftTableColumnLengthList)){
      leftTableColumnLengthList = JSON.parse(leftTableColumnLengthList);
    }

    if(typeof(rightTableColumnLengthList) !== "undefined" && rightTableColumnLengthList !== null && rightTableColumnLengthList !== "" && this.isJsonString(rightTableColumnLengthList)){
      rightTableColumnLengthList = JSON.parse(rightTableColumnLengthList);
    }

    if(typeof(leftTableColumnTypeList) !== "undefined" && leftTableColumnTypeList !== null && leftTableColumnTypeList !== "" && this.isJsonString(leftTableColumnTypeList)){
      leftTableColumnTypeList = JSON.parse(leftTableColumnTypeList);
    }

    if(typeof(rightTableColumnTypeList) !== "undefined" && rightTableColumnTypeList !== null && rightTableColumnTypeList !== "" && this.isJsonString(rightTableColumnTypeList)){
      rightTableColumnTypeList = JSON.parse(rightTableColumnTypeList);
    }

    if (typeof(leftTableColumnList) === 'object') {
      isValidBalanceTableList = true;
      leftTableColumnList.forEach((leftTableColumn, index) => {
        if (leftTableColumn === '') {
          const columnName = rightTableColumnList[index];

          if (typeof(columnName) !== 'undefined' && columnName !== '') {
            if (typeof(rightTableRowList) === 'object' && rightTableColumnList.filter(rightTableColumn => rightTableColumn === columnName).length === 1) {
              rightTableRowList.filter(rightTableRow => rightTableRow.column.toLowerCase() === columnName.toLowerCase()).map(rightTableRow => {
                rightTableRow.selected = false

                if (typeof(rightTableRow.gby) !== 'undefined') {
                  rightTableRow.gby = false
                }

                if (typeof(rightTableRow.oby) !== 'undefined') {
                  rightTableRow.oby = ''
                }
                return rightTableRow
              })
            }

            if (typeof(rightTableColumnList) === 'object') {
                rightTableColumnList[index] = '';
            }

            if (typeof(leftTableColumnLengthList) === 'object') {
                leftTableColumnLengthList.splice(index, 1)
            }

            if (typeof(rightTableColumnLengthList) === 'object') {
                rightTableColumnLengthList.splice(index, 1)
            }

            if (typeof(leftTableColumnTypeList) === 'object') {
                leftTableColumnTypeList.splice(index, 1)
            }

            if (typeof(rightTableColumnTypeList) === 'object') {
                rightTableColumnTypeList.splice(index, 1)
            }
          }
        }
      })
    }

    if (typeof(rightTableColumnList) === 'object') {
      isValidBalanceTableList = true;

      rightTableColumnList.forEach((rightTableColumn, index) => {
        if (rightTableColumn === '') {
          const columnName = leftTableColumnList[index];
          if (typeof(columnName) !== 'undefined' && columnName !== '') {
            if (typeof(leftTableRowList) === 'object' && leftTableColumnList.filter(leftTableColumn => leftTableColumn === columnName).length === 1) {
              leftTableRowList.filter(leftTableRow => leftTableRow.column.toLowerCase() === columnName.toLowerCase()).map(leftTableRow => {
                leftTableRow.selected = false;

                if (typeof(leftTableRow.gby) !== 'undefined') {
                  leftTableRow.gby = false
                }

                if (typeof(leftTableRow.oby) !== 'undefined') {
                  leftTableRow.oby = ''
                }
                return leftTableRow
              })
            }

            if (typeof(leftTableColumnList) === 'object') {
              leftTableColumnList[index] = '';
            }

            if (typeof(leftTableColumnLengthList) === 'object') {
                leftTableColumnLengthList.splice(index, 1)
            }

            if (typeof(rightTableColumnLengthList) === 'object') {
                rightTableColumnLengthList.splice(index, 1)
            }

            if (typeof(leftTableColumnTypeList) === 'object') {
                leftTableColumnTypeList.splice(index, 1)
            }

            if (typeof(rightTableColumnTypeList) === 'object') {
                rightTableColumnTypeList.splice(index, 1)
            }
          }
        }
      })
    }

    if (typeof(leftTableColumnList) === 'object' && typeof(leftTableColumnLengthList) === 'object' && typeof(leftTableColumnTypeList) === 'object' && typeof(rightTableColumnList) === 'object' && typeof(rightTableColumnLengthList) === 'object' && typeof(rightTableColumnTypeList) === 'object') {
      let removeVariableIndex = 0;

      while(removeVariableIndex > -1) {
        if (typeof(leftTableColumnList[removeVariableIndex]) !== 'undefined' && typeof(leftTableColumnLengthList[removeVariableIndex]) !== 'undefined' && typeof(leftTableColumnTypeList[removeVariableIndex]) !== 'undefined' && typeof(rightTableColumnList[removeVariableIndex]) !== 'undefined' && typeof(rightTableColumnLengthList[removeVariableIndex]) !== 'undefined' && typeof(rightTableColumnTypeList[removeVariableIndex]) !== 'undefined' && leftTableColumnList[removeVariableIndex] === '' && rightTableColumnList[removeVariableIndex] === '') {
          leftTableColumnList.splice(removeVariableIndex, 1)
          leftTableColumnLengthList.splice(removeVariableIndex, 1)
          leftTableColumnTypeList.splice(removeVariableIndex, 1)
          rightTableColumnList.splice(removeVariableIndex, 1)
          rightTableColumnLengthList.splice(removeVariableIndex, 1)
          rightTableColumnTypeList.splice(removeVariableIndex, 1)
          removeVariableIndex--;
        }

        removeVariableIndex++;
        if (typeof(leftTableColumnList[removeVariableIndex]) === 'undefined') {
          removeVariableIndex = -1;
        }
      }
    }

    if (isValidBalanceTableList === true) {
      leftTableColumnList = leftTableColumnList.filter(leftTableColumn => leftTableColumn !== '')
      rightTableColumnList = rightTableColumnList.filter(rightTableColumn => rightTableColumn !== '')

      this.saveGlobalValue(3, JSON.stringify(leftTableRowList));
      this.saveGlobalValue(24, JSON.stringify(rightTableRowList));
      this.saveGlobalValue(26, JSON.stringify(leftTableColumnList))
      this.saveGlobalValue(27, JSON.stringify(rightTableColumnList))
      this.saveGlobalValue(28, JSON.stringify(leftTableColumnLengthList))
      this.saveGlobalValue(29, JSON.stringify(rightTableColumnLengthList))
      this.saveGlobalValue(30, JSON.stringify(leftTableColumnTypeList))
      this.saveGlobalValue(31, JSON.stringify(rightTableColumnTypeList))
    }
  }

  replaceTableMappedColumnType(controlId, sourceTableRows, columnMappedId){
    let actionName = this.getGlobalValue(-1);
    if(actionName === DataOpsIdentifierConstants.R_Report_OP || actionName === DataOpsIdentifierConstants.PROCREPORT_ANALYSIS_CHAR_OP || actionName === DataOpsIdentifierConstants.PROCREPORT_ANALYSIS_NUM_OP){
      return;
    }
    let typeStr = this.getGlobalValue(controlId);
    if(typeof(typeStr) !== "undefined" && typeStr!== null && typeStr !== ""){
      let typeArr = JSON.parse(typeStr);
      let columnStr = this.getGlobalValue(columnMappedId);
      if(columnStr !== ""){
        let columnArray = JSON.parse(columnStr);
        if(Array.isArray(columnArray)){
          for(var i = 0; i< columnArray.length; i++){
            let selectedRow = sourceTableRows.rows.filter( row=> row.column.toUpperCase() === columnArray[i].toUpperCase());
            if(selectedRow.length > 0){
              typeArr[i] = selectedRow[0].type;
            }
          }
          this.saveGlobalValue( controlId, JSON.stringify(typeArr))
        }
      }
    }
  }

  replaceVariableMappingRulesForLeftTable(leftMappedColumn, rightMappedColumn){
    let actionName = this.getGlobalValue(-1);
    if(actionName !== "VariableMapping"){
      return;
    }
    let mapping = this.getGlobalValue(53);
    if(mapping !== ""){
      let maapingRules = JSON.parse(mapping);
      if(Array.isArray(maapingRules)){
        maapingRules.map((rule)=>{
          let length = leftMappedColumn.length;
          for(var i=0;i<length;i++){
            if (rightMappedColumn.indexOf(leftMappedColumn[i]) === -1) {
              rule.sourceColumn = rule.sourceColumn.replace(new RegExp("\\b"+leftMappedColumn[i]+"\\b",'ig'),rightMappedColumn[i]);
              rule.expression = rule.expression.replace(new RegExp("\\b"+leftMappedColumn[i]+"\\b",'ig'),rightMappedColumn[i]);
            }
          }
          return rule;
        })
        this.saveGlobalValue( 53 , JSON.stringify(maapingRules))
      }
    }

  }

  replaceVariableMappingRulesForRighttTable(leftMappedColumn, rightMappedColumn){
    let actionName = this.getGlobalValue(-1);
    if(actionName !== "VariableMapping"){
      return;
    }
    let mapping = this.getGlobalValue(53);
    if(mapping !== ""){
      let maapingRules = JSON.parse(mapping);
      if(Array.isArray(maapingRules)){
        maapingRules.map((rule)=>{
          let length = leftMappedColumn.length;
          for(var i=0;i<length;i++){
            if (rightMappedColumn.indexOf(leftMappedColumn[i]) === -1) {
              rule.targetColumn = rule.targetColumn.replace(new RegExp("\\b"+leftMappedColumn[i]+"\\b",'ig'),rightMappedColumn[i]);
            }
          }
          return rule;
        })
        this.saveGlobalValue( 53 , JSON.stringify(maapingRules))
      }
    }
  }

  getNewTransformTable(sourceTable, inputTable, mapping) {

    if(!Array.isArray(inputTable)){
      return sourceTable.rows.map((row)=>{
        delete row.usage;
        delete row.status;
        return row;
      });
    }
    let newRows= [];
    let newProps = this.getObjectExtraPropertyAccordingToOperation(inputTable[0]);
    sourceTable.rows.forEach((row, index) => {
      //if column found in input table, replace row property with new source table row
      let rowExists = inputTable.filter(data => data.column.toUpperCase() === row.column.toUpperCase());
      if (rowExists.length > 0) {
        let newR = {};
        if (this.isColumnMapped(row.column, mapping.rightMappedColumn)) {
          let index = mapping.rightMappedColumn.indexOf(row.column);
          let leftMappedCol = mapping.leftMappedColumn[index];
          let mappedRow = inputTable.filter(data => data.column.toUpperCase() === leftMappedCol.toUpperCase());
          if (mappedRow.length > 0) {
            newR = Object.assign({}, mappedRow[0], {column:row.column, columnId:row.columnId, label:row.label, type:row.type,length:row.length });
          }  else {
            newR = Object.assign({}, rowExists[0], {column:row.column, columnId:row.columnId, label:row.label, type:row.type,length:row.length });
          }
        } else {
          newR = Object.assign({}, rowExists[0], {column:row.column, columnId:row.columnId, label:row.label, type:row.type,length:row.length });
        }
        delete newR.usage;
        delete newR.status;
        newRows.push(newR);
      }
      else if (this.isColumnMapped(row.column, mapping.rightMappedColumn)) {
        //if input table column is mapped,
        //then fetch mapped input row
        // add mapped input row and new source row
        let index = mapping.rightMappedColumn.indexOf(row.column);
        let leftMappedCol = mapping.leftMappedColumn[index];
        let mappedRow = inputTable.filter(data => data.column.toUpperCase() === leftMappedCol.toUpperCase());
        if (mappedRow.length > 0) {
          let newR = Object.assign({}, mappedRow[0], {columnId:row.columnId, column:row.column, label:row.label, type:row.type,length:row.length});
          delete newR.usage;
          delete newR.status;
          newRows.push(newR);
        }
      }
      else {
        //if column not exists in input table,
        //then add new row from source and additionally add extra properties accordingly to other input row
        let newR = Object.assign({}, row, newProps);
          delete newR.usage;
          delete newR.status;
          newRows.push(newR);
      }
    });
    return newRows;
  }

  isColumnMapped(column, rightMappedColumn){
    let col = rightMappedColumn.filter(a=>a === column);
    return col.length > 0;
  }

  getObjectExtraPropertyAccordingToOperation(inputRow){
    var newProps = {};
    for(var prop in inputRow){
      if(prop !== "columnId" && prop !== "column" && prop !== "label" && prop !== "type" && prop !== "length"){
        newProps[prop] = "";
      }
    }
    return newProps;
  }

  componentDidMount() {
    let inputTableListExtended = this.state.inputTableListExtended;
    let inputTableListDetail = [];
    let inputTableList = []

    global.processBlockControlValues.forEach((control, index) => {
      if (control.controlId === -97 && control.controlValue !== '') {
        inputTableList.push(...control.controlValue.split(','))
      }
    });

    global.processBlockControlValues.forEach((control, index) => {
      if (control.controlId === 3 && control.controlValue !== '' && typeof(inputTableList[0]) !== 'undefined') {
        inputTableListDetail.push({ tableName: inputTableList[0], records: JSON.parse(control.controlValue)})
      }

      if (control.controlId === 24 && control.controlValue !== '' && typeof(inputTableList[1]) !== 'undefined') {
        inputTableListDetail.push({ tableName: inputTableList[1], records: JSON.parse(control.controlValue)})
      }
    });

    this.state.inputTableStructure.forEach((inputTable) => {
      inputTableListExtended = [...inputTableListExtended, this.inputTableGrid(inputTable, inputTableListDetail)];
    })

    if (this.state.sourceInputTableList.length > 0) {
      $(".loader").show();
      let sourceInputTableExtendedList= this.state.sourceInputTableList.map( sourceInputTable => sourceInputTable = sourceInputTable+'_extended' );

      let that = this;
      let tenantId = this.state.tenantId;
      //tenantId = 'schema_1_50';
      axios.all([this.fetchDataViewer({'Tenant-ID': tenantId}, {tableNames: sourceInputTableExtendedList})])
            .then(axios.spread((dataViewer) => {
              if (typeof(dataViewer.data) !== 'undefined') {
                let sourceTableListExtended = this.state.sourceTableListExtended;
                let tableViewerData = dataViewer.data;
                if (this.state.processFlowAction === 'applyProcessFlow') {
                    tableViewerData.map(tableExtendedData => {
                      let virtualTargetTable = that.props.virtualTargetTableList.filter(virtualTargetTable => virtualTargetTable.tableName === tableExtendedData.tableName);

                      if (virtualTargetTable.length > 0) {
                        tableExtendedData.blockName = virtualTargetTable[0].blockName
                        tableExtendedData.count = virtualTargetTable[0].count
                        tableExtendedData.records = virtualTargetTable[0].records
                      }
                      return tableExtendedData
                    })

                    tableViewerData.map(table => {
                      this.state.mappedTargetTableCarryForwardColumnList.forEach(mappedTargetTableCarryForwardColumn => {
                        if (table.records.filter(record => record.column_header === mappedTargetTableCarryForwardColumn.columnTitle.source).length === 0) {
                          table.records.push(mappedTargetTableCarryForwardColumn.column.source);
                          table.count++;
                        }
                      })
                      return table;
                    })
                }

                tableViewerData.forEach((sourceTableExtended) => {
                  sourceTableListExtended = [...sourceTableListExtended, this.sourceInputTableGrid(sourceTableExtended.tableName.replace('_extended', ''), sourceTableExtended.records)];
                });

                let applyProcessFlow = this.state.applyProcessFlow
                let removeColumnMapperNodeList = []
                if (this.state.processFlowAction === 'applyProcessFlow' && typeof(applyProcessFlow) !== 'undefined' && typeof(applyProcessFlow.columnMapperNodeList) !== 'undefined' && applyProcessFlow.columnMapperNodeList.length > 0 && typeof(applyProcessFlow.processFlowList) !== 'undefined' && applyProcessFlow.processFlowList.length > 0) {
                  applyProcessFlow.columnMapperNodeList.map(columnMapperNode => {
                    applyProcessFlow.processFlowList[0].columnMappingRules.filter(columnMappingRule => columnMappingRule.columnMappingType === 2 && columnMappingRule.columnId === columnMapperNode.node.source && columnMappingRule.mappedColumnId === columnMapperNode.node.target).map(columnMappingRule => {

                      if (inputTableListExtended.length > 0) {
                        inputTableListExtended[0].rows.filter(row => row.column === columnMappingRule.columnName).forEach(row => {
                          columnMapperNode.node.source = row.columnId;
                          columnMappingRule.columnId = row.columnId;

                          if (sourceTableListExtended.length > 0 && typeof(sourceTableListExtended[0].rows) !== 'undefined' && sourceTableListExtended[0].rows.filter(row => row.column === columnMappingRule.columnName).length > 0) {
                            columnMappingRule.columnMappingType = 3;
                            columnMappingRule.mappedColumnId = null;
                            columnMappingRule.mappedColumnLabel = null;
                            columnMappingRule.mappedColumnLength = null;
                            columnMappingRule.mappedColumnName = null;
                            columnMappingRule.mappedColumnType = null;
                            removeColumnMapperNodeList.push(columnMapperNode.node)
                          }
                        })
                      }
                      if (sourceTableListExtended.length > 0) {
                        sourceTableListExtended[0].rows.filter(row => row.column === columnMappingRule.mappedColumnName).forEach(row => {
                          columnMapperNode.node.target = row.columnId;
                          columnMappingRule.mappedColumnId = row.columnId;

                          if (inputTableListExtended.length > 0 && typeof(inputTableListExtended[0].rows) !== 'undefined' && inputTableListExtended[0].rows.filter(row => row.column === columnMappingRule.mappedColumnName).length > 0) {
                            columnMappingRule.columnMappingType = 1;
                            columnMappingRule.columnId = null;
                            columnMappingRule.columnLabel = null;
                            columnMappingRule.columnLength = null;
                            columnMappingRule.columnName   = null;
                            columnMappingRule.columnType = null;
                            removeColumnMapperNodeList.push(columnMapperNode.node)
                          }
                        })
                      }

                      return columnMappingRule
                    })
                    return columnMapperNode
                  })

                  if (removeColumnMapperNodeList.length > 0) {
                    applyProcessFlow.columnMapperNodeList = applyProcessFlow.columnMapperNodeList.filter(columnMapperNode => removeColumnMapperNodeList.filter(removeColumnMapperNode => removeColumnMapperNode.source === columnMapperNode.node.source && removeColumnMapperNode.target === columnMapperNode.node.target).length === 0 )
                  }
                }
                that.setState({ inputTableListExtended: inputTableListExtended, sourceTableListExtended: sourceTableListExtended, applyProcessFlow: applyProcessFlow }, () => {
                  if(that.isInputAndSourceTableSame(tableViewerData) && this.state.processFlowAction !== 'applyProcessFlow'){
                    this.handleInputStructureUpdateClick(false);
                  }
                });
                $(".loader").hide();
              }
            })
      ).catch(error=>{
        $(".loader").hide();
        that.props.setMessage("Error occurred while fetching data.",MessageTypeConst.ERROR_MESSAGE);
      });
    } else if (this.state.processFlowAction === 'annotate') {
      let sourceTableListExtended = [];
      this.state.sourceTableStructure.forEach((sourceTable) => {
        sourceTableListExtended = [...sourceTableListExtended, this.sourceInputTableGrid(sourceTable.tableName, sourceTable.tableColumns)];
      })
      this.setState({ inputTableListExtended: inputTableListExtended, sourceTableListExtended: sourceTableListExtended })
    }


  }


  isInputAndSourceTableSame(sourceTable){
      let colMatches = true;
      let that = this;
      let inputstructure = that.state.inputTableStructure;
      for(let i =0 ;i<inputstructure.length;i++ ){
        inputstructure[i].tableColumns.forEach((row)=>{
          if(row.colName !== null && row.colName.indexOf('_app_') === -1 && row.colName.indexOf('APP_') === -1 && typeof(sourceTable[i]) !== 'undefined')
          {
            let selectedCol = sourceTable[i].records.filter(sourceRow => sourceRow.column_header !== null && sourceRow.column_header.toLowerCase() === row.colName.toLowerCase() && sourceRow.column_data_type.toLowerCase() === row.colType.toLowerCase());
            if(selectedCol.length === 0)
            {
              colMatches = false;
              return colMatches;
            }
          }
        })
      }

      if (sourceTable.filter(table => table.message === 'Table not found').length > 0) {
        colMatches = true
      }
      return colMatches;
  }

  sourceInputTableGrid(table, data) {
    var initialProcessOptionGrid = [];
    data.forEach((row, index) => {
      initialProcessOptionGrid.push({
        "columnId" : index,
        "selected" : true,
        "tableTitle" : typeof(row.tableTitle) !== 'undefined' ? row.tableTitle : "",
        "tableName" : typeof(row.tableName) !== 'undefined' ? row.tableName : "",
        "column" : typeof(row.column_header) !== 'undefined' ? row.column_header : "",
        "label" : typeof(row.column_label) !== 'undefined' ? row.column_label : "",
        "type" : typeof(row.column_data_type) !== 'undefined' ? row.column_data_type : "",
        "length" : typeof(row.column_data_length) !== 'undefined' ? parseInt(row.column_data_length, 10) : "",
        "usage" : null,
        "status" : 0
      });
    });

    return {
      id: table,
      rows: initialProcessOptionGrid,
      //rows: initialProcessOptionGrid.filter((row) => row.column.indexOf('_app_') === -1  && row.column.indexOf('APP_') === -1),
      columnProperty: [{field: "selected", show: false}],
      gridClassName: "String_Ops"
    }
  }

  inputTableGrid(data, inputTableListDetail){
    let inputObject = [];
    let inputTableDetail = inputTableListDetail.filter( inputTable => inputTable.tableName === data.tableName );

    data.tableColumns.forEach((row, index) => {
      let usage = '';
      let column = typeof(row.colName) !== 'undefined' ? row.colName : "";

      if (column !== '' && inputTableDetail.length > 0) {
        let columnDetail = inputTableDetail[0].records.filter( row1 => row1.column.toLowerCase() === column.toLowerCase() );

        if (columnDetail.length > 0) {

          if (typeof(columnDetail[0].selected) !== 'undefined' && columnDetail[0].selected === true) {
            usage = usage + 'D';
          }

          if (typeof(columnDetail[0].gby) !== 'undefined' && columnDetail[0].gby === true) {
            usage = usage + (usage !== '' ? '/' : '') +'G';
          }

          if (typeof(columnDetail[0].oby) !== 'undefined' && columnDetail[0].oby !== '') {
            usage = usage + (usage !== '' ? '/' : '') +'O';
          }
        }
      }

      if (usage === '') {
        usage = '-';
      }

      inputObject.push({
        "columnId" : index,
        "selected" : true,
        "tableTitle" : typeof(row.tableTitle) !== 'undefined' ? row.tableTitle : "",
        "tableName" : typeof(row.tableName) !== 'undefined' ? row.tableName : "",
        "column" : column,
        "label" : typeof(row.colLabel) !== 'undefined' ? row.colLabel : "",
        "type" : typeof(row.colType) !== 'undefined' ? row.colType : "",
        "length" : typeof(row.colLength) !== 'undefined' ? parseInt(row.colLength, 10) : "",
        "usage" : usage,
        "status" : 0
      });
    })

    //inputObject = inputObject.filter((row) => row.column.indexOf('_app_') === -1 && row.column.indexOf('APP_') === -1);

    return {
      id: data.tableName,
      rows: inputObject,
      columnProperty: [{field: "selected", show: false}],
      gridClassName: "String_Ops"
    }
  }

  handleSelect = (e) => {
    let tabColumnMappedList = this.state.tabColumnMappedList;
    let tabVisitedIndex = this.state.tabVisitedIndex;

    if (this.state.processFlowAction === "annotate") {
      let { tableList, nodeList } = DrawMapppingLine.getState();
      let type = tableList[0].id === 'Source Table' ? "source" : "target";
      let replaceColumnList = this.getReplaceColumnMappedList(tableList, nodeList, type)
      tabColumnMappedList = tabColumnMappedList.filter(tabColumnMapped => tabColumnMapped.type !== type)
      tabColumnMappedList.push(...replaceColumnList)
    }

    tabGridElement = tabGridElement.filter( gridElement => gridElement.tabIndex !== this.state.selected );

    tabGridElement.push({ tabIndex:  this.state.selected, gridList: global.gridElement });
    let gridElement = tabGridElement.filter( gridElement => gridElement.tabIndex === e.selected );

    if (gridElement.length > 0) {
      global.gridElement = gridElement[0].gridList;
    }

    global.tabGridIndex = e.selected;

    if (tabVisitedIndex.indexOf(e.selected) === -1) {
      tabVisitedIndex.push(e.selected);
    }
    this.storeColumnMapping();
    this.setState({ selected: e.selected, tabColumnMappedList: tabColumnMappedList, tabVisitedIndex: tabVisitedIndex });
  }

  storeColumnMapping(){
    //to save column mapping of input tables in global variable
    let { nodeList, tableList } = DrawMapppingLine.getState();
    let gridUniqueKey = '';

    if (tableList.length > 0 && typeof(tableList[0].id) !== 'undefined') {
      gridUniqueKey = tableList[0].id;
    }

    if (tableList.length > 1 && typeof(tableList[1].id) !== 'undefined') {
      gridUniqueKey = gridUniqueKey +"-"+ tableList[1].id;
    }

    if (gridUniqueKey !== '') {
      let leftMappedColumn = [];
      let rightMappedColumn = [];
      let gridMapping = {gridUniqueKey: gridUniqueKey, leftMappedColumn: leftMappedColumn, rightMappedColumn: rightMappedColumn};

      nodeList.forEach((node) => {
        if (typeof(node.node) !== 'undefined') {
          if (tableList[0].rows.filter(row => row.columnId === node.node.source).length > 0) {
            leftMappedColumn.push(...tableList[0].rows.filter(row => row.columnId === node.node.source).map(a => a.column));
          }
          if (tableList[1].rows.filter(row => row.columnId === node.node.target).length > 0) {
            rightMappedColumn.push(...tableList[1].rows.filter(row => row.columnId === node.node.target).map(a => a.column));
          }
        }
      });

      global.constructiveOperationGridMapping = global.constructiveOperationGridMapping.filter( gridMapping => gridMapping.gridUniqueKey !== gridUniqueKey );
      global.constructiveOperationGridMapping.push(gridMapping);
    }
  }

  gridComparison = (tableList) => {
    if (typeof(tableList[0]) !== 'undefined' && typeof(tableList[0].rows) !== 'undefined' && typeof(tableList[1]) !== 'undefined' && typeof(tableList[1].rows) !== 'undefined') {
      tableList[0].rows.filter(row => row.column !== null).map((row) => {
        let columnExists =  tableList[1].rows.filter( sourceRow => sourceRow.column !== null && sourceRow.column.toLowerCase() === row.column.toLowerCase() && sourceRow.type.toLowerCase() === row.type.toLowerCase() );

        row.status = columnExists.length > 0 ? 1 : 0;

        return row;
      })

      tableList[1].rows.filter(row => row.column !== null).map((row) => {
        let columnExists =  tableList[0].rows.filter( inputRow => inputRow.column !== null && inputRow.column.toLowerCase() === row.column.toLowerCase() && inputRow.type.toLowerCase() === row.type.toLowerCase());

        row.status = columnExists.length > 0 ? 1 : 0;

        return row;
      })

      if (this.state.processFlowAction === 'applyProcessFlow') {
        let mappedTargetColumnList = tableList[1].rows.filter(row => row.status === 1 || this.state.mappedTargetTableColumnList.indexOf(row.column) > -1)

        tableList[1].rows.length = 0;
        tableList[1].rows.push(...mappedTargetColumnList)
      }
    }

    return tableList;
  }

  handleSourceInputTableGridChange = (event) => {
    let gridMapping = this.state.inputTableNameMapping.filter((table) => table.leftGrid === event.inputTableName && table.rightGrid === event.value );

    if (gridMapping.length === 0) {
      let inputTableNameMapping = this.state.inputTableNameMapping.filter((table) => table.leftGrid !== event.inputTableName );

      inputTableNameMapping.push({leftGrid: event.inputTableName, rightGrid: event.value });

      this.setState({ inputTableNameMapping: inputTableNameMapping});
    }
  }

  handleInputTableMappingClick = (event) => {
    this.setState({ inputTableMaapingDialog: !this.state.inputTableMaapingDialog });
  }

  handleSaveInputTableMappingClick = (event) => {
    ConfirmBox.open("Are you sure you want to change input table mapping?").then((data) => {
      let inputTableRichGrid = this.state.inputTableRichGrid;
      inputTableRichGrid.rows = global.gridData;

      let inputTableStructure = this.state.inputTableStructure;
      let inputTableNameMapping = [];
      inputTableRichGrid.rows.forEach((row)=>{
        inputTableNameMapping.push({leftGrid:row.currentInputTable,rightGrid:row.newInputTable});
      });
      inputTableStructure.map((inputTable) => {
        let inputTableGrid = inputTableRichGrid.rows.filter(inputTableGrid => inputTableGrid.currentInputTable === inputTable.tableName);

        if (inputTableGrid.length > 0) {
          inputTable.selected = inputTableGrid[0].selected
        }
        return inputTable;
      });


      this.setState({ inputTableRichGrid: inputTableRichGrid, inputTableMaapingDialog: !this.state.inputTableMaapingDialog,
        inputTableStructure: inputTableStructure, inputTableNameMapping: inputTableNameMapping});
    })
  }

  findTableListForTableGrid = (inputTable, index) => {
    let tableList = [];
    let leftGrid = this.state.inputTableListExtended.filter( table => table.id === inputTable.tableName );
    let rightGrid = null;

    if (leftGrid.length > 0) {
      tableList = [leftGrid[0]]
    }

    if (this.state.sourceTableListExtended.length === 1) {
      tableList = [...tableList, this.state.sourceTableListExtended[0]];
    } else {
      let inputTableNameMapping = this.state.inputTableNameMapping.filter((table) => table.leftGrid === inputTable.tableName );

      if (inputTableNameMapping.length > 0) {
        let sourceTableNameExtened = this.state.sourceTableListExtended.filter((table) => table.id === inputTableNameMapping[0].rightGrid );

        if (sourceTableNameExtened.length > 0) {
          rightGrid = sourceTableNameExtened[0];
        }
      }

      if (rightGrid === null) {
        if (inputTable.tableName !== 'Target Table') {
          this.state.sourceTableListExtended.forEach((sourceInputTableGrid) =>{
            let id = sourceInputTableGrid.id.split('_').slice(0, -1).join('_');

            if (inputTable.tableName.split('_').slice(0, -1).join('_') === id && rightGrid === null) {
                rightGrid = sourceInputTableGrid;
                return rightGrid;
            }
          });
        }

        if (rightGrid === null &&  typeof(this.state.sourceTableListExtended[index]) !== 'undefined') {
          rightGrid = this.state.sourceTableListExtended[index];
        }
      }

      if (rightGrid !== null) {
        tableList = [...tableList, rightGrid];

        this.state.inputTableRichGrid.rows.forEach((row) => {
          if (row.currentInputTable === tableList[0].id) {
              if (row.newInputTable === '') {
                  row.newInputTable = tableList[1].id
              } else {
                let sourceTableNameExtened = this.state.sourceTableListExtended.filter((table) => table.id === row.newInputTable );

                if (sourceTableNameExtened.length > 0) {
                  tableList[1] = sourceTableNameExtened[0];
                }
              }
          }
        })
      }
    }

    if (tableList.length > 1) {
      tableList = this.gridComparison(tableList);
      tableList.map((table)=>{
        table.rows = table.rows.sort(function(a, b){
          if(a.column < b.column) { return -1; }
          if(a.column > b.column) { return 1; }
          return 0;});
          table.rows = table.rows.sort((a, b) => a.status - b.status);
        return table;
      })
    }

    if (this.state.processFlowAction === 'applyProcessFlow' && typeof(this.state.applyProcessFlow) !== 'undefined' && typeof(this.state.applyProcessFlow.id) !== 'undefined') {
      tableList.map(table => {
        if (table.id === this.state.applyProcessFlow.tableName) {
          table.displayTableName = this.state.applyProcessFlow.displayTableName
        } else if (table.id !== this.state.applyProcessFlow.tableName) {
          this.state.applyProcessFlow.processFlowList.filter(processFlow => processFlow.sourceBlockTable === table.id).forEach(processFlow => {
            table.displayTableName = processFlow.sourceBlockName + " (v"+table.id.split('_v')[1]+")";
          })
        }
        return table
      })
    }

    return tableList;
  }

  updatePivotMetrix() {
    let totalPivotOperation = this.getGlobalValue(92);
    let pivotRows = this.getGlobalValue(91);
    let dataOperationType = this.getGlobalValue(-1);

    if (totalPivotOperation !== null && pivotRows !== null && totalPivotOperation !== '' && pivotRows !== '' && dataOperationType !== 'ProcReport(AnalysisChar)') {
      let pivotMatrix = this.removeVerticalBlankGroup({maximumGroupLength: parseInt(totalPivotOperation), rows: typeof(JSON.parse(pivotRows)) === 'object' ? JSON.parse(pivotRows) : []})
      this.saveGlobalValue(92, pivotMatrix.maximumGroupLength);
      this.saveGlobalValue(91, JSON.stringify(pivotMatrix.rows));
    }
  }

  removeVerticalBlankGroup = (pivotMatrix) => {
    const pivotMatrixMaximumGroupLength = pivotMatrix.maximumGroupLength;
    let verticalGroupList = [];
    for(var i = 1; i <=pivotMatrixMaximumGroupLength; i++) {
      let totalBlankGroupCount = 0;
      let groupTitle = "group"+i;
      pivotMatrix.rows.map(row => {
        if (typeof(row[groupTitle]) === 'undefined') {
          row[groupTitle] = '""';
          if (row.blankCell.indexOf(groupTitle) === -1) {
            row.blankCell.push(groupTitle)
          }

          if (typeof(row['selectedSourceColumnList']) !== 'undefined') {
            row['selectedSourceColumnList'] = row['selectedSourceColumnList'].filter(selectedSourceColumn => selectedSourceColumn.groupTitle !== groupTitle)
          }
        }

        if (row[groupTitle] === '' && row.blankCell.indexOf(groupTitle) === -1) {
          row[groupTitle] = '""';
          row.blankCell.push(groupTitle);
          if (typeof(row['selectedSourceColumnList']) !== 'undefined') {
            row['selectedSourceColumnList'] = row['selectedSourceColumnList'].filter(selectedSourceColumn => selectedSourceColumn.groupTitle !== groupTitle)
          }
        }

        if (typeof(row['selectedSourceColumnList']) !== 'undefined') {
          row['selectedSourceColumnList'].filter(selectedSourceColumn => selectedSourceColumn.column === '').forEach(selectedSourceColumn => {
            if (row.blankCell.indexOf(selectedSourceColumn.groupTitle) === -1) {
              row[selectedSourceColumn.groupTitle] = '""';
              row.blankCell.push(selectedSourceColumn.groupTitle);
            }
          })
          row['selectedSourceColumnList'] = row['selectedSourceColumnList'].filter(selectedSourceColumn => selectedSourceColumn.column !== '')
        }

        let pivotSourceColumn = this.findGroupSourceColumn(row, groupTitle);
        const groupSourceColumn = pivotSourceColumn !== '' ? pivotSourceColumn : row[groupTitle];

        if (row.blankCell.indexOf(groupTitle) > -1 && groupSourceColumn === '""') {
          totalBlankGroupCount++
        }
        return row
      })

      if (pivotMatrix.rows.length === totalBlankGroupCount) {
        verticalGroupList.push(groupTitle)
      }
    }

    let maxAvailableGroupList = []
    verticalGroupList.forEach(verticalGroup => {
      pivotMatrix.rows.map(row => {
        row.blankCell = row.blankCell.filter(group => group !== verticalGroup);
        if (typeof(row['selectedSourceColumnList']) !== 'undefined') {
          row['selectedSourceColumnList'] = row['selectedSourceColumnList'].filter(selectedSourceColumn => selectedSourceColumn.groupTitle !== verticalGroup)
        }
        delete row[verticalGroup];

        for (var key in row) {
          if (verticalGroupList.indexOf(key) === -1 && key.indexOf('group') > -1 && maxAvailableGroupList.indexOf(key) === -1) {
            maxAvailableGroupList.push(key);
          }
        }
        return row
      })
      pivotMatrix.maximumGroupLength--;
    })

    maxAvailableGroupList.sort((a, b) => parseInt(a.replace('group', '')) - parseInt(b.replace('group', '')));
    pivotMatrix.rows.map(row => {
      maxAvailableGroupList.forEach((maxAvailableGroup, groupIndex) => {
        const groupTitle = 'group'+(groupIndex+1);
        if (pivotMatrix.maximumGroupLength >= (groupIndex+1)){
          row[groupTitle] = row[maxAvailableGroup];
          if (row.blankCell.filter(group => group === maxAvailableGroup).length > 0) {
            row.blankCell = row.blankCell.filter(group => group !== maxAvailableGroup);
            row.blankCell.push(groupTitle);

            if (typeof(row['selectedSourceColumnList']) !== 'undefined') {
              row['selectedSourceColumnList'] = row['selectedSourceColumnList'].filter(selectedSourceColumn => selectedSourceColumn.groupTitle !== maxAvailableGroup)
              row['selectedSourceColumnList'] = row['selectedSourceColumnList'].filter(selectedSourceColumn => selectedSourceColumn.groupTitle !== groupTitle)
            }

          } else {
            if (typeof(row['selectedSourceColumnList']) !== 'undefined' && groupTitle !== maxAvailableGroup) {
              const selectedSourceColumn = row['selectedSourceColumnList'].filter(selectedSourceColumn => selectedSourceColumn.groupTitle === maxAvailableGroup);

              if (selectedSourceColumn.length > 0) {
                row['selectedSourceColumnList'].push({groupTitle: groupTitle, column: selectedSourceColumn[0].column})
                row['selectedSourceColumnList'] = row['selectedSourceColumnList'].filter(selectedSourceColumn => selectedSourceColumn.groupTitle !== maxAvailableGroup);
              }
            }
          }
        }
      })
      return row
    })

    for(var i = 1; i <= pivotMatrix.maximumGroupLength; i++) {
      pivotMatrix.rows.map(row => {
        for(var key in row) {
          if (key.indexOf("group") > -1 && parseInt(key.replace('group', '')) > pivotMatrix.maximumGroupLength) {
              delete row[key];
          }
        }
        return row
      })
    }
    return pivotMatrix
  }

  findGroupSourceColumn = (row, key) => {
    let pivotSourceColumn = '';
    if (typeof(row['selectedSourceColumnList']) !== 'undefined') {
      row['selectedSourceColumnList'].filter(selectedSourceColumn => selectedSourceColumn.groupTitle === key).forEach(selectedSourceColumn => {
        pivotSourceColumn = selectedSourceColumn.column;
      })
    }

    return pivotSourceColumn;
  }

  render() {
    return template.call(this);
  }
}


const mapStateToProps = createSelector(
  state => state.processFlow.tenantId,
  state => state.annotate.virtualTargetTableList,
  state => state.processFlow.processFlowList,
  (tenantId, virtualTargetTableList, processFlowList) => ({
    tenantId,
    virtualTargetTableList,
    processFlowList
  })
);

const mapActionsToProps = {
  setMessage: setMessage,
    setConstructiveOperationStatus: setConstructiveOperationStatus,
}

ConstructiveOperation.propTypes = {
  tenantId: PropTypes.string
}

export default connect(mapStateToProps, mapActionsToProps)(ConstructiveOperation);
