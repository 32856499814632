import React from 'react';
import { PropTypes } from "prop-types";
import Dialog from 'react-dialog';
import { Button } from './StandardButton';
import axios from 'axios';
import $ from 'jquery';
import { connect } from 'react-redux';
import { createSelector } from 'reselect';
import { POST_PARAM_BODY } from '../../../../../restClient/types';
import restClient from '../../../../../restClient';
import MessageTypeConst from "../../../../MessageTypeConst";
import { setMessage } from '../../../../../actions/actionNotification';
import { setProcessFlowTable, setProcessFlowTableId } from '../../../../../actions/actionProcessFlow';
import PropertiesGrid  from './../../../DataProperties/PropertiesGrid';

export const RelatedBlocks = ({ tableManager, value, data, column, colIndex, rowIndex }) => {
    if (typeof(tableManager) === 'undefined') {
      return (null)
    } else {
      return (
          <div className='width-full rgt-cell-inner' style={{overflow: 'hidden'}}>
              <RelatedBlocksClass dataItem={data} field={column.field} value={typeof(data[column.field]) !== 'undefined' ? data[column.field]: value} tableManager={tableManager}/>
          </div>
      )
    }
}
class BaseRelatedBlocksClass extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            visible: false
        };
        this.openDialog = this.openDialog.bind(this);
        this.closeDialog = this.closeDialog.bind(this);
    }

    openDialog(e){
        e.preventDefault();
        e.stopPropagation();
        $(".loader").show();
        axios.all([restClient(POST_PARAM_BODY, 'processblock/relatedblocks', { data: this.props.dataItem.processBlock }, {'Tenant-ID': this.props.tenantId})])
               .then(axios.spread((response) => {
                 let rows=[];
                 if (typeof(response.data) !== 'undefined' && Array.isArray(response.data)) {
                    response.data.forEach((row) => {
                      if(row.relation === "Parent"){
                        rows.push({selected:false,studyName:row.sourceStudyName,relation:row.relation,blockCreationType:row.sourceBlockCreationName,studyId:row.sourceStudyId,processFlowId:row.sourceProcessId,processBlockId:row.sourceBlockId,flowName:row.sourceProcessFlowName,groupName:row.sourceProcessFlowGroupName,blockName:row.sourceBlockName,details:'',schemaName:row.sourceSchemaName});
                      }else{
                        rows.push({selected:false,studyName:row.targetStudyName,relation:row.relation,blockCreationType:row.targetBlockCreationName,studyId:row.targetStudyId,processFlowId:row.targetProcessId,processBlockId:row.targetBlockId,flowName:row.targetProcessFlowName,groupName:row.targetProcessFlowGroupName,blockName:row.targetBlockName,details:'',schemaName:row.targetSchemaName});
                      }
                    });
                  }
                  this.setState({rowData:rows, visible: true});
                  $(".loader").hide();
               })).catch(error => {
                 $(".loader").hide();
                 this.props.setMessage("Failed to fetch parent/child blocks for'"+ this.props.dataItem.blockName +"'.", MessageTypeConst.ERROR_MESSAGE)
              });
    }

    closeDialog() {
      this.setState({visible: false});
    }

    render() {
        return (
            <>
              <div className="link-button">
                <Button onClick={this.openDialog}>{this.props.dataItem[this.props.field]}</Button>
              </div>
              {this.state.visible &&
                <div className="data-operation code-dialog related-blocks-dialog">
                  <Dialog title={`Related Blocks - ${this.props.dataItem.blockName}`} width="calc(100% - 200px)" height="calc(100vh - 180px)" onClose={this.closeDialog}>
                    <div className="align-left m-t-10">
                      <PropertiesGrid blockName={this.props.dataItem.blockName} data={this.state.rowData}/>
                    </div>
                  </Dialog>
                </div>
              }
            </>
        );
    }
}

const mapStateToProps = createSelector(
  state => state.processFlow.tenantId,
  (tenantId) => ({
    tenantId
  })
);

const mapActionsToProps = {
  setMessage: setMessage,
  setProcessFlowTable: setProcessFlowTable,
  setProcessFlowTableId: setProcessFlowTableId,
}

const RelatedBlocksClass = connect(mapStateToProps, mapActionsToProps)(BaseRelatedBlocksClass);
