import React    from "react";
import {PathLine} from 'react-svg-pathline';
import $ from 'jquery';

const getDimensions = (element) => {
  if (element !== '' && typeof(element) !== 'undefined') {
    const { width, height, x, y, top, bottom, left, right } = element.getBoundingClientRect();
    return { width, height, x, y, top, bottom, left, right };
  } else {
    return false;
  }
};

const wrapperOffsetElement = () => {
  if (document.getElementsByClassName('svg-line-mapper')[0].querySelectorAll('svg').length > 0) {
      return getDimensions(document.getElementsByClassName('svg-line-mapper')[0].querySelectorAll('svg')[0]);
  }
}

const drawLineElementLimit = (element) => {
  if ($(element).parents('.drawline_limit').length > 0) {
      return getDimensions($(element).parents('.drawline_limit')[0]);
  }
}

const getElement = (node, type=null) => {
  let element = null;
  if (type !== null && typeof(node.node[type]) !== 'undefined') {
    let elementId = node.node[type];
    element = document.getElementById(elementId);

    if (element !== null && typeof(element) !== 'undefined' && typeof(element.className) !== 'undefined' && element.className.indexOf(' hidden') > 0) {
        element = null
    }

    if (element === null) {
      let tableId = '';
      let column = node.column[type];
      if (node.mappingType.indexOf('value') > -1) {
        elementId = elementId.split('-');
        elementId.length = elementId.length - 2;
        elementId = elementId.join('-');

        element = document.getElementById(elementId);

        if (element === null) {
          let tempElementId = elementId.replace(node.table[type]+'-', '');

          tempElementId = tempElementId.split('-');
          tempElementId.length = 2;
          tableId = node.table[type]+'-'+tempElementId.join('-');
        }
      } else {
        if (typeof(column.column_header) === 'undefined' && typeof(node.parentColumn[type].column_header) !== 'undefined') {
          column = node.parentColumn[type];

          elementId = elementId.split('-');
          elementId.length = elementId.length - 2;
          elementId = elementId.join('-');

          element = document.getElementById(elementId);
        }

        if(element === null && typeof(column.column_header) !== 'undefined') {
          tableId = elementId.substring(0, elementId.indexOf('-'+column.column_header));
        }
      }

      if (tableId !== '') {
        element = document.getElementById(tableId);
      }

      if(element === null) {

        let rootId = '';

        if (typeof(column.column_header) !== 'undefined') {
          rootId = elementId.substring(elementId.indexOf(column.column_header)+column.column_header.length + 1);
        } else {
          rootId = elementId.substring(elementId.indexOf(node.table[type])+node.table[type].length + 1);
        }

        if (rootId !== '') {
          let spliteRootId = rootId.split('-');

          if (spliteRootId.length > 1) {
            rootId = spliteRootId[spliteRootId.length - 2];
            element = document.getElementById(rootId);
          }
        }
      }
    }
  }

  return element;
}
class DrawColumnMappingLine extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      style: {
        width: '100%',
        height: '100%'
      },
      line: {
        startX:0,
        startY: 0,
        stopX: 0,
        stopY: 0
      },
      strokeColor:'white',
      node: {
        source: null,
        target: null
      },
      column: {
        source: null,
        target: null
      },
      table: {
        source: null,
        target: null
      },
      columnValue: {
        source: [],
        target: []
      },
      parentColumn: {
        source: null,
        target: null
      },
      columnTitle: {
        source: null,
        target: null
      },
      nodeList: [],
      nodePosition: {
        start: 'left',
        stop: 'right'
      },
      mappingType: 'column-column',
      filterTargetId: null,
      lineDraw: false
    }

    DrawColumnMappingLine.setLineState = this.setLineState.bind(this);
    DrawColumnMappingLine.drawLine = this.drawLine.bind(this);
    DrawColumnMappingLine.endDropLine = this.endDropLine.bind(this);
    DrawColumnMappingLine.getState = this.getState.bind(this);
    DrawColumnMappingLine.setNodeListState = this.setNodeListState.bind(this);
    DrawColumnMappingLine.setColumnValueMappingFilter = this.setColumnValueMappingFilter.bind(this);
    DrawColumnMappingLine.handleScrollCallback = this.handleScrollCallback.bind(this);
    this.handleScrollCallback = this.handleScrollCallback.bind(this);
  }

  componentDidMount() {
    let totalTreeView = document.getElementsByClassName('treeview-content').length;

    for(let i= 0; i < totalTreeView; i++) {
      document.getElementsByClassName('treeview-content')[i].addEventListener('scroll', this.handleScrollCallback);
    }

    window.addEventListener("resize", this.handleScrollCallback);
  }

  componentWillUnmount() {
    let totalTreeView = document.getElementsByClassName('treeview-content').length;

    for(let i= 0; i < totalTreeView; i++) {
      document.getElementsByClassName('treeview-content')[i].removeEventListener('scroll', this.handleScrollCallback);
    }

    window.removeEventListener("resize", this.handleScrollCallback);
  }

  handleScrollCallback() {
    global.directionMode = 'left';
    let nodeList = this.state.nodeList;
    if($('.svg-line-mapper').parents('.k-animation-container').length > 0 &&  $('.svg-line-mapper').parents('.k-animation-container').css('display') === 'none') {
      return false;
    }

    nodeList.forEach((node, i) => {
      var sourceElement = getElement(node, 'source');
      var targetElement = getElement(node, 'target');

      if (sourceElement !== null && targetElement !== null) {
          let offset = wrapperOffsetElement();
          let drawlineLimit = drawLineElementLimit(sourceElement);
          let sourceColumn = $(sourceElement).attr('column');
          let targetColumn = $(targetElement).attr('column');
          let targetTableExist = false;

          if (node.mappingType === 'column-table' && $('#'+node.node.target).length > 0) {
            targetTableExist = true;
          }

          if (node.mappingType.indexOf('value') > -1) {
            node.display = 'show';
          } else {
            if (sourceColumn === '{}' || (targetColumn === '{}' && !targetTableExist)) {
              node.display = 'hidden';
            } else {
              node.display = 'show';
            }
          }

          sourceElement = getDimensions(sourceElement);
          targetElement =getDimensions(targetElement);

          node.line.startX = sourceElement.right - 13;
          node.line.startY = sourceElement.y - offset.top + (sourceElement.height/2);

          node.line.stopX = targetElement.left - 30;;
          node.line.stopY = targetElement.y - offset.top + (targetElement.height/2);


          if (typeof(drawlineLimit.height) !== 'undefined' && (drawlineLimit.height + drawlineLimit.top - offset.top)  < node.line.startY) {
            node.line.startY = drawlineLimit.height + drawlineLimit.top - offset.top;
          }

          if (node.line.startY < (drawlineLimit.top - offset.top + 5)) {
            node.line.startY = drawlineLimit.top - offset.top + 5;
          }

          if (typeof(drawlineLimit.height) !== 'undefined' && (drawlineLimit.height + drawlineLimit.top - offset.top)  < node.line.stopY) {
            node.line.stopY = drawlineLimit.height + drawlineLimit.top - offset.top;
          }

          if (node.line.stopY < (drawlineLimit.top - offset.top + 5)) {
            node.line.stopY = drawlineLimit.top - offset.top + 5;
          }

          nodeList[i].line = node.line;
      } else {
        nodeList[i].display = 'hidden';
      }
    });

    this.setState({nodeList: nodeList})
  }

  setLineState(line, nodeElment = null, columnElement = null, tableElement = null, columnValueElement = [], parentColumnElement = null, columnTitleElement = null) {
    let node = this.state.node;
    let column = this.state.column;
    let table = this.state.table;
    let columnValue = this.state.columnValue;
    let parentColumn = this.state.parentColumn;
    let columnTitle = this.state.columnTitle;

    if (nodeElment !== null) {
      if (typeof(nodeElment.source) !== 'undefined') {
        node.source = nodeElment.source;
      }

      if (typeof(nodeElment.target) !== 'undefined') {
        node.target = nodeElment.target;
      }
    }

    if (columnElement !== null) {
      if (typeof(columnElement.source) !== 'undefined') {
        column.source = columnElement.source;
      }

      if (typeof(columnElement.target) !== 'undefined') {
        column.target = columnElement.target;
      }
    }

    if (tableElement !== null) {
      if (typeof(tableElement.source) !== 'undefined') {
        table.source = tableElement.source;
      }

      if (typeof(tableElement.target) !== 'undefined') {
        table.target = tableElement.target;
      }
    }

    if (columnValueElement !== null) {
      if (typeof(columnValueElement.source) !== 'undefined') {
        columnValue.source = columnValueElement.source;
      }

      if (typeof(columnValueElement.target) !== 'undefined') {
        columnValue.target = columnValueElement.target;
      }
    }

    if (parentColumnElement !== null) {
      if (typeof(parentColumnElement.source) !== 'undefined') {
        parentColumn.source = parentColumnElement.source;
      }

      if (typeof(parentColumnElement.target) !== 'undefined') {
        parentColumn.target = parentColumnElement.target;
      }
    }

    if (columnTitleElement !== null) {
      if (typeof(columnTitleElement.source) !== 'undefined') {
        columnTitle.source = columnTitleElement.source;
      }

      if (typeof(columnTitleElement.target) !== 'undefined') {
        columnTitle.target = columnTitleElement.target;
      }
    }

    this.setState({ strokeColor:'black', line: line, node: node, column: column, table: table, columnValue: columnValue, parentColumn: parentColumn, columnTitle: columnTitle, lineDraw: false })
  }

  setNodeListState(nodeList) {
    this.setState({nodeList: nodeList, lineDraw: false})
  }

  drawLine({stopX, stopY}) {
    let line = this.state.line;
    line.stopX = line.startX < stopX ? stopX : line.startX;
    line.stopY = stopY;
    this.setState({strokeColor:'black', line: line})
  }

  endDropLine({stopX, stopY}, target, targetColumn, targetTable, targetColumnValue, targetParentColumn, targetId, targetColumnTitle, retainSourceTableList = [], customValue = false) {
    let node = this.state.node;
    let nodeList = this.state.nodeList;
    let line = this.state.line;
    let column = this.state.column;
    let table = this.state.table;
    let columnValue = this.state.columnValue;
    let mappingType = this.state.mappingType;
    let parentColumn = this.state.parentColumn;
    let columnTitle = this.state.columnTitle;

    line.stopX = line.startX < stopX ? stopX : line.startX;
    line.stopY = stopY;

    column.target = targetColumn;
    table.target = targetTable;
    columnValue.target = targetColumnValue;
    parentColumn.target = targetParentColumn;
    columnTitle.target = targetColumnTitle;
    node.target = target;

    let sourceValue = node.source.match(new RegExp('-sourceValue', "g"));
    let targetValue = node.target.match(new RegExp('-targetValue', "g"));

    if(typeof(column.source.column_header) !== 'undefined' && typeof(column.target.column_header) === 'undefined') {
      mappingType = 'column-table';
    } else if(typeof(column.source.column_header) === 'undefined' && typeof(column.target.column_header) !== 'undefined') {
      mappingType = 'value-column';
    } else if(typeof(column.source.column_header) === 'undefined' && typeof(column.target.column_header) === 'undefined') {
      let sourceValueCount = sourceValue !== null ? sourceValue.length : 0;
      let targetValueCount = targetValue !== null ? targetValue.length : 0;

      if ((sourceValueCount === 3 && targetValueCount === 3) || (typeof(parentColumn.source) === 'string' && typeof(parentColumn.target) === 'object' && typeof(parentColumn.target.column_header) !== 'undefined')) {
        mappingType = 'value-value';
      } else if (sourceValueCount === 3 && targetValueCount === 2) {
        mappingType = 'value-column';
      } else {
        mappingType = 'value-table';
      }
    }

    if (sourceValue === null || targetValue === null) {
      targetId = null;
    }

    nodeList = [...nodeList, { node: node, line: line, strokeColor: 'green', column: column, table: table, columnValue: columnValue, mappingType: mappingType, parentColumn: parentColumn, targetId: targetId, columnTitle: columnTitle, display: 'show', customValue: customValue}].filter(mappedNode => !(mappedNode.targetId === target && retainSourceTableList.indexOf(mappedNode.table.source) === -1))
    this.setState({strokeColor:'white', nodeList: nodeList})

    this.setState({ line: {startX:0,startY: 0,stopX: 0,stopY: 0}, node: {source: null, target: null}, strokeColor: 'white', column:{source: null, target: null}, table: {source: null, target: null}, columnValue: {source: [], target: []}, mappingType:'column-column', parentColumn: {source: null, target: null}, columnTitle: {source: null, target: null}, lineDraw: true })
  }

  getState() {
    return this.state;
  }

  setColumnValueMappingFilter(filterTargetId) {
    this.setState({ filterTargetId: filterTargetId }, () => {
      this.handleScrollCallback();
    });
  }

  displayMappedNodeList = (mappedNode, nodeIndex) => {
    return (
      <PathLine
          key={nodeIndex}
          id={`mapped-node-${mappedNode.node.source}-${mappedNode.node.target}`}
          className={`link ${mappedNode.display}`}
          markerStart={`url(#annotate-start-arrow-mapped)`}
          markerEnd={`url(#annotate-end-arrow-mapped)`}
          points={[{x: mappedNode.line.startX, y: mappedNode.line.startY}, {x: mappedNode.line.stopX, y: mappedNode.line.stopY}]}
          stroke={`${mappedNode.strokeColor}`}
          strokeWidth="3"
          fill="none"
          r={0}
          onClick={(e) => { e.target.classList.toggle('selected') }}
          />
    )
  };

  render() {
    return (
      <div className='svg-line-mapper'>
        <svg style={this.state.style}>
          <defs>
            <marker id={`annotate-end-arrow`} viewBox='0 -5 10 10' refX='6' orient='auto' markerWidth='3' markerHeight='3'>
              <path d='M0,-5L10,0L0,5' fill={`${this.state.strokeColor}`} />
            </marker>
            <marker id={`annotate-start-arrow`} viewBox='0 -5 10 10' refX='6' orient='auto' markerWidth='3' markerHeight='3'>
              <path d='M10,-5L0,0L10,5' fill={`${this.state.strokeColor}`} />
            </marker>
            <marker id={`annotate-end-arrow-mapped`} viewBox='0 -5 10 10' refX='6' orient='auto' markerWidth='3' markerHeight='3'>
              <path d='M0,-5L10,0L0,5' fill='green' />
            </marker>
            <marker id={`annotate-start-arrow-mapped`} viewBox='0 -5 10 10' refX='6' orient='auto' markerWidth='3' markerHeight='3'>
              <path d='M10,-5L0,0L10,5' fill='green' />
            </marker>
          </defs>
          <PathLine
              className='link'
              markerStart={`url(#annotate-start-arrow)`}
              markerEnd={`url(#annotate-end-arrow)`}
              points={[{x: this.state.line.startX, y: this.state.line.startY}, {x: this.state.line.stopX, y: this.state.line.stopY}]}
              stroke={`${this.state.strokeColor}`}
              strokeWidth="3"
              fill="none"
              r={0}
              />
            { this.state.nodeList.filter(line => (line.targetId === null || line.targetId === this.state.filterTargetId) && line.node.source !== '' && line.node.target !== '').map(this.displayMappedNodeList)}
        </svg>
      </div>

    )

  }
}

export default DrawColumnMappingLine;
