import React, { Component } from 'react';
import { DEFAULT_LOGIN_URL } from './../../../config';

import './loginCss.css';
class Login extends Component {
  constructor(props) {
    super(props);

    this.state = {
      username: "",
      password: ""
    };

    window.location.href = DEFAULT_LOGIN_URL;
  }

  UNSAFE_componentWillMount() {

  }

  componentDidMount(){
    localStorage.removeItem('access-token');
    Promise.resolve();
  }

  handleChange = event => {
    this.setState({
      [event.target.id]: event.target.value
    });
  }

  handleSubmit = event => {
    event.preventDefault();
    if (this.state.username === 'admin' && this.state.password === 'admin') {
      localStorage.clear();
      localStorage.setItem('access-token', 'gohead');
      Promise.resolve();
      this.props.history.push("/process-flow-view");
      this.props.history.push("/study");
    }
  }
  render() {
    return (
      <div>
      </div>
    );
    /*return (
      <div className="app flex-row align-items-center hidden">
        <Container>
          <Row className="justify-content-center">
            <Col md="5">
              <CardGroup>
                <Card className="border border-primary">
                  <form onSubmit={this.handleSubmit}>
                  <CardBody className="text-center">
                    <div className="login-logo">
                      <img src={loginLogo} alt="Analysis Mate" />
                    </div>
                    <p className="text-muted">Sign In to your account</p>
                    <InputGroup className="mb-3">
                      <InputGroupAddon addonType="prepend">
                        <InputGroupText>
                          <i className="icon-user"></i>
                        </InputGroupText>
                      </InputGroupAddon>
                      <Input type="text" id="username" onChange={this.handleChange} placeholder="Username" />
                    </InputGroup>
                    <InputGroup className="mb-4">
                      <InputGroupAddon addonType="prepend">
                        <InputGroupText>
                          <i className="icon-lock"></i>
                        </InputGroupText>
                      </InputGroupAddon>
                      <Input type="password" id="password" onChange={this.handleChange} placeholder="Password" />
                    </InputGroup>
                    <Row>
                      <Col xs="6">
                        <Button color="primary" className="px-4" >Login</Button>
                      </Col>
                      <Col xs="6" className="text-right">
                        <Button color="link" className="px-0">Forgot password?</Button>
                      </Col>
                    </Row>
                  </CardBody>
                  </form>
                </Card>
                {
                // <Card className="text-white bg-primary py-5 d-md-down-none" style={{ width: 44 + '%' }}>
                //   <CardBody className="text-center">
                //     <div>
                //       <h2>Sign up</h2>
                //       <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut
                //         labore et dolore magna aliqua.</p>
                //       <Button color="primary" className="mt-3" active>Register Now!</Button>
                //     </div>
                //   </CardBody>
                // </Card> 
              }
              </CardGroup>
            </Col>
          </Row>
        </Container>
      </div>
    );*/
  }
}

export default Login;
