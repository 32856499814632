import React from 'react';
import { PropTypes } from "prop-types";
import Dialog from 'react-dialog';
import RichGridTable from '../../../RichGridTable';
import { Button } from './../Button/StandardButton';
import { Input } from './../InputBox/StandardInput';
import { NewColumnNameCell } from '../InputBox/NewColumnNameCell';

export class PowerGrid extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      tableList:[props.table],
      refreshKey: 0,
      addDialogStatus: false,
      rowForm:{}
    }

    this.handleAddRowDialogClick = this.handleAddRowDialogClick.bind(this)
	  this.handleAddRowClick = this.handleAddRowClick.bind(this);
    this.handleRemoveRowClick = this.handleRemoveRowClick.bind(this);
    this.handleItemUpEvent = this.handleItemUpEvent.bind(this);
    this.handleItemDownEvent = this.handleItemDownEvent.bind(this);
  }

  handleAddRowDialogClick = (event) => {
    let tableList = this.state.tableList;
    let rowForm = {};
    if (tableList.length > 0 && typeof(tableList[0].columnList) !== 'undefined') {
      tableList[0].columnList.forEach(column => {
        rowForm = Object.assign(rowForm, {[column]: ''})
      })

      this.setState({addDialogStatus: true, rowForm: rowForm})
    }
  }

  handleColumnInputChange = (value, column) => {
    let rowForm = this.state.rowForm;
    rowForm[column] = value;
    this.setState({rowForm: rowForm})
  }

  handleAddRowCloseClick = (event) => {
    this.setState({addDialogStatus: false, rowForm:{}})
  }

  handleAddRowClick(event) {
    var  tableList = this.state.tableList;
    var newRowId = 0;
    var orderId= 0;
    let refreshKey = this.state.refreshKey;
    if (typeof(tableList[0].rows[0]) !== 'undefined' && tableList[0].rows[0]["rowId"] === '') {
        tableList[0].rows.pop();
    }

    if (tableList[0].rows.length > 0) {
      let maxRowId = Math.max.apply(Math, tableList[0].rows.map(function(o) { return o.rowId; }))
      newRowId = maxRowId + 1;
      let maxOrderId = Math.max.apply(Math, tableList[0].rows.map(function(o) { return o.order; }))
      orderId = maxOrderId + 1;
    }

    tableList[0].rows.push({...this.state.tableList[0].blankRow, selected: true, rowId: newRowId, columnId: newRowId, order: orderId, ...this.state.rowForm});
    refreshKey++;
    this.setState({ tableList: tableList, refreshKey: refreshKey }, () => {
      if (this.state.addDialogStatus) {
        this.handleAddRowCloseClick();
      }
    });
  }

  handleRemoveRowClick(event) {
    let tableList = this.state.tableList;
    const { rows } = this.state.tableList[0];
    if(typeof(rows) !== 'undefined') {
      tableList = this.removeTableRowsRecursiveElements(tableList)
    }
    tableList[0].rows.forEach((row,index)=>{
      rows[index].order=index;
    })
    this.setState({ tableList: tableList });
  }

  removeTableRowsRecursiveElements(tableList) {
    var index;
    for (index = 0; index < tableList[0].rows.length; index++) {
      if (tableList[0].rows[index].selected === true) {
        tableList[0].rows.splice(index, 1);
      }
    }

    for (index = 0; index < tableList[0].rows.length; index++) {
      if (tableList[0].rows[index].selected === true) {
        tableList = this.removeTableRowsRecursiveElements(tableList);
      }
    }

    return tableList;
  }

  handleItemUpEvent(event) {
    let tableList = this.state.tableList;
    let { rows } = tableList[0];
    rows.forEach((row, index) => {
        if (row.selected === true) {

            row.order = row.order > 1 ? row.order - 1 : 0;
            rows[index] = row;

            if (typeof(rows[index-1]) !== 'undefined') {
                rows[index-1].order = rows[index-1].order + 1;
            }
        }
    });

    rows = rows.sort((a, b) => a.order - b.order);
    var tmpRows = [].concat(rows)
    tableList[0].rows.splice(0, rows.length);
    tmpRows.forEach((row, index) => {
      tableList[0].rows.push(row);
    });
    this.setState({ tableList: tableList })
  }
  handleItemDownEvent(event) {
    let tableList = this.state.tableList;
    let { rows } = tableList[0];
    rows.forEach((row, index) => {
        if (row.selected === true) {

            row.order = row.order >= rows.length ? rows.length : row.order + 1;
            rows[index] = row;

            if (typeof(rows[index+1]) !== 'undefined') {
                rows[index+1].order = rows[index+1].order - 1;
            }
        }
    });

    rows = rows.sort((a, b) => a.order - b.order);
    var tmpRows = [].concat(rows)
    tableList[0].rows.splice(0, rows.length);
    tmpRows.forEach((row, index) => {
      tableList[0].rows.push(row);
    });
    this.setState({ tableList: tableList })
  }

  render() {
      return (
        <div className="analysis-grid">
          <div className="filter-area am-form-inline col-12">
            <div className="clearfix">
              <Button primary={true} onClick={typeof(this.state.tableList[0]) !== 'undefined' && typeof(this.state.tableList[0].addDialog) !== 'undefined' ? this.handleAddRowDialogClick : this.handleAddRowClick}>Add</Button>
              <Button primary={true} className="m-left" onClick={this.handleRemoveRowClick}>Delete</Button>
              <Button primary={true} className="m-left" onClick={this.handleItemUpEvent}>Up</Button>
              <Button primary={true} className="m-left" onClick={this.handleItemDownEvent}>Down</Button>
            </div>
          </div>
          {this.state.addDialogStatus &&
            <div className="add-column-data-dialog">
              <Dialog width="870px" height="auto" modal={true} className="expression" title={`Add Row`} onClose={this.handleAddRowCloseClick} buttons={[<Button primary={true} onClick={this.handleAddRowClick} key="btn-save"> Save</Button>, <Button onClick={this.handleAddRowCloseClick} key="btn-cancel"> Cancel</Button>]}>
                {this.state.tableList[0].columnList.map((column, index) => {
                  return (
                    <div key={index} className="am-form-line-left">
                        <label className="pro-label width-200"><span >{column}</span></label>
                        <Input type="text" className="k-textbox width-500" onChange={(event) => this.handleColumnInputChange(event.target.value, column)} value={this.state.rowForm[column]} />
                    </div>
                  )
                })}

              </Dialog>
            </div>
          }
          <RichGridTable {...this.state.tableList[0]} key={this.state.refreshKey}/>
        </div>
      )
    }
}

Dialog.propTypes = {
    height: PropTypes.oneOfType([
        PropTypes.string
    ]),
    width: PropTypes.oneOfType([
        PropTypes.string
    ])
}
