import React from 'react';
import { DropDownList } from './StandardDropDownList';

export const DataOpsProcessOptionCell = ({ tableManager, value, data, column, colIndex, rowIndex }) => {
    if (typeof(tableManager) === 'undefined') {
      return (null)
    } else {
      return (
          <div className='rgt-cell-inner' style={{display: 'flex', alignItems: 'center', overflow: 'hidden'}}>
              <DataOpsProcessOptionCellClass dataItem={data} field={column.field} value={typeof(data[column.field]) !== 'undefined' ? data[column.field]: value} column={column} tableManager={tableManager}/>
          </div>
      )
    }
}

class DataOpsProcessOptionCellClass extends React.Component {

    handleChange(e) {
      this.props.dataItem[this.props.field] = typeof(e) !== "undefined" ? e.target.value : null;
      this.props.tableManager.rowsApi.setRows(this.props.tableManager.rowsApi.rows)
    }
    render() {
      var data = [""];

      var disabled = false;
      if (this.props.dataItem['rowId'] === '' || this.props.dataItem['Variable'] === '') {
        disabled = true;
      }

      if (this.props.dataItem['variable'] !== '') {
        if (typeof(this.props.dataItem['variable'].split('-')[1]) !== 'undefined' && this.props.dataItem['variable'].split('-')[1].split(' ')[0] !== '') {
          var type = this.props.dataItem['variable'].split('-')[1].split(' ')[0];
          if (this.props.dataItem['resultType'] !== 'Variable Like') {
            if (type === 'CHAR') {
              if (this.props.dataItem['resultType'] === 'Character') {
                data = [ "", "Count", "Flag(num)", "Flag(char)", "Reverse Flag(num)", "Reverse Flag(char)", "CONCATENAT"]
              } else {
                data = [ "", "Min", "Max"];
              }
            } else if (type === 'NUM') {
              data = [ "", "Min", "Max", "Mean", "Median", "Sum", "Count", "Flag(num)", "Flag(char)", "Reverse Flag(num)", "Reverse Flag(char)"]
            } else if (type === 'DATE' || type === 'DATETIME') {
              data = [ "", "Min", "Max", "Count", "Flag(num)", "Flag(char)", "Reverse Flag(num)", "Reverse Flag(char)"]
            }
          } else {
            if (type === 'CHAR' && this.props.dataItem['resultType'] === 'Variable Like') {
              data = [ "", "CONCATENAT" ]
            } else {
              data = [ "", "Min", "Max" ]
            }
          }
        }
      }
      //fetching scale direction value
        //if scale Direction ="All" , then this cell is enable else its disable
        global.processBlockControlValues.forEach((control, index) => {
          if (control.controlId === 21) {
              if(global.processBlockControlValues[index].controlValue === "All"){
                  let newData = data.filter(item => item !== "Median");
                  data = newData;
                  if(this.props.dataItem[this.props.field] === "Median"){
                    this.props.dataItem[this.props.field] = null;
                    this.handleChange();
                  }
              }

          }
        });

        return (
            <>
              <DropDownList className="width-full" onChange={this.handleChange.bind(this)} data={data} value={this.props.dataItem[this.props.field]} disabled={disabled}/>
            </>
        );
    }
}
