import React from 'react';
import { Button } from './../DataOperation/common/Button/StandardButton';
import { connect } from 'react-redux';
import { createSelector } from 'reselect';
import { addProcessFlow, setActiveProcessFlow } from '../../../actions/actionProcessFlow';
import ConfirmBox from './../DataOperation/common/ConfirmBox';

export const JumpToFlowButton = ({ tableManager, value, data, column, colIndex, rowIndex }) => {
    if (typeof(tableManager) === 'undefined') {
      return (null)
    } else {
      return (
          <div className='rgt-cell-inner' style={{alignItems: 'center', overflow: 'hidden', textAlign: "center"}}>
              <JumpToFlowButtonClass dataItem={data} field={column.field} value={value} tableManager={tableManager}/>
          </div>
      )
    }
}


class BaseJumpToFlowButton extends React.Component {

    handleChange(e) {
      this.props.dataItem[this.props.field] = e.target.value;
      this.props.tableManager.rowsApi.setRows(this.props.tableManager.rowsApi.rows)
    }
    fetchProcessFlow = (studyId, processId) =>{
        var that = this;
        let treeView = that.props.treeView.data;
        let selectedFlow = null;
        treeView.children.forEach((project, index) => {
          project.children.forEach((study) => {
            if (study.study.studyId === studyId) {
              study.children.forEach((studyElement) => {
                if (studyElement.name === 'ProcessFlows') {
                  studyElement.children.forEach((classifier) => {
                    classifier.children.forEach((processGroup) => {
                      processGroup.children.forEach((flow)=>{
                        if(processId === flow.process.processId){
                          selectedFlow = flow;
                          return selectedFlow;
                        }
                      })
                    });
                  })
                }
              })
            }
         });
        })
        return selectedFlow;
      }

    handleOnSourceFlowClick = (event) => {
        let that = this;
        let process = that.fetchProcessFlow(that.props.dataItem.sourceStudyId,that.props.dataItem.sourceFlowId );
        if(process !== null){
          let flowAlreadyOpen = false;
          that.props.processFlowList.forEach((flow, index) => {
            if(flow.process.processId === process.process.processId && flow.process.studyId === process.process.studyId && flow.process.processGroupId === process.process.processGroupId){
              flowAlreadyOpen = true;
              that.props.setActiveProcessFlow(index);
            }
          });
          if(!flowAlreadyOpen){
            that.props.addProcessFlow(process);
          }
          window.location.hash='#process-flow-list';
          that.props.dataItem.closeEvent();
        }else{
          ConfirmBox.alert("Study for selected jump to block not included. Please select appropriate Study using ‘Study Select’ link.");
        }
    }

    handleOnCopyFlowClick = (event) => {
        let that = this;
        let process = that.fetchProcessFlow(that.props.dataItem.processStudyId,that.props.dataItem.processFlowId );
        if(process !== null){
          let flowAlreadyOpen = false;
          that.props.processFlowList.forEach((flow, index) => {
            if(flow.process.processId === process.process.processId && flow.process.studyId === process.process.studyId && flow.process.processGroupId === process.process.processGroupId){
              flowAlreadyOpen = true;
              that.props.setActiveProcessFlow(index);
            }
          });
          if(!flowAlreadyOpen){
            that.props.addProcessFlow(process);
          }
          window.location.hash='#process-flow-list';
          that.props.dataItem.closeEvent();
        }else{
          ConfirmBox.alert("Study for selected jump to block not included. Please select appropriate Study using ‘Study Select’ link.");
        }
    }

    render() {
        let css = "col-md-12";
        if(this.props.dataItem.processFlowId !== 0 && this.props.dataItem.sourceFlowId !== 0){
            css = "col-md-6";
        }
        return (
          <div className="row">
          {
              this.props.dataItem.sourceFlowId !== 0 &&
              <div className={css}>
              <Button primary={true} onClick={this.handleOnSourceFlowClick}> Open Source Flow</Button>
              </div>
          }{
              this.props.dataItem.processFlowId !== 0 &&
              <div className={css}>
              <Button primary={true} onClick={this.handleOnCopyFlowClick}> Open Target Flow</Button>
              </div>
          }

          </div>
        );
    }
}

const mapStateToProps = createSelector(
    state => state.processFlow.tenantId,
    state => state.treeView,
    state => state.processFlow.processFlowList,
    (tenantId, treeView, processFlowList) => ({
        tenantId,
        treeView,
        processFlowList
    })
  );

  const mapActionsToProps = {
    addProcessFlow: addProcessFlow,
    setActiveProcessFlow: setActiveProcessFlow
  }

const JumpToFlowButtonClass = connect(mapStateToProps, mapActionsToProps)(BaseJumpToFlowButton);
