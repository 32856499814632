import React from 'react';

export const TargetColumnName = ({ tableManager, value, data, column, colIndex, rowIndex }) => {
    if (typeof(tableManager) === 'undefined') {
      return (null)
    } else {
      return (
          <div className='rgt-cell-inner' style={{display: 'flex', alignItems: 'center', overflow: 'hidden'}}>
              <TargetColumnNameClass dataItem={data} field={column.field} value={typeof(data[column.field]) !== 'undefined' ? data[column.field]: value} column={column} tableManager={tableManager}/>
          </div>
      )
    }
}

class TargetColumnNameClass extends React.Component {
  render() {
        let gbyColumn = false;
        let obyColumn = false;
        if (typeof(this.props.dataItem['expressionDetail'].node) !== 'undefined') {
          if (this.props.dataItem['expressionDetail'].node.gby === true) {
            gbyColumn = true
          }
          if (this.props.dataItem['expressionDetail'].node.oby === true) {
            obyColumn = true
          }
        }

        return (
            <>
              {this.props.dataItem[this.props.field]}
              {gbyColumn === true &&
                <span className="gby-column">[G]</span>
              }
              {obyColumn === true &&
                <span className="oby-column">[O]</span>
              }

            </>
        );
    }
}
