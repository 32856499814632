import React from 'react';
import { Button } from './StandardButton';
import { PropTypes } from "prop-types";
import Dialog from 'react-dialog';
import { connect } from 'react-redux';
import { createSelector } from 'reselect';
import { setMessage } from '../../../../../actions/actionNotification';
import "../../DataOperation.css";

export const OutputTableButton = ({ tableManager, value, data, column, colIndex, rowIndex }) => {
  if (typeof(tableManager) === 'undefined') {
    return (null)
  } else {
    return (
        <div className='rgt-cell-inner' style={{display: 'flex', alignItems: 'center', overflow: 'hidden'}}>
            <OutputTableButtonClass dataItem={data} field={column} value={typeof(data[column.field]) !== 'undefined' ? data[column.field]: value} tenantId={typeof(column.tenantId) !== 'undefined' ? column.tenantId: ''}/>
        </div>
    )
  }
}
class BaseOutputTableButton extends React.Component {

    constructor(props) {
        super(props);
        this.openTab = this.openTab.bind(this);
      }
      openTab(){
        let outTableBlock = this.props.dataItem['outTableBlock'];
        if (typeof(outTableBlock) !== 'undefined' && outTableBlock.length > 0) {
          let jump = {
            p: eval(outTableBlock[0].schemaName.split('_')[1]),
            s: outTableBlock[0].studyId,
            pf: outTableBlock[0].processId,
            b: outTableBlock[0].blockId
          };
          localStorage.setItem("login_feature", "false");
          localStorage.setItem("id_token", global.login_token.id_token);

          window.open(window.location.origin+'/#/jump='+window.btoa(JSON.stringify(jump)),'_blank');
        }

      }

      render() {
          let disabled = true;

          if(typeof(this.props.dataItem['outputTable']) !== "undefined" && this.props.dataItem['outputTable'] !== null && this.props.dataItem['outputTable'] !== "" ){
              disabled = false;
          }
          return (
            <>
              {disabled === false && this.props.dataItem['outputTable'].indexOf('Output') === -1 ? (
                <div className={"link-button"}>
                    <Button onClick={this.openTab} disabled={disabled}>{this.props.dataItem['outputTable']}</Button>
                  </div>
                ):(
                  <div>{this.props.dataItem['outputTable']}</div>
                )}
            </>
          )
      }
}

const mapStateToProps = createSelector(
    state => state.processFlow.tenantId,
    (tenantId) => ({
        tenantId
    })
  );

  const mapActionsToProps = {
    setMessage: setMessage
  }

  BaseOutputTableButton.propTypes = {
    tenantId: PropTypes.string
  }


  Dialog.propTypes = {
      height: PropTypes.oneOfType([
          PropTypes.string
      ]),
      width: PropTypes.oneOfType([
          PropTypes.string
      ])
  }
const OutputTableButtonClass = connect(mapStateToProps, mapActionsToProps)(BaseOutputTableButton);
