import React from "react";
import { TabStrip, TabStripTab  } from './../common/Layout';
import { Title, FootNote, Define, Compute, Break, Ods, StyleTemplate } from '../common/ProcReport';
import DataOpsIdentifierConstants from "../../../DataOpsIdentifierConstants";
import { Input } from './../common/InputBox/StandardInput';
import ConfirmBox from './../common/ConfirmBox';

function getPreOptions (preOptionsList,  preOptioName){
  let value ='';
  if(preOptionsList === null || preOptionsList === ''){
    return value;
  }
  let selectedItem = preOptionsList.filter(item => item.property.toLowerCase() === preOptioName.toLowerCase());
  if(selectedItem.length > 0){
    value= selectedItem[0].value;
  }

  return value;
}

function getGlobalValue(controlIndex){
  let controlValue = '';
  global.processBlockControlValues.forEach((control, index) => {
    if (control.controlId === controlIndex) {
      controlValue = global.processBlockControlValues[index].controlValue;

    }
  });
  return controlValue;
}

function saveGlobalValue(controlIndex, value){
  let controlIdFound = false;
  global.processBlockControlValues.forEach((control, index) => {
    if (control.controlId === controlIndex) {
      controlIdFound = true;
      global.processBlockControlValues[index].controlValue = value;
    }
  });
  if(!controlIdFound){
      global.processBlockControlValues.push({controlId:controlIndex, controlType:controlIndex,controlValue:value});
  }
}

function getColumnValue(footnote, inputColumns, colName) {
  if (footnote === "" || footnote === null || typeof(footnote) === "undefined") {
    let selectedValue = inputColumns.filter(item => item.toLowerCase() === colName.toLowerCase());
    if (selectedValue.length > 0) {
      footnote = selectedValue[0];
    }
  }
  else {
    let selectedValue = inputColumns.filter(item => item.toLowerCase() === footnote.toLowerCase());
    if (selectedValue.length > 0) {
      footnote = selectedValue[0];
    }
    else {
      let selectedValue = inputColumns.filter(item => item.toLowerCase() === colName.toLowerCase());
      if (selectedValue.length > 0) {
        footnote = selectedValue[0];
      }else{
        footnote = "";
      }
    }
  }
  return footnote;
}

export function SaveDefaultParametersToGlobal(objectData, inputColumns, tableRows, treeView, activeProcessFlow, autoTlf = false){
  let ProcReportComputeTableIndex  = 18;
  let DefineColumnStatementIndex = 17;
  let ProcReportDefineIndex  = 14;
  let ProcReportStyleTemplateIndex  = 20;
  let ProcReportPageOrientationIndex  = 21;
  let ProcReportPageSizeIndex  = 22;
  let ProcReportTopMarginIndex  = 23;
  let ProcReportBottomMarginIndex  = 90;
  let ProcReportLeftMarginIndex  = 25;
  let ProcReportRightMarginIndex  = 26;
  let ProcReportEscapeCharacterIndex  = 27;
  let ProcReportPdfOptionsIndex  = 28;
  let ProcReportRtfOptionsIndex  = 29;
  let ProcReportHtmlOptionsIndex  = 30;
  let ProcReportXlsOptionsIndex  = 31;
  let ProcReportBreakTableIndex  = 19;
  let ReportOptionIndex = 11;
  let BlankValueIndex = 89;
  let ProcReportTitlesIndex = 12;
  let ProcReportFootNotesIndex = 13;
  let ProcReportTitleOptionType =  36;
  let ProcReportFootNoteOptionType =  37;
  let ProcReportPagePerLineIndex = 91;
  let ProcReportAcrossColumnIndex = 92;

  let titleObject = {
    title1:"",
    title2:"",
    title3:"",
    title4:"",
    title5:"",
    title6:"",
    title7:"",
    title8:"",
    title9:"",
    title10:""
  }
  let footNoteObject = {
    footNote1:"",
    footNote2:"",
    footNote3:"",
    footNote4:"",
    footNote5:"",
    footNote6:"",
    footNote7:"",
    footNote8:"",
    footNote9:"",
    footNote10:""
  }
  if(getGlobalValue(ProcReportTitleOptionType) === ""){
    for(let i=1 ; i<=10 ; i++){
      titleObject[`title${i}`] = getColumnValue(titleObject[`title${i}`], inputColumns, `title${i}`);
    }
    saveGlobalValue(ProcReportTitlesIndex, JSON.stringify(titleObject));
    saveGlobalValue(ProcReportTitleOptionType,"TEXT");
  }
  if(getGlobalValue(ProcReportFootNoteOptionType) === ""){
    for(let i=1 ; i<=10 ; i++){
      footNoteObject[`footNote${i}`] = getColumnValue(footNoteObject[`footNote${i}`], inputColumns, `footNote${i}`);
    }
    saveGlobalValue(ProcReportFootNotesIndex, JSON.stringify(footNoteObject));
    saveGlobalValue(ProcReportFootNoteOptionType,"TEXT");
  }
  if(getGlobalValue(BlankValueIndex) === "" && !global.updateBlock){
    saveGlobalValue(BlankValueIndex,"0");
  }
  if(getGlobalValue(ProcReportPagePerLineIndex) === "" && !global.updateBlock){
    saveGlobalValue(ProcReportPagePerLineIndex,"");
  }
  if(getGlobalValue(ProcReportAcrossColumnIndex) === "" && !global.updateBlock){
    saveGlobalValue(ProcReportAcrossColumnIndex,"");
  }

  //validation to ensure user to have some column specified in '_proc_report_column'
  let value = getPreOptions(objectData,'_proc_report_column' );
  let requiredColumns = value;
  let preColumn = value.split(' ');
  preColumn.forEach((col,index)=>{
    preColumn[index] = col.replace('(','').replace(')','');
  });
  let regexColumn = [];
  //only those columns that are present in tables need to be added
  let newInputColumns = [];
  inputColumns.forEach((item)=>{
    newInputColumns.push(item);
  });
  if(preColumn && preColumn.length >0){
    regexColumn = preColumn.filter(item => item.indexOf('<n>') !== -1);
    regexColumn.forEach((col,index) => {
      if(col.indexOf(',') !== -1){
        regexColumn[index] = col.substr(0,col.lastIndexOf(','));
      }
    });
    regexColumn.forEach((col,index) => {
      regexColumn[index] = col.replace("<n>","");
    });
  }
  let newCol = "";
  regexColumn.forEach((col,index) => {
    newCol = "";
    let pattern = "\\b" +col + "[0-9]+\\b";
    let  obj = new RegExp(pattern,'i');
    newInputColumns.forEach((column) => {
      if(obj.test(column)){
        if(/\bcol_var_sort[0-9]+\b/i.test(newCol)){
          newCol = newCol + "," + column
          newCol = newCol.trim()
        }else{
          newCol = newCol + " " + column
          newCol = newCol.trim()
        }
      }
    });
    value = value.replace(col+"<n>" ,  newCol);
  });
  let inputColumnFound = false;
  preColumn.forEach((col) => {
    if(newInputColumns.indexOf(col) === -1){
      value = value.replace(col, '').trim();
    }else{
      inputColumnFound = true;
    }
  });
  var inbetweencol = value.substring(
    value.lastIndexOf("(") + 1,
    value.lastIndexOf(")")
);
  inbetweencol = inbetweencol.trim();
  if(inbetweencol === ""){
    value= value.replace('(','').replace(')','');
  }
  if(!inputColumnFound && !autoTlf){
    ConfirmBox.alert('Any Columns from \'' + requiredColumns +'\' required for Proc Report not found.Hence Proc Report execution will not work properly.');
  }

  saveGlobalValue(DefineColumnStatementIndex, value);


  value = getPreOptions(objectData,'_orientation' );
  saveGlobalValue(ProcReportPageOrientationIndex, value);
  value = getPreOptions(objectData,'_proc_report_options' );
  saveGlobalValue(ReportOptionIndex, value);
  value = getPreOptions(objectData,'_papersize' );
  saveGlobalValue(ProcReportPageSizeIndex, value);
  value = getPreOptions(objectData,'_topMargin' );
  saveGlobalValue(ProcReportTopMarginIndex, value);
  value = getPreOptions(objectData,'_bottomMargin' );
  saveGlobalValue(ProcReportBottomMarginIndex, value);
  value = getPreOptions(objectData,'_leftMargin' );
  saveGlobalValue(ProcReportLeftMarginIndex, value);
  value = getPreOptions(objectData,'_rightMargin' );
  saveGlobalValue(ProcReportRightMarginIndex, value);
  value = getPreOptions(objectData,'_escapeChar' );
  saveGlobalValue(ProcReportEscapeCharacterIndex, value);
  value = getPreOptions(objectData,'_htmlOdsOpts' );
  saveGlobalValue(ProcReportHtmlOptionsIndex, JSON.stringify(value.split("\n").filter(a=>a)));
  value = getPreOptions(objectData,'_rtfOdsOpts' );
  saveGlobalValue(ProcReportRtfOptionsIndex, JSON.stringify(value.split("\n").filter(a=>a)));
  value = getPreOptions(objectData,'_pdfOdsOpts' );
  saveGlobalValue(ProcReportPdfOptionsIndex, JSON.stringify(value.split("\n").filter(a=>a)));
  value = getPreOptions(objectData,'_csvOdsOpts' );
  saveGlobalValue(ProcReportXlsOptionsIndex, JSON.stringify(value.split("\n").filter(a=>a)));
  value = getPreOptions(objectData,'_orientation' );
  saveGlobalValue(ProcReportPageOrientationIndex, value);
  value = getPreOptions(objectData,'_proc_template' );
  saveGlobalValue(ProcReportStyleTemplateIndex, JSON.stringify(value.split("\n").filter(a=>a)));
  //saving default value for Define
  let defineRows = [];
  tableRows.forEach((row)=>{
    let newRow = {...row,...{"gby" : getGBYValue(row) , "defineOptions" : ''}};
    if(row.column.toLowerCase().indexOf('col_var_sort') !== -1){
      newRow.defineOptions = getPreOptions(objectData,'_define_col_var_sort').replace('<n>',row.column.replace(new RegExp("\\bcol_var_sort\\b",'ig'), "").trim());
    } else  if(/\brow_var[0-9]+\b/i.test(row.column)){
      newRow.defineOptions = getPreOptions(objectData,'_define_row_var' );
    } else if (/\brow_var_sort[0-9]+\b/i.test(row.column)){
      //regex to check if column is '_by_var<n>'
      newRow.defineOptions = getPreOptions(objectData,'_define_row_var_sort<n>' ).replace('<n>',row.column.replace(new RegExp("\\brow_var_sort\\b",'ig'), "").trim());
    }else {
      switch(row.column.toLowerCase()){
        case 'blockid':
          newRow.defineOptions = getPreOptions(objectData,'_define_blockid' );
        break;
        case 'blank_line':
          newRow.defineOptions = getPreOptions(objectData,'_define_blank_line' );
        break;
        case 'avalue_title':
          newRow.defineOptions = getPreOptions(objectData,'_define_avalue_title' );
        break;
        case 'avalue_sort':
          newRow.defineOptions = getPreOptions(objectData,'_define_avalue_sort' );
        break;
        case 'avalue_indent':
          newRow.defineOptions = getPreOptions(objectData,'_define_avalue_indent' );
        break;
        case 'avalue_char_s':
          newRow.defineOptions = getPreOptions(objectData,'_define_avalue_char_s' );
        break;
        case 'avalue_char':
          newRow.defineOptions = getPreOptions(objectData,'_define_avalue_char' );
        break;
        case 'avalue_num':
          newRow.defineOptions = getPreOptions(objectData,'_define_avalue_num' );
        break;
        case 'dummy':
          newRow.defineOptions = getPreOptions(objectData,'_define_dummy' );
        break;
        case 'avalue_label':
          newRow.defineOptions = getPreOptions(objectData,'_define_avalue_label' );
        break;
        case 'pageno':
          newRow.defineOptions = getPreOptions(objectData,'_define_pageno' );
        break;
        default:
          newRow.defineOptions = '';
        break;
      }
    }
    defineRows.push(newRow);
  });
  saveGlobalValue(ProcReportDefineIndex,JSON.stringify(defineRows));
  //saving default value for Compute
  let computeRows = [];
  tableRows.forEach((row)=>{
    let newRow = {...row,...{ "computeStatement" : ''}};
    if(typeof(newRow.computeStatement) !== 'undefined'){
      switch(row.column.toLowerCase()){
        case 'blockid':
          newRow.computeStatement = getPreOptions(objectData,'_compute_blockid' );
        break;
        case 'avalue_title':
          newRow.computeStatement = getPreOptions(objectData,'_compute_avalue_title' );
        break;
        case 'blank_line':
          newRow.computeStatement = getPreOptions(objectData,'_compute_blank_line' );
        break;
        case 'avalue_label':
          newRow.computeStatement = getPreOptions(objectData,'_compute_avalue_label' );
        break;
        default:
          newRow.computeStatement = '';
        break;
      }
    }
    computeRows.push(newRow);
  });
  saveGlobalValue(ProcReportComputeTableIndex,JSON.stringify(computeRows));
  //saving default value for Break
  let breakRows = [];
  tableRows.forEach((row)=>{
    let newRow = {...row,...{ "breakStatement" : ''}};
    if(typeof(newRow.breakStatement) !== 'undefined'){
      switch(row.column.toLowerCase()){
        case 'pageno':
          newRow.breakStatement = getPreOptions(objectData,'_break_pageno' );
        break;
      }
    }
    breakRows.push(newRow);
  });
  saveGlobalValue(ProcReportBreakTableIndex,JSON.stringify(breakRows));
}


export function SaveDefaultParametersToGlobalForR(objectData, inputColumns, tableRows, treeView, activeProcessFlow){
  let ProcReportComputeTableIndex  = 18;
  let DefineColumnStatementIndex = 17;
  let ProcReportDefineIndex  = 14;
  let ProcReportStyleTemplateIndex  = 20;
  let ProcReportPageOrientationIndex  = 21;
  let ProcReportPageSizeIndex  = 22;
  let ProcReportTopMarginIndex  = 23;
  let ProcReportBottomMarginIndex  = 90;
  let ProcReportLeftMarginIndex  = 25;
  let ProcReportRightMarginIndex  = 26;
  let ProcReportEscapeCharacterIndex  = 27;
  let ProcReportPdfOptionsIndex  = 28;
  let ProcReportRtfOptionsIndex  = 29;
  let ProcReportHtmlOptionsIndex  = 30;
  let ProcReportXlsOptionsIndex  = 31;
  let ProcReportBreakTableIndex  = 19;
  let ReportOptionIndex = 11;
  let BlankValueIndex = 89;
  let ProcReportTitlesIndex = 12;
  let ProcReportFootNotesIndex = 13;
  let ProcReportTitleOptionType =  36;
  let ProcReportFootNoteOptionType =  37;
  let ProcReportPagePerLineIndex = 91;
  let ProcReportAcrossColumnIndex = 92;
  let titleObject = {
    title1:"",
    title2:"",
    title3:"",
    title4:"",
    title5:"",
    title6:"",
    title7:"",
    title8:"",
    title9:"",
    title10:""
  }
  let footNoteObject = {
    footNote1:"",
    footNote2:"",
    footNote3:"",
    footNote4:"",
    footNote5:"",
    footNote6:"",
    footNote7:"",
    footNote8:"",
    footNote9:"",
    footNote10:""
  }
  if(getGlobalValue(ProcReportTitleOptionType) === ""){
    for(let i=1 ; i<=10 ; i++){
      titleObject[`title${i}`] = getColumnValue(titleObject[`title${i}`], inputColumns, `title${i}`);
    }
    saveGlobalValue(ProcReportTitlesIndex, JSON.stringify(titleObject));
    saveGlobalValue(ProcReportTitleOptionType,"TEXT");
  }
  if(getGlobalValue(ProcReportFootNoteOptionType) === ""){
    for(let i=1 ; i<=10 ; i++){
      footNoteObject[`footNote${i}`] = getColumnValue(footNoteObject[`footNote${i}`], inputColumns, `footNote${i}`);
    }
    saveGlobalValue(ProcReportFootNotesIndex, JSON.stringify(footNoteObject));
    saveGlobalValue(ProcReportFootNoteOptionType,"TEXT");
  }
  if(getGlobalValue(BlankValueIndex) === "" && !global.updateBlock){
    saveGlobalValue(BlankValueIndex,"0");
  }
  if(getGlobalValue(ProcReportPagePerLineIndex) === "" && !global.updateBlock){
    saveGlobalValue(ProcReportPagePerLineIndex,"");
  }
  if(getGlobalValue(ProcReportAcrossColumnIndex) === "" && !global.updateBlock){
    saveGlobalValue(ProcReportAcrossColumnIndex,"");
  }

  //validation to ensure user to have some column specified in 'r_report_column'
  let originalvalue = getPreOptions(objectData,'r_report_column' );
  let value = originalvalue.match(/"([^"]+\W*)"/ig).join(" ").replace(/"/g,'');
  let dupicateCols = value.split(' ');
  let uniqueCols = [...new Set(dupicateCols)];
  value = uniqueCols.join(' ');
  let requiredColumns = value;
  let preColumn = value.split(' ');
  preColumn.forEach((col,index)=>{
    preColumn[index] = col.replace('(','').replace(')','');
  });
  let regexColumn = [];
  //only those columns that are present in tables need to be added
  let newInputColumns = [];
  inputColumns.forEach((item)=>{
    newInputColumns.push(item);
  });
  if(preColumn && preColumn.length >0){
    regexColumn = preColumn.filter(item => item.indexOf('<n>') !== -1);
    regexColumn.forEach((col,index) => {
      if(col.indexOf(',') !== -1){
        regexColumn[index] = col.substr(0,col.lastIndexOf(','));
      }
    });
    regexColumn.forEach((col,index) => {
      regexColumn[index] = col.replace("<n>","");
    });
  }
  let newCol = "";
  regexColumn.forEach((col,index) => {
    newCol = "";
    let pattern = "\\b" +col + "[0-9]+\\b";
    let  obj = new RegExp(pattern,'i');
    newInputColumns.forEach((column) => {
      if(obj.test(column)){
        if(/\bcol_var_sort[0-9]+\b/i.test(newCol)){
          newCol = newCol + "," + column
          newCol = newCol.trim()
        }else{
          newCol = newCol + " " + column
          newCol = newCol.trim()
        }
      }
    });
    originalvalue = originalvalue.replace(col+"<n>" ,  newCol);
  });
  let inputColumnFound = false;
  preColumn.forEach((col) => {
    if(newInputColumns.indexOf(col) === -1){
      originalvalue = originalvalue.replace(col, '').trim();
    }else{
      inputColumnFound = true;
    }
  });

  if(!inputColumnFound){
    ConfirmBox.alert('Any Columns from \'' + requiredColumns +'\' required for R Report not found.Hence R Report execution will not work properly.');
  }

  saveGlobalValue(DefineColumnStatementIndex, originalvalue);


  value = getPreOptions(objectData,'orientation' );
  saveGlobalValue(ProcReportPageOrientationIndex, value);
  value = getPreOptions(objectData,'r_report_options' );
  saveGlobalValue(ReportOptionIndex, value);
  value = getPreOptions(objectData,'papersize' );
  saveGlobalValue(ProcReportPageSizeIndex, value);
  value = getPreOptions(objectData,'topMargin' );
  saveGlobalValue(ProcReportTopMarginIndex, value);
  value = getPreOptions(objectData,'bottomMargin' );
  saveGlobalValue(ProcReportBottomMarginIndex, value);
  value = getPreOptions(objectData,'leftMargin' );
  saveGlobalValue(ProcReportLeftMarginIndex, value);
  value = getPreOptions(objectData,'rightMargin' );
  saveGlobalValue(ProcReportRightMarginIndex, value);
  value = getPreOptions(objectData,'escapeChar' );
  saveGlobalValue(ProcReportEscapeCharacterIndex, value);
  value = getPreOptions(objectData,'htmlOdsOpts' );
  saveGlobalValue(ProcReportHtmlOptionsIndex, JSON.stringify(value.split("\n").filter(a=>a)));
  value = getPreOptions(objectData,'rtfOdsOpts' );
  saveGlobalValue(ProcReportRtfOptionsIndex, JSON.stringify(value.split("\n").filter(a=>a)));
  value = getPreOptions(objectData,'pdfOdsOpts' );
  saveGlobalValue(ProcReportPdfOptionsIndex, JSON.stringify(value.split("\n").filter(a=>a)));
  value = getPreOptions(objectData,'csvOdsOpts' );
  saveGlobalValue(ProcReportXlsOptionsIndex, JSON.stringify(value.split("\n").filter(a=>a)));
  value = getPreOptions(objectData,'orientation' );
  saveGlobalValue(ProcReportPageOrientationIndex, value);
  value = getPreOptions(objectData,'r_template' );
  saveGlobalValue(ProcReportStyleTemplateIndex, JSON.stringify(value.split("\n").filter(a=>a)));
  //saving default value for Define
  let defineRows = [];
  tableRows.forEach((row)=>{
    let newRow = {...row,...{"gby" : getGBYValue(row) , "defineOptions" : ''}};
    if (/\bby_var[0-9]+\b/i.test(row.column)){
      newRow.defineOptions = getPreOptions(objectData,'define_by_var').replace(/by_var<n>/i,row.column.replace(new RegExp("\\bby_var\\b",'ig'), "").trim()).replace(/<n>/i,row.column.replace(new RegExp("by_var",'ig'), "").trim())
    }
    else if (/\bcol_var[0-9]+\b/i.test(row.column)){
      newRow.defineOptions = getPreOptions(objectData,'define_col_var').replace(/col_var<n>/i,row.column.replace(new RegExp("\\bcol_var\\b",'ig'), "").trim()).replace(/<n>/i,row.column.replace(new RegExp("col_var",'ig'), "").trim())
    }  else if (/\brow_var[0-9]+\b/i.test(row.column)){
      newRow.defineOptions = getPreOptions(objectData,'define_row_var' ).replace(/row_var<n>/i,row.column.replace(new RegExp("\\brow_var\\b",'ig'), "").trim()).replace(/<n>/i,row.column.replace(new RegExp("row_var",'ig'), "").trim())
    }else {
      switch(row.column.toLowerCase()){
        case 'blockid':
          newRow.defineOptions = getPreOptions(objectData,'define_blockid' );
        break;
        case 'blank_line':
          newRow.defineOptions = getPreOptions(objectData,'define_blank_line' );
        break;
        case 'avalue_title':
          newRow.defineOptions = getPreOptions(objectData,'define_avalue_title' );
        break;
        case 'avalue_sort':
          newRow.defineOptions = getPreOptions(objectData,'define_avalue_sort' );
        break;
        case 'avalue_indent':
          newRow.defineOptions = getPreOptions(objectData,'define_avalue_indent' );
        break;
        case 'avalue_char_s':
          newRow.defineOptions = getPreOptions(objectData,'define_avalue_char_s' );
        break;
        case 'avalue_char':
          newRow.defineOptions = getPreOptions(objectData,'define_avalue_char' );
        break;
        case 'avalue_num':
          newRow.defineOptions = getPreOptions(objectData,'define_avalue_num' );
        break;
        case 'avalue_label':
          newRow.defineOptions = getPreOptions(objectData,'define_avalue_label' );
        break;
        default:
          newRow.defineOptions = '';
        break;
      }
    }
    defineRows.push(newRow);
  });
  saveGlobalValue(ProcReportDefineIndex,JSON.stringify(defineRows));
  //saving default value for Compute
  let computeRows = [];
  tableRows.forEach((row)=>{
    let newRow = {...row,...{ "computeStatement" : ''}};
    if(typeof(newRow.computeStatement) !== 'undefined'){
      switch(row.column.toLowerCase()){
        case 'blockid':
          newRow.computeStatement = getPreOptions(objectData,'group_hf_blockid' );
        break;
        case 'avalue_title':
          newRow.computeStatement = getPreOptions(objectData,'group_hf_avalue_title' );
        break;
        case 'blank_line':
          newRow.computeStatement = getPreOptions(objectData,'group_hf_avalue_blank_line' );
        break;
        case 'avalue_label':
          newRow.computeStatement = getPreOptions(objectData,'group_hf_avalue_label' );
        break;
        default:
          newRow.computeStatement = '';
        break;
      }
    }
    computeRows.push(newRow);
  });
  saveGlobalValue(ProcReportComputeTableIndex,JSON.stringify(computeRows));
  //saving default value for Break
  let breakRows = [];
  tableRows.forEach((row)=>{
    let newRow = {...row,...{ "breakStatement" : ''}};
    breakRows.push(newRow);
  });
  saveGlobalValue(ProcReportBreakTableIndex,JSON.stringify(breakRows));
}

function getGBYValue(row){
  let gby= false;
  if(/\bby_var_sort[0-9]+\b/i.test(row.column)){
    gby = true;
  }
  return gby;
}

function ProcReportLayoutTemplate() {
  let ReportOptionIndex = 11;
  let ReportOutputFormatIndex = 45;
  let BlankValueIndex = 89;
  let ProcReportPagePerLineIndex = 91;
  let ProcReportAcrossColumnIndex = 92;
  let blankValue = getGlobalValue(BlankValueIndex);
  let reportOption = getGlobalValue(ReportOptionIndex);
  let pagePerLine = getGlobalValue(ProcReportPagePerLineIndex);
  let acrossColumn = getGlobalValue(ProcReportAcrossColumnIndex);
  let chkPdfFormat = false;
  let chkXlsFormat = false;
  let chkRtfFormat = false;
  let chkHtmlFormat = false;
  if(this.state.processFlowAction === DataOpsIdentifierConstants.R_Report_OP){
    chkRtfFormat = true;
    chkHtmlFormat = true;
  }else{
    chkRtfFormat = true;
  }
  let ProcReportTitleOptionType =  36;
  let ProcReportFootNoteOptionType =  37;

  if(getGlobalValue(ProcReportTitleOptionType) === "" && global.updateBlock){
    saveGlobalValue(ProcReportTitleOptionType,"TEXT");
  }

  if(getGlobalValue(ProcReportFootNoteOptionType) === "" && global.updateBlock){
    saveGlobalValue(ProcReportFootNoteOptionType,"TEXT");
  }

  if(getGlobalValue(ReportOutputFormatIndex) !== ""){
    chkHtmlFormat = false;
    chkPdfFormat = false;
    chkRtfFormat = false;
    chkXlsFormat = false;
    let formats = JSON.parse(getGlobalValue(ReportOutputFormatIndex));
    if(formats.indexOf("HTML") > -1){
      chkHtmlFormat = true;
    }
    if(formats.indexOf("PDF") > -1){
      chkPdfFormat = true;
    }
    if(formats.indexOf("RTF") > -1){
      chkRtfFormat = true;
    }
    if(formats.indexOf("XLS") > -1){
      chkXlsFormat = true;
    }
  }else{
    let formats = [];
    if(chkHtmlFormat)
    {
      formats.push("HTML");
    }
    if(chkPdfFormat)
    {
      formats.push("PDF");
    }
    if(chkXlsFormat)
    {
      formats.push("XLS");
    }
    if(chkRtfFormat)
    {
      formats.push("RTF");
    }
    saveGlobalValue(ReportOutputFormatIndex, JSON.stringify(formats));
  }

  var handleBlankValueChange = (event) =>{
    blankValue = event.target.value;
    saveGlobalValue(BlankValueIndex,blankValue);
    this.setState({...this.state.tableList});
  }
  var handlePagePerLineChange = (event) =>{
    pagePerLine = event.target.value;
    saveGlobalValue(ProcReportPagePerLineIndex,pagePerLine);
    this.setState({...this.state.tableList});
  }
  var handleAcrossColumnChange = (event) =>{
    acrossColumn = event.target.value;
    saveGlobalValue(ProcReportAcrossColumnIndex,acrossColumn);
    this.setState({...this.state.tableList});
  }
  var handleReportOptionChange = (event) =>{
    reportOption = event.target.value;
    saveGlobalValue(ReportOptionIndex,reportOption);
    this.setState({...this.state.tableList});
  }
  var handleReportOutputFormatChange = (event) =>{
    switch(event.target.id){
      case "report-outout-format-pdf":
      chkPdfFormat = event.target.checked;
      break;
      case "report-outout-format-xls":
      chkXlsFormat = event.target.checked;
      break;
      case "report-outout-format-html":
      chkHtmlFormat = event.target.checked;
      break;
      case "report-outout-format-rtf":
      chkRtfFormat = event.target.checked;
      break;
      default:
      break;
    }
    let formats = [];
    if(chkHtmlFormat)
    {
      formats.push("HTML");
    }
    if(chkPdfFormat)
    {
      formats.push("PDF");
    }
    if(chkXlsFormat)
    {
      formats.push("XLS");
    }
    if(chkRtfFormat)
    {
      formats.push("RTF");
    }
    saveGlobalValue(ReportOutputFormatIndex, JSON.stringify(formats));
    this.setState({...this.state.tableList});
  }

  return (
    <div className="process-option report-operation">
      <div className="field_group">
        <div className="container-fluid p_15">
        <div className="row">
        <div className="filter-area am-form-inline m-0 col-12">
        <div className="am-form-field ">
          <span className="m-left"><b className="m-right">Report Output Format </b></span>
          <input type="checkbox" id="report-outout-format-pdf" className="am-checkbox m-0" checked={chkPdfFormat} onChange={handleReportOutputFormatChange} />
          <label className="am-checkbox-label m-r-5-i" htmlFor="report-outout-format-pdf">PDF</label>
          <input type="checkbox" id="report-outout-format-xls" className="am-checkbox" checked={chkXlsFormat} onChange={handleReportOutputFormatChange}/>
          <label className="am-checkbox-label m-r-5-i" htmlFor="report-outout-format-xls">XLS</label>
          <input type="checkbox" id="report-outout-format-html" className="am-checkbox" checked={chkHtmlFormat} onChange={handleReportOutputFormatChange}/>
          <label className="am-checkbox-label m-r-5-i" htmlFor="report-outout-format-html">HTML</label>
          <input type="checkbox" id="report-outout-format-rtf" className="am-checkbox" checked={chkRtfFormat} onChange={handleReportOutputFormatChange}/>
          <label className="am-checkbox-label m-r-10-i" htmlFor="report-outout-format-rtf">RTF</label>
          <span className="m-left"><b className="m-right">Blank Value </b>  </span>
          <Input value={blankValue} onChange={handleBlankValueChange}/>
          <span className="m-left"><b className="m-right">Page (Row) </b>  </span>
          <Input value={pagePerLine} onChange={handlePagePerLineChange}/>
          <span className="m-left"><b className="m-right">Page (Columns) </b>  </span>
          <Input value={acrossColumn} onChange={handleAcrossColumnChange}/>
        </div>
      </div>
            </div>
          <div className="row">

            <div className="filter-area am-form-inline m-0 col-12 ">
              <label className="am-form-field">
                <span className="m-left">
                  <b className="m-right">{ this.state.processFlowAction === DataOpsIdentifierConstants.R_Report_OP ? "R Report Option" : "Proc Report Option"}</b></span>
                  <Input value={reportOption} onChange={handleReportOptionChange}/>
              </label>
            </div>
          </div>
        </div>
      </div>
      { this.state.processFlowAction !== DataOpsIdentifierConstants.R_Report_OP &&
      <TabStrip keepTabsMounted={true} selected={this.state.subtabSelected} onSelect={this.handleSelectSubTabPanel}>
        <TabStripTab title={this.state.processFlowAction === DataOpsIdentifierConstants.R_Report_OP ? "Header" : "Title"}>
          <Title table={this.state.tableList[0]} processFlowAction={this.state.processFlowAction}/>
        </TabStripTab>
        <TabStripTab title={this.state.processFlowAction === DataOpsIdentifierConstants.R_Report_OP ? "Footer" : "Footnotes"}>
          <FootNote table={this.state.tableList[0]} processFlowAction={this.state.processFlowAction}/>
        </TabStripTab>
        <TabStripTab title="Define">
          <Define table={this.state.tableList[0]} preOptions={this.state.preOptions}/>
        </TabStripTab>
        <TabStripTab title={this.state.processFlowAction === DataOpsIdentifierConstants.R_Report_OP ? "Group Header and Footer" :"Compute"}>
          <Compute table={this.state.tableList[0]} processFlowAction={this.state.processFlowAction} />
        </TabStripTab>
        <TabStripTab title="Break">
          <Break table={this.state.tableList[0]} />
        </TabStripTab>
        <TabStripTab title={ this.state.processFlowAction === DataOpsIdentifierConstants.R_Report_OP ? "Format" :"ODS"}>
          <Ods preOptions={this.state.preOptions} processFlowAction={this.state.processFlowAction} />
        </TabStripTab>
        <TabStripTab title="Style Template">
          <StyleTemplate preOptions={this.state.preOptions} dataOps={this.state.processFlowAction}/>
        </TabStripTab>
      </TabStrip>
        }
        { this.state.processFlowAction === DataOpsIdentifierConstants.R_Report_OP &&
      <TabStrip keepTabsMounted={true} selected={this.state.subtabSelected} onSelect={this.handleSelectSubTabPanel}>
        <TabStripTab title={this.state.processFlowAction === DataOpsIdentifierConstants.R_Report_OP ? "Header" : "Title"}>
          <Title table={this.state.tableList[0]} processFlowAction={this.state.processFlowAction}/>
        </TabStripTab>
        <TabStripTab title={this.state.processFlowAction === DataOpsIdentifierConstants.R_Report_OP ? "Footer" : "Footnotes"}>
          <FootNote table={this.state.tableList[0]} processFlowAction={this.state.processFlowAction}/>
        </TabStripTab>
        <TabStripTab title="Define">
          <Define table={this.state.tableList[0]} preOptions={this.state.preOptions}/>
        </TabStripTab>
        <TabStripTab title={this.state.processFlowAction === DataOpsIdentifierConstants.R_Report_OP ? "Group Header and Footer" :"Compute"}>
          <Compute table={this.state.tableList[0]} processFlowAction={this.state.processFlowAction} />
        </TabStripTab>
        <TabStripTab title={ this.state.processFlowAction === DataOpsIdentifierConstants.R_Report_OP ? "Format" :"ODS"}>
          <Ods preOptions={this.state.preOptions} processFlowAction={this.state.processFlowAction} />
        </TabStripTab>
        <TabStripTab title="Style Template">
          <StyleTemplate preOptions={this.state.preOptions} dataOps={this.state.processFlowAction}/>
        </TabStripTab>
      </TabStrip>
        }
    </div>
  );

};

export default ProcReportLayoutTemplate;
