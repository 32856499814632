import React from "react";
import RichGridTable from './../../../RichGridTable';

export class ProcessOptionGrid extends React.Component {
  constructor(props) {
    super(props);

    this.state={
      tableList: props.tableList
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if(JSON.stringify(nextProps) !== '') {
      this.setState({tableList: nextProps.tableList});
    }
  }

  TableDataBar = (table, tableIndex) => {
    return (
      <RichGridTable key={tableIndex} {...table} />
    )
  };

  render() {
      return (
        <div>
          { this.state.tableList.map(this.TableDataBar)}
        </div>
      );
    }
}
