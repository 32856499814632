import React from 'react';
import { connect } from 'react-redux';
import { PropTypes } from 'prop-types';
import { createSelector } from 'reselect';
import { setMessage } from '../../../../../actions/actionNotification';
import MessageTypeConst from "../../../../MessageTypeConst";
import { Input } from './StandardInput';

export const NewColumnNameRequired = ({ tableManager, value, data, column, colIndex, rowIndex }) => {
    if (typeof(tableManager) === 'undefined') {
      return (null)
    } else {
      return (
          <div className='rgt-cell-inner' style={{display: 'flex', alignItems: 'center', overflow: 'hidden'}}>
              <NewColumnNameRequiredClass dataItem={data} field={column.field} value={typeof(data[column.field]) !== 'undefined' ? data[column.field]: value} column={column} tableManager={tableManager}/>
          </div>
      )
    }
}

class NewColumnNameRequiredClass extends React.Component {

    handleChange(e) {
      if(e.target.value === ""){
        this.props.setMessage("Column Name required.", MessageTypeConst.WARNING_MESSAGE);
      }
      this.props.dataItem[this.props.field] = e.target.value;
      this.props.tableManager.rowsApi.setRows(this.props.tableManager.rowsApi.rows)
    }

    render() {
        return (
            <>
              <Input className="width-full" onChange={this.handleChange.bind(this)} value={this.props.dataItem[this.props.field]} length={40} />
            </>
        );
    }
}
