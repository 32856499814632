import React    from "react";
import { PropTypes } from "prop-types";
import { connect } from 'react-redux';
import { createSelector } from 'reselect';
import { POST_PARAM_BODY } from '../../../restClient/types';
import restClient from '../../../restClient';
import axios from 'axios';
import $ from 'jquery';
import MessageTypeConst from "../../MessageTypeConst";
import { setMessage } from '../../../actions/actionNotification';
import { setTreeViewData } from '../../../actions/actionTreeView';
import { addImportTable } from '../../../actions/actionProcessFlow';
import { addStudy } from '../../../actions/actionStudy';
import { updateProjectList } from '../../../actions/actionProject';
import Dialog from 'react-dialog';
import { Button } from './../DataOperation/common/Button/StandardButton';
import RichGridTable from "../RichGridTable";
import ASSESSMENT_TYPE from "./AssessmentType.js";
import { FlowSyncDetailsButton } from './FlowSyncDetailsButton';
import { GroupNameCell } from'./GroupNameCell';
import { CopyObjectStatusCell } from './CopyObjectStatusCell';
import { BasicCheckBoxCell } from "./../DataOperation/common/index.js";
import { DropDownList } from './../DataOperation/common/DropDown/StandardDropDownList';
import CopyAssessmentStatus from "./CopyAssessmentStatus";
import COPY_TYPE from "../../CopyType.js";
import { NewColumnNameCell } from './../DataOperation/common/InputBox/NewColumnNameCell';
import {manageProceeFlowGroupInTreeView, manageProcessInTreeView } from '../NavigationBarMenu/NavigationBarMenu.js';
import ConfirmBox from './../DataOperation/common/ConfirmBox';

class StudyAssessment extends React.Component {
    constructor(props){
        super(props);
        this.state ={
            copyInputTable:true,
            showReCopyDialog:false,
            closeEvent:props.closeEvent,
            sourceStudy:[{sourceStudyId:0, sourceStudyName:'',sourceSchemaName:''}],
            selectedSourceStudy:{sourceStudyId:0, sourceStudyName:'',sourceSchemaName:''},
            assessmentNode:props.assessmentNode,
            reCopyGroupRows: [{selected:"",sourceGroupId:0 ,sourceGroupName:'',targetGroupName:''}],
            reCopyGroupColumnProperty: [
              { field: "selected", show: false},
              { field: "sourceGroupId",show: false},
              { field: "sourceGroupName", title:"Source Group"},
              { field: "targetGroupName", title: "Group Name", cell :NewColumnNameCell},
            ],
            groupRows:[{selected:false,columnId:0, processStudyId:0, processGroupId:0, sourceStudyId:0, sourceGroupId:0,
                sourceFlowId:0,processGroupName:'',processFlowName:'', processFlowId:0, status:0,sourceGroupName:'',sourceFlowName:'',closeEvent:props.closeEvent}],
            groupColumnProperty:[
                { field: "selected", title:"", width:"20px", orderIndex:0, cell: BasicCheckBoxCell, sortable:false,},
                { field:"columnId", show :false},
                { field: "processGroupId",title:"Flow Sync Details",cell:FlowSyncDetailsButton, orderIndex:5},
                { field: "sourceStudyId",show: false},
                { field: "sourceGroupId",show: false},
                { field: "sourceFlowId",show: false},
                { field: "processGroupName", title:"Target Process Group", width:"250px", orderIndex:1, cell: GroupNameCell},
                { field: "processFlowName", title: "Process Flow", width:"200px", show: false, orderIndex:3},
                { field: "status",title:"Status (in comparison with source)",cell:CopyObjectStatusCell,  width:"250px", orderIndex:4},
                { field: "processFlowId", show :false},
                { field: "processStudyId", show :false},
                { field: "sourceGroupName", title:"Source Process Group", width:"250px", orderIndex:2, cell: GroupNameCell},
                { field: "sourceFlowName",show: false},
                { field: "closeEvent",show: false},
            ],
            title: this.getTitle(props.assessmentNode),
            studyComparisonData:[{
            sourceStudyId:0,
            copyAssessmentResponse:[{selected:false,columnId:0, processStudyId:0, processGroupId:0, sourceStudyId:0, sourceGroupId:0,
                sourceFlowId:0,processGroupName:'',processFlowName:'', processFlowId:0, status:0,sourceGroupName:'',sourceFlowName:''}]
            }]
        }
    }

    componentDidMount(){
        let request = {
            copyAssessmentType : this.state.assessmentNode.copyAssesmentType,
            processFlowGroups:this.getListOfGroupsForassessment(),
            sourceSchemaName:'',
            sourceStudyId:0
        };
        this.fetchAssessmentResult(request);

    }

    getListOfGroupsForassessment = () => {
        let groups = [];
        switch(this.state.assessmentNode.copyAssesmentType){
            case ASSESSMENT_TYPE.STUDY_ASSESSMENT:
            return this.getAllGroupDetails();
            case ASSESSMENT_TYPE.GROUP_ASSESSMENT:
            break;
            default:
            break;
        }
        return groups;
    }

    getTitle = (assessmentNode) => {
        let title = "";
        if(assessmentNode === null || typeof(assessmentNode) === "undefined"){
            return;
        }
        switch(assessmentNode.copyAssesmentType){
            case ASSESSMENT_TYPE.GROUP_ASSESSMENT:
                title = `${assessmentNode.name} Comparison`;
                break;
            case ASSESSMENT_TYPE.STUDY_ASSESSMENT:
                title = `${assessmentNode.name} Comparison`;
                break;
            default:
                break;

        }
        return title;
    }

    getAllGroupDetails = () => {
        let treeView = this.props.treeView.data;
        let assessmentNode = this.state.assessmentNode;
        let groupObj = {
            processGroupId:0,
            studyId:0,
            processGroupName:'',
            processFlow:[]
        };
        if(assessmentNode === null || typeof(assessmentNode) === "undefined"){
            return [groupObj];
        }
        let allgroups = [];
        let selectedProject = treeView.children.filter(proj => proj.projectId === assessmentNode.projectId);
        if(selectedProject.length > 0){
            let selectedStudy = selectedProject[0].children.filter(study => study.study.studyId === assessmentNode.studyId);
            if(selectedStudy.length > 0){
                let rootprocessFlowNode = selectedStudy[0].children.filter(node => node.name === "ProcessFlows");
                if(rootprocessFlowNode.length > 0){
                   rootprocessFlowNode[0].children.forEach((groupClassifierNode)=>{
                    groupClassifierNode.children.forEach((groupNode)=>{
                            let group = {
                                processGroupId : groupNode.processGroupId,
                                studyId : groupNode.studyId,
                                processGroupName : groupNode.name,
                                processFlow : []
                            };
                            groupNode.children.forEach((flow)=>{
                                let process = {
                                    processId:flow.process.processId,
                                    studyId:flow.process.studyId,
                                    processName:flow.process.processName,
                                    processGroupId:groupNode.processGroupId
                                }
                                group.processFlow.push(process);
                            });
                            allgroups.push(group);
                        });
                    });
                }
            }
        }
        return allgroups;
    }

    closeDialog = (event) => {
        this.state.closeEvent();
    }

    handleSourceStudyChange = (event) => {
        let that = this;
        let studyComparisonData = that.state.studyComparisonData;
        let selectedStudyData = studyComparisonData.filter(item=>item.sourceStudyId === event.target.value.sourceStudyId);
        if(selectedStudyData.length > 0){
            let groupRows = that.state.groupRows;
            groupRows.length = 0;
            groupRows.push(...selectedStudyData[0].copyAssessmentResponse);
            this.setState({selectedSourceStudy:event.target.value, groupRows:groupRows});
        }else{
            this.setState({selectedSourceStudy:event.target.value});
            let request = {
                copyAssessmentType : this.state.assessmentNode.copyAssesmentType,
                processFlowGroups:this.getListOfGroupsForassessment(),
                sourceSchemaName:event.target.value.sourceSchemaName,
                sourceStudyId:event.target.value.sourceStudyId
            };
            this.fetchAssessmentResult(request);
        }
    }

    validateGroupCopy = () => {
        var that = this;
        let isGroupValid  = true;
        let assessmentNode = this.state.assessmentNode;
        if(typeof(assessmentNode) === "undefined" || assessmentNode === null){
            return false;
        }
        let treeView = that.props.treeView.data;
        let selectedProject = treeView.children.filter(proj => proj.projectId === assessmentNode.projectId);
        if(selectedProject.length > 0){
            let selectedStudy = selectedProject[0].children.filter(study => study.study.studyId === assessmentNode.studyId);
            if(selectedStudy.length > 0){
                let rootprocessFlowNode = selectedStudy[0].children.filter(node => node.name === "ProcessFlows");
                if(rootprocessFlowNode.length > 0){
                    let groupName = '';
                    that.state.reCopyGroupRows.forEach((row)=>{
                        //let selectedGroup = rootprocessFlowNode[0].children.filter(grp => grp.name.toLowerCase() === row.targetGroupName.toLowerCase());
                        let selectedGroup = null;
                        rootprocessFlowNode[0].children.forEach((classifier) => {
                            selectedGroup =  classifier.children.filter( grp =>
                                grp.name.toLowerCase() === row.targetGroupName.toLowerCase());
                                if(selectedGroup !== null && selectedGroup.length > 0){
                                    groupName = groupName + `'` + row.targetGroupName +`', `;
                                }
                        })
                    });
                    if(groupName !== ''){
                        groupName = groupName.trim().substr(0, groupName.trim().length -1 );
                        that.props.setMessage(`Process Group(s) ${groupName} already exists in selected target study. Please specify new process group name.`, MessageTypeConst.WARNING_MESSAGE)
                        isGroupValid = false;
                        return false;

                    }
                }
            }
        }
        return isGroupValid;
    }

    executeGroupCopy = () => {
        if(!this.validateGroupCopy()){
            return;
        }
        $(".loader").show();
        let that = this;
        let requestObj = [];
        this.state.reCopyGroupRows.forEach((row)=>{
            let selectedRow = that.state.groupRows.filter(grpRow=> grpRow.selected && grpRow.status === CopyAssessmentStatus.GROUP_NEWLY_ADDED &&
                grpRow.sourceGroupId === row.sourceGroupId);
            if(selectedRow.length > 0){
                let request = {
                    sourceProjectId : 0,
                    sourceStudyId : selectedRow[0].sourceStudyId,
                    sourceGroupId : selectedRow[0].sourceGroupId,
                    sourceFlowId : 0,
                    targetProjectId : that.state.assessmentNode.projectId,
                    targetStudyId : that.state.assessmentNode.studyId,
                    targetGroupId : 0,
                    targetFlowId : 0,
                    copyType :COPY_TYPE.COPY_GROUP,
                    targetNewName:row.targetGroupName,
                    groupNames:[],
                    sourceSchemaName:that.state.selectedSourceStudy.sourceSchemaName,
                    targetSchemaName:that.state.assessmentNode.schemaName,
                    processSyncFlag: 0,
                    copyTables:that.state.copyInputTable,
                }
                requestObj.push(request);
            }

        });
        axios.all([restClient(POST_PARAM_BODY, 'recopy', {data : requestObj}, {'Tenant-ID': this.state.assessmentNode.schemaName})])
        .then(axios.spread(function (response)  {
            if (typeof(response.data) !== 'undefined' && response.data.message != null && response.data.messageType !==  MessageTypeConst.SUCCESS_MESSAGE) {
                that.props.setMessage(response.data.message, response.data.messageType);
            }
            else if (typeof(response.data) !== 'undefined' && response.data !== null && typeof(response.data) !== 'undefined'
            && response.data.processFlowGroup !== null && typeof(response.data.processFlowGroup) !== 'undefined' && response.data.processFlowGroup.length > 0) {
                that.updateTreeViewBasedOnResponse(that.state.assessmentNode.projectId, that.state.assessmentNode.studyId, that.state.assessmentNode.schemaName, response, true);
            }
            that.closeDialog();
            $(".loader").hide();
        })).catch(error => {
            $(".loader").hide();
            that.props.setMessage("Error occurred while copying group.", MessageTypeConst.ERROR_MESSAGE);
        });


    }

    updateTreeViewBasedOnResponse = (targetProjectId, targetStudyId, targetSchemaName, response, addGroup) => {
        let that = this;
        let treeView = that.props.treeView.data;
        treeView.children.forEach((proj, projIndex) => {
            if (proj.projectId === targetProjectId) {
                treeView.children[projIndex].children.forEach((study, studyIndex) => {
                    if (study.study.studyId === targetStudyId) {
                        if (study.study.sourceTable === null || typeof (study.study.sourceTable) === "undefined") {
                            study.study.sourceTable = [];
                        }
                        if (study.study.metaTable === null || typeof (study.study.metaTable) === "undefined") {
                            study.study.metaTable = [];
                        }
                        if (study.study.studyDocuments === null || typeof (study.study.studyDocuments) === "undefined") {
                            study.study.studyDocuments = [];
                        }
                        treeView.children[projIndex].children[studyIndex].children.forEach((node, nodeIndex) => {
                            if (node.name === "ProcessFlows") {
                                node.children.forEach((classifierNode) => {

                                if(addGroup){
                                    response.data.processFlowGroup.forEach((newGroup) => {
                                        if (classifierNode.classifierId === newGroup.classifier) {
                                        var processFlows = [];
                                        if (typeof (newGroup.processFlow) !== "undefined"
                                            && newGroup.processFlow !== null
                                            && newGroup.processFlow.length > 0) {
                                            newGroup.processFlow.forEach((updatedFlow) => {
                                                processFlows.push(manageProcessInTreeView(Object.assign({}, updatedFlow, { selected: false }), targetProjectId, targetSchemaName, study.study.studyName));
                                            });
                                        }
                                        classifierNode.children.push(manageProceeFlowGroupInTreeView(newGroup, processFlows, study.study));

                                        }
                                    });
                                }else{
                                    response.data.processFlowGroup.forEach((updatedGroup) => {
                                        classifierNode.children.forEach((group, groupIndex) => {
                                            if(group.processGroupId === updatedGroup.processGroupId){
                                                if(typeof(updatedGroup.processFlow) !== "undefined"
                                                    && updatedGroup.processFlow !== null
                                                    && updatedGroup.processFlow.length > 0){
                                                        updatedGroup.processFlow.forEach(updatedFlow => {
                                                            let selectedFlow = classifierNode.children[groupIndex].children.filter(flow => flow.process.processId === updatedFlow.processId);
                                                            if(selectedFlow === null || selectedFlow.length <= 0 ){
                                                                classifierNode.children[groupIndex].children.push(manageProcessInTreeView(Object.assign({}, updatedFlow, { selected: false }), targetProjectId, targetSchemaName, study.study.studyName));
                                                            }
                                                        });
                                                    }
                                            }
                                        })
                                    });
                                }
                                });
                            }
                            else if (node.name === "Source Table" &&
                                response.data.sourceTables !== null && typeof (response.data.sourceTables) !== 'undefined'
                                && response.data.sourceTables.length > 0) {
                                response.data.sourceTables.forEach((srcTable) => {
                                    let newImportTable = Object.assign({
                                    name: srcTable.srcTableName, projectId: targetProjectId,
                                        studyId: study.study.studyId, cssClassName: 'manage-study-edit-source-table', schemaName: study.study.schemaName,
                                        tableType: 0, tag: 'open-data-viewer', tableName: srcTable.tableName
                                    });
                                    treeView.children[projIndex].children[studyIndex].children[nodeIndex].children.push(newImportTable);
                                    let newTable = Object.assign({
                                    srcTableName: srcTable.srcTableName,
                                        tableId: srcTable.tableId, tableName: srcTable.tableName, tableVersion: srcTable.tableVersion,
                                        versionLimit: srcTable.versionLimit, importLog: '', tableType: 0, timestamp: srcTable.timestamp
                                    });
                                    let sourceTable = study.study.sourceTable.filter(table => table.srcTableName !== srcTable.srcTableName);
                                    sourceTable.splice(sourceTable.length, 0, newTable);
                                    study.study.sourceTable.splice(0);
                                    study.study.sourceTable.splice(0, 0, ...sourceTable);
                                    that.props.addImportTable(newImportTable);
                                });
                            }
                            else if (node.name === "Meta Table" &&
                                response.data.metaTables !== null && typeof (response.data.metaTables) !== 'undefined'
                                && response.data.metaTables.length > 0) {
                                response.data.metaTables.forEach((metaDataTable) => {
                                    let newImportTable = Object.assign({
                                    name: metaDataTable.srcTableName, projectId: targetProjectId,
                                        studyId: study.study.studyId, cssClassName: 'manage-study-edit-meta-table', schemaName: study.study.schemaName, tableType: 1,
                                        tag: 'open-data-viewer', tableName: metaDataTable.tableName
                                    });
                                    treeView.children[projIndex].children[studyIndex].children[nodeIndex].children.push(newImportTable);
                                    let metaTable = study.study.metaTable.filter(table => table.srcTableName !== metaDataTable.srcTableName);
                                    let newTable = Object.assign({
                                    srcTableName: metaDataTable.srcTableName,
                                        tableId: metaDataTable.tableId, tableName: metaDataTable.tableName, tableVersion: metaDataTable.tableVersion,
                                        versionLimit: metaDataTable.versionLimit, importLog: '', tableType: 1, timestamp: metaDataTable.timestamp
                                    });
                                    metaTable.splice(metaTable.length, 0, newTable);
                                    study.study.metaTable.splice(0);
                                    study.study.metaTable.splice(0, 0, ...metaTable);
                                    that.props.addImportTable(newImportTable);
                                });
                            }
                            else if (node.name === "Documents" &&
                                response.data.documents !== null && typeof (response.data.documents) !== 'undefined'
                                && response.data.documents.length > 0) {
                                response.data.documents.forEach((document) => {
                                    let newDoc = Object.assign({
                                    name: document.filename, projectId: targetProjectId,
                                        studyId: study.study.studyId, cssClassName: 'manage-study-edit-documents',
                                        fileId: document.fileId, path: document.filepath,
                                        schemaName: study.study.schemaName, tableType: 2
                                    });
                                    if (newDoc !== null) {
                                        treeView.children[projIndex].children[studyIndex].children[nodeIndex].children.push(newDoc);
                                        let tableList = treeView.children[projIndex].children[studyIndex].children[nodeIndex].children.slice();
                                        let importTableIndex = tableList.length;
                                        treeView.children[projIndex].children[studyIndex].children[nodeIndex].children.forEach((table, index) => {
                                            if (table.name === document.filename) {
                                                importTableIndex = index;
                                            }
                                        });
                                        tableList = tableList.filter(table => table.name !== document.filename);
                                        tableList.splice(importTableIndex, 0, newDoc);
                                        treeView.children[projIndex].children[studyIndex].children[nodeIndex].toggled = true;
                                        treeView.children[projIndex].children[studyIndex].children[nodeIndex].children.splice(0);
                                        treeView.children[projIndex].children[studyIndex].children[nodeIndex].children.splice(0, 0, ...tableList);
                                        if (newDoc.tableType === 2) {
                                            let documents = study.study.studyDocuments.filter(table => table.name !== document.filename);
                                            ;
                                            documents.splice(documents.length, 0, document);
                                            study.study.studyDocuments.splice(0);
                                            study.study.studyDocuments.splice(0, 0, ...documents);
                                        }
                                    }
                                });
                            }
                        });
                    }
                });
            }
        });
        that.props.setTreeViewData(treeView);
    }

    fetchAssessmentResult = (request) => {
        let that = this;
        $(".loader").show();
        axios.all([restClient(POST_PARAM_BODY, 'copy/assessment', { data: request }, { 'Tenant-ID': this.state.assessmentNode.schemaName })])
            .then(axios.spread(function (response) {
                if (response.data !== null && typeof (response.data) !== "undefined" &&
                    response.data.copyAssessmentResponse !== null && typeof (response.data.copyAssessmentResponse) !== "undefined" && Array.isArray(response.data.copyAssessmentResponse)) {
                    let groupRows = that.state.groupRows;
                    let sourceStudy = that.state.sourceStudy;
                    let selectedSourceStudy = that.state.selectedSourceStudy;
                    if (response.data.sourceStudy !== null && typeof (response.data.sourceStudy) !== "undefined" && Array.isArray(response.data.sourceStudy)) {
                        if (response.data.copyAssessmentResponse.length > 0) {
                            sourceStudy.length = 0;
                            response.data.sourceStudy.forEach((study) => {
                                let srcStudy = {
                                    sourceStudyId: study.studyId, sourceStudyName: study.studyName, sourceSchemaName: study.schemaName
                                };
                                sourceStudy.push(srcStudy);
                            });
                        }
                    }
                    if (response.data.selectedStudyId !== null && typeof (response.data.selectedStudyId) !== "undefined" && response.data.selectedStudyId !== 0) {
                        if (sourceStudy.length > 0) {
                            let selectedStudy = sourceStudy.filter(item => item.sourceStudyId === response.data.selectedStudyId);
                            if (selectedStudy.length > 0) {
                                selectedSourceStudy = selectedStudy[0];
                            }
                        }
                    }
                    if (response.data.copyAssessmentResponse.length > 0) {
                        groupRows.length = 0;
                        response.data.copyAssessmentResponse.forEach((flow, index) => {
                            let row = {
                                selected: true,
                                columnId: index,
                                processStudyId: that.state.assessmentNode.studyId,
                                processGroupId: flow.processGroupId,
                                sourceStudyId: flow.sourceStudyId,
                                sourceGroupId: flow.sourceGroupId,
                                sourceFlowId: flow.sourceFlowId,
                                processGroupName: flow.processGroupName,
                                processFlowName: flow.processFlowName,
                                status: flow.status,
                                processFlowId: flow.processFlowId,
                                sourceGroupName: flow.sourceGroupName,
                                sourceFlowName: flow.sourceFlowName,
                                closeEvent:that.props.closeEvent
                            };
                            groupRows.push(row);
                        });
                    }
                    let studyComparisonData = that.state.studyComparisonData;
                    let copyAssessmentResponse = [];
                    copyAssessmentResponse.push(...groupRows);
                    studyComparisonData.push({
                        sourceStudyId:response.data.selectedStudyId,
                        copyAssessmentResponse:copyAssessmentResponse
                    });
                    that.setState({ groupRows: groupRows, sourceStudy: sourceStudy, selectedSourceStudy: selectedSourceStudy,studyComparisonData:studyComparisonData });
                }
                if(response.data.message !== "" && response.data.message !== null){
                    that.props.setMessage(response.data.message, response.data.messageType);
                }
                if(parseInt(response.data.messageType, 10) === parseInt(MessageTypeConst.WARNING_MESSAGE,10)){
                    that.closeDialog();
                }
                $(".loader").hide();
            })).catch(error => {
                $(".loader").hide();
                that.props.setMessage("Error occurred while impact assessment.", MessageTypeConst.ERROR_MESSAGE);
            });
    }

    closeReCopyDialog = () =>{
        this.setState({showReCopyDialog:false});
    }

    openReCopyDialog = () =>{
        let newSourceGroups =  this.state.groupRows.filter(row=>row.selected && row.status === CopyAssessmentStatus.GROUP_NEWLY_ADDED);
        if(newSourceGroups.length > 0){
            let reCopyGroupRows = this.state.reCopyGroupRows;
            reCopyGroupRows.length = 0;
            newSourceGroups.forEach((item)=>{
                reCopyGroupRows.push({selected:true,sourceGroupId:item.sourceGroupId ,sourceGroupName:item.sourceGroupName,targetGroupName:item.sourceGroupName});
            });
            this.setState({showReCopyDialog:true, reCopyGroupRows: reCopyGroupRows});
        }else{
            ConfirmBox.alert("No new source group found or selected to copy.")
        }
    }

    handleCopyInputTableChange = (event) => {
        let copyInputTable = true;
        switch(event.target.id){
            case "optImportTrue":
            copyInputTable = true;
            break;
            case "optImportFalse":
            copyInputTable = false;
            break;
            default:
            break;
        }
        this.setState({copyInputTable:copyInputTable});
    }

    render(){
        return(
            <div className="data-properties">
                    <Dialog className={this.className} title={this.state.title} width="75%" height="auto" modal={true} onClose={this.closeDialog} buttons={[<Button primary={true} onClick={this.openReCopyDialog} key="btn-add-group"> Copy Newly Added Group(s)</Button>,<Button onClick={this.closeDialog} key="btn-cancel"> Cancel</Button>]}>
                      <div className="data-properties" >
                        <div className="m-t-10 m-b-10 richgrid-height-200">
                          <label className="am-form-field row">
                            <span className="m-l-t-15-5">Source Study:</span>
                            <DropDownList className="col-8" data={this.state.sourceStudy}  textField={'sourceStudyName'} dataItemKey={'sourceSchemaName'} value={this.state.selectedSourceStudy} onChange={this.handleSourceStudyChange}/>
                          </label>
                          <RichGridTable id={"copy-assessment-grid-table"} rows={this.state.groupRows} selectedField="selected" columnProperty={this.state.groupColumnProperty} filterable={false}/>
                        </div>
                      </div>
                    </Dialog>
                    {
                        this.state.showReCopyDialog &&
                        <Dialog className={this.className} width="75%" height="auto" modal={true} title={"Copy New Groups"} onClose={this.closeReCopyDialog} buttons={[<Button primary={true} onClick={this.executeGroupCopy} key="btn-copy"> Copy</Button>,<Button onClick={this.closeReCopyDialog} key="btn-cancel"> Cancel</Button>]}>
                            <div className="data-properties" >
                              <div className="m-t-10 m-b-10">
                                <fieldset>
                                  <div className="field_group m-t-10 m-b-10">
                                    <legend>While copying</legend>
                                    <div>
                                        <label><input type="radio" name="optImport" value="Source" onChange={this.handleCopyInputTableChange}
                                        checked={this.state.copyInputTable} id="optImportTrue"/> Copy input table/document if not exists</label>
                                    </div>
                                    <div>
                                        <label><input type="radio" name="optImport" value="Source" onChange={this.handleCopyInputTableChange}
                                        checked={!this.state.copyInputTable} id="optImportFalse"/> Skip copying input table/document if not exists (child blocks will be in Invalid state)</label>
                                    </div>
                                  </div>
                                </fieldset>
                                <p>
                                    <b>Note: </b> While copying LINK blocks, will check compare source block name from which block is created. If block name not found, then LINK block will be skipped and all its child blocks will be in Invalid state.
                                </p>
                                <fieldset>
                                  <div className="field_group m-t-10 m-b-10">
                                    <legend>Copying Group As</legend>
                                    <RichGridTable id={"group-compare-grid-table"} rows={this.state.reCopyGroupRows} columnProperty={this.state.reCopyGroupColumnProperty} />
                                  </div>
                                </fieldset>
                              </div>
                            </div>
                          </Dialog>
                      }
            </div>);
    }


}

const mapStateToProps = createSelector(
    state => state.treeView,
    state => state.project.projectList,
    (treeView, projectList) => ({
        treeView  ,
        projectList  })
  );

  const mapActionsToProps = {
    setMessage: setMessage,
    setTreeViewData: setTreeViewData,
    addImportTable: addImportTable,
    addStudy: addStudy,
    updateProjectList: updateProjectList
  }
  Dialog.propTypes = {
      height: PropTypes.oneOfType([
          PropTypes.string
      ]),
      width: PropTypes.oneOfType([
          PropTypes.string
      ])
  }
  export default connect(mapStateToProps, mapActionsToProps)(StudyAssessment);
