import React    from "react";
import axios from 'axios';
import { connect } from 'react-redux';
import { createSelector } from 'reselect';
import $ from 'jquery';
import moment from 'moment';
import template from "./CreateTask.jsx";
import restClient from './../../../../restClient';
import { GET, CREATE } from './../../../../restClient/types';
import {setMessage} from './../../../../actions/actionNotification';
import MessageTypeConst from "./../../../MessageTypeConst";
import TaskConstantType from './../TaskConstantType';

const nodeList = [
  {nodeName: "Study", nodeId: 1},
  {nodeName: "Process Group", nodeId: 2},
  {nodeName: "Process Flow", nodeId: 3},
  {nodeName: "Block", nodeId: 4}
]

class CreateTask extends React.Component {
  constructor(props) {
    super(props);

    let selectedStudy = '';
    let selectedNode = '';
    let selectedProcessGroup = '';
    let selectedProcessFlow = '';
    let selectedBlock = '';
    if (typeof(this.props.node) !== 'undefined' && typeof(this.props.node.cssClassName) !== 'undefined') {
      if (this.props.node.cssClassName === 'manage-study-property') {
        selectedNode = {nodeName: "Study", nodeId: 1};
        selectedStudy = {studyId: this.props.node.study.studyId, studyName: this.props.node.study.studyName, schemaName:  this.props.node.study.schemaName};
      } else if (this.props.node.cssClassName === 'manage-study-process-flow') {
        selectedNode = {nodeName: "Process Group", nodeId: 2};
        this.props.treeView.children.filter(project => project.projectId === this.props.node.projectId).forEach(project => {
          project.children.filter(study => study.study.schemaName === this.props.node.schemaName).forEach(study => {
            selectedStudy = {studyId: study.study.studyId, studyName: study.study.studyName, schemaName:  study.study.schemaName};
          })
        })

        selectedProcessGroup = {processGroupName: this.props.node.name, processGroupId: this.props.node.processGroupId}
      } else if (this.props.node.cssClassName === 'manage-study-process') {
        selectedNode = {nodeName: "Process Flow", nodeId: 3};
        this.props.treeView.children.filter(project => project.projectId === this.props.node.projectId).forEach(project => {
          project.children.filter(study => study.study.schemaName === this.props.node.schemaName).forEach(study => {
            selectedStudy = {studyId: study.study.studyId, studyName: study.study.studyName, schemaName:  study.study.schemaName};

            study.children.filter(studyElement => studyElement.name === 'ProcessFlows').forEach(studyElement => {
              studyElement.children.forEach(classifier => {
                classifier.children.filter(processGroup => processGroup.processGroupId === this.props.node.process.processGroupId).forEach(processGroup => {
                    selectedProcessGroup = {processGroupName: processGroup.name, processGroupId: processGroup.processGroupId}
                })
              })
            })
          })
        })
        selectedProcessFlow = {processFlowName: this.props.node.name, processId: this.props.node.process.processId}
      }
    } else if (typeof(this.props.node.blockId) !== 'undefined') {
      selectedNode = {nodeName: "Block", nodeId: 4};
      selectedBlock = {blockName: this.props.node.blockName, blockId: parseInt(this.props.node.blockId)}
      this.props.treeView.children.forEach(project => {
        project.children.filter(study => study.study.schemaName === this.props.node.schemaName).forEach(study => {
          selectedStudy = {studyId: study.study.studyId, studyName: study.study.studyName, schemaName:  study.study.schemaName};

          study.children.filter(studyElement => studyElement.name === 'ProcessFlows').forEach(studyElement => {
            studyElement.children.forEach(classifier => {
              classifier.children.forEach(processGroup => {
                processGroup.children.filter(processFlow => processFlow.process.processId === this.props.node.blockProcessId).forEach(processFlow => {
                  selectedProcessGroup = {processGroupName: processGroup.name, processGroupId: processGroup.processGroupId}
                  selectedProcessFlow = {processFlowName: processFlow.name, processId: processFlow.process.processId}
                })
              })
            })
          })
        })
      })
    }

    this.state = {
      node: this.props.node,
      nodeList: nodeList,
      studyList: [],
      processGroupList: [],
      processFlowList: [],
      blockList: [],
      statusList: [],
      userList: [],
      selectedNode: selectedNode,
      selectedStudy: selectedStudy,
      selectedProcessGroup: selectedProcessGroup,
      selectedProcessFlow: selectedProcessFlow,
      selectedBlock: selectedBlock,
      selectedStatus: {statusName: "Open", statusId: 1},
      selectedUser: {},
      createTaskDialogStatus: false,
      title: '',
      description: '',
      mode: typeof(props.mode) !== 'undefined' ? props.mode : '',
      commentDescription: '',
      commentsList: [],
      taskPrefix: 'Create'
    }

    TaskConstantType.STATUSLIST.forEach(status => {
      if (this.state.mode == 'edit' && props.data.status === status[0]) {
        this.state.selectedStatus = {statusId: status[0], statusName: status[1]}
      }
      this.state.statusList.push({statusId: status[0], statusName: status[1]})
    })

    if (this.state.mode == 'edit') {
      this.state.title = props.data.title
      this.state.description = props.data.description
      this.state.selectedUser = {userId: props.data.userId, userName: props.data.userName}
      this.state.commentsList = props.data.commentsList
      this.state.taskPrefix = 'Update'
    }

    this.getDropdownList = this.getDropdownList.bind(this);
    this.handleSaveTaskClick = this.handleSaveTaskClick.bind(this);
  }

  createUserTask = (headers, params) => restClient(CREATE, 'task/add', params, headers);

  updateUserTask = (headers, params) => restClient(CREATE, 'task/update', params, headers);

  createTaskComment = (headers, params) => restClient(CREATE, 'task/comment/add', params, headers);

  fetchUserList = (headers, params) => restClient(GET, 'users/list', params, headers);

  handleSaveTaskClick = (event) => {
    let that = this;
    let params = {
        studyId: 0,
        processGroupId: 0,
        processId: 0,
        blockId: 0,
        title: this.state.title,
        description: this.state.description,
        status: this.state.selectedStatus.statusId,
        userId: this.state.selectedUser.userId,
        userName: this.state.selectedUser.userName
    }

    if (this.state.selectedNode.nodeId === 1) {
      params.studyId = this.state.selectedStudy.studyId
    }

    if (this.state.selectedNode.nodeId === 2) {
      params.processGroupId = this.state.selectedProcessGroup.processGroupId
    }

    if (this.state.selectedNode.nodeId === 3) {
      params.processId = this.state.selectedProcessFlow.processId
    }

    if (this.state.selectedNode.nodeId === 4) {
      params.blockId = parseInt(this.state.selectedBlock.blockId)
    }

    if (this.state.mode === 'edit') {
      params = Object.assign({}, params, {taskId: this.props.data.taskId})
      let paramsComment = {
        taskId: this.props.data.taskId,
        commentDescription: this.state.commentDescription.trim(),
        userId: this.state.selectedUser.userId,
        userName: this.state.selectedUser.userName
      }
      $(".loader").show();
      axios.all([this.state.commentDescription.trim() === '' ?
        (this.updateUserTask({'Tenant-ID': this.state.selectedStudy.schemaName}, {data: [params]})):
        (this.updateUserTask({'Tenant-ID': this.state.selectedStudy.schemaName}, {data: [params]}), this.createTaskComment({'Tenant-ID': this.state.selectedStudy.schemaName}, {data: paramsComment}))
      ])
            .then(axios.spread((responseTask, responseTaskComment) => {
              if (typeof(responseTask.data) !== 'undefined') {
                that.props.setMessage("Task "+that.state.taskPrefix.toLocaleLowerCase()+"d successfully", MessageTypeConst.SUCCESS_MESSAGE)
                $(".loader").hide();
                that.props.closeEvent(event, true);
              } else {
                  $(".loader").hide();
              }
            })).catch(error => {
                $(".loader").hide();
                that.props.setMessage("Failed to "+that.state.taskPrefix.toLocaleLowerCase()+" task.",MessageTypeConst.ERROR_MESSAGE)
            })
    } else {
      $(".loader").show();
      axios.all([this.createUserTask({'Tenant-ID': this.state.selectedStudy.schemaName}, {data: params})])
            .then(axios.spread((response) => {
              if (typeof(response.data) !== 'undefined') {
                that.props.setMessage("Task "+that.state.taskPrefix.toLocaleLowerCase()+"d successfully", MessageTypeConst.SUCCESS_MESSAGE)
                $(".loader").hide();
                that.props.closeEvent(event, true);
              } else {
                $(".loader").hide();
              }
            })).catch(error => {
                $(".loader").hide();
                that.props.setMessage("Failed to "+that.state.taskPrefix.toLocaleLowerCase()+" task.",MessageTypeConst.ERROR_MESSAGE)
            })
    }


  }

  handleTaskTitleChange = (event) => {
    this.setState({title: event.target.value})
  }

  handleDescriptionChange = (event) => {
    this.setState({description: event})
  }

  enterKeyPressForSaveTask = (event) => {

  }

  componentDidMount = () => {
    let that = this;
    this.setState({studyList: this.getDropdownList('study'), processGroupList: this.getDropdownList('processGroup')}, () => {
      if (this.state.selectedProcessFlow !== '') {
        this.setState({processFlowList: this.getDropdownList('processFlow')});
      }

      if (this.state.selectedBlock !== '') {
        this.getDropdownList('block');
      }
    })

    $(".loader").show();
    axios.all([this.fetchUserList({'Tenant-ID': "master"}, {})])
        .then(axios.spread((responseUser, responseGroup) => {
          if (typeof(responseUser.data.message) === 'undefined') {
            if (typeof(responseUser.data) !== 'undefined') {
              let userList = [];
              responseUser.data.forEach(user => {
                userList.push({
                  userId: user.userId,
                  userName: user.firstName + ' ' + user.lastName
                })
              })
              that.setState({userList: userList, selectedUser: userList.length > 0 &&  typeof(this.state.selectedUser.userId) === 'undefined' ? userList[0] : this.state.selectedUser})
            }
          } else {
              that.props.setMessage(responseUser.data.message, responseUser.data.messageType);
          }

          $(".loader").hide();
        })).catch(error => {
          $(".loader").hide();
          that.props.setMessage("Error occurred while fetching user list", MessageTypeConst.ERROR_MESSAGE);
      });
  }

  handleSelectNodeChange = (event) => {
    let { processGroupList, processFlowList, blockList, selectedProject, selectedStudy, selectedProcessGroup, selectedProcessFlow, selectedBlock } = this.state;

    if (event.target.value.nodeId === 1) {
      selectedProcessGroup = '';
      selectedProcessFlow = '';
      processGroupList = [];
      processFlowList = [];
    } else if (event.target.value.nodeId === 2) {
      selectedProcessGroup = '';
      selectedProcessFlow = '';
      processGroupList = this.getDropdownList('processGroup')
      processFlowList = [];
    } else if (event.target.value.nodeId === 3) {
      selectedProcessFlow = '';
      processGroupList = this.getDropdownList('processGroup')
      if (selectedProcessGroup !== '') {
        processFlowList = this.getDropdownList('processFlow')
      } else {
        processFlowList = [];
      }
    } else if (event.target.value.nodeId === 4) {
      if (selectedProcessGroup === '') {
        selectedProcessFlow = ''
        processGroupList = this.getDropdownList('processGroup')
        processFlowList = [];
      } else if (processFlowList.length === 0) {
        processFlowList = this.getDropdownList('processFlow')
      }
    }

    selectedBlock = '';
    blockList = [];
    this.setState({selectedNode: event.target.value, processGroupList: processGroupList, processFlowList: processFlowList, blockList: blockList, selectedProject: selectedProject, selectedStudy: selectedStudy, selectedProcessGroup: selectedProcessGroup, selectedProcessFlow: selectedProcessFlow, selectedBlock: selectedBlock}, () => {
      if (this.state.selectedNode.nodeId > 3 && this.state.selectedProcessFlow !== '') {
        this.getDropdownList('block');
      }
    })
  }

  handleSelectChange = (event, type) => {
    let {studyList, processGroupList, processFlowList, blockList, selectedProject, selectedStudy, selectedProcessGroup, selectedProcessFlow, selectedBlock } = this.state;

    if (type === 'study') {
      this.setState({selectedStudy: event.target.value, selectedProcessGroup: '', selectedProcessFlow: '', selectedBlock: '', processGroupList: [], processFlowList: [], blockList: []}, () => {
          this.setState({processGroupList: this.getDropdownList('processGroup')})
      })
    } else if (type === 'processGroup') {
      this.setState({selectedProcessGroup: event.target.value, selectedProcessFlow: '', selectedBlock: '', processFlowList: [], blockList: []}, () => {
          this.setState({processFlowList: this.getDropdownList('processFlow')})
      })
    } else if (type === 'processFlow') {
      this.setState({selectedProcessFlow: event.target.value, selectedBlock: '', blockList: []}, () => {
        if (this.state.selectedNode.nodeId > 3) {
            this.getDropdownList('block');
        }
      })
    } else if (type === 'block') {
      this.setState({selectedBlock: event.target.value})
    }
  }

  getDropdownList = (type) => {
    let itemList = [];
    this.props.treeView.children.forEach(project => {
      if (type === 'study') {
        project.children.filter(study => study.study.schemaName === this.state.selectedStudy.schemaName).forEach(study => {
          itemList.push({studyName: study.name, studyId: study.study.studyId, schemaName: study.study.schemaName})
        })
      } else if (type === 'processGroup') {
        project.children.filter(study => study.study.studyId === this.state.selectedStudy.studyId).forEach(study => {
          study.children.filter(studyElement => studyElement.name === 'ProcessFlows').forEach(studyElement => {
            studyElement.children.forEach(classifier => {
              classifier.children.forEach(processGroup => {
                  itemList.push({processGroupName: processGroup.name, processGroupId: processGroup.processGroupId})
              })
            })
          })
        })
      } else if (type === 'processFlow') {
        project.children.filter(study => study.study.studyId === this.state.selectedStudy.studyId).forEach(study => {
          study.children.filter(studyElement => studyElement.name === 'ProcessFlows').forEach(studyElement => {
            studyElement.children.forEach(classifier => {
              classifier.children.filter(processGroup => processGroup.processGroupId === this.state.selectedProcessGroup.processGroupId).forEach(processGroup => {
                processGroup.children.forEach(processFlow => {
                  itemList.push({processFlowName: processFlow.name, processId: processFlow.process.processId})
                })
              })
            })
          })
        })
      }
    })

    if (type === 'block') {
      $(".loader").show();
      let that = this;
      axios.all([restClient(GET, 'processblock/blockAndLinkList?studyId='+this.state.selectedStudy.studyId+'&processId='+this.state.selectedProcessFlow.processId, {}, {'Tenant-ID': this.state.selectedStudy.schemaName})])
      .then(axios.spread((response) => {
          if (typeof(response.data) !== 'undefined' && typeof(response.data.processBlock) !== 'undefined') {
            response.data.processBlock.forEach(block => {
              itemList.push({blockName: block.blockName, blockId: block.blockId})
            })
            that.setState({blockList: itemList});
            $(".loader").hide();
          }
        })).catch(error => {
            $(".loader").hide();
            that.props.setMessage("Failed to fetch block list.",MessageTypeConst.ERROR_MESSAGE)
        })
    } else {
      return itemList;
    }
  }

  handleStatusChange = (event) => {
    this.setState({selectedStatus: event.target.value})
  }

  handleCommentDescriptionChange = (event) => {
    this.setState({commentDescription: event.target.value})
  }

  handleUserChange = (event) => {
    this.setState({selectedUser: event.target.value})
  }

  render() {
    return template.call(this);
  }
}


const mapStateToProps = createSelector(
  state => state.processFlow.tenantId,
  state => state.treeView.data,
  (tenantId, treeView) => ({
    tenantId,
    treeView
  })
);

const mapActionsToProps = {
  setMessage: setMessage,
}

export default connect(mapStateToProps, mapActionsToProps)(CreateTask);
