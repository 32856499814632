import React from "react";
import { ComboBox } from './../common/DropDown/StandardComboBox';
import { TabStrip, TabStripTab  } from './../common/Layout';
import { ProcessOptionGrid } from "../common/gridOperation";
import { SortOrder } from "../common";

function DecollapseOneLayoutTemplate() {
  let dateColumns = [];
  if(this.state.tableList){
    //Continues Date operation is applicable to single block only
    if(this.state.tableList[0]){
      let dateObjects = this.state.tableList[0].rows.filter(row=>row.type === 'DATE' || row.type === 'DATETIME' || row.type === 'NUM');
      dateColumns = dateObjects.map(a=>a.column);
    }
  }

  let startDateValue = "";
  let stopDateValue = "";

  global.processBlockControlValues.forEach((control, index) => {
    if (control.controlId === 4) {
      startDateValue = global.processBlockControlValues[index].controlValue;
      this.updateGlobalColumnAlwaysDisplay(startDateValue);
      this.updateTableRowAccoringToDisplayColumn(0, global.ColumnAlwaysNeedsToBeDisplay, []);
    }
  });

  global.processBlockControlValues.forEach((control, index) => {
    if (control.controlId === 5) {
      stopDateValue = global.processBlockControlValues[index].controlValue;
      this.updateGlobalColumnAlwaysDisplay(stopDateValue);
      this.updateTableRowAccoringToDisplayColumn(0, global.ColumnAlwaysNeedsToBeDisplay, []);
    }
  });

  var handleContinuesDateStartColumnChange = (event) => {
    global.processBlockControlValues.forEach((control, index) => {
      if (control.controlId === 4) {
        global.processBlockControlValues[index].controlValue = event.target.value;
        this.updateGlobalColumnAlwaysDisplay(event.target.value, startDateValue);
        this.updateTableRowAccoringToDisplayColumn(0, global.ColumnAlwaysNeedsToBeDisplay, [startDateValue]);
        this.setState({tableList:this.state.tableList, gridKey: this.state.gridKey+1});
        return true;
      }
    });
  }

  var handleContinuesDateEndColumnChange = (event) => {
    global.processBlockControlValues.forEach((control, index) => {
      if (control.controlId === 5) {
        global.processBlockControlValues[index].controlValue = event.target.value;
        this.updateGlobalColumnAlwaysDisplay(event.target.value, stopDateValue);
        this.updateTableRowAccoringToDisplayColumn(0, global.ColumnAlwaysNeedsToBeDisplay, [stopDateValue]);
        this.setState({tableList:this.state.tableList, gridKey: this.state.gridKey+1});
        return true;
      }
    });
  }

  return (
    <div className="process-option">
      <TabStrip selected={this.state.tabSelected} onSelect={this.handleSelectTabPanel}>
        <TabStripTab title="Process">
          <div className="field_group">
            <div className="am-form-inline m-0">
              <div className="row">
              <div className="col-md-4">
                <div className="am-form-field m-0">
                  <span>Start (Date/Num):</span>
                  <ComboBox className="m-0" data={dateColumns} onChange={handleContinuesDateStartColumnChange} value={startDateValue} />
                </div>
              </div>
              <div className="col-md-4">
                <div className="am-form-field m-0">
                  <span>Stop (Date/Num):</span>
                  <ComboBox className="m-0" data={dateColumns} onChange={handleContinuesDateEndColumnChange} value={stopDateValue}/>
                </div>
              </div>
              </div>
            </div>
          </div>
          <div className="field_group">
            {/* <div>
              <label className="am-form-field">
                <span>Find:</span>
                <input className="am-textbox m_l_5" placeholder="Search data" onChange={this.handleSeachDataChangeEvent} />
              </label>
            </div> */}
            <ProcessOptionGrid tableList={this.state.tableList} />
          </div>
        </TabStripTab>
        <TabStripTab title="GBY Order">
          {typeof(this.state.tableList[0]) !== 'undefined' &&
            <SortOrder table={this.state.tableList[0]}  />
          }
        </TabStripTab>
      </TabStrip>
    </div>
  );
};

export default DecollapseOneLayoutTemplate;
