import { REFRESH_DATA_STATUS, CONSTRUCTIVE_OPERATION_STATUS, UPDATE_REFERENCE_POINS, ENABLE_EXECUTE_BUTTON, SET_PROCESS_ENGINELIST, SET_PROCESS_ENGINE } from './actionsTypes';

export const setRefreshDataStatus = status => ({
    type: REFRESH_DATA_STATUS,
    payload: status
})

export const setConstructiveOperationStatus = status => ({
    type: CONSTRUCTIVE_OPERATION_STATUS,
    payload: status
})

export const setReferencePoints = (referencePoints,referencePointsName) => ({
    type: UPDATE_REFERENCE_POINS,
    payload: {referencePoints:referencePoints, referencePointsName:referencePointsName}
})

export const enableExecuteButton = enable => ({
    type: ENABLE_EXECUTE_BUTTON,
    payload: enable
})

export const setProcessEngineList = engineList => ({
    type: SET_PROCESS_ENGINELIST,
    payload: engineList
})

export const setProcessEngine = engine => ({
    type: SET_PROCESS_ENGINE,
    payload: engine
})
