
class ExecuteAllTypeConst {
    constructor(props){
        this.ProcessFlowLevelExecuteAll = 1;
        this.ProcessFlowLevelExecuteAllWarning = 2;
        this.ProcessBlockLevelExecuteAll =  3;
        this.ProcessGroupLevelExecuteAll = 4;
        this.StudyLevelExecuteAll = 5;
    }
}

export default (new ExecuteAllTypeConst());