class DataOpsIdentifierConstants {
    constructor(props){

        // Long Name
        this.APPEND_OP = "Append";
        this.APPEND_QUICK_OP = "AppendQuick";
        this.COLLAPSING_OP = "Collapsing";
        this.COMPARE_OP = "Compare";
        this.CODEEDITOR_OP = "CodeEditor";
        this.CRITERIAOPS_OP = "CriteriaOps";
        this.CONTINUOUSDATE_OP = "ContinuesDate";
        this.DATAOPS_OP = "Dataops";
        this.SORTOPS_OP = "Sortops";
        this.SORT_QUICK_OP = "SortQuick";
        this.RANKOPS_OP ="Rankops";
        this.SEQUQENCEOPS_OP ="Sequenceops";
        this.DECOLLAPSE_ONETABLE_OP = "Decollapse(One)";
        this.DECOLLAPSE_TWOTABLE_OP = "Decollapse(Two)";
        this.JOIN_OP = "Join";
        this.JOIN_QUICK_OP = "JoinQuick";
        this.IFELSEOPS_OP = "IfElseOps";
        this.LINK_OP = "Link";
        this.PIVOT_RTC_OP = "Pivot(RowToCol)";
        this.PIVOT_CTR_OP = "Pivot(ColToRow)";
        this.PIVOT_MATRIX = "PivotMetrics";
        this.REFERENCEPOINT_ONETABLE_OP = "ReferencePoint(One)";
        this.REFERENCEPOINT_TWOTABLE_OP = "ReferencePoint(Two)";
        this.REFERENCEPOINT_COMPOSITE_OP = "ReferencePoint(Composite)";
        this.REFERENCEPOINT_REPLACE_OP = "ReferencePoint(Replace)";
        this.REFERENCEPOINT_COMBINE_OP = "ReferencePoint(Combine)";
        this.REPORTINGOPS_DESCRIPTIVE_OP = "ReportingOps(Descriptive)";
        this.REPORTINGOPS_CATEGORICAL_OP = "ReportingOps(Categorical)";
        this.REPORTINGOPS_CATEGORICAL_TOTAL_OP = "ReportingOps(CategoricalTotal)";
        this.REPORTINGOPS_CATEGORICAL_TOTAL_UNIQUE_OP = "ReportingOps(CategoricalTotalUniquePerId)";
        this.REPORTINGOPS_CATEGORICAL_HIERARCHICAL_OP = "ReportingOps(CategoricalHierarchical)";
        this.REPORTINGOPS_CATEGORICAL_HIERARCHICAL_UNIQUE_OP = "ReportingOps(CategoricalHierarchicalUniquePerId)";
        this.REPORTINGOPS_CUSTOM_OP = "ReportingOps(Custom)";
        this.SPLIT_OP = "Split Table";
        this.SPLIT_QUICK_OP = "SplitQuick";
        this.STRINGOPS_ALIGN_OP = "StringOps(Align)";
        this.STRINGOPS_INDUCESPACING_OP = "StringOps(Insert Space)";
        this.STRINGOPS_TRIMCOMPRESS_OP = "StringOps(Trim)";
        this.STRINGOPS_CHANGECASE_OP = "StringOps(Case)";
        this.STRINGOPS_SEARCHNREPLACE_OP = "StringOps(Search)";
        this.STRINGOPS_OCCURENCE_OP = "StringOps(Occurence)";
        this.STRINGOPS_SPLIT_OP = "StringOps(Split)";
        this.TABLEOPS_RENAME_OP = "TableOps(Rename)";
        this.TABLEOPS_LENGTH_ADJ_OP = "TableOps(LengthAdj)";
        this.TYPECONVERSION_DATETIME_OP = "TypeConversion(DateTime)";
        this.TYPECONVERSION_CHARNUM_OP = "TypeConversion(CharNum)";
        this.PROCREPORT_ANALYSIS_CHAR_OP = "ProcReport(AnalysisChar)";
        this.PROCREPORT_ANALYSIS_NUM_OP = "ProcReport(AnalysisNum)";
        this.VARMAPPING_OP = "VariableMapping";
        this.CODEEDITOR_TABLE_OP = "CodeEditor(Table)";
        this.CODEEDITOR_FILE_OP = "CodeEditor(Report)";
        this.TIMELINE_OP = "Timeline";
        this.MAP_ANNOTATE_OP = "MapAnnotate";
        this.R_Report_OP = "R Report";
        this.GRAPH_OP = "Graph";
    }
}

export default (new DataOpsIdentifierConstants());
