import React from 'react';

export const ReportingOpsGBYCell = ({ tableManager, value, data, column, colIndex, rowIndex }) => {
    if (typeof(tableManager) === 'undefined') {
      return (null)
    } else {
      return (
          <div className='rgt-cell-inner' style={{display: 'flex', alignItems: 'center', overflow: 'hidden'}}>
              <ReportingOpsGBYCellClass dataItem={data} field={column.field} value={typeof(data[column.field]) !== 'undefined' ? data[column.field]: value} tableManager={tableManager}/>
          </div>
      )
    }
}


class ReportingOpsGBYCellClass extends React.Component {

    getGlobalValue(controlIndex){
        let controlValue = '';
        global.processBlockControlValues.forEach((control, index) => {
          if (control.controlId === controlIndex) {
            controlValue = global.processBlockControlValues[index].controlValue;

          }
        });
        return controlValue;
      }

      saveGlobalValue(controlIndex, value){
          let controlIdFound = false;
        global.processBlockControlValues.forEach((control, index) => {
          if (control.controlId === controlIndex) {
            controlIdFound = true;
            global.processBlockControlValues[index].controlValue = value;
          }
        });
        if(!controlIdFound){
            global.processBlockControlValues.push({controlId:controlIndex, controlType:controlIndex,controlValue:value});
        }
      }
    handleChange(e) {
        this.props.dataItem[this.props.field] = e.target.checked;
        this.props.tableManager.rowsApi.setRows(this.props.tableManager.rowsApi.rows)

        if(!e.target.checked){
            if(this.getGlobalValue(88) !== ""){
                let rowData = JSON.parse(this.getGlobalValue(88));
                //removing row data from Row Grid on row uncheck event
                let newRowData = rowData.filter(item => item.column !== this.props.dataItem['column']);
                this.saveGlobalValue(88, JSON.stringify(newRowData));
            }
        }
        else{
            let row = this.props.dataItem;
            //adding row data in Row Grid on row checked
            if(this.getGlobalValue(88) !== ""){
                let rowData = JSON.parse(this.getGlobalValue(88));
                let rowCount = [];
                let length = rowData.length;
                if(typeof(length) === "undefined" || isNaN(length)){
                    length = 0;
                }
                let count = length + 1;
                rowData.forEach((item,index) => {
                    if(typeof(rowData[index].gbyHash)!== "undefined"){
                        rowCount.push(rowData[index].gbyHash);
                    }
                });
                count = Math.max(...rowCount) + 1;

                if(!isFinite(count) || isNaN(count)){
                    count = 1;
                }
                if (count === Number.POSITIVE_INFINITY || count === Number.NEGATIVE_INFINITY)
                {
                    count = 1;
                }
                rowData.push({...row,...{"gbyHash": `${count}` , "gbyVarSort": "", "gbyTitle": "", "sort_variables":[]}});
                this.saveGlobalValue(88, JSON.stringify(rowData));
            } else{
                let rowDataNew = [];
                rowDataNew.push({...row,...{"gbyHash": `${1}`, "gbyVarSort": "", "gbyTitle": "", "sort_variables":[]}});
                this.saveGlobalValue(88, JSON.stringify(rowDataNew));
            }
        }
    }
    render() {
        let checked = false;
        if (this.props.dataItem[this.props.field]) {
          checked = true;
        }
        return (
            <div className="text-center width-full">
               <input type="checkbox" className="am-checkbox" id={`checkbox-${this.props.field.replace(' ', '_')}-${this.props.dataItem['columnId']}`} onChange={this.handleChange.bind(this)}
               checked={checked}/>
               <label className="am-checkbox-label" htmlFor={`checkbox-${this.props.field.replace(' ', '_')}-${this.props.dataItem['columnId']}`}></label>
            </div>
        );
    }
}
