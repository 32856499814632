import React from 'react';
import { Button } from './../Button/StandardButton';

export class SortOrder extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      itemList: [],
      itemSelected: '',
      totalGry: 0,
      totalOry: 0
    }

    this.state.itemList = [].concat(this.props.table.rows).filter(item => item.gby === true || (item.oby && item.oby !== '')).sort((a, b) => a.oby > b.oby || (a.oby === b.oby && a.columnId > b.columnId))

    this.state.itemList.forEach((item, index) => {
      if (item.gby === true) {
        this.state.totalGry = this.state.totalGry + 1
      } else if (item.oby !== '') {
        this.state.totalOry = this.state.totalOry + 1
      }
    });

    let grpcount = 1;
    let ordercount =1;
    this.state.itemList.forEach((item, index) => {
      if (item.gby === true) {
        this.state.itemList[index] = {...item, ...{"order": grpcount}};
        grpcount++;
      } else if (item.oby !== '') {
        this.state.itemList[index] = {...item, ...{"order": this.state.totalGry + ordercount}};
        ordercount++;
      }
    });

    this.ReorderItems = this.ReorderItems.bind(this);
    this.handleSelectItem = this.handleSelectItem.bind(this);
    this.handleItemUpEvent = this.handleItemUpEvent.bind(this);
    this.handleItemDownEvent = this.handleItemDownEvent.bind(this);
    this.SaveProcessBlockValue = this.SaveProcessBlockValue.bind(this);
    this.state.itemList = this.ReorderItems(this.state.itemList, this.state.totalGry);
    //save default group by list  without any up/down operation
    if(!global.updateBlock){
      this.SaveProcessBlockValue();
    }
    SortOrder.getItems = this.getItems.bind(this);
  }

    ReorderItems(itemList, totalGry)
    {
      global.processBlockControlValues.forEach((control, index) => {
        if (control.controlId === 9 && global.processBlockControlValues[index].controlValue) {
          let grpList = JSON.parse(global.processBlockControlValues[index].controlValue);
          let existingGroupCount = grpList.length;
          let outerCount =1;
          itemList.forEach((item, index) => {
            if(grpList.indexOf(item.column) !== -1)
            {
              item.order = grpList.indexOf(item.column) + 1;
            } else if(item.gby){
              item.order =existingGroupCount + outerCount;
              outerCount++;
            }
          });
        }
        if (control.controlId === 10 && global.processBlockControlValues[index].controlValue) {
          let grpList = JSON.parse(global.processBlockControlValues[index].controlValue);
          let existingOrderCount = grpList.length;
          let count = 0;
          let outerCount =1;
          itemList.forEach((item, index) => {

            let itemFound = false;
            grpList.forEach((item1,index1)=>{
              if(item1.column  === item.column && !item.gby){
                count = index1 + 1;
                itemFound = true;
              }
            });
            if(itemFound && !item.gby)
            {
              item.order = totalGry + count;
            } else if (!item.gby){
              item.order = totalGry + existingOrderCount + outerCount;
              outerCount++;
            }
          });

          let orderList = [];
          //let count = this.state.itemList.length -1;
          const sortedObyItems = itemList.filter(item => item.oby && item.oby !== '').sort((item1,item2)=>item1.order-item2.order);
          sortedObyItems.forEach((item, index) => {
            let orderItem = {"column":item.column,"oby":item.oby};
            orderList.push(orderItem);
          });
          global.processBlockControlValues[index].controlValue = JSON.stringify(orderList);
        }
      });
      return itemList;
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
      if(JSON.stringify(nextProps) !== '') {
        if (typeof(nextProps.table) !== 'undefined') {
          let itemList = [].concat(this.props.table.rows).filter(item => item.gby === true || (item.oby && item.oby !== '')).sort((a, b) => a.oby > b.oby || (a.oby === b.oby && a.columnId > b.columnId))
          var totalGry = 0;
          var totalOry = 0;
          itemList.forEach((item, index) => {
            if (item.gby === true) {
              totalGry = totalGry + 1
            } else if (item.oby !== '') {
              totalOry = totalOry + 1
            }
          });
          let grpcount = 1;
          let ordercount =1;
          itemList.forEach((item, index) => {
            if (item.gby === true) {
              itemList[index] = {...item, ...{"order": grpcount}};
              grpcount++;
            } else if (item.oby !== '') {
              itemList[index] = {...item, ...{"order": totalGry + ordercount}};
              ordercount++;
            }
          });

          itemList = this.ReorderItems(itemList, totalGry);
          this.setState({itemList:itemList,totalGry:totalGry,totalOry:totalOry},(()=>{
            //save default group by list  without any up/down operation
            this.SaveProcessBlockValue();
          }).bind(this));
        }
      }
    }

    SaveProcessBlockValue() {
      global.processBlockControlValues.forEach((control, index) => {
        if (control.controlId === 9) {
          let grpList = [];
          //let count = this.state.itemList.length -1;
          const sortedGrpItems = this.state.itemList.filter(item => item.gby === true).sort((item1,item2)=>item1.order-item2.order);
          sortedGrpItems.forEach((item, index) => {
            grpList.push(item.column);
          });
        global.processBlockControlValues[index].controlValue = JSON.stringify(grpList);
      }
      if (control.controlId === 10) {
        let orderList = [];
        //let count = this.state.itemList.length -1;
        const sortedObyItems = this.state.itemList.filter(item => item.oby && item.oby !== '').sort((item1,item2)=>item1.order-item2.order);
        sortedObyItems.forEach((item, index) => {
          let orderItem = {"column":item.column,"oby":item.oby};
          orderList.push(orderItem);
        });
      global.processBlockControlValues[index].controlValue = JSON.stringify(orderList);
    }

    });
  }

    handleSelectItem(event) {
      var itemSelected = event.target.id.replace('item-', '');
      document.getElementsByClassName('list-wrapper')[0].querySelectorAll('.selected').forEach((item, i) => {
        if (event.target.id !== item.id) {
            item.classList.remove('selected')
        }
      });

      event.target.classList.toggle('selected')

      if (!event.target.classList.contains('selected')) {
        itemSelected = '';
      }

      this.setState({ itemSelected : itemSelected });
    }

    displayGBYItem = (item, index) => {
      var selectedClassName = '';

      if (parseInt(this.state.itemSelected,10) === parseInt(item.columnId,10)) {
        selectedClassName = 'selected';
      }

      if (item.gby === true) {
        return (
          <li key={index} onClick={this.handleSelectItem} id={`item-${item.columnId}`} className={selectedClassName}>{`${item.column}`}</li>
        )
      }
    };

    displayOBYItem = (item, index) => {
      var selectedClassName = '';

      if (parseInt(this.state.itemSelected,10) === parseInt(item.columnId,10)) {
        selectedClassName = 'selected';
      }
      if (item.gby === false && item.oby && item.oby !== '') {
        return (
          <li key={index} onClick={this.handleSelectItem} id={`item-${item.columnId}`} className={selectedClassName}>{`${item.column}`}</li>
        )
      }
    };

    handleItemUpEvent(event) {
      let itemList = this.state.itemList;
      itemList.forEach((item, index) => {
        if (parseInt(item.columnId,10) === parseInt(this.state.itemSelected,10)) {
            if (typeof(itemList[index-1]) !== 'undefined' && (item.gby === true || (item.gby === false && item.order > this.state.totalGry))) {
              item.order = item.order - 1;
              itemList[index] = item;
              itemList[index-1].order = itemList[index-1].order + 1;
            }
          }
      });
      this.setState({ itemList : itemList.sort((item1,item2)=>item1.order-item2.order) });
      this.SaveProcessBlockValue();
    }
    handleItemDownEvent(event) {
      let itemList = this.state.itemList;
      itemList.forEach((item, index) => {
        if (parseInt(item.columnId,10) === parseInt(this.state.itemSelected,10)) {
            if (typeof(itemList[index+1]) !== 'undefined' && (item.gby === false || (item.gby === true && item.order < (this.state.totalGry)))) {
                item.order = item.order + 1;
                itemList[index] = item;
                itemList[index+1].order = itemList[index+1].order - 1;
            }


          }
      });
      this.setState({ itemList : itemList.sort((item1,item2)=>item1.order-item2.order) });
      this.SaveProcessBlockValue();
    }

    getItems() {
      return this.state.itemList;
    }

    render() {
        let itemList = this.state.itemList.sort((a, b) => a.order - b.order);
        return (
            <div className="sort-items">
              <div className="list-wrapper">
                <ul className="list">
                  { itemList.map(this.displayGBYItem) }
                </ul>
                <hr className="break-line"/>
                <ul className="list">
                  { itemList.map(this.displayOBYItem) }
                </ul>
              </div>
              <div className="button-wrapper">
                <Button primary={true} className="m-left" onClick={this.handleItemUpEvent}> Up</Button>
                <Button primary={true} className="m-left" onClick={this.handleItemDownEvent}> Down</Button>
              </div>
            </div>
        );
    }
}
