import React    from "react";
import { PropTypes } from "prop-types";
import axios from 'axios';
import $ from 'jquery';
import { connect } from 'react-redux';
import { createSelector } from 'reselect';
import { setMessage } from './../../../actions/actionNotification';
import MessageTypeConst from '../../MessageTypeConst';
import Dialog from 'react-dialog';
import { Button } from './../DataOperation/common/Button/StandardButton';
import { Input } from './../DataOperation/common/InputBox/StandardInput';
import { DropDownList } from './../DataOperation/common/DropDown/StandardDropDownList';
import {SERVER_URL} from '../../../../src/config/index.js';
import { setTreeViewData } from '../../../actions/actionTreeView';
import { addImportTable, setActiveProcessFlow, removeProcessFlow, updateProcessflowBlockRenderStatus, removeProcessFlowByStudyId } from '../../../actions/actionProcessFlow';
import { addStudy, removeStudy } from '../../../actions/actionStudy';
import { updateProjectList } from '../../../actions/actionProject';
import moment from 'moment';
import { manageClassifierInTreeView, manageProceeFlowGroupInTreeView, manageProcessInTreeView, manageStudyInTreeView, manageStudyElementsInTreeView } from '../NavigationBarMenu/NavigationBarMenu.js';
import ConfirmBox from './../DataOperation/common/ConfirmBox';

class ImportStudyDef extends React.Component {

    constructor(props){
        super(props);
        this.state = {
            closeEvent : props.closeEvent,
            node:props.node,
            chkNewStudy: true,
            studyList:this.populateStudy(props.node),
            selectedStudy:{text:'',value:0, schemaName:''},
            defaultSelectedStudy:{text:'',value:0, schemaName:''},
            fileName:'',
            files:[],
            newStudyName:'',
            classifierName:this.populateStudyClassifier(props.node),
            description: ''
        }
    }

    populateStudyClassifier = (projectNode) => {
        let classifierName = '';
        if(projectNode !== null && typeof(projectNode) !== "undefined" && projectNode.cssClassName === "manage-study-by-tag"){
            classifierName =projectNode.name;
        }
        return classifierName;
    }

    populateStudy = (projectNode) => {
        let studyList = [{text:'',value:0}];
        if(projectNode !== null && typeof(projectNode) !== "undefined" && projectNode.children !== null && typeof(projectNode.children) !== "undefined" &&
        projectNode.children.length >0){
            if(projectNode.cssClassName === "manage-study-by-tag"){
                projectNode.children.forEach((child)=>{
                    studyList.push({text:child.study.studyName, value:child.study.studyId, schemaName:child.study.schemaName});
                });
            }else if(projectNode.cssClassName === "manage-study"){
                projectNode.children.forEach((tagNode)=>{
                    if(tagNode.children !== null && typeof(tagNode.children) !== "undefined" &&
                    tagNode.children.length >0){
                        tagNode.children.forEach((child)=>{
                            studyList.push({text:child.study.studyName, value:child.study.studyId, schemaName:child.study.schemaName});
                        });
                    }
                });
            }
        }
        return studyList;
    }

    handleStudyChange = (event, data) => {
        this.setState({selectedStudy:event.target.value});
    }

    handleRadioChange = (event) => {
        let chkNewStudy= this.state.chkNewStudy;
        let selectedStudy = this.state.selectedStudy;
        let newStudyName = this.state.newStudyName;
        let classifierName = this.state.classifierName;
        if(event.target.id === "newStudy"){
            chkNewStudy = event.target.checked;
            selectedStudy = {text:'',value:0, schemaName:''};
        }else{
            chkNewStudy = !event.target.checked;
            newStudyName = '';
            classifierName = '';
        }
        this.setState({chkNewStudy:chkNewStudy, selectedStudy:selectedStudy,newStudyName:newStudyName, classifierName:classifierName});

    }

    handleImportClick = (event) => {
        let that = this;
        if(this.state.newStudyName === "" && this.state.chkNewStudy){
            ConfirmBox.alert("Please enter new study name.");
        } else if(!/^[A-Z0-9-_ ]+$/i.test(this.state.newStudyName) && this.state.chkNewStudy) {
            ConfirmBox.alert("Study Name can\'t be allow special characters.");
        }else if(this.state.classifierName === "" && this.state.chkNewStudy){
            ConfirmBox.alert("Please enter study classifier.");
        }
        else if(this.state.selectedStudy.value === 0 && !this.state.chkNewStudy){
            ConfirmBox.alert("Please select Study.");
        }else if (this.state.fileName === ""){
            ConfirmBox.alert("Please select Study Definition file.");
        }else{
            if (typeof(this.state.node.projectId) !== 'undefined') {
                let selectedProject = that.props.projectList.filter(project => project.projectId === this.state.node.projectId);
                if(selectedProject.length > 0 && selectedProject[0].studyList !== null && typeof(selectedProject[0].studyList) !== "undefined"){
                  let selectedStudy = selectedProject[0].studyList.filter(study1 => study1.studyName.toLowerCase() === that.state.newStudyName.trim().toLowerCase()
                  && study1.classifier.toLowerCase() === that.state.classifierName.trim().toLowerCase());
                  if(selectedStudy.length > 0){
                    ConfirmBox.alert(`Study '${this.state.newStudyName}' and Classifier '${this.state.classifierName}' already exists. Study name and Classifier must be unique within project.`);
                    return;
                  }
                }
              }

            let url = SERVER_URL +'/importLibrary';
            let tenantID = this.state.selectedStudy.schemaName === '' ? 'master' : this.state.selectedStudy.schemaName;
            let formData = new FormData();
            let config = {};

            let authorizationToken = null;
            if (typeof(global.login_token) !== 'undefined') {
              authorizationToken = 'Bearer '+ global.login_token.id_token;
            }

            if (typeof(this.state.files[0].size) !== 'undefined') {
              formData = new FormData();
              formData.append('files', this.state.files[0]);
              formData.append('studyId', this.state.selectedStudy.value);
              formData.append('newStudyName', this.state.newStudyName);
              formData.append('studyClassifier', this.state.classifierName);
              formData.append('studyDescription',this.state.description);
              formData.append('projectId',this.state.node.projectId);
              config = {
                  headers: {
                      'Tenant-ID': tenantID,
                      'content-type': 'multipart/form-data',
                      'Authorization': authorizationToken
                  }
              }
              $(".loader").show();
              axios.post(url, formData, config).then((response)=>{
                if (typeof(response.data) !== 'undefined') {
                    if(response.data.messageType === MessageTypeConst.SUCCESS_MESSAGE){
                        let treeView = that.props.treeView.data;
                        if(!this.state.chkNewStudy && response.data.study !== null && typeof(response.data.study) !== "undefined"){
                            treeView.children.forEach((project, index) => {

                                if (project.projectId === that.state.node.projectId) {
                                  project.children = project.children.filter( study => study.study.studyId !== response.data.study.studyId );
                                  that.props.removeStudy(response.data.study.studyId);

                                  that.props.removeProcessFlowByStudyId(response.data.study.studyId);
                                  let openProcessFlowIndex = that.props.processFlowList.length - 1;
                                  if (openProcessFlowIndex > -1) {
                                      that.props.updateProcessflowBlockRenderStatus(openProcessFlowIndex, true);
                                      that.props.setActiveProcessFlow(openProcessFlowIndex);
                                  }

                                  that.props.projectList.map((project) => {
                                    if (project.projectId === response.data.study.projectId) {
                                      let projectStudyList = project.study.filter( study => study.studyId !== response.data.study.studyId);
                                      let projectStudyLists = project.studyList.filter( study => study.studyId !== response.data.study.studyId);

                                      project.study.splice(0);
                                      project.study.splice(0, 0, ...projectStudyList);
                                      project.studyList.splice(0);
                                      project.studyList.splice(0, 0, ...projectStudyLists);
                                    }
                                    return project;
                                  })
                                }
                              })
                        }
                        if(response.data.study !== null && typeof(response.data.study) !== "undefined"){
                        let documents = [];
                        if(response.data.documents !== null && typeof(response.data.documents) !== 'undefined'){
                            response.data.documents.forEach((document) => {
                                documents.push({
                                  name: document.filename,
                                  cssClassName: 'manage-study-edit-documents',
                                  schemaName: response.data.study.schemaName,
                                  studyId:response.data.study.studyId,
                                  projectId:that.state.node.projectId,
                                  fileId: document.fileId,
                                  path: document.filepath,
                                  tableType: 2
                                })
                              })
                        }
                        let sourceTables = [];
                        if(response.data.sourceTables !== null && typeof(response.data.sourceTables) !== 'undefined'){
                            response.data.sourceTables.forEach((table) => {
                                sourceTables.push({
                                  name: table.srcTableName,
                                  cssClassName: 'manage-study-edit-source-table',
                                  schemaName: response.data.study.schemaName,
                                  studyId:response.data.study.studyId,
                                  projectId:that.state.node.projectId,
                                  tag:'open-data-viewer',
                                  tableName:table.tableName,
                                  tableType: 0
                                })
                              })
                        }
                        let metaTables = [];
                        if(response.data.metaTables !== null && typeof(response.data.metaTables) !== 'undefined'){
                            response.data.metaTables.forEach((table) => {
                                metaTables.push({
                                  name: table.srcTableName,
                                  cssClassName: 'manage-study-edit-meta-table',
                                  schemaName: response.data.study.schemaName,
                                  studyId:response.data.study.studyId,
                                  projectId:that.state.node.projectId,
                                  tag:'open-data-viewer',
                                  tableName:table.tableName,
                                  tableType: 1
                                })
                              })
                        }

                        let groupClassifier = [];

                        if (typeof(response.data.groupClassifier) !== 'undefined' && response.data.groupClassifier !== null) {
                        response.data.groupClassifier.forEach((classifier) => {
                            let processFlowGroup = [];
                            if(response.data.processFlowGroup !== null && typeof(response.data.processFlowGroup) !== 'undefined'){
                                response.data.processFlowGroup.forEach((group) => {
                                    var processFlow = [];
                                    if (classifier.classifierId === group.classifier) {
                                    if (typeof(group.processFlow) !== 'undefined' && group.processFlow !== null) {
                                        group.processFlow.forEach((process) => {
                                        processFlow.push(manageProcessInTreeView(process, that.state.node.projectId, response.data.study.schemaName, response.data.study.studyName));
                                        })
                                    }
                                    processFlowGroup.push(manageProceeFlowGroupInTreeView(group, processFlow, response.data.study));
                                    }
                                })
                            }

                            groupClassifier.push(manageClassifierInTreeView(classifier, processFlowGroup, response.data.study))
                        })
                        }

                        let studyNode = manageStudyInTreeView(response.data.study, manageStudyElementsInTreeView(response.data.study, sourceTables, metaTables, groupClassifier, documents), true);
                        if (studyNode.study.sourceTable === null || typeof (studyNode.study.sourceTable) === "undefined") {
                            studyNode.study.sourceTable = response.data.sourceTables !== null && typeof(response.data.sourceTables) !== 'undefined' ? response.data.sourceTables : [];;
                        }
                        if (studyNode.study.metaTable === null || typeof (studyNode.study.metaTable) === "undefined") {
                            studyNode.study.metaTable = response.data.metaTables !== null && typeof(response.data.metaTables) !== 'undefined' ? response.data.metaTables : [];;
                        }
                        if (studyNode.study.studyDocuments === null || typeof (studyNode.study.studyDocuments) === "undefined") {
                            studyNode.study.studyDocuments = response.data.documents !== null && typeof(response.data.documents) !== 'undefined' ? response.data.documents : [];;
                        }
                        if (studyNode.study.groupClassifier === null || typeof (studyNode.study.groupClassifier) === "undefined") {
                            studyNode.study.groupClassifier = response.data.groupClassifier !== null && typeof(response.data.groupClassifier) !== 'undefined' ? response.data.groupClassifier : [];
                        }
                        if (studyNode.study.processFlowGroup === null || typeof (studyNode.study.processFlowGroup) === "undefined") {
                            studyNode.study.processFlowGroup = response.data.processFlowGroup !== null && typeof(response.data.processFlowGroup) !== 'undefined' ? response.data.processFlowGroup : [];
                        }

                        treeView.children.forEach((proj, projIndex) => {
                            if (proj.projectId === that.state.node.projectId) {
                                treeView.children[projIndex].children.push(studyNode);
                            }
                        });

                        let newStudy = Object.assign({}, response.data.study, { selected: true,  timestamp:response.data.study.audit !== null ? moment.utc(response.data.study.audit.createdAt).format('lll') : ""  });
                        that.props.addStudy(newStudy);

                        that.props.projectList.map((project) => {
                        if (project.projectId === newStudy.projectId) {

                            if (typeof(project.study) === 'undefined' || project.study === null) {
                                project.study = [];
                            }
                            let projectStudyList = project.study.slice();
                            projectStudyList.splice(projectStudyList.length, 0, newStudy);

                            project.study.splice(0);
                            project.study.splice(0, 0, ...projectStudyList);

                            if (typeof(project.studyList) === 'undefined' || project.studyList === null) {
                                project.studyList = [];
                            }

                            project.studyList.splice(0);
                            project.studyList.splice(0, 0, ...projectStudyList);
                        }
                        return project;
                        });
                        that.props.updateProjectList(that.props.projectList);
                        that.props.setTreeViewData(treeView, true);
                    }
                }
                    this.props.setMessage(response.data.message, response.data.messageType);
                }
                $(".loader").hide();
                that.state.closeEvent();
              }).catch(error => {
                $(".loader").hide();
                that.state.closeEvent();
                that.props.setMessage("Error occurred while importing global library",MessageTypeConst.ERROR_MESSAGE);
              });
            }



        }
    }

    handleFileSelection = (event) => {

        var files = event.target.files;
        if(files.length >0){
            this.setState({fileName:files[0].name, files:files})
        }
    }

    handleStudyNameChange = (event) => {
        let newStudyName= event.target.value;
        this.setState({newStudyName:newStudyName});
    }

    handleStudyClassifierChange = (event) => {
        let classifierName= event.target.value.toUpperCase();
        this.setState({classifierName:classifierName});
    }

    handleStudyDescriptionChange = (event) => {
        this.setState({description: event.target.value});
    }

    render(){
        return (
            <div className="import-study-defination">
                 <Dialog className="study-properties" title={"Import Study Definition"} width="450px" height="auto" modal={true} onClose={this.state.closeEvent} buttons={[<Button primary={true}  onClick={this.handleImportClick} key="btn-import"> Import</Button>,<Button onClick={this.state.closeEvent} key="btn-cancel"> Cancel</Button>]}>
                    <div>
                    <fieldset>
                        <div className="field_group m-t-10 m-b-10">
                        <legend>Import Definition As </legend>
                        <label className="am-form-field"><input className="m-0" type="radio" name="newStudy" value="newStudy" checked={this.state.chkNewStudy}
                        onChange={this.handleRadioChange} id="newStudy" /> New Study</label>
                         <fieldset>
                                <div className="field_group m-t-10 m-b-10">
                                <legend>Study Details</legend>
                                <div className="am-form-field">
                        <label className="pro-label col-sm-2 text-left p-0"><span >Study</span></label>
                        <Input type="text" className='text-box-display am-textbox study-width-text' name="txtStudyName" id="txtStudyName" value={this.state.newStudyName} disabled={!this.state.chkNewStudy} onChange={this.handleStudyNameChange}/>

                      </div>
                      <div className="am-form-field">
                        <label className="pro-label col-sm-2 text-left p-0"><span >Classifier</span></label>
                        <Input type="text" className='text-box-display am-textbox study-width-text' name="txtClassifier" id="txtClassifier" value={this.state.classifierName} disabled={!this.state.chkNewStudy} onChange={this.handleStudyClassifierChange}/>

                      </div>
                        <div className="am-form-field">
                          <label className="pro-label col-sm-2 text-left p-0"><span >Description</span></label>
                          <textarea className='text-box-display am-textbox textarea-box study-width-text' name="txtDescription" id="txtDescription" value={this.state.description} disabled={!this.state.chkNewStudy} onChange={this.handleStudyDescriptionChange}/>

                        </div>
                                </div>
                        </fieldset>
                        <br/>
                        <label className="am-form-field"><input className="m-0" type="radio" name="existingStudy" value="existingStudy" checked={!this.state.chkNewStudy}
                        onChange={this.handleRadioChange} id="existingStudy"/> Existing Study</label>
                        <fieldset>
                                <div className="field_group m-t-10 m-b-10">
                                <legend>Select existing study</legend>
                                <div className="am-form-field">
                        <label className="pro-label col-sm-4 text-left p-0"><span >Selected Study</span></label>
                        <DropDownList className="custom-width-full" onChange={this.handleStudyChange}
                          data={this.state.studyList} textField={'text'} dataItemKey={'value'}  value={this.state.selectedStudy}
                          defaultValue={this.state.defaultSelectedStudy} disabled={this.state.chkNewStudy} />
                      </div>
                                </div>
                        </fieldset>
                        <fieldset>
                        <div className="field_group m-t-10 m-b-10">
                        <legend>Study Definition File </legend>
                        <div className="row">
                        <div className="col-3">
                        <div className="file am-button am-primary" style={{"position": "relative", "overflow": "hidden"}}>
							Upload
							<input type="file" accept=".zip" name="file" style={{"position": "absolute","fontSize": "50px","opacity": "0","right":"0","top": "0"}} onChange={this.handleFileSelection}/>
						</div>
                        </div>
                        <div className="col-4">
                        <label className="pro-label text-left p-50"><span >{this.state.fileName} </span></label>
                        </div>
                        </div>
                        </div>
                        </fieldset>
                        </div>
                        </fieldset>
                    </div>
                </Dialog>
             </div>
        );
    }
}

const mapStateToProps = createSelector(
    state => state.processFlow.tenantId,
    state => state.treeView,
    state => state.project.projectList,
    state => state.processFlow.processFlowList,
    (tenantId, treeView, projectList, processFlowList) => ({
      tenantId ,
      treeView  ,
      projectList ,
      processFlowList
    })
  );

  const mapActionsToProps = {
    setMessage: setMessage,
    setTreeViewData: setTreeViewData,
    addImportTable: addImportTable,
    addStudy: addStudy,
    updateProjectList: updateProjectList,
    setActiveProcessFlow: setActiveProcessFlow,
    removeProcessFlow: removeProcessFlow,
    updateProcessflowBlockRenderStatus: updateProcessflowBlockRenderStatus,
    removeProcessFlowByStudyId: removeProcessFlowByStudyId,
    removeStudy: removeStudy
  }


  Dialog.propTypes = {
      height: PropTypes.oneOfType([
          PropTypes.string
      ]),
      width: PropTypes.oneOfType([
          PropTypes.string
      ])
  }

  export default connect(mapStateToProps, mapActionsToProps, null, { withRef: true })(ImportStudyDef);
