import React from 'react';
import RichGridTable from '../../../RichGridTable';
import { BasicCheckBoxCell } from '../CheckBox/BasicCheckBoxCell';
import { AutoCompleteRowHashCell } from '../DropDown/AutoCompleteRowHashCell';
import { AutoCompleteRowVariableSortCell } from '../DropDown/AutoCompleteRowVariableSortCell';
import { NewColumnNameCell } from '../InputBox/NewColumnNameCell';
import { AutoCompleteColumnUniqueCell } from '../DropDown/AutoCompleteColumnUniqueCell'

export class ColumnGrid extends React.Component {
  constructor(props) {
    super(props);
    let numericColumns = [];
    this.props.table.rows.forEach((item)=>{
      if(item.type === "NUM"){
        numericColumns.push(item.column);
      }
    });
    this.state = {
      rows: [],
      columnProperty: [
        { field: "selected", show: false},
        { field: "gby", show: false},
        { field: "rowCheckox", show: false},
        { field: "columnCheckbox", show: false},
        { field: "tableName", show: false},
        { field: "tableTitle", show: false},
        { field: "column", width:"120px"},
        { field: "label", width:"180px"},
        { field: "type", width:"70px"},
        { field: "length", width:"70px"},
        { field: "column_hase", title: "Column #", cell: AutoCompleteRowHashCell },
        { field: "column_var_sort", title: "Column Var(Sort)", cell: AutoCompleteRowVariableSortCell},
        { field: "column_title", title: "Column Title", cell :NewColumnNameCell},
        { field: "column_num", title: "Column(n)", cell: BasicCheckBoxCell },
        { field: "column_total", title: "Column(total)", cell :NewColumnNameCell},
        { field: "column_unique", title: "Column(Unique)", cell :AutoCompleteColumnUniqueCell},
        { field : "sort_variables", show:false}
      ],
      key: 0
    }

   this.state.rows = [].concat(this.props.table.rows).filter(item => item.columnCheckbox === true )
   this.state.rows = this.appendGridColumns(this.state.rows, {"column_hase": "", "column_var_sort": "", "column_title": "", "column_num": false, "column_total": "", "column_unique":"", "sort_variables":numericColumns})
   this.state.rows = this.updateExistingRows(this.state.rows, numericColumns);
  }

  saveGlobalValue(controlIndex, value){
    global.processBlockControlValues.forEach((control, index) => {
      if (control.controlId === controlIndex) {
        global.processBlockControlValues[index].controlValue = value;
      }
    });
  }

  getGlobalValue(controlIndex){
    let controlValue = '';
    global.processBlockControlValues.forEach((control, index) => {
      if (control.controlId === controlIndex) {
        controlValue = global.processBlockControlValues[index].controlValue;

      }
    });
    return controlValue;
  }

  appendGridColumns(data, newFields, position='') {
    data.forEach((row, index) => {
      if (position !== '') {
        var beforePosition = {};
        var afterPosition = {};
        for (var key in row) {
          if(key === position) {
            beforePosition = {...beforePosition, ...{ [key]:row[key]}}
          } else {
            afterPosition = {...afterPosition, ...{ [key]:row[key]}}
          }
        }
        data[index] = { ...beforePosition, ...newFields, ...afterPosition};
      } else {
        data[index] = { ...row, ...newFields};
      }
    });

    return data;
  }

  UNSAFE_componentWillReceiveProps(nextProps){
    if (typeof(nextProps.table) !== 'undefined' && typeof(nextProps.table.rows) !== 'undefined') {
      let key = this.state.key;
      let numericColumns = [];
      nextProps.table.rows.forEach((item)=>{
        if(item.type === "NUM"){
          numericColumns.push(item.column);
        }
      });
      let rows = [].concat(nextProps.table.rows).filter(item => item.columnCheckbox === true )
      rows = this.appendGridColumns(rows, {"column_hase": "", "column_var_sort": "", "column_title": "", "column_num": false, "column_total": "", "column_unique":"", "sort_variables":numericColumns})
      key = key +1;
      rows = this.updateExistingRows(rows, numericColumns);
      this.setState({rows: rows, table: nextProps.table, key: key})
    }
  }

  updateExistingRows = (rows, numericColumns) => {
    if(this.getGlobalValue(86) !== ""){
      let rowData = JSON.parse(this.getGlobalValue(86));
      let existingRow = [...rows];
      let newData = [];
      existingRow.forEach((item)=>{
        let selectedRow = rowData.filter(row => row.column === item.column);
        if(selectedRow.length > 0){
          newData.push({...selectedRow[0],...{"sort_variables":numericColumns}});
        }else {
          newData.push({...item, ...{"sort_variables":numericColumns}});
        }
      });
      newData = newData.sort((a,b) => a.row - b.row);
      rows = newData;
      this.saveGlobalValue(86, JSON.stringify(newData));
    }

    return rows
  }

  render() {
      return (
        <RichGridTable id={"column-grid-table"} rows={this.state.rows} columnProperty={this.state.columnProperty} key={this.state.key}/>
      )
    }
}
