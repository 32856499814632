import React from 'react';
import cloneDeep from 'lodash/cloneDeep';
import restClient from '../../../../restClient';
import { CREATE, GET } from '../../../../restClient/types';
import axios from 'axios';
import $ from 'jquery';
import { connect } from 'react-redux';
import { PropTypes } from 'prop-types';
import { createSelector } from 'reselect';
import { TabStrip, TabStripTab  } from '../common/Layout';
import { Button } from './../common/Button/StandardButton';
import Dialog from 'react-dialog';
import Switch from "react-switch";
import MessageTypeConst from '../../../MessageTypeConst';
import { setMessage } from '../../../../actions/actionNotification';
import RichGridTable from '../../RichGridTable';
import { ExecuteAllLogButton } from '../common/Button/ExecuteAllLogButton';
import { ExecuteAllStatusCell } from './ExecuteAllStatusCell';
import BlockSaveType from '../../../BlockSaveType';
import { ExecuteAllPreWhereInput, SampleFilterCondition } from '../common';
import { addProcessFlowBlockLinkByTabIndex, updateProcessflowBlockRenderStatus, updateProcessFlowBlockByTabIndex, updateProcessFlowSyncFlagByTabIndex } from '../../../../actions/actionProcessFlow';
import ExecuteAllTypeConst from './ExecuteAllTypeConst';
import BlockStatus from '../../../ProcessFlow/BlockStatus';



class ExecuteAllNode extends React.Component{
    constructor(props){
      super(props);
      this.state = {
          node:props.node,
          nodeType: props.nodeType,
          classifier: props.classifier,
          groupJobCountTableList: {
            id: 'execute-all-group-job-count',
            rows: [],
            resize: true,
            filterable: false,
            selectedField: "selected",
            message: '',
            columnProperty: [
              { field: "rowId", show:false },
              { field: "jobs", show:false },
              { field: "blockCount", width:'100px',align:"center" },
              { field: "refreshTabList", show:false }
            ]
          },
          tableList:[{
              id: 'execute-all-jobs-grid',
              rows: [{jobId:0,inputTable:'',outputTable:'',blockSaveType:'',operation:'',statusIcon:'',log:'',status:false,inputBlockLevel:0}],
              resize: true,
              filterable: false,
              selectedField: "selected",
              message: '',
              columnProperty: [
                { field: "jobId", sortable:false, title:"Job Id", width:"50px", className:"text-center", orderIndex:1},
                { field: "processGroup", sortable:false, title:"Process Group", width:"120px", className:"text-center", orderIndex:2},
                { field: "process", sortable:false, title:"Process Flow", width:"150px", className:"text-center", orderIndex:3},
                { field: "inputTable", sortable:false, title:"Input", className:"text-center", width:"200px", orderIndex:4, show: false },
                { field: "displayInputTables", title: "inputTable", sortable:false, title:"Input", className:"text-center", width:"250px", orderIndex:4 },
                { field: "leftWhereCondition", sortable:false, title:"Pre-Where (Left)", className:"text-center", width:"280px", orderIndex:5, cell:ExecuteAllPreWhereInput},
                { field: "leftSampleFilter", sortable:false, title:"Sample Filter (Left)", className:"text-center", width:"280px", orderIndex:5, cell:SampleFilterCondition},
                { field: "rightWhereCondition", sortable:false, title:"Pre-Where (Right)", className:"text-center", width:"280px", orderIndex:6, cell:ExecuteAllPreWhereInput},
                { field: "rightSampleFilter", sortable:false, title:"Sample Filter (Right)", className:"text-center", width:"280px", orderIndex:6, cell:SampleFilterCondition},
                { field: "outputTable", sortable:false,title:"Output", width:"180px", className:"text-center", orderIndex:7, show: false  },
                { field: "displayOutputTable", title: "outputTable", sortable:false,title:"Output", width:"180px", className:"text-center", orderIndex:7  },
                { field: "blockSaveType", sortable:false, title:"Save Type", className:"text-center", width:"154px", orderIndex:8},
                { field: "operation", sortable:false, title:"Operation", className:"text-center", width:"200px", orderIndex:9 },
                { field: "statusIcon", sortable:false, title:"Status",cell:ExecuteAllStatusCell, className:"text-center",width:"50px", orderIndex:10 },
                { field: "log", sortable:false, title:"Log", cell:ExecuteAllLogButton, className:"text-center", width:"70px", orderIndex:11},
                { field: "status", sortable:false, title:"Job Status", show:false, className:"text-center",width:"40px", orderIndex:12 },
                { field: "inputBlockLevel", sortable:false, title:"Input Block Level", show:false, className:"text-center" },
                { field: "outputBlockId", show: false },
                { field: "leftEngineCode", show: false },
                { field: "leftInputSampleFilter", show: false },
                { field: "rightEngineCode", show: false },
                { field: "rightInputSampleFilter", show: false },
                { field: "isSampleFiltered", show: false }
              ]
            }],
          selected: 0,
          prepareNodeJobList: '',
          flowcheckedInByApi: [],
          isSampleFilter: false,
          isKeepAllData: false,
          isKeepLinkData: true,
          isjobFetched: false
      }

      ExecuteAllNode.getFlowcheckedInByApi = this.getFlowcheckedInByApi.bind(this);
      ExecuteAllNode.getStateDetail = this.getStateDetail.bind(this);
    }

    componentDidMount = () => {
      $(".loader").show();
      let that = this;
      let params = {
          "studyId": this.state.node.studyId,
          "processId": 0,
          "processGroupId": 0,
          "executeAllType": 5,
          "blockId":0,
          "classifierId": typeof(this.state.classifier.classifierId) ? this.state.classifier.classifierId : 0
      }

      if (params.classifierId > 0) {
        params.executeAllType = 7
      }
      this.setState({isjobFetched: true})
      axios.all([restClient(CREATE, 'executeAll/prepareStudyJobs', {data : params}, {'Tenant-ID': this.state.node.schemaName})])
            .then(axios.spread(function (response)  {
                if(response.data.messageType === MessageTypeConst.SUCCESS_MESSAGE){
                  let {tableList, groupJobCountTableList} = that.state;
                  groupJobCountTableList.rows.length = 0;
                  tableList[0].rows.length = 0;
                  if (typeof(response.data.groupJobs) !== 'undefined') {
                    response.data.groupJobs.forEach(groupJob => {
                      groupJobCountTableList.rows.push({
                        selected: groupJob.jobs.length > 0,
                        rowId: groupJobCountTableList.rows.length,
                        columnId: groupJobCountTableList.rows.length,
                        processGroupName: groupJob.processGroupName,
                        blockCount: groupJob.jobs.length,
                        jobs: groupJob.jobs,
                        message: groupJob.message === null || groupJob.message === ''  ? '-' : groupJob.message,
                        refreshTabList: that.refreshTabList
                      })

                      let jobId = 0;
                      groupJob.jobs.forEach((job)=>{
                          let displayInputTables = cloneDeep(job.inputTable);
                          displayInputTables.forEach((displayInputTable, index) => {
                            if (typeof(job.inputTableName[index]) !== 'undefined') {
                              displayInputTables[index] = job.inputTableName[index] + " ("+displayInputTable+")"
                            }
                          })
                          jobId = jobId + 1;
                          tableList[0].rows.push({jobId:jobId, processGroup:job.processGroupName, process:job.processName,
                          operation:job.operation, displayInputTables: displayInputTables.join(', '), inputTable: job.inputTable.join(","),
                          rightWhereCondition:job.rightInputWhereCondition,
                          leftWhereCondition:job.leftInputWhereCondition,
                          leftSampleFilter: '',
                          rightSampleFilter: '',
                          outputTable:job.outputTable,
                          displayOutputTable:job.outputTableName +"("+job.outputTable+")",
                          blockSaveType: (job.outputBlockSaveType === 1 ? 'Persistent' : 'Temp'),
                          statusIcon:job.outputBlockSyncFlag === BlockStatus.VALID ? 'fa fa-check-circle green status' :
                          ( job.outputBlockSyncFlag === BlockStatus.INVALID ? 'fa fa-exclamation-circle red status' :
                          'fa fa-exclamation-triangle yellow status'),
                           log:job.log, status:'', inputBlockLevel:job.inputBlockLevel,
                           outputBlockId: job.outputBlockId,
                           leftEngineCode: job.leftEngineCode,
                           leftInputSampleFilter: job.leftInputSampleFilter,
                           rightEngineCode: job.rightEngineCode,
                           rightInputSampleFilter: job.rightInputSampleFilter,
                           isSampleFiltered: job.isSampleFiltered
                          });
                      })
                    })
                  }
                  that.setState({groupJobCountTableList: groupJobCountTableList, tableList: that.applySampleFilterInJob(tableList), prepareNodeJobList: response.data, flowcheckedInByApi: response.data.flowcheckedInByApi, isjobFetched: false})
                }
                $(".loader").hide();
                that.props.setMessage(response.data.message,response.data.messageType);
            })).catch(error => {
                $(".loader").hide();
                that.props.setMessage("Error occurred while preparing jobs.",MessageTypeConst.ERROR_MESSAGE);
            });
    }

    handleSelect = (e) => {
      this.setState({ selected: e.selected })
    }

    refreshTabList = () => {
      this.setState({tableList: this.state.tableList})
    }

    executeJobs = () => {
      let {groupJobCountTableList, prepareNodeJobList, tableList, isKeepLinkData, isKeepAllData} = this.state;

      if (typeof(prepareNodeJobList.groupJobs) !== 'undefined') {
        prepareNodeJobList.groupJobs = prepareNodeJobList.groupJobs.filter(groupJob => groupJobCountTableList.rows.filter(row => row.selected === true && row.blockCount > 0 && row.processGroupName === groupJob.processGroupName).length > 0)
      }

      prepareNodeJobList.groupJobs.map(groupJob => {
        groupJob.jobs.map(job => {
          tableList[0].rows.filter(row => row.processGroup === job.processGroupName && row.process === job.processName && row.outputBlockId === job.outputBlockId).forEach(row => {
            job.leftSampleFilterApplied = row.leftSampleFilter
            job.rightSampleFilterApplied = row.rightSampleFilter
            job.isSampleFiltered = row.isSampleFiltered;
            if (job.leftSampleFilterApplied === '' && job.rightSampleFilterApplied === '') {
              job.isSampleFiltered = false
            }

            job.leftInputWhereCondition = row.leftWhereCondition;
            job.rightInputWhereCondition = row.rightWhereCondition;
          })
          return job;
        })
        return groupJob
      })

      if (typeof(prepareNodeJobList.blockId) !== 'undefined') {
        delete prepareNodeJobList.blockId;
      }
      if (typeof(prepareNodeJobList.processGroupId) !== 'undefined') {
        delete prepareNodeJobList.processGroupId;
      }
      if (typeof(prepareNodeJobList.processId) !== 'undefined') {
        delete prepareNodeJobList.processId;
      }
      if (typeof(prepareNodeJobList.studyId) !== 'undefined') {
        delete prepareNodeJobList.studyId;
      }

      prepareNodeJobList.keepLinkData = isKeepLinkData;
      prepareNodeJobList.keepAllData = isKeepAllData;

      $(".loader").show();
      let that = this;
      axios.all([restClient(CREATE, 'executeAllStudy', {data : prepareNodeJobList}, {'Tenant-ID': this.state.node.schemaName})])
            .then(axios.spread(function (response)  {
              that.setState({flowcheckedInByApi: []})
              that.props.setMessage(response.data.message,response.data.messageType);
              that.props.toggleDialog();
              $(".loader").hide();
            })).catch(error => {
                $(".loader").hide();
                that.props.setMessage("Error occurred while executing jobs.",MessageTypeConst.ERROR_MESSAGE);
            });
    }

    getFlowcheckedInByApi() {
      return this.state.flowcheckedInByApi
    }

    handleSampleFilterStatusChange = (event) => {
      this.setState({isSampleFilter: event}, () => {
        let tableList = this.state.tableList;
        tableList = this.applySampleFilterInJob(this.state.tableList);
        this.setState({tableList: tableList})
      })
    }

    handleKeepAllDataStatusChange = (event) => {
      this.setState({isKeepAllData: event, isKeepLinkData : event === true ? event : this.state.isKeepLinkData})
    }

    handleKeepLinkDataStatusChange = (event) => {
      this.setState({isKeepLinkData: event})
    }

    getStateDetail = () => {
      return this.state;
    }

    applySampleFilterInJob = (tableList) => {
      tableList[0].rows.map(row => {
        row.isSampleFiltered = false;
        if (typeof(row.leftInputSampleFilter) !== 'undefined' && row.leftInputSampleFilter !== '' && row.leftInputSampleFilter !== null && typeof(row.leftInputSampleFilter) !== 'undefined' && row.leftEngineCode !== '') {
            const leftInputSampleFilter = JSON.parse(row.leftInputSampleFilter);

            if (leftInputSampleFilter !== '') {
              leftInputSampleFilter.filter(leftInputSampleFilter => leftInputSampleFilter.engineCode === row.leftEngineCode && leftInputSampleFilter.whereCondition !== '').forEach(leftInputSampleFilter => {
                row.isSampleFiltered = this.state.isSampleFilter
                row.leftSampleFilter = this.state.isSampleFilter === true ? leftInputSampleFilter.whereCondition : ''
              })
            }
        }

        if (typeof(row.rightInputSampleFilter) !== 'undefined' && row.rightInputSampleFilter !== '' && row.rightInputSampleFilter !== null && typeof(row.rightInputSampleFilter) !== 'undefined' && row.rightEngineCode !== '') {
            const rightInputSampleFilter = JSON.parse(row.rightInputSampleFilter);

            if (rightInputSampleFilter !== '') {
              rightInputSampleFilter.filter(rightInputSampleFilter => rightInputSampleFilter.engineCode === row.rightEngineCode && rightInputSampleFilter.whereCondition !== '').forEach(rightInputSampleFilter => {
                row.isSampleFiltered = this.state.isSampleFilter
                row.rightSampleFilter = this.state.isSampleFilter === true ? rightInputSampleFilter.whereCondition : ''
              })
            }
        }
        return row
      })

      return tableList
    }

    render(){
      return(
        <div className={`study-properties-main executeallproperties execute-all-node${this.state.selected === 0 ? ' small-window': ''}`}>
          <Dialog className="data-properties" title={`Execute All (Batch) - ${this.state.node.studyName}${typeof(this.state.classifier.classifierName) !== 'undefined' ? ' - '+this.state.classifier.classifierName: ''}`} width="calc(100% - 10px)" height="calc(100vh - 30px)" modal={true} onClose={this.props.toggleDialog} buttons={[<Button primary={true} onClick={this.executeJobs} disabled={this.state.tableList[0].rows.length === 0} key="btn-execute" > Execute</Button>,<Button onClick={this.props.toggleDialog} key="btn-close"> Close</Button>]}>
            <>
            {this.state.groupJobCountTableList.rows.length > 0 &&
              <TabStrip selected={this.state.selected} onSelect={this.handleSelect}>
                {
                  [{processGroupName: "General", jobs: 0, selected: true, blockCount: 1}, ...this.state.groupJobCountTableList.rows].filter(tab => tab.selected === true && tab.blockCount > 0).map((tab, index) => {
                      let tableDetail = this.state.groupJobCountTableList;

                      if (index > 0) {
                        tableDetail = {
                          ...this.state.tableList[0],
                          id: 'execute-all-jobs-grid-'+tab.processGroupName+'-'+index,
                          rows:this.state.tableList[0].rows.filter(row => row.processGroup === tab.processGroupName)
                        }
                      }
                      return (<TabStripTab title={tab.processGroupName} key={index}>
                              {index === 0 &&
                                <div className="row">
                                  <div className="col-12">
                                    <label className="custom-switch">
                                      <span>Sample Filter</span>
                                      <Switch onChange={this.handleSampleFilterStatusChange} height={20} width={45} checked={this.state.isSampleFilter} />
                                    </label>
                                    <label className="custom-switch m-l-10">
                                      <span>Keep All Data</span>
                                      <Switch onChange={this.handleKeepAllDataStatusChange} height={20} width={45} checked={this.state.isKeepAllData} />
                                    </label>
                                    <label className="custom-switch m-l-10">
                                      <span>Keep Link Data</span>
                                      <Switch onChange={this.handleKeepLinkDataStatusChange} height={20} width={45} checked={this.state.isKeepLinkData} disabled={this.state.isKeepAllData === true} />
                                    </label>
                                  </div>
                                </div>
                              }
                              <div className="executealljobs">
                                <RichGridTable {...tableDetail}/>
                              </div>
                            </TabStripTab>);
                  })
                }
              </TabStrip>
            }
            {this.state.groupJobCountTableList.rows.length === 0 && this.state.isjobFetched === false &&
              <div className="align-center m-t-10"><h4>No jobs to execute</h4></div>
            }
            </>
          </Dialog>
        </div>
      )
    }
}

const mapStateToProps = createSelector(
    state => state.processFlow.tenantId,
    (tenantId) => ({
      tenantId
    })
  );

  const mapActionsToProps = {
    setMessage: setMessage
  }
Dialog.propTypes = {
      height: PropTypes.oneOfType([
          PropTypes.string
      ]),
      width: PropTypes.oneOfType([
          PropTypes.string
      ])
  }

export default connect(mapStateToProps, mapActionsToProps, null, { withRef: true })(ExecuteAllNode);
