import React from 'react';
import { DropDownList } from './StandardDropDownList';
import BlockCreationType from '../../../../BlockCreationType';
import BlockStatus from '../../../../ProcessFlow/BlockStatus';

export const MultiplePasteCopyAsCell = ({ tableManager, value, data, column, colIndex, rowIndex }) => {
    if (typeof(tableManager) === 'undefined') {
      return (null)
    } else {
      return (
          <div className='rgt-cell-inner' style={{display: 'flex', alignItems: 'center', overflow: 'hidden'}}>
              <MultiplePasteCopyAsCellClass dataItem={data} field={column.field} value={typeof(data[column.field]) !== 'undefined' ? data[column.field]: value} column={column} tableManager={tableManager}/>
          </div>
      )
    }
}


class MultiplePasteCopyAsCellClass extends React.Component {

    constructor(props){
      super(props);
      this.copyData = {text: "Copy Data", value: 4};
      this.linkData = {text: "Link Data", value: 5};
      this.copyDef = {text: "Copy Definition", value: 6};
      this.options = [];
      this.selectedValue = this.copyDef;
      if(parseInt(this.props.dataItem['blockType'], 10) === 2){
        this.options.push(this.copyDef);
      } else{
        if(parseInt(this.props.dataItem['blockCreationType'], 10) === BlockCreationType.IMPORT_META_TABLE
            || parseInt(this.props.dataItem['blockCreationType'], 10) === BlockCreationType.IMPORT_SOURCE_TABLE
            || parseInt(this.props.dataItem['blockCreationType'], 10) === BlockCreationType.DATA_LINK
            || parseInt(this.props.dataItem['blockCreationType'], 10) === BlockCreationType.DATA_COPY){
              this.options.push(this.copyData);
              this.options.push(this.linkData);
              this.selectedValue = this.linkData;
              if(this.props.dataItem['blockStudyId'] !== this.props.dataItem['destinationStudyId']){
                this.selectedValue = this.copyData;
              }
        } else if(parseInt(this.props.dataItem['blockCreationType'], 10) === BlockCreationType.CREATE_UPDATE_BLOCK
                    || parseInt(this.props.dataItem['blockCreationType'], 10) === BlockCreationType.DEF_COPY){
                      this.options.push(this.copyData);
            this.options.push(this.linkData);
            this.options.push(this.copyDef);
        }

        if(this.props.dataItem['blockStudyId'] !== this.props.dataItem['destinationStudyId']){
          this.options = this.options.filter(item => item.value !== 5);
        }
        if(this.props.dataItem['blockStudyId'] === this.props.dataItem['destinationStudyId'] &&
        this.props.dataItem['destinationProcessId'] === this.props.dataItem['sourceProcessId']){
          this.options = this.options.filter(item => item.value === 6);
          if(this.options.length > 0){
            this.selectedValue = this.copyDef;
          }else{
           this.selectedValue = "";
          }
        }
        if(this.props.dataItem['blockCreationType'] === BlockCreationType.DEF_COPY
            && this.props.dataItem['blockSyncFlag'] === BlockStatus.INVALID){
            this.options = this.options.filter(item => item.value === 6);
            if(this.options.length > 0){
              this.selectedValue = this.copyDef;
            }else{
             this.selectedValue = "";
            }
        }
        if(this.props.dataItem['tableExists'] === 0){
            this.options = this.options.filter(item => item.value === 6);
            if(this.options.length > 0){
              this.selectedValue = this.copyDef;
            }else{
             this.selectedValue = "";
            }
        }
      }

      this.state = {
        selectedValue : this.selectedValue
      }
    }
    handleChange(e) {
      this.setState({
        selectedValue:e.target.value
      });

      if (typeof(e.target.value.value) !== 'undefined' && e.target.value.value === 5 && typeof(this.props.dataItem['destinationBlock']) !== 'undefined') {
        this.props.dataItem['destinationBlock'] = this.props.dataItem['item'].blockName;
      }

        this.props.dataItem[this.props.field] = e.target.value;
        this.props.tableManager.rowsApi.setRows(this.props.tableManager.rowsApi.rows)

      }
      render() {
        this.props.dataItem[this.props.field] = this.state.selectedValue;
          return (
              <>
                <DropDownList className="width-full" textField={'text'} dataItemKey={'value'} onChange={this.handleChange.bind(this)} data={this.options} value={this.state.selectedValue} />
              </>
          );
      }
}
