import React, { memo, useState } from 'react';
import { Handle, Position } from 'reactflow';
//import { Tooltip  } from 'reactstrap';
import DataOpsIdentifierConstants from './../../DataOpsIdentifierConstants';
import ShortNamesConst from './../../ShortNameConst';
import BlockType from './../../BlockType';
import BlockSaveType from './../../BlockSaveType';
import BlockStatus from './../../ProcessFlow/BlockStatus';
import BlockCreationType from './../../BlockCreationType';
import copyDataImg from './../../ProcessFlow/img/copyData.png';
import metaTable from './../../ProcessFlow/img/database.svg';
import sourceTable from './../../ProcessFlow/img/table.svg';
import referencePointImg from './../../ProcessFlow/img/referncePoint.jpg';
import fileImg from './../../ProcessFlow/img/file.svg';
import tempImg from './../../ProcessFlow/img/temp.png';
import persistentImg from './../../ProcessFlow/img/persistent.png';
import linkDataImg from './../../ProcessFlow/img/link.png';
import timelineImg from './../../ProcessFlow/img/timeline.png';
import invalidImg from './../../ProcessFlow/img/invalid.png';
import validImg from './../../ProcessFlow/img/valid.svg';
import downImg from './../../ProcessFlow/img/down.png';
import upImg from './../../ProcessFlow/img/up.png';
import virualImg from './../../ProcessFlow/img/virtual.png';
import warningImg from './../../ProcessFlow/img/warning.png';
import sasLogo from './../../ProcessFlow/img/SAS_logo.png';
import rLogo from './../../ProcessFlow/img/R_logo.png';
import leftPreWhere from './../../ProcessFlow/img/left-pre-where.png';
import rightPreWhere from './../../ProcessFlow/img/right-pre-where.png';

export default memo(({ data }) => {
  let operationType = data.dataop;
  let strOperationType = "" ;
  const [tooltipOpen, setTooltipOpen] = useState(false);

  if (operationType == null) {
      operationType = "";
  }
  //convert to log dataop name to short name
  switch (operationType) {

      case DataOpsIdentifierConstants.SORTOPS_OP:
      case DataOpsIdentifierConstants.SORT_QUICK_OP:
          strOperationType = ShortNamesConst.SORTOPS_OP;
          break;
      case DataOpsIdentifierConstants.APPEND_OP:
      case DataOpsIdentifierConstants.APPEND_QUICK_OP:
          strOperationType = ShortNamesConst.APPEND_OP;
          break;
      case DataOpsIdentifierConstants.COMPARE_OP:
          strOperationType = ShortNamesConst.COMPARE_OP;
          break;
      case DataOpsIdentifierConstants.CONTINUOUSDATE_OP:
          strOperationType = ShortNamesConst.CONTINUOUSDATE_OP;
          break;
      case DataOpsIdentifierConstants.CRITERIAOPS_OP:
          strOperationType = ShortNamesConst.CRITERIAOPS_OP;
          break;
      case DataOpsIdentifierConstants.DATAOPS_OP:
          strOperationType = ShortNamesConst.DATAOPS_OP;
          break;
      case DataOpsIdentifierConstants.DECOLLAPSE_ONETABLE_OP:
          strOperationType = ShortNamesConst.DECOLLAPSE_ONETABLE_OP;
          break;
      case DataOpsIdentifierConstants.DECOLLAPSE_TWOTABLE_OP:
          strOperationType = ShortNamesConst.DECOLLAPSE_TWOTABLE_OP;
          break;
      case DataOpsIdentifierConstants.IFELSEOPS_OP:
          strOperationType = ShortNamesConst.IFELSEOPS_OP;
          break;
      case DataOpsIdentifierConstants.JOIN_OP:
      case DataOpsIdentifierConstants.JOIN_QUICK_OP:
          strOperationType = ShortNamesConst.JOIN_OP;
          break;
      case DataOpsIdentifierConstants.LINK_OP:
          strOperationType = ShortNamesConst.LINK_OP;
          break;
      case DataOpsIdentifierConstants.PIVOT_CTR_OP:
          strOperationType = ShortNamesConst.PIVOT_CTR_OP;
          break;
      case DataOpsIdentifierConstants.PIVOT_RTC_OP:
          strOperationType = ShortNamesConst.PIVOT_RTC_OP;
          break;
      case DataOpsIdentifierConstants.REFERENCEPOINT_COMBINE_OP:
          strOperationType = ShortNamesConst.REFERENCEPOINT_COMBINE_OP;
          break;
      case DataOpsIdentifierConstants.REFERENCEPOINT_COMPOSITE_OP:
          strOperationType = ShortNamesConst.REFERENCEPOINT_COMPOSITE_OP;
          break;
      case DataOpsIdentifierConstants.REFERENCEPOINT_ONETABLE_OP:
          strOperationType = ShortNamesConst.REFERENCEPOINT_ONETABLE_OP;
          break;
      case DataOpsIdentifierConstants.REFERENCEPOINT_TWOTABLE_OP:
          strOperationType = ShortNamesConst.REFERENCEPOINT_TWOTABLE_OP;
          break;
      case DataOpsIdentifierConstants.REFERENCEPOINT_REPLACE_OP:
          strOperationType = ShortNamesConst.REFERENCEPOINT_REPLACE_OP;
          break;
      case DataOpsIdentifierConstants.TABLEOPS_RENAME_OP:
          strOperationType = ShortNamesConst.TABLEOPS_RENAME_OP;
          break;
      case DataOpsIdentifierConstants.TABLEOPS_LENGTH_ADJ_OP:
          strOperationType = ShortNamesConst.TABLEOPS_LENGTH_ADJ_OP;
          break;
      case DataOpsIdentifierConstants.REPORTINGOPS_DESCRIPTIVE_OP:
      case DataOpsIdentifierConstants.REPORTINGOPS_CATEGORICAL_OP:
      case DataOpsIdentifierConstants.REPORTINGOPS_CATEGORICAL_TOTAL_OP:
      case DataOpsIdentifierConstants.REPORTINGOPS_CATEGORICAL_HIERARCHICAL_OP:
      case DataOpsIdentifierConstants.REPORTINGOPS_CATEGORICAL_HIERARCHICAL_UNIQUE_OP:
      case DataOpsIdentifierConstants.REPORTINGOPS_CATEGORICAL_TOTAL_UNIQUE_OP:
      case DataOpsIdentifierConstants.REPORTINGOPS_CUSTOM_OP:
          strOperationType = ShortNamesConst.REPORTINGOPS_OP;
          break;
      case DataOpsIdentifierConstants.SPLIT_OP:
      case DataOpsIdentifierConstants.SPLIT_QUICK_OP:
          strOperationType = ShortNamesConst.SPLIT_OP;
          break;
      case DataOpsIdentifierConstants.TYPECONVERSION_CHARNUM_OP:
          strOperationType = ShortNamesConst.TYPECONVERSION_CHARNUM_OP;
          break;
      case DataOpsIdentifierConstants.TYPECONVERSION_DATETIME_OP:
          strOperationType = ShortNamesConst.TYPECONVERSION_DATETIME_OP;
          break;
      case DataOpsIdentifierConstants.VARMAPPING_OP:
          strOperationType = ShortNamesConst.VARMAPPING_OP;
          break;
      case DataOpsIdentifierConstants.STRINGOPS_ALIGN_OP:
          strOperationType = ShortNamesConst.STRINGOPS_ALIGN_OP;
          break;
      case DataOpsIdentifierConstants.STRINGOPS_CHANGECASE_OP:
          strOperationType = ShortNamesConst.STRINGOPS_CHANGECASE_OP;
          break;
      case DataOpsIdentifierConstants.STRINGOPS_INDUCESPACING_OP:
          strOperationType = ShortNamesConst.STRINGOPS_INDUCESPACING_OP;
          break;
      case DataOpsIdentifierConstants.STRINGOPS_OCCURENCE_OP:
          strOperationType = ShortNamesConst.STRINGOPS_OCCURENCE_OP;
          break;
      case DataOpsIdentifierConstants.STRINGOPS_SEARCHNREPLACE_OP:
          strOperationType = ShortNamesConst.STRINGOPS_SEARCHNREPLACE_OP;
          break;
      case DataOpsIdentifierConstants.STRINGOPS_SPLIT_OP:
          strOperationType = ShortNamesConst.STRINGOPS_SPLIT_OP;
          break;
      case DataOpsIdentifierConstants.STRINGOPS_TRIMCOMPRESS_OP:
          strOperationType = ShortNamesConst.STRINGOPS_TRIMCOMPRESS_OP;
          break;
      case DataOpsIdentifierConstants.PROCREPORT_ANALYSIS_CHAR_OP:
      case DataOpsIdentifierConstants.PROCREPORT_ANALYSIS_NUM_OP:
          strOperationType = ShortNamesConst.PROCREPORT_OP;
          break;
      case DataOpsIdentifierConstants.R_Report_OP:
          strOperationType = ShortNamesConst.R_Report_OP;
          break;
      case DataOpsIdentifierConstants.MAP_ANNOTATE_OP:
          strOperationType = ShortNamesConst.AUTO_OP;
          break;
      default:
          strOperationType = operationType;
          break;
  }
  let fontColor = '#fff';
  if(data.blockColor === 'yellow' || fontColor.blockColor === 'orange'){
      fontColor = '#000'
  }

  let rootBlockType = '';
  if(data.blockCreationType === BlockCreationType.IMPORT_SOURCE_TABLE){
    rootBlockType = "DATA";
  } else if (data.blockCreationType === BlockCreationType.IMPORT_META_TABLE) {
    rootBlockType = "META DATA";
  }
  let blockStatusImg = '';

  if(data.blockSyncFlag === BlockStatus.VALID){
    blockStatusImg = validImg;
  } else if(data.blockSyncFlag === BlockStatus.INVALID){
    blockStatusImg = invalidImg;
  } else if(data.blockSyncFlag === BlockStatus.WARNING){
    blockStatusImg = warningImg;
  } else if(data.blockSyncFlag === BlockStatus.VIRTUAL){
    blockStatusImg = virualImg;
  }
  return (
    <>
      <div className="block-wrapper" style={{borderColor: data.blockColor}} id={`block-tooltip-handle-${data.blockId}`}>
        <div className="header">
          <span className="block-save-type">
            <img src={data.blockSaveType === BlockSaveType.PERSISTENT ? persistentImg : tempImg} alt="Block Save Type" />
            {data.blockType === BlockType.FILE ? (<img src={fileImg} width="14" height="14" alt="Block File Type" />) : (null)}
            {data.isSampleFiltered === true ? (<i className="fa fa-filter sample-filter-icon" aria-hidden="true"></i>) : (null)}
          </span>
          {data.dataop !== '' && data.dataop !== null && data.dataop.indexOf("Reference") !== -1 ? (<img src={referencePointImg} width="16" height="16" className="reference-point-logo" alt="Block File Type" />) : (null)}
          {data.blockCreationType === BlockCreationType.IMPORT_SOURCE_TABLE ? (<img src={sourceTable} width="14" height="14" className="common-icon-location" alt="source table" />) : (null)}
          {data.blockCreationType === BlockCreationType.IMPORT_META_TABLE ? (<img src={metaTable} width="14" height="14" className="common-icon-location" alt="metadata table" />) : (null)}
          {data.blockCreationType === BlockCreationType.DATA_COPY || data.blockCreationType === BlockCreationType.DEF_COPY ? (<img src={copyDataImg} width="14" height="14" className="common-icon-location" alt="copy table" />) : (null)}
          {data.blockCreationType === BlockCreationType.DATA_LINK ? (<img src={linkDataImg} width="14" height="14" className="common-icon-location" alt="metadata table" />) : (null)}
          {data.blockCreationType === BlockCreationType.TIMELINE ? (<img src={timelineImg} width="14" height="14" className="common-icon-location" alt="metadata table" />) : (null)}
          {data.blockType !== BlockType.TIMELINE && blockStatusImg !== '' ? (<span className="block-status"><img src={blockStatusImg} alt="Block Status" /></span>) : (null)}
        </div>
        <div className="content">
          <div className="operation-type text">{data.isLeftPreWhere === true ? (<img src={leftPreWhere} width="14" height="14" className="left-pre-where-icon" alt="Left Pre-Where" />) : (null)}{strOperationType}{rootBlockType}{data.isRightPreWhere === true ? (<img src={rightPreWhere} width="14" height="14" className="right-pre-where-icon" alt="Right Pre-Where" />) : (null)}</div>
          <div className="engine-logo">
            {data.functionInputBlock === 1 ? (<img src={downImg} width="30" height="30" className="inputblock-arrow" alt="Input Block Logo" />) : (null)}
            {data.functionOutputBlock === 1 ? (<img src={upImg} width="30" height="30" className="outputblock-arrow" alt="Output Block Logo" />) : (null)}
            {data.engineCode === 2 ? (<img src={sasLogo} alt="Engine Logo" />) : (null)}
            {data.engineCode === 3 ? (<img src={rLogo} alt="Engine Logo" />) : (null)}
            {data.isPostInitialize === true ? (<i className="fa fa-lightbulb-o post-initialize-icon" title="Post Initialize" aria-hidden="true"></i>) : (null)}
          </div>
          <div className="block-name text">{data.label}</div>
        </div>
        <div className="footer text" style={{color:fontColor, backgroundColor:data.blockColor}}>{data.tableExists === 1 ? (data.blockRowCount === -1 ? 0 : data.blockRowCount) : ''}</div>
      </div>
      <Handle
        type="source"
        position={Position.Top}
        style={{ top: "0px" }}
        id="a"
      />
      <Handle
        type="source"
        position={Position.Right}
        style={{ right: "0px" }}
        id="b"
      />
      <Handle
        type="source"
        position={Position.Bottom}
        style={{ bottom: "0px" }}
        id="c"
      />
      <Handle
        type="source"
        position={Position.Left}
        style={{ left: "0px" }}
        id="d"
      />
    </>
  );
});

/*
<Tooltip placement="auto" isOpen={tooltipOpen} className="am-tooltip" autohide={false} target={`block-tooltip-handle-${data.blockId}`} toggle={(event) => setTooltipOpen(!tooltipOpen)}>
  <div className="tooltip-item">
    <span>Left Pre-Where</span>
    <div>DOMAIN="DM"</div>
  </div>
  <div className="tooltip-item">
    <span>Right Pre-Where</span>
    <div>DOMAIN="DM"</div>
  </div>
  <div className="tooltip-item">
    <span>Post Initialize</span>
    <div>DOMAIN="DM"</div>
  </div>
</Tooltip >
*/
