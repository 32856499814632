import React from 'react';
import { Input } from './StandardInput';

export const DataOpsCopyFromFlagCell = ({ tableManager, value, data, column, colIndex, rowIndex }) => {
    if (typeof(tableManager) === 'undefined') {
      return (null)
    } else {
      return (
          <div className='rgt-cell-inner' style={{display: 'flex', alignItems: 'center', overflow: 'hidden'}}>
              <DataOpsCopyFromFlagCellClass dataItem={data} field={column.field} value={typeof(data[column.field]) !== 'undefined' ? data[column.field]: value} column={column} tableManager={tableManager}/>
          </div>
      )
    }
}

class DataOpsCopyFromFlagCellClass extends React.Component {

    handleChange(e) {
      this.props.dataItem[this.props.field] = typeof(e) !== "undefined" ? e.target.value : '';
      this.props.tableManager.rowsApi.setRows(this.props.tableManager.rowsApi.rows)
    }

    render() {
        var disabled = true;
        //fetching scale direction value
        //if scale Direction ="All" , then this cell is enable else its disable
        global.processBlockControlValues.forEach((control, index) => {
            if (control.controlId === 21) {
                if(global.processBlockControlValues[index].controlValue === "All" && this.props.dataItem['rowId'] !== ''){
                    disabled = false;
                }
            }
          });
          if(disabled){
              if(this.props.dataItem[this.props.field] !== ""){
                this.props.dataItem[this.props.field] = "";
                this.handleChange();
              }
          }
        return (
            <>
              <Input className="width-full" onChange={this.handleChange.bind(this)} value={this.props.dataItem[this.props.field]} length={40} disabled={disabled}/>
            </>
        );
    }
}
