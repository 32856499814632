import React  from "react";
import axios from 'axios';
import $ from 'jquery';
import cloneDeep from 'lodash/cloneDeep';
import restClient from '../../../restClient';
import { GET_ONE, GET_ALL } from '../../../restClient/types';
import template from "./RichGridTable.jsx";
import { BasicCheckBoxCell, NewColumnSpanCell } from "../DataOperation/common";
import DataOpsIdentifierConstants from '../../DataOpsIdentifierConstants';
import { connect } from 'react-redux';
import MessageTypeConst from "../../MessageTypeConst";
import { setMessage } from '../../../actions/actionNotification';

const getColumnName = (string, isDynamicGrid = false) => {
  return isDynamicGrid === true ? string : string.replace(/([a-z])([A-Z])/g, '$1 $2').replace(/([A-Z])([A-Z][a-z])/g, '$1 $2');
};

const checkboxFieldList = ['selected'];

class RichGridTable extends React.Component {
  constructor(props) {
    super(props);

    let { height, columns, ref, primaryColumn, resize, initialFilter, filterable, initialColumnFilter, filterLogic, columnProperty, selectedField, rows, dragAndDrop, controlId, rowClick, isSingleRowSelectionEnable, pageable, total, take, skip, pagination, sortable, gridClassName, isDynamicGrid, disableDefaultSortTable, isPaginated, showSearch, showColumnVisibilityManager } = this.buildGridData(props);
    this.rowsRef = React.createRef();
    this.rowsRef.current = [];
    this.state = {
      filter: initialFilter,
      primaryColumn: primaryColumn,
      columns: columns,
      gridData: rows,
      originGridData:props.rows,
      gridHeight: height,
      ref: ref,
      resize: resize,
      // filterable: filterable,
      sortable:sortable,
      columnProperty: columnProperty,
      selectedField: selectedField,
      dragAndDrop: dragAndDrop,
      controlId: controlId,
      rowClick: rowClick,
      pageable: pageable,
      total: total,
      dataState: { take: take, skip: skip },
      pagination: pagination,
      sort:[{
        field: "column",
        dir: "asc"
      }],
      allowUnsort: false,
      multiple: false,
      gridClassName: gridClassName,
      isSingleRowSelectionEnable:isSingleRowSelectionEnable,
      disableDefaultSortTable: disableDefaultSortTable,
      isPaginated: isPaginated,
      showSearch: showSearch,
      showColumnVisibilityManager: showColumnVisibilityManager
    };

    this.setGridData();
  }

  UNSAFE_componentWillReceiveProps(nextProps){
    if(JSON.stringify(nextProps) !== '') {
      let {rows, columns, columnProperty} = this.buildGridData(nextProps)
        this.setState({
            originGridData: rows,
            gridData: rows,
            columns: columns,
            columnProperty: columnProperty
        }, () => {
          this.widgetRef({props: {data: rows, refs: this.state.ref}})
          this.setLastRowClass()
        });
    }
  }

  buildGridData = (props) => {
    var height = 300;
    var columns = [];
    var ref = '';
    var primaryColumn = '';
    var resize = false;
    var filterable = false;
    var initialColumnFilter = [];
    var filterLogic = "and";
    var columnProperty = [];
    var selectedField = '';
    var rows = [];
    var dragAndDrop = false;
    var controlId = '';
    var rowClick = false;
    var isSingleRowSelectionEnable = false;
    var pageable= false;
    var total = null;
    var take = 50;
    var skip = 50;
    var pagination = {};
    var sortable = false;
    var gridClassName = '';
    var isDynamicGrid = typeof(props.isDynamicGrid) !== 'undefined' ? props.isDynamicGrid : false;
    var disableDefaultSortTable = true;
    var isPaginated = false;
    var showSearch = false;
    var showColumnVisibilityManager = false;

    if (typeof(props.rows) !== 'undefined') {
      rows = props.rows;
    }

    if (rows.length > 0) {
      for (var key in rows[0]) {
        if (rows[0].hasOwnProperty(key)) {
          if (rows[0][key] === null || typeof (rows[0][key]) !== 'object') {
            columns = [...columns, {field: key, label: getColumnName(key, isDynamicGrid)}];
          }
        }
      }
    }

    if (typeof(props.id) !== 'undefined') {
      ref = props.id;
    }

    if (typeof(props.height) !== 'undefined') {
      height = parseInt(props.height, 10);
    }

    if (typeof(props.columns) !== 'undefined') {
      var columnFieldList = props.columns.columnField.split(',');
      var columnTitleList = [];
      if (typeof(props.columns.columnTitle) !== 'undefined') {
          columnTitleList = props.columns.columnTitle.split(',');
      }

      if (columnFieldList.length > 0) {
        columns = [];
        columnFieldList.forEach((value, index) => {
          columns = [...columns, {field: value, title: (typeof(columnTitleList[index]) !== 'undefined') ? columnTitleList[index] : getColumnName(value, isDynamicGrid)}];
        });
      }
    }

    if (typeof(props.primaryColumn) !== 'undefined') {
      primaryColumn = props.primaryColumn;
    } else {
      if (columns.lenght > 0) {
          primaryColumn = columns[0].columnField;
      } else {
        primaryColumn = '';
      }
    }

    if (typeof(props.resize) !== 'undefined') {
      resize = props.resize;
    }

    if (typeof(props.filterable) !== 'undefined') {
      filterable = props.filterable;
    }

    if (typeof(props.sortable) !== 'undefined') {
      sortable = props.sortable;
    }

    if (typeof(props.filterLogic) !== 'undefined') {
      filterLogic = props.filterLogic;
    }

    if (typeof(props.initialColumnFilter) !== 'undefined') {
      initialColumnFilter = props.initialColumnFilter;
    }

    if (typeof(props.columnProperty) !== 'undefined') {
      columnProperty = props.columnProperty;
      columns.forEach((column, columnIndex) =>{
        if (column.field === 'columnId') {
          columns[columnIndex] = {...column, ...{visible:false}};
        } else if (column.field === 'selected') {
          columns[columnIndex] = {...column, ...{filterable: false, width: "25px",sortable:false, cell:BasicCheckBoxCell, resizable: false, orderIndex: 1}};
        } else if (column.field === 'column') {
          columns[columnIndex] = {...column, ...{filterable: false, title: "Variable", width: "150px", cell:NewColumnSpanCell, resizable: false, sortable:true }};
        } else if (column.field === 'label') {
          columns[columnIndex] = {...column, ...{filterable: false, width: "260px",sortable:false, resizable: false }};
        } else if (column.field === 'type') {
          columns[columnIndex] = {...column, ...{filterable: false, width: "45px",sortable:false, resizable: false, className: "text-center"}};
        } else if (column.field === 'length') {
          columns[columnIndex] = {...column, ...{filterable: false, filter: "numeric", width: "50px",sortable:false, resizable: false, className:"text-center"}};
        } else if(column.field === 'columnFormat'){
          columns[columnIndex] = {...column, ...{visible: false}}
        } else {
          columns[columnIndex] = {...column,...{sortable:false}};
        }
      });

      columns.forEach((column, columnIndex) =>{
        columnProperty.forEach((property, index) => {
            if(property.field.toLowerCase() === column.field.toLowerCase()) {
                columns[columnIndex] = {...column, ...property};
                return;
            }
        })
      })
    }

    columns.forEach((column, columnIndex) =>{
        let cellObject = {}
        if (typeof(column.cell) !== 'undefined') {
          cellObject = {cellRenderer: column.cell};
          delete column.cell;
        }

        if (typeof(column.title) !== 'undefined') {
          cellObject = Object.assign(cellObject, {label: column.title})
          delete column.title
        }

        if (typeof(column.show) !== 'undefined') {
          cellObject = Object.assign(cellObject, {visible: column.show})
          delete column.show
        }

        if (typeof(column.width) === 'undefined') {
          cellObject = Object.assign(cellObject, {width: 'auto'})
          delete column.show
        }

        if (typeof(column.field) !== 'undefined' && column.field === 'selected') {
          cellObject = Object.assign(cellObject, {headerCellRenderer: this.headerCellRenderer})
        }

        if (typeof(column.resizable) === 'undefined') {
          cellObject = Object.assign(cellObject, {resizable: false})
        }

        columns[columnIndex] = {...column, ...cellObject};
    })

    columns.sort((a, b) => a.visible - b.visible || a.orderIndex - b.orderIndex)
    columns.forEach((column, columnIndex) =>{
        columns[columnIndex] = {...{id: (checkboxFieldList.indexOf(column.field) > -1 ? "checkbox" : columnIndex+1)}, ...column};
    })

    if (columns.filter(column => column.field === 'id').length === 0) {
      columns.push({id: columns.length, field: 'id', visible: false})
    } else {
      columns.filter(column => column.field === 'id').map(column => {
        column.visible = false;
        return column
      })
    }

    if (ref === 'data-ops-process-grid') {
      columns.filter(column => column.field === 'id').map(column => {
        column.visible = true;
        return column
      })
    }

    if (typeof(props.selectedField) !== 'undefined') {
      selectedField = props.selectedField;
    }

    if (typeof(props.controlId) !== 'undefined') {
      controlId = props.controlId;
    }

    if (typeof(props.rowClick) !== 'undefined') {
      rowClick = props.rowClick;
    }

    if (typeof(props.isSingleRowSelectionEnable) !== 'undefined') {
      isSingleRowSelectionEnable = props.isSingleRowSelectionEnable;
    }

    if (typeof(props.pageable) !== 'undefined') {
      pageable = props.pageable;
    }

    if (typeof(props.total) !== 'undefined') {
      total = props.total;
    }

    if (typeof(props.take) !== 'undefined') {
      take = props.take;
    }

    if (typeof(props.skip) !== 'undefined') {
      skip = props.skip;
    }

    if (typeof(props.dragAndDrop) !== 'undefined') {
      dragAndDrop = props.dragAndDrop;
      global.dragAndDropRow = {start: '', end: ''};
    }

    if (typeof(props.pagination) !== 'undefined') {
      pagination = props.pagination;
    }

    if (typeof(props.gridClassName) !== 'undefined') {
      gridClassName = props.gridClassName;
    }

    if (typeof(props.disableDefaultSortTable) !== 'undefined') {
      disableDefaultSortTable = props.disableDefaultSortTable;
    }

    if (typeof(props.isPaginated) !== 'undefined') {
      isPaginated = props.isPaginated;
    }

    if (typeof(props.showSearch) !== 'undefined') {
      showSearch = props.showSearch;
    }

    if (typeof(props.showColumnVisibilityManager) !== 'undefined') {
      showColumnVisibilityManager = props.showColumnVisibilityManager;
    }

    const initialFilter = {
            logic: filterLogic,
            filters: initialColumnFilter
        };

    if (rows.length > 0 && typeof(rows[0].id) === 'undefined') {
      rows.forEach((row, rowIndex) =>{
          rows[rowIndex] = {...row, ...{id: rowIndex+1}};
      })
    }

    return {
      height: height, columns: columns, ref: ref, primaryColumn: primaryColumn, initialFilter: initialFilter, resize: resize, filterable: filterable, initialColumnFilter: initialColumnFilter, filterLogic: filterLogic, columnProperty: columnProperty, selectedField: selectedField, rows: rows, dragAndDrop: dragAndDrop, controlId: controlId, rowClick: rowClick, isSingleRowSelectionEnable: isSingleRowSelectionEnable, pageable: pageable, total: total, take: take, skip: skip, pagination: pagination, sortable: sortable, gridClassName: gridClassName, isDynamicGrid: typeof(props.isDynamicGrid) !== 'undefined' ? props.isDynamicGrid : false,  disableDefaultSortTable: disableDefaultSortTable, isPaginated: isPaginated, showSearch: showSearch, showColumnVisibilityManager: showColumnVisibilityManager
    }
  }

  onRowsRequest = async (requestData, tableManager) => {
    let dataState = this.state.dataState;
    let page = (requestData.from / dataState.take) + 1;
    if (requestData.from === 0 || (this.state.gridData.length / dataState.take) === page) {
      this.rowsRef.current = tableManager.asyncApi.mergeRowsAt(this.rowsRef.current, this.state.gridData, requestData.from)
      const { searchText, setSearchText } = tableManager.searchApi;
      setSearchText(requestData.searchText)
      this.setState({gridData: this.state.gridData, total: this.state.total})
      return {
            rows: this.state.gridData,
            totalItems: this.state.total
          };
    }

    let method =GET_ONE;
    let params = {id: this.state.ref};
    if( typeof(this.state.pagination.method) !== 'undefined' && this.state.pagination.method === "GET_ALL"){
        method = GET_ALL;
    }
    if(typeof(this.state.pagination.params)!== 'undefined' && this.state.pagination.params !== null){
        params = this.state.pagination.params;
    }
    await axios.all([restClient(method, this.state.pagination.action, {...params, pagination:{ page: page, perPage: dataState.take }}, {'Tenant-ID': this.state.pagination.tenant_id})])
        .then(axios.spread((dataViewer) => {
            if(typeof(dataViewer.data) !== 'undefined') {
              this.rowsRef.current = tableManager.asyncApi.mergeRowsAt(this.rowsRef.current, dataViewer.data.records, requestData.from)
              this.setState({gridData: dataViewer.data.records, total: dataViewer.data.count})
              return {
                    rows: dataViewer.data.records,
                    totalItems: dataViewer.data.count
                  };
            }

          })).catch(error => {
            //this.props.setMessage("Error occurred while fetching data.", MessageTypeConst.ERROR_MESSAGE);
          });

  }

  onRowsReset = () => {
    this.rowsRef.current = [];
    this.setState({gridData: [], total: null})
  }

  componentDidMount = () => {
    if (document.querySelectorAll('.am-rich-grid-table[refs="'+this.state.ref+'"] .rgt-container').length > 0) {
      if (document.querySelectorAll('.am-rich-grid-table[refs="'+this.state.ref+'"] .rgt-container').length === 1) {
        document.querySelector('.am-rich-grid-table[refs="'+this.state.ref+'"] .rgt-container').style.setProperty('grid-template-columns', document.querySelector('.am-rich-grid-table[refs="'+this.state.ref+'"] .rgt-container').style.getPropertyValue('grid-template-columns').slice(0, -4)+'max-content')
      } else {
        document.querySelectorAll('.am-rich-grid-table[refs="'+this.state.ref+'"] .rgt-container').forEach(gridContainer => {
          gridContainer.style.setProperty('grid-template-columns', gridContainer.style.getPropertyValue('grid-template-columns').slice(0, -4)+'max-content')
        })
      }

      this.setLastRowClass();
    }
  }

  setLastRowClass = () => {
    if (document.querySelectorAll('.am-rich-grid-table[refs="'+this.state.ref+'"] .rgt-container').length > 0) {
      document.querySelectorAll('.am-rich-grid-table[refs="'+this.state.ref+'"] .rgt-container .rgt-cell').forEach(rowColumn => {
        if (rowColumn.classList.contains('last') === true) {
          rowColumn.classList.remove('last')
        }
      })
      document.querySelectorAll('.am-rich-grid-table[refs="'+this.state.ref+'"] .rgt-container .rgt-cell[data-row-index="'+this.state.gridData.length+'"]').forEach(lastRowColumn => {
        if (lastRowColumn.classList.contains('last') === false) {
          lastRowColumn.classList.add('last')
        }
      })
    }
  }
  onRowsChange = (rows) => {
    this.setState({gridData: rows}, () => {
      this.setGridData();
    })
  }

  setGridData(event=null) {
    let rows = cloneDeep(this.state.gridData)
    rows.map(row => {
      if (typeof(row.id) !== 'undefined' && this.state.ref !== 'data-ops-process-grid') {
        delete row.id;
      }
      return row
    })
    this.widgetRef({props: {data: rows, refs: this.state.ref}})
    global.gridData = rows;
    if (typeof(global.processBlockControlValues) !== 'undefined') {
      global.processBlockControlValues.forEach((control, index) => {
        if (control.controlId === 3 && this.state.controlId === control.controlId) {
          global.processBlockControlValues[index].controlValue = JSON.stringify(rows);
          return true;
        }
      });
      //case to handle data change event in grid other than main grid in global variable
      let actionName = '';
      global.processBlockControlValues.forEach((control, index) => {
        if (control.controlId === -1) {
          actionName = global.processBlockControlValues[index].controlValue;
        }
      });

      if(global.gridRef){
        let postGrid  = global.gridRef.filter(item => item.props.refs === 'post-initialization-grid');
        let targetGrid = this.state.ref;
        if(postGrid && targetGrid !== "" && targetGrid === 'post-initialization-grid'){
          if(postGrid.length > 0){
            global.processBlockControlValues.forEach((control, index) => {
              if (control.controlId === -94) {
                global.processBlockControlValues[index].controlValue = JSON.stringify(postGrid[0].props.data);;
              }
            });
          }
        }
      }

      switch (actionName) {
        case DataOpsIdentifierConstants.COMPARE_OP:
        case DataOpsIdentifierConstants.VARMAPPING_OP:
        case DataOpsIdentifierConstants.APPEND_OP:
        case DataOpsIdentifierConstants.JOIN_OP:
        case DataOpsIdentifierConstants.LINK_OP:
        case DataOpsIdentifierConstants.DECOLLAPSE_TWOTABLE_OP:
          if (typeof(global.processBlockControlValues) !== 'undefined') {
            let grid  = global.gridRef.filter(item => item.props.refs === this.state.ref);
            if (grid.length > 0) {
              global.processBlockControlValues.forEach((control, index) => {
                if ((control.controlId === 3 && this.state.ref === 'left-grid') || (control.controlId === 24 && this.state.ref === 'right-grid')) {
                  global.processBlockControlValues[index].controlValue = JSON.stringify(grid[0].props.data);
                  return true;
                }
              });
            }
          }
          if (typeof(global.processBlockControlValues) !== 'undefined') {
            let sourceMappedVariableList = [];
            let targetMappedVariableList = [];
            let sourceGrid = [];
            let targetGrid = [];
            let isDataCompareOp = false;
            global.processBlockControlValues.forEach((control, index) => {
              if (control.controlId === -1 && control.controlValue !== '' && control.controlValue === DataOpsIdentifierConstants.COMPARE_OP) {
                isDataCompareOp = true;
              }
              if (control.controlId === 3 && control.controlValue !== '') {
                sourceGrid = JSON.parse(control.controlValue);
              }
              if (control.controlId === 24 && control.controlValue !== '') {
                targetGrid = JSON.parse(control.controlValue);
              }
              if (control.controlId === 26 && control.controlValue !== '') {
                sourceMappedVariableList = JSON.parse(control.controlValue);
              }
              if (control.controlId === 27 && control.controlValue !== '') {
                targetMappedVariableList = JSON.parse(control.controlValue);
              }
            });
            if(isDataCompareOp ===  true) {
              sourceMappedVariableList.forEach((sourceMappedVariable, index) => {
                if (typeof(targetMappedVariableList[index]) !== 'undefined') {
                  const targetMappedVariable = targetMappedVariableList[index];
                  const sourceVariableGBY = sourceGrid.filter(sourceColumn => sourceColumn.column === sourceMappedVariable && sourceColumn.gby === true);
                  const targetVariableGBY = targetGrid.filter(targetColumn => targetColumn.column === targetMappedVariable && targetColumn.gby === true);

                  if (sourceVariableGBY.length === 0) {
                    sourceGrid.filter(sourceColumn => sourceColumn.column === sourceMappedVariable).forEach(sourceColumn => {
                      if  (document.getElementById('checkbox-gby-'+sourceColumn.columnId+'-left') !== null) {
                        document.getElementById('checkbox-gby-'+sourceColumn.columnId+'-left').click()
                        document.getElementById('checkbox-gby-'+sourceColumn.columnId+'-left').disabled = true;
                      }
                    })
                  }

                  if (targetVariableGBY.length === 0) {
                    targetGrid.filter(targetColumn => targetColumn.column === targetMappedVariable).forEach(targetColumn => {
                      if  (document.getElementById('checkbox-gby-'+targetColumn.columnId+'-right') !== null) {
                        if (!document.getElementById('checkbox-gby-'+targetColumn.columnId+'-right').checked) {
                          //document.getElementById('checkbox-gby-'+targetColumn.columnId+'-right').parentNode.parentNode.parentNode.parentNode.querySelector('.rgt-cell[data-row-id="'+document.getElementById('checkbox-gby-'+targetColumn.columnId+'-left').parentNode.parentNode.parentNode.dataset["rowId"]+'"][data-column-id="4"] .am-dropdown .Dropdown-placeholder').innerText = "A"
                        }
                        document.getElementById('checkbox-gby-'+targetColumn.columnId+'-right').click()
                        document.getElementById('checkbox-gby-'+targetColumn.columnId+'-right').disabled = true;
                      }
                    })
                  }
                }
              })
            }
          }
        break;
        case DataOpsIdentifierConstants.DATAOPS_OP:
        if(global.gridRef){
          let dataOpsProcessGrid = global.gridRef.filter(item => item.props.refs === 'data-ops-process-grid');
          if(dataOpsProcessGrid){
            if(dataOpsProcessGrid.length > 0){
              global.processBlockControlValues.forEach((control, index) => {
                if (control.controlId === 23) {
                  global.processBlockControlValues[index].controlValue = JSON.stringify(dataOpsProcessGrid[0].props.data);
                }
              });
            }
          }
        }
        break;
        case DataOpsIdentifierConstants.REFERENCEPOINT_COMPOSITE_OP:
        case DataOpsIdentifierConstants.REFERENCEPOINT_REPLACE_OP:
        if(global.gridRef){
          let startOptionsGrid = global.gridRef.filter(item => item.props.refs === 'reporting-ops-composite-grid-startOption');
          let stopOptionsGrid = global.gridRef.filter(item => item.props.refs === 'reporting-ops-composite-grid-stopOption');
          if(startOptionsGrid){
            if(startOptionsGrid.length > 0){
              global.processBlockControlValues.forEach((control, index) => {
                if (control.controlId === 32) {
                  global.processBlockControlValues[index].controlValue = JSON.stringify(startOptionsGrid[0].props.data);;
                }
              });
            }
          }if(stopOptionsGrid){
            if(stopOptionsGrid.length > 0){
              global.processBlockControlValues.forEach((control, index) => {
                if (control.controlId === 33) {
                  global.processBlockControlValues[index].controlValue = JSON.stringify(stopOptionsGrid[0].props.data);;
                }
              });
            }
          }
        }
        break;
        case DataOpsIdentifierConstants.REPORTINGOPS_DESCRIPTIVE_OP:
        case DataOpsIdentifierConstants.REPORTINGOPS_CATEGORICAL_OP:
        case DataOpsIdentifierConstants.REPORTINGOPS_CATEGORICAL_TOTAL_OP:
        case DataOpsIdentifierConstants.REPORTINGOPS_CATEGORICAL_HIERARCHICAL_OP:
        case DataOpsIdentifierConstants.REPORTINGOPS_CATEGORICAL_HIERARCHICAL_UNIQUE_OP:
        case DataOpsIdentifierConstants.REPORTINGOPS_CATEGORICAL_TOTAL_UNIQUE_OP:
        case DataOpsIdentifierConstants.REPORTINGOPS_CUSTOM_OP:
        let targetGrid = this.state.ref;
        if(global.gridRef){
          let columnGrid = global.gridRef.filter(item => item.props.refs === 'column-grid-table');
          let rowGrid = global.gridRef.filter(item => item.props.refs === 'row-grid-table');
          let analysisGrid = global.gridRef.filter(item => item.props.refs === 'reporting-ops-analysis-grid');
          let gbyGrid = global.gridRef.filter(item => item.props.refs === 'gby-grid-table');
          if(columnGrid && targetGrid !== "" && targetGrid === 'column-grid-table'){
            if(columnGrid.length > 0){
              global.processBlockControlValues.forEach((control, index) => {
                if (control.controlId === 86) {
                  global.processBlockControlValues[index].controlValue = JSON.stringify(columnGrid[0].props.data);;
                }
              });
            }
          }
          if(rowGrid && targetGrid !== "" && targetGrid === 'row-grid-table'){
            if(rowGrid.length > 0){
              global.processBlockControlValues.forEach((control, index) => {
                if (control.controlId === 85) {
                  global.processBlockControlValues[index].controlValue = JSON.stringify(rowGrid[0].props.data);;
                }
              });
            }
          }
          if(analysisGrid && targetGrid !== "" && targetGrid === 'reporting-ops-analysis-grid'){
            if(analysisGrid.length > 0){
              global.processBlockControlValues.forEach((control, index) => {
                if (control.controlId === 87) {
                  global.processBlockControlValues[index].controlValue = JSON.stringify(analysisGrid[0].props.data);;
                }
              });
            }
          }
          if(gbyGrid && targetGrid !== "" && targetGrid === 'gby-grid-table'){
            if(gbyGrid.length > 0){
              global.processBlockControlValues.forEach((control, index) => {
                if (control.controlId === 88) {
                  global.processBlockControlValues[index].controlValue = JSON.stringify(gbyGrid[0].props.data);;
                }
              });
            }
          }
        }
        break;
        case DataOpsIdentifierConstants.PROCREPORT_ANALYSIS_CHAR_OP:
        case DataOpsIdentifierConstants.PROCREPORT_ANALYSIS_NUM_OP:
        case DataOpsIdentifierConstants.R_Report_OP:
        if(global.gridRef){
          let defineProcessGrid = global.gridRef.filter(item => item.props.refs === 'define-grid-table');
          if(defineProcessGrid){
            if(defineProcessGrid.length > 0){
              global.processBlockControlValues.forEach((control, index) => {
                if (control.controlId === 14) {
                  global.processBlockControlValues[index].controlValue = JSON.stringify(defineProcessGrid[0].props.data);;
                }
              });
            }
          }
          let breakProcessGrid = global.gridRef.filter(item => item.props.refs === 'break-grid-table');
          if(breakProcessGrid){
            if(breakProcessGrid.length > 0){
              global.processBlockControlValues.forEach((control, index) => {
                if (control.controlId === 19) {
                  global.processBlockControlValues[index].controlValue = JSON.stringify(breakProcessGrid[0].props.data);;
                }
              });
            }
          }
          let computeProcessGrid = global.gridRef.filter(item => item.props.refs === 'compute-grid-table');
          if(computeProcessGrid){
            if(computeProcessGrid.length > 0){
              global.processBlockControlValues.forEach((control, index) => {
                if (control.controlId === 18) {
                  global.processBlockControlValues[index].controlValue = JSON.stringify(computeProcessGrid[0].props.data);;
                }
              });
            }
          }
        }
        break;
        case DataOpsIdentifierConstants.PIVOT_MATRIX:
          if(global.gridRef){
            let pivotMatrixProcessGrid = global.gridRef.filter(item => item.props.refs === 'data-operation-pivot-matrix-grid');
            if (pivotMatrixProcessGrid.length > 0) {
              let dataRows = cloneDeep(pivotMatrixProcessGrid[0].props.data);
              dataRows.map(row => row.columnList.length = 0)
              global.processBlockControlValues.forEach((control, index) => {
                if (control.controlId === 91) {
                  global.processBlockControlValues[index].controlValue = JSON.stringify(dataRows);
                }
              });
            }
          }
        break;
        default:
        break;
      }
    }
  }

  widgetRef(element) {
    if (typeof(global.gridRef) === 'undefined') {
        global.gridRef = [];
    }

    if (element !== null) {
      var isNewElement = true;
      global.gridRef.forEach((grid, index) => {
        if (typeof(element.props.refs) !== 'undefined' && grid.props.refs === element.props.refs) {
          global.gridRef[index] = element;
          isNewElement = false;
        }
      })

      if (isNewElement) {
        global.gridRef.push(element);
      }
    }
  }

  headerOnChangeEvent = (onChange, tableManager, checked) => {
    onChange()
    tableManager.rowsApi.rows.filter(row => typeof(global.ColumnAlwaysNeedsToBeDisplay) === 'undefined' || (typeof(global.ColumnAlwaysNeedsToBeDisplay) !== 'undefined') && global.ColumnAlwaysNeedsToBeDisplay.indexOf(row.column) === -1).map(row => {
      row.selected = checked
      if (typeof(row.study) !== 'undefined') {
        row.study.map(studyObject => {
          studyObject.selected = row.selected
          return studyObject
        })
      }

      return row
    })
    tableManager.rowsApi.setRows(tableManager.rowsApi.rows)
  }

  headerCellRenderer = ({ tableManager, column, mode, ref, checked, disabled, indeterminate, onChange }) => {
    return (
      <>
        <input type="checkbox" className="am-checkbox" id={`checkbox-header-${this.state.ref}`} onChange={(event) => this.headerOnChangeEvent(onChange, tableManager, indeterminate ? !indeterminate : !checked) } checked={ checked } disabled={ disabled } />
        <label className="am-checkbox-label" htmlFor={`checkbox-header-${this.state.ref}`}></label>
      </>
    )
  }

  onRowClick = ({ rowIndex, data, column, isEdit, event }, tableManager) => {
    if (document.getElementsByClassName('row-selectable').length > 0 && event.target.parentNode.parentNode.parentNode.parentNode.classList.contains('disabled-grid') === false) {
      if (document.getElementsByClassName('row-selectable single').length > 0) {
        tableManager.rowsApi.rows.filter(row => row.id !== data.id && row.selected === true).map(row => {
          row.selected = false

          for(var i = 0; i < event.target.parentNode.parentNode.parentNode.querySelectorAll('.rgt-row-'+row.id).length; i++) {
            event.target.parentNode.parentNode.parentNode.querySelectorAll('.rgt-row-'+row.id)[i].classList.remove('rgt-row-selected')
          }

          tableManager.rowSelectionApi.toggleRowSelection(row.id)
          return row
        })
        tableManager.rowsApi.setRows(tableManager.rowsApi.rows)
      }

      tableManager.rowSelectionApi.toggleRowSelection(data.id)
      data['selected'] = !data['selected'];
      tableManager.rowsApi.setRows(tableManager.rowsApi.rows)
      if (document.getElementsByClassName('row-selectable single').length > 0) {
        setTimeout(() => {
          tableManager.rowsApi.rows.filter(row => row.id === data.id && row.selected === true).map(row => {
            for(var i = 0; i < event.target.parentNode.parentNode.parentNode.querySelectorAll('.rgt-row-'+row.id).length; i++) {
              if (event.target.parentNode.parentNode.parentNode.querySelectorAll('.rgt-row-'+row.id)[i].classList.contains('rgt-row-selected') === false) {
                event.target.parentNode.parentNode.parentNode.querySelectorAll('.rgt-row-'+row.id)[i].classList.add('rgt-row-selected')
              }
            }
          })
        }, 1)
      }
    }

    if (typeof(column.field) !== 'undefined' && ['column', 'label', 'type', 'length'].indexOf(column.field) > -1 && document.getElementsByClassName('row-clickable').length > 0) {
      if (event.target.parentNode.parentNode.parentNode.querySelectorAll('.rgt-row-'+rowIndex).length > 0) {
        if (document.getElementsByClassName('pivot-grid row-clickable').length == 0) {
          for(var i = 0; i < event.target.parentNode.parentNode.parentNode.querySelectorAll('.rgt-cell').length; i++) {
            if (event.target.parentNode.parentNode.parentNode.querySelectorAll('.rgt-cell')[i].classList.contains('row-black') === true) {
              event.target.parentNode.parentNode.parentNode.querySelectorAll('.rgt-cell')[i].classList.remove('row-black')
            }
          }
        }

        if (document.getElementsByClassName('pivot-grid row-clickable').length > 0) {
          tableManager.rowsApi.rows.filter(row => row.column === data.column).map(row => {
            row.selected = !row.selected
            return row
          })
          tableManager.rowsApi.setRows(tableManager.rowsApi.rows)
        }

        for(var i = 0; i < event.target.parentNode.parentNode.parentNode.querySelectorAll('.rgt-row-'+rowIndex).length; i++) {
          if (event.target.parentNode.parentNode.parentNode.querySelectorAll('.rgt-row-'+rowIndex)[i].classList.contains('row-black') === false) {
            event.target.parentNode.parentNode.parentNode.querySelectorAll('.rgt-row-'+rowIndex)[i].classList.add('row-black')
          } else {
            event.target.parentNode.parentNode.parentNode.querySelectorAll('.rgt-row-'+rowIndex)[i].classList.remove('row-black')
          }
        }

        if (event.target.parentNode.closest('.am-rich-grid-table') !== null && event.target.parentNode.closest('.am-rich-grid-table').getAttribute('refs') !== null) {
          const gridPosition = event.target.parentNode.closest('.am-rich-grid-table').getAttribute('refs').indexOf('right-grid') > -1 ? 'target' : 'source';
          const inputStructureGrids  = event.target.parentNode.closest('.am-rich-grid-table').getAttribute('refs').indexOf('-input-structure') > 0 ? '-input-structure' : '';
          for(var i = 0; i < document.querySelectorAll('.grid-mapper .am-rich-grid-table[refs="'+(gridPosition === 'target' ? 'left' : 'right')+'-grid'+inputStructureGrids+'"] .rgt-container .rgt-cell').length; i++) {
            if (document.querySelectorAll('.grid-mapper .am-rich-grid-table[refs="'+(gridPosition === 'target' ? 'left' : 'right')+'-grid'+inputStructureGrids+'"] .rgt-container .rgt-cell')[i].classList.contains('row-black') === true) {
              document.querySelectorAll('.grid-mapper .am-rich-grid-table[refs="'+(gridPosition === 'target' ? 'left' : 'right')+'-grid'+inputStructureGrids+'"] .rgt-container .rgt-cell')[i].classList.remove('row-black')
            }
          }
          if (typeof(global.columnMapperNodeList) !== 'undefined') {
            global.columnMapperNodeList.filter(columnMapperNode => columnMapperNode.node[gridPosition] === (rowIndex - 1)).forEach(columnMapperNode => {
              if (document.querySelectorAll('.grid-mapper .am-rich-grid-table[refs="'+(gridPosition === 'target' ? 'left' : 'right')+'-grid'+inputStructureGrids+'"] .rgt-container .rgt-row-'+(columnMapperNode.node[gridPosition === 'target' ? 'source' : 'target'] + 1)).length > 0) {
                for(var i = 0; i < document.querySelectorAll('.grid-mapper .am-rich-grid-table[refs="'+(gridPosition === 'target' ? 'left' : 'right')+'-grid'+inputStructureGrids+'"] .rgt-container .rgt-row-'+(columnMapperNode.node[gridPosition === 'target' ? 'source' : 'target'] + 1)).length; i++) {
                  if (document.querySelectorAll('.grid-mapper .am-rich-grid-table[refs="'+(gridPosition === 'target' ? 'left' : 'right')+'-grid'+inputStructureGrids+'"] .rgt-container .rgt-row-'+(columnMapperNode.node[gridPosition === 'target' ? 'source' : 'target'] + 1))[i].classList.contains('row-black') === false) {
                    document.querySelectorAll('.grid-mapper .am-rich-grid-table[refs="'+(gridPosition === 'target' ? 'left' : 'right')+'-grid'+inputStructureGrids+'"] .rgt-container .rgt-row-'+(columnMapperNode.node[gridPosition === 'target' ? 'source' : 'target'] + 1))[i].classList.add('row-black');
                  }
                }
              }
            })

            if (gridPosition === 'target' && global.columnMapperNodeList.filter(columnMapperNode => columnMapperNode.node[gridPosition] === (rowIndex - 1)).length > 0) {
              global.columnMapperTargetSelected = data.column;
            } else {
              global.columnMapperTargetSelected = '';
            }
          }
        }
      }
    }
  }

  render() {
    return template.call(this);
  }
}

export default RichGridTable;
