
import React    from "react";
import axios from 'axios';
import $ from 'jquery';
import { connect } from 'react-redux';
import { createSelector } from 'reselect';
import restClient from '../../../restClient';
import { GET, CREATE } from '../../../restClient/types';
import template from "./RootNodeVersionManager.jsx";
import { setMessage } from './../../../actions/actionNotification';
import MessageTypeConst from '../../MessageTypeConst';
import { DataButton, TagValueCell, PermanentFlagCheckBoxCell } from '../DataOperation/common';
import { setTenantId } from './../../../actions/actionProcessFlow';
import moment from 'moment';

class RootNodeVersionManager extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            studyId:props.studyId,
            tableType:props.tableType,
            closeEvent:props.closeEvent,
            tenantId:props.tenantId,//aster for Tree view so use schema name
            schemaName:props.schemaName,
            processFlowTableId: props.processFlowTableId,
            processFlowList:props.processFlowList,
            activeProcessFlow:props.activeProcessFlow,
            tableList:[{
                id: 'version-grid',
                rows: [{"srcTableName":"","selected":false,"columnId":0,"blockTable":'',"tableVersion":"","tag":"","permanent":false,
                    "versionLimit":"","timestamp":"", "timestampModified": "", "username": "","data":'',"tableType":0}],
                resize: true,
                filterable: false,
                selectedField: "selected",
                columnProperty: [
                    { field: "srcTableName",className:"text-center", sortable:false,width:"200px" ,"title":"Node Name", show: true, orderIndex:1},
                    { field: "tableType",className:"text-center", show: false},
                    { field: "selected",className:"text-center", show: false, orderIndex:1},
                    { field: "blockTable",sortable:false, className:"text-center","title":"Table Name", show: true, orderIndex:2 },
                    { field: "columnId", show: false},
                    { field: "versionLimit",sortable:false,width:"150px" ,className:"text-center", show: false},
                    { field: "tableVersion", sortable:false,width:"100px" ,className:"text-center", show: true, orderIndex:3 },
                    { field: "tag", sortable:false,className:"text-center", width:"200px", cell:TagValueCell, show: true, orderIndex:4 },
                    { field: "permanent", sortable:false, width:"100px",className:"text-center", cell:PermanentFlagCheckBoxCell, show: true, orderIndex:5 },
                    //{ field: "timestamp", sortable:false , width:"150",className:"text-center", show: true, title:"Creation Date", orderIndex:6},
                    //{ field: "timestampModified", sortable:false , width:"150",className:"text-center", show: true, title:"Modified Date", orderIndex:7},
                    { field: "username", sortable:false ,className:"text-center", title:"User Name", show: true, orderIndex:8},
                    { field: "data", sortable:false, width:"80px",className:"text-center", cell:DataButton, show: true, orderIndex:9 },
                ]
            }]
        }

        this.closeDialog = this.closeDialog.bind(this);
        this.updateVersionDetails = this.updateVersionDetails.bind(this);
    }


    updateVersionDetails(event){
        event.target.disabled = true;
        let params = [];
        this.state.tableList[0].rows.filter(r => r.selected === true).forEach((row)=>{
            let item = {
                tableType:row.tableType,
                srcTableName:row.srcTableName,
                tableVersion:row.tableVersion,
                tag:row.tag === null ? '' : row.tag,
                permanentFlag:row.permanent ? 1 : 0
            };
            params.push(item);
        })
        if(params.length === 0){
            this.props.setMessage("No data change.All versions are as it is.",MessageTypeConst.SUCCESS_MESSAGE);
            this.closeDialog();
            return;
        }
        $(".loader").show();
        let versionUpdate = (headers, params) => restClient(CREATE, 'manageVersion/updateTableVersions?tableType='+this.state.tableType+'&updateVersionLimit=false&versionLimit=0&srcTableName=', params, headers);
        axios.all([versionUpdate({'Tenant-ID': this.state.schemaName}, {data: params})])
         .then(axios.spread((response) => {
            $(".loader").hide();
             if(response.data !== null && typeof(response.data) !== "undefined" && response.data.message !== null && typeof(response.data.message) !== "undefined"){
                this.closeDialog();
                this.props.setMessage(response.data.message, response.data.messageType);
             }
         })).catch(error => {
             $(".loader").hide();
            this.closeDialog();
            this.props.setMessage("Error occurred while updating table versions.",MessageTypeConst.ERROR_MESSAGE);
          });
    }

    componentDidMount(){
        this.props.setTenantId(this.state.schemaName);
        global.processBlockControlValues = [];
        global.processBlockControlValues.push({
            "controlId": -97,
            "controlType": -97,
            "controlValue": ""
          });
        global.processBlockControlValues.push({
            "controlId": -96,
            "controlType": -96,
            "controlValue": ""
        });
        let that = this;
        $(".loader").show();
        axios.all([restClient(GET, 'manageVersion/getTableVersions?tableType='+this.state.tableType+"&srcTableName=", {}, {'Tenant-ID': this.state.schemaName})])
        .then(axios.spread((response) => {
            if(response!= null && response.data !== null && typeof(response.data.messageType) !== "undefined"
            &&  response.data.messageType !== null && response.data.messageType === MessageTypeConst.WARNING_MESSAGE )
            {
                that.props.setMessage(response.data.message, response.data.messageType);
                $(".loader").hide();
            }
            if(response!= null && response.data !== null && typeof(response.data.versions) !== "undefined"
            &&  response.data.versions !== null && response.data.versions.length >0 )
            {
                let tableList = that.state.tableList;
                let versionLimit='';
                tableList[0].rows.pop();
                response.data.versions.forEach((version, index)=>{
                    versionLimit = version.versionLimit;
                    tableList[0].rows.push({
                        "selected":false,
                        "columnId":index,
                        "srcTableName":version.srcTableName,
                        "blockTable":version.tableName,
                        "tag":version.tag !== null ? version.tag: '',
                        "permanent":version.permFlag === 0 ? false : true,
                        "versionLimit":version.versionLimit,
                        "tableVersion":version.tableVersion,
                        "timestamp":version.audit !== null ? moment.utc(version.audit.createdAt).format('lll') : "",
                        "timestampModified":version.audit !== null ? moment.utc(version.audit.modifiedAt).format('lll') : "",
                        "username":version.audit !== null ? version.audit.createdBy : "",
                        "data":'',
                        "tableType":version.tableType
                    });
                })
                that.setState({tableList:tableList, dataVersionLimit:versionLimit});
            }
            $(".loader").hide();
        })).catch(error=>{
            $(".loader").hide();
            that.props.setMessage("Failed to fetch version.",MessageTypeConst.ERROR_MESSAGE);
          });
    }

    closeDialog(){
        this.state.closeEvent();
    }


    render() {
        return template.call(this);
    }
}

const mapStateToProps = createSelector(
    state => state.processFlow.processFlowList,
    state => state.processFlow.tenantId,
    state => state.processFlow.processFlowTable,
    state => state.processFlow.processFlowTableId,
    state => state.processFlow.activeProcessFlow,
    state => state.treeView.data,
    (processFlowList, tenantId, processFlowTable, processFlowTableId, activeProcessFlow, treeView) => ({
      processFlowList,
      tenantId,
      processFlowTable,
      processFlowTableId,
      activeProcessFlow,
      treeView
    })
  );
  const mapActionsToProps = {
    setMessage: setMessage,
    setTenantId:setTenantId,
  }
  export default connect(mapStateToProps, mapActionsToProps)(RootNodeVersionManager);
