import { BUILD_TREEVIEW_STATUS, SET_TREEVIEW_DATA, REDISPLAY_TREEVIEW_STATUS, TREEVIEW_REFRESH_STUDY_NODE } from './actionsTypes';

export const buildTreeViewStatus = build => ({
    type: BUILD_TREEVIEW_STATUS,
    payload: build
})

export const setTreeViewData = (treeView, reDisplay = false) => ({
    type: SET_TREEVIEW_DATA,
    payload: {treeView: treeView, reDisplay: reDisplay}
})

export const reDisplayTree = reDisplay => ({
    type: REDISPLAY_TREEVIEW_STATUS,
    payload: reDisplay
})
export const refreshStudyNode = refreshStudyNodeStatus => dispatch => {
  dispatch({
    type: TREEVIEW_REFRESH_STUDY_NODE,
    payload: refreshStudyNodeStatus
  });
}
