import React, { Component } from 'react';
import { Button } from './../DataOperation/common/Button/StandardButton';
import { Col, Container, Row } from 'reactstrap';
import { connect } from 'react-redux';
import { createSelector } from 'reselect';
import { PropTypes } from 'prop-types';
import moment from 'moment';
import { addStudy, addMultipleStudy, setStudyManager } from '../../../actions/actionStudy';
import { fetchProject, apiConnectProject, updateProjectList } from '../../../actions/actionProject';
import { buildTreeViewStatus } from '../../../actions/actionTreeView';
import { removeProcessFlowByStudyId, setALLProcessFlowRenderStatus, setActiveProcessFlow } from '../../../actions/actionProcessFlow';
import { setMessage } from '../../../actions/actionNotification';
import { DetailComponent } from './DetailComponent';
import restClient from '../../../restClient';
import { GET_LIST } from '../../../restClient/types';
import Dialog from 'react-dialog';
import RichGridTable from './../RichGridTable';
import './study.css';
import $ from 'jquery';
import MessageTypeConst from '../../MessageTypeConst';
import cloneDeep from 'lodash/cloneDeep';
import { saveSelectedStudy } from '../../../actions/actionUserPreference';

Dialog.propTypes = {
    height: PropTypes.oneOfType([
        PropTypes.string
    ]),
    width: PropTypes.oneOfType([
        PropTypes.string
    ])
}

class Study extends Component {
  constructor(props) {
      super(props);
      this.state = {
        items: [],
        visible: true,
        selectedProjectId:[],
        originalState:[]
      }
      this.onClickHandle = this.onClickHandle.bind(this);
      this.onClickCancel = this.onClickCancel.bind(this);
      this.toggleDialog = this.toggleDialog.bind(this);
  }

  fetchProjectList = (headers) => restClient(GET_LIST, 'project/list', {pagination: { page: 1, perPage: -1 }, sort: { field: 'id', order: 'ASC' }}, headers);

  UNSAFE_componentWillMount() {
    $(".loader").show();
    if (this.props.projectList.length === 0 || this.props.apiConnectProject) {
        this.props.fetchProject(this.props.userPreferenceStudyList);
        this.props.fetchProject();
        this.props.onApiConnectProject(false);
    } else {
      this.setState({ items: this.props.projectList.slice().map(obj=> Object.assign({},obj)), originalState: cloneDeep(this.props.projectList)  });
    }
  }

   componentDidMount() {
      $(".loader").hide();
   }

    onClickHandle = () => {
      let that = this;
      let activeStudyProcessFlowOpen = false;
      this.state.items.forEach((project) =>{
        if(project.study !== null && typeof(project.study) !== "undefined"){
          let selectedstudy = project.study.filter(item => item.selected === false);
          if(selectedstudy.length > 0){
            selectedstudy.forEach((studyNotSelected)=>{
              if (this.props.processFlowList.filter(processFlow => processFlow.schemaName === studyNotSelected.schemaName).length > 0) {
                activeStudyProcessFlowOpen = true;
              }
              this.props.removeProcessFlowByStudyId(studyNotSelected.studyId);
            })
          }
        }
      });

      this.state.items.map(item => {
        if (item.study.length > 0) {
          item.study.map(study => {
            that.props.addStudy(study);
          });
        }

        item.selected = item.study.filter(studyOjbect => studyOjbect.selected).length > 0
        return item
      })
      this.props.saveSelectedStudy(this.state.items);
      this.props.setALLProcessFlowRenderStatus(true);
      if (activeStudyProcessFlowOpen) {
        this.props.setActiveProcessFlow(0);
      }
      this.props.updateProjectList(this.state.items);

      if (this.props.studyManagerSchemaName !== '' && !(this.state.items.filter(project => project.selected === true && project.study !== null && typeof(project.study) !== "undefined" && project.study.filter(item => item.selected === true && item.schemaName === this.props.studyManagerSchemaName).length > 0).length > 0)) {
        this.props.setStudyManager('')
      }

      this.props.buildTreeViewStatus(true);
      this.props.history.goBack();
    }
    toggleDialog() {
      this.props.history.goBack();
    }
    onClickCancel(){
      this.props.updateProjectList(this.state.originalState);
      this.props.history.goBack();
    }
    UNSAFE_componentWillReceiveProps(nextProps) {
      $(".loader").hide();
      if(JSON.stringify(nextProps) !== '') {
        if(nextProps.message === "Error occurred while fetching project." && nextProps.messageType === MessageTypeConst.ERROR_MESSAGE){
          this.props.history.push("/process-flow-list");
        }
        if(nextProps.message === "Project not found. Please create new project." && nextProps.messageType === MessageTypeConst.WARNING_MESSAGE){
          this.props.history.push("/process-flow-list");
        }
        let items =  nextProps.projectList.slice().map(obj=> Object.assign({},obj));

        this.setState({ items: items, originalState: this.state.originalState.length === 0 ? cloneDeep(items) : this.state.originalState });
      }
    }

  render() {
    return (
      <div className="study">
        { this.state.visible &&
          <Dialog title="Study Select" width="550px" height="auto" modal={true} onClose={this.toggleDialog} buttons={[<Button primary={true} onClick={this.onClickHandle} autoFocus={true} key="btn-select">Select</Button>,<Button className="margins-cancel" onClick={this.onClickCancel} key="btn-cancel">Cancel</Button>]}>
            <Row className="justify-content-center add_study_pro">
              <Col md="12">
              {this.state.items.length > 0 ? (
                <RichGridTable {...{id: `select-study-grid`, rows: this.state.items, columnProperty: [
                  { field: "expanded", width: "25px", title:"", show: true, cell: DetailComponent, orderIndex: 1},
                  { field: "selected", width: "25px", title:"", show: true, className:`parent-checkbox`, orderIndex: 2},
                  { field: "projectName", title:"Project Name", width: "454px", show: true, orderIndex: 3},
                  { field: "projectId", show: false},
                  { field: "classifier", show: false},
                  { field: "timestamp", show: false},
                ]}}/>
              ) : (<></>)}

              </Col>
            </Row>
          </Dialog>
        }

      </div>
    );
  }
}

const mapStateToProps = createSelector(
  state => state.project.projectList,
  state => state.project.apiConnect,
  state => state.study.studyList,
  state => state.study.fetchedStudyProjectIds,
  state => state.notification.message,
  state => state.notification.messageType,
  state => state.processFlow.processFlowList,
  state => state.userPreference.studyList,
  state => state.study.studyManagerSchemaName,
  (projectList, apiConnectProject, studyList, fetchedStudyProjectIds, message, messageType, processFlowList, userPreferenceStudyList, studyManagerSchemaName) => ({
    projectList,
    apiConnectProject,
    studyList,
    fetchedStudyProjectIds,
    message,
    messageType,
    processFlowList,
    userPreferenceStudyList,
    studyManagerSchemaName
  })
);

const mapActionsToProps = {
  fetchProject: fetchProject,
  onApiConnectProject: apiConnectProject,
  buildTreeViewStatus: buildTreeViewStatus,
  removeProcessFlowByStudyId: removeProcessFlowByStudyId,
  setALLProcessFlowRenderStatus: setALLProcessFlowRenderStatus,
  setActiveProcessFlow: setActiveProcessFlow,
  addStudy: addStudy,
  setMessage: setMessage,
  updateProjectList: updateProjectList,
  saveSelectedStudy: saveSelectedStudy,
  addMultipleStudy: addMultipleStudy,
  setStudyManager: setStudyManager,
}

Study.propTypes = {
  projectList: PropTypes.array,
  apiConnectProject: PropTypes.bool,
  studyList: PropTypes.array
}

Dialog.propTypes = {
    height: PropTypes.oneOfType([
        PropTypes.string
    ]),
    width: PropTypes.oneOfType([
        PropTypes.string
    ])
}
export default connect(mapStateToProps, mapActionsToProps)(Study);
